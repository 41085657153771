import { createAction, props } from '@ngrx/store';
import { GetCatalogDisplayInfoResponse } from '../../../../api/endpoints/get-catalog-display-info';
import { WpError } from '@rootTypes';

export const getCatalogDisplayInfoRequested = createAction(
  '[CatalogDisplayInfo] API Get catalog display info requested',
  props<{ catalogId: string }>(),
);

export const getCatalogDisplayInfoSuccess = createAction(
  '[CatalogDisplayInfo] API Get catalog display info sucess',
  props<{ catalogId: string; response: GetCatalogDisplayInfoResponse }>(),
);

export const getCatalogDisplayInfoFailed = createAction(
  '[CatalogDisplayInfo] API Get catalog display info failed',
  props<{ catalogId: string; error: WpError }>(),
);
