export const isMatchedWithSearchTerm = (textLowerCased = '', searchTermLowerCased = ''): boolean => {
  if (searchTermLowerCased.length <= 1) {
    return true;
  }
  return textLowerCased.includes(searchTermLowerCased);
};

export const isMatchedWithSearchTermSubsequence = (textLowerCased = '', searchTermLowerCased = ''): boolean => {
  if (searchTermLowerCased.length <= 1) {
    return true;
  }
  const termChars = searchTermLowerCased.split('');
  const textChars = textLowerCased.split('');
  let termIndex = 0;
  let textIndex = 0;
  let matches = 0;
  for (; termIndex < termChars.length; termIndex++) {
    for (; textIndex < textChars.length; textIndex++) {
      if (termChars[termIndex] === textChars[textIndex]) {
        matches++;
        textIndex++;
        break;
      }
    }
  }
  return matches === termChars.length;
};
