import { createSelector, createFeatureSelector } from '@ngrx/store';
import { AccountState } from '../reducers';
import { currentUser, logoutLoading } from './auth.selectors';

export const accountFeatureKey = 'account';

export const accountState = createFeatureSelector<AccountState>(accountFeatureKey);

export const selectedAccountState = createSelector(accountState, (state) => state.selectedAccount);

export const selectedAccount = createSelector(selectedAccountState, (state) => state.data);

export const isSubAccountRequired = createSelector(selectedAccountState, (state) =>
  state.data ? state.data.isSubAccountsRequired : null,
);

export const accountsState = createSelector(accountState, (state) => state.accounts);

export const accounts = createSelector(accountsState, (state) => state.data);

export const isAccountsLoading = createSelector(accountsState, (state) => state.isLoading);

export const setAccountState = createSelector(accountState, (state) => state.setAccount);

export const isSetAccountLoading = createSelector(setAccountState, (state) => state.isLoading);

export const setAccountError = createSelector(setAccountState, (state) => state.error);

export const homeGuardCheckAccountLoading = createSelector(
  accountState,
  (state) => state.homeGuardCheckAccount.isLoading,
);

export const isSwitchAccountPossible = createSelector(selectedAccountState, (state) => {
  if (!state.data) {
    return false;
  }
  return state.data.isAccountRequired || state.data.isSubAccountsRequired;
});

export const isSelectedAccountShown = createSelector(selectedAccountState, (state) => !!state?.data?.accountId);

export const selectedAccountName = createSelector(selectedAccountState, (state): string | undefined => {
  if (state?.data?.name) {
    return state.data.name;
  }
  return undefined;
});

export const selectedAccountId = createSelector(selectedAccountState, (state): string | undefined => {
  return state?.data?.accountId ? '' + state?.data?.accountId : undefined;
});

export const selectedAccountImagePath = createSelector(selectedAccountState, (state): string | undefined => {
  if (state?.data?.imagePath) {
    return state.data.imagePath;
  }
  return undefined;
});

export const selectedAccountTimezone = createSelector(selectedAccountState, (state) => state?.data?.timezone);

export const isAutoLogoutPossible = createSelector(currentUser, logoutLoading, (user, loading) => !!user && !loading);

export const getSignedInVendor = createSelector(accountState, (state) => state.signedInVendor);
export const getSignedInDistrict = createSelector(accountState, (state) => state.signedInDistrict);
