import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-icon-vm-photo-view',
  templateUrl: './icon-vm-photo-view.component.html',
  styleUrls: ['./icon-vm-photo-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconVmPhotoViewComponent implements OnInit {
  @Input() public selected = false;

  constructor() {}

  ngOnInit(): void {}
}
