import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-icon-close',
  templateUrl: './icon-close.component.html',
  styleUrls: ['./icon-close.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconCloseComponent implements OnInit {
  /**
   * 32px - large
   * 16px - small
   * 12px - xsmall
   */

  @Input() public size: 'large' | 'small' | 'xsmall' = 'large';

  constructor() {}

  ngOnInit(): void {}
}
