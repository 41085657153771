import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

const ResizeObserver: any = window['ResizeObserver'];

@Directive({
  selector: '[wpWidthChanged]',
})
export class WidthChangedDirective implements AfterViewInit, OnDestroy {
  @Output() public widthChanged: EventEmitter<number> = new EventEmitter<number>();
  private subscription = new Subscription();

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    if (!ResizeObserver) {
      return;
    }
    const sub = this.getWidthObservable().subscribe((width) => {
      this.widthChanged.emit(width);
    });
    this.subscription.add(sub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getWidthObservable(): Observable<number> {
    return new Observable<number>((observer) => {
      const callback = (entries: any[]) => {
        const width = entries && entries.length ? entries[0].contentRect.width : undefined;
        observer.next(width);
      };
      const resizeObs = new ResizeObserver(callback);
      resizeObs.observe(this.el.nativeElement);
      return () => {
        resizeObs.unobserve(this.el.nativeElement);
      };
    });
  }
}
