import { PortalRouterService } from '../../types/portal-router-service';
import { Injectable } from '@angular/core';
import { campusDetailsRoute, CampusDetailsRouteData } from './campus-details-route';
import { RouterOutlets } from '@rootTypes/entities/router';

const route = campusDetailsRoute;

@Injectable({ providedIn: 'root' })
export class CampusDetailsRouterService extends PortalRouterService<CampusDetailsRouteData> {
  outlet = RouterOutlets.DRAWER;
  route = route;
}
