import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromApi from '../../api';
import { ApiService } from '../../api/api.service';
import * as fromTypes from './types';
import { getEntityFilterRequest } from '@rootTypes/utils/portal-entity/get-entity-filter-request';
import { entitySearchResultToPortalEntity } from '@rootTypes/utils/portal-entity/entity-search-result-to-portal-enity';

@Injectable({
  providedIn: 'root',
})
export class EntityFilterApiService {
  constructor(private api: ApiService) {}

  public entityFilter(
    query: string,
    skip: number,
    limit: number,
    type: fromTypes.PortalEntityType,
    status: fromTypes.EntityStatus,
    options: fromApi.entityFilter.EntityFilterOptions,
  ): Observable<fromTypes.SearchResponseWithSearhTerm> {
    const request = getEntityFilterRequest(query, type, status, skip, limit, options);
    return this.api.entityFilter(request).pipe(
      map((resp) => {
        const result: fromTypes.SearchResponseWithSearhTerm = {
          results: resp.results.map((r) => entitySearchResultToPortalEntity(r)),
          nextSkip: resp.nextSkip,
          searchTerm: query,
          error: null,
        };
        return result;
      }),
    );
  }
}
