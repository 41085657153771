import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const moduleKey = 'Vendor Employees';
const submoduleKey = 'Manage Permissions';

export const initPermissionsEditor = createAction(
  `[${moduleKey}] [${submoduleKey}]: Init permissions editor`,
  props<{ employeeId?: string }>(),
);

export const updateEmployeePermissionsRequested = createAction(
  `[${moduleKey}] [${submoduleKey}]: Update vendor employee permissions requested`,
  props<{ target: fromTypes.EmployeePermission[] }>(),
);

export const updateEmployeePermissionsSuccess = createAction(
  `[${moduleKey}] [${submoduleKey}]: Update vendor employee permissions success`,
  props<{ employeeId: string }>(),
);

export const updateEmployeePermissionsFailed = createAction(
  `[${moduleKey}] [${submoduleKey}]: Update vendor employee permissions failed`,
  props<{ employeeId: string; error: fromTypes.WpError }>(),
);

export const updateEmployeePermissionsCanceled = createAction(
  `[${moduleKey}] [${submoduleKey}]: Update vendor employee permissions canceled`,
);
