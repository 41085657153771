<div
  class="wp-section-error"
  [ngStyle]="{ height: sectionHeightStr }"
  [ngClass]="{ 'full-height': sectionHeightStr === '100%' }"
>
  <ng-content></ng-content>
  <ng-container *ngIf="errorStatements">
    <div>
      <div *ngIf="isGeneralMessage && errorStatements?.generalErrorMessage">
        {{errorStatements.generalErrorMessage}}&nbsp;
        <span class="retry-btn" *ngIf="error.retryFn" (click)="error.retryFn()"
          >Retry</span
        >
      </div>
      <div *ngIf="errorStatements?.specialErrorMessage">
        {{errorStatements.specialErrorMessage}}
      </div>
      <div *ngIf="errorStatements?.errorReason">
        {{errorStatements.errorReason}}
      </div>
      <div *ngIf="errorStatements?.correctiveAction">
        {{errorStatements.correctiveAction}}
      </div>
      <div *ngIf="isContextId && errorStatements?.contextId">
        Context id: {{errorStatements.contextId}}
      </div>
    </div>
  </ng-container>
</div>
