import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { NavigationRequest } from '../../types/navigation-request';
import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '../../../types/entities/router';
import { getRoutingRequest } from '@rootTypes/utils/router/get-routing-request';

export interface SchoolEmployeeListRouteData {
  page?: string;
  pageSize?: string;
  search?: string;
  filters?: string;
}

export const schoolEmployeeListRoute: PortalRoute<SchoolEmployeeListRouteData> = {
  path: 'list',
  isOnRoute: (state: RouterStateUrl): boolean => {
    return state.url.startsWith('/school-employees/list');
  },
  parse: (state: RouterStateUrl): SchoolEmployeeListRouteData => {
    let data: SchoolEmployeeListRouteData = {};
    if (state.queryParams) {
      data = {
        ...state.queryParams,
      };
    }
    return data;
  },
  request: (
    data: SchoolEmployeeListRouteData = {},
    queryParamStrategy?: QueryParamsHandlingStrategy,
    replaceUrl = false,
  ): NavigationRequest => {
    const path = ['school-employees', 'list'];
    const routeData = { ...data };
    return getRoutingRequest(path, routeData, queryParamStrategy, replaceUrl);
  },
  getHref(data?: SchoolEmployeeListRouteData): string {
    return '/school-employees/list';
  },
};

@Injectable({ providedIn: 'root' })
export class SchoolEmployeeListRouterService extends PortalRouterService<SchoolEmployeeListRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route: PortalRoute<SchoolEmployeeListRouteData> = schoolEmployeeListRoute;
}
