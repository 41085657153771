import { StudentRfidListStatusFilter } from '../types/student-rfid-list';
import { StudentRfidListStatus } from '../types/student-rfid-list-status';

export const createStudentRfidListStatusFilter = (status?: StudentRfidListStatus): StudentRfidListStatusFilter => {
  if (!status) {
    return undefined;
  }
  return {
    id: `rfid-status,${status}`,
    type: 'rfid-status',
    payload: status,
  };
};
