import { SmartFormFieldAddress, SmartFormFieldHomeAddress, SmartFormFieldType } from '@apiEntities';

export const isSmartFormFieldHomeAddress = (arg: any): arg is SmartFormFieldAddress => {
  return !!arg && typeof arg === 'object' && arg.type === SmartFormFieldType.HOME_ADDRESS;
};

export const isSmartFormFieldHomeAddressValid = (address: SmartFormFieldHomeAddress, required: boolean): boolean => {
  if (!required && !address.value) {
    return true;
  }
  if (!address.value) {
    return false;
  }
  return !!address.value.address || address.value.isProtectedAddress || address.value.isAddressNotKnown;
};
