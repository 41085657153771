import { Injectable } from '@angular/core';
import { FlashApiService } from '../../../api/flash-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromActions from './flash.actions';

@Injectable()
export class FlashEffects {
  constructor(
    private flashApi: FlashApiService,
    private actions$: Actions,
  ) {}

  /**
   * Connect/disconnect
   */
  public connectRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.flashConnectRequested),
      map(() => {
        return this.flashApi.connectFlashClient();
      }),
      map(() => fromActions.flashConnectSuccess()),
      catchError((err) => {
        console.log(err);
        return of(
          fromActions.flashConnectFailed({
            error: {
              text: 'Failed to connect flash client',
              originalError: err,
            },
          }),
        );
      }),
    ),
  );
}
