import { createSelector } from '@ngrx/store';
import { AllRouteListState, RouteListState } from '../reducers/route-lists.reducers';
import { getFeature } from './get-feature';
import * as fromTypes from '../../types';

export const getAllRouteListsState = createSelector<any, any, AllRouteListState>(getFeature, (state) => state.lists);

export const getSingleRouteList = createSelector(
  getAllRouteListsState,
  (state, props: { listName: fromTypes.RouteListNames }): RouteListState => {
    const { listName } = props;
    return state[listName];
  },
);

export const getRouteListSnapshot = createSelector(getSingleRouteList, (state): fromTypes.RouteListSnapshot => {
  return {
    currentPage: state.pagination.currentPage,
    pageSize: state.pagination.pageSize,
    filters: state.filters,
    staticFilters: state.staticFilters,
    statusType: state.currentRouteStatusType,
  };
});

export const getSelectedWeekday = createSelector(getSingleRouteList, (state) => state.weekday);

export const getSelectedRouteId = createSelector(getSingleRouteList, (state) => state.selectedRouteId);

export const getSelectedStatusType = createSelector(getSingleRouteList, (state) => state.currentRouteStatusType);

// page
const getPageState = createSelector(getSingleRouteList, (state) => state.page);

export const getRoutePageLoading = createSelector(getPageState, (state) => state.pageLoading);

export const getRoutePageError = createSelector(getPageState, (state) => state.error);

export const getRoutePageItems = createSelector(getPageState, (state) => state.currentRoutes);

export const getTotalItemsCount = createSelector(getPageState, (state) => state.totalItemsCount);

// selected filters
export const getSelectedFilters = createSelector(getSingleRouteList, (state) => state.filters);

// pagination
const getPaginationState = createSelector(getSingleRouteList, (state) => state.pagination);

export const getCurrentPage = createSelector(getPaginationState, (state) => state.currentPage);

export const getPageSize = createSelector(getPaginationState, (state) => state.pageSize);
