import { DriverListSnapshot, SerializedDriverListSnapshot } from '../entities';
import { driverListDefaultPageSize, driverListDefaultStatus } from '../ui';

export const getSerializedSnapshot = (params: DriverListSnapshot): SerializedDriverListSnapshot => {
  return {
    search: params.search || undefined,
    page: pageIndexToPageNumber(params.pageIndex),
    driverStatus: params.driverStatus === driverListDefaultStatus ? undefined : params.driverStatus,
    pageSize: params.pageSize === driverListDefaultPageSize ? undefined : params.pageSize.toString(),
  };
};

function pageIndexToPageNumber(source = 0): string | undefined {
  if (source === 0) {
    return undefined;
  }
  return (source + 1).toString();
}
