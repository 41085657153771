import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { StackLayoutService } from '../stack-layout.service';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'wp-stack-layout-item',
  templateUrl: './stack-layout-item.component.html',
  styleUrls: ['./stack-layout-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StackLayoutItemComponent implements OnInit {
  @Input() public widthStr = 'auto';
  public first$ = new BehaviorSubject<boolean>(false);
  public last$ = new BehaviorSubject<boolean>(false);

  public stylesObject$: Observable<any>;

  constructor(private stateService: StackLayoutService) {}

  ngOnInit(): void {
    const spacing$ = this.stateService.spacing$;
    const direction$ = this.stateService.direction$;
    this.stylesObject$ = combineLatest([this.first$, this.last$, spacing$, direction$]).pipe(
      map(([first, last, spacing, direction]) => {
        const styleProperty =
          direction === 'column'
            ? 'margin-bottom'
            : direction === 'column-reverse'
              ? 'margin-top'
              : direction === 'row'
                ? 'margin-right'
                : 'margin-left';
        const shouldApply = !last;
        if (shouldApply) {
          return {
            [styleProperty]: spacing + 'px',
            width: this.widthStr,
          };
        } else {
          return {
            width: this.widthStr,
          };
        }
      }),
    );
  }
}
