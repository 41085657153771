import { SelectOption } from '../../../../shared/form-controls';
import { VehicleInspectionDisplayStatus } from '../entities';
import { VehicleInspectionStatus } from '@apiEntities';

const vehicleInspectionStatusOptions: SelectOption[] = [
  {
    displayLabel: VehicleInspectionDisplayStatus.ALL,
    value: VehicleInspectionDisplayStatus.ALL,
  },
  {
    displayLabel: VehicleInspectionDisplayStatus.DRAFT,
    value: VehicleInspectionDisplayStatus.DRAFT,
  },
  {
    displayLabel: VehicleInspectionDisplayStatus.SATISFACTORY,
    value: VehicleInspectionDisplayStatus.SATISFACTORY,
  },
  {
    displayLabel: VehicleInspectionDisplayStatus.SATISFACTORY_WITH_ISSUES,
    value: VehicleInspectionDisplayStatus.SATISFACTORY_WITH_ISSUES,
  },
  {
    displayLabel: VehicleInspectionDisplayStatus.UNSATISFACTORY,
    value: VehicleInspectionDisplayStatus.UNSATISFACTORY,
  },
];

export const getVehicleInspectionStatusOptions = (isDraftOption: boolean, isAllOption: boolean): SelectOption[] => {
  const allOptions = [...vehicleInspectionStatusOptions];
  const skip: { [key: string]: true } = {};
  if (!isAllOption) {
    skip[VehicleInspectionDisplayStatus.ALL] = true;
  }
  if (!isDraftOption) {
    skip[VehicleInspectionDisplayStatus.DRAFT] = true;
  }
  return allOptions.filter((opt) => !skip[opt.value]);
};

const displayToStatusMap = {
  [VehicleInspectionDisplayStatus.ALL]: undefined,
  [VehicleInspectionDisplayStatus.UNSATISFACTORY]: VehicleInspectionStatus.UNSATISFACTORY,
  [VehicleInspectionDisplayStatus.SATISFACTORY]: VehicleInspectionStatus.SATISFACTORY,
  [VehicleInspectionDisplayStatus.DRAFT]: VehicleInspectionStatus.DRAFT,
  [VehicleInspectionDisplayStatus.SATISFACTORY_WITH_ISSUES]: VehicleInspectionStatus.SATISFACTORY_WITH_ISSUES,
};

const statusToDisplayMap = {
  [VehicleInspectionStatus.UNSATISFACTORY]: VehicleInspectionDisplayStatus.UNSATISFACTORY,
  [VehicleInspectionStatus.SATISFACTORY]: VehicleInspectionDisplayStatus.SATISFACTORY,
  [VehicleInspectionStatus.DRAFT]: VehicleInspectionDisplayStatus.DRAFT,
  [VehicleInspectionStatus.SATISFACTORY_WITH_ISSUES]: VehicleInspectionDisplayStatus.SATISFACTORY_WITH_ISSUES,
};

export const vehicleInspectionStatusToDisplay = (status?: VehicleInspectionStatus): VehicleInspectionDisplayStatus => {
  if (!status) {
    return VehicleInspectionDisplayStatus.ALL;
  }
  return statusToDisplayMap[status];
};

export const displayToVehicleInspectionStatus = (
  display: VehicleInspectionDisplayStatus,
): VehicleInspectionStatus | undefined => {
  if (display === VehicleInspectionDisplayStatus.ALL) {
    return undefined;
  }
  return displayToStatusMap[display];
};
