import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { go } from '@rootStore';
import { userProfileInfoEditRoute } from 'src/app/router/portal-routes/home';
import { currentUserEmail, currentUserFullName, currentUserPhone } from 'src/app/auth/store';

@Injectable()
export class UserProfileInfoFacade {
  constructor(private store: Store) {}

  public getUserFullName$(): Observable<string> {
    return this.store.select(currentUserFullName);
  }

  public getUserPhone$(): Observable<string> {
    return this.store.select(currentUserPhone);
  }

  public getUserEmail$(): Observable<string> {
    return this.store.select(currentUserEmail);
  }

  public goProfileInfoEdit(): void {
    this.store.dispatch(go(userProfileInfoEditRoute.request()));
  }
}
