<div class="wp-alert warning">
  <div class="icon-wrap">
    <div class="icon">
      <wp-icon-alert-warning></wp-icon-alert-warning>
    </div>
  </div>
  <div class="text">
    <ng-content></ng-content>
  </div>

  <div class="close-icon" *ngIf="!readonly">
    <wp-icon-close [size]="'small'"></wp-icon-close>
  </div>
</div>
