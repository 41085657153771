import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'wp-input-suffix',
  templateUrl: './input-suffix.component.html',
  styleUrls: ['./input-suffix.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputSuffixComponent implements OnInit {
  @Input() public marginRight = '0px';
  @Input() public moveDown = '0px';
  @Input() public moveRight = '0px';
  @Output() public onClicked = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
