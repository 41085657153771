import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-icon-vendor-badge',
  templateUrl: './icon-vendor-badge.component.html',
  styleUrls: ['./icon-vendor-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconVendorBadgeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
