import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as fromActions from '../actions/error.actions';
import { WpError } from '@rootTypes';

@Injectable()
export class ErrorFacade {
  constructor(private store: Store) {}

  public goErrorPage(error: WpError): void {
    this.store.dispatch(fromActions.goErrorPage({ error }));
  }

  public openErrorPopup(error: WpError, hideErrorHeader?: boolean): void {
    this.store.dispatch(fromActions.openErrorPopup({ error, hideErrorHeader }));
  }
}
