import { EmployeePermission, SpecialPermissions } from '@rootTypes/entities/employees';

/**
 * Detect, if the resulting permissions will be narrower than the initial ones.
 * Keep simple for now, might include more complicated logic for ph.2
 */
export const isEmployeePermissionsReduced = (prev: EmployeePermission[], target: EmployeePermission[]): boolean => {
  const isSelectAllAdded = target.some((p) => p.entityId === SpecialPermissions.ALL);
  if (isSelectAllAdded) {
    return false;
  }
  const somePermissionRemoved = (prev || []).some((prevPermission) => {
    return !target.find((targetPermission) => targetPermission.entityId === prevPermission.entityId);
  });
  return somePermissionRemoved;
};
