import { createAction, props } from '@ngrx/store';
import * as fromTypes from '@rootTypes';
import { ApiStudentWithProjections } from '@apiEntities';
import { StudentLoadOptions } from '../../types/student-load-options';

const modulePrefix = 'Data';
const sectionPrefix = 'Students';

export const loadStudentDetailsRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load student details requested`,
  props<{ studentId: string; options?: StudentLoadOptions }>(),
);

export const loadStudentDetailsOnApplyChangeRequest = createAction(
  `[ChangeRequestsApplyEffects]: Load student details requested on apply change request`,
  props<{ studentId: string; options?: StudentLoadOptions }>(),
);

export const loadStudentDetailsRequestedOnSelectStudentInRouteExplorerRefreshStudents = createAction(
  `[RouteExplorerRefreshStudentPopupEffects]: Load student details requested`,
  props<{ studentId: string; options?: StudentLoadOptions }>(),
);

export const loadStudentDetailsForPastProgramsRequested = createAction(
  `[StudentPastPrograms]: Load student details for past programs requested`,
  props<{ studentId: string; options?: StudentLoadOptions }>(),
);

export const loadStudentDetailsSuccess = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load student details success`,
  props<{ studentId: string; data: ApiStudentWithProjections }>(),
);

export const loadStudentDetailsFailed = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load student details failed`,
  props<{ studentId: string; error: fromTypes.WpError }>(),
);

export const loadStudentDetailsActions = [
  loadStudentDetailsRequested,
  loadStudentDetailsOnApplyChangeRequest,
  loadStudentDetailsRequestedOnSelectStudentInRouteExplorerRefreshStudents,
  loadStudentDetailsForPastProgramsRequested,
];

/**
 * Get student verification status
 */
export const getStudentVerificationStatusRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: getStudentVerificationStatus requested`,
  props<{ studentId: string }>(),
);

export const getStudentVerificationStatusRequestedFromList = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: getStudentVerificationStatus requested from student list`,
  props<{ studentId: string }>(),
);

export const getStudentVerificationStatusSuccess = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: getStudentVerificationStatus success`,
  props<{ studentId: string; data: fromTypes.rider.VerificationStatus[] }>(),
);

export const getStudentVerificationStatusFailed = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: getStudentVerificationStatus failed`,
  props<{ studentId: string; error: fromTypes.WpError }>(),
);
