import { Component, OnInit, ViewChild, ViewContainerRef, AfterViewInit } from '@angular/core';
import { AbstractPopupComponent } from '../types/abstract-popup-component';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { take, filter, switchMap, map, shareReplay } from 'rxjs/operators';
import { ComponentItem } from '../types/component-item';
import { PopupRef } from '../types/popup-ref';
import { PopupableOptions } from '../types';

@Component({
  selector: 'wp-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements AfterViewInit, OnInit {
  private static componentSubj: Subject<ComponentItem> = new Subject<ComponentItem>();
  private static closedPopup: Subject<any> = new Subject<any>();
  private static isHiddenSubj = new BehaviorSubject<boolean>(false);

  public isOpen$: Observable<boolean> | undefined;
  public popupRef$: Observable<PopupRef<any, any> | undefined>;
  public options$: Observable<PopupableOptions>;
  public contentZIndex$: Observable<number>;
  public pageMaskZIndex$: Observable<number>;
  public isHidden$: Observable<boolean>;

  public static open<C extends AbstractPopupComponent<D, R>, D, R>(
    component: C,
    data: D,
    options?: PopupableOptions,
  ): Observable<R> {
    this.componentSubj.next({
      name: component,
      data,
      options,
      popupRef: new PopupRef(),
    });

    return this.closedPopup.asObservable().pipe(take(1));
  }

  public static setHidden(hidden: boolean): void {
    this.isHiddenSubj.next(hidden);
  }

  public static close(): void {
    PopupComponent.closedPopup.next(undefined);
    PopupComponent.componentSubj.next(undefined);
  }

  @ViewChild('container', { read: ViewContainerRef }) private containerRef!: ViewContainerRef;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    PopupComponent.componentSubj
      .asObservable()
      .pipe(
        filter((cmp) => !!cmp),
        switchMap((cmp) => this.onComponentNameChanged(cmp)),
        map((resultData) => {
          PopupComponent.closedPopup.next(resultData);
        }),
      )
      .subscribe();

    this.isOpen$ = PopupComponent.componentSubj.asObservable().pipe(
      switchMap((cmp) => {
        if (!(cmp && cmp.popupRef)) {
          return of(false);
        }
        return cmp.popupRef.isOpen$;
      }),
    );
    this.options$ = PopupComponent.componentSubj.asObservable().pipe(
      map((comp) => {
        if (comp && comp.options) {
          return comp.options;
        }
        return null;
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
    this.contentZIndex$ = this.options$.pipe(
      map((options) => {
        if (options && options.contentZIndex) {
          return options.contentZIndex;
        }
        return 11;
      }),
    );
    this.pageMaskZIndex$ = this.options$.pipe(
      map((options) => {
        if (options && options.pageMaskZIndex) {
          return options.pageMaskZIndex;
        }
        return 10;
      }),
    );

    this.isHidden$ = PopupComponent.isHiddenSubj.asObservable();
    this.popupRef$ = PopupComponent.componentSubj.pipe(
      map((item) => {
        if (item && item.popupRef) {
          return item.popupRef;
        }
        return undefined;
      }),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );
    this.popupRef$.subscribe();

    PopupComponent.closedPopup.asObservable().subscribe(() => {
      this.containerRef.clear();
    });
  }

  private onComponentNameChanged(component: ComponentItem): Observable<any> {
    component.popupRef.data = component.data;
    this.containerRef.clear();
    const { instance } = this.containerRef.createComponent(component.name);
    instance['popupRef'] = component.popupRef;
    return component.popupRef.closed();
  }

  public onClose(): void {
    PopupComponent.close();
  }

  public async onPageMaskClicked(): Promise<void> {
    const popupRef = await this.popupRef$.pipe(take(1)).toPromise();
    popupRef.onPageMaskClick();
    const options = await this.options$.pipe(take(1)).toPromise();
    if (!options?.preventCloseOnPageMaskClick) {
      popupRef.close();
    }
  }
}
