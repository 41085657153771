import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { SegmentAnalyticsService } from '../../segment-analytics.service';
import { enableSegment } from '../actions';

@Injectable({
  providedIn: 'root',
})
export class SegmentManagerService {
  constructor(
    private store: Store,
    private analytics: SegmentAnalyticsService,
  ) {}

  public async initialize(): Promise<void> {
    if (!wpEnvironment.segmentEnabled) {
      await this.analytics.initializeMock();
      return;
    }

    if (wpEnvironment.segmentId) {
      await this.analytics.initialize(wpEnvironment.segmentId);
      await this.analytics.debug();
      this.store.dispatch(enableSegment());
    } else {
      console.warn('SEGMENT_ID is not defined in the environment, skipping Segment initialization');
      await this.analytics.initializeMock();
    }
  }
}
