import { EntityFilterRequest } from '../../../../api/endpoints/entity-filter';
import { YardListFilterType, YardListSnapshot } from '../entities';
import { YardListEntityFilter } from '../entities/yard-list-filter';
import { PortalEntityType } from '../../../../types';
import { getEntityFilterRequest } from '@rootTypes/utils/portal-entity/get-entity-filter-request';
import { EntitySearchResponseType } from '@apiEntities/entity-search';

export const getYardListRequest = (snapshot: YardListSnapshot): EntityFilterRequest => {
  const { page, pageSize, search, status, filters } = snapshot;
  const skip = page * pageSize;
  const limit = pageSize;
  const entityFilters: YardListEntityFilter[] = (filters || []).filter((f) => f.type === YardListFilterType.ENTITY);
  const vendorIds = entityFilters
    .filter((f) => f.payload.type === PortalEntityType.VENDOR)
    .map((f) => f.payload.entityId);
  return getEntityFilterRequest(search, EntitySearchResponseType.YARD, status, skip, limit, { vendorIds });
};
