import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'wp-animated-width-change',
  templateUrl: './animated-width-change.component.html',
  styleUrls: ['./animated-width-change.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnimatedWidthChangeComponent implements OnInit {
  @Input() public shouldShrinkToContent = false;
  @ViewChild('outer') public outerComponent: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  public onWidthChanged(width: number): void {
    if (!this.outerComponent) {
      return;
    }
    const element: HTMLElement = this.outerComponent.nativeElement;
    element.style.width = `${width}px`;
  }
}
