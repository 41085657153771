import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/api/api.service';
import * as fromTypes from '../types';
import * as fromApi from '../../../api';
import { RouteProjection } from '../../../api/endpoints/get-route';

@Injectable()
export class RoutesApiService {
  constructor(private api: ApiService) {}

  public getRoute(request: {
    routeId: string;
    withPolyline: boolean;
    withDistrictProgram;
  }): Observable<fromTypes.GetRoutePayload> {
    const apiRequest: fromApi.getRoute.GetRouteRequest = { route_id: request.routeId };
    if (request.withPolyline) {
      apiRequest._projections = [fromApi.getRoute.RouteProjection.polyline];
    }
    if (request.withDistrictProgram) {
      apiRequest._projections = [...(apiRequest._projections || []), RouteProjection.districtProgram];
    }
    return this.api.getRoute(apiRequest).pipe(map(fromApi.getRoute.convertors.dbToGetRoutePayload));
  }

  public getRouteCalendar(routeId: string): Observable<fromTypes.RouteCalendar> {
    return this.api.getRouteCalendar(routeId).pipe(
      map((resp) => {
        return fromApi.getRouteCalendar.convertors.dbToRouteCalendar(resp);
      }),
    );
  }

  public getDriver(driverId: string): Observable<fromTypes.Driver> {
    return this.api
      .getDriverProfile(driverId)
      .pipe(map((res) => fromApi.getDriverProfile.convertors.dbDriverObjectToDriver(res)));
  }

  public getRider(riderId: string): Observable<fromTypes.Rider> {
    return this.api.getRider(riderId).pipe(map((resp) => fromApi.getRiders.convertors.dbToRider(resp)));
  }

  public loadRoutesPage(snapshot: fromTypes.RouteListSnapshot): Observable<fromApi.routes.RouteFilterResponse> {
    const request = fromTypes.utils.getRouteListRequest(snapshot.statusType, snapshot.currentPage, snapshot.pageSize, [
      ...snapshot.staticFilters,
      ...snapshot.filters,
    ]);
    return this.api.routes(request);
  }
}
