import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-dropdown-trigger',
  templateUrl: './dropdown-trigger.component.html',
  styleUrls: ['./dropdown-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownTriggerComponent implements OnInit {
  @Input() public disabled = false;
  /**
   * | Label   > | (true)
   * vs
   * | Label > |   (false)
   */
  @Input() public triggerSpread = false;

  constructor() {}

  ngOnInit(): void {}
}
