<div class="follow-up">
  <div *ngFor="let item of items" class="follow-up-action">
    <div class="completed-icon">
      <ng-container *ngIf="item.completed">
        <wp-portal-icon
          [path]="checkIconPath"
          [alt]="'Checked'"
        ></wp-portal-icon>
      </ng-container>
    </div>
    <div class="label">{{item.label}}</div>
  </div>
</div>
