import { Observable, of, zip } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { RouterStateUrl } from '@rootStore';
import { PortalPermissionsFacade } from 'src/app/auth/store/facades/portal-permissions.facade';
import { workQueueDriverDocumentsRoute } from './work-queue-driver-documents';
import { workQueueStudentRequestsRoute } from './work-queue-student-requests';
import { workQueueTimesheetRequestsRoute } from './work-queue-timesheet-requests';
import { workQueueRouteGroupRequestsRoute } from './work-queue-route-group-requests';
import { workQueueDriverBusReportsRoute } from './work-queue-driver-bus-reports.router-service';
import { workQueueTripAssignmentRoute } from './work-queue-trip-assignment.router-service';
import { workQueueStudentRfidListRoute } from './work-queue-student-rfid-list.router-service';

export interface WorkQueueTab {
  id: WorkQueueTabId;
  label: string;
  isLeftBorder: boolean;
  isEnabledForUser$(permissions: PortalPermissionsFacade): Observable<boolean>;
  isOnRoute(state: RouterStateUrl): boolean;
  getHref(): string;
}

export enum WorkQueueTabId {
  DRIVER_DOCUMENTS = 'DRIVER_DOCUMENTS',
  STUDENT_REQUESTS = 'STUDENT_REQUESTS',
  TIMESHEET_REQUESTS = 'TIMESHEET_REQUESTS',
  ROUTE_GROUP_REQUESTS = 'ROUTE_GROUP_REQUESTS',
  DRIVER_BUS_REPORTS = 'DRIVER_BUS_REPORTS',
  TRIP_ASSIGNMENT = 'TRIP_ASSIGNMENT',
  STUDENT_RFID_CARDS = 'STUDENT_RFID_CARDS',
}

const allTabs: WorkQueueTab[] = [
  {
    id: WorkQueueTabId.DRIVER_DOCUMENTS,
    label: 'Driver Documents',
    isLeftBorder: false,
    isEnabledForUser$: (permissions) => permissions.isDriverDocumentEdit$(),
    isOnRoute: (state) => workQueueDriverDocumentsRoute.isOnRoute(state),
    getHref: () => workQueueDriverDocumentsRoute.getHref(),
  },
  {
    id: WorkQueueTabId.STUDENT_REQUESTS,
    label: 'Student Requests',
    isLeftBorder: false,
    isEnabledForUser$: (permissions) => permissions.isStudentEdit$(),
    isOnRoute: (state) => workQueueStudentRequestsRoute.isOnRoute(state),
    getHref: () => workQueueStudentRequestsRoute.getHref(),
  },
  {
    id: WorkQueueTabId.TIMESHEET_REQUESTS,
    label: 'Timesheet Entries',
    isLeftBorder: false,
    isEnabledForUser$: (permissions) => permissions.isTimesheetEntryEdit$(),
    isOnRoute: (state) => workQueueTimesheetRequestsRoute.isOnRoute(state),
    getHref: () => workQueueTimesheetRequestsRoute.getHref(),
  },
  {
    id: WorkQueueTabId.ROUTE_GROUP_REQUESTS,
    label: 'Route Changes',
    isLeftBorder: false,
    isEnabledForUser$: (permissions) => permissions.isRouteGroupApproveEdit$(),
    isOnRoute: (state) => workQueueRouteGroupRequestsRoute.isOnRoute(state),
    getHref: () => workQueueRouteGroupRequestsRoute.getHref(),
  },
  {
    id: WorkQueueTabId.DRIVER_BUS_REPORTS,
    label: 'Driver Bus Reports',
    isLeftBorder: false,
    isEnabledForUser$: (permissions) => permissions.isDriverBusReportRawOCREdit$(),
    isOnRoute: (state) => workQueueDriverBusReportsRoute.isOnRoute(state),
    getHref: () => workQueueDriverBusReportsRoute.getHref(),
  },
  {
    id: WorkQueueTabId.TRIP_ASSIGNMENT,
    label: 'Trip Assignment',
    isLeftBorder: false,
    isEnabledForUser$: (permissions) => permissions.isCharterVendorAssignmentEdit$(),
    isOnRoute: (state) => workQueueTripAssignmentRoute.isOnRoute(state),
    getHref: () => workQueueTripAssignmentRoute.getHref(),
  },
  {
    id: WorkQueueTabId.STUDENT_RFID_CARDS,
    label: 'RFID cards',
    isLeftBorder: false,
    isEnabledForUser$: (permissions) => permissions.isStudentRfidWorkQueueEdit$(),
    isOnRoute: (state) => workQueueStudentRfidListRoute.isOnRoute(state),
    getHref: () => workQueueStudentRfidListRoute.getHref(),
  },
];

export const getWorkQueueTabs = (permissions: PortalPermissionsFacade): Observable<WorkQueueTab[]> => {
  return permissions.isWorkQueueView$().pipe(
    mergeMap((isWorkQueue) => {
      if (!isWorkQueue) {
        return of([]);
      }
      return zip(...allTabs.map((tab) => tab.isEnabledForUser$(permissions))).pipe(
        map((flags) => {
          const tabs: WorkQueueTab[] = [];
          let isFirstTabDefined = false;
          allTabs.forEach((tab, index) => {
            const isEnabled = flags[index];
            if (isEnabled) {
              const copy = { ...tab };
              if (!isFirstTabDefined) {
                copy.isLeftBorder = true;
                isFirstTabDefined = true;
              }
              tabs.push(copy);
            }
          });
          return tabs;
        }),
      );
    }),
  );
};
