import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

interface LinkBtnCssClasses {
  disabled: boolean;
  bold: boolean;
  'no-underline': boolean;
  'text-align-left': boolean;
}

/**
 * Looks like <a> but behaves like <button>,
 * it does not provide [src] and opening in new tab behavior
 * WARNING: Do not use this component for navigation or as labels for file inputs
 */
@Component({
  selector: 'wp-link-btn',
  templateUrl: './link-btn.component.html',
  styleUrls: ['./link-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkBtnComponent implements OnChanges {
  @Input() disabled = false;
  @Input() isBold = false;
  @Input() fontSize = 'inherit';
  @Input() isUnderlined = true;
  @Input() isTextAlignLeft = false;
  @Input() tabIndex = '0';
  @Output()
  clicked = new EventEmitter<MouseEvent>();

  public classes: LinkBtnCssClasses;

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.classes = {
      disabled: this.disabled,
      bold: this.isBold,
      'no-underline': !this.isUnderlined,
      'text-align-left': this.isTextAlignLeft,
    };
  }

  public onClick(event: MouseEvent): void {
    if (this.disabled) {
      event.stopPropagation();
    } else {
      this.clicked.emit(event);
    }
  }
}
