import { Pipe, PipeTransform } from '@angular/core';
import { ApiStudent } from '@apiEntities';
import { RiderEquipmentMap } from '@rootTypes/entities/rider';
import { getEquipmentMapForStudentV2 } from '@rootTypes/utils/rider/get-equipment-map-for-student-v2';

@Pipe({
  name: 'studentEquipmentV2',
})
export class StudentEquipmentV2Pipe implements PipeTransform {
  transform(value: ApiStudent): RiderEquipmentMap {
    return getEquipmentMapForStudentV2(value?.profile);
  }
}
