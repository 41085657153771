<wp-auth-wrapper [rightColumnImage]="'resetPassword'">
  <section class="wp-reset-password-success">
    <div class="container">
      <img src="/assets/img/logo.svg" alt="Zūm" />
      <div class="line"></div>
      <div class="header">
        <i class="checkmark-icon"></i>
        <span class="header-text">{{headerText$ | async}}</span>
      </div>
      <wp-link-btn class="link" (clicked)="goLoginPage()">Login </wp-link-btn>
    </div>
  </section>
</wp-auth-wrapper>
