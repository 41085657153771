<div class="icon" [ngClass]="{ selected: selected }">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g fill="none" fill-rule="evenodd">
      <circle cx="16" cy="16" r="15.5" fill="#F8F8FC" stroke="#636365" />
      <rect
        width="18"
        height="18"
        x="7"
        y="7"
        fill="#FFF"
        stroke="#5DBA4E"
        stroke-width="2"
        rx="2"
      />
      <path
        fill="#99CA41"
        fill-rule="nonzero"
        d="M23 24.333L18.74 15.333 16.002 19.915 13.264 17.416 9 24.333z"
      />
      <circle cx="12" cy="12" r="2" fill="#99CA41" fill-rule="nonzero" />
    </g>
  </svg>
</div>
