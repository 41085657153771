<div class="content">
  <div
    wpExpand
    [show]="isContainerExpanded"
    [displayCollapsedContent]="true"
    [ngStyle]="{'min-height': this.collapsedHeight}"
  >
    <ng-content></ng-content>
  </div>
  <div class="expand-button-container {{expandButtonPosition}}">
    <wp-link-btn
      class="expand-button"
      [ngStyle]="{margin: expandButtonMargin}"
      (clicked)="toggle()"
    >
      {{expandButtonText}}
    </wp-link-btn>
  </div>
</div>
