import { Injectable } from '@angular/core';
import * as fromTypes from '../../types';
import * as fromSelectors from '../selectors/vendor-lists.selectors';
import * as fromActions from '../actions/vendor-list.actions';
import { Observable } from 'rxjs/internal/Observable';
import { Store } from '@ngrx/store';
import { State } from '../../../../store';
import { Actions, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Injectable()
export class VendorListFacade {
  constructor(
    private store: Store<State>,
    private actions$: Actions,
  ) {}

  public getListLoading(listName: fromTypes.VendorListName): Observable<boolean> {
    return this.store.select(fromSelectors.getPageLoading, { listName });
  }

  public getListSerializedSnapshot(
    listName: fromTypes.VendorListName,
  ): Observable<fromTypes.SerializedVendorListSnapshot> {
    return this.actions$.pipe(
      ofType(fromActions.vendorLoadListSuccess),
      switchMap(() => this.store.select(fromSelectors.getListState, { listName })),
      map((listState) => {
        return fromTypes.utils.snapshotToSerialized({
          page: listState.pagination.page,
          pageSize: listState.pagination.pageSize,
          search: listState.currentSearch,
          filters: [],
        });
      }),
    );
  }

  public getListItems(listName: fromTypes.VendorListName): Observable<fromTypes.PortalEntity[]> {
    return this.store.select(fromSelectors.getPageItems, { listName });
  }

  public getListError(listName: fromTypes.VendorListName): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getPageError, { listName });
  }

  public getListEmpty(listName: fromTypes.VendorListName): Observable<boolean> {
    return combineLatest([this.getListItems(listName), this.getListLoading(listName)]).pipe(
      map(([items, loading]) => !loading && !items.length),
    );
  }

  public getPage(listName: fromTypes.VendorListName): Observable<number> {
    return this.store.select(fromSelectors.getCurrentPage, { listName });
  }

  public getPageSize(listName: fromTypes.VendorListName): Observable<number> {
    return this.store.select(fromSelectors.getPageSize, { listName });
  }

  public getSelectedVendorId(listName: fromTypes.VendorListName): Observable<string> {
    return this.store.select(fromSelectors.getSelectedVendorId, { listName });
  }

  public getTotalFound(listName: fromTypes.VendorListName): Observable<number> {
    return this.store.select(fromSelectors.getTotalCount, { listName });
  }

  public getCurrentSearch(listName: fromTypes.VendorListName): Observable<string> {
    return this.store.select(fromSelectors.getCurrentSearch, { listName });
  }

  public onListInitialized(
    listName: fromTypes.VendorListName,
    serialized: fromTypes.SerializedVendorListSnapshot,
  ): void {
    const snapshot = fromTypes.utils.serializedToSnapshot(serialized);
    this.store.dispatch(fromActions.listInitialized({ listName, snapshot }));
  }

  public onListItemInitialized(vendorId: string): void {
    this.store.dispatch(fromActions.vendorListItemInitialized({ vendorId }));
  }

  public onListItemDestroyed(vendorId: string): void {
    this.store.dispatch(fromActions.vendorListItemDestroyed({ vendorId }));
  }

  public onSearchChanged(listName: fromTypes.VendorListName, query: string): void {
    this.store.dispatch(fromActions.searchChanged({ listName, searchTerm: query }));
  }

  public onSelectedVendorId(listName: fromTypes.VendorListName, vendorId: string): void {
    this.store.dispatch(fromActions.vendorItemSelected({ listName, vendorId }));
  }

  public onPageChanged(listName: fromTypes.VendorListName, page: number): void {
    this.store.dispatch(fromActions.pageChanged({ listName, page }));
  }

  public onPageSizeChanged(listName: fromTypes.VendorListName, pageSize: number): void {
    this.store.dispatch(fromActions.pageSizeChanged({ listName, pageSize }));
  }
}
