import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DistrictApiService } from '../../services/districts-api.service';
import * as fromActions from '../actions/add-edit-calendar.actions';

@Injectable()
export class AddEditCalendarEffects {
  constructor(
    private actions$: Actions,
    private apiService: DistrictApiService,
  ) {}

  public updateCalendarRequested = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateCalendarRequested),
      switchMap(({ districtId, previousCalendar, updatedCalendar }) => {
        return this.apiService.updateDistrictCalendar(districtId, updatedCalendar, previousCalendar).pipe(
          map((res) => {
            return fromActions.updateCalendarSuccess({ districtId, calendar: res });
          }),
          catchError((err) => {
            console.log(err);
            return of(
              fromActions.updateCalendarFailed({
                districtId,
                error: { text: 'Failed to update calendar for district' },
              }),
            );
          }),
        );
      }),
    ),
  );
}
