import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { SelectOption } from '../index';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, fromEvent, Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, startWith } from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { iconPaths, isMatchedWithSearchTerm, isMatchedWithSearchTermSubsequence } from '@rootTypes/utils';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';

const defaultOptionGroupName = 'Other';

interface OptionGroup {
  label: string;
  options: SelectOption[];
}

export type AutocompleteOptionSearchFn = (options: SelectOption[], searchText: string) => SelectOption[];
export type AutocompleteOptionDisplayFn = (option?: SelectOption) => string;

export const autocompleteDefaultOptionSearchFn: AutocompleteOptionSearchFn = (
  options: SelectOption[],
  searchText: string,
): SelectOption[] => {
  if (!(searchText && searchText.length)) {
    return options.slice();
  }
  const preparedSearchText = searchText.toLowerCase();
  return options.filter((option) => {
    return isMatchedWithSearchTerm(option.displayLabel.toLowerCase(), preparedSearchText);
  });
};

export const autocompleteSubsequenceOptionSearchFn: AutocompleteOptionSearchFn = (
  options: SelectOption[],
  searchText: string,
): SelectOption[] => {
  if (!(searchText && searchText.length)) {
    return options.slice();
  }
  const preparedSearchText = searchText.toLowerCase();
  return options.filter((option) => {
    return isMatchedWithSearchTermSubsequence(option.displayLabel.toLowerCase(), preparedSearchText);
  });
};

export const autocompleteDefaultOptionDisplayFn: AutocompleteOptionDisplayFn = (option?: SelectOption) =>
  option?.displayLabel;

const defaultOptionGroupingFn = (options: SelectOption[], defaultGroupNameParam: string): OptionGroup[] => {
  const groupMap = options.reduce((prev, curr) => {
    const groupName = curr.groupName || defaultGroupNameParam;
    const currentMap = { ...prev };
    if (!currentMap[groupName]) {
      currentMap[groupName] = [];
    }
    currentMap[groupName].push(curr);
    return { ...currentMap };
  }, {});
  const groupNameKeys = Object.keys(groupMap);
  groupNameKeys.sort();
  return groupNameKeys.map((groupName) => {
    return {
      label: groupName,
      options: groupMap[groupName],
    };
  });
};

@Component({
  selector: 'wp-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public label: string;
  @Input() public control: UntypedFormControl; // Should contain Option.value if any
  @Input() public controlStateChange?: Observable<void>;
  @Input() public options: SelectOption[];
  @Input() public optionSearchFn: AutocompleteOptionSearchFn = autocompleteDefaultOptionSearchFn;
  @Input() public optionGroupingFn = defaultOptionGroupingFn;
  @Input() public defaultOptionGroupName = defaultOptionGroupName;
  @Input() public requiredErrorText = 'Required field';
  @Input() public optionDisplayFn: AutocompleteOptionDisplayFn = autocompleteDefaultOptionDisplayFn;
  /**
   * Parent element to listen to close the dropdown.
   * Note, this is required when using the component inside a modal,
   * in other cases the dropdown is closing normally.
   */
  @Input() public parentSelectorToCloseByClick?: string;
  @Input() public tabIndex = '0';

  @Output() public valueChangedByUser = new EventEmitter<any>();

  public displayControl: UntypedFormControl;
  public allOptions$: BehaviorSubject<SelectOption[]> = new BehaviorSubject([]);
  public isOptionGroups$: Observable<boolean>;
  public searchText$: Observable<string>;
  public searchedOptions$: Observable<SelectOption[]>;
  public searchedOptionGroups$: Observable<OptionGroup[]>;

  @ViewChild('autocompleteInput')
  private autocompleteInput: ElementRef<HTMLInputElement>;
  @ViewChild('autocompleteInput', { read: MatAutocompleteTrigger })
  private autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('autocomplete')
  private autocomplete: MatAutocomplete;

  private selectedOption?: SelectOption;
  private sub = new Subscription();

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
  ) {
    this.iconRegistry.addSvgIcon(
      'wp-arrow-down',
      sanitizer.bypassSecurityTrustResourceUrl(window.origin + iconPaths.ARROW_DOWN_GREY),
    );
  }

  public ngOnInit(): void {
    this.allOptions$.next(this.options || []);
    this.setSelectedOptionFromControl();
    this.initDisplayControl();

    this.searchText$ = this.displayControl.valueChanges.pipe(startWith(this.displayControl.value));
    // if at least one option has a group name
    this.isOptionGroups$ = this.allOptions$.pipe(
      map((options) => {
        return options.some((option) => !!option.groupName);
      }),
    );
    this.searchedOptions$ = combineLatest([this.allOptions$, this.searchText$]).pipe(
      map(([allOptions, searchText]) => this.optionSearchFn(allOptions, searchText)),
    );
    this.searchedOptionGroups$ = this.searchedOptions$.pipe(
      map((options) => this.optionGroupingFn(options, this.defaultOptionGroupName)),
    );

    if (this.parentSelectorToCloseByClick) {
      const onClickOutside = fromEvent(document.querySelector(this.parentSelectorToCloseByClick), 'click').subscribe(
        () => {
          if (this.autocomplete && this.autocomplete.isOpen) {
            this.autocompleteTrigger.closePanel();
          }
        },
      );
      this.sub.add(onClickOutside);
    }

    if (this.controlStateChange) {
      this.sub.add(
        this.controlStateChange.subscribe(() => {
          if (this.control.invalid && this.control.hasError('required')) {
            this.displayControl.setErrors({ required: true });
            this.displayControl.markAsTouched();
            this.cdRef.detectChanges();
          }
        }),
      );
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && !changes.options.isFirstChange()) {
      this.allOptions$.next(changes.options.currentValue || []);
      this.setSelectedOptionFromControl();
    }
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const optionLabel = event.option.value;
    if (optionLabel !== this.selectedOption?.displayLabel) {
      this.selectedOption = this.options.find((opt) => this.optionDisplayFn(opt) === optionLabel);
      this.control.setValue(this.selectedOption.value);
      this.control.markAsDirty();
      this.valueChangedByUser.emit(this.selectedOption.value);
    }
  }

  public onClearInput(event: MouseEvent): void {
    event.stopPropagation();
    this.selectedOption = undefined;
    this.displayControl.setValue(undefined);
    this.control.setValue(undefined);
    this.control.markAsDirty();
    this.autocompleteInput.nativeElement.focus();
  }

  private initDisplayControl(): void {
    this.displayControl = new UntypedFormControl(this.optionDisplayFn(this.selectedOption));
    const onControlValueChangeOutsideSub = this.control.valueChanges
      .pipe(filter((value) => value !== this.selectedOption?.value))
      .subscribe(() => {
        this.setSelectedOptionFromControl();
        this.displayControl.setValue(this.selectedOption?.displayLabel);
      });
    this.sub.add(onControlValueChangeOutsideSub);

    const statusChangeSub = this.control.statusChanges.subscribe(() => {
      if (this.control.hasError('required') && this.control.dirty) {
        this.displayControl.setErrors({ required: true });
        this.cdRef.detectChanges();
      } else {
        this.displayControl.setErrors(null);
      }
    });
    this.sub.add(statusChangeSub);

    const onInputClearedByTypingSub = this.displayControl.valueChanges.subscribe((value) => {
      if (value === '' && this.selectedOption) {
        this.selectedOption = undefined;
        this.control.setValue(undefined);
        this.control.markAsDirty();
      }
    });
    this.sub.add(onInputClearedByTypingSub);

    if (this.control.disabled) {
      this.displayControl.disable({ emitEvent: false });
    }
    this.control.registerOnDisabledChange((disabled): void => {
      if (disabled) {
        this.displayControl.disable({ emitEvent: false });
      } else {
        this.displayControl.enable({ emitEvent: false });
      }
      this.cdRef.detectChanges();
    });
  }

  private setSelectedOptionFromControl(): void {
    const value = this.control.value;
    this.selectedOption = this.options.find((opt) => opt.value === value);
  }
}
