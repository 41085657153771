import { PortalRoute } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { workQueuePath } from './work-queue-path';
import { NavigationRequest } from '../../types/navigation-request';
import { getRoutingRequest } from '@rootTypes/utils/router/get-routing-request';
import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';
import { RouteGroupRequestListParams } from '../../../features/work-queue/store/actions/route-group-requests.actions';
import { getInitialRouteGroupRequestsState } from '../../../features/work-queue/store/reducers/route-group-requests.reducer';
import { serializeBaseParamsToUrl } from '@rootTypes/utils/list-store-utils/serialize-base-params-to-url';
import { deserializeBaseParamsFromUrl } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';

export type WorkQueueRouteGroupRequestsRouteData = Partial<RouteGroupRequestListParams>;

const statePath = 'route-group-requests';

export const workQueueRouteGroupRequestsRoute: PortalRoute<WorkQueueRouteGroupRequestsRouteData> = {
  path: statePath,
  isOnRoute: (state: RouterStateUrl) => {
    return state && state.url && state.url.startsWith(`/${workQueuePath}/${statePath}`);
  },
  parse: (state: RouterStateUrl): WorkQueueRouteGroupRequestsRouteData => {
    const query = state?.queryParams || {};
    const deserialized = deserializeBaseParamsFromUrl<RouteGroupRequestListParams>(query);
    if (query.status) {
      deserialized.status = query.status;
    }
    return deserialized;
  },
  request: (data: WorkQueueRouteGroupRequestsRouteData = {}, queryParamStrategy, replaceUrl): NavigationRequest => {
    const path = ['/', workQueuePath, statePath];
    const defaultState = getInitialRouteGroupRequestsState().params;
    let serialized = serializeBaseParamsToUrl(data, defaultState);
    if (data.status !== defaultState.status) {
      serialized = { ...serialized, status: data.status };
    }
    return getRoutingRequest(path, serialized, queryParamStrategy, replaceUrl, {
      status: undefined,
      page: undefined,
      pageSize: undefined,
      filters: undefined,
    });
  },
  getHref(data?: WorkQueueRouteGroupRequestsRouteData): string {
    return `/${workQueuePath}/${statePath}`;
  },
};

@Injectable({
  providedIn: 'root',
})
export class WorkQueueRouteGroupRequestRouterService extends PortalRouterService<WorkQueueRouteGroupRequestsRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route = workQueueRouteGroupRequestsRoute;
}
