import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import * as fromActions from '../actions/student-change-request-root.actions';
import { StudentChangeRequestApiService } from '../../services';
import { studentChangeRequestReviewReloadPendingRequestsRequested } from '../actions';

import { WpError } from '@rootTypes';

@Injectable()
export class StudentChangeRequestRootEffects {
  constructor(
    private actions$: Actions,
    private store$: Store,
    private api: StudentChangeRequestApiService,
  ) {}

  public loadRequestsByStudent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.studentChangeRequestRootLoadPendingRequestsRequested,
        studentChangeRequestReviewReloadPendingRequestsRequested,
      ),
      mergeMap(({ studentId }) => {
        return this.api.getPendingStudentChangeRequestsByStudent({ studentId }).pipe(
          map((response) => {
            return fromActions.studentChangeRequestRootLoadPendingRequestsSuccess({ response });
          }),
          catchError((originalError) => {
            return of(
              fromActions.studentChangeRequestRootLoadPendingRequestsFailed({
                error: {
                  text: 'Failed to load pending change requests by student',
                  originalError,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  public loadStudentRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadStudentRequested),
      switchMap(({ studentId }) => {
        return this.api.getStudent$(studentId).pipe(
          map((resp) => {
            return fromActions.loadStudentSuccess({ studentId, student: resp });
          }),
          catchError((originalError) => {
            console.log('Error loading student', originalError);
            const error: WpError = {
              originalError,
              text: 'Failed to load student',
              retryFn: () => this.store$.dispatch(fromActions.loadStudentRequested({ studentId })),
            };
            return of(fromActions.loadStudentFailed({ studentId, error }));
          }),
        );
      }),
    ),
  );
}
