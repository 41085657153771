import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  ApiService,
  GetStudentChangeRequestConfigRequest,
  GetStudentChangeRequestConfigResponse,
  GetPendingStudentChangeRequestsByStudentRequest,
  GetPendingStudentChangeRequestsByStudentResponse,
  GetStudentChangeRequestSummaryRequest,
  GetStudentChangeRequestSummaryResponse,
  ApplyStudentChangeRequestRequest,
  ApplyStudentChangeRequestResponse,
  GetProcessedStudentChangeRequestResponse,
} from '../dependencies';
import { map } from 'rxjs/operators';
import { GetStudentV2Response } from '../../../api/endpoints/get-student-v2';

@Injectable({
  providedIn: 'root',
})
export class StudentChangeRequestApiService {
  constructor(private api: ApiService) {}

  public getStudent$(studentId: string): Observable<GetStudentV2Response> {
    return this.api.getStudentV2({ studentId });
  }

  public getStudentChangeRequestConfig(
    request: GetStudentChangeRequestConfigRequest,
  ): Observable<GetStudentChangeRequestConfigResponse> {
    return this.api.getStudentChangeRequestConfig(request);
  }

  public getPendingStudentChangeRequestsByStudent(
    request: GetPendingStudentChangeRequestsByStudentRequest,
  ): Observable<GetPendingStudentChangeRequestsByStudentResponse> {
    return this.api.getPendingStudentChangeRequestsByStudent(request);
  }

  public getStudentChangeRequestSummary(
    request: GetStudentChangeRequestSummaryRequest,
  ): Observable<GetStudentChangeRequestSummaryResponse> {
    return this.api.getStudentChangeRequestSummary(request);
  }

  public applyStudentChangeRequest(
    request: ApplyStudentChangeRequestRequest,
  ): Observable<ApplyStudentChangeRequestResponse> {
    return this.api.applyStudentChangeRequest(request).pipe(
      map((res) => {
        if (typeof res === 'object') {
          return res;
        }
        return {
          isValid: true,
          errors: [],
        };
      }),
    );
  }

  public getProcessedStudentChangeRequest(
    changeRequestId: string,
  ): Observable<GetProcessedStudentChangeRequestResponse> {
    return this.api.getProcessedStudentChangeRequest({ changeRequestId });
  }
}
