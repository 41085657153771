import { MapLocation } from '../../../types';

export const getLocationsHash = (locs: MapLocation[]): string => {
  if (!locs) {
    return null;
  }
  return locs.reduce((prev, curr) => {
    return prev + `${curr.lat}${curr.lng}`;
  }, '');
};
