import { RouteRepeat } from '@apiEntities/routes/route-repeat';
import { RouteRepeatMap } from '@rootTypes/entities/route/route-repeat-map';
import { Weekday } from '@rootTypes/entities/common/weekday';

export const dbToRouteRepeatMap = (repeat?: RouteRepeat): RouteRepeatMap | undefined => {
  if (!repeat) {
    return undefined;
  }
  const result = {} as RouteRepeatMap;
  Object.keys(repeat).forEach((weekday: Weekday) => {
    result[weekday] = repeat[weekday].isSelected;
  });
  return result;
};
