import { GetUnassignedExplorerStudentsV2ByDistrictProgramsResponse } from './index';
import { ExplorerStudent } from '@apiEntities/route-explorer/explorer-student';
import { CampusId } from '@apiEntities/routes';
import { GetStudentV2Response } from '../get-student-v2';
import { ApiStudentAddress } from '@apiEntities';

export const transformGetUnassignedExplorerStudentsV2ByDistrictProgramsToLegacy = (
  source: GetUnassignedExplorerStudentsV2ByDistrictProgramsResponse,
): GetUnassignedExplorerStudentsV2ByDistrictProgramsResponse => {
  const result: GetUnassignedExplorerStudentsV2ByDistrictProgramsResponse = {
    byDistrictProgramId: {},
  };

  Object.keys(source.byDistrictProgramId).forEach((districtProgramId) => {
    const allStudentsLegacy: {
      [studentId: string]: {
        student: GetStudentV2Response;
        campusId: CampusId;
      };
    } = {};

    const byDistrictProgram = source.byDistrictProgramId[districtProgramId];
    Object.keys(byDistrictProgram.allStudents).forEach((studentId) => {
      const explorerStudent = byDistrictProgram.allStudents[studentId] as any as ExplorerStudent;
      const addresses = explorerStudent.addresses.map<ApiStudentAddress>((explorerStudentAddress) => {
        return {
          address: {
            geometry: {
              location: explorerStudentAddress.location,
            },
            // formatted_address: '',
            // name: '',
          },
          name: explorerStudent.name,
          districtProgramId: explorerStudent.districtProgramId,
          isUsedForRoutes: true,
          type: explorerStudentAddress.type, // TODO: Roman, remove the redundant one
          locationType: explorerStudentAddress.type, // TODO: Roman, remove the redundant one
          locationSchedule: explorerStudentAddress.schedule,
        } as ApiStudentAddress;
      });
      const studentLegacy: GetStudentV2Response = {
        id: explorerStudent.studentId,
        version: explorerStudent.version,
        profile: {
          firstName: explorerStudent.name,
          lastName: '',
          districtId: null,
          status: null,
          specialNeeds: explorerStudent.specialNeeds,
          equipmentNeeds: explorerStudent.equipmentNeeds,
        },
        campuses: [],
        addresses,
        guardians: [],
      };
      if (explorerStudent.campus) {
        studentLegacy.campuses.push({
          campusLocationId: '',
          districtId: '',
          districtName: '',
          externalStudentId: '',
          isDefaultProgram: true,
          schoolYear: '',
          studentToCampusId: '',
          campusName: explorerStudent.campus.name,
          campusId: explorerStudent.campus.campusId,
          grade: explorerStudent.campus.grade,
          districtProgramId: explorerStudent.districtProgramId,
        });
      }

      allStudentsLegacy[studentId] = {
        student: studentLegacy,
        campusId: explorerStudent.campus?.campusId,
      };
    });

    result.byDistrictProgramId[districtProgramId] = {
      byDirection: byDistrictProgram.byDirection,
      allStudents: allStudentsLegacy,
    };
  });

  return result;
};
