import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-icon-plus-circle',
  templateUrl: './icon-plus-circle.component.html',
  styleUrls: ['./icon-plus-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconPlusCircleComponent implements OnInit {
  @Input() public grey: boolean;
  @Input() public darkGrey: boolean;
  constructor() {}

  ngOnInit(): void {}
}
