import { District } from '../../../types/entities/districts';
import { UpdateDistrictRequest } from './request';
import { ContactPerson } from '@rootTypes';
import { Contact } from '../common';

export const getUpdateDistrictRequest = (prev: District, curr: District): UpdateDistrictRequest => {
  const request = { id: prev.id, ...getUpdateDistrictRequestFromDiffs(prev, curr) };
  return request;
};

function getUpdateDistrictRequestFromDiffs(prev: District, curr: District): UpdateDistrictRequest {
  const result = {} as UpdateDistrictRequest;
  if (prev.name !== curr.name) {
    result.name = curr.name;
  }
  // We've encountered districts without addresses, so need to check if it exists
  if (JSON.stringify(prev?.address?.geometry) !== JSON.stringify(curr?.address?.geometry)) {
    result.address = curr.address;
  }
  if (prev.website !== curr.website) {
    result.websiteUrl = curr.website;
  }
  if (prev.photo !== curr.photo) {
    result.logoPath = curr.photo;
  }
  if (normalize(prev.contactPerson?.name) !== normalize(curr.contactPerson?.name)) {
    result.contact = result.contact || ({} as Contact);
    result.contact.name = curr.contactPerson.name || null;
  }
  if (normalize(prev.contactPerson?.phone) !== normalize(curr.contactPerson?.phone)) {
    result.contact = result.contact || ({} as Contact);
    result.contact.phoneNumber = curr.contactPerson.phone || null;
  }
  if (normalize(prev.contactPerson?.position) !== normalize(curr.contactPerson?.position)) {
    result.contact = result.contact || ({} as Contact);
    result.contact.position = curr.contactPerson.position || null;
  }
  if (isGradesChanged(prev, curr)) {
    result.grades = {} as UpdateDistrictRequest['grades'];
    if (prev.grades.selectedStandardGradeIds.join('') !== curr.grades.selectedStandardGradeIds.join('')) {
      result.grades.selectedStandardGradeIds = curr.grades.selectedStandardGradeIds;
    }
    const customGradeChanges = {} as UpdateDistrictRequest['grades']['customGradeChanges'];
    const addedGradeValues = curr.grades.customGrades
      .filter((g) => !prev.grades.customGrades.some((pg) => pg.gradeId === g.gradeId))
      .map((g) => g.value);
    if (addedGradeValues.length) {
      customGradeChanges.addedGradeValues = addedGradeValues;
    }
    const updatedGrades = curr.grades.customGrades
      .filter((g) => {
        const prevGrade = prev.grades.customGrades.find((pg) => pg.gradeId === g.gradeId);
        return prevGrade && prevGrade.value !== g.value;
      })
      .map((g) => ({ id: g.gradeId, value: g.value }));
    if (updatedGrades.length) {
      customGradeChanges.updatedGrades = updatedGrades;
    }
    if (Object.keys(customGradeChanges).length) {
      result.grades.customGradeChanges = customGradeChanges;
    }
  }
  return result;
}

function normalize(val): string | null {
  return val || null;
}

function isAnyContactData(contact: ContactPerson): boolean {
  return !!contact && !!(contact.name || contact.position || contact.phone);
}

function contactHash(contact: ContactPerson): string {
  return `${contact.name}${contact.position}${contact.phone}`;
}

function isGradesChanged(prev: District, curr: District): boolean {
  return JSON.stringify(prev?.grades || {}) !== JSON.stringify(curr?.grades || {});
}
