import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SideBarTabId } from '../../types/entities';

@Injectable()
export class SideBarService {
  public active$: Observable<string>;
  private activeSub$: BehaviorSubject<string>;
  constructor() {
    this.activeSub$ = new BehaviorSubject<SideBarTabId>('rides');
    this.active$ = this.activeSub$.pipe(filter((id) => !!id));
  }

  public set active(id: string) {
    this.activeSub$.next(id);
  }
}
