import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';

import { ImageApiService } from 'src/app/api';
import { DownloadImageSize, downloadImageSizePerson, downloadImageSizeVehicle } from '@rootTypes';
import { PlaceholderType } from '../types';

@Component({
  selector: 'wp-display-photo',
  templateUrl: './display-photo.component.html',
  styleUrls: ['./display-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayPhotoComponent implements OnChanges {
  @Input() public width = '100px';
  @Input() public height = '100px';
  @Input() public isRound = false;
  @Input() public placeholderWidth?: string;
  @Input() public placeholderType: PlaceholderType = PlaceholderType.PERSON;
  @Input() public placeholderBackground: 'white' | 'grey' = 'grey';
  @Input() public placeholderBorderStyle?: string;
  /**
   * Displays abbreviation inside a circle as a placeholder.
   * If set, placeholderType is ignored
   */
  @Input() public placeholderAbbreviation: string;
  @Input() public placeholderAbbreviationFontSize: string;

  /**
   * Downloads the image from the path
   */
  @Input() public imagePath: string | null = null;
  @Input() public downloadImageSize?: DownloadImageSize;

  /**
   * If passed imagePath is ignored
   */
  @Input() public imageBase64 = '';
  @Input() public showFullImageWithinDimensions = false;

  public loadedImageData?: string;

  constructor(
    private imageApiService: ImageApiService,
    private cdRef: ChangeDetectorRef,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.imagePath && !this.imageBase64) {
      this.loadImageData();
    }
  }

  private async loadImageData(): Promise<void> {
    if (!this.imagePath) {
      this.loadedImageData = undefined;
      return;
    }
    try {
      this.loadedImageData = await this.imageApiService.getImageBase64(this.imagePath, this.getDownloadImageSize());
    } catch (err) {
      // We silence this error since Browser already logs 500 XHR error in the console
      this.loadedImageData = undefined;
    } finally {
      this.cdRef.detectChanges();
    }
  }

  private getDownloadImageSize(): DownloadImageSize | undefined {
    if (this.downloadImageSize) {
      if (this.downloadImageSize.width && this.downloadImageSize.height) {
        return this.downloadImageSize;
      } else {
        console.warn(
          'DisplayPhotoComponent: invalid value was passed for [downloadImageSize] param, using the default one',
        );
      }
    }

    switch (this.placeholderType) {
      case PlaceholderType.PERSON:
        return downloadImageSizePerson;
      case PlaceholderType.CAR:
        return downloadImageSizeVehicle;
      default:
        return undefined;
    }
  }
}
