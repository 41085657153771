<!--matPrefix and matSuffix are for consistent padding-->
<mat-form-field class="wp-select" [hideRequiredMarker]="true">
  <mat-label>{{ label }}</mat-label>

  <input matInput [formControl]="control" [matAutocomplete]="autocomplete" (input)="setAutocompleteOptions($event)" />
  <wp-icon-arrow-down matSuffix />

  <mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="displayWith" [requireSelection]="true">
    <mat-option *ngFor="let option of autocompleteOptions" [value]="option.value">{{ option.displayLabel }}</mat-option>
  </mat-autocomplete>

  <mat-error *ngIf="control.hasError('required')">Required field</mat-error>
</mat-form-field>
