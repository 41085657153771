import { Injectable } from '@angular/core';

import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { workQueuePath } from './work-queue-path';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';
import { deserializeBaseParamsFromUrl } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';
import { serializeBaseParamsToUrl } from '@rootTypes/utils/list-store-utils/serialize-base-params-to-url';
import { getRoutingRequest } from '@rootTypes/utils/router/get-routing-request';
import {
  deserializeTripAssignmentStatusFilter,
  createInitialTripAssignmentListState,
  TripAssignmentListParams,
  deserializeTripAssignmentVendorResponseFilter,
} from '../../../features/work-queue/types';
import { deserializeLocationFilter } from '../../../features/trips/utils/get-trip-list-location-filter';

export type WorkQueueTripAssignmentRouteData = Partial<TripAssignmentListParams>;

const statePath = 'trip-assignment';
const queryPrefix = 'ta';

export const workQueueTripAssignmentRoute: PortalRoute<WorkQueueTripAssignmentRouteData> = {
  path: statePath,

  isOnRoute: (state: RouterStateUrl) => {
    return state && state.url && state.url.startsWith(`/${workQueuePath}/${statePath}`);
  },

  parse: (state: RouterStateUrl): WorkQueueTripAssignmentRouteData => {
    const query = state?.queryParams || {};
    const data = deserializeBaseParamsFromUrl<TripAssignmentListParams>(query, queryPrefix, [
      deserializeTripAssignmentStatusFilter,
      deserializeLocationFilter,
      deserializeTripAssignmentVendorResponseFilter,
    ]);
    if (!data.filters) {
      data.filters = [];
    }
    return data;
  },

  request: (
    data?: WorkQueueTripAssignmentRouteData,
    queryParamStrategy?: QueryParamsHandlingStrategy,
    replaceUrl?: boolean,
  ): NavigationRequest => {
    const path = ['/', workQueuePath, statePath];
    const query = data
      ? serializeBaseParamsToUrl(data, createInitialTripAssignmentListState().params, queryPrefix)
      : {};
    return getRoutingRequest(path, query, queryParamStrategy, replaceUrl);
  },

  getHref(data?: WorkQueueTripAssignmentRouteData): string {
    return `/${workQueuePath}/${statePath}`;
  },
};

@Injectable({
  providedIn: 'root',
})
export class WorkQueueTripAssignmentRouterService extends PortalRouterService<WorkQueueTripAssignmentRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route = workQueueTripAssignmentRoute;
}
