import { EmployeeListSnapshot, SerializedEmployeeListSnapshot } from '../entities';
import * as ui from '../ui';

export const getSerializedFromSnapshot = (source: EmployeeListSnapshot): SerializedEmployeeListSnapshot => {
  const { page, pageSize, search, status } = source;
  const pageStr = page === ui.DEFAULT_EMPLOYEE_LIST_PAGE_NUMBER ? undefined : '' + (page + 1);
  const pageSizeStr = pageSize === ui.DEFAULT_EMPLOYEE_LIST_PAGE_SIZE ? undefined : '' + pageSize;
  const searchStr = search && search.length ? search : undefined;
  const statusStr: string = status === ui.DEFAULT_EMPLOYEE_LIST_STATUS ? undefined : '' + status;
  const res: SerializedEmployeeListSnapshot = {
    page: pageStr,
    pageSize: pageSizeStr,
    search: searchStr,
    status: statusStr,
  };
  return res;
};
