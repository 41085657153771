import { CampusListStateSnapshot } from '../campus-list-state-snapshot';
import { CampusListSerializedSnapshot } from '../campus-list-serialized-snapshot';
import * as ui from '../ui';
import { getCampusFiltersFromSerialized } from './serialize-campus-filters';
import { EntityStatus } from '../../../../types';

export const getCampusListSnapshotFromSerialized = (
  serialized: CampusListSerializedSnapshot,
): CampusListStateSnapshot => {
  const { pageSize, page, query, filters, status } = serialized;
  const pageSizeValue = pageSize ? parseInt(pageSize, 10) : ui.DEFAULT_PAGE_SIZE;
  const currentPageValue = page ? parseInt(page, 10) - 1 : ui.DEFAULT_PAGE;
  const filtersValue = getCampusFiltersFromSerialized(filters);
  const statusValue = status || ui.DEFAULT_STATUS;
  return {
    pageSize: pageSizeValue,
    currentPage: currentPageValue,
    searchQuery: query,
    filters: filtersValue,
    listStatus: statusValue as EntityStatus,
  };
};
