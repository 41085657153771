import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SmartSelect, SmartSelectEntitySearchParams } from '../../models';
import { EntityStatus, PortalEntity } from '@rootTypes';
import { CrossEntitySearchApiService } from '../../../cross-entity-search/cross-entity-search.api.service';

@Component({
  selector: 'wp-smart-select',
  templateUrl: './smart-select.component.html',
  styleUrls: ['./smart-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartSelectComponent implements OnInit, OnDestroy {
  @Input() public id: string;
  @Input() public model: SmartSelect;
  @Input() public entitySearchParams?: SmartSelectEntitySearchParams;
  @Input() public prefixTemplate: any;
  @Input() public suffixTemplate: any;
  @Input() public entityFilterStatus = EntityStatus.ANY;
  @Input() public tabIndex = '0';
  @Output() public inputEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public clearClicked: EventEmitter<void> = new EventEmitter<void>();

  public fixedSelectCompareFn: (optionValue: any, selectedValue: any) => boolean = (optionValue, selectedValue) => {
    return optionValue?.id === selectedValue?.id;
  };

  constructor(private entityApiService: CrossEntitySearchApiService) {}

  ngOnInit(): void {
    this.model.setEntityNameGetter(this.entityApiService.getDisplayEntityName.bind(this.entityApiService));
    this.entitySearchParams = this.model.entitySearchParams;
  }

  public onInputEvent(): void {
    this.model.onInputEvent();
  }

  public onEntityOptionSelect(entity: PortalEntity): void {
    this.model.onEntitySelect(entity);
    this.inputEvent.emit();
  }

  ngOnDestroy(): void {
    if (this.model) {
      this.model.dispose();
    }
  }
}
