import { PortalEntity } from '@rootTypes';

export interface VehicleListFilter {
  id: string;
  type: VehicleListFilterType;
  payload: any;
}

export interface VehicleListEntityFilter {
  id: string;
  type: VehicleListFilterType.ENTITY;
  payload: PortalEntity;
}

export interface VehicleListMultiAccountFilter {
  id: string;
  type: VehicleListFilterType.MULTI_ACCOUNT;
  payload: PortalEntity & { operatorIds: string[] };
}

export enum VehicleListFilterType {
  ENTITY = 'entity',
  MULTI_ACCOUNT = 'multi-account',
}
