import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, HostListener } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ResetPasswordSetupFacade } from '../../store';

@Component({
  selector: 'wp-reset-password-setup',
  templateUrl: './reset-password-setup.component.html',
  styleUrls: ['./reset-password-setup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ResetPasswordSetupFacade],
})
export class ResetPasswordSetupComponent implements OnInit, OnDestroy {
  public newPassword: UntypedFormControl;
  public confirmNewPassword: UntypedFormControl;
  public confirmNewPasswordErrorKey: string;
  public headerText$: Observable<string>;
  public instructions$: Observable<string>;
  public submitBtnText$: Observable<string>;
  public isLoading$: Observable<boolean>;
  public isSubmitDisabled$: Observable<boolean>;
  public errorMsg$: Observable<string>;

  constructor(private facade: ResetPasswordSetupFacade) {}

  @HostListener('window:keydown', ['$event'])
  public onEnterKey(event: KeyboardEvent): void {
    if (event.code === 'Enter') {
      event.preventDefault(); // prevent page reloading
      combineLatest([this.isLoading$, this.isSubmitDisabled$])
        .pipe(take(1))
        .subscribe(([isLoading, isDisabled]) => {
          if (!isLoading && !isDisabled) {
            this.setNewPassword();
          }
        });
    }
  }

  public ngOnInit(): void {
    this.facade.init();
    this.newPassword = this.facade.newPassword;
    this.confirmNewPassword = this.facade.confirmNewPassword;
    this.confirmNewPasswordErrorKey = this.facade.confirmNewPasswordControlErrorKey;
    this.headerText$ = this.facade.getHeaderText$();
    this.instructions$ = this.facade.getInstructions$();
    this.submitBtnText$ = this.facade.getSubmitBtnText$();
    this.isLoading$ = this.facade.getIsLoading$();
    this.isSubmitDisabled$ = this.facade.getIsSubmitDisabled$();
    this.errorMsg$ = this.facade.getErrorMsg$();
  }

  public ngOnDestroy(): void {
    this.facade.unsubscribe();
  }

  public setNewPassword(): void {
    this.facade.setNewPassword();
  }
}
