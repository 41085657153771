import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PortalEntity, PortalEntityType, PortalPermissionItem } from '@rootTypes';
import { getSignedInVendor } from './account.selectors';
import { MultiAccountManagementState } from '../reducers';
import { isPermissionGivenForAccount } from './portal-permissions.selectors';

export const multiAccountManagementFeatureKey = 'multiAccountManagement';

export const selectMultiAccountManagementEntityType = createSelector(getSignedInVendor, (vendor) => {
  return vendor ? PortalEntityType.YARD : null;
});

export const isMultiAccountManagement = createSelector(
  selectMultiAccountManagementEntityType,
  isPermissionGivenForAccount(PortalPermissionItem.INTER_YARD_MANAGEMENT_EDIT),
  (entityType, isPermission) => !!entityType && isPermission,
);

const selectMultiAccountManagementState = createFeatureSelector<MultiAccountManagementState>(
  multiAccountManagementFeatureKey,
);

const selectMultiAccountManagementOptionState = createSelector(
  selectMultiAccountManagementState,
  (state) => state.options,
);

export const selectMultiAccountManagementOptions = createSelector(
  selectMultiAccountManagementOptionState,
  (state) => state.entity || [],
);

export const selectMultiAccountManagementDefaultEntity = createSelector(
  isMultiAccountManagement,
  getSignedInVendor,
  selectMultiAccountManagementOptions,
  (isMultiAccount, signedInVendor, options): PortalEntity & { operatorIds: string[] } => {
    if (!isMultiAccount) {
      return null;
    }
    if (!options?.length) {
      return null;
    }
    if (signedInVendor) {
      const option = options.find((o) => o.value === signedInVendor.yardId);
      if (!option) {
        return null;
      }
      return {
        entityId: signedInVendor.yardId,
        type: PortalEntityType.YARD,
        operatorIds: option.meta.operatorIds || [],
      };
    }
    return null;
  },
);

export const selectMultiAccountManagementOptionById = (accountId?: string) =>
  createSelector(
    isMultiAccountManagement,
    getSignedInVendor,
    selectMultiAccountManagementOptions,
    (isMultiAccount, signedInVendor, options) => {
      if (!accountId || !isMultiAccount || !signedInVendor || !options?.length) {
        return null;
      }
      const option = options.find((opt) => opt.value === accountId);
      return option ? option : null;
    },
  );
