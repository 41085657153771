import { PortalEntityType } from '../../../../types';
import { encodeDataComponents } from '../../../../types/utils';
import { StudentListFilter, StudentListFilterType } from '../student-list-entity-filter';

export function getEntityFilter(entityType: PortalEntityType, entityId: string): StudentListFilter {
  return {
    id: encodeDataComponents([StudentListFilterType.ENTITY, entityType, entityId]),
    type: StudentListFilterType.ENTITY,
    payload: {
      entityId,
      type: entityType,
    },
  };
}
