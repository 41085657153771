import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'wp-btn-label-for-input',
  template: `
    <label
      [for]="labelFor"
      class="wp-btn-secondary"
      [ngClass]="{ disabled: disabled, slim: slim }"
      [ngStyle]="btnSize()"
      tabindex="0"
    >
      <div class="btn-content-wrap">
        <ng-content select="wp-btn-icon"> </ng-content>
        <ng-content> </ng-content>
      </div>
    </label>
  `,
  styleUrl: './btn-label-for-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnLabelForInputComponent {
  @Input() public height!: string;
  @Input() public width!: string;
  @Input() public labelFor!: string;
  @Input() public fontSize = '14px';
  @Input() public disabled = false;
  @Input() public slim = false;

  public btnSize(): any {
    return {
      width: this.width,
      height: this.height,
      padding: this.width === 'auto' ? '0 24px' : '0px',
      fontSize: this.fontSize,
    };
  }
}
