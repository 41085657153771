import { YYYYMMDDString } from '@rootTypes';

/**
 *
 * @param yyyymmdd
 * @param yyyymmddToCompare
 * @return true if yyyymmdd is before yyyymmddToCompare
 */
export const yyyymmddIsBefore = (yyyymmdd: YYYYMMDDString, yyyymmddToCompare: YYYYMMDDString): boolean => {
  return yyyymmdd < yyyymmddToCompare;
};
