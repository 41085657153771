import { FilterDeserializer } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';
import { TransportationRequestProgramFilter } from '../types/transportation-request-list';
import { createTransportationRequestProgramFilter } from './create-transportation-request-program-filter';

export const deserializeTransportationRequestProgramFilter: FilterDeserializer<TransportationRequestProgramFilter> = (
  str: string,
): TransportationRequestProgramFilter | undefined => {
  if (str && str.startsWith('program')) {
    const value = str.split(',')[1];
    return createTransportationRequestProgramFilter(value);
  }
  return undefined;
};
