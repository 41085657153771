import { Directive, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[wpUnloadGuard]',
})
export class UnloadGuardDirective implements OnChanges, OnDestroy {
  @Input() public shouldGuard: boolean;

  private beforeUnloadHandler = (event) => {
    event.preventDefault();
    // This text will appear only in IE
    event.returnValue = 'Are you sure? You changes might be lost';
  };

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shouldGuard) {
      const shouldGuard = changes.shouldGuard.currentValue;
      if (shouldGuard) {
        this.setUnloadListener();
      } else {
        this.removeUnloadListener();
      }
    }
  }

  ngOnDestroy(): void {
    this.removeUnloadListener();
  }

  private setUnloadListener(): void {
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
  }

  private removeUnloadListener(): void {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
  }
}
