import { PortalRoute } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { workQueuePath } from './work-queue-path';
import { NavigationRequest } from '../../types/navigation-request';
import { getRoutingRequest } from '@rootTypes/utils/router/get-routing-request';
import { Injectable } from '@angular/core';
import { RouterOutlets } from '@rootTypes/entities/router';
import { PortalRouterService } from '../../types/portal-router-service';

export interface WorkQueueTimesheetRequestsRouteData {
  filterEntityIds?: string;
  status?: string;
  page?: string;
  pageSize?: string;
  dateFrom?: string;
  dateTo?: string;
}

const statePath = 'timesheet-requests';

export const workQueueTimesheetRequestsRoute: PortalRoute<WorkQueueTimesheetRequestsRouteData> = {
  path: statePath,
  isOnRoute: (state: RouterStateUrl) => {
    return state && state.url && state.url.startsWith(`/${workQueuePath}/${statePath}`);
  },
  parse: (state: RouterStateUrl): WorkQueueTimesheetRequestsRouteData => {
    const query = state?.queryParams || {};
    const data: WorkQueueTimesheetRequestsRouteData = {
      filterEntityIds: query.filterEntityIds,
      page: query.page,
      pageSize: query.pageSize,
      status: query.status,
      dateFrom: query.dateFrom,
      dateTo: query.dateTo,
    };
    return data;
  },
  request: (data: WorkQueueTimesheetRequestsRouteData = {}, queryParamStrategy, replaceUrl): NavigationRequest => {
    const path = ['/', workQueuePath, statePath];
    return getRoutingRequest(path, data, queryParamStrategy, replaceUrl, {
      status: undefined,
      page: undefined,
      pageSize: undefined,
      dateFrom: undefined,
      dateTo: undefined,
    });
  },
  getHref(data?: WorkQueueTimesheetRequestsRouteData): string {
    return `/${workQueuePath}/${statePath}`;
  },
};

@Injectable({
  providedIn: 'root',
})
export class WorkQueueTimesheetRequestRouterService extends PortalRouterService<WorkQueueTimesheetRequestsRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route = workQueueTimesheetRequestsRoute;
}
