import { VehicleInspectionMechanicAssessmentStatus } from '@apiEntities';
import { MechanicAssessmentStatusQueryParam } from '../types';

export const mechanicAssessmentStatusToQueryParam = (
  status?: VehicleInspectionMechanicAssessmentStatus,
): MechanicAssessmentStatusQueryParam => {
  const map = {
    [VehicleInspectionMechanicAssessmentStatus.PENDING]: MechanicAssessmentStatusQueryParam.PENDING,
    [VehicleInspectionMechanicAssessmentStatus.FIXED]: MechanicAssessmentStatusQueryParam.FIXED,
    [VehicleInspectionMechanicAssessmentStatus.NOT_CRITICAL]: MechanicAssessmentStatusQueryParam.NOT_CRITICAL,
  };
  return map[status];
};
