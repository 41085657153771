import { createReducer, on } from '@ngrx/store';
import * as fromTypes from '../../types';
import * as fromActions from '../actions/add-edit-calendar.actions';

export interface AddEditCalendarState {
  isLoading: boolean;
  error: fromTypes.WpError;
}

export const initialAddEditCalendarState: AddEditCalendarState = {
  isLoading: false,
  error: null,
};

export const addEditCalendarReducer = createReducer(
  { ...initialAddEditCalendarState },
  on(fromActions.initializeCalendarEditor, (state, action) => {
    return {
      ...initialAddEditCalendarState,
    };
  }),
  on(fromActions.updateCalendarRequested, (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(fromActions.updateCalendarSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  }),
  on(fromActions.updateCalendarFailed, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isSuccessfullySaved: false,
      error: action.error,
    };
  }),
);
