import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'wp-common-popup-buttons',
  templateUrl: './common-popup-buttons.component.html',
  styleUrls: ['./common-popup-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonPopupButtonsComponent implements OnInit {
  @Input() public confirmButtonLabel = 'OK';
  @Input() public confirmButtonColor: 'red' | 'green' = 'green';
  @Input() public confirmButtonWidth = '150px';
  @Input() public confirmButtonDisabled: boolean;
  @Input() public cancelButtonLabel = 'Cancel';
  @Input() public cancelButtonWidth = '150px';
  @Input() public isCancelButton = true;
  @Input() public isConfirmLoading: boolean;
  @Output() public cancelClicked = new EventEmitter<void>();
  @Output() public confirmClicked = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
