import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-round-number-indicator',
  templateUrl: './round-number-indicator.component.html',
  styleUrls: ['./round-number-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoundNumberIndicatorComponent implements OnInit {
  @Input() public borderColor: string;
  @Input() public fontColor: string;
  @Input() public fontSize = '12px';
  @Input() public borderWidth = '2px';
  @Input() public bgColor: string;
  @Input() public sizeStr = '24px';

  constructor() {}

  ngOnInit(): void {}
}
