import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';

import { RouterOutlets } from '@rootTypes/entities/router';
import {
  studentChangeRequestPendingListByStudent,
  StudentChangeRequestPendingListByStudentRouteData,
} from './student-change-request-pending-list-by-student';

@Injectable({
  providedIn: 'root',
})
export class StudentChangeRequestPendingListByStudentRouterService extends PortalRouterService<StudentChangeRequestPendingListByStudentRouteData> {
  outlet = RouterOutlets.DRAWER;
  route = studentChangeRequestPendingListByStudent;
}
