import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { WpError } from '@rootTypes';

@Component({
  selector: 'wp-inline-error',
  templateUrl: './inline-server-error.component.html',
  styleUrls: ['./inline-server-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineServerErrorComponent implements OnInit {
  @Input() public error: WpError;

  constructor() {}

  ngOnInit(): void {}
}
