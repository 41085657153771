import { AuthEffects } from './auth.effects';
import { AccountEffects } from './account.effects';
import { ResetPasswordEffects } from './reset-password.effects';
import { UserProfileEffects } from './user-profile.effects';
import { MultiAccountManagementEffects } from './multi-account-management.effects';

export const effects = [
  AuthEffects,
  AccountEffects,
  ResetPasswordEffects,
  UserProfileEffects,
  MultiAccountManagementEffects,
];

export * from './auth.effects';
export * from './account.effects';
export * from './reset-password.effects';
