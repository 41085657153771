import { Injectable } from '@angular/core';
import { RouterOutlets } from '@rootTypes/entities/router';
import { PortalRouterService } from '../../types/portal-router-service';
import { createPortalRoute } from '../../utils/createPortalRoute';

export type SchoolEmployeeCampusAccessManagerRouteData = {
  employeeId: string;
};

export const schoolEmployeeCampusAccessManagerRoute = createPortalRoute<SchoolEmployeeCampusAccessManagerRouteData>({
  path: ':employeeId/campus-access/manage',
  prefix: 'school-employees',

  outlet: RouterOutlets.DRAWER,
});

@Injectable({ providedIn: 'root' })
export class SchoolEmployeeCampusAccessManagerRouterService extends PortalRouterService<SchoolEmployeeCampusAccessManagerRouteData> {
  readonly route = schoolEmployeeCampusAccessManagerRoute;
}
