import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { iconPaths } from '@rootTypes/utils/common';

@Component({
  selector: 'wp-round-rating-badge',
  templateUrl: './round-rating-badge.component.html',
  styleUrls: ['./round-rating-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoundRatingBadgeComponent implements OnInit {
  @Input() public rating: number;
  iconPaths = iconPaths;

  constructor() {}

  ngOnInit(): void {}
}
