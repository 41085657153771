import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromStore from './store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [],
  providers: [...fromStore.facades.featureFacades],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers.rideFeatureReducer),
    EffectsModule.forFeature(fromStore.effects.featureEffects),
  ],
})
export class RidesDataModule {}
