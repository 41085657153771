<wp-auth-wrapper [rightColumnImage]="'resetPassword'">
  <section class="reset-password-email-sent">
    <div class="content">
      <img
        class="img"
        alt="Email sent"
        src="/assets/icons/auth/reset-password-email-sent.svg"
      />
      <div class="header">Reset instructions sent</div>
      <div class="instructions">
        Please check your email for password reset instructions.
      </div>
      <wp-link-btn class="login-link" (clicked)="onReturnToLoginPage()"
        >Return to login page
      </wp-link-btn>
    </div>
  </section>
</wp-auth-wrapper>
