import { FilterDeserializer } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';
import { StudentRfidListStatusFilter } from '../types/student-rfid-list';
import { StudentRfidStatus } from '@apiEntities';
import { createStudentRfidListStatusFilter } from './create-student-rfid-list-status-filter';

export const deserializeStudentRfidListStatusFilter: FilterDeserializer<StudentRfidListStatusFilter> = (
  str: string,
): StudentRfidListStatusFilter | undefined => {
  if (str && str.startsWith('rfid-status')) {
    const value = str.split(',')[1] as StudentRfidStatus;
    return createStudentRfidListStatusFilter(value);
  }
  return undefined;
};
