import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromStore from './store';
import { DistrictApiService } from './services/districts-api.service';

@NgModule({
  declarations: [],
  providers: [DistrictApiService, ...fromStore.facades.facades],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers.featureReducer),
    EffectsModule.forFeature(fromStore.effects.effects),
  ],
})
export class DistrictDataModule {}
