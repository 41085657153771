import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'wp-expand-container',
  templateUrl: './expand-container.component.html',
  styleUrls: ['./expand-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandContainerComponent implements OnInit, OnChanges {
  @Input() public isExpanded = false;
  @Input() public collapsedHeight: string;
  @Input() public expandText = 'Show more';
  @Input() public collapseText = 'Show less';
  @Input() public expandButtonMargin = '10px 0 0 0';
  @Input() public expandButtonPosition: 'left' | 'right' = 'left';

  @Output() public expandedChange = new EventEmitter<boolean>();

  public isContainerExpanded: boolean;
  public expandButtonText: string;

  public ngOnInit(): void {
    this.isContainerExpanded = this.isExpanded;
    this.setExpandButtonText();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.expandText && !changes.expandText.isFirstChange()) {
      this.setExpandButtonText();
    }
    if (changes.collapseText && !changes.collapseText.isFirstChange()) {
      this.setExpandButtonText();
    }
    if (changes.isExpanded && !changes.isExpanded.isFirstChange()) {
      this.isContainerExpanded = this.isExpanded;
    }
  }

  public toggle(): void {
    this.isContainerExpanded = !this.isContainerExpanded;
    this.setExpandButtonText();
    this.expandedChange.emit(this.isContainerExpanded);
  }

  private setExpandButtonText(): void {
    this.expandButtonText = this.isContainerExpanded ? this.collapseText : this.expandText;
  }
}
