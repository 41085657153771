import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { AbstractDropdown } from '../abstract-dropdown';
import { UntypedFormControl } from '@angular/forms';
import { SelectOption } from '../..';

@Component({
  selector: 'wp-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterDropdownComponent extends AbstractDropdown implements OnInit, OnDestroy {
  @Input() public disabled = false;
  @Input() public label!: string;
  @Input() public control!: UntypedFormControl;
  @Input() public options!: SelectOption[];
  @Input() public width = '100%';
  @Input() public alignDropdown: 'left' | 'right' | 'center' = 'center';
  @Input() public setOptionSelected: boolean;
  // render custom dropdown template
  @Input() public dropdownTemplate: any;

  constructor() {
    super();
  }
  ngOnDestroy(): void {
    super.onNgOnDestroy();
  }

  ngOnInit(): void {
    super.onNgInit();
  }
}
