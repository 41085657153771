import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfFilePageCountPipe } from './pipes/pdf-file-page-count.pipe';
import { IsPdfFilePipe } from './pipes/is-pdf-file.pipe';

@NgModule({
  declarations: [PdfFilePageCountPipe, IsPdfFilePipe],
  imports: [CommonModule],
  exports: [PdfFilePageCountPipe, IsPdfFilePipe],
})
export class PdfUtilsModule {}
