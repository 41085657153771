import { Component, ChangeDetectionStrategy } from '@angular/core';
import { HomeUIFacade } from '@rootStore';

@Component({
  selector: 'wp-drawer-access-denied',
  templateUrl: './drawer-access-denied.component.html',
  styleUrls: ['./drawer-access-denied.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerAccessDeniedComponent {
  constructor(private homeUI: HomeUIFacade) {}

  public goBack(): void {
    window.history.back();
  }

  public closeDrawer(): void {
    this.homeUI.closeDrawer();
  }
}
