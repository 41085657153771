import { createSelector } from '@ngrx/store';
import { getFeature } from './get-feature';

const getNationalHolidayState = createSelector(getFeature, (state) => state.nationalHolidays);

export const getNationalHolidaysLoading = createSelector(getNationalHolidayState, (state) => state.isLoading);

export const getNationalHolidaysEntities = createSelector(getNationalHolidayState, (state) => state.entities);

export const getNationalHolidaysSorted = createSelector(getNationalHolidaysEntities, (state) => {
  if (!state) {
    return [];
  }
  const array = Object.keys(state).map((id) => state[id]);
  array.sort((a, b) => {
    if (a.date < b.date) {
      return -1;
    }
    return 1;
  });
  return array;
});

export const getNationalHolidaysError = createSelector(getNationalHolidayState, (state) => state.error);
