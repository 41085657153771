import { RidersByEventsWeekdayDependent } from '../../../../types/entities/route/riders-by-events-weekday-dependent';
import { GetRouteResponse } from '../get-route';
import { WeekdayDependentValue, Collection } from '@rootTypes';
import * as fromRootUtils from '../../../../types/utils';

export const dbToRiderIdsByEventsByWeekdays = (source: GetRouteResponse): RidersByEventsWeekdayDependent => {
  const target = {} as RidersByEventsWeekdayDependent;
  if (source && source.events) {
    Object.keys(source.events).forEach((eventId) => {
      const weekdayDepRiders = {} as WeekdayDependentValue<Collection<string>>;
      if (source.riders) {
        Object.keys(source.riders).forEach((riderId) => {
          const routeRider = source.riders[riderId];
          if (routeRider.active_days) {
            Object.keys(routeRider.active_days).forEach((dayId) => {
              const day = routeRider.active_days[dayId];
              const dayEvents = day.events;
              if (dayEvents) {
                if (dayEvents.pickup_event_id === eventId || dayEvents.dropoff_event_id === eventId) {
                  const weekday = fromRootUtils.date.getWeekdayFromDayPrefixedWeekday(dayId);
                  if (!weekdayDepRiders[weekday]) {
                    weekdayDepRiders[weekday] = {};
                  }
                  weekdayDepRiders[weekday][riderId] = riderId;
                }
              }
            });
          }
        });
      }
      target[eventId] = weekdayDepRiders;
    });
  }
  return target;
};
