<div class="wp-vendor-home">
  <div class="wp-vendor-home__left">
    <wp-side-menu></wp-side-menu>
  </div>
  <div class="wp-vendor-home__right">
    <div
      class="padding-wrap"
      [ngStyle]="{
        paddingLeft: homePageSidePadding$ | async,
        paddingRight: homePageSidePadding$ | async
      }"
    >
      <div *ngIf="sectionLoading$ | async; else templateContent">
        <wp-section-spinner [sectionHeight]="'100vh'" [spinnerSize]="50"> </wp-section-spinner>
      </div>
      <ng-template #templateContent>
        <router-outlet></router-outlet>
      </ng-template>
    </div>
  </div>
</div>

<wp-employee-impersonation-session-timer></wp-employee-impersonation-session-timer>
