import { Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { filter, take, switchMap, map } from 'rxjs/operators';

import * as fromApi from '../../api';
import { AccountState } from '../store/reducers';
import * as fromSelectors from '../store/selectors';
import { State } from '../../store';
import * as fromActions from '../store/actions';

export const loadAccounts = (
  store: Store<AccountState | State>,
  getAccountsAction: fromActions.GetAccountsActionCreator,
): Observable<fromApi.account.UserAccount[] | null> => {
  return store.select(fromSelectors.accountsState).pipe(
    filter((state) => !state.isLoading),
    take(1),
    switchMap((state) => {
      if (state.data) {
        return of(state.data);
      }
      store.dispatch(getAccountsAction());
      return store.select(fromSelectors.accountsState).pipe(
        filter((accountsState) => !accountsState.isLoading && !!accountsState.data),
        take(1),
        map((accountsState) => accountsState.data),
      );
    }),
  );
};
