import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';

import { SmartSelect, SmartSelectValue } from './smart-select';
import { SmartSelectAsync } from './smart-select-async';
import { SmartInputSchool, SmartInputSchoolEntity } from './smart-input-school';
import { SmartInputModel } from './smart-input-model';
import { StudentSchoolInfoApiService } from '../services';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { SelectOption } from '../../form-controls';
import { EntityFilterOptions } from '../../../api/endpoints/entity-filter';

export interface SmartInputStudentSchoolInfoConfig {
  districtLabel?: string; // defaults to 'District'
  campusLabel?: string; // defaults to 'School campus'
  schoolYearLabel?: string; // defaults to 'School year'
  gradeLabel?: string; // defaults to 'Grade'
  value?: SmartInputStudentSchoolInfoValue;
  isSchoolRequired?: boolean;
  isGroupDisabled?: boolean;
  isGradeValueSameAsLabel?: boolean;
  campusEntityFilterOptions?: EntityFilterOptions;
}

export interface SmartInputStudentSchoolInfoValue {
  district: SmartInputSchoolEntity;
  campus: SmartInputSchoolEntity;
  schoolYear?: { value: string };
  grade?: SmartInputSchoolEntity;
}

export class SmartInputStudentSchoolInfo implements SmartInputModel {
  public district: SmartSelect;
  public campus: SmartSelect;
  public schoolYear: SmartSelectAsync;
  public grade: SmartSelectAsync;

  private school: SmartInputSchool;
  private sub = new Subscription();
  private api$$ = new BehaviorSubject<StudentSchoolInfoApiService>(null);
  private setLoadersForCampusId: string;
  private isGradeValueSameAsLabel: boolean;

  constructor(config: SmartInputStudentSchoolInfoConfig) {
    this.isGradeValueSameAsLabel = config.isGradeValueSameAsLabel;
    this.school = new SmartInputSchool({
      districtLabel: config.districtLabel,
      campusLabel: config.campusLabel,
      value: config.value
        ? {
            district: config.value.district,
            campus: config.value.campus,
          }
        : undefined,
      disabled: config.isGroupDisabled,
      required: config.isSchoolRequired,
      campusEntityFilterOptions: config.campusEntityFilterOptions,
    });
    this.district = this.school.district;
    this.campus = this.school.campus;

    this.schoolYear = new SmartSelectAsync({
      label: config.schoolYearLabel || 'School year',
      value: this.schoolYearValueToSmartSelectValue(config.value?.schoolYear),
      loadOptionsErrorMessage: 'Failed to load school year options',
      disabled: config.isGroupDisabled,
      required: false,
    });

    this.grade = new SmartSelectAsync({
      label: config.gradeLabel || 'Grade',
      value: config.value?.grade,
      loadOptionsErrorMessage: 'Failed to load grade options',
      disabled: config.isGroupDisabled,
      required: false,
    });
  }

  public getValue(): SmartInputStudentSchoolInfoValue | undefined {
    const school = this.school.getValue();
    if (!school) {
      return undefined;
    }
    const value: SmartInputStudentSchoolInfoValue = {
      district: school.district,
      campus: school.campus,
    };
    const schoolYear = this.schoolYear.getValue();
    if (schoolYear) {
      value.schoolYear = { value: schoolYear.id };
    }
    const grade = this.grade.getValue();
    if (grade) {
      value.grade = {
        id: grade.id,
        label: grade.label,
      };
    }
    return value;
  }

  public isValid(): boolean {
    return this.school.isValid() && this.schoolYear.isValid() && this.grade.isValid();
  }

  public showErrorIfAny(): void {
    this.school.showErrorIfAny();
    this.schoolYear.showErrorIfAny();
    this.grade.showErrorIfAny();
  }

  public hasChanges(): boolean {
    return this.school.hasChanges() || this.schoolYear.hasChanges() || this.grade.hasChanges();
  }

  public async setValue(value: SmartInputStudentSchoolInfoValue): Promise<void> {
    this.sub.unsubscribe();
    if (value) {
      this.school.setValue({ district: value.district, campus: value.campus });
      this.grade.setValue(value.grade);
      this.schoolYear.setValue({ id: value.schoolYear?.value });
      if (value.campus?.id) {
        const api = await this.api$$
          .pipe(
            filter((api) => !!api),
            take(1),
          )
          .toPromise();
        this.setLoadOptionsFnForAsyncSelect(
          this.grade,
          this.isGradeValueSameAsLabel
            ? api.getGradeOptionsWithValueSameAsLabel.bind(api)
            : api.getGradeOptions.bind(api),
          value.campus.id,
        );
        this.grade.loadOptions();
        this.setLoadOptionsFnForAsyncSelect(this.schoolYear, api.getSchoolYearOptions.bind(api), value.campus.id);
      }
    }
    this.setSubscriptions();
  }

  public setDisabled(disabled): void {
    this.sub.unsubscribe();
    this.school.setDisabled(disabled);
    disabled ? this.grade.control.disable() : this.grade.control.enable();
    disabled ? this.schoolYear.control.disable() : this.schoolYear.control.enable();
    this.setSubscriptions();
  }

  public onComponentInit(api: StudentSchoolInfoApiService): void {
    this.api$$.next(api);
    this.school.onComponentInit();

    const campusInitial = this.campus.getValue();
    if (campusInitial) {
      this.setLoadOptionsFnForAsyncSelect(this.schoolYear, api.getSchoolYearOptions.bind(api), campusInitial.id, false);
      this.setLoadOptionsFnForAsyncSelect(
        this.grade,
        this.isGradeValueSameAsLabel
          ? api.getGradeOptionsWithValueSameAsLabel.bind(api)
          : api.getGradeOptions.bind(api),
        campusInitial.id,
        false,
      );
    }
    this.setSubscriptions();
  }

  public onComponentDestroy(): void {
    this.sub.unsubscribe();
    this.school.onComponentDestroy();
  }

  public getValueChanges(): Observable<any> {
    throw new Error('Not implemented');
  }

  private schoolYearValueToSmartSelectValue(source?: { value: string }): SmartSelectValue {
    if (source) {
      return {
        id: source.value,
        label: source.value,
      };
    }
    return undefined;
  }

  private setSubscriptions(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = new Subscription();
    const api$ = this.api$$.pipe(filter((api) => !!api));
    const campusChange$ = this.campus.control.valueChanges.pipe(
      distinctUntilChanged((prev, curr) => prev?.id === curr?.id),
    );
    const campusChangeSub = combineLatest([campusChange$, api$]).subscribe(([value, api]) => {
      const selectedCampusId = value?.id;
      if (selectedCampusId !== this.setLoadersForCampusId) {
        this.schoolYear.setValue(undefined);
        this.grade.setValue(undefined);
        if (value) {
          this.setLoadOptionsFnForAsyncSelect(this.schoolYear, api.getSchoolYearOptions.bind(api), selectedCampusId);
          this.setLoadOptionsFnForAsyncSelect(
            this.grade,
            this.isGradeValueSameAsLabel
              ? api.getGradeOptionsWithValueSameAsLabel.bind(api)
              : api.getGradeOptions.bind(api),
            selectedCampusId,
          );
        } else {
          this.schoolYear.resetOptions();
          this.grade.resetOptions();
          this.setLoadersForCampusId = undefined;
        }
      }
    });
    this.sub.add(campusChangeSub);
  }

  private setLoadOptionsFnForAsyncSelect(
    select: SmartSelectAsync,
    fn: (campusId) => Promise<SelectOption[]>,
    campusId: string,
    resetCurrentOptions = true,
  ): void {
    select.setLoadOptionsFn(() => fn(campusId), resetCurrentOptions);
    this.setLoadersForCampusId = campusId;
  }
}
