import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const moduleKey = 'Vendor Employee';
const submoduleKey = 'Details';

export const setEmployeeId = createAction(
  `[${moduleKey}] [${submoduleKey}]: Set employee id`,
  props<{ employeeId: string | undefined }>(),
);

export const setTab = createAction(
  `[${moduleKey}] [${submoduleKey}]: Set tab`,
  props<{ tab: fromTypes.EmployeeDetailsTabs }>(),
);

export const employeePermissionLinkClicked = createAction(
  `[${moduleKey}] [${submoduleKey}]: Employee permission link clicked`,
  props<{ permission: fromTypes.EmployeePermission }>(),
);
