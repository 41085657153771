import { SmartFormField } from '@apiEntities/smart-forms';

/**
 * If there is CREATE operation existingEntities might be [];
 * For DELETE exisingEntities will be filled and changeRequestFields will contain placeholder values (like '--')
 */
export type StudentChangeRequest = {
  id: StudentChangeRequestId;
  studentId: string;
  districtId: string;
  districtProgram: {
    id: string;
    name: string;
  };
  label: StudentChangeRequestLabel;
  updateExistingDataForLabel: string;
  operation: StudentChangeRequestOperation;
  requestedBy: StudentChangeRequestUserIdentity;
  isMaxEntityCountReached: boolean;
  allFields: StudentChangeRequestAllFieldsItem[];
  existingEntities: StudentChangeRequestExistingEntity[];
  changeRequestFields: {
    [fieldName: string]: StudentChangeRequestField;
  };
  suggestedComparisonEntityId?: string;
  deleteEntityLabel?: string;
};

export type StudentChangeRequestId = string;

export type StudentChangeRequestLabel =
  | 'Student School Information'
  | 'Profile Information'
  | 'Student Address'
  | 'Guardian Information';

export enum StudentChangeRequestOperation {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export type StudentChangeRequestUserIdentity = {
  id: string;
  role: string;
};

export type StudentChangeRequestAllFieldsItem = {
  fieldName: StudentChangeRequestFieldName;
  value: StudentChangeRequestFieldType;
  label: string;
  isOptional: boolean; // might be used if we want to enforce selection (example firstName during create)
};

export type StudentChangeRequestField = {
  fieldName: StudentChangeRequestFieldName;
  isEditable: boolean;
  isUseRevisedDataEnabled: boolean;
  isKeepExistingDataEnabled: boolean;
  value: StudentChangeRequestFieldType;
};

// example 'campusName', or 'firstName' etc
export type StudentChangeRequestFieldName = string;

export type StudentChangeRequestExistingEntity = {
  entityId: StudentChangeRequestExistingEntityId;
  label: string;
  dataFields: {
    [fieldName: string]: StudentChangeRequestExistingDataField;
  };
};

// For example, student_location
export type StudentChangeRequestExistingEntityId = string;

export type StudentChangeRequestExistingDataField = {
  name: string; // 'campusName', 'firstName'
  value: StudentChangeRequestFieldType;
};

export type StudentChangeRequestFieldType = SmartFormField;
