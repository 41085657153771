import { createReducer, on } from '@ngrx/store';

import * as fromRootTypes from '@rootTypes';
import { PortalPermissionItem } from '@rootTypes';
import * as fromAuthActions from '../actions/auth.actions';
import * as fromActions from '../actions/account.actions';
import * as fromApi from 'src/app/api';
import { userProfileFailed } from '../actions/user-profile.actions';
import { SignedInDistrict, SignedInVendor } from '@rootTypes/entities/auth';

export type AccountState = {
  homeGuardCheckAccount: {
    isLoading: boolean;
  };
  selectedAccount: {
    isLoading: boolean;
    data: fromApi.account.SelectedAccount | null;
    error: fromRootTypes.WpError | null;
  };
  permissions: {
    [key in PortalPermissionItem]?: true;
  };
  accounts: {
    isLoading: boolean;
    data: fromApi.account.UserAccount[] | null;
    error: fromRootTypes.WpError | null;
  };
  setAccount: {
    isLoading: boolean;
    error: fromRootTypes.WpError | null;
  };
  signedInVendor?: SignedInVendor;
  signedInDistrict?: SignedInDistrict;
};

const createInitialAccountState = (): AccountState => {
  return {
    homeGuardCheckAccount: {
      isLoading: false,
    },
    selectedAccount: {
      isLoading: false,
      data: null,
      error: null,
    },
    permissions: {},
    accounts: {
      isLoading: false,
      data: null,
      error: null,
    },
    setAccount: {
      isLoading: false,
      error: null,
    },
    signedInVendor: null,
    signedInDistrict: null,
  };
};

export const accountReducer = createReducer<AccountState>(
  createInitialAccountState(),
  on(fromAuthActions.logoutPageLogoutSuccess, () => {
    return createInitialAccountState();
  }),
  on(fromActions.homeGuardCheckAccountStart, (state) => {
    const newState: AccountState = {
      ...state,
      homeGuardCheckAccount: {
        isLoading: true,
      },
    };
    return newState;
  }),
  on(
    fromActions.homeGuardCheckAccountEnd,
    fromActions.homeGuardAccountFailed,
    fromActions.homeGuardAccountsFailed,
    fromActions.homeGuardSetAccountFailed,
    userProfileFailed,
    (state) => {
      const newState: AccountState = {
        ...state,
        homeGuardCheckAccount: {
          isLoading: false,
        },
      };
      return newState;
    },
  ),
  on(fromActions.homeGuardAccountRequested, fromActions.selectAccountGuardAccountRequested, (state, action) => {
    const newState: AccountState = {
      ...state,
      selectedAccount: {
        isLoading: true,
        error: null,
        data: null,
      },
    };
    return newState;
  }),
  on(fromActions.homeGuardAccountSuccess, fromActions.selectAccountGuardAccountSuccess, (state, action) => {
    const permissions = action?.permissions || [];
    const newPermissionsMap = permissions.reduce((prev, curr) => {
      return { ...prev, [curr]: true };
    }, {});
    const newState: AccountState = {
      ...state,
      selectedAccount: {
        isLoading: false,
        error: null,
        data: action.data,
      },
      permissions: {
        ...newPermissionsMap,
      },
      signedInVendor: null,
      signedInDistrict: null,
    };
    if (action.vendor) {
      newState.signedInVendor = { ...action.vendor };
    }
    if (action.district) {
      newState.signedInDistrict = { ...action.district };
    }
    return newState;
  }),
  on(fromActions.homeGuardAccountFailed, fromActions.selectAccountGuardAccountFailed, (state, action) => {
    const newState: AccountState = {
      ...state,
      selectedAccount: {
        isLoading: false,
        error: action.error,
        data: null,
      },
    };
    return newState;
  }),
  on(
    fromActions.homeGuardAccountsRequested,
    fromActions.selectAccountGuardAccountsRequested,
    fromActions.switchAccountPopupAccountsRequested,
    (state, action) => {
      const newState: AccountState = {
        ...state,
        accounts: {
          isLoading: true,
          error: null,
          data: null,
        },
      };
      return newState;
    },
  ),
  on(
    fromActions.homeGuardAccountsSuccess,
    fromActions.selectAccountGuardAccountsSuccess,
    fromActions.switchAccountPopupAccountsSuccess,
    (state, action) => {
      const newState: AccountState = {
        ...state,
        accounts: {
          isLoading: false,
          error: null,
          data: action.data,
        },
      };
      return newState;
    },
  ),
  on(
    fromActions.homeGuardAccountsFailed,
    fromActions.selectAccountGuardAccountsFailed,
    fromActions.switchAccountPopupAccountsFailed,
    (state, action) => {
      const newState: AccountState = {
        ...state,
        accounts: {
          isLoading: false,
          error: action.error,
          data: null,
        },
      };
      return newState;
    },
  ),
  on(
    fromActions.homeGuardSetAccountRequested,
    fromActions.selectAccountGuardSetAccountRequested,
    fromActions.selectAccountPageSetAccountRequested,
    fromActions.switchAccountPopupSetAccountRequested,
    (state, action) => {
      const newState: AccountState = {
        ...state,
        setAccount: {
          isLoading: true,
          error: null,
        },
      };
      return newState;
    },
  ),
  on(
    fromActions.homeGuardSetAccountSuccess,
    fromActions.selectAccountGuardSetAccountSuccess,
    fromActions.selectAccountPageSetAccountSuccess,
    fromActions.switchAccountPopupSetAccountSuccess,
    (state, action) => {
      const newAccount = action.response.account;
      const newState: AccountState = {
        ...state,
        setAccount: {
          isLoading: false,
          error: null,
        },
        selectedAccount: {
          isLoading: false,
          error: null,
          data: {
            ...state.selectedAccount.data,
            name: newAccount.name,
            imagePath: newAccount.imagePath || null,
            accountId: newAccount.accountId,
            subAccountIds: newAccount.subAccountIds || null,
            timezone: newAccount.timezone,
          },
        },
        signedInVendor: null,
        signedInDistrict: null,
      };
      if (action.response.vendor) {
        newState.signedInVendor = { ...action.response.vendor };
      }
      if (action.response.district) {
        newState.signedInDistrict = { ...action.response.district };
      }
      const newPermissions = {};
      (action.response.permissions || []).forEach((item) => {
        newPermissions[item] = true;
      });
      newState.permissions = newPermissions;
      return newState;
    },
  ),
  on(
    fromActions.homeGuardSetAccountFailed,
    fromActions.selectAccountGuardSetAccountFailed,
    fromActions.selectAccountPageSetAccountFailed,
    fromActions.switchAccountPopupSetAccountFailed,
    (state, action) => {
      const newState: AccountState = {
        ...state,
        setAccount: {
          isLoading: false,
          error: action.error,
        },
      };
      return newState;
    },
  ),
);
