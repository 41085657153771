import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromStore from './store';
import * as fromServices from './services';

@NgModule({
  providers: [...fromStore.facades.vehiclesDataFacades, ...fromServices.featureServices],
  imports: [
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers.vehicleFeatureReducer),
    EffectsModule.forFeature(fromStore.effects.featureEffects),
  ],
})
export class VehiclesDataModule {}
