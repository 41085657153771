import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';
import { GetStudentSnapshotResponse } from '../../../../api/endpoints/get-student-snapshot';

const modulePrefix = 'Data';
const sectionPrefix = 'Routes';

/**
 * Load route
 */
export const loadRouteRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load route requested`,
  props<{ routeId: string; withPolyline?: boolean; withDistrictProgram?: boolean }>(),
);

export const loadedRouteSuccess = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Loaded route successfully`,
  props<{ payload: fromTypes.GetRoutePayload; routeId: string }>(),
);

export const loadRouteFailed = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Failed to load route`,
  props<{ routeId: string; error: fromTypes.WpError }>(),
);

/**
 * Load riders on a route (if not loaded)
 */
export const loadRidersForRouteRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load riders for route requested`,
  props<{ routeId: string }>(),
);

export const loadRiderIfEmptyRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load rider if empty requested`,
  props<{ riderId: string; routeId: string }>(),
);

export const loadedRiderIfEmptySuccess = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Loaded rider successfully`,
  props<{ rider: GetStudentSnapshotResponse }>(),
);

export const loadedRiderIfEmptyFailed = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Failed to load rider`,
  props<{ error: fromTypes.WpError; riderId: string }>(),
);

/**
 * Load calendar for a route
 */
export const loadCalendarForRouteRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load calendar for route requested`,
  props<{ routeId: string }>(),
);

export const loadedCalendarForRouteSuccessfully = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Loaded calendar for route successfully`,
  props<{ calendar: fromTypes.RouteCalendar; routeId: string }>(),
);

export const loadCalendarForRouteFailed = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load calendar for route failed`,
  props<{ error: fromTypes.WpError; routeId: string }>(),
);

/**
 * Load driver
 */
export const loadDriverForRouteRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load drivers for route requested`,
  props<{ routeId: string }>(),
);
