import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import * as fromTypes from '../../types';
import * as fromActions from '../actions/route-lists.actions';
import * as fromSelectors from '../selectors/route-lists.selectors';

@Injectable()
export class RouteListsFacade {
  constructor(
    private store: Store<State>,
    private actions$: Actions,
  ) {}

  public getStatusType$(listName: fromTypes.RouteListNames): Observable<fromTypes.RouteStatusType> {
    return this.store.select(fromSelectors.getSelectedStatusType, { listName });
  }

  public getFilters$(listName: fromTypes.RouteListNames): Observable<fromTypes.RouteFilter[]> {
    return this.store.select(fromSelectors.getSelectedFilters, { listName });
  }

  public getCurrentPage$(listName: fromTypes.RouteListNames): Observable<number> {
    return this.store.select(fromSelectors.getCurrentPage, { listName });
  }

  public getPageSize$(listName: fromTypes.RouteListNames): Observable<number> {
    return this.store.select(fromSelectors.getPageSize, { listName });
  }

  public getList$(listName: fromTypes.RouteListNames): Observable<string[]> {
    return this.store.select(fromSelectors.getRoutePageItems, { listName });
  }

  public getListLoading$(listName: fromTypes.RouteListNames): Observable<boolean> {
    return this.store.select(fromSelectors.getRoutePageLoading, { listName });
  }

  public getSnapshotAfterListLoad$(
    listName: fromTypes.RouteListNames,
  ): Observable<fromTypes.SerializedRouteListSnapshot> {
    return this.actions$.pipe(
      ofType(fromActions.loadRouteListSuccess),
      filter((a) => a.listName === listName),
      switchMap((a) => {
        return this.store.select(fromSelectors.getRouteListSnapshot, { listName }).pipe(
          take(1),
          map((snapshot) => fromTypes.utils.getSerializedSnapshot(snapshot)),
        );
      }),
    );
  }

  public getListEmpty$(listName: fromTypes.RouteListNames): Observable<boolean> {
    return combineLatest([this.getList$(listName), this.getListLoading$(listName)]).pipe(
      map(([list, loading]) => !loading && list.length === 0),
    );
  }

  public getTotalListItemsCount$(listName: fromTypes.RouteListNames): Observable<number> {
    return this.store.select(fromSelectors.getTotalItemsCount, { listName });
  }

  public getListError$(listName: fromTypes.RouteListNames): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getRoutePageError, { listName });
  }

  public getSelectedRouteId$(listName: fromTypes.RouteListNames): Observable<string | null> {
    return this.store.select(fromSelectors.getSelectedRouteId, { listName });
  }

  public getWeekday$(listName: fromTypes.RouteListNames): Observable<fromTypes.Weekday> {
    return this.store.select(fromSelectors.getSelectedWeekday, { listName });
  }

  public onStatusTypeChanged(listName: fromTypes.RouteListNames, statusType: fromTypes.RouteStatusType): void {
    this.store.dispatch(fromActions.routeStatusTypeChanged({ listName, statusType }));
  }

  public onInitializedRouteList(listName: fromTypes.RouteListNames): void {
    this.store.dispatch(fromActions.initializedRouteList({ listName }));
  }

  public onEntityFilterAdded(listName: fromTypes.RouteListNames, entity: fromTypes.PortalEntity): void {
    const filter: fromTypes.RouteEntityFilter = {
      filterId: fromTypes.utils.encodeDataComponents([fromTypes.RouteFilterType.ENTITY, entity.type, entity.entityId]),
      type: fromTypes.RouteFilterType.ENTITY,
      payload: {
        entityId: entity.entityId,
        entityType: entity.type,
      },
    };
    this.store.dispatch(fromActions.routeEntityFilterAddSelected({ listName, filter }));
  }

  public onSearchHitFilterAdded(listName: fromTypes.RouteListNames, hit: fromTypes.PortalEntitySearchHit): void {
    const filter: fromTypes.SearchHitFilter = {
      filterId: fromTypes.utils.encodeDataComponents([
        fromTypes.RouteFilterType.SEARCH_HIT_FILTER,
        hit.pillLabel,
        hit.field,
        hit.value,
      ]),
      type: fromTypes.RouteFilterType.SEARCH_HIT_FILTER,
      payload: {
        pillLabel: hit.pillLabel,
        field: hit.field,
        value: hit.value,
      },
    };
    this.store.dispatch(fromActions.routeSearchHitFilterAddSelected({ listName, filter }));
  }

  public onFilterRemoved(listName: fromTypes.RouteListNames, filterId: string): void {
    this.store.dispatch(fromActions.routeEntityFilterDeleteSelected({ listName, filterId }));
  }

  public onAllFiltersRemoved(listName: fromTypes.RouteListNames): void {
    this.store.dispatch(fromActions.routeFiltersRemoveAllSelected({ listName }));
  }

  public onSelectRouteId(listName: fromTypes.RouteListNames, routeId: string | null): void {
    this.store.dispatch(fromActions.selectedRouteIdChanged({ listName, routeId }));
  }

  public onSetStaticEntityFilters(listName: fromTypes.RouteListNames, entities: fromTypes.PortalEntity[]): void {
    const filters = entities.map((entity) => {
      const filter: fromTypes.RouteEntityFilter = {
        filterId: fromTypes.utils.encodeDataComponents([
          fromTypes.RouteFilterType.ENTITY,
          entity.type,
          entity.entityId,
        ]),
        type: fromTypes.RouteFilterType.ENTITY,
        payload: {
          entityId: entity.entityId,
          entityType: entity.type,
        },
      };
      return filter;
    });
    this.store.dispatch(fromActions.setStaticFilters({ listName, filters }));
  }

  public onSetListStateFromSnapshot(
    listName: fromTypes.RouteListNames,
    serializedSnapshot: fromTypes.SerializedRouteListSnapshot,
  ): void {
    const snapshot = fromTypes.utils.getSnapshotFromSerialized(serializedSnapshot);
    this.store.dispatch(fromActions.setRouteListFromSnapshot({ listName, snapshot }));
  }

  public onCurrentPageChanged(listName: fromTypes.RouteListNames, currentPage: number): void {
    this.store.dispatch(fromActions.currentPageChanged({ listName, page: currentPage }));
  }

  public onPageSizeChanged(listName: fromTypes.RouteListNames, pageSize: number): void {
    this.store.dispatch(fromActions.pageSizeChanged({ listName, pageSize }));
  }

  public onWeekdayChanged(listName: fromTypes.RouteListNames, weekday: fromTypes.Weekday): void {
    this.store.dispatch(fromActions.weekdaySelectedChanged({ listName, weekday }));
  }
}
