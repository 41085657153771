import { createReducer, on } from '@ngrx/store';
import * as fromTypes from '../../types';
import * as fromActions from '../actions/employee-profile-editor.actions';

export interface EmployeeProfileEditorState {
  employeeId?: string;
  createForVendorId?: string;
  isLoading: boolean;
  isSuccess: boolean;
  updatedModel?: fromTypes.EmployeeProfileEditModel;
  isUpdatedModelValid?: boolean;
  error?: fromTypes.WpError;
}

export const initialEmployeeProfileEditorState: EmployeeProfileEditorState = {
  isLoading: false,
  isSuccess: false,
};

export const employeeProfileEditorReducer = createReducer<EmployeeProfileEditorState>(
  { ...initialEmployeeProfileEditorState },
  on(fromActions.initEmployeeProfileEditor, (state, action) => {
    const { employeeId, createForVendorId } = action;
    return {
      ...initialEmployeeProfileEditorState,
      employeeId,
      createForVendorId,
    };
  }),
  on(fromActions.updatedEmployeeModel, (state, action) => {
    const { model } = action;
    return {
      ...state,
      updatedModel: model,
    };
  }),
  on(fromActions.updatedEmployeeModelValid, (state, action) => {
    const { valid } = action;
    return {
      ...state,
      isUpdatedModelValid: valid,
    };
  }),
  on(fromActions.createEmployeeRequested, fromActions.updateEmployeeRequested, (state, action) => {
    return {
      ...state,
      isLoading: true,
      isSuccess: false,
      error: undefined,
    };
  }),
  on(fromActions.createEmployeeSuccess, fromActions.updateEmployeeSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isSuccess: true,
      error: undefined,
      updatedModel: undefined,
      isUpdatedModelValid: false,
    };
  }),
  on(fromActions.createEmployeeFailed, fromActions.updateEmployeeFailed, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isSuccess: false,
      error: action.error,
    };
  }),
);
