<div class="edit-btn">
  <div class="edit-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="21"
      viewBox="0 0 19 21"
    >
      <g fill="none" fill-rule="evenodd">
        <g stroke="#959597" class="edit-svg">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              stroke-width="2"
                              d="M2.968.743h.21c1.105 0 2 .895 2 2v.003l-.017 11.67-2.378 3.305L.95 14.416.968 2.74c.002-1.104.896-1.997 2-1.997z"
                              transform="translate(-737 -961) translate(486) translate(20 720) translate(1 165) translate(230 75) translate(1) rotate(45 6.695 17.92)"
                            />
                            <path
                              stroke-linecap="square"
                              d="M.693 13.444h4.21M1.4 4.252h4.21"
                              transform="translate(-737 -961) translate(486) translate(20 720) translate(1 165) translate(230 75) translate(1) rotate(45 6.695 17.92)"
                            />
                          </g>
                          <path
                            stroke-width="2"
                            d="M7.843 4.177H3.768C1.258 4.051.004 5.29.004 7.89c.03 2.84.03 7.376 0 9.434-.03 2.058 0 2.772 3.764 2.772h8.406c2.42 0 3.696-1.212 3.826-3.636V11"
                            transform="translate(-737 -961) translate(486) translate(20 720) translate(1 165) translate(230 75) translate(1)"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
  <div class="edit-text">
    <ng-content></ng-content>
  </div>
</div>
