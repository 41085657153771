import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertCriticalComponent } from './alert-critical/alert-critical.component';
import { AlertWarningComponent } from './alert-warning/alert-warning.component';
import { AlertInfoComponent } from './alert-info/alert-info.component';
import { AlertSuccessComponent } from './alert-success/alert-success.component';
import { IconsModule } from '../icons/icons.module';
import { IconCloseModule } from '../icons/icon-close/icon-close.module';
import { ColumnWarningComponent } from './column-warning/column-warning.component';

const components = [
  AlertCriticalComponent,
  AlertWarningComponent,
  AlertInfoComponent,
  AlertSuccessComponent,
  ColumnWarningComponent,
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, IconsModule, IconCloseModule],
})
export class AlertsModule {}
