import { formatInTimeZone, format as formatNoTZ, FormatOptionsWithTZ } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';
import { enGB } from 'date-fns/locale';
import { getUserTimezone } from './get-user-timezone';

/**
 * See https://date-fns.org/v2.30.0/docs/format for format string options
 * @param date
 * @param format
 * @param timezone if null, device's local timezone is used
 * @param options
 */
export const dateFormat = (date: Date, format: string, timezone?: string, options?: FormatOptionsWithTZ): string => {
  if (!date) {
    return undefined;
  }
  const finalTimezone = timezone || getUserTimezone();
  if (!finalTimezone) {
    return formatNoTZ(date, format, options || {});
  }
  const isEnGB = finalTimezone && finalTimezone.startsWith('Europe');
  return formatInTimeZone(date, finalTimezone, format, {
    timeZone: timezone,
    locale: isEnGB ? enGB : enUS,
    ...(options || {}),
  });
};
