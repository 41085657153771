import { createAction, props } from '@ngrx/store';
import { WpError } from '@rootTypes';
import { CurrentUserProfile } from 'src/app/api/endpoints/get-profile';

export const userProfileRequested = createAction('[HomeGuard] User profile requested');
export const userProfileSuccess = createAction(
  '[HomeGuard] User profile success',
  props<{ profile: CurrentUserProfile }>(),
);
export const userProfileFailed = createAction('[HomeGuard] User profile failed', props<{ error: WpError }>());

export const switchAccountUserProfileRequested = createAction('[SwitchAccountTransition]: User profile requested');
export const switchAccountUserProfileSuccess = createAction(
  '[SwitchAccountTransition]: User profile success',
  props<{ profile: CurrentUserProfile }>(),
);
export const switchAccountUserProfileFailed = createAction(
  '[SwitchAccountTransition]: User profile failed',
  props<{ error: WpError }>(),
);

export const userPhotoRequested = createAction('[UserProfileEffects] User photo requested');
export const userPhotoSuccess = createAction(
  '[UserProfileEffects] User photo success',
  props<{ imageBase64: string | '' }>(),
);
export const userPhotoFailed = createAction('[UserProfileEffects] User photo failed', props<{ error: WpError }>());

export const updateUserPhotoRequested = createAction(
  '[UserProfile] Update user photo requested',
  props<{ imageBase64: string | '' }>(),
);
export const updateUserPhotoSuccess = createAction(
  '[UserProfile] Update user photo success',
  props<{ imageBase64: string | ''; photoPath: string | '' }>(),
);
export const updateUserPhotoFailed = createAction(
  '[UserProfile] Update user photo failed',
  props<{ error: WpError }>(),
);
