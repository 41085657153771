import {
  StudentChangeRequestOperation,
  StudentChangeRequestReviewFormRow,
} from '@rootTypes/entities/student-change-requests';
import { isSmartFormFieldValid } from '@rootTypes/utils/smart-forms';

export const validateStudentChangeRequestFormRows = (
  operation: StudentChangeRequestOperation,
  rows: StudentChangeRequestReviewFormRow[],
): { isValidOnClientSide: boolean; validatedRows: StudentChangeRequestReviewFormRow[] } => {
  let isValidOnClientSide = true;
  const validatedRows: StudentChangeRequestReviewFormRow[] = [];

  if (operation === StudentChangeRequestOperation.CREATE) {
    rows.forEach((row) => {
      const isRevisedDataHighlighted =
        row.isEditable &&
        (typeof row.isKeepExistingDataSelected !== 'boolean' ||
          !isSmartFormFieldValid(row.revisedDataCurrent, !row.isOptional));
      if (isRevisedDataHighlighted) {
        isValidOnClientSide = false;
      }
      validatedRows.push({
        ...row,
        isRevisedDataHighlighted,
      });
    });
    return {
      isValidOnClientSide,
      validatedRows,
    };
  }

  if (operation === StudentChangeRequestOperation.DELETE) {
    return {
      isValidOnClientSide,
      validatedRows: rows.map((row) => {
        return {
          ...row,
          isRevisedDataHighlighted: false,
        };
      }),
    };
  }

  // update operation
  rows.forEach((row) => {
    const isRevisedDataHighlighted =
      row.isEditable &&
      (typeof row.isKeepExistingDataSelected !== 'boolean' ||
        !isSmartFormFieldValid(row.revisedDataCurrent, !row.isOptional));

    if (isRevisedDataHighlighted) {
      isValidOnClientSide = false;
    }
    validatedRows.push({
      ...row,
      isRevisedDataHighlighted,
    });
  });
  return {
    isValidOnClientSide,
    validatedRows,
  };
};
