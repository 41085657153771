import { YYYYMMDDString } from '@rootTypes';
import { dateFormat } from './date-format';

/**
 *
 * @param date
 * @param timezone if null, device's local timezone is used
 */
export const dateToYYYYMMDD = (date: Date, timezone: string = null): YYYYMMDDString => {
  if (!date) {
    return undefined;
  }
  return dateFormat(date, 'yyyyMMdd', timezone);
};
