import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-square-pill',
  templateUrl: './square-pill.component.html',
  styleUrls: ['./square-pill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SquarePillComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
