import { GetVendorProfileResponse } from '../get-vendor-profile-response';
import { Vendor } from '@rootTypes/entities/vendor';

export const getVendorFromApi = (source: GetVendorProfileResponse): Vendor => {
  const result: Vendor = {
    id: source.vendor_id,
    name: source.vendor_info.company_name,
    displayAddress: source.vendor_info?.address,
    code: source.code,
    photo: source.vendor_info?.logo_url,
    website: source.vendor_info?.website,
  };
  if (source.primary_contact) {
    result.contactPerson = {
      name: source.primary_contact.primary_email,
      phone: source.primary_contact.primary_phone,
    };
  }
  return result;
};
