// API error
export interface WebError {
  code: ErrorCodes;
  data: string | WebErrorData;
  contextId?: string; // unique error id
  name?: string; // 'WebError'
}

export enum ErrorCodes {
  LEGITIMATE_ERROR = 501,
  CRITICAL_ERROR = 400,
}

export interface WebErrorDetails {
  code: number; // 400
  contextId: string; // unique error id
  data: WebErrorData;
  name: string;
}

export interface WebErrorData {
  message: string;
  errorCode: string;
}

export const isWebError = (arg: any): arg is WebError => {
  return !!arg && typeof arg === 'object' && !!arg.data;
};

export const isWebErrorDetails = (arg: any): arg is WebErrorDetails => {
  return isWebError(arg) && !!arg.data && typeof arg.data === 'object' && !!arg.data.errorCode && !!arg.data.message;
};
