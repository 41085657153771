import { isAfter, isBefore, isSameDay, addDays } from 'date-fns';
import { dateClone } from '@rootTypes/utils/common/date-time-fns/date-clone';
/**
 *
 * @param dateStart - Date
 * @param dateEnd - Date
 * @returns - Array of dates between dateStart and dateEnd (per each day), including dateStart and dateEnd
 */
export const datesGetSpan = (dateStart: Date, dateEnd: Date): Date[] => {
  if (isAfter(dateStart, dateEnd)) {
    throw new Error('dateStart must be before dateEnd');
  }
  const result: Date[] = [];
  for (let c = dateClone(dateStart); isBefore(c, dateEnd) && !isSameDay(c, dateEnd); c = addDays(c, 1)) {
    result.push(c);
  }
  result.push(dateClone(dateEnd));
  return result;
};
