import { getFirstNSymbolsFromString } from '@rootTypes/utils';
import { StudentChangeRequestSummary } from '@apiEntities';

export const getProcessedByUserDisplayFromChangeRequestSummary = (summary?: StudentChangeRequestSummary): string => {
  const reviewed = summary?.reviewed;
  if (!reviewed) {
    return '--';
  }
  return `${reviewed.userFirstName} ${getFirstNSymbolsFromString(reviewed.userLastName)} - ${reviewed.userRole}`;
};
