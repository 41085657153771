import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute } from '../../types/portal-route';

export const forgotPasswordRoute: PortalRoute<void> = {
  path: 'forgot-password',
  request(): NavigationRequest {
    return {
      path: [`/${this.path}`],
    };
  },
};
