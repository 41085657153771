import { PortalRouteTracePoint } from '../../../types/entities/ride';

export const getHTMLForRouteTracePoint = (point: PortalRouteTracePoint): string => {
  return `
      <div style='position: relative; bottom: 7px; background-color: white; z-index: 110; padding: 12px 7px; border-radius: 5px; box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);'>
        <div style='display: flex; font-size: 12px;'>
          <div style='font-weight: bold; margin-right: 2px'>Time: </div>
          <div>${point.displayTime} <br><span style="color: #636365;">${
            point.isInUserTimezone ? '' : point.displayTimeUserTimezone
          }</span></div>
       </div>
       <div style='display: flex; font-size: 12px;'>
          <div style='font-weight: bold; margin-right: 2px'>Speed: </div>
          <div>${point.speedMPH} mph</div>
       </div>
       <div style='display: ${point.battery_level ? 'flex' : 'none'}; font-size: 12px;'>
          <div style='font-weight: bold; margin-right: 2px'>Battery: </div>
          <div>${point.battery_level}%<span style='display: ${point.battery_state ? 'inline' : 'none'};'>, ${
            point.battery_state
          }</span></div>
       </div>
      </div>

    `;
};

export const getClickHTMLForRouteTracePointWithVideoLinks = (point: PortalRouteTracePoint): string => {
  return `
      <div style='cursor: text; position: relative; bottom: 25px; background-color: white; z-index: 120; padding: 12px 7px; border-radius: 5px; box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);'>
        <div style="cursor: pointer; width: 10px; height: 10px; position: absolute; top: 4px; right: 8px;" id="close-${
          point.id
        }">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 16 16"
              class="hoverable-close-svg"
            >
              <g
                fill="none"
                fill-rule="evenodd"
                stroke="#7C7C7E"
                stroke-linecap="round"
                stroke-width="3"
              >
                <path d="M13.148 2.966L2.966 13.148M13.148 13.148L2.966 2.966" />
              </g>
            </svg>
        </div>
        <div style='display: flex; font-size: 12px;'>
          <div style='font-weight: bold; margin-right: 2px'>Time: </div>
          <div>${point.displayTime} <br><span style="color: #636365;">${
            point.isInUserTimezone ? '' : point.displayTimeUserTimezone
          }</span></div>
       </div>
       <div style='display: flex; font-size: 12px;'>
          <div style='font-weight: bold; margin-right: 2px'>Speed: </div>
          <div>${point.speedMPH} mph</div>
       </div>
       <div style='display: flex; font-size: 12px;'>
          <div style='font-weight: bold; margin-right: 2px'>Battery: </div>
          <div>${point.battery_level}%<span style='display: ${point.battery_state ? 'inline' : 'none'};'>, ${
            point.battery_state
          }</span></div>
       </div>
       <div style='display: flex; font-size: 12px;'>
          <div style='font-weight: bold; margin-right: 2px'>Video: </div>
          <div><span class="hoverable-text-green" style="text-decoration: underline; cursor: pointer;" id="all-${
            point.id
          }">All</span>&nbsp;<span class="hoverable-text-green" style="text-decoration: underline; cursor: pointer;"  id="driver-${
            point.id
          }">Driver</span></div>
       </div>
      </div>
    `;
};

export const getClickHTMLForRouteTracePointWithoutVideoLinks = (point: PortalRouteTracePoint): string => {
  return `
      <div style='position: relative; bottom: 15px; background-color: white; z-index: 120; padding: 12px 7px; border-radius: 5px; box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);'>
        <div style="width: 10px; height: 10px; position: absolute; top: 4px; right: 8px;" id="close-${point.id}">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 16 16"
            >
              <g
                fill="none"
                fill-rule="evenodd"
                stroke="#7C7C7E"
                stroke-linecap="round"
                stroke-width="3"
              >
                <path d="M13.148 2.966L2.966 13.148M13.148 13.148L2.966 2.966" />
              </g>
            </svg>
        </div>
        <div style='display: flex; font-size: 12px;'>
          <div style='font-weight: bold; margin-right: 2px'>Time: </div>
          <div>${point.displayTime} <br><span style="color: #636365;">${
            point.isInUserTimezone ? '' : point.displayTimeUserTimezone
          }</span></div>
       </div>
       <div style='display: flex; font-size: 12px;'>
          <div style='font-weight: bold; margin-right: 2px'>Speed: </div>
          <div>${point.speedMPH} mph</div>
       </div>
       <div style='display: ${point.battery_level ? 'flex' : 'none'}; font-size: 12px;'>
          <div style='font-weight: bold; margin-right: 2px'>Battery: </div>
          <div>${point.battery_level}%<span style='display: ${point.battery_state ? 'inline' : 'none'};'>, ${
            point.battery_state
          }</span></div>
       </div>
      </div>
    `;
};
