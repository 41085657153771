import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';

import { goErrorPage, openDefaultErrorPopup, openErrorPopup } from '../actions';
import { ErrorPopupService } from '../services/error-popup.service';

@Injectable()
export class ErrorEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private errorPopupService: ErrorPopupService,
  ) {}

  public goErrorPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(goErrorPage),
        tap(() => {
          this.router.navigate(['/error']);
        }),
      ),
    { dispatch: false },
  );

  public openErrorPopup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openErrorPopup),
      map((action) => {
        return openDefaultErrorPopup({
          error: action.error,
          hideErrorHeader: action.hideErrorHeader,
          closePrevious: action.closePrevious,
        });
      }),
    ),
  );

  public openDefaultErrorPopup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openDefaultErrorPopup),
        tap((action) => {
          const { error, hideErrorHeader, closePrevious } = action;
          this.errorPopupService.openDefaultErrorPopup(error, hideErrorHeader, closePrevious);
        }),
      ),
    { dispatch: false },
  );
}
