import { createSelector } from '@ngrx/store';
import { getEmployeesFeature } from './get-feature';
import {
  getAllEmployeeEntities,
  getAllEmployeePermissions,
  getAllPermissionOptionState,
} from './employee-data.selectors';
import { getEmployeeId } from './employee-details.selectors';
import { getAllVendorEntities } from '../../../vendors/store/selectors';

export const getPermissionsManagerState = createSelector(getEmployeesFeature, (state) => state.permissionManager);

export const getOpenEmployeeId = createSelector(getPermissionsManagerState, (state) => state.employeeId);

const getOpenedEmployeeEntityState = createSelector(
  getEmployeeId,
  getAllEmployeeEntities,
  (employeeId, allEmployees) => {
    if (!employeeId) {
      return undefined;
    }
    return allEmployees[employeeId];
  },
);

export const getOpenedEmployee = createSelector(getOpenedEmployeeEntityState, (state) => state?.entity);

export const getOpenedEmployeeLoading = createSelector(getOpenedEmployeeEntityState, (state) => state?.isLoading);

export const getOpenedEmployeeError = createSelector(getOpenedEmployeeEntityState, (state) => state?.error);

const getOpenEmployeePermissionOptionState = createSelector(
  getOpenEmployeeId,
  getAllPermissionOptionState,
  (employeeId, allOptions) => allOptions[employeeId],
);
// permissions
export const getOpenEmployeePermissions = createSelector(
  getEmployeeId,
  getAllEmployeePermissions,
  (employeeId, allPermissions) => allPermissions[employeeId],
);

// permission options
export const getOpenEmployeePermissionOptions = createSelector(
  getOpenEmployeePermissionOptionState,
  (state) => state.entity,
);

export const getOpenEmployeePermissionOptionsLoading = createSelector(
  getOpenEmployeePermissionOptionState,
  (state) => state.isLoading,
);

export const getOpenEmployeePermissionOptionsError = createSelector(
  getOpenEmployeePermissionOptionState,
  (state) => state.error,
);

// vendor by open employee
const getOpenEmployeeVendorState = createSelector(getOpenedEmployee, getAllVendorEntities, (employee, allVendors) => {
  if (!employee) {
    return undefined;
  }
  return allVendors[employee.parentEntityId];
});

export const getVendorByOpenEmployee = createSelector(getOpenEmployeeVendorState, (state) => state.entity);
export const getVendorLoadingByOpenEmployee = createSelector(getOpenEmployeeVendorState, (state) => state.isLoading);
export const getVendorErrorByOpenEmployee = createSelector(getOpenEmployeeVendorState, (state) => state.error);

// update permissions request
export const getUpdatePermissionsLoading = createSelector(getPermissionsManagerState, (state) => state.isLoading);

export const getUpdatePermissionsLoaded = createSelector(getPermissionsManagerState, (state) => state.isLoaded);
