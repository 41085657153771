import { District } from '../../../../types/entities/districts';
import { GetDistrictResponse } from '../response';
import { ArrivalDepartureTimeBuffer } from '@apiEntities/campus-programs';

export const getDistrictFromDb = (
  source: Partial<GetDistrictResponse['district']>,
  defaultTimeBuffers?: ArrivalDepartureTimeBuffer,
): District => {
  const result = {
    id: source.id,
    name: source.name,
    address: source.address,
    photo: source.logoPath,
    website: source.websiteUrl,
    state: source.state,
    grades: source.grades,
    arrivalDepartureTimeBuffer: source.arrivalDepartureTimeBuffer ?? defaultTimeBuffers ?? null,
  } as District;
  if (source.checks) {
    result.checks = source.checks;
  }
  if (source.contact) {
    result.contactPerson = {
      name: source.contact.name,
      phone: source.contact.phoneNumber,
      position: source.contact.position,
    };
  }
  return result;
};
