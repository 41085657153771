import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';
import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { DashboardTab } from '@rootTypes/entities/dashboard';
import { dashboardRootPath } from './dashboard-root-path';
import { MapViewType } from '../../../features/dashboard/types/map-view-type';
import { getNthUrlSegment } from '@rootTypes/utils/common/get-nth-url-segment';

export interface DashboardRouteData {
  tab?: DashboardTab;
  mapViewType?: MapViewType;
  mapViewFilters?: string;
}

const getTabFromUrl = (url: string): DashboardTab => {
  if (!url) {
    return undefined;
  }
  const urlWithoutQuery = url.split('?')[0];
  const segments = urlWithoutQuery.split('/');
  return segments[2] as DashboardTab;
};

const getDefaultTab = (): DashboardTab => {
  if (wpEnvironment.userRole === 'admin') {
    return DashboardTab.LIVE_VIEW;
  }
  return DashboardTab.MAP_VIEW;
};

export const dashboardRoute: PortalRoute<DashboardRouteData> = {
  path: '',
  getHref(data?: DashboardRouteData): string {
    return `${dashboardRootPath}/${getDefaultTab()}`;
  },
  isOnRoute: (state: RouterStateUrl): boolean => {
    return state.url.startsWith(`/${dashboardRootPath}`);
  },
  parse: (state: RouterStateUrl): DashboardRouteData => {
    const data: DashboardRouteData = {
      tab: getTabFromUrl(state.url) || getDefaultTab(),
      mapViewType: getNthUrlSegment(state.url, 3) as MapViewType,
    };
    return data;
  },
  request: (
    data: DashboardRouteData = {},
    queryParamStrategy?: QueryParamsHandlingStrategy,
    replaceUrl?,
  ): NavigationRequest => {
    const tab = data.tab || getDefaultTab();
    const req: NavigationRequest = {
      path: [`/${dashboardRootPath}`, tab],
    };
    if (data.mapViewType) {
      req.path.push(data.mapViewType);
    }
    req.query = {
      mapViewFilters: data.mapViewFilters,
    };
    if (queryParamStrategy) {
      req.extras = {
        queryParamsHandling: queryParamStrategy,
      };
    }
    if (typeof replaceUrl === 'boolean') {
      req.extras = {
        ...(req.extras || {}),
        replaceUrl,
      };
    }
    return req;
  },
};

@Injectable({
  providedIn: 'root',
})
export class DashboardRouterService extends PortalRouterService<DashboardRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route = dashboardRoute;
}
