import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, filter, map, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';
import { Observable, of, timer } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { State } from '@rootStore';
import { SchoolEmployeeApiService } from '../../services/school-employee-api.service';
import * as fromDataActions from '../actions/employee-data.actions';
import * as fromActions from '../actions/employee-list.actions';
import * as fromSelectors from '../selectors/employee-list.selectors';
import * as fromTypes from '../../types';
import { updatedList } from '../actions/employee-list.actions';
import { getListActive } from '../selectors/employee-list.selectors';
import { EmployeeListState } from '../../../employees-vendor/store/reducers/employee-list.reducer';

@Injectable()
export class EmployessListEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private apiService: SchoolEmployeeApiService,
  ) {
    this.createLoadListEffects();
  }

  public listChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.initializedList,
        fromActions.staticFiltersChanged,
        fromActions.filtersChanged,
        fromActions.pageChanged,
        fromActions.pageSizeChanged,
        fromActions.statusChanged,
        fromActions.searchChanged,
      ),
      switchMap((action) => {
        const { listName } = action;
        return this.store.select(fromSelectors.getListState, { listName }).pipe(
          take(1),
          map((list) => {
            const snapshot: fromTypes.EmployeeListSnapshot = this.getSnapshotFromListState(list);
            return fromActions.employeeLoadListRequested({
              listName,
              snapshot,
            });
          }),
        );
      }),
    ),
  );

  /**
   * Updated list (e.g. employee was added)
   */
  public employeeListUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatedList),
      switchMap((action) => {
        const { listName } = action;
        return this.store.select(getListActive, { listName }).pipe(
          take(1),
          filter((isActive) => isActive),
          switchMap(() => this.onEmployeeListUpdated(listName)),
        );
      }),
    ),
  );

  /**
   * Yard list item initialized
   * Load yard details with debounce
   */
  public employeeCardInitialized$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.employeeListItemInitialized),
      mergeMap((action) => {
        const { employeeId } = action;
        return timer(fromTypes.ui.EMPLOYEE_LIST_LOAD_AFTER_CARD_INIT_MILLIS).pipe(
          takeUntil(
            this.actions$.pipe(
              ofType(fromActions.employeeListItemDestroyed),
              filter((a) => a.employeeId === employeeId),
            ),
          ),
          map(() => action),
        );
      }),
      map((action) => {
        const { employeeId } = action;
        return fromDataActions.loadEmployeeRequested({ employeeId });
      }),
    ),
  );

  private createLoadListEffects(): void {
    fromTypes.employeeLists.forEach((listName) => {
      this[`loadListRequested_${listName}`] = createEffect(() =>
        this.actions$.pipe(
          ofType(fromActions.employeeLoadListRequested),
          filter((action) => action.listName === listName),
          debounceTime(fromTypes.ui.EMPLOYEE_LIST_LOAD_DEBOUNCE_MILLIS),
          switchMap((action) => this.onLoadEmployeeListRequested(action.snapshot, action.listName)),
        ),
      );
    });
  }

  private onLoadEmployeeListRequested(
    snapshot: fromTypes.EmployeeListSnapshot,
    listName: fromTypes.EmployeeListName,
  ): Observable<Action> {
    const request = fromTypes.utils.getEmployeeListRequest(snapshot);
    return this.apiService.entityFilter(request).pipe(
      map((resp) => {
        const { total, items } = resp;
        return fromActions.employeeLoadListSuccess({
          listName,
          total,
          items,
        });
      }),
      catchError((err) => {
        console.log(err);
        return of(
          fromActions.employeeLoadListFailed({
            listName,
            error: {
              text: 'Failed to load yard list',
              originalError: err,
            },
          }),
        );
      }),
    );
  }

  private onEmployeeListUpdated(listName: fromTypes.EmployeeListName): Observable<Action> {
    return this.store.select(fromSelectors.getListState, { listName }).pipe(
      take(1),
      switchMap((listState) => {
        const snapshot = this.getSnapshotFromListState(listState);
        return this.onLoadEmployeeListRequested(snapshot, listName);
      }),
    );
  }

  private getSnapshotFromListState(state: EmployeeListState): fromTypes.EmployeeListSnapshot {
    const snapshot: fromTypes.EmployeeListSnapshot = {
      page: state.pagination.page || 0,
      pageSize: state.pagination.pageSize,
      filters: [...(state.filters || []), ...(state.staticFilters || [])],
      search: state.currentSearch,
      status: state.status,
    };
    return snapshot;
  }
}
