import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';
import { StandbyDriverStatusResponse } from '@apiEntities';

const moduleKey = 'Data';
const submoduleKey = 'Drivers';

export const loadDriverRequested = createAction(
  `[${moduleKey}] [${submoduleKey}]: Load driver requested`,
  props<{ driverId: string; options: fromTypes.LoadDriverOptions }>(),
);

export const loadDriverRequestedFromDBRListItem = createAction(
  `[${moduleKey}] [${submoduleKey}]: Load driver requested from DBR list item`,
  props<{ driverId: string; options: fromTypes.LoadDriverOptions }>(),
);

export const loadDriverRequestedFromTimesheetChangeRequestItem = createAction(
  `[TimesheetChangeRequestItem]: Load driver requested`,
  props<{ driverId: string; options: fromTypes.LoadDriverOptions }>(),
);

export const loadDriverRequestedFromTimesheetChangeRequestDetails = createAction(
  `[TimesheetChangeRequestDetails]: Load driver requested`,
  props<{ driverId: string; options: fromTypes.LoadDriverOptions }>(),
);

export const loadDriverRequestedFromCreateTimesheetEntry = createAction(
  `[CreateTimesheetEntryEffects]: Load driver requested`,
  props<{ driverId: string; options: fromTypes.LoadDriverOptions }>(),
);

export const loadDriverSuccess = createAction(
  `[${moduleKey}] [${submoduleKey}]: Load driver success`,
  props<{
    driver: fromTypes.Driver;
    requestOptions: fromTypes.LoadDriverOptions;
    vehicle?: fromTypes.Vehicle;
    rating?: number;
    vendor?: fromTypes.Vendor;
    yard?: fromTypes.Yard;
    standby?: StandbyDriverStatusResponse;
  }>(),
);

export const loadDriverFailed = createAction(
  `[${moduleKey}] [${submoduleKey}]: Load driver failed`,
  props<{ driverId: string; error: fromTypes.WpError }>(),
);

export const getAvailableServiceAreasRequested = createAction(
  `[${moduleKey}] [${submoduleKey}]: Available service areas requested`,
);

export const getAvailableServiceAreasSuccess = createAction(
  `[${moduleKey}] [${submoduleKey}]: Available service areas success`,
  props<{ response: fromTypes.GetAvailableServiceAreasResponse }>(),
);

export const getAvailableServiceAreasFailed = createAction(
  `[${moduleKey}] [${submoduleKey}]: Available service areas failed`,
  props<{ error: fromTypes.WpError }>(),
);

export const getSecuritySettingsForDriverRequested = createAction(
  `[${moduleKey}] [${submoduleKey}]: Get security settings by driver requested`,
  props<{ driverId: string }>(),
);
// override enabled state of a driver login setting
// used to update state instantly after successful enabled toggle
export const setDriverLoginMethodEnabledState = createAction(
  `[${moduleKey}] [${submoduleKey}]: Set driver login method enabled state`,
  props<{ driverId: string; loginMethod: fromTypes.DriverLoginScheme; enabled: boolean }>(),
);

export const getSecuritySettingsForDriverSuccess = createAction(
  `[${moduleKey}] [${submoduleKey}]: Get security settings by driver success`,
  props<{ driverId: string; settings: fromTypes.DriverLoginSetting[] }>(),
);

export const getSecuritySettingsForDriverFailed = createAction(
  `[${moduleKey}] [${submoduleKey}]: Get security settings by driver failed`,
  props<{ driverId: string; error: fromTypes.WpError }>(),
);

export const deleteDraftDriverSuccess = createAction(
  `[${moduleKey}] [${submoduleKey}]: Delete draft driver success`,
  props<{ driverId: string }>(),
);
