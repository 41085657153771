import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { checkNotNull } from '@rootTypes/utils';

@Directive({
  selector: '[wpApplyClassOnSticky]',
})
export class ApplyClassOnStickyDirective implements AfterViewInit, OnDestroy {
  @Input() wpApplyClassOnSticky: string;
  @Input() rootSelector: string;
  // -1px - to trigger on stick to top (top: 0px), side margin is to ignore
  // scroll bars
  @Input() rootMargin = '-1px 20px 0px 20px';

  private observer: IntersectionObserver;
  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    this.initIntersectionObserver();
  }

  ngOnDestroy(): void {
    if (!this.observer) {
      return;
    }
    try {
      this.observer.unobserve(this.el.nativeElement);
      this.observer.disconnect();
    } catch (err) {
      console.log(err);
    }
  }

  private initIntersectionObserver(): void {
    let root: HTMLElement = null;
    if (this.rootSelector) {
      root = document.querySelector(this.rootSelector) || null;
    }
    checkNotNull(this.wpApplyClassOnSticky, 'wpApplyClassOnSticky should not be null');
    this.observer = new IntersectionObserver(
      ([e]) => {
        e.target.classList.toggle(this.wpApplyClassOnSticky, e.intersectionRatio < 1 && e.intersectionRatio > 0);
      },
      {
        rootMargin: this.rootMargin,
        threshold: [1],
        root,
      },
    );
    this.observer.observe(this.el.nativeElement);
  }
}
