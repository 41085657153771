import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import * as fromActions from '../actions/router.actions';
import * as fromSelectors from '../selectors/router.selectors';
import { Observable } from 'rxjs';
import { Router, UrlTree } from '@angular/router';

@Injectable()
export class RouterCommonFacade {
  constructor(
    private store: Store<State>,
    private router: Router,
  ) {}

  public getDisplayBackText(): Observable<string> {
    return this.store.select(fromSelectors.getDisplayBackText);
  }

  public getDefaultUrlTree(): UrlTree {
    return this.router.createUrlTree(['/']);
  }

  public onGoBack(count?: number): void {
    this.store.dispatch(fromActions.back({ count }));
  }
}
