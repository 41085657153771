import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-icon-location-pin-border',
  templateUrl: './icon-location-pin-border.component.html',
  styleUrls: ['./icon-location-pin-border.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconLocationPinBorderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
