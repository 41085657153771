<div class="icon-info" style="width: 4px; height: 20px">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g fill="none" fill-rule="evenodd">
      <circle cx="10" cy="10" r="9" stroke="#2486F7" stroke-width="2" />
      <path fill="#2486F7" d="M9 7H11V15H9zM9 4H11V6H9z" />
    </g>
  </svg>
</div>
