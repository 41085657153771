import { HHMMTimeString } from '../../../entities/common';

export const durationMinutesToHHMM = (durationMinutes?: number): HHMMTimeString => {
  if (typeof durationMinutes !== 'number') {
    return null;
  }
  const numberToString = (value: number): string => {
    return (value + '').padStart(2, '0');
  };
  if (durationMinutes <= 59) {
    return '00' + numberToString(durationMinutes);
  }
  const hours = Math.trunc(durationMinutes / 60);
  const minutes = durationMinutes - hours * 60;
  return numberToString(hours) + numberToString(minutes);
};
