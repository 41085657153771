import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import { PortalEntityType } from '@rootTypes';
import { homeGuardCheckAccountLoading, selectedAccountState } from '../store';
import { loadMultiAccountManagementOptionsRequested } from '../store/actions/multi-account-management.actions';
import { selectMultiAccountManagementEntityType } from '../store/selectors/multi-account-management.selectors';

export function multiAccountManagementOptionsGuardFn(): CanActivateFn {
  return async () => {
    const store = inject(Store);
    const authed = await userAuthed(store);
    if (authed) {
      const multiAccountEntityType = await getMultiAccountManagementEntityType(store);
      if (multiAccountEntityType) {
        store.dispatch(loadMultiAccountManagementOptionsRequested({ entityType: multiAccountEntityType }));
      }
    }
    return true;
  };
}

async function userAuthed(store: Store): Promise<boolean> {
  const obs = store.select(homeGuardCheckAccountLoading).pipe(
    filter((loading) => !loading),
    take(1),
    switchMap(() => {
      return store.select(selectedAccountState).pipe(take(1));
    }),
    map((state) => !!state?.data?.accountId),
  );
  return firstValueFrom(obs);
}

async function getMultiAccountManagementEntityType(store: Store): Promise<PortalEntityType> {
  const obs = store.select(selectMultiAccountManagementEntityType);
  return firstValueFrom(obs);
}
