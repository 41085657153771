import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getDisplayBackText, State } from '../../../../../store';
import * as fromSelectors from '../../selectors/campus-details/campus-details.selectors';
import * as fromActions from '../../actions/campus-details/campus-details.actions';
import { Observable } from 'rxjs';
import * as fromTypes from '../../../types';
import * as fromRootStore from '@rootStore';
import { PortalPermissionsFacade } from '../../../../../auth/store/facades/portal-permissions.facade';

@Injectable()
export class CampusDetailsFacade {
  constructor(
    private store: Store<State>,
    private permissionsFacade: PortalPermissionsFacade,
  ) {}

  public getCampusId$(): Observable<string> {
    return this.store.select(fromSelectors.getCampusId);
  }

  public getCampus$(): Observable<fromTypes.Campus> {
    return this.store.select(fromSelectors.getCampus);
  }

  public getDistrict$(): Observable<fromTypes.District> {
    return this.store.select(fromSelectors.getDistrict);
  }

  public getDistrictLoading$(): Observable<boolean> {
    return this.store.select(fromSelectors.getDistrictLoading);
  }

  public getCampusLoading$(): Observable<boolean> {
    return this.store.select(fromSelectors.getCampusLoading);
  }

  public getCampusRemoving$(): Observable<boolean> {
    return this.store.select(fromSelectors.getCampusRemoving);
  }

  public getCampusRemoved$(): Observable<boolean> {
    return this.store.select(fromSelectors.getCampusRemoved);
  }

  public getCampusError$(): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getCampusError);
  }

  public getDisplayBackText$(): Observable<string> {
    return this.store.select(getDisplayBackText);
  }

  public getTab$(): Observable<fromTypes.CampusDetailsTab> {
    return this.store.select(fromSelectors.getTab);
  }

  public onSetCampusId(campusId: string): void {
    this.store.dispatch(fromActions.setCampusId({ campusId }));
  }

  public onSetTab(tab: fromTypes.CampusDetailsTab): void {
    this.store.dispatch(fromActions.setTab({ tab }));
  }

  public onCloseDrawer(): void {
    this.store.dispatch(fromRootStore.drawerClosedSelected());
  }

  public onRemoveCampus(): void {
    this.store.dispatch(fromActions.removeCampusClicked());
  }

  public onEditCampusClicked(): void {
    this.store.dispatch(fromActions.editCampusClicked());
  }
}
