import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-icon-arrow-up',
  templateUrl: './icon-arrow-up.component.html',
  styleUrls: ['./icon-arrow-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconArrowUpComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
