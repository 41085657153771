import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

import { PlaceholderType } from '../types';
import { AbstractPopupComponent } from '../../../core/popupable/types';
import { PopupRef } from '../../../core/popupable/types/popup-ref';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { generateId, iconPaths } from '@rootTypes/utils';
import { Base64String } from '@apiEntities';
import { SnackbarService } from '../../snackbar/snackbar.service';

export interface MultiPhotoInputPopupConfig {
  imagePlaceholder: PlaceholderType;
  values: MultiPhotoInputPopupImageCategory[];
  popupTitle?: string;
  autoSelectedCategoryId?: string;
  loadImageFn?: (imageId: string) => Promise<Base64String>;
}

export interface MultiPhotoInputPopupImageCategory {
  categoryId: string;
  title: string;
  images: MultiPhotoInputPopupImage[];
  addBtnText?: string;
  maxImageCount?: number;
}

export interface MultiPhotoInputPopupImage {
  imageId: string; // id or imagePath
  imageBase64?: string;
}

export type MultiPhotoInputPopupOutput = MultiPhotoInputPopupImageCategoryOutput[];

export interface MultiPhotoInputPopupImageCategoryOutput {
  categoryId: string;
  imagesBase64: string[];
  added: { imageBase64: string }[];
  updated: { imageId: string; imageBase64: string }[];
  removed: { imageId: string }[];
  hasChanges: boolean;
}

interface ImageCategory {
  categoryId: string;
  title: string;
  addBtnText: string;
  images: Image[];
  maxImageCount: number;
  activeImageCount: number;
}

interface Image {
  categoryId: string;
  imageId: string;
  isChanged: boolean;
  isRemoved: boolean;
  outerImageId?: string; // absent for new images
  sourceBase64?: string;
  croppedBase64?: string;
  isImageData: boolean;
  canvasRotate: number; // try rotating with this
}

@Component({
  selector: 'wp-multi-photo-input-popup',
  templateUrl: './multi-photo-input-popup.component.html',
  styleUrls: ['./multi-photo-input-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiPhotoInputPopupComponent
  implements OnInit, AbstractPopupComponent<MultiPhotoInputPopupConfig, MultiPhotoInputPopupOutput>
{
  public popupRef: PopupRef<MultiPhotoInputPopupConfig, MultiPhotoInputPopupOutput>;
  public popupTitle: string;
  public imageCategories: ImageCategory[];
  public selectedImage?: Image;
  public correctedImage?: Image;
  public isImageLoading = false;
  public isImageLoadError = false;
  public isImageLoaded = false;
  public imageFileChangeEvent: any;
  public addIcon = iconPaths.PLUS_GEY;
  public isInitialLoading = false;

  @ViewChild('imageFileInput')
  private imageFileInput: ElementRef<HTMLInputElement>;

  private hasCorrectImageChanges = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private snackbar: SnackbarService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.popupTitle = this.popupRef.data.popupTitle || 'Upload image';

    let loadedImages: { [imageId: string]: MultiPhotoInputPopupImage };
    if (this.popupRef.data.loadImageFn) {
      loadedImages = await this.loadImages();
    }
    this.initImageCategories(loadedImages);

    let autoSelectedCategory: ImageCategory;
    if (this.popupRef.data.autoSelectedCategoryId) {
      autoSelectedCategory = this.imageCategories.find(
        ({ categoryId }) => categoryId === this.popupRef.data.autoSelectedCategoryId,
      );
    } else {
      autoSelectedCategory = this.imageCategories[0];
    }
    const firstImage = autoSelectedCategory.images[0];
    if (firstImage) {
      this.selectedImage = firstImage;
    }
  }

  public save(): void {
    const result: MultiPhotoInputPopupOutput = this.imageCategories.map((category) => {
      const imagesBase64: string[] = [];
      const added: { imageBase64: string }[] = [];
      const removed: { imageId: string }[] = [];
      const updated: { imageId: string; imageBase64: string }[] = [];

      category.images.forEach((image) => {
        if (image.isRemoved) {
          if (image.outerImageId) {
            removed.push({ imageId: image.imageId });
          }
          return;
        }
        const imageBase64 = image.croppedBase64 || image.sourceBase64;
        if (imageBase64) {
          imagesBase64.push(imageBase64);
        }
        if (!image.outerImageId && image.croppedBase64) {
          added.push({ imageBase64: image.croppedBase64 });
          return;
        }
        if (image.isChanged && image.croppedBase64) {
          updated.push({ imageId: image.imageId, imageBase64: image.croppedBase64 });
        }
      });

      return {
        categoryId: category.categoryId,
        imagesBase64,
        added,
        updated,
        removed,
        hasChanges: !!added.length || !!removed.length || !!updated.length,
      };
    });

    this.popupRef.close(result);
  }

  public close(): void {
    this.popupRef.close();
  }

  public selectImage(image: Image): void {
    if (!this.isImageLoading) {
      image.croppedBase64 = undefined;
      this.selectedImage = image;
    }
  }

  public addImage(categoryId: string): void {
    if (this.isImageLoading) {
      return;
    }
    const category = this.imageCategories.find((category) => category.categoryId === categoryId);
    this.selectedImage = {
      categoryId: category.categoryId,
      imageId: generateId(''),
      isChanged: false,
      isRemoved: false,
      isImageData: false,
      canvasRotate: 0,
    };
    category.images.push(this.selectedImage);
    category.activeImageCount++;
    this.imageFileInput.nativeElement.click();
  }

  public onImageFileChange(event: any): void {
    const filePath = event.target.value;
    if (!filePath) {
      // user clicked Cancel on "Select a file" dialog
      return;
    }
    this.isImageLoadError = false;
    this.isImageLoaded = false;
    this.isImageLoading = true;
    this.imageFileChangeEvent = event;
    this.selectedImage.croppedBase64 = undefined;
    if (this.selectedImage.outerImageId) {
      this.selectedImage.isChanged = true;
    }
  }

  public onImageLoaded(): void {
    this.isImageLoaded = true;
    this.isImageLoading = false;
    this.isImageLoadError = false;
  }

  public onLoadImageFailed(): void {
    this.isImageLoaded = false;
    this.isImageLoading = false;
    this.isImageLoadError = true;
  }

  public async onImageCropped(event: ImageCroppedEvent): Promise<void> {
    const image = this.selectedImage;
    if (image.croppedBase64 && image.outerImageId) {
      this.selectedImage.isChanged = true;
    }
    this.selectedImage.croppedBase64 = event.base64;
    this.selectedImage.isImageData = true;
    if (!this.selectedImage.sourceBase64) {
      this.selectedImage.sourceBase64 = this.selectedImage.croppedBase64;
    }
    this.cdRef.detectChanges();
  }

  public removeImage(): void {
    this.imageFileInput.nativeElement.value = '';
    this.imageFileChangeEvent = undefined;

    const category = this.imageCategories.find((category) => category.categoryId === this.selectedImage.categoryId);
    category.activeImageCount--;
    this.selectedImage.sourceBase64 = undefined;
    this.selectedImage.croppedBase64 = undefined;
    this.selectedImage.isChanged = false;
    this.selectedImage.isRemoved = true;
    this.selectedImage.isImageData = false;
    this.selectedImage = undefined;
    this.isImageLoaded = false;
  }

  public correctImage(): void {
    this.hasCorrectImageChanges = false;
    this.imageFileChangeEvent = undefined;
    this.correctedImage = this.selectedImage;
  }

  public onCorrectImageChange(): void {
    this.hasCorrectImageChanges = true;
  }

  public onCorrectImageClose(): void {
    this.correctedImage = undefined;
    this.hasCorrectImageChanges = false;
  }

  public onCorrectImageBeforeClose(event: Event): void {
    if (this.hasCorrectImageChanges && !confirm('Are you sure? Your changes will be lost...')) {
      event.preventDefault();
    }
  }

  public onCorrectImageSave(base64: string): void {
    this.hasCorrectImageChanges = false;
    this.selectedImage.croppedBase64 = base64;
    this.selectedImage.sourceBase64 = base64;
    this.selectedImage.isImageData = true;
    this.selectedImage.canvasRotate = 0;
    if (this.selectedImage.outerImageId) {
      this.selectedImage.isChanged = true;
    }
    this.correctedImage = undefined;
    this.hasCorrectImageChanges = false;
    this.selectImage(this.selectedImage);
  }

  public rotateImage(): void {
    this.selectedImage.canvasRotate++;
  }

  private async loadImages(): Promise<{ [imageId: string]: MultiPhotoInputPopupImage }> {
    try {
      this.isInitialLoading = true;
      const promises: Promise<Base64String>[] = [];
      const imageIndexToIdMap: { [index: number]: string } = {};
      this.popupRef.data.values.forEach((item) => {
        item.images.forEach(({ imageId }) => {
          promises.push(this.popupRef.data.loadImageFn(imageId));
          imageIndexToIdMap[promises.length - 1] = imageId;
        });
      });
      const resolved: Base64String[] = await Promise.all(promises);
      const result: { [imageId: string]: MultiPhotoInputPopupImage } = {};
      resolved.forEach((imageBase64, index) => {
        const imageId = imageIndexToIdMap[index];
        result[imageId] = { imageId, imageBase64 };
      });
      return result;
    } catch (error) {
      this.snackbar.error('Failed to load one or more images', 4000);
      return {};
    } finally {
      this.isInitialLoading = false;
      this.cdRef.detectChanges();
    }
  }

  private initImageCategories(loadedImages: { [imageId: string]: MultiPhotoInputPopupImage } = {}): void {
    this.imageCategories = this.popupRef.data.values.map((item) => {
      const { categoryId, title, images, maxImageCount, addBtnText } = item;
      return {
        categoryId,
        title,
        maxImageCount: typeof maxImageCount === 'number' ? maxImageCount : Infinity,
        activeImageCount: images.length,
        addBtnText: addBtnText || 'Add image',
        images: images.map(({ imageId, imageBase64 }): Image => {
          const sourceBase64 = imageBase64 || loadedImages[imageId]?.imageBase64;
          return {
            categoryId,
            outerImageId: imageId,
            imageId: imageId,
            sourceBase64,
            isImageData: !!sourceBase64,
            isChanged: false,
            isRemoved: false,
            canvasRotate: 0,
          };
        }),
      };
    });
  }
}
