import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-icon-employee',
  templateUrl: './icon-employee.component.html',
  styleUrls: ['./icon-employee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconEmployeeComponent implements OnInit {
  @Input() public green: boolean;

  constructor() {}

  ngOnInit(): void {}
}
