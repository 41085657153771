import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SmartSelect } from '../../../models';
import { SmartInputStudentSchoolInfoService } from '../../../services';

@Component({
  selector: 'wp-smart-input-student-school-info-district',
  templateUrl: './smart-input-student-school-info-district.component.html',
  styleUrls: ['./smart-input-student-school-info-district.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartInputStudentSchoolInfoDistrictComponent implements OnInit {
  public model: SmartSelect;

  constructor(private service: SmartInputStudentSchoolInfoService) {}

  public ngOnInit(): void {
    this.model = this.service.district;
  }
}
