import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { getDisplayActivityType } from '../../data/timesheet-entries/selectors';

@Pipe({
  name: 'displayActivityType',
})
export class DisplayActivityTypePipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(value: string): Observable<string> {
    return this.store.select(getDisplayActivityType(value));
  }
}
