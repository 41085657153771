import { createSelector } from '@ngrx/store';
import { getFeature } from './district-data-feature.selector';

const getDistrictEditorState = createSelector(getFeature, (state) => state.editDistrict);

export const getEditedDistrictId = createSelector(getDistrictEditorState, (state) => state.districtId);

export const getAddEditDistrictLoading = createSelector(getDistrictEditorState, (state) => state.isLoading);

export const getAddEditDistrictSuccess = createSelector(getDistrictEditorState, (state) => state.isSuccess);

export const getAddEditDistrictError = createSelector(getDistrictEditorState, (state) => state.error);
