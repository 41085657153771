import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import * as fromTypes from '../types';
import * as fromApi from '../../../api';
import { ImageApiService } from '../../../api';
import { map } from 'rxjs/operators';
import { ApiService } from '../../../api/api.service';
import { entitySearchResultToPortalEntity } from '@rootTypes/utils/portal-entity/entity-search-result-to-portal-enity';

interface LoadListResponse {
  total: number;
  items: fromTypes.PortalEntity[];
}

interface LoadEmployeeResponse {
  employee: fromTypes.VendorEmployee;
  permissions?: fromTypes.EmployeePermission[];
}

@Injectable()
export class EmployeesApiService {
  constructor(
    private apiService: ApiService,
    private imageApi: ImageApiService,
  ) {}

  public getEmployee(employeeId: string, options?: fromTypes.LoadEmployeeOptions): Observable<LoadEmployeeResponse> {
    const request: fromApi.getVendorEmployee.GetVendorEmployeeRequest = {
      vendorEmployeeId: employeeId,
    };
    const projections = [];
    if (options?.withPermissions) {
      projections.push(fromApi.getVendorEmployee.VendorEmployeeProjection.permission);
    }
    if (projections.length) {
      request._projections = projections;
    }
    return this.apiService.getVendorEmployee(request).pipe(
      map((res) => {
        const result: LoadEmployeeResponse = {
          employee: fromApi.getVendorEmployee.convertors.getEmployeeFromApiVendorEmployee(res.vendorEmployee),
        };
        if (res._projections && res._projections?.permission) {
          let permissions = res._projections.permission.map((source) => {
            return {
              entityId: source.yardId,
            };
          });
          if (res._projections.yards) {
            permissions = permissions.map((source) => {
              return {
                ...source,
                entityName: res._projections.yards.find((yard) => yard.id === source.entityId)?.name,
              };
            });
          }
          result.permissions = permissions;
        }
        return result;
      }),
    );
  }

  public entityFilter(request: fromApi.entityFilter.EntityFilterRequest): Observable<LoadListResponse> {
    return this.apiService.entityFilter(request).pipe(
      map((resp) => {
        return {
          total: resp.total,
          items: (resp.results || []).map((source) => entitySearchResultToPortalEntity(source)),
        };
      }),
    );
  }

  public getPermissionOptions(request: fromApi.entityFilter.EntityFilterRequest): Observable<fromTypes.PortalEntity[]> {
    return this.apiService.entityFilter(request).pipe(
      map((resp) => {
        return (resp.results || []).map((source) => entitySearchResultToPortalEntity(source));
      }),
    );
  }

  public createEmployee(
    request: fromApi.createVendorEmployee.CreateVendorEmployeeRequest,
  ): Observable<fromTypes.VendorEmployee> {
    return this.apiService.createVendorEmployee(request).pipe(
      map((resp) => {
        return fromApi.getVendorEmployee.convertors.getEmployeeFromApiVendorEmployee(resp?.vendorEmployee);
      }),
    );
  }

  public updateEmployeePermissions(
    request: fromApi.updateVendorEmployeePermissions.UpdateVendorEmployeePermissionsRequest,
  ): Observable<fromApi.updateVendorEmployeePermissions.UpdateVendorEmployeePermissionsResponse> {
    return this.apiService.updateVendorEmployeePermissions(request);
  }

  public updateEmployee(
    request: fromApi.updateVendorEmployee.UpdateVendorEmployeeRequest,
  ): Observable<fromTypes.VendorEmployee> {
    return this.apiService.updateVendorEmployee(request).pipe(
      map((res) => {
        return fromApi.getVendorEmployee.convertors.getEmployeeFromApiVendorEmployee(res.vendorEmployee);
      }),
    );
  }

  public uploadPhoto(base64: string): Observable<string> {
    return this.imageApi.uploadImage(base64);
  }
}
