import { CalendarDate as DbCalendarDate, GetRouteCalendarResponse } from '../get-route-calendar-response';
import { CalendarDate, RouteCalendar, RouteScheduleItem } from 'src/app/types/entities/route';
import { Weekday } from '@rootTypes';
import { RouteScheduleSummary } from '../../get-route-calendar-v2';

// const mockExclusions = ['Sep 6', 'Oct 15-16', 'Nov 1-3', 'Dec 4'];

// const mockCampusHolidaysList: CampusHolidays[] = [
//   {
//     campusName: 'Main Campus [mock]',
//     holidays: [
//       {
//         dateLabels: ['Sep 7'],
//         name: 'Labor Day'
//       },
//       {
//         dateLabels: ['Nov 28 - 30'],
//         name: 'Thanksgiving break'
//       },
//       {
//         dateLabels: ['Dec 24 - Dec 27', 'Dec 31 - Jan 3'],
//         name: 'Winter break'
//       },
//     ]
//   },
//   {
//     campusName: 'Middle School Campus [mock]',
//     holidays: [
//       {
//         dateLabels: ['Nov 28 - 30'],
//         name: 'Thanksgiving break<br>second line<br>third line'
//       },
//     ]
//   },
//   {
//     campusName: 'High School Campus [mock]',
//     holidays: [
//       {
//         dateLabels: ['Nov 28 - 30'],
//         name: 'Thanksgiving break'
//       },
//       {
//         dateLabels: ['Dec 24 - Dec 27', 'Dec 31 - Jan 3'],
//         name: 'Winter break'
//       },
//     ]
//   },

// ];

// const mockScheduleSummary: RouteScheduleSummary = {
//   days: {
//     monday: {value: '9:00 AM'},
//     wednesday: {value: '2:00 PM'},
//     friday: {value: '5:00 PM'}
//   },
//   exceptions: [
//     {
//       dateLabels: ['Sep 6'],
//       timeLabel: '3:00 PM',
//       name: 'Early leave before Labor Day [mock]<br>Second line<br>Third line'
//     },
//     {
//       dateLabels: ['Oct 15-16', 'Dec 31 - Jan 10'],
//       timeLabel: '11:30 AM',
//       name: 'Teacher\'s meeting [mock]'
//     }
//   ]
// };

export const scheduleSummaryToSchedule = (source?: RouteScheduleSummary): RouteScheduleItem[] => {
  const weekdays = [
    Weekday.SUNDAY,
    Weekday.MONDAY,
    Weekday.TUESDAY,
    Weekday.WEDNESDAY,
    Weekday.THURSDAY,
    Weekday.FRIDAY,
    Weekday.SATURDAY,
  ];
  if (!source || !source.days) {
    return weekdays.map((weekday) => {
      return { weekday };
    });
  }
  return weekdays.map((weekday) => {
    return {
      weekday,
      time: source.days[weekday] ? source.days[weekday].value : undefined,
    };
  });
};

export const dbToRouteCalendar = (source: GetRouteCalendarResponse): RouteCalendar => {
  const result: RouteCalendar = {
    calendarId: source.calendarId,
    startDate: source.startDate,
    endDate: source.endDate,
    runDates: source.dates ? source.dates.filter((d) => d.isSelected).map((d) => d.date) : [],
    calendarDates: getCaledarDatesFromCalendar(source.dates),
    exclusions: source.exclusions || [],
    campusHolidaysList: source.campusHolidaysList || [],
    schedule: scheduleSummaryToSchedule(source.scheduleSummary),
    scheduleExceptions: source.scheduleSummary?.exceptions ? source.scheduleSummary.exceptions : [],
  };
  return result;
};

function getCaledarDatesFromCalendar(source: DbCalendarDate[]): CalendarDate[] {
  if (!source) {
    return [];
  }
  return source.map((sourceDate) => {
    return {
      date: sourceDate.date,
      tooltip: sourceDate.label,
      color: (sourceDate.isSelected ? sourceDate.color.selected : sourceDate.color.unselected) as any,
    };
  });
}
