import { Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { filter, take, switchMap, map } from 'rxjs/operators';

import * as fromApi from '../../api';
import { AccountState } from '../store/reducers';
import * as fromSelectors from '../store/selectors';
import { State } from '../../store';
import * as fromActions from '../store/actions';

export const loadAccount = (
  store: Store<AccountState | State>,
  getAccountAction: fromActions.GetAccountActionCreator,
): Observable<fromApi.account.SelectedAccount> => {
  return store.select(fromSelectors.selectedAccountState).pipe(
    filter((state) => !state.isLoading),
    take(1),
    switchMap((state) => {
      if (state.data) {
        return of(state.data);
      }
      store.dispatch(getAccountAction());
      return store.select(fromSelectors.selectedAccountState).pipe(
        filter((accountState) => !accountState.isLoading && !!accountState.data),
        take(1),
        map((accountState) => accountState.data),
      );
    }),
  );
};
