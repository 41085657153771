import { TransportationRequestListState } from '../types/transportation-request-list';

export const createInitialTransportationRequestListState = (): TransportationRequestListState => {
  return {
    params: {
      page: 0,
      pageSize: 10,
      filters: [],
      searchQuery: undefined,
      status: undefined,
      listByEntity: undefined,
      selectedItemId: undefined,
    },
    api: { isLoading: false, error: undefined, items: [], total: undefined },
  };
};
