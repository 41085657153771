import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GotoPageComponent } from './components/goto-page/goto-page.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatAutocomplete, MatAutocompleteTrigger, MatOptgroup, MatOption } from '@angular/material/autocomplete';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { ComponentsModule } from '../../shared/components/components.module';

@NgModule({
  declarations: [GotoPageComponent],
  imports: [
    CommonModule,
    MatFormField,
    MatAutocomplete,
    MatOptgroup,
    MatAutocompleteTrigger,
    MatOption,
    ReactiveFormsModule,
    MatInput,
    MatLabel,
    ComponentsModule,
  ],
  exports: [GotoPageComponent],
})
export class GotoPageModule {}
