import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, filter, map, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';
import * as fromListActions from '../actions/student-list.actions';
import * as fromDataActions from '../actions/student-data.actions';
import * as fromListSelectors from '../selectors/student-list.selectors';
import * as fromTypes from '../../types';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { State } from '@rootStore';
import { Observable, of, timer } from 'rxjs';
import { StudentsApiService } from '../../services/students-api.service';

@Injectable()
export class StudentListEffects {
  constructor(
    private actions$: Actions,
    private apiService: StudentsApiService,
    private store: Store<State>,
  ) {}

  public listControlsChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromListActions.setStudentListState,
        fromListActions.studentSearchInputChanged,
        fromListActions.studentStatusChanged,
        fromListActions.setStaticFilters,
        fromListActions.currentPageChanged,
        fromListActions.pageSizeChanged,
      ),
      map((action) => action.listName),
      switchMap((listName) => {
        return this.store.select(fromListSelectors.getStudentListState, { listName }).pipe(
          take(1),
          map((state) => {
            return fromListActions.studentListLoadRequested({
              listName,
              request: {
                studentSearchQuery: state.currentSearchQuery || undefined,
                currentPage: state.pagination.currentPage,
                filters: [...state.filters, ...state.staticFilters],
                pageSize: state.pagination.pageSize,
                listStatus: state.selectedStudentStatus,
              },
            });
          }),
        );
      }),
    ),
  );

  public loadListRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromListActions.studentListLoadRequested),
      debounceTime(200),
      switchMap((action) => {
        return this.loadStudentList(action.listName, action.request);
      }),
    ),
  );

  // load student details with debounce,
  // quit, if the card was destroyed
  public studentCardInitialized$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromListActions.studentCardInitialized),
      map((action) => action.studentId),
      mergeMap((studentId) => {
        return timer(1200).pipe(
          takeUntil(
            this.actions$.pipe(
              ofType(fromListActions.studentCardDestroyed),
              filter((action) => action.studentId === studentId),
            ),
          ),
          map(() => studentId),
        );
      }),
      map((studentId) => {
        this.store.dispatch(
          fromDataActions.loadStudentDetailsRequested({
            studentId,
            options: { withRouteReadiness: true, withRoutes: true },
          }),
        );
        return fromDataActions.getStudentVerificationStatusRequestedFromList({
          studentId,
        });
      }),
    ),
  );

  private loadStudentList(
    listName: fromTypes.StudentListName,
    snapshot: fromTypes.StudentListStateSnapshot,
  ): Observable<Action> {
    const apiRequest = fromTypes.utils.studentListStateSnapshotToRequest(snapshot);
    return this.apiService.entityFilter(apiRequest).pipe(
      map((res) =>
        fromListActions.studentListLoadSuccess({
          listName,
          studentIds: res.items,
          totalFound: res.totalFound,
        }),
      ),
      catchError((err) => {
        console.log(err);
        return of(
          fromListActions.studentListLoadFailed({
            listName,
            error: {
              text: 'Failed to load student list',
            },
          }),
        );
      }),
    );
  }
}
