<div class="icon" [ngClass]="{ 'rotate-down': rotateDown }">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="#636365" fill-rule="evenodd">
      <path
        d="M14.851 6.906c.02-.294-.089-.594-.329-.82L8.91.827C8.469.41 7.75.413 7.313.823l.224-.21c-.44.413-.44 1.083.001 1.497l5.103 4.784-5.103 4.784c-.441.414-.439 1.087-.001 1.497l-.224-.21c.44.413 1.155.413 1.597-.001l5.612-5.262c.234-.219.343-.51.33-.797z"
        transform="matrix(-1 0 0 1 16 1)"
      />
      <path
        d="M8.441 6.906c.02-.294-.09-.594-.33-.82L2.5.827C2.058.41 1.34.413.902.823l.225-.21c-.441.413-.44 1.083 0 1.497l5.104 4.784-5.103 4.784c-.441.414-.439 1.087-.001 1.497l-.225-.21c.441.413 1.156.413 1.598-.001l5.612-5.262c.233-.219.343-.51.329-.797z"
        transform="matrix(-1 0 0 1 16 1)"
      />
    </g>
  </svg>
</div>
