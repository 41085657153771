<div class="label-down-value-wrap">
  <div class="label" [ngStyle]="{'margin-bottom': labelValueMargin }">
    <wp-grey-label>{{ label }}</wp-grey-label>
  </div>
  <div class="value">
    <wp-field-value>
      <span *ngIf="!isHardCoded">
        <span [innerHTML]="valueHTML"></span>
      </span>
      <span *ngIf="isHardCoded" wpHardCoded>
        <span [innerHTML]="valueHTML"></span>
      </span>
    </wp-field-value>
  </div>
</div>
