<wp-page>
  <wp-page-top>
    <wp-page-top-title>Access denied</wp-page-top-title>
  </wp-page-top>
  <wp-page-body>
    <div class="content">
      <div class="instructions">
        You don't have permission to access this content.
      </div>
      <div class="instructions">
        Please contact your administrator for further instructions.
      </div>
      <wp-btn-primary class="go-home-btn" (clicked)="goHome()">
        Go home
      </wp-btn-primary>
    </div>
  </wp-page-body>
</wp-page>
