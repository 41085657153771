import { EntitySearchResult } from '@apiEntities/entity-search';
import { ExtendedPortalEntity, PortalEntity, PortalEntityType } from '@rootTypes';
import { entitySearchConfig } from '@rootTypes/utils/portal-entity/entity-search-config';

export const entitySearchResultToPortalEntity = (source: EntitySearchResult): PortalEntity => {
  if ('transformer' in entitySearchConfig[source.type]) {
    return entitySearchConfig[source.type].transformer(source as any);
  }
  const entityIdField = entitySearchConfig[source.type].idField;
  const id = source[entityIdField] as string;
  const label = 'label' in source ? source.label : '';
  return {
    ...source,
    entityId: id,
    type: source.type,
    label,
  };
};

export const entitySearchResultToExtendedPortalEntity = <T extends PortalEntityType>(
  source: EntitySearchResult,
): ExtendedPortalEntity<T> => {
  return entitySearchResultToPortalEntity(source) as ExtendedPortalEntity<T>;
};
