import { Observable } from 'rxjs';

export interface ImageSizePx {
  width: number;
  height: number;
}
export const getImageNaturalSize = (imgBase64: string): Observable<ImageSizePx> => {
  return new Observable<ImageSizePx>((observer) => {
    const img = new Image();
    img.onload = () => {
      observer.next({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
      observer.complete();
    };
    img.onerror = (err) => {
      observer.error(err);
    };
    img.src = imgBase64;
  });
};
