<wp-entity-filter
  [searchByType]="searchByType"
  [searchInputControl]="searchInputControl"
  [placeholder]="label"
  [optionTemplate]="optionTemplate"
  [entityStatus]="entityStatus"
  [disabled]="disabled"
  [resetSelectedOnInputClear]="true"
  [fillInputOnSelect]="true"
  [required]="required"
  [isSearchGlassIcon]="isSearchGlassIcon"
  [tabIndex]="tabIndex"
  [autofocus]="autofocus"
  (selected)="onOptionSelect($event)"
  (inputCleared)="onOptionSelect(undefined)"
></wp-entity-filter>

<ng-template #optionTemplate let-entity="entity">
  <div class="wp-vendor-select-option">
    <div class="vendor-name">{{entity.label}}</div>
    <!-- <div class="vendor-id">Vendor ID</div> -->
  </div>
</ng-template>
