import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { setInitialSideBarExpand, State, xLargeScreen } from '@rootStore';
import { Store } from '@ngrx/store';
import * as fromUIStore from '../../../store';
import { asyncScheduler, Observable, scheduled, Subscription } from 'rxjs';
import { map, startWith, tap, withLatestFrom } from 'rxjs/operators';

import { BreakpointObserver } from '@angular/cdk/layout';
import { SessionService } from 'src/app/api/services/api/session.service';
import { homePageInitialized, homePageAutoLogoutRequested, isAutoLogoutPossible } from 'src/app/auth/store';
import { LocalStorageService } from '../../../core/services';
import { getDefaultDistrictGradesRequested } from '../../../data/common/store/actions/default-district-grades.actions';

@Component({
  selector: 'wp-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, OnDestroy {
  public sectionLoading$: Observable<boolean>;
  public homePageSidePadding$: Observable<string>;

  private screenChangedSubscription!: Subscription;
  private screenSubscription!: Subscription;
  private sessionEndSubscription: Subscription;

  constructor(
    private store: Store<State>,
    private breakpointObserver: BreakpointObserver,
    private session: SessionService,
    private location: Location,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(homePageInitialized());
    this.sectionLoading$ = this.store.select(fromUIStore.sectionLoading).pipe(startWith(true));
    this.homePageSidePadding$ = this.store.select(fromUIStore.homePageSidePadding);

    this.screenChangedSubscription = this.breakpointObserver
      .observe('(max-width: 2020px)')
      .pipe(map((data) => !data.matches))
      .subscribe((isXLarge) => {
        this.store.dispatch(fromUIStore.screenXLargeChanged({ isXLarge }));
      });

    this.screenSubscription = scheduled(this.store.select(xLargeScreen), asyncScheduler).subscribe((largeScreen) => {
      const defaultForScreen = largeScreen;
      const initFromLocalStorage = this.localStorageService.get('sidebarExpanded', false);
      if (initFromLocalStorage === null) {
        this.store.dispatch(setInitialSideBarExpand({ isExpanded: defaultForScreen }));
      } else {
        this.store.dispatch(setInitialSideBarExpand({ isExpanded: initFromLocalStorage === 'true' }));
      }
    });
    if (wpEnvironment.userRole === 'admin' || wpEnvironment.userRole === 'enterprise') {
      this.store.dispatch(getDefaultDistrictGradesRequested());
    }

    this.sessionEndSubscription = this.session.sessionEnd$
      .pipe(
        withLatestFrom(this.store.select(isAutoLogoutPossible)),
        tap(([_, isLogoutPossible]) => {
          if (isLogoutPossible) {
            this.store.dispatch(
              homePageAutoLogoutRequested({
                pathToEnterAfterLogin: this.location.path(),
              }),
            );
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    if (this.screenChangedSubscription) {
      this.screenChangedSubscription.unsubscribe();
    }
    if (this.screenSubscription) {
      this.screenSubscription.unsubscribe();
    }
    if (this.sessionEndSubscription) {
      this.sessionEndSubscription.unsubscribe();
    }
  }
}
