import { createSelector } from '@ngrx/store';
import { getFeatureState } from './feature-selector';
import * as fromTypes from '../../types';
import * as fromReducer from '../reducers/campus-list.reducers';

const getLists = createSelector(getFeatureState, (state) => state.lists);

export const getCampusListState = createSelector<any, any, any, fromReducer.CampusListState>(
  getLists,
  (state, props: { listName: fromTypes.CampusListName }) => state[props.listName],
);

export const getSearchQuery = createSelector(getCampusListState, (state) => state.currentSearchQuery);

export const getSelectedCampusStatus = createSelector(getCampusListState, (state) => state.selectedCampusStatus);

export const getPageState = createSelector(getCampusListState, (state) => state.page);

export const getListCampusIds = createSelector(getPageState, (state) => state.listCampusIds);

export const getListLoading = createSelector(getPageState, (state) => state.isLoading);

export const getListTotalFound = createSelector(getPageState, (state) => state.totalFound);

export const getListError = createSelector(getPageState, (state) => state.listError);

export const getPaginationState = createSelector(getCampusListState, (state) => state.pagination);

export const getCurrentPage = createSelector(getPaginationState, (state) => state.currentPage);

export const getPageSize = createSelector(getPaginationState, (state) => state.pageSize);

export const getSelectedCampusId = createSelector(getCampusListState, (state) => state.selectedCampusId);
