import { Routes } from '@angular/router';
import { getPortalFeatures } from '../get-portal-features';

const featureRoutes: Routes = getPortalFeatures().map((feature) => {
  return {
    path: feature.path,
    loadChildren: feature.loadChildren,
  };
});

export const getFeatureRoutesWithPrefix = (prefix?: string): Routes => {
  return featureRoutes.map((sourceRoute) => {
    const res = {
      ...sourceRoute,
      path: prefix ? `${prefix}/${sourceRoute.path}` : sourceRoute.path,
    };
    return res;
  });
};
