import { Injectable } from '@angular/core';
import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';
import { vehicleInspectionsRootPath } from './vehicle-inspections-root-path';
import {
  VehicleInspectionAssignedMechanicFilter,
  VehicleInspectionDriverReviewStatus,
  VehicleInspectionMechanicAssessmentStatus,
  VehicleInspectionStatus,
} from '@apiEntities';
import { SmartDateRangeValue } from '../../../shared/smart-forms';
import {
  mechanicAssignmentToQueryParam,
  driverReviewStatusToQueryParam,
  mechanicAssessmentStatusToQueryParam,
  queryParamToMechanicAssignment,
  queryParamToDriverReviewStatus,
  queryParamToMechanicAssessmentStatus,
  queryParamToQuickFilter,
  queryParamToVehicle,
  queryParamToVehicleInspectionStatus,
  quickFilterToQueryParam,
  vehicleInspectionStatusToQueryParam,
  vehicleToQueryParam,
} from './utils';
import { VehicleInspectionQuickFilter } from '../../../data/vehicle-inspections/types';
import { inspectionsRootPath } from '../inspections/inspections-path';
import { InspectionsTab } from '@rootTypes/entities/inspections/inspections-tab';

const statePath = vehicleInspectionsRootPath;

export interface VehicleInspectionListRouterData {
  dateRange?: SmartDateRangeValue;
  pageIndex?: number;
  pageSize?: number;
  vehicles?: { vehicleId: string; vehicleDisplayId: string }[];
  driverIds?: string[];
  assignedMechanicIds?: VehicleInspectionAssignedMechanicFilter[];
  inspectionStatus?: VehicleInspectionStatus[];
  mechanicAssessmentStatus?: VehicleInspectionMechanicAssessmentStatus[];
  driverReviewStatus?: VehicleInspectionDriverReviewStatus[];
  quickFilter?: VehicleInspectionQuickFilter;
}

export const vehicleInspectionListRoute: PortalRoute<VehicleInspectionListRouterData> = {
  path: statePath,

  isOnRoute: (state: RouterStateUrl): boolean => {
    return state && state.url && state.url.startsWith(`/${statePath}`);
  },

  parse: (state: RouterStateUrl): VehicleInspectionListRouterData => {
    const {
      page,
      pageSize,
      dateFrom,
      dateTo,
      vehicles,
      driverIds,
      assignedMechanicIds,
      inspectionStatus,
      mechanicAssessmentStatus,
      driverReviewStatus,
      quickFilter,
    } = state?.queryParams || {};

    const result: VehicleInspectionListRouterData = {
      pageIndex: page ? +page - 1 : 0,
      pageSize: pageSize ? +pageSize : 10,
    };
    if (dateFrom || dateTo) {
      result.dateRange = {
        from: dateFrom || dateTo,
        to: dateTo || dateFrom,
      };
    }

    if (vehicles) {
      if (Array.isArray(vehicles)) {
        result.vehicles = vehicles.map((query) => queryParamToVehicle(query));
      } else {
        result.vehicles = [queryParamToVehicle(vehicles)];
      }
    } else {
      result.vehicles = [];
    }

    if (driverIds) {
      if (Array.isArray(driverIds)) {
        result.driverIds = driverIds;
      } else {
        result.driverIds = [driverIds];
      }
    } else {
      result.driverIds = [];
    }

    if (assignedMechanicIds) {
      if (Array.isArray(assignedMechanicIds)) {
        result.assignedMechanicIds = assignedMechanicIds.map((id) => queryParamToMechanicAssignment(id));
      } else {
        result.assignedMechanicIds = [queryParamToMechanicAssignment(assignedMechanicIds)];
      }
    } else {
      result.assignedMechanicIds = [];
    }

    if (inspectionStatus) {
      if (Array.isArray(inspectionStatus)) {
        result.inspectionStatus = inspectionStatus.map((query) => queryParamToVehicleInspectionStatus(query));
      } else {
        result.inspectionStatus = [queryParamToVehicleInspectionStatus(inspectionStatus)];
      }
    } else {
      result.inspectionStatus = [];
    }

    if (mechanicAssessmentStatus) {
      if (Array.isArray(mechanicAssessmentStatus)) {
        result.mechanicAssessmentStatus = mechanicAssessmentStatus.map((query) =>
          queryParamToMechanicAssessmentStatus(query),
        );
      } else {
        result.mechanicAssessmentStatus = [queryParamToMechanicAssessmentStatus(mechanicAssessmentStatus)];
      }
    } else {
      result.mechanicAssessmentStatus = [];
    }

    if (driverReviewStatus) {
      if (Array.isArray(driverReviewStatus)) {
        result.driverReviewStatus = driverReviewStatus.map((query) => queryParamToDriverReviewStatus(query));
      } else {
        result.driverReviewStatus = [queryParamToDriverReviewStatus(driverReviewStatus)];
      }
    } else {
      result.driverReviewStatus = [];
    }

    if (quickFilter) {
      result.quickFilter = queryParamToQuickFilter(quickFilter);
    } else {
      result.quickFilter = VehicleInspectionQuickFilter.NONE;
    }

    return result;
  },

  request: (data?: VehicleInspectionListRouterData, queryParamStrategy?): NavigationRequest => {
    const request: NavigationRequest = {
      path: ['/', inspectionsRootPath, InspectionsTab.DVIR],
      extras: {
        queryParamsHandling: queryParamStrategy ?? 'merge',
      },
    };
    if (data) {
      const {
        dateRange,
        pageIndex,
        pageSize,
        vehicles,
        driverIds,
        assignedMechanicIds,
        inspectionStatus,
        mechanicAssessmentStatus,
        driverReviewStatus,
        quickFilter,
      } = data;

      const query: { [key: string]: string | string[] } = {};
      if (dateRange?.from) {
        query.dateFrom = dateRange.from;
      }
      if (dateRange?.to) {
        query.dateTo = dateRange.to;
      }
      if (typeof pageIndex === 'number') {
        query.page = `${pageIndex + 1}`;
      }
      if (pageSize) {
        query.pageSize = `${pageSize}`;
      }
      if (vehicles?.length) {
        query.vehicles = vehicles.map((vehicle) => vehicleToQueryParam(vehicle));
      }
      if (driverIds?.length) {
        query.driverIds = driverIds;
      }
      if (assignedMechanicIds?.length) {
        query.assignedMechanicIds = assignedMechanicIds.map((id) => mechanicAssignmentToQueryParam(id));
      }
      if (inspectionStatus?.length) {
        query.inspectionStatus = inspectionStatus.map((status) => vehicleInspectionStatusToQueryParam(status));
      }
      if (mechanicAssessmentStatus?.length) {
        query.mechanicAssessmentStatus = mechanicAssessmentStatus.map((status) =>
          mechanicAssessmentStatusToQueryParam(status),
        );
      }
      if (driverReviewStatus?.length) {
        query.driverReviewStatus = driverReviewStatus.map((status) => driverReviewStatusToQueryParam(status));
      }
      if (quickFilter) {
        query.quickFilter = quickFilterToQueryParam(quickFilter);
      }
      if (Object.keys(query).length) {
        request.query = query;
      }
    }
    return request;
  },

  getHref(data?: VehicleInspectionListRouterData): string {
    return `/${statePath}`;
  },
};

@Injectable({
  providedIn: 'root',
})
export class VehicleInspectionListRouterService extends PortalRouterService<VehicleInspectionListRouterData> {
  outlet = RouterOutlets.PRIMARY;
  route = vehicleInspectionListRoute;
}
