import { RideListSnapshot } from '../ride-list-snapshot';
import { OrderByField, RideFilterRequest, RideStatus } from '../../../../api/endpoints/rides';
import { PortalEntityType, RideTimetable, YYYYMMDDString } from '../entities';
import {
  DateRideFilter,
  RideFilter,
  RideFilterType,
  RideQuickFilter,
  RideQuickFilterType,
  SearchHitRideFilter,
  UserRideFilter,
} from '../filters';
import { date } from '../../../../types/utils/common';
import { MultiAccountManagementOption } from '@rootTypes/entities/auth/multi-subaccount-management-option';

export const getRidesListRequest = (
  { timetable, staticFilters, dynamicFilters, currentPage, pageSize }: RideListSnapshot,
  account?: MultiAccountManagementOption,
): RideFilterRequest => {
  const filters = [...staticFilters, ...dynamicFilters];
  const campusIds: string[] = getFiltersByEntityType(filters, PortalEntityType.CAMPUS).map(
    (f: UserRideFilter) => f.payload.userId,
  );

  const districtIds: string[] = getFiltersByEntityType(filters, PortalEntityType.DISTRICT).map(
    (f: UserRideFilter) => f.payload.userId,
  );

  const studentIds: string[] = getFiltersByEntityType(filters, PortalEntityType.STUDENT).map(
    (f: UserRideFilter) => f.payload.userId,
  );

  const driverIds: string[] = getFiltersByEntityType(filters, PortalEntityType.DRIVER).map(
    (f: UserRideFilter) => f.payload.userId,
  );

  const parentIds: string[] = getFiltersByEntityType(filters, PortalEntityType.PARENT).map(
    (f: UserRideFilter) => f.payload.userId,
  );

  const routeIds: string[] = getFiltersByEntityType(filters, PortalEntityType.ROUTE).map(
    (f: UserRideFilter) => f.payload.userId,
  );

  const yardIds: string[] = getFiltersByEntityType(filters, PortalEntityType.YARD).map(
    (f: UserRideFilter) => f.payload.userId,
  );

  const vendorIds: string[] = getFiltersByEntityType(filters, PortalEntityType.VENDOR).map(
    (f: UserRideFilter) => f.payload.userId,
  );

  const rideIds: string[] = getFiltersByEntityType(filters, PortalEntityType.RIDE).map(
    (f: UserRideFilter) => f.payload.userId,
  );

  const tripIds: string[] = getFiltersByEntityType(filters, PortalEntityType.CHARTER_TRIP).map(
    ({ payload }) => payload.userId,
  );

  const routeGroupIds: string[] = getFiltersByEntityType(filters, PortalEntityType.ROUTE_GROUP).map(
    ({ payload }) => payload.userId,
  );

  const searchHitFilters = getSearchHitFilters(filters);
  const searchHitFilterFieldValueMap = searchHitFilters.reduce((prev, curr) => {
    const filterValues = prev[curr.payload.field] || [];
    filterValues.push(curr.payload.value);
    return { ...prev, [curr.payload.field]: filterValues };
  }, {});

  const limit = pageSize;
  const skip = currentPage * pageSize;
  const apiRideStatuses =
    timetable === RideTimetable.ALL
      ? [timetableToRideStatus(RideTimetable.PAST), timetableToRideStatus(RideTimetable.UPCOMING)]
      : [timetableToRideStatus(timetable)];

  const target: RideFilterRequest = {
    parentIds,
    districtIds,
    studentIds,
    driverIds,
    campusIds,
    routeIds,
    yardIds,
    vendorIds,
    rideIds,
    rideStatuses: apiRideStatuses,
    tripIds,
    routeGroupIds,
    skip,
    limit,
    isCharterRide: !!getCharterRidesFilter(filters),
    orderByFields: getSortOrderForList(timetable, filters),
    ...searchHitFilterFieldValueMap,
  };

  const startDate = getStartDateFromFilters(filters);
  const endDate = getEndDateFromFilters(filters);

  if (startDate && endDate) {
    target.date = {
      from: startDate,
      to: endDate,
    };
  }

  if (account) {
    target.operatorIds = [...account.meta.operatorIds];
  }

  return target;
};

function timetableToRideStatus(source: RideTimetable): RideStatus {
  switch (source) {
    case RideTimetable.PAST:
      return RideStatus.PAST;
    case RideTimetable.UPCOMING:
      return RideStatus.UPCOMING;
    case RideTimetable.ACCEPTED:
      return RideStatus.ACCEPTED;
    case RideTimetable.ASSIGNED:
      return RideStatus.ASSIGNED;
    case RideTimetable.UNASSIGNED:
      return RideStatus.UNASSIGNED;
    default:
      return RideStatus.UPCOMING;
  }
}

function getSearchHitFilters(filters: RideFilter[]): SearchHitRideFilter[] {
  return filters.filter((f) => f.type === RideFilterType.SEARCH_HIT_FILTER);
}

function getFiltersByEntityType(filters: RideFilter[], entityType: PortalEntityType): UserRideFilter[] {
  return filters
    .filter((f) => f.type === RideFilterType.USER)
    .filter((f: UserRideFilter) => f.payload.userType === entityType) as UserRideFilter[];
}

function getStartDateFromFilters(filters: RideFilter[]): YYYYMMDDString {
  const dateFilter: DateRideFilter = filters.find((f) => f.type === RideFilterType.DATE) as DateRideFilter;
  if (dateFilter) {
    return dateFilter.payload.startDate;
  }
  const todaysRidesByRouteFilter = getTodaysRidesByRoutesFilter(filters);
  if (todaysRidesByRouteFilter) {
    return date.YearMonthDay.today();
  }
  return null;
}

function getEndDateFromFilters(filters: RideFilter[]): YYYYMMDDString {
  const dateFilter: DateRideFilter = filters.find((f) => f.type === RideFilterType.DATE) as DateRideFilter;
  if (dateFilter) {
    return dateFilter.payload.endDate;
  }
  const todaysRidesByRouteFilter = getTodaysRidesByRoutesFilter(filters);
  if (todaysRidesByRouteFilter) {
    return date.YearMonthDay.today();
  }
  return null;
}

function getSortOrderForList(timetable: RideTimetable, filters: RideFilter[]): OrderByField[] {
  const todaysRidesByRouteFilter = getTodaysRidesByRoutesFilter(filters);
  if (todaysRidesByRouteFilter) {
    return [
      {
        fieldName: 'route_name',
      },
      {
        fieldName: 'ride_start_timestamp',
      },
    ];
  }
  const order = timetable === RideTimetable.PAST ? 'desc' : 'asc';
  return [{ fieldName: 'ride_start_timestamp', order }];
}

function getTodaysRidesByRoutesFilter(filters: RideFilter[]): RideQuickFilter | undefined {
  return filters.find((f) => {
    if (f.type === RideFilterType.QUICK_FILTER) {
      return (f as RideQuickFilter).payload === RideQuickFilterType.TODAY_RIDES_BY_ROUTE;
    }
    return false;
  });
}

function getCharterRidesFilter(filters: RideFilter[]): RideQuickFilter | undefined {
  return filters.find((f) => {
    if (f.type === RideFilterType.QUICK_FILTER) {
      return (f as RideQuickFilter).payload === RideQuickFilterType.CHARTER_RIDES;
    }
    return false;
  });
}
