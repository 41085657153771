import { Component, OnInit, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import { go } from '@rootStore';
import { portalRoutes } from '@router';
import { sendPasswordResetEmailRequested } from '../../store';

@Component({
  selector: 'wp-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent implements OnInit {
  public emailControl: UntypedFormControl;
  public isSubmitLoading = false;

  constructor(private store: Store) {}

  @HostListener('window:keydown', ['$event'])
  public onEnterKey(event: KeyboardEvent): void {
    if (event.code === 'Enter') {
      event.preventDefault(); // prevent page reloading
      if (!this.isSubmitLoading) {
        this.onSubmit();
      }
    }
  }

  public ngOnInit(): void {
    this.emailControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  }

  public onSubmit(): void {
    if (this.emailControl.valid) {
      this.isSubmitLoading = true;
      this.store.dispatch(
        sendPasswordResetEmailRequested({
          email: this.emailControl.value,
        }),
      );
    }
  }

  public onReturnToLoginPage(): void {
    this.store.dispatch(go(portalRoutes.auth.login.request()));
  }
}
