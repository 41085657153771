import { Store } from '@ngrx/store';
import { drawerClosedSelected, State } from '@rootStore';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { getDisplayBackText } from '@rootStore';
import * as fromActions from '../actions/employee-details.actions';
import * as fromSelectors from '../selectors/employee-details.selectors';
import * as fromTypes from '../../types';
import { map } from 'rxjs/operators';

@Injectable()
export class EmployeeDetailsFacade {
  constructor(private store: Store<State>) {}

  public getDisplayBackText$(): Observable<string> {
    return this.store.select(getDisplayBackText);
  }

  public getOpenedEmployeeId$(): Observable<string> {
    return this.store.select(fromSelectors.getEmployeeId);
  }

  public getTab$(): Observable<fromTypes.EmployeeDetailsTabs> {
    return this.store.select(fromSelectors.getTab);
  }

  public getOpenedEmployee$(): Observable<fromTypes.Employee> {
    return this.store.select(fromSelectors.getOpenedEmployee);
  }

  public getOpenedEmployeeLoading$(): Observable<boolean> {
    return this.store.select(fromSelectors.getOpenedEmployeeLoading);
  }

  public getOpenedEmployeeError$(): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getOpenedEmployeeError);
  }

  public getOpenEmployeePermissons$(): Observable<fromTypes.EmployeePermission[]> {
    return this.store.select(fromSelectors.getOpenEmployeePermissions).pipe(
      map((permissions) => {
        if (!permissions?.length) {
          return undefined;
        }
        return permissions;
      }),
    );
  }

  public onSetEmployeeId(employeeId: string): void {
    this.store.dispatch(fromActions.setEmployeeId({ employeeId }));
  }

  public onSetTab(tab: fromTypes.EmployeeDetailsTabs): void {
    this.store.dispatch(fromActions.setTab({ tab }));
  }

  public onPermissionLinkClicked(permission: fromTypes.EmployeePermission): void {
    this.store.dispatch(fromActions.employeePermissionLinkClicked({ permission }));
  }

  public onCloseDrawer(): void {
    this.store.dispatch(drawerClosedSelected());
  }
}
