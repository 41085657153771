import { createSelector } from '@ngrx/store';
import { getFeatureState } from './feature-selector';

const getLogFeatureState = createSelector(getFeatureState, (state) => state.changeLog);
export const getChangeLogStudentId = createSelector(getLogFeatureState, (state) => state.studentId);

const getLogState = createSelector(getLogFeatureState, (state) => state.logs);
export const isStudentChangeLogsLoading = createSelector(getLogState, (state) => state.isLoading);
export const getStudentChangeLogs = createSelector(getLogState, (state) => state.entity);
export const getStudentChangeLogsError = createSelector(getLogState, (state) => state.error);

export const getStudentChangeLogsCount = createSelector(getStudentChangeLogs, (state) => state?.length);
export const isStudentChangeLogsEmpty = createSelector(getStudentChangeLogsCount, (count) => {
  if (typeof count === 'number') {
    return count === 0;
  }
  return undefined;
});
