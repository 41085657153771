import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getPortalEntityActions,
  getPortalEntityFailed,
  getPortalEntitySuccess,
} from '../actions/portal-entities-info.actions';
import { bufferTime, catchError, filter, map, mergeMap } from 'rxjs/operators';
import { PortalEntityInfoApiService } from '../../services/portal-entity-info-api.service';
import { WpError } from '@rootTypes';
import { from, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { concatLatestFrom } from '@ngrx/operators';
import { getPortalEntity } from '../selectors/portal-entity-info.selectors';

const PORTAL_ENTITY_LOAD_BUFFER_TIME = 300;

@Injectable()
export class PortalEntityInfoEffects {
  constructor(
    private actions$: Actions,
    private api: PortalEntityInfoApiService,
    private store: Store,
  ) {}

  public getPortalEntityRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...getPortalEntityActions),
      bufferTime(300),
      mergeMap((actions) => {
        const uniqueMap = {};
        actions.forEach(({ entityType, entityId }) => {
          uniqueMap[entityType + entityId] = { entityType, entityId };
        });
        return from(Object.values(uniqueMap));
      }),
      concatLatestFrom(({ entityType, entityId }) => this.store.select(getPortalEntity(entityType, entityId))),
      filter(([, portalEntity]) => !portalEntity),
      mergeMap(([{ entityType, entityId }]) => {
        return this.api.getPortalEntity(entityId, entityType).pipe(
          map((entity) => getPortalEntitySuccess({ entity })),
          catchError((originalError) => {
            console.error(originalError);
            const error: WpError = {
              originalError,
              text: `Failed to load info for ${entityType} (id: ${entityId})`,
            };
            return of(getPortalEntityFailed({ error, entityId, entityType }));
          }),
        );
      }),
    ),
  );
}
