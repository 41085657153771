import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-popup-body-top',
  templateUrl: './popup-body-top.component.html',
  styleUrls: ['./popup-body-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupBodyTopComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
