import { Pipe, PipeTransform } from '@angular/core';
import { centsToDollars } from '@rootTypes/utils';
import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'centsToDollars' })
export class CentsToDollarsPipe implements PipeTransform {
  constructor(private currency: CurrencyPipe) {}

  public transform(cents: number, emptyVal = '--'): string {
    if (typeof cents === 'number') {
      const dollars = centsToDollars(cents);
      return this.currency.transform(dollars);
    }
    return emptyVal;
  }
}
