import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Base64String } from '@apiEntities/common';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AbstractPopupComponent } from '../../../core/popupable/types';
import { PopupRef } from '../../../core/popupable/types/popup-ref';
import { PlaceholderType } from '../types';
import {
  getPhotoInputPopupDefaultImageStyles,
  getPhotoInputPopupSquareImageStyles,
  PhotoInputPopupConfigStyles,
} from './photo-input-popup-config-styles';

export interface PhotoInputPopupConfig {
  isSquareImage: boolean; // if an image is a square or a rectangle
  imagePlaceholder: PlaceholderType;
  sourceImageBase64?: string;
  sourceImageLoadFn?: () => Promise<Base64String>;
  // If ommitted the default width will be used:
  // 600 for a square and 640 for a rectangle image
  resultImageWidthPx?: number;
  // If set to true resultImageWidthPx is ignored,
  // default to false
  keepOriginalImageWidth?: boolean;
  customStyles?: PhotoInputPopupConfigStyles;
}

export type NewImageBase64 = string | void;

@Component({
  selector: 'wp-photo-input-popup',
  templateUrl: './photo-input-popup.component.html',
  styleUrls: ['./photo-input-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoInputPopupComponent implements OnInit, AbstractPopupComponent<PhotoInputPopupConfig, NewImageBase64> {
  public popupRef: PopupRef<PhotoInputPopupConfig, NewImageBase64>;

  public isSourceImageLoading = false;
  public isSourceImageLoadError = false;
  public sourceImageBase64 = '';
  public sourceImageLoadingContainerStyles: { [key: string]: string };

  public resultImageBase64: string;
  public imageFileChangeEvent: any;
  public imageLoaded = false;
  public imageLoading = false;
  public imageLoadError = false;

  public uploadAreaStyles: { [key: string]: string };
  public uploadAreaIconStyles: { [key: string]: string };
  public verticalLineHeight: string;

  public isRoundPreview: boolean;
  public placeholder: PlaceholderType;
  public previewWidth: string;
  public previewHeight: string;
  public previewStyles: { [key: string]: string };
  public aspectRatio: number;
  public resizeToWidth: number;

  @ViewChild('imageFileInput')
  private imageFileInput: ElementRef<HTMLInputElement>;
  private imageInitiallyCropped = false;
  private imageChanged = false;

  constructor(private cd: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.setImageParams(this.popupRef.data);
    if (this.popupRef.data.sourceImageBase64) {
      this.sourceImageBase64 = this.popupRef.data.sourceImageBase64;
    } else if (this.popupRef.data.sourceImageLoadFn) {
      this.loadSourceImage();
    }
  }

  public onImageFileChange(event: any): void {
    const filePath = event.target.value;
    if (!filePath) {
      // user clicked Cancel on "Select a file" dialog
      return;
    }
    this.imageLoadError = false;
    this.imageLoaded = false;
    this.imageLoading = true;
    this.resultImageBase64 = undefined;
    this.imageFileChangeEvent = event;
    this.imageChanged = true;
    this.cd.detectChanges();
  }

  public onImageLoaded(): void {
    this.imageLoaded = true;
    this.imageLoading = false;
    this.imageLoadError = false;
  }

  public onLoadImageFailed(): void {
    this.imageLoaded = false;
    this.imageLoading = false;
    this.imageLoadError = true;
  }

  public async onImageCropped(event: ImageCroppedEvent): Promise<void> {
    this.resultImageBase64 = event.base64;
    if (this.imageInitiallyCropped) {
      this.imageChanged = true;
    } else {
      this.imageInitiallyCropped = true;
    }
  }

  public onRemoveImage(): void {
    this.imageFileInput.nativeElement.value = '';
    this.imageFileChangeEvent = undefined;
    this.resultImageBase64 = undefined;
    this.imageLoaded = false;
  }

  public onClose(): void {
    this.popupRef.close(undefined);
  }

  public onSave(): void {
    if (this.resultImageBase64 && this.imageChanged) {
      this.popupRef.close(this.resultImageBase64);
    } else {
      this.popupRef.close();
    }
  }

  private setImageParams(config: PhotoInputPopupConfig): void {
    this.placeholder = config.imagePlaceholder;
    this.isRoundPreview = false;
    const resizeToWidth = config.resultImageWidthPx ? config.resultImageWidthPx : undefined;
    this.resizeToWidth = config.keepOriginalImageWidth ? 0 : resizeToWidth || 640;
    if (config.isSquareImage) {
      this.isRoundPreview = true;
      this.resizeToWidth = config.keepOriginalImageWidth ? 0 : resizeToWidth || 600;
    }

    let styles: PhotoInputPopupConfigStyles;
    if (config.customStyles) {
      styles = config.customStyles;
    } else if (config.isSquareImage) {
      styles = getPhotoInputPopupSquareImageStyles();
    } else {
      styles = getPhotoInputPopupDefaultImageStyles();
    }
    const {
      uploadAreaWidthPx,
      uploadAreaHeightPx,
      uploadAreaMarginLeftPx,
      uploadAreaMarginRightPx,
      uploadAreaIconWidthPx,
      uploadAreaIconHeightPx,
      uploadAreaMarginBottomPx,
      previewWidthPx,
      previewHeightPx,
      previewMarginLeftPx,
      previewMarginRightPx,
    } = styles;

    this.uploadAreaStyles = {
      width: uploadAreaWidthPx + 'px',
      height: uploadAreaHeightPx + 'px',
      marginLeft: uploadAreaMarginLeftPx + 'px',
      marginRight: uploadAreaMarginRightPx + 'px',
    };
    this.uploadAreaIconStyles = {
      width: uploadAreaIconWidthPx + 'px',
      height: uploadAreaIconHeightPx + 'px',
      marginBottom: uploadAreaMarginBottomPx + 'px',
    };
    this.verticalLineHeight = uploadAreaHeightPx + 'px';
    this.previewWidth = previewWidthPx + 'px';
    this.previewHeight = previewHeightPx + 'px';
    this.previewStyles = {
      marginLeft: previewMarginLeftPx + 'px',
      marginRight: previewMarginRightPx + 'px',
    };

    this.sourceImageLoadingContainerStyles = {
      width:
        uploadAreaWidthPx +
        uploadAreaMarginLeftPx +
        uploadAreaMarginRightPx +
        previewWidthPx +
        previewMarginLeftPx +
        previewMarginRightPx +
        1 +
        'px',
      height: uploadAreaHeightPx + 'px',
    };

    this.aspectRatio = previewWidthPx / previewHeightPx;
  }

  private async loadSourceImage(): Promise<void> {
    try {
      this.isSourceImageLoading = true;
      this.sourceImageBase64 = await this.popupRef.data.sourceImageLoadFn();
    } catch (error) {
      this.isSourceImageLoadError = true;
    } finally {
      this.isSourceImageLoading = false;
      this.cd.detectChanges();
    }
  }
}
