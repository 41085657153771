import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { WpError, Yard, LoadYardOptions, Vendor } from '../../types';
import * as fromSelectors from '../selectors';
import * as fromActions from '../actions/yard-data.actions';

@Injectable()
export class YardDataFacade {
  constructor(private store: Store) {}

  public getYard(yardId: string): Observable<Yard> {
    return this.store.select(fromSelectors.getYard, { yardId });
  }

  public getYardLoading(yardId: string): Observable<boolean> {
    return this.store.select(fromSelectors.getYardLoading, { yardId });
  }

  public getYardError(yardId: string): Observable<WpError> {
    return this.store.select(fromSelectors.getYardError, { yardId });
  }

  public getYardErrorMessage(yardId: string): Observable<string> {
    return this.store.select(fromSelectors.getYardErrorMessage, { yardId });
  }

  public getYardName(yardId: string): Observable<string> {
    return this.store.select(fromSelectors.getYardName, { yardId });
  }

  public getVendorByYard(yardId: string): Observable<Vendor> {
    return this.store.select(fromSelectors.getVendorByYard, { yardId });
  }

  public onLoadYardRequested(yardId: string, options?: LoadYardOptions): void {
    this.store.dispatch(fromActions.loadYardRequested({ yardId, options }));
  }

  public onLazyLoadYardRequested(yardId: string): void {
    this.store.dispatch(fromActions.lazyLoadYardRequested({ yardId }));
  }
}
