import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'wp-common-popup-page-top',
  templateUrl: './common-popup-page-top.component.html',
  styleUrls: ['./common-popup-page-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonPopupPageTopComponent implements OnInit {
  @Input() public isSeparatorLine: boolean;
  @Input() public isCloseButton = true;
  @Output() public closeClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  public onCloseClicked(): void {
    this.closeClicked.emit();
  }
}
