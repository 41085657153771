import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { EntityStatus } from '../../cross-entity-search';
import { PortalEntity, PortalEntityType, VendorPortalEntity } from '../types';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'wp-vendor-select',
  templateUrl: './vendor-select.component.html',
  styleUrls: ['./vendor-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VendorSelectComponent implements OnInit, OnDestroy {
  /**
   * Contains VendorPortalEntity
   */
  @Input() public control: UntypedFormControl;
  @Input() public controlStateChange?: Observable<any>;
  /**
   * Input placeholder
   */
  @Input() public label: string;
  /**
   * This param should be passed together with Validators.required in the form control,
   * since EntityFilterComponent does not work with the form control directly
   */
  @Input() public required = false;
  @Input() public isSearchGlassIcon = true;
  @Input() public tabIndex = '0';
  @Input() public autofocus: boolean;
  @Output() public selectedChanged = new EventEmitter<VendorPortalEntity>();

  public searchByType = PortalEntityType.VENDOR;
  public searchInputControl: UntypedFormControl;

  public entityStatus = EntityStatus.ANY;
  public disabled: boolean;

  private sub = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.searchInputControl = new UntypedFormControl(null, [this.searchInputControlValidatorFn.bind(this)]);
    const initialDisplayValue = this?.control?.value?.label ? this.control.value.label : null;
    this.searchInputControl.setValue(initialDisplayValue);
    this.control.valueChanges
      .pipe(
        map((value) => value?.label),
        distinctUntilChanged(),
      )
      .subscribe((displayLabel) => {
        this.searchInputControl.setValue(displayLabel);
      });
    this.control.statusChanges.pipe(distinctUntilChanged()).subscribe((status) => {
      this.searchInputControl.markAsTouched();
      this.searchInputControl.updateValueAndValidity();
    });
    this.disabled = this.control.disabled;
    this.control.registerOnDisabledChange((disabled) => {
      this.disabled = disabled;
      this.cdRef.detectChanges();
    });
    if (this.controlStateChange) {
      const onStateChangeSub = this.controlStateChange.subscribe(() => {
        this.cdRef.detectChanges();
      });
      this.sub.add(onStateChangeSub);
    }
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public onOptionSelect(option: PortalEntity): void {
    this.control.setValue(option as VendorPortalEntity);
    this.selectedChanged.emit(option);
  }

  private searchInputControlValidatorFn(): ValidationErrors | null {
    if (this.control?.errors) {
      return { ...this.control.errors };
    }
    return null;
  }
}
