import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wp-btn-add-form-line',
  template: `
    <button class="button" [disabled]="disabled" (click)="buttonClicked.emit()">
      <div class="icon">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle class="has-stroke" cx="12" cy="12.5" r="10" stroke="#959597" stroke-width="2" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13 6.5H11V11.5H6V13.5H11V18.5H13V13.5H18V11.5H13V6.5Z"
            fill="#959597"
            class="has-fill"
          />
        </svg>
      </div>
      <div class="text">
        <ng-content></ng-content>
      </div>
    </button>
  `,
  styles: [
    `
      .button {
        display: flex;
        align-items: center;
        border: none;
        background: none;
        font-size: inherit;
      }

      .button:not(.disabled) {
        cursor: pointer;
      }

      .icon {
        margin-right: 4px;
      }

      .button:hover > .text {
        color: #47a540;
      }

      .button:hover > .icon > svg > .has-stroke {
        stroke: #47a540;
      }

      .button:hover > .icon > svg > .has-fill {
        fill: #47a540;
      }

      .button:disabled > .text {
        color: #c6c6ca;
      }

      .button:disabled > .icon > svg > .has-stroke {
        stroke: #c6c6ca;
      }

      .button:disabled > .icon > svg > .has-fill {
        fill: #c6c6ca;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnAddFormLineComponent implements OnInit {
  @Input() public disabled: boolean;
  @Output() public buttonClicked = new EventEmitter<void>();
  constructor() {}

  ngOnInit(): void {}
}
