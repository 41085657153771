import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wp-divider-vertical-line',
  template: ` <div class="divider" [ngStyle]="{ margin, height }"></div> `,
  styleUrls: ['./divider-vertical-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DividerVerticalLineComponent implements OnInit {
  @Input() public margin = 'auto auto';
  @Input() public height = '100%';
  constructor() {}

  ngOnInit(): void {}
}
