import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import * as fromActions from '../actions/student-change-request-processed-details.actions';
import { StudentChangeRequestApiService } from '../../services';

@Injectable()
export class StudentChangeRequestProcessedDetailsEffects {
  constructor(
    private store$: Store,
    private actions$: Actions,
    private api: StudentChangeRequestApiService,
  ) {}

  public loadDataRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadProcessedStudentChangeRequestRequested),
      mergeMap(({ studentChangeRequestId }) => {
        return this.api.getProcessedStudentChangeRequest(studentChangeRequestId).pipe(
          map((response) => fromActions.loadProcessedStudentChangeRequestSuccess({ response })),
          catchError((originalError) => {
            return of(
              fromActions.loadProcessedStudentChangeRequestFailed({
                error: {
                  text: 'Failed to load processed student change request',
                  originalError,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  public loadSummaryRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadProcessedStudentChangeRequestSummaryRequested),
      mergeMap(({ studentChangeRequestId }) => {
        return this.api.getStudentChangeRequestSummary({ studentChangeRequestId }).pipe(
          map((response) => fromActions.loadProcessedStudentChangeRequestSummarySuccess({ response })),
          catchError((originalError) => {
            return of(
              fromActions.loadProcessedStudentChangeRequestSummaryFailed({
                error: {
                  text: 'Failed to load student change request summary',
                  originalError,
                },
              }),
            );
          }),
        );
      }),
    ),
  );
}
