import { createSelector } from '@ngrx/store';
import { accountState } from './account.selectors';
import { PortalPermissionItem } from '@rootTypes';

export const getAllPermissions = createSelector(accountState, (state) => state.permissions);

export const isPermissionGivenForAccount = (permissionName: PortalPermissionItem) =>
  createSelector(getAllPermissions, (allPermissions) => !!allPermissions[permissionName]);
export const isEitherPermissionGivenForAccount = (...permissionNames: PortalPermissionItem[]) => {
  return createSelector(getAllPermissions, (permissions) => permissionNames.some((p) => !!permissions[p]));
};

export const isAnyPermissions = createSelector(accountState, (state) => {
  if (!state.selectedAccount.data) {
    return undefined;
  }
  return Object.keys(state.permissions).length > 0;
});
