import { CampusListSerializedSnapshot } from '../campus-list-serialized-snapshot';
import { EntityStatus } from '../../../../types';
import { CampusListFilter } from '../campus-list-entity-filter';
import * as ui from '../ui';
import { serializeCampusFilters } from './serialize-campus-filters';

export const campusListStateToSerialized = (
  pageSize: number,
  currentPage: number,
  status: EntityStatus,
  query: string,
  filters: CampusListFilter[],
): CampusListSerializedSnapshot => {
  const pageSizeValue = pageSize === ui.DEFAULT_PAGE_SIZE ? undefined : '' + pageSize;
  const currentPageValue = currentPage === ui.DEFAULT_PAGE ? undefined : '' + (currentPage + 1);
  const statusValue = status === ui.DEFAULT_STATUS ? undefined : status;
  const filterValue = serializeCampusFilters(filters);
  return {
    page: currentPageValue,
    pageSize: pageSizeValue,
    status: statusValue,
    query: query || undefined,
    filters: filterValue,
  };
};
