import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ResponseWithApiNotification } from './types/response-with-api-notification';
import { PopupableService } from '../../core/popupable/popupable.service';
import { NotificationApiPopupComponent } from '../../containers/notification-api-popup/notification-api-popup.component';
import { isNotificationApiResponse } from './is-notification-api-response';

@Injectable({ providedIn: 'root' })
export class NotificationApiService {
  constructor(private popupableService: PopupableService) {}

  public handle<Response = any>(response: Response): Observable<Response> {
    if (isNotificationApiResponse(response)) {
      return this.handleNotificationAPIResponse(response);
    }
    return of(response);
  }

  private handleNotificationAPIResponse(response: ResponseWithApiNotification): Observable<any> {
    return this.popupableService.openApiPromptPopup(NotificationApiPopupComponent, response);
  }
}
