import { MapUtils } from '../map-utils';
import { Coords } from '../coords';

export class MapUtilsGoogle implements MapUtils {
  getBearingBetweenPoints(origin: Coords, target: Coords): number {
    return google.maps.geometry.spherical.computeHeading(
      new google.maps.LatLng(origin.lat, origin.lng),
      new google.maps.LatLng(target.lat, target.lng),
    );
  }

  movePointByDistanceBearing(origin: Coords, distance: number, bearing: number): Coords {
    const res = google.maps.geometry.spherical.computeOffset(
      new google.maps.LatLng(origin.lat, origin.lng),
      distance,
      bearing,
    );
    return {
      lat: res.lat(),
      lng: res.lng(),
    };
  }

  isSamePoints(p1: Coords, p2: Coords): boolean {
    return p1?.lat === p2?.lat && p1?.lng === p2?.lng;
  }

  getDistanceBetweenPoints(p1: Coords, p2: Coords): number {
    return google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(p1.lat, p1.lng),
      new google.maps.LatLng(p2.lat, p2.lng),
    );
  }

  interpolate(origin: Coords, target: Coords, fraction: number): Coords {
    const rs = google.maps.geometry.spherical.interpolate(
      new google.maps.LatLng(origin.lat, origin.lng),
      new google.maps.LatLng(target.lat, target.lng),
      fraction,
    );
    return {
      lat: rs.lat(),
      lng: rs.lng(),
    };
  }
}
