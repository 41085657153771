import { combineLatest, Observable } from 'rxjs';
import * as fromTypes from '../../entities/campus';
import { MapMarker } from '../../entities';
import { debounceTime, map } from 'rxjs/operators';
import { isLocationsEqual } from '../common/is-locations-equal';
import { iconPaths } from '@rootTypes/utils';

const locationMarkersMap = {
  campus: {
    url: iconPaths.MAP_CAMPUS_GREEN,
    width: 36,
    height: 36,
  },
  location: {
    url: iconPaths.MAP_PIN_GREEN_WHITE_BG,
    width: 40,
    height: 40,
  },
  locationSelected: {
    url: iconPaths.MAP_PIN_GREEN_WHITE_BG,
    width: 64,
    height: 64,
  },
};

export abstract class AbstractLocationsMapService {
  public markers$: Observable<MapMarker[]>;

  /**
   * provide these streams whereever you use the service
   */
  abstract campus$: Observable<fromTypes.Campus>;
  abstract locations$: Observable<fromTypes.CampusLocation[]>;
  abstract selectedLocationId$: Observable<string>;
  abstract clickCallback: (locationId: string) => void;

  public init(): void {
    this.markers$ = combineLatest([this.campus$, this.locations$, this.selectedLocationId$]).pipe(
      debounceTime(200),
      map(([campus, locations, selectedLocationId]) => this.getMarkers(campus, locations, selectedLocationId)),
    );
  }

  private getMarkers(
    campus: fromTypes.Campus,
    locations: fromTypes.CampusLocation[],
    selectedLocationId: string,
  ): MapMarker[] {
    const campusMarker: MapMarker = {
      compareId: `campus_${campus.id}`,
      url: locationMarkersMap.campus.url,
      width: locationMarkersMap.campus.width,
      height: locationMarkersMap.campus.height,
      location: {
        lat: campus.address.geometry.location.lat,
        lng: campus.address.geometry.location.lng,
      },
      tooltip: campus.name,
    };
    const locationSameAsCampus = locations.find((l) => {
      return isLocationsEqual(campus.address.geometry.location, l.address.geometry.location);
    });
    if (locationSameAsCampus) {
      campusMarker.clickCallbackFn = () => {
        if (this.clickCallback) {
          this.clickCallback(locationSameAsCampus.pickupDropoffLocationId);
        }
      };
    }
    const locationMarkers = locations
      .filter((loc) => {
        const isSameAsCampus = isLocationsEqual(campus.address.geometry.location, loc.address.geometry.location);
        return !isSameAsCampus;
      })
      .map((loc) => {
        const isSelected = loc.pickupDropoffLocationId === selectedLocationId;
        const markerObj = isSelected ? locationMarkersMap.locationSelected : locationMarkersMap.location;
        return {
          compareId: `${isSelected}_${loc.pickupDropoffLocationId}_${loc.address.geometry.location.lat}_${loc.address.geometry.location.lng}`,
          url: markerObj.url,
          width: markerObj.width,
          height: markerObj.height,
          location: {
            lat: loc.address.geometry.location.lat,
            lng: loc.address.geometry.location.lng,
          },
          clickCallbackFn: () => {
            if (this.clickCallback) {
              this.clickCallback(loc.pickupDropoffLocationId);
            }
          },
          tooltip: loc.name,
        } as MapMarker;
      });
    return [campusMarker, ...locationMarkers];
  }
}
