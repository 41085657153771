<ng-container [ngSwitch]="type">
  <ng-container *ngIf="isActive$ | async as active">
    <ng-container *ngSwitchCase="dashboardPath">
      <wp-icon-dashboard [green]="active === dashboardPath"></wp-icon-dashboard>
    </ng-container>

    <ng-container *ngSwitchCase="'rides'">
      <wp-icon-rides [green]="active === 'rides'"></wp-icon-rides>
    </ng-container>

    <ng-container *ngSwitchCase="'routes'">
      <wp-icon-routes [green]="active === 'routes'"></wp-icon-routes>
    </ng-container>

    <ng-container *ngSwitchCase="'vehicles'">
      <wp-icon-vehicles [green]="active === 'vehicles'"></wp-icon-vehicles>
    </ng-container>

    <ng-container *ngSwitchCase="'drivers'">
      <wp-icon-drivers [green]="active === 'drivers'"></wp-icon-drivers>
    </ng-container>

    <ng-container *ngSwitchCase="'districts'">
      <wp-icon-districts [green]="active === 'districts'"></wp-icon-districts>
    </ng-container>

    <ng-container *ngSwitchCase="'schools'">
      <wp-icon-schools [green]="active === 'schools'"></wp-icon-schools>
    </ng-container>

    <ng-container *ngSwitchCase="'students'">
      <wp-icon-students [green]="active === 'students'"></wp-icon-students>
    </ng-container>

    <ng-container *ngSwitchCase="'insights'">
      <wp-icon-insights [green]="active === 'insights'"></wp-icon-insights>
    </ng-container>

    <ng-container *ngSwitchCase="'campuses'">
      <wp-icon-campus [green]="active === 'campuses'"></wp-icon-campus>
    </ng-container>

    <ng-container *ngSwitchCase="'vendors'">
      <wp-icon-vendor [green]="active === 'vendors'"></wp-icon-vendor>
    </ng-container>

    <ng-container *ngSwitchCase="'work-queue'">
      <wp-icon-work-queue
        [green]="active === 'work-queue'"
      ></wp-icon-work-queue>
    </ng-container>

    <ng-container *ngSwitchCase="'yards'">
      <wp-icon-yard [green]="active === 'yards'"></wp-icon-yard>
    </ng-container>

    <ng-container *ngSwitchCase="'vendor-employees'">
      <wp-icon-employee
        [green]="active === 'vendor-employees'"
      ></wp-icon-employee>
    </ng-container>
    <ng-container *ngSwitchCase="'school-employees'">
      <wp-icon-employee
        [green]="active === 'school-employees'"
      ></wp-icon-employee>
    </ng-container>
    <ng-container *ngSwitchCase="'route-management'">
      <wp-portal-icon [path]="routeManagementIconPath"></wp-portal-icon>
    </ng-container>

    <ng-container *ngSwitchCase="'reports'">
      <wp-portal-icon
        [path]="active === 'reports' ? reportsIconPathActive : reportsIconPathInactive"
      ></wp-portal-icon>
    </ng-container>

    <ng-container *ngSwitchCase="'vehicle-inspections'">
      <wp-portal-icon
        [path]="active === 'vehicle-inspections' ? vehicleInspectionsIconActive : vehicleInspectionsIconInactive"
      ></wp-portal-icon>
    </ng-container>

    <ng-container *ngSwitchCase="'activities'">
      <wp-portal-icon
        [path]="active === 'activities' ? activitiesActive : activitiesInactive"
      ></wp-portal-icon>
    </ng-container>

    <ng-container *ngSwitchCase="accessManagementId">
      <wp-portal-icon
        [path]="active === accessManagementId ? accessManagementActive : accessManagementInactive"
      ></wp-portal-icon>
    </ng-container>

    <ng-container *ngSwitchCase="'route-group'">
      <wp-icon-route-group
        [isActive]="active === 'route-group'"
      ></wp-icon-route-group>
    </ng-container>

    <ng-container *ngSwitchCase="'dbr'">
      <wp-icon-driver-bus-report
        [green]="active === 'dbr'"
      ></wp-icon-driver-bus-report>
    </ng-container>

    <ng-container *ngSwitchCase="tripsUrlPath">
      <wp-icon-trips [green]="active === tripsUrlPath"></wp-icon-trips>
    </ng-container>

    <ng-container *ngSwitchCase="invoicingUrlPath">
      <wp-icon-invoicing-menu
        [green]="active === invoicingUrlPath"
      ></wp-icon-invoicing-menu>
    </ng-container>

    <ng-container *ngSwitchCase="transportationRequestsId">
      <wp-portal-icon
        [path]="active === transportationRequestsId ? transportationRequestsActive : transportationRequestsInactive"
      >
      </wp-portal-icon>
    </ng-container>

    <ng-container *ngSwitchCase="'inspections'">
      <wp-portal-icon
        [path]="active === 'inspections' ? vehicleInspectionsIconActive : vehicleInspectionsIconInactive"
      ></wp-portal-icon>
    </ng-container>

    <ng-container *ngSwitchDefault> {{type}} </ng-container>
  </ng-container>
</ng-container>
