import { createAction, props } from '@ngrx/store';
import * as fromRootTypes from '@rootTypes';
import { GetAuthenticationProviderResponse } from '../../../api/endpoints/get-authentication-provider';
import { WpError } from '@rootTypes';

// set user role
export const setUserRole = createAction(
  '[Auth]: Set user role',
  props<{
    userRole: fromRootTypes.auth.UserRole;
  }>(),
);

// login
const loginPage = '[LoginPage]';

export const editEmailForLogin = createAction(`${loginPage}: Edit email for login`);

export const getAuthProviderRequested = createAction(
  `${loginPage}: Get auth provider requested`,
  props<{ email: string; relayState?: string }>(),
);
export const getAuthProviderSuccess = createAction(
  `${loginPage}: Get auth provider success`,
  props<{ response: GetAuthenticationProviderResponse }>(),
);
export const getAuthProviderFailed = createAction(
  `${loginPage}: Get auth provider failed`,
  props<{ error: WpError }>(),
);

export const loginWithPasswordRequested = createAction(
  `${loginPage}: Login with password requested`,
  props<{ password: string }>(),
);
export const loginWithPasswordSuccess = createAction(
  `${loginPage}: Login with password success`,
  props<{ firebaseUserId: string }>(),
);
export const loginWithPasswordFailed = createAction(
  `${loginPage}: Login with password failed`,
  props<{ error: fromRootTypes.WpError }>(),
);

export const loginWithTokenRequested = createAction(
  `${loginPage}: Login with token`,
  props<{
    token: string;
    relayState?: string;
  }>(),
);
export const loginWithTokenSuccess = createAction(
  `${loginPage}: Login with token success`,
  props<{ firebaseUserId: string }>(),
);
export const loginWithTokenFailed = createAction(
  `${loginPage}: Login with token failed`,
  props<{ error: fromRootTypes.WpError }>(),
);

// check auth
export const checkAuthRequested = createAction('[Auth]: Check auth requested');

export const checkAuthSuccess = createAction(
  '[Auth]: Check auth success',
  props<{ firebaseUserId: string | null }>(), // null, if not authorized
);

export const checkAuthFailed = createAction('[Auth]: Check auth failed', props<{ error: fromRootTypes.WpError }>());

// logout
export const logoutPageRequested = createAction('[Auth]: Logout Page requested');

export const homePageAutoLogoutRequested = createAction(
  '[HomePage]: Auto logout requested',
  props<{ pathToEnterAfterLogin: string }>(),
);

export const accountTimezoneErrorLogoutRequested = createAction('[AccountTimezoneError]: logout requested');

export const logoutPageLogoutRequested = createAction('[LogoutPage]: Logout requested');

export const logoutPageLogoutSuccess = createAction('[LogoutPage]: Logout success');

export const logoutPageLogoutFailed = createAction(
  '[LogoutPage]: Logout failed',
  props<{ error: fromRootTypes.WpError }>(),
);

export const logoutOnEmployeeImpersonationSessionEnd = createAction(
  '[EmployeeImpersonationSessionTimer]: Logout on session ended',
);

export const homePageInitialized = createAction('[HomePage]: Initialized');
