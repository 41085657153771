import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { RouteStatusType } from '@rootTypes/entities/route';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';
import { Injectable } from '@angular/core';

export interface RouteListRouteData {
  statusType: string;
  currentPage?: string;
  pageSize?: string;
  filters?: string;
}

export const routeList: PortalRoute<RouteListRouteData> = {
  path: 'list/:statusType',
  isOnRoute: (state: RouterStateUrl) => {
    return state.url.startsWith('/routes/list');
  },
  parse: (state: RouterStateUrl) => {
    let res = {
      statusType: state.params.statusType,
    } as RouteListRouteData;
    if (state.queryParams) {
      res = {
        ...res,
        ...state.queryParams,
      };
    }
    return res;
  },
  request: (data: RouteListRouteData, queryParamStrategy?: QueryParamsHandlingStrategy) => {
    // do not alter the original object
    const copy = { ...data };
    const { statusType } = copy;
    const res = {
      path: ['routes', 'list', statusType],
    } as NavigationRequest;

    delete copy.statusType;
    // assign remaining data items as query
    if (Object.keys(copy).length) {
      res.query = { ...copy };
    }
    if (queryParamStrategy) {
      res.extras = {
        queryParamsHandling: queryParamStrategy,
      };
    }
    return res;
  },
  getHref(data?: RouteListRouteData): string {
    const statusType = data?.statusType || RouteStatusType.ACTIVE;
    return `/routes/list/${statusType}`;
  },
};

@Injectable({ providedIn: 'root' })
export class RouteListRouterService extends PortalRouterService<RouteListRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route = routeList;
}
