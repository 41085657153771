import { StudentRfidListState } from '../types/student-rfid-list';

export const createInitialStudentRfidListState = (): StudentRfidListState => {
  return {
    params: {
      page: 0,
      pageSize: 10,
      filters: [],
      searchQuery: undefined,
      status: undefined,
      listByEntity: undefined,
      selectedItemId: undefined,
    },
    api: { isLoading: false, error: undefined, items: [], total: undefined },
  };
};
