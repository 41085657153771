import { createAction, props } from '@ngrx/store';
import * as fromTypes from '@rootTypes';
import { LoadDistrictOptions } from '../../types';
import { WpError } from '@rootTypes';
import { BusAddress } from '@apiEntities/district/bus-address';
import { DistrictProgram } from '@apiEntities/district/district-programs';
import { ArrivalDepartureTimeBuffer } from '@apiEntities/campus-programs';

export type LoadDistrictSuccessPayload = {
  district: fromTypes.district.District;
  program?: DistrictProgram;
  defaultArrivalDepartureTimeBuffer?: ArrivalDepartureTimeBuffer;
};

const moduleKey = 'Data';
const submoduleKey = 'Districts';

/**
 * Load district requested
 */
export const loadDistrictRequested = createAction(
  `[${moduleKey}] [${submoduleKey}]: Load district requested`,
  props<{ districtId: string; forceLoading?: boolean; options?: LoadDistrictOptions }>(),
);

export const loadDistrictRequestedOnCreateCampusDistrictChange = createAction(
  `[CampusEditorEffects]: API Load district requested on campus district change`,
  props<{ districtId: string; forceLoading?: boolean; options?: LoadDistrictOptions }>(),
);

export const loadDistrictRequestedOnInitCampusCalendarEditor = createAction(
  `[CampusCalendarEditorEffects]: API Load district requested on init`,
  props<{ districtId: string; forceLoading?: boolean; options?: LoadDistrictOptions }>(),
);

export const loadDistrictRequestedFromStudentDetails = createAction(
  `[${moduleKey}] [${submoduleKey}]: Load district requested from student details`,
  props<{ districtId: string; forceLoading?: boolean; options?: LoadDistrictOptions }>(),
);

export const loadDistrictSuccess = createAction(
  `[${moduleKey}] [${submoduleKey}]: Load district success`,
  props<{ districtId: string; payload: LoadDistrictSuccessPayload }>(),
);

export const loadDistrictFailed = createAction(
  `[${moduleKey}] [${submoduleKey}]: Load district failed`,
  props<{ districtId: string; error: fromTypes.WpError }>(),
);

/**
 * Load all possible district checks
 */
export const loadAllDistrictChecksRequested = createAction(
  `[${moduleKey}] [${submoduleKey}]: Load all district checks requested`,
);

export const loadAllDistrictChecksSuccess = createAction(
  `[${moduleKey}] [${submoduleKey}]: Load all district checks success`,
  props<{ checks: fromTypes.Check[] }>(),
);

export const loadAllDistrictChecksFailed = createAction(
  `[${moduleKey}] [${submoduleKey}]: Load all district checks failed`,
  props<{ error: fromTypes.WpError }>(),
);

/**
 * Load district bus addresses
 */
export const getBusAddressesSuccess = createAction(
  `[Districts] [API]: Get district bus addresses success`,
  props<{ districtId: string; addresses: BusAddress[] }>(),
);

export const getBusAddressesFailed = createAction(
  `[Districts] [API]: Get district bus addresses failed`,
  props<{ districtId: string; error: WpError }>(),
);
