import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../actions/campus-calendar-data.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CampusApiService } from '../../services/campus-api.service';
import { of } from 'rxjs';

@Injectable()
export class CampusCalendarsDataEffects {
  constructor(
    private actions$: Actions,
    private api: CampusApiService,
  ) {}

  /**
   * Load calendars requested
   */
  public loadCampusCalendarsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.loadCampusCalendarsRequested,
        fromActions.loadCampusCalendarsRequestedFromCampusCalendarEditor,
        fromActions.loadCampusCalendarsRequestedFromCampusScheduleEditor,
      ),
      mergeMap((action) => {
        const { campusId, districtId } = action;
        return this.api.getCampusCalendars(campusId, districtId).pipe(
          map((res) => fromActions.loadCampusCalendarsSuccess({ campusId, payload: res })),
          catchError((err) => {
            console.log(err);
            return of(
              fromActions.loadCampusCalendarsFailed({
                campusId,
                error: { text: 'Failed to load campus calendars', originalError: err },
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * Make calendar default
   */
  public makeCalendarDefaultRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.makeCalendarDefaultRequested),
      mergeMap((action) => {
        const { calendarId, campusId, districtId } = action;
        return this.api.makeCalendarDefault(calendarId, campusId, districtId).pipe(
          map((res) => fromActions.makeCalendarDefaultSuccess({ campusId, calendarId })),
          catchError((err) => {
            console.log(err);
            return of(
              fromActions.makeCalendarDefaultFailed({
                campusId,
                calendarId,
                error: {
                  text: 'Failed to make calendar default',
                  originalError: err,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * Remove calendar
   */
  public removeCalendarRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeCalendarRequested),
      mergeMap((action) => {
        const { calendarId, campusId, districtId } = action;
        return this.api.deactivateCampusCalendar(calendarId, campusId, districtId).pipe(
          map((res) => fromActions.removeCalendarSuccess({ campusId, calendarId, districtId })),
          catchError((err) => {
            console.log(err);
            return of(
              fromActions.removeCalendarFailed({
                campusId,
                calendarId,
                districtId,
                error: {
                  text: 'Failed to deactivate campus calendar',
                  originalError: err,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * Remove schedule
   */
  public removeScheduleRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeScheduleRequested),
      mergeMap((action) => {
        const { calendarId, campusId, districtId, scheduleId } = action;
        return this.api.deactivateCampusSchedule(scheduleId, calendarId, campusId, districtId).pipe(
          map((res) => fromActions.removeScheduleSuccess({ scheduleId, campusId, calendarId, districtId })),
          catchError((err) => {
            console.log(err);
            return of(
              fromActions.removeScheduleFailed({
                scheduleId,
                campusId,
                calendarId,
                districtId,
                error: {
                  text: 'Failed to deactivate campus schedule',
                  originalError: err,
                },
              }),
            );
          }),
        );
      }),
    ),
  );
}
