<div
  class="square-border"
  [ngStyle]="{ width: width }"
  [ngClass]="{ 'open': isOpen$ | async, disabled: disabled }"
  (click)="$event.stopPropagation(); openDropdown()"
>
  <div class="wp-dropdown">
    <div style="width: 100%">
      <wp-dropdown-trigger [disabled]="disabled" [triggerSpread]="true">
        <div
          class="wp-dropdown__input-text"
          *ngIf="displayText$ | async; let optionText"
        >
          {{ optionText }}
        </div>
        <div
          class="wp-dropdown__input-label"
          *ngIf="!(displayText$ | async)"
          [ngClass]="{'option-selected': setOptionSelected}"
        >
          {{ label }}
        </div>
      </wp-dropdown-trigger>
    </div>
    <div
      class="wp-dropdown__pane"
      [ngClass]="{
        'align-left': alignDropdown === 'left',
        'align-right': alignDropdown === 'right',
        'align-center': alignDropdown === 'center'
      }"
      *ngIf="isOpen$ | async"
      wpClickOutside
      (clickedOutside)="closeDropdown()"
    >
      <ng-container *ngIf="dropdownTemplate; else dropdownOptions;">
        <wp-dropdown-pane>
          <ng-template [ngTemplateOutlet]="dropdownTemplate"></ng-template>
        </wp-dropdown-pane>
      </ng-container>
      <ng-template #dropdownOptions>
        <wp-dropdown-pane>
          <wp-dropdown-option
            *ngFor="let option of options"
            (click)="$event.stopPropagation(); onOptionClick(option)"
            [selected]="(selectedOption$ | async)?.value === option?.value"
          >
            {{ option.displayLabel }}
          </wp-dropdown-option>
        </wp-dropdown-pane>
      </ng-template>
    </div>
  </div>
</div>
