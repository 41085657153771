<div class="wp-alert critical">
  <div class="icon-wrap">
    <div class="icon">
      <wp-icon-alert-critical></wp-icon-alert-critical>
    </div>
  </div>

  <div class="text">
    <ng-content></ng-content>
  </div>

  <div class="close-btn" *ngIf="!readonly">
    <wp-icon-close [size]="'small'" (click)="closed.emit()"></wp-icon-close>
  </div>
</div>
