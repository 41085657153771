import { createReducer, on } from '@ngrx/store';
import * as fromTypes from '../../types';
import * as fromActions from '../actions/employee-data.actions';

export interface EmployeeDataState {
  employees: {
    [employeeId: string]: fromTypes.EntityState<fromTypes.VendorEmployee>;
  };
  permissions: {
    [employeeId: string]: fromTypes.EmployeePermission[];
  };
  permissionOptions: {
    [employeeId: string]: fromTypes.EntityState<fromTypes.PortalEntity[]>;
  };
}

export const initialEmployeeDataState: EmployeeDataState = {
  employees: {},
  permissions: {},
  permissionOptions: {},
};

export const employeeDataReducer = createReducer<EmployeeDataState>(
  { ...initialEmployeeDataState },
  /**
   * Load employee item
   */
  on(fromActions.loadEmployeeRequested, (state, action) => {
    const { employeeId } = action;
    const employeeEntityState = state.employees[employeeId] || fromTypes.utils.createEntityState(undefined, undefined);
    const result: EmployeeDataState = {
      ...state,
      employees: {
        ...state.employees,
        [employeeId]: {
          ...employeeEntityState,
          isLoading: true,
        },
      },
    };
    return result;
  }),
  on(fromActions.loadEmployeeSuccess, (state, action) => {
    const { employeeId, employee, permissions } = action;
    const employeeEntityState = state.employees[employeeId] || fromTypes.utils.createEntityState(undefined, undefined);
    let result: EmployeeDataState = {
      ...state,
      employees: {
        ...state.employees,
        [employeeId]: {
          ...employeeEntityState,
          isLoading: false,
          entity: employee,
        },
      },
    };
    if (permissions) {
      result = {
        ...result,
        permissions: {
          ...result.permissions,
          [employeeId]: permissions,
        },
      };
    }
    return result;
  }),
  on(fromActions.loadEmployeeFailed, (state, action) => {
    const { employeeId, error } = action;
    const employeeEntityState = state.employees[employeeId] || fromTypes.utils.createEntityState(undefined, undefined);
    const result: EmployeeDataState = {
      ...state,
      employees: {
        ...state.employees,
        [employeeId]: {
          ...employeeEntityState,
          isLoading: false,
          error,
        },
      },
    };
    return result;
  }),
  // permission options
  on(fromActions.loadPermissionOptionsRequested, (state, action) => {
    const { employeeId } = action;
    const employeeEntityState = state.permissionOptions[employeeId] || fromTypes.utils.createEntityState();
    const result: EmployeeDataState = {
      ...state,
      permissionOptions: {
        ...state.permissionOptions,
        [employeeId]: {
          ...employeeEntityState,
          isLoading: true,
        },
      },
    };
    return result;
  }),
  on(fromActions.loadPermissionOptionsSuccess, (state, action) => {
    const { employeeId, options } = action;
    const employeeEntityState = state.permissionOptions[employeeId] || fromTypes.utils.createEntityState();
    const result: EmployeeDataState = {
      ...state,
      permissionOptions: {
        ...state.permissionOptions,
        [employeeId]: {
          ...employeeEntityState,
          isLoading: false,
          entity: options,
        },
      },
    };
    return result;
  }),
  on(fromActions.loadPermissionOptionsFailed, (state, action) => {
    const { employeeId, error } = action;
    const employeeEntityState = state.permissionOptions[employeeId] || fromTypes.utils.createEntityState();
    const result: EmployeeDataState = {
      ...state,
      permissionOptions: {
        ...state.permissionOptions,
        [employeeId]: {
          ...employeeEntityState,
          isLoading: false,
          error,
        },
      },
    };
    return result;
  }),
);
