import { QueryParamsHandlingStrategy } from '../../../router/types/portal-route';
import { NavigationRequest } from '../../../router/types/navigation-request';

/**
 *
 * @param path - e.g. [ drawer, :yardId, details, :tabId ],
 * if any path segment starts with ':', it will be replaced
 * with the respective item from routeData
 *
 * @param data
 * @param defaultValues
 * @param queryParamsHandling
 * @param replaceUrl
 */
export function getRoutingRequest<D extends Object>(
  path: string[],
  routeData: D = {} as D,
  queryParamsHandling: QueryParamsHandlingStrategy = 'merge',
  replaceUrl = false,
  defaultValues?: Partial<D>,
): NavigationRequest {
  const queryParams = { ...routeData };
  if (defaultValues) {
    Object.keys(defaultValues).forEach((defaultKey) => {
      if (!(defaultKey in queryParams)) {
        queryParams[defaultKey] = defaultValues[defaultKey];
      }
    });
  }
  const targetPath = path.map((source) => {
    // e.g. if ':tab', take 'tab' from routeData
    if (source.startsWith(':')) {
      const fieldName = source.slice(1);
      const segment = routeData[fieldName];
      // since it is a path value,
      // remove it from queryParams
      delete queryParams[fieldName];
      return segment;
    } else {
      return source;
    }
  });
  return {
    path: targetPath,
    query: queryParams,
    extras: {
      queryParamsHandling,
      replaceUrl,
    },
  };
}
