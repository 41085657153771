<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <g fill="none" fill-rule="evenodd">
    <g stroke="#979797" [ngClass]="{ green: green }">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      fill-rule="nonzero"
                      stroke-width="2"
                      d="M16 20H6c.552 0 1 .448 1 1s-.448 1-1 1H5c-.552 0-1-.448-1-1s.448-1 1-1H4V9.994C4 9.474 4.523 9 5.024 9h11.973C17.55 9 18 9.528 18 10.024V20h-1c.552 0 1 .448 1 1s-.448 1-1 1h-1c-.552 0-1-.448-1-1s.448-1 1-1z"
                      transform="translate(-19 -289) translate(0 85) translate(0 192) translate(19 12) translate(1 1)"
                    />
                    <path
                      fill-rule="nonzero"
                      d="M6.5 11.5h9v4h-9v-4z"
                      transform="translate(-19 -289) translate(0 85) translate(0 192) translate(19 12) translate(1 1)"
                    />
                    <path
                      fill="#D8D8D8"
                      d="M6.5 17.5H7.5V18.5H6.5zM14.5 17.5H15.5V18.5H14.5z"
                      transform="translate(-19 -289) translate(0 85) translate(0 192) translate(19 12) translate(1 1)"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M0 22L0 7.15 11 0 22 7.15 22 22"
                      transform="translate(-19 -289) translate(0 85) translate(0 192) translate(19 12) translate(1 1)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
