import { EntityFilterOptions, EntityFilterRequest } from '../../../../api/endpoints/entity-filter';
import { PortalEntityType } from '../../../../types';
import { EmployeeListEntityFilter, EmployeeListFilterType, EmployeeListSnapshot } from '../entities';
import { getEntityFilterRequest } from '@rootTypes/utils/portal-entity/get-entity-filter-request';
import { EntitySearchResponseType } from '@apiEntities/entity-search';

export const getEmployeeListRequest = (snapshot: EmployeeListSnapshot): EntityFilterRequest => {
  const { page, pageSize, search, status, filters } = snapshot;
  const skip = page * pageSize;
  const limit = pageSize;
  const entityFilters: EmployeeListEntityFilter[] = (filters || []).filter(
    (f) => f.type === EmployeeListFilterType.ENTITY,
  );
  const vendorIds = entityFilters
    .filter((f) => f.payload.type === PortalEntityType.VENDOR)
    .map((f) => f.payload.entityId);
  const yardIds = entityFilters.filter((f) => f.payload.type === PortalEntityType.YARD).map((f) => f.payload.entityId);
  const options: EntityFilterOptions = {};
  if (vendorIds.length) {
    options.vendorIds = vendorIds;
  }
  if (yardIds.length) {
    options.yardIds = yardIds;
  }
  return getEntityFilterRequest(search, EntitySearchResponseType.VENDOR_EMPLOYEE, status, skip, limit, options);
};
