<div class="wrap" [ngClass]="{ 'align-left-to-parent': alignLeftToParent }">
  <div
    *ngIf="color"
    class="status-circle"
    [ngStyle]="{
      backgroundColor: bgColor,
      border: border
    }"
    [matTooltip]="tooltip"
    [matTooltipDisabled]="!tooltip"
    matTooltipPosition="above"
  ></div>
  <div class="text">
    <ng-content></ng-content>
  </div>
</div>
