<wp-common-popup-page>
  <wp-common-popup-page-top
    (closeClicked)="closeClicked.emit()"
    [isSeparatorLine]="isSeparatorLine"
  >
    <wp-common-popup-page-title>
      <ng-content select=".title"></ng-content>
    </wp-common-popup-page-title>
  </wp-common-popup-page-top>
  <wp-common-popup-page-body>
    <wp-stack-layout
      [direction]="'column'"
      [spacing]="30"
      [secondaryAxisAlignment]="'flex-end'"
    >
      <wp-stack-layout-item>
        <ng-content select=".content"></ng-content>
      </wp-stack-layout-item>

      <wp-stack-layout-item>
        <wp-common-popup-buttons
          [confirmButtonLabel]="confirmButtonLabel"
          [confirmButtonColor]="confirmButtonColor"
          [cancelButtonLabel]="cancelButtonLabel"
          [isConfirmLoading]="isConfirmLoading"
          [isCancelButton]="isCancelButton"
          [confirmButtonDisabled]="isConfirmButtonDisabled"
          (confirmClicked)="confirmButtonClicked.emit()"
          (cancelClicked)="cancelButtonClicked.emit()"
        ></wp-common-popup-buttons>
      </wp-stack-layout-item>
    </wp-stack-layout>
  </wp-common-popup-page-body>
</wp-common-popup-page>
