import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PlaceholderType } from '../types';

@Component({
  selector: 'wp-photo-placeholder',
  templateUrl: './photo-placeholder.component.html',
  styleUrls: ['./photo-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoPlaceholderComponent {
  @Input() public type!: PlaceholderType;
  @Input() public background: 'white' | 'grey' = 'grey';
  @Input() public borderStyle?: string;
  @Input() public width?: string;

  public typeOptions = PlaceholderType;
}
