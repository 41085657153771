import { VehicleInspectionMechanicAssessmentStatus } from '@apiEntities';

enum DisplayStatus {
  PENDING = 'Pending assessment',
  FIXED = 'Issues fixed',
  NOT_CRITICAL = 'Vehicle can operate safety',
}

export const displayVehicleInspectionMechanicAssessmentStatus = (
  status: VehicleInspectionMechanicAssessmentStatus,
): string => {
  switch (status) {
    case VehicleInspectionMechanicAssessmentStatus.FIXED:
      return DisplayStatus.FIXED;
    case VehicleInspectionMechanicAssessmentStatus.PENDING:
      return DisplayStatus.PENDING;
    case VehicleInspectionMechanicAssessmentStatus.NOT_CRITICAL:
      return DisplayStatus.NOT_CRITICAL;
    default:
      return undefined;
  }
};
