import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { SmartInputTransportationProvider } from '../models';

@Injectable()
export class SmartInputTransportationProviderService {
  public vendorLabel: string;
  public vendorControl: UntypedFormControl;
  public yardLabel: string;
  public yardControl: UntypedFormControl;
  public formGroupStateChange$: Observable<void>;
  public selectedVendorId$: Observable<string | undefined>;
  public required: boolean;
  public onVendorInputChanged: () => void;

  public initialize(model: SmartInputTransportationProvider): void {
    this.vendorLabel = model.vendorLabel;
    this.vendorControl = model.vendorControl;
    this.yardLabel = model.yardLabel;
    this.yardControl = model.yardControl;
    this.formGroupStateChange$ = model.formGroupStateChange$;
    this.selectedVendorId$ = model.selectedVendorId$;
    this.required = model.required;
    this.onVendorInputChanged = model.onVendorInputChanged.bind(model);
  }
}
