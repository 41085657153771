import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { SmartTextArea } from '../../models';

@Component({
  selector: 'wp-smart-textarea',
  templateUrl: './smart-textarea.component.html',
  styleUrls: ['./smart-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartTextareaComponent {
  @Input() public model: SmartTextArea;
  @Input() public characterCounter: boolean;
  @Output() public inputEvent = new EventEmitter<string>();
  @Output() public focusEvent = new EventEmitter<void>();
}
