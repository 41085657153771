import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import * as fromTypes from '../types';

@Component({
  selector: 'wp-search-option',
  templateUrl: './search-option.component.html',
  styleUrls: ['./search-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchOptionComponent implements OnInit {
  @Input() public entity: fromTypes.PortalEntity;
  public entityTypes = fromTypes.PortalEntityType;

  constructor() {}

  ngOnInit(): void {}
}
