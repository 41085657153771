import { PortalRoute } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import {
  deserializeBaseParamsFromUrl,
  FilterDeserializer,
} from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';
import { NavigationRequest } from '../../types/navigation-request';
import { serializeBaseParamsToUrl } from '@rootTypes/utils/list-store-utils/serialize-base-params-to-url';
import { getRoutingRequest } from '@rootTypes/utils/router/get-routing-request';
import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';
import { getInitialRouteGroupListState } from '../../../features/common/route-group-list/store/reducer';
import { RouteGroupListParams, RouteGroupQuickFilterIntf } from '../../../features/common/route-group-list/types';
import { RouteGroupQuickFilterType } from '@apiEntities/route-group/route-group';

export type MainRouteGroupListRouteData = Partial<RouteGroupListParams>;

const routeGroupQuickFilterDeserializer: FilterDeserializer<RouteGroupQuickFilterIntf> = (fid: string) => {
  const cast = fid as RouteGroupQuickFilterType;
  let parsed: RouteGroupQuickFilterType;
  switch (cast) {
    case RouteGroupQuickFilterType.NO_CHANGE:
      parsed = RouteGroupQuickFilterType.NO_CHANGE;
      break;
    case RouteGroupQuickFilterType.SHIFT_TIME_CHANGE:
      parsed = RouteGroupQuickFilterType.SHIFT_TIME_CHANGE;
      break;
    case RouteGroupQuickFilterType.ROUTE_CHANGE:
      parsed = RouteGroupQuickFilterType.ROUTE_CHANGE;
      break;
  }
  if (parsed) {
    return {
      id: parsed,
      type: 'quick-filter',
      payload: parsed,
    } as RouteGroupQuickFilterIntf;
  } else {
    return undefined;
  }
};

export const mainRouteGroupListRoute: PortalRoute<MainRouteGroupListRouteData> = {
  path: '',
  isOnRoute: (state: RouterStateUrl) => {
    throw new Error('Not implemented');
  },
  parse: (state: RouterStateUrl): MainRouteGroupListRouteData => {
    const query = state?.queryParams || {};
    const deserialized = deserializeBaseParamsFromUrl<RouteGroupListParams>(query, '', [
      routeGroupQuickFilterDeserializer,
    ]);
    // date filter should be displayed first
    if (deserialized?.filters?.length) {
      const dateFilter = deserialized?.filters.find((f) => f.type === 'date-range');
      if (dateFilter) {
        dateFilter.order = 0;
      }
    }
    return deserialized;
  },
  request: (data: MainRouteGroupListRouteData = {}, queryParamStrategy, replaceUrl): NavigationRequest => {
    const path = ['route-group', 'list'];
    const defaultState = getInitialRouteGroupListState().params;
    const serialized = serializeBaseParamsToUrl(data, defaultState);
    const req = getRoutingRequest(path, serialized, queryParamStrategy, replaceUrl, {
      status: undefined,
      page: undefined,
      pageSize: undefined,
      filters: undefined,
      quickFilter: undefined,
    });
    return req;
  },
};

@Injectable({
  providedIn: 'root',
})
export class MainRouteGroupListRouterService extends PortalRouterService<MainRouteGroupListRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route = mainRouteGroupListRoute;
}
