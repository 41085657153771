import { isSameDay } from 'date-fns';
export const dateIsSameOrBefore = (date: Date, compareDate: Date, granularity?: 'day'): boolean => {
  if (!granularity) {
    return date.getTime() <= compareDate.getTime();
  }
  switch (granularity) {
    case 'day':
      return isSameDay(date, compareDate) || date.getTime() < compareDate.getTime();
  }
};
