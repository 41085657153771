import { Directive, EventEmitter, Output, ElementRef } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Directive({
  selector: '[wpClickOutside]',
})
export class ClickOutsideDirective {
  private listening: boolean;
  private globalClick!: Subscription;

  @Output() private clickedOutside!: EventEmitter<Event>;

  constructor(private _elRef: ElementRef) {
    this.listening = false;
    this.clickedOutside = new EventEmitter<Event>();
  }

  public ngOnInit() {
    this.globalClick = fromEvent(document, 'click')
      .pipe(
        delay(1),
        tap(() => {
          this.listening = true;
        }),
      )
      .subscribe((event: Event) => {
        this.onGlobalClick(event);
      });
  }

  public ngOnDestroy() {
    this.globalClick.unsubscribe();
  }

  public onGlobalClick(event: Event) {
    if (event instanceof MouseEvent && this.listening === true) {
      if (this.isDescendant(this._elRef.nativeElement, event.target) === true) {
        return;
      } else {
        this.clickedOutside.emit(event);
      }
    }
  }

  private isDescendant(parent: any, child: any) {
    let node = child;
    while (node !== null) {
      if (node === parent) {
        return true;
      } else {
        node = node.parentNode;
      }
    }
    return false;
  }
}
