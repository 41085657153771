import { createReducer, on } from '@ngrx/store';
import * as fromTypes from '../../types';
import * as fromActions from '../actions/routes-data.actions';
import { GetRouteResponse } from '../../../../api/endpoints/get-route';
import { GetStudentSnapshotResponse } from '../../../../api/endpoints/get-student-snapshot';
import { DistrictProgram } from '@apiEntities/district/district-programs';

type RouteDrivers = fromTypes.Collection<fromTypes.RouteDriver>;
export type DriverEntityState = fromTypes.EntityState<fromTypes.Driver>;
export type RiderEntityState = fromTypes.EntityState<GetStudentSnapshotResponse>;
export type StopEntityState = fromTypes.EntityState<fromTypes.RouteStop>;
export type RouteEntityState = fromTypes.EntityState<fromTypes.Route>;
export type CalendarEntityState = fromTypes.EntityState<fromTypes.RouteCalendar>;

export interface RouteDataState {
  routes: {
    [routeId: string]: RouteEntityState;
  };
  routeStops: {
    [routeId: string]: {
      [stopId: string]: string;
    };
  };
  routeCalendars: {
    [routeId: string]: CalendarEntityState;
  };
  routeRiders: {
    [routeId: string]: fromTypes.Collection<string>;
  };
  routeRiderDOWSelection: {
    [routeId: string]: {
      [riderId: string]: GetRouteResponse['riders'][string]['dowSelection'] | undefined;
    };
  };
  routeDriversByWeekdays: {
    [routeId: string]: {
      [weekday: string]: RouteDrivers | null;
    };
  };
  stopRidersByWeekdays: {
    [stopId: string]: {
      [weekday: string]: {
        [riderId: string]: string;
      };
    };
  };
  riderStopsByWeekdays: {
    [routeId: string]: fromTypes.EventsByRidersWeekdayDependent;
  };
  riders: fromTypes.Collection<RiderEntityState>;
  stops: fromTypes.Collection<StopEntityState>;
  polylines: {
    [routeId: string]: fromTypes.EncodedPolyline;
  };
  districtProgramsByRoutes: {
    [routeId: string]: DistrictProgram;
  };
}

export const createInitialRouteDataState = (): RouteDataState => {
  return {
    routes: {},
    routeCalendars: {},
    routeDriversByWeekdays: {},
    routeStops: {},
    routeRiders: {},
    routeRiderDOWSelection: {},
    stopRidersByWeekdays: {},
    riderStopsByWeekdays: {},
    riders: {},
    stops: {},
    polylines: {},
    districtProgramsByRoutes: {},
  };
};

export const routeDataReducer = createReducer<RouteDataState>(
  createInitialRouteDataState(),
  on(fromTypes.cleanUpStore, createInitialRouteDataState),
  // load route
  on(fromActions.loadRouteRequested, (state, action) => {
    const { routeId } = action;
    const routeEntityState =
      state.routes[routeId] ||
      ({
        isLoading: false,
        entity: null,
        error: null,
      } as RouteEntityState);
    return {
      ...state,
      routes: {
        ...state.routes,
        [routeId]: {
          ...routeEntityState,
          isLoading: !routeEntityState.entity,
        },
      },
    };
  }),
  on(fromActions.loadedRouteSuccess, (state, action) => {
    const {
      route,
      routeDrivers,
      stops,
      riderIds,
      riderIdsByEvents,
      eventIdsByRiders,
      polyline,
      riderDOWSelection,
      districtProgram,
    } = action.payload;
    const { routeId } = route;
    const stopIdsCollection = stops.reduce((prev, curr) => ({ ...prev, [curr.stopId]: curr.stopId }), {});
    const stopsCollection = stops.reduce((prev, curr) => ({ ...prev, [curr.stopId]: { entity: curr } }), {});
    const riderEntityCollection = Object.keys(riderIds).reduce((prev, currId) => {
      // don't overwrite existing riders
      const entityState =
        state.riders[currId] ||
        ({
          isLoading: false,
          entity: null,
        } as RiderEntityState);
      return { ...prev, [currId]: entityState };
    }, {});
    const routeEntityState =
      state.routes[routeId] ||
      ({
        isLoading: false,
        entity: null,
        error: null,
      } as RouteEntityState);

    const newRouteDataState: RouteDataState = {
      ...state,
      routes: {
        ...state.routes,
        [routeId]: {
          ...routeEntityState,
          isLoading: false,
          entity: route,
        },
      },
      routeDriversByWeekdays: {
        ...state.routeDriversByWeekdays,
        [routeId]: routeDrivers,
      },
      riders: {
        ...state.riders,
        ...riderEntityCollection,
      },
      riderStopsByWeekdays: {
        ...state.riderStopsByWeekdays,
        [routeId]: {
          ...eventIdsByRiders,
        },
      },
      stopRidersByWeekdays: {
        ...state.stopRidersByWeekdays,
        ...riderIdsByEvents,
      },
      routeRiders: {
        ...state.routeRiders,
        [routeId]: riderIds,
      },
      routeRiderDOWSelection: {
        ...state.routeRiderDOWSelection,
        [routeId]: riderDOWSelection || {},
      },
      routeStops: {
        ...state.routeStops,
        [routeId]: { ...stopIdsCollection },
      },
      stops: {
        ...state.stops,
        ...stopsCollection,
      },
    };
    if (polyline) {
      newRouteDataState.polylines = {
        ...state.polylines,
        [routeId]: polyline,
      };
    }
    if (districtProgram) {
      newRouteDataState.districtProgramsByRoutes = {
        ...state.districtProgramsByRoutes,
        [routeId]: districtProgram,
      };
    }
    return newRouteDataState;
  }),
  on(fromActions.loadRouteFailed, (state, action) => {
    const { routeId, error } = action;
    const routerEntityState =
      state.routes[routeId] ||
      ({
        isLoading: false,
        entity: null,
        error: null,
      } as RouteEntityState);
    return {
      ...state,
      routes: {
        ...state.routes,
        [routeId]: {
          ...routerEntityState,
          isLoading: false,
          error,
        },
      },
    };
  }),
  // load rider basic profile
  on(fromActions.loadRiderIfEmptyRequested, (state, action) => {
    const { riderId } = action;
    const riderEntityState: RiderEntityState =
      state.riders[riderId] ||
      ({
        isLoading: false,
        entity: null,
        error: null,
      } as RiderEntityState);
    return {
      ...state,
      riders: {
        ...state.riders,
        [riderId]: {
          ...riderEntityState,
          isLoading: !riderEntityState.entity,
        },
      },
    };
  }),
  on(fromActions.loadedRiderIfEmptySuccess, (state, action) => {
    const { id: riderId } = action.rider;
    const riderEntityState =
      state.riders[riderId] ||
      ({
        isLoading: false,
        isFullProfileLoading: false,
        fullProfile: null,
        entity: null,
        error: null,
      } as RiderEntityState);
    return {
      ...state,
      riders: {
        ...state.riders,
        [riderId]: {
          ...riderEntityState,
          entity: action.rider,
          isLoading: false,
        },
      },
    };
  }),
  on(fromActions.loadedRiderIfEmptyFailed, (state, action) => {
    const { riderId, error } = action;
    const riderEntityState =
      state.riders[riderId] ||
      ({
        isLoading: false,
        isFullProfileLoading: false,
        fullProfile: null,
        entity: null,
        error: null,
      } as RiderEntityState);
    return {
      ...state,
      riders: {
        ...state.riders,
        [riderId]: {
          ...riderEntityState,
          error,
          isLoading: false,
        },
      },
    };
  }),
  // load route calendar
  on(fromActions.loadCalendarForRouteRequested, (state, action) => {
    const { routeId } = action;
    const calendarEntityState: CalendarEntityState = state.routeCalendars[routeId] || {
      isLoading: false,
      entity: null,
      error: null,
    };
    return {
      ...state,
      routeCalendars: {
        ...state.routeCalendars,
        [routeId]: {
          ...calendarEntityState,
          isLoading: !calendarEntityState.entity,
        },
      },
    };
  }),
  on(fromActions.loadedCalendarForRouteSuccessfully, (state, action) => {
    const { routeId, calendar } = action;
    const calendarEntityState: CalendarEntityState = state.routeCalendars[routeId] || {
      isLoading: false,
      entity: null,
      error: null,
    };
    return {
      ...state,
      routeCalendars: {
        ...state.routeCalendars,
        [routeId]: {
          ...calendarEntityState,
          isLoading: false,
          entity: calendar,
        },
      },
    };
  }),
  on(fromActions.loadCalendarForRouteFailed, (state, action) => {
    const { routeId, error } = action;
    const calendarEntityState: CalendarEntityState = state.routeCalendars[routeId] || {
      isLoading: false,
      entity: null,
      error: null,
    };
    return {
      ...state,
      routeCalendars: {
        ...state.routeCalendars,
        [routeId]: {
          ...calendarEntityState,
          isLoading: false,
          error,
        },
      },
    };
  }),
  // eof
);
