<div class="loading-content-error">
  <ng-container *ngIf="loading; else loadResult">
    <ng-template [ngTemplateOutlet]="loadingTemplate"></ng-template>
  </ng-container>
  <ng-template #loadResult>
    <ng-container *ngIf="error && errorTemplate; else loadedContent">
      <ng-template [ngTemplateOutlet]="errorTemplate"></ng-template>
    </ng-container>
    <ng-template #loadedContent>
      <ng-container *ngIf="!!(empty && emptyTemplate); else nonEmptyContent;">
        <ng-template [ngTemplateOutlet]="emptyTemplate"></ng-template>
      </ng-container>
      <ng-template #nonEmptyContent>
        <ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>
      </ng-template>
    </ng-template>
  </ng-template>
</div>
