import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-placeholder-box',
  templateUrl: './placeholder-box.component.html',
  styleUrls: ['./placeholder-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceholderBoxComponent implements OnInit {
  @Input() public height = '16px';
  @Input() public margin = '0px';

  constructor() {}

  ngOnInit(): void {}
}
