import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WpError } from '@rootTypes';

@Component({
  selector: 'wp-portal-pdf-viewer',
  template: `
    <div style="height: 0px; width: 100%; position: relative; padding: 20px 35px 20px 32px">
      <wp-popup-page-back-bar [pageTitle]="pageTitle" [displayBackText]="displayBackText"></wp-popup-page-back-bar>
    </div>
    <ng-container *ngIf="isLoading; else loaded">
      <wp-section-spinner [sectionHeight]="'100%'"></wp-section-spinner>
    </ng-container>
    <ng-template #loaded>
      <ng-container *ngIf="error; else pdf">
        <wp-section-error [error]="error" [sectionHeightStr]="'100%'"></wp-section-error>
      </ng-container>
      <ng-template #pdf>
        <iframe id="pdf-js-viewer" class="iframe" [src]="url" width="100%"></iframe>
      </ng-template>
    </ng-template>
  `,
  styles: [
    `
      .iframe {
        height: calc(100vh - 40px);
        border: none;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalPdfViewerComponent implements OnChanges {
  @Input() public pdfBlob: Blob;
  @Input() public isLoading: boolean;
  @Input() public error: WpError;
  @Input() public pageTitle: string;
  @Input() public displayBackText: string;

  public url: SafeResourceUrl;

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pdfBlob) {
      if (this.pdfBlob) {
        const resourceUrl = URL.createObjectURL(this.pdfBlob);
        const url = 'assets/pdfjs/web/viewer.html?file=' + encodeURIComponent(resourceUrl);
        this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
      } else {
        this.url = null;
      }
    }
  }
}
