export const objectToFormData = (obj: any): FormData => {
  return objectToFormDataRecursive(obj);
};
const objectToFormDataRecursive = (obj: any, form?: FormData, namespace?: string): FormData => {
  const fd = form || new FormData();
  let formKey;
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = `${namespace}[${property}]`;
      } else {
        formKey = property;
      }
      // if the property is an object, but not a File, use recursivity.
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        objectToFormDataRecursive(obj[property], fd, property);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  }
  return fd;
};
