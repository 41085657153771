import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconCalendarComponent } from './icon-calendar/icon-calendar.component';

@NgModule({
  declarations: [IconCalendarComponent],
  imports: [CommonModule],
  exports: [IconCalendarComponent],
})
export class CalendarIconModule {}
