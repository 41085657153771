import { NgModule } from '@angular/core';

import { driverAggregationFacades } from './facades';

@NgModule({
  declarations: [],
  providers: [...driverAggregationFacades],
  imports: [],
})
export class DriverAggregationModule {}
