import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[wpDoubleRangeSelectColor]',
})
export class DoubleRangeSelectColorDirective implements AfterViewInit, OnChanges {
  @Input() public doubleColorFloor = 0;
  @Input() public doubleColorCeil = 0;
  @Input() public doubleColorLowValue = 0;
  @Input() public doubleColorHighValue = 0;
  @Input() public colorLow: string;
  @Input() public colorHigh: string;

  private selectionElement: HTMLSpanElement;

  constructor(private elementRef: ElementRef<HTMLDivElement>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.doubleColorLowValue || changes.doubleColorHighValue) {
      this.updateDoubleColor();
    }
  }

  ngAfterViewInit(): void {
    this.selectionElement = this.elementRef.nativeElement.querySelector('.ngx-slider-selection');
    this.updateDoubleColor();
  }

  private updateDoubleColor(): void {
    if (!this.selectionElement) {
      return;
    }
    const { lowColor, highColor } = this.calculateDoubleColor();
    this.selectionElement.style.background = `linear-gradient(to right, ${this.colorLow} 0 ${lowColor}%, ${
      this.colorHigh
    } ${lowColor}% ${lowColor + highColor}%)`;
  }

  private calculateDoubleColor(): { lowColor: number; highColor: number } {
    const halfRangeWidth = (this.doubleColorCeil - this.doubleColorFloor) / 2;
    const halfRangeValue = this.doubleColorFloor + halfRangeWidth;
    if (this.doubleColorHighValue <= halfRangeValue) {
      return {
        lowColor: 100,
        highColor: 0,
      };
    } else if (this.doubleColorLowValue >= halfRangeValue) {
      return {
        lowColor: 0,
        highColor: 100,
      };
    } else {
      const lowColorAbs = Math.max(halfRangeValue - this.doubleColorLowValue, 0);
      const lowColorPercent = (lowColorAbs / (this.doubleColorHighValue - this.doubleColorLowValue)) * 100;
      const highColorPercent = 100 - lowColorPercent;
      return {
        lowColor: lowColorPercent,
        highColor: highColorPercent,
      };
    }
  }
}
