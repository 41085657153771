import { Routes } from '@angular/router';

import {
  switchAccountTransitionRoute,
  userProfileChangePasswordRoute,
  userProfileInfoEditRoute,
  userProfileRoute,
} from '../router/portal-routes/home';
import { ResetPasswordRequestComponent } from './containers/reset-password-request/reset-password-request.component';
import { SwitchAccountTransitionComponent } from './containers/switch-account-transition/switch-account-transition.component';
import { UserProfileChangePasswordComponent } from './containers/user-profile-change-password/user-profile-change-password.component';
import { UserProfileInfoEditComponent } from './containers/user-profile-info-edit/user-profile-info-edit.component';
import { UserProfilePreviewComponent } from './containers/user-profile-preview/user-profile-preview.component';
import { UserProfileComponent } from './containers/user-profile/user-profile.component';
import { PolylineViewerComponent } from './containers/polyline-viewer/polyline-viewer.component';
import { GoogleInitGuardService } from '../guards/google-init-guard.service';

export const homeChildRoutes: Routes = [
  {
    path: switchAccountTransitionRoute.path,
    component: SwitchAccountTransitionComponent,
  },
  {
    path: userProfileRoute.path,
    component: UserProfileComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: UserProfilePreviewComponent,
      },
      {
        path: userProfileInfoEditRoute.path,
        component: UserProfileInfoEditComponent,
      },
      {
        path: userProfileChangePasswordRoute.path,
        component: UserProfileChangePasswordComponent,
      },
    ],
  },
  {
    path: 'password-reset-request',
    component: ResetPasswordRequestComponent,
  },
  {
    path: 'ui-demo',
    loadChildren: () => import('./ui-demo/ui-demo.module').then((m) => m.UiDemoModule),
  },
  {
    path: 'polyline-viewer',
    canActivate: [GoogleInitGuardService],
    component: PolylineViewerComponent,
  },
];
