import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { DropdownModule } from './dropdown/dropdown.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { DateRangePickerModule } from './date-range-picker/date-range-picker.module';
import { MultiDatePickerModule } from './multi-date-picker/multi-date-picker.module';
import { AlertsModule } from '../alerts/alerts.module';
import { ComponentsModule } from '../components/components.module';

// components
import { InputComponent } from './input/input.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { InputPrefixComponent } from './input-prefix/input-prefix.component';
import { DateRangeFilterComponent } from './date-range-filter/date-range-filter.component';
import { InputSuffixComponent } from './input-suffix/input-suffix.component';
import { InputSearchComponent } from './input-search/input-search.component';
import { InputPhoneComponent } from './input-phone/input-phone.component';
import { MatNativeDateModule } from '@angular/material/core';
import { SelectComponent } from './select/select.component';
import { TextareaComponent } from './textarea/textarea.component';
import { InputAddressComponent } from './input-address/input-address.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { ToggleComponent } from './toggle/toggle.component';
import { InputDateComponent } from './input-date/input-date.component';
import { InputDateMultiComponent } from './input-date-multi/input-date-multi.component';
import { SelectYardComponent } from './select-yard/select-yard.component';
import { SelectServiceAreaComponent } from './select-service-area/select-service-area.component';
import { LabeledToggleComponent } from './labeled-toggle/labeled-toggle.component';
import { QuickFiltersSelectComponent } from './quick-filters-select/quick-filters-select.component';
import { InputMaskedComponent } from './input-masked/input-masked.component';
import { IconCloseModule } from '../icons/icon-close/icon-close.module';
import { SearchIconModule } from '../icons/search-icon/search-icon.module';
import { CalendarIconModule } from '../icons/calendar-icon/calendar-icon.module';
import { IconFilterSelectComponent } from './icon-filter-select/icon-filter-select.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { InputTimeIncrementComponent } from './input-time-increment/input-time-increment.component';
import { DirectivesModule } from '../directives/directives.module';
import { MatMenuModule } from '@angular/material/menu';
import { ArrowIconsModule } from '../icons/arrow-icons/arrow-icons.module';
import { WeekFilterComponent } from './week-filter/week-filter.component';
import { PipesModule } from '../pipes/pipes.module';
import { TypographyModule } from '../typography/typography.module';
import { DateFilterComponent } from './date-filter/date-filter.component';
import { InlineSelectNoLabelComponent } from './inline-select-no-label/inline-select-no-label.component';
import { AutocompleteSimpleComponent } from './autocomplete-simple/autocomplete-simple.component';
import { InlineSelectComponent } from './inline-select/inline-select.component';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { InputDateRangeComponent } from './input-date-range/input-date-range.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { InputAddressCustomPanelComponent } from './input-address-custom-panel/input-address-custom-panel.component';
import { GoogleMapModule } from '../google-map/google-map.module';

const components = [
  InputComponent,
  InputMaskedComponent,
  InputPrefixComponent,
  DateRangeFilterComponent,
  InputSuffixComponent,
  InputSearchComponent,
  InputPhoneComponent,
  SelectComponent,
  TextareaComponent,
  InputAddressComponent,
  InputNumberComponent,
  ToggleComponent,
  InputDateComponent,
  InputDateMultiComponent,
  SelectYardComponent,
  SelectServiceAreaComponent,
  LabeledToggleComponent,
  QuickFiltersSelectComponent,
  IconFilterSelectComponent,
  AutocompleteComponent,
  InlineSelectNoLabelComponent,
  InputTimeIncrementComponent,
  WeekFilterComponent,
  DateFilterComponent,
  AutocompleteSimpleComponent,
  InlineSelectComponent,
  InputDateRangeComponent,
  InputAddressCustomPanelComponent,
];

@NgModule({
  declarations: [
    ...components,
    ClickOutsideDirective,
    InputDateRangeComponent,
    MultiSelectComponent,
    InputDateRangeComponent,
    MultiSelectComponent,
  ],
  imports: [
    CommonModule,
    DateRangePickerModule,
    MultiDatePickerModule,
    DropdownModule,
    MatInputModule,
    MatNativeDateModule,
    MatIconModule,
    MatSelectModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    FormsModule,
    ButtonsModule,
    AlertsModule,
    ComponentsModule,
    IconCloseModule,
    SearchIconModule,
    CalendarIconModule,
    DirectivesModule,
    PipesModule,
    TypographyModule,
    MatMenuModule,
    ArrowIconsModule,
    NgxMaskDirective,
    GoogleMapModule,
  ],
  providers: [provideNgxMask()],
  exports: [...components, DropdownModule, DateRangePickerModule, MultiDatePickerModule, MultiSelectComponent],
})
export class FormControlsModule {}
