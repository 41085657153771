import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { SelectOption } from '../index';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { getRandomString } from '@rootTypes/utils';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'wp-autocomplete-simple',
  templateUrl: './autocomplete-simple.component.html',
  styleUrls: ['./autocomplete-simple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteSimpleComponent implements OnInit, OnChanges {
  // FormControl with a { value: string } | null | undefined;
  @Input() public control: FormControl<Partial<SelectOption> | null | undefined>;
  @Input() public label: string;
  @Input() public options: SelectOption[];
  @Output() public valueChanged = new EventEmitter<SelectOption>();

  public filteredOptions$: Observable<SelectOption[]>;
  private allOptions$ = new BehaviorSubject<SelectOption[]>([]);
  public autocompleteValue = getRandomString();

  constructor() {}

  ngOnInit(): void {
    const searchInputValue$ = this.control.valueChanges.pipe(
      startWith(this.control.value),
      map((value) => {
        if (!value) {
          return null;
        }
        return (typeof value === 'string' ? value : value.displayLabel) as string;
      }),
      map((str) => (str ? str.toLowerCase() : null)),
    );
    this.filteredOptions$ = combineLatest([searchInputValue$, this.allOptions$]).pipe(
      map(([search, options]) => {
        if (!search?.length) {
          return options.slice();
        } else {
          return options.filter((opt) => {
            return opt?.displayLabel?.toLowerCase().includes(search);
          });
        }
      }),
    );
    this.control.addValidators([this.selectValueFromListValidator]);
  }

  displayFn(option: SelectOption): string {
    return option && option.displayLabel ? option.displayLabel : '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.allOptions$.next(changes.options.currentValue || []);
      if (this.control.value && this.control.value.value && !this.control.value.displayLabel) {
        const option = this.allOptions$.value.find((opt) => opt.value === this.control.value.value);
        if (option && option.displayLabel) {
          this.control.setValue(option);
        }
      }
    }
  }

  onInputClear(): void {
    this.control.setValue(null);
    this.valueChanged.emit(null);
  }

  onOptionSelect(event: MatAutocompleteSelectedEvent): void {
    this.valueChanged.emit(event.option.value);
    this.control.setValue(event.option.value);
  }

  private selectValueFromListValidator(control: UntypedFormControl): null | any {
    if (control?.value) {
      if (typeof control.value === 'string') {
        return {
          selectFromList: 'Please select value from the list',
        };
      }
    }
    return null;
  }
}
