import { Pipe, PipeTransform } from '@angular/core';
import { ApiStudent, ApiStudentCampus } from '@apiEntities';

@Pipe({
  name: 'studentV2Campus',
})
export class StudentV2CampusPipe implements PipeTransform {
  transform(value: ApiStudent): ApiStudentCampus {
    if (value && value.campuses && value.campuses.length) {
      return value.campuses.find((c) => c.isDefaultProgram) ?? value.campuses[0];
    }
    return undefined;
  }
}
