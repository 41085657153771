import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';

import { UserProfileEditorFacade } from '../../store';

@Component({
  selector: 'wp-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserProfileEditorFacade],
})
export class UserProfileComponent implements OnInit {
  public photoBase64$: Observable<string>;
  public userInitials$: Observable<string>;
  public isPhotoLoading$: Observable<boolean>;

  constructor(private facade: UserProfileEditorFacade) {}

  public ngOnInit(): void {
    this.facade.init();
    this.photoBase64$ = this.facade.getUserPhotoBase64$();
    this.userInitials$ = this.facade.getUserInitials$();
    this.isPhotoLoading$ = this.facade.getIsPhotoLoading$();
  }

  public openUploadPhotoPopup(): void {
    this.facade.openUploadPhotoPopup();
  }
}
