import { StudentRfidListRequestTypeFilter } from '../types/student-rfid-list';
import { StudentRfidRequestType } from '@apiEntities';

export const createStudentRfidListRequestTypeFilter = (
  type?: StudentRfidRequestType,
): StudentRfidListRequestTypeFilter => {
  if (!type) {
    return undefined;
  }
  return {
    id: `request-type,${type}`,
    type: 'request-type',
    payload: type,
  };
};
