import { createReducer, on } from '@ngrx/store';

import { VendorDataState, createEntityState, cleanUpStore } from '../../types';
import * as fromActions from '../actions';

export const createInitialVendorState = (): VendorDataState => {
  return {
    vendors: {},
  };
};

export const vendorDataReducer = createReducer<VendorDataState>(
  createInitialVendorState(),
  on(cleanUpStore, createInitialVendorState),
  // load vendor
  on(fromActions.loadVendorRequested, (state, action) => {
    const { vendorId } = action;
    const vendorEntityState = state.vendors[vendorId] || createEntityState();
    return {
      ...state,
      vendors: {
        ...state.vendors,
        [vendorId]: {
          ...vendorEntityState,
          isLoading: true,
          error: undefined,
        },
      },
    };
  }),
  on(fromActions.loadVendorSuccess, (state, action) => {
    const { vendor, vendorId } = action;
    return {
      ...state,
      vendors: {
        ...state.vendors,
        [vendorId]: createEntityState(vendor),
      },
    };
  }),
  on(fromActions.loadVendorFailed, (state, action) => {
    const { vendorId, error } = action;
    return {
      ...state,
      vendors: {
        ...state.vendors,
        [vendorId]: createEntityState(undefined, error),
      },
    };
  }),
);
