import { createSelector } from '@ngrx/store';

import { EntityState, VendorDataState, Vendor } from '../../types';
import { getVendorFeature } from './get-feature';

const getVendorDataState = createSelector(getVendorFeature, (state): VendorDataState => state.data);

export const getAllVendorEntities = createSelector(getVendorDataState, (state) => state.vendors);

export const getVendorState = (vendorId: string) => {
  return createSelector(getAllVendorEntities, (state): EntityState<Vendor> => {
    return state[vendorId] || { isLoading: false };
  });
};

export const getVendorEntity = createSelector(
  getAllVendorEntities,
  (state, props: { vendorId: string }): EntityState<Vendor> | undefined => {
    return state[props.vendorId];
  },
);

export const getVendorLoading = createSelector(getVendorEntity, (state) => (state ? state.isLoading : false));

export const getVendorError = createSelector(getVendorEntity, (state) => state?.error);

export const getVendorErrorMessage = createSelector(getVendorError, (state) => state?.text);

export const getVendor = createSelector(getVendorEntity, (state) => state?.entity);

export const getVendorName = createSelector(getVendor, (state) => state?.name);
