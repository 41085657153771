import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { go } from '@rootStore';
import { userProfileChangePasswordRoute } from 'src/app/router/portal-routes/home';
import { isChangePasswordSuccess } from '../selectors';

@Injectable()
export class ChangePasswordPreviewFacade {
  constructor(private store: Store) {}

  public getIsJustUpdated$(): Observable<boolean> {
    return this.store.select(isChangePasswordSuccess);
  }

  public goChangePassword(): void {
    this.store.dispatch(go(userProfileChangePasswordRoute.request()));
  }
}
