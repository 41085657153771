import { MechanicAssessmentStatusQueryParam } from '../types';
import { VehicleInspectionMechanicAssessmentStatus } from '@apiEntities';

export const queryParamToMechanicAssessmentStatus = (
  query?: MechanicAssessmentStatusQueryParam,
): VehicleInspectionMechanicAssessmentStatus => {
  const map = {
    [MechanicAssessmentStatusQueryParam.PENDING]: VehicleInspectionMechanicAssessmentStatus.PENDING,
    [MechanicAssessmentStatusQueryParam.FIXED]: VehicleInspectionMechanicAssessmentStatus.FIXED,
    [MechanicAssessmentStatusQueryParam.NOT_CRITICAL]: VehicleInspectionMechanicAssessmentStatus.NOT_CRITICAL,
  };
  return map[query];
};
