import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'wp-field-error',
  templateUrl: './field-error.component.html',
  styleUrls: ['./field-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldErrorComponent implements OnInit, OnChanges {
  @Input() public error: string;
  @Input() public value: any;
  public isClicked = false;

  constructor() {}

  ngOnInit(): void {}

  public onIconClick(): void {
    this.isClicked = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.error && !changes.error.isFirstChange()) {
      this.isClicked = false;
    }
    if (changes.value && !changes.value.isFirstChange()) {
      this.isClicked = true;
    }
  }
}
