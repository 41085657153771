import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { State } from '@rootStore';
import { Store } from '@ngrx/store';

import * as fromAuthSelectors from '../auth/store/selectors';
import { filter, take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DrawerGuardService {
  constructor(private store: Store<State>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return combineLatest([
      this.store.select(fromAuthSelectors.authStatus),
      this.store.select(fromAuthSelectors.isCurrentUserProfileLoaded),
    ]).pipe(
      filter(([auth, profileLoaded]) => {
        if (profileLoaded && !auth.isLoading && auth.isUserAuthenticated) {
          return true;
        }
        return false;
      }),
      take(1),
      map(() => true),
    );
  }
}
