<div class="icon" [ngClass]="{ 'rotate-down': rotateDown }">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="14"
    viewBox="0 0 9 14"
  >
    <path
      fill="#636365"
      fill-rule="evenodd"
      attr.opacity="{{disabled ? 0.4 : 1}}"
      d="M.559 6.834c-.02-.294.09-.594.33-.82L6.5.754C6.942.339 7.66.342 8.098.752l-.225-.21c.441.413.44 1.083 0 1.497L2.768 6.823l5.103 4.784c.441.414.439 1.087.001 1.497l.225-.21c-.441.413-1.156.413-1.598-.001L.888 7.63c-.233-.219-.343-.51-.329-.797z"
    />
  </svg>
</div>
