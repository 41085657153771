import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PdfUtilsService {
  private isInitialized = false;
  private pdfjsLib: any;

  constructor() {}

  private initialize(): Promise<void> {
    if (!this.isInitialized) {
      return new Promise((resolve, reject) => {
        const scriptEl = document.createElement('script');
        scriptEl.src = '/assets/pdfjs/build/pdf.js';
        document.body.appendChild(scriptEl);
        scriptEl.onload = () => {
          this.pdfjsLib = (window as any).pdfjsLib;
          if (!this.pdfjsLib) {
            reject('pdfjsLib not found');
          } else {
            this.pdfjsLib.GlobalWorkerOptions.workerSrc = '/assets/pdfjs/build/pdf.worker.js';
            this.isInitialized = true;
            resolve();
          }
        };
        scriptEl.onerror = () => {};
        this.pdfjsLib = (window as any).pdfjsLib;
      });
    } else {
      return Promise.resolve();
    }
  }

  public async getPDFPageCount(file: File): Promise<number> {
    await this.initialize();
    const pdfDocument = await this.getPDFDocument(file);
    return pdfDocument.numPages;
  }

  private getPDFDocument(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const typedArray = new Uint8Array(fileReader.result as ArrayBuffer);
        this.pdfjsLib.getDocument(typedArray).promise.then(resolve, reject);
      };
      fileReader.readAsArrayBuffer(file);
    });
  }
}
