<wp-entity-filter
  [searchByType]="searchByType"
  [initialDisplayValue]="initialDisplayValue"
  [vendorIds]="vendorIds"
  [yardIds]="yardIds"
  [operatorIds]="operatorIds"
  [placeholder]="label"
  [optionTemplate]="optionTemplate"
  [entityStatus]="entityStatus"
  [disabled]="control.disabled"
  (selected)="onOptionSelect($event)"
></wp-entity-filter>

<!-- Option template. Mind how entity is bound to entity variable -->
<ng-template #optionTemplate let-entity="entity">
  <wp-vehicle-select-option [entity]="entity"></wp-vehicle-select-option>
</ng-template>
