import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';

import { go } from '@rootStore';
import { ApiService } from 'src/app/api/api.service';
import { CreatePasswordResetRequestRes } from 'src/app/api/endpoints/password-reset';
import { newUserPasswordSetupRoute, resetPasswordSetupRoute } from 'src/app/router/portal-routes/auth';

@Component({
  selector: 'wp-reset-password-request',
  templateUrl: './reset-password-request.component.html',
  styleUrls: ['./reset-password-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordRequestComponent {
  public loading = false;

  constructor(
    private api: ApiService,
    private store: Store,
  ) {}

  public async resetPassword(): Promise<void> {
    this.loading = true;
    const { token } = await this.sendPasswordResetRequest();
    this.store.dispatch(go(resetPasswordSetupRoute.request({ token })));
  }

  public async setupPasswordForNewUser(): Promise<void> {
    this.loading = true;
    const { token } = await this.sendPasswordResetRequest();
    window.location.replace(`https://school.devridezum.com/${newUserPasswordSetupRoute.path}?token=${token}`);
  }

  private sendPasswordResetRequest(): Promise<CreatePasswordResetRequestRes> {
    if (wpEnvironment.envName === 'development' || wpEnvironment.envName === 'sandbox') {
      return this.api.createPasswordResetRequest({ userId: '8gt4KVZT5wZr4g9WZeL1j5ZZnLD2' });
    }
    return Promise.reject();
  }
}
