import { createAction, props } from '@ngrx/store';
import { PortalEntityType, WpError } from '@rootTypes';
import { MultiAccountManagementOption } from '@rootTypes/entities/auth/multi-subaccount-management-option';

export const loadMultiAccountManagementOptionsRequested = createAction(
  '[MultiAccountManagementGuard]: Load multi account management options requested',
  props<{ entityType: PortalEntityType }>(),
);

export const loadMultiAccountManagementOptionsSuccess = createAction(
  '[MultiAccountManagementEffects]: Load multi account management options success',
  props<{ options: MultiAccountManagementOption[] }>(),
);

export const loadMultiAccountManagementOptionsFailed = createAction(
  '[MultiAccountManagementEffects]: Load multi account management options failed',
  props<{ error: WpError }>(),
);
