import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { NavigationRequest } from '../../types/navigation-request';
import { getRoutingRequest } from '@rootTypes/utils/router/get-routing-request';
import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';
import { createNewEntityPath } from '@rootTypes/utils/campus-info-editor';

export type CampusScheduleEditorRouteData = {
  campusId: string;
  calendarId: string;
  scheduleId?: string;
  displayBackText?: string;
};

export const campusScheduleEditorRoute: PortalRoute<CampusScheduleEditorRouteData> = {
  path: undefined,
  isOnRoute: (state: RouterStateUrl): boolean => {
    throw new Error('Not implemented');
  },
  parse: (state: RouterStateUrl): CampusScheduleEditorRouteData => {
    let data: CampusScheduleEditorRouteData = {
      campusId: state.drawer?.params?.campusId,
      calendarId: state.drawer?.params?.calendarId,
      scheduleId:
        state.drawer?.params?.scheduleId === createNewEntityPath ? undefined : state.drawer?.params?.scheduleId,
    } as CampusScheduleEditorRouteData;
    if (state.queryParams) {
      data = {
        ...data,
        ...state.queryParams,
      };
    }
    return data;
  },
  request: (
    data: CampusScheduleEditorRouteData,
    queryParamStrategy?: QueryParamsHandlingStrategy,
    replaceUrl?,
  ): NavigationRequest => {
    data.scheduleId = data.scheduleId ?? createNewEntityPath;
    const path = ['_', 'drawer', 'campuses', 'editor', 'schedule', ':campusId', ':calendarId', ':scheduleId'];
    return getRoutingRequest(path, data, queryParamStrategy, replaceUrl);
  },
};

@Injectable({ providedIn: 'root' })
export class CampusScheduleEditorRouterService extends PortalRouterService<CampusScheduleEditorRouteData> {
  outlet = RouterOutlets.DRAWER;
  route: PortalRoute<CampusScheduleEditorRouteData> = campusScheduleEditorRoute;
}
