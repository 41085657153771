import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const moduleKey = 'Employees School';
const submoduleKey = 'Data';

export const loadEmployeeRequested = createAction(
  `[${moduleKey}] [${submoduleKey}]: Load employee requested`,
  props<{ employeeId: string; options?: fromTypes.LoadEmployeeOptions }>(),
);

export const loadEmployeeSuccess = createAction(
  `[${moduleKey}] [${submoduleKey}]: Load employee success`,
  props<{ employeeId: string; employee: fromTypes.VendorEmployee; permissions?: fromTypes.EmployeePermission[] }>(),
);

export const loadEmployeeFailed = createAction(
  `[${moduleKey}] [${submoduleKey}]: Load employee failed`,
  props<{ employeeId: string; error: fromTypes.WpError }>(),
);

export const loadPermissionOptionsRequested = createAction(
  `[${moduleKey}] [${submoduleKey}]: Load permission options requested`,
  props<{ employeeId: string }>(),
);

export const loadPermissionOptionsSuccess = createAction(
  `[${moduleKey}] [${submoduleKey}]: Load permission options success`,
  props<{ employeeId: string; options: fromTypes.PortalEntity[] }>(),
);

export const loadPermissionOptionsFailed = createAction(
  `[${moduleKey}] [${submoduleKey}]: Load permission options failed`,
  props<{ employeeId: string; error: fromTypes.WpError }>(),
);

export const loadDistrictOnEmployeeRequested = createAction(
  `[${moduleKey}] [${submoduleKey}]: Load district on employee requested`,
  props<{ employeeId: string }>(),
);
