import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wp-popup-page-top-photo',
  templateUrl: './popup-page-top-photo.component.html',
  styleUrls: ['./popup-page-top-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupPageTopPhotoComponent {
  @Input() public borderBottom = true;
  @Input() public isCloseBtn = true;

  @Output() public closeClicked: EventEmitter<void> = new EventEmitter<void>();

  public onCloseClick(): void {
    this.closeClicked.emit();
  }
}
