import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import * as fromTypes from '@rootTypes';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';
import { Injectable } from '@angular/core';

export interface DistrictDetailsRouteData {
  districtId: string;
  tabId?: fromTypes.district.DistrictDetailsTabs;
  displayBackText?: string;
}

export const districtDetailsRoute: PortalRoute<DistrictDetailsRouteData> = {
  path: ':districtId/details',
  isOnRoute: (state: RouterStateUrl): boolean => {
    throw new Error('Not implemented');
  },
  parse: (state: RouterStateUrl): DistrictDetailsRouteData => {
    let data: DistrictDetailsRouteData = {
      districtId: state.drawer.params.districtId,
      tabId: getTabIdFromFromUrl(state.drawer.url),
    } as DistrictDetailsRouteData;
    if (state.queryParams) {
      data = {
        ...data,
        ...state.queryParams,
      };
    }
    return data;
  },
  request: (
    data: DistrictDetailsRouteData = {} as DistrictDetailsRouteData,
    queryParamStrategy?: QueryParamsHandlingStrategy,
    replaceUrl?,
  ): NavigationRequest => {
    const req: NavigationRequest = {
      path: [
        '_',
        'drawer',
        'districts',
        data.districtId,
        'details',
        data.tabId || fromTypes.district.DistrictDetailsTabs.DEFAULT,
      ],
    };
    delete data.districtId;
    delete data.tabId;
    const query = { ...data };
    if (Object.keys(query).length) {
      req.query = query;
    }
    if (queryParamStrategy) {
      req.extras = {
        queryParamsHandling: queryParamStrategy,
      };
    }
    if (replaceUrl) {
      req.extras = req.extras || {};
      req.extras.replaceUrl = true;
    }
    return req;
  },
};

function getTabIdFromFromUrl(url: string): fromTypes.district.DistrictDetailsTabs {
  if (!url) {
    return null;
  }
  const segments = url.split('/');
  return segments[5] as fromTypes.district.DistrictDetailsTabs;
}

@Injectable({ providedIn: 'root' })
export class DistrictDetailsRouterService extends PortalRouterService<DistrictDetailsRouteData> {
  outlet = RouterOutlets.DRAWER;
  route: PortalRoute<DistrictDetailsRouteData> = districtDetailsRoute;
}
