import { Injectable } from '@angular/core';
import { ApiService } from '../../../api/api.service';
import * as fromApi from '../../../api';
import * as fromTypes from '../types';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { entitySearchResultToPortalEntity } from '@rootTypes/utils/portal-entity/entity-search-result-to-portal-enity';

interface ListResult {
  totalFound: number;
  items: fromTypes.PortalEntity[];
}

@Injectable()
export class VendorApiService {
  constructor(private api: ApiService) {}

  public entityFilter(request: fromApi.entityFilter.EntityFilterRequest): Observable<ListResult> {
    return this.api.entityFilter(request).pipe(
      map((resp) => {
        return {
          totalFound: resp.total,
          items: resp.results.map((source) => entitySearchResultToPortalEntity(source)),
        };
      }),
    );
  }

  public getVendorProfile(vendorId: string): Observable<fromTypes.Vendor> {
    return this.api
      .getVendorProfile({ vendor_id: vendorId })
      .pipe(map((source) => fromApi.getVendorProfile.convertors.getVendorFromApi(source)));
  }
}
