import { Injectable } from '@angular/core';
import { concatLatestFrom } from '@ngrx/operators';
import {
  loadMultiAccountManagementOptionsFailed,
  loadMultiAccountManagementOptionsRequested,
  loadMultiAccountManagementOptionsSuccess,
} from '../actions/multi-account-management.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap } from 'rxjs';
import { ApiService } from '../../../api/api.service';
import { Observable, of } from 'rxjs';
import { MultiAccountManagementOption } from '@rootTypes/entities/auth/multi-subaccount-management-option';
import { PortalEntityType, WpError } from '@rootTypes/entities/common';
import { YardResult } from '@apiEntities/entity-search';
import { switchAccountPopupSetAccountSuccess } from '../actions';
import { Store } from '@ngrx/store';
import { selectMultiAccountManagementEntityType } from '../selectors/multi-account-management.selectors';
import { EntityFilterRequest, EntityFilterRequestType } from '../../../api/endpoints/entity-filter';

@Injectable()
export class MultiAccountManagementEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store,
  ) {}

  // load multi account manage options on switch account
  public setAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(switchAccountPopupSetAccountSuccess),
      concatLatestFrom(() => this.store.select(selectMultiAccountManagementEntityType)),
      filter(([_, entityType]) => !!entityType),
      map(([_, entityType]) => loadMultiAccountManagementOptionsRequested({ entityType })),
    ),
  );

  public loadMultiAccountManagementOptionsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMultiAccountManagementOptionsRequested),
      switchMap(({ entityType }) => {
        return this.getMultiAccountManagementOptionsForEntityType$(entityType).pipe(
          map((options) => loadMultiAccountManagementOptionsSuccess({ options })),
          catchError((originalError) => {
            console.log(originalError);
            const error: WpError = {
              originalError,
              text: 'Failed to load multi-account management options',
            };
            return of(loadMultiAccountManagementOptionsFailed({ error }));
          }),
        );
      }),
    ),
  );

  private getMultiAccountManagementOptionsForEntityType$(
    entityType: PortalEntityType,
  ): Observable<MultiAccountManagementOption[]> {
    const searchType = entityType === PortalEntityType.YARD ? EntityFilterRequestType.YARD : null;
    const request: EntityFilterRequest = {
      type: searchType,
      isSkipSelectedSubAccountFiltering: true,
      query: '',
      limit: 1000,
      skip: 0,
    };
    return this.api.entityFilter(request).pipe(
      map((response) => {
        return (response?.results || []).map((r: YardResult) => {
          return {
            value: r.yardId,
            displayLabel: r.label,
            meta: {
              type: PortalEntityType.YARD,
              operatorIds: r.operatorIds || [],
            },
          } satisfies MultiAccountManagementOption;
        });
      }),
    );
  }
}
