<ng-container *ngIf="loading; else primaryToggle;">
  <div class="primary-location">
    <div class="primary-location__label" *ngIf="label">{{ label }}</div>
    <div class="primary-location__label" *ngIf="!label">
      <ng-content></ng-content>
    </div>
    <div class="primary-location__toggle spinner">
      <wp-section-spinner [sectionHeight]="'35px'" [spinnerSize]="28"></wp-section-spinner>
    </div>
  </div>
</ng-container>
<ng-template #primaryToggle>
  <div
    class="primary-location"
    [ngClass]="{
          'highlight': !disabled && highlightOnHover,
           disabled: disabled
       }"
    (click)="onToggle(); $event.stopPropagation()"
  >
    <div class="highlight-mask"></div>
    <div
      class="mask"
      [ngClass]="{
            'cursor-pointer': !disabled
          }"
    ></div>
    <div class="label-holder">
      <div class="primary-location__label" *ngIf="label">{{ label }}</div>
      <div class="primary-location__hint" *ngIf="label && hint">{{hint}}</div>
    </div>
    <div class="primary-location__label" *ngIf="!label">
      <ng-content></ng-content>
    </div>
    <div class="primary-location__toggle">
      <wp-toggle [selected]="selected" [disabled]="disabled"></wp-toggle>
    </div>
  </div>
</ng-template>
