import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, tap, switchMap } from 'rxjs/operators';

import {
  currentUserInitials,
  currentUserPhotoBase64,
  isCurrentUserPhotoLoadingOrUpdating,
  updateUserPhotoRequested,
} from 'src/app/auth/store';

import {
  PhotoInputPopupComponent,
  PhotoInputPopupConfig,
  NewImageBase64,
} from 'src/app/shared/photos/photo-input-popup/photo-input-popup.component';

import { PopupableService } from 'src/app/core/popupable/popupable.service';
import { PlaceholderType } from 'src/app/shared/photos/types';
import { initUserProfileState } from '../actions';

@Injectable()
export class UserProfileEditorFacade {
  constructor(
    private store: Store,
    private popupableService: PopupableService,
  ) {}

  public getUserPhotoBase64$(): Observable<string> {
    return this.store.select(currentUserPhotoBase64);
  }

  public getUserInitials$(): Observable<string> {
    return this.store.select(currentUserInitials);
  }

  public init(): void {
    this.store.dispatch(initUserProfileState());
  }

  public openUploadPhotoPopup(): void {
    this.store
      .select(currentUserPhotoBase64)
      .pipe(
        take(1),
        switchMap((sourceImageBase64) => {
          return this.popupableService.openPopup<any, PhotoInputPopupConfig, NewImageBase64>(PhotoInputPopupComponent, {
            isSquareImage: true,
            imagePlaceholder: PlaceholderType.PERSON,
            resultImageWidthPx: 600,
            sourceImageBase64,
          });
        }),
        take(1),
        tap((newImageBase64) => {
          if (newImageBase64) {
            this.store.dispatch(updateUserPhotoRequested({ imageBase64: newImageBase64 }));
          }
        }),
      )
      .subscribe();
  }

  public getIsPhotoLoading$(): Observable<boolean> {
    return this.store.select(isCurrentUserPhotoLoadingOrUpdating);
  }
}
