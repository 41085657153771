import { SmartFormFieldTransportationProvider, SmartFormFieldType } from '@apiEntities/smart-forms';
import { SmartInputTransportationProvider } from 'src/app/shared/smart-forms';

export const isSmartFormFieldTransportationProvider = (arg: any): arg is SmartFormFieldTransportationProvider => {
  return !!arg && typeof arg === 'object' && arg.type === SmartFormFieldType.TRANSPORTATION_PROVIDER;
};

export const isSmartFormFieldTransportationProviderValid = (
  provider: SmartFormFieldTransportationProvider,
  required: boolean,
): boolean => {
  if (required || !!provider.value?.vendor?.id) {
    return !!provider.value?.vendor?.id && !!provider.value?.yard?.id;
  }
  return true;
};

export const smartFormFieldTransportationProviderToSmartInput = (
  field: SmartFormFieldTransportationProvider,
  required?: boolean,
  disabled?: boolean,
): SmartInputTransportationProvider => {
  return new SmartInputTransportationProvider({
    vendor: {
      label: field.vendorLabel,
      value: field.value?.vendor,
    },
    yard: {
      label: field.yardLabel,
      value: field.value?.yard,
    },
    required,
    disabled,
  });
};
