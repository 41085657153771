import { EntityState } from '@rootTypes';
import { RouteGroupScheduleChangeRequest } from '@apiEntities/route-group/route-group-schedule-change-request';
import { createReducer, on } from '@ngrx/store';
import {
  getRouteGroupScheduleChangeRequestFailed,
  getRouteGroupScheduleChangeRequestSuccess,
  getScheduleChangeRequestActions,
} from '../actions/route-group-schedule-change-request.actions';

export interface RouteGroupScheduleChangeRequestState {
  changeRequests: {
    [changeRequestId: string]: EntityState<RouteGroupScheduleChangeRequest>;
  };
}

export const getInitialRouteGroupScheduleChangeRequestState = (): RouteGroupScheduleChangeRequestState => {
  return {
    changeRequests: {},
  };
};

export const routeGroupScheduleChangeRequestReducer = createReducer(
  getInitialRouteGroupScheduleChangeRequestState(),
  on(...getScheduleChangeRequestActions, (state, action) => {
    const { changeRequestId } = action;
    const prevState = state.changeRequests[changeRequestId] || { isLoading: false };
    return {
      ...state,
      changeRequests: {
        ...state.changeRequests,
        [changeRequestId]: {
          ...prevState,
          isLoading: true,
        },
      },
    };
  }),
  on(getRouteGroupScheduleChangeRequestSuccess, (state, action) => {
    const { changeRequest } = action;
    const { changeRequestId } = changeRequest;
    return {
      ...state,
      changeRequests: {
        ...state.changeRequests,
        [changeRequestId]: {
          isLoading: false,
          entity: changeRequest,
        },
      },
    };
  }),
  on(getRouteGroupScheduleChangeRequestFailed, (state, action) => {
    const { changeRequestId, error } = action;
    return {
      ...state,
      changeRequests: {
        ...state.changeRequests,
        [changeRequestId]: {
          isLoading: false,
          error,
        },
      },
    };
  }),
);
