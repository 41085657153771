<div
  class="wp-dropdown"
  [ngClass]="{
  'position-relative': isDropdownPositionRelative
}"
>
  <ng-container *ngIf="!triggerTemplate; else customTriggerTemplate">
    <mat-form-field
      class="wp-select-wrap"
      (click)="$event.stopPropagation(); openDropdown()"
    >
      <mat-label *ngIf="!!label">{{ label }}</mat-label>
      <input
        matInput
        [formControl]="displayInputControl"
        autocomplete="off"
        tabindex="{{tabIndex}}"
      />
      <div matSuffix>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          class="dropdown-icon"
        >
          <g fill="#959597" fill-rule="evenodd">
            <path
              d="M1.318 7.182c-.39-.39-.391-1.023-.002-1.412L6.27.816c.39-.39 1.019-.391 1.412.002.39.39.391 1.023.002 1.412L2.73 7.184c-.39.39-1.019.391-1.412-.002z"
              transform="matrix(1 0 0 -1 0 12)"
            />
            <path
              d="M12.682 7.182c-.39.39-1.023.391-1.412.002L6.316 2.23c-.39-.39-.391-1.019.002-1.412.39-.39 1.023-.391 1.412-.002l4.954 4.954c.39.39.391 1.019-.002 1.412z"
              transform="matrix(1 0 0 -1 0 12)"
            />
          </g>
        </svg>
      </div>
    </mat-form-field>
  </ng-container>
  <ng-template #customTriggerTemplate>
    <div (click)="$event.stopPropagation(); openDropdown()">
      <ng-template [ngTemplateOutlet]="triggerTemplate"></ng-template>
    </div>
  </ng-template>

  <div
    class="wp-dropdown__pane"
    [ngClass]="{
      'align-left': alignDropdown === 'left',
      'align-right': alignDropdown === 'right',
      'align-center': alignDropdown === 'center',
      'full-width': alignDropdown === 'full-width'
    }"
    [ngStyle]="{ top: dropdownTopPosition, bottom: dropdownBottomPosition, width: dropdownWidth }"
    *ngIf="isOpen$ | async"
    wpClickOutside
    (clickedOutside)="onCloseDropdown()"
  >
    <ng-container *ngIf="dropdownTemplate; else dropdownOptions">
      <div (click)="onCloseDropdown()">
        <ng-template [ngTemplateOutlet]="dropdownTemplate"></ng-template>
      </div>
    </ng-container>
    <ng-template #dropdownOptions>
      <wp-dropdown-pane>
        <wp-dropdown-option
          *ngFor="let option of options"
          (click)="onOptionClick(option)"
          [disabled]="option.disabled"
          [selected]="(selectedOption$ | async)?.value === option?.value"
          (selectedWithKeyboard)="onOptionClick(option)"
        >
          {{ option.displayLabel }}
        </wp-dropdown-option>
      </wp-dropdown-pane>
    </ng-template>
  </div>
</div>
