import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import * as fromActions from '../actions/ride-list.actions';
import * as fromSelectors from '../selectors/ride-lists.selectors';
import * as fromTypes from '../../types';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class RideListFacadeService {
  constructor(
    private store: Store<State>,
    private actions$: Actions,
  ) {}

  public getCurrentPage$(listName: fromTypes.RideListName): Observable<number> {
    return this.store.select(fromSelectors.currentPageNumber(listName));
  }

  public getSerializeSnapshot(listName: fromTypes.RideListName): Observable<fromTypes.SerializedRideListSnapshot> {
    return this.store.select(fromSelectors.getRideListSerializedSnapshot(listName));
  }

  public getPageSize$(listName: fromTypes.RideListName): Observable<number> {
    return this.store.select(fromSelectors.pageSize(listName));
  }

  public listLoadRequested$(listName: fromTypes.RideListName): Observable<fromTypes.SerializedRideListSnapshot> {
    return this.actions$.pipe(
      ofType(fromActions.loadRidePageRequested),
      filter((a) => a.listName === listName),
      map((a) => a.snapshot),
      map((snapshot) => fromTypes.utils.serializeSnapshot(snapshot)),
    );
  }

  public listInitialized$(listName: fromTypes.RideListName): Observable<any> {
    return this.actions$.pipe(
      ofType(fromActions.rideListInitialized),
      filter((a) => a.listName === listName),
    );
  }

  /**
   * Will not return static filters
   * @param listName
   */
  public getFilters$(listName: fromTypes.RideListName): Observable<fromTypes.filters.RideFilter[]> {
    return this.store.select(fromSelectors.currentRideFilters(listName));
  }

  public getCurrentDateRange(listName: fromTypes.RideListName): Observable<fromTypes.DateRange> {
    return this.getFilters$(listName).pipe(
      map((filters) => {
        if (filters && filters.length) {
          const df = filters.find(
            (f) => f.type === fromTypes.filters.RideFilterType.DATE,
          ) as fromTypes.filters.DateRideFilter;
          if (df) {
            return {
              startDate: df.payload.startDate,
              endDate: df.payload.endDate,
            };
          }
          const todayRidesFilter = filters.find((f) => {
            if (f.type === fromTypes.filters.RideFilterType.QUICK_FILTER) {
              return (
                (f as fromTypes.filters.RideQuickFilter).payload ===
                fromTypes.filters.RideQuickFilterType.TODAY_RIDES_BY_ROUTE
              );
            }
            return false;
          });
          if (todayRidesFilter) {
            const today = fromTypes.utils.date.YearMonthDay.today();
            return {
              startDate: today,
              endDate: today,
            };
          }
        }
        return {
          startDate: null,
          endDate: null,
        };
      }),
    );
  }

  public getTimetable$(listName: fromTypes.RideListName): Observable<fromTypes.RideTimetable> {
    return this.store.select(fromSelectors.getCurrentRideTimetable(listName));
  }

  public getAccountId$(listName: fromTypes.RideListName): Observable<string> {
    return this.store.select(fromSelectors.getRideListAccountId(listName));
  }

  public getListIds$(listName: fromTypes.RideListName): Observable<string[]> {
    return this.store.select(fromSelectors.currentPageRideIds(listName));
  }

  public getListLoading$(listName: fromTypes.RideListName): Observable<boolean> {
    return this.store.select(fromSelectors.currentPageLoading(listName));
  }

  public getListError$(listName: fromTypes.RideListName): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.currentPageError(listName));
  }

  public getListEmpty$(listName: fromTypes.RideListName): Observable<boolean> {
    return combineLatest([this.getListIds$(listName), this.getListLoading$(listName)]).pipe(
      map(([list, loading]) => !loading && (list || []).length === 0),
    );
  }

  public getSelectedRideId$(listName: fromTypes.RideListName): Observable<string | null> {
    return this.store.select(fromSelectors.selectedRideId(listName));
  }

  public getSelectedQuickFilterType$(
    listName: fromTypes.RideListName,
  ): Observable<fromTypes.filters.RideQuickFilterType | undefined> {
    return this.store.select(fromSelectors.getSelectedQuickRideFilter(listName));
  }

  public getTotalFound$(listName: fromTypes.RideListName): Observable<number> {
    return this.store.select(fromSelectors.totalItems(listName));
  }

  public onCurrentPageChange(listName: fromTypes.RideListName, page: number): void {
    this.store.dispatch(fromActions.currentPageChanged({ listName, page }));
  }

  public onListInitialize(listName: fromTypes.RideListName): void {
    this.store.dispatch(fromActions.rideListInitialized({ listName }));
  }

  public onSetListStateFromSnapshot(
    listName: fromTypes.RideListName,
    snapshot: fromTypes.SerializedRideListSnapshot,
  ): void {
    this.store.dispatch(fromActions.setStateFromSerializedSnapshot({ listName, snapshot }));
  }

  public onListDestroyed(listName: fromTypes.RideListName): void {
    this.store.dispatch(fromActions.rideListDestroyed({ listName }));
  }

  public onPageSizeChange(listName: fromTypes.RideListName, pageSize: number): void {
    this.store.dispatch(fromActions.pageSizeChanged({ listName, pageSize }));
  }

  public onSetDateRange(listName: fromTypes.RideListName, range: fromTypes.DateRange): void {
    const { startDate, endDate } = range;
    const filter = {
      filterId: fromTypes.utils.getDateFilterId(startDate, endDate),
      type: fromTypes.filters.RideFilterType.DATE,
      payload: {
        startDate,
        endDate,
      },
    } as fromTypes.filters.DateRideFilter;
    this.store.dispatch(fromActions.filterByDatesSelected({ listName, filter }));
  }

  public onSetStaticFilters(
    listName: fromTypes.RideListName,
    entities: fromTypes.PortalEntity[] = [],
    dateRange: fromTypes.DateRange = null,
  ): void {
    const filters = [];
    if (dateRange && dateRange.startDate && dateRange.endDate) {
      const { startDate, endDate } = dateRange;
      const filter = {
        filterId: fromTypes.utils.getDateFilterId(startDate, endDate),
        type: fromTypes.filters.RideFilterType.DATE,
        payload: {
          startDate,
          endDate,
        },
      } as fromTypes.filters.DateRideFilter;
      filters.push(filter);
    }
    if (entities && entities.length) {
      entities.forEach((entity) => {
        const { type, label, entityId } = entity;
        const filter = {
          filterId: fromTypes.utils.getUserFilterId(type, entityId),
          type: fromTypes.filters.RideFilterType.USER,
          payload: {
            userId: entityId,
            userType: type,
          },
        } as fromTypes.filters.UserRideFilter;
        filters.push(filter);
      });
    }
    this.store.dispatch(fromActions.staticFiltersChanged({ listName, filters }));
  }

  public onSelectEntityFilter(listName: fromTypes.RideListName, entity: fromTypes.PortalEntity): void {
    const { type, label, entityId } = entity;
    const filter = {
      filterId: fromTypes.utils.getUserFilterId(type, entityId),
      type: fromTypes.filters.RideFilterType.USER,
      payload: {
        userId: entityId,
        userType: type,
        displayName: label,
      },
    } as fromTypes.filters.UserRideFilter;
    this.store.dispatch(fromActions.filterByEntitySelected({ listName, filter }));
  }

  public onSelectSearchHitFilter(listName: fromTypes.RideListName, hit: fromTypes.PortalEntitySearchHit): void {
    const { pillLabel, field, value } = hit;
    const filter = {
      filterId: fromTypes.utils.getSearchHitFilterId(hit),
      type: fromTypes.filters.RideFilterType.SEARCH_HIT_FILTER,
      payload: {
        pillLabel,
        field,
        value,
      },
    } as fromTypes.filters.SearchHitRideFilter;
    this.store.dispatch(fromActions.searchHitFilterSelected({ listName, filter }));
  }

  public onSelectQuickFilter(
    listName: fromTypes.RideListName,
    filterType: fromTypes.filters.RideQuickFilterType,
  ): void {
    const filter = {
      filterId: fromTypes.utils.encodeDataComponents([fromTypes.filters.RideFilterType.QUICK_FILTER, filterType]),
      type: fromTypes.filters.RideFilterType.QUICK_FILTER,
      payload: filterType,
    } as fromTypes.filters.RideQuickFilter;
    this.store.dispatch(fromActions.quickFilterSelected({ listName, filter }));
  }

  public onFilterRemoved(listName: fromTypes.RideListName, filterId: string): void {
    this.store.dispatch(fromActions.filterRemoveSelected({ listName, filterId }));
  }

  public onAllFiltersRemoved(listName: fromTypes.RideListName): void {
    this.store.dispatch(fromActions.removeAllFilters({ listName }));
  }

  public onSetTimetable(listName: fromTypes.RideListName, timetable: fromTypes.RideTimetable): void {
    this.store.dispatch(fromActions.timetableChanged({ listName, timetable }));
  }

  public onSetAccount(listName: fromTypes.RideListName, accountId: string): void {
    this.store.dispatch(fromActions.accountChanged({ listName, accountId }));
  }

  public onSelectedRideIdChanged(listName: fromTypes.RideListName, rideId: string | null): void {
    this.store.dispatch(fromActions.selectedRideIdChanged({ listName, rideId }));
  }
}
