import { createSelector } from '@ngrx/store';
import { getRouterState, RouterStateUrl } from '../reducers';

export const getRouterNavigationState = createSelector<any, any, RouterStateUrl>(getRouterState, (state) =>
  state ? state.state : {},
);

export const getDisplayBackText = createSelector(getRouterNavigationState, (state) =>
  state.queryParams ? state.queryParams.displayBackText || null : null,
);
