<div class="wp-ce-search-option">
  <div class="left">
    <div class="icon">
      <div *ngIf="entity?.type === entityTypes.DRIVER" class="icon-box driver">
        <wp-icon-drivers-green></wp-icon-drivers-green>
      </div>
      <div *ngIf="entity?.type === entityTypes.RIDE" class="icon-box">
        <wp-icon-rides-green></wp-icon-rides-green>
      </div>
      <div *ngIf="entity?.type === entityTypes.ROUTE" class="icon-box">
        <wp-icon-routes-green></wp-icon-routes-green>
      </div>
      <div
        *ngIf="entity?.type === entityTypes.STUDENT"
        class="icon-box student"
      >
        <wp-icon-students-green></wp-icon-students-green>
      </div>
      <div
        *ngIf="entity?.type === entityTypes.DISTRICT"
        class="icon-box district"
      >
        <wp-ic-district-green></wp-ic-district-green>
      </div>
      <div *ngIf="entity?.type === entityTypes.PARENT" class="icon-box">
        <wp-icon-parent-brand></wp-icon-parent-brand>
      </div>
      <div *ngIf="entity?.type === entityTypes.CAMPUS" class="icon-box">
        <wp-icon-campus-brand></wp-icon-campus-brand>
      </div>
      <div *ngIf="entity?.type === entityTypes.SCHOOL" class="icon-box">
        <wp-icon-school-green></wp-icon-school-green>
      </div>
      <div *ngIf="entity?.type === entityTypes.VENDOR" class="icon-box">
        <wp-icon-vendor [green]="true"></wp-icon-vendor>
      </div>
      <wp-portal-icon
        *ngIf="entity?.type === entityTypes.SCHOOL_EMPLOYEE || entity?.type === entityTypes.ZUM_EMPLOYEE || entity?.type === entityTypes.VENDOR_EMPLOYEE"
        class="employee-icon"
        [path]="iconEmployee"
        [widthStr]="'24px'"
        [heightStr]="'24px'"
      ></wp-portal-icon>
    </div>

    <div>
      <div>{{ entity?.label }}</div>

      <div
        class="employee-info"
        *ngIf="entity?.type === entityTypes.SCHOOL_EMPLOYEE && entity['organizationName']; else labelInfo"
      >
        <span class="employee-org-name ellipsis">
          {{entity['organizationName']}}
        </span>
        <span class="text-divider-dot">•</span>
        <span class="employee-email ellipsis">{{entity['email']}}</span>
      </div>

      <ng-template #labelInfo>
        <div class="email ellipsis" *ngIf="entity && entity['email']">
          {{entity['email']}}
        </div>
      </ng-template>
    </div>
  </div>
  <div class="display-type">{{ entity?.type | displayEntityType }}</div>
</div>
