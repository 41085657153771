export const getDisplayQuantity = (value: number, entityName: string, customPluralForm?: string): string => {
  if (entityName === undefined || value === undefined || value === null) {
    return '-';
  }
  const singular = entityName;
  const plural = customPluralForm ? customPluralForm : `${entityName}s`;
  if (value === 1) {
    return `1 ${singular}`;
  }
  return `${value} ${plural}`;
};
