import { ApiCampusCalendar } from '../../common';

export const getScheduleIdsByCalendars = (source: ApiCampusCalendar[]): { [calendarId: string]: string[] } => {
  const res = {};
  if (source) {
    source.forEach((calendar) => {
      res[calendar.calendarId] = calendar.campusSchedules.map((schedule) => schedule.scheduleId);
    });
  }
  return res;
};
