import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { timesheetEntryDataFeatureKey } from './feature-key';
import { timesheetEntryDataReducer } from './reducer';
import { TimesheetEntryDataApiService } from './timesheet-entry-data-api.service';
import { EffectsModule } from '@ngrx/effects';
import { TimesheetEntryDataEffects } from './timesheet-entry-data.effects';
import { TimesheetEntryContextApiService } from './timesheet-entry-context-api.service';
import { TimesheetEntryDetailsContextEffects } from './timesheet-entry-details-context.effects';
import { TimesheetEntryContextFacade } from './timesheet-entry-context.facade';
import { ContextSpecificItemFacade } from './context-specific-item.facade';
import { ContextActivityItemFacade } from './context-activity-item.facade';
import { ActivityTimesheetEntryDataFacade } from './activity-timesheet-entry-data.facade';

@NgModule({
  declarations: [],
  providers: [
    TimesheetEntryDataApiService,
    TimesheetEntryContextApiService,
    TimesheetEntryContextFacade,
    ContextSpecificItemFacade,
    ContextActivityItemFacade,
    ActivityTimesheetEntryDataFacade,
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(timesheetEntryDataFeatureKey, timesheetEntryDataReducer),
    EffectsModule.forFeature([TimesheetEntryDataEffects, TimesheetEntryDetailsContextEffects]),
  ],
})
export class TimesheetEntryDataStoreModule {}
