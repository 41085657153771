import { Injectable } from '@angular/core';
import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs';

const getGitVersionHash = (source: string): string => {
  if (!source) {
    return '--';
  }
  return source.split(' ').pop();
};

const datadogConfig: LogsInitConfiguration = {
  clientToken: wpEnvironment.datadogClientToken,
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  // env is either prod or dev
  env: wpEnvironment.envName === 'production' ? 'prod' : 'dev',
  // enterprise should be displayed as "school"
  service: `portal_${wpEnvironment.userRole === 'enterprise' ? 'school' : wpEnvironment.userRole}`,
  // env version can be "2024-09-10 19:31 6fdacc", datadog version should
  // follow tag syntax https://docs.datadoghq.com/getting_started/tagging/#define-tags
  version: `hash:${getGitVersionHash(wpEnvironment.gitVersion)}`,
};

@Injectable({ providedIn: 'root' })
export class DatadogService {
  constructor() {}

  public init(): void {
    if (!wpEnvironment.datadogEnabled) {
      return;
    }
    datadogLogs.init(datadogConfig);
  }
}
