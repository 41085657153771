import { TraceDisposition } from '@rootTypes/entities/ride';
import { PortalRouteTracePoint } from '../../interfaces';

export const routeTracePointToLatLng = (source: PortalRouteTracePoint): google.maps.LatLng => {
  if (source) {
    return new google.maps.LatLng(source.lat, source.lng);
  }
  return null;
};

export const latLngToRouteTracePoint = (source: google.maps.LatLng): PortalRouteTracePoint => {
  if (source) {
    return { lat: source.lat(), lng: source.lng() };
  }
  return null;
};

export const isPointsWithinDistance = (
  point1: google.maps.LatLng,
  point2: google.maps.LatLng,
  distanceMeters: number,
): boolean => {
  return google.maps.geometry.spherical.computeDistanceBetween(point1, point2) <= distanceMeters;
};

/**
 * Distance in meters
 */
export const getDistanceBetweenPoints = (point1: google.maps.LatLng, point2: google.maps.LatLng): number => {
  return google.maps.geometry.spherical.computeDistanceBetween(point1, point2);
};

export const isArrowSequence = (item: google.maps.IconSequence): boolean => {
  return !!item && !!item.icon && item.icon['meta']?.type === 'arrow';
};

export const drawArrowIcon = (
  repeat: boolean,
  traceDisposition: TraceDisposition = 'default',
  strokeColor: string = '#7fb2da',
): google.maps.IconSequence[] => {
  const BEFORE_PICKUP_ARROW = {
    path: 'M8 2.925v15.196c0 1.05.895 1.902 2 1.902s2-.851 2-1.902V2.925c0-1.05-.895-1.902-2-1.902s-2 .852-2 1.902z M16.586 11.414a2 2 0 1 0 2.828-2.828l-7.997-8a2 2 0 0 0-2.828 0l-8.003 8a2 2 0 1 0 2.828 2.828l6.588-6.586 6.584 6.586z',
    fillColor: '#fff',
    fillOpacity: 1,
    strokeColor,
    anchor: new google.maps.Point(10, 10),
    strokeOpacity: 1,
    scale: 0.5,
    meta: {
      type: 'arrow',
    },
  };

  const DEFAULT_ARROW = {
    path: 'M0 20 9.004 0 18 20l-8.991-6z',
    fillColor: '#fff',
    fillOpacity: 1,
    strokeColor,
    anchor: new google.maps.Point(9.5, 10),
    strokeOpacity: 1,
    scale: 0.5,
    meta: {
      type: 'arrow',
    },
  };
  const icon = traceDisposition === 'before_pickup' ? BEFORE_PICKUP_ARROW : DEFAULT_ARROW;
  return [
    {
      icon,
      repeat: repeat ? '80px' : null,
      offset: '50%',
    },
  ];
};

export const setArrowOnPolyline = (
  polyline: google.maps.Polyline,
  repeat?: boolean,
  traceDisposition?: TraceDisposition,
): void => {
  const prevIcons = polyline.get('icons') || ([] as google.maps.IconSequence[]);
  const hasArrow = prevIcons.some((s) => isArrowSequence(s));
  if (!hasArrow) {
    polyline.setOptions({
      icons: [...prevIcons, ...drawArrowIcon(repeat, traceDisposition)],
    });
  }
};

export const removeArrowFromPolyline = (polyline: google.maps.Polyline): void => {
  const prevIcons = polyline.get('icons') || ([] as google.maps.IconSequence[]);
  polyline.setOptions({
    icons: prevIcons.filter((s) => !isArrowSequence(s)),
  });
};

export const addArrowOnPolylineWithZoomListener = (
  polyline: google.maps.Polyline,
  repeat?: boolean,
  traceDisposition?: TraceDisposition,
): void => {
  if (!polyline.getMap()) {
    return null;
  }
  polyline.getMap().addListener('zoom_changed', () => {
    if (polyline.getMap() && polyline.getMap().getZoom() < 15) {
      removeArrowFromPolyline(polyline);
    } else {
      setArrowOnPolyline(polyline, repeat, traceDisposition);
    }
  });
};
