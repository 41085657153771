<div class="wp-pill">
  <div class="wp-pill__wrap" [ngStyle]="{ width: width }" [ngClass]="{ readonly: readonly }">
    <wp-placeholder-box style="flex: 1" *ngIf="loading"></wp-placeholder-box>
    <ng-content></ng-content>

    <div class="close-btn" *ngIf="!readonly" (click)="onClose()">
      <wp-icon-close [size]="'xsmall'"></wp-icon-close>
    </div>
  </div>
</div>
