import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-popup-page-subtitle',
  templateUrl: './popup-page-subtitle.component.html',
  styleUrls: ['./popup-page-subtitle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupPageSubtitleComponent implements OnInit {
  @Input() public isBold = true;
  constructor() {}

  ngOnInit(): void {}
}
