import { PortalListStoreActions } from '@rootTypes/utils/list-store-utils/portal-list-store-actions';
import { PortalListBaseParams } from '@rootTypes/utils/list-store-utils/portal-list-base-state';
import { RouteGroupChangeRequestListItem } from '../../dependencies/types/route-group-change-request-list-item';
import { RouteGroupChangeRequestListStatus } from '../../dependencies/types/route-group-change-request-list-status';
import { createAction, props } from '@ngrx/store';
import { PortalListFilterType } from '@rootTypes/utils/list-store-utils/portal-list-filter';

export type RouteGroupRequestsFilterType =
  | PortalListFilterType
  | {
      id: string;
      type: 'custom-test-type';
      payload: {
        foo: 'bar';
      };
    };

export interface RouteGroupRequestListParams extends PortalListBaseParams<RouteGroupRequestsFilterType> {
  status: RouteGroupChangeRequestListStatus;
}

export const routeGroupRequestListActions = new PortalListStoreActions<
  RouteGroupRequestListParams,
  RouteGroupRequestsFilterType,
  RouteGroupChangeRequestListItem
>('RouteGroupRequestList');

export const routeGroupRequestListStatusSelected = createAction(
  '[RouteGroupRequestList]: Status selected',
  props<{ status: RouteGroupChangeRequestListStatus }>(),
);
