<section>
  <ng-container *ngIf="exactLocation; else empty">
    <div class="row exact-name">
      <wp-field-label-down-value
        [label]="'Exact location'"
        [value]="exactLocation.label"
      ></wp-field-label-down-value>
    </div>
    <div class="row exact-coords">
      <div class="coord lat">
        <div class="coord-name" [ngClass]="{ 'short': short }">
          {{short ? 'Lat:' : 'Latitude:'}}
        </div>
        <div class="coord-value">{{ exactLocation.location.lat}}</div>
      </div>
      <div class="coord lng">
        <div class="coord-name" [ngClass]="{ 'short': short }">
          {{short ? 'Lng:' : 'Longitude:'}}
        </div>
        <div class="coord-value">{{exactLocation.location.lng}}</div>
      </div>
    </div>
  </ng-container>
  <ng-template #empty>
    <wp-field-label-down-value
      [label]="'Exact location'"
      [value]="'--'"
    ></wp-field-label-down-value>
  </ng-template>
</section>
