import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';

import { AuthApiService } from '../../services/auth-api.service';
import { portalRoutes } from '@router';
import { isWebErrorDetails, WebError } from '@rootTypes';

import {
  sendPasswordResetEmailRequested,
  sendPasswordResetEmailFailed,
  sendPasswordResetEmailSuccess,
  setNewPasswordRequested,
  setNewPasswordSuccess,
  setNewPasswordFailed,
} from '../actions';

import { getRouterNavigationState, go } from '@rootStore';
import { SetPasswordForTokenRequest } from 'src/app/api/endpoints/password-reset';
import {
  newUserPasswordSetupRoute,
  newUserPasswordSuccessRoute,
  resetPasswordSuccessRoute,
} from 'src/app/router/portal-routes/auth';
import { SnackbarService } from '../../../shared/snackbar/snackbar.service';

@Injectable()
export class ResetPasswordEffects {
  constructor(
    private authApiService: AuthApiService,
    private actions$: Actions,
    private store: Store,
    private snackbar: SnackbarService,
  ) {}

  public sendPasswordResetEmailRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendPasswordResetEmailRequested),
      switchMap((action) => {
        return this.authApiService.sendPasswordResetEmail(action.email).pipe(
          map(() => sendPasswordResetEmailSuccess()),
          catchError((error) => {
            const message = 'Failed to send password reset email';
            // Do not log the error in console for security reason
            // console.error(new Error(message), error);
            return of(
              sendPasswordResetEmailFailed({
                error: {
                  text: message,
                  originalError: error,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  public sendPasswordResetEmailFinished$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        sendPasswordResetEmailSuccess,
        // For security reason navigate to success page if failed
        sendPasswordResetEmailFailed,
      ),
      map(() => {
        this.snackbar.success(
          "Thanks! If there's an account associated with this email, we'll send the password reset instructions immediately.",
          3000,
        );
        return go(portalRoutes.auth.resetPasswordEmailSentRoute.request());
      }),
    ),
  );

  public setNewPasswordRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setNewPasswordRequested),
      switchMap((action) => {
        return this.isUserAuthenticated().pipe(
          switchMap((isAuthenticated) => {
            if (isAuthenticated) {
              return this.authApiService.signOut().pipe(
                switchMap(() => this.setPasswordForToken(action.request)),
                catchError(() => this.setPasswordForToken(action.request)),
              );
            }
            return this.setPasswordForToken(action.request);
          }),
        );
      }),
    ),
  );

  public setNewPasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setNewPasswordSuccess),
      withLatestFrom(this.store.select(getRouterNavigationState)),
      map(([action, routerStateUrl]) => {
        if (newUserPasswordSetupRoute.isOnRoute(routerStateUrl)) {
          return go(newUserPasswordSuccessRoute.request());
        }
        return go(resetPasswordSuccessRoute.request());
      }),
    ),
  );

  private setPasswordForToken(request: SetPasswordForTokenRequest): Observable<Action> {
    return this.authApiService.setPasswordForToken(request).pipe(
      map(() => setNewPasswordSuccess()),
      catchError((originalError: WebError) => {
        let text: string;
        if (isWebErrorDetails(originalError)) {
          text = originalError.data.message;
        } else {
          text = 'Failed to set new password';
        }
        return of(
          setNewPasswordFailed({
            error: {
              text,
              originalError,
            },
          }),
        );
      }),
    );
  }

  private isUserAuthenticated(): Observable<boolean> {
    return this.authApiService.checkFirebaseAuth().pipe(
      map((user) => !!user),
      catchError(() => of(false)),
    );
  }
}
