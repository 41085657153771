import { FilterDeserializer } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';
import { TripAssignmentListVendorResponseFilter, TripAssignmentListVendorResponseFilterValue } from '../../entities';
import { createTripAssignmentVendorResponseFilter } from './create-trip-assignment-vendor-response-filter';

export const deserializeTripAssignmentVendorResponseFilter: FilterDeserializer<
  TripAssignmentListVendorResponseFilter
> = (str: string): TripAssignmentListVendorResponseFilter | undefined => {
  if (str && str.startsWith('vendor-response')) {
    const value = str.split(',')[1] as TripAssignmentListVendorResponseFilterValue;
    return createTripAssignmentVendorResponseFilter(value);
  }
  return undefined;
};
