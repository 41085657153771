import { Injectable } from '@angular/core';
import { LocalStorageService } from '../../../../core/services';
import { RouteExplorerRouteData } from '../../../../router/portal-routes/route-explorer';

export type RouteManagementPersistedState = RouteExplorerRouteData;

@Injectable({
  providedIn: 'root',
})
export class RouteManagementPersistenceService {
  constructor(private localStorage: LocalStorageService) {}

  public saveState(state: RouteManagementPersistedState): void {
    this.localStorage.set('route-management', state);
  }

  public getState(): RouteManagementPersistedState | null {
    return this.localStorage.get('route-management', true);
  }
}
