import { createFeatureSelector, createSelector } from '@ngrx/store';
import { timesheetEntryDataFeatureKey } from './feature-key';
import { TimesheetEntriesDataState } from './reducer';
import { PortalEntity, PortalEntityType } from '@rootTypes';
import { UTCTime } from '@rootTypes/utils/common/date';
import { getActivityAssignmentEntityType } from '@rootTypes/utils/activities';
import { getActivityTypeOptionsFromMap } from './get-activity-type-options-from-map';
import { ActivityTypeOption } from '@rootTypes/entities/activities/activity-type-option';
import { GetDriverBusReportResponse } from '../../api/endpoints/get-driver-bus-report';

export const timesheetEntryDataFeature = createFeatureSelector<TimesheetEntriesDataState>(timesheetEntryDataFeatureKey);

export const getAllActivityStatuses = createSelector(timesheetEntryDataFeature, (state) => state.activityStatuses);

export const getActivityStatus = (activityId) => createSelector(getAllActivityStatuses, (state) => state[activityId]);

export const getAllActivityEntities = createSelector(timesheetEntryDataFeature, (state) => state.activities);

const getSingleActivityEntity = (activityId) => createSelector(getAllActivityEntities, (state) => state[activityId]);

/**
 *Activity data
 */
export const getActivity = (activityId) =>
  createSelector(getSingleActivityEntity(activityId), (state) => state?.entity);
const getActivityDateFormatter = (activityId) =>
  createSelector(getActivity(activityId), (state) => {
    if (!state) {
      return null;
    }
    const df = new UTCTime(state.scheduledStartTimestamp, state.timezone);
    return df;
  });
export const getActivityYYYYMMDD = (activityId) =>
  createSelector(getActivityDateFormatter(activityId), (state) => {
    if (!state) {
      return null;
    }
    return state.getYYYYMMDDString();
  });

export const getActivityDisplayDate = (activityId) =>
  createSelector(getActivityDateFormatter(activityId), (state) => {
    if (!state) {
      return null;
    }
    return state.getDisplayWeekdayAndDateStr();
  });

export const getActivityEntityId = (activityId) => createSelector(getActivity(activityId), (state) => state?.user?.id);
export const getActivityEntityType = (activityId) =>
  createSelector(getActivity(activityId), (state) => {
    return getActivityAssignmentEntityType(state);
  });

export const getUserPortalEntityForActivity = (activityId) =>
  createSelector(
    getActivityEntityId(activityId),
    getActivityEntityType(activityId),
    (entityId, entityType): PortalEntity => {
      return {
        entityId,
        type: entityType,
      } as PortalEntity;
    },
  );

export const getSpecificContextEntityType = (activityId) =>
  createSelector(getActivityEntityType(activityId), (entityType) => {
    if (!entityType) {
      return undefined;
    }
    switch (entityType) {
      case PortalEntityType.DRIVER:
        return PortalEntityType.RIDE;
      default:
        throw new Error('Unknown activity user entity type');
    }
  });

export const getRequestedByName = (activityId) =>
  createSelector(getActivity(activityId), (state) =>
    state?.lastRequestedBy ? state.lastRequestedBy.userLabel : undefined,
  );

export const getRequestedByRole = (activityId) =>
  createSelector(getActivity(activityId), (state) => (state?.lastRequestedBy ? state.lastRequestedBy.role : undefined));

export const getRequestedDisplayDateTime = (activityId) =>
  createSelector(getActivity(activityId), (state) => {
    if (state?.lastRequestedBy) {
      const df = new UTCTime(state.lastRequestedBy.timestamp, state.timezone);
      return df.getDisplayWeekdayDateAndTimeStr();
    }
    return undefined;
  });

export const getAllPendingTimesheetEntriesCounts = createSelector(
  timesheetEntryDataFeature,
  (state) => state.pendingReviewTimesheetEntriesCounts,
);

export const getAllActivityLogEntities = createSelector(timesheetEntryDataFeature, (state) => state.activityLogs);

const getActivityTypesState = createSelector(timesheetEntryDataFeature, (state) => state.activityTypes);

export const isActivityTypesLoading = createSelector(getActivityTypesState, (state) => state.isLoading);

export const getActivityTypesEntity = createSelector(getActivityTypesState, (state) => state.entity);
export const getAllActivitySubTypesEntity = createSelector(getActivityTypesState, (state) => state.allSubtypes);

export const getActivityTypesOptions = createSelector(getActivityTypesEntity, (state): ActivityTypeOption[] => {
  if (!state) {
    return [];
  }
  return getActivityTypeOptionsFromMap(state);
});

const getAllRemovedActivities = createSelector(timesheetEntryDataFeature, (state) => state.removedActivities);

export const isActivityRemoved = (activityId) =>
  createSelector(getAllRemovedActivities, (state) => !!state[activityId]);

const getAllDriverBusRecordsByActivities = createSelector(
  timesheetEntryDataFeature,
  (state) => state.driverBusReportsByActivities,
);

export const getDriverBusReportByActivity = (activityId) =>
  createSelector(
    getAllDriverBusRecordsByActivities,
    (state): GetDriverBusReportResponse => (state[activityId] ? state[activityId].entity : null),
  );

export const getDisplayActivityType = (type: string) =>
  createSelector(getActivityTypesEntity, (state): string => {
    if (state && state[type]) {
      return state[type].type.displayLabel;
    }
    return undefined;
  });

export const getDisplayActivitySubType = (subType: string) =>
  createSelector(getAllActivitySubTypesEntity, (state): string => {
    return state ? state[subType] : undefined;
  });
