import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SmartInputSchoolService } from '../../services';
import { SmartSelect } from '../../models';

@Component({
  selector: 'wp-smart-input-school-campus',
  templateUrl: './smart-input-school-campus.component.html',
  styleUrls: ['./smart-input-school-campus.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartInputSchoolCampusComponent implements OnInit {
  public model: SmartSelect;

  constructor(private service: SmartInputSchoolService) {}

  public ngOnInit(): void {
    this.model = this.service.campus;
  }
}
