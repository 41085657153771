import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AddressAutocompleteOption,
  GooglePlaceAutocompleteCustomUIStore,
} from '../google-place-autocomplete-custom-ui-store';
import { iconPaths } from '@rootTypes/utils';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Address } from '@rootTypes';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wp-google-address-autocomplete-custom-ui-panel',
  template: `
    <mat-autocomplete
      [displayWith]="displayWithFn"
      (optionSelected)="onOptionSelected($event)"
      [panelWidth]="panelWidth"
    >
      @for (option of store.options$ | async; track option.formatted_address) {
        <mat-option [value]="option">
          <div style="display: flex; margin-bottom: 6px">
            <div>
              <span style="word-break: break-word">
                {{ option.formatted_address }}
              </span>
              <div *ngIf="option.geometry?.location">
                <wp-grey-label
                  ><span style="font-size: 12px"
                    >{{ option.geometry.location.lat }}, {{ option.geometry.location.lng }}</span
                  ></wp-grey-label
                >
              </div>
            </div>
          </div>
        </mat-option>
      }
      @if (store.isLoading$ | async) {
        <mat-option class="info-option" [disabled]="true">
          <div class="info-option-inner">
            <wp-section-spinner [spinnerSize]="33" [sectionHeight]="'50px'"></wp-section-spinner>
          </div>
        </mat-option>
      }
      @if (store.loadError$ | async; as errorMessage) {
        <mat-option class="info-option" [disabled]="true">
          <div class="info-option-inner">
            <wp-section-error [sectionHeightStr]="'50px'"
              >Failed to process request: {{ errorMessage }}</wp-section-error
            >
          </div>
        </mat-option>
      }
      <mat-option
        *ngIf="(store.options$ | async)?.length || (store.isLoading$ | async) || (store.loadError$ | async)"
        class="attribution-option"
        [disabled]="true"
      >
        <div #attributionDiv></div>
      </mat-option>
    </mat-autocomplete>
  `,
  styleUrl: './google-address-autocomplete-custom-ui-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoogleAddressAutocompleteCustomUiPanelComponent implements AfterViewInit, OnDestroy {
  // any css width
  @Input() public panelWidth: string = null;
  @Output() public addressSelected = new EventEmitter<Address>();
  @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete;
  @ViewChildren('attributionDiv') private attributionDiv: QueryList<HTMLDivElement>;
  public iconPath = iconPaths.MAP_PIN_GREEN;
  private subs = new Subscription();

  constructor(public store: GooglePlaceAutocompleteCustomUIStore) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public displayWithFn = (o: AddressAutocompleteOption) => o?.formatted_address;

  async onOptionSelected(event: MatAutocompleteSelectedEvent): Promise<void> {
    const address = await this.store.onOptionClick(event.option.value);
    this.addressSelected.emit(address);
  }

  ngAfterViewInit(): void {
    const s = this.attributionDiv.changes.subscribe((c) => {
      const attributionDiv = c.first && c.first.nativeElement ? c.first.nativeElement : null;
      if (attributionDiv) {
        this.store.initPlacesServiceForElement(attributionDiv);
      }
    });
    this.subs.add(s);
  }
}
