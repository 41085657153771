import { UpdateVendorEmployeeRequest } from '../../../../api/endpoints/update-vendor-employee';
import { EmployeeProfileEditModel } from '@rootTypes/entities/employees';

export const getUpdateEmployeeRequest = (
  initial: EmployeeProfileEditModel,
  target: EmployeeProfileEditModel,
): UpdateVendorEmployeeRequest => {
  const request = {
    id: initial.id,
  } as UpdateVendorEmployeeRequest;
  if (initial.photo.imagePath !== target.photo?.imagePath) {
    request.vendorEmployeeImagePath = target.photo.imagePath;
  }
  if (initial.firstName !== target.firstName) {
    request.firstName = target.firstName;
  }
  if (initial.lastName !== target.lastName) {
    request.lastName = target.lastName;
  }
  if (initial.position !== target.position) {
    request.position = target.position;
  }
  if (initial.phoneNumber !== target.phoneNumber) {
    request.phoneNumber = target.phoneNumber;
  }
  if (initial.status !== target.status) {
    request.status = target.status;
  }
  return request;
};
