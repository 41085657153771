import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import {
  PortalEntityType,
  VendorPortalEntity,
  smartInputVendorValueToVendorPortalEntity,
  vendorPortalEntityToSmartInputVendorValue,
} from '../dependencies';
import { SmartInputModel } from './smart-input-model';
import { EntityNameGetter } from './smart-select';

export interface SmartInputTransportationProviderConfig {
  vendor: {
    label?: string; // defaults to 'Transportation provider'
    value?: SmartInputTransportationProviderEntity;
  };
  yard: {
    label?: string; // defaults to 'Yard'
    value?: SmartInputTransportationProviderEntity;
  };
  required?: boolean;
  disabled?: boolean;
}

export type SmartInputTransportationProviderValue = {
  vendor: SmartInputTransportationProviderEntity | null;
  yard: SmartInputTransportationProviderEntity | null;
};

export interface SmartInputTransportationProviderEntity {
  id: string;
  name: string;
}

export class SmartInputTransportationProvider implements SmartInputModel {
  public vendorLabel: string;
  public vendorControl: UntypedFormControl; // value is VendorPortalEntity
  public yardLabel: string;
  public yardControl: UntypedFormControl; // value is SmartInputTransportationProviderEntity
  public formGroup: UntypedFormGroup;
  public formGroupStateChange$: Observable<void>;
  public selectedVendorId$: Observable<string | undefined>;
  public required: boolean;

  private formGroupStateChange$$: Subject<void>;
  private sub = new Subscription();
  private initialIds: string;

  private entityNameGetter$$: BehaviorSubject<EntityNameGetter> = new BehaviorSubject<EntityNameGetter>(null);

  constructor(config: SmartInputTransportationProviderConfig) {
    this.initialIds = this.valueToIds({
      vendor: config.vendor.value,
      yard: config.yard.value,
    });

    this.vendorLabel = config.vendor.label || 'Transportation provider';
    this.yardLabel = config.yard.label || 'Yard';
    this.required = config.required || false;

    const vendorValidator = this.required ? Validators.required : undefined;
    this.vendorControl = new UntypedFormControl(
      smartInputVendorValueToVendorPortalEntity(config.vendor.value),
      vendorValidator,
    );
    this.yardControl = new UntypedFormControl(config.yard.value, this.yardValidator.bind(this));
    if (config.disabled) {
      this.disable();
    }

    this.selectedVendorId$ = this.vendorControl.valueChanges.pipe(
      startWith(this.vendorControl.value as VendorPortalEntity),
      map((vendor?: VendorPortalEntity) => {
        return vendor ? vendor.entityId : undefined;
      }),
      // shareReplay is needed so that this stream always contain the latest
      // vendorId value. Otherwise, it ignores the values set before the
      // 'wp-smart-input-transportation-provider-yard' initializes.
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    this.selectedVendorId$.subscribe();

    this.formGroup = new UntypedFormGroup({
      vendor: this.vendorControl,
      yard: this.yardControl,
    });

    this.formGroupStateChange$$ = new Subject<void>();
    this.formGroupStateChange$ = this.formGroupStateChange$$.asObservable();
    this.setVendorPortalEntityNameGetterSub();
  }

  public getValue(): SmartInputTransportationProviderValue {
    const vendor = vendorPortalEntityToSmartInputVendorValue(this.vendorControl.value);
    const yard = this.yardControl.value;
    return {
      vendor: vendor || null,
      yard: yard || null,
    };
  }

  public disable(): void {
    this.vendorControl.disable();
    this.yardControl.disable();
  }

  public enable(): void {
    this.vendorControl.enable();
    this.yardControl.enable();
  }

  public isValid(): boolean {
    if (this.required || this.vendorControl.value) {
      return this.formGroup.valid || this.formGroup.disabled;
    }
    return true;
  }

  public showErrorIfAny(): void {
    if (!this.isValid()) {
      this.vendorControl.markAsTouched();
      this.vendorControl.updateValueAndValidity();
      this.yardControl.markAsTouched();
      this.formGroupStateChange$$.next();
    }
  }

  public hasChanges(): boolean {
    const currIds = this.valueToIds(this.getValue());
    return currIds !== this.initialIds;
  }

  public onComponentInit(entityNameGetter?: EntityNameGetter): void {
    this.entityNameGetter$$.next(entityNameGetter);
  }

  public onVendorInputChanged(): void {
    this.yardControl.reset(undefined);
  }

  public onComponentDestroy(): void {
    this.sub.unsubscribe();
  }

  public getValueChanges(): Observable<any> {
    return this.formGroup.valueChanges;
  }

  private setVendorPortalEntityNameGetterSub(): void {
    const getterFn$ = this.entityNameGetter$$.pipe(
      filter((getterFn) => !!getterFn),
      take(1),
    );
    const entityGetterSub = getterFn$
      .pipe(
        switchMap((getterFn) => {
          return this.vendorControl.valueChanges.pipe(
            startWith(this.vendorControl.value),
            filter((value) => !!value && !!value.entityId && !value.label),
            map((value) => value.entityId),
            distinctUntilChanged(),
            switchMap((vendorId) => {
              return getterFn(vendorId, PortalEntityType.VENDOR).pipe(
                map((vendorName) => {
                  if (this.vendorControl.value?.entityId === vendorId) {
                    this.vendorControl.setValue({ ...this.vendorControl.value, label: vendorName });
                  }
                }),
              );
            }),
          );
        }),
      )
      .subscribe();
    this.sub.add(entityGetterSub);
  }

  private yardValidator = (control: AbstractControl): { required: true } | null => {
    if (this.vendorControl.value) {
      return control.value ? null : { required: true };
    }
    return null;
  };

  private valueToIds(value?: SmartInputTransportationProviderValue): string {
    let ids = '';
    if (value && value.vendor) {
      ids += value.vendor.id;
    }
    if (value && value.yard) {
      ids += `.${value.yard.id}`;
    }
    return ids;
  }
}
