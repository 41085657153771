import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getRouteGroupScheduleChangeRequestFailed,
  getRouteGroupScheduleChangeRequestSuccess,
  getScheduleChangeRequestActions,
} from '../actions/route-group-schedule-change-request.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApiService } from '../../../api/api.service';
import { WpError } from '@rootTypes';
import { of } from 'rxjs';

@Injectable()
export class RouteGroupScheduleChangeRequestEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}

  public getScheduleChangeRequestRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...getScheduleChangeRequestActions),
      mergeMap(({ changeRequestId }) => {
        return this.api.getRouteGroupScheduleChangeRequest({ changeRequestId }).pipe(
          map((response) => getRouteGroupScheduleChangeRequestSuccess({ changeRequest: response })),
          catchError((originalError) => {
            console.error(originalError);
            const error: WpError = {
              originalError,
              text: 'Failed to load schedule change request: ' + changeRequestId,
            };
            return of(getRouteGroupScheduleChangeRequestFailed({ changeRequestId, error }));
          }),
        );
      }),
    ),
  );
}
