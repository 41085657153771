import { createSelector } from '@ngrx/store';
import { SelectOption } from '../../shared/form-controls';
import { getTripDataFeature } from './feature.selector';
import { TripsConfigState } from './reducer/trips-config.reducer';
import { GetCharterConfigResponse } from '../../api/endpoints/get-charter-config';
import { TripListQuickFilterType } from '../../features/trips/types/trip-list';

const getCharterConfigFeature = createSelector(getTripDataFeature, (state): TripsConfigState => state.config);

export const getCharterConfig = createSelector(
  getCharterConfigFeature,
  (state): GetCharterConfigResponse => state.entity,
);
export const getCharterConfigFresh = createSelector(getCharterConfigFeature, (state) => {
  return state.isLoading ? undefined : state.entity;
});
export const isCharterConfigLoading = createSelector(getCharterConfigFeature, (state) => state?.isLoading || false);
export const getCharterConfigError = createSelector(getCharterConfigFeature, (state) => state.error);

export const getRegionApiOptions = createSelector(getCharterConfig, (state) => {
  if (!state) {
    return [];
  }
  return state.regions;
});
export const getRegionSelectOptions = createSelector(getRegionApiOptions, (state): SelectOption[] => {
  return state.map((s) => ({ value: s.id, displayLabel: s.label }));
});

const getCharterConfigFilters = createSelector(getCharterConfig, (config) => config?.filters);
export const getTripAssignmentStatusFilterOptions = createSelector(
  getCharterConfigFilters,
  (filters): SelectOption[] => {
    if (filters) {
      return filters.assignmentStatusFilters.map(({ id, label }) => {
        return {
          value: id,
          displayLabel: label,
        };
      });
    }
    return undefined;
  },
);
export const isTripRegionFilterShown = createSelector(getCharterConfigFilters, (config) => config?.isRegionFilterShown);
export const isTripAssignmentVendorResponseFilterShown = createSelector(
  getCharterConfigFilters,
  (config) => config?.isVendorResponseFilterShown,
);
export const isTripAssignmentAdvancedFiltersShown = createSelector(
  isTripRegionFilterShown,
  isTripAssignmentVendorResponseFilterShown,
  (isRegion, isVendorResponse) => isRegion || isVendorResponse,
);

export const getQuickFilterAllTripsLabel = createSelector(
  getCharterConfigFilters,
  (config) => config?.showAllTripsFilterLabel,
);

export const getTripListQuickFilterOptions = createSelector(getQuickFilterAllTripsLabel, (showAllTripsLabel) => {
  return [
    {
      displayLabel: 'Trips starting today & beyond',
      value: TripListQuickFilterType.STARTING_TODAY,
    },
    {
      displayLabel: showAllTripsLabel,
      value: TripListQuickFilterType.MY_CAMPUS,
    },
    {
      displayLabel: 'All trips created by me',
      value: TripListQuickFilterType.MY_TRIPS,
    },
  ];
});

// vehicle catalog options
export const getVehicleCatalogApiOptions = createSelector(getCharterConfig, (state) => {
  if (!state) {
    return [];
  }
  return state.catalogs || [];
});
export const getVehicleCatalogSelectOptions = createSelector(getVehicleCatalogApiOptions, (state) => {
  return state.map((s) => ({ value: s.id, displayLabel: s.label })) as SelectOption[];
});

export const getDisplayVehicleCatalogOptionLabel = (catalogId) =>
  createSelector(getVehicleCatalogApiOptions, (options) => {
    return (options || []).find(({ id }) => id === catalogId)?.label;
  });

export const getVehicleCatalogAbbreviationMap = createSelector(getVehicleCatalogApiOptions, (catalogs) => {
  const map: { [catalogId: string]: string } = {};
  catalogs.forEach((catalog) => {
    map[catalog.id] = catalog.abbreviation;
  });
  return map;
});
export const getVehicleCatalogAbbreviation = (catalogId: string) =>
  createSelector(getVehicleCatalogAbbreviationMap, (map) => {
    return map[catalogId];
  });
