import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/api/api.service';
import * as fromApi from '../../../api';
import { ImageApiService } from '../../../api';
import * as fromActions from '../store/actions';
import * as fromTypes from '../types';
import { LoadDistrictOptions } from '../types';
import { ApiDistrictProjections } from '../../../api/endpoints/get-district';

@Injectable()
export class DistrictApiService {
  constructor(
    private apiService: ApiService,
    private imageApi: ImageApiService,
  ) {}

  public getChecksForDistrict(): Observable<fromTypes.Check[]> {
    return this.apiService.getChecks({ type: fromApi.getChecks.CheckType.DISTRICT });
  }

  public getDistrict(
    districtId: string,
    loadOptions: LoadDistrictOptions,
  ): Observable<fromActions.LoadDistrictSuccessPayload> {
    const request: fromApi.getDistrict.GetDistrictRequest = {
      districtId,
    };
    const projections: ApiDistrictProjections[] = [];
    if (loadOptions?.withCalendar) {
      projections.push(ApiDistrictProjections.program);
    }
    if (loadOptions?.withDefaultArrivalDepartureTimeBuffer) {
      projections.push(ApiDistrictProjections.defaultArrivalDepartureTimeBuffer);
    }
    if (projections.length) {
      request._projections = projections;
    }
    return this.apiService.getDistrict(request).pipe(
      map((source) => {
        const program = source?._projections?.[ApiDistrictProjections.program];
        const defaultArrivalDepartureTimeBuffer =
          source?._projections?.[ApiDistrictProjections.defaultArrivalDepartureTimeBuffer];
        return {
          district: fromApi.getDistrict.convertors.getDistrictFromDb(
            source?.district,
            defaultArrivalDepartureTimeBuffer,
          ),
          program,
          defaultArrivalDepartureTimeBuffer,
        };
      }),
    );
  }

  public updateDistrictCalendar(
    districtId: string,
    calendar: fromTypes.DistrictCalendar,
    previousCalendar?: fromTypes.DistrictCalendar,
  ): Observable<fromTypes.DistrictCalendar> {
    const request = fromApi.upsertDistrictCalendar.convertors.getUpdateCalendarRequest(
      districtId,
      previousCalendar,
      calendar,
    );
    return this.apiService
      .upsertDistrictCalendar(request)
      .pipe(map((res) => fromApi.getZumEnterpriseDetails.convertors.getDistrictCalendarFromEnterpriseDb(res as any)));
  }

  public addDistrict(district: fromTypes.District): Observable<fromTypes.District> {
    const request = fromApi.createDistrict.convertors.getCreateDistrictRequest(district);
    return this.apiService
      .createDistrict(request)
      .pipe(map((res) => fromApi.getDistrict.convertors.getDistrictFromDb(res.district)));
  }

  public updateDistrict(previous: fromTypes.District, district: fromTypes.District): Observable<fromTypes.District> {
    const request = fromApi.updateDistrict.convertors.getUpdateDistrictRequest(previous, district);
    return this.apiService
      .updateDistrict(request)
      .pipe(map((res) => fromApi.getDistrict.convertors.getDistrictFromDb(res.district)));
  }

  public uploadImage(base64: string): Observable<string> {
    return this.imageApi.uploadImage(base64);
  }
}
