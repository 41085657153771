import { createAction, props } from '@ngrx/store';

const moduleKey = 'Data';
const submoduleKey = 'Campuses';
const subsubmoduleKey = 'Calendars Tab';

export const makeCalendarDefaultClicked = createAction(
  `[${moduleKey}] [${submoduleKey}] [${subsubmoduleKey}] Make calendar default clicked`,
  props<{ calendarId: string }>(),
);

export const removeCalendarClicked = createAction(
  `[${moduleKey}] [${submoduleKey}] [${subsubmoduleKey}] Remove calendar clicked`,
  props<{ calendarId: string }>(),
);

export const removeScheduleClicked = createAction(
  `[${moduleKey}] [${submoduleKey}] [${subsubmoduleKey}] Remove schedule clicked`,
  props<{ calendarId: string; scheduleId: string }>(),
);

export const toggleCalendarExpanded = createAction(
  `[${moduleKey}] [${submoduleKey}] [${subsubmoduleKey}] Toggle calendar expanded`,
  props<{ calendarId: string }>(),
);

export const toggleHolidaysExpanded = createAction(
  `[${moduleKey}] [${submoduleKey}] [${subsubmoduleKey}] Toggle holidays expanded`,
  props<{ calendarId: string }>(),
);

export const addNewCalendarClicked = createAction('[CalendarsTabComponent]: Create new calendar clicked');

export const editCalendarClicked = createAction(
  '[CalendarsTabComponent]: Edit calendar clicked',
  props<{ calendarId: string }>(),
);

export const addScheduleClicked = createAction(
  '[CalendarListItemComponent]: Add schedule clicked',
  props<{ calendarId: string }>(),
);

export const editScheduleClicked = createAction(
  '[ScheduleListItemComponent]: Edit schedule clicked',
  props<{ calendarId: string; scheduleId: string }>(),
);
