import { MapLocation } from '@rootTypes';

const coordinateRegex = new RegExp(
  '^[-+]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?),\\s*[-+]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)$',
);

export const getCoordinatesFromString = (source: string): MapLocation | null => {
  if (!source?.length) {
    return null;
  }
  const trimmed = source.trim();
  if (!coordinateRegex.test(trimmed)) {
    return null;
  }
  const [latStr, lonStr] = trimmed.split(', ');
  const lat = parseFloat(latStr);
  const lng = parseFloat(lonStr);
  if (lat < -90 || lat > 90 || lng < -180 || lng > 180) {
    return null;
  }
  return {
    lat,
    lng,
  };
};
