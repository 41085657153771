import { EntityState } from '@rootTypes';
import { MultiAccountManagementOption } from '@rootTypes/entities/auth/multi-subaccount-management-option';
import { createReducer, on } from '@ngrx/store';
import {
  loadMultiAccountManagementOptionsFailed,
  loadMultiAccountManagementOptionsRequested,
  loadMultiAccountManagementOptionsSuccess,
} from '../actions/multi-account-management.actions';

export interface MultiAccountManagementState {
  options: EntityState<MultiAccountManagementOption[]>;
}

export const getInitialState = (): MultiAccountManagementState => {
  return {
    options: {
      isLoading: false,
    },
  };
};

export const multiAccountManagementReducer = createReducer<MultiAccountManagementState>(
  getInitialState(),
  on(loadMultiAccountManagementOptionsRequested, (state, action) => {
    const newState: MultiAccountManagementState = {
      ...state,
      options: {
        isLoading: true,
      },
    };
    return newState;
  }),
  on(loadMultiAccountManagementOptionsSuccess, (state, action) => {
    const newState: MultiAccountManagementState = {
      ...state,
      options: {
        isLoading: false,
        entity: action.options,
      },
    };
    return newState;
  }),
  on(loadMultiAccountManagementOptionsFailed, (state, action) => {
    const newState: MultiAccountManagementState = {
      ...state,
      options: {
        isLoading: false,
        error: action.error,
      },
    };
    return newState;
  }),
);
