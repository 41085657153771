export enum StudentListName {
  MAIN_STUDENTS_LIST = 'MAIN_STUDENTS_LIST',
  DISTRICT_DETAILS_STUDENTS_TAB = 'DISTRICT_DETAILS_STUDENTS_TAB',
  CAMPUS_DETAILS_STUDENTS_TAB = 'CAMPUS_DETAILS_STUDENTS_TAB',
}

export const StudentListsArray = [
  StudentListName.MAIN_STUDENTS_LIST,
  StudentListName.DISTRICT_DETAILS_STUDENTS_TAB,
  StudentListName.CAMPUS_DETAILS_STUDENTS_TAB,
];
