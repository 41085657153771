import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromActions from '../actions/vehicle-list.actions';

@Injectable()
export class VehiclesSectionFacade {
  constructor(private store: Store) {}

  public initialize(): void {
    this.store.dispatch(fromActions.initVehiclesSection());
  }

  public destroy(): void {
    this.store.dispatch(fromActions.destroyVehiclesSection());
  }
}
