import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HomeComponent } from './containers/home/home.component';
import { SideMenuComponent } from './containers/side-menu/side-menu.component';
import { ProfileMenuComponent } from './containers/profile-menu/profile-menu.component';
import { SwitchAccountPopupComponent } from './containers/switch-account-popup/switch-account-popup.component';
import { SwitchAccountTransitionComponent } from './containers/switch-account-transition/switch-account-transition.component';
import { UserProfileComponent } from './containers/user-profile/user-profile.component';
import { UserProfilePreviewComponent } from './containers/user-profile-preview/user-profile-preview.component';
import { UserProfileInfoComponent } from './containers/user-profile-info/user-profile-info.component';
import { UserProfileInfoEditComponent } from './containers/user-profile-info-edit/user-profile-info-edit.component';
import { UserProfileChangePasswordComponent } from './containers/user-profile-change-password/user-profile-change-password.component';
import { UserProfileChangePasswordPreviewComponent } from './containers/user-profile-change-password-preview/user-profile-change-password-preview.component';
import { homeEffects, homeFeatureKey, homeReducer } from './store';
import { ResetPasswordRequestComponent } from './containers/reset-password-request/reset-password-request.component';
import { ComponentsModule } from '../shared/components/components.module';
import { AlertsModule } from '../shared/alerts/alerts.module';
import { FormControlsModule } from '../shared/form-controls/form-controls.module';
import { TypographyModule } from '../shared/typography/typography.module';
import { ButtonsModule } from '../shared/buttons/buttons.module';
import { PhotosModule } from '../shared/photos/photos.module';
import { SideBarModule } from '../shared/side-bar/side-bar.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../shared/pipes/pipes.module';
import { IconCloseModule } from '../shared/icons/icon-close/icon-close.module';
import { SchoolCampusAccountSelectPopupComponent } from './containers/school-campus-account-select-popup/school-campus-account-select-popup.component';
import { PageModule } from '../shared/page/page.module';
import { CrossEntitySearchModule } from '../shared/cross-entity-search/cross-entity-search.module';
import { AuthModule } from '../auth/auth.module';
import { HomePageResolverComponent } from './containers/home-page-resolver/home-page-resolver.component';
import { DirectivesModule } from '../shared/directives/directives.module';
import { EmployeeImpersonationSessionTimerComponent } from '../auth/containers/employee-impersonation/employee-impersonation-session-timer.component';
import { PolylineViewerComponent } from './containers/polyline-viewer/polyline-viewer.component';
import { GotoPageModule } from '../core/goto-page/goto-page.module';

@NgModule({
  declarations: [
    HomeComponent,
    SideMenuComponent,
    ProfileMenuComponent,
    SwitchAccountPopupComponent,
    SwitchAccountTransitionComponent,
    UserProfileComponent,
    UserProfilePreviewComponent,
    UserProfileInfoComponent,
    UserProfileInfoEditComponent,
    UserProfileChangePasswordComponent,
    UserProfileChangePasswordPreviewComponent,
    ResetPasswordRequestComponent,
    SchoolCampusAccountSelectPopupComponent,
    HomePageResolverComponent,
    PolylineViewerComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatInputModule,
    MatSelectModule,
    StoreModule.forFeature(homeFeatureKey, homeReducer),
    EffectsModule.forFeature(homeEffects),
    ComponentsModule,
    AlertsModule,
    FormControlsModule,
    TypographyModule,
    ButtonsModule,
    PhotosModule,
    SideBarModule,
    ReactiveFormsModule,
    PipesModule,
    IconCloseModule,
    PageModule,
    CrossEntitySearchModule,
    AuthModule,
    DirectivesModule,
    EmployeeImpersonationSessionTimerComponent,
    GotoPageModule,
  ],
})
export class HomeModule {}
