import * as fromTypes from '../../../types';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from '../../actions/campus-details/campus-details.actions';

export interface CampusDetailsState {
  campusId: string | undefined;
  tab: fromTypes.CampusDetailsTab;
}

const initialCampusDetailsState: CampusDetailsState = {
  campusId: undefined,
  tab: undefined,
};

export const campusDetailsReducer = createReducer<CampusDetailsState>(
  { ...initialCampusDetailsState },
  on(fromActions.setCampusId, (state, action) => {
    const { campusId } = action;
    const result: CampusDetailsState = {
      ...state,
      campusId,
    };
    return result;
  }),
  on(fromActions.setTab, (state, action) => {
    const { tab } = action;
    const result: CampusDetailsState = {
      ...state,
      tab,
    };
    return result;
  }),
);
