import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const moduleName = 'Yards';
const submoduleName = 'Details';

export const setYardId = createAction(
  `[${moduleName}] [${submoduleName}]: Set yard id`,
  props<{ yardId: string | undefined }>(),
);

export const setTab = createAction(
  `[${moduleName}] [${submoduleName}]: Set tab`,
  props<{ tab: fromTypes.YardDetailsTab }>(),
);
