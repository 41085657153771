import { Component, ChangeDetectionStrategy, Input, OnInit, OnDestroy } from '@angular/core';
import { SmartInputPhotoItem, SmartInputPhotoMulti } from '../../models/smart-input-photo-multi';
import { SnackbarService } from '../../../snackbar/snackbar.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wp-smart-input-photo-multi',
  templateUrl: './smart-input-photo-multi.component.html',
  styleUrls: ['./smart-input-photo-multi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartInputPhotoMultiComponent implements OnInit, OnDestroy {
  @Input() public model: SmartInputPhotoMulti;

  private sub: Subscription;

  constructor(private snackbar: SnackbarService) {}

  public ngOnInit(): void {
    this.sub = new Subscription();
    const errorSub = this.model.errorMessage$.subscribe((message) => {
      this.snackbar.error(message);
    });
    this.sub.add(errorSub);
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public removePhoto(itemId: string): void {
    this.model.removePhoto(itemId);
  }

  public trackByFn(index: number, item: SmartInputPhotoItem): string {
    return item?.itemId;
  }
}
