import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Address, MapBounds } from '@rootTypes';
import { GooglePlaceAutocompleteCustomUIStore } from '../../google-map/google-place-autocomplete/custom-ui/google-place-autocomplete-custom-ui-store';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'wp-input-address-custom-panel',
  providers: [GooglePlaceAutocompleteCustomUIStore],
  template: `
    <!--matPrefix and matSuffix are for consistent layout-->
    <mat-form-field class="wp-address-input" [hideRequiredMarker]="true">
      <mat-label *ngIf="!!label">{{ label }}</mat-label>
      <div matPrefix></div>
      <input
        matInput
        wpGoogleAddressAutocompleteCustomUIInput
        [required]="required"
        [selectedAddress]="selectedValue$ | async"
        [matAutocomplete]="panel.autocomplete"
        [matAutocompleteDisabled]="!panel.autocomplete"
        #searchInput
      />
      <div matSuffix>
        <div *ngIf="searchInput?.value?.length" class="suffix">
          <wp-icon-close (click)="searchInput.value = null; onCrossClicked()" [size]="'small'"></wp-icon-close>
        </div>
      </div>
      <!--      TODO: Implement error states, when this input control is used with forms i.e. required and "pick address from the list". We'll need to use ErrorStateMatcher. -->
      <wp-google-address-autocomplete-custom-ui-panel
        #panel
        (addressSelected)="control.setValue($event)"
      ></wp-google-address-autocomplete-custom-ui-panel>
    </mat-form-field>
  `,
  styleUrl: './input-address-custom-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputAddressCustomPanelComponent implements OnInit, OnChanges {
  @Input() public control: FormControl<Address>;
  @Input() public label: string;
  @Input() public required: boolean;
  // provide map bounds to bias search towards the region selected on the map
  @Input() public searchBias: MapBounds;

  public selectedValue$: Observable<Address | null>;

  constructor(private store: GooglePlaceAutocompleteCustomUIStore) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchBias) {
      this.store.setGoogleAutocompleteBias(this.searchBias);
    }
  }

  ngOnInit(): void {
    this.selectedValue$ = this.control.valueChanges.pipe(startWith(this.control.value));
  }

  onCrossClicked(): void {
    this.store.resetSearch();
    this.control.setValue(null);
  }
}
