import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { isHalfDrawer } from 'src/app/store/selectors';

@Component({
  selector: 'wp-popup-page-back-bar',
  templateUrl: './popup-page-back-bar.component.html',
  styleUrls: ['./popup-page-back-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupPageBackBarComponent implements OnInit {
  @Input() public pageTitle: string;
  @Input() public displayBackText: string;
  @Input() public callHistoryBackOnClick = true;
  @Input() public preventGoBackOnBackClick: boolean;

  @Output() public backClicked: EventEmitter<void> = new EventEmitter<void>();

  public isHalfDrawer$: Observable<boolean>;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.isHalfDrawer$ = this.store.select(isHalfDrawer);
  }

  public onBackClicked(event: MouseEvent): void {
    if (this.preventGoBackOnBackClick) {
      this.backClicked.emit();
    } else {
      // call window history back directly
      // to ensure correct behavior in Safari
      // right now it doesn't work, if dispatch through effects
      if (this.callHistoryBackOnClick) {
        window.history.back();
      } else {
        this.backClicked.emit();
      }
    }
  }
}
