import { createAction, props } from '@ngrx/store';

import {
  WpError,
  EntityFilterRequest,
  WorkQueueStudentRequestsRouteData,
  EntityAggregateResultStudentChangeRequest,
  StudentChangeRequestResult,
  GetPendingStudentChangeRequestsByStudentResponse,
  GetStudentChangeRequestSummaryResponse,
  EntityAggregateRequest,
} from '../../dependencies';
import { StudentRequestQuickFilter } from '../../dependencies/types/student-request-quick-filter';

const workQueue = '[StudentRequestsWorkQueue]';

export const initWorkQueueStudentRequestsState = createAction(
  `${workQueue} Init state`,
  props<{
    routeData: WorkQueueStudentRequestsRouteData;
    selectedStudentId?: string;
    selectedProcessedRequestId?: string;
  }>(),
);
export const destroyWorkQueueStudentRequestsState = createAction(`${workQueue} Destroy state`);

// Filters
export const workQueueStudentRequestsFilterStudentSelected = createAction(
  `${workQueue} Student filter selected`,
  props<{ studentId: string }>(),
);

export const workQueueStudentRequestsQuickFilterSelected = createAction(
  `${workQueue} Quick filter selected`,
  props<{ value: StudentRequestQuickFilter }>(),
);

export const workQueueStudentRequestsQuickFilterUnSelected = createAction(`${workQueue} Quick filter unselected`);

export const workQueueStudentRequestsFilterStudentUnselected = createAction(
  `${workQueue} Student filter unselected`,
  props<{ studentId: string }>(),
);
export const workQueueStudentRequestsFilterCampusSelected = createAction(
  `${workQueue} Campus filter selected`,
  props<{ campusId: string }>(),
);
export const workQueueStudentRequestsFilterCampusUnselected = createAction(
  `${workQueue} Campus filter unselected`,
  props<{ campusId: string }>(),
);
export const workQueueStudentRequestsFilterDistrictSelected = createAction(
  `${workQueue} District filter selected`,
  props<{ districtId: string }>(),
);
export const workQueueStudentRequestsFilterDistrictUnselected = createAction(
  `${workQueue} District filter unselected`,
  props<{ districtId: string }>(),
);
export const workQueueStudentRequestsFiltersCleared = createAction(`${workQueue} Filters cleared`);
export const workQueueStudentRequestsStatusSelected = createAction(
  `${workQueue} Status selected`,
  props<{ isStatusPending: boolean }>(),
);

// Pagination
export const workQueueStudentRequestsPageSelected = createAction(
  `${workQueue} Page selected`,
  props<{ pageIndex: number }>(),
);
export const workQueueStudentRequestsPageSizeSelected = createAction(
  `${workQueue} Page size selected`,
  props<{ pageSize: number }>(),
);

// Page items
export const workQueueStudentRequestsPageItemsRequested = createAction(
  `${workQueue} Page items requested`,
  props<{ pending?: EntityAggregateRequest; processed?: EntityFilterRequest }>(),
);
export const workQueueStudentRequestsPageItemsSuccess = createAction(
  `${workQueue} Page items success`,
  props<{
    data: {
      pendingResults?: EntityAggregateResultStudentChangeRequest[];
      processedResults?: StudentChangeRequestResult[];
      totalFound: number;
    };
  }>(),
);
export const workQueueStudentRequestsPageItemsFailed = createAction(
  `${workQueue} Page items failed`,
  props<{ error: WpError }>(),
);

export const workQueueStudentRequestsPageItemDetailsPendingRequested = createAction(
  `${workQueue} Page item details pending requested`,
  props<{ studentId: string }>(),
);
export const workQueueStudentRequestsPageItemDetailsPendingSuccess = createAction(
  `${workQueue} Page item details pending success`,
  props<{ studentId: string; response: GetPendingStudentChangeRequestsByStudentResponse }>(),
);
export const workQueueStudentRequestsPageItemDetailsPendingFailed = createAction(
  `${workQueue} Page item details pending failed`,
  props<{ studentId: string; error: WpError }>(),
);

export const workQueueStudentRequestsPageItemDetailsProcessedRequested = createAction(
  `${workQueue} Page item details processed requested`,
  props<{ studentChangeRequestId: string }>(),
);
export const workQueueStudentRequestsPageItemDetailsProcessedSuccess = createAction(
  `${workQueue} Page item details processed success`,
  props<{ studentChangeRequestId: string; response: GetStudentChangeRequestSummaryResponse }>(),
);
export const workQueueStudentRequestsPageItemDetailsProcessedFailed = createAction(
  `${workQueue} Page item details processed failed`,
  props<{ studentChangeRequestId: string; error: WpError }>(),
);

export const openStudentRequestListFromWorkQueue = createAction(
  `${workQueue} Open student request list`,
  props<{ selectedStudentId: string }>(),
);
export const openStudentRequestListOutsideWorkQueue = createAction(
  `${workQueue} Open student request list outside`,
  props<{ selectedStudentId: string }>(),
);
export const workQueueStudentRequestListClosed = createAction(`${workQueue} Student request list closed`);

export const workQueueStudentRequestsUpdatePendingListOnRequestResolve = createAction(
  `${workQueue} Update pending list on request resolve`,
  props<{ studentId: string }>(),
);

export const openStudentRequestProcessedDetailsFromWorkQueue = createAction(
  `${workQueue} Open student request processed details`,
  props<{ studentChangeRequestId: string }>(),
);
export const openStudentRequestProcessedDetailsOutsideWorkQueue = createAction(
  `${workQueue} Open student request processed details outside`,
  props<{ studentChangeRequestId: string }>(),
);
export const workQueueStudentRequestProcessedDetailsClosed = createAction(
  `${workQueue} Student request processed details closed`,
);
