import { YYYYMMDDString } from '@rootTypes';
import * as fromGlobalTypes from '../../../types';

export enum RideStatus {
  PAST = 'PAST',
  UPCOMING = 'UPCOMING',
  ACCEPTED = 'ACCEPTED',
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED',
}

export type RideFilterRequest = {
  parentIds?: Array<string>;
  studentIds?: Array<string>;
  driverIds?: Array<string>;
  routeIds?: Array<string>;
  date?: {
    from: YYYYMMDDString;
    to: YYYYMMDDString;
  };
  rideStatuses?: Array<RideStatus>;
  districtIds?: Array<string>;
  campusIds?: Array<string>;
  regionIds?: Array<string>;
  zoneIds?: Array<string>;
  vendorIds?: Array<string>;
  yardIds?: Array<string>;
  rideIds?: Array<string>;
  tripIds?: Array<string>;
  routeGroupIds?: Array<string>;
  operatorIds?: Array<string>;
  skip: number;
  limit: number;
  order?: fromGlobalTypes.RideOrder;
  isCharterRide?: boolean;
  orderByFields?: OrderByField[];
};

export type OrderByField = {
  fieldName: string;
  order?: 'asc' | 'desc'; // default is 'asc'
};
