import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import * as fromTypes from '../types';

@Component({
  selector: 'wp-dp-day-placeholder',
  templateUrl: './dp-day-placeholder.component.html',
  styleUrls: ['./dp-day-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DpDayPlaceholderComponent implements OnInit {
  @Input() public day: fromTypes.RangePickerDay;

  constructor() {}

  ngOnInit(): void {}
}
