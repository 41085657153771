import { SchoolEmployee } from '@apiEntities/school-employee';
import { Employee } from '@rootTypes/entities/employees';

export const getEmployeeFromApiSchoolEmployee = (source: SchoolEmployee): Employee => {
  const copy = { ...source };
  delete copy.districtId;
  delete copy.schoolEmployeeImagePath;
  const result: Employee = {
    ...copy,
    parentEntityId: source.districtId,
    photo: source.schoolEmployeeImagePath,
  };
  return result;
};
