import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wp-grey-closable-block',
  templateUrl: './grey-closable-block.component.html',
  styleUrls: ['./grey-closable-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GreyClosableBlockComponent implements OnInit {
  @Input() public title: string;
  @Output() public closed: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  public onClose(): void {
    this.closed.emit();
  }
}
