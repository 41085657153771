export enum DriverStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  SUSPENDED = 'SUSPENDED',
  BLACKLISTED = 'BLACKLISTED',
  DRAFT = 'DRAFT',
  // FE sets this status after deleting the draft driver to disable the driver list item.
  // BE removes the driver from the database so next time user refreshes the driver list the item will disappear.
  DELETED = 'DELETED',
}
