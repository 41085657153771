import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SideBarService } from '../side-bar.service';

@Component({
  selector: 'wp-side-bar-item',
  templateUrl: './side-bar-item.component.html',
  styleUrls: ['./side-bar-item.component.scss'],
})
export class SideBarItemComponent implements OnInit {
  @Input() public id!: string;
  @Input() public displayIconTooltip: string;
  @Input() public isSideBarOpened: boolean;
  @Input() private selectable = true;

  public isActive$: Observable<boolean>;

  constructor(private sideBarService: SideBarService) {
    this.isActive$ = this.sideBarService.active$.pipe(map((id) => this.id === id));
  }

  ngOnInit(): void {}

  public onSelect(): void {
    if (!this.selectable) {
      return;
    }
    this.sideBarService.active = this.id;
  }
}
