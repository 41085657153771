import { MapTrackerConfig } from '../map-tracker-config';
import { MapUtilsGoogle } from './map-utils-google';
import { DefaultChangeDetectionOptimizer } from './default-change-detection-optimizer';

export const defaultMapTrackerConfig: MapTrackerConfig = {
  animationBatchSize: 200,
  mapUtils: new MapUtilsGoogle(),
  isAnimateBearing: false,
  bearingAnimationStep: 10,
  tickerFn: requestAnimationFrame,
  changeDetectionOptimizer: new DefaultChangeDetectionOptimizer(),
};
