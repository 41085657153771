<ng-container *ngIf="value; else noVal">
  <div>
    <div class="item">
      <wp-field-label-down-value
        [label]="'Type'"
        [value]="value.locationType"
      ></wp-field-label-down-value>
    </div>
    <div class="item">
      <wp-field-label-down-value
        [label]="'Address'"
        [value]="value.address?.formatted_address || '--'"
      ></wp-field-label-down-value>
    </div>
    <div class="item">
      <wp-display-exact-location
        [short]="true"
        [exactLocation]="value.exactLocation"
      ></wp-display-exact-location>
    </div>
  </div>
</ng-container>

<ng-template #noVal>--</ng-template>
