import { SelectOption } from 'src/app/shared/form-controls';

export type StateUSA = SelectOption;

export class StatesUSA {
  private static states: { [token: string]: StateUSA } = {
    AL: {
      displayLabel: 'Alabama',
      value: 'AL',
    },
    AK: {
      displayLabel: 'Alaska',
      value: 'AK',
    },
    AS: {
      displayLabel: 'American Samoa',
      value: 'AS',
    },
    AZ: {
      displayLabel: 'Arizona',
      value: 'AZ',
    },
    AR: {
      displayLabel: 'Arkansas',
      value: 'AR',
    },
    CA: {
      displayLabel: 'California',
      value: 'CA',
    },
    CO: {
      displayLabel: 'Colorado',
      value: 'CO',
    },
    CT: {
      displayLabel: 'Connecticut',
      value: 'CT',
    },
    DE: {
      displayLabel: 'Delaware',
      value: 'DE',
    },
    DC: {
      displayLabel: 'District Of Columbia',
      value: 'DC',
    },
    FM: {
      displayLabel: 'Federated States Of Micronesia',
      value: 'FM',
    },
    FL: {
      displayLabel: 'Florida',
      value: 'FL',
    },
    GA: {
      displayLabel: 'Georgia',
      value: 'GA',
    },
    GU: {
      displayLabel: 'Guam',
      value: 'GU',
    },
    HI: {
      displayLabel: 'Hawaii',
      value: 'HI',
    },
    ID: {
      displayLabel: 'Idaho',
      value: 'ID',
    },
    IL: {
      displayLabel: 'Illinois',
      value: 'IL',
    },
    IN: {
      displayLabel: 'Indiana',
      value: 'IN',
    },
    IA: {
      displayLabel: 'Iowa',
      value: 'IA',
    },
    KS: {
      displayLabel: 'Kansas',
      value: 'KS',
    },
    KY: {
      displayLabel: 'Kentucky',
      value: 'KY',
    },
    LA: {
      displayLabel: 'Louisiana',
      value: 'LA',
    },
    ME: {
      displayLabel: 'Maine',
      value: 'ME',
    },
    MH: {
      displayLabel: 'Marshall Islands',
      value: 'MH',
    },
    MD: {
      displayLabel: 'Maryland',
      value: 'MD',
    },
    MA: {
      displayLabel: 'Massachusetts',
      value: 'MA',
    },
    MI: {
      displayLabel: 'Michigan',
      value: 'MI',
    },
    MN: {
      displayLabel: 'Minnesota',
      value: 'MN',
    },
    MS: {
      displayLabel: 'Mississippi',
      value: 'MS',
    },
    MO: {
      displayLabel: 'Missouri',
      value: 'MO',
    },
    MT: {
      displayLabel: 'Montana',
      value: 'MT',
    },
    NE: {
      displayLabel: 'Nebraska',
      value: 'NE',
    },
    NV: {
      displayLabel: 'Nevada',
      value: 'NV',
    },
    NH: {
      displayLabel: 'New Hampshire',
      value: 'NH',
    },
    NJ: {
      displayLabel: 'New Jersey',
      value: 'NJ',
    },
    NM: {
      displayLabel: 'New Mexico',
      value: 'NM',
    },
    NY: {
      displayLabel: 'New York',
      value: 'NY',
    },
    NC: {
      displayLabel: 'North Carolina',
      value: 'NC',
    },
    ND: {
      displayLabel: 'North Dakota',
      value: 'ND',
    },
    MP: {
      displayLabel: 'Northern Mariana Islands',
      value: 'MP',
    },
    OH: {
      displayLabel: 'Ohio',
      value: 'OH',
    },
    OK: {
      displayLabel: 'Oklahoma',
      value: 'OK',
    },
    OR: {
      displayLabel: 'Oregon',
      value: 'OR',
    },
    PW: {
      displayLabel: 'Palau',
      value: 'PW',
    },
    PA: {
      displayLabel: 'Pennsylvania',
      value: 'PA',
    },
    PR: {
      displayLabel: 'Puerto Rico',
      value: 'PR',
    },
    RI: {
      displayLabel: 'Rhode Island',
      value: 'RI',
    },
    SC: {
      displayLabel: 'South Carolina',
      value: 'SC',
    },
    SD: {
      displayLabel: 'South Dakota',
      value: 'SD',
    },
    TN: {
      displayLabel: 'Tennessee',
      value: 'TN',
    },
    TX: {
      displayLabel: 'Texas',
      value: 'TX',
    },
    UT: {
      displayLabel: 'Utah',
      value: 'UT',
    },
    VT: {
      displayLabel: 'Vermont',
      value: 'VT',
    },
    VI: {
      displayLabel: 'Virgin Islands',
      value: 'VI',
    },
    VA: {
      displayLabel: 'Virginia',
      value: 'VA',
    },
    WA: {
      displayLabel: 'Washington',
      value: 'WA',
    },
    WV: {
      displayLabel: 'West Virginia',
      value: 'WV',
    },
    WI: {
      displayLabel: 'Wisconsin',
      value: 'WI',
    },
    WY: {
      displayLabel: 'Wyoming',
      value: 'WY',
    },
  };

  private constructor() {}

  public static getStateByToken(token: string): StateUSA | undefined {
    return this.states[token] ? this.states[token] : undefined;
  }

  public static toArray(): StateUSA[] {
    return Object.keys(this.states).map((key) => {
      return this.states[key];
    });
  }
}
