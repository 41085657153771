import { createAction, props } from '@ngrx/store';
import { ApiGrade } from '../../../../api/endpoints/common';
import { WpError } from '@rootTypes';

export const getDefaultDistrictGradesRequested = createAction(
  '[Default District Grades] Get Default District Grades Requested',
);

export const getDefaultDistrictGradesSuccess = createAction(
  '[Default District Grades] Get Default District Grades Success',
  props<{ standardGrades: ApiGrade[] }>(),
);

export const getDefaultDistrictGradesFailed = createAction(
  '[Default District Grades] Get Default District Grades Failed',
  props<{ error: WpError }>(),
);
