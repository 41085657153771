<div class="ic-parent-brand">
  <svg width="28" height="28" viewBox="0 0 28 28">
    <g fill="none" fill-rule="evenodd">
      <g fill="#47A540" fill-rule="nonzero">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d="M5.92 14.61v2.374c-.816.632-1.642 1.156-2.514 1.624-.451.242-1.734.86-1.925.973C.308 20.279 0 21.047 0 22.64v3.66C0 27.193.55 28 1.57 28h12.329v-2.333H2.333V22.64c0-.854 0-.852.34-1.055.084-.05 1.302-.636 1.835-.922 1.152-.617 2.239-1.326 3.322-2.22l.424-.35v-4.672l-.46-.35c-.103-.079-.45-.314-.492-.343-.167-.12-.299-.229-.422-.355-.59-.603-.945-1.572-.945-3.27V6.998c0-2.747 1.856-4.664 4.582-4.664 2.517 0 3.123.449 4.138 2.617l2.113-.989C15.393 1.024 14.011 0 10.517 0 6.486 0 3.6 2.978 3.6 6.997v2.108c0 2.28.572 3.838 1.61 4.9.223.227.45.417.71.604z"
                      transform="translate(-534 -394) translate(524 36) translate(0 40) translate(0 310) translate(10 8)"
                    />
                    <path
                      d="M25.605 19.725c1.156.69 1.937 1.855 2.356 3.424.026.098.039.2.039.3v3.384c0 .645-.522 1.167-1.167 1.167H8.167C7.522 28 7 27.478 7 26.833V23.45l.008-.136c.206-1.765 1.087-3.043 2.59-3.665.992-.412 2.27-.747 3.843-1.014-1.838-.17-3.095-.82-3.656-2.076-.291-.652-.394-1.043-.244-1.536.126-.415.42-.706.786-.857.172-.07.296-.095.505-.124.248-.033.276-.042.333-.089.021-.018.046-.042.023-.01.052-.073.086-.126.11-.168.108-.197.12-.392.092-2.586-.01-.814-.015-1.486-.015-2.27 0-4.946 6.103-6.914 8.567-4.487.025.025-.063.013.187-.033l.081-.015c1.568-.28 2.563.5 3.427 2.966.043.124.065.255.065.386 0 .929-.03 1.758-.093 2.935-.097 1.782-.106 2.003-.083 2.485.011.23.034.397.063.502.434.315.66.572.847.924l.007.013c.036.069.023.053.184.176 1.092.839.864 2.207-.279 2.957-.628.413-1.558.698-2.748.845v.02c1.791.234 3.116.6 4.005 1.132zM9.333 25.667h16.334v-2.058c-.274-.939-.697-1.546-1.258-1.88-.688-.411-2.068-.74-4.088-.933-.603-.057-1.062-.566-1.056-1.171.002-.317.002-1.026 0-2.122-.002-.624.487-1.138 1.11-1.168.953-.046 1.698-.172 2.197-.335-.07-.099-.133-.202-.194-.317l-.005-.01c-.018-.036-.011-.028-.222-.174-1.06-.735-1.08-1.134-.872-4.954.057-1.052.085-1.807.09-2.608-.278-.756-.512-1.163-.664-1.297.026.013.026.022-.083.041l-.07.013c-.41.076-.627.098-.955.055-.479-.063-.913-.281-1.292-.655-.27-.264-1.506-.397-2.557-.058-1.292.416-2.04 1.343-2.04 2.883 0 .774.005 1.436.015 2.24.036 2.84.026 3.003-.383 3.744-.072.13-.153.256-.302.46-.12.145-.248.274-.385.387-.116.096-.225.176-.332.242.424.223 1.16.358 2.27.341.65-.01 1.183.516 1.183 1.167v2.135c0 .581-.428 1.074-1.003 1.155-1.862.263-3.293.605-4.28 1.015-.668.276-1.037.796-1.158 1.717v2.145z"
                      transform="translate(-534 -394) translate(524 36) translate(0 40) translate(0 310) translate(10 8)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</div>
