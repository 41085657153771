import { PortalListBaseParams } from '@rootTypes/utils/list-store-utils/portal-list-base-state';
import { PortalListFilter } from '@rootTypes/utils/list-store-utils/portal-list-filter';
import { capitalize } from '@rootTypes/utils/common/capitalize';

export function serializeBaseParamsToUrl<Params extends PortalListBaseParams<PortalListFilter>>(
  params: Partial<Params>,
  defaultValues: Partial<Params>,
  prefix?: string,
): any {
  const result = {};
  if (params.status !== defaultValues.status) {
    result[getFieldNameWithPrefix('status', prefix)] = params.status;
  } else {
    result[getFieldNameWithPrefix('status', prefix)] = undefined;
  }
  if ((params.page || 0) !== (defaultValues.page || 0)) {
    result[getFieldNameWithPrefix('page', prefix)] = params.page + 1;
  } else {
    result[getFieldNameWithPrefix('page', prefix)] = undefined;
  }
  if (params.pageSize !== defaultValues.pageSize) {
    result[getFieldNameWithPrefix('pageSize', prefix)] = params.pageSize;
  } else {
    result[getFieldNameWithPrefix('pageSize', prefix)] = undefined;
  }
  if (params?.filters?.length) {
    result[getFieldNameWithPrefix('filters', prefix)] = params.filters.map((f) => f.id).join(';');
  } else {
    result[getFieldNameWithPrefix('filters', prefix)] = undefined;
  }
  if (params.searchQuery?.length) {
    result[getFieldNameWithPrefix('searchQuery', prefix)] = params.searchQuery;
  } else {
    result[getFieldNameWithPrefix('searchQuery', prefix)] = undefined;
  }
  return result;
}

export const getFieldNameWithPrefix = (field: string, prefix: string): string => {
  if (!prefix) {
    return field;
  }
  return prefix + capitalize(field);
};
