import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { drawerClosedSelected, getDisplayBackText, State } from '../../../../store';
import { Observable } from 'rxjs';
import * as fromSelectors from '../selectors/vendor-details.selectors';
import * as fromActions from '../actions/vendor-details.actions';
import * as fromTypes from '../../types';

@Injectable()
export class VendorDetailsFacade {
  constructor(private store: Store<State>) {}

  public getDisplayBackText$(): Observable<string | undefined> {
    return this.store.select(getDisplayBackText);
  }

  public getVendorId(): Observable<string> {
    return this.store.select(fromSelectors.getVendorId);
  }

  public getTab(): Observable<fromTypes.VendorDetailsTabs> {
    return this.store.select(fromSelectors.getTab);
  }

  public getVendor$(): Observable<fromTypes.Vendor> {
    return this.store.select(fromSelectors.getOpenedVendor);
  }

  public getVendorLoading$(): Observable<boolean> {
    return this.store.select(fromSelectors.getOpenedVendorLoading);
  }

  public getVendorError$(): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getOpenedVendorError);
  }

  public onSetVendorId(vendorId: string): void {
    this.store.dispatch(fromActions.setVendorId({ vendorId }));
  }

  public onSetTab(tab: fromTypes.VendorDetailsTabs): void {
    this.store.dispatch(fromActions.setTab({ tab }));
  }

  public onDrawerClosed(): void {
    this.store.dispatch(drawerClosedSelected());
  }
}
