import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-vertical-collapse-toggle-button',
  template: `
    <div [ngStyle]="{ width: size + 'px', height: size + 'px' }" class="toggle-wrap" [ngClass]="{ collapsed }">
      <div class="toggle-inner">
        <svg height="100%" width="100%" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d_253_220223)">
            <rect
              x="24"
              y="22"
              width="20"
              height="20"
              rx="10"
              transform="rotate(-180 24 22)"
              fill="white"
              class="bg"
              shape-rendering="crispEdges"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.3981 15.7147C13.6946 15.9927 13.6946 16.4634 13.3981 16.7413C13.1275 16.9951 12.7063 16.9951 12.4357 16.7413L8.05637 12.6357C7.89641 12.4858 7.82274 12.2797 7.83537 12.0781C7.82274 11.8765 7.89641 11.6705 8.05637 11.5205L12.4357 7.41491C12.7063 7.16118 13.1275 7.16118 13.3981 7.41491C13.6946 7.69289 13.6946 8.16355 13.3981 8.44153L9.5191 12.0781L13.3981 15.7147Z"
              fill="#636365"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.2067 15.7147C18.5032 15.9927 18.5032 16.4634 18.2067 16.7413C17.9361 16.9951 17.5149 16.9951 17.2443 16.7413L12.865 12.6357C12.705 12.4858 12.6313 12.2797 12.644 12.0781C12.6313 11.8765 12.705 11.6705 12.865 11.5205L17.2443 7.41491C17.5149 7.16118 17.9361 7.16118 18.2067 7.41491C18.5032 7.69289 18.5032 8.16355 18.2067 8.44153L14.3277 12.0781L18.2067 15.7147Z"
              fill="#636365"
            />
            <rect
              x="23.5"
              y="21.5"
              width="19"
              height="19"
              rx="9.5"
              transform="rotate(-180 23.5 21.5)"
              stroke="#DFDFE3"
              shape-rendering="crispEdges"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_253_220223"
              x="0"
              y="0"
              width="28"
              height="28"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_253_220223" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_253_220223" result="shape" />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  `,
  styles: [
    `
      .toggle-wrap {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        overflow: visible;
      }

      .toggle-inner {
        --toggle-neg-margin: -5px;
        position: absolute;
        left: var(--toggle-neg-margin);
        right: var(--toggle-neg-margin);
        top: var(--toggle-neg-margin);
        bottom: var(--toggle-neg-margin);
        display: flex;
        align-items: center;
      }

      .toggle-wrap.collapsed {
        -moz-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        transform: scale(-1, 1);
      }

      .toggle-wrap:hover .bg {
        fill: #f1f8f0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalCollapseToggleButtonComponent implements OnInit {
  @Input() collapsed: boolean;
  @Input() public size = 20;
  constructor() {}

  ngOnInit(): void {}
}
