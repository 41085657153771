import { NgModule } from '@angular/core';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

export const defaultSnackbarDurationMs = 2000;

@NgModule({
  declarations: [],
  imports: [MatSnackBarModule],
  exports: [MatSnackBarModule],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: defaultSnackbarDurationMs },
    },
  ],
})
export class SnackbarModule {}
