import { AbstractControl } from '@angular/forms';

/**
 * Converts '+1 (999) 888 7777', '+19998887777', '9998887777'
 * to '9998887777' string
 */
export const unformatPhone = (source: string | number): string => {
  // 1. remove all symbols except numbers
  let target = ('' + source).replace(/\D/g, '');

  // 2. take last 10 symbols
  target = target.slice(-10);

  return target;
};

/**
 * Formats phone number if the source is a valid phone string
 */
export const formatPhone = (source: string | number): string => {
  if (!source) {
    return null;
  }
  const value: string = unformatPhone(source);
  return value.replace(/(\d{3})(\d{3})(\d{4})/g, '($1) $2 $3');
};

export const phoneValidator = (control: AbstractControl): { invalidPhoneNumber: true } | null => {
  if (!control.value) {
    return null;
  }
  const unformatted = unformatPhone(control.value);
  return unformatted.length === 10 ? null : { invalidPhoneNumber: true };
};
