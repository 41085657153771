<div
  class="wp-section-spinner__section"
  [ngStyle]="{ height: sectionHeight }"
  [ngClass]="{ 'full-height': sectionHeight === '100%'}"
>
  <div
    class="wp-section-spinner__box"
    [ngStyle]="{ width: spinnerSize + 'px', height: spinnerSize + 'px' }"
  >
    <mat-spinner [diameter]="spinnerSize"></mat-spinner>
  </div>
</div>
