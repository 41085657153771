import { Yard } from '../../entities/yard';
import { GetVendorProfileResponse } from '../get-vendor-profile';

export type GetYardRequest = {
  yardId: string;
  _projections?: YardProjection[];
};

export enum YardProjection {
  vendor = 'vendor',
}

export type GetYardResponse = {
  yard: Yard;
  _projections: {
    vendor?: GetVendorProfileResponse;
  };
};
