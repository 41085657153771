import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getCatalog } from '../../data/trips/trip-data.selectors';
import { map } from 'rxjs/operators';
import { getCharterCatalogRequested } from '../../data/trips/actions';

@Pipe({
  name: 'displayCatalogLabel',
})
export class DisplayCatalogLabelPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(catalogId: string): Observable<string> {
    // TODO: remove this setTimeout once we have a better way to handle this (dispatch actions are not allowed in pipe transform context in the new ngrx)
    setTimeout(() => {
      this.store.dispatch(getCharterCatalogRequested({ catalogId }));
    });
    return this.store.select(getCatalog(catalogId)).pipe(map((c) => c?.displayName));
  }
}
