import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'wp-separator-horizontal-with-mid-text',
  template: `
    <div [ngStyle]="{ margin }" class="or">
      <div class="divider start">
        <wp-divider-horizontal-line></wp-divider-horizontal-line>
      </div>
      <div>
        <ng-content></ng-content>
      </div>
      <div class="divider end">
        <wp-divider-horizontal-line></wp-divider-horizontal-line>
      </div>
    </div>
  `,
  styles: `
    .or {
      display: flex;
      align-items: center;
      text-transform: uppercase;
    }
    .divider {
      flex: 1;
    }
    .divider.start {
      margin-right: 16px;
    }
    .divider.end {
      margin-left: 16px;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeparatorHorizontalWithMidTextComponent {
  @Input() public margin = '16px 0';
}
