export const generateId = (prefix = 'wp-', length = 10): string => {
  const dec2hex = (dec: number): string => {
    return ('0' + dec.toString(16)).substr(-2);
  };

  const arr = new Uint8Array(length / 2);
  // for IE 11, see https://developer.mozilla.org/en-US/docs/Web/API/Window/crypto
  const crypto = window.crypto || window['msCrypto'];
  crypto.getRandomValues(arr);

  return `${prefix}${Array.from(arr, dec2hex).join('')}`;
};
