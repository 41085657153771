import { Address } from '@rootTypes';

interface Geometry {
  location: {
    lat: number;
    lng: number;
  };
}
const normalizeGeometry = (g: google.maps.places.PlaceGeometry): Geometry => {
  return {
    location: {
      lat: (typeof g.location.lat === 'function' ? g.location.lat() : g.location.lat) as number,
      lng: (typeof g.location.lng === 'function' ? g.location.lng() : g.location.lng) as number,
    },
  };
};

export const getAddressFromPlace = (place: google.maps.places.PlaceResult): Address => {
  return {
    address_components: place.address_components as any,
    formatted_address: place.formatted_address,
    name: place.name,
    geometry: normalizeGeometry(place.geometry),
  };
};
