import { driverEditorPath } from './driver-editor-path';
import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { driver } from '@rootTypes/entities';
import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';
// prevent circular dependency

export interface EditDriverProfileRouteData {
  driverId: string;
  type: string;
}

const pathPrefix = 'edit';

export const editDriverProfileRoute: PortalRoute<EditDriverProfileRouteData> = {
  path: `${pathPrefix}/:driverId/:type`,
  isOnRoute(state: RouterStateUrl): boolean {
    return (
      state.drawer && state.drawer.url && state.drawer.url.startsWith(`_/drawer/${driverEditorPath}/${pathPrefix}`)
    );
  },
  parse(state: RouterStateUrl): EditDriverProfileRouteData {
    const { driverId, type } = state.drawer.params;
    return {
      driverId,
      type: type === 'vendor' ? driver.DriverType.VENDOR : driver.DriverType.ZUMER,
    };
  },
  request(data: EditDriverProfileRouteData): NavigationRequest {
    const type = data.type === driver.DriverType.VENDOR ? 'vendor' : 'zumer';
    const request: NavigationRequest = {
      path: ['_', 'drawer', driverEditorPath, pathPrefix, data.driverId, type],
      extras: { queryParamsHandling: 'preserve', replaceUrl: true },
    };
    return request;
  },
};

@Injectable({
  providedIn: 'root',
})
export class EditDriverProfileRouterService extends PortalRouterService<EditDriverProfileRouteData> {
  outlet = RouterOutlets.DRAWER;
  route: PortalRoute<EditDriverProfileRouteData> = editDriverProfileRoute;
}
