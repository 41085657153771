import { SmartFormFieldEmail, SmartFormFieldType } from '@apiEntities/smart-forms';

export const isSmartFormFieldEmail = (arg: any): arg is SmartFormFieldEmail => {
  return !!arg && typeof arg === 'object' && arg.type === SmartFormFieldType.EMAIL;
};

export const isSmartFormFieldEmailValid = (email: SmartFormFieldEmail, required: boolean): boolean => {
  if (!required && !email.value) {
    return true;
  }
  return typeof email.value === 'string' && email.value.length && email.value !== '--';
};
