import { createReducer, on } from '@ngrx/store';
import * as fromActions from '../actions';
import { SideBarTabId } from '@rootTypes';
import { screenXLargeChanged } from '../actions';

export interface HomeUIState {
  sectionRouteLoading: boolean;
  sideBar: {
    selectedSection: SideBarTabId;
    isExpanded: boolean;
  };
  drawer: {
    isOpened: boolean;
    isHalfDrawer: boolean;
  };
  screen: {
    isXLarge: boolean;
  };
}

export const initialUIState: HomeUIState = {
  sectionRouteLoading: false,
  sideBar: {
    selectedSection: 'rides',
    isExpanded: false,
  },
  drawer: {
    isOpened: false,
    isHalfDrawer: false,
  },
  screen: {
    isXLarge: false,
  },
};

export const homeUIReducer = createReducer(
  initialUIState,
  on(screenXLargeChanged, (state, action) => {
    const newState: HomeUIState = {
      ...state,
      screen: {
        ...state.screen,
        isXLarge: action.isXLarge,
      },
    };
    return newState;
  }),
  on(fromActions.sectionLoadStart, (state, action) => {
    const newState: HomeUIState = {
      ...state,
      sectionRouteLoading: true,
    };
    return newState;
  }),
  on(fromActions.sectionLoadEnd, (state, action) => {
    const newState: HomeUIState = {
      ...state,
      sectionRouteLoading: false,
    };
    return newState;
  }),
  on(fromActions.setInitialSideBarExpand, (state, action) => {
    const newState: HomeUIState = {
      ...state,
      sideBar: {
        ...state.sideBar,
        isExpanded: action.isExpanded,
      },
    };
    return newState;
  }),
  on(fromActions.sideBarExpandToggled, (state, action) => {
    const newState: HomeUIState = {
      ...state,
      sideBar: {
        ...state.sideBar,
        isExpanded: !state.sideBar.isExpanded,
      },
    };
    return newState;
  }),
  on(fromActions.sideBarSectionChanged, (state, action) => {
    const newState: HomeUIState = {
      ...state,
      sideBar: {
        ...state.sideBar,
        selectedSection: action.section,
      },
    };
    return newState;
  }),
  // Drawer
  on(fromActions.drawerOpenedChanged, (state, action) => {
    const newState: HomeUIState = {
      ...state,
      drawer: {
        isOpened: true,
        isHalfDrawer: action.isHalfDrawer,
      },
    };
    return newState;
  }),
  on(fromActions.drawerClosedChanged, (state, action) => {
    const newState: HomeUIState = {
      ...state,
      drawer: {
        isOpened: false,
        isHalfDrawer: false,
      },
    };
    return newState;
  }),
);
