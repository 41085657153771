import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import * as fromTypes from '../types';
import { Observable } from 'rxjs';
import { DateRangePickerService } from '../date-range-picker.service';
import { dateToYYYYMMDD } from '@rootTypes/utils/common/date-time-fns';

@Component({
  selector: 'wp-dp-month',
  templateUrl: './dp-month.component.html',
  styleUrls: ['./dp-month.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DpMonthComponent implements OnInit {
  @Input() month!: fromTypes.RangePickerMonth;
  @Input() readonly!: boolean;

  public weekdays$!: Observable<fromTypes.RangePickerWeekday[]>;
  public days$!: Observable<fromTypes.RangePickerDay[]>;

  constructor(private store: DateRangePickerService) {}

  ngOnInit(): void {
    this.weekdays$ = this.store.weekdays$();
    this.days$ = this.store.daysForMonth$(dateToYYYYMMDD(this.month.moment));
  }
}
