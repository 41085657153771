import { FilterDeserializer } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';
import { TripAssignmentListStatusFilter } from '../../entities';
import { CharterVendorAssignmentStatus } from '@apiEntities/trips';
import { createTripAssignmentStatusFilter } from './create-trip-assignment-status-filter';

export const deserializeTripAssignmentStatusFilter: FilterDeserializer<TripAssignmentListStatusFilter> = (
  str: string,
): TripAssignmentListStatusFilter | undefined => {
  if (str && str.startsWith('assignment-status')) {
    const value = str.split(',')[1] as CharterVendorAssignmentStatus;
    return createTripAssignmentStatusFilter(value);
  }
  return undefined;
};
