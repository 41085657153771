import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconSearchComponent } from './icon-search/icon-search.component';

@NgModule({
  declarations: [IconSearchComponent],
  imports: [CommonModule],
  exports: [IconSearchComponent],
})
export class SearchIconModule {}
