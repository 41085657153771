import { createAction, props } from '@ngrx/store';
import { RouteGroupScheduleChangeRequest } from '@apiEntities/route-group/route-group-schedule-change-request';
import { WpError } from '@rootTypes';

export const getRouteGroupScheduleChangeRequestFromChangeRequestListItem = createAction(
  '[ChangeRequestListItem]: API Get route group schedule change request',
  props<{ changeRequestId: string }>(),
);

export const getRouteGroupScheduleChangeRequestFromChangeRequestDetails = createAction(
  '[ChangeRequestDetails]: API Get route group schedule change request',
  props<{ changeRequestId: string }>(),
);

export const getRouteGroupScheduleChangeRequestFromOnChangeRequestApprove = createAction(
  '[ChangeRequestDetailsEffects]: API Get route group schedule change request on approve',
  props<{ changeRequestId: string }>(),
);

export const getScheduleChangeRequestActions = [
  getRouteGroupScheduleChangeRequestFromChangeRequestListItem,
  getRouteGroupScheduleChangeRequestFromChangeRequestDetails,
  getRouteGroupScheduleChangeRequestFromOnChangeRequestApprove,
];

export const getRouteGroupScheduleChangeRequestSuccess = createAction(
  '[RouteGroupScheduleChangeEffects]: Get schedule change request success',
  props<{ changeRequest: RouteGroupScheduleChangeRequest }>(),
);

export const getRouteGroupScheduleChangeRequestFailed = createAction(
  '[RouteGroupScheduleChangeEffects]: Get schedule change request failed',
  props<{ changeRequestId: string; error: WpError }>(),
);
