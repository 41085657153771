import { Injectable } from '@angular/core';

import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { workQueuePath } from './work-queue-path';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';
import { DriverBusReportsListParams } from '../../../features/common/driver-bus-reports-common/store/types';
import { deserializeBaseParamsFromUrl } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';
import { serializeBaseParamsToUrl } from '@rootTypes/utils/list-store-utils/serialize-base-params-to-url';
import { driverBusReportsInitialStateGetter } from '../../../features/common/driver-bus-reports-common/store/driver-bus-report-list-store.service';
import { getRoutingRequest } from '@rootTypes/utils/router/get-routing-request';

export type WorkQueueDriverBusReportsRouteData = Partial<DriverBusReportsListParams>;

const statePath = 'driver-bus-reports';

export const workQueueDriverBusReportsRoute: PortalRoute<WorkQueueDriverBusReportsRouteData> = {
  path: statePath,

  isOnRoute: (state: RouterStateUrl) => {
    return state && state.url && state.url.startsWith(`/${workQueuePath}/${statePath}`);
  },

  parse: (state: RouterStateUrl): WorkQueueDriverBusReportsRouteData => {
    const query = state?.queryParams || {};
    const data: WorkQueueDriverBusReportsRouteData = deserializeBaseParamsFromUrl(query, 'dbr');
    return data;
  },

  request: (data?: WorkQueueDriverBusReportsRouteData): NavigationRequest => {
    const path = ['/', workQueuePath, statePath];
    const query = serializeBaseParamsToUrl(data, driverBusReportsInitialStateGetter().params, 'dbr');
    return getRoutingRequest(path, query);
  },

  getHref(data?: WorkQueueDriverBusReportsRouteData): string {
    return `/${workQueuePath}/${statePath}`;
  },
};

@Injectable({
  providedIn: 'root',
})
export class WorkQueueDriverBusReportsRouterService extends PortalRouterService<WorkQueueDriverBusReportsRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route = workQueueDriverBusReportsRoute;
}
