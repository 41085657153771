import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wp-portal-drawer-editor-page',
  templateUrl: './portal-drawer-editor-page.component.html',
  styleUrls: ['./portal-drawer-editor-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalDrawerEditorPageComponent implements OnInit {
  @Input() displayBackText: string;
  @Input() displayBackTitle: string;
  @Input() cancelButtonText: string;
  @Input() confirmButtonText: string;
  @Input() isConfirmButtonLoading: boolean;
  @Input() isConfirmButtonDisabled: boolean;
  @Input() isCloseButton: boolean;
  @Input() hideEditButtons: boolean;
  @Input() navigateBackOnCancel: boolean;
  @Input() callHistoryBackOnDisplayBackTextClick: boolean = true;

  @Output() public displayBackClicked = new EventEmitter<void>();
  @Output() public cancelClicked = new EventEmitter<void>();
  @Output() public confirmClicked = new EventEmitter<void>();
  @Output() public closeClicked = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onDisplayBackClicked() {
    this.displayBackClicked.emit();
  }

  onCancelClicked() {
    if (this.navigateBackOnCancel) {
      window.history.back();
    } else {
      this.cancelClicked.emit();
    }
  }

  onConfirmClicked() {
    this.confirmClicked.emit();
  }

  onCloseClicked() {
    this.closeClicked.emit();
  }
}
