<div
  class="square-border"
  [ngClass]="{ open: isOpen$ | async }"
  (click)="$event.stopPropagation(); openDropdown()"
>
  <div class="wp-dropdown">
    <div class="wp-dropdown__trigger">
      <wp-dropdown-trigger
        (click)="$event.stopPropagation(); openDropdown()"
        [triggerSpread]="true"
      >
        <div *ngIf="displayValue$ | async; let value;" class="input-value">
          {{ value }}
        </div>
        <div *ngIf="!(displayValue$ | async)" class="label">{{ label }}</div>
      </wp-dropdown-trigger>
    </div>

    <div
      class="wp-dropdown__pane"
      [ngClass]="{ 'align-pane-right': alignPaneRight }"
      [ngStyle]="panePositionStyles"
      *ngIf="isOpen$ | async"
      wpClickOutside
      (clickedOutside)="closeDropdown()"
    >
      <wp-dropdown-pane (click)="$event.stopPropagation()">
        <div class="wp-range-picker-wrap">
          <wp-date-range-picker
            [control]="dateRangeControl"
            [isStartWeekFromMonday]="isStartWeekFromMonday"
          ></wp-date-range-picker>
        </div>

        <div class="action-buttons">
          <wp-btn-secondary
            *ngIf="isClearButton"
            [width]="'120px'"
            (clicked)="clearDateRange()"
          >
            Clear dates
          </wp-btn-secondary>
          <wp-btn-primary
            [width]="'120px'"
            [disabled]="isInvalidDateRange$ | async"
            class="primary"
            (clicked)="$event.stopPropagation(); onApply()"
          >
            Apply
          </wp-btn-primary>
        </div>
      </wp-dropdown-pane>
    </div>
  </div>
</div>
