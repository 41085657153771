import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import * as fromRootTypes from '@rootTypes';
import { YYYYMMDDString } from '@rootTypes';
import { DateRangePickerService } from '../date-range-picker/date-range-picker.service';
import { YearMonthDay } from 'src/app/types/utils/common/date';
import { validateDateRange } from './date-range-validator';

interface DateRangeFilterState {
  isOpen: boolean;
  startDate: string | null;
  endDate: string | null;
}

enum DateRangeStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
}

const initialState: DateRangeFilterState = {
  isOpen: false,
  startDate: null,
  endDate: null,
};

interface FormControlValue {
  startDate: string | null;
  endDate: string | null;
}

@Component({
  selector: 'wp-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DateRangePickerService],
})
export class DateRangeFilterComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public alignPaneRight = false;
  @Input() public panePositionPx?: { top: number; left: number };
  @Input() public label!: string;
  @Input() public isClearButton = true;
  @Input() public isStartWeekFromMonday: boolean;

  // controls the overall filter value
  // see FormControlValue above
  @Input() public control!: UntypedFormControl;

  public isOpen$!: Observable<boolean>;
  public displayValue$!: Observable<string | null>;
  // controls date range picker selection
  public dateRangeControl: UntypedFormControl;
  public isInvalidDateRange$: Observable<boolean>;
  public panePositionStyles?: { [rule: string]: string };

  private state$: BehaviorSubject<DateRangeFilterState> = new BehaviorSubject<DateRangeFilterState>(initialState);
  private subscriptions: Subscription = new Subscription();

  @Output() public selectedChanged = new EventEmitter<fromRootTypes.DateRange>();

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.dateRangeControl = new UntypedFormControl(this.control ? this.control.value : null, validateDateRange);
    this.isOpen$ = this.state$.asObservable().pipe(map((state) => state.isOpen));

    // show value selected on the date range picker immediately
    this.displayValue$ = this.dateRangeControl.valueChanges.pipe(
      startWith(this.control.value || {}),
      map((state) => {
        if (state.startDate && state.endDate) {
          const displayStartDate = new YearMonthDay(state.startDate).formatTo('monthAndDay');
          const displayEndDate = new YearMonthDay(state.endDate).formatTo('monthAndDay');
          return `${displayStartDate} - ${displayEndDate}`;
        }
        if (state.startDate) {
          const displayStartDate = new YearMonthDay(state.startDate).formatTo('monthAndDay');
          return `${displayStartDate} - ${displayStartDate}`;
        }
        if (state.endDate) {
          return `${fromRootTypes.utils.date.yyyymmddDisplay(state.endDate)}`;
        }
        return null;
      }),
    );
    if (this.control) {
      this.subscriptions.add(
        this.control.valueChanges
          .pipe(
            startWith(this.control.value),
            filter((val) => !!val),
            distinctUntilChanged((prev, curr) => {
              return prev.startDate === curr.startDate && prev.endDate === curr.endDate;
            }),
          )
          .subscribe((val) => {
            this.state$.next({
              ...this.state$.value,
              startDate: val.startDate,
              endDate: val.endDate,
            });
            this.dateRangeControl.setValue(val);
            this.cd.detectChanges();
          }),
      );
    }

    this.isInvalidDateRange$ = this.dateRangeControl.statusChanges.pipe(
      distinctUntilChanged(),
      startWith(DateRangeStatus.INVALID),
      map((val) => val === DateRangeStatus.INVALID),
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.panePositionPx) {
      if (this.panePositionPx) {
        const { top, left } = this.panePositionPx;
        this.panePositionStyles = {
          top: `${top}px`,
          left: `${left}px`,
        };
      } else {
        this.panePositionStyles = undefined;
      }
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public openDropdown(): void {
    document.body.click();
    this.state$.next({
      ...this.state$.value,
      isOpen: true,
    });
  }

  public closeDropdown(): void {
    this.state$.next({
      ...this.state$.value,
      isOpen: false,
    });
  }

  public clearDateRange(emitEvent = true): void {
    this.control.setValue({});
    this.dateRangeControl.setValue({});
    if (emitEvent) {
      this.selectedChanged.emit({ startDate: null, endDate: null });
    }
  }

  public setValue(value: { startDate: YYYYMMDDString; endDate: YYYYMMDDString }, emitEvent = true): void {
    this.control.setValue({ ...value });
    this.dateRangeControl.setValue({ ...value });
    if (emitEvent) {
      this.selectedChanged.emit({ ...value });
    }
  }

  public onApply(): void {
    const { startDate } = this.dateRangeControl.value;
    let { endDate } = this.dateRangeControl.value;
    // make end date same as start date if not defined
    endDate = endDate === null ? startDate : endDate;
    this.control.setValue({ startDate, endDate });
    this.selectedChanged.emit({ startDate, endDate });
    this.closeDropdown();
  }
}
