export enum FormattingTokens {
  YEAR = 'yyyy',
  YEAR_SHORT = 'yy',
  MONTH = 'M',
  MONTH_PADDED = 'MM',
  MONTH_TEXT_SHORT = 'MMM',
  MONTH_TEXT_LONG = 'MMMM',
  DAY = 'd',
  DAY_PADDED = 'dd',
  WEEKDAY_TEXT_ONE_CHAR = 'EEEEE',
  WEEKDAY_TEXT_TWO_CHAR = 'EEEEEE',
  WEEKDAY_TEXT_THREE_CHAR = 'EEE',
  WEEKDAY_TEXT_FULL = 'EEEE',
  HOUR_1_12 = 'h',
  HOUR_1_12_PADDED = 'hh',
  HOUR_0_23 = 'H',
  HOUR_0_23_PADDED = 'HH',
  MINUTE = 'm',
  MINUTE_PADDED = 'mm',
  SECOND = 's',
  AM_PM = 'a',
  TIMEZONE_SHORT = 'zzz',
}

export const formattingConstants = {
  // 19700121
  yyyyMMDD: FormattingTokens.YEAR + FormattingTokens.MONTH_PADDED + FormattingTokens.DAY_PADDED,
  // 21012021
  mmDDYYYY: FormattingTokens.MONTH_PADDED + FormattingTokens.DAY_PADDED + FormattingTokens.YEAR,
  // 2359
  hhMM: FormattingTokens.HOUR_0_23_PADDED + FormattingTokens.MINUTE_PADDED,
  // Jan 01, 1970
  dateMedium: FormattingTokens.MONTH_TEXT_SHORT + ' ' + FormattingTokens.DAY_PADDED + ', ' + FormattingTokens.YEAR,
  // January 01, 1970
  dateFullMonth: FormattingTokens.MONTH_TEXT_LONG + ' ' + FormattingTokens.DAY_PADDED + ', ' + FormattingTokens.YEAR,
  // Thu Jan 01, 1970
  dateAndWeekdayMedium:
    FormattingTokens.WEEKDAY_TEXT_THREE_CHAR +
    ' ' +
    FormattingTokens.MONTH_TEXT_SHORT +
    ' ' +
    FormattingTokens.DAY_PADDED +
    ', ' +
    FormattingTokens.YEAR,
  // Thu January 01, 1970
  dateAndWeekdayFullMonth:
    FormattingTokens.WEEKDAY_TEXT_THREE_CHAR +
    ' ' +
    FormattingTokens.MONTH_TEXT_LONG +
    ' ' +
    FormattingTokens.DAY_PADDED +
    ', ' +
    FormattingTokens.YEAR,
  // Thu Jan 01, 1970 - 12:00 AM PST
  dateAndTime:
    FormattingTokens.WEEKDAY_TEXT_THREE_CHAR +
    ' ' +
    FormattingTokens.MONTH_TEXT_SHORT +
    ' ' +
    FormattingTokens.DAY_PADDED +
    ', ' +
    FormattingTokens.YEAR +
    ' - ' +
    FormattingTokens.HOUR_1_12 +
    ':' +
    FormattingTokens.MINUTE_PADDED +
    ' ' +
    FormattingTokens.AM_PM +
    ' ' +
    FormattingTokens.TIMEZONE_SHORT,
  // Thu Jan 01, 1970 - 12:00 AM
  dateAndTimeNoTimezone:
    FormattingTokens.WEEKDAY_TEXT_THREE_CHAR +
    ' ' +
    FormattingTokens.MONTH_TEXT_SHORT +
    ' ' +
    FormattingTokens.DAY_PADDED +
    ', ' +
    FormattingTokens.YEAR +
    ' - ' +
    FormattingTokens.HOUR_1_12 +
    ':' +
    FormattingTokens.MINUTE_PADDED +
    ' ' +
    FormattingTokens.AM_PM,
  // Jan 01, 1970 12:00 AM
  dateAndTimeShort:
    FormattingTokens.MONTH_TEXT_SHORT +
    ' ' +
    FormattingTokens.DAY_PADDED +
    ', ' +
    FormattingTokens.YEAR +
    ' - ' +
    FormattingTokens.HOUR_1_12 +
    ':' +
    FormattingTokens.MINUTE_PADDED +
    ' ' +
    FormattingTokens.AM_PM,
  dayNoPadding: FormattingTokens.DAY,
  timeWithTimezone:
    FormattingTokens.HOUR_1_12 +
    ':' +
    FormattingTokens.MINUTE_PADDED +
    ' ' +
    FormattingTokens.AM_PM +
    ' ' +
    FormattingTokens.TIMEZONE_SHORT,
  // 1:00 AM
  timeNoTimezone: FormattingTokens.HOUR_1_12 + ':' + FormattingTokens.MINUTE_PADDED + ' ' + FormattingTokens.AM_PM,
  // 01:00 AM
  timeNoTimezonePadded:
    FormattingTokens.HOUR_1_12_PADDED + ':' + FormattingTokens.MINUTE_PADDED + ' ' + FormattingTokens.AM_PM,
  timeSlim: FormattingTokens.HOUR_1_12 + ':' + FormattingTokens.MINUTE_PADDED,
  // 14:00 or 01:00
  time24Padded: FormattingTokens.HOUR_0_23_PADDED + ':' + FormattingTokens.MINUTE_PADDED,
  // Jun 01
  monthAndDay: FormattingTokens.MONTH_TEXT_SHORT + ' ' + FormattingTokens.DAY_PADDED,
  // June 1
  monthAndDayFullNoPadding: FormattingTokens.MONTH_TEXT_LONG + ' ' + FormattingTokens.DAY,
  // Jun 1
  monthAndDayNoPadding: FormattingTokens.MONTH_TEXT_SHORT + ' ' + FormattingTokens.DAY,
  // Jun
  monthShort: FormattingTokens.MONTH_TEXT_SHORT,
  // March 2024
  monthAndYear: FormattingTokens.MONTH_TEXT_LONG + ' ' + FormattingTokens.YEAR,
  // 12:00
  hoursAndMinutes: FormattingTokens.HOUR_1_12_PADDED + ':' + FormattingTokens.MINUTE_PADDED,
  // 9:00
  hoursAndMinutesNoPadding: FormattingTokens.HOUR_1_12 + ':' + FormattingTokens.MINUTE_PADDED,
  // 01-24-1970
  mm_dd_yyyy: FormattingTokens.MONTH_PADDED + '-' + FormattingTokens.DAY_PADDED + '-' + FormattingTokens.YEAR,
  // 01/24/1970
  mm_slash_dd_slash_yyyy:
    FormattingTokens.MONTH_PADDED + '/' + FormattingTokens.DAY_PADDED + '/' + FormattingTokens.YEAR,
  // 01/24
  mm_slash_dd: FormattingTokens.MONTH_PADDED + '/' + FormattingTokens.DAY_PADDED,
  // 1/24
  m_slash_dd: FormattingTokens.MONTH + '/' + FormattingTokens.DAY_PADDED,
  // 1/3
  m_slash_d: FormattingTokens.MONTH + '/' + FormattingTokens.DAY,
  // Wed
  weekdayShort: FormattingTokens.WEEKDAY_TEXT_THREE_CHAR,
  // YYYY-MM-DD HH:mm:ss
  iso:
    FormattingTokens.YEAR +
    '-' +
    FormattingTokens.MONTH_PADDED +
    '-' +
    FormattingTokens.DAY_PADDED +
    ' ' +
    FormattingTokens.HOUR_0_23_PADDED +
    ':' +
    FormattingTokens.MINUTE_PADDED +
    ':' +
    FormattingTokens.SECOND,
};
