import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SmartInputSchoolService } from '../../services';
import { SmartSelect } from '../../models';

@Component({
  selector: 'wp-smart-input-school-district',
  templateUrl: './smart-input-school-district.component.html',
  styleUrls: ['./smart-input-school-district.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartInputSchoolDistrictComponent implements OnInit {
  public model: SmartSelect;

  constructor(private service: SmartInputSchoolService) {}

  public ngOnInit(): void {
    this.model = this.service.district;
  }
}
