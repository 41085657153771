import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wp-btn-ghost',
  templateUrl: './btn-ghost.component.html',
  styleUrls: ['./btn-ghost.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnGhostComponent implements OnInit {
  @Input() public disabled = false;
  @Input() public width = '112px';
  @Input() public height = '44px';

  @Output() public clicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  public btnSize(): any {
    return { width: this.width, height: this.height };
  }

  public onClick(): void {
    if (this.disabled) {
      return;
    }
    this.clicked.emit();
  }
}
