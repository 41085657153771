import { createSelector } from '@ngrx/store';
import { getDriversFeature } from './get-feature';
import * as fromTypes from '../../types';
import { DriverListFilter, DriverListFilterType, DriverListMultiAccountFilter } from '../../types';
import { selectMultiAccountManagementDefaultEntity } from '../../../../auth/store/selectors/multi-account-management.selectors';

export const allDriverListState = createSelector(getDriversFeature, (state) => state.lists);

export const driverListState = createSelector(
  allDriverListState,
  (state, props: { listName: fromTypes.DriverListName }) => {
    return state[props.listName];
  },
);

export const driverListParams = createSelector(driverListState, (state) => state.params);

// List params
export const driverListDriverStatus = createSelector(driverListParams, (state) => state.driverStatus);
export const driverListPageIndex = createSelector(driverListParams, (state) => state.pageIndex);
export const driverListPageSize = createSelector(driverListParams, (state) => state.pageSize);
export const driverListSearchTerm = createSelector(driverListParams, (state) => state.search);
export const driverListFilters = createSelector(driverListParams, (state): DriverListFilter[] => state.filters);
export const driverListMultiAccountFilter = createSelector(
  driverListFilters,
  (state): DriverListMultiAccountFilter | null =>
    (state || []).find((f) => f.type === DriverListFilterType.MULTI_ACCOUNT_FILTER) as DriverListMultiAccountFilter,
);
export const driverListMultiAccountSelectedEntityId = createSelector(
  driverListMultiAccountFilter,
  selectMultiAccountManagementDefaultEntity,
  (driverListMultiAccountFilter, defaultEntity) => {
    return driverListMultiAccountFilter?.payload?.entityId || defaultEntity?.entityId;
  },
);

// List items
const pageItemsState = createSelector(driverListState, (state) => state.pageItems);
export const driverListPageItemsLoading = createSelector(pageItemsState, (state) => state.isLoading);
export const driverListPageItems = createSelector(pageItemsState, (state) => state.data);
export const driverListTotalItemsFound = createSelector(pageItemsState, (state) => state.totalFound);
export const isDriverListEmpty = createSelector(
  driverListPageItemsLoading,
  driverListPageItems,
  (loading, items) => !loading && items.length === 0,
);

// Selected driver id
export const driverListSelectedDriverId = createSelector(driverListParams, (state) => state.selectedDriverId);

export const isDriverListItemSelected = createSelector(
  driverListSelectedDriverId,
  (selectedDriverId: string, props: { listName: fromTypes.DriverListName; driverId: string }) => {
    return selectedDriverId === props.driverId;
  },
);
