import { EntityState } from '@rootTypes';
import { createReducer, on } from '@ngrx/store';
import {
  finalizeInvoiceSuccess,
  getCharterCatalogFailed,
  getCharterCatalogRequested,
  getCharterCatalogSuccess,
  getCharterTripFailed,
  getCharterTripItineraryFailed,
  getCharterTripItineraryRequested,
  getCharterTripItinerarySuccess,
  getCharterTripRequested,
  getCharterTripSuccess,
  getContractFailed,
  getContractRequested,
  getContractSuccess,
  getTripInvoiceFailed,
  getTripInvoiceRequested,
  getTripInvoiceSuccess,
  getTripStatusHistoryFailed,
  getTripStatusHistoryRequested,
  getTripStatusHistorySuccess,
  updateInvoiceFinalDraftSuccess,
} from '../actions';
import { GetCharterTripResponse } from '../../../api/endpoints/get-charter-trip';
import { GetCharterTripItineraryResponse } from 'src/app/api/endpoints/get-charter-trip-itinerary';
import { GetCharterCatalogResponse } from 'src/app/api/endpoints/get-charter-catalog';
import { GetCharterTripInvoiceResponse } from 'src/app/api/endpoints/get-charter-trip-invoice';
import { GetCharterContractResponse } from '../../../api/endpoints/get-charter-contract';
import { GetCharterTripChangeHistoryResponse } from '../../../api/endpoints/get-charter-trip-change-history';
import { cleanUpStore } from '@rootStore';

export type TripDataInfoState = {
  trips: {
    [tripId: string]: EntityState<GetCharterTripResponse>;
  };
  itineraries: {
    [charterTripItineraryId: string]: EntityState<GetCharterTripItineraryResponse>;
  };
  catalogs: {
    [catalogId: string]: EntityState<GetCharterCatalogResponse>;
  };
  invoicesByTrips: {
    [tripId: string]: EntityState<GetCharterTripInvoiceResponse>;
  };
  contracts: {
    [contractId: string]: EntityState<GetCharterContractResponse>;
  };
  tripStatusHistory: {
    [tripId: string]: EntityState<GetCharterTripChangeHistoryResponse>;
  };
};

const getInitialTripDataState = (): TripDataInfoState => {
  return {
    trips: {},
    itineraries: {},
    catalogs: {},
    invoicesByTrips: {},
    contracts: {},
    tripStatusHistory: {},
  };
};

export const tripDataReducer = createReducer<TripDataInfoState>(
  getInitialTripDataState(),
  on(cleanUpStore, getInitialTripDataState),
  /**
   * Get charter trip
   */
  on(getCharterTripRequested, (state, action): TripDataInfoState => {
    return {
      ...state,
      trips: {
        ...state.trips,
        [action.tripId]: {
          ...(state.trips[action.tripId] || {}),
          isLoading: !action?.options?.ignoreLoading,
        },
      },
    };
  }),
  on(getCharterTripSuccess, (state, action): TripDataInfoState => {
    const { trip } = action;
    return {
      ...state,
      trips: {
        ...state.trips,
        [trip.tripId]: {
          ...(state.trips[trip.tripId] || {}),
          isLoading: false,
          entity: trip,
        },
      },
    };
  }),
  on(getCharterTripFailed, (state, action): TripDataInfoState => {
    const { tripId, error } = action;
    return {
      ...state,
      trips: {
        ...state.trips,
        [tripId]: {
          isLoading: false,
          error,
        },
      },
    };
  }),
  /**
   * Get charter trip itinerary
   */
  on(getCharterTripItineraryRequested, (state, action): TripDataInfoState => {
    const { charterTripItineraryId } = action;
    return {
      ...state,
      itineraries: {
        ...state.itineraries,
        [charterTripItineraryId]: {
          ...(state.itineraries[charterTripItineraryId] || {}),
          isLoading: true,
        },
      },
    };
  }),
  on(getCharterTripItinerarySuccess, (state, action): TripDataInfoState => {
    const { charterTripItinerary } = action;
    const { tripItineraryId: charterTripItineraryId } = charterTripItinerary;
    return {
      ...state,
      itineraries: {
        ...state.itineraries,
        [charterTripItinerary.tripItineraryId]: {
          ...(state.itineraries[charterTripItineraryId] || {}),
          isLoading: false,
          entity: charterTripItinerary,
        },
      },
    };
  }),
  on(getCharterTripItineraryFailed, (state, action): TripDataInfoState => {
    const { charterTripItineraryId, error } = action;
    return {
      ...state,
      itineraries: {
        ...state.itineraries,
        [charterTripItineraryId]: {
          ...(state.itineraries[charterTripItineraryId] || {}),
          isLoading: false,
          error,
        },
      },
    };
  }),
  /**
   * Get charter catalog
   */
  on(getCharterCatalogRequested, (state, action): TripDataInfoState => {
    const { catalogId } = action;
    return {
      ...state,
      catalogs: {
        ...state.catalogs,
        [catalogId]: {
          ...(state.catalogs[catalogId] || {}),
          isLoading: true,
        },
      },
    };
  }),
  on(getCharterCatalogSuccess, (state, action): TripDataInfoState => {
    const { catalog, catalogId } = action;
    return {
      ...state,
      catalogs: {
        ...state.catalogs,
        [catalogId]: {
          ...(state.catalogs[catalogId] || {}),
          isLoading: false,
          entity: catalog,
        },
      },
    };
  }),
  on(getCharterCatalogFailed, (state, action): TripDataInfoState => {
    const { catalogId, error } = action;
    return {
      ...state,
      catalogs: {
        ...state.catalogs,
        [catalogId]: {
          ...(state.catalogs[catalogId] || {}),
          isLoading: false,
          error,
        },
      },
    };
  }),
  /**
   * Get invoice
   */
  on(getTripInvoiceRequested, (state, action): TripDataInfoState => {
    const { request } = action;
    return {
      ...state,
      invoicesByTrips: {
        ...state.invoicesByTrips,
        [request.tripId]: {
          isLoading: true,
        },
      },
    };
  }),
  on(getTripInvoiceSuccess, (state, action): TripDataInfoState => {
    const { invoice, tripId } = action;
    return {
      ...state,
      invoicesByTrips: {
        ...state.invoicesByTrips,
        [tripId]: {
          ...(state.invoicesByTrips[tripId] || {}),
          isLoading: false,
          entity: invoice,
        },
      },
    };
  }),
  on(getTripInvoiceFailed, (state, action): TripDataInfoState => {
    const { tripId, error } = action;
    return {
      ...state,
      invoicesByTrips: {
        ...state.invoicesByTrips,
        [tripId]: {
          ...(state.invoicesByTrips[tripId] || {}),
          isLoading: false,
          error,
        },
      },
    };
  }),
  on(updateInvoiceFinalDraftSuccess, finalizeInvoiceSuccess, (state, action): TripDataInfoState => {
    const { tripId, invoice } = action;
    return {
      ...state,
      invoicesByTrips: {
        ...state.invoicesByTrips,
        [tripId]: {
          isLoading: false,
          entity: invoice,
        },
      },
    };
  }),
  /**
   * Get contract
   */
  on(getContractRequested, (state, action): TripDataInfoState => {
    const { request } = action;
    return {
      ...state,
      contracts: {
        ...state.contracts,
        [request.contractId]: {
          isLoading: true,
        },
      },
    };
  }),
  on(getContractSuccess, (state, action): TripDataInfoState => {
    const { contract, contractId } = action;
    return {
      ...state,
      contracts: {
        ...state.contracts,
        [contractId]: {
          ...(state.contracts[contractId] || {}),
          isLoading: false,
          entity: contract,
        },
      },
    };
  }),
  on(getContractFailed, (state, action): TripDataInfoState => {
    const { contractId, error } = action;
    return {
      ...state,
      contracts: {
        ...state.contracts,
        [contractId]: {
          ...(state.contracts[contractId] || {}),
          isLoading: false,
          error,
        },
      },
    };
  }),
  /**
   * Get charter trip status history
   */
  on(getTripStatusHistoryRequested, (state, action): TripDataInfoState => {
    const { tripId, noLoadingIndicator } = action;
    return {
      ...state,
      tripStatusHistory: {
        ...state.tripStatusHistory,
        [tripId]: {
          ...(state.tripStatusHistory[tripId] || {}),
          isLoading: !noLoadingIndicator,
        },
      },
    };
  }),
  on(getTripStatusHistorySuccess, (state, action): TripDataInfoState => {
    const { tripId, history } = action;
    return {
      ...state,
      tripStatusHistory: {
        ...state.tripStatusHistory,
        [tripId]: {
          ...(state.tripStatusHistory[tripId] || {}),
          isLoading: false,
          entity: history,
        },
      },
    };
  }),
  on(getTripStatusHistoryFailed, (state, action): TripDataInfoState => {
    const { tripId, error } = action;
    return {
      ...state,
      tripStatusHistory: {
        ...state.tripStatusHistory,
        [tripId]: {
          ...(state.tripStatusHistory[tripId] || {}),
          isLoading: false,
          error,
        },
      },
    };
  }),
);
