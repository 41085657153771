import { Injectable } from '@angular/core';
import { SmartInputSchool, SmartSelect } from '../models';

@Injectable()
export class SmartInputSchoolService {
  public district: SmartSelect;
  public campus: SmartSelect;

  public initialize(model: SmartInputSchool): void {
    this.district = model.district;
    this.campus = model.campus;
  }
}
