import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { PlaceholderType } from '../types';

@Component({
  selector: 'wp-display-photo-square-multi',
  templateUrl: './display-photo-square-multi.component.html',
  styleUrls: ['./display-photo-square-multi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayPhotoSquareMultiComponent implements OnInit {
  @Input() public photos: string[];

  public placeholder = PlaceholderType;

  constructor() {}

  ngOnInit(): void {}
}
