import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';

import { go, State } from '@rootStore';
import * as fromActions from '../../store/actions';
import * as fromSelectors from '../../store/selectors';
import { getRelayStateFromRoute } from '../../utils';
import { portalRoutes } from '@router';
import { AuthenticationProvider } from '../../../api/endpoints/get-authentication-provider';

// Note, how we configure login page to work with LastPass
// https://support.logmeininc.com/lastpass/help/how-do-i-configure-my-website-to-work-with-lastpass
// Pay attention that we intentionally added hidden email input to HTML to store email/password credentials in LastPass for new users.
@Component({
  selector: 'wp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public email: UntypedFormControl;
  public password: UntypedFormControl;

  public authEmail$: Observable<string>;
  public authProviderLoading$: Observable<boolean>;
  public isAuthProviderPassword$: Observable<boolean>;

  public loginWithPasswordLoading$: Observable<boolean>;
  public loginWithPasswordError$: Observable<boolean>;

  public loginWithGoogleUserNotFoundError = false;

  public loginWithTokenLoading$: Observable<boolean>;
  public loginWithTokenError$: Observable<boolean>;

  public loginError$: Observable<boolean>;

  private loginWithPasswordLoading$$: BehaviorSubject<boolean>;
  private loginWithTokenLoading$$: BehaviorSubject<boolean>;

  private sub = new Subscription();

  constructor(
    private store: Store<State>,
    private activatedRoute: ActivatedRoute,
  ) {
    this.loginWithPasswordLoading$$ = new BehaviorSubject(false);
    this.loginWithPasswordLoading$ = this.loginWithPasswordLoading$$.asObservable();

    this.loginWithTokenLoading$$ = new BehaviorSubject(false);
    this.loginWithTokenLoading$ = this.loginWithTokenLoading$$.asObservable();
  }

  @HostListener('window:keydown', ['$event'])
  public onEnterKey(event: KeyboardEvent): void {
    if (event.code === 'Enter') {
      event.preventDefault(); // prevent page reloading
      this.store
        .select(fromSelectors.getAuthProvider)
        .pipe(withLatestFrom(this.authProviderLoading$, this.loginWithPasswordLoading$), take(1))
        .subscribe(([authProvider, authProviderLoading, loginWithPasswordLoading]) => {
          if (!authProvider && !authProviderLoading && this.email.valid) {
            this.requestAuthProvider();
            return;
          }
          if (authProvider === AuthenticationProvider.PASSWORD && !loginWithPasswordLoading) {
            this.loginWithPassword();
          }
        });
    }
  }

  public ngOnInit(): void {
    this.email = new UntypedFormControl('', [Validators.required, Validators.email]);
    this.password = new UntypedFormControl('', [Validators.required]);
    this.authEmail$ = this.store.select(fromSelectors.getAuthEmail);
    this.authProviderLoading$ = this.store.select(fromSelectors.isAuthProviderLoading);
    this.isAuthProviderPassword$ = this.store.select(fromSelectors.isAuthProviderPassword);

    this.loginWithPasswordError$ = this.store.select(fromSelectors.getLoginError).pipe(
      map((data) => {
        return !!data;
      }),
    );
    const loginWithPasswordErrorSub = this.loginWithPasswordError$.subscribe(() =>
      this.loginWithPasswordLoading$$.next(false),
    );
    this.sub.add(loginWithPasswordErrorSub);

    this.loginWithTokenError$ = this.store.select(fromSelectors.loginWithTokenError).pipe(map((error) => !!error));
    const loginWithTokenErrorSub = this.loginWithTokenError$.subscribe(() => {
      this.loginWithTokenLoading$$.next(false);
    });
    this.sub.add(loginWithTokenErrorSub);

    this.loginError$ = combineLatest([this.loginWithPasswordError$, this.loginWithTokenError$]).pipe(
      map((errors) => errors.some((error) => !!error)),
    );

    const token = this.activatedRoute.snapshot.queryParams.token;
    if (token) {
      // To prevent login page blink issue
      // don't stop the indicator while navigation is finished
      // and the component is destroyed
      this.loginWithTokenLoading$$.next(true);
      this.store.dispatch(
        fromActions.loginWithTokenRequested({
          token,
          relayState: this.getRelayState(),
        }),
      );
    }

    const loginWithGoogleError = this.activatedRoute.snapshot.queryParams.error;
    if (loginWithGoogleError === 'user-not-found') {
      this.loginWithGoogleUserNotFoundError = true;
    }
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public editAuthEmail(): void {
    this.email.reset(this.email.value);
    this.password.reset('');
    this.store.dispatch(fromActions.editEmailForLogin());
  }

  public requestAuthProvider(): void {
    this.store.dispatch(
      fromActions.getAuthProviderRequested({
        email: this.getEmailControlValue(),
        relayState: this.getRelayState(),
      }),
    );
  }

  public loginWithPassword(): void {
    if (!this.password.value) {
      return;
    }
    this.loginWithPasswordLoading$$.next(true);
    this.store.dispatch(
      fromActions.loginWithPasswordRequested({
        password: this.password.value,
      }),
    );
  }

  public forgotPassword(): void {
    this.store.dispatch(go(portalRoutes.auth.forgotPasswordRoute.request()));
  }

  // Private methods

  private getRelayState(): string | undefined {
    return getRelayStateFromRoute(this.activatedRoute.snapshot);
  }

  private getEmailControlValue(): string {
    return this.email.value.trim();
  }
}
