import { on } from '@ngrx/store';

import { AuthState } from './auth-state';
import {
  switchAccountUserProfileFailed,
  switchAccountUserProfileRequested,
  switchAccountUserProfileSuccess,
  updateUserPhotoFailed,
  updateUserPhotoRequested,
  updateUserPhotoSuccess,
  userPhotoFailed,
  userPhotoRequested,
  userPhotoSuccess,
  userProfileFailed,
  userProfileRequested,
  userProfileSuccess,
} from '../actions';

export const userProfileReducers = [
  on(userProfileRequested, switchAccountUserProfileRequested, (state: AuthState, action): AuthState => {
    return {
      ...state,
      profile: {
        ...state.profile,
        user: {
          isLoading: true,
          error: null,
          data: null,
        },
      },
    };
  }),
  on(userProfileSuccess, switchAccountUserProfileSuccess, (state: AuthState, action): AuthState => {
    return {
      ...state,
      profile: {
        ...state.profile,
        user: {
          isLoading: false,
          error: null,
          data: action.profile,
        },
      },
    };
  }),
  on(userProfileFailed, switchAccountUserProfileFailed, (state: AuthState, action): AuthState => {
    return {
      ...state,
      profile: {
        ...state.profile,
        user: {
          isLoading: false,
          error: action.error,
          data: null,
        },
      },
    };
  }),
  on(userPhotoRequested, (state: AuthState, action): AuthState => {
    return {
      ...state,
      profile: {
        ...state.profile,
        userPhoto: {
          ...state.profile.userPhoto,
          isLoading: true,
          loadingError: null,
        },
      },
    };
  }),
  on(userPhotoSuccess, (state: AuthState, action): AuthState => {
    return {
      ...state,
      profile: {
        ...state.profile,
        userPhoto: {
          ...state.profile.userPhoto,
          isLoading: false,
          loadingError: null,
          imageBase64: action.imageBase64,
        },
      },
    };
  }),
  on(userPhotoFailed, (state: AuthState, action): AuthState => {
    return {
      ...state,
      profile: {
        ...state.profile,
        userPhoto: {
          ...state.profile.userPhoto,
          isLoading: false,
          loadingError: action.error,
          imageBase64: '',
        },
      },
    };
  }),
  on(updateUserPhotoRequested, (state: AuthState, action): AuthState => {
    return {
      ...state,
      profile: {
        ...state.profile,
        userPhoto: {
          ...state.profile.userPhoto,
          isUpdating: true,
          updatingError: null,
        },
      },
    };
  }),
  on(updateUserPhotoSuccess, (state: AuthState, action): AuthState => {
    return {
      ...state,
      profile: {
        ...state.profile,
        user: {
          ...state.profile.user,
          data: {
            ...state.profile.user.data,
            avatarPath: action.photoPath,
          },
        },
        userPhoto: {
          ...state.profile.userPhoto,
          isUpdating: false,
          updatingError: null,
          imageBase64: action.imageBase64,
        },
      },
    };
  }),
  on(updateUserPhotoFailed, (state: AuthState, action): AuthState => {
    return {
      ...state,
      profile: {
        ...state.profile,
        userPhoto: {
          ...state.profile.userPhoto,
          isUpdating: false,
          updatingError: action.error,
        },
      },
    };
  }),
];
