import { Pipe, PipeTransform } from '@angular/core';
import { YYYYMMDDString } from '@rootTypes';
import { YearMonthDay } from 'src/app/types/utils/common/date';

@Pipe({
  name: 'YYYYMMDDToMD',
})
export class YYYYMMDDToMDPipe implements PipeTransform {
  transform(value: YYYYMMDDString): string {
    if (!value) {
      return '--';
    }
    const formatter = new YearMonthDay(value);
    return formatter.formatTo('mm_slash_dd');
  }
}
