import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { NavigationRequest } from '../../types/navigation-request';
import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '../../../types/entities/router';
import { getRoutingRequest } from '@rootTypes/utils/router/get-routing-request';

export interface VendorEmployeeListRouteData {
  page?: string;
  pageSize?: string;
  search?: string;
  filters?: string;
}

export const vendorEmployeeListRoute: PortalRoute<VendorEmployeeListRouteData> = {
  path: 'list',
  isOnRoute: (state: RouterStateUrl): boolean => {
    return state.url.startsWith('/employees/list');
  },
  parse: (state: RouterStateUrl): VendorEmployeeListRouteData => {
    let data: VendorEmployeeListRouteData = {};
    if (state.queryParams) {
      data = {
        ...state.queryParams,
      };
    }
    return data;
  },
  request: (
    data: VendorEmployeeListRouteData = {},
    queryParamStrategy?: QueryParamsHandlingStrategy,
    replaceUrl = false,
  ): NavigationRequest => {
    const path = ['vendor-employees', 'list'];
    const routeData = { ...data };
    return getRoutingRequest(path, routeData, queryParamStrategy, replaceUrl);
  },
  getHref(data?: VendorEmployeeListRouteData): string {
    return '/vendor-employees/list';
  },
};

@Injectable({ providedIn: 'root' })
export class VendorEmployeeListRouterService extends PortalRouterService<VendorEmployeeListRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route: PortalRoute<VendorEmployeeListRouteData> = vendorEmployeeListRoute;
}
