import { MapMarker } from '../interfaces';

interface AddedMapMarker {
  getPosition(): google.maps.LatLng;
  remove?: () => void;
  setMap?: (map: google.maps.Map | null) => void;
  get?: (field: string) => any;
}

export class MarkerRenderer {
  private gMarkers: AddedMapMarker[] = [];

  constructor(private map: google.maps.Map) {}

  public async setMarkers(markers: MapMarker[]): Promise<void> {
    if (!markers) {
      return;
    }
    const removedMarkers = this.getRemovedMarkers(this.gMarkers, markers);
    const addedMarkers = this.getAddedMarkers(this.gMarkers, markers);
    removedMarkers.forEach((removed) => {
      removed.setMap(null);
      // call remove for custom markers
      if ('remove' in removed) {
        (removed as any).remove();
      }
      const removedIndex = this.gMarkers.findIndex((gm) => gm === removed);
      this.gMarkers.splice(removedIndex, 1);
    });
    const promises = addedMarkers.map((m) => this.addMarkerToMap(m));
    await Promise.all(promises);
  }

  public fitMapToMarkers(): void {
    if (!this.gMarkers.length) {
      return;
    }
    if (this.gMarkers.length === 1) {
      const marker = this.gMarkers[0];
      this.map.setCenter(marker.getPosition());
      this.map.setZoom(17);
      return;
    }
    const bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < this.gMarkers.length; i++) {
      bounds.extend(this.gMarkers[i].getPosition());
    }
    if (this.map) {
      this.map.fitBounds(bounds);
    }
  }

  private async addMarkerToMap(marker: MapMarker): Promise<void> {
    if (!marker.html) {
      return this.addIconMarkerToMap(marker);
    } else {
      return this.addCustomHtmlMarkerToMap(marker);
    }
  }

  private async addIconMarkerToMap(marker: MapMarker): Promise<void> {
    const width = marker.width || 16;
    const widthHalf = Math.floor(width / 2);
    const height = marker.height || 16;
    const image = {
      url: marker.url,
      scaledSize: new google.maps.Size(width, height),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(widthHalf, height),
    };
    const gMarker = new google.maps.Marker({
      position: { lat: marker.location.lat, lng: marker.location.lng },
      icon: image,
      map: this.map,
      zIndex: marker.zIndex || 100,
      draggable: marker.draggable || false,
    });
    gMarker.set('compareId', marker.compareId);
    this.gMarkers.push(gMarker);

    if (marker.clickCallbackFn) {
      gMarker.addListener('click', (event) => {
        event.stop();
        marker.clickCallbackFn();
      });
    }
    if (marker.tooltip && marker.tooltip.length) {
      const infoWindow = new google.maps.InfoWindow({
        content: `${marker.tooltip}`,
        disableAutoPan: true,
      });
      gMarker.addListener('mouseover', () => {
        infoWindow.open(this.map, gMarker);
      });
      gMarker.addListener('mouseout', () => {
        infoWindow.close();
      });
    }

    if (marker.dragCallbackFn) {
      gMarker.addListener('dragend', (event) => {
        marker.dragCallbackFn(event);
      });
    }
  }

  private async addCustomHtmlMarkerToMap(marker: MapMarker): Promise<void> {
    const latnlng = new google.maps.LatLng(marker.location.lat, marker.location.lng);
    return import('../models/custom-html-marker').then((customMarker) => {
      const cm = new customMarker.CustomHtmlMarker(
        latnlng,
        this.map,
        marker.html,
        marker.width + 'px',
        marker.height + 'px',
        marker.zIndex || 100,
        {
          preventDefaultClick: false,
          tooltip: {
            html: marker.customMarkerTooltip?.html,
            fix: marker?.customMarkerTooltip?.fix,
            applyClass: marker?.customMarkerTooltip?.applyClass,
          },
          popup: { html: marker.customMarkerPopup?.html, applyClass: marker.customMarkerPopup?.applyClass },
          htmlListeners: marker.htmlMarkerListeners,
        },
      );
      cm.set('compareId', marker.compareId);
      this.gMarkers.push(cm as any);
      if (marker.clickCallbackFn) {
        cm.addListener('click', () => {
          marker.clickCallbackFn();
        });
      }
      if (marker.tooltip && marker.tooltip.length) {
        const infoWindow = new google.maps.InfoWindow({
          content: `${marker.tooltip}`,
          pixelOffset: new google.maps.Size(0, -1 * (marker.height - 1)),
        });
        let timeout: any;
        let opened = false;
        cm.addListener('mouseenter', () => {
          if (timeout) {
            clearTimeout(timeout);
          }
          if (marker.mouseOverCallbackFn) {
            marker.mouseOverCallbackFn();
          }
          if (!opened) {
            infoWindow.open(this.map, cm);
            opened = true;
          }
        });
        cm.addListener('mouseleave', () => {
          timeout = setTimeout(() => {
            if (marker.mouseOutCallbackFn) {
              marker.mouseOutCallbackFn();
            }
            infoWindow.close();
            opened = false;
          }, 200);
        });
      }
    });
  }

  private getAddedMarkers(previous: AddedMapMarker[], newMarkers: MapMarker[]): MapMarker[] {
    const result = [];
    newMarkers.forEach((newMarker) => {
      if (!newMarker.compareId) {
        result.push(newMarker);
      } else {
        const wasAdded = previous.some((prevMarker) => prevMarker.get('compareId') === newMarker.compareId);
        if (!wasAdded) {
          result.push(newMarker);
        }
      }
    });
    return result;
  }

  private getRemovedMarkers(previous: AddedMapMarker[], newMarkers: MapMarker[]): AddedMapMarker[] {
    const result = [];
    previous.forEach((prevMarker) => {
      const prevCompareId = prevMarker.get('compareId');
      if (!prevCompareId) {
        result.push(prevMarker);
      } else {
        const isPresentInTheNewBatch = newMarkers.some((newMarker) => newMarker.compareId === prevCompareId);
        if (!isPresentInTheNewBatch) {
          result.push(prevMarker);
        }
      }
    });
    return result;
  }
}
