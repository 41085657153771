<svg
  class="icon"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <g fill="none" fill-rule="evenodd">
    <path
      stroke="#959597"
      stroke-width="2"
      d="M14.257 12.796c-.56-.332-.257-.332-1.214-.537V8.825c.543-.415.87-1.053.87-2.02V4.09C13.913 1.84 12.609 0 10 0S6.087 1.841 6.087 4.09v2.714c0 .977.327 1.617.87 2.03v3.425C5.19 13.052 1.429 15.31.87 15.643c-.697.415-.87.807-.87 1.765v2.137c0 .25.196.455.435.455h13.478"
      transform="translate(1 2)"
    />
    <g transform="translate(1 2) translate(13 12)">
      <circle cx="4.5" cy="4.5" r="4.5" stroke="#959597" stroke-width="2" />
      <path
        fill="#959597"
        d="M4.634 5h-.268L.585 7.183l-.5-.866L4 4.057V0h1v4.057l3.915 2.26-.5.866L4.634 5z"
      />
    </g>
  </g>
</svg>
