import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { DriverDataFacade, Vehicle, VehicleDataFacade, WpError } from '../types';

@Injectable()
export class DriverVehicleFacade {
  constructor(
    private driverFacade: DriverDataFacade,
    private vehicleFacade: VehicleDataFacade,
  ) {}

  public getVehicle(driverId: string): Observable<Vehicle> {
    return this.driverFacade.getDriverVehicleId(driverId).pipe(
      switchMap((vehicleId) => {
        if (vehicleId) {
          return this.vehicleFacade.getVehicle(vehicleId);
        }
        return of(undefined);
      }),
    );
  }

  public isVehicle(driverId: string): Observable<boolean> {
    return this.driverFacade.isDriverVehicle(driverId);
  }

  public getVehicleId(driverId: string): Observable<string> {
    return this.driverFacade.getDriverVehicleId(driverId);
  }

  public getVehicleLoading(driverId: string): Observable<boolean> {
    return this.driverFacade.getDriverVehicleId(driverId).pipe(
      switchMap((vehicleId) => {
        if (vehicleId) {
          return this.vehicleFacade.getVehicleLoading(vehicleId);
        }
        return of(undefined);
      }),
    );
  }

  public getVehicleError(driverId: string): Observable<WpError> {
    return this.driverFacade.getDriverVehicleId(driverId).pipe(
      switchMap((vehicleId) => {
        if (vehicleId) {
          return this.vehicleFacade.getVehicleError(vehicleId);
        }
        return of(undefined);
      }),
    );
  }

  public getVehicleErrorMessage(driverId: string): Observable<string> {
    return this.driverFacade.getDriverVehicleId(driverId).pipe(
      switchMap((vehicleId) => {
        if (vehicleId) {
          return this.vehicleFacade.getVehicleErrorMessage(vehicleId);
        }
        return of(undefined);
      }),
    );
  }

  public getDisplayName(driverId: string): Observable<string> {
    return this.driverFacade.getDriverVehicleId(driverId).pipe(
      switchMap((vehicleId) => {
        if (vehicleId) {
          return this.vehicleFacade.getDisplayName(vehicleId);
        }
        return of(undefined);
      }),
    );
  }

  public getLicenseName(driverId: string): Observable<string> {
    return this.driverFacade.getDriverVehicleId(driverId).pipe(
      switchMap((vehicleId) => {
        if (vehicleId) {
          return this.vehicleFacade.getLicenseName(vehicleId);
        }
        return of(undefined);
      }),
    );
  }

  public getColor(driverId: string): Observable<string> {
    return this.driverFacade.getDriverVehicleId(driverId).pipe(
      switchMap((vehicleId) => {
        if (vehicleId) {
          return this.vehicleFacade.getColor(vehicleId);
        }
        return of(undefined);
      }),
    );
  }

  public getImagePath(driverId: string): Observable<string> {
    return this.driverFacade.getDriverVehicleId(driverId).pipe(
      switchMap((vehicleId) => {
        if (vehicleId) {
          return this.vehicleFacade.getImagePath(vehicleId);
        }
        return of(undefined);
      }),
    );
  }

  public getSeatingCapacity(driverId: string): Observable<number> {
    return this.driverFacade.getDriverVehicleId(driverId).pipe(
      switchMap((vehicleId) => {
        if (vehicleId) {
          return this.vehicleFacade.getSeatingCapacity(vehicleId);
        }
        return of(undefined);
      }),
    );
  }

  public getRegistrationExpDate(driverId: string): Observable<Date> {
    return this.driverFacade.getDriverVehicleId(driverId).pipe(
      switchMap((vehicleId) => {
        if (vehicleId) {
          return this.vehicleFacade.getRegistrationExpDate(vehicleId);
        }
        return of(undefined);
      }),
    );
  }

  public getInsuranceExpDate(driverId: string): Observable<Date> {
    return this.driverFacade.getDriverVehicleId(driverId).pipe(
      switchMap((vehicleId) => {
        if (vehicleId) {
          return this.vehicleFacade.getInsuranceExpDate(vehicleId);
        }
        return of(undefined);
      }),
    );
  }

  public getBackgroundCheckNames(driverId: string): Observable<string[]> {
    return this.driverFacade.getDriverVehicleId(driverId).pipe(
      switchMap((vehicleId) => {
        if (vehicleId) {
          return this.vehicleFacade.getBackgroundCheckNames(vehicleId);
        }
        return of(undefined);
      }),
    );
  }

  public getYear(driverId: string): Observable<number> {
    return this.driverFacade.getDriverVehicleId(driverId).pipe(
      switchMap((vehicleId) => {
        if (vehicleId) {
          return this.vehicleFacade.getYear(vehicleId);
        }
        return of(undefined);
      }),
    );
  }
}
