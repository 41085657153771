import * as fromTypes from '../../../types';
import * as fromActions from '../../actions/campus-details/locations-tab.actions';
import * as fromDetailsActions from '../../actions/campus-details/campus-details.actions';
import { createReducer, on } from '@ngrx/store';

export interface LocationsTabState {
  selectedLocationId: string;
  viewMode: fromTypes.CampusLocationViewMode;
}

export const initialLocationsTabState: LocationsTabState = {
  selectedLocationId: undefined,
  viewMode: fromTypes.CampusLocationViewMode.MAP,
};

export const locationsTabReducer = createReducer<LocationsTabState>(
  { ...initialLocationsTabState },
  on(fromDetailsActions.cleanCampusDetails, (state, action) => {
    return { ...initialLocationsTabState };
  }),
  on(fromActions.setViewMode, (state, action) => {
    const { viewMode } = action;
    return {
      ...state,
      viewMode,
    };
  }),
  on(fromActions.selectLocation, (state, action) => {
    const { locationId } = action;
    return {
      ...state,
      selectedLocationId: locationId,
    };
  }),
);
