import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { PortalRouteTracePoint } from '../../../../types/entities/ride';
import { GoogleMapState } from '../../interfaces';
import { MapLocation } from '../../../../types';
import { getLocationsHash } from '../get-locations-hash';
import { DrivingRouteRenderer } from '../driving-route-renderer';
import { CustomRouteRenderer } from '../custom-route-renderer';

export class RouteDisplayMode {
  // draws default driving route between points
  private drivingRouteRenderer: DrivingRouteRenderer;
  // draws actual route using the points
  private subscriptions: Subscription = new Subscription();

  constructor(
    private state$: BehaviorSubject<GoogleMapState>,
    private customRouteRenderer: CustomRouteRenderer,
    private traceGapMeters: number,
    private showAdditionalRouteTraceInfo: boolean,
    private isCalculateDirectionsNeeded: boolean,
  ) {
    this.state$
      .asObservable()
      .pipe(
        filter((s) => !!s.map),
        take(1),
      )
      .subscribe(() => this.init());
  }

  public dispose(): void {
    this.subscriptions.unsubscribe();
  }

  public reset(): void {
    if (this.customRouteRenderer) {
      this.customRouteRenderer.setPoints([]);
    }
  }

  private init(): void {
    this.drivingRouteRenderer = new DrivingRouteRenderer(this.state$.value.map, this.isCalculateDirectionsNeeded);
    this.setDisplayRouteTraceSubscriptions();
  }

  private setDisplayRouteTraceSubscriptions(): void {
    const getTracePoints$ = this.state$.asObservable().pipe(
      map((state) => state.displayTracePoints),
      distinctUntilChanged(this.locationsChanged.bind(this)),
    );
    const defaultDrivingRoutePoints$ = this.state$.asObservable().pipe(
      map((state) => state.displayDefaultDrivingRoutePoints),
      distinctUntilChanged(this.locationsChanged.bind(this)),
    );
    const isDisplayDefaultDrivingRoute$ = this.state$.asObservable().pipe(
      map((state) => state.isDisplayDefaultDrivingRoute),
      distinctUntilChanged(),
    );
    const isDisplayTraceHistory$ = this.state$.asObservable().pipe(
      map((state) => state.isShowTraceHistory),
      distinctUntilChanged(),
    );

    const displayRouteSubscription = combineLatest([
      getTracePoints$,
      defaultDrivingRoutePoints$,
      isDisplayDefaultDrivingRoute$,
      isDisplayTraceHistory$,
    ]).subscribe(([tracePoints, defaultRoutePoints, isDisplayDefault, isDisplayTraceHistory]) =>
      this.onDisplayRouteTraceChanged(tracePoints, defaultRoutePoints, isDisplayDefault, isDisplayTraceHistory),
    );
    this.subscriptions.add(displayRouteSubscription);
  }

  private onDisplayRouteTraceChanged(
    routeTracePoints: PortalRouteTracePoint[],
    defaultDrivingRoutePoints: PortalRouteTracePoint[],
    isDisplayDefaultDrivingRoute: boolean,
    isDisplayTraceHistory: boolean,
  ): void {
    this.drivingRouteRenderer.hideRoute();
    this.customRouteRenderer.hide();
    if (isDisplayDefaultDrivingRoute) {
      this.drivingRouteRenderer.setPoints(defaultDrivingRoutePoints);
    } else if (isDisplayTraceHistory) {
      this.customRouteRenderer.setPoints(routeTracePoints);
    }
  }

  private locationsChanged(prev: MapLocation[], curr: MapLocation[]): boolean {
    return getLocationsHash(prev) === getLocationsHash(curr);
  }
}
