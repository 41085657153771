import { EntityFilterRequest } from '../../../../api/endpoints/entity-filter';
import { DriverListSnapshot } from '../entities/driver-list-snapshot';
import { PortalEntityType } from '@rootTypes';
import { DriverListEntityFilter, DriverListFilterType, DriverListMultiAccountFilter } from '../entities';
import { getEntityFilterRequest } from '@rootTypes/utils/portal-entity/get-entity-filter-request';
import { EntitySearchResponseType } from '@apiEntities/entity-search';
import { getUniqueArrayValues } from '@rootTypes/utils';

export const getRequestFromSnapshot = (snapshot: DriverListSnapshot): EntityFilterRequest => {
  const { pageIndex, pageSize, search, driverStatus, filters } = snapshot;
  const skip = pageIndex * pageSize;
  const limit = pageSize;
  const entityFilters: DriverListEntityFilter[] = (filters || []).filter(
    (f) => f.type === DriverListFilterType.ENTITY,
  ) as DriverListEntityFilter[];
  const yardIds = entityFilters.filter((f) => f.payload.type === PortalEntityType.YARD).map((f) => f.payload.entityId);
  const multiAccountFilters = (filters || []).filter(
    (f) => f.type === DriverListFilterType.MULTI_ACCOUNT_FILTER,
  ) as DriverListMultiAccountFilter[];
  const operatorIds = multiAccountFilters.flatMap((f) => f.payload.operatorIds);
  return getEntityFilterRequest(search, EntitySearchResponseType.DRIVER, driverStatus, skip, limit, {
    yardIds,
    operatorIds: getUniqueArrayValues(operatorIds),
  });
};
