import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PlaceholderType } from '../../photos/types';
import * as fromTypes from '../types';

@Component({
  selector: 'wp-vehicle-select-option',
  templateUrl: './vehicle-select-option.component.html',
  styleUrls: ['./vehicle-select-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleSelectOptionComponent {
  @Input() public entity: fromTypes.VehiclePortalEntity;
  public photoPlaceholder = PlaceholderType.CAR;

  constructor() {}
}
