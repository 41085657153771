<div class="wp-ride-list-result-bar">
  <ng-container *ngIf="(totalFound !== null && totalFound !== undefined)">
    <ng-container *ngIf="customTotalFoundSafeHtml; else regularTotalFound">
      <div
        class="wp-ride-list-result-bar__left"
        [ngStyle]="{'font-size': totalFoundFontSize}"
        [innerHTML]="customTotalFoundSafeHtml"
      ></div>
    </ng-container>
    <ng-template #regularTotalFound>
      <div
        *ngIf="totalFound <= maxQuantity"
        class="wp-ride-list-result-bar__left"
        [ngStyle]="{'font-size': totalFoundFontSize}"
      >
        {{ totalFound | displayQuantity: entityName:customPluralForm }} found
      </div>
      <div
        *ngIf="totalFound > maxQuantity"
        class="wp-ride-list-result-bar__left"
        [ngStyle]="{'font-size': totalFoundFontSize}"
      >
        {{ maxQuantity }}+ {{ customPluralForm ? customPluralForm : entityName +
        's'}} found
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="centerContent">
    <ng-template [ngTemplateOutlet]="centerContent"></ng-template>
  </ng-container>

  <div class="wp-ride-list-result-bar__right">
    <ng-container *ngIf="rightContent">
      <ng-template [ngTemplateOutlet]="rightContent"></ng-template>
    </ng-container>

    <div *ngIf="totalFound" class="wp-ride-list-result-bar__list-showing">
      <span class="slate-grey">Showing </span>
      <span class="bold">{{ showingFrom }} - {{ showingTo }} </span>
      <span class="slate-grey"> of </span>
      <span class="bold" *ngIf="totalFound <= maxQuantity">
        {{ totalFound }}
      </span>
      <span class="bold" *ngIf="totalFound > maxQuantity">
        {{ maxQuantity }}+
      </span>
      <span class="slate-grey"> results </span>
    </div>
  </div>
</div>
