import { Pipe, PipeTransform } from '@angular/core';
import { Equipment } from '@rootTypes/entities/vehicles';
import { getDisplayVehicleEquipment } from '@rootTypes/utils/vehicle/get-vehicle-display-equipment';

@Pipe({
  name: 'vehicleDisplayEquipment',
})
export class VehicleDisplayEquipmentPipe implements PipeTransform {
  transform(vehicle: Equipment): string {
    return getDisplayVehicleEquipment(vehicle);
  }
}
