<wp-icon-insights-green *ngIf="green"></wp-icon-insights-green>
<wp-icon-insights-grey *ngIf="!green"></wp-icon-insights-grey>
<!-- <svg
  class="icon"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  *ngIf="!green"
>
  <g
    fill="none"
    fill-rule="evenodd"
    stroke-width="2"
    transform="translate(1 4)"
  >
    <circle cx="20" cy="2" r="2" stroke="#959597" />
    <circle cx="2" cy="16" r="2" stroke="#959597" />
    <circle cx="7" cy="7" r="2" stroke="#959597" />
    <circle cx="14" cy="12" r="2" stroke="#959597" />
    <path
      stroke="#979797"
      d="M2.338 14.3l3.59-5.954M9 8l3.352 2.742M14.934 10.523L18.944 4"
    />
  </g>
</svg>

<svg
  class="icon"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  *ngIf="green"
>
  <g
    fill="none"
    fill-rule="evenodd"
    stroke-width="2"
    transform="translate(1 4)"
  >
    <circle cx="20" cy="2" r="2" stroke="#47A540" />
    <circle cx="2" cy="16" r="2" stroke="#47A540" />
    <circle cx="7" cy="7" r="2" stroke="#47A540" />
    <circle cx="14" cy="12" r="2" stroke="#47A540" />
    <path
      stroke="#47A540"
      d="M2.338 14.3l3.59-5.954M9 8l3.352 2.742M14.934 10.523L18.944 4"
    />
  </g>
</svg> -->
