import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-title-one',
  templateUrl: './title-one.component.html',
  styleUrls: ['./title-one.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleOneComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
