import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import {
  studentChangeRequestsFeatureEffects,
  studentChangeRequestsFeatureKey,
  studentChangeRequestsFeatureReducer,
} from './store';

@NgModule({
  declarations: [],
  providers: [],
  imports: [
    StoreModule.forFeature(studentChangeRequestsFeatureKey, studentChangeRequestsFeatureReducer),
    EffectsModule.forFeature(studentChangeRequestsFeatureEffects),
  ],
})
export class StudentChangeRequestsStoreModule {}
