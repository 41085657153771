import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurrentUserProfile } from 'src/app/api/endpoints/get-profile';
import { AuthState } from '../reducers';
import { AuthenticationProvider } from '../../../api/endpoints/get-authentication-provider';

export const authModuleKey = 'auth';

export const getAuthState = createFeatureSelector<AuthState>(authModuleKey);

// login
const getLoginState = createSelector(getAuthState, (state) => state.loginWithPassword);

export const loginLoading = createSelector(getLoginState, (state) => state.isLoading);

export const getLoginError = createSelector(getLoginState, (state) => state.error);

const loginWithTokenState = createSelector(getAuthState, (state) => state.loginWithToken);

export const loginWithTokenLoading = createSelector(loginWithTokenState, (state) => state.isLoading);

export const loginWithTokenError = createSelector(loginWithTokenState, (state) => state.error);

// logout
const getLogoutState = createSelector(getAuthState, (state) => state.logout);

export const logoutLoading = createSelector(getLogoutState, (state) => state.isLoading);

// check auth
const getCheckAuthState = createSelector(getAuthState, (state) => state.checkAuth);

export const checkAuthLoading = createSelector(getCheckAuthState, (state) => state.isLoading);

// current user profile
export const getUserProfileState = createSelector(getAuthState, (state) => state.profile);

const currentUserState = createSelector(getUserProfileState, (state) => state.user);

export const isCurrentUserProfileLoaded = createSelector(currentUserState, (state) => !!state.data && !state.isLoading);

export const currentUser = createSelector(currentUserState, (state): CurrentUserProfile => state.data);

export const firebaseUserId = createSelector(getAuthState, (state) => state.firebaseUserId);
export const getAccountUserRole = createSelector(getAuthState, (state) => state.userRole);

export const currentUserFullName = createSelector(currentUser, (user): string | '' => {
  if (!user) {
    return '';
  }
  let fullName = '';
  if (user.name.first) {
    fullName += user.name.first;
  }
  if (user.name.last) {
    if (fullName) {
      fullName += ' ';
    }
    fullName += user.name.last;
  }
  return fullName;
});

export const currentUserPhotoPath = createSelector(currentUser, (user) => user?.avatarPath || '');

export const authStatus = createSelector(checkAuthLoading, firebaseUserId, (isLoading, id) => {
  return {
    isLoading,
    isUserAuthenticated: !!id,
  };
});

export const loginRelayState = createSelector(getAuthState, (state) => state.loginRelayState);

export const logoutRelayState = createSelector(getAuthState, (state) => state.logoutRelayState);

const authProviderState = createSelector(getAuthState, (state) => state.authProvider);
export const getAuthEmail = createSelector(authProviderState, (state) => state.email);
export const getAuthProvider = createSelector(
  authProviderState,
  (state): AuthenticationProvider | null => state.response?.provider || null,
);
export const isAuthProviderLoading = createSelector(authProviderState, (state) => state.isLoading);
export const isAuthProviderPassword = createSelector(
  authProviderState,
  (state) => state.response?.provider === AuthenticationProvider.PASSWORD,
);
