import { EntityState } from '@rootTypes';
import { createReducer, on } from '@ngrx/store';
import {
  getRouteGroupActivityPaymentConfigActions,
  getRouteGroupActivityPaymentConfigFailed,
  getRouteGroupActivityPaymentConfigSuccess,
} from '../actions/route-group-activity-payment-configs.actions';

export interface RouteGroupActivityPaymentConfigState {
  paymentOptions: {
    [routeGroupId: string]: EntityState<RouteGroupActivityPaymentConfigData>;
  };
}

export interface RouteGroupActivityPaymentConfigData {
  options: { id: string; label: string }[];
  map: {
    [id: string]: { label: string };
  };
}

export const getInitialRouteGroupActivityPaymentConfigState = (): RouteGroupActivityPaymentConfigState => {
  return {
    paymentOptions: {},
  };
};

export const routeGroupActivityPaymentConfigReducer = createReducer<RouteGroupActivityPaymentConfigState>(
  getInitialRouteGroupActivityPaymentConfigState(),
  on(...getRouteGroupActivityPaymentConfigActions, (state, action) => {
    const { routeGroupId } = action;
    const prevState = state.paymentOptions[routeGroupId] || { isLoading: false };
    return {
      ...state,
      paymentOptions: {
        ...state.paymentOptions,
        [routeGroupId]: {
          ...prevState,
          isLoading: true,
        },
      },
    };
  }),
  on(...getRouteGroupActivityPaymentConfigActions, (state, action) => {
    const { routeGroupId } = action;
    const prevState = state.paymentOptions[routeGroupId] || { isLoading: false };
    return {
      ...state,
      paymentOptions: {
        ...state.paymentOptions,
        [routeGroupId]: {
          ...prevState,
          isLoading: true,
        },
      },
    };
  }),
  on(getRouteGroupActivityPaymentConfigSuccess, (state, action) => {
    const { routeGroupId, config } = action;
    const options = config.paymentOptions;
    const map = config.paymentOptions.reduce((p, c) => {
      return { ...p, [c.id]: { label: c.label } };
    }, {});
    return {
      ...state,
      paymentOptions: {
        ...state.paymentOptions,
        [routeGroupId]: {
          isLoading: false,
          entity: { options, map },
        },
      },
    };
  }),
  on(getRouteGroupActivityPaymentConfigFailed, (state, action) => {
    const { routeGroupId, error } = action;
    return {
      ...state,
      paymentOptions: {
        ...state.paymentOptions,
        [routeGroupId]: {
          isLoading: false,
          error,
        },
      },
    };
  }),
);
