import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { withLatestFrom, map, filter, switchMap, tap } from 'rxjs/operators';

import { SegmentAnalyticsService } from '../../segment-analytics.service';
import { firebaseUserId, logoutPageLogoutSuccess, userProfileSuccess } from 'src/app/auth/store';
import { isSegmentEnabled } from '../selectors';
import { SegmentIdentifyInfo } from '../types';
import { segmentIdentifyUserRequested, segmentIdentifyUserSuccess, segmentResetUserSuccess } from '../actions';
import { routerNavigatedAction } from '@ngrx/router-store';

@Injectable({
  providedIn: 'root',
})
export class SegmentEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private segment: SegmentAnalyticsService,
  ) {}

  public onUserAuthorized$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProfileSuccess),
      withLatestFrom(this.store.select(firebaseUserId), this.isSegmentEnabled$()),
      filter(([, , isEnabled]) => isEnabled),
      map(([, userId]) => {
        const data: SegmentIdentifyInfo = {
          gitVersion: wpEnvironment.gitVersion,
        };
        return segmentIdentifyUserRequested({ data, userId });
      }),
    ),
  );

  public identifySegmentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(segmentIdentifyUserRequested),
      switchMap(({ data, userId }) => {
        return this.segment.identify$(userId, data).pipe(map(() => segmentIdentifyUserSuccess()));
      }),
    ),
  );

  public resetSegmentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutPageLogoutSuccess),
      withLatestFrom(this.isSegmentEnabled$()),
      filter(([action, isEnabled]) => isEnabled),
      switchMap(() => {
        return this.segment.reset$().pipe(map(() => segmentResetUserSuccess()));
      }),
    ),
  );

  public logPageChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigatedAction),
        withLatestFrom(this.isSegmentEnabled$()),
        filter(([navigationState, isEnabled]) => isEnabled),
        tap(() => {
          // log the current URL with the default Segment settings
          this.segment.page();
        }),
      ),
    { dispatch: false },
  );

  private isSegmentEnabled$(): Observable<boolean> {
    return this.store.select(isSegmentEnabled);
  }
}
