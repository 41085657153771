import { createAction, props } from '@ngrx/store';
import { WpError } from '@rootTypes';
import { GetStudentChangeRequestLogsResponse } from 'src/app/api/endpoints/get-student-change-request-logs';

const changeLog = '[StudentDetailsTabChangeLog]';
export const initStudentChangeLog = createAction(`${changeLog}: Initialize`, props<{ studentId: string }>());
export const destroyStudentChangeLog = createAction(`${changeLog}: Destroy`);

export const loadStudentChangeLogRequested = createAction(`${changeLog}: Load logs requested`);
export const loadStudentChangeLogSuccess = createAction(
  `${changeLog}: Load logs success`,
  props<{ response: GetStudentChangeRequestLogsResponse }>(),
);
export const loadStudentChangeLogFailed = createAction(`${changeLog}: Load logs failed`, props<{ error: WpError }>());
