<svg
  xmlns="http://www.w3.org/2000/svg"
  width="16"
  height="22"
  viewBox="0 0 16 22"
>
  <g fill="none" fill-rule="evenodd">
    <g stroke="#47A540" stroke-width="2">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M10 10.833c-1.841 0-3.333-1.493-3.333-3.333 0-1.841 1.492-3.333 3.333-3.333 1.84 0 3.333 1.492 3.333 3.333 0 1.84-1.493 3.333-3.333 3.333zm0-10c-3.682 0-6.667 2.931-6.667 6.548C3.333 13.928 10 19.167 10 19.167s6.667-5.239 6.667-11.786c0-3.617-2.985-6.548-6.667-6.548z"
                transform="translate(-518 -373) translate(486) translate(0 230) translate(1 123) translate(29 21)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
