<div
  class="field-error"
  [ngClass]="{
  'has-error': !!error
}"
>
  <div class="content"><ng-content></ng-content></div>
  <div class="error-sign" *ngIf="error" wpFadeInOut [show]="true">
    <div class="error-inner">
      <mat-menu #menu="matMenu" [yPosition]="'above'">
        <div class="menu-error-content">{{ error }}</div>
      </mat-menu>
      <div
        [matMenuTriggerFor]="menu"
        class="error-icon"
        [ngClass]="{
      checked: isClicked
    }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <g fill="none" fill-rule="evenodd">
            <g>
              <g>
                <g>
                  <g>
                    <g
                      transform="translate(-1333.000000, -281.000000) translate(487.000000, 1.000000) translate(0.000000, 192.000000) translate(846.000000, 86.000000) translate(0.000000, 2.000000)"
                    >
                      <circle
                        cx="8"
                        cy="8"
                        r="7"
                        stroke="#f44336"
                        stroke-width="2"
                        class="colored-stroke"
                      />
                      <path
                        fill="#f44336"
                        class="colored-fill"
                        d="M7.2 5.6H8.8V12H7.2zM7.2 3.2H8.8V4.800000000000001H7.2z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</div>
