<svg
  width="24px"
  height="24px"
  viewBox="0 0 24 24"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g
    id="2-element-/-icon-/-ic_driver_brand"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g transform="translate(1.000000, 2.000000)">
      <path
        d="M14.2568359,12.7962646 C13.6972707,12.4639919 14,12.4639919 13.0434783,12.2586364 L13.0434783,8.82454545 C13.586087,8.41 13.9130435,7.77181818 13.9130435,6.80409091 L13.9130435,4.09090909 C13.9130435,1.84136364 12.6086957,0 10,0 C7.39130435,0 6.08695652,1.84136364 6.08695652,4.09090909 L6.08695652,6.80409091 C6.08695652,7.78136364 6.41391304,8.42136364 6.95652174,8.83409091 L6.95652174,12.2586364 C5.18956522,13.0522727 1.42913043,15.3109091 0.869565217,15.6431818 C0.173043478,16.0577273 -4.79616347e-13,16.4504545 -4.79616347e-13,17.4081818 L-4.79616347e-13,19.5454545 C-4.79616347e-13,19.7959091 0.195652174,20 0.434782609,20 L13.9130435,20"
        id="Fill-1"
        stroke="#47A540"
        stroke-width="2"
      ></path>
      <g id="wheel" transform="translate(13.000000, 12.000000)">
        <circle
          id="Oval"
          stroke="#47A540"
          stroke-width="2"
          cx="4.5"
          cy="4.5"
          r="4.5"
        ></circle>
        <g id="Group" fill="#47A540">
          <path
            d="M5,0 L4.99993649,4.056 L8.91506351,6.3169873 L8.41506351,7.1830127 L4.63293649,5 L4.36593649,5 L0.584936491,7.1830127 L0.0849364905,6.3169873 L3.99993649,4.056 L4,0 L5,0 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </g>
  </g>
</svg>
