import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../../types';

const moduleKey = 'Campus';
const submoduleKey = 'Locations Tab';

export const selectLocation = createAction(
  `[${moduleKey}] [${submoduleKey}] Select location`,
  props<{ locationId: string | undefined }>(),
);

export const setViewMode = createAction(
  `[${moduleKey}] [${submoduleKey}] Set view mode`,
  props<{ viewMode: fromTypes.CampusLocationViewMode }>(),
);

export const locationItemClicked = createAction(
  `[${moduleKey}] [${submoduleKey}] [Component] Location item clicked`,
  props<{ locationId: string }>(),
);

export const showPhotosForLocationClicked = createAction(
  `[${moduleKey}] [${submoduleKey}] [Component] Show photos for location clicked`,
  props<{ locationId: string }>(),
);

export const makeLocationPrimaryClicked = createAction(
  `[${moduleKey}] [${submoduleKey}] Make location primary clicked`,
  props<{ locationId: string }>(),
);

export const removeLocationClicked = createAction(
  `[${moduleKey}] [${submoduleKey}] Remove location clicked`,
  props<{ locationId: string }>(),
);
