export const queryParamToVehicle = (query?: string): { vehicleId: string; vehicleDisplayId: string } => {
  if (!query) {
    return undefined;
  }
  const [vehicleId, vehicleDisplayId] = query.split('.');
  return {
    vehicleId,
    vehicleDisplayId,
  };
};
