import { GoogleMapState } from '../../interfaces';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { PortalRouteTracePoint } from '../../../../types/entities/ride';
import { CustomRouteRenderer } from '../custom-route-renderer';
import { CarMover2 } from '../car-mover/car-mover-2';
import { defaultTracePolylineOptions } from '../car-mover/polyline-options';
import { NgZone } from '@angular/core';

export class MovementIndicatorMode {
  private carMover: CarMover2;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private state$: BehaviorSubject<GoogleMapState>,
    private customRouteRenderer: CustomRouteRenderer,
    private traceGapMeters: number,
    private showAdditionalRouteTraceInfo: boolean,
    private ngZone: NgZone,
  ) {
    this.state$
      .asObservable()
      .pipe(
        filter((state) => !!state.map),
        take(1),
      )
      .subscribe(() => this.init());
  }

  public dispose(): void {
    if (this.carMover) {
      this.carMover.destroy();
    }
    this.subscriptions.unsubscribe();
  }

  public reset(): void {
    if (this.carMover) {
      this.carMover.destroy();
    }
  }

  private init(): void {
    this.carMover = new CarMover2(
      this.state$.value.map,
      this.customRouteRenderer,
      {
        traceGapMeters: this.traceGapMeters,
        isShowTraceAdditionalInfo: this.showAdditionalRouteTraceInfo,
        tracePolylineOptions: defaultTracePolylineOptions,
      },
      this.ngZone,
    );
    this.setAnimateTraceSubscriptions();
  }

  private setAnimateTraceSubscriptions(): void {
    const isAnimateTrace$ = this.state$.asObservable().pipe(
      map((state) => state.isAnimateTrace),
      distinctUntilChanged(),
    );
    const animateTraceToPoint$ = this.state$.asObservable().pipe(
      map((state) => state.animateToPoint),
      distinctUntilChanged(),
    );
    const getTracePoints$ = this.state$.asObservable().pipe(
      map((state) => state.displayTracePoints),
      distinctUntilChanged(),
    );
    const animateTraceSubscription = isAnimateTrace$
      .pipe(
        switchMap((isAnimateTrace) => {
          if (!isAnimateTrace) {
            this.carMover.destroy();
            return of();
          } else {
            return getTracePoints$.pipe(
              switchMap((initialRouteTracePoints) => {
                if (initialRouteTracePoints?.length) {
                  const lastPastPoint = initialRouteTracePoints[initialRouteTracePoints.length - 1];
                  const pointBeforeLast =
                    initialRouteTracePoints.length > 1
                      ? initialRouteTracePoints[initialRouteTracePoints.length - 2]
                      : null;
                  this.carMover.jumpTo(lastPastPoint, pointBeforeLast, true);
                }
                return animateTraceToPoint$.pipe(
                  filter((point) => !!(point && point.length)),
                  map((points) => {
                    this.onIncomingPoints(points);
                  }),
                );
              }),
            );
          }
        }),
      )
      .subscribe();
    this.subscriptions.add(animateTraceSubscription);
  }

  private onIncomingPoints(points: PortalRouteTracePoint[]): void {
    this.carMover.pushLocations(points, true);
  }
}
