<svg
  xmlns="http://www.w3.org/2000/svg"
  width="10"
  height="16"
  viewBox="0 0 10 16"
>
  <g fill="none" fill-rule="evenodd">
    <g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    stroke="#47A540"
                    stroke-width="2"
                    d="M1 13.907l4-3.232 4 3.232V6.364 2.017C9 .959 9.043 1 8.025 1H2.043C.965 1 1 .972 1 2.033V13.907z"
                    transform="translate(-645 -57) translate(486) translate(156 54) translate(0 3) translate(3)"
                  />
                  <path
                    fill="#47A540"
                    d="M0 3H10V5H0z"
                    transform="translate(-645 -57) translate(486) translate(156 54) translate(0 3) translate(3)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
