import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-grey-label',
  templateUrl: './grey-label.component.html',
  styleUrls: ['./grey-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GreyLabelComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
