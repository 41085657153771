import { createAction, props } from '@ngrx/store';
import { RouteGroupOwnerHistory } from '@apiEntities/route-group/route-group-owner-history';
import { WpError } from '@rootTypes';

export const getRouteGroupOwnerHistoryRequestedFromOwnerTab = createAction(
  '[RouteGropDetailsOwnersTab]: API Get route group owner history requested',
  props<{ routeGroupId: string }>(),
);

export const getRouteGroupOwnerHistoryRequestedFromManageDriverAssignment = createAction(
  '[ManageDriverAssignment]: API Get route group owner history requested',
  props<{ routeGroupId: string }>(),
);

export const getRouteGroupOwnerHistorySuccess = createAction(
  '[RouteGroupOwnerHistoryEffects]: API Get route group owner history success',
  props<{ routeGroupId: string; ownersHistory: RouteGroupOwnerHistory[] }>(),
);

export const getRouteGroupOwnerHistoryFailed = createAction(
  '[RouteGroupOwnerHistoryEffects]: API Get route group owner history failed',
  props<{ routeGroupId: string; error: WpError }>(),
);

export const loadRouteGroupOwnerHistoryActions = [
  getRouteGroupOwnerHistoryRequestedFromOwnerTab,
  getRouteGroupOwnerHistoryRequestedFromManageDriverAssignment,
];
