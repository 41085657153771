<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
>
  <g fill="none" fill-rule="evenodd">
    <g stroke="#959597">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g
                  transform="translate(-2031 -1767) translate(1991 1562) translate(30 69) translate(8 92) translate(2 43) translate(0 1)"
                >
                  <circle cx="10" cy="10" r="9" stroke-width="2" />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.465"
                    d="M12 6.5H9.194c-.323-.026-.642.091-.871.321-.229.23-.346.548-.32.871C8.003 9 12 10 12 11.308c.006.317-.117.625-.342.849-.225.225-.532.35-.85.343H8.003m1.998-6V5m0 9v-1.5"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
