import { createSelector } from '@ngrx/store';
import { getCampusId } from './campus-details.selectors';
import {
  getAllCalendarsByCampus,
  getAllDefaultCalendarState,
  getAllSchedulesByCalendarsState,
} from '../calendar-data.selectors';
import { getFeatureState } from '../feature-selector';

const getCalendarsTab = createSelector(getFeatureState, (state) => state.details.calendars);

const getAllCalendarsExpandedState = createSelector(getCalendarsTab, (state) => state.calendarsExpanded);

export const getCalendarExpanded = createSelector(
  getAllCalendarsExpandedState,
  (state, props: { calendarId: string }) => {
    return state[props.calendarId];
  },
);

const getAllHolidaysExpandedState = createSelector(getCalendarsTab, (state) => state.holidaysExpanded);

export const getHolidaysExpanded = createSelector(
  getAllHolidaysExpandedState,
  (state, props: { calendarId: string }) => {
    return state[props.calendarId];
  },
);

const getCalendarsStateByOpenedCampus = createSelector(
  getCampusId,
  getAllCalendarsByCampus,
  (campusId, allCalendarsByCampus) => allCalendarsByCampus[campusId],
);

export const getCalendarIdsForOpenCampus = createSelector(getCalendarsStateByOpenedCampus, (state) => state?.entity);

export const getCalendarsCountForOpenCampus = createSelector(
  getCalendarsStateByOpenedCampus,
  (state) => (state?.entity || []).length,
);

export const getCalendarsLoadingForOpenCampus = createSelector(
  getCalendarsStateByOpenedCampus,
  (state) => state?.isLoading || false,
);

export const getCalendarsEmptyForOpenCampus = createSelector(
  getCalendarIdsForOpenCampus,
  getCalendarsLoadingForOpenCampus,
  (calendars, loading) => !loading && !calendars?.length,
);

export const getCalendarsErrorForOpenCampus = createSelector(getCalendarsStateByOpenedCampus, (state) => state?.error);

/**
 * Default calendar
 */
export const getDefaultCalendarStateForOpenCampus = createSelector(
  getCampusId,
  getAllDefaultCalendarState,
  (campusId, allDefaultState) => allDefaultState[campusId],
);

export const getDefaultCalendarId = createSelector(getDefaultCalendarStateForOpenCampus, (state) => {
  return state?.calendarId;
});

export const getDefaultCalendarLoading = createSelector(
  getDefaultCalendarStateForOpenCampus,
  (state) => state?.isLoading || false,
);

// cannot toggle default, if only one calendar
export const getDefaultCalendarDisabled = createSelector(getCalendarsCountForOpenCampus, (count) => count === 1);

// cannot remove calendar, if only one calendar
export const getRemoveCalendarDisabled = createSelector(
  getCalendarsCountForOpenCampus,
  getDefaultCalendarId,
  (count, defaultId, props: { calendarId: string }) => {
    return count === 1 || defaultId === props.calendarId;
  },
);

// cannot remove schedule, if only one schedule for calendar
export const getRemoveScheduleDisabled = createSelector(
  getAllSchedulesByCalendarsState,
  (schedulesByCalendar, props: { calendarId: string }) => {
    const { calendarId } = props;
    return schedulesByCalendar[calendarId]?.length === 1;
  },
);
