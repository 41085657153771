import { RouteListSnapshot } from '../entities';
import { SerializedRouteListSnapshot } from '../entities/serialized-snapshot';
import { DEFAULT_PAGE_SIZE } from '../ui';
import { deserializeRouteFilters, serializeRouteFilters } from './serialize-filters';

export const getSerializedSnapshot = (snapshot: RouteListSnapshot): SerializedRouteListSnapshot => {
  const currentPage = snapshot.currentPage !== 0 ? '' + (snapshot.currentPage + 1) : undefined;
  const pageSize = snapshot.pageSize !== DEFAULT_PAGE_SIZE ? '' + snapshot.pageSize : undefined;
  return {
    statusType: snapshot.statusType,
    filters: serializeRouteFilters(snapshot.filters),
    staticFilters: serializeRouteFilters(snapshot.staticFilters),
    currentPage,
    pageSize,
  } as SerializedRouteListSnapshot;
};

export const getSnapshotFromSerialized = (snapshot: SerializedRouteListSnapshot): RouteListSnapshot => {
  const currentPage = parseInt(snapshot.currentPage || '1', 10) - 1;
  const pageSize = parseInt(snapshot.pageSize || '' + DEFAULT_PAGE_SIZE, 10);
  return {
    statusType: snapshot.statusType,
    filters: deserializeRouteFilters(snapshot.filters),
    staticFilters: deserializeRouteFilters(snapshot.staticFilters),
    currentPage,
    pageSize,
  } as RouteListSnapshot;
};
