import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SmartDate } from '../../models/smart-date';

@Component({
  selector: 'wp-smart-input-date',
  templateUrl: './smart-input-date.component.html',
  styleUrls: ['./smart-input-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartInputDateComponent implements OnInit {
  @Input() public tabIndex = '0';
  @Input() public model: SmartDate;
  @Input() public alignDropdown: 'left' | 'right' | 'center';

  constructor() {}

  ngOnInit(): void {}
}
