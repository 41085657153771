import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
interface LinkBtnCssClasses {
  disabled: boolean;
  bold: boolean;
  'no-underline': boolean;
  'text-align-left': boolean;
}
@Component({
  selector: 'wp-text-formatted-as-link',
  template: `
    <span class="wp-link-btn" [ngClass]="classes" [ngStyle]="{ fontSize: fontSize }">
      <ng-content></ng-content>
    </span>
  `,
  styles: `
    .wp-link-btn {
      display: inline-block;
      text-decoration: underline;
      background: none !important;
      border: none !important;
      padding: 0 !important;
      cursor: pointer;
      font-family:
        Open Sans,
        sans-serif;
      &.text-align-left {
        text-align: left;
      }
      &.no-underline {
        text-decoration: none;
      }
      &.bold {
        font-weight: 600;
      }
      &.disabled {
        cursor: default;
        opacity: 0.8;
      }
      &:hover:not(.disabled) {
        color: #47a540;
      }
      &:active:not(.disabled) {
        color: #106b2e;
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextFormattedAsLinkComponent implements OnChanges {
  @Input() disabled = false;
  @Input() isBold = false;
  @Input() fontSize = 'inherit';
  @Input() isUnderlined = true;
  @Input() isTextAlignLeft = false;

  public classes: LinkBtnCssClasses;

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.classes = {
      disabled: this.disabled,
      bold: this.isBold,
      'no-underline': !this.isUnderlined,
      'text-align-left': this.isTextAlignLeft,
    };
  }
}
