import { Injectable } from '@angular/core';
import { ApiService } from '../../../api/api.service';
import {
  getRouteGroupActivityPaymentConfigActions,
  getRouteGroupActivityPaymentConfigFailed,
  getRouteGroupActivityPaymentConfigSuccess,
} from '../actions/route-group-activity-payment-configs.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { WpError } from '@rootTypes';
import { of } from 'rxjs';

@Injectable()
export class RouteGroupActivityPaymentConfigsEffects {
  constructor(
    private api: ApiService,
    private actions$: Actions,
  ) {}

  public getRouteGroupActivityPaymentConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...getRouteGroupActivityPaymentConfigActions),
      mergeMap(({ routeGroupId }) => {
        return this.api.getRouteGroupActivityPaymentConfigs({ routeGroupId }).pipe(
          map((resp) => getRouteGroupActivityPaymentConfigSuccess({ routeGroupId, config: resp })),
          catchError((originalError) => {
            console.error(originalError);
            const error: WpError = {
              originalError,
              text: 'Failed to load route activity payment config',
            };
            return of(getRouteGroupActivityPaymentConfigFailed({ routeGroupId, error }));
          }),
        );
      }),
    ),
  );
}
