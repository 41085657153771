import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { YYYYMMDDString } from '@rootTypes';

@Component({
  selector: 'wp-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateFilterComponent implements OnInit {
  @Input() public selectedDate: YYYYMMDDString;
  @Input() public label: string;
  @Input() public widthStr = 'auto';
  @Output() public selectedChanged = new EventEmitter<YYYYMMDDString>();

  public isOpened: boolean;

  constructor() {}

  ngOnInit(): void {}

  public onMenuOpenChanged(isOpened: boolean): void {
    this.isOpened = isOpened;
  }
}
