<div class="wp-input-masked">
  <mat-form-field style="width: 100%" [hideRequiredMarker]="true">
    <mat-label *ngIf="!!label">{{label}}</mat-label>
    <div matPrefix>
      <ng-content select="[inputMaskedPrefix]"></ng-content>
    </div>
    <input
      matInput
      type="text"
      autocomplete="off"
      [name]="name"
      [placeholder]="placeholder"
      [ngModel]="displayValue"
      [showMaskTyped]="showMaskTyped"
      [thousandSeparator]="thousandSeparator"
      [prefix]="prefix"
      [placeHolderCharacter]="placeholderCharacter"
      [disabled]="control?.disabled"
      [dropSpecialCharacters]="dropSpecialCharacters"
      (ngModelChange)="onUserInputChange($event)"
      (blur)="onInputBlur()"
      [mask]="mask"
      [suffix]="suffix"
      [errorStateMatcher]="errorStateMatcher"
      tabindex="{{tabIndex}}"
    />
    <div matSuffix>
      <ng-content select="[inputMaskedSuffix]"></ng-content>
    </div>
    <mat-error *ngFor="let error of errors$ | async"> {{ error }} </mat-error>
  </mat-form-field>
</div>
