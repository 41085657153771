<div class="popup">
  <input
    id="image-input"
    #imageFileInput
    type="file"
    accept="image/png, image/jpeg"
    (change)="onImageFileChange($event)"
  />

  <div class="header">
    <span>{{popupTitle}}</span>
    <wp-icon-close class="header-close-btn" (click)="close()"> </wp-icon-close>
  </div>

  <div class="body">
    <div class="loading-container" *ngIf="isInitialLoading; else content">
      <wp-section-spinner [sectionHeight]="'100%'"></wp-section-spinner>
    </div>

    <ng-template #content>
      <ng-container *ngIf="correctedImage; else defaultEditTemplate">
        <wp-image-editor
          [imageBase64]="correctedImage.croppedBase64 || correctedImage.sourceBase64"
          [isOpenOnInit]="true"
          [maxWidth]="960"
          [maxHeight]="600"
          [zIndex]="20"
          (closed)="onCorrectImageClose()"
          (beforeClose)="onCorrectImageBeforeClose($event)"
          (imageChanged)="onCorrectImageChange()"
          (imageSaved)="onCorrectImageSave($event)"
        ></wp-image-editor>
      </ng-container>
      <ng-template #defaultEditTemplate>
        <div class="top-bar">
          <div
            class="category-container"
            *ngFor="let category of imageCategories; last as isLast"
          >
            <div class="category">
              <div class="category-title">{{category.title}}</div>
              <div class="thumbnails">
                <ng-container *ngFor="let thumbnail of category.images">
                  <div
                    class="thumbnail"
                    *ngIf="!thumbnail.isRemoved"
                    [ngClass]="{selected: thumbnail.imageId === this.selectedImage?.imageId}"
                    (click)="selectImage(thumbnail)"
                  >
                    <img
                      *ngIf="thumbnail.isImageData"
                      [src]="thumbnail.croppedBase64 || thumbnail.sourceBase64"
                      width="auto"
                      height="100%"
                      alt="thumbnail"
                    />
                  </div>
                </ng-container>

                <div
                  class="add-image-btn"
                  *ngIf="category.activeImageCount < category.maxImageCount"
                  (click)="addImage(category.categoryId)"
                >
                  <wp-portal-icon
                    class="add-image-icon"
                    [path]="addIcon"
                    [widthStr]="'22px'"
                    [heightStr]="'22px'"
                  ></wp-portal-icon>
                  <div>{{category.addBtnText}}</div>
                </div>
              </div>
            </div>

            <wp-text-divider-vertical-line
              *ngIf="!isLast"
              [height]="'80px'"
              [containerHeight]="'80px'"
              [margin]="'16px 32px 0 32px'"
            ></wp-text-divider-vertical-line>
          </div>
        </div>

        <div class="cropper-container">
          <div class="image-area">
            <div class="image-error" *ngIf="isImageLoadError">
              Failed to load the image
            </div>

            <div
              class="image-loading"
              [style.display]="isImageLoading ? null : 'none'"
            >
              Loading ...
            </div>

            <div
              [style.display]="(isImageLoading || isImageLoaded) ? 'none': null"
            >
              Upload an image using the controls above
            </div>

            <image-cropper
              [style.display]="isImageLoaded ? null : 'none'"
              [maintainAspectRatio]="false"
              [containWithinAspectRatio]="false"
              [roundCropper]="false"
              [alignImage]="'center'"
              [backgroundColor]="'#dfdfe3'"
              [format]="'jpeg'"
              [output]="'base64'"
              [imageBase64]="selectedImage?.sourceBase64"
              [canvasRotation]="selectedImage?.canvasRotate ?? 0"
              [imageChangedEvent]="imageFileChangeEvent"
              (imageLoaded)="onImageLoaded()"
              (loadImageFailed)="onLoadImageFailed()"
              (imageCropped)="onImageCropped($event)"
            >
            </image-cropper>
          </div>

          <wp-text-divider-vertical-line
            [containerHeight]="'284px'"
            [height]="'284px'"
            [margin]="'0 60px'"
          ></wp-text-divider-vertical-line>

          <div class="image-preview">
            <div class="image-preview-header">
              <span *ngIf="isImageLoaded; else noImage"> Image preview </span>
              <ng-template #noImage>
                <span>No image to preview </span>
              </ng-template>
            </div>

            <wp-display-photo
              [width]="'259px'"
              [height]="'175px'"
              [isRound]="false"
              [placeholderType]="popupRef.data.imagePlaceholder"
              [imageBase64]="selectedImage?.croppedBase64"
              [showFullImageWithinDimensions]="true"
              [placeholderWidth]="'210px'"
            >
            </wp-display-photo>
          </div>
        </div>

        <div class="buttons-panel">
          <ng-container *ngIf="selectedImage && !isImageLoading">
            <label for="image-input">
              <wp-text-formatted-as-link>
                <i class="update-btn-icon"></i>
                <span>Update image</span>
              </wp-text-formatted-as-link>
            </label>

            <div class="vertical-line"></div>

            <wp-link-btn (clicked)="rotateImage()">
              <i class="rotate-btn-icon"></i>
              <span>Rotate image</span>
            </wp-link-btn>

            <div class="vertical-line"></div>

            <wp-link-btn (clicked)="removeImage()">
              <i class="remove-btn-icon"></i>
              <span>Remove image</span>
            </wp-link-btn>

            <div class="vertical-line"></div>

            <wp-link-btn (clicked)="correctImage()">
              <i class="correct-btn-icon"></i>
              <span>Correct image</span>
            </wp-link-btn>
          </ng-container>
        </div>

        <div class="footer">
          <wp-btn-secondary
            [width]="'175px'"
            [disabled]="isImageLoading"
            (clicked)="close()"
            >Cancel
          </wp-btn-secondary>

          <wp-btn-primary
            class="save-btn"
            [width]="'175px'"
            [disabled]="isImageLoading"
            (clicked)="save()"
            >Save
          </wp-btn-primary>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
