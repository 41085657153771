import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Vendor, WpError } from '../../types';
import * as fromSelectors from '../selectors';
import * as fromActions from '../actions/vendor-data.actions';

@Injectable()
export class VendorDataFacade {
  constructor(private store: Store) {}

  public getVendor(vendorId: string): Observable<Vendor> {
    return this.store.select(fromSelectors.getVendor, { vendorId });
  }

  public getVendorLoading(vendorId: string): Observable<boolean> {
    return this.store.select(fromSelectors.getVendorLoading, { vendorId });
  }

  public getVendorError(vendorId: string): Observable<WpError> {
    return this.store.select(fromSelectors.getVendorError, { vendorId });
  }

  public getVendorErrorMessage(vendorId: string): Observable<string> {
    return this.store.select(fromSelectors.getVendorErrorMessage, { vendorId });
  }

  public getVendorName(vendorId: string): Observable<string> {
    return this.store.select(fromSelectors.getVendorName, { vendorId });
  }

  public onLoadVendorRequested(vendorId: string): void {
    this.store.dispatch(fromActions.loadVendorRequested({ vendorId }));
  }

  public onLazyLoadVendorRequested(vendorId: string): void {
    this.store.dispatch(fromActions.lazyLoadVendorRequested({ vendorId }));
  }
}
