import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  public transform(value: string[], separator = ''): string {
    if (!Array.isArray(value) || !value.length) {
      return '';
    }
    return value.join(separator);
  }
}
