/**
 * export const base64Encode = (string: string): string => {
 *   return Buffer.from(string).toString('base64');
 * };
 *
 * export const base64Decode = (key: string): string => {
 *   return Buffer.from(key, 'base64').toString();
 * };
 */

/**
 * It should polyfill the Buffer class for the browser. Only from base64 to string and vice versa.
 */
export class BufferPolyfill {
  static from(value: string, type: 'base64' | 'str' = 'str'): BufferPolyfill {
    return new BufferPolyfill(value, type);
  }

  constructor(
    private value: string,
    private type?: 'base64' | 'str',
  ) {}

  toString(type: 'base64' | 'str' = 'str'): string {
    if (this.type === type) {
      return this.value;
      // base64 to string in browser
    } else if (this.type === 'base64') {
      return atob(this.value);
    } else {
      // string to base64 in browser
      return btoa(this.value);
    }
  }
}
