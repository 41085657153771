import { createReducer, on } from '@ngrx/store';

import * as fromActions from '../actions/student-change-log.actions';
import { EntityState } from '@rootTypes';
import {
  createEntityState,
  getProcessedByUserDisplayFromChangeRequestSummary,
  getRequestedByUserDisplayFromChangeRequestSummary,
  studentChangeRequestStatusToDisplay,
  studentChangeRequestTypeToDisplay,
} from '@rootTypes/utils';
import { StudentChangeRequestStatus } from '@apiEntities';

export interface StudentChangeLogState {
  studentId?: string;
  logs: EntityState<StudentChangeLogItem[]>;
}

export interface StudentChangeLogItem {
  studentChangeRequestId: string;
  status: StudentChangeRequestStatus;
  statusDisplay: string;
  typeDisplay: string;
  requestedByUserDisplay: string;
  requestedTimestamp: number;
  reviewedByUserDisplay?: string;
  reviewedTimestamp?: number;
}

const createInitialStudentChangeLogState = (): StudentChangeLogState => {
  return {
    logs: { isLoading: false },
  };
};

export const studentChangeLogReducer = createReducer(
  createInitialStudentChangeLogState(),
  on(fromActions.destroyStudentChangeLog, createInitialStudentChangeLogState),
  on(fromActions.initStudentChangeLog, (state, { studentId }): StudentChangeLogState => {
    return {
      ...state,
      studentId,
    };
  }),
  on(fromActions.loadStudentChangeLogRequested, (state): StudentChangeLogState => {
    return {
      ...state,
      logs: { isLoading: true },
    };
  }),
  on(fromActions.loadStudentChangeLogSuccess, (state, { response }): StudentChangeLogState => {
    const logs: StudentChangeLogItem[] = response.map((item) => {
      const { studentChangeRequestId, status } = item;
      return {
        studentChangeRequestId,
        status,
        statusDisplay: studentChangeRequestStatusToDisplay(status),
        typeDisplay: studentChangeRequestTypeToDisplay(item.type),
        requestedByUserDisplay: getRequestedByUserDisplayFromChangeRequestSummary(item),
        requestedTimestamp: item.requested.timestamp,
        reviewedByUserDisplay: getProcessedByUserDisplayFromChangeRequestSummary(item),
        reviewedTimestamp: item.reviewed?.timestamp,
      };
    });
    return {
      ...state,
      logs: createEntityState(logs),
    };
  }),
  on(fromActions.loadStudentChangeLogFailed, (state, { error }): StudentChangeLogState => {
    return {
      ...state,
      logs: createEntityState(undefined, error),
    };
  }),
);
