import { createReducer, on } from '@ngrx/store';

import * as fromActions from '../actions/student-change-request-processed-details.actions';
import {
  EntityState,
  createEntityState,
  StudentChangeRequestReviewFormRow,
  GetStudentChangeRequestSummaryResponse,
  ProcessedStudentChangeRequestField,
  StudentChangeRequestStatus,
} from '../../dependencies';

export interface StudentChangeRequestProcessedDetailsState {
  studentChangeRequestId?: string;
  requestData: EntityState<StudentChangeRequestProcessedDetailsData>;
  requestSummary: EntityState<GetStudentChangeRequestSummaryResponse>;
}

export interface StudentChangeRequestProcessedDetailsData {
  deleteEntityLabel?: string;
  status: StudentChangeRequestStatus;
  formLabel: string;
  formRows: StudentChangeRequestReviewFormRow[];
}

export const createInitialStudentChangeRequestProcessedDetailsState = (): StudentChangeRequestProcessedDetailsState => {
  return {
    requestData: createEntityState(),
    requestSummary: createEntityState(),
  };
};

export const studentChangeRequestProcessedDetailsReducer = createReducer(
  createInitialStudentChangeRequestProcessedDetailsState(),
  on(fromActions.destroyStudentChangeRequestProcessedDetails, createInitialStudentChangeRequestProcessedDetailsState),
  on(
    fromActions.initStudentChangeRequestProcessedDetails,
    (state, { studentChangeRequestId }): StudentChangeRequestProcessedDetailsState => {
      return {
        ...state,
        studentChangeRequestId,
      };
    },
  ),
  on(
    fromActions.loadProcessedStudentChangeRequestSummaryRequested,
    (state): StudentChangeRequestProcessedDetailsState => {
      return {
        ...state,
        requestSummary: {
          isLoading: true,
        },
      };
    },
  ),
  on(
    fromActions.loadProcessedStudentChangeRequestSummarySuccess,
    (state, { response }): StudentChangeRequestProcessedDetailsState => {
      return {
        ...state,
        requestSummary: createEntityState(response),
      };
    },
  ),
  on(
    fromActions.loadProcessedStudentChangeRequestSummaryFailed,
    (state, { error }): StudentChangeRequestProcessedDetailsState => {
      return {
        ...state,
        requestSummary: createEntityState(undefined, error),
      };
    },
  ),
  on(fromActions.loadProcessedStudentChangeRequestRequested, (state): StudentChangeRequestProcessedDetailsState => {
    return {
      ...state,
      requestData: {
        isLoading: true,
      },
    };
  }),
  on(
    fromActions.loadProcessedStudentChangeRequestSuccess,
    (state, { response }): StudentChangeRequestProcessedDetailsState => {
      const revisedData: { [key: string]: ProcessedStudentChangeRequestField } = {};
      response.to.forEach((field) => {
        revisedData[field.name] = field;
      });

      const formRows: StudentChangeRequestReviewFormRow[] = response.from.map((existingField) => {
        const revisedField = revisedData[existingField.name];
        let isKeepExistingDataSelected: boolean | undefined;
        if (existingField.isSelected) {
          isKeepExistingDataSelected = true;
        } else if (revisedField.isSelected) {
          isKeepExistingDataSelected = false;
        }
        return {
          districtId: response.districtId,
          label: existingField.label || existingField.name,
          fieldName: existingField.name,
          isOptional: false,
          isKeepExistingDataSelected,
          existingData: existingField.value,
          revisedDataCurrent: revisedField.value,
        };
      });
      const { status, label, deleteEntityLabel } = response;
      const data: StudentChangeRequestProcessedDetailsData = {
        status,
        formLabel: label || response.type,
        deleteEntityLabel,
        formRows,
      };

      return {
        ...state,
        requestData: createEntityState(data),
      };
    },
  ),
  on(
    fromActions.loadProcessedStudentChangeRequestFailed,
    (state, { error }): StudentChangeRequestProcessedDetailsState => {
      return {
        ...state,
        requestData: createEntityState(undefined, error),
      };
    },
  ),
);
