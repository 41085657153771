import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-icon-alert-critical',
  templateUrl: './icon-alert-critical.component.html',
  styleUrls: ['./icon-alert-critical.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconAlertCriticalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
