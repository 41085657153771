<div
  class="wp-common-popup-page"
  tabindex="0"
  role="dialog"
  [ngStyle]="{padding: padding}"
  [ngClass]="{ dark: isDarkMode }"
  #container
>
  <div class="wp-common-popup-page__top">
    <ng-content select="wp-common-popup-page-top"></ng-content>
  </div>
  <div class="wp-common-popup-page__body">
    <ng-content select="wp-common-popup-page-body"></ng-content>
  </div>
</div>
