import { createReducer, on } from '@ngrx/store';

import { cleanUpStore, createEntityState, YardDataState } from '../../types';
import * as fromActions from '../actions';

export const createInitialYardState = (): YardDataState => {
  return {
    yards: {},
    vendorIdsByYards: {},
  };
};

export const yardDataReducer = createReducer<YardDataState>(
  createInitialYardState(),
  on(cleanUpStore, createInitialYardState),
  // load yard
  on(fromActions.loadYardRequested, (state, action): YardDataState => {
    const { yardId } = action;
    const yardEntityState = state.yards[yardId] || createEntityState();
    return {
      ...state,
      yards: {
        ...state.yards,
        [yardId]: {
          ...yardEntityState,
          isLoading: true,
          error: undefined,
        },
      },
    };
  }),
  on(fromActions.loadYardSuccess, (state, action): YardDataState => {
    const { yard, vendor, yardId } = action;
    let resultState: YardDataState = {
      ...state,
      yards: {
        ...state.yards,
        [yardId]: createEntityState(yard),
      },
    };
    if (vendor) {
      const vendorId = vendor.id;
      resultState = {
        ...resultState,
        vendorIdsByYards: {
          ...resultState.vendorIdsByYards,
          [yardId]: {
            vendorId,
          },
        },
      };
    }
    return resultState;
  }),
  on(fromActions.loadYardFailed, (state, action): YardDataState => {
    const { yardId, error } = action;
    return {
      ...state,
      yards: {
        ...state.yards,
        [yardId]: createEntityState(undefined, error),
      },
    };
  }),
);
