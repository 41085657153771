import { EmployeePermission, PermissionItem } from '../../entities/employees';
import { Diff, getDiff, isAdditionChange, isRemovalChange } from '../common/get-diff';

export interface GenericUpdatePermissionRequest {
  added?: EmployeePermission[];
  removed?: string[];
  // updated?: { // in V2
  //   [entityId: string]: {
  //     [key in PermissionEntities]?: PermissionType
  //   }
  // }
}

export const getGenericUpdatePermissionRequest = (
  initial: EmployeePermission[],
  target: EmployeePermission[],
): GenericUpdatePermissionRequest => {
  const initialCompare = getCompareObject(initial);
  const targetCompare = getCompareObject(target);
  const diffs = getDiff(initialCompare, targetCompare);
  const request = getRequestFromDiffs(diffs, target);
  return request;
};

interface PermissionCompareObject {
  [entityId: string]: {
    permission?: PermissionItem;
  };
}

function getCompareObject(permissions: EmployeePermission[] = []): PermissionCompareObject {
  return permissions.reduce((acc, curr) => {
    return { ...acc, [curr.entityId]: { permission: curr.permission } };
  }, {});
}

function getRequestFromDiffs(
  diffs: Diff<any>[],
  targetPermissions: EmployeePermission[],
): GenericUpdatePermissionRequest {
  if (!(diffs && diffs.length)) {
    return {};
  }
  const request = {} as GenericUpdatePermissionRequest;
  diffs.forEach((item) => {
    if (isAdditionChange(item)) {
      request.added = request.added || [];
      const addedId = item.path[0];
      const addedPermission = targetPermissions.find((p) => p.entityId === addedId);
      request.added.push(addedPermission);
    }
    if (isRemovalChange(item)) {
      request.removed = request.removed || [];
      const removedId = item.path[0];
      request.removed.push(removedId);
    }
  });
  return request;
}
