import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { getRoutingRequest } from '@rootTypes/utils/router/get-routing-request';
import { InspectionsTab } from '@rootTypes/entities/inspections/inspections-tab';
import { getNthUrlSegment } from '@rootTypes/utils/common/get-nth-url-segment';
import { inspectionsRootPath } from './inspections-path';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';
import { Injectable } from '@angular/core';
import { DateRange } from '@rootTypes';

export interface InspectionsRouteData {
  tabId?: InspectionsTab;
  dateRange?: DateRange;
}

export const inspectionsRoute: PortalRoute<InspectionsRouteData> = {
  path: inspectionsRootPath,
  isOnRoute: (state: RouterStateUrl) => {
    throw new Error('Not implemented');
  },
  parse: (state: RouterStateUrl): InspectionsRouteData => {
    let res = {
      tabId: getNthUrlSegment(state.url, 2) as InspectionsTab,
    } as InspectionsRouteData;
    if (state.queryParams?.dateRange?.length) {
      const [startDate, endDate] = state.queryParams.dateRange.split(',') as [string, string];
      res = {
        ...res,
        dateRange: { startDate, endDate },
      };
    }
    return res;
  },
  request: (
    data: InspectionsRouteData,
    queryParamStrategy: QueryParamsHandlingStrategy,
    replaceUrl: boolean,
  ): NavigationRequest => {
    // do not mutate the original arg
    const copy: any = { ...data };
    copy.tabId = copy.tabId ?? InspectionsTab.DEFAULT;
    if (copy.dateRange) {
      copy.dateRange = `${copy.dateRange.startDate},${copy.dateRange.endDate}`;
    }
    const path = [inspectionsRootPath, ':tabId'];
    return getRoutingRequest(path, copy, queryParamStrategy, replaceUrl);
  },
  getHref(data?: InspectionsRouteData): string {
    return data?.tabId ? `/${inspectionsRootPath}/${data.tabId}` : `/${inspectionsRootPath}`;
  },
};

@Injectable({ providedIn: 'root' })
export class InspectionsRouterService extends PortalRouterService<InspectionsRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route = inspectionsRoute;
}
