import { Weekday } from '../common';

export enum DriverType {
  ZUMER = 'ZUMER',
  VENDOR = 'VENDOR',
}

export interface DriverByWeekday {
  type: DriverType;
  vendor?: {
    vendorId: string;
    driverId?: string;
    contractId?: string;
  };
  zumer?: {
    defaultDriverId?: string;
    drivers?: {
      [driverId: string]: {
        driverId: string;
      };
    };
    driverPayCents?: number;
  };
}

export type DriverByWeekdayMap = {
  [key in Weekday]: DriverByWeekday;
};
