import { createReducer, on } from '@ngrx/store';
import * as fromActions from '../../actions/campus-details/calendars-tab.actions';

export interface CalendarsTabState {
  calendarsExpanded: {
    [calendarId: string]: boolean;
  };
  holidaysExpanded: {
    [calendarId: string]: boolean;
  };
}

export const initialCalendarsTabState: CalendarsTabState = {
  calendarsExpanded: {},
  holidaysExpanded: {},
};

export const calendarTabReducer = createReducer<CalendarsTabState>(
  { ...initialCalendarsTabState },
  on(fromActions.toggleCalendarExpanded, (state, action) => {
    const { calendarId } = action;
    const prevExpanded = !!state.calendarsExpanded[calendarId];
    return {
      ...state,
      calendarsExpanded: {
        ...state.calendarsExpanded,
        [calendarId]: !prevExpanded,
      },
    };
  }),
  on(fromActions.toggleHolidaysExpanded, (state, action) => {
    const { calendarId } = action;
    const prevExpanded = !!state.holidaysExpanded[calendarId];
    return {
      ...state,
      holidaysExpanded: {
        ...state.holidaysExpanded,
        [calendarId]: !prevExpanded,
      },
    };
  }),
);
