import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { State } from '@rootStore';
import { Store } from '@ngrx/store';
import * as fromActions from '../../../store/actions';
import * as fromSelectors from '../../../store/selectors';
import * as fromAuthSelectors from '../../../auth/store/selectors';
import { Observable } from 'rxjs';
import { PlaceholderType } from 'src/app/shared/photos/types';
import { PopupableService } from '../../../core/popupable/popupable.service';
import { SchoolCampusAccountSelectPopupComponent } from '../school-campus-account-select-popup/school-campus-account-select-popup.component';
import { PortalEntityType } from '@rootTypes';
import { take } from 'rxjs/operators';
import { RouteExplorerRouterService } from '../../../router/portal-routes/route-explorer';
import { AccountsFacade } from '../../../auth/store';
import { PortalPermissionsFacade } from '../../../auth/store/facades/portal-permissions.facade';
import { SideMenuService, SideMenuItem } from '../../side-menu.service';
import { RouteManagementPersistenceService } from '../../../features/route-explorer/store/services/route-management-persistence.service';
import { iconPaths } from '@rootTypes/utils';

@Component({
  selector: 'wp-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuComponent implements OnInit {
  public tabs: SideMenuItem[];
  public selectedSection$: Observable<string>;
  public sideBarExpanded$: Observable<boolean>;
  public placeholderTypes = PlaceholderType;
  public accountShown$: Observable<boolean>;
  public accountName$: Observable<string | undefined>;
  public accountImagePath$: Observable<string | undefined>;
  public isFeatureRouteManagement$: Observable<boolean>;
  public isSettingsPermissionView$: Observable<boolean>;
  public settingsIcon = iconPaths.SETTINGS_GREEN;
  private readonly settingsLink = 'settings/sso/list';

  constructor(
    private store: Store<State>,
    private popupService: PopupableService,
    private routeExplorerRoute: RouteExplorerRouterService,
    private accountFacade: AccountsFacade,
    private permissionsFacade: PortalPermissionsFacade,
    private router: Router,
    private sideMenuService: SideMenuService,
    private routeManagementPersistence: RouteManagementPersistenceService,
  ) {}

  ngOnInit(): void {
    this.accountShown$ = this.store.select(fromAuthSelectors.isSelectedAccountShown);
    this.accountName$ = this.store.select(fromAuthSelectors.selectedAccountName);
    this.accountImagePath$ = this.store.select(fromAuthSelectors.selectedAccountImagePath);
    this.isFeatureRouteManagement$ = this.permissionsFacade.isRouteExplorerView$();
    this.isSettingsPermissionView$ = this.permissionsFacade.ssoSettingsView$();
    this.selectedSection$ = this.store.select(fromSelectors.currentSideBarSection);
    this.sideBarExpanded$ = this.store.select(fromSelectors.sidebarExpanded);
    this.tabs = this.sideMenuService.getSideMenuItems();
  }

  public async onSideBarItemClick(item: SideMenuItem): Promise<void> {
    const link = await item.link$.pipe(take(1)).toPromise();
    await this.router.navigateByUrl(link);
  }

  public onSettingsItemClick(): void {
    this.router.navigateByUrl(this.settingsLink);
  }

  public onRouteManagementMenuClick(): void {
    if (wpEnvironment.userRole === 'admin' || wpEnvironment.userRole === 'vendor') {
      const preSelectedDistrictId = this.routeManagementPersistence.getState()?.districtId;
      this.popupService
        .openPopup<any>(SchoolCampusAccountSelectPopupComponent, {
          preSelectDistrictId: preSelectedDistrictId,
          preSelectedHint: 'Your last visit',
        })
        .pipe(take(1))
        .subscribe((result) => {
          if (!result) {
            return;
          }
          if (result.type === PortalEntityType.DISTRICT) {
            const districtId = result.entityId;
            return this.routeExplorerRoute.navigate({ districtId });
          }
        });
    } else {
      this.accountFacade
        .getSelectedAccountId()
        .pipe(take(1))
        .subscribe((accountId) => {
          if (accountId) {
            this.routeExplorerRoute.navigate({ districtId: accountId });
          }
        });
    }
  }

  public onSidebarExpandedChanged(): void {
    this.store.dispatch(fromActions.sideBarExpandToggled());
  }
}
