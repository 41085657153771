<div class="switch-account-popup">
  <div class="switch-account-popup__header">
    <div class="close-btn">
      <wp-icon-close (click)="onClosed()"> </wp-icon-close>
    </div>
  </div>
  <div class="switch-account-popup__content">
    <div class="photo">
      <wp-display-photo
        [width]="'80px'"
        [height]="'80px'"
        [isRound]="true"
        [imageBase64]="userPhotoBase64$ | async"
        [placeholderAbbreviation]="userInitials$ | async"
        [placeholderAbbreviationFontSize]="'20px'"
      >
      </wp-display-photo>
    </div>
    <wp-title-one>
      <div class="user-name">{{userName$ | async}}</div>
    </wp-title-one>

    <ng-container
      *ngIf="(!!(accounts$ | async) && initialAccountId); else accountsLoading"
    >
      <wp-select-account-form
        [accounts]="accounts$ | async"
        [isSubmitLoading]="isSwitchAccountLoading$ | async"
        [isSubAccountRequired]="isSubAccountRequired"
        [initialAccountId]="initialAccountId"
        [initialSubAccountId]="initialSubAccountId"
        [parentSelector]="'.switch-account-popup'"
        (request)="switchAccount($event)"
      ></wp-select-account-form>
    </ng-container>

    <ng-template #accountsLoading>
      <wp-section-spinner [sectionHeight]="'150px'"> </wp-section-spinner>
    </ng-template>
  </div>
</div>
