<div
  class="wp-server-form-field-display-value"
  [ngStyle]="{fontSize: fontSize, fontWeight: fontWeight}"
  [ngClass]="{ 'display-block': isEmailFormField }"
>
  <ng-container [ngSwitch]="formField?.type">
    <!--    Image field-->
    <ng-template [ngSwitchCase]="fieldTypes.IMAGE">
      <wp-display-photo
        [imagePath]="imageFieldPath"
        [isRound]="true"
      ></wp-display-photo>
    </ng-template>
    <!--    Email field-->
    <ng-template [ngSwitchCase]="fieldTypes.EMAIL">
      <wp-ellipsis [tooltipText]="displayValue" *ngIf="isEmailFormField"
        >{{displayValue}}</wp-ellipsis
      >
    </ng-template>
    <!--    String field-->
    <ng-template [ngSwitchCase]="fieldTypes.STRING">
      <ng-container *ngIf="isMultiLineString; else singleLineStringTemplate">
        <div class="multiline-el">{{displayValue}}</div>
      </ng-container>
      <ng-template #singleLineStringTemplate>
        <div class="display-field-value">{{displayValue}}</div>
      </ng-template>
    </ng-template>
    <!--    Address with exact location-->
    <ng-template [ngSwitchCase]="fieldTypes.ADDRESS_LOCATION">
      <wp-display-address-with-exact-location
        [value]="addressWithExactLocation"
      ></wp-display-address-with-exact-location>
    </ng-template>
    <!--    Home address-->
    <ng-template [ngSwitchCase]="fieldTypes.HOME_ADDRESS">
      <span *ngIf="homeAddress?.address">
        {{homeAddress.address.formatted_address}}
      </span>
      <span *ngIf="homeAddress?.isProtectedAddress"
        >Home address is protected</span
      >
      <span *ngIf="homeAddress?.isAddressNotKnown"
        >Home address is not known</span
      >
    </ng-template>
    <!--    Default-->
    <ng-container *ngSwitchDefault>
      <div class="display-field-value">{{displayValue}}</div>
    </ng-container>
  </ng-container>
</div>
