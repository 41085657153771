import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject, switchMap } from 'rxjs';
import * as fromTypes from './types';
import { RangePickerFixedRangeConfig, RangePickerState } from './types';
import { RangePickerDay } from './types/entities/range-picker-day';
import { distinctUntilChanged, map, shareReplay, take } from 'rxjs/operators';
import { DateRange, YYYYMMDDString } from '@rootTypes';
import { getWeekSpanForDate } from '@rootTypes/utils/common/date/get-current-week-span';
import {
  dateAddDay,
  dateAddMonth,
  dateClone,
  dateEndOfMonth,
  dateEndOfWeek,
  dateFormat,
  dateGetWeekday,
  dateIsAfter,
  dateIsBefore,
  dateIsSameOrBefore,
  datesGetSpan,
  datesIsSame,
  dateStartOfMonth,
  dateStartOfWeek,
  dateSubtractDay,
  dateSubtractMonth,
  dateToYYYYMMDD,
  FormattingTokens,
  yyyymmddToDate,
} from '@rootTypes/utils/common/date-time-fns';
import { DayConfigs } from '../multi-date-picker/types';

@Injectable()
export class DateRangePickerService implements fromTypes.RangePickerStore {
  private hoveredDate$!: Observable<YYYYMMDDString | null>;
  private hoveredDateRange$: Observable<YYYYMMDDString[] | null>;
  private startDateSelected$!: Observable<YYYYMMDDString | null>;
  private endDateSelected$!: Observable<YYYYMMDDString | null>;
  private userSelectedDateRange$$ = new Subject<DateRange>();

  private state$: BehaviorSubject<fromTypes.RangePickerState> = new BehaviorSubject<fromTypes.RangePickerState>(
    fromTypes.initialRangePickerStore,
  );

  constructor() {
    this.hoveredDate$ = this.state$.asObservable().pipe(
      map((state) => state.hovered),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.date),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.startDateSelected$ = this.state$.asObservable().pipe(
      map((state) => state.selected),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.startDate),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.endDateSelected$ = this.state$.asObservable().pipe(
      map((state) => state.selected),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.endDate),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.hoveredDateRange$ = this.state$.asObservable().pipe(
      map((s) => s.config),
      distinctUntilChanged((p: fromTypes.RangePickerState['config'], c: fromTypes.RangePickerState['config']) => {
        return p.updatedAt === c.updatedAt;
      }),
      switchMap((config) => {
        if (!config.entity.fixedRange) {
          return of(null);
        } else {
          return this.getHoveredFixedRange(config.entity.fixedRange);
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
  selectedChanged$(): Observable<{
    startDate: YYYYMMDDString | null;
    endDate: YYYYMMDDString | null;
  }> {
    return this.state$.asObservable().pipe(
      map((state) => state.selected),
      distinctUntilChanged(
        (prev: fromTypes.RangePickerState['selected'], curr: fromTypes.RangePickerState['selected']) =>
          prev.updatedAt === curr.updatedAt,
      ),
    );
  }

  setDayConfigs(dayConfigs: DayConfigs): void {
    const prevState = this.state$.value;
    this.state$.next({
      ...prevState,
      dayConfigs: {
        entity: dayConfigs,
        updatedAt: new Date().getTime(),
      },
    });
  }

  dayClicked(day: fromTypes.RangePickerDay): void {
    if (!this.isMomentWithinAllowedRange(day.moment)) {
      return;
    }
    const state = this.state$.value;
    if (state.config.entity.fixedRange) {
      this.hoveredDateRange$.pipe(take(1)).subscribe((hoveredFixedRange) => {
        const start = hoveredFixedRange?.length ? hoveredFixedRange[0] : null;
        const end = hoveredFixedRange?.length ? hoveredFixedRange[hoveredFixedRange.length - 1] : null;
        this.state$.next({
          ...state,
          selected: {
            ...state.selected,
            startDate: start,
            endDate: end,
            updatedAt: new Date().getTime(),
          },
        });
        this.userSelectedDateRange$$.next({
          startDate: start,
          endDate: end,
        });
      });
      return;
    }
    const prevStart = state.selected.startDate;
    const prevEnd = state.selected.endDate;
    const clicked = day.moment;
    const prevStartDate = prevStart ? yyyymmddToDate(prevStart) : null;
    const prevEndDate = prevEnd ? yyyymmddToDate(prevEnd) : null;
    const clickedDate = yyyymmddToDate(clicked);
    const newState: {
      startDate: YYYYMMDDString | null;
      endDate: YYYYMMDDString | null;
    } = { startDate: null, endDate: null };
    if (!prevStartDate && !prevEndDate) {
      newState.startDate = clicked;
    }
    if (prevStartDate && !prevEndDate) {
      if (dateIsAfter(clickedDate, prevStartDate, 'day')) {
        newState.startDate = prevStart;
        newState.endDate = clicked;
      } else if (dateIsBefore(clickedDate, prevStartDate, 'day')) {
        newState.startDate = clicked;
        newState.endDate = prevStart;
      }
    }
    if (prevStartDate && prevEndDate) {
      // reset range
      newState.startDate = clicked;
    }
    this.state$.next({
      ...state,
      selected: {
        ...state.selected,
        startDate: newState.startDate,
        endDate: newState.endDate,
        updatedAt: new Date().getTime(),
      },
    });
    if (newState.startDate && newState.endDate) {
      this.userSelectedDateRange$$.next({
        startDate: newState.startDate,
        endDate: newState.endDate,
      });
    } else if (newState.startDate) {
      this.userSelectedDateRange$$.next(undefined);
      this.userSelectedDateRange$$.next({
        startDate: newState.startDate,
        endDate: newState.startDate,
      });
    } else {
      this.userSelectedDateRange$$.next(undefined);
    }
  }

  weekdays$(): Observable<fromTypes.RangePickerWeekday[]> {
    const isStartMonday$ = this.state$.pipe(
      map((state) => state.config.entity.isStartWeekFromMonday),
      distinctUntilChanged(),
    );
    return isStartMonday$.pipe(
      map((isStartMonday) => {
        return getWeekSpanForDate(new Date(), isStartMonday).map((weekdayMom) => {
          return {
            date: weekdayMom,
            label: fromTypes.utils.getMomentWeekdayLabel(weekdayMom),
          };
        });
      }),
    );
  }

  public forward(): void {
    const state = this.state$.value;
    const currentMonth = state.currentView.date;
    const currentMonthDate = yyyymmddToDate(currentMonth);
    const nextMonth = dateAddMonth(currentMonthDate, 1);
    this.state$.next({
      ...state,
      currentView: {
        ...state.currentView,
        date: dateToYYYYMMDD(nextMonth),
        updatedAt: new Date().getTime(),
      },
    });
  }
  public backward(): void {
    const state = this.state$.value;
    const currentMonth = state.currentView.date;
    const currentMonthDate = yyyymmddToDate(currentMonth);
    const prevMonth = dateSubtractMonth(currentMonthDate, 1);
    this.state$.next({
      ...state,
      currentView: {
        ...state.currentView,
        date: dateToYYYYMMDD(prevMonth),
        updatedAt: new Date().getTime(),
      },
    });
  }
  public months$(): Observable<fromTypes.RangePickerMonth[]> {
    const numMonthsDisplayed$ = this.state$.asObservable().pipe(
      map((state) => state.config),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.entity.numMonthsDisplayed),
    );
    const currentMonth$ = this.state$.asObservable().pipe(
      map((state) => state.currentView),
      distinctUntilChanged((prev, curr) => prev.updatedAt === curr.updatedAt),
      map((state) => state.date),
    );
    return combineLatest([currentMonth$, numMonthsDisplayed$]).pipe(
      map(([currMonth, numDisplayed]) => {
        const result = [] as fromTypes.RangePickerMonth[];
        const currMonthDate = yyyymmddToDate(currMonth);
        for (let i = 0; i < numDisplayed; i++) {
          const m = dateAddMonth(currMonthDate, i);
          result.push({
            moment: m,
            label: fromTypes.utils.getMomentMonthLabel(m, true),
          });
        }
        return result;
      }),
    );
  }

  public daysForMonth$(month: YYYYMMDDString): Observable<RangePickerDay[]> {
    const monthDate = yyyymmddToDate(month);
    const startOfMonth = dateStartOfMonth(monthDate);
    const endOfMonth = dateEndOfMonth(monthDate);
    const isStartMonday$ = this.state$.pipe(
      map((state) => state.config.entity.isStartWeekFromMonday),
      distinctUntilChanged(),
    );
    return isStartMonday$.pipe(
      map((isStartMonday) => {
        const startOfWeek = dateStartOfWeek(startOfMonth, isStartMonday);
        const endOfWeek = dateEndOfWeek(endOfMonth, isStartMonday);
        const result = [] as RangePickerDay[];
        for (let m = dateClone(startOfWeek); dateIsSameOrBefore(m, endOfWeek, 'day'); m = dateAddDay(m, 1)) {
          const curr = dateToYYYYMMDD(m);
          const day = {
            moment: curr,
            isDisplayed: datesIsSame(m, monthDate, 'month'),
            label: dateFormat(m, FormattingTokens.DAY),
          } as RangePickerDay;
          result.push(day);
        }
        return result;
      }),
    );
  }

  public dayChanges$(day: fromTypes.RangePickerDay): Observable<RangePickerDay> {
    return combineLatest([
      this.hoveredDate$,
      this.startDateSelected$,
      this.endDateSelected$,
      this.hoveredDateRange$,
    ]).pipe(
      map(([hovered, start, end, hoveredFixedRange]) => {
        return {
          ...day,
          css: hoveredFixedRange
            ? this.getCSSForFixedRangeSelect(day.moment, start, end, hoveredFixedRange)
            : this.getCssForDate(day.moment, hovered, start, end),
        };
      }),
    );
  }

  public dateRangeSelectedByUser(): Observable<DateRange> {
    return this.userSelectedDateRange$$.asObservable();
  }

  public setCurrentMonth(m: YYYYMMDDString): void {
    this.state$.next({
      ...this.state$.value,
      currentView: {
        ...this.state$.value.currentView,
        date: m,
        updatedAt: new Date().getTime(),
      },
    });
  }

  public setHovered(m: YYYYMMDDString | null): void {
    const state = this.state$.value;
    this.state$.next({
      ...state,
      hovered: {
        ...state.hovered,
        date: m,
        updatedAt: new Date().getTime(),
      },
    });
  }

  public setStartWeekFromMonday(isStartFromMonday: boolean): void {
    const state = this.state$.value;
    const newState: RangePickerState = {
      ...state,
      config: {
        ...state.config,
        entity: {
          ...state.config.entity,
          isStartWeekFromMonday: isStartFromMonday,
        },
      },
    };
    this.state$.next(newState);
  }

  public setStartDate(m: YYYYMMDDString | null): void {
    const state = this.state$.value;
    const newState = {
      ...state,
      selected: {
        ...state.selected,
        startDate: m,
        updatedAt: new Date().getTime(),
      },
    };
    this.state$.next(newState);
  }

  public setEndDate(m: YYYYMMDDString | null): void {
    const state = this.state$.value;
    this.state$.next({
      ...state,
      selected: {
        ...state.selected,
        endDate: m,
        updatedAt: new Date().getTime(),
      },
    });
  }

  public setMonthView(month: number): void {
    const state = this.state$.value;
    this.state$.next({
      ...state,
      config: {
        ...state.config,
        entity: { ...state.config.entity, numMonthsDisplayed: month },
      },
    });
  }

  public setFixedRangeConfig(config: RangePickerFixedRangeConfig | null): void {
    const state = this.state$.value;
    this.state$.next({
      ...state,
      config: {
        ...state.config,
        entity: { ...state.config.entity, fixedRange: config },
        updatedAt: new Date().getTime(),
      },
    });
  }

  public setMaxRangeLength(maxLen: number | undefined): void {
    const state = this.state$.value;
    this.state$.next({
      ...state,
      config: {
        ...state.config,
        entity: { ...state.config.entity, maxRangeLength: maxLen },
        updatedAt: new Date().getTime(),
      },
    });
  }

  public setDisableDatesBefore(mom: YYYYMMDDString): void {
    const state = this.state$.value;
    this.state$.next({
      ...state,
      config: {
        ...state.config,
        entity: { ...state.config.entity, disableDatesBefore: mom },
        updatedAt: new Date().getTime(),
      },
    });
  }

  public setDisableDatesAfter(mom: YYYYMMDDString): void {
    const state = this.state$.value;
    this.state$.next({
      ...state,
      config: {
        ...state.config,
        entity: { ...state.config.entity, disableDatesAfter: mom },
        updatedAt: new Date().getTime(),
      },
    });
  }

  public setReadonly(): void {
    const state = this.state$.value;
    this.state$.next({
      ...state,
      config: {
        ...state.config,
        readonly: true,
      },
    });
  }

  public initStore(): void {
    this.state$.next(fromTypes.initialRangePickerStore);
  }

  public isReadOnly(): Observable<boolean> {
    return this.state$.asObservable().pipe(map((state) => state.config.readonly));
  }

  private getCssForDate(
    current: YYYYMMDDString,
    hovered: YYYYMMDDString | null,
    start: YYYYMMDDString | null,
    end: YYYYMMDDString | null,
  ): string {
    let css = '';
    const isWithinAllowedRange = this.isMomentWithinAllowedRange(current);
    if (!isWithinAllowedRange) {
      css += 'disabled';
    }
    const currentDate = yyyymmddToDate(current);
    const hoveredDate = hovered ? yyyymmddToDate(hovered) : null;
    const startDate = start ? yyyymmddToDate(start) : null;
    if (start && datesIsSame(currentDate, startDate, 'day')) {
      css += ' selected';
    }
    const endDate = end ? yyyymmddToDate(end) : null;
    if (end && datesIsSame(endDate, currentDate, 'day')) {
      css += ' selected';
    }
    const today = new Date();
    if (datesIsSame(currentDate, today, 'day')) {
      css += ' today';
    }
    if (isWithinAllowedRange && hovered && datesIsSame(currentDate, hoveredDate, 'day')) {
      css += ' hovered';
    }
    if (isWithinAllowedRange && start && (end || hovered)) {
      if (dateIsAfter(currentDate, startDate) && dateIsBefore(currentDate, endDate || hoveredDate)) {
        css += ' in-range';
      } else if (
        !end &&
        hovered &&
        start &&
        dateIsAfter(currentDate, hoveredDate) &&
        dateIsBefore(currentDate, startDate)
      ) {
        css += ' in-range';
      }
    }
    return css;
  }

  private getCSSForFixedRangeSelect(
    current: YYYYMMDDString,
    start: YYYYMMDDString | null,
    end: YYYYMMDDString | null,
    hoveredFixedRange: YYYYMMDDString[],
  ): string {
    let css = '';
    const isWithinAllowedRange = this.isMomentWithinAllowedRange(current);
    if (!isWithinAllowedRange) {
      css += 'disabled';
    }
    if (current === start || current === end) {
      css += ' selected';
    }
    const currentDate = yyyymmddToDate(current);
    const today = new Date();
    if (datesIsSame(currentDate, today, 'day')) {
      css += ' today';
    }
    const startDate = start ? yyyymmddToDate(start) : null;
    const endDate = end ? yyyymmddToDate(end) : null;
    if (isWithinAllowedRange && start && end) {
      if (dateIsAfter(currentDate, startDate) && dateIsBefore(currentDate, endDate)) {
        css += ' in-range';
      }
    }
    if (hoveredFixedRange.length) {
      if (hoveredFixedRange[0] === current) {
        css += ' hover-fix-range-start';
      } else if (hoveredFixedRange[hoveredFixedRange.length - 1] === current) {
        css += ' hover-fix-range-end';
      } else {
        for (let i = 1; i < hoveredFixedRange.length - 1; i++) {
          if (hoveredFixedRange[i] === current) {
            css += ' hover-fix-range-in-range';
          }
        }
      }
    }
    return css;
  }

  private isMomentWithinAllowedRange(mom: YYYYMMDDString): boolean {
    const { disableDatesAfter, disableDatesBefore, maxRangeLength } = this.state$.value.config.entity;
    if (disableDatesAfter) {
      const disableAfter = yyyymmddToDate(disableDatesAfter);
      const momDate = yyyymmddToDate(mom);
      if (dateIsAfter(momDate, disableAfter)) {
        return false;
      }
    }
    if (disableDatesBefore) {
      const disableBefore = yyyymmddToDate(disableDatesBefore);
      const momDate = yyyymmddToDate(mom);
      if (dateIsBefore(momDate, disableBefore)) {
        return false;
      }
    }
    if (maxRangeLength) {
      const momDate = yyyymmddToDate(mom);
      if (this.state$.value.selected.startDate && !this.state$.value.selected.endDate) {
        const selectedStartDate = yyyymmddToDate(this.state$.value.selected.startDate);
        const allowedStart = dateSubtractDay(selectedStartDate, maxRangeLength);
        const allowedEnd = dateAddDay(selectedStartDate, maxRangeLength);
        return dateIsAfter(momDate, allowedStart) && dateIsBefore(momDate, allowedEnd);
      }
    }
    return true;
  }

  private getHoveredFixedRange(config: RangePickerFixedRangeConfig): Observable<YYYYMMDDString[]> {
    return this.hoveredDate$.pipe(
      map((hover) => {
        if (!hover) {
          return null;
        }
        if (!this.isMomentWithinAllowedRange(hover)) {
          return [];
        }
        const hoverDate = yyyymmddToDate(hover);
        let startDate: Date;
        if (config.startFromWeekday) {
          const spanForHoverDate = getWeekSpanForDate(hoverDate, this.state$.value.config.entity.isStartWeekFromMonday);
          for (const weekDate of spanForHoverDate) {
            if (
              dateGetWeekday(weekDate) === config.startFromWeekday &&
              this.isMomentWithinAllowedRange(dateToYYYYMMDD(weekDate))
            ) {
              startDate = weekDate;
              break;
            }
          }
        } else {
          startDate = hoverDate;
        }
        if (startDate) {
          return datesGetSpan(startDate, dateAddDay(startDate, config.rangeLengthDays - 1)).map((d) =>
            dateToYYYYMMDD(d),
          );
        } else {
          return [];
        }
      }),
    );
  }
}
