<svg
  class="icon"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <path
    fill="none"
    fill-rule="evenodd"
    stroke="#959597"
    stroke-width="2"
    d="M3.689 10.614h4c1.104 0 2 .896 2 2v8c0 1.105-.896 2-2 2h-4c-1.105 0-2-.895-2-2v-8c0-1.104.895-2 2-2zM15.727 1h4c1.105 0 2 .895 2 2v8c0 1.105-.895 2-2 2h-4c-1.104 0-2-.895-2-2V3c0-1.105.896-2 2-2zm.413 15.785h4c1.105 0 2 .896 2 2v2c0 1.105-.895 2-2 2h-4c-1.104 0-2-.895-2-2v-2c0-1.104.896-2 2-2zM3.91 1h4c1.104 0 2 .895 2 2v2c0 1.105-.896 2-2 2h-4c-1.105 0-2-.895-2-2V3c0-1.105.895-2 2-2z"
  />
</svg>
