import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import { Actions, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import * as fromTypes from '../../types';
import { VehicleListFilterType, VehicleListMultiAccountFilter } from '../../types';
import * as fromSelectors from '../selectors/vehicle-lists.selectors';
import { getSelectedMultiAccountOptionId } from '../selectors/vehicle-lists.selectors';
import * as fromActions from '../actions/vehicle-list.actions';

@Injectable()
export class VehicleListsFacade {
  constructor(
    private store: Store<State>,
    private actions$: Actions,
  ) {}

  public getListLoading$(listName: fromTypes.VehicleListName): Observable<boolean> {
    return this.store.select(fromSelectors.getListLoading, { listName });
  }

  public getListEmpty$(listName: fromTypes.VehicleListName): Observable<boolean> {
    const loading$ = this.getListLoading$(listName);
    const listItems$ = this.getListItems$(listName);
    return combineLatest([loading$, listItems$]).pipe(map(([loading, items]) => !loading && items.length === 0));
  }

  public getListSerializedSnapshot(
    listName: fromTypes.VehicleListName,
  ): Observable<fromTypes.SerializedVehicleListSnapshot> {
    return this.actions$.pipe(
      ofType(fromActions.loadVehicleListSuccess),
      switchMap(() => this.store.select(fromSelectors.getVehicleListState, { listName })),
      map((listState) => {
        return fromTypes.utils.getSerializedFromSnapshot({
          page: listState.pagination.page,
          pageSize: listState.pagination.pageSize,
          search: listState.currentSearch,
          status: listState.status,
          filters: [],
        });
      }),
    );
  }

  public getListItems$(listName: fromTypes.VehicleListName): Observable<fromTypes.PortalEntity[]> {
    return this.store.select(fromSelectors.getListItems, { listName });
  }

  public getListError$(listName: fromTypes.VehicleListName): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getListError, { listName });
  }

  public getTotalFound(listName: fromTypes.VehicleListName): Observable<number> {
    return this.store.select(fromSelectors.getTotalFound, { listName });
  }

  public getPage$(listName: fromTypes.VehicleListName): Observable<number> {
    return this.store.select(fromSelectors.getPage, { listName });
  }

  public getCurrentSearch(listName: fromTypes.VehicleListName): Observable<string> {
    return this.store.select(fromSelectors.getCurrentSearch, { listName });
  }

  public getCurrentStatus(listName: fromTypes.VehicleListName): Observable<fromTypes.EntityStatus> {
    return this.store.select(fromSelectors.getCurrentStatus, { listName });
  }

  public getCurrentSelectedMultiAccountOptionId$(listName: fromTypes.VehicleListName): Observable<string> {
    return this.store.select(getSelectedMultiAccountOptionId, { listName });
  }

  public getPageSize$(listName: fromTypes.VehicleListName): Observable<number> {
    return this.store.select(fromSelectors.getPageSize, { listName });
  }

  public getSelectedVehicleId(listName: fromTypes.VehicleListName): Observable<string> {
    return this.store.select(fromSelectors.getSelectedVehicleId, { listName });
  }

  public onInitialized(
    listName: fromTypes.VehicleListName,
    staticFilters: fromTypes.PortalEntity[],
    serialized?: fromTypes.SerializedVehicleListSnapshot,
    isSelectedVehicleSupported = false,
    selectedMultiAccountManagementEntity: fromTypes.PortalEntity & { operatorIds: string[] } = null,
  ): void {
    const snapshot = fromTypes.utils.getSnapshotFromSerialized(serialized, isSelectedVehicleSupported);
    let multiAccountFilter: VehicleListMultiAccountFilter = null;
    if (selectedMultiAccountManagementEntity) {
      multiAccountFilter = {
        id: `${VehicleListFilterType.MULTI_ACCOUNT},${selectedMultiAccountManagementEntity.type},${
          selectedMultiAccountManagementEntity.entityId
        },${selectedMultiAccountManagementEntity.operatorIds.join('$')}`,
        type: VehicleListFilterType.MULTI_ACCOUNT,
        payload: {
          entityId: selectedMultiAccountManagementEntity.entityId,
          type: selectedMultiAccountManagementEntity.type,
          operatorIds: selectedMultiAccountManagementEntity.operatorIds,
        },
      };
    }
    this.store.dispatch(
      fromActions.vehicleListInitialized({
        listName,
        snapshot,
        staticFilters: this.portalEntitiesToListFilters(staticFilters),
        selectedMultiAccountManagementEntity: multiAccountFilter,
      }),
    );
  }

  public onCardInitialized(listName: fromTypes.VehicleListName, vehicleId: string): void {
    this.store.dispatch(fromActions.listItemInitialized({ listName, vehicleId }));
  }

  public onCardDestroyed(listName: fromTypes.VehicleListName, vehicleId: string): void {
    this.store.dispatch(fromActions.listItemDestroyed({ listName, vehicleId }));
  }

  public onPageChanged(listName: fromTypes.VehicleListName, page: number): void {
    this.store.dispatch(fromActions.pageChanged({ listName, page }));
  }

  public onPageSizeChanged(listName: fromTypes.VehicleListName, pageSize: number): void {
    this.store.dispatch(fromActions.pageSizeChanged({ listName, pageSize }));
  }

  public onSearchChanged(listName: fromTypes.VehicleListName, search: string): void {
    this.store.dispatch(fromActions.searchChanged({ listName, search }));
  }

  public onSelectedVehicleChanged(listName: fromTypes.VehicleListName, VehicleId: string): void {
    this.store.dispatch(fromActions.selectedVehicleChanged({ listName, VehicleId }));
  }

  public onStatusChanged(listName: fromTypes.VehicleListName, status: fromTypes.EntityStatus): void {
    this.store.dispatch(fromActions.statusChanged({ listName, status }));
  }

  public filterSelected(listName: fromTypes.VehicleListName, filters: fromTypes.VehicleListFilter[]): void {
    this.store.dispatch(fromActions.filtersChanged({ listName, filters }));
  }

  private portalEntitiesToListFilters(entities: fromTypes.PortalEntity[]): fromTypes.VehicleListFilter[] {
    const filters: fromTypes.VehicleListFilter[] = entities.map((entity) => {
      const id = fromTypes.utils.encodeDataComponents([entity.type, entity.entityId]);
      return {
        id,
        type: fromTypes.VehicleListFilterType.ENTITY,
        payload: entity,
      };
    });
    return filters;
  }
}
