<div class="wp-user-profile-info">
  <div class="columns">
    <div>
      <div class="header">Profile information</div>

      <div class="info">{{fullName$ | async}}</div>

      <div class="info" *ngIf="phone$ | async">
        {{phone$ | async | phoneFormat}}
      </div>

      <div class="info">{{email$ | async}}</div>
    </div>

    <div *ngIf="isEditBtnShown">
      <wp-btn-secondary
        [disabled]="editDisabled"
        [width]="'150px'"
        [slim]="true"
        (clicked)="onEdit()"
        >Edit
      </wp-btn-secondary>
    </div>
  </div>

  <div class="bottom-line"></div>
</div>
