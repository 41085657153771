import { SerializedVendorListSnapshot } from '../serialized-vendor-list-snapshot';
import { VendorListSnapshot } from '../vendor-list-snapshot';
import * as ui from '../ui';

export const snapshotToSerialized = (source: VendorListSnapshot): SerializedVendorListSnapshot => {
  const { page, pageSize, search } = source;
  const pageStr = page === ui.DEFAULT_VENDOR_LIST_PAGE ? undefined : '' + (page + 1);
  const pageSizeStr = pageSize === ui.DEFAULT_VENDOR_LIST_PAGE_SIZE ? undefined : '' + pageSize;
  const searchStr = search && search.length ? search : undefined;
  const res: SerializedVendorListSnapshot = {
    page: pageStr,
    pageSize: pageSizeStr,
    search: searchStr,
  };
  return res;
};
