import { SmartFormFieldDateRange, SmartFormFieldType } from '@apiEntities/smart-forms';
import { isSmartFormFieldDateValueValid } from '@rootTypes/utils/smart-forms/smart-form-field-date';

export const isSmartFormFieldDateRange = (arg: any): arg is SmartFormFieldDateRange => {
  return !!arg && typeof arg === 'object' && arg.type === SmartFormFieldType.DATE_RANGE;
};

export const isSmartFormFieldDateRangeValid = (dateRange: SmartFormFieldDateRange, required: boolean): boolean => {
  if (!required && !dateRange.from.value && !dateRange.to.value) {
    return true;
  }
  return (
    isSmartFormFieldDateValueValid(true, dateRange.from.value) &&
    isSmartFormFieldDateValueValid(true, dateRange.to.value)
  );
};
