import { CampusListEffects } from './campus-list.effects';
export { CampusListEffects };

import { CampusDataEffects } from './campus-data.effects';
export { CampusDataEffects };

import { CampusCalendarsDataEffects } from './campus-calendars-data.effects';
import { CampusLocationsDataEffects } from './campus-locations-data.effects';

import { campusDetailsEffects } from './campus-details';

export const featureEffectsArray = [
  CampusListEffects,
  CampusDataEffects,
  CampusCalendarsDataEffects,
  CampusLocationsDataEffects,
  ...campusDetailsEffects,
];
