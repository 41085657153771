import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-btn-suffix',
  templateUrl: './btn-suffix.component.html',
  styleUrls: ['./btn-suffix.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnSuffixComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
