import { UntypedFormControl, Validators } from '@angular/forms';
import { SmartInputModel } from './smart-input-model';
import { SelectOption } from '../../form-controls';
import {
  autocompleteDefaultOptionDisplayFn,
  autocompleteDefaultOptionSearchFn,
  AutocompleteOptionDisplayFn,
  AutocompleteOptionSearchFn,
} from '../../form-controls/autocomplete/autocomplete.component';
import { Observable, Subject } from 'rxjs';

export interface SmartAutocompleteConfig {
  label: string;
  options: SelectOption[];
  value?: string | number; // should contain SelectOption.value
  disabled?: boolean;
  required?: boolean;
  optionDisplayFn?: AutocompleteOptionDisplayFn;
  optionSearchFn?: AutocompleteOptionSearchFn;
  /**
   * Parent element to listen to close the dropdown.
   * Note, this is required when using the component inside a modal,
   * in other cases the dropdown is closing normally.
   */
  parentSelectorToCloseByClick?: string;
  noOptionalLabel?: boolean;
}

export class SmartAutocomplete implements SmartInputModel {
  public label: string;
  public control: UntypedFormControl;
  public controlStateChange$: Observable<void>;
  public options: SelectOption[];
  public optionDisplayFn: AutocompleteOptionDisplayFn;
  public optionSearchFn: AutocompleteOptionSearchFn;
  public parentSelectorToCloseByClick?: string;
  public valueChangesByUser$: Observable<any>;

  private initialValue: string | number | null;
  private controlStateChange$$: Subject<void>;
  private valueChangesByUser$$: Subject<any>;

  constructor(config: SmartAutocompleteConfig) {
    this.label = config.required || config?.noOptionalLabel ? config.label : `${config.label} (optional)`;
    this.initialValue = config.value || null;
    const validator = config.required ? Validators.required : undefined;
    this.control = new UntypedFormControl(config.value, validator);
    if (config.disabled) {
      this.control.disable();
    }
    this.options = config.options;
    if (config.optionDisplayFn) {
      this.optionDisplayFn = config.optionDisplayFn;
    } else {
      this.optionDisplayFn = autocompleteDefaultOptionDisplayFn;
    }
    if (config.optionSearchFn) {
      this.optionSearchFn = config.optionSearchFn;
    } else {
      this.optionSearchFn = autocompleteDefaultOptionSearchFn;
    }
    if (config.parentSelectorToCloseByClick) {
      this.parentSelectorToCloseByClick = config.parentSelectorToCloseByClick;
    }
    this.controlStateChange$$ = new Subject();
    this.controlStateChange$ = this.controlStateChange$$.asObservable();
    this.valueChangesByUser$$ = new Subject();
    this.valueChangesByUser$ = this.valueChangesByUser$$.asObservable();
  }

  public showErrorIfAny(): void {
    this.control.markAsDirty();
    this.control.updateValueAndValidity();
    this.controlStateChange$$.next();
  }

  public hasChanges(): boolean {
    const value = this.control.value || null;
    return value !== this.initialValue;
  }

  public isValid(): boolean {
    return this.control.valid || this.control.disabled;
  }

  public getValue(): string | number | undefined {
    return this.control.value || undefined;
  }

  public setValue(value?: string | number): void {
    this.control.setValue(value);
  }

  public setRequired(required: boolean): void {
    if (required) {
      this.control.addValidators([Validators.required]);
    } else {
      this.control.removeValidators(Validators.required);
    }
  }

  public setDisabled(disabled: boolean): void {
    if (disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  public setOptions(options: SelectOption[]): void {
    this.options = options;
    this.control.reset(undefined);
  }

  public getValueChanges(): Observable<any> {
    return this.control.valueChanges;
  }

  public emitValueChangeByUser(value?: any): void {
    this.valueChangesByUser$$.next(value);
  }
}
