import { PortalEntity } from '@rootTypes';

export interface EmployeeListFilter {
  id: string;
  type: EmployeeListFilterType;
  payload: any;
}

export interface EmployeeListEntityFilter {
  type: EmployeeListFilterType.ENTITY;
  payload: PortalEntity;
}

export enum EmployeeListFilterType {
  ENTITY = 'entity',
}
