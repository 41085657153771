import { Driver } from '../../../../types/entities/driver';
import { GetDriverProfileResponse } from '../index';

export const dbDriverObjectToDriver = (source: GetDriverProfileResponse): Driver => {
  const target = {
    id: source.profile.owner_id,
    firstName: source.profile.first_name,
    lastName: source.profile.last_name,
  } as Driver;
  if (source.profile.profile_pic_url) {
    target.profileImagePath = source.profile.profile_pic_url;
  }
  return target;
};
