import { createSelector, MemoizedSelector } from '@ngrx/store';
import { EntityState, PortalEntity, PortalEntityType, VehiclePortalEntity, WpError } from '../../../vehicles/types';
import {
  VehicleInspectionListItem,
  VehicleInspectionListName,
  VehicleInspectionListParams,
  VehicleInspectionListResults,
  VehicleInspectionQuickFilter,
} from '../../types';
import { VehicleInspectionList } from '../reducers/vehicle-inspections.reducer';
import { SmartDateRangeValue } from '../../../../shared/smart-forms';
import { VehicleInspectionAssignedMechanicStatusFilter, VehicleInspectionStatus } from '@apiEntities';
import { VehicleInspectionDetails } from '../../../../api/endpoints/get-vehicle-inspection';
import { vehicleInspectionStatusToDisplay } from '../../types/utils/vehicle-inspection-display-status-utils';
import {
  VehicleInspectionListAdvancedFilterPill,
  VehicleInspectionListAdvancedFilterType,
} from '../../types/utils/vehicle-inspection-list-advanced-filter-pill';
import { getVehicleInspectionsFeatureState } from './get-vehicle-inspections-feature-state';
import {
  displayVehicleInspectionDriverReviewStatus,
  displayVehicleInspectionMechanicAssessmentStatus,
} from '@rootTypes/utils';

const getAllLists = createSelector(getVehicleInspectionsFeatureState, (state) => state.lists);
const getListState = (listName: VehicleInspectionListName): MemoizedSelector<any, VehicleInspectionList> => {
  return createSelector(getAllLists, (lists) => lists[listName]);
};

export const getListItemDetails = createSelector(getVehicleInspectionsFeatureState, (state) => {
  const result: { [id: string]: VehicleInspectionDetails } = {};
  Object.keys(state.listItemDetails).forEach((id) => {
    const details = state.listItemDetails[id].entity;
    if (details) {
      result[id] = details;
    }
  });
  return result;
});

const getListItemsState = (
  listName: VehicleInspectionListName,
): MemoizedSelector<any, EntityState<VehicleInspectionListResults>> => {
  return createSelector(getListState(listName), (state) => state.listItems);
};

export const isListLoading = (listName: VehicleInspectionListName): MemoizedSelector<any, boolean> => {
  return createSelector(getListItemsState(listName), (state) => state.isLoading);
};

export const getListError = (listName: VehicleInspectionListName): MemoizedSelector<any, WpError> => {
  return createSelector(getListItemsState(listName), (state) => state.error);
};

const getListItemsEntity = (
  listName: VehicleInspectionListName,
): MemoizedSelector<any, VehicleInspectionListResults> => {
  return createSelector(getListItemsState(listName), (state) => state.entity);
};

export const getListItems = (
  listName: VehicleInspectionListName,
): MemoizedSelector<any, VehicleInspectionListItem[]> => {
  return createSelector(getListItemsEntity(listName), (state) => state?.items);
};

export const getTotalFound = (listName: VehicleInspectionListName): MemoizedSelector<any, number> => {
  return createSelector(getListItemsEntity(listName), (state) => state?.totalFound);
};

export const isListEmpty = (listName: VehicleInspectionListName): MemoizedSelector<any, boolean> => {
  return createSelector(getTotalFound(listName), (state) => {
    return typeof state === 'number' ? state === 0 : undefined;
  });
};

export const getListParams = (
  listName: VehicleInspectionListName,
): MemoizedSelector<any, VehicleInspectionListParams> => {
  return createSelector(getListState(listName), (state) => state.listParams);
};

export const getPage = (listName: VehicleInspectionListName): MemoizedSelector<any, number> => {
  return createSelector(getListParams(listName), (state) => state.pageIndex);
};

export const getPageSize = (listName: VehicleInspectionListName): MemoizedSelector<any, number> => {
  return createSelector(getListParams(listName), (state) => state.pageSize);
};

export const getDateRangeFilter = (listName: VehicleInspectionListName): MemoizedSelector<any, SmartDateRangeValue> => {
  return createSelector(getListParams(listName), (state) => state.dateRange);
};

export const getInspectionStatusFilter = (
  listName: VehicleInspectionListName,
): MemoizedSelector<any, VehicleInspectionStatus> => {
  return createSelector(getListParams(listName), (state) => {
    if (state.inspectionStatus && state.inspectionStatus.length > 0) {
      return state.inspectionStatus[0];
    }
    return undefined;
  });
};

const getAllDetailsState = createSelector(getVehicleInspectionsFeatureState, (state) => state.listItemDetails);

export const getDetailsState = (
  vehicleInspectionId: string,
): MemoizedSelector<any, EntityState<VehicleInspectionDetails>> => {
  return createSelector(getAllDetailsState, (state) => state[vehicleInspectionId]);
};

export const getInspectionDetailsError = (vehicleInspectionId: string): MemoizedSelector<any, WpError> => {
  return createSelector(getDetailsState(vehicleInspectionId), (state) => state?.error);
};

export const getInspectionDetails = (vehicleInspectionId: string): MemoizedSelector<any, VehicleInspectionDetails> => {
  return createSelector(getDetailsState(vehicleInspectionId), (state) => state?.entity);
};

const getMainList = createSelector(getAllLists, (state) => state.main);
const getMainListParams = createSelector(getMainList, (state) => state.listParams);

export const hasFilterPills = createSelector(getMainListParams, (state): boolean => {
  return (
    !!state.dateRange ||
    state.vehicles.length > 0 ||
    state.assignedMechanicIds.length > 0 ||
    state.driverIds.length > 0 ||
    state.mechanicAssessmentStatus.length > 0 ||
    state.driverReviewStatus.length > 0 ||
    state.inspectionStatus.length > 0 ||
    state.quickFilter !== VehicleInspectionQuickFilter.NONE
  );
});

export const getEntityFilterPills = createSelector(getMainListParams, (state): PortalEntity[] => {
  const entities: PortalEntity[] = [];
  state.assignedMechanicIds.forEach((entityId) => {
    if (
      entityId === VehicleInspectionAssignedMechanicStatusFilter.ASSIGNED ||
      entityId === VehicleInspectionAssignedMechanicStatusFilter.UNASSIGNED
    ) {
      return;
    }
    entities.push({
      type: PortalEntityType.VENDOR_EMPLOYEE,
      entityId,
    });
  });
  state.driverIds.forEach((entityId) => {
    entities.push({
      type: PortalEntityType.DRIVER,
      entityId,
    });
  });
  state.vehicles.forEach((vehicle) => {
    entities.push({
      type: PortalEntityType.VEHICLE,
      entityId: vehicle.vehicleId,
      vehicleDisplayId: vehicle.vehicleDisplayId,
    } as VehiclePortalEntity);
  });
  return entities;
});

export const getMainListAdvancedFilters = createSelector(getMainListParams, (state) => {
  const { assignedMechanicIds, inspectionStatus, mechanicAssessmentStatus, driverReviewStatus } = state;
  return {
    assignedMechanicIds,
    inspectionStatus,
    mechanicAssessmentStatus,
    driverReviewStatus,
  };
});

export const getMainListAdvancedFiltersCount = createSelector(getMainListAdvancedFilters, (state) => {
  let count = 0;
  Object.keys(state).forEach((key) => {
    count += state[key].length;
  });
  return count;
});

export const getAdvancedFilterPills = createSelector(getMainListAdvancedFilters, (state) => {
  const { assignedMechanicIds, inspectionStatus, mechanicAssessmentStatus, driverReviewStatus } = state;
  const filterPills: VehicleInspectionListAdvancedFilterPill[] = [];
  if (assignedMechanicIds.includes(VehicleInspectionAssignedMechanicStatusFilter.ASSIGNED)) {
    filterPills.push({
      type: VehicleInspectionListAdvancedFilterType.MECHANIC_ASSIGNMENT_STATUS,
      label: 'Assigned',
      value: VehicleInspectionAssignedMechanicStatusFilter.ASSIGNED,
    });
  }
  if (assignedMechanicIds.includes(VehicleInspectionAssignedMechanicStatusFilter.UNASSIGNED)) {
    filterPills.push({
      type: VehicleInspectionListAdvancedFilterType.MECHANIC_ASSIGNMENT_STATUS,
      label: 'Unassigned',
      value: VehicleInspectionAssignedMechanicStatusFilter.UNASSIGNED,
    });
  }
  inspectionStatus.forEach((status) => {
    filterPills.push({
      type: VehicleInspectionListAdvancedFilterType.INSPECTION_STATUS,
      label: vehicleInspectionStatusToDisplay(status),
      value: status,
    });
  });
  mechanicAssessmentStatus.forEach((status) => {
    filterPills.push({
      type: VehicleInspectionListAdvancedFilterType.MECHANIC_ASSESSMENT_STATUS,
      label: displayVehicleInspectionMechanicAssessmentStatus(status),
      value: status,
    });
  });
  driverReviewStatus.forEach((status) => {
    filterPills.push({
      type: VehicleInspectionListAdvancedFilterType.DRIVER_REVIEW_STATUS,
      label: displayVehicleInspectionDriverReviewStatus(status),
      value: status,
    });
  });
  return filterPills;
});

const getSelectedVehicleInspectionId = createSelector(getMainList, (state) => state.selectedVehicleInspectionId);
export const isVehicleInspectionSelected = (vehicleInspectionId: string): MemoizedSelector<any, boolean> => {
  return createSelector(getSelectedVehicleInspectionId, (selectedId) => {
    return !!selectedId && selectedId === vehicleInspectionId;
  });
};

export const getQuickFilter = createSelector(getMainList, (state) => state.listParams.quickFilter);
export const isLateInspectionsFilterApplied = createSelector(
  getQuickFilter,
  (value) => value === VehicleInspectionQuickFilter.LATE_INSPECTIONS,
);
export const isMissingInspectionsFilterApplied = createSelector(
  getQuickFilter,
  (value) => value === VehicleInspectionQuickFilter.MISSING_INSPECTIONS,
);

const getMissingInspectionsState = createSelector(getMainList, (state) => state.missingInspections);
export const getMissingInspections = createSelector(getMissingInspectionsState, (state) => state.entity);
export const isMissingInspectionsEmpty = createSelector(getMissingInspections, (state) => {
  if (Array.isArray(state)) {
    return state.length === 0;
  }
  return undefined;
});
export const isMissingInspectionsLoading = createSelector(getMissingInspectionsState, (state) => state.isLoading);
export const getMissingInspectionsError = createSelector(getMissingInspectionsState, (state) => state.error);
export const getMissingInspectionsCount = createSelector(getMissingInspections, (state) => {
  if (Array.isArray(state)) {
    return state.length;
  }
  return undefined;
});

export const getSelectedMissingInspectionDriverId = createSelector(
  getMainList,
  (state) => state.selectedMissingInspectionDriverId,
);
export const isMissingInspectionSelected = (driverId: string): MemoizedSelector<any, boolean> => {
  return createSelector(getSelectedMissingInspectionDriverId, (selectedDriverId) => {
    return selectedDriverId === driverId;
  });
};
