<svg
  class="icon"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <path
    fill="none"
    fill-rule="evenodd"
    stroke="#959597"
    stroke-width="2"
    d="M12 13c-2.21 0-4-1.792-4-4 0-2.21 1.79-4 4-4 2.208 0 4 1.79 4 4 0 2.208-1.792 4-4 4zm0-12C7.581 1 4 4.517 4 8.857 4 16.714 12 23 12 23s8-6.286 8-14.143C20 4.517 16.419 1 12 1z"
  />
</svg>
