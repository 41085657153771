import { createReducer, on } from '@ngrx/store';
import * as fromTypes from '../../types';
import * as fromActions from '../actions/add-edit-district.actions';

export interface AddEditDistrictState {
  districtId: string; // if edit district
  isLoading: boolean;
  isSuccess: boolean;
  error: fromTypes.WpError;
}

export const initialAddEditDistrictState: AddEditDistrictState = {
  districtId: null,
  isLoading: false,
  isSuccess: false,
  error: null,
};

export const addEditDistrictReducer = createReducer<AddEditDistrictState>(
  { ...initialAddEditDistrictState },
  on(fromActions.initializeDistrictEditor, (state, action) => {
    const { districtId } = action;
    return {
      ...state,
      districtId,
      isLoading: false,
      isSuccess: false,
      error: null,
    };
  }),
  on(fromActions.addDistrictRequested, fromActions.editDistrictRequested, (state, action) => {
    return {
      ...state,
      isLoading: true,
      isSuccess: false,
    };
  }),
  on(fromActions.addDistrictSuccess, fromActions.editDistrictSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isSuccess: true,
    };
  }),
  on(fromActions.addDistrictFailed, fromActions.editDistrictFailed, (state, action) => {
    const { error } = action;
    return {
      ...state,
      isLoading: false,
      isSuccess: false,
      error,
    };
  }),
);
