import {
  Component,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
  ElementRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';

import { magnify } from './magnify';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wp-image-zoom',
  templateUrl: './image-zoom.component.html',
  styleUrls: ['./image-zoom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageZoomComponent implements AfterViewInit, OnDestroy {
  @Input() public imageSrc: string;
  @Input() public widthPx: number;
  @Input() public heightPx: number;
  @Input() public zoomEnabled = true;
  @Input() public zoom = 2;
  @Input() public glassWidthPx?: number;
  @Input() public glassHeightPx?: number;
  @Input() public glassHorizontalShiftPx = 0;
  @Input() public glassVerticalShiftPx = 0;

  @ViewChild('image') private image: ElementRef<HTMLImageElement>;
  @ViewChild('imageContainer') private imageContainer: ElementRef<HTMLDivElement>;

  private sub?: Subscription;

  constructor() {}

  public ngAfterViewInit(): void {
    if (this.zoomEnabled) {
      const imageContainer = this.imageContainer.nativeElement;
      const image = this.image.nativeElement;
      this.sub = magnify(
        imageContainer,
        image,
        this.zoom,
        {
          widthPx: this.glassWidthPx ? this.glassWidthPx : this.widthPx * 0.7,
          heightPx: this.glassHeightPx ? this.glassHeightPx : this.heightPx * 0.7,
        },
        this.glassHorizontalShiftPx,
        this.glassVerticalShiftPx,
      );
    }
  }

  public ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
