import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { RideAssignmentRouteData, rideAssignmentRoute } from './ride-assignment';
import { RouterOutlets } from '@rootTypes/entities/router';

@Injectable({
  providedIn: 'root',
})
export class RideAssignmentRouterService extends PortalRouterService<RideAssignmentRouteData> {
  outlet = RouterOutlets.DRAWER;
  route = rideAssignmentRoute;
}
