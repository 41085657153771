import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { go } from '@rootStore';
import { portalRoutes } from '@router';

@Component({
  selector: 'wp-reset-password-email-sent',
  templateUrl: './reset-password-email-sent.component.html',
  styleUrls: ['./reset-password-email-sent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordEmailSentComponent {
  constructor(private store: Store) {}

  public onReturnToLoginPage(): void {
    this.store.dispatch(go(portalRoutes.auth.login.request()));
  }
}
