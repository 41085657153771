import { PortalEntity } from '@rootTypes';

export enum YardListFilterType {
  ENTITY = 'entity',
}

export interface YardListFilter {
  type: YardListFilterType;
  id: string;
  payload: any;
}

export interface YardListEntityFilter {
  id: string;
  type: YardListFilterType.ENTITY;
  payload: PortalEntity;
}
