import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import * as fromGlobalTypes from '@rootTypes';
import { iconPaths } from '@rootTypes/utils';

@Component({
  selector: 'wp-search-option',
  templateUrl: './search-option.component.html',
  styleUrls: ['./search-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchOptionComponent implements OnInit {
  @Input() public entity: fromGlobalTypes.PortalEntity;

  public entityTypes = fromGlobalTypes.PortalEntityType;
  public iconEmployee = iconPaths.EMPLOYEE_CARD_GREEN;

  constructor() {}

  ngOnInit(): void {}
}
