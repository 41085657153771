import { TripListLocationFilter } from '../types/trip-list';
import { FilterDeserializer } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';

export const getTripListLocationFilter = (location: string): TripListLocationFilter => {
  return {
    id: `location,${location}`,
    type: 'location',
    payload: location,
  };
};

export const deserializeLocationFilter: FilterDeserializer<TripListLocationFilter> = (
  s: string,
): TripListLocationFilter | undefined => {
  if (s && s.startsWith('location')) {
    const value: string = s.split(',')[1];
    return getTripListLocationFilter(value);
  }
  return undefined;
};
