import { DistrictDataFacadeService } from './district-data-facade.service';
import * as fromTypes from '../../types';
import { take } from 'rxjs/operators';
import { State } from '@rootStore';
import { Store } from '@ngrx/store';
import * as fromActions from '../actions/add-edit-calendar.actions';
import * as fromSelectors from '../selectors/add-edit-calendar.selectors';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AddEditDistrictCalendarFacade {
  constructor(
    private dataFacade: DistrictDataFacadeService,
    private store: Store<State>,
  ) {}

  public onUpdateCalendarReuqested(districtId: string, newCalendar: fromTypes.DistrictCalendar): void {
    this.dataFacade
      .getCalendarForDistrict$(districtId)
      .pipe(take(1))
      .subscribe((oldCalendar) => {
        this.store.dispatch(
          fromActions.updateCalendarRequested({
            districtId,
            updatedCalendar: newCalendar,
            previousCalendar: oldCalendar,
          }),
        );
      });
  }

  public onInitialize(): void {
    this.store.dispatch(fromActions.initializeCalendarEditor());
  }

  public getIsUpdateLoading(): Observable<boolean> {
    return this.store.select(fromSelectors.getUpdateCalendarLoading);
  }

  public getUpdateError(): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getUpdateCalendarError);
  }
}
