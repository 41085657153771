import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'src/app/api/api.service';
import { GetDriverApiService } from 'src/app/api/services/get-driver-api.service';
import * as fromApi from '../../../api';
import * as fromTypes from '../types';
import { entitySearchResultToPortalEntity } from '@rootTypes/utils/portal-entity/entity-search-result-to-portal-enity';

export interface DriverListResponse {
  results: fromTypes.PortalEntity[];
  nextSkip?: number;
  total: number;
}

@Injectable()
export class DriversApiService {
  constructor(
    private api: ApiService,
    private getDriverApi: GetDriverApiService,
  ) {}

  public getDriver(
    driverId: string,
    options: fromTypes.LoadDriverOptions = {},
  ): Observable<fromApi.getDriver.GetDriverResponse> {
    return this.getDriverApi.getDriver(driverId, options);
  }

  public getDriverListPage(request: fromApi.entityFilter.EntityFilterRequest): Observable<DriverListResponse> {
    return this.api.entityFilter(request).pipe(
      map((resp) => {
        const { results, total } = resp;
        return {
          results: results.map((source) => entitySearchResultToPortalEntity(source)),
          total,
        };
      }),
    ) as Observable<DriverListResponse>;
  }

  public getAvailableServiceAreas(): Observable<fromTypes.GetAvailableServiceAreasResponse> {
    return this.api.getAvailableServiceAreas();
  }

  public getDriverSecuritySettings(driverId: string): Observable<fromTypes.DriverLoginSetting[]> {
    return this.api.getSecuritySettings(driverId);
  }
}
