<div class="wp-select-yard">
  <wp-select
    *ngIf="isPlaceholder; else content"
    [control]="placeholderControl"
    [options]="placeholderOptions"
    [label]="label"
  >
  </wp-select>

  <ng-template #content>
    <div *ngIf="isLoading" class="loading-block">
      <div class="spinner">
        <wp-section-spinner
          class="spinner"
          [sectionHeight]="'60px'"
          [spinnerSize]="30"
        >
        </wp-section-spinner>
      </div>
      <span class="loading-text">Loading yards</span>
    </div>

    <wp-select
      *ngIf="!isLoading && !isError"
      [control]="control"
      [controlStateChange]="controlStateChange"
      [options]="options"
      [compareWith]="compareWith"
      [label]="label"
    >
    </wp-select>

    <div class="error-block" *ngIf="isError">
      <wp-alert-critical [readonly]="true">
        Failed to load yards
      </wp-alert-critical>
    </div>
  </ng-template>
</div>
