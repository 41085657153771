import { StudentDataEffects } from './student-data.effects';
export { StudentDataEffects };

import { StudentListEffects } from './student-list.effects';
import { StudentChangeRequestsDataEffects } from './student-change-requests-data.effects';
import { StudentChangeLogEffects } from './student-change-log.effects';
export { StudentListEffects };

export const featureEffectsArray = [
  StudentDataEffects,
  StudentListEffects,
  StudentChangeRequestsDataEffects,
  StudentChangeLogEffects,
];
