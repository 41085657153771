import { USNationalHolidaysEffects } from './us-national-holidays.effects';
import { PortalEntityInfoEffects } from './portal-entity-info.effects';
import { CatalogDisplayInfoEffects } from './catalog-display-info.effects';
import { DefaultDistrictGradesEffects } from './default-district-grades.effects';

export const featureEffects = [
  USNationalHolidaysEffects,
  PortalEntityInfoEffects,
  CatalogDisplayInfoEffects,
  DefaultDistrictGradesEffects,
];
