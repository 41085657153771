import { fromEvent, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

/**
 * @link https://www.w3schools.com/howto/howto_js_image_magnifier_glass.asp
 */
export function magnify(
  imgContainer: HTMLDivElement,
  img: HTMLImageElement,
  zoom: number,
  glassSize: { widthPx: number; heightPx: number },
  glassHorizontalShiftPx = 0,
  glassVerticalShiftPx = 0,
): Subscription {
  let glass: HTMLDivElement;
  let width: number;
  let height: number;
  let backgroundWidth: number;

  /* Create magnifier glass: */
  glass = document.createElement('DIV') as HTMLDivElement;

  glass.style.position = 'absolute';

  glass.style.cursor = 'none';
  glass.style.boxSizing = 'border-box';

  glass.style.width = glassSize.widthPx + 'px';
  glass.style.height = glassSize.heightPx + 'px';

  img.parentElement.insertBefore(glass, img);

  glass.style.backgroundImage = "url('" + img.src + "')";
  glass.style.backgroundRepeat = 'no-repeat';
  glass.style.backgroundSize = img.width * zoom + 'px ' + img.height * zoom + 'px';
  glass.style.boxShadow = '-3px -3px 10px 0px rgba(0,0,0,0.5)';
  glass.style.border = '1px solid #7c7c7e';
  glass.style.zIndex = '12'; // overlay over DisabledMaskComponent
  backgroundWidth = 1;
  width = glass.offsetWidth / 2;
  height = glass.offsetHeight / 2;

  const sub = new Subscription();

  /* Execute a function when someone moves the magnifier glass over the image: */
  const glassMouseMoveSub = fromEvent(glass, 'mousemove')
    // Even 20 ms gives a significant improvement in performance,
    // removes visual delays with opened Browser console for big images.
    // In the same time 40-100 ms is poor for human eyes so 20 ms is a golden middle
    .pipe(throttleTime(20))
    .subscribe((event: MouseEvent) => moveMagnifier(event));
  sub.add(glassMouseMoveSub);

  const imgMouseMoveSub = fromEvent(img, 'mousemove')
    .pipe(throttleTime(20))
    .subscribe((event: MouseEvent) => moveMagnifier(event));
  sub.add(imgMouseMoveSub);

  /*and also for touch screens:*/
  const glassTouchMoveSub = fromEvent(glass, 'touchmove')
    .pipe(throttleTime(20))
    .subscribe((event: MouseEvent) => moveMagnifier(event));
  sub.add(glassTouchMoveSub);

  const imgTouchMoveSub = fromEvent(img, 'touchmove')
    .pipe(throttleTime(20))
    .subscribe((event: MouseEvent) => moveMagnifier(event));
  sub.add(imgTouchMoveSub);

  glass.style.display = 'none';

  const mouseEnterSub = fromEvent(imgContainer, 'mouseenter').subscribe(() => {
    glass.style.display = 'block';
    img.style.opacity = '0.7';
  });
  sub.add(mouseEnterSub);

  const mouseLeaveSub = fromEvent(imgContainer, 'mouseleave').subscribe(() => {
    glass.style.display = 'none';
    img.style.opacity = '1';
  });
  sub.add(mouseLeaveSub);

  function moveMagnifier(event: MouseEvent): void {
    /* Prevent any other actions that may occur when moving over the image */
    event.preventDefault();
    /* Get the cursor's x and y positions: */
    const pos = getCursorPos(event);
    let x = pos.x;
    let y = pos.y;
    /* Prevent the magnifier glass from being positioned outside the image: */
    if (x > img.width - width / zoom) {
      x = img.width - width / zoom;
    }
    if (x < width / zoom) {
      x = width / zoom;
    }
    if (y > img.height - height / zoom) {
      y = img.height - height / zoom;
    }
    if (y < height / zoom) {
      y = height / zoom;
    }
    /* Set the position of the magnifier glass: */
    glass.style.left = x + glassHorizontalShiftPx - width + 'px';
    glass.style.top = y + glassVerticalShiftPx - height + 'px';
    /* Display what the magnifier glass 'sees': */
    glass.style.backgroundPosition =
      '-' + (x * zoom - width + backgroundWidth) + 'px -' + (y * zoom - height + backgroundWidth) + 'px';
  }

  function getCursorPos(event: any): { x: number; y: number } {
    let a: DOMRect;
    let x = 0;
    let y = 0;
    event = event || window.event;
    /* Get the x and y positions of the image: */
    a = img.getBoundingClientRect();
    /* Calculate the cursor's x and y coordinates, relative to the image: */
    x = event.pageX - a.left;
    y = event.pageY - a.top;
    /* Consider any page scrolling: */
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return { x, y };
  }

  return sub;
}
