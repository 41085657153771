import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { NavigationRequest } from '../../types/navigation-request';

export interface VendorListRouteData {
  page?: string;
  pageSize?: string;
  search?: string;
}

export const vendorListRoute: PortalRoute<VendorListRouteData> = {
  path: 'list',
  isOnRoute: (state: RouterStateUrl): boolean => {
    return state.url.startsWith('/campuses/list');
  },
  parse: (state: RouterStateUrl): VendorListRouteData => {
    let data: VendorListRouteData = {};
    if (state.queryParams) {
      data = {
        ...state.queryParams,
      };
    }
    return data;
  },
  request: (data: VendorListRouteData = {}, queryParamStrategy?: QueryParamsHandlingStrategy): NavigationRequest => {
    const req: NavigationRequest = {
      path: ['/vendors', 'list'],
    };
    const query = { ...data };
    if (Object.keys(query).length) {
      req.query = query;
    }
    if (queryParamStrategy) {
      req.extras = {
        queryParamsHandling: queryParamStrategy,
      };
    }
    return req;
  },
  getHref(data?: VendorListRouteData): string {
    return '/vendors/list';
  },
};
