import { RouterStateUrl } from '@rootStore';

import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute } from '../../types/portal-route';

export const newUserPasswordSuccessRoute: PortalRoute<void> = {
  path: 'password-setup-success',
  isOnRoute(state: RouterStateUrl): boolean {
    return state.url === `/${this.path}`;
  },
  request(): NavigationRequest {
    return {
      path: [`/${this.path}`],
    };
  },
};
