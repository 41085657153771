import { createAction, props } from '@ngrx/store';
import {
  WpError,
  GetStudentChangeRequestConfigResponse,
  StudentChangeRequestReviewFormFieldEditorOutput,
  StudentChangeRequestApplyDataPayload,
  ApplyStudentChangeRequestRequest,
  ApplyStudentChangeRequestResponse,
} from '../../dependencies';

const studentChangeRequestReview = '[StudentChangeRequestReview]';
export const initStudentChangeRequestReview = createAction(
  `${studentChangeRequestReview}: Init state`,
  props<{ requestId: string }>(),
);
export const destroyStudentChangeRequestReview = createAction(`${studentChangeRequestReview}: Destroy state`);

export const studentChangeRequestReviewLoadRequestConfigRequested = createAction(
  `${studentChangeRequestReview}: Load request requested`,
  props<{ requestId: string }>(),
);
export const studentChangeRequestReviewLoadRequestConfigSuccess = createAction(
  `${studentChangeRequestReview}: Load request success`,
  props<{ response: GetStudentChangeRequestConfigResponse }>(),
);
export const studentChangeRequestReviewLoadRequestConfigFailed = createAction(
  `${studentChangeRequestReview}: Load request failed`,
  props<{ error: WpError }>(),
);

export const studentChangeRequestReviewChooseOperation = createAction(
  `${studentChangeRequestReview}: Choose operation`,
  props<{ isUpdate: boolean }>(),
);
export const studentChangeRequestReviewChooseEntityToUpdate = createAction(
  `${studentChangeRequestReview}: Choose entity to update`,
  props<{ entityId: string }>(),
);

export const studentChangeRequestReviewUpdateRevisedData = createAction(
  `${studentChangeRequestReview}: Update revised data`,
  props<{ event: StudentChangeRequestReviewFormFieldEditorOutput }>(),
);
export const studentChangeRequestReviewRevertRevisedData = createAction(
  `${studentChangeRequestReview}: Revert revised data`,
  props<{ fieldName: string }>(),
);
export const studentChangeRequestReviewApplyData = createAction(
  `${studentChangeRequestReview}: Apply data`,
  props<{ payload: StudentChangeRequestApplyDataPayload }>(),
);
export const studentChangeRequestReviewConfirmBtnClick = createAction(
  `${studentChangeRequestReview}: Confirm btn click`,
);
export const applyStudentChangeRequestRequested = createAction(
  `${studentChangeRequestReview}: Apply change request requested`,
  props<{ request: ApplyStudentChangeRequestRequest }>(),
);
export const applyStudentChangeRequestSuccess = createAction(
  `${studentChangeRequestReview}: Apply change request success`,
  props<{ response: ApplyStudentChangeRequestResponse }>(),
);
export const applyStudentChangeRequestFailed = createAction(
  `${studentChangeRequestReview}: Apply change request failed`,
  props<{ error: WpError }>(),
);
export const studentChangeRequestReviewReloadPendingRequestsRequested = createAction(
  `${studentChangeRequestReview}: Reload pending requests requested`,
  props<{ studentId: string }>(),
);
