import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  Input,
} from '@angular/core';

@Component({
  selector: 'wp-info-window',
  templateUrl: './info-window.component.html',
  styleUrls: ['./info-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoWindowComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() public isCloseBtn = true;
  @Input() public isRounded: boolean;
  @Output() public closed: EventEmitter<void> = new EventEmitter<void>();
  @Output() public heightChanged: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('wrapperEl') private wrapperEl: ElementRef;

  constructor() {}

  ngAfterViewInit(): void {
    if (this.wrapperEl) {
      const wrapperDiv = this.wrapperEl.nativeElement as HTMLDivElement;
      const height = wrapperDiv.getBoundingClientRect().height;
      this.heightChanged.emit(height);
    }
  }

  ngOnDestroy(): void {
    this.heightChanged.emit(0);
  }

  ngOnInit(): void {}

  public onClose(): void {
    this.closed.emit();
  }
}
