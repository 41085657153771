import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-icon-dashboard-grey',
  templateUrl: './icon-dashboard-grey.component.html',
  styleUrls: ['./icon-dashboard-grey.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconDashboardGreyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
