import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'wp-add-link-button',
  template: `
    <div class="add-link-button">
      <i class="add-icon"><wp-icon-plus-circle></wp-icon-plus-circle></i>
      <wp-link-btn (clicked)="clicked.emit($event)"><ng-content></ng-content></wp-link-btn>
    </div>
  `,
  styleUrl: './add-link-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddLinkButtonComponent {
  @Output() clicked = new EventEmitter<MouseEvent>();
}
