import { createSelector } from '@ngrx/store';
import { capitalize } from '../../../../types/utils/common/capitalize';
import {
  RideEventTallyItem,
  RiderStatusBottomLabel,
  RiderStatusDetails,
  RiderStatusPill,
} from '@rootTypes/entities/ride';
import {
  RideEventV2,
  RideEventWithUpdateV2,
  RideRiderV2,
  RideRiderWithProjectionV2,
  RideV2,
} from '@apiEntities/rides/ride-v2';
import { RideEventRidersMeta } from '../../types';
import { UTCTime } from '@rootTypes/utils/common/date';
import { getRidesFeature } from './get-feature';
import {
  CampusLocationImageV2Projection,
  RiderCancellationReasonRideV2Projection,
  RiderRideV2Projection,
  RideV2ProjectionKey,
  RideV2Projections,
  VendorAssignmentRideV2Projection,
} from '@apiEntities/rides/ride-v2-projection';
import { RideEventPhotoItem } from '@rootTypes/entities/ride/ride-event-photo-item';
import { isRideInUserTimezone } from '@rootTypes/utils/ride/is-ride-in-user-timezone';
import { yyyymmddToday } from '@rootTypes/utils';

const getRideDataState = createSelector(getRidesFeature, (state) => state.data);

export const getAllRidesV2State = createSelector(getRideDataState, (state) => state.ridesV2);

const getRideV2EntityState = (rideId) => createSelector(getAllRidesV2State, (state) => state[rideId]);

export const isRideV2Loading = (rideId) =>
  createSelector(getRideV2EntityState(rideId), (state) => !state?.state || state?.state === 'loading');

export const isRideV2FreshLoading = (rideId) =>
  createSelector(
    getRideV2EntityState(rideId),
    (state) => !state?.state || state?.state === 'loading' || state?.state === 'old',
  );

export const getRideV2Error = (rideId) => createSelector(getRideV2EntityState(rideId), (state) => state?.error || null);

export const getRideV2 = (rideId) =>
  createSelector(getRideV2EntityState(rideId), (state): RideV2 => state?.entity || null);

export const selectRideV2Status = (rideId) => createSelector(getRideV2(rideId), (state) => state?.status || null);

export const getFreshRideV2 = (rideId) =>
  createSelector(getRideV2EntityState(rideId), (state): RideV2 => (state?.state === 'fresh' ? state?.entity : null));
export const getRideTimezoneV2 = (rideId) => createSelector(getRideV2(rideId), (state) => state?.timezone);

export const isInUserTimezoneV2 = (rideId) =>
  createSelector(getRideV2(rideId), (ride) => {
    return isRideInUserTimezone(ride);
  });
export const selectRideV2Error = (rideId) => createSelector(getRideV2EntityState(rideId), (state) => state?.error);

// route by ride
export const getRouteByRideV2 = (rideId) => createSelector(getRideV2(rideId), (state) => state?.route);
export const getRouteIdByRideV2 = (rideId) => createSelector(getRouteByRideV2(rideId), (state) => state?.routeId);

const getRouteProjectionByRideV2 = (rideId) => createSelector(getRideV2ProjectionsByRide(rideId), (p) => p?.route);

export const getRouteNameByRideV2 = (rideId) => createSelector(getRouteProjectionByRideV2(rideId), (p) => p?.name);

// events
export const getRideV2ScheduledStartTimestamp = (rideId) =>
  createSelector(getRideV2(rideId), (ride) => ride?.startTimestamp?.scheduled || null);

export const getRideV2ScheduledStartYYYYMMDD = (rideId) =>
  createSelector(
    getRideTimezoneV2(rideId),
    getRideV2ScheduledStartTimestamp(rideId),
    (timezone, scheduledStartTimestamp) => {
      if (!scheduledStartTimestamp) {
        return null;
      }
      return new UTCTime(scheduledStartTimestamp, timezone).getYYYYMMDDString();
    },
  );

const getRideV2Route = (rideId) => createSelector(getRideV2(rideId), (ride) => ride?.route || null);
export const getRideV2RouteId = (rideId) => createSelector(getRideV2Route(rideId), (route) => route?.routeId || null);

export const getRideEventsV2 = (rideId: string) =>
  createSelector(getRideV2(rideId), (ride) => {
    if (!ride) {
      return [];
    }
    return ride?.events;
  });

const getRidersV2 = (rideId: string) => createSelector(getRideV2(rideId), (ride) => ride?.riders || []);

export const getRiderV2 = (rideId: string, riderId: string) =>
  createSelector(getRidersV2(rideId), (riders) => {
    return riders.find((r) => r.riderId === riderId);
  });

const getRideV2LastEvent = (rideId: string) =>
  createSelector(getRideEventsV2(rideId), (events) => {
    if (!events?.length) {
      return null;
    }
    return events[events.length - 1];
  });
export const getRideV2ScheduledEndTimestamp = (rideId) =>
  createSelector(getRideV2LastEvent(rideId), (event) => event?.scheduled?.timestamp);
export const getRideEventIdsV2 = (rideId: string) =>
  createSelector(getRideEventsV2(rideId), (events) => events.map((e) => e.eventId));
export const getRideEventV2 = (rideId: string, eventIdParam: string) =>
  createSelector(
    getRideEventsV2(rideId),
    (events): RideEventV2 => events.find(({ eventId }) => eventId === eventIdParam),
  );

const getRideEventRidersV2 = (rideId: string, eventId: string) =>
  createSelector(getRideEventV2(rideId, eventId), (state) => state?.riders || []);

export const getRideEventRidersMetaV2 = (rideId: string, eventId: string) =>
  createSelector(getRideEventRidersV2(rideId, eventId), (riders): RideEventRidersMeta => {
    if (!riders?.length) {
      return {
        firstRiderId: null,
        restRiderIds: [],
        riderCount: 0,
      };
    }
    const [first, ...rest] = riders;
    return {
      firstRiderId: first.riderId as string,
      restRiderIds: rest.map((r) => r.riderId) as string[],
      riderCount: riders.length,
    };
  });

const mapRiderStatusToTallyStatus = (
  riderEventStatus: RideV2['events'][0]['riders'][0]['status'],
  eventType: RideV2['events'][0]['action'],
): string | undefined => {
  if (riderEventStatus === 'PICKED_UP' || riderEventStatus === 'PICKED_UP_ON_EARLIER_STOP') {
    return 'Pick up';
  }
  if (riderEventStatus === 'DROPPED_OFF' || riderEventStatus === 'DROPPED_OFF_ON_EARLIER_STOP') {
    return 'Dropoff';
  }
  if (riderEventStatus === 'ON_HOLD') {
    return 'On hold';
  }
  if (riderEventStatus === 'SCHEDULED') {
    return eventType === 'PICK_UP' ? 'Pick up' : 'Dropoff';
  }
  if (riderEventStatus === 'NO_SHOW') {
    return 'No show';
  }
  if (riderEventStatus === 'CANCELLED_OWNER') {
    return 'Canceled';
  }
  return undefined;
};

const eventTallyItemOrder = {
  ['Dropoff']: 0,
  ['On hold']: 1,
  ['Pick up']: 2,
  ['No show']: 3,
  ['Canceled']: 4,
};

export const getRideEventTallyV2 = (rideId: string, eventId: string) =>
  createSelector(getRideEventV2(rideId, eventId), (event): RideEventTallyItem[] => {
    const byStatus = event.riders.reduce((p, c) => {
      const tally = mapRiderStatusToTallyStatus(c.status, event.action);
      if (tally) {
        if (!p[tally]) {
          p[tally] = 0;
        }
        p[tally]++;
      }
      return p;
    }, {});
    const tallyLabels = Object.keys(byStatus);
    tallyLabels.sort((a, b) => eventTallyItemOrder[a] - eventTallyItemOrder[b]);
    return tallyLabels.map((tallyLabel) => ({ label: tallyLabel, count: byStatus[tallyLabel] }));
  });

const getRideEventScheduledInfo = (rideId: string, eventId: string) =>
  createSelector(getRideEventV2(rideId, eventId), (event) => event?.scheduled);

const getRideEventArrivedInfo = (rideId: string, eventId: string) =>
  createSelector(getRideEventV2(rideId, eventId), (event) => event?.arrived);

const getRideEventCompletedInfo = (rideId: string, eventId: string) =>
  createSelector(getRideEventV2(rideId, eventId), (event) => event?.completed);

export const getRideEventScheduledTimestamp = (rideId: string, eventId: string) =>
  createSelector(getRideEventScheduledInfo(rideId, eventId), (state) => state?.timestamp);
export const getRideEventArrivedTimestamp = (rideId: string, eventId: string) =>
  createSelector(getRideEventArrivedInfo(rideId, eventId), (state) => state?.timestamp);
export const getRideEventCompletedTimestamp = (rideId: string, eventId: string) =>
  createSelector(getRideEventCompletedInfo(rideId, eventId), (state) => state?.timestamp);
export const getRideEventCompletedLabel = (rideId: string, eventId: string) =>
  createSelector(getRideEventV2(rideId, eventId), (state) => {
    return 'Confirmed';
  });

const getRideEventRiderV2 = (rideId: string, eventId: string, riderId: string) =>
  createSelector(getRideEventRidersV2(rideId, eventId), (state): RideV2['events'][0]['riders'][0] =>
    state.find((r) => r.riderId === riderId),
  );

const getRideEventRiderStatus = (rideId: string, eventId: string, riderId: string) =>
  createSelector(getRideEventRiderV2(rideId, eventId, riderId), (rider) => rider?.status);

const isRideRiderPlacedOnHoldOnEvent = (rideId: string, eventId: string, riderId: string) =>
  createSelector(getRideEventRiderStatus(rideId, eventId, riderId), (status) => status === 'ON_HOLD');

export const getRiderEventsV2 = (rideId: string, riderId: string) =>
  createSelector(getRideEventsV2(rideId), (events) => {
    return events.filter((ev) => {
      return ev.riders.some((r) => r.riderId === riderId);
    });
  });
export const getRiderEventsIdsV2 = (rideId: string, riderId: string) =>
  createSelector(getRiderEventsV2(rideId, riderId), (events) => {
    return events.map((ev) => ev.eventId);
  });

const getRideEventRiderAdmissionType = (rideId: string, eventId: string, riderId: string) =>
  createSelector(getRideEventRiderV2(rideId, eventId, riderId), (state) => state?.admissionType);

const isOnDemandEvent = (rideId: string, eventId: string, riderId: string) =>
  createSelector(
    getRideEventRiderAdmissionType(rideId, eventId, riderId),
    (admissionType) => admissionType === 'ON_DEMAND',
  );

const wasPlacedOnHoldEarlierEvent = (rideId: string, eventId: string, riderId: string) =>
  createSelector(getRiderEventsV2(rideId, riderId), (events) => {
    if (!events.length) {
      return false;
    }
    for (const event of events) {
      const rider = event.riders.find((r) => r.riderId === riderId);
      if (rider.status === 'ON_HOLD') {
        return true;
      }
      if (event.eventId === eventId) {
        return false;
      }
    }
    return false;
  });

const isRiderPlacedOnHoldOnThisEvent = (rideId: string, eventId: string, riderId: string) =>
  createSelector(getRiderEventsV2(rideId, riderId), (events) => {
    if (!events?.length) {
      return false;
    }
    for (const event of events) {
      const rider = event.riders.find((r) => r.riderId === riderId);
      if (rider.status === 'ON_HOLD') {
        return event.eventId === eventId;
      }
    }
    return false;
  });

export const getRiderStatusPillV2 = (rideId: string, eventId: string, riderId: string) =>
  createSelector(
    wasPlacedOnHoldEarlierEvent(rideId, eventId, riderId),
    isOnDemandEvent(rideId, eventId, riderId),
    getRideEventRiderStatus(rideId, eventId, riderId),
    (wasPlacedOnHold, isOnDemand, riderEventStatus): RiderStatusPill | null => {
      if (wasPlacedOnHold) {
        return { color: 'orange', text: 'On hold' };
      }
      if (isOnDemand) {
        return { color: 'blue', text: 'On demand' };
      }
      if (riderEventStatus === 'CANCELLED_OWNER') {
        return { color: 'gray', text: 'Canceled' };
      }
      if (riderEventStatus === 'NO_SHOW') {
        return { color: 'gray', text: 'No show' };
      }
      return null;
    },
  );

export const getRiderStatusBottomLabelV2 = (rideId: string, eventId: string, riderId: string) =>
  createSelector(
    isRideRiderPlacedOnHoldOnEvent(rideId, eventId, riderId),
    getRideEventRiderStatus(rideId, eventId, riderId),
    (isPlacedOnHoldOnEvent, riderEventStatus): RiderStatusBottomLabel | null => {
      if (riderEventStatus === 'CANCELLED_OWNER' || riderEventStatus === 'NO_SHOW') {
        return null;
      }
      if (isPlacedOnHoldOnEvent) {
        return {
          icon: 'exclamation',
          text: 'Placed on hold',
        };
      }
      if (riderEventStatus === 'PICKED_UP' || riderEventStatus === 'PICKED_UP_ON_EARLIER_STOP') {
        return {
          icon: 'check',
          text: 'Picked up',
        };
      }
      if (riderEventStatus === 'DROPPED_OFF' || riderEventStatus === 'DROPPED_OFF_ON_EARLIER_STOP') {
        return {
          icon: 'check',
          text: 'Dropped off',
        };
      }
      if (riderEventStatus) {
        const plainTextStatus = capitalize(riderEventStatus.toLowerCase());
        return {
          icon: null,
          text: plainTextStatus,
        };
      }
      return null;
    },
  );

export const getRiderStatusDetailsV2 = (rideId: string, eventId: string, riderId: string) =>
  createSelector(
    getRideV2(rideId),
    getRideEventRiderStatus(rideId, eventId, riderId),
    isRiderPlacedOnHoldOnThisEvent(rideId, eventId, riderId),
    getRiderV2(rideId, riderId),
    selectRiderCancellationReasonV2(rideId, riderId),
    (ride, riderEventStatus, isPlacedOnHold, rider: RideRiderV2, cancellationReason): RiderStatusDetails | null => {
      if (riderEventStatus === 'CANCELLED_OWNER') {
        if (cancellationReason) {
          const formatter = new UTCTime(cancellationReason.timestamp, ride.timezone);
          let displayReason = cancellationReason.reason;
          if (cancellationReason.comment?.length) {
            displayReason = `${displayReason} (${cancellationReason.comment})`;
          }
          return {
            actionBy: `${cancellationReason.canceledBy.name} (${cancellationReason.canceledBy.role})`,
            actionAt: formatter.getDisplayWeekdayDateAndTimeStr(),
            reason: displayReason,
          };
        } else {
          return null;
        }
      } else if (isPlacedOnHold) {
        // TODO: Clarify where we read placed-on-hold details, for now return null
        // return {
        //   actionBy: '--',
        //   actionAt: '--',
        //   reason: '--',
        // };
        return null;
      } else {
        return null;
      }
    },
  );

/**
 * PROJECTIONS
 */

const getRideV2ProjectionsState = createSelector(getRideDataState, (state) => state.projections);

const getRideV2ProjectionsByRide = (rideId) =>
  createSelector(getRideV2ProjectionsState, (state): RideV2Projections => state[rideId]);

export const selectRideV2Projection = <K extends RideV2ProjectionKey>(rideId: string, projectionKey: K) =>
  createSelector(getRideV2ProjectionsByRide(rideId), (state): RideV2Projections[K] => state?.[projectionKey]);

// rider cancellation reasons

export const selectRiderCancellationReasonsByRideV2 = (rideId: string) =>
  selectRideV2Projection(rideId, 'riderCancellationReason');

function selectRiderCancellationReasonV2(rideId: string, riderId: string) {
  return createSelector(
    selectRiderCancellationReasonsByRideV2(rideId),
    (reasons): RiderCancellationReasonRideV2Projection | null => {
      if (!reasons?.length) {
        return null;
      }
      return reasons.find((r) => r.riderId === riderId);
    },
  );
}

// route groups by rides
export const getRouteGroupIdByRideV2 = (rideId: string) =>
  createSelector(getRideV2(rideId), (ride) => ride?.routeGroup?.routeGroupId);

const selectRouteGroupProjectionByRideV2 = (rideId: string) => selectRideV2Projection(rideId, 'routeGroup');

export const selectRouteGroupNameByRideV2 = (rideId: string) =>
  createSelector(selectRouteGroupProjectionByRideV2(rideId), (p) => p?.name);

// driver
export const getDriverIdByRideV2 = (rideId: string) =>
  createSelector(getRideV2(rideId), (ride) => ride?.driver?.driverId || null);

export const getDriverProjectionByRideV2 = (rideId: string) => selectRideV2Projection(rideId, 'driver');

// vehicle
export const getVehicleProjectionByRideV2 = (rideId: string) =>
  createSelector(getRideV2ProjectionsByRide(rideId), (p) => p?.vehicle);
export const getVehicleIdByRideV2 = (rideId: string) =>
  createSelector(getVehicleProjectionByRideV2(rideId), (vehicle) => {
    return vehicle?.id || null;
  });

// event updates
const getEventUpdatesProjectionByRideV2 = (rideId: string) => selectRideV2Projection(rideId, 'userEventsEstimate');

export const getEventWithUpdatesV2 = (rideId: string, eventId: string) =>
  createSelector(
    getRideEventV2(rideId, eventId),
    getEventUpdatesProjectionByRideV2(rideId),
    (event, updates): RideEventWithUpdateV2 => {
      if (!updates?.length) {
        return event;
      }
      const update = updates.find((u) => u.eventId === event.eventId);
      if (!update) {
        return event;
      }
      return {
        ...event,
        ...update,
      };
    },
  );

export const getEventsWithUpdatesByRideV2 = (rideId: string) =>
  createSelector(
    getRideEventsV2(rideId),
    getEventUpdatesProjectionByRideV2(rideId),
    (events, updates): RideEventWithUpdateV2[] => {
      if (!events?.length) {
        return [];
      }
      if (!updates?.length) {
        return events;
      }
      return events.map((event) => {
        const update = updates?.find((u) => u.eventId === event.eventId);
        return {
          ...event,
          ...update,
        };
      });
    },
  );

export const getRiderEventsWithUpdatesV2 = (rideId: string, riderId: string) =>
  createSelector(
    getRiderEventsV2(rideId, riderId),
    getEventsWithUpdatesByRideV2(rideId),
    (riderEvents, updates): RideEventWithUpdateV2[] => {
      const riderEventIdsMap = riderEvents.reduce((p, c) => {
        p[c.eventId] = true;
        return p;
      }, {});
      if (updates?.length) {
        return updates.filter((u) => riderEventIdsMap[u.eventId]);
      }
      return [];
    },
  );

export const getNextEventV2 = (rideId: string) =>
  createSelector(getEventsWithUpdatesByRideV2(rideId), (events: RideEventWithUpdateV2[]) => {
    if (!events?.length) {
      return null;
    }
    return events.find((e) => e.status === 'IN_PROGRESS');
  });

export const getNextEventIdV2 = (rideId: string) =>
  createSelector(getNextEventV2(rideId), (event) => {
    return event?.eventId || null;
  });

// charter trips
export const getTripIdByRideV2 = (rideId: string) =>
  createSelector(getRideV2(rideId), (ride) => ride?.charter?.tripId || null);
export const getCharterTripRideV2Projection = (rideId) => selectRideV2Projection(rideId, 'charterTrip');

export const getCharterTripDisplayIdByRideV2 = (rideId) =>
  createSelector(getCharterTripRideV2Projection(rideId), (p) => p?.name);

export const getCharterTripDisplayCatalogIdByRideV2 = (rideId) =>
  createSelector(getCharterTripRideV2Projection(rideId), (p) => {
    throw new Error('Not implemented');
  });

// vendor assignments
export const getVendorAssignmentByRideV2 = (rideId: string) =>
  createSelector(getRideV2ProjectionsByRide(rideId), (p): VendorAssignmentRideV2Projection => p?.vendorAssignment);

export const getVendorIdByRideV2 = (rideId: string) =>
  createSelector(getVendorAssignmentByRideV2(rideId), (p) => p?.vendorId);

export const getYardIdByRideV2 = (rideId: string) =>
  createSelector(getVendorAssignmentByRideV2(rideId), (p) => p?.yardId);

// riders
export const getRidersProjectionByRideV2 = (rideId: string) =>
  createSelector(getRideV2ProjectionsByRide(rideId), (p) => p?.riders || []);

const getRiderProjectionByRideV2 = (rideId: string, riderId: string) =>
  createSelector(getRidersProjectionByRideV2(rideId), (riders): RiderRideV2Projection => {
    return riders.find((r) => r.id === riderId);
  });

export const getRiderV2WithProjection = (rideId: string, riderId: string) =>
  createSelector(
    getRiderV2(rideId, riderId),
    getRiderProjectionByRideV2(rideId, riderId),
    (rider, projection): RideRiderWithProjectionV2 => {
      return {
        ...rider,
        ...(projection || {}),
      };
    },
  );

export const getRidersV2WithProjection = (rideId: string) =>
  createSelector(
    getRidersV2(rideId),
    getRidersProjectionByRideV2(rideId),
    (riders, projections): RideRiderWithProjectionV2[] => {
      return riders.map((rider) => {
        const projection = projections.find((r) => r.id === rider.riderId);
        return {
          ...rider,
          ...(projection || {}),
        };
      });
    },
  );

// district

const getDistrictV2RideProjection = (rideId: string) => selectRideV2Projection(rideId, 'district');

export const getDistrictIdByRideV2 = (rideId: string) =>
  createSelector(getDistrictV2RideProjection(rideId), (p) => p?.id);

// event photos
const selectEventPhotosProjectionByRide = (rideId: string) => selectRideV2Projection(rideId, 'campusLocationImages');

export const selectRidePhotos = (rideId: string) =>
  createSelector(
    selectEventPhotosProjectionByRide(rideId),
    getRideEventsV2(rideId),
    (photosProjection: CampusLocationImageV2Projection[], events): RideEventPhotoItem[] => {
      return (events || [])
        .map((event: RideEventV2) => {
          const images =
            (photosProjection || []).find((c) => c.eventId === event.eventId)?.campusLocationImagePath || [];
          return {
            eventId: event.eventId,
            name: event.scheduled.address.full,
            images,
          };
        })
        .filter((event) => event.images.length);
    },
  );

export const selectRidePhotosByEvent = (rideId: string, eventId: string) =>
  createSelector(selectRidePhotos(rideId), (state): RideEventPhotoItem[] => {
    return state.filter((event) => event.eventId === eventId);
  });

// notes (by event and by rider/event)
const eventNotesProjection = (rideId: string) => selectRideV2Projection(rideId, 'eventNote');

export const selectEventNotes = (rideId: string, eventId: string) =>
  createSelector(eventNotesProjection(rideId), (state) => state?.find((n) => n.eventId === eventId)?.note || null);

const riderEventNotesProjection = (rideId: string) => selectRideV2Projection(rideId, 'eventRiderNote');

export const selectNotesByRider = (rideId: string, riderId: string) =>
  createSelector(riderEventNotesProjection(rideId), (state) => {
    return state?.filter((n) => n.riderId === riderId) || [];
  });

export const selectRiderEventNotes = (rideId: string, eventId: string, riderId: string) =>
  createSelector(
    selectNotesByRider(rideId, riderId),
    (state) => state?.find((n) => n.eventId === eventId)?.note || null,
  );

// campuses

const selectCampusesProjectionByRide = (rideId: string) => selectRideV2Projection(rideId, 'campuses');

export const selectCampusByRide = (rideId: string, campusId: string) =>
  createSelector(selectCampusesProjectionByRide(rideId), (state) => state?.find((c) => c.id === campusId));

/**
 * Additional data (API)
 */

// polyline
export const selectAllRidePolylineStates = createSelector(getRideDataState, (state) => state.ridePolylines);

export const selectRidePolylineState = (rideId: string) =>
  createSelector(selectAllRidePolylineStates, (state) => state[rideId]);

export const selectRidePolyline = (rideId: string) =>
  createSelector(selectRidePolylineState(rideId), (state) => state?.entity?.polyline);

export const isRidePolylineLoading = (rideId: string) =>
  createSelector(selectRidePolylineState(rideId), (state) => state?.isLoading);

export const selectRidePolylineError = (rideId: string) =>
  createSelector(selectRidePolylineState(rideId), (state) => state?.error);

// ride driver fee
export const selectAllRideDriverFeeStates = createSelector(getRideDataState, (state) => state.rideDriverFees);

export const selectRideDriverFeeState = (rideId: string) =>
  createSelector(selectAllRideDriverFeeStates, (state) => state[rideId]);

export const selectRideDriverFee = (rideId: string) =>
  createSelector(selectRideDriverFeeState(rideId), (state) => state?.entity);

export const isRideDriverFeeLoading = (rideId: string) =>
  createSelector(selectRideDriverFeeState(rideId), (state) => state?.isLoading);

export const selectRideDriverFeeError = (rideId: string) =>
  createSelector(selectRideDriverFeeState(rideId), (state) => state?.error);

// end of ride inspections
export const selectAllRideEndOfRideInspectionStates = createSelector(
  getRideDataState,
  (state) => state.endOfRideInspections,
);

export const selectRideEndOfRideInspectionState = (rideId: string) =>
  createSelector(selectAllRideEndOfRideInspectionStates, (state) => state[rideId]);

export const selectEndOfRideInspectionByRide = (rideId: string) =>
  createSelector(
    selectRideEndOfRideInspectionState(rideId),
    (state): string | null => state?.entity?.endOfRideInspectionId || null,
  );

export const isEndOfRideInspectionLoading = (rideId: string) =>
  createSelector(selectRideEndOfRideInspectionState(rideId), (state) => state?.isLoading);

export const selectEndOfRideInspectionError = (rideId: string) =>
  createSelector(selectRideEndOfRideInspectionState(rideId), (state) => state?.error);

export const isManageRideDriverWithRouteGroupFlow = (rideId: string) =>
  createSelector(getRouteGroupIdByRideV2(rideId), (routeGroupId) => {
    return false;
    // return !!routeGroupId
  });

// show warning otherwise
export const isManageRideDriverWithRouteGroupFlowAllowed = (rideId: string) =>
  createSelector(
    getRideV2ScheduledStartYYYYMMDD(rideId),
    getRideTimezoneV2(rideId),
    (rideStartDate, timezone) => rideStartDate === yyyymmddToday(timezone),
  );
