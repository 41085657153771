import { VehicleListState } from '../../store/reducers/vehicle-lists.reducer';
import { SerializedVehicleListSnapshot } from '@rootTypes/entities/vehicles';

export const getSnapshotFromListState = (state: VehicleListState): SerializedVehicleListSnapshot => {
  const result: SerializedVehicleListSnapshot = {
    page: (state.pagination.page + 1).toString(),
    pageSize: state.pagination.pageSize.toString(),
  };
  if (state.currentSearch) {
    result.search = state.currentSearch;
  }
  if (state.filters?.length) {
    let filterStr = '';
    const multiAccountManagementFilters = state.filters.map((f) => f.id).join(';');
    filterStr += multiAccountManagementFilters;
    if (filterStr.length > 0) {
      result.filters = filterStr;
    }
  }
  return result;
};
