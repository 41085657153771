import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wp-page-top-title',
  templateUrl: './page-top-title.component.html',
  styleUrls: ['./page-top-title.component.scss'],
})
export class PageTopTitleComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
