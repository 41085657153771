import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';

export interface RideAssignmentRouteData {
  rideId: string;
}

const rideAssignmentPath = 'ride-assignment';

export const rideAssignmentRoute: PortalRoute<RideAssignmentRouteData> = {
  path: `${rideAssignmentPath}/:rideId`,
  isOnRoute: (state: RouterStateUrl) => {
    return state.drawer && state.drawer.url && state.drawer.url.startsWith(`_/drawer/${rideAssignmentPath}`);
  },
  parse: (state: RouterStateUrl): RideAssignmentRouteData => {
    return {
      rideId: state.drawer.params.rideId,
    };
  },
  request: (
    data: RideAssignmentRouteData,
    queryParamsHandling: QueryParamsHandlingStrategy = 'preserve',
  ): NavigationRequest => {
    const res: NavigationRequest = {
      path: ['_', 'drawer', rideAssignmentPath, data.rideId],
      extras: { queryParamsHandling },
    };
    return res;
  },
};
