import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, Observable, forkJoin, combineLatest } from 'rxjs';
import { filter, switchMap, take, map, catchError, mergeMap, withLatestFrom, tap, debounceTime } from 'rxjs/operators';

import {
  vehicleToAssignmentVehicle,
  driverToAssignmentDriver,
  DriversApiService,
  VehiclesApiService,
  SnackbarService,
  openErrorPopup,
  RideAssignmentRouterService,
  RideAssignmentDriver,
  RideAssignmentVehicle,
  RideAssignmentInitialVendor,
  VehicleDataResponse,
  GetDriverResponse,
} from '../../dependencies';

import * as fromActions from '../actions';
import * as fromSelectors from '../selectors';
import { RideAssignmentApiService } from '../../ride-assignment-api.service';
import { getDriverFeeByRideRequested, getRideV2Requested } from '../../../store/actions/rides-data.actions';
import {
  getFreshRideV2,
  selectRideDriverFee,
  selectRideV2Error,
} from '../../../store/selectors/ride-data-v2.selectors';
import { RideZumerFee } from '@rootTypes/entities/ride';
import { getRideStartDateLong } from '@rootTypes/utils/ride/get-ride-start-date-long';

interface AssignmentDetailsObservables {
  driverObj?: Observable<GetDriverResponse>;
  vehicleObj?: Observable<VehicleDataResponse>;
  loggedInVendor?: Observable<RideAssignmentInitialVendor>;
  completeIfEmpty: Observable<void>;
}

interface AssignmentDetails {
  driverObj?: GetDriverResponse;
  vehicleObj?: VehicleDataResponse;
  loggedInVendor?: RideAssignmentInitialVendor;
}

@Injectable()
export class RideAssignmentEffects {
  constructor(
    private actions: Actions,
    private driverApi: DriversApiService,
    private vehicleApi: VehiclesApiService,
    private assignmentApi: RideAssignmentApiService,
    private store: Store,
    private snackbar: SnackbarService,
    private router: RideAssignmentRouterService,
  ) {}

  public loadAssignmentDataRequested = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.loadRideAssignmentDataRequested),
      mergeMap(({ rideId }) => {
        this.store.dispatch(getRideV2Requested({ request: { rideId, _projections: ['driver', 'vehicle'] } }));
        this.store.dispatch(getDriverFeeByRideRequested({ rideId }));
        return combineLatest([
          this.store.select(getFreshRideV2(rideId)),
          this.store.select(selectRideDriverFee(rideId)),
          this.store.select(selectRideV2Error(rideId)),
        ]).pipe(
          debounceTime(0), // wait till ride isLoading flag is updated
          filter(([ride, zumerFeeData, error]) => !!(ride && zumerFeeData) || !!error),
          take(1),
          mergeMap(([ride, zumerFeeData, error]) => {
            const driverId = ride.driver?.driverId;
            const vehicleId = ride.vehicle?.vehicleId;
            const zumerFee: RideZumerFee = {
              suggestedFeeCents: zumerFeeData?.suggestedFeeCents || 0,
              existingFeeCents: zumerFeeData?.driverFeeCents || 0,
            };
            if (error) {
              console.log('error', error);
              return of(
                fromActions.loadRideAssignmentDataFailed({
                  error: {
                    text: 'Failed to load ride',
                    originalError: error.originalError,
                  },
                }),
              );
            }

            const assignmentDetailsObservables: AssignmentDetailsObservables = {
              completeIfEmpty: of(undefined),
            };
            if (driverId) {
              assignmentDetailsObservables.driverObj = this.driverApi
                .getDriver(driverId, { withVehicle: true, withVendor: true, withYard: true })
                .pipe(
                  filter((driver) => !!driver),
                  take(1),
                );
            }
            if (vehicleId) {
              assignmentDetailsObservables.vehicleObj = this.vehicleApi
                .getVehicle(vehicleId, { withDriver: true, withVendor: !driverId })
                .pipe(
                  filter((vehicle) => !!vehicle),
                  take(1),
                );
            }
            if (!driverId && !vehicleId && wpEnvironment.userRole === 'vendor') {
              assignmentDetailsObservables.loggedInVendor = this.assignmentApi.getLoggedInVendorInfo();
            }

            return forkJoin<{
              driverObj?: Observable<GetDriverResponse>;
              vehicleObj?: Observable<VehicleDataResponse>;
              loggedInVendor?: Observable<RideAssignmentInitialVendor>;
            }>(assignmentDetailsObservables).pipe(
              map((data: AssignmentDetails) => {
                const { driverObj, vehicleObj, loggedInVendor } = data;
                let driver: RideAssignmentDriver;
                if (driverObj) {
                  driver = this.driverAggregationToAssignmentDriver(driverObj);
                }
                let vehicle: RideAssignmentVehicle;
                if (vehicleObj) {
                  vehicle = vehicleToAssignmentVehicle(vehicleObj.vehicle, vehicleObj.driver);
                }
                let initialVendor: RideAssignmentInitialVendor;
                if (driverObj?._projections.vendor) {
                  initialVendor = {
                    vendorId: driverObj._projections.vendor.id,
                    vendorName: driverObj._projections.vendor.name,
                    yardId: driverObj._projections.yard.id,
                  };
                } else if (vehicleObj?.vendor) {
                  initialVendor = {
                    vendorId: vehicleObj.vendor.id,
                    vendorName: vehicleObj.vendor.name,
                    yardId: vehicleObj.vehicle.yardId,
                  };
                } else if (loggedInVendor) {
                  initialVendor = loggedInVendor;
                }
                const startDateDisplay = getRideStartDateLong(ride.startTimestamp.scheduled, ride.timezone);
                return fromActions.loadRideAssignmentDataSuccess({
                  data: {
                    rideId,
                    rideDisplayId: ride.displayId,
                    rideStatus: ride.status,
                    rideDisplayStartDateLong: startDateDisplay,
                    zumerFee,
                    driver,
                    vehicle,
                    initialVendor,
                  },
                });
              }),
            );
          }),
          catchError((originalError) => {
            console.log('originalError', originalError);
            return of(
              fromActions.loadRideAssignmentDataFailed({
                error: {
                  text: 'Failed to driver / vehicle assignment data',
                  originalError,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  public loadNewDriverSelected = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.newDriverSelected),
      filter(({ driverId }) => !!driverId),
      switchMap(({ driverId }) => {
        return this.driverApi.getDriver(driverId, { withVehicle: true, withYard: true }).pipe(
          map((res) => {
            return fromActions.loadNewDriverSuccess({
              driver: this.driverAggregationToAssignmentDriver(res),
            });
          }),
          catchError((originalError) => {
            return of(
              fromActions.loadNewDriverFailed({
                error: {
                  text: 'Failed to load driver',
                  originalError,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  public loadNewVehicleSelected = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.newVehicleSelected),
      filter(({ vehicleId }) => !!vehicleId),
      switchMap(({ vehicleId }) => {
        return this.vehicleApi.getVehicle(vehicleId, { withDriver: true }).pipe(
          map((res) => {
            return fromActions.loadNewVehicleSuccess({
              vehicle: vehicleToAssignmentVehicle(res.vehicle, res.driver),
            });
          }),
          catchError((originalError) => {
            return of(
              fromActions.loadNewVehicleFailed({
                error: {
                  text: 'Failed to load vehicle',
                  originalError,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  public submitRequested = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.submitRideAssignmentRequested),
      withLatestFrom(this.store.select(fromSelectors.getSubmitRideAssignmentRequest)),
      mergeMap(([action, request]) => {
        return this.assignmentApi.submitRideAssignment(request).pipe(
          map(() => fromActions.submitRideAssignmentSuccess()),
          catchError((originalError) => {
            return of(
              fromActions.submitRideAssignmentFailed({
                error: {
                  text: 'Failed to submit ride assignment',
                  originalError,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  public submitSuccess = createEffect(
    () =>
      this.actions.pipe(
        ofType(fromActions.submitRideAssignmentSuccess),
        tap(() => {
          setTimeout(() => {
            this.snackbar.success('Driver / vehicle assignment changed successfully');
          }, 1500);
          this.router.back();
        }),
      ),
    { dispatch: false },
  );

  public submitFailed = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.submitRideAssignmentFailed),
      map(({ error }) => openErrorPopup({ error })),
    ),
  );

  private driverAggregationToAssignmentDriver(driverObj: GetDriverResponse): RideAssignmentDriver {
    const defaultVehicle = driverObj._projections.vehicle
      ? vehicleToAssignmentVehicle(driverObj._projections.vehicle, driverObj.driver)
      : undefined;
    return driverToAssignmentDriver(driverObj.driver, defaultVehicle, driverObj._projections.yard);
  }
}
