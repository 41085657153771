import { SerializedYardListSnapshot, YardListSnapshot } from '../entities';
import * as ui from '../ui';

export const getSerializedFromSnapshot = (source: YardListSnapshot): SerializedYardListSnapshot => {
  const { page, pageSize, search, status } = source;
  const pageStr = page === ui.DEFAULT_YARD_LIST_PAGE ? undefined : '' + (page + 1);
  const pageSizeStr = pageSize === ui.DEFAULT_YARD_LIST_PAGE_SIZE ? undefined : '' + pageSize;
  const searchStr = search && search.length ? search : undefined;
  const statusStr: string = status === ui.DEFAULT_YARD_LIST_STATUS ? undefined : '' + status;
  const res: SerializedYardListSnapshot = {
    page: pageStr,
    pageSize: pageSizeStr,
    search: searchStr,
    status: statusStr,
  };
  return res;
};
