import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { StudentsApiService } from '../../services/students-api.service';
import * as fromActions from '../actions/student-change-log.actions';
import * as fromSelectors from '../selectors/student-change-log.selectors';

@Injectable()
export class StudentChangeLogEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private api: StudentsApiService,
  ) {}

  public loadLogsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadStudentChangeLogRequested),
      withLatestFrom(this.store.select(fromSelectors.getChangeLogStudentId)),
      mergeMap(([, studentId]) => {
        return this.api.getStudentChangeRequestLogs(studentId).pipe(
          map((response) => fromActions.loadStudentChangeLogSuccess({ response })),
          catchError((originalError) => {
            return of(
              fromActions.loadStudentChangeLogFailed({
                error: {
                  text: 'Failed to load change logs',
                  originalError,
                },
              }),
            );
          }),
        );
      }),
    ),
  );
}
