import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractPopupComponent } from '../../core/popupable/types';
import { PopupRef } from '../../core/popupable/types/popup-ref';
import { ErrorStatements } from '../../store/services/error-parser.service';

@Component({
  selector: 'wp-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPopupComponent implements OnInit, AbstractPopupComponent<any, any> {
  popupRef: PopupRef<any, any>;
  public errorStatements: ErrorStatements;
  public errorToCopy: string;
  public confirmButtonText: string;
  public hideErrorHeader: boolean;
  constructor() {}

  ngOnInit(): void {
    this.errorStatements = this.popupRef.data.errorStatements;
    this.hideErrorHeader = this.popupRef.data.hideErrorHeader;
    this.errorToCopy = JSON.stringify(this.popupRef.data.error);
    this.confirmButtonText = this.popupRef.data.confirmBtnText;
  }

  onClose() {
    this.popupRef.close();
  }
}
