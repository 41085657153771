export const getPathToEnterAppAfterAuth = (relayState?: string): string | '' => {
  const defaultPath = '';
  if (!relayState) {
    return defaultPath;
  }
  if (relayState) {
    const path = decodeURIComponent(relayState);
    if (path === '/') {
      return defaultPath;
    }
    return path;
  }
  return defaultPath;
};
