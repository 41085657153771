export enum RouteListNames {
  MAIN_ROUTE_LIST = 'MAIN_ROUTE_LIST',
  DISTRICT_DETAILS_ROUTE_LIST = 'DISTRICT_DETAILS_ROUTE_LIST',
  STUDENT_DETAILS_ROUTE_LIST = 'STUDENT_DETAILS_ROUTE_LIST',
  DRIVER_DETAILS_ROUTE_LIST = 'DRIVER_DETAILS_ROUTE_LIST',
  CAMPUS_DETAILS_ROUTE_LIST = 'CAMPUS_DETAILS_ROUTE_LIST',
  YARD_DETAILS_ROUTE_LIST = 'YARD_DETAILS_ROUTE_LIST',
}

export const routeLists = [
  RouteListNames.MAIN_ROUTE_LIST,
  RouteListNames.DISTRICT_DETAILS_ROUTE_LIST,
  RouteListNames.STUDENT_DETAILS_ROUTE_LIST,
  RouteListNames.DRIVER_DETAILS_ROUTE_LIST,
  RouteListNames.CAMPUS_DETAILS_ROUTE_LIST,
  RouteListNames.YARD_DETAILS_ROUTE_LIST,
];
