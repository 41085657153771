import { ApiPermissionEntities, ApiPermissionItem, ApiPermissionType } from '@apiEntities/employees/api-permissions';

export interface EmployeePermission {
  entityId: string | SpecialPermissions;
  entityName?: string;
  permission?: ApiPermissionItem;
}

export type PermissionItem = ApiPermissionItem;

export type PermissionEntities = ApiPermissionEntities;

export type PermissionType = ApiPermissionType;

export enum SpecialPermissions {
  ALL = 'ALL',
}
