<div class="rich-option-card">
  <div class="card-left">
    <div class="icon">
      <ng-content select=".icon"></ng-content>
    </div>
    <div class="text-content">
      <div class="title">
        <wp-title-five>
          <ng-content select=".title"></ng-content>
        </wp-title-five>
      </div>
      <div class="subtitle">
        <ng-content select=".subtitle"></ng-content>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
  <div class="card-right">
    <ng-content select=".card-right"></ng-content>
  </div>
</div>
