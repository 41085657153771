import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { rideAssignmentFeatureKey, rideAssignmentFeatureReducer, rideAssignmentFeatureEffects } from './store';

@NgModule({
  declarations: [],
  providers: [],
  imports: [
    StoreModule.forFeature(rideAssignmentFeatureKey, rideAssignmentFeatureReducer),
    EffectsModule.forFeature(rideAssignmentFeatureEffects),
  ],
})
export class RideAssignmentStoreModule {}
