<div class="wp-popup-page-top__wrap">
  <div class="wp-popup-page-top__back-bar-wrap">
    <ng-content select="wp-popup-page-back-bar"></ng-content>
  </div>
  <div class="wp-popup-page-top">
    <div class="wp-popup-page-top__left">
      <div class="wp-popup-page__title">
        <ng-content select="wp-popup-page-title"></ng-content>
      </div>
      <div class="wp-popup-page__subtitle">
        <ng-content select="wp-popup-page-subtitle"></ng-content>
      </div>
    </div>
    <div class="wp-popup-page-top__right">
      <div class="wp-popup-page-top__right__actions">
        <ng-content select="wp-popup-page-top-right"></ng-content>
      </div>
      <div
        *ngIf="isCloseBtn"
        class="wp-popup-page-top__right__close"
        (click)="onCloseClick()"
      >
        <wp-icon-close></wp-icon-close>
      </div>
    </div>
  </div>
  <div class="wp-popup-page-top__line" *ngIf="borderBottom"></div>
</div>
