import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-divider',
  styleUrls: ['./divider.component.scss'],
  template: `
    <div class="wp-divider">
      <div class="wp-divider_line" [ngStyle]="lineOption()"></div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DividerComponent implements OnInit {
  @Input() public width = 'auto';
  @Input() public marginY = '30px';
  constructor() {}

  ngOnInit(): void {}

  public lineOption(): any {
    return {
      width: this.width,
      margin: `${this.marginY} 0`,
    };
  }
}
