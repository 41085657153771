<mat-form-field
  class="wp-textarea"
  [floatLabel]="floatLabel"
  [hideRequiredMarker]="true"
  [style.line-height]="'23px'"
  [style.font-size]="'14px'"
>
  <mat-label>{{label}}</mat-label>
  <div matPrefix>
    <ng-content select="wp-input-prefix"></ng-content>
  </div>
  <textarea
    matInput
    style="resize: none; box-sizing: content-box; height: auto"
    [placeholder]="placeholder"
    [formControl]="control"
    [required]="required"
    [maxLength]="maxLength"
    [readonly]="readonly"
    tabindex="{{tabIndex}}"
    #textAreaElement
    rows="1"
    (input)="onInputChange($event)"
    (focus)="onTextAreaFocus()"
  >
  </textarea>
  <div matSuffix>
    <ng-content select="wp-input-suffix"></ng-content>
  </div>

  <mat-hint *ngIf="!!hintMsg" class="hint-message"> {{ hintMsg }} </mat-hint>

  <mat-hint *ngIf="!!maxLength && characterCounter">
    {{ control.value?.length ?? 0 }} / {{ maxLength }}
  </mat-hint>

  <mat-error *ngIf="control.invalid && control.hasError('required')">
    Required field
  </mat-error>
  <mat-error
    *ngIf="customErrorMessageKey && !!control.errors && !!control.errors[customErrorMessageKey]"
  >
    {{ control.errors[customErrorMessageKey] }}
  </mat-error>
</mat-form-field>
