<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  [ngClass]="{
    grey: grey,
    'dark-grey': darkGrey
  }"
>
  <g fill="none" fill-rule="evenodd">
    <g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g
                        transform="translate(-1278 -280) translate(486) translate(0 200) translate(27 72) translate(3) translate(742) translate(20 8) translate(.833 .833)"
                      >
                        <circle
                          cx="9.167"
                          cy="9.167"
                          r="8.167"
                          stroke="#47A540"
                          stroke-width="2"
                        />
                        <path
                          fill="#47A540"
                          d="M10 8.333h4.167V10H10v4.167H8.333V10H4.167V8.333h4.166V4.167H10v4.166z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
