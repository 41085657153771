import { createReducer, on } from '@ngrx/store';

import * as fromActions from '../actions/vehicle-details.actions';
import { VehicleDetailsTab } from '@rootTypes/entities/vehicles';

export interface VehicleDetailsState {
  vehicleId?: string;
  currentTab?: VehicleDetailsTab;
}

export const createInitialVehicleDetailsState = (): VehicleDetailsState => {
  return {};
};

export const vehicleDetailsReducer = createReducer(
  createInitialVehicleDetailsState(),
  on(fromActions.destroyVehicleDetails, createInitialVehicleDetailsState),
  on(fromActions.initVehicleDetails, (state, { vehicleId, tab }): VehicleDetailsState => {
    return {
      vehicleId,
      currentTab: tab,
    };
  }),
  on(fromActions.vehicleDetailsTabChanged, (state, { tab }): VehicleDetailsState => {
    return {
      ...state,
      currentTab: tab,
    };
  }),
);
