export const fitMapToLocations = (map: google.maps.Map, locations: google.maps.LatLngLiteral[]): void => {
  if (locations.length) {
    if (locations.length === 1) {
      map.setCenter(new google.maps.LatLng(locations[0].lat, locations[0].lng));
    } else {
      const bounds = new google.maps.LatLngBounds();
      locations.forEach((l) => bounds.extend(l));
      const mapBounds = map.getBounds();
      if (!mapBounds) {
        return;
      }
      const contains = mapBounds.contains(bounds.getNorthEast()) && mapBounds.contains(bounds.getSouthWest());
      if (!contains) {
        map.fitBounds(bounds);
      }
    }
  }
};
