import { ApiStudentCampus, ApiStudentPastProgram, ApiStudentProgram, ApiStudentWithProjections } from '@apiEntities';
import { StudentProgramCard } from '../student-program-card';

export const getStudentPrograms = (
  campuses: ApiStudentCampus[],
  programs: ApiStudentProgram[],
  student: ApiStudentWithProjections,
): StudentProgramCard[] => {
  return campuses.map((campus) => {
    const program = programs.find((program) => campus.districtProgramId === program.id);
    const addresses = student.addresses.filter((address) => address.districtProgramId === program.id);
    return {
      programId: program.id,
      studentId: student.id,
      campusId: campus.campusId,
      programName: program.name,
      campusName: campus.campusName,
      grade: campus.grade,
      isParentVerified: false, // waiting api for model
      addresses: addresses,
    };
  });
};

export const getStudentPastPrograms = (
  pastPrograms: ApiStudentPastProgram[],
  student: ApiStudentWithProjections,
): StudentProgramCard[] => {
  return pastPrograms.map((program) => {
    return {
      programId: program.program.id,
      studentId: student.id,
      campusId: program.campus.campusId,
      programName: program.program.name,
      campusName: program.campus.campusName,
      grade: program.campus.grade,
      isParentVerified: false, // waiting api for model
      addresses: program.addresses,
    };
  });
};
