import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wp-side-bar-top',
  templateUrl: './side-bar-top.component.html',
  styleUrls: ['./side-bar-top.component.scss'],
})
export class SideBarTopComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
