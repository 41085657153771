import { Component, ChangeDetectionStrategy, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'wp-common-popup-page',
  templateUrl: './common-popup-page.component.html',
  styleUrls: ['./common-popup-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonPopupPageComponent implements AfterViewInit {
  @Input() padding = '44px 40px 35px 40px';
  @Input() isDarkMode = false;

  @ViewChild('container') private container: ElementRef<HTMLDivElement>;

  ngAfterViewInit(): void {
    if (this.container?.nativeElement) {
      this.container.nativeElement.focus();
    }
  }
}
