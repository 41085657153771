import { YYYYMMDDString } from '@rootTypes';

export interface RouteFilterRequest {
  name?: string;
  routeIds?: Array<string>;
  rideIds?: Array<string>;
  districtIds?: Array<string>;
  routeStatuses?: Array<RouteFilterRequestStatuses>;
  routeDirections?: Array<string>;

  parentIds?: Array<string>;
  studentIds?: Array<string>;
  campusIds?: Array<string>;

  driverIds?: Array<string>;
  vendorIds?: Array<string>;
  vehicleIds?: Array<string>;
  assignmentStatuses?: Array<string>;
  operatorIds?: Array<string>;
  yardIds?: Array<string>; // test, if this field works
  routeGroupIds?: Array<string>;
  date?: {
    from?: YYYYMMDDString;
    to?: YYYYMMDDString;
  };

  zoneIds?: Array<string>;
  regionIds?: Array<string>;

  skip: number;
  limit: number;
}

export enum RouteFilterRequestStatuses {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  INACTIVE = 'INACTIVE',
}
