import { PortalListDateRangeFilter, PortalListFilter } from '@rootTypes/utils/list-store-utils/portal-list-filter';
import { PortalListBaseParams, PortalListBaseState } from '@rootTypes/utils/list-store-utils/portal-list-base-state';
import { FinanceTransactionAggregateResult } from '../../../api/endpoints/entity-aggregate';
import { DateRange } from '@rootTypes';
import { YearMonthDay } from '@rootTypes/utils/common/date';

export type AccountSelectListFilter = PortalListDateRangeFilter | AccountSelectListQuickFilter;

export type AccountSelectListParams = PortalListBaseParams<AccountSelectListFilter>;

export type AccountSelectListState = PortalListBaseState<AccountSelectListParams, FinanceTransactionAggregateResult>;

export enum AccountSelectListQuickFilterValue {
  LAST_WEEK = 'last-week',
  LAST_MONTH = 'last-month',
  CURRENT_MONTH = 'current-month',
}

export interface AccountSelectListQuickFilter extends PortalListFilter {
  type: 'quick-filter';
  payload: AccountSelectListQuickFilterValue;
}

export const accountSelectQuickFilterConfig = {
  [AccountSelectListQuickFilterValue.LAST_WEEK]: {
    label: 'Last week',
    rangeGetter: () => {
      return {
        startDate: new YearMonthDay(YearMonthDay.today()).startSundayOfPreviousWeek(),
        endDate: new YearMonthDay(YearMonthDay.today()).endSaturdayOfPreviousWeek(),
      } as DateRange;
    },
  },
  [AccountSelectListQuickFilterValue.LAST_MONTH]: {
    label: 'Last month',
    rangeGetter: () => {
      return {
        startDate: new YearMonthDay(YearMonthDay.today()).startOfPreviousMonth(),
        endDate: new YearMonthDay(YearMonthDay.today()).endOfPreviousMonth(),
      } as DateRange;
    },
  },
  [AccountSelectListQuickFilterValue.CURRENT_MONTH]: {
    label: 'Current month',
    rangeGetter: () => {
      return {
        startDate: new YearMonthDay(YearMonthDay.today()).startOfMonth(),
        endDate: new YearMonthDay(YearMonthDay.today()).endOfMonth(),
      } as DateRange;
    },
  },
};

export const getAccountSelectListQuickFilter = (
  value: AccountSelectListQuickFilterValue,
): AccountSelectListQuickFilter => ({
  type: 'quick-filter',
  payload: value,
  id: `quick-filter,${value}`,
  label: accountSelectQuickFilterConfig[value].label,
});

export const accountSelectQuickFilterDeserializer = (source: string): AccountSelectListQuickFilter | undefined => {
  if (source.startsWith('quick-filter,')) {
    const value = source.split(',')[1] as AccountSelectListQuickFilterValue;
    return getAccountSelectListQuickFilter(value);
  }
  return undefined;
};
