import { createSelector } from '@ngrx/store';
import { getEmployeesFeature } from './get-feature';
import {
  getAllEmployeeEntities,
  getAllEmployeePermissions,
  getAllPermissionOptionState,
} from './employee-data.selectors';
import { getEmployeeId } from './employee-details.selectors';
import { getAllDistrictsState } from '../../../districts/store/selectors';

export const getPermissionsManagerState = createSelector(getEmployeesFeature, (state) => state.permissionManager);

export const getOpenEmployeeId = createSelector(getPermissionsManagerState, (state) => state.employeeId);

const getOpenedEmployeeEntityState = createSelector(
  getEmployeeId,
  getAllEmployeeEntities,
  (employeeId, allEmployees) => {
    if (!employeeId) {
      return undefined;
    }
    return allEmployees[employeeId];
  },
);

export const getOpenedEmployee = createSelector(getOpenedEmployeeEntityState, (state) => state?.entity);

export const getOpenedEmployeeLoading = createSelector(getOpenedEmployeeEntityState, (state) => state?.isLoading);

export const getOpenedEmployeeError = createSelector(getOpenedEmployeeEntityState, (state) => state?.error);

const getOpenEmployeePermissionOptionState = createSelector(
  getOpenEmployeeId,
  getAllPermissionOptionState,
  (employeeId, allOptions) => allOptions[employeeId],
);
// permissions
export const getOpenEmployeePermissions = createSelector(
  getEmployeeId,
  getAllEmployeePermissions,
  (employeeId, allPermissions) => allPermissions[employeeId],
);

// permission options
export const getOpenEmployeePermissionOptions = createSelector(
  getOpenEmployeePermissionOptionState,
  (state) => state.entity,
);

export const getOpenEmployeePermissionOptionsLoading = createSelector(
  getOpenEmployeePermissionOptionState,
  (state) => state.isLoading,
);

export const getOpenEmployeePermissionOptionsError = createSelector(
  getOpenEmployeePermissionOptionState,
  (state) => state.error,
);

// district by open employee
const getOpenEmployeeDistrictState = createSelector(
  getOpenedEmployee,
  getAllDistrictsState,
  (employee, allDistricts) => {
    if (!employee) {
      return undefined;
    }
    return allDistricts[employee.parentEntityId];
  },
);

export const getDistrictByOpenEmployee = createSelector(getOpenEmployeeDistrictState, (state) => state.entity);
export const getDistrictLoadingByOpenEmployee = createSelector(
  getOpenEmployeeDistrictState,
  (state) => state.isLoading,
);
export const getDistrictErrorByOpenEmployee = createSelector(getOpenEmployeeDistrictState, (state) => state.error);

// update permissions request
export const getUpdatePermissionsLoading = createSelector(getPermissionsManagerState, (state) => state.isLoading);

export const getUpdatePermissionsLoaded = createSelector(getPermissionsManagerState, (state) => state.isLoaded);
