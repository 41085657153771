<div class="wp-popup-page-top-photo-wrap">
  <div class="wp-popup-page-top__back-bar-wrap">
    <ng-content select="wp-popup-page-back-bar"></ng-content>
  </div>
  <div class="wp-popup-page-top-photo">
    <div class="wp-popup-page-top-photo__left">
      <div class="wp-popup-page__photo">
        <ng-content select="wp-popup-page-photo"></ng-content>
      </div>
      <div class="wp-popup-page-top-photo__left__title-wrapper">
        <div class="wp-popup-page__title">
          <ng-content select="wp-popup-page-title"></ng-content>
        </div>
        <div class="wp-popup-page__subtitle">
          <ng-content select="wp-popup-page-subtitle"></ng-content>
        </div>
      </div>
    </div>
    <div class="wp-popup-page-top-photo__right">
      <div class="wp-popup-page-top-photo__right__actions">
        <ng-content select="wp-popup-page-top-right"></ng-content>
      </div>
      <div
        class="wp-popup-page-top-photo__right__close"
        *ngIf="isCloseBtn"
        (click)="onCloseClick()"
      >
        <wp-icon-close></wp-icon-close>
      </div>
    </div>
  </div>
  <div class="wp-popup-page-top-photo__line" *ngIf="borderBottom"></div>
</div>
