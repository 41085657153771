import { inject, Injectable } from '@angular/core';
import { SessionStorageService } from './storage.service';
import { CanActivateFn } from '@angular/router';

const userLocalSettingsKey = 'userLocalSettings';

export interface UserLocalSettings {
  dashboardVehicleMapView?: {
    isSatelliteView?: boolean;
  };
  dashboardRideMapView?: {
    isSatelliteView?: boolean;
  };
  routeExplorer?: {
    isSatelliteView?: boolean;
  };
}

@Injectable({ providedIn: 'root' })
export class UserLocalSettingsService {
  public settings: UserLocalSettings | null;

  constructor(private sessionStorageService: SessionStorageService) {}

  public async resolveUserLocalSettings(forceRefresh?: boolean): Promise<UserLocalSettings> {
    if (!this.settings || forceRefresh) {
      this.settings = this.sessionStorageService.get(this.key(), true);
    }
    return Promise.resolve(this.settings);
  }

  public async patchUserLocalSettings<K extends keyof UserLocalSettings>(patch: {
    [k in K]: Partial<UserLocalSettings[K]>;
  }): Promise<void> {
    const settings: UserLocalSettings = (await this.resolveUserLocalSettings()) ?? {};
    Object.keys(patch).forEach((keyToUpdate) => {
      const prevK = settings[keyToUpdate] ?? {};
      settings[keyToUpdate] = {
        ...prevK[keyToUpdate],
        ...patch[keyToUpdate],
      };
    });
    this.settings = settings;
    this.sessionStorageService.set(this.key(), this.settings);
  }

  private key(): string {
    return userLocalSettingsKey;
  }
}

export const userLocalSettingsCanActivateFn: CanActivateFn = () => {
  const userLocalSettingsService = inject(UserLocalSettingsService);
  return userLocalSettingsService.resolveUserLocalSettings(true).then(() => true);
};
