import { CalendarExclusion, DistrictCalendar } from '../../../../types/entities/districts';
import { GetZumEnterpriseDetailsResponse } from '../response';
import { getCalendarExclusionFromApi } from '../../common/convertors';

export const getDistrictCalendarFromEnterpriseDb = (
  source: GetZumEnterpriseDetailsResponse['calendar'],
): DistrictCalendar => {
  if (!source) {
    return null;
  }
  const res: DistrictCalendar = {
    id: source.calendarId,
    name: source.name,
    startDate: source.startDate,
    endDate: source.endDate,
    exclusions: getExclusionsFromDbCalendar(source.exclusions),
  };
  return res;
};

function getExclusionsFromDbCalendar(
  source: GetZumEnterpriseDetailsResponse['calendar']['exclusions'],
): CalendarExclusion[] {
  if (!source) {
    return [];
  }
  return source.map((s) => getCalendarExclusionFromApi(s));
}
