<div
  (click)="onClicked.emit()"
  class="wp-input-suffix"
  [ngStyle]="{
    marginRight: marginRight,
    top: moveDown,
    left: moveRight
  }"
>
  <ng-content></ng-content>
</div>
