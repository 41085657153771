export enum PolylineStyle {
  SOLID = 'SOLID',
  DASHED = 'DASHED',
}

export type PolylineOptions = google.maps.PolylineOptions;

export const defaultTracePolylineOptions = {
  strokeOpacity: 0.9,
  strokeColor: '#7fb2da',
  strokeWeight: 8,
  zIndex: 4,
};

export const defaultGapPolylineOptions = {
  strokeOpacity: 0,
  icons: [
    {
      icon: {
        path: 'M 0,-3 0, 3',
        strokeOpacity: 0.6,
        scale: 1,
        strokeColor: '#222',
        strokeWeight: 1,
      },
      offset: '0',
      repeat: '15px',
    },
  ],
};
