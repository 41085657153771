<div class="remove-btn">
  <div class="bin-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
    >
      <g
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <g class="bin-svg" stroke="#979797" stroke-width="2">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <path
                            d="M.108 16.969h11.96c3.314 0 6-2.686 6-6s-2.686-6-6-6H2.143"
                            transform="translate(-1346 -295) translate(486 41) translate(30 229) translate(0 25) translate(830) translate(.932 .031)"
                          />
                          <path
                            d="M5.07 0.974L1.075 4.938 5.099 8.92"
                            transform="translate(-1346 -295) translate(486 41) translate(30 229) translate(0 25) translate(830) translate(.932 .031)"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
  <div class="remove-text">
    <ng-content></ng-content>
  </div>
</div>
