import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getChangeRequestsForStudentFailed,
  getChangeRequestsForStudentSuccess,
  loadChangeRequestsActions,
} from '../actions/student-change-requests-data.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StudentsApiService } from '../../services/students-api.service';
import { WpError } from '@rootTypes';
import { of } from 'rxjs';

@Injectable()
export class StudentChangeRequestsDataEffects {
  constructor(
    private actions$: Actions,
    private api: StudentsApiService,
  ) {}

  public getChangeRequestsForStudent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...loadChangeRequestsActions),
      switchMap(({ studentId }) => {
        return this.api.getPendingStudentChangeRequestsByStudent(studentId).pipe(
          map((result) => {
            return getChangeRequestsForStudentSuccess({ studentId, result });
          }),
          catchError((originalError) => {
            console.error(originalError);
            const error: WpError = {
              text: 'Failed to load pending change requests by student',
              originalError,
            };
            return of(getChangeRequestsForStudentFailed({ studentId, error }));
          }),
        );
      }),
    ),
  );
}
