import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import { combineLatest, Observable } from 'rxjs';
import * as fromTypes from '../../types';
import * as fromActions from '../actions/yard-list.actions';
import * as fromSelectors from '../selectors/yard-lists.selectors';
import { map, switchMap } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class YardListFacade {
  constructor(
    private store: Store<State>,
    private actions$: Actions,
  ) {}

  public getListLoading$(listName: fromTypes.YardListName): Observable<boolean> {
    return this.store.select(fromSelectors.getListLoading, { listName });
  }

  public getListEmpty$(listName: fromTypes.YardListName): Observable<boolean> {
    const loading$ = this.getListLoading$(listName);
    const listItems$ = this.getListItems$(listName);
    return combineLatest([loading$, listItems$]).pipe(map(([loading, items]) => !loading && items.length === 0));
  }

  public getListSerializedSnapshot(listName: fromTypes.YardListName): Observable<fromTypes.SerializedYardListSnapshot> {
    return this.actions$.pipe(
      ofType(fromActions.loadYardListSuccess),
      switchMap(() => this.store.select(fromSelectors.getYardListState, { listName })),
      map((listState) => {
        return fromTypes.utils.getSerializedFromSnapshot({
          page: listState.pagination.page,
          pageSize: listState.pagination.pageSize,
          search: listState.currentSearch,
          status: listState.status,
          filters: [],
        });
      }),
    );
  }

  public getListItems$(listName: fromTypes.YardListName): Observable<fromTypes.PortalEntity[]> {
    return this.store.select(fromSelectors.getListItems, { listName });
  }

  public getListError$(listName: fromTypes.YardListName): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getListError, { listName });
  }

  public getTotalFound(listName: fromTypes.YardListName): Observable<number> {
    return this.store.select(fromSelectors.getTotalFound, { listName });
  }

  public getPage$(listName: fromTypes.YardListName): Observable<number> {
    return this.store.select(fromSelectors.getPage, { listName });
  }

  public getCurrentSearch(listName: fromTypes.YardListName): Observable<string> {
    return this.store.select(fromSelectors.getCurrentSearch, { listName });
  }

  public getCurrentStatus(listName: fromTypes.YardListName): Observable<fromTypes.EntityStatus> {
    return this.store.select(fromSelectors.getCurrentStatus, { listName });
  }

  public getPageSize$(listName: fromTypes.YardListName): Observable<number> {
    return this.store.select(fromSelectors.getPageSize, { listName });
  }

  public getSelectedYardId(listName: fromTypes.YardListName): Observable<string> {
    return this.store.select(fromSelectors.getSelectedYardId, { listName });
  }

  public onInitialized(listName: fromTypes.YardListName, serialized?: fromTypes.SerializedYardListSnapshot): void {
    const snapshot = fromTypes.utils.getSnapshotFromSerialized(serialized);
    this.store.dispatch(fromActions.yardListInitialized({ listName, snapshot }));
  }

  public onCardInitialized(listName: fromTypes.YardListName, yardId: string): void {
    this.store.dispatch(fromActions.listItemInitialized({ listName, yardId }));
  }

  public onCardDestroyed(listName: fromTypes.YardListName, yardId: string): void {
    this.store.dispatch(fromActions.listItemDestroyed({ listName, yardId }));
  }

  public onPageChanged(listName: fromTypes.YardListName, page: number): void {
    this.store.dispatch(fromActions.pageChanged({ listName, page }));
  }

  public onPageSizeChanged(listName: fromTypes.YardListName, pageSize: number): void {
    this.store.dispatch(fromActions.pageSizeChanged({ listName, pageSize }));
  }

  public onSearchChanged(listName: fromTypes.YardListName, search: string): void {
    this.store.dispatch(fromActions.searchChanged({ listName, search }));
  }

  public onSelectedYardChanged(listName: fromTypes.YardListName, yardId: string): void {
    this.store.dispatch(fromActions.selectedYardChanged({ listName, yardId }));
  }

  public onStatusChanged(listName: fromTypes.YardListName, status: fromTypes.EntityStatus): void {
    this.store.dispatch(fromActions.statusChanged({ listName, status }));
  }

  public onSetStaticEntityFilters(listName: fromTypes.YardListName, entities: fromTypes.PortalEntity[]): void {
    const filters: fromTypes.YardListFilter[] = entities.map((entity) => {
      const id = fromTypes.utils.encodeDataComponents([entity.type, entity.entityId]);
      return {
        id,
        type: fromTypes.YardListFilterType.ENTITY,
        payload: entity,
      };
    });
    this.store.dispatch(fromActions.staticFiltersChanged({ listName, filters }));
  }
}
