<div class="map-content">
  <div class="recenter-button-wrap">
    <wp-recenter-button (click)="onRecenterClik()" />
  </div>

  <div #map class="map-div"></div>

  <ng-container *ngIf="mapTilesLoading$ | async">
    <div class="map-tiles-loading-mask">
      <wp-section-spinner [sectionHeight]="'40px'" [spinnerSize]="40" />
    </div>
  </ng-container>
</div>
