import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'wp-section-title-gray-full-width',
  template: `
    <div [ngStyle]="{ padding, margin }" class="section-title-gray-full-width">
      <ng-content></ng-content>
    </div>
  `,
  styles: `
    .section-title-gray-full-width {
      font-size: 16px;
      font-weight: 600;
      width: 100%;
      background-color: #f8f8fc;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionTitleGrayFullWidthComponent {
  @Input() public padding = '10px 34px';
  // margin is used to offset paddings of the drawer, change if needed
  @Input() public margin = '-32px -22px 0 -32px';
}
