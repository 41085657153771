import * as fromRootDetails from './campus-details.reducer';
import * as fromStudents from './students-tab.reducer';
import * as fromCalendars from './calendars-tab.reducer';
import * as fromLocations from './locations-tab.reducer';
import { combineReducers } from '@ngrx/store';

export interface CampusDetailsState {
  root: fromRootDetails.CampusDetailsState;
  students: fromStudents.StudentsTabState;
  calendars: fromCalendars.CalendarsTabState;
  locations: fromLocations.LocationsTabState;
}

export const campusDetailsReducer = combineReducers<CampusDetailsState>({
  root: fromRootDetails.campusDetailsReducer,
  students: fromStudents.studentsTabReducer,
  calendars: fromCalendars.calendarTabReducer,
  locations: fromLocations.locationsTabReducer,
});
