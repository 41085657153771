import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { drawerClosedSelected, State, getDisplayBackText } from '../../../../store';
import { Observable } from 'rxjs';
import * as fromSelectors from '../selectors/yard-details.selectors';
import * as fromActions from '../actions/yard-details.actions';
import * as fromTypes from '../../types';

@Injectable()
export class YardDetailsFacade {
  constructor(private store: Store<State>) {}

  public getYardId(): Observable<string> {
    return this.store.select(fromSelectors.getOpenedYardId);
  }

  public getTab(): Observable<fromTypes.YardDetailsTab> {
    return this.store.select(fromSelectors.getTab);
  }

  public getDisplayBackText(): Observable<string | undefined> {
    return this.store.select(getDisplayBackText);
  }

  public getOpenedYard(): Observable<fromTypes.Yard> {
    return this.store.select(fromSelectors.getOpenedYard);
  }

  public getOpenedYardLoading(): Observable<boolean> {
    return this.store.select(fromSelectors.getOpenedYardLoading);
  }

  public getOpenedYardError(): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getOpenedYardError);
  }

  public getOpenedYardVendorId(): Observable<string> {
    return this.store.select(fromSelectors.getOpenedYardVendorId);
  }

  public onSetYardId(yardId: string | undefined): void {
    this.store.dispatch(fromActions.setYardId({ yardId }));
  }

  public onSetTab(tab: fromTypes.YardDetailsTab): void {
    this.store.dispatch(fromActions.setTab({ tab }));
  }

  public onDrawerClosed(): void {
    this.store.dispatch(drawerClosedSelected());
  }
}
