export const getDownloadUrlFromPath = (path: string, prefix: string = wpEnvironment.userRole): string => {
  if (!path) {
    return undefined;
  }
  const base = wpEnvironment.apiBaseUrl + '/' + prefix;
  if (path.startsWith('/')) {
    return base + path;
  }
  return base + '/' + path;
};
