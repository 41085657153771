<wp-dropdown
  [triggerTemplate]="triggerTemplate"
  [dropdownTemplate]="dropdownTemplate"
  [alignDropdown]="alignDropdown"
>
</wp-dropdown>

<ng-template #triggerTemplate>
  <div class="selection">
    <wp-dropdown-trigger
      *ngIf="viewStyle === 'bold-selection'; else defaultView"
    >
      <span class="grey-label">{{label}}</span>
      <span class="bold">{{ selectedOption?.displayLabel || '--' }}</span>
    </wp-dropdown-trigger>
    <ng-template #defaultView>
      <wp-dropdown-trigger>
        <span>{{ label }}</span>
        &nbsp;<span>•</span>&nbsp;
        <span>{{ selectedOption?.displayLabel || '--' }}</span>
      </wp-dropdown-trigger>
    </ng-template>
  </div>
</ng-template>

<ng-template #dropdownTemplate>
  <div [ngStyle]="{width: dropdownWidthPx + 'px'}">
    <wp-dropdown-pane>
      <wp-dropdown-option
        *ngFor="let option of options"
        [selected]="selectedOption?.value === option.value"
        [showTickWhenSelected]="true"
        (click)="onSelectedChanged(option)"
        (selectedWithKeyboard)="onSelectedChanged(option)"
      >
        {{ option.displayLabel }}
      </wp-dropdown-option>
    </wp-dropdown-pane>
  </div>
</ng-template>
