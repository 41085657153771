import { Pipe, PipeTransform } from '@angular/core';
import { PortalEntityType } from '@rootTypes';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getPortalEntityLabel } from '../../data/common/store/selectors/portal-entity-info.selectors';
import { getPortalEntityInfo } from '../../data/common/store/actions/portal-entities-info.actions';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'displayEntityLabel',
})
export class DisplayEntityLabelPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(entityId: string, entityType: PortalEntityType, placeholder?: string): Observable<string> {
    // TODO: remove this setTimeout once we have a better way to handle this (dispatch actions are not allowed in pipe transform context in the new ngrx)
    setTimeout(() => {
      this.store.dispatch(getPortalEntityInfo({ entityId, entityType }));
    });
    return this.store.select(getPortalEntityLabel(entityType, entityId)).pipe(map((d) => d ?? placeholder));
  }
}
