import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Provide it at component level
 */
@Injectable()
export class ScrollService {
  /**
   * Holds the number of pixels scrolled by the component, passed through init
   */
  private scrollSubj$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  private currentElement: HTMLElement;
  private currentCallbackFn: (this: HTMLElement, ev: HTMLElementEventMap['scroll']) => any;

  constructor() {}

  /**
   * Sets scroll listener for the element
   * @param element
   */
  public init(element: HTMLElement): void {
    if (this.currentElement && this.currentCallbackFn) {
      this.currentElement.removeEventListener('scroll', this.currentCallbackFn);
    }
    this.currentElement = element;
    this.currentCallbackFn = (event) => {
      this.scrollSubj$.next(element.scrollTop);
    };
    this.currentElement.addEventListener('scroll', this.currentCallbackFn.bind(this));
  }

  /**
   * Removes scroll listener for the element
   */
  public reset(): void {
    if (this.currentElement && this.currentCallbackFn) {
      this.currentElement.removeEventListener('scroll', this.currentCallbackFn);
    }
    this.currentElement = null;
    this.currentCallbackFn = null;
  }

  public scrolledTop$(): Observable<number> {
    return this.scrollSubj$.asObservable();
  }
}
