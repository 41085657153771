<div style="width: 14px; height: 14px">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <g
      fill="#959597"
      fill-rule="evenodd"
      [ngClass]="{
      green: green,
      white: white
    }"
    >
      <path
        d="M1.318 7.182c-.39-.39-.391-1.023-.002-1.412L6.27.816c.39-.39 1.019-.391 1.412.002.39.39.391 1.023.002 1.412L2.73 7.184c-.39.39-1.019.391-1.412-.002z"
        transform="matrix(1 0 0 -1 0 12)"
      />
      <path
        d="M12.682 7.182c-.39.39-1.023.391-1.412.002L6.316 2.23c-.39-.39-.391-1.019.002-1.412.39-.39 1.023-.391 1.412-.002l4.954 4.954c.39.39.391 1.019-.002 1.412z"
        transform="matrix(1 0 0 -1 0 12)"
      />
    </g>
  </svg>
</div>
