import { DriverReviewStatusQueryParam } from '../types';
import { VehicleInspectionDriverReviewStatus } from '@apiEntities';

export const queryParamToDriverReviewStatus = (
  query?: DriverReviewStatusQueryParam,
): VehicleInspectionDriverReviewStatus => {
  const map = {
    [DriverReviewStatusQueryParam.PENDING]: VehicleInspectionDriverReviewStatus.PENDING,
    [DriverReviewStatusQueryParam.REVIEWED]: VehicleInspectionDriverReviewStatus.REVIEWED,
  };
  return map[query];
};
