import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../api/api.service';
import * as fromApi from '../../api';
import { ActivityStatus, ActivityType, PortalEntityType } from '@rootTypes';
import { map } from 'rxjs/operators';

@Injectable()
export class TimesheetEntryDataApiService {
  constructor(private baseApi: ApiService) {}

  public getActivity(
    request: fromApi.getActivity.GetActivityRequest,
  ): Observable<fromApi.getActivity.GetActivityResponse> {
    return this.baseApi.getActivity(request);
  }

  public getPendingRequestsCountByUser(entityId: string, entityType: PortalEntityType): Observable<number> {
    const request: fromApi.entityFilter.EntityFilterRequest = {
      type: fromApi.entityFilter.EntityFilterRequestType.ACTIVITY_TIME_ENTRY,
      extraFilters: {
        status: [ActivityStatus.PENDING_REVIEW],
      },
      skip: 0,
      limit: 2,
    };
    switch (entityType) {
      case PortalEntityType.DRIVER:
        request.driverIds = [entityId];
        break;
      default:
        throw new Error('Unknown entity type');
    }
    return this.baseApi.entityFilter(request).pipe(map((resp) => resp.total));
  }

  public getActivityLogs(activityId: string): Observable<fromApi.getActivityLog.GetActivityLogResponse> {
    return this.baseApi.getActivityLog(activityId);
  }

  public getActivityTypes(): Observable<ActivityType[]> {
    return this.baseApi.getActivityTypes().pipe(
      map((resp) => {
        return resp.map((s) => {
          return {
            type: {
              displayLabel: s.type.label,
              value: s.type.id,
            },
            subTypes: s.subTypes.map((sub) => {
              return {
                value: sub.id,
                displayLabel: sub.label,
                durationInMinutes: sub.durationInMinutes,
              };
            }),
          } as ActivityType;
        });
      }),
    );
  }
}
