import { SmartInputAddress, SmartInputAddressConfig } from './smart-input-address';
import { Address, MapMarker } from '@rootTypes';
import { SmartInputModel } from './smart-input-model';
import { BehaviorSubject, Observable } from 'rxjs';

export interface SmartInputAddressMapConfig extends SmartInputAddressConfig {
  mapWidthPx: number;
  mapHeightPx: number;
  initialZoom?: number;
  addressChangeZoom?: number;
  centerMapOn?: Address;
  markers?: MapMarker[];
  noGeocoder?: boolean;
}

export class SmartInputAddressMap implements SmartInputModel {
  public address: SmartInputAddress;
  public mapWidthPx: number;
  public mapHeightPx: number;
  public initialZoom: number;
  public addressChangeZoom: number;
  public centerMapOn?: Address;
  public markers$: Observable<MapMarker[]>;
  public noGeocoder: boolean;

  private markers$$: BehaviorSubject<MapMarker[]>;

  constructor(config: SmartInputAddressMapConfig) {
    this.address = new SmartInputAddress(config);
    const { initialZoom, addressChangeZoom, centerMapOn, markers, mapWidthPx, mapHeightPx } = config;
    this.mapWidthPx = mapWidthPx;
    this.mapHeightPx = mapHeightPx;
    this.initialZoom = initialZoom || 8;
    this.addressChangeZoom = addressChangeZoom;
    this.centerMapOn = centerMapOn;
    this.noGeocoder = config.noGeocoder;

    this.markers$$ = new BehaviorSubject<MapMarker[]>(markers || []);
    this.markers$ = this.markers$$.asObservable();
  }

  public hasChanges(): boolean {
    return this.address.hasChanges();
  }

  public getValue(): Address {
    return this.address.getValue();
  }

  public isValid(): boolean {
    return this.address.isValid();
  }

  public showErrorIfAny(): void {
    this.address.showErrorIfAny();
  }

  public setValue(addr: Address): void {
    this.address.setValue(addr);
  }

  public setMarkers(markers: MapMarker[]): void {
    this.markers$$.next(markers);
  }

  public getValueChanges(): Observable<any> {
    return this.address.control.valueChanges;
  }
}
