import { StudentChangeRequestStatus } from '@apiEntities';

export const studentChangeRequestStatusToDisplay = (status?: StudentChangeRequestStatus): string => {
  if (!status) {
    return '--';
  }
  const map = {
    [StudentChangeRequestStatus.PENDING]: 'Pending',
    [StudentChangeRequestStatus.APPROVED]: 'Processed',
    [StudentChangeRequestStatus.REJECTED]: 'Rejected',
  };
  return map[status] || status;
};
