import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-icon-insights',
  templateUrl: './icon-insights.component.html',
  styleUrls: ['./icon-insights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconInsightsComponent implements OnInit {
  @Input() public green = false;

  constructor() {}

  ngOnInit(): void {}
}
