import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CommonDataApiService } from '../../services/common-data-api.service';
import * as fromActions from '../actions/us-national-holidays.actions';

@Injectable()
export class USNationalHolidaysEffects {
  constructor(
    private actions$: Actions,
    private apiService: CommonDataApiService,
  ) {}

  public loadNationalHolidaysRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getUSNationalHolidaysRequested),
      switchMap(({ startDate, endDate }) => {
        return this.apiService.getUSNationalHolidays(startDate, endDate).pipe(
          map((res) => fromActions.getUSNationalHolidaysSuccess({ holidays: res })),
          catchError((error) => {
            console.log(error);
            return of(
              fromActions.getUSNationalHolidaysFailed({
                error: {
                  text: 'Failed to load US national holidays',
                },
              }),
            );
          }),
        );
      }),
    ),
  );
}
