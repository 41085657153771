import { RideV2ProjectionKey } from '@apiEntities/rides/ride-v2-projection';

export enum RideListName {
  MAIN_RIDE_LIST = 'MAIN_RIDE_LIST',
  DISTRICT_DETAILS_RIDES_LIST = 'DISTRICT_DETAILS_RIDES_LIST',
  DRIVER_DETAILS_RIDES_LIST = 'DRIVER_DETAILS_RIDES_LIST',
  ROUTE_DETAILS_RIDES_LIST = 'ROUTE_DETAILS_RIDES_LIST',
  STUDENT_DETAILS_RIDES_LIST = 'STUDENT_DETAILS_RIDES_LIST',
  CAMPUS_DETAILS_RIDES_LIST = 'CAMPUS_DETAILS_RIDES_LIST',
  YARD_DETAILS_RIDES_LIST = 'YARD_DETAILS_RIDES_LIST',
  DASHBOARD_DRIVER_LIST = 'DASHBOARD_DRIVER_LIST',
}

export const rideListNames = [
  RideListName.MAIN_RIDE_LIST,
  RideListName.DISTRICT_DETAILS_RIDES_LIST,
  RideListName.DRIVER_DETAILS_RIDES_LIST,
  RideListName.ROUTE_DETAILS_RIDES_LIST,
  RideListName.STUDENT_DETAILS_RIDES_LIST,
  RideListName.CAMPUS_DETAILS_RIDES_LIST,
  RideListName.YARD_DETAILS_RIDES_LIST,
  RideListName.DASHBOARD_DRIVER_LIST,
];

export interface RideListConfig {
  projections: RideV2ProjectionKey[];
}

export const rideListConfigs: { [key in RideListName]: RideListConfig } = {
  [RideListName.MAIN_RIDE_LIST]: {
    projections: ['driver', 'route', 'routeGroup', 'charterTrip', 'vehicle', 'userEventsEstimate', 'campuses'],
  },
  [RideListName.STUDENT_DETAILS_RIDES_LIST]: {
    projections: ['campuses', 'userEventsEstimate', 'eventRiderNote', 'route', 'routeGroup'],
  },
  [RideListName.DISTRICT_DETAILS_RIDES_LIST]: {
    projections: ['userEventsEstimate', 'campuses', 'route', 'routeGroup'],
  },
  [RideListName.DRIVER_DETAILS_RIDES_LIST]: {
    projections: ['userEventsEstimate', 'campuses', 'route', 'routeGroup'],
  },
  [RideListName.ROUTE_DETAILS_RIDES_LIST]: {
    projections: ['driver', 'vehicle', 'userEventsEstimate', 'campuses'],
  },
  [RideListName.CAMPUS_DETAILS_RIDES_LIST]: {
    projections: ['userEventsEstimate', 'campuses', 'route', 'routeGroup'],
  },
  [RideListName.YARD_DETAILS_RIDES_LIST]: {
    projections: ['userEventsEstimate', 'campuses', 'route', 'routeGroup'],
  },
  [RideListName.DASHBOARD_DRIVER_LIST]: {
    projections: ['route', 'routeGroup', 'charterTrip', 'userEventsEstimate', 'campuses', 'route', 'routeGroup'],
  },
};
