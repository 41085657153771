import { createReducer, on } from '@ngrx/store';
import * as fromTypes from '../../types';
import { CachedEntityState } from '../../types';
import {
  getDriverFeeByRideFailed,
  getDriverFeeByRideRequested,
  getDriverFeeByRideSuccess,
  getEndOfRideInspectionFailed,
  getEndOfRideInspectionRequested,
  getEndOfRideInspectionSuccess,
  getRidePolylineFailed,
  getRidePolylineRequested,
  getRidePolylineSuccess,
  getRideRouteTraceIndexSuccess,
  getRideV2Failed,
  getRideV2Requested,
  getRideV2Success,
  incomingRouteTracesReceived,
  resetRouteTrace,
  routeTraceHistoryReceived,
} from '../actions/rides-data.actions';
import { RideV2Projections } from '@apiEntities/rides/ride-v2-projection';
import { RideV2 } from '@apiEntities/rides/ride-v2';
import { GetRideDriverFeeResponse } from '../../../../api/endpoints/get-ride-driver-fee';
import { GetRidePolylineResponse } from '../../../../api/endpoints/get-ride-polyline';
import { GetEndOfRideInspectionAbstractByRideResponse } from '../../../../api/endpoints/get-end-of-ride-inspection-abstract-by-ride';
import { apiRouteTraceToPortalRouteTraceMapper } from '@rootTypes/utils/ride/api-route-trace-to-portal-route-trace.mapper';

export interface RideDataState {
  ridesV2: {
    [rideId: string]: CachedEntityState<RideV2>;
  };
  projections: {
    [rideId: string]: RideV2Projections;
  };
  routeTraces: {
    [rideId: string]: {
      startAtIndex: number;
      endAtIndex: number;
      lastRouteTrace: fromTypes.PortalRouteTracePoint;
      routeTraceHistory: fromTypes.PortalRouteTracePoint[];
      incomingRouteTraces: fromTypes.PortalRouteTracePoint[];
      error?: fromTypes.WpError;
    };
  };
  rideDriverFees: {
    [rideId: string]: {
      isLoading: boolean;
      error?: fromTypes.WpError;
      entity: GetRideDriverFeeResponse;
    };
  };
  ridePolylines: {
    [rideId: string]: {
      isLoading: boolean;
      error?: fromTypes.WpError;
      entity?: GetRidePolylineResponse;
    };
  };
  endOfRideInspections: {
    [rideId: string]: {
      isLoading: boolean;
      error?: fromTypes.WpError;
      entity?: GetEndOfRideInspectionAbstractByRideResponse;
    };
  };
}

const initialRideRouteTraceState: RideDataState['routeTraces'][string] = {
  startAtIndex: undefined,
  endAtIndex: undefined,
  lastRouteTrace: undefined,
  routeTraceHistory: [],
  incomingRouteTraces: [],
  error: null,
};

export const createInitialRideDataState = (): RideDataState => {
  return {
    ridesV2: {},
    projections: {},
    routeTraces: {},
    rideDriverFees: {},
    ridePolylines: {},
    endOfRideInspections: {},
  };
};

export const rideDataReducer = createReducer(
  createInitialRideDataState(),
  on(fromTypes.cleanUpStore, createInitialRideDataState),
  // load ride details
  // adds incoming route traces to the existing ones
  on(incomingRouteTracesReceived, (state, action) => {
    const { rideId, routeTraces } = action;
    const routeTraceState =
      state.routeTraces[rideId] ||
      ({
        ...initialRideRouteTraceState,
      } as RideDataState['routeTraces'][string]);
    // add some additional data for route traces
    const newRouteTraces: fromTypes.PortalRouteTracePoint[] = [...routeTraces].map((source) => {
      return apiRouteTraceToPortalRouteTraceMapper(source, state.ridesV2[rideId].entity as RideV2);
    });
    // update last route trace, if any received
    const lastRouteTrace = newRouteTraces.length
      ? newRouteTraces[newRouteTraces.length - 1]
      : routeTraceState.lastRouteTrace;

    const newState: RideDataState = {
      ...state,
      routeTraces: {
        ...state.routeTraces,
        [rideId]: {
          ...routeTraceState,
          lastRouteTrace,
          incomingRouteTraces: newRouteTraces,
        },
      },
    };
    return newState;
  }),
  on(routeTraceHistoryReceived, (state, action) => {
    const { rideId, routeTraces } = action;
    const routeTraceState =
      state.routeTraces[rideId] ||
      ({
        ...initialRideRouteTraceState,
      } as RideDataState['routeTraces'][string]);
    const newRouteTraces: fromTypes.PortalRouteTracePoint[] = [...routeTraces].map((source) => {
      return apiRouteTraceToPortalRouteTraceMapper(source, state.ridesV2[rideId].entity as RideV2);
    });
    // update last route trace, if any received
    const lastRouteTrace = newRouteTraces.length
      ? newRouteTraces[newRouteTraces.length - 1]
      : routeTraceState.lastRouteTrace;
    const newState: RideDataState = {
      ...state,
      routeTraces: {
        ...state.routeTraces,
        [rideId]: {
          ...routeTraceState,
          routeTraceHistory: newRouteTraces,
          lastRouteTrace,
          incomingRouteTraces: [],
        },
      },
    };
    return newState;
  }),
  on(resetRouteTrace, (state, action) => {
    const { rideId } = action;
    const routeTraceState = {
      ...initialRideRouteTraceState,
    } as RideDataState['routeTraces'][string];
    const newState: RideDataState = {
      ...state,
      routeTraces: {
        ...state.routeTraces,
        [rideId]: {
          ...routeTraceState,
        },
      },
    };
    return newState;
  }),
  on(getRideRouteTraceIndexSuccess, (state, action) => {
    const { rideId, indexType, index } = action;
    let routeTraceState =
      state.routeTraces[rideId] ||
      ({
        ...initialRideRouteTraceState,
      } as RideDataState['routeTraces'][string]);
    if (indexType === 'start') {
      routeTraceState = {
        ...routeTraceState,
        startAtIndex: index,
      };
    } else if (indexType === 'end') {
      routeTraceState = {
        ...routeTraceState,
        endAtIndex: index,
      };
    }
    const newState: RideDataState = {
      ...state,
      routeTraces: {
        ...state.routeTraces,
        [rideId]: {
          ...routeTraceState,
        },
      },
    };
    return newState;
  }),
  on(getRideV2Requested, (state, action) => {
    const rideId = action.request.rideId;
    const oldState = state.ridesV2[rideId] || ({} as CachedEntityState<RideV2>);
    return {
      ...state,
      ridesV2: {
        ...state.ridesV2,
        [rideId]: {
          ...(state.ridesV2[rideId] || {}),
          state: (oldState?.entity ? 'old' : 'loading') as CachedEntityState<RideV2>['state'],
          error: null,
        },
      },
    };
  }),
  on(getRideV2Success, (state, action) => {
    const rideId = action.rideId;
    let projections: RideV2Projections = action.response._projections || {};
    if (!projections.userEventsEstimate) {
      projections = { ...projections, userEventsEstimate: null };
    }
    if ((action.requestProjections ?? []).includes('driver') && !projections.driver) {
      projections = { ...projections, driver: null };
    }
    if ((action.requestProjections ?? []).includes('vehicle') && !projections.vehicle) {
      projections = { ...projections, vehicle: null };
    }
    const resultState: RideDataState = {
      ...state,
      ridesV2: {
        ...state.ridesV2,
        [rideId]: {
          ...(state.ridesV2[rideId] || {}),
          state: 'fresh',
          entity: action.response.ride,
          error: null,
        },
      },
      projections: {
        ...state.projections,
        [rideId]: {
          ...(state.projections[rideId] || {}),
          ...projections,
        },
      },
    };
    return resultState;
  }),
  on(getRideV2Failed, (state, action) => {
    const rideId = action.rideId;
    return {
      ...state,
      ridesV2: {
        ...state.ridesV2,
        [rideId]: {
          ...(state.ridesV2[rideId] || {}),
          state: 'error' as CachedEntityState<RideV2>['state'],
          error: action.error,
        },
      },
    };
  }),
  // Driver fee by the ride
  on(getDriverFeeByRideRequested, (state, action) => {
    const rideId = action.rideId;
    const prevState = state.rideDriverFees[rideId] || ({} as RideDataState['rideDriverFees'][string]);
    return {
      ...state,
      rideDriverFees: {
        ...state.rideDriverFees,
        [rideId]: {
          ...prevState,
          isLoading: true,
        },
      },
    };
  }),
  on(getDriverFeeByRideSuccess, (state, action) => {
    const { rideId, response } = action;
    const prevState = state.rideDriverFees[rideId] || ({} as RideDataState['rideDriverFees'][string]);
    return {
      ...state,
      rideDriverFees: {
        ...state.rideDriverFees,
        [rideId]: {
          ...prevState,
          isLoading: false,
          entity: response,
        },
      },
    };
  }),
  on(getDriverFeeByRideFailed, (state, action) => {
    const { rideId, error } = action;
    const prevState = state.rideDriverFees[rideId] || ({} as RideDataState['rideDriverFees'][string]);
    return {
      ...state,
      rideDriverFees: {
        ...state.rideDriverFees,
        [rideId]: {
          ...prevState,
          isLoading: false,
          error,
        },
      },
    };
  }),
  // Ride polyline
  on(getRidePolylineRequested, (state, action) => {
    const { rideId } = action;
    const prevState = state.ridePolylines[rideId] || ({} as RideDataState['ridePolylines'][string]);
    return {
      ...state,
      ridePolylines: {
        ...state.ridePolylines,
        [rideId]: {
          ...prevState,
          isLoading: true,
        },
      },
    };
  }),
  on(getRidePolylineSuccess, (state, action) => {
    const { rideId, response } = action;
    const prevState = state.ridePolylines[rideId] || ({} as RideDataState['ridePolylines'][string]);
    return {
      ...state,
      ridePolylines: {
        ...state.ridePolylines,
        [rideId]: {
          ...prevState,
          isLoading: false,
          entity: response,
        },
      },
    };
  }),
  on(getRidePolylineFailed, (state, action) => {
    const { rideId, error } = action;
    const prevState = state.ridePolylines[rideId] || ({} as RideDataState['ridePolylines'][string]);
    return {
      ...state,
      ridePolylines: {
        ...state.ridePolylines,
        [rideId]: {
          ...prevState,
          isLoading: false,
          error,
        },
      },
    };
  }),
  // End of ride inspections
  on(getEndOfRideInspectionRequested, (state, action) => {
    const { rideId } = action;
    const prevState = state.endOfRideInspections || ({} as RideDataState['endOfRideInspections'][string]);
    return {
      ...state,
      endOfRideInspections: {
        ...state.endOfRideInspections,
        [rideId]: {
          ...prevState,
          isLoading: true,
        },
      },
    };
  }),
  on(getEndOfRideInspectionSuccess, (state, action) => {
    const { rideId, response } = action;
    const prevState = state.endOfRideInspections || ({} as RideDataState['endOfRideInspections'][string]);
    return {
      ...state,
      endOfRideInspections: {
        ...state.endOfRideInspections,
        [rideId]: {
          ...prevState,
          isLoading: false,
          entity: response,
        },
      },
    };
  }),
  on(getEndOfRideInspectionFailed, (state, action) => {
    const { rideId, error } = action;
    const prevState = state.endOfRideInspections || ({} as RideDataState['endOfRideInspections'][string]);
    return {
      ...state,
      endOfRideInspections: {
        ...state.endOfRideInspections,
        [rideId]: {
          ...prevState,
          isLoading: false,
          error,
        },
      },
    };
  }),
);
