export enum DriverBusReportStatus {
  MISSING = 'MISSING',
  RAW_OCR_PENDING = 'RAW_OCR_PENDING',
  OCR_REVIEW_PENDING = 'OCR_REVIEW_PENDING',
  DBR_REVIEW_PENDING = 'DBR_REVIEW_PENDING',
  REQUIRES_CORRECTION = 'REQUIRES_CORRECTION',
  VERIFIED = 'DBR_VERIFIED',
  SUBMITTED = 'SUBMITTED',
  REJECTED = 'REJECTED',
}
