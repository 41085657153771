import { Pipe, PipeTransform } from '@angular/core';
import { Weekday } from '@rootTypes';
import { Weekday as ApiWeekday } from '@apiEntities/common/weekday';
import { getShortWeekday } from 'src/app/types/utils/common/date';

@Pipe({
  name: 'shortWeekday',
})
export class ShortWeekdayPipe implements PipeTransform {
  /**
   * display wednesday as 'Wed'
   */
  transform(value: Weekday | ApiWeekday): string {
    if (!value) {
      return null;
    }
    return getShortWeekday(value);
  }
}
