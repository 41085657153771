import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const moduleKey = 'Yards';
const submoduleKey = 'List';

export const yardListInitialized = createAction(
  `[${moduleKey}] [${submoduleKey}]: Initialized`,
  props<{ listName: fromTypes.YardListName; snapshot?: fromTypes.YardListSnapshot }>(),
);

export const searchChanged = createAction(
  `[${moduleKey}] [${submoduleKey}]: Search changed`,
  props<{ listName: fromTypes.YardListName; search: string }>(),
);

export const statusChanged = createAction(
  `[${moduleKey}] [${submoduleKey}]: Status changed`,
  props<{ listName: fromTypes.YardListName; status: fromTypes.EntityStatus }>(),
);

export const filtersChanged = createAction(
  `[${moduleKey}] [${submoduleKey}]: Filters changed`,
  props<{ listName: fromTypes.YardListName; filters: fromTypes.YardListFilter[] }>(),
);

export const staticFiltersChanged = createAction(
  `[${moduleKey}] [${submoduleKey}]: Static filters changed`,
  props<{ listName: fromTypes.YardListName; filters: fromTypes.YardListFilter[] }>(),
);

export const pageChanged = createAction(
  `[${moduleKey}] [${submoduleKey}]: Page changed`,
  props<{ listName: fromTypes.YardListName; page: number }>(),
);

export const pageSizeChanged = createAction(
  `[${moduleKey}] [${submoduleKey}]: Page size changed`,
  props<{ listName: fromTypes.YardListName; pageSize: number }>(),
);

export const selectedYardChanged = createAction(
  `[${moduleKey}] [${submoduleKey}]: Selected yard changed`,
  props<{ listName: fromTypes.YardListName; yardId: string }>(),
);

/**
 * Yard list item
 */
export const listItemInitialized = createAction(
  `[${moduleKey}] [${submoduleKey}]: List item initialized`,
  props<{ listName: fromTypes.YardListName; yardId: string }>(),
);

export const listItemDestroyed = createAction(
  `[${moduleKey}] [${submoduleKey}]: List item destroyed`,
  props<{ listName: fromTypes.YardListName; yardId: string }>(),
);

/**
 * Load yard list requested
 */
export const loadYardListRequested = createAction(
  `[${moduleKey}] [${submoduleKey}] [Data]: Load yard list requested`,
  props<{ listName: fromTypes.YardListName; snapshot: fromTypes.YardListSnapshot }>(),
);

export const loadYardListSuccess = createAction(
  `[${moduleKey}] [${submoduleKey}] [Data]: Load yard list success`,
  props<{ listName: fromTypes.YardListName; total: number; items: fromTypes.PortalEntity[] }>(),
);

export const loadYardListFailed = createAction(
  `[${moduleKey}] [${submoduleKey}] [Data]: Load yard list failed`,
  props<{ listName: fromTypes.YardListName; error: fromTypes.WpError }>(),
);
