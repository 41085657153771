import { diff } from 'deep-diff';

export const getDiff = (first: any, second: any): Diff<any>[] => {
  return diff(first, second);
};

export interface Diff<T> {
  kind: 'A' | 'D' | 'E' | 'N';
  path: string[];
  index?: number; // for array changes
  item?: T; // for array inserts
  rhs: T;
  lhs: T;
}

export const isEditChange = (diff: Diff<any>, path: (string | '*')[] = []): boolean => {
  return (
    diff.kind === 'E' && //
    diff.path.length === path.length + 1 &&
    path.every((pSeg, pInd) => pSeg === '*' || diff.path[pInd] === pSeg)
  );
};

export const isAdditionChange = (diff: Diff<any>, path: (string | '*')[] = []) => {
  return (
    diff.kind === 'N' && //
    diff.path.length === path.length + 1 &&
    path.every((pSeg, pInd) => pSeg === '*' || diff.path[pInd] === pSeg)
  );
};

export const isRemovalChange = (diff: Diff<any>, path: (string | '*')[] = []) => {
  return (
    diff.kind === 'D' && //
    diff.path.length === path.length + 1 &&
    path.every((pSeg, pInd) => pSeg === '*' || diff.path[pInd] === pSeg)
  );
};
