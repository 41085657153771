import { createSelector } from '@ngrx/store';
import { getEmployeesFeature } from './get-feature';
import { getAllEmployeeEntities } from './employee-data.selectors';
import * as fromTypes from '../../types';
import { getAllDistrictsState } from '../../../districts/store/selectors';

const employeeProfileEditorState = createSelector(getEmployeesFeature, (state) => state.profileEditor);

export const getCurrentUpdatedModel = createSelector(employeeProfileEditorState, (state) => state.updatedModel);

export const getCurrentUpdatedModelValid = createSelector(
  employeeProfileEditorState,
  (state) => state.isUpdatedModelValid,
);

export const getCreateForDistrictId = createSelector(employeeProfileEditorState, (state) => state.createForDistrictId);

const getCreateForDistrictState = createSelector(
  getCreateForDistrictId,
  getAllDistrictsState,
  (districtId, allDistrictEntities) => {
    if (districtId) {
      return allDistrictEntities[districtId];
    }
    return undefined;
  },
);

export const getCreateForDistrict = createSelector(getCreateForDistrictState, (state) => state?.entity);
export const getCreateForDistrictLoading = createSelector(
  getCreateForDistrictState,
  (state) => state?.isLoading || false,
);
export const getCreateForDistrictError = createSelector(getCreateForDistrictState, (state) => state?.error);

export const getOpenedEmployeeId = createSelector(employeeProfileEditorState, (state) => state.employeeId);
const getOpenedEmployeeState = createSelector(
  getOpenedEmployeeId,
  getAllEmployeeEntities,
  (employeeId, allEntities) => {
    if (employeeId) {
      return allEntities[employeeId];
    }
    return undefined;
  },
);

export const getOpenEmployee = createSelector(getOpenedEmployeeState, (state) => state?.entity);
export const getOpenEmployeeEditModel = createSelector(
  getOpenEmployee,
  (source): fromTypes.EmployeeProfileEditModel => {
    if (!source) {
      return undefined;
    }
    return { ...source, photo: { imagePath: source.photo } };
  },
);

export const getOpenEmployeeLoading = createSelector(getOpenedEmployeeState, (state) => state?.isLoading || false);

export const getRequestLoading = createSelector(employeeProfileEditorState, (state) => state.isLoading);
