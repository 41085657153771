import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { PortalEntityInfoApiService } from '../../data/common/services/portal-entity-info-api.service';
import { DateRange, PortalEntity, YYYYMMDDString } from '@rootTypes';
import { YearMonthDay } from '@rootTypes/utils/common/date';
import { map, tap } from 'rxjs/operators';

const commonPortalFilters = {
  entity: true,
  date: true,
  'date-range': true,
  'date-range-no-year': true,
};

export type LabelLoaderFn<T> = (value: T) => Observable<string> | Promise<string> | string;

export type PaginatedListFilterLabelConfig<F> = {
  [K in keyof F]?: keyof typeof commonPortalFilters | LabelLoaderFn<F[K] extends Array<infer Item> ? Item : F[K]>;
};

@Pipe({
  name: 'paginatedListFilterLabel',
})
export class PaginatedListFilterLabelPipe implements PipeTransform {
  private commonLabelLoaders: {
    [K in keyof typeof commonPortalFilters]: (value: any) => string | Observable<string>;
  };
  constructor(
    private commonLabelLoader: PaginatedListFilterLabelLoaderService,
    private entityAPI: PortalEntityInfoApiService,
  ) {
    this.commonLabelLoaders = {
      date: (value: YYYYMMDDString) => new YearMonthDay(value).formatTo('dateMedium'),
      'date-range': (value: DateRange) => {
        if (!value) {
          return '--';
        } else if (value.startDate === value.endDate) {
          return new YearMonthDay(value.startDate).formatTo('dateMedium');
        } else {
          return `${new YearMonthDay(value.startDate).formatTo('dateMedium')} - ${new YearMonthDay(value.endDate).formatTo('dateMedium')}`;
        }
      },
      'date-range-no-year': (value: DateRange) => {
        if (!value) {
          return '--';
        } else if (value.startDate === value.endDate) {
          return new YearMonthDay(value.startDate).formatTo('monthAndDayNoPadding');
        } else {
          return `${new YearMonthDay(value.startDate).formatTo('monthAndDayNoPadding')} - ${new YearMonthDay(value.endDate).formatTo('monthAndDayNoPadding')}`;
        }
      },
      entity: (value: PortalEntity) => {
        return this.entityAPI.getPortalEntity(value.entityId, value.type).pipe(map((e) => e.label));
      },
    };
  }

  transform<F extends Record<any, any>, K extends keyof F>(
    value: F[K] extends Array<infer Item> ? Item : F[K],
    type: K,
    config: PaginatedListFilterLabelConfig<F>,
  ): Observable<string> {
    const labelLoader = config[type as keyof PaginatedListFilterLabelConfig<K>];
    if (!labelLoader) {
      console.error('No label loader found for filter: ' + type.toString());
      return of(JSON.stringify(value));
    }
    const loaderFn: LabelLoaderFn<any> =
      typeof labelLoader === 'string'
        ? this.commonLabelLoaders[labelLoader as keyof typeof commonPortalFilters]
        : labelLoader;
    return this.commonLabelLoader.loadLabel(value, loaderFn);
  }
}

@Injectable({ providedIn: 'root' })
class PaginatedListFilterLabelLoaderService {
  private labelCache: Map<string, string> = new Map<string, string>();

  constructor() {}

  public loadLabel(request: any, labelLoader: LabelLoaderFn<any>): Observable<string> {
    const cacheId = JSON.stringify(request) + labelLoader.toString();
    if (this.labelCache.has(cacheId)) {
      return of(this.labelCache.get(cacheId));
    } else {
      const loaded = labelLoader(request);
      if (typeof loaded === 'string') {
        this.labelCache.set(cacheId, loaded);
        return of(loaded);
      } else if (loaded instanceof Promise) {
        return from(
          loaded.then((label) => {
            this.labelCache.set(cacheId, label);
            return label;
          }),
        );
      } else {
        return loaded.pipe(
          tap((label) => {
            this.labelCache.set(cacheId, label);
          }),
        );
      }
    }
  }
}
