import {
  GetStudentChangeRequestConfigResponse,
  SmartFormFieldType,
  StudentChangeRequestOperation,
  StudentTransportationCancelReason,
  StudentTransportationStatus,
} from '../dependencies';

export const getStudentChangeRequestConfigMockResponse: GetStudentChangeRequestConfigResponse = {
  id: 'changeRequestId',
  studentId: '-MdPeCJF484JtGWHLooU',
  districtId: '3mYxuFp0M_',
  districtProgram: {
    id: 'test-program',
    name: 'Test program',
  },
  label: 'Profile Information',
  operation: StudentChangeRequestOperation.UPDATE,
  requestedBy: {
    id: '84e5222f-7da1-4789-bd0d-7bfc9e4d00ee',
    role: 'admin',
  },
  isMaxEntityCountReached: false,
  suggestedComparisonEntityId: '-MdPeCJF484JtGWHLooU',
  updateExistingDataForLabel: 'Student',
  allFields: [
    {
      fieldName: 'studentTransportationStatus',
      value: {
        type: SmartFormFieldType.STUDENT_TRANSPORTATION_STATUS,
        status: StudentTransportationStatus.ACTIVE,
      },
      label: 'Transportation status',
      isOptional: false,
    },
    {
      fieldName: 'school',
      value: {
        type: SmartFormFieldType.STUDENT_SCHOOL_INFO,
      },
      label: 'School district, campus, year and grade',
      isOptional: false,
    },
    {
      fieldName: 'firstName',
      value: {
        type: SmartFormFieldType.STRING,
        value: undefined,
        isMultiline: false,
      },
      label: 'First Name',
      isOptional: false,
    },
    {
      fieldName: 'lastName',
      value: {
        type: SmartFormFieldType.STRING,
        value: undefined,
        isMultiline: false,
      },
      label: 'Last Name',
      isOptional: false,
    },
    {
      fieldName: 'dob',
      value: {
        type: SmartFormFieldType.DATE,
        value: undefined,
      },
      label: 'Date of Birth',
      isOptional: false,
    },
    {
      fieldName: 'phoneNumber',
      value: {
        type: SmartFormFieldType.PHONE_NUMBER,
        value: undefined,
      },
      label: 'Phone number',
      isOptional: false,
    },
    {
      fieldName: 'email',
      value: {
        type: SmartFormFieldType.EMAIL,
        value: 'bob@example.com',
      },
      label: 'Email',
      isOptional: false,
    },
    {
      fieldName: 'specialNeeds',
      value: {
        type: SmartFormFieldType.CHECKBOXES,
        values: [
          {
            name: 'specialNeeds',
            isChecked: false,
            checkedLabel: 'Special Needs',
            uncheckedLabel: '--',
          },
        ],
      },
      label: 'Special Needs',
      isOptional: true,
    },
    {
      fieldName: 'equipmentNeeds',
      value: {
        type: SmartFormFieldType.CHECKBOXES,
        values: [
          {
            name: 'booster',
            isChecked: false,
            checkedLabel: 'Booster',
          },
          {
            name: 'seatbelt',
            isChecked: false,
            checkedLabel: 'Seatbelt',
          },
          {
            name: 'carseat',
            isChecked: false,
            checkedLabel: 'Carseat',
          },
          {
            name: 'walker',
            isChecked: false,
            checkedLabel: 'Walker',
          },
          {
            name: 'harness',
            isChecked: false,
            checkedLabel: 'Harness',
          },
          {
            name: 'wheelchair',
            isChecked: false,
            checkedLabel: 'Wheelchair',
          },
        ],
      },
      label: 'Equipment Needs',
      isOptional: true,
    },
    {
      fieldName: 'internalNotes',
      isOptional: true,
      label: 'Internal notes',
      value: {
        type: SmartFormFieldType.STRING,
        value: undefined,
        isMultiline: true,
      },
    },
    {
      fieldName: 'externalNotes',
      isOptional: true,
      label: 'External notes',
      value: {
        type: SmartFormFieldType.STRING,
        value: undefined,
        isMultiline: true,
      },
    },
    {
      fieldName: 'address',
      isOptional: false,
      label: 'Home address',
      value: {
        type: SmartFormFieldType.ADDRESS,
        value: undefined,
      },
    },
    {
      fieldName: 'transportationDates',
      isOptional: false,
      label: 'Transportation dates',
      value: {
        type: SmartFormFieldType.DATE_RANGE,
        from: {
          name: 'transportationStartDate',
          label: 'Transportation start date',
          isEditable: true,
        },
        to: {
          name: 'transportationEndDate',
          label: 'Transportation end date',
          isEditable: true,
        },
      },
    },
    {
      fieldName: 'transportationProvider',
      isOptional: true,
      label: 'Transportation provider & yard',
      value: {
        type: SmartFormFieldType.TRANSPORTATION_PROVIDER,
        vendorLabel: 'Transportation provider',
        yardLabel: 'Yard',
      },
    },
  ],
  existingEntities: [
    {
      entityId: '-MdPeCJF484JtGWHLooU',
      label: 'Bobby Sweeney',
      dataFields: {
        firstName: {
          name: 'firstName',
          value: {
            type: SmartFormFieldType.STRING,
            value: 'Bobby',
            isMultiline: false,
          },
        },
        lastName: {
          name: 'lastName',
          value: {
            type: SmartFormFieldType.STRING,
            value: 'Sweeney',
            isMultiline: false,
          },
        },
        dob: {
          name: 'dob',
          value: {
            type: SmartFormFieldType.DATE,
            value: '20090101',
          },
        },
        phoneNumber: {
          name: 'phoneNumber',
          value: {
            type: SmartFormFieldType.PHONE_NUMBER,
            value: '2001234567',
          },
        },
        email: {
          name: 'email',
          value: {
            type: SmartFormFieldType.EMAIL,
            value: 'bob@example.com',
          },
        },
        internalNotes: {
          name: 'internalNotes',
          value: {
            type: SmartFormFieldType.STRING,
            value: undefined,
            isMultiline: true,
          },
        },
        externalNotes: {
          name: 'externalNotes',
          value: {
            type: SmartFormFieldType.STRING,
            value: 'Bob is allergic to pet hair',
            isMultiline: true,
          },
        },
        address: {
          name: 'address',
          value: {
            type: SmartFormFieldType.ADDRESS,
            value: {
              formatted_address: '123 San Mateo Drive, San Mateo',
              geometry: {
                location: {
                  lat: 123,
                  lng: 321,
                },
              },
              address_components: undefined,
              name: '123 San Mateo Drive, San Mateo',
            },
          },
        },
        transportationDates: {
          name: 'transportationDates',
          value: {
            type: SmartFormFieldType.DATE_RANGE,
            from: {
              name: 'transportationStartDate',
              label: 'Transportation start date',
              isEditable: true,
              value: '20210816',
            },
            to: {
              name: 'transportationEndDate',
              label: 'Transportation end date',
              isEditable: true,
              value: '20211220',
            },
          },
        },
        school: {
          name: 'school',
          value: {
            type: SmartFormFieldType.STUDENT_SCHOOL_INFO,
            // value: {
            //   district: {
            //     id: '1_OpMBG0OX',
            //     name: 'San Francisco Yuri District',
            //   },
            //   campus: {
            //     id: '1_OuaF_0OZ',
            //     name: 'Spring Valley Science Middle School',
            //   },
            // },
          },
        },
        studentTransportationStatus: {
          name: 'studentTransportationStatus',
          value: {
            type: SmartFormFieldType.STUDENT_TRANSPORTATION_STATUS,
            status: StudentTransportationStatus.ACTIVE,
          },
        },
      },
    },
    {
      entityId: 'mike-sweeney',
      label: 'Michael Sweeney',
      dataFields: {
        firstName: {
          name: 'firstName',
          value: {
            type: SmartFormFieldType.STRING,
            value: 'Mike',
            isMultiline: false,
          },
        },
        lastName: {
          name: 'lastName',
          value: {
            type: SmartFormFieldType.STRING,
            value: 'Sweeney',
            isMultiline: false,
          },
        },
        dob: {
          name: 'dob',
          value: {
            type: SmartFormFieldType.DATE,
            value: '20070605',
          },
        },
        phoneNumber: {
          name: 'phoneNumber',
          value: {
            type: SmartFormFieldType.PHONE_NUMBER,
            value: '2001235580',
          },
        },
        email: {
          name: 'email',
          value: {
            type: SmartFormFieldType.EMAIL,
            value: 'mike-sweeny@example.com',
          },
        },
        externalNotes: {
          name: 'externalNotes',
          value: {
            type: SmartFormFieldType.STRING,
            value: `Dislikes fast acceleration and emergency braking.`,
            isMultiline: true,
          },
        },
        equipmentNeeds: {
          name: 'equipmentNeeds',
          value: {
            type: SmartFormFieldType.CHECKBOXES,
            values: [
              {
                name: 'booster',
                isChecked: true,
                checkedLabel: 'Booster',
              },
              {
                name: 'seatbelt',
                isChecked: false,
                checkedLabel: 'Seatbelt',
              },
              {
                name: 'carseat',
                isChecked: false,
                checkedLabel: 'Carseat',
              },
              {
                name: 'walker',
                isChecked: false,
                checkedLabel: 'Walker',
              },
              {
                name: 'harness',
                isChecked: false,
                checkedLabel: 'Harness',
              },
              {
                name: 'wheelchair',
                isChecked: false,
                checkedLabel: 'Wheelchair',
              },
            ],
          },
        },
        transportationProvider: {
          name: 'transportationProvider',
          value: {
            type: SmartFormFieldType.TRANSPORTATION_PROVIDER,
            vendorLabel: 'Transportation provider',
            yardLabel: 'Yard',
            value: {
              vendor: {
                id: '-LB73XKEn5J4lE_ZR5CV',
                name: 'Zum Testing',
              },
              yard: {
                id: '19GFUzK0Oy',
                name: 'My yard',
              },
            },
          },
        },
      },
    },
  ],
  changeRequestFields: {
    school: {
      fieldName: 'school',
      isEditable: true,
      isKeepExistingDataEnabled: true,
      isUseRevisedDataEnabled: true,
      value: {
        type: SmartFormFieldType.STUDENT_SCHOOL_INFO,
        value: {
          district: {
            id: '1_OpMBG0OX',
            name: 'San Francisco Yuri District',
          },
          campus: {
            id: '1_OuaF_0OZ',
            name: 'Spring Valley Science Middle School',
          },
          // schoolYear: {
          //   value: 'SY 2021-22',
          // },
          grade: {
            id: '1_OuaGh0Ob',
            label: '1',
          },
        },
      },
    },
    firstName: {
      fieldName: 'firstName',
      isEditable: true,
      isKeepExistingDataEnabled: true,
      isUseRevisedDataEnabled: true,
      value: {
        type: SmartFormFieldType.STRING,
        value: 'Bob',
        isMultiline: false,
      },
    },
    dob: {
      fieldName: 'dob',
      isEditable: true,
      isKeepExistingDataEnabled: true,
      isUseRevisedDataEnabled: true,
      value: {
        type: SmartFormFieldType.DATE,
        value: '20100105',
      },
    },
    phoneNumber: {
      fieldName: 'phoneNumber',
      isEditable: true,
      isKeepExistingDataEnabled: true,
      isUseRevisedDataEnabled: true,
      value: {
        type: SmartFormFieldType.PHONE_NUMBER,
        value: '2001234569',
      },
    },
    email: {
      fieldName: 'email',
      isEditable: true,
      isKeepExistingDataEnabled: true,
      isUseRevisedDataEnabled: true,
      value: {
        type: SmartFormFieldType.EMAIL,
        value: 'bob-sweeny@example.com',
      },
    },
    internalNotes: {
      fieldName: 'internalNotes',
      isEditable: true,
      isKeepExistingDataEnabled: true,
      isUseRevisedDataEnabled: true,
      value: {
        type: SmartFormFieldType.STRING,
        value: 'Do not book rides on alternate thursdays',
        isMultiline: true,
      },
    },
    externalNotes: {
      fieldName: 'externalNotes',
      isEditable: true,
      isKeepExistingDataEnabled: true,
      isUseRevisedDataEnabled: true,
      value: {
        type: SmartFormFieldType.STRING,
        value: 'He does not like open doors',
        isMultiline: true,
      },
    },
    address: {
      fieldName: 'address',
      isEditable: true,
      isKeepExistingDataEnabled: true,
      isUseRevisedDataEnabled: true,
      value: {
        type: SmartFormFieldType.ADDRESS,
        value: {
          formatted_address: '125 Main St, San Mateo',
          geometry: {
            location: {
              lat: 1234,
              lng: 321,
            },
          },
          address_components: undefined,
          name: '125 Main St, San Mateo',
        },
      },
    },
    specialNeeds: {
      fieldName: 'specialNeeds',
      isEditable: true,
      isKeepExistingDataEnabled: true,
      isUseRevisedDataEnabled: true,
      value: {
        type: SmartFormFieldType.CHECKBOXES,
        values: [
          {
            name: 'specialNeeds',
            isChecked: true,
            checkedLabel: 'Special Needs',
            uncheckedLabel: '--',
          },
        ],
      },
    },
    equipmentNeeds: {
      fieldName: 'equipmentNeeds',
      isEditable: true,
      isKeepExistingDataEnabled: true,
      isUseRevisedDataEnabled: true,
      value: {
        type: SmartFormFieldType.CHECKBOXES,
        values: [
          {
            name: 'booster',
            isChecked: true,
            checkedLabel: 'Booster',
          },
          {
            name: 'seatbelt',
            isChecked: false,
            checkedLabel: 'Seatbelt',
          },
          {
            name: 'carseat',
            isChecked: false,
            checkedLabel: 'Carseat',
          },
          {
            name: 'walker',
            isChecked: false,
            checkedLabel: 'Walker',
          },
          {
            name: 'harness',
            isChecked: false,
            checkedLabel: 'Harness',
          },
          {
            name: 'wheelchair',
            isChecked: false,
            checkedLabel: 'Wheelchair',
          },
        ],
      },
    },
    transportationDates: {
      fieldName: 'transportationDates',
      isEditable: true,
      isKeepExistingDataEnabled: true,
      isUseRevisedDataEnabled: true,
      value: {
        type: SmartFormFieldType.DATE_RANGE,
        from: {
          name: 'transportationStartDate',
          label: 'Transportation start date',
          isEditable: true,
        },
        to: {
          name: 'transportationEndDate',
          label: 'Transportation end date',
          isEditable: true,
        },
      },
    },
    transportationProvider: {
      fieldName: 'transportationProvider',
      isEditable: true,
      isKeepExistingDataEnabled: true,
      isUseRevisedDataEnabled: true,
      value: {
        type: SmartFormFieldType.TRANSPORTATION_PROVIDER,
        vendorLabel: 'Transportation provider',
        yardLabel: 'Yard',
        value: {
          vendor: {
            id: '-LB73XKEn5J4lE_ZR5CV',
            name: 'Zum Testing',
          },
          yard: {
            id: '0IoxBoC0IE',
            name: 'Default Yard',
          },
        },
      },
    },
    studentTransportationStatus: {
      fieldName: 'studentTransportationStatus',
      isEditable: true,
      isKeepExistingDataEnabled: true,
      isUseRevisedDataEnabled: true,
      value: {
        type: SmartFormFieldType.STUDENT_TRANSPORTATION_STATUS,
        status: StudentTransportationStatus.CANCELED,
        cancelTransportationDetails: {
          reason: StudentTransportationCancelReason.TRANSPORTATION_NOT_NEEDED,
          effectiveDate: '20210901',
          comment: 'Parent drives the kid himself',
        },
      },
    },
  },
};
