import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const moduleKey = 'Data';
const submoduleKey = 'District Editor';

export const initializeDistrictEditor = createAction(
  `[${moduleKey}] [${submoduleKey}]: Initialize district editor`,
  props<{ districtId: string }>(),
);

export const addDistrictRequested = createAction(
  `[${moduleKey}] [${submoduleKey}]: Add district requested`,
  props<{
    district: fromTypes.District;
    profileImageBase64?: string;
  }>(),
);

export const addDistrictSuccess = createAction(
  `[${moduleKey}] [${submoduleKey}]: Add district success`,
  props<{
    district: fromTypes.District;
  }>(),
);

export const addDistrictFailed = createAction(
  `[${moduleKey}] [${submoduleKey}]: Add district failed`,
  props<{
    error: fromTypes.WpError;
  }>(),
);

export const editDistrictRequested = createAction(
  `[${moduleKey}] [${submoduleKey}]: Edit district requested`,
  props<{
    district: fromTypes.District;
    profileImageBase64?: string;
  }>(),
);

export const editDistrictSuccess = createAction(
  `[${moduleKey}] [${submoduleKey}]: Edit district success`,
  props<{
    district: fromTypes.District;
  }>(),
);

export const editDistrictFailed = createAction(
  `[${moduleKey}] [${submoduleKey}]: Edit district failed`,
  props<{
    districtId: string;
    error: fromTypes.WpError;
  }>(),
);
