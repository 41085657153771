<div class="rating-box">
  <div class="rating-box-inner">
    <div class="rating-icon">
      <div class="star-wrap">
        <wp-portal-icon [path]="iconPaths.STAR_GREEN"></wp-portal-icon>
      </div>
    </div>
    <div class="rating-text">{{ rating | number : '1.1-1' }}</div>
  </div>
</div>
