import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { SmartInputTransportationProviderService } from '../../services';
import { PortalEntity } from '@rootTypes';

@Component({
  selector: 'wp-smart-input-transportation-provider-vendor',
  templateUrl: './smart-input-transportation-provider-vendor.component.html',
  styleUrls: ['./smart-input-transportation-provider-vendor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartInputTransportationProviderVendorComponent implements OnInit {
  public label: string;
  public control: UntypedFormControl;
  public controlStateChange$: Observable<void>;
  public required: boolean;

  constructor(private service: SmartInputTransportationProviderService) {}

  public ngOnInit(): void {
    this.label = this.service.vendorLabel;
    this.control = this.service.vendorControl;
    this.controlStateChange$ = this.service.formGroupStateChange$;
    this.required = this.service.required;
  }

  public onSelectedChanged(event: PortalEntity): void {
    this.service.onVendorInputChanged();
  }
}
