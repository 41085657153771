import { TripListState } from '../types/trip-list';

export const DEFAULT_PAGE_SIZE = 10;

export const getInitialTripListState = (): TripListState => {
  return {
    params: {
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      filters: [],
      searchQuery: undefined,
      status: undefined,
      listByEntity: undefined,
      selectedItemId: undefined,
    },
    api: { isLoading: false, error: undefined, items: [], total: undefined },
  };
};
