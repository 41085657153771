import { RangePickerConfig } from '../entities/range-picker-config';
import { YYYYMMDDString } from '@rootTypes';
import { dateToYYYYMMDD } from '@rootTypes/utils/common/date-time-fns';
import { DayConfigs } from '../../../multi-date-picker/types';

export interface RangePickerState {
  config: {
    entity: RangePickerConfig;
    readonly: boolean;
    updatedAt: number;
  };
  hovered: {
    date: YYYYMMDDString | null;
    updatedAt: number;
  };
  currentView: {
    date: YYYYMMDDString;
    updatedAt: number;
  };
  selected: {
    startDate: YYYYMMDDString | null;
    endDate: YYYYMMDDString | null;
    updatedAt: number;
  };
  dayConfigs: {
    entity: DayConfigs;
    updatedAt: number;
  };
}

export const initialRangePickerStore: RangePickerState = {
  config: {
    readonly: false,
    entity: {
      numMonthsDisplayed: 2,
      isStartWeekFromMonday: false,
      disableDatesBefore: null,
      disableDatesAfter: null,
    },
    updatedAt: 0,
  },
  hovered: {
    date: null,
    updatedAt: 0,
  },
  currentView: {
    date: dateToYYYYMMDD(new Date()),
    updatedAt: 0,
  },
  dayConfigs: {
    entity: {},
    updatedAt: null,
  },
  selected: {
    startDate: null,
    endDate: null,
    updatedAt: 0,
  },
};
