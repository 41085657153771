import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-common-popup-page-title',
  templateUrl: './common-popup-page-title.component.html',
  styleUrls: ['./common-popup-page-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonPopupPageTitleComponent implements OnInit {
  @Input() public isDarkMode: boolean;
  constructor() {}

  ngOnInit(): void {}
}
