import { VehicleInspectionAssignedMechanicFilter, VehicleInspectionAssignedMechanicStatusFilter } from '@apiEntities';
import { MechanicAssignmentQueryParam, MechanicAssignmentStatusQueryParam } from '../types';

export const mechanicAssignmentToQueryParam = (
  value: VehicleInspectionAssignedMechanicFilter,
): MechanicAssignmentQueryParam => {
  if (value === VehicleInspectionAssignedMechanicStatusFilter.ASSIGNED) {
    return MechanicAssignmentStatusQueryParam.ASSIGNED;
  }
  if (value === VehicleInspectionAssignedMechanicStatusFilter.UNASSIGNED) {
    return MechanicAssignmentStatusQueryParam.UNASSIGNED;
  }
  return value;
};
