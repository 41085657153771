import { PortalRouteTracePoint } from '../interfaces';
import { AnimatedLine } from './car-mover';
import { defaultGapPolylineOptions, defaultTracePolylineOptions } from './car-mover/polyline-options';
import {
  getDistanceBetweenPoints,
  removeArrowFromPolyline,
  routeTracePointToLatLng,
  setArrowOnPolyline,
} from './car-mover/utils';
import { MapConfigObserver } from './map-config-observer';
import {
  getClickHTMLForRouteTracePointWithoutVideoLinks,
  getClickHTMLForRouteTracePointWithVideoLinks,
  getHTMLForRouteTracePoint,
} from './get-html-for-route-trace-point';
import { TraceDisposition } from '@rootTypes/entities/ride';
import { NgZone } from '@angular/core';

type PointWithMessage = {
  id: string;
  point: google.maps.LatLng;
  messageHTML?: () => string;
  clickMessageHTML?: () => string;
  traceDisposition?: TraceDisposition;
  distanceFromPrevious: number;
  timestamp: number;
};

interface PointGroup {
  type: 'trace' | 'gap';
  points: PointWithMessage[];
}

/**
 * Renders custom route between the points
 */
export class CustomRouteRenderer {
  private polylines: AnimatedLine[] = [];
  private arrowPolylines: google.maps.Polyline[] = [];
  // for polyline fine-tuning, remove later
  private mapConfig$ = new MapConfigObserver().configChanged$();
  private tracePolylineOptions = defaultTracePolylineOptions;
  private gapPolylineOptions = defaultGapPolylineOptions;
  private viewVideoListeners: { [id: string]: (type: 'driver' | 'all', ts: number) => void } = {};

  constructor(
    private map: google.maps.Map,
    private points: PortalRouteTracePoint[],

    private traceGapMeters: number,
    private showAdditionalRouteTraceInfo: boolean,
    private showRideVideo: boolean,
    private zone: NgZone,
    private tracePolylineOptionsConfig?: google.maps.PolylineOptions,
  ) {
    if (this.tracePolylineOptionsConfig) {
      this.tracePolylineOptions = this.tracePolylineOptionsConfig as {
        strokeOpacity: number;
        strokeColor: string;
        strokeWeight: number;
        zIndex: number;
      };
    }
    this.mapConfig$.subscribe((config) => {
      this.tracePolylineOptions = config.tracePolyline as any;
      this.gapPolylineOptions = config.gapPolyline as any;
      this.traceGapMeters = config.traceGapMeters;
      this.initPolyline(this.points);
      this.updateArrowsOnRoute();
    });
    this.map.addListener('zoom_changed', () => {
      this.updateArrowsOnRoute();
    });
  }

  public setPoints(points: PortalRouteTracePoint[]): void {
    this.points = points;
    this.initPolyline(this.points);
  }

  public addPoints(points: PortalRouteTracePoint[]): void {
    const lastPoint = this.points[this.points.length - 1];
    this.appendPolyline(lastPoint, points);
    this.points = [...this.points, ...points];
  }

  public onViewVideoClicked(listener: (type: 'driver' | 'all', ts: number) => void): string {
    const id = '' + Math.random();
    this.viewVideoListeners[id] = listener;
    return id;
  }

  public show(): void {
    if (!this.polylines) {
      this.initPolyline(this.points);
    }
    this.polylines.forEach((p) => p.show());
  }

  public hide(): void {
    if (this.polylines) {
      this.polylines.forEach((p) => p.hide());
      this.arrowPolylines.forEach((p) => p.setMap(null));
    }
  }

  private appendPolyline(previousLastPoint: PortalRouteTracePoint, addedPoints: PortalRouteTracePoint[]): void {
    if (!previousLastPoint) {
      this.initPolyline(addedPoints);
    } else {
      this.zone.runOutsideAngular(() => {
        const addedPolylines = this.getDisplayPolylinesForPoints([previousLastPoint, ...addedPoints]);
        this.polylines.push(...addedPolylines);
        this.updateArrowsOnRoute();
      });
    }
  }

  private initPolyline(points: PortalRouteTracePoint[]): void {
    this.zone.runOutsideAngular(() => {
      if (this.polylines) {
        this.polylines.forEach((p) => p.hide());
        this.polylines.length = 0;
      }
      this.polylines = this.getDisplayPolylinesForPoints(points);
      this.updateArrowsOnRoute();
    });
  }

  private getDisplayPolylinesForPoints(points: PortalRouteTracePoint[]): AnimatedLine[] {
    const pointGroups = this.getPointGroups(points);
    return (
      pointGroups
        .map((pointGroup) => {
          if (pointGroup.type === 'trace') {
            return this.getTraceAnimatedLines(pointGroup);
          } else {
            const distance = pointGroup.points[pointGroup.points.length - 1].distanceFromPrevious;
            return [
              new AnimatedLine(
                pointGroup.points[0].id,
                this.map,
                pointGroup.points.map((p) => p.point),
                { ...this.gapPolylineOptions },
                undefined,
                undefined,
                undefined,
                { distance },
              ),
            ];
          }
        })
        // flatten array
        .reduce((prev, curr) => {
          return [...prev, ...curr];
        }, [])
    );
  }

  private updateArrowsOnRoute(): void {
    if (!this.polylines?.length) {
      return;
    }
    this.arrowPolylines.forEach((p) => {
      removeArrowFromPolyline(p);
      p.setMap(null);
    });
    this.arrowPolylines.length = 0;
    const arrowBreakPoint = this.getArrowDistanceBreakPointForZoom(this.map.getZoom());
    if (arrowBreakPoint !== undefined) {
      let currDistance = 0;
      let currDisposition = this.polylines[0].traceDisposition;
      const currPath = [this.polylines[0].getFirstPoint()];
      this.polylines.forEach((p) => {
        if (currDistance >= arrowBreakPoint || p.traceDisposition !== currDisposition) {
          this.arrowPolylines.push(this.getArrowPolyline(currPath, currDisposition));
          currDistance = 0;
          currPath.length = 0;
        }
        currDistance += p.meta.distance;
        currPath.push(p.getLastPoint());
        currDisposition = p.traceDisposition;
      });
      if (currPath.length) {
        this.arrowPolylines.push(this.getArrowPolyline(currPath, currDisposition));
      }
    }
  }

  private getArrowPolyline(path: google.maps.LatLng[], traceDisposition: TraceDisposition): google.maps.Polyline {
    const arrowPoly = new google.maps.Polyline({
      path: [...path],
      strokeWeight: 0,
      map: this.map,
      zIndex: 5,
    });
    setArrowOnPolyline(arrowPoly, false, traceDisposition);
    return arrowPoly;
  }

  private getArrowDistanceBreakPointForZoom(zoom: number): number {
    if (zoom >= 19) {
      return 50;
    }
    if (15 <= zoom && zoom < 19) {
      return 100;
    }
    if (12 <= zoom && zoom < 15) {
      return 1000;
    }
    if (9 <= zoom && zoom < 12) {
      return 5000;
    }
    return undefined;
  }

  /**
   * Trace polylines should be split by two points, since
   * we'd like to show popup on hover with the info for these points
   */
  private getTraceAnimatedLines(pointGroup: PointGroup): AnimatedLine[] {
    const options = {
      ...this.tracePolylineOptions,
    };
    const polylines: AnimatedLine[] = [];
    pointGroup.points.forEach((currentPoint, i) => {
      const nextPoint = pointGroup.points[i + 1];
      if (nextPoint) {
        const line = new AnimatedLine(
          currentPoint.id,
          this.map,
          [currentPoint.point, nextPoint.point],
          options,
          currentPoint.messageHTML,
          currentPoint.clickMessageHTML,
          currentPoint.traceDisposition,
          { distance: nextPoint.distanceFromPrevious },
        );
        polylines.push(line);
        line.onViewAllVideoClicked(() => {
          Object.values(this.viewVideoListeners).forEach((listener) => listener('all', currentPoint.timestamp));
        });
        line.onViewDriverVideoClicked(() => {
          Object.values(this.viewVideoListeners).forEach((listener) => listener('driver', currentPoint.timestamp));
        });
        line.onClickMessageChange((isOpen) => {
          if (isOpen) {
            this.polylines.forEach((p) => {
              if (p.id !== currentPoint.id) {
                p.closeClickMessage();
              }
            });
          }
          this.polylines.forEach((p) => p.setHoverDisabled(isOpen));
        });
      }
    });
    return polylines;
  }

  private getMessageHTMLForPoint(point: PortalRouteTracePoint): string {
    return getHTMLForRouteTracePoint(point);
  }

  /**
   * PointGroup is an intermediate data type to assist in drawing route traces and gaps
   * They are determined based on the distance between points
   */
  private getPointGroups(points: PortalRouteTracePoint[]): PointGroup[] {
    if (!points.length) {
      return [];
    }
    const lats: PointWithMessage[] = [];
    for (let i = 0; i < points.length; i++) {
      const p = points[i];
      const messageHTMLFn = this.showAdditionalRouteTraceInfo ? () => this.getMessageHTMLForPoint(p) : undefined;
      const clickMessageHTMLFn = this.showRideVideo
        ? () => getClickHTMLForRouteTracePointWithVideoLinks(p)
        : getClickHTMLForRouteTracePointWithoutVideoLinks(p);
      const target = {
        id: p.id,
        point: routeTracePointToLatLng(p),
        messageHTML: messageHTMLFn,
        clickMessageHTML: clickMessageHTMLFn,
        traceDisposition: p.traceDisposition,
        timestamp: p.collected_timestamp,
      } as PointWithMessage;
      const distanceFromPrevious = i === 0 ? 0 : getDistanceBetweenPoints(lats[lats.length - 1].point, target.point);
      target.distanceFromPrevious = distanceFromPrevious;
      lats.push(target);
    }
    // group points that are within the animation distance
    const groupedPoints = [[]] as PointWithMessage[][];
    for (let i = 0; i < lats.length; i++) {
      const point = lats[i];
      if (point.distanceFromPrevious > this.traceGapMeters) {
        groupedPoints.push([]);
      }
      const lastGroup = groupedPoints[groupedPoints.length - 1];
      lastGroup.push(point);
    }
    const result: PointGroup[] = [];
    for (let j = 0; j < groupedPoints.length; j++) {
      const pointGroup = groupedPoints[j];
      const nextPointGroup = groupedPoints[j + 1];
      // solid line for points within animation distance
      result.push({ type: 'trace', points: pointGroup });
      if (nextPointGroup) {
        // dashed line between point groups
        result.push({ type: 'gap', points: [pointGroup[pointGroup.length - 1], nextPointGroup[0]] });
      }
    }
    return result;
  }
}
