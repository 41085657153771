export enum TimeFormat {
  /**
   * See @type DisplayDateTimeStamp
   */
  DISPLAY_DATE_TIME = 'DISPLAY_DATE_TIME',
  /**
   * YYYYMM
   */
  YEAR_MONTH = 'YEAR_MONTH',
  /**
   * YYYYMMDD
   */
  YEAR_MONTH_DAY = 'YEAR_MONTH_DAY',

  /**
   * See @type UTCTimestamp
   */
  UTC_TIMESTAMP = 'UTC_TIMESTAMP',
}

/**
 * e.g. America/Los-Angeles
 */
export type TimezoneString = string;

export abstract class BaseDate {
  protected value: string | number;
  protected format: TimeFormat;

  constructor(value: string | number, format: TimeFormat) {
    this.value = value;
    this.format = format;
  }

  abstract toDate(tz: TimezoneString): Date;
}

export enum CommonTimezones {
  UTC = 'Etc/UTC',
}
