import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { SmartInputModel } from './smart-input-model';

export interface SmartCheckboxConfig<CheckedValue, UncheckedValue> {
  id: string;
  label?: string;
  uncheckedLabel?: string; // for compatibility with SmartFormFieldCheckbox
  checked?: boolean; // defaults to false
  checkedValue?: CheckedValue;
  uncheckedValue?: UncheckedValue;
  disabled?: boolean;
  labelPosition?: 'before' | 'after' | 'above';
  isLarge?: boolean;
}

export interface SmartCheckboxValue<CheckedValue, UncheckedValue> {
  id: string;
  checked: boolean;
  label?: string;
  // present if checked === true and checkedValue is not undefined
  checkedValue?: CheckedValue;
  // present if checked === false and uncheckedValue is not undefined
  uncheckedValue?: UncheckedValue;
}

export class SmartCheckbox<CheckedValue, UncheckedValue> implements SmartInputModel {
  public id: string;
  public control: UntypedFormControl;
  public label?: string;
  public matLabelPosition: 'before' | 'after';
  public configLabelPosition: 'before' | 'after' | 'above';
  public isLarge: boolean;

  private initiallyChecked?: boolean;
  private checkedValue?: CheckedValue;
  private uncheckedValue?: UncheckedValue;

  constructor(config: SmartCheckboxConfig<CheckedValue, UncheckedValue>) {
    this.id = config.id;
    this.label = config.label;
    this.matLabelPosition = config.labelPosition === 'before' ? 'before' : 'after';
    this.configLabelPosition = config.labelPosition;
    this.isLarge = config.isLarge || false;
    this.initiallyChecked = config.checked || false;
    this.control = new UntypedFormControl(this.initiallyChecked);
    if (config.disabled) {
      this.control.disable();
    }
    this.checkedValue = config.checkedValue;
    this.uncheckedValue = config.uncheckedValue;
  }

  public getValue(): SmartCheckboxValue<CheckedValue, UncheckedValue> {
    const value: SmartCheckboxValue<CheckedValue, UncheckedValue> = {
      id: this.id,
      checked: this.control.value,
    };
    if (this.label) {
      value.label = this.label;
    }
    if (value.checked && typeof this.checkedValue !== 'undefined') {
      value.checkedValue = this.checkedValue;
      return value;
    }
    if (value.checked === false && typeof this.uncheckedValue !== 'undefined') {
      value.uncheckedValue = this.uncheckedValue;
      return value;
    }
    return value;
  }

  public hasChanges(): boolean {
    return this.control.value !== this.initiallyChecked;
  }

  public showErrorIfAny(): void {
    this.control.updateValueAndValidity();
    this.control.markAsTouched();
  }

  public isValid(): boolean {
    return true;
  }

  public setChecked(isChecked: boolean, emitEvent = true, onlySelf = false): void {
    this.control.setValue(isChecked, { emitEvent, onlySelf });
  }

  public setDisabled(isDisabled: boolean, emitEvent = true, onlySelf = false): void {
    if (isDisabled) {
      this.control.disable({ emitEvent, onlySelf });
    } else {
      this.control.enable({ emitEvent, onlySelf });
    }
  }

  public getValueChanges(): Observable<any> {
    return this.control.valueChanges;
  }
}
