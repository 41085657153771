import { Weekday } from '../../../entities/common/weekday';

/**
 * day_0, day_1, ..., day_6;
 * day_0 - sunday
 */
export type PrefixedWeekday = string;

/**
 * This decision map supports conversions between weekday formats
 */
const weekdaysData = {
  [Weekday.MONDAY]: {
    number: 1,
    short: 'Mon',
    shorter: 'Mo',
    xs: 'M',
  },
  [Weekday.TUESDAY]: {
    number: 2,
    short: 'Tue',
    shorter: 'Tu',
    xs: 'T',
  },
  [Weekday.WEDNESDAY]: {
    number: 3,
    short: 'Wed',
    shorter: 'We',
    xs: 'W',
  },
  [Weekday.THURSDAY]: {
    number: 4,
    short: 'Thu',
    shorter: 'Th',
    xs: 'U',
  },
  [Weekday.FRIDAY]: {
    number: 5,
    short: 'Fri',
    shorter: 'Fr',
    xs: 'F',
  },
  [Weekday.SATURDAY]: {
    number: 6,
    short: 'Sat',
    shorter: 'Sa',
    xs: 'Sa',
  },
  [Weekday.SUNDAY]: {
    number: 0,
    short: 'Sun',
    shorter: 'Su',
    xs: 'Su',
  },
};

/**
 *
 * @param dayNumber - 0 for Sunday
 */
export const getWeekdayFromDayNumber = (dayNumber: number): Weekday => {
  return (Object.keys(weekdaysData) as Weekday[]).find(
    (weekday) => weekdaysData[weekday as Weekday].number === dayNumber,
  );
};

export const getShortWeekday = (wd: Weekday): string => {
  return weekdaysData[wd].short;
};

export const getShorterWeekday = (wd: Weekday): string => {
  return weekdaysData[wd].shorter;
};

export const getXSWeekday = (wd: Weekday): string => {
  return weekdaysData[wd]?.xs;
};

/**
 * @param source
 */
export const getWeekdayFromDayPrefixedWeekday = (source: PrefixedWeekday): Weekday => {
  const dayNumber = parseInt(source.replace('day_', ''), 10);
  return getWeekdayFromDayNumber(dayNumber);
};

export const sortWeekdays = (weekdays: Weekday[]): Weekday[] => {
  if (!weekdays) {
    return [];
  }
  const copy = weekdays.slice();
  copy.sort((a, b) => {
    const aIndex = weekdaysData[a] ? weekdaysData[a].number : 100;
    const bIndex = weekdaysData[b] ? weekdaysData[b].number : 100;
    return aIndex - bIndex;
  });
  return copy;
};
