import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getDisplayVehicleCatalogOptionLabel } from '../../data/trips/config.selectors';

@Pipe({
  name: 'displayCatalogOptionLabel',
})
export class DisplayCatalogOptionLabelPipe implements PipeTransform {
  constructor(private store: Store) {}

  transform(catalogId: string): Observable<string> {
    return this.store.select(getDisplayVehicleCatalogOptionLabel(catalogId));
  }
}
