import {
  StudentChangeRequestField,
  StudentChangeRequestExistingDataField,
  StudentChangeRequestOperation,
  StudentChangeRequestReviewFormRow,
  StudentChangeRequestAllFieldsItem,
  StudentChangeRequestFieldType,
} from '@rootTypes/entities/student-change-requests';

export const createStudentChangeRequestFormRows = (
  operation: StudentChangeRequestOperation,
  isOperationSelectedByUser: boolean,
  allFields: StudentChangeRequestAllFieldsItem[],
  existingFields: { [fieldName: string]: StudentChangeRequestExistingDataField },
  changeRequestFields: { [fieldName: string]: StudentChangeRequestField },
): StudentChangeRequestReviewFormRow[] => {
  return allFields.map((fieldItem) => {
    const { fieldName, label, isOptional } = fieldItem;
    let existingData: StudentChangeRequestFieldType;
    if (existingFields[fieldName]) {
      existingData = existingFields[fieldName].value;
    } else {
      existingData = { ...fieldItem.value };
    }

    const changeRequestField = changeRequestFields[fieldName];
    let revisedData: StudentChangeRequestFieldType;
    let isEditable: boolean;
    let isUseRevisedDataEnabled: boolean;
    let isKeepExistingDataEnabled: boolean;
    if (isOperationSelectedByUser && operation === StudentChangeRequestOperation.CREATE) {
      existingData = undefined;
      revisedData = changeRequestField?.value || fieldItem.value;
      isEditable = true;
      isUseRevisedDataEnabled = true;
      isKeepExistingDataEnabled = true;
    } else {
      if (changeRequestField) {
        revisedData = changeRequestField.value;
        isEditable = changeRequestField.isEditable;
        isUseRevisedDataEnabled = changeRequestField.isUseRevisedDataEnabled;
        isKeepExistingDataEnabled = changeRequestField.isKeepExistingDataEnabled;
      } else {
        revisedData = { ...existingData };
        isEditable = false;
        isUseRevisedDataEnabled = false;
        isKeepExistingDataEnabled = false;
      }
    }

    const row: StudentChangeRequestReviewFormRow = {
      label,
      fieldName,
      isOptional,
      isEditable,
      isUseRevisedDataEnabled,
      isKeepExistingDataEnabled,
      isKeepExistingDataSelected: undefined,
      existingData,
      revisedDataCurrent: revisedData,
      revisedDataOriginal: revisedData,
      isEditEnabled: operation !== StudentChangeRequestOperation.DELETE,
      isEdited: false,
      isRevisedDataHighlighted: false,
    };
    return row;
  });
};
