<wp-icon-students-green *ngIf="green"></wp-icon-students-green>
<wp-icon-students-grey *ngIf="!green"></wp-icon-students-grey>
<!-- <svg
  class="icon"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  *ngIf="!green"
>
  <g fill="none" fill-rule="evenodd" transform="translate(3 1)">
    <path
      stroke="#959597"
      stroke-width="2"
      d="M1 9.99c.08 3.737.086 7.74.018 12.01h15.988c.026-4.742.024-8.74-.006-12 0-3.47-3.021-5.988-8-5.988-4.905 0-7.994 2.61-8 5.978z"
    />
    <rect width="9" height="6" x="4.5" y="11.5" stroke="#959597" rx="1" />
    <path
      stroke="#959597"
      stroke-width="2"
      d="M12 3.5C12 1.567 10.657 0 9 0S6 1.567 6 3.5"
    />
    <path class="filled-element" fill="#959597" d="M5 13H13V14H5z" />
    <path stroke="#959597" d="M10.5 14.5H11.5V15.5H10.5z" />
  </g>
</svg>

<svg
  width="24px"
  height="24px"
  viewBox="0 0 24 24"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  *ngIf="green"
>
  <g
    id="2-element-/-icon-/-ic_student_brand"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g transform="translate(3.000000, 1.000000)">
      <path
        d="M9,4.01196289 C11.5095209,4.01196289 13.4936022,4.64305212 14.8689632,5.7109492 C16.262788,6.79318242 17,8.32239813 17.0000415,10.0091135 C17.0296797,13.2611003 17.0315884,17.2559838 17.0058051,21.9937639 L17.0058051,21.9937639 L1.0175456,22 C1.0863024,17.7290675 1.08042554,13.725559 1.00001102,9.98929599 C1.0033475,8.36920378 1.76414005,6.83027139 3.18163007,5.7296633 C4.55378684,4.66425414 6.51942471,4.01196289 9,4.01196289 Z"
        id="Rectangle"
        stroke="#47A540"
        stroke-width="2"
      ></path>
      <rect
        id="Rectangle"
        stroke="#47A540"
        x="4.5"
        y="11.5"
        width="9"
        height="6"
        rx="1"
      ></rect>
      <path
        d="M12,3.5 C12,1.56700338 10.6568542,0 9,0 C7.34314575,0 6,1.56700338 6,3.5"
        id="Oval"
        stroke="#47A540"
        stroke-width="2"
      ></path>
      <rect
        id="Rectangle"
        fill="#47A540"
        x="5"
        y="13"
        width="8"
        height="1"
      ></rect>
      <rect
        id="Rectangle"
        fill="#47A540"
        x="10"
        y="14"
        width="1"
        height="2"
      ></rect>
    </g>
  </g>
</svg> -->
