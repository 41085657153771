<div
  class="empty-folder__wrap"
  [ngClass]="{ 'at-center': alignAtCenter }"
  [ngStyle]="{ 'margin-top': marginTopStr }"
>
  <div class="empty-folder__inner-box">
    <div class="empty-folder__image">
      <img src="/assets/img/empty-folder-grey.svg" height="{{height}}" />
    </div>
    <div class="empty-folder__text-wrap">
      <div>
        <div class="empty-folder__text">
          <ng-content></ng-content>
        </div>
        <div class="empty-folder__subtext">
          <ng-content select=".subtext"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
