import { Injectable } from '@angular/core';
import { PortalEntityType } from '@rootTypes';
import { Observable } from 'rxjs/internal/Observable';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { RidesDetailsDrawerRouterService } from '../../router/portal-routes/rides/rides-details-drawer-router.service';
import { getDisplaySpecificContextEntityTypeLabel } from './timesheet-entry-context.selectors';
import { getRideV2Requested } from '../rides/store/actions/rides-data.actions';
import { getRideV2, isRideV2Loading } from '../rides/store/selectors/ride-data-v2.selectors';
import { UTCTime } from '@rootTypes/utils/common/date';
import { RideV2 } from '@apiEntities/rides/ride-v2';
import { getRideScheduledEndTimestamp } from '@rootTypes/utils/ride/get-ride-scheduled-end-timestamp';
import { getRideActualEndTimestamp } from '@rootTypes/utils/ride/get-ride-actual-end-timestamp';

@Injectable()
export class ContextSpecificItemFacade {
  constructor(
    private store: Store,
    private rideDetailsRoute: RidesDetailsDrawerRouterService,
  ) {}

  public onItemInitialized(entityId: string, entityType: PortalEntityType): void {
    switch (entityType) {
      case PortalEntityType.RIDE:
        return this.onRideInitialized(entityId);
      default:
        throw new Error('Unknown context entity type initialized');
    }
  }

  public isLoading$(entityId: string, entityType: PortalEntityType): Observable<boolean> {
    switch (entityType) {
      case PortalEntityType.RIDE:
        return this.isRideLoading$(entityId);
      default:
        throw new Error('Unknown context entity type loading requested');
    }
  }

  public getEntityLinkLabel$(entityId: string, entityType: PortalEntityType): Observable<string> {
    switch (entityType) {
      case PortalEntityType.RIDE:
        return this.getRideLinkLabel$(entityId);
      default:
        throw new Error('Unknown context entity type link label requested');
    }
  }

  public onItemLinkClicked(entityId: string, entityType: PortalEntityType): void {
    switch (entityType) {
      case PortalEntityType.RIDE:
        this.rideDetailsRoute.navigate({ rideId: entityId, displayBackText: 'Back to activity' });
        break;
      default:
        throw new Error('Navigation to ' + entityType + ' details not implemented');
    }
  }

  public getTimeLabel$(entityId: string, entityType: PortalEntityType): Observable<string> {
    switch (entityType) {
      case PortalEntityType.RIDE:
        return this.getRideTimeLabel$(entityId);
      default:
        throw new Error('Unknown context entity type time label requested');
    }
  }

  public getActualTimeLabel$(entityId: string, entityType: PortalEntityType): Observable<string> {
    switch (entityType) {
      case PortalEntityType.RIDE:
        return this.getRideActualTimeLabel$(entityId);
      default:
        throw new Error('Unknown context entity type time label requested');
    }
  }

  public getEntityTypeLabel$(activityId: string): Observable<string> {
    return this.store.select(getDisplaySpecificContextEntityTypeLabel(activityId));
  }

  private onRideInitialized(rideId: string): void {
    this.store.dispatch(getRideV2Requested({ request: { rideId } }));
  }

  private isRideLoading$(rideId): Observable<boolean> {
    return this.store.select(isRideV2Loading(rideId));
  }

  private getRideLinkLabel$(rideId): Observable<string> {
    return this.store.select(getRideV2(rideId)).pipe(map((ride) => ride?.displayId));
  }

  private getRideDetailsLink$(rideId: string): Observable<string> {
    return of(this.rideDetailsRoute.getUrlTree({ rideId, displayBackText: undefined }).toString());
  }

  private getRideTimeLabel$(rideId: string): Observable<string> {
    return this.store.select(getRideV2(rideId)).pipe(
      filter((ride) => !!ride),
      map((ride: RideV2) => {
        const start = new UTCTime(ride.startTimestamp.scheduled, ride.timezone).getDisplayTimeStrNoTZ();
        const endStamp = getRideScheduledEndTimestamp(ride);
        const end = new UTCTime(endStamp, ride.timezone).getDisplayTimeStrNoTZ();
        return `${start} - ${end}`;
      }),
    );
  }

  private getRideActualTimeLabel$(rideId: string): Observable<string> {
    return this.store.select(getRideV2(rideId)).pipe(
      filter((ride) => !!ride),
      map((ride) => {
        const actualStart = ride?.startTimestamp?.actual;
        if (!actualStart) {
          return '--';
        }
        const displayStart = new UTCTime(actualStart, ride.timezone).getDisplayTimeStrNoTZ();
        const actualEnd = getRideActualEndTimestamp(ride);
        if (!actualEnd) {
          return `${displayStart} - N/A`;
        }
        const displayEnd = new UTCTime(actualEnd, ride.timezone).getDisplayTimeStrNoTZ();
        return `${displayStart} - ${displayEnd}`;
      }),
    );
  }
}
