import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { NavigationRequest } from '../../types/navigation-request';
import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '../../../types/entities/router';
import { RouteExplorerView } from '@rootTypes/entities/route-explorer/route-explorer-view';
import { YYYYMMDDString } from '@rootTypes';
import { RouteListAdditionalFiltersValue, RouteExplorerMapLayer } from '../../../features/route-explorer/dependencies';
import { stringOrArrayToArray } from '@rootTypes/utils/common/string-or-array-to-array';

export interface RouteExplorerRouteData {
  districtId?: string;
  campusId?: string;
  selectedDistrictProgramIds?: string[];
  view?: RouteExplorerView;
  // route explorer view
  explorerAdditionalFilters?: RouteListAdditionalFiltersValue;
  explorerLayers?: string[];
  sandboxProgramId?: string;
  // route utilization view
  editGroupRouteId?: string;
  editGroupWeekday?: YYYYMMDDString;
  utilizationStartDateOfTheWeek?: YYYYMMDDString;
  viewRouteGroupId?: string;
  viewRouteId?: string;
  viewRouteGroupWeekday?: string;
  viewRouteGroupStartDateOfTheWeek?: string;
}

export const routeExplorerRoute: PortalRoute<RouteExplorerRouteData> = {
  path: undefined,
  isOnRoute: (state: RouterStateUrl): boolean => {
    throw new Error('Not implemented');
  },
  parse: (state: RouterStateUrl): RouteExplorerRouteData => {
    let data = {
      view: getViewParamFromUrl(state.url) || RouteExplorerView.DEFAULT,
    } as RouteExplorerRouteData;
    if (state.queryParams) {
      data = {
        ...data,
        ...state.queryParams,
      };
    }
    if (state.queryParams?.selectedDistrictProgramIds) {
      data.selectedDistrictProgramIds = state.queryParams.selectedDistrictProgramIds.split(',');
    }
    if (state.queryParams?.explorerAdditionalFilters) {
      try {
        data.explorerAdditionalFilters = JSON.parse(atob(state.queryParams.explorerAdditionalFilters));
      } catch (e) {
        console.error('Error parsing explorerAdditionalFilters', e);
      }
    }
    if (state.queryParams?.explorerLayers) {
      data.explorerLayers = stringOrArrayToArray(state.queryParams?.explorerLayers) as RouteExplorerMapLayer[];
    }
    return data;
  },
  request: (
    data: RouteExplorerRouteData,
    queryParamStrategy?: QueryParamsHandlingStrategy,
    replaceUrl?,
  ): NavigationRequest => {
    const req: NavigationRequest = {
      path: ['route-management', 'explorer', data.view || RouteExplorerView.DEFAULT],
    };
    delete data.view;
    const query = { ...data } as any;
    if (data.selectedDistrictProgramIds) {
      query.selectedDistrictProgramIds = data.selectedDistrictProgramIds.join(',');
    } else {
      query.selectedDistrictProgramIds = undefined;
    }
    if (data.explorerAdditionalFilters) {
      query.explorerAdditionalFilters = btoa(JSON.stringify(data.explorerAdditionalFilters));
    } else {
      query.explorerAdditionalFilters = undefined;
    }
    if (Object.keys(query).length) {
      req.query = query;
    }
    if (queryParamStrategy) {
      req.extras = {
        queryParamsHandling: queryParamStrategy,
      };
    }
    if (replaceUrl) {
      req.extras = req.extras || {};
      req.extras.replaceUrl = true;
    }
    return req;
  },
};

@Injectable({ providedIn: 'root' })
export class RouteExplorerRouterService extends PortalRouterService<RouteExplorerRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route: PortalRoute<RouteExplorerRouteData> = routeExplorerRoute;
}

function getViewParamFromUrl(url: string): RouteExplorerView {
  const beforeBrackets = url.split('(')[0];
  return beforeBrackets.split('?')[0].split('/')[3] as RouteExplorerView;
}
