import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import {
  dateFormat,
  dateParse,
  dateToHHMM,
  formattingConstants,
  hhmmIsValid,
  hhmmToDate,
} from '@rootTypes/utils/common/date-time-fns';
import { isValidDateString } from '@rootTypes/utils/common/date-time-fns/is-valid-date-string';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wp-input-time-increment',
  templateUrl: './input-time-increment.component.html',
  styleUrls: ['./input-time-increment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputTimeIncrementComponent implements OnInit, OnChanges {
  @Input() public control: AbstractControl;
  @Input() public controlValueUpdater: any;
  @Input() public incrementMillis: number = 5 * 60 * 1000;
  @Input() public displayFormat: keyof typeof formattingConstants = 'hoursAndMinutesNoPadding';
  @Input() public preventManualInput: boolean;
  @Input() public inputWidthStr = '50px';

  public displayInputValue: string;
  public timestampInputValue: number;
  private subs = new Subscription();

  constructor() {}

  ngOnInit(): void {
    if (!this.control) {
      this.control = new UntypedFormControl();
    }
    this.setStateFromControlValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.controlValueUpdater && !changes.controlValueUpdater.isFirstChange()) {
      this.setStateFromControlValue();
    }
  }

  public onInputBlurEvent(): void {
    if (this.preventManualInput) {
      return;
    }
    const event = this.displayInputValue;
    if (isValidDateString(event, this.displayFormat)) {
      const date = dateParse(event, formattingConstants[this.displayFormat]);
      this.timestampInputValue = this.roundTimestampValueToIncrement(date.getTime());
      const displayDate = new Date(this.timestampInputValue);
      this.displayInputValue = dateFormat(displayDate, formattingConstants[this.displayFormat]);
      this.control.setValue(dateToHHMM(displayDate));
    } else {
      this.displayInputValue = undefined;
      this.timestampInputValue = undefined;
      this.control.setValue(undefined);
    }
  }

  public onIncrement(): void {
    this.timestampInputValue = this.getCurrentTimestamp() + this.incrementMillis;
    const date = new Date(this.timestampInputValue);
    this.displayInputValue = dateFormat(date, formattingConstants[this.displayFormat]);
    this.control.setValue(dateToHHMM(date));
  }

  public onDecrement(): void {
    this.timestampInputValue = this.getCurrentTimestamp() - this.incrementMillis;
    const date = new Date(this.timestampInputValue);
    this.displayInputValue = dateFormat(date, formattingConstants[this.displayFormat]);
    this.control.setValue(dateToHHMM(date));
  }

  private roundTimestampValueToIncrement(source: number): number {
    if (!source) {
      return 0;
    }
    const halfIncr = Math.floor(this.incrementMillis / 2);
    const rem = source % this.incrementMillis;
    if (rem === 0) return source;
    else if (rem < halfIncr) return source - rem;
    else return source - rem + this.incrementMillis;
  }

  private getCurrentTimestamp(): number {
    return this.roundTimestampValueToIncrement(this.timestampInputValue || new Date().getTime());
  }

  private setStateFromControlValue(): void {
    if (this.control.value) {
      if (hhmmIsValid(this.control.value)) {
        const date = hhmmToDate(this.control.value);
        this.timestampInputValue = date.getTime();
        this.displayInputValue = dateFormat(date, formattingConstants[this.displayFormat]);
      } else {
        console.warn('Invalid time input control value (expected HHmm): ', this.control.value);
      }
    }
  }
}
