import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { isArrowDownEvent, isArrowUpEvent, isEnterEvent } from '@rootTypes/utils/common/dom';

@Directive({
  selector: '[wpKeyboardListener]',
})
export class KeyboardListenerDirective implements AfterViewInit, OnDestroy {
  @Output() public enterPressed = new EventEmitter<void>();
  @Output() public arrowDownPressed = new EventEmitter<void>();
  @Output() public arrowUpPressed = new EventEmitter<void>();
  @Output() public controlPressed = new EventEmitter<void>();
  @Output() public controlReleased = new EventEmitter<void>();

  private sub: Subscription = new Subscription();
  constructor(private elRef: ElementRef) {}

  ngAfterViewInit(): void {
    const sub = fromEvent(this.elRef.nativeElement, 'keydown').subscribe((event: KeyboardEvent) => {
      if (isEnterEvent(event)) {
        this.enterPressed.emit();
      } else if (isArrowUpEvent(event)) {
        this.arrowUpPressed.emit();
      } else if (isArrowDownEvent(event)) {
        this.arrowDownPressed.emit();
      } else if (event.ctrlKey) {
        this.controlPressed.emit();
        event.preventDefault();
      }
      event.stopPropagation();
    });
    const sub2 = fromEvent(this.elRef.nativeElement, 'keyup').subscribe((event: KeyboardEvent) => {
      if (event.ctrlKey) {
        this.controlReleased.emit();
      }
      event.stopPropagation();
    });
    this.sub.add(sub);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
