export interface DownloadImageSize {
  width: number;
  height: number;
}

// The most common person preview width in the app: 100-132px
export const downloadImageSizePerson = { width: 132, height: 132 };

// The most common vehicle preview width in the app: 132-184px
export const downloadImageSizeVehicle = { width: 184, height: 138 };
