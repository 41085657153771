import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wp-alert-critical',
  templateUrl: './alert-critical.component.html',
  styleUrls: ['./alert-critical.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertCriticalComponent implements OnInit {
  @Input() readonly = false;
  @Output() closed = new EventEmitter<void>();
  constructor() {}

  ngOnInit(): void {}
}
