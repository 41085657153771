import { Injectable } from '@angular/core';

import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';

import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';

export type DrawerAccessDeniedRouteData = {};

const statePath = 'access-denied';

export const drawerAccessDeniedRoute: PortalRoute<DrawerAccessDeniedRouteData> = {
  path: `drawer/${statePath}`,
  isOnRoute(state: RouterStateUrl): boolean {
    return state.drawer && state.drawer.url && state.drawer.url.startsWith(`_/drawer/${statePath}`);
  },
  parse(state: RouterStateUrl): DrawerAccessDeniedRouteData {
    return {};
  },
  request(
    data?: DrawerAccessDeniedRouteData,
    queryParamStrategy?: QueryParamsHandlingStrategy,
    replaceUrl?: boolean,
  ): NavigationRequest {
    const path = ['_', 'drawer', statePath];
    const request: NavigationRequest = { path };
    if (queryParamStrategy) {
      request.extras = {
        queryParamsHandling: queryParamStrategy,
      };
    }
    if (replaceUrl) {
      request.extras = { ...(request.extras || {}), replaceUrl };
    }
    return request;
  },
};

@Injectable({ providedIn: 'root' })
export class DrawerAccessDeniedRouterService extends PortalRouterService<DrawerAccessDeniedRouteData> {
  outlet = RouterOutlets.DRAWER;
  route: PortalRoute<DrawerAccessDeniedRouteData> = drawerAccessDeniedRoute;
}
