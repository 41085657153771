import { createSelector } from '@ngrx/store';
import { getYardFeature } from './get-feature';
import { getAllVendorIdsByYards, getAllYardEntities } from './yard-data.selectors';

const getYardDetailsState = createSelector(getYardFeature, (state) => state.details);

export const getTab = createSelector(getYardDetailsState, (state) => state.tab);

export const getOpenedYardId = createSelector(getYardDetailsState, (state) => state.yardId);

const getOpenedYardEntityState = createSelector(getOpenedYardId, getAllYardEntities, (yardId, allEntities) => {
  return allEntities[yardId];
});

export const getOpenedYard = createSelector(getOpenedYardEntityState, (state) => state?.entity);

export const getOpenedYardLoading = createSelector(getOpenedYardEntityState, (state) => state?.isLoading || false);

export const getOpenedYardVendorId = createSelector(getOpenedYardId, getAllVendorIdsByYards, (yardId, allVendorIds) => {
  return allVendorIds[yardId]?.vendorId;
});

export const getOpenedYardError = createSelector(getOpenedYardEntityState, (state) => state?.error);
