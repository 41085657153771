import {
  dateAddDay,
  dateEndOfMonth,
  dateEndOfWeek,
  dateFormat,
  dateStartOfDay,
  dateStartOfMonth,
  dateStartOfWeek,
  dateSubtractDay,
  dateToYYYYMM,
  dateToYYYYMMDD,
  formattingConstants,
  yyyymmddToDate,
} from '@rootTypes/utils/common/date-time-fns';
import { BaseDate, TimeFormat, TimezoneString } from './base-date';

/**
 * Like 202008
 */
export type YYYYMMString = string;

/**
 * Like 20200818
 */
export type YYYYMMDDString = string;

export class YearMonthDay extends BaseDate {
  public static currentMonth(timezone?: string): YYYYMMString {
    return dateToYYYYMM(new Date(), timezone);
  }

  public static today(timezone?: string): YYYYMMDDString {
    return dateToYYYYMMDD(new Date(), timezone);
  }

  public static yesterday(timezone?: string, isStartOfDay = true): YYYYMMDDString {
    let d = dateSubtractDay(new Date(), 1);
    if (isStartOfDay) {
      d = dateStartOfDay(d);
    }
    return dateToYYYYMMDD(d, timezone);
  }

  public static tomorrow(timezone?: string): YYYYMMDDString {
    let d = dateAddDay(new Date(), 1);
    d = dateStartOfDay(d);
    return dateToYYYYMMDD(d, timezone);
  }

  public static fromDate(date: Date): YYYYMMDDString {
    return dateToYYYYMMDD(date);
  }

  public static fromUTCStamp(utc: number, timezone?: string): YYYYMMDDString {
    return dateToYYYYMMDD(new Date(utc), timezone);
  }

  private tz: TimezoneString;

  constructor(value: YYYYMMDDString, tz?: TimezoneString) {
    super(value, TimeFormat.YEAR_MONTH_DAY);
    this.tz = tz;
  }

  toDate(tz: string = this.tz): Date {
    return yyyymmddToDate(this.value as string, tz);
  }

  formatTo(format: keyof typeof formattingConstants = 'dateAndWeekdayMedium'): string {
    return dateFormat(this.toDate(), formattingConstants[format], this.tz);
  }

  startSundayOfWeek(): YYYYMMDDString {
    return dateToYYYYMMDD(dateStartOfWeek(this.toDate(), false));
  }

  endSaturdayOfWeek(): YYYYMMDDString {
    return dateToYYYYMMDD(dateEndOfWeek(this.toDate(), false));
  }

  startMondayOfWeek(): YYYYMMDDString {
    return dateToYYYYMMDD(dateStartOfWeek(this.toDate(), true));
  }

  startOfMonth(): YYYYMMDDString {
    return dateToYYYYMMDD(dateStartOfMonth(this.toDate()));
  }

  endOfMonth(): YYYYMMDDString {
    return dateToYYYYMMDD(dateEndOfMonth(this.toDate()));
  }

  startOfPreviousMonth(): YYYYMMDDString {
    const prevMonth = dateSubtractDay(dateStartOfMonth(this.toDate()), 1);
    return dateToYYYYMMDD(dateStartOfMonth(prevMonth));
  }

  endOfPreviousMonth(): YYYYMMDDString {
    const prevMonth = dateSubtractDay(dateStartOfMonth(this.toDate()), 1);
    return dateToYYYYMMDD(dateEndOfMonth(prevMonth));
  }

  endSundayOfWeek(): YYYYMMDDString {
    return dateToYYYYMMDD(dateEndOfWeek(this.toDate(), true));
  }

  startSundayOfPreviousWeek(): YYYYMMDDString {
    const prevWeek = dateSubtractDay(dateStartOfWeek(this.toDate(), false), 1);
    return dateToYYYYMMDD(dateStartOfWeek(prevWeek, false));
  }

  endSaturdayOfPreviousWeek(): YYYYMMDDString {
    const prevWeek = dateSubtractDay(dateStartOfWeek(this.toDate(), false), 1);
    return dateToYYYYMMDD(dateEndOfWeek(prevWeek, false));
  }
}
