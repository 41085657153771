import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { AppVersionUpdateService } from '../services/app-version-update.service';

@Component({
  selector: 'wp-new-app-version-available-popup',
  templateUrl: './new-app-version-available-popup.component.html',
  styleUrl: './new-app-version-available-popup.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewAppVersionAvailablePopupComponent implements OnInit {
  public isOpened$: Observable<boolean>;

  constructor(private service: AppVersionUpdateService) {}

  public ngOnInit(): void {
    this.isOpened$ = this.service.isNewVersionAvailablePopupOpened$;
  }

  public onApply(): void {
    this.service.applyNewVersion();
  }

  public onDelay(): void {
    this.service.delayNewVersion();
  }
}
