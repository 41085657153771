import { Injectable } from '@angular/core';
import { entitySearchResultToPortalEntity } from '@rootTypes/utils/portal-entity/entity-search-result-to-portal-enity';
import { map, Observable } from 'rxjs';
import * as fromApi from '../../../api';
import { ApiService } from '../../../api/api.service';
import * as fromTypes from '../types';

@Injectable()
export class CampusApiService {
  constructor(private api: ApiService) {}

  public entityFilter(
    request: fromApi.entityFilter.EntityFilterRequest,
  ): Observable<{ total: number; items: fromTypes.PortalEntity[] }> {
    return this.api.entityFilter(request).pipe(
      map((res) => {
        return {
          total: res.total,
          items: res.results.map((r) => entitySearchResultToPortalEntity(r)),
        };
      }),
    );
  }

  public getCampus(campusId: string): Observable<fromTypes.Campus> {
    return this.api
      .getCampus({ campusId })
      .pipe(map((res) => fromApi.getCampus.convertors.getCampusFromApi(res.campus, res.campus.checks)));
  }

  public getAllChecksForCampus(): Observable<fromTypes.Check[]> {
    return this.api.getChecks({ type: fromApi.getChecks.CheckType.CAMPUS });
  }

  public getCampusCalendars(campusId: string, districtId: string): Observable<fromTypes.LoadCalendarPayload> {
    return this.api.getCampusCalendars(campusId, districtId).pipe(
      map((res) => {
        const allSchedules = res.reduce((prev, curr) => {
          return [...prev, ...(curr.campusSchedules || [])];
        }, []);
        return {
          calendars: res.map((s) => fromApi.getCampusCalendars.convertors.getCampusCalendarFromApi(s)),
          schedules: allSchedules.map((s) => fromApi.getCampusCalendars.convertors.getCampusScheduleFromApi(s)),
          schedulesByCalendars: fromApi.getCampusCalendars.convertors.getScheduleIdsByCalendars(res),
        };
      }),
    );
  }

  public getCampusLocations(campusId: string, districtId: string): Observable<fromTypes.CampusLocation[]> {
    return this.api.getCampusLocations(campusId, districtId).pipe(map((res) => res.pickupDropoffLocations));
  }

  public makeLocationPrimary(
    locationId: string,
    campusId: string,
  ): Observable<fromApi.upsertCampusLocation.UpsertCampusLocationResponse> {
    return this.api.upsertCampusLocation({
      campusId,
      pickupDropoffLocationId: locationId,
      isPrimary: true,
    });
  }

  public makeCalendarDefault(
    calendarId: string,
    campusId: string,
    districtId: string,
  ): Observable<fromApi.upsertCampusCalendar.UpsertCampusCalendarResponse> {
    return this.api.upsertCampusCalendar({
      calendarId,
      campusId,
      districtId,
      isDefault: true,
    });
  }

  public deactivateCampusCalendar(
    calendarId: string,
    campusId: string,
    districtId: string,
  ): Observable<fromApi.deactivateCampusCalendar.DeactivateCampusCalendarResponse> {
    return this.api.deactivateCampusCalendar({
      calendarId,
      campusId,
      districtId,
    });
  }

  public deactivateCampusSchedule(
    scheduleId: string,
    calendarId: string,
    campusId: string,
    districtId: string,
  ): Observable<fromApi.deactivateCampusSchedule.DeactivateCampusScheduleResponse> {
    return this.api.deactivateCampusSchedule({
      campusScheduleId: scheduleId,
      calendarId,
      campusId,
      districtId,
    });
  }

  public deactivateCampusLocation(locationId: string, campusId: string): Observable<void> {
    return this.api.deactivateCampusLocation({
      campusId,
      pickupDropoffLocationId: locationId,
    });
  }

  public deactivateCampus(
    campusId: string,
    districtId: string,
  ): Observable<fromApi.deactivateCampus.DeactivateCampusResponse> {
    return this.api.deactivateCampus({
      campusId,
      districtId,
    });
  }

  public upsertCampusLocation(
    request: fromApi.upsertCampusLocation.UpsertCampusLocationRequest,
  ): Observable<fromApi.upsertCampusLocation.UpsertCampusLocationResponse> {
    return this.api.upsertCampusLocation(request);
  }
}
