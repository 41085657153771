import { EmployeeListSnapshot } from '../entities';
import { EntityFilterRequest } from '../../../../api/endpoints/entity-filter';
import { getEntityFilterRequest } from '@rootTypes/utils/portal-entity/get-entity-filter-request';
import { EntitySearchResponseType } from '@apiEntities/entity-search';

export const getEmployeeListRequest = (snapshot: EmployeeListSnapshot): EntityFilterRequest => {
  const { page, pageSize, search, status, filters } = snapshot;
  const skip = page * pageSize;
  const limit = pageSize;
  return getEntityFilterRequest(search, EntitySearchResponseType.SCHOOL_EMPLOYEE, status, skip, limit, {});
};
