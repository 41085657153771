import { YYYYMMDDString } from '../common/yyyymmdd';
import { RouteBundle } from './route-bundle';
import { RouteGroupSchedule } from './route-group-schedule';
import { Weekday } from '@apiEntities/common/weekday';
import { RouteStatus } from '@apiEntities/route-explorer-route-management/route-status';

export type RouteGroupDistrictProgram = {
  id: string;
  label: string;
  isSelected: boolean;
};

export interface RouteGroup {
  routeGroupId: string;
  routeGroupName: string;
  timezone: string;
  routeGroupEffectiveFrom: YYYYMMDDString;
  routeGroupEffectiveTo: YYYYMMDDString;
  district: {
    districtId: string;
    districtLabel: string;
  };
  districtPrograms?: RouteGroupDistrictProgram[];
  hasUnpublishedChanges: boolean;
  ownerDrivers: RouteGroupOwnerDriver[];
  yard: {
    yardId: string;
    yardLabel: string;
  };
  vehicleType?: {
    label: string;
    vehicleType: string;
  };
  vendor: { vendorId: string; vendorLabel: string };
  routeGroupSchedule?: RouteGroupScheduleInfo;
  routeGroupSchedulesForThisWeek: RouteGroupScheduleVersion[];
  pendingRouteGroupScheduleChangeRequestId?: string;
  pendingRouteGroupScheduleChangeCount: number; // used in the route group list, not required in route group layout page
  // used in the route group list, not required in route group layout page
  activityPaymentConfig?: {
    activityTypeId: string;
    activitySubTypeId: string;
    paymentOptionIds?: string[];
  };
  isActivitySyncEnabled: boolean;
  lastNotificationDate?: YYYYMMDDString;
  latestChangeDate?: YYYYMMDDString;
  status: RouteStatus;
}

export type RouteGroupOwnerDriver = {
  driverId?: string;
  driverLabel?: string;
  effectiveFrom: YYYYMMDDString;
};

export type AssignmentType = 'OWNER' | 'COVER';
export interface RouteGroupScheduleInfo {
  version: string; // corresponds to the version , V1, V2, V3
  routeGroupScheduleId: string;
  routeGroupScheduleEffectiveFrom: YYYYMMDDString;
  effectiveFrom: YYYYMMDDString; //Sun Apr 4
  effectiveTo: YYYYMMDDString; // Sat Apr 10
  schedule: RouteGroupSchedule;
  driverAssignments: {
    [weekday in Weekday]?: {
      drivers: {
        driverId: string;
        driverLabel: string;
        assignmentType: AssignmentType;
      }[];
      isFullDayAssigned: boolean;
    };
  };
  routeBundles: RouteBundle[];
  daysString: string; //  Mo/Tu/
  totalHours: number; // used in the route group list, not required in route group layout page
  hasAdjustedDeadhead: boolean;
}

export interface RouteGroupScheduleVersion {
  version: string; // corresponds to the version , V1, V2, V3
  routeGroupScheduleId: string;
}

export type RouteGroupScheduleDriverAssignment = {
  [weekday in Weekday]?: {
    driverId: string;
    driverLabel: string;
    date: YYYYMMDDString;
    assignmentType: AssignmentType;
  };
};

export enum RouteGroupSheetType {
  FACE_SHEET = 'FACE_SHEET',
  ROUTE_SHEET = 'ROUTE_SHEET',
}

export enum RouteGroupQuickFilterType {
  SHIFT_TIME_CHANGE = 'SHIFT_TIME_CHANGE',
  NO_CHANGE = 'NO_CHANGE',
  ALL = 'ALL',
  ROUTE_CHANGE = 'ROUTE_CHANGE',
}

export enum RouteGroupDownloadSheetsFilterType {
  ROUTE_CHANGE = 'ROUTE_CHANGE',
  ALL = 'ALL',
}
