import { Injectable } from '@angular/core';
import { SmartInputStudentSchoolInfo, SmartSelect, SmartSelectAsync } from '../models';

@Injectable()
export class SmartInputStudentSchoolInfoService {
  public district: SmartSelect;
  public campus: SmartSelect;
  public schoolYear: SmartSelectAsync;
  public grade: SmartSelectAsync;

  constructor() {}

  public initialize(model: SmartInputStudentSchoolInfo): void {
    this.district = model.district;
    this.campus = model.campus;
    this.schoolYear = model.schoolYear;
    this.grade = model.grade;
  }
}
