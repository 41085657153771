import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as fromTypes from '../types';
import { EntityStatus } from '../types';

@Component({
  selector: 'wp-vehicle-select',
  templateUrl: './vehicle-select.component.html',
  styleUrls: ['./vehicle-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleSelectComponent implements OnInit {
  /**
   * Container VehiclePortalEntity
   */
  @Input() public control: UntypedFormControl;
  /**
   * Input placeholder
   */
  @Input() public label: string;
  /**
   * Show only vehicles of these vendors
   */
  @Input() public vendorIds: string[];
  /**
   * Show only vehicles of these vendor's yards
   */
  @Input() public yardIds: string[];

  @Input() public operatorIds: string[];

  public searchByType = fromTypes.PortalEntityType.VEHICLE;
  public initialDisplayValue: string;

  public entityStatus = EntityStatus.ACTIVE;

  constructor() {}

  ngOnInit(): void {
    this.initialDisplayValue =
      this.control && this.control.value && this.control.value.label ? this.control.value.label : null;
  }

  public onOptionSelect(option: fromTypes.PortalEntity): void {
    this.control.setValue(option as fromTypes.VehiclePortalEntity);
  }
}
