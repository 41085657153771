import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromTypes from '../../types';
import * as fromActions from '../actions/routes-data.actions';
import * as fromSelectors from '../selectors/route-data.selectors';
import * as fromDriverByRoutesSelectors from '../selectors/drivers-by-routes.selectors';
import * as fromVehiclesByRoutesSelectors from '../selectors/vehicles-by-routes.selectors';
import { GetStudentSnapshotResponse } from '../../../../api/endpoints/get-student-snapshot';

@Injectable()
export class RouteDataFacade {
  constructor(private store: Store<State>) {}

  /**
   * Also sets order sequence on the stop to be displayed in timeline
   */
  public getActiveStopsByRouteByWeekday$(
    routeId: string,
    weekday: fromTypes.Weekday,
  ): Observable<fromTypes.RouteStop[]> {
    return this.store
      .select(fromSelectors.getActiveStopsByRouteByWeekday(weekday), {
        routeId,
      })
      .pipe(
        map((stops) => {
          return stops.map((source, index) => ({
            ...source,
            orderSequence: index,
          }));
        }),
      );
  }

  public getActiveRidersByRouteByWeekday$(
    routeId: string,
    weekday: fromTypes.Weekday,
  ): Observable<GetStudentSnapshotResponse[]> {
    return this.store.select(fromSelectors.getActiveRidersByRouteByWeekday(weekday), { routeId });
  }

  public getCalendarForRoute$(routeId: string): Observable<fromTypes.RouteCalendar> {
    return this.store.select(fromSelectors.getCalendar, { routeId });
  }

  public getCalendarLoadingForRoute$(routeId: string): Observable<boolean> {
    return this.store.select(fromSelectors.getCalendarLoading, { routeId });
  }

  public getCalendarErrorForRoute$(routeId: string): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getCalendarError, { routeId });
  }

  public getDefaultDriverForRouteForWeekday$(
    routeId: string,
    weekday: fromTypes.Weekday,
  ): Observable<fromTypes.Driver> {
    return this.store.select(fromDriverByRoutesSelectors.getDefaultDriver, {
      routeId,
      weekday,
    });
  }

  public getDefaultDriverForRouteForWeekdayLoading$(routeId: string, weekday: fromTypes.Weekday): Observable<boolean> {
    return this.store.select(fromDriverByRoutesSelectors.getDefaultDriverLoading, {
      routeId,
      weekday,
    });
  }

  public getDefaultDriverForRouteForWeekdayError$(
    routeId: string,
    weekday: fromTypes.Weekday,
  ): Observable<fromTypes.WpError> {
    return this.store.select(fromDriverByRoutesSelectors.getDefaultDriverError, {
      routeId,
      weekday,
    });
  }

  public getDefaultVehicleForRouteForWeekday$(
    routeId: string,
    weekday: fromTypes.Weekday,
  ): Observable<fromTypes.Vehicle> {
    return this.store.select(fromVehiclesByRoutesSelectors.getVehicleByRoute, {
      routeId,
      weekday,
    });
  }

  public getRoute$(routeId: string): Observable<fromTypes.Route> {
    return this.store.select(fromSelectors.getRoute, { routeId });
  }

  public getRouteLoading$(routeId: string): Observable<boolean> {
    return this.store.select(fromSelectors.getRouteLoading, { routeId });
  }

  public getRouteError$(routeId: string): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getRouteError, { routeId });
  }

  public getRouteTimezoneAbbreviation$(routeId: string): Observable<string> {
    return this.store.select(fromSelectors.getRouteTimezoneAbbreviation, {
      routeId,
    });
  }

  public getRouteEquipmentSummary$(routeId: string): Observable<fromTypes.RiderEquipmentSummary> {
    return this.store.select(fromSelectors.riderEquipmentByRoute, { routeId });
  }

  public getStopsByRiderForRouteAndWeekday(
    routeId: string,
    riderId: string,
    weekday: fromTypes.Weekday,
  ): Observable<[fromTypes.RouteStop, fromTypes.RouteStop]> {
    return this.store.select(fromSelectors.stopsByRouteByRiderByWeekday, {
      routeId,
      riderId,
      weekday,
    }) as Observable<[fromTypes.RouteStop, fromTypes.RouteStop]>;
  }

  public getStopsByRoute$(routeId: string): Observable<fromTypes.RouteStop[]> {
    return this.store.select(fromSelectors.getStopsByRoute, { routeId });
  }

  public getStop$(stopId: string): Observable<fromTypes.RouteStop> {
    return this.store.select(fromSelectors.routeStop, { stopId });
  }

  public getWeekdayPillsForRoute(routeId: string): Observable<fromTypes.WeekdayPillState[]> {
    return this.store.select(fromSelectors.weekdayPillTabsForRoute, {
      routeId,
    });
  }

  public getRider$(riderId: string): Observable<GetStudentSnapshotResponse> {
    return this.store.select(fromSelectors.getRider(riderId));
  }

  public getRiderLoading$(riderId: string): Observable<boolean> {
    return this.store.select(fromSelectors.getRiderLoading(riderId));
  }

  public getRiderError$(riderId: string): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getRiderError(riderId));
  }

  public getRidersMetaByStopsByWeekdays(
    stopId: string,
    weekday: fromTypes.Weekday,
  ): Observable<fromTypes.RidersByStopMeta> {
    return this.store.select(fromSelectors.getRidersByStopsByWeekdaysMeta(stopId, weekday));
  }

  public isStopWithRiderDOWVariability$(
    routeId: string,
    stopId: string,
    weekday: fromTypes.Weekday,
  ): Observable<boolean> {
    return this.store.select(fromSelectors.isStopWithRiderDOWVariability(routeId, stopId, weekday));
  }

  public hasRiders$(routeId: string): Observable<boolean> {
    return this.store
      .select(fromSelectors.getRiderIdsByRoute, {
        routeId,
      })
      .pipe(map((riderIds) => !!(riderIds && riderIds.length)));
  }

  public getRoutePolyline$(routeId: string): Observable<fromTypes.EncodedPolyline> {
    return this.store.select(fromSelectors.getRoutePolyline(routeId));
  }

  public onLoadRouteRequested(routeId: string, options: fromTypes.LoadRouteOptions = {}): void {
    const { withPolyline, withDistrictProgram } = options;
    this.store.dispatch(fromActions.loadRouteRequested({ routeId, withPolyline, withDistrictProgram }));
  }

  public onLoadCalendarRequested(routeId: string): void {
    this.store.dispatch(fromActions.loadCalendarForRouteRequested({ routeId }));
  }

  public onLoadRidersForRouteRequested(routeId: string): void {
    this.store.dispatch(fromActions.loadRidersForRouteRequested({ routeId }));
  }

  public onLoadDriversForRouteRequested(routeId: string): void {
    this.store.dispatch(fromActions.loadDriverForRouteRequested({ routeId }));
  }
}
