import { BaseDate, TimeFormat, TimezoneString } from './base-date';
import { YYYYMMDDString } from './year-month-day';
import {
  dateEndOfYear,
  dateFormat,
  dateParse,
  dateStartOfYear,
  dateToYYYYMMDD,
  formattingConstants,
} from '@rootTypes/utils/common/date-time-fns';

/**
 * A number, VISUALLY resembling a unix timestamp (but not being the same thing), which encodes
 * a date, hours and minutes (seconds and millis are usually set to 0),
 *
 * These is NO information about timezone which this date and time belong to.
 *
 * Simply speaking, this value just encodes a string like "March 12, 2020 11:37PM" as a number (1584056220000).
 *
 * See @class DisplayDateTime
 */
export type DisplayDateTimeStamp = number;

export class DisplayDateTime extends BaseDate {
  private tz: TimezoneString;

  public static get startOfCurrentYear(): DisplayDateTimeStamp {
    const d = dateStartOfYear(new Date());
    return DisplayDateTime.fromDate(d);
  }

  public static get endOfCurrentYear(): DisplayDateTimeStamp {
    const d = dateEndOfYear(new Date());
    return DisplayDateTime.fromDate(d);
  }

  public static fromDate(d: Date): DisplayDateTimeStamp {
    const target = new Date();
    target.setUTCFullYear(d.getFullYear(), d.getMonth(), d.getDate());
    target.setUTCHours(d.getHours(), d.getMinutes(), d.getSeconds(), d.getMilliseconds());
    return target.getTime();
  }

  constructor(value: DisplayDateTimeStamp, tz?: TimezoneString) {
    super(value, TimeFormat.DISPLAY_DATE_TIME);
    this.tz = tz;
  }

  toDate(tz: string = this.tz): Date {
    const utcDateTimeStr = dateFormat(new Date(this.value), formattingConstants.iso, 'Etc/UTC');
    return dateParse(utcDateTimeStr, formattingConstants.iso, tz);
  }

  toHHmm(): string {
    return dateFormat(this.toDate(), formattingConstants.hhMM);
  }

  public toUTCTimestamp(): number {
    return this.toDate().getTime();
  }

  public toYearMonthDay(): YYYYMMDDString {
    return dateToYYYYMMDD(this.toDate());
  }

  public getDisplayTimeStr(format: keyof typeof formattingConstants = 'timeWithTimezone'): string {
    return dateFormat(this.toDate(), formattingConstants[format], this.tz);
  }
}
