import { StudentListStateSnapshot } from '../student-list-state-snapshot';
import * as fromApi from '../../../../api/endpoints/entity-filter';
import * as fromRoot from '@rootTypes';
import { PortalEntityType } from '@rootTypes';
import { StudentListFilter, StudentListFilterType } from '../student-list-entity-filter';
import { ApiStudentStatus } from '@apiEntities';

export const studentListStateSnapshotToRequest = ({
  currentPage,
  pageSize,
  studentSearchQuery,
  filters,
  listStatus,
}: StudentListStateSnapshot): fromApi.EntityFilterRequest => {
  const res: fromApi.EntityFilterRequest = {
    type: fromApi.EntityFilterRequestType.STUDENT,
    query: studentSearchQuery,
    skip: currentPage * pageSize,
    limit: pageSize,
  };
  if (filters && filters.length) {
    const districtIds = getIdsFromEntityFilters(filters, PortalEntityType.DISTRICT);
    res.districtIds = districtIds;
    const campusIds = getIdsFromEntityFilters(filters, PortalEntityType.CAMPUS);
    res.campusIds = campusIds;
  }
  if (listStatus && listStatus !== fromRoot.rider.StudentListStatus.ALL) {
    res.extraFilters = {
      studentStatus: [...studentListStatusToApi(listStatus)],
    };
  }
  return res;
};

function getIdsFromEntityFilters(filters: StudentListFilter[], entityType: PortalEntityType): string[] {
  return filters
    .filter((f) => f.type === StudentListFilterType.ENTITY)
    .filter((f) => f.payload.type === entityType)
    .map((f) => f.payload.entityId);
}

function studentListStatusToApi(source: fromRoot.rider.StudentListStatus): ApiStudentStatus[] {
  switch (source) {
    case fromRoot.rider.StudentListStatus.ACTIVE:
      return [
        ApiStudentStatus.ACTIVE,
        ApiStudentStatus.NEW,
        ApiStudentStatus.HOLD,
        ApiStudentStatus.REQUIRES_VERIFICATION,
        ApiStudentStatus.NO_STATUS,
      ];
    case fromRoot.rider.StudentListStatus.INACTIVE:
      return [ApiStudentStatus.INACTIVE];
    case fromRoot.rider.StudentListStatus.ALL:
      return [
        ApiStudentStatus.ACTIVE,
        ApiStudentStatus.INACTIVE,
        ApiStudentStatus.NEW,
        ApiStudentStatus.HOLD,
        ApiStudentStatus.REQUIRES_VERIFICATION,
        ApiStudentStatus.NO_STATUS,
      ];
    default:
      return [
        ApiStudentStatus.ACTIVE,
        ApiStudentStatus.NEW,
        ApiStudentStatus.HOLD,
        ApiStudentStatus.REQUIRES_VERIFICATION,
        ApiStudentStatus.NO_STATUS,
      ];
  }
}
