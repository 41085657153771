import { Observable, of, switchMap } from 'rxjs';
import { take } from 'rxjs/operators';
import { AccessDeniedRouterService } from '../../../router/portal-routes';
import { UrlTree } from '@angular/router';

export abstract class AbstractCanActivateFeatureGuard {
  public static accessDeniedRouter: AccessDeniedRouterService;

  protected next?: () => Observable<boolean | UrlTree>;

  public abstract canActivateFeature: Observable<boolean>;

  public static setAccessDeniedRouter(router: AccessDeniedRouterService): void {
    this.accessDeniedRouter = router;
  }

  public canActivate(): Observable<boolean | UrlTree> {
    return this.canActivateFeature.pipe(
      take(1),
      switchMap((hasAccess) => {
        if (!hasAccess) {
          return of(AbstractCanActivateFeatureGuard.accessDeniedRouter.getUrlTree({}));
        }
        if (typeof this.next === 'function') {
          return this.next();
        }
        return of(hasAccess);
      }),
    );
  }
}
