import { SmartFormFieldImage, SmartFormFieldType } from '@apiEntities';

export const isSmartFormFieldImage = (arg: any): arg is SmartFormFieldImage => {
  return !!arg && typeof arg === 'object' && arg.type === SmartFormFieldType.IMAGE;
};

export const isSmartFormFieldImageValid = (field: SmartFormFieldImage, required: boolean): boolean => {
  if (!required && !field.value) {
    return true;
  }
  return typeof field.value === 'string' && field.value.length && field.value !== '--';
};
