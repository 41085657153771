import { Pipe, PipeTransform } from '@angular/core';
import * as fromTypes from '../../types';

@Pipe({
  name: 'vehicleName',
})
export class VehicleNamePipe implements PipeTransform {
  transform(value: { make: string; model: string }): string {
    return fromTypes.utils.vehicle.getVehicleDisplayName(value);
  }
}
