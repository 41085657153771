<div class="icon-critical" style="width: 4px; height: 20px">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 4 20"
  >
    <g fill="#D52504" fill-rule="evenodd">
      <path
        d="M4 1.051v8.815c0 .692-.035 1.378-.106 2.059-.07.68-.164 1.374-.28 2.08H.386c-.116-.706-.21-1.4-.28-2.08C.036 11.245 0 10.558 0 9.866V1.051C.326.684.66.335 1 .006c.91-.009 1.1-.007 1.93 0C3.328.4 3.685.749 4 1.05zM0 18.006c0-.281.051-.542.153-.784.103-.242.243-.452.42-.632.179-.179.39-.321.632-.427.242-.105.507-.157.795-.157.28 0 .542.052.784.157.243.106.455.248.636.427.182.18.324.39.427.632.102.242.153.503.153.784 0 .28-.051.543-.153.789-.103.246-.245.458-.427.637-.181.18-.393.32-.636.422-.242.1-.504.152-.784.152-.288 0-.553-.051-.795-.152-.243-.102-.453-.242-.631-.422-.178-.179-.318-.391-.42-.637-.103-.246-.154-.509-.154-.79z"
      />
    </g>
  </svg>
</div>
