<div class="container">
  <div
    class="arrow-btn"
    *ngIf="isNext; else previous"
    [ngClass]="{disabled: pagination.isNextDisabled$ | async}"
    (click)="pagination.next()"
  >
    <wp-icon-arrow-right
      [disabled]="pagination.isNextDisabled$ | async"
    ></wp-icon-arrow-right>
  </div>

  <ng-template #previous>
    <div
      class="arrow-btn"
      [ngClass]="{disabled: pagination.isPreviousDisabled$ | async}"
      (click)="pagination.previous()"
    >
      <wp-icon-arrow-left
        [disabled]="pagination.isPreviousDisabled$ | async"
      ></wp-icon-arrow-left>
    </div>
  </ng-template>

  <div class="current-page" *ngIf="isCurrentPageShown">
    Page {{pagination.currentPageNumber$ | async}}/{{pagination.totalPages$ |
    async}}
  </div>
</div>
