import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-field-value',
  templateUrl: './field-value.component.html',
  styleUrls: ['./field-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldValueComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
