import { PortalEntityType, VendorPortalEntity } from '@rootTypes';
import { SmartInputVendorValue } from '../types';

export const smartInputVendorValueToVendorPortalEntity = (source?: SmartInputVendorValue): VendorPortalEntity => {
  if (source) {
    return {
      entityId: source.id,
      label: source.name,
      type: PortalEntityType.VENDOR,
    };
  }
  return undefined;
};
