<div class="icon-campus-brand">
  <svg width="28" height="28" viewBox="0 0 28 27">
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    stroke="#47A540"
                    stroke-linecap="round"
                    stroke-width="2"
                    d="M3.484 4.084L3.484 0 24.499 0 24.483 7.059 24.483 21 13.402 21"
                    transform="translate(-534 -496) translate(524 36) translate(0 40) translate(0 410) translate(10 11.5) matrix(-1 0 0 1 27.983 0)"
                  />
                  <path
                    stroke="#47A540"
                    d="M9.917 19.848L9.917 12.25 12.829 12.25"
                    transform="translate(-534 -496) translate(524 36) translate(0 40) translate(0 410) translate(10 11.5)"
                  />
                  <path
                    fill="#47A540"
                    d="M0 19.833H3.5V22.165999999999997H0z"
                    transform="translate(-534 -496) translate(524 36) translate(0 40) translate(0 410) translate(10 11.5)"
                  />
                  <path
                    stroke="#47A540"
                    d="M6.333 5.167H10V8.834H6.333z"
                    transform="translate(-534 -496) translate(524 36) translate(0 40) translate(0 410) translate(10 11.5)"
                  />
                  <path
                    stroke="#47A540"
                    stroke-linecap="round"
                    d="M13.501 8.804L12.301 8.804 12.25 5.276 15.771 5.276 15.771 6.454"
                    transform="translate(-534 -496) translate(524 36) translate(0 40) translate(0 410) translate(10 11.5)"
                  />
                  <path
                    stroke="#47A540"
                    stroke-width="2"
                    d="M21 7c-2.333 0-5.833 1.167-5.833 5.833 0 5.833 5.833 10.5 5.833 10.5s5.833-4.667 5.833-10.5C26.833 8.167 23.333 7 21 7z"
                    transform="translate(-534 -496) translate(524 36) translate(0 40) translate(0 410) translate(10 11.5)"
                  />
                  <path
                    fill="#FFF"
                    stroke="#47A540"
                    stroke-width="2"
                    d="M21 15.167c-1.289 0-2.333-1.046-2.333-2.334S19.71 10.5 21 10.5c1.288 0 2.333 1.045 2.333 2.333 0 1.288-1.045 2.334-2.333 2.334z"
                    transform="translate(-534 -496) translate(524 36) translate(0 40) translate(0 410) translate(10 11.5)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</div>
