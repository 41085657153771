import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { map, startWith, take } from 'rxjs/operators';

import { ImageApiService } from 'src/app/api';
import { DownloadImageSizePx } from '@rootTypes/utils';
import { PopupableService } from 'src/app/core/popupable/popupable.service';
import {
  NewImageBase64,
  PhotoInputPopupComponent,
  PhotoInputPopupConfig,
} from '../photo-input-popup/photo-input-popup.component';
import { PlaceholderType } from '../types';
import { Observable } from 'rxjs/internal/Observable';
import { isEnterEvent } from '@rootTypes/utils/common/dom';

@Component({
  selector: 'wp-photo-input',
  templateUrl: './photo-input.component.html',
  styleUrls: ['./photo-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoInputComponent implements OnInit {
  /**
   * Path to download the initial image (does not react on changes)
   */
  @Input() public imagePath?: string;

  /**
   * Consumes new FormControl(undefined || '', Validators.required),
   * imageBase64 will be set as a value once the image is loaded from imagePath
   */
  @Input() public control: UntypedFormControl;
  @Input() public label: string;

  @Input() public uploadImageText = 'Upload image';
  @Input() public updateImageText = 'Update image';
  @Input() public imageForm: 'circle' | 'rectangle' = 'circle';
  @Input() public imagePlaceholder: PlaceholderType = PlaceholderType.PERSON;
  /**
   * If ommitted the default width will be used:
   * 132 for a circle (square) and 184 for a rectangle image
   */
  @Input() public imagePreviewWidthPx?: number;
  /**
   * If ommitted the default width will be used:
   * 600 for a circle (square) and 640 for a rectangle image
   */
  @Input() public imageResultWidthPx?: number;

  /**
   * TODO: Discuss if we need a separate previewImageBase64 field to show preview,
   * or we can always use control value
   */
  @Input() public useFormControlForPreview: boolean;

  @Input() public tabIndex = '0';

  @Output() public photoChanged: EventEmitter<string> = new EventEmitter<string>();

  public initialImageLoading = false;
  public initialImageError = false;
  public buttonText: string;
  public isRequiredErrorShown = false;

  public isRoundPreview: boolean;
  public containerWidth: string;
  public containerHeight: string;
  public previewWidth: string;
  public previewHeight: string;
  public previewImageBase64: string;

  // value - image base64
  // used for preview
  public controlValueChanges$: Observable<string>;
  public isRequiredErrorShown$: Observable<boolean>;

  constructor(
    private popupableService: PopupableService,
    private imageApi: ImageApiService,
    private cdRef: ChangeDetectorRef,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.setButtonText();

    this.isRoundPreview = this.imageForm === 'circle';
    let previewWidthPx: number;
    let previewHeightPx: number;
    if (this.isRoundPreview) {
      previewWidthPx = this.imagePreviewWidthPx || 132;
      previewHeightPx = previewWidthPx;
    } else {
      previewWidthPx = this.imagePreviewWidthPx || 184;
      previewHeightPx = (previewWidthPx * 3) / 4;
    }
    this.previewWidth = `${previewWidthPx}px`;
    this.previewHeight = `${previewHeightPx}px`;
    this.containerWidth = this.previewWidth;
    this.containerHeight = `${previewHeightPx + 75}px`; // including error message height

    if (this.imagePath) {
      await this.downloadInitialImage();
    } else {
      this.previewImageBase64 = '';
    }
    this.controlValueChanges$ = this.control.valueChanges.pipe(startWith(this.control.value));
    this.isRequiredErrorShown$ = this.control.statusChanges.pipe(
      map(() => {
        return this.control.touched && this.control.hasError('required');
      }),
    );
    this.cdRef.detectChanges();
  }

  onInputKeyUp(event: KeyboardEvent): void {
    if (isEnterEvent(event) && !this.control.disabled) {
      this.open();
    }
  }

  public open(): void {
    const config: PhotoInputPopupConfig = {
      isSquareImage: this.imageForm === 'circle',
      imagePlaceholder: this.imagePlaceholder,
      sourceImageBase64: this.control.value || '',
    };
    if (this.imageResultWidthPx) {
      config.resultImageWidthPx = this.imageResultWidthPx;
    }
    this.popupableService
      .openPopup<any, PhotoInputPopupConfig, NewImageBase64>(PhotoInputPopupComponent, config, {
        contentZIndex: 11,
        pageMaskZIndex: 10,
      })
      .pipe(take(1))
      .subscribe((newImageBase64) => {
        this.initialImageError = false;
        this.control.markAsTouched();
        if (newImageBase64) {
          this.isRequiredErrorShown = false;
          this.previewImageBase64 = newImageBase64;
          this.control.setValue(newImageBase64);
          this.control.markAsDirty();
          this.photoChanged.emit(newImageBase64 as string);
        }
        this.setButtonText();
        this.cdRef.detectChanges();
      });
  }

  public setButtonText(): void {
    if (this.imagePath) {
      this.buttonText = this.updateImageText;
      return;
    }
    this.buttonText = this.control.value ? this.updateImageText : this.uploadImageText;
  }

  private async downloadInitialImage(): Promise<void> {
    try {
      this.initialImageLoading = true;
      let imageSize: DownloadImageSizePx;
      if (this.imageForm === 'circle') {
        imageSize = {
          width: this.imageResultWidthPx || 600,
          height: this.imageResultWidthPx || 600,
        };
      } else {
        imageSize = {
          width: this.imageResultWidthPx || 640,
          height: this.imageResultWidthPx ? (this.imageResultWidthPx * 3) / 4 : 480,
        };
      }
      this.previewImageBase64 = await this.imageApi.getImageBase64(this.imagePath, imageSize);
    } catch (err) {
      // We silence this error since Browser already logs 500 XHR error in the console
      this.previewImageBase64 = '';
      this.initialImageError = true;
    } finally {
      this.control.setValue(this.previewImageBase64);
      this.control.markAsPristine();
      this.initialImageLoading = false;
      this.cdRef.detectChanges();
    }
  }
}
