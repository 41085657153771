import { VehicleInspectionDriverReviewStatus } from '@apiEntities';
import { DriverReviewStatusQueryParam } from '../types';

export const driverReviewStatusToQueryParam = (
  status?: VehicleInspectionDriverReviewStatus,
): DriverReviewStatusQueryParam => {
  const map = {
    [VehicleInspectionDriverReviewStatus.REVIEWED]: DriverReviewStatusQueryParam.REVIEWED,
    [VehicleInspectionDriverReviewStatus.PENDING]: DriverReviewStatusQueryParam.PENDING,
  };
  return map[status];
};
