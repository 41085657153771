import {
  SmartFormFieldStudentSchoolInfo,
  SmartFormFieldStudentSchoolInfoValue,
  SmartFormFieldType,
} from '@apiEntities';
import { SmartInputStudentSchoolInfo, SmartInputStudentSchoolInfoValue } from 'src/app/shared/smart-forms';

export const isSmartFormFieldStudentSchoolInfo = (arg: any): arg is SmartFormFieldStudentSchoolInfo => {
  return !!arg && typeof arg === 'object' && arg.type === SmartFormFieldType.STUDENT_SCHOOL_INFO;
};

export const isSmartFormFieldStudentSchoolInfoValid = (
  info: SmartFormFieldStudentSchoolInfo,
  required: boolean,
): boolean => {
  if (!required && !info.value?.district?.id && !info.value?.campus?.id) {
    return true;
  }
  return !!info.value?.district?.id && !!info.value?.campus?.id;
};

export const smartFormFieldStudentSchoolInfoToSmartInput = (
  field: SmartFormFieldStudentSchoolInfo,
  required?: boolean,
  disabled?: boolean,
): SmartInputStudentSchoolInfo => {
  const district = field.value?.district
    ? {
        id: field.value.district.id,
        label: field.value.district.name,
      }
    : undefined;

  const campus = field.value?.campus
    ? {
        id: field.value.campus.id,
        label: field.value.campus.name,
      }
    : undefined;

  const schoolYear = field.value?.schoolYear ? { value: field.value.schoolYear.value } : undefined;

  const grade = field.value?.grade
    ? {
        id: field.value.grade.id,
        label: field.value.grade.label,
      }
    : undefined;

  return new SmartInputStudentSchoolInfo({
    districtLabel: field.districtLabel,
    campusLabel: field.campusLabel,
    schoolYearLabel: field.schoolYearLabel,
    gradeLabel: field.gradeLabel,
    value: district ? { district, campus, schoolYear, grade } : undefined,
    isSchoolRequired: required,
    isGroupDisabled: disabled,
  });
};

export const smartInputStudentSchoolInfoValueToSmartFieldValue = (
  source?: SmartInputStudentSchoolInfoValue,
): SmartFormFieldStudentSchoolInfoValue => {
  if (!source) {
    return undefined;
  }
  const value: SmartFormFieldStudentSchoolInfoValue = {
    district: {
      id: source.district.id,
      name: source.district.label,
    },
    campus: {
      id: source.campus.id,
      name: source.campus.label,
    },
  };
  if (source.schoolYear) {
    value.schoolYear = source.schoolYear;
  }
  if (source.grade) {
    value.grade = source.grade;
  }
  return value;
};
