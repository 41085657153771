import { EmployeePermission } from '@rootTypes/entities/employees';
import { getGenericUpdatePermissionRequest } from '@rootTypes/utils/employees';
import { ApiSchoolEmployeePermission } from '@apiEntities/employees/api-permissions';
import { UpdateSchoolEmployeePermissionsRequest } from '../../../../api/endpoints/update-school-employee-permissions';

export const getUpdatePermissionsRequest = (
  employeeId: string,
  initial: EmployeePermission[],
  target: EmployeePermission[],
): UpdateSchoolEmployeePermissionsRequest => {
  const genericRequest = getGenericUpdatePermissionRequest(initial, target);
  const request = {
    schoolEmployeeId: employeeId,
  } as UpdateSchoolEmployeePermissionsRequest;
  if (genericRequest.added) {
    request.permissionsChange = {};
    request.permissionsChange.added = genericRequest.added.map((source) => {
      const target: ApiSchoolEmployeePermission = {
        campusId: source.entityId,
      };
      if (source.permission) {
        target.permission = source.permission;
      }
      return target;
    });
  }
  if (genericRequest.removed) {
    request.permissionsChange = { ...(request.permissionsChange || {}) };
    request.permissionsChange.removed = genericRequest.removed;
  }
  return request;
};
