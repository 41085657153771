<svg
  width="24px"
  height="24px"
  viewBox="0 0 24 24"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g
    id="2-element-/-icon-/-ic_pin_brand"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <path
      d="M12,13 C9.7906269,13 8,11.2081558 8,9 C8,6.7906269 9.7906269,5 12,5 C14.2081558,5 16,6.7906269 16,9 C16,11.2081558 14.2081558,13 12,13 Z M12,1 C7.58120282,1 4,4.51713043 4,8.85686957 C4,16.7137391 12,23 12,23 C12,23 20,16.7137391 20,8.85686957 C20,4.51713043 16.4187972,1 12,1 Z"
      stroke="#47A540"
      stroke-width="2"
    ></path>
  </g>
</svg>
