import { createReducer, on, ReducerTypes } from '@ngrx/store';

import * as fromActions from '../actions';
import { homePageInitialized, setNewPasswordFailed, setNewPasswordRequested, setNewPasswordSuccess } from '../actions';
import { userProfileReducers } from './user-profile.reducers';
import { AuthState } from './auth-state';
import { AuthenticationProvider } from '../../../api/endpoints/get-authentication-provider';

export const createInitialAuthState = (): AuthState => {
  return {
    loginRelayState: null,
    authProvider: {
      isLoading: false,
      error: null,
      email: null,
      response: null,
    },
    loginWithPassword: {
      isLoading: false,
      error: null,
    },
    logoutRelayState: null,
    loginWithToken: {
      isLoading: false,
      error: null,
    },
    logout: {
      isLoading: false,
      error: null,
    },
    checkAuth: {
      isLoading: false,
      error: null,
    },
    firebaseUserId: null,
    userRole: undefined,
    profile: {
      user: {
        isLoading: false,
        data: null,
        error: null,
      },
      userPhoto: {
        imageBase64: '',
        isLoading: false,
        loadingError: null,
        isUpdating: false,
        updatingError: null,
      },
    },
    resetPassword: {
      newPasswordSetup: {
        isLoading: false,
        error: null,
      },
    },
  };
};

export const authReducer = createReducer<AuthState>(
  createInitialAuthState(),
  // set user role
  on(fromActions.setUserRole, (state, action) => {
    const { userRole } = action;
    const newState: AuthState = {
      ...state,
      userRole,
    };
    return newState;
  }),
  // login
  on(fromActions.editEmailForLogin, (state) => {
    const newState: AuthState = {
      ...state,
      authProvider: {
        isLoading: false,
        error: null,
        email: null,
        response: null,
      },
      loginWithPassword: {
        ...state.loginWithPassword,
        error: null,
      },
    };
    return newState;
  }),
  on(fromActions.getAuthProviderRequested, (state, { email, relayState }) => {
    const newState: AuthState = {
      ...state,
      loginRelayState: relayState || null,
      authProvider: {
        isLoading: true,
        error: null,
        email,
        response: null,
      },
      loginWithToken: {
        isLoading: false,
        error: null,
      },
    };
    return newState;
  }),
  on(fromActions.getAuthProviderSuccess, (state, { response }) => {
    const newState: AuthState = {
      ...state,
      authProvider: {
        ...state.authProvider,
        isLoading: response.provider === AuthenticationProvider.SSO,
        error: null,
        response,
      },
    };
    return newState;
  }),
  on(fromActions.getAuthProviderFailed, (state, { error }) => {
    const newState: AuthState = {
      ...state,
      loginRelayState: null,
      authProvider: {
        ...state.authProvider,
        isLoading: false,
        error,
        response: null,
      },
    };
    return newState;
  }),
  on(fromActions.loginWithPasswordRequested, (state) => {
    const newState: AuthState = {
      ...state,
      loginWithPassword: {
        ...state.loginWithPassword,
        isLoading: true,
      },
    };
    return newState;
  }),
  on(fromActions.loginWithPasswordSuccess, (state, action) => {
    const newState: AuthState = {
      ...state,
      loginWithPassword: {
        ...state.loginWithPassword,
        isLoading: false,
        error: null,
      },
      firebaseUserId: action.firebaseUserId,
    };
    return newState;
  }),
  on(fromActions.loginWithPasswordFailed, (state, action) => {
    const newState: AuthState = {
      ...state,
      loginWithPassword: {
        ...state.loginWithPassword,
        error: action.error,
        isLoading: false,
      },
      firebaseUserId: null,
    };
    return newState;
  }),
  on(fromActions.loginWithTokenRequested, (state, action) => {
    const newState: AuthState = {
      ...state,
      loginRelayState: action.relayState || null,
      loginWithToken: {
        isLoading: true,
        error: null,
      },
    };
    return newState;
  }),
  on(fromActions.loginWithTokenSuccess, (state, action) => {
    const newState: AuthState = {
      ...state,
      loginWithToken: {
        ...state.loginWithToken,
        isLoading: false,
        error: null,
      },
      firebaseUserId: action.firebaseUserId,
    };
    return newState;
  }),
  on(fromActions.loginWithTokenFailed, (state, action) => {
    const newState: AuthState = {
      ...state,
      loginRelayState: null,
      loginWithToken: {
        isLoading: false,
        error: action.error,
      },
      firebaseUserId: null,
    };
    return newState;
  }),
  // check auth
  on(fromActions.checkAuthRequested, (state) => {
    const newState: AuthState = {
      ...state,
      checkAuth: {
        ...state.checkAuth,
        isLoading: true,
      },
    };
    return newState;
  }),
  on(fromActions.checkAuthSuccess, (state, action) => {
    const newState: AuthState = {
      ...state,
      checkAuth: {
        ...state.checkAuth,
        isLoading: false,
      },
      firebaseUserId: action.firebaseUserId,
    };
    return newState;
  }),
  on(fromActions.checkAuthFailed, (state, action) => {
    const newState: AuthState = {
      ...state,
      checkAuth: {
        ...state.checkAuth,
        isLoading: false,
        error: action.error,
      },
      firebaseUserId: null,
    };
    return newState;
  }),
  // logout
  on(fromActions.logoutPageRequested, (state) => {
    const newState: AuthState = {
      ...state,
      logoutRelayState: null,
      logout: {
        ...state.logout,
        isLoading: true,
        error: null,
      },
    };
    return newState;
  }),
  on(fromActions.homePageAutoLogoutRequested, (state, action) => {
    const newState: AuthState = {
      ...state,
      logoutRelayState: encodeURIComponent(action.pathToEnterAfterLogin),
      logout: {
        ...state.logout,
        isLoading: true,
        error: null,
      },
    };
    return newState;
  }),
  on(fromActions.logoutPageLogoutSuccess, (state) => {
    if (state.logoutRelayState) {
      const newState = createInitialAuthState();
      return {
        ...newState,
        logoutRelayState: state.logoutRelayState,
      };
    }
    return createInitialAuthState();
  }),
  on(fromActions.logoutPageLogoutFailed, (state, action) => {
    const newState: AuthState = {
      ...state,
      logout: {
        ...state.logout,
        isLoading: false,
        error: action.error,
      },
    };
    return newState;
  }),
  on(homePageInitialized, (state) => {
    const newState: AuthState = {
      ...state,
      loginRelayState: null,
      logoutRelayState: null,
    };
    return newState;
  }),
  on(setNewPasswordRequested, (state) => {
    return {
      ...state,
      logoutRelayState: null,
      logout: {
        ...state.logout,
        isLoading: true,
        error: null,
      },
      resetPassword: {
        ...state.resetPassword,
        newPasswordSetup: {
          isLoading: true,
          error: null,
        },
      },
    };
  }),
  on(setNewPasswordSuccess, createInitialAuthState),
  on(setNewPasswordFailed, (state, action) => {
    return {
      ...createInitialAuthState(),
      resetPassword: {
        ...state.resetPassword,
        newPasswordSetup: {
          isLoading: false,
          error: action.error,
        },
      },
    };
  }),
  ...(userProfileReducers as ReducerTypes<AuthState, any>[]),
);
