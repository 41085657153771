<!-- Grey icon -->
<svg
  *ngIf="!green"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="22"
  viewBox="0 0 24 22"
>
  <g fill="none" fill-rule="evenodd">
    <g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      stroke="#959597"
                      stroke-width="2"
                      d="M5 1H19V21H5z"
                      transform="translate(-19 -436) translate(0 85) translate(0 338) translate(18 12) translate(1 1)"
                    />
                    <path
                      fill="#959597"
                      d="M3 0H21V2H3z"
                      transform="translate(-19 -436) translate(0 85) translate(0 338) translate(18 12) translate(1 1)"
                    />
                    <path
                      stroke="#959597"
                      stroke-width="2"
                      d="M19 9H23V21H19z"
                      transform="translate(-19 -436) translate(0 85) translate(0 338) translate(18 12) translate(1 1)"
                    />
                    <g stroke="#959597">
                      <path
                        d="M0.5 0.5H5.5V5.5H0.5z"
                        transform="translate(-19 -436) translate(0 85) translate(0 338) translate(18 12) translate(1 1) translate(9 15)"
                      />
                    </g>
                    <path
                      stroke="#959597"
                      d="M13.5 4.5H16.5V7.5H13.5zM7.5 9.5H10.5V12.5H7.5zM7.5 4.5H10.5V7.5H7.5zM13.5 9.5H16.5V12.5H13.5z"
                      transform="translate(-19 -436) translate(0 85) translate(0 338) translate(18 12) translate(1 1)"
                    />
                    <path
                      stroke="#959597"
                      stroke-width="2"
                      d="M1 9H5V21H1z"
                      transform="translate(-19 -436) translate(0 85) translate(0 338) translate(18 12) translate(1 1)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>

<!-- Green icon -->
<svg
  *ngIf="green"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="22"
  viewBox="0 0 24 22"
>
  <g fill="none" fill-rule="evenodd">
    <g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    stroke="#47A540"
                    stroke-width="2"
                    d="M5 1H19V21H5z"
                    transform="translate(-19 -436) translate(0 85) translate(0 338) translate(19 13)"
                  />
                  <path
                    fill="#47A540"
                    d="M3 0H21V2H3z"
                    transform="translate(-19 -436) translate(0 85) translate(0 338) translate(19 13)"
                  />
                  <path
                    stroke="#47A540"
                    stroke-width="2"
                    d="M19 9H23V21H19z"
                    transform="translate(-19 -436) translate(0 85) translate(0 338) translate(19 13)"
                  />
                  <g stroke="#47A540">
                    <path
                      d="M0.5 0.5H5.5V5.5H0.5z"
                      transform="translate(-19 -436) translate(0 85) translate(0 338) translate(19 13) translate(9 15)"
                    />
                  </g>
                  <path
                    stroke="#47A540"
                    d="M13.5 4.5H16.5V7.5H13.5zM7.5 9.5H10.5V12.5H7.5zM7.5 4.5H10.5V7.5H7.5zM13.5 9.5H16.5V12.5H13.5z"
                    transform="translate(-19 -436) translate(0 85) translate(0 338) translate(19 13)"
                  />
                  <path
                    stroke="#47A540"
                    stroke-width="2"
                    d="M1 9H5V21H1z"
                    transform="translate(-19 -436) translate(0 85) translate(0 338) translate(19 13)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
