export enum ActivityStatus {
  NEW = 'NEW',
  ASSIGNED = 'ASSIGNED',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  PENDING_REVIEW = 'PENDING_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  SUBMITTED_FOR_PAYMENT = 'SUBMITTED_FOR_PAYMENT',
  UNPAID = 'UNPAID',
  CANCELED = 'CANCELED',
}
