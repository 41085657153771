import { createAction, props } from '@ngrx/store';
import { RideV2 } from '@apiEntities/rides/ride-v2';
import * as fromTypes from '../../../flash/types';
import { RideV2ProjectionKey } from '@apiEntities/rides/ride-v2-projection';

const moduleKey = 'Ride data flash';

/**
 * Ride details
 */
export const subscribeRideDetailsPrimary = createAction(
  `[${moduleKey}]: Subscribe ride details primary`,
  props<{ rideIds: string[]; projections: RideV2ProjectionKey[] }>(),
);

export const subscribeRideDetailsSecondary = createAction(
  `[${moduleKey}]: Subscribe ride details secondary`,
  props<{ rideIds: string[]; projections: RideV2ProjectionKey[] }>(),
);

export const unsubscribeRideDetailsPrimary = createAction(`[${moduleKey}]: Unsubscribe ride details primary`);

export const unsubscribeRideDetailsSecondary = createAction(`[${moduleKey}]: Unsubscribe ride details secondary`);

/**
 * Route traces
 */
export const subscribeRouteTraces = createAction(`[${moduleKey}]: Subscribe route traces`, props<{ rideId: string }>());

export const subscribeRouteTracesIdle = createAction(
  `[${moduleKey}]: Subscribe route traces idle (ineligible status)`,
  props<{ rideId: string; status: RideV2['status'] }>(),
);

export const unsubscribeRouteTraces = createAction(`[${moduleKey}]: Unsubscribe route traces`);

export const unsubscribeRouteTracesSuccess = createAction(`[${moduleKey}]: Unsubscribe route traces success`);

export const flashUpdateRouteTraces = createAction(
  `[${moduleKey}]: Flash update route traces`,
  props<{ rideId: string; result: fromTypes.PortalRouteTracePoint[] }>(),
);
