import { ApiStudent } from '@apiEntities';
import { RiderEquipmentMap } from '@rootTypes/entities/rider';
import * as fromEntities from '@rootTypes/entities/rider';

export const getEquipmentMapForStudentV2 = (studentProfile: ApiStudent['profile']): RiderEquipmentMap => {
  const target = {} as fromEntities.RiderEquipmentMap;
  if (!studentProfile) {
    return target;
  }
  if (studentProfile.equipmentNeeds) {
    const source = studentProfile.equipmentNeeds;
    target.isCarSeat = source.isCarSeatRequired;
    target.isBoosterSeat = source.isBoosterSeatRequired;
    target.isSeatBeltGuard = source.isSeatBeltGuardRequired;
    target.isWalkerSupport = source.isWalkerSupportRequired;
    target.isHarness = source.isHarnessRequired;
    target.isWheelchair = source.isWheelchairSupportRequired;
  }
  if (studentProfile.specialNeeds) {
    const source = studentProfile.specialNeeds;
    target.isSpecialNeeds = source.isSpedStudent;
    target.isAide = source.isAideRequired;
    target.heartProgram = source.heartProgram;
    target.fosterProgram = source.fosterProgram;
    target.doorToDoorStop = source.doorToDoorStop;
    target.cornerStop = source.cornerStop;
    target.isAttendant = source.isAttendantRequired;
  }
  return target;
};
