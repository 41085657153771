import { EntityStatus, SerializedVehicleListSnapshot, VehicleListFilterType, VehicleListSnapshot } from '../entities';
import * as ui from '../ui';

export const getSnapshotFromSerialized = (
  source: SerializedVehicleListSnapshot,
  isSelectedVehicleSupported: boolean,
): VehicleListSnapshot => {
  if (!source) {
    return undefined;
  }
  const { search, page, status, pageSize, selectedVehicleId, filters } = source || {};
  const res: VehicleListSnapshot = {
    search,
    page: page ? parseInt(page, 10) - 1 : ui.DEFAULT_VEHICLE_LIST_PAGE,
    status: (status as EntityStatus) || ui.DEFAULT_VEHICLE_LIST_STATUS,
    pageSize: pageSize ? parseInt(pageSize, 10) : ui.DEFAULT_VEHICLE_LIST_PAGE_SIZE,
    filters: [], // no filters for now
  };
  if (isSelectedVehicleSupported && selectedVehicleId) {
    res.selectedVehicleId = selectedVehicleId;
  }
  if (filters?.length > 0) {
    const filterSegments = filters.split(';');
    filterSegments.forEach((segment) => {
      const [type] = segment.split(',');
      if (type === VehicleListFilterType.MULTI_ACCOUNT) {
        const [, entityType, entityId, operatorIdsStr] = segment.split(',');
        const operatorIds = operatorIdsStr ? operatorIdsStr.split('$') : [];
        res.filters.push({
          id: segment,
          type: VehicleListFilterType.MULTI_ACCOUNT,
          payload: {
            entityId: entityId,
            type: entityType,
            operatorIds,
          },
        });
      }
    });
  }
  return res;
};
