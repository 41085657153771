import { EmployessListEffects } from './employess-list.effects';
import { EmployeeDataEffects } from './employee-data.effects';
import { EmployeeDetailsEffects } from './employee-details.effects';
import { EmployeeProfileEditorEffects } from './employee-profile-editor.effects';
import { PermissionManagerEffects } from './permission-manager.effects';

export const employeeEffects = [
  EmployessListEffects,
  EmployeeDataEffects,
  EmployeeDetailsEffects,
  EmployeeProfileEditorEffects,
  PermissionManagerEffects,
];
