<wp-auth-wrapper [rightColumnImage]="'resetPassword'">
  <section class="reset-password">
    <div class="reset-password-container">
      <img src="/assets/img/logo.svg" alt="Zūm" />
      <div class="header">Forgot your password?</div>
      <div class="instructions">
        Please enter the email address you used to create your account. We’ll
        send you instructions to reset your password.
      </div>

      <wp-input
        class="email-input"
        [label]="'Email'"
        [control]="emailControl"
        type="email"
      >
      </wp-input>

      <wp-btn-primary
        class="submit-btn"
        (clicked)="onSubmit()"
        [loading]="isSubmitLoading"
        >Submit
      </wp-btn-primary>

      <wp-link-btn
        class="login-link"
        [disabled]="isSubmitLoading"
        (clicked)="onReturnToLoginPage()"
        >Return to login page
      </wp-link-btn>
    </div>
  </section>
</wp-auth-wrapper>
