import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const moduleKey = 'School Employees';
const submoduleKey = 'Profile Editor';

export const initEmployeeProfileEditor = createAction(
  `[${moduleKey}] [${submoduleKey}]: Init employee profile editor`,
  props<{ employeeId?: string; createForDistrictId?: string }>(),
);

export const updatedEmployeeModel = createAction(
  `[${moduleKey}] [${submoduleKey}]: Updated employee model`,
  props<{ model: fromTypes.EmployeeProfileEditModel }>(),
);

export const updatedEmployeeModelValid = createAction(
  `[${moduleKey}] [${submoduleKey}]: Updated employee model valid`,
  props<{ valid: boolean }>(),
);

export const createEmployeeRequested = createAction(`[${moduleKey}] [${submoduleKey}]: Create employee requested`);

export const createEmployeeSuccess = createAction(
  `[${moduleKey}] [${submoduleKey}]: Create employee success`,
  props<{ employee: fromTypes.Employee }>(),
);

export const createEmployeeFailed = createAction(
  `[${moduleKey}] [${submoduleKey}]: Create employee failed`,
  props<{ error: fromTypes.WpError }>(),
);

export const updateEmployeeRequested = createAction(`[${moduleKey}] [${submoduleKey}]: Update employee requested`);

export const updateEmployeeSuccess = createAction(
  `[${moduleKey}] [${submoduleKey}]: Update employee success`,
  props<{ employee: fromTypes.Employee }>(),
);

export const updateEmployeeFailed = createAction(
  `[${moduleKey}] [${submoduleKey}]: Update employee failed`,
  props<{ error: fromTypes.WpError }>(),
);
