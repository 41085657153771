import { concatLatestFrom } from '@ngrx/operators';
import { ApiService } from '../../../api/api.service';
import { Injectable } from '@angular/core';
import { AbstractListComponentStore } from '@rootTypes/utils/list-store-utils/abstract-list-component-store';
import {
  AccountSelectListFilter,
  AccountSelectListParams,
  AccountSelectListQuickFilter,
  AccountSelectListQuickFilterValue,
  AccountSelectListState,
  accountSelectQuickFilterConfig,
  getAccountSelectListQuickFilter,
} from '../types/account-select-list';
import { EntityAggregateRequest, FinanceTransactionAggregateResult } from '../../../api/endpoints/entity-aggregate';
import { EntityFilterRequest, EntityFilterRequestType } from '../../../api/endpoints/entity-filter';
import { PortalListDateRangeFilter } from '@rootTypes/utils/list-store-utils/portal-list-filter';
import { map, tap } from 'rxjs';
import { AccountSelectRouterService } from '../../../router/portal-routes/invoicing/account-select';
import { YearMonthDay } from '@rootTypes/utils/common/date';
import { DistrictResult } from '../../../api/entities/entity-search';

export const DEFAULT_ACCOUNT_SELECT_PAGE_SIZE = 10;
export const ACCOUNT_SELECT_MAX_FILTER_DATE = YearMonthDay.yesterday();

export const getInitialAccountSelectListState = (): AccountSelectListState => ({
  params: {
    filters: [getAccountSelectListQuickFilter(AccountSelectListQuickFilterValue.LAST_MONTH)],
    page: 0,
    pageSize: DEFAULT_ACCOUNT_SELECT_PAGE_SIZE,
    searchQuery: undefined,
    listByEntity: undefined,
    status: undefined,
    selectedItemId: undefined,
  },
  api: {
    total: undefined,
    items: [],
    isLoading: false,
    error: undefined,
  },
});

@Injectable()
export class AccountSelectStore extends AbstractListComponentStore<
  AccountSelectListFilter,
  string,
  AccountSelectListParams,
  FinanceTransactionAggregateResult
> {
  public init = this.effect((origin) =>
    origin.pipe(
      concatLatestFrom(() => this.accountSelectRoute.parse$()),
      tap(([_, routeData]) => {
        this.initializedFromUrlParams(routeData);
        this.reloadList();
      }),
    ),
  );

  constructor(
    private api: ApiService,
    private accountSelectRoute: AccountSelectRouterService,
  ) {
    super({ initialStateGetter: getInitialAccountSelectListState, listReloadDebounceTime: 200 });
  }

  async loadListAPI(
    params: AccountSelectListParams,
  ): Promise<{ items: FinanceTransactionAggregateResult[]; total: number }> {
    const request: EntityAggregateRequest = {
      type: EntityFilterRequestType.FINANCE_TRANSACTION,
      aggregate: {
        field: 'district_id',
      },
      skip: params.page * params.pageSize,
      limit: params.pageSize,
    };
    const dateFilter = this.getOneFilterOfType<PortalListDateRangeFilter>(params.filters, 'date-range');
    const quickFilter = this.getOneFilterOfType<AccountSelectListQuickFilter>(params.filters, 'quick-filter');
    const dateRange = dateFilter
      ? dateFilter.payload
      : quickFilter
        ? accountSelectQuickFilterConfig[quickFilter.payload].rangeGetter()
        : undefined;
    if (dateRange) {
      request.date = {
        fieldName: 'local_date',
        from: dateRange.startDate,
        to: dateRange.endDate,
      };
    }
    if (params.searchQuery) {
      request.districtIds = await this.getDistrictIdsForSearchQuery(params.searchQuery);
    }
    return this.api
      .entityAggregate(request)
      .pipe(
        map((response) => ({ total: response.total, items: response.results as FinanceTransactionAggregateResult[] })),
        tap(() => {
          this.accountSelectRoute.navigate(params, 'merge');
        }),
      )
      .toPromise();
  }

  private async getDistrictIdsForSearchQuery(searchQuery: string): Promise<string[]> {
    const request: EntityFilterRequest = {
      type: EntityFilterRequestType.DISTRICT,
      query: searchQuery,
      skip: 0,
      limit: 1000,
    };
    return this.api
      .entityFilter(request)
      .pipe(map((response) => response.results.map((r) => (r as DistrictResult).districtId)))
      .toPromise();
  }
}
