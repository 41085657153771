import { TransportationRegistrationStatus } from '@apiEntities/transportation-request';
import { TransportationRequestStatusFilter } from '../types/transportation-request-list';

export const createTransportationRequestStatusFilter = (
  status?: TransportationRegistrationStatus,
): TransportationRequestStatusFilter => {
  if (!status) {
    return undefined;
  }
  return {
    id: `registration-status,${status}`,
    type: 'registration-status',
    payload: status,
  };
};
