import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const moduleKey = 'Vendors';
const submoduleKey = 'Details';

export const setVendorId = createAction(
  `[${moduleKey}] [${submoduleKey}]: Set vendor id`,
  props<{ vendorId: string | undefined }>(),
);

export const setTab = createAction(
  `[${moduleKey}] [${submoduleKey}]: Set tab`,
  props<{ tab: fromTypes.VendorDetailsTabs }>(),
);
