import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { iconPaths } from '@rootTypes/utils';

@Component({
  selector: 'wp-column-warning',
  templateUrl: './column-warning.component.html',
  styleUrls: ['./column-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnWarningComponent {
  @Input() public width: string;
  @Input() public height: string;
  @Input() public margin?: string;
  @Input() public iconHeight = 'auto';
  public warnIcon = iconPaths.EXCLAMATION_SIGN_ORANGE_BOLD;
}
