import { Pipe, PipeTransform } from '@angular/core';
import { ApiStudent } from '@apiEntities';
import { Weekday } from '@rootTypes';
import { getShortWeekday, sortWeekdays } from '@rootTypes/utils/common/date';

@Pipe({
  name: 'studentV2ScheduleDays',
})
export class StudentV2ScheduleDaysPipe implements PipeTransform {
  transform(value: ApiStudent['addresses'][0]['locationSchedule']['pickup']): string {
    if (!value) {
      return '--';
    }
    const weekdays: Weekday[] = Object.keys(value).filter((d) => !!value[d]) as Weekday[];
    if (!weekdays.length) {
      return '--';
    }
    return sortWeekdays(weekdays)
      .map((wd) => getShortWeekday(wd))
      .join(', ');
  }
}
