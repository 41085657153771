import { Pipe, PipeTransform } from '@angular/core';
import { YYYYMMDDString } from '@rootTypes';
import { YearMonthDay } from 'src/app/types/utils/common/date';
import { formattingConstants } from '@rootTypes/utils/common/date-time-fns';

@Pipe({
  name: 'YYYYMMDDToWeekday',
})
export class YYYYMMDDToWeekdayPipe implements PipeTransform {
  transform(value: YYYYMMDDString, format: keyof typeof formattingConstants = 'weekdayShort'): string {
    if (!value) {
      return '--';
    }
    const formatter = new YearMonthDay(value);
    return formatter.formatTo(format);
  }
}
