import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconVmStreetviewComponent } from './icon-vm-streetview/icon-vm-streetview.component';
import { IconVmPhotoViewComponent } from './icon-vm-photo-view/icon-vm-photo-view.component';
import { IconCheckedCircleComponent } from './icon-checked-circle/icon-checked-circle.component';
import { IconGreenPinComponent } from './icon-green-pin/icon-green-pin.component';
import { IconPickupLineComponent } from './icon-pickup-line/icon-pickup-line.component';
import { IconDropoffLineComponent } from './icon-dropoff-line/icon-dropoff-line.component';
import { IconAlertCriticalComponent } from './icon-alert-critical/icon-alert-critical.component';
import { IconAlertWarningComponent } from './icon-alert-warning/icon-alert-warning.component';
import { IconAlertInfoComponent } from './icon-alert-info/icon-alert-info.component';
import { IconAlertSuccessComponent } from './icon-alert-success/icon-alert-success.component';
import { IconDashboardGreyComponent } from './icon-dashboard-grey/icon-dashboard-grey.component';
import { IconDashboardGreenComponent } from './icon-dashboard-green/icon-dashboard-green.component';
import { IconDriversGreyComponent } from './icon-drivers-grey/icon-drivers-grey.component';
import { IconDriversGreenComponent } from './icon-drivers-green/icon-drivers-green.component';
import { IconInsightsGreyComponent } from './icon-insights-grey/icon-insights-grey.component';
import { IconInsightsGreenComponent } from './icon-insights-green/icon-insights-green.component';
import { IconRidesGreyComponent } from './icon-rides-grey/icon-rides-grey.component';
import { IconRidesGreenComponent } from './icon-rides-green/icon-rides-green.component';
import { IconRoutesGreyComponent } from './icon-routes-grey/icon-routes-grey.component';
import { IconRoutesGreenComponent } from './icon-routes-green/icon-routes-green.component';
import { IconSchoolsGreyComponent } from './icon-schools-grey/icon-schools-grey.component';
import { IconSchoolsGreenComponent } from './icon-schools-green/icon-schools-green.component';
import { IconStudentsGreyComponent } from './icon-students-grey/icon-students-grey.component';
import { IconStudentsGreenComponent } from './icon-students-green/icon-students-green.component';
import { IconVehiclesGreyComponent } from './icon-vehicles-grey/icon-vehicles-grey.component';
import { IconVehiclesGreenComponent } from './icon-vehicles-green/icon-vehicles-green.component';
import { IconBntGhostComponent } from './icon-bnt-ghost/icon-bnt-ghost.component';
import { IconCampusBrandComponent } from './icon-campus-brand/icon-campus-brand.component';
import { IconParentBrandComponent } from './icon-parent-green/icon-parent-brand.component';
import { IconSchoolGreenComponent } from './icon-school-green/icon-school-green.component';
import { IconDistrictGreenComponent } from './icon-district-green/icon-district-green.component';
import { IconParentGreyComponent } from './icon-parent-grey/icon-parent-grey.component';
import { IconNoteGreyComponent } from './icon-note-grey/icon-note-grey.component';
import { IconCheckedCircleLargeComponent } from './icon-checked-circle-large/icon-checked-circle-large.component';
import { IconDistrictsComponent } from './icon-districts/icon-districts.component';
import { IconPlusCircleComponent } from './icon-plus-circle/icon-plus-circle.component';
import { IconBinComponent } from './icon-bin/icon-bin.component';
import { IconStarComponent } from './icon-star/icon-star.component';
import { IconVendorBadgeComponent } from './icon-vendor-badge/icon-vendor-badge.component';
import { IconBulletPointComponent } from './icon-bullet-point/icon-bullet-point.component';
import { IconUploadComponent } from './icon-upload/icon-upload.component';
import { IconAttentionSquareComponent } from './icon-attention-square/icon-attention-square.component';
import { IconAttentionRoundComponent } from './icon-attention-round/icon-attention-round.component';
import { IconDocumentComponent } from './icon-document/icon-document.component';
import { IconCampusComponent } from './icon-campus/icon-campus.component';
import { IconTimeComponent } from './icon-time/icon-time.component';
import { IconLocationPinBorderComponent } from './icon-location-pin-border/icon-location-pin-border.component';
import { IconCampusLightGreenComponent } from './icon-campus-light-green/icon-campus-light-green.component';
import { IconWorkQueueComponent } from './icon-work-queue/icon-work-queue.component';
import { IconVendorComponent } from './icon-vendor/icon-vendor.component';
import { IconYardComponent } from './icon-yard/icon-yard.component';
import { IconDollarInCircleComponent } from './icon-dollar-in-circle/icon-dollar-in-circle.component';
import { IconEmployeeComponent } from './icon-employee/icon-employee.component';
import { IconFilterComponent } from './icon-filter/icon-filter.component';
import { IconCheckSmallComponent } from './icon-check-small/icon-check-small.component';
import { IconUndoComponent } from './icon-undo/icon-undo.component';
import { ArrowIconsModule } from './arrow-icons/arrow-icons.module';
import { SearchIconModule } from './search-icon/search-icon.module';
import { CalendarIconModule } from './calendar-icon/calendar-icon.module';
import { IconCloseModule } from './icon-close/icon-close.module';
import { IconRouteGroupComponent } from './icon-route-group/icon-route-group.component';
import { IconDriverBusReportComponent } from './icon-driver-bus-report/icon-driver-bus-report.component';
import { IconTripsComponent } from './icon-trips/icon-trips.component';
import { IconInvoicingMenuComponent } from './icon-invoicing-menu/icon-invoicing-menu.component';

const components = [
  IconBinComponent,
  IconVmStreetviewComponent,
  IconVmPhotoViewComponent,
  IconDistrictsComponent,
  IconCheckedCircleComponent,
  IconCheckedCircleLargeComponent,
  IconGreenPinComponent,
  IconPickupLineComponent,
  IconDropoffLineComponent,
  IconAlertCriticalComponent,
  IconAlertWarningComponent,
  IconAlertInfoComponent,
  IconAlertSuccessComponent,
  IconDashboardGreyComponent,
  IconDashboardGreenComponent,
  IconDriversGreyComponent,
  IconDriversGreenComponent,
  IconInsightsGreyComponent,
  IconInsightsGreenComponent,
  IconRidesGreyComponent,
  IconRidesGreenComponent,
  IconRoutesGreyComponent,
  IconRoutesGreenComponent,
  IconSchoolsGreyComponent,
  IconSchoolsGreenComponent,
  IconStudentsGreyComponent,
  IconStudentsGreenComponent,
  IconVehiclesGreyComponent,
  IconVehiclesGreenComponent,
  IconBntGhostComponent,
  IconCampusBrandComponent,
  IconParentBrandComponent,
  IconPlusCircleComponent,
  IconSchoolGreenComponent,
  IconDistrictGreenComponent,
  IconParentGreyComponent,
  IconNoteGreyComponent,
  IconStarComponent,
  IconVendorBadgeComponent,
  IconBulletPointComponent,
  IconUploadComponent,
  IconAttentionSquareComponent,
  IconAttentionRoundComponent,
  IconDocumentComponent,
  IconCampusComponent,
  IconTimeComponent,
  IconLocationPinBorderComponent,
  IconCampusLightGreenComponent,
  IconWorkQueueComponent,
  IconVendorComponent,
  IconYardComponent,
  IconDollarInCircleComponent,
  IconFilterComponent,
  IconCheckSmallComponent,
  IconEmployeeComponent,
  IconUndoComponent,
  IconRouteGroupComponent,
  IconDriverBusReportComponent,
  IconTripsComponent,
  IconInvoicingMenuComponent,
];

@NgModule({
  declarations: [...components],
  imports: [CommonModule, ArrowIconsModule, SearchIconModule, CalendarIconModule, IconCloseModule],
  exports: [...components, ArrowIconsModule, SearchIconModule, CalendarIconModule, IconCloseModule],
})
export class IconsModule {}
