export function checkNotNull<T>(value: T, message = 'Expected a defined value'): NonNullable<T> {
  if (isNull(value)) {
    throw new Error(message);
  }
  return value as NonNullable<T>;
}

function isNull<T>(value: T | null | undefined): value is null | undefined {
  return value === undefined || value === null;
}
