<div class="ic-district-green">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="26"
    viewBox="0 0 28 26"
  >
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    stroke="#47A540"
                    stroke-width="2"
                    d="M5.667 1H22.334000000000003V24.667H5.667z"
                    transform="translate(-534 -439) translate(524 36) translate(0 40) translate(0 354) translate(10 9.167)"
                  />
                  <path
                    fill="#47A540"
                    d="M3.5 0H24.5V2.333H3.5z"
                    transform="translate(-534 -439) translate(524 36) translate(0 40) translate(0 354) translate(10 9.167)"
                  />
                  <path
                    stroke="#47A540"
                    stroke-width="2"
                    d="M22 10.333H27V24.666H22z"
                    transform="translate(-534 -439) translate(524 36) translate(0 40) translate(0 354) translate(10 9.167)"
                  />
                  <g stroke="#47A540">
                    <path
                      d="M0.5 0.5H6.5V6.5H0.5z"
                      transform="translate(-534 -439) translate(524 36) translate(0 40) translate(0 354) translate(10 9.167) translate(10.5 17.5)"
                    />
                  </g>
                  <path
                    stroke="#47A540"
                    d="M15.667 5.167H19.334V8.834H15.667zM8.667 11H12.334V14.667H8.667zM8.667 5.167H12.334V8.834H8.667zM15.667 11H19.334V14.667H15.667z"
                    transform="translate(-534 -439) translate(524 36) translate(0 40) translate(0 354) translate(10 9.167)"
                  />
                  <path
                    stroke="#47A540"
                    stroke-width="2"
                    d="M1 10.333H6V24.666H1z"
                    transform="translate(-534 -439) translate(524 36) translate(0 40) translate(0 354) translate(10 9.167)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</div>
