import { getDefaultDriverForWeekday } from './route-data.selectors';
import { getAllVehicleEntities } from '../../../vehicles/store/selectors/vehicle-data.selectors';
import { getAllVehiclesByDriverState } from '../../../drivers/store/selectors/driver-data.selectors';
import { createSelector } from '@ngrx/store';

const getVehicleEntityStateByRouteByWeekday = createSelector(
  getDefaultDriverForWeekday,
  getAllVehiclesByDriverState,
  getAllVehicleEntities,
  (driverId, vehiclesByDrivers, allVehicles) => {
    if (!driverId) {
      return null;
    }
    const vehicleIdByDriver = vehiclesByDrivers[driverId]?.vehicleId;
    if (!vehicleIdByDriver) {
      return null;
    }
    return allVehicles[vehicleIdByDriver];
  },
);

export const getVehicleByRoute = createSelector(getVehicleEntityStateByRouteByWeekday, (state) =>
  state ? state.entity : null,
);
