import { createReducer, on } from '@ngrx/store';

import { RideAssignmentState } from '../../dependencies';
import * as fromActions from '../actions';

const createInitialState = (): RideAssignmentState => {
  return {
    loading: true,
    submitRideAssignment: {
      loading: false,
    },
    hasUnsavedChanges: false,
  };
};

export const rideAssignmentFeatureReducer = createReducer(
  createInitialState(),
  on(fromActions.destroyRideAssignment, fromActions.loadRideAssignmentDataRequested, createInitialState),
  on(fromActions.loadRideAssignmentDataSuccess, (state, action): RideAssignmentState => {
    const { rideId, rideDisplayId, rideStatus, rideDisplayStartDateLong, driver, vehicle, zumerFee, initialVendor } =
      action.data;
    const isVendorPortal = wpEnvironment.userRole === 'vendor';

    return {
      ...state,
      loading: false,
      error: undefined,
      data: {
        rideId,
        rideDisplayId,
        rideStatus,
        rideDisplayStartDateLong,
        isSubmitEnabled: false,
        existingAssignmentCard: {
          selected: {
            driver,
            vehicle,
          },
        },
        newAssignmentCard: {
          inProgress: {
            isDriverStep: true,
          },
        },
        zumerFee: {
          isInProgress: false,
          isStartButtonShown: false,
          isNewFeeChangedByUser: false,
          suggestedFeeCents: zumerFee.suggestedFeeCents,
          existingFeeCents: driver && !driver.vendor ? zumerFee.existingFeeCents : undefined,
        },
        rideType: {
          isShown: wpEnvironment.userRole !== 'vendor',
          isInProgress: !driver && !vehicle && !isVendorPortal,
          isApplied: isVendorPortal,
          initialVendor: initialVendor,
          selectedVendor: initialVendor
            ? {
                vendorId: initialVendor.vendorId,
                yardId: initialVendor.yardId,
              }
            : undefined,
        },
        assignDriver: isVendorPortal
          ? {
              newDriver: {
                loading: false,
              },
            }
          : undefined,
      },
    };
  }),
  on(fromActions.loadRideAssignmentDataFailed, (state, { error }): RideAssignmentState => {
    return {
      ...state,
      loading: false,
      error,
    };
  }),
  // Select ride type step
  on(fromActions.startRideTypeSelection, (state): RideAssignmentState => {
    return {
      ...state,
      data: {
        ...state.data,
        rideType: {
          ...state.data.rideType,
          isInProgress: true,
        },
      },
    };
  }),
  on(fromActions.applyRideType, (state, { vendor }): RideAssignmentState => {
    return {
      ...state,
      hasUnsavedChanges: true,
      data: {
        ...state.data,
        rideType: {
          ...state.data.rideType,
          isInProgress: false,
          isApplied: true,
          selectedVendor: vendor,
        },
        assignDriver: {
          newDriver: {
            loading: false,
          },
        },
      },
    };
  }),
  // Select driver step
  on(fromActions.newDriverSelected, (state, { driverId }): RideAssignmentState => {
    return {
      ...state,
      hasUnsavedChanges: true,
      data: {
        ...state.data,
        assignDriver: {
          newDriverId: driverId,
          newDriver: {
            loading: !!driverId,
          },
        },
      },
    };
  }),
  on(fromActions.loadNewDriverSuccess, (state, { driver }): RideAssignmentState => {
    return {
      ...state,
      data: {
        ...state.data,
        assignDriver: {
          ...state.data.assignDriver,
          newDriver: {
            loading: false,
            data: driver,
          },
        },
      },
    };
  }),
  on(fromActions.loadNewDriverFailed, (state, { error }): RideAssignmentState => {
    return {
      ...state,
      data: {
        ...state.data,
        assignDriver: {
          ...state.data.assignDriver,
          newDriver: {
            loading: false,
            error,
          },
        },
      },
    };
  }),
  on(fromActions.applyNewDriver, (state): RideAssignmentState => {
    // zumer
    if (!state.data.rideType.selectedVendor) {
      const defaultVehicle = state.data.assignDriver.newDriver.data.defaultVehicle;
      return {
        ...state,
        data: {
          ...state.data,
          isSubmitEnabled: !!defaultVehicle,
          newAssignmentCard: {
            selected: {
              driver: {
                ...state.data.assignDriver.newDriver.data,
              },
              vehicle: defaultVehicle ? { ...defaultVehicle } : undefined,
            },
          },
          assignDriver: undefined,
          rideType: {
            ...state.data.rideType,
            isShown: false,
          },
          zumerFee: {
            ...state.data.zumerFee,
            isStartButtonShown: true,
            newFeeCents: state.data.zumerFee.suggestedFeeCents,
          },
        },
      };
    }
    // vendor
    const driver = state.data.assignDriver.newDriver.data;
    const defaultVehicle = state.data.assignDriver.newDriver.data.defaultVehicle;
    return {
      ...state,
      data: {
        ...state.data,
        newAssignmentCard: {
          inProgress: {
            isDriverStep: false,
            driver: { ...driver },
          },
        },
        assignDriver: undefined,
        assignVehicle: {
          defaultDriverVehicleId: defaultVehicle?.id,
          newVehicleId: defaultVehicle?.id,
          newVehicle: {
            loading: false,
            data: defaultVehicle ? { ...defaultVehicle } : undefined,
          },
        },
      },
    };
  }),
  on(fromActions.keepExistingDriver, (state): RideAssignmentState => {
    const existingDriver = state.data.existingAssignmentCard.selected.driver;
    if (!state.data.rideType.selectedVendor) {
      const vehicle = state.data.existingAssignmentCard.selected.vehicle;
      return {
        ...state,
        hasUnsavedChanges: true,
        data: {
          ...state.data,
          isSubmitEnabled: !!vehicle,
          newAssignmentCard: {
            selected: {
              driver: { ...existingDriver },
              vehicle: vehicle ? { ...vehicle } : undefined,
            },
          },
          assignDriver: undefined,
          rideType: {
            ...state.data.rideType,
            isShown: false,
          },
          zumerFee: {
            ...state.data.zumerFee,
            isStartButtonShown: true,
            newFeeCents: state.data.zumerFee.existingFeeCents,
          },
        },
      };
    }
    // vendor
    return {
      ...state,
      hasUnsavedChanges: true,
      data: {
        ...state.data,
        newAssignmentCard: {
          inProgress: {
            isDriverStep: false,
            driver: {
              ...existingDriver,
            },
          },
        },
        assignDriver: undefined,
        assignVehicle: {
          defaultDriverVehicleId: existingDriver.defaultVehicle?.id,
          newVehicleId: existingDriver.defaultVehicle?.id,
          newVehicle: {
            loading: false,
            data: existingDriver.defaultVehicle ? { ...existingDriver.defaultVehicle } : undefined,
          },
        },
      },
    };
  }),
  on(fromActions.keepVendorDriverUnassigned, (state): RideAssignmentState => {
    return {
      ...state,
      hasUnsavedChanges: true,
      data: {
        ...state.data,
        newAssignmentCard: {
          ...state.data.newAssignmentCard,
          inProgress: {
            ...state.data.newAssignmentCard.inProgress,
            isDriverStep: false,
          },
        },
        assignDriver: undefined,
        assignVehicle: {
          newVehicle: { loading: false },
        },
      },
    };
  }),
  on(fromActions.unassignExistingDriver, (state): RideAssignmentState => {
    if (!state.data.rideType.selectedVendor) {
      return {
        ...state,
        hasUnsavedChanges: true,
        data: {
          ...state.data,
          isSubmitEnabled: true,
          newAssignmentCard: {
            selected: {},
          },
          assignDriver: undefined,
          rideType: {
            ...state.data.rideType,
            isShown: false,
          },
        },
      };
    }
    // vendor
    return {
      ...state,
      hasUnsavedChanges: true,
      data: {
        ...state.data,
        newAssignmentCard: {
          inProgress: {
            isDriverStep: false,
          },
        },
        assignDriver: undefined,
        assignVehicle: {
          newVehicle: {
            loading: false,
          },
        },
      },
    };
  }),
  // Select vehicle step
  on(fromActions.newVehicleSelected, (state, { vehicleId }): RideAssignmentState => {
    return {
      ...state,
      data: {
        ...state.data,
        assignVehicle: {
          ...state.data.assignVehicle,
          newVehicleId: vehicleId,
          newVehicle: { loading: !!vehicleId },
        },
      },
    };
  }),
  on(fromActions.loadNewVehicleSuccess, (state, { vehicle }): RideAssignmentState => {
    return {
      ...state,
      data: {
        ...state.data,
        assignVehicle: {
          ...state.data.assignVehicle,
          newVehicle: {
            loading: false,
            data: vehicle,
          },
        },
      },
    };
  }),
  on(fromActions.loadNewVehicleFailed, (state, { error }): RideAssignmentState => {
    return {
      ...state,
      data: {
        ...state.data,
        assignVehicle: {
          ...state.data.assignVehicle,
          newVehicle: {
            loading: false,
            error,
          },
        },
      },
    };
  }),
  on(fromActions.applyNewVehicle, (state): RideAssignmentState => {
    return {
      ...state,
      data: {
        ...state.data,
        isSubmitEnabled: true,
        newAssignmentCard: {
          ...state.data.newAssignmentCard,
          selected: {
            driver: state.data.newAssignmentCard.inProgress.driver,
            vehicle: { ...state.data.assignVehicle.newVehicle.data },
          },
          inProgress: undefined,
        },
        assignVehicle: undefined,
        rideType: {
          ...state.data.rideType,
          isShown: false,
        },
      },
    };
  }),
  on(fromActions.keepExistingVehicle, (state): RideAssignmentState => {
    return {
      ...state,
      data: {
        ...state.data,
        isSubmitEnabled: true,
        newAssignmentCard: {
          ...state.data.newAssignmentCard,
          selected: {
            driver: state.data.newAssignmentCard.inProgress.driver,
            vehicle: { ...state.data.existingAssignmentCard.selected.vehicle },
          },
          inProgress: undefined,
        },
        assignVehicle: undefined,
        rideType: {
          ...state.data.rideType,
          isShown: false,
        },
      },
    };
  }),
  on(fromActions.keepVehicleUnassigned, fromActions.unassignExistingVehicle, (state): RideAssignmentState => {
    return {
      ...state,
      data: {
        ...state.data,
        isSubmitEnabled: true,
        newAssignmentCard: {
          ...state.data.newAssignmentCard,
          selected: {
            driver: state.data.newAssignmentCard.inProgress.driver,
            vehicle: undefined,
          },
          inProgress: undefined,
        },
        assignVehicle: undefined,
        rideType: {
          ...state.data.rideType,
          isShown: false,
        },
      },
    };
  }),
  // Zumer fee step
  on(fromActions.startZumerFeeStep, (state): RideAssignmentState => {
    return {
      ...state,
      data: {
        ...state.data,
        isSubmitEnabled: false,
        zumerFee: {
          ...state.data.zumerFee,
          isStartButtonShown: false,
          isInProgress: true,
        },
      },
    };
  }),
  on(fromActions.cancelZumerFeeStep, (state): RideAssignmentState => {
    return {
      ...state,
      data: {
        ...state.data,
        isSubmitEnabled: true,
        zumerFee: {
          ...state.data.zumerFee,
          isStartButtonShown: true,
          isInProgress: false,
        },
      },
    };
  }),
  on(fromActions.applyNewZumerFee, (state, { newFeeCents }): RideAssignmentState => {
    return {
      ...state,
      data: {
        ...state.data,
        isSubmitEnabled: true,
        zumerFee: {
          ...state.data.zumerFee,
          newFeeCents,
          isStartButtonShown: true,
          isInProgress: false,
          isNewFeeChangedByUser: true,
        },
      },
    };
  }),
  // Submit
  on(fromActions.submitRideAssignmentRequested, (state): RideAssignmentState => {
    return {
      ...state,
      data: {
        ...state.data,
        zumerFee: {
          ...state.data.zumerFee,
          isStartButtonShown: false,
        },
      },
      submitRideAssignment: {
        loading: true,
      },
    };
  }),
  on(fromActions.submitRideAssignmentSuccess, (state): RideAssignmentState => {
    return {
      ...state,
      hasUnsavedChanges: false,
      submitRideAssignment: {
        loading: true,
        success: true,
      },
    };
  }),
  on(fromActions.submitRideAssignmentFailed, (state, { error }): RideAssignmentState => {
    return {
      ...state,
      submitRideAssignment: {
        loading: false,
        error,
      },
    };
  }),
);
