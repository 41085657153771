import { SmartFormFieldAddress, SmartFormFieldAddressLocation, SmartFormFieldType } from '@apiEntities';

export const isSmartFormFieldAddressWithExactLocation = (arg: any): arg is SmartFormFieldAddress => {
  return !!arg && typeof arg === 'object' && arg.type === SmartFormFieldType.ADDRESS_LOCATION;
};

export const isSmartFormFieldAddressWithExactLocationValid = (
  address: SmartFormFieldAddressLocation,
  required: boolean,
): boolean => {
  if (!required && !address.value) {
    return true;
  }
  if (!address.value) {
    return false;
  }
  if (address.value.locationType === 'home') {
    return (
      Array.isArray(address.value?.address.address_components) && !!address.value.address.address_components.length
    );
  } else if (address.value.locationType === 'bus') {
    return (
      !!address.value.busAddressId &&
      Array.isArray(address.value?.address.address_components) &&
      !!address.value.address.address_components.length
    );
  } else {
    console.warn('Unknown address type: ', address.value.locationType);
    return false;
  }
};
