<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
>
  <g fill="none" fill-rule="evenodd">
    <g fill="#959597">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d="M0 5.714c0-.789.638-1.428 1.425-1.428h10.007c.787 0 1.425.634 1.425 1.428 0 .79-.637 1.429-1.425 1.429H1.425C.638 7.143 0 6.509 0 5.714z"
                      transform="translate(-1387 -372) translate(486) translate(0 230) translate(0 67) translate(0 50) translate(1) matrix(1 0 0 -1 900 42.143) rotate(-45 6.429 5.714)"
                    />
                    <path
                      d="M7.143 5.714c0-.789.637-1.428 1.425-1.428h10.007c.787 0 1.425.634 1.425 1.428 0 .79-.638 1.429-1.425 1.429H8.568c-.787 0-1.425-.634-1.425-1.429z"
                      transform="translate(-1387 -372) translate(486) translate(0 230) translate(0 67) translate(0 50) translate(1) matrix(1 0 0 -1 900 42.143) rotate(-135 13.571 5.714)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
