import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-round-marker',
  templateUrl: './round-marker.component.html',
  styleUrls: ['./round-marker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoundMarkerComponent {
  @Input() public containerHeight = '20px';
  @Input() public markerWidth = '8px';
  @Input() public color: 'green' | 'orange' | 'red' | 'grey' = 'green';
  @Input() public margin: string;
}
