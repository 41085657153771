import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-icon-drivers',
  templateUrl: './icon-drivers.component.html',
  styleUrls: ['./icon-drivers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconDriversComponent implements OnInit {
  @Input() public green = false;

  constructor() {}

  ngOnInit(): void {}
}
