<div class="wp-auth-wrapper">
  <ng-content></ng-content>

  <div class="wp-auth-wrapper-right-column">
    <img
      *ngIf="isKidImage; else resetPasswordImage"
      class="kid-img"
      alt="Welcome to Zum"
    />

    <ng-template #resetPasswordImage>
      <img class="reset-password-img" alt="Reset password" />
    </ng-template>
  </div>
</div>
