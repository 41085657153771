import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { segmentEffects, segmentFeatureKey, segmentFeatureReducer } from './store';

@NgModule({
  imports: [StoreModule.forFeature(segmentFeatureKey, segmentFeatureReducer), EffectsModule.forFeature(segmentEffects)],
})
export class SegmentModule {}
