import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { iconPaths } from '@rootTypes/utils';

@Component({
  selector: 'wp-alert-with-icon-warning',
  template: `
    <div class="warning" [ngClass]="{ 'no-border': noBorders }">
      <div class="warning-icon" [ngClass]="{ mini: mini }">
        <ng-container *ngIf="circle; else plainTemplate">
          <wp-portal-icon [path]="iconCirclePath" [heightStr]="'20px'"></wp-portal-icon>
        </ng-container>
        <ng-template #plainTemplate>
          <wp-portal-icon [path]="iconPath" [heightStr]="mini ? '18px' : 'auto'"></wp-portal-icon>
        </ng-template>
      </div>
      <div class="warning-text">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      .warning {
        display: flex;
        padding: 4px 0;
        border-top: 1px solid #dfdfe3;
        border-bottom: 1px solid #dfdfe3;
      }
      .warning.no-border {
        border-top: none;
        border-bottom: none;
      }
      .warning-icon {
        margin-right: 10px;
        width: 32px;
        padding: 4px 0;
        background-color: rgba(255, 150, 0, 0.12);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .warning-icon.mini {
        width: 16px;
        padding: 3px 0;
      }
      .warning-text {
        display: flex;
        align-items: center;
        width: fit-content;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertWithIconWarningComponent implements OnInit {
  @Input() public mini;
  @Input() public circle: boolean;
  @Input() public noBorders;
  public iconPath = iconPaths.EXCLAMATION_SIGN_ORANGE_BOLD;
  public iconCirclePath = iconPaths.EXCLAMATION_SIGN_CIRCLED_ORANGE;
  constructor() {}

  ngOnInit(): void {}
}
