<wp-autocomplete
  [label]="model.label"
  [control]="model.control"
  [controlStateChange]="model.controlStateChange$"
  [options]="model.options"
  [optionDisplayFn]="model.optionDisplayFn"
  [optionSearchFn]="model.optionSearchFn"
  [parentSelectorToCloseByClick]="model.parentSelectorToCloseByClick"
  (valueChangedByUser)="model.emitValueChangeByUser($event)"
></wp-autocomplete>
