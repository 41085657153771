import { VehicleInspectionStatus } from '@apiEntities';
import { VehicleInspectionStatusQueryParam } from '../types';

export const vehicleInspectionStatusToQueryParam = (
  status: VehicleInspectionStatus,
): VehicleInspectionStatusQueryParam => {
  const map = {
    [VehicleInspectionStatus.DRAFT]: VehicleInspectionStatusQueryParam.DRAFT,
    [VehicleInspectionStatus.SATISFACTORY]: VehicleInspectionStatusQueryParam.SATISFACTORY,
    [VehicleInspectionStatus.UNSATISFACTORY]: VehicleInspectionStatusQueryParam.UNSATISFACTORY,
    [VehicleInspectionStatus.SATISFACTORY_WITH_ISSUES]: VehicleInspectionStatusQueryParam.SATISFACTORY_WITH_ISSUES,
  };
  return map[status];
};
