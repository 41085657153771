import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, filter, map, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { State } from '@rootStore';
import { Observable, of, timer } from 'rxjs';
import { VendorApiService } from '../../services/vendor-api.service';
import * as fromActions from '../actions/vendor-list.actions';
import * as fromSelectors from '../selectors/vendor-lists.selectors';
import * as fromTypes from '../../types';
import * as fromDataActions from '../actions/vendor-data.actions';

@Injectable()
export class VendorListEffects {
  constructor(
    private actions: Actions,
    private store: Store<State>,
    private apiService: VendorApiService,
  ) {
    this.createLoadListEffects();
  }

  public vendorListChanged$ = createEffect(() =>
    this.actions.pipe(
      ofType(
        fromActions.listInitialized,
        fromActions.pageChanged,
        fromActions.pageSizeChanged,
        fromActions.searchChanged,
      ),
      switchMap((a) => {
        return this.store.select(fromSelectors.getListState, { listName: a.listName }).pipe(
          take(1),
          map((list) => {
            return fromActions.vendorLoadListRequested({
              snapshot: {
                pageSize: list.pagination.pageSize,
                page: list.pagination.page,
                search: list.currentSearch,
                filters: [],
              },
              listName: a.listName,
            });
          }),
        );
      }),
    ),
  );

  /**
   * Vendor list item initialized
   * Load vendor details with debounce
   */
  public vendorCardInitialized$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.vendorListItemInitialized),
      mergeMap((action) => {
        const { vendorId } = action;
        return timer(fromTypes.ui.VENDOR_LIST_CARD_LOAD_DEBOUNCE).pipe(
          takeUntil(
            this.actions.pipe(
              ofType(fromActions.vendorListItemDestroyed),
              filter((a) => a.vendorId === vendorId),
            ),
          ),
          map(() => action),
        );
      }),
      map((action) => {
        const { vendorId } = action;
        return fromDataActions.loadVendorRequested({ vendorId });
      }),
    ),
  );

  private createLoadListEffects(): void {
    fromTypes.vendorLists.forEach((listName) => {
      this[`loadRequested_${listName}`] = createEffect(() =>
        this.actions.pipe(
          ofType(fromActions.vendorLoadListRequested),
          filter((a) => a.listName === listName),
          debounceTime(fromTypes.ui.VENDOR_LIST_LOAD_DEBOUNCE_MILLIS),
          switchMap((action) => this.onLoadVendorListRequested(action.snapshot, action.listName)),
        ),
      );
    });
  }

  private onLoadVendorListRequested(
    snapshot: fromTypes.VendorListSnapshot,
    listName: fromTypes.VendorListName,
  ): Observable<Action> {
    const request = fromTypes.utils.getVendorListRequest(snapshot);
    return this.apiService.entityFilter(request).pipe(
      map((resp) =>
        fromActions.vendorLoadListSuccess({
          listName,
          ...resp,
        }),
      ),
      catchError((err) => {
        console.log(err);
        return of(
          fromActions.vendorLoadListFailed({
            listName,
            error: {
              text: 'Failed to load vendor list',
              originalError: err,
            },
          }),
        );
      }),
    );
  }
}
