import { login } from './portal-routes/auth';

export const commonRoutes = {
  login: {
    url: `/${login.path}`,
    path: login.path,
    queryParams: {},
  },
  home: {
    url: '/home',
    path: 'home',
    queryParams: {},
  },
  selectAccount: {
    url: '/select-account',
    path: 'select-account',
    queryParams: {},
  },
  accountSwitching: {
    url: '/account-switching',
    path: 'account-switching',
    queryParams: {},
  },
  logout: {
    url: '/logout',
    path: 'logout',
    queryParams: {},
  },
  noViewPermissions: {
    url: '/no-view-permissions',
    path: 'no-view-permissions',
    queryParams: {},
  },
};
