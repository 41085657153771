import { Injectable } from '@angular/core';
import { isWebError, isWebErrorDetails, WpError } from '@rootTypes';

export interface ErrorStatements {
  generalErrorMessage: string;
  specialErrorMessage?: string;
  errorReason?: string;
  correctiveAction?: string;
  contextId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorParserService {
  /**
   * Helper functions
   */
  public getErrorStatements(error: WpError): ErrorStatements {
    const generalErrorMessage = error.text;
    const specialErrorMessage = this.getSpecialErrorMessage(error);
    const errorReason = this.getErrorReason(error);
    const correctiveAction = this.getErrorCorrectiveAction(error);
    const contextId = this.getErrorContextId(error);
    // return all defined error statements
    return { generalErrorMessage, specialErrorMessage, errorReason, correctiveAction, contextId };
  }

  private getSpecialErrorMessage(error: WpError): string {
    if (isWebError(error.originalError)) {
      const { originalError } = error;
      const message = isWebErrorDetails(originalError) ? originalError.data.message : (originalError.data as string);
      if (this.isJSON(message)) {
        const json = JSON.parse(message);
        return JSON.stringify(json);
      } else {
        return message;
      }
    } else {
      return undefined;
    }
  }

  private isJSON(source: string): boolean {
    try {
      JSON.parse(source);
    } catch (e) {
      return false;
    }
    return true;
  }

  /** Not implemented yet */
  private getErrorCorrectiveAction(error: WpError): string {
    return undefined;
  }

  private getErrorReason(error: WpError): string {
    return error.reason;
  }

  private getErrorContextId(error: WpError): string {
    if (isWebError(error.originalError)) {
      return error.originalError.contextId;
    }
    return undefined;
  }
}
