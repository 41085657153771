import { createSelector } from '@ngrx/store';

import { getRideAssignmentState } from './feature-selector';
import { submitRideAssignment } from '../../dependencies';

export const isDataLoading = createSelector(getRideAssignmentState, (state) => state.loading);

const getStateData = createSelector(getRideAssignmentState, (state) => state.data);

export const getRideAssignmentDataError = createSelector(getRideAssignmentState, (state) => state.error);
export const isDataLoaded = createSelector(getStateData, (state) => !!state);
export const getRideDisplayId = createSelector(getStateData, (state) => state?.rideDisplayId);
export const getRideStatus = createSelector(getStateData, (state) => state?.rideStatus);
export const getRideDisplayStartDateLong = createSelector(getStateData, (state) => state?.rideDisplayStartDateLong);
export const getExistingAssignmentCard = createSelector(getStateData, (state) => state?.existingAssignmentCard);
export const getNewAssignmentCard = createSelector(getStateData, (state) => state?.newAssignmentCard);
export const isSubmitEnabled = createSelector(getStateData, (state) => state?.isSubmitEnabled || false);
export const hasUnsavedChanges = createSelector(getRideAssignmentState, (state) => state.hasUnsavedChanges);

const getSubmitState = createSelector(getRideAssignmentState, (state) => state.submitRideAssignment);
export const isSubmitLoading = createSelector(getSubmitState, (state) => state.loading);

const getRideTypeState = createSelector(getStateData, (state) => state?.rideType);
export const isRideTypeShown = createSelector(getRideTypeState, (state) => state?.isShown || false);
export const isRideTypeInProgress = createSelector(getRideTypeState, (state) => state?.isInProgress || false);
export const isRideTypeApplied = createSelector(getRideTypeState, (state) => state?.isApplied || false);
export const getInitialVendor = createSelector(getRideTypeState, (state) => state?.initialVendor);
export const getVendor = createSelector(getRideTypeState, (state) => state?.selectedVendor);
export const isZumer = createSelector(getVendor, (vendor) => !vendor);

const getAssignDriverState = createSelector(getStateData, (state) => state?.assignDriver);
export const isAssignDriverStep = createSelector(getAssignDriverState, (state) => !!state);
export const getExistingDriver = createSelector(getExistingAssignmentCard, (state) => state?.selected?.driver);

const getNewDriverState = createSelector(getAssignDriverState, (state) => state?.newDriver);
export const getNewDriver = createSelector(getNewDriverState, (state) => state?.data);
export const getNewDriverErrorMessage = createSelector(getNewDriverState, (state) => state?.error?.text);

const getAssignVehicleState = createSelector(getStateData, (state) => state?.assignVehicle);
export const isAssignVehicleStep = createSelector(getAssignVehicleState, (state) => !!state);
export const getExistingVehicle = createSelector(getExistingAssignmentCard, (state) => state?.selected?.vehicle);

const getNewVehicleState = createSelector(getAssignVehicleState, (state) => state?.newVehicle);
export const getNewVehicle = createSelector(getNewVehicleState, (state) => state?.data);
export const getNewVehicleErrorMessage = createSelector(getNewVehicleState, (state) => state?.error?.text);

const getNewVehicleId = createSelector(getAssignVehicleState, (state) => state?.newVehicleId);
const getDefaultDriverVehicleId = createSelector(getAssignVehicleState, (state) => state?.defaultDriverVehicleId);
export const isNewVehicleDefaultForDriver = createSelector(
  getNewVehicleId,
  getDefaultDriverVehicleId,
  (newVehicleId, defaultVehicleId) => {
    if (!newVehicleId || !defaultVehicleId) {
      return false;
    }
    return newVehicleId === defaultVehicleId;
  },
);

const getZumerFeeState = createSelector(getStateData, (state) => state?.zumerFee);
export const isZumerFeeStepInProgress = createSelector(getZumerFeeState, (state) => state?.isInProgress || false);
export const isStartZumerFeeStepButton = createSelector(
  getZumerFeeState,
  (state) => state?.isStartButtonShown || false,
);
export const getSuggestedZumerFeeCents = createSelector(getZumerFeeState, (state) => state?.suggestedFeeCents);
export const getExistingZumerFeeCents = createSelector(getExistingDriver, getZumerFeeState, (driver, zumerFee) => {
  if (driver && !driver.vendor) {
    return zumerFee?.existingFeeCents;
  }
  return undefined;
});
export const getNewZumerFeeCents = createSelector(getVendor, getZumerFeeState, (vendor, zumerFee) => {
  if (!vendor) {
    return zumerFee?.newFeeCents;
  }
  return undefined;
});

const getRideId = createSelector(getStateData, (state) => state?.rideId);
const isNewZumerFeeChangedByUser = createSelector(getZumerFeeState, (state) => state?.isNewFeeChangedByUser || false);
export const getSubmitRideAssignmentRequest = createSelector(
  getRideId,
  getExistingDriver,
  getExistingVehicle,
  getNewAssignmentCard,
  isNewZumerFeeChangedByUser,
  getNewZumerFeeCents,
  (
    rideId,
    existingDriver,
    existingVehicle,
    newCard,
    isFeeChangedByUser,
    newFee,
  ): submitRideAssignment.SubmitRideAssignmentRequest => {
    const newAssignment = newCard?.selected || {};
    const request: submitRideAssignment.SubmitRideAssignmentRequest = {
      rideId,
      vehicleAssignmentChange: {
        sourceId: existingVehicle?.id || null,
        targetId: newAssignment.vehicle?.id || null,
      },
      driverAssignmentChange: {
        sourceId: existingDriver?.id || null,
        targetId: newAssignment.driver?.id || null,
        targetDriverPrimaryVehicleId: newAssignment.driver?.defaultVehicle?.id || null,
      },
    };
    if (isFeeChangedByUser) {
      request.driverAssignmentChange.feeInCents = newFee;
    }
    return request;
  },
);
