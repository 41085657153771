import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getCharterConfigFailed,
  getCharterConfigByGuard,
  getCharterConfigRequested,
  getCharterConfigSuccess,
  getCharterConfigForTripAssignmentList,
} from '../actions';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ApiService } from '../../../api/api.service';

@Injectable()
export class TripsConfigEffects {
  public onConfigRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCharterConfigByGuard, getCharterConfigForTripAssignmentList),
      map(() => getCharterConfigRequested()),
    );
  });

  public getCharterConfigRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCharterConfigRequested),
      switchMap(() => {
        return this.api.getCharterConfig({}).pipe(
          map((data) => getCharterConfigSuccess({ data })),
          catchError((originalError) => {
            console.error(originalError);
            const error = {
              originalError,
              text: 'Failed to load charter config',
            };
            return of(getCharterConfigFailed({ error }));
          }),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}
}
