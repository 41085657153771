import { Pipe, PipeTransform } from '@angular/core';
import { millisToDisplayDuration } from '@rootTypes/utils';

@Pipe({
  name: 'millisDisplayDuration',
})
export class MillisDisplayDurationPipe implements PipeTransform {
  transform(value: number, ...args: string[]): string {
    if (value === undefined || value === null) {
      return '';
    }
    const isShort = args[0] === 'short';
    return millisToDisplayDuration(value, isShort);
  }
}
