import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const moduleKey = 'Data';
const submoduleKey = 'Common';

export const getUSNationalHolidaysRequested = createAction(
  `[${moduleKey}] [${submoduleKey}]: US national holidays requested`,
  props<{ startDate: fromTypes.YYYYMMDDString; endDate: fromTypes.YYYYMMDDString }>(),
);

export const getUSNationalHolidaysSuccess = createAction(
  `[${moduleKey}] [${submoduleKey}]: US national holidays success`,
  props<{
    holidays: {
      [holidayId: string]: fromTypes.NationalHoliday;
    };
  }>(),
);

export const getUSNationalHolidaysFailed = createAction(
  `[${moduleKey}] [${submoduleKey}]: US national holidays failed`,
  props<{ error: fromTypes.WpError }>(),
);
