import { RouteDetailsTab } from 'src/app/features/runs/types/ui';
import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';

export interface RouteDetailsRouteData {
  routeId: string;
  tabId?: string; // defaultRoutesTab by default
  displayBackText?: string;
}

const defaultRoutesTab = RouteDetailsTab.STOPS;

export const routeDetailsDrawer: PortalRoute<RouteDetailsRouteData> = {
  path: 'details/:routeId/:tabId',
  isOnRoute: (state: RouterStateUrl) => {
    return state.drawer && state.drawer.url && state.drawer.url.startsWith('_/drawer/routes/details');
  },
  parse: (state: RouterStateUrl): RouteDetailsRouteData => {
    let res = {
      routeId: state.drawer.params.routeId,
      tabId: state.drawer.params.tabId,
    } as RouteDetailsRouteData;
    if (state.queryParams) {
      res = {
        ...res,
        ...state.queryParams,
      };
    }
    return res;
  },
  request: (data: RouteDetailsRouteData, queryParamStrategy: QueryParamsHandlingStrategy): NavigationRequest => {
    // do not mutate the original arg
    const copy = { ...data };
    const { routeId, tabId } = copy;
    const res = {
      path: ['_', 'drawer', 'routes', 'details', routeId, tabId || defaultRoutesTab],
    } as NavigationRequest;
    delete data.routeId;
    delete data.tabId;
    // extract additional data fields as query params
    if (Object.keys(data).length) {
      res.query = { ...data };
    }
    if (queryParamStrategy) {
      res.extras = {
        queryParamsHandling: queryParamStrategy,
      };
    }
    return res;
  },
};
