import { QuickFilterQueryParam } from '../types';
import { VehicleInspectionQuickFilter } from '../../../../data/vehicle-inspections/types';

export const queryParamToQuickFilter = (param: QuickFilterQueryParam): VehicleInspectionQuickFilter => {
  const map = {
    [QuickFilterQueryParam.NONE]: VehicleInspectionQuickFilter.NONE,
    [QuickFilterQueryParam.LATE_INSPECTIONS]: VehicleInspectionQuickFilter.LATE_INSPECTIONS,
    [QuickFilterQueryParam.MISSING_INSPECTIONS]: VehicleInspectionQuickFilter.MISSING_INSPECTIONS,
  };
  return map[param] || VehicleInspectionQuickFilter.NONE;
};
