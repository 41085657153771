import { Injectable } from '@angular/core';
import { ActivityStatus, PortalEntity, PortalEntityType, YYYYMMDDString } from '@rootTypes';
import { Observable } from 'rxjs';
import * as fromApi from '../../api';
import { EntityFilterRequestType } from '../../api/endpoints/entity-filter';
import { ApiService } from '../../api/api.service';
import { map } from 'rxjs/operators';

@Injectable()
export class TimesheetEntryContextApiService {
  constructor(private api: ApiService) {}

  public getActivityContext(
    entityId: string,
    entityType: PortalEntityType,
    yyyyMMDD: YYYYMMDDString,
  ): Observable<fromApi.entityFilter.EntityFilterResponse> {
    const request = {
      type: EntityFilterRequestType.ACTIVITY_TIME_ENTRY,
      extraFilters: {
        status: [
          ActivityStatus.PENDING_REVIEW,
          ActivityStatus.SUBMITTED_FOR_PAYMENT,
          ActivityStatus.UNPAID,
          ActivityStatus.APPROVED,
          ActivityStatus.STARTED,
          ActivityStatus.ASSIGNED,
          ActivityStatus.COMPLETED,
        ],
      },
      date: {
        fieldName: 'local_date',
        from: yyyyMMDD,
        to: yyyyMMDD,
      },
    } as fromApi.entityFilter.EntityFilterRequest;
    if (entityType === PortalEntityType.DRIVER) {
      request.driverIds = [entityId];
    }
    request.skip = 0;
    request.limit = 1000; // unlimited
    return this.api.entityFilter(request);
  }

  public getSpecificContext(
    entityId: string,
    entityType: PortalEntityType,
    contextEntityType: PortalEntityType,
    yyyyMMDD: YYYYMMDDString,
  ): Observable<PortalEntity[]> {
    if (contextEntityType === PortalEntityType.RIDE) {
      return this.getContextRides(entityId, entityType, yyyyMMDD);
    } else {
      throw new Error('Unknown context type');
    }
  }

  private getContextRides(
    entityId: string,
    entityType: PortalEntityType,
    yyyyMMDD: YYYYMMDDString,
  ): Observable<PortalEntity[]> {
    const request = {
      date: {
        from: yyyyMMDD,
        to: yyyyMMDD,
      },
    } as fromApi.rides.RideFilterRequest;
    if (entityType === PortalEntityType.DRIVER) {
      request.driverIds = [entityId];
      request.rideStatuses = [fromApi.rides.RideStatus.UPCOMING, fromApi.rides.RideStatus.PAST];
    }
    request.skip = 0;
    request.limit = 1000; // unlimited
    return this.api.rides(request).pipe(
      map((resp) => {
        return resp.rideIds.map((rideId) => {
          return {
            entityId: rideId,
            type: PortalEntityType.RIDE,
          };
        });
      }),
    );
  }
}
