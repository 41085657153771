import { ApiBelltimeException, ApiBelltimeSchedule, ApiCampusSchedule, ApiIntradaySchedule } from '../../common';
import { BelltimeException, BelltimeSchedule, CampusSchedule } from '../../../../types/entities/campus';
import { Weekday } from '../../../../types';

export const getCampusScheduleFromApi = (source: ApiCampusSchedule): CampusSchedule => {
  const res: CampusSchedule = {
    id: source.scheduleId,
    name: source.name,
    grades: source.grades,
    belltimeExceptions: (source.belltimeSchedule.exceptions || []).map((s) => getBellTimeExceptionFromApi(s)),
    belltimeSchedule: getBellTimeTimeScheduleFromApi(source.belltimeSchedule.days),
  };
  return res;
};

function getBellTimeTimeScheduleFromApi(source: ApiBelltimeSchedule['days']): BelltimeSchedule {
  const res: BelltimeSchedule = {};
  if (source) {
    Object.keys(source).forEach((key) => {
      const sourceDay = source[key] as ApiIntradaySchedule;
      res[key as Weekday] = {
        pickup: sourceDay.pickup.value,
        dropoff: sourceDay.dropoff.value,
      };
    });
  }
  return res;
}

function getBellTimeExceptionFromApi(source: ApiBelltimeException): BelltimeException {
  const res: BelltimeException = {
    id: source.belltimeExceptionId,
    name: source.name,
    intradaySchedule: {
      pickup: source.intradaySchedule.pickup.value,
      dropoff: source.intradaySchedule.dropoff.value,
    },
    dates: source.dates,
  };
  return res;
}
