import { VendorListSnapshot } from '../vendor-list-snapshot';
import { SerializedVendorListSnapshot } from '../serialized-vendor-list-snapshot';
import * as ui from '../ui';

export const serializedToSnapshot = (source: SerializedVendorListSnapshot): VendorListSnapshot => {
  const { search, page, pageSize } = source || {};
  const res: VendorListSnapshot = {
    search,
    page: page ? parseInt(page, 10) - 1 : ui.DEFAULT_VENDOR_LIST_PAGE,
    pageSize: pageSize ? parseInt(pageSize, 10) : ui.DEFAULT_VENDOR_LIST_PAGE_SIZE,
    filters: [], // no filters for now
  };
  return res;
};
