import { createReducer, on } from '@ngrx/store';
import * as fromTypes from '../../types';
import * as fromActions from '../actions/us-national-holidays.actions';

export interface USNationalHolidayState {
  isLoading: boolean;
  error: fromTypes.WpError;
  entities: {
    [holidayId: string]: fromTypes.NationalHoliday;
  };
}

export const createInitialUSNationalHolidayState = (): USNationalHolidayState => {
  return {
    isLoading: false,
    error: null,
    entities: null,
  };
};

export const nationalHolidayReducer = createReducer<USNationalHolidayState>(
  createInitialUSNationalHolidayState(),
  on(fromTypes.cleanUpStore, createInitialUSNationalHolidayState),
  on(fromActions.getUSNationalHolidaysRequested, (state, action) => {
    return {
      ...state,
      entities: null,
      isLoading: true,
    };
  }),
  on(fromActions.getUSNationalHolidaysSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
      entities: action.holidays,
    };
  }),
  on(fromActions.getUSNationalHolidaysFailed, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
);
