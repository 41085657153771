import { BehaviorSubject, Observable, Subject } from 'rxjs';

export class PopupRef<D, R> {
  public isOpen$: Observable<boolean>;
  private closeSubj: Subject<R> = new Subject<R>();
  private pageMaskClickSubj: Subject<void> = new Subject<void>();
  private isOpen$$ = new BehaviorSubject(true);
  data!: D;

  constructor() {
    this.isOpen$ = this.isOpen$$.asObservable();
  }

  close(result?: R): void {
    this.closeSubj.next(result);
    this.isOpen$$.next(false);
  }
  closed(): Observable<R> {
    return this.closeSubj.asObservable();
  }
  onPageMaskClick(): void {
    this.pageMaskClickSubj.next();
  }
  pageMaskClicked(): Observable<void> {
    return this.pageMaskClickSubj.asObservable();
  }
}
