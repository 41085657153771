import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { ErrorPageData, ErrorState } from '@rootTypes';
import { errorPageData, destroyErrorPage, go } from '@rootStore';
import { logoutPageRequested } from 'src/app/auth/store';
import { map } from 'rxjs/operators';
import { commonRoutes } from '@router';

@Component({
  selector: 'wp-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent implements OnInit, OnDestroy {
  public data: Observable<ErrorPageData>;

  constructor(private store: Store<ErrorState>) {}

  public ngOnInit(): void {
    this.data = this.store.select(errorPageData).pipe(
      map((data) => {
        if (data) {
          return data;
        }
        return {
          text: 'Something went wrong',
          originalError: {
            unknownError: undefined,
          },
        };
      }),
    );
  }

  public ngOnDestroy(): void {
    this.store.dispatch(destroyErrorPage());
  }

  public goHomePage(): void {
    this.store.dispatch(go({ path: [commonRoutes.home.url] }));
  }

  public logout(): void {
    this.store.dispatch(logoutPageRequested());
  }
}
