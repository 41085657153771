import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostListener,
  Input,
  OnInit,
  QueryList,
} from '@angular/core';
import { DropdownOptionComponent } from '../dropdown-option/dropdown-option.component';
import { startWith } from 'rxjs/operators';
import * as fromUtils from '../utils';

@Component({
  selector: 'wp-dropdown-pane',
  templateUrl: './dropdown-pane.component.html',
  styleUrls: ['./dropdown-pane.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownPaneComponent implements OnInit, AfterContentInit {
  @Input() public maxHeightStr = 'auto';
  @Input() public hasBackdrop = true;
  @ContentChildren(DropdownOptionComponent, { descendants: true }) public options$: QueryList<DropdownOptionComponent>;
  public highlightIndex = -1;
  public options: DropdownOptionComponent[] = [];
  public highlightedOption: DropdownOptionComponent;

  constructor() {}

  ngOnInit(): void {}

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (fromUtils.isArrowUpEvent(event)) {
      this.handleArrowUpPressed();
      event.stopPropagation();
    }
    if (fromUtils.isArrowDownEvent(event)) {
      this.handleArrowDownPressed();
      event.stopPropagation();
    }
    if (fromUtils.isEnterEvent(event)) {
      this.handleEnterPressed();
      event.stopPropagation();
    }
  }

  ngAfterContentInit(): void {
    this.options$.changes.pipe(startWith(this.options$)).subscribe((ch) => {
      const arr = ch.map((v) => v);
      this.options = arr;
      this.highlightIndex = -1;
      this.updateOptionHighlight();
    });
  }

  private handleArrowDownPressed(): void {
    this.highlightIndex = this.highlightIndex + 1;
    if (this.highlightIndex > this.options?.length - 1) {
      this.highlightIndex = 0;
    }
    this.updateOptionHighlight();
  }

  private handleArrowUpPressed(): void {
    this.highlightIndex = this.highlightIndex - 1;
    if (this.highlightIndex < 0) {
      this.highlightIndex = Math.max(this.options?.length - 1, 0);
    }
    this.updateOptionHighlight();
  }

  private handleEnterPressed(): void {
    if (this.highlightedOption) {
      this.highlightedOption.selectedWithKeyboard.emit(this.highlightedOption.value);
    }
  }

  private updateOptionHighlight(): void {
    (this.options || []).forEach((option) => option.setOptionHighlighted(false));
    this.highlightedOption = this.options[this.highlightIndex];
    if (this.highlightedOption) {
      this.highlightedOption.setOptionHighlighted(true);
    }
  }
}
