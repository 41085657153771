import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[wpApplyClassOnDiff]',
})
export class ApplyClassOnDiffDirective implements OnChanges {
  @Input() public firstValue: any;
  @Input() public secondValue: any;
  @Input() public className: string;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setClass();
  }

  private setClass(): void {
    if (this.firstValue === this.secondValue) {
      this.renderer.removeClass(this.elementRef.nativeElement, this.className);
    } else {
      this.renderer.addClass(this.elementRef.nativeElement, this.className);
    }
  }
}
