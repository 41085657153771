export enum DistrictDetailsTabs {
  CAMPUSES = 'campuses',
  PROGRAMS = 'programs',
  STUDENTS = 'students',
  ROUTE_GROUPS = 'route_groups',
  ROUTES = 'routes',
  RIDES = 'rides',
  EMPLOYEES = 'employees',
  DEFAULT = 'campuses',
}
