import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DateRangePickerService } from '../date-range-picker.service';
import * as fromTypes from '../types';

@Component({
  selector: 'wp-dp-decade',
  templateUrl: './dp-decade.component.html',
  styleUrls: ['./dp-decade.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DpDecadeComponent implements OnInit {
  @Input() public decade: fromTypes.RangePickerDecade;
  @Input() public isLoading = false;

  public yearsForDecade$: Observable<fromTypes.RangePickerYear[]>;

  constructor(private store: DateRangePickerService) {}

  ngOnInit(): void {
    this.yearsForDecade$ = this.store.yearsForDecade$(this.decade);
  }

  public onLabelClick(): void {
    this.store.setViewType(fromTypes.ViewType.DAY);
  }
}
