import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromStore from './store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CommonDataApiService } from './services/common-data-api.service';
import { PortalEntityInfoApiService } from './services/portal-entity-info-api.service';

@NgModule({
  declarations: [],
  providers: [CommonDataApiService, PortalEntityInfoApiService, ...fromStore.facades.featureFacades],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers.featureReducer),
    EffectsModule.forFeature(fromStore.effects.featureEffects),
  ],
})
export class CommonDataModule {}
