import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { routerNavigatedAction } from '@ngrx/router-store';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs';
import * as fromActions from '../actions/home-ui-actions';
import * as fromRouterActions from '../actions/router.actions';
import { SideBarTabId } from '@rootTypes/entities/common';
import { timer } from 'rxjs';
import { getPortalFeatures } from '@rootTypes/utils';
import { Store } from '@ngrx/store';
import { sidebarExpanded, State } from '@rootStore';
import { LocalStorageService } from '../../core/services';

@Injectable()
export class HomeUIEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private localStorage: LocalStorageService,
  ) {}

  public sectionLoadStarted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRouterActions.lazyLoadingStart),
      map((action) => action.url), // e.g. /rides/list/...
      filter((url) => !!url),
      distinctUntilChanged(),
      map((url) => this.getFirstUrlSegment(url)),
      distinctUntilChanged(),
      map(() => {
        return fromActions.sectionLoadStart();
      }),
    ),
  );

  public sectionLoadFinished$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      map((action) => action.payload.routerState.url), // e.g. /rides/list/...
      filter((url) => !!url),
      distinctUntilChanged(),
      map((url) => this.getFirstUrlSegment(url)),
      distinctUntilChanged(),
      switchMap(() => timer(500)),
      map(() => {
        return fromActions.sectionLoadEnd();
      }),
    ),
  );

  public navigatedBetweenAppSections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      map((action) => action.payload.routerState.url), // e.g. /rides/list/...
      filter((url) => !!url),
      distinctUntilChanged(),
      map((url) => this.getFirstUrlSegment(url)),
      distinctUntilChanged(),
      map((firstUrlSegment) => {
        return fromActions.sideBarSectionChanged({
          section: this.getSectionForFirstUrlSegment(firstUrlSegment),
        });
      }),
    ),
  );

  public sideBarExpandToggled$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.sideBarExpandToggled),
        concatLatestFrom(() => this.store.select(sidebarExpanded)),
        tap(([_, isExpanded]) => {
          this.localStorage.set('sidebarExpanded', isExpanded);
        }),
      ),
    { dispatch: false },
  );

  private getSectionForFirstUrlSegment(segment: string): SideBarTabId {
    const feature = getPortalFeatures().find((f) => f.firstUrlSegment === segment);
    return feature?.id as SideBarTabId;
  }

  private getFirstUrlSegment(url: string): string {
    return url.split('?')[0].split('(')[0].split('/')[1];
  }
}
