import { combineReducers } from '@ngrx/store';
import * as fromData from './driver-data.reducer';
import * as fromLists from './driver-lists.reducer';

export interface DriverStoreState {
  data: fromData.DriverDataState;
  lists: fromLists.AllDriverListState;
}

export const featureReducer = combineReducers<DriverStoreState>({
  data: fromData.driverDataReducer,
  lists: fromLists.driverListsReducer,
});
