import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ComponentsModule } from '../../components/components.module';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DpDayPlaceholderComponent } from './dp-day-placeholder/dp-day-placeholder.component';
import { DpDayComponent } from './dp-day/dp-day.component';
import { DpDecadeComponent } from './dp-decade/dp-decade.component';
import { DpMonthComponent } from './dp-month/dp-month.component';
import { DpYearComponent } from './dp-year/dp-year.component';

@NgModule({
  declarations: [
    DateRangePickerComponent,
    DpMonthComponent,
    DpDayComponent,
    DpDecadeComponent,
    DpYearComponent,
    DpDayPlaceholderComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, MatTooltipModule, ComponentsModule],
  exports: [DateRangePickerComponent],
})
export class MultiDatePickerModule {}
