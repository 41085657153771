import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { SmartInputPhone } from '../../models';

@Component({
  selector: 'wp-smart-input-phone',
  templateUrl: './smart-input-phone.component.html',
  styleUrls: ['./smart-input-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartInputPhoneComponent {
  @Input() model: SmartInputPhone;
  @Input() tabIndex = '0';
}
