import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'wp-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent implements OnChanges, AfterViewInit {
  @Input() href: string; // optional
  @Input() openInNewTab = false;
  @Input() isBold = false;
  @Input() downloadFileName?: string;

  @ViewChild('link') private link: ElementRef<HTMLAnchorElement>;

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.downloadFileName && !changes.downloadFileName.isFirstChange()) {
      this.setLinkAttributes();
    }
  }

  public ngAfterViewInit(): void {
    this.setLinkAttributes();
  }

  // Since we can't bind to [href] with undefined value,
  // and using of ngIf="href" with 2 <ng-content> is not convenient,
  // we should set attributes in the native way
  private setLinkAttributes(): void {
    // if (this.href) {
    //   this.link.nativeElement.setAttribute('href', this.href);
    // }
    // const target = this.openInNewTab ? '_blank' : '_self';
    // this.link.nativeElement.setAttribute('target', target);
    //
    if (!this.link?.nativeElement) {
      return;
    }
    if (this.downloadFileName?.length) {
      this.link.nativeElement.setAttribute('download', this.downloadFileName);
    } else {
      this.link.nativeElement.removeAttribute('download');
    }
  }
}
