import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'wp-plus-minus-quantity-selector',
  template: `
    <div class="wrap">
      <div class="flex-wrap">
        <wp-btn-increment-minus [disabled]="quantity <= min" (btnClick)="minusClicked.emit()"></wp-btn-increment-minus>
        <div class="input">
          <mat-form-field style="width: 100%" [hideRequiredMarker]="true">
            <input
              matInput
              tabindex="0"
              autocomplete="off"
              [(ngModel)]="quantity"
              (ngModelChange)="onModelChange()"
              [type]="'text'"
              [mask]="'separator.0'"
              [suffix]="' ' + label"
              [ngModelOptions]="{ updateOn: 'blur' }"
              [allowNegativeNumbers]="true"
              #inputEl
            />
          </mat-form-field>
        </div>
        <wp-btn-increment-plus [disabled]="quantity >= max" (btnClick)="plusClicked.emit()"></wp-btn-increment-plus>
      </div>
      <div class="field-error" *ngIf="quantity < min">Minimum is {{ min }}</div>
      <div class="field-error" *ngIf="quantity > max">Max is {{ max }}</div>
    </div>
  `,
  styles: [
    `
      :host {
        width: 100%;
      }
      .wrap {
        position: relative;
        width: 100%;
      }
      .flex-wrap {
        display: flex;
        align-items: center;
        width: 100%;
      }
      .input {
        position: relative;
        margin: 0 6px;
        overflow: hidden;
      }
      .field-error {
        font-size: 12px;
        color: #d52504;
        position: absolute;
        top: 40px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlusMinusQuantitySelectorComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() public label = '';
  @Input() public quantity: number;
  @Input() public max: number = Number.MAX_SAFE_INTEGER;
  @Input() public min: number = Number.MIN_SAFE_INTEGER;

  @Output() public plusClicked = new EventEmitter<void>();
  @Output() public minusClicked = new EventEmitter<void>();
  @Output() public manualInput = new EventEmitter<number>();

  @ViewChild('inputEl', { read: UntypedFormControl }) private inputEl: any;

  private prev: number;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  onModelChange(): void {
    if (this.quantity !== this.prev) {
      if (this.quantity > this.min && this.quantity < this.max) {
        this.manualInput.emit(this.quantity);
        this.prev = this.quantity;
      }
    }
  }

  ngAfterViewInit(): void {
    console.log(this.inputEl);
  }
}
