import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface SidebarDropdownState {
  isOpen: boolean;
}

const initialState: SidebarDropdownState = {
  isOpen: false,
};

@Component({
  selector: 'wp-side-bar-dropdown',
  templateUrl: './side-bar-dropdown.component.html',
  styleUrls: ['./side-bar-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideBarDropdownComponent implements OnInit {
  public isOpen$: Observable<boolean>;

  private state$: BehaviorSubject<SidebarDropdownState> = new BehaviorSubject<SidebarDropdownState>(initialState);

  constructor() {}

  ngOnInit(): void {
    this.isOpen$ = this.state$.asObservable().pipe(map((s) => s.isOpen));
  }

  public onTriggerClick(): void {
    this.state$.next({
      ...this.state$.value,
      isOpen: !this.state$.value.isOpen,
    });
  }

  public onOutsideClick(): void {
    this.state$.next({
      ...this.state$.value,
      isOpen: false,
    });
  }
}
