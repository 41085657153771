import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { SelectOption } from '../index';

@Component({
  selector: 'wp-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * Supports Validators.required and disabled feature
   */
  @Input() public control: UntypedFormControl;

  @Input() public label: string;
  @Input() public options?: SelectOption[];
  @Input() public compareWith: (optionValue: any, selectedValue: any) => boolean;

  /**
   * These 3 fields should be used together to define the custom options
   * instead of the standard options.
   */
  @Input() public customOptions?: any[];
  @Input() public customOptionValueFn?: (option: any) => any;
  @Input() public customOptionLabelFn?: (option: any) => string;

  // Should be passed when control state (valid, dirty, touched) is updated outside
  @Input() public controlStateChange?: Observable<void>;

  public displayWith = (value: any): string => {
    if (this.compareWith) {
      return this.displayOptions.find((option) => this.compareWith(option.value, value))?.displayLabel;
    } else {
      return this.displayOptions.find((option) => option.value === value)?.displayLabel;
    }
  };

  public autocompleteOptions: SelectOption[];

  private displayOptions: SelectOption[];

  private sub = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.customOptions && !changes.customOptions.isFirstChange()) ||
      (changes.options && !changes.options.isFirstChange())
    ) {
      this.setDisplayOptions();
    }
  }

  public ngOnInit(): void {
    this.setDisplayOptions();

    if (this.controlStateChange) {
      const controlStateSub = this.controlStateChange.subscribe(() => {
        this.cdRef.detectChanges();
      });
      this.sub.add(controlStateSub);
    }
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public setAutocompleteOptions(event: Event): void {
    const { value } = event.target as HTMLInputElement;

    if (value && value !== this.control.value) {
      if (this.compareWith) {
        this.autocompleteOptions = this.displayOptions.filter((option) => {
          return this.compareWith(option.value, value);
        });
      } else {
        this.autocompleteOptions = this.displayOptions.filter((option) => {
          return option.displayLabel.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      this.autocompleteOptions = this.displayOptions;
    }
  }

  private setDisplayOptions(): void {
    if (Array.isArray(this.customOptions)) {
      if (!this.customOptions.length) {
        throw new Error('SelectComponent: [customOptions] must contain at least one option');
      }

      if (typeof this.customOptionValueFn !== 'function') {
        throw new Error('SelectComponent: [customOptionValueFn] is not a function');
      }

      if (typeof this.customOptionLabelFn !== 'function') {
        throw new Error('SelectComponent: [customOptionLabelFn] is not a function');
      }

      this.displayOptions = this.autocompleteOptions = this.customOptions.map((opt) => {
        return {
          displayLabel: this.customOptionLabelFn(opt),
          value: this.customOptionValueFn(opt),
        };
      });

      return;
    }

    this.displayOptions = this.autocompleteOptions = this.options || [];
  }
}
