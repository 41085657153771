import { GetCharterTripResponse } from '../../../api/endpoints/get-charter-trip';
import { GetCharterTripItineraryResponse } from '../../../api/endpoints/get-charter-trip-itinerary';
import { TripStop } from '@apiEntities/trips';

export const getTripDisplayId = (trip: GetCharterTripResponse | undefined): string => trip?.tripDisplayId;

/**
 *Itinerary
 */
export const getItineraryFirstStop = (itinerary: GetCharterTripItineraryResponse | undefined): TripStop | undefined => {
  if (!itinerary) return undefined;
  if (!(itinerary.outbound && itinerary.outbound.stops?.length)) return undefined;
  return itinerary.outbound.stops[0];
};

export const getItineraryLastStop = (itinerary: GetCharterTripItineraryResponse | undefined): TripStop | undefined => {
  if (!itinerary) return undefined;
  if (!(itinerary.outbound && itinerary.outbound.stops?.length)) return undefined;
  return itinerary.outbound.stops[itinerary.outbound.stops.length - 1];
};

export const getItineraryFirstReturnStop = (
  itinerary: GetCharterTripItineraryResponse | undefined,
): TripStop | undefined => {
  if (!itinerary) return undefined;
  if (!(itinerary.return && itinerary.return.stops?.length)) return undefined;
  return itinerary.return.stops[0];
};
