<svg
  xmlns="http://www.w3.org/2000/svg"
  width="16"
  height="16"
  viewBox="0 0 16 16"
>
  <g fill="none" fill-rule="evenodd">
    <g fill="#47A540">
      <g>
        <g>
          <path
            d="M7.494 13.815l-3.849 2.12c-.266.146-.595.039-.735-.24-.055-.11-.075-.238-.054-.362l.735-4.49c.06-.37-.057-.747-.313-1.009L.164 6.654c-.215-.22-.22-.58-.01-.806.084-.09.193-.148.312-.166l4.302-.655c.355-.054.661-.287.82-.624L7.512.318c.133-.282.46-.398.729-.259.107.055.194.147.247.259l1.924 4.085c.159.337.465.57.82.624l4.302.655c.297.045.503.334.46.646-.017.124-.073.239-.158.326l-3.114 3.18c-.256.262-.373.64-.313 1.01l.735 4.49c.051.31-.148.604-.444.658-.118.02-.24 0-.345-.058l-3.849-2.12c-.317-.174-.695-.174-1.012 0z"
            transform="translate(-576 -57) translate(576 54) translate(0 3)"
          />
        </g>
      </g>
    </g>
  </g>
</svg>
