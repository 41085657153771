import { combineReducers } from '@ngrx/store';

import { VendorDataState } from '../../types';
import { vendorDataReducer } from './vendor-data.reducer';

import * as fromLists from './vendor-list.reducer';
import * as fromDetails from './vendor-details.reducer';

export interface VendorStoreState {
  data: VendorDataState;
  lists: fromLists.AllVendorListState;
  details: fromDetails.VendorDetailsState;
}

export const vendorFeatureReducer = combineReducers<VendorStoreState>({
  data: vendorDataReducer,
  lists: fromLists.vendorListReducer,
  details: fromDetails.vendorDetailsReducer,
});
