import { YYYYMMDDString } from '@apiEntities/common';
import { ApiAddress, Weekday } from '../endpoints/common';
import { LocationType } from '../endpoints/get-student-profile';
import { ApiStudentCampusCancellation, ApiStudentTransportationStatus } from '@apiEntities/api-create-edit-student';
import { Address, MapLocation } from '@rootTypes/entities';
import { StudentDetailsRfid } from '@apiEntities/student-rfid-cards';
import { EntityVersion } from '@apiEntities/common/entity-version';

export enum ApiStudentStatus {
  NEW = 'New',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  HOLD = 'Hold',
  NO_STATUS = 'No status',
  REQUIRES_VERIFICATION = 'Requires verification',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export type ExactLocationValue = {
  location: MapLocation;
  label: string;
};

export type AddressLocationType = 'home' | 'bus' | 'business' | 'school';

export type ApiStudentHomeAddress = {
  address: Address | null; // pass null if address is being removed
  isProtectedAddress: boolean;
  isAddressNotKnown: boolean;
};

export type ApiStudent = {
  id?: string;
  version: EntityVersion;
  profile: {
    firstName: string;
    lastName: string;
    districtId: string;
    status: ApiStudentStatus;
    dob?: YYYYMMDDString;
    driverGenderPreference?: Gender;
    phoneNumber?: string;
    studentImagePath?: string;
    email?: string;
    externalNote?: string;
    internalNote?: string;
    externalStudentId?: string;
    homeAddress?: ApiStudentHomeAddress;
    specialNeeds: {
      isSpedStudent: boolean;
      isSpedQuestionnaireCompleted: boolean;
      isAideRequired: boolean;
      fosterProgram: boolean;
      heartProgram: boolean;
      doorToDoorStop: boolean;
      cornerStop: boolean;
      isAttendantRequired: boolean;
    };
    equipmentNeeds: {
      isBoosterSeatRequired: boolean;
      isHarnessRequired: boolean;
      isWalkerSupportRequired: boolean;
      isCarSeatRequired: boolean;
      isWheelchairSupportRequired: boolean;
      isSeatBeltGuardRequired: boolean;
    };
  };
  campuses: ApiStudentCampus[];
  addresses: ApiStudentAddress[];
  guardians: ApiGuardian[];
  rfid?: StudentDetailsRfid;
};

// in case of primary guardian, firstName, lastName, email and phoneNumber will be present.
export type ApiGuardian = {
  id: string;
  isPrimary?: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
};

export type ApiStudentCampus = {
  districtProgramId: string;
  isDefaultProgram: boolean;
  studentToCampusId: string;
  schoolYear: string;
  districtId: string;
  districtName: string;
  campusName: string;
  campusId: string;
  campusLocationId: string;
  grade?: string;
  pickupInstruction?: string;
  dropoffInstruction?: string;
  vendorId?: string;
  yardId?: string;
  externalId?: string;
  externalStudentId: string;
  transportationEffectiveFromDate?: YYYYMMDDString;
  transportationEffectiveToDate?: YYYYMMDDString;
  studentTransportationStatus?: {
    status: ApiStudentTransportationStatus;
    cancelTransportationDetails?: ApiStudentCampusCancellation | null;
  };
};

export type ApiStudentAddress = {
  districtProgramId: string;
  isCurrentProgram: string;
  studentLocationId: string;
  address: ApiAddress;
  type?: LocationType;
  name?: string;
  pickupDropoffLocationAddress?: ExactLocationValue;
  locationType: AddressLocationType;
  pickupDropoffInstruction?: string;
  isUsedForRoutes?: boolean;
  locationSchedule: {
    pickup: {
      [weekday in Weekday]?: boolean;
    };
    dropoff: {
      [weekday in Weekday]?: boolean;
    };
  };
};

export enum StudentProjections {
  ROUTE_READINESS = 'routeReadiness',
  ROUTES = 'routes',
  PARENT_ACCOUNTS = 'parentAccounts',
  PROGRAMS = 'programs',
  PAST_PROGRAMS = 'pastPrograms',
}

export type ApiStudentRouteReadiness = {
  isStudentReady: boolean;
  failedChecks: string[];
};

export type ApiStudentProjectionRoute = {
  name: string;
  route_id: string;
  status: 'ACTIVE' | 'INACTIVE';
};

export type ApiStudentWithProjections = ApiStudent & {
  _projections?: ApiStudentProjections;
};

export type ApiStudentProgram = {
  id: string;
  name: string;
  dateRange: {
    from: YYYYMMDDString;
    to: YYYYMMDDString;
  };
};

export type ApiStudentPastProgram = {
  campus: ApiStudentCampus;
  addresses: ApiStudentAddress[];
  program: ApiStudentProgram;
};

export type ApiStudentProjections = {
  [StudentProjections.ROUTE_READINESS]?: ApiStudentRouteReadiness;
  [StudentProjections.ROUTES]?: ApiStudentProjectionRoute[];
  [StudentProjections.PARENT_ACCOUNTS]?: {
    accounts: StudentParentAccount[];
  };
  [StudentProjections.PROGRAMS]?: {
    associatedDistrictPrograms: ApiStudentProgram[];
  };
  [StudentProjections.PAST_PROGRAMS]?: ApiStudentPastProgram[];
};

export type StudentParentAccountStatus = 'ACTIVE' | 'INACTIVE' | 'BLOCKED';

export interface StudentParentAccount {
  accountId: string;
  contacts: string[];
  verificationAttemptsLeft: number;
  status: StudentParentAccountStatus;
}
