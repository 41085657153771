import { StudentListStatus } from '../../../types/entities/rider/student-list-status';
import { getNumericValueFromString } from '../../../types/utils/common/get-numeric-value-from-string';
import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';

export interface StudentListRouteData {
  listStatus?: StudentListStatus;
  studentSearchQuery?: string;
  currentPage?: number;
  pageSize?: number;
}

export const studentList: PortalRoute<StudentListRouteData> = {
  path: 'list',
  isOnRoute: (state: RouterStateUrl) => {
    return state.url.startsWith('/students/list');
  },
  parse: (state: RouterStateUrl): StudentListRouteData => {
    const res = {} as StudentListRouteData;
    const { queryParams } = state;
    if (queryParams) {
      res.listStatus = queryParams.listStatus;
      res.currentPage = getNumericValueFromString(queryParams.currentPage);
      res.pageSize = getNumericValueFromString(queryParams.pageSize);
      res.studentSearchQuery = queryParams.studentSearchQuery;
    }
    return res;
  },
  request: (data: StudentListRouteData, queryParamStrategy: QueryParamsHandlingStrategy): NavigationRequest => {
    // do not mutate the original arg
    const copy = { ...data };

    const res = {
      path: ['students', 'list'],
    } as NavigationRequest;
    // extract additional data fields as query params
    if (Object.keys(data).length) {
      res.query = { ...data };
    }
    if (queryParamStrategy) {
      res.extras = {
        queryParamsHandling: queryParamStrategy,
      };
    }
    return res;
  },
  getHref(data?: StudentListRouteData): string {
    return '/students/list';
  },
};
@Injectable({
  providedIn: 'root',
})
export class StudentListRouterService extends PortalRouterService<StudentListRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route = studentList;
}
