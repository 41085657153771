import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { rideDetailsDrawer, RideDetailsRouteData } from './ride-details-drawer';
import { RouterOutlets } from '@rootTypes/entities/router';

@Injectable({
  providedIn: 'root',
})
export class RidesDetailsDrawerRouterService extends PortalRouterService<RideDetailsRouteData> {
  outlet = RouterOutlets.DRAWER;
  route = rideDetailsDrawer;
}
