<div class="outer" #outer>
  <div
    class="inner"
    [ngClass]="{ 'shrink': shouldShrinkToContent }"
    #inner
    wpWidthChanged
    (widthChanged)="onWidthChanged($event)"
  >
    <ng-content></ng-content>
  </div>
</div>
