import { createAction, props } from '@ngrx/store';
import { EntityFilterVehicleInspectionsResponse } from '../../../../api/endpoints/entity-filter';
import { WpError, YYYYMMDDString } from '@rootTypes';
import {
  VehicleInspectionAssignedMechanicStatusFilter,
  VehicleInspectionDriverReviewStatus,
  VehicleInspectionMechanicAssessmentStatus,
  VehicleInspectionStatus,
} from '@apiEntities';
import {
  VehicleInspectionListAdvancedFilters,
  VehicleInspectionListName,
  VehicleInspectionQuickFilter,
} from '../../types';
import { VehicleInspectionDetails } from '../../../../api/endpoints/get-vehicle-inspection';
import { VehicleInspectionListRouterData } from '../../../../router/portal-routes/vehicle-inspections';
import { MissingVehicleInspection } from '../../../../api/endpoints/get-missing-vehicle-inspections';

const inspectionsComponent = '[VehicleInspectionList]:';
const inspectionsEffects = '[VehicleInspectionsEffects]:';
export const initVehicleInspectionList = createAction(
  `${inspectionsComponent} Initialize`,
  props<{ listName: VehicleInspectionListName; vehicleId: string; vehicleDisplayId: string }>(),
);
export const initDriverInspectionList = createAction(
  `${inspectionsComponent} Initialize inspections by driver list`,
  props<{ listName: VehicleInspectionListName; driverId: string }>(),
);

const mainList = '[VehicleInspectionMainList]:';
export const initVehicleInspectionMainList = createAction(
  `${mainList} Initialize`,
  props<{ routerData: VehicleInspectionListRouterData; selectedVehicleInspectionId?: string }>(),
);
export const destroyVehicleInspectionMainList = createAction(`${mainList} Destroy`);

export const clearAllFilters = createAction(`${mainList} Clear all filters`);

export const addVehicleFilter = createAction(
  `${mainList} Add vehicle filter`,
  props<{ vehicle: { vehicleId: string; vehicleDisplayId: string } }>(),
);
export const removeVehicleFilter = createAction(
  `${mainList} Remove vehicle filter`,
  props<{ vehicle: { vehicleId: string; vehicleDisplayId: string } }>(),
);
export const addDriverFilter = createAction(`${mainList} Add driver filter`, props<{ driverId: string }>());
export const removeDriverFilter = createAction(`${mainList} Remove driver filter`, props<{ driverId: string }>());

export const loadVehicleInspectionListRequested = createAction(
  `${inspectionsEffects} Load list requested`,
  props<{ listName: VehicleInspectionListName }>(),
);
export const loadVehicleInspectionListSuccess = createAction(
  `${inspectionsEffects} Load list success`,
  props<{ listName: VehicleInspectionListName; response: EntityFilterVehicleInspectionsResponse }>(),
);
export const loadVehicleInspectionListFailed = createAction(
  `${inspectionsEffects} Load list failed`,
  props<{ listName: VehicleInspectionListName; error: WpError }>(),
);

export const vehicleInspectionsPageChanged = createAction(
  `${inspectionsComponent} Page changed`,
  props<{ listName: VehicleInspectionListName; pageIndex: number }>(),
);
export const vehicleInspectionsPageSizeChanged = createAction(
  `${inspectionsComponent} Page size changed`,
  props<{ listName: VehicleInspectionListName; pageSize: number }>(),
);
export const vehicleInspectionsDateRangeChanged = createAction(
  `${inspectionsComponent} Date range changed`,
  props<{ listName: VehicleInspectionListName; dateRange?: { from: YYYYMMDDString; to: YYYYMMDDString } }>(),
);
export const vehicleInspectionsSingleStatusChanged = createAction(
  `${inspectionsComponent} Single status changed`,
  props<{ listName: VehicleInspectionListName; status?: VehicleInspectionStatus }>(),
);

export const getVehicleInspectionRequested = createAction(
  `${inspectionsEffects} Get inspection requested`,
  props<{ vehicleInspectionId: string }>(),
);
export const getVehicleInspectionSuccess = createAction(
  `${inspectionsEffects} Get inspection success`,
  props<{ vehicleInspectionId: string; data: VehicleInspectionDetails }>(),
);
export const getVehicleInspectionFailed = createAction(
  `${inspectionsEffects} Get inspection failed`,
  props<{ vehicleInspectionId: string; error: WpError }>(),
);

export const reloadVehicleInspectionDetailsOnAssignmentUpdate = createAction(
  '[MechanicAssignmentService]: Reload inspection details',
  props<{ inspectionIds: string[] }>(),
);

export const updateVehicleInspectionDetailsOnUserSubmit = createAction(
  '[InspectionDetailsStore]: Update inspection details on user submit',
  props<{ vehicleInspectionId: string; data: VehicleInspectionDetails }>(),
);

export const selectedVehicleInspectionChanged = createAction(
  `${mainList} Selected vehicle inspection changed`,
  props<{ selectedVehicleInspectionId?: string }>(),
);

export const removeAssignedMechanicFilter = createAction(
  `${mainList} Remove assigned mechanic filter`,
  props<{ mechanicId: string }>(),
);
export const removeMechanicAssignmentStatusFilter = createAction(
  `${mainList} Remove mechanic assignment status filter`,
  props<{ value: VehicleInspectionAssignedMechanicStatusFilter }>(),
);
export const removeMechanicAssessmentStatusFilter = createAction(
  `${mainList} Remove mechanic assessment status filter`,
  props<{ value: VehicleInspectionMechanicAssessmentStatus }>(),
);
export const removeDriverReviewStatusFilter = createAction(
  `${mainList} Remove driver review status filter`,
  props<{ value: VehicleInspectionDriverReviewStatus }>(),
);
export const removeInspectionStatusFilter = createAction(
  `${mainList} Remove inspection status filter`,
  props<{ value: VehicleInspectionStatus }>(),
);

export const setAdvancedFilters = createAction(
  '[MainListFilters]: Set advanced filters',
  props<{ filters: VehicleInspectionListAdvancedFilters }>(),
);

const inspectionDetails = '[VehicleInspectionDetails]:';
export const vehicleInspectionDetailsOpened = createAction(
  `${inspectionDetails} Opened`,
  props<{ selectedVehicleInspectionId: string }>(),
);
export const vehicleInspectionDetailsClosed = createAction(`${inspectionDetails} Closed`);

export const inspectionQuickFilterChanged = createAction(
  `${mainList} Quick filter changed`,
  props<{ value: VehicleInspectionQuickFilter }>(),
);
export const removeInspectionQuickFilter = createAction(`${mainList} Remove inspections quick filter`);

export const loadMissingInspectionsRequested = createAction(`${mainList} Load missing inspections requested`);
export const loadMissingInspectionsSuccess = createAction(
  `${mainList} Load missing inspections success`,
  props<{ data: MissingVehicleInspection[] }>(),
);
export const loadMissingInspectionsFailed = createAction(
  `${mainList} Load missing inspections failed`,
  props<{ error: WpError }>(),
);

export const selectedMissingInspectionChanged = createAction(
  `${mainList} Selected missing inspection changed`,
  props<{ selectedMissingInspectionDriverId?: string }>(),
);
export const missingInspectionDriverDetailsClosed = createAction(
  `${inspectionsEffects} Missing inspection driver details closed`,
);
