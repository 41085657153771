import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { iconPaths } from '@rootTypes/utils';

interface FollowUpItem {
  label: string;
  completed?: boolean;
}

@Component({
  selector: 'wp-followup-actions-popup',
  templateUrl: './followup-actions-popup.component.html',
  styleUrls: ['./followup-actions-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FollowupActionsPopupComponent implements OnInit {
  @Input() public items: FollowUpItem[];

  public checkIconPath = iconPaths.CHECKED_CIRCLE_LARGE;

  constructor() {}

  ngOnInit(): void {}
}
