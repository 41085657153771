import { createSelector } from '@ngrx/store';
import { getVendorIdByYard } from './yard-data.selectors';
import { getAllVendorEntities } from '../../../vendors/store/selectors';

export const getVendorEntityStateByYard = createSelector(
  getVendorIdByYard,
  getAllVendorEntities,
  (vendorId, allVendorEntities) => {
    if (vendorId) {
      return allVendorEntities[vendorId];
    }
    return undefined;
  },
);

export const getVendorByYard = createSelector(getVendorEntityStateByYard, (state) => state?.entity);
