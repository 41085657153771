import { createAction, props } from '@ngrx/store';
import {
  GetStudentChangeRequestSummaryResponse,
  GetProcessedStudentChangeRequestResponse,
  WpError,
} from '../../dependencies';

const processedRequestDetails = '[StudentChangeRequestProcessedDetails]';

export const initStudentChangeRequestProcessedDetails = createAction(
  `${processedRequestDetails}: Init state`,
  props<{ studentChangeRequestId: string }>(),
);
export const destroyStudentChangeRequestProcessedDetails = createAction(`${processedRequestDetails}: Destroy state`);

export const loadProcessedStudentChangeRequestRequested = createAction(
  `${processedRequestDetails}: Load processed change request requested`,
  props<{ studentChangeRequestId: string }>(),
);
export const loadProcessedStudentChangeRequestSuccess = createAction(
  `${processedRequestDetails}: Load processed change request success`,
  props<{ response: GetProcessedStudentChangeRequestResponse }>(),
);
export const loadProcessedStudentChangeRequestFailed = createAction(
  `${processedRequestDetails}: Load processed change request failed`,
  props<{ error: WpError }>(),
);

export const loadProcessedStudentChangeRequestSummaryRequested = createAction(
  `${processedRequestDetails}: Load request summary requested`,
  props<{ studentChangeRequestId: string }>(),
);
export const loadProcessedStudentChangeRequestSummarySuccess = createAction(
  `${processedRequestDetails}: Load request summary success`,
  props<{ response: GetStudentChangeRequestSummaryResponse }>(),
);
export const loadProcessedStudentChangeRequestSummaryFailed = createAction(
  `${processedRequestDetails}: Load request summary failed`,
  props<{ error: WpError }>(),
);
