import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import { AbstractPopupComponent } from 'src/app/core/popupable/types';
import { PopupRef } from 'src/app/core/popupable/types/popup-ref';
import * as fromApi from 'src/app/api';
import * as fromSelectors from 'src/app/auth/store/selectors';
import * as fromActions from 'src/app/auth/store/actions';
import { AccountState } from 'src/app/auth/store';
import { loadAccounts } from 'src/app/auth/utils';

interface AccountSelection {
  accountId?: number | string;
  subAccountIds?: (number | string)[];
}

@Component({
  selector: 'wp-switch-account-popup',
  templateUrl: './switch-account-popup.component.html',
  styleUrls: ['./switch-account-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchAccountPopupComponent implements OnInit, AbstractPopupComponent<any, any> {
  userName$: Observable<string>;
  userInitials$: Observable<string>;
  userPhotoBase64$: Observable<string>;

  accounts$: Observable<fromApi.account.UserAccount[]>;
  isSwitchAccountLoading$: Observable<boolean>;
  initialAccountId: string | number;
  initialSubAccountId?: string | number;
  initialAccountIds: string;
  isSubAccountRequired: boolean;
  popupRef: PopupRef<any, any>;

  constructor(private store: Store<AccountState>) {}

  public ngOnInit(): void {
    this.userName$ = this.store.select(fromSelectors.currentUserFullName);
    this.userInitials$ = this.store.select(fromSelectors.currentUserInitials);
    this.userPhotoBase64$ = this.store.select(fromSelectors.currentUserPhotoBase64);

    this.accounts$ = loadAccounts(this.store, fromActions.switchAccountPopupAccountsRequested).pipe(take(1));

    this.store
      .select(fromSelectors.selectedAccount)
      .pipe(
        filter((account) => !!account),
        take(1),
        tap((account) => {
          this.initialAccountIds = this.accountSelectionToIdString(account);
          this.initialAccountId = account.accountId;
          this.isSubAccountRequired = account.isSubAccountsRequired;
          if (account.subAccountIds) {
            this.initialSubAccountId = account.subAccountIds[0];
          }
        }),
      )
      .subscribe();

    this.isSwitchAccountLoading$ = this.store.select(fromSelectors.isSetAccountLoading);
  }

  public onClosed(): void {
    this.popupRef.close();
  }

  public switchAccount(request: fromApi.account.SetAccountRequest): void {
    const newAccountIds = this.accountSelectionToIdString(request);
    if (newAccountIds === this.initialAccountIds) {
      this.popupRef.close();
      return;
    }

    this.store.dispatch(fromActions.switchAccountPopupSetAccountRequested({ request }));

    this.store
      .select(fromSelectors.setAccountState)
      .pipe(
        filter((state) => !state.isLoading),
        take(1),
        tap((state) => {
          if (!state.error) {
            this.popupRef.close();
          }
        }),
      )
      .subscribe();
  }

  private accountSelectionToIdString(value: AccountSelection): string {
    let result = '';
    if (typeof value.accountId === 'number' || typeof value.accountId === 'string') {
      result += value.accountId;
    }
    if (value.subAccountIds) {
      const subAccount = value.subAccountIds[0];
      if (typeof subAccount === 'number' || typeof subAccount === 'string') {
        result += `/${value.subAccountIds[0]}`;
      }
    }
    return result;
  }
}
