import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { UserProfileInfoFacade } from '../../store';

@Component({
  selector: 'wp-user-profile-info',
  templateUrl: './user-profile-info.component.html',
  styleUrls: ['./user-profile-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserProfileInfoFacade],
})
export class UserProfileInfoComponent implements OnInit {
  @Input() editDisabled: boolean;

  public fullName$: Observable<string>;
  public email$: Observable<string>;
  public phone$: Observable<string>;
  public isEditBtnShown = false;

  constructor(private facade: UserProfileInfoFacade) {}

  public ngOnInit(): void {
    this.fullName$ = this.facade.getUserFullName$();
    this.email$ = this.facade.getUserEmail$();
    this.phone$ = this.facade.getUserPhone$();
  }

  public onEdit(): void {
    if (!this.editDisabled) {
      this.facade.goProfileInfoEdit();
    }
  }
}
