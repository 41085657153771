import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { EntityState, WpError } from '@rootTypes/entities/common';
import { GetDriverBusReportResponse } from '../../../api/endpoints/get-driver-bus-report';
import { Injectable } from '@angular/core';
import { ApiService } from '../../../api/api.service';
import { FlashApiService } from '../../../api/flash-api.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs';

interface DriverBusReportDataState {
  reports: {
    [reportId: string]: EntityState<GetDriverBusReportResponse>;
  };
}

const getInitialDriverBusReportDataState = (): DriverBusReportDataState => {
  return {
    reports: {},
  };
};

const getInitialEntityState = (): EntityState<any> => {
  return { isLoading: false };
};

@Injectable({ providedIn: 'root' })
export class DriverBusReportDataStore extends ComponentStore<DriverBusReportDataState> {
  // selectors
  private getEntityState = (reportId: string) => this.select((state) => state.reports[reportId]);
  public getDriverBusReport$ = (reportId: string) =>
    this.select(this.getEntityState(reportId), (state) => state?.entity);
  public isDriverBusReportLoading$ = (reportId) =>
    this.select(this.getEntityState(reportId), (state) => state?.isLoading);
  public getDriverBusReportLoadError$ = (reportId) =>
    this.select(this.getEntityState(reportId), (state) => state?.error);
  public getDriverBusReportStatus$ = (reportId) =>
    this.select(this.getDriverBusReport$(reportId), (state) => state?.reportStatus);

  // updaters
  private setIsLoading = this.updater((state, reportId: string) => {
    return {
      ...state,
      reports: {
        ...state.reports,
        [reportId]: { ...(state.reports[reportId] || getInitialEntityState()), isLoading: true },
      },
    };
  });
  private setIsLoaded = this.updater((state, report: GetDriverBusReportResponse) => {
    const reportId = report.driverBusReportId;
    return {
      ...state,
      reports: {
        ...state.reports,
        [reportId]: { ...(state.reports[reportId] || getInitialEntityState()), isLoading: false, entity: report },
      },
    };
  });
  private setLoadError = this.updater((state, p: { reportId: string; error: WpError }) => {
    const { reportId, error } = p;
    return {
      ...state,
      reports: {
        ...state.reports,
        [reportId]: { ...(state.reports[reportId] || getInitialEntityState()), isLoading: false, error },
      },
    };
  });

  // effects
  private readonly _onLoadDriverBusReport = this.effect((origin$: Observable<string>) =>
    origin$.pipe(
      mergeMap((reportId: string) => {
        this.setIsLoading(reportId);
        return this.api.getDriverBusReport({ driverBusReportId: reportId }).pipe(
          tapResponse(
            (resp) => {
              this.setIsLoaded(resp);
            },
            (originalError) => {
              console.log(originalError);
              const error: WpError = {
                text: 'Failed to load driver bus report',
                originalError,
                retryFn: () => this._onLoadDriverBusReport(reportId),
              };
              this.setLoadError({ reportId, error });
            },
          ),
        );
      }),
    ),
  );
  private unsubscribeDBRList = new Subject();
  private subscribeDBRList = this.effect((origin: Observable<string[]>) => {
    return origin.pipe(
      switchMap((reportIds) => {
        return this.flashApi.subscribeToDBRList(reportIds).pipe(
          map(({ result }) => {
            if (result) {
              this.setIsLoaded(result);
            }
          }),
          catchError((originalError) => {
            console.log('Error while listening for driver bus report updates');
            console.log(originalError);
            return of(originalError);
          }),
          takeUntil(this.unsubscribeDBRList.asObservable()),
        );
      }),
    );
  });
  constructor(
    private api: ApiService,
    private flashApi: FlashApiService,
  ) {
    super(getInitialDriverBusReportDataState());
  }

  public onLoadDBR(reportId: string): void {
    this._onLoadDriverBusReport(reportId);
  }

  public onSubscribeDBRList(reportIds: string[]): void {
    this.subscribeDBRList(reportIds);
  }

  public onUnsubscribeDBRList(): void {
    this.unsubscribeDBRList.next(undefined);
  }
}
