<div class="ic-school-brand">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="25"
    viewBox="0 0 28 25"
  >
    <g fill="none" fill-rule="evenodd">
      <g stroke="#47A540">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      stroke-width="2"
                      d="M2.167 3.333H23.5V18.833H2.167z"
                      transform="translate(-534 -220) translate(524 36) translate(0 40) translate(0 134) translate(10 8) translate(1.167 3.5)"
                    />
                    <path
                      d="M11 9.833H14.667V18.166H11zM5.167 9.833H8.834V13.5H5.167zM16.833 9.833H20.5V13.5H16.833z"
                      transform="translate(-534 -220) translate(524 36) translate(0 40) translate(0 134) translate(10 8) translate(1.167 3.5)"
                    />
                    <path
                      stroke-width="2"
                      d="M4.667 0H21V3.5H4.667zM0 18.667H25.667V22.167H0z"
                      transform="translate(-534 -220) translate(524 36) translate(0 40) translate(0 134) translate(10 8) translate(1.167 3.5)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</div>
