import { GetRouteResponse } from '../get-route';

export const getRiderDowSelection = (
  source: GetRouteResponse,
): { [riderId: string]: GetRouteResponse['riders'][string]['dowSelection'] } => {
  const target = {};
  if (source.riders) {
    Object.keys(source.riders).forEach((riderId) => {
      const rider = source.riders[riderId];
      if (rider.dowSelection) {
        target[riderId] = rider.dowSelection;
      }
    });
  }
  return target;
};
