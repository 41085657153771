import { Injectable } from '@angular/core';
import { RouterOutlets } from '@rootTypes/entities/router';
import { PortalRouterService } from '../../types/portal-router-service';
import { createPortalRoute } from '../../utils/createPortalRoute';

export type VehicleMaintenanceStatusManagerRouteData = {
  vehicleId: string;
};

export const vehicleMaintenanceStatusManagerPath = 'manage-maintenance-status';

export const vehicleMaintenanceStatusManagerRoute = createPortalRoute<VehicleMaintenanceStatusManagerRouteData>({
  path: ':vehicleId',
  prefix: vehicleMaintenanceStatusManagerPath,

  outlet: RouterOutlets.DRAWER,
});

@Injectable({ providedIn: 'root' })
export class VehicleMaintenanceStatusManagerRouterService extends PortalRouterService<VehicleMaintenanceStatusManagerRouteData> {
  public readonly route = vehicleMaintenanceStatusManagerRoute;
}
