import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../../actions/campus-details/campus-details.actions';
import * as fromSelectors from '../../selectors/campus-details/campus-details.selectors';
import * as fromTypes from '../../../types';
import * as fromRoutes from '../../../routes';
import * as fromCampusData from '../../actions/campus-data.actions';
import * as fromLocationsData from '../../actions/campus-location-data.actions';
import { debounceTime, distinctUntilChanged, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { drawerClosedSelected, openErrorPopup, State } from '@rootStore';
import { Store } from '@ngrx/store';
import { SnackbarService } from '../../../../../shared/snackbar/snackbar.service';

@Injectable()
export class CampusDetailsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private snackbar: SnackbarService,
    private campusEditorRouter: fromRoutes.CampusEditorRouterService,
  ) {}

  public campusIdChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setCampusId),
      filter((a) => !!a.campusId),
      map((a) => fromCampusData.loadCampusDetailsRequested({ campusId: a.campusId, options: { withDistrict: true } })),
    ),
  );

  public campusIdChangedForCleanState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setCampusId),
      filter((a) => !!a.campusId),
      map((a) => a.campusId),
      distinctUntilChanged(),
      map((a) => fromActions.cleanCampusDetails()),
    ),
  );

  /**
   * Campus tab changed
   */
  // on tab change to calendars, wait until campus is there and load calendars for it
  // public campusTabChangedForCalendars$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromActions.setTab),
  //     filter((action) => action.tab === fromTypes.CampusDetailsTab.CALENDARS),
  //     debounceTime(200),
  //     switchMap((action) => {
  //       return this.store.select(fromSelectors.getCampus).pipe(
  //         filter((c) => !!c),
  //         take(1),
  //         map((campus) =>
  //           fromCalendarData.loadCampusCalendarsRequested({ campusId: campus.id, districtId: campus.districtId }),
  //         ),
  //       );
  //     }),
  //   ),
  // );

  // on tab change to calendars, wait until campus is there and load calendars for it
  public campusTabChangedForLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setTab),
      filter((action) => action.tab === fromTypes.CampusDetailsTab.LOCATIONS),
      debounceTime(200),
      switchMap((action) => {
        return this.store.select(fromSelectors.getCampus).pipe(
          filter((c) => !!c),
          take(1),
          map((campus) =>
            fromLocationsData.getCampusLocationsRequested({ campusId: campus.id, districtId: campus.districtId }),
          ),
        );
      }),
    ),
  );

  /**
   * Remove campus requested
   */
  public removeCampusClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeCampusClicked),
      mergeMap(() => {
        return this.store.select(fromSelectors.getCampus).pipe(
          filter((c) => !!c),
          take(1),
          map((campus) => fromCampusData.removeCampusRequested({ campusId: campus.id, districtId: campus.districtId })),
        );
      }),
    ),
  );

  public editCampusClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.editCampusClicked),
        switchMap(() => {
          return this.store.select(fromSelectors.getCampusId).pipe(
            take(1),
            tap((campusId) => {
              this.campusEditorRouter.navigate({
                campusId,
                createCampusDisableDistrict: true,
              });
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  public removeCampusSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampusData.removeCampusSuccess),
      map(() => {
        this.snackbar.success('Successfully removed campus');
        return drawerClosedSelected();
      }),
    ),
  );

  public removeCampusFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampusData.removeCampusFailed),
      map((action) => {
        return openErrorPopup({ error: action.error });
      }),
    ),
  );
}
