<svg
  *ngIf="!green"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <g fill="none" fill-rule="evenodd">
    <g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    stroke="#979797"
                    stroke-width="2"
                    d="M1 8.53V22h14V5.688L1 8.53z"
                    transform="translate(-130 -367) translate(114 126) translate(0 85) translate(0 144) translate(16 12) translate(0 1)"
                  />
                  <path
                    stroke="#979797"
                    stroke-width="2"
                    d="M4 11.5H12V14.5H4zM6 17.5H10V22H6z"
                    transform="translate(-130 -367) translate(114 126) translate(0 85) translate(0 144) translate(16 12) translate(0 1)"
                  />
                  <path
                    stroke="#979797"
                    stroke-width="2"
                    d="M9.754 5.896L9.754 0.512 23 3.143 23 22 7 22"
                    transform="translate(-130 -367) translate(114 126) translate(0 85) translate(0 144) translate(16 12) translate(0 1)"
                  />
                  <path
                    fill="#959597"
                    d="M17 7H21V9H17zM17 11H21V13H17zM17 15H21V17H17z"
                    transform="translate(-130 -367) translate(114 126) translate(0 85) translate(0 144) translate(16 12) translate(0 1)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>

<svg
  *ngIf="green"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <g fill="none" fill-rule="evenodd">
    <g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      stroke="#47A540"
                      stroke-width="2"
                      d="M1 8.53V22h14V5.688L1 8.53z"
                      transform="translate(-680 -367) translate(662 126) translate(0 85) translate(0 144) translate(18 12) translate(0 1)"
                    />
                    <path
                      stroke="#47A540"
                      stroke-width="2"
                      d="M4 11.5H12V14.5H4zM6 17.5H10V22H6z"
                      transform="translate(-680 -367) translate(662 126) translate(0 85) translate(0 144) translate(18 12) translate(0 1)"
                    />
                    <path
                      stroke="#47A540"
                      stroke-width="2"
                      d="M9.754 5.896L9.754 0.51 23 3.143 23 22 7 22"
                      transform="translate(-680 -367) translate(662 126) translate(0 85) translate(0 144) translate(18 12) translate(0 1)"
                    />
                    <path
                      fill="#47A540"
                      d="M17 7H21V9H17zM17 11H21V13H17zM17 15H21V17H17z"
                      transform="translate(-680 -367) translate(662 126) translate(0 85) translate(0 144) translate(18 12) translate(0 1)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
