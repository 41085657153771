import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import { Observable } from 'rxjs';
import * as fromTypes from '../../types';
import * as fromSelectors from '../selectors/campus-data.selectors';
import * as fromActions from '../actions/campus-data.actions';

@Injectable()
export class CampusDataFacade {
  constructor(private store: Store<State>) {}

  public getAllCampusChecks$(): Observable<fromTypes.Check[]> {
    return this.store.select(fromSelectors.getAllCampusChecks);
  }

  public getAllCampusChecksLoading$(): Observable<boolean> {
    return this.store.select(fromSelectors.getAllCampusChecksLoading);
  }

  public getAllCampusChecksError$(): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getAllCampusChecksError);
  }

  public getCampus$(campusId: string): Observable<fromTypes.Campus> {
    return this.store.select(fromSelectors.getCampus(campusId));
  }

  public getCampusRemoved$(campusId: string): Observable<boolean> {
    return this.store.select(fromSelectors.getCampusRemoved(campusId));
  }

  public getCampusLoading$(campusId: string): Observable<boolean> {
    return this.store.select(fromSelectors.getCampusLoading(campusId));
  }

  public getCampusError$(campusId: string): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getCampusError(campusId));
  }

  public onLoadCampus(campusId: string): void {
    this.store.dispatch(fromActions.loadCampusDetailsRequested({ campusId }));
  }

  public onLoadCampusFromCampusEditor(campusId: string): void {
    this.store.dispatch(fromActions.loadCampusDetailsRequested({ campusId, options: { withDistrict: true } }));
  }

  public onLoadAllChecksForCampuses(): void {
    this.store.dispatch(fromActions.loadAllChecksForCampusesRequested());
  }
}
