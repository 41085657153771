import { EntityState } from '@rootTypes';
import { RouteGroupOwnerHistory } from '@apiEntities/route-group/route-group-owner-history';
import { createReducer, on } from '@ngrx/store';
import {
  getRouteGroupOwnerHistoryFailed,
  getRouteGroupOwnerHistorySuccess,
  loadRouteGroupOwnerHistoryActions,
} from '../actions/route-group-owner-history.actions';

export interface RouteGroupOwnerHistoryState {
  ownerHistory: {
    [routeGroupId: string]: EntityState<RouteGroupOwnerHistory[]>;
  };
}

export const getInitialRouteGroupOwnerHistoryState = (): RouteGroupOwnerHistoryState => {
  return {
    ownerHistory: {},
  };
};

export const routeGroupOwnerHistoryReducer = createReducer<RouteGroupOwnerHistoryState>(
  getInitialRouteGroupOwnerHistoryState(),
  on(...loadRouteGroupOwnerHistoryActions, (state, action) => {
    const { routeGroupId } = action;
    const prevState = state.ownerHistory[routeGroupId] || { isLoading: false };
    return {
      ...state,
      ownerHistory: {
        ...state.ownerHistory,
        [routeGroupId]: {
          ...prevState,
          isLoading: true,
        },
      },
    };
  }),
  on(getRouteGroupOwnerHistorySuccess, (state, action) => {
    const { routeGroupId, ownersHistory } = action;
    const prevState = state.ownerHistory[routeGroupId];
    return {
      ...state,
      ownerHistory: {
        ...state.ownerHistory,
        [routeGroupId]: {
          ...prevState,
          isLoading: false,
          entity: ownersHistory,
        },
      },
    };
  }),
  on(getRouteGroupOwnerHistoryFailed, (state, action) => {
    const { routeGroupId, error } = action;
    const prevState = state.ownerHistory[routeGroupId];
    return {
      ...state,
      ownerHistory: {
        ...state.ownerHistory,
        [routeGroupId]: {
          ...prevState,
          isLoading: false,
          error,
        },
      },
    };
  }),
);
