<div style="width: 14px; height: 14px">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <g fill="#959597" fill-rule="evenodd">
      <path
        d="M1.506 8.208c-.446-.446-.447-1.169-.002-1.614L7.165.933C7.61.488 8.33.485 8.78.935c.447.446.448 1.169.002 1.614L3.121 8.21c-.446.445-1.165.447-1.615-.002z"
        transform="matrix(-1 0 0 1 16 4.286)"
      />
      <path
        d="M14.494 8.208c-.447.446-1.17.447-1.615.002L7.22 2.55c-.446-.446-.448-1.166.002-1.615C7.667.489 8.389.487 8.835.933l5.66 5.66c.446.446.448 1.166-.001 1.615z"
        transform="matrix(-1 0 0 1 16 4.286)"
      />
    </g>
  </svg>
</div>
