import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-common-popup-page-body',
  templateUrl: './common-popup-page-body.component.html',
  styleUrls: ['./common-popup-page-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonPopupPageBodyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
