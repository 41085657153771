<div class="wp-ce-search-option">
  <div class="left">
    <div class="icon">
      <div *ngIf="entity?.type === entityTypes.DRIVER" class="icon-box driver">
        <wp-icon-drivers-green></wp-icon-drivers-green>
      </div>
      <div
        *ngIf="entity?.type === entityTypes.STUDENT"
        class="icon-box student"
      >
        <wp-icon-students-green></wp-icon-students-green>
      </div>
      <div
        *ngIf="entity?.type === entityTypes.DISTRICT"
        class="icon-box district"
      >
        <wp-ic-district-green></wp-ic-district-green>
      </div>
      <div *ngIf="entity?.type === entityTypes.PARENT" class="icon-box">
        <wp-icon-parent-brand></wp-icon-parent-brand>
      </div>
      <div *ngIf="entity?.type === entityTypes.CAMPUS" class="icon-box">
        <wp-icon-campus-brand></wp-icon-campus-brand>
      </div>
      <div *ngIf="entity?.type === entityTypes.SCHOOL" class="icon-box">
        <wp-icon-school-green></wp-icon-school-green>
      </div>
    </div>
    <div class="label">{{ entity?.label }}</div>
  </div>
  <div class="display-type">{{ entity?.type | displayEntityType }}</div>
</div>
