import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-side-bar-icon-wrap',
  templateUrl: './side-bar-icon-wrap.component.html',
  styleUrls: ['./side-bar-icon-wrap.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideBarIconWrapComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
