import { createReducer, on } from '@ngrx/store';
import * as fromTypes from '@rootTypes';
import * as fromActions from '../actions/student-data.actions';
import { cleanUpStore } from '../../types';
import { ApiStudent, ApiStudentProjections } from '@apiEntities';

export interface StudentDataState {
  students: {
    [studentId: string]: StudentItemState;
  };
  projections: {
    [studentId: string]: ApiStudentProjections;
  };
  studentsVerificationStatuses: {
    [studentId: string]: {
      isLoading: boolean;
      entity: fromTypes.rider.VerificationStatus[];
      error: fromTypes.WpError;
    };
  };
}

export interface StudentItemState {
  entity: ApiStudent;
  isLoading: boolean;
  error: fromTypes.WpError;
}

export const createInitialStudentDataState = (): StudentDataState => {
  return {
    students: {},
    projections: {},
    studentsVerificationStatuses: {},
  };
};

export const studentDataReducer = createReducer(
  createInitialStudentDataState(),
  on(cleanUpStore, createInitialStudentDataState),
  // load student details
  on(...fromActions.loadStudentDetailsActions, (state, action) => {
    const { studentId } = action;
    const currentStudentState = state.students[studentId] || {
      entity: null,
      isLoading: false,
      error: null,
    };
    const resultState: StudentDataState = {
      ...state,
      students: {
        ...state.students,
        [studentId]: {
          ...currentStudentState,
          isLoading: !currentStudentState.entity,
        },
      },
    };
    return resultState;
  }),
  on(fromActions.loadStudentDetailsSuccess, (state, action) => {
    const { studentId, data } = action;
    const currentStudentState = state.students[studentId] || {
      entity: null,
      isLoading: false,
      error: null,
    };
    let resultState: StudentDataState = {
      ...state,
      students: {
        ...state.students,
        [studentId]: {
          ...currentStudentState,
          isLoading: false,
          entity: data,
          error: null,
        },
      },
    };
    if (data._projections) {
      resultState = {
        ...resultState,
        projections: {
          ...resultState.projections,
          [studentId]: {
            ...(resultState.projections[studentId] || {}),
            ...data._projections,
          },
        },
      };
    }
    return resultState;
  }),
  on(fromActions.loadStudentDetailsFailed, (state, action) => {
    const { studentId, error } = action;
    const currentStudentState = state.students[studentId] || {
      entity: null,
      isLoading: false,
      error: null,
    };
    const resultState: StudentDataState = {
      ...state,
      students: {
        ...state.students,
        [studentId]: {
          ...currentStudentState,
          isLoading: false,
          error,
        },
      },
    };
    return resultState;
  }),
  // fetch verification statuses
  on(fromActions.getStudentVerificationStatusRequested, (state, action) => {
    const { studentId } = action;
    const currentStudentVerificationState = state.studentsVerificationStatuses[studentId] || {
      entity: null,
      isLoading: false,
      error: null,
    };
    const resultState: StudentDataState = {
      ...state,
      studentsVerificationStatuses: {
        ...state.studentsVerificationStatuses,
        [studentId]: {
          ...currentStudentVerificationState,
          isLoading: true,
        },
      },
    };
    return resultState;
  }),
  on(fromActions.getStudentVerificationStatusSuccess, (state, action) => {
    const { studentId, data } = action;
    const currentStudentVerificationState = state.studentsVerificationStatuses[studentId] || {
      entity: null,
      isLoading: false,
      error: null,
    };
    const resultState: StudentDataState = {
      ...state,
      studentsVerificationStatuses: {
        ...state.studentsVerificationStatuses,
        [studentId]: {
          ...currentStudentVerificationState,
          entity: data,
          isLoading: false,
        },
      },
    };
    return resultState;
  }),
  on(fromActions.getStudentVerificationStatusFailed, (state, action) => {
    const { studentId, error } = action;
    const currentStudentVerificationState = state.studentsVerificationStatuses[studentId] || {
      entity: null,
      isLoading: false,
      error: null,
    };
    const resultState: StudentDataState = {
      ...state,
      studentsVerificationStatuses: {
        ...state.studentsVerificationStatuses,
        [studentId]: {
          ...currentStudentVerificationState,
          error,
          isLoading: false,
        },
      },
    };
    return resultState;
  }),
);
