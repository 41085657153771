import { ActivityTypeMap } from '@rootTypes/entities/activities/activity-type-map';
import { SelectOption } from '../../shared/form-controls';
import { ActivityTypeOption } from '@rootTypes/entities/activities/activity-type-option';

export const getActivityTypeOptionsFromMap = (activityTypeMap: ActivityTypeMap): ActivityTypeOption[] => {
  const result: ActivityTypeOption[] = Object.keys(activityTypeMap).map((typeId) => {
    const typeOption: ActivityTypeOption = {
      value: typeId,
      displayLabel: activityTypeMap[typeId].type.displayLabel,
      subTypes: [],
    };
    const subTypes: SelectOption[] = Object.keys(activityTypeMap[typeId].subTypes).map((subTypeId) => {
      return {
        value: subTypeId,
        displayLabel: activityTypeMap[typeId].subTypes[subTypeId].subType.displayLabel,
      };
    });
    subTypes.sort((a, b) => {
      return activityTypeMap[typeId].subTypes[a.value].index - activityTypeMap[typeId].subTypes[b.value].index;
    });
    typeOption.subTypes = subTypes;
    return typeOption;
  });
  result.sort((a, b) => {
    return activityTypeMap[a.value].index - activityTypeMap[b.value].index;
  });
  return result;
};
