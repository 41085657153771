export interface ApiNotification {
  title: RichText;
  image?: APIImage;
  message: RichText;
  button?: APIButton;
}

type RichText = {
  text?: string;
  value?: LimitedHtmlString;
  icon?: RichTextIcon;
  style?: string;
};

export enum RichTextIcon {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARN = 'WARN',
  CRITICAL = 'CRITICAL',
  CLOCK = 'CLOCK',
}

export type LimitedHtmlString = string;

type APIButton = {
  displayText: string;
  style?: APIButtonStyle;
};
// Client should default style to `POSITIVE` when style is missing or returned value is not supported

export enum APIButtonStyle {
  POSITIVE = 'POSITIVE', // Green
  NEUTRAL = 'NEUTRAL', // Grey
  DESTRUCTIVE = 'DESTRUCTIVE', // Orange
}

export enum APIImage {
  SUCCESS = 'SUCCESS',
  WARN = 'WARN',
  ARROW_RIGHT = 'ARROW_RIGHT',
}
