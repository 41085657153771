import { RouterStateUrl } from '@rootStore';
import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute } from '../../types/portal-route';

export interface NewUserPasswordSetupParams {
  token: string;
}

export const newUserPasswordSetupRoute: PortalRoute<NewUserPasswordSetupParams> = {
  path: 'password-setup',
  isOnRoute(state: RouterStateUrl): boolean {
    return state.url.startsWith(`/${this.path}`);
  },
  parse(state: RouterStateUrl): NewUserPasswordSetupParams {
    return { token: state.queryParams.token };
  },
  request(data: NewUserPasswordSetupParams): NavigationRequest {
    return {
      path: [`/${this.path}`],
      query: {
        token: data.token,
      },
    };
  },
};
