import * as fromEntities from '../../../entities';

const driverStatusToDisplayMap = {
  ACTIVE: 'Active',
  ARCHIVED: 'Archived',
  SUSPENDED: 'Suspended',
  BLACKLISTED: 'Blocked',
  DRAFT: 'Draft',
  DELETED: 'Deleted',
};

export const driverStatusToDisplay = (driverStatus: fromEntities.driver.DriverStatus): string => {
  if (!driverStatus) {
    return undefined;
  }
  const displayStatus = driverStatusToDisplayMap[driverStatus];
  if (displayStatus) {
    return displayStatus;
  }
  return 'Unknown';
};
