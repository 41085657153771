import { EntityStatus, SerializedYardListSnapshot, YardListSnapshot } from '../entities';
import * as ui from '../ui';

export const getSnapshotFromSerialized = (source: SerializedYardListSnapshot): YardListSnapshot => {
  if (!source) {
    return undefined;
  }
  const { search, page, status, pageSize } = source || {};
  const res: YardListSnapshot = {
    search,
    page: page ? parseInt(page, 10) - 1 : ui.DEFAULT_YARD_LIST_PAGE,
    status: (status as EntityStatus) || ui.DEFAULT_YARD_LIST_STATUS,
    pageSize: pageSize ? parseInt(pageSize, 10) : ui.DEFAULT_YARD_LIST_PAGE_SIZE,
    filters: [], // no filters for now
  };
  return res;
};
