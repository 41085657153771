import { createReducer, on } from '@ngrx/store';
import * as fromTypes from '../../types';
import * as fromActions from '../actions/route-lists.actions';

export type AllRouteListState = {
  [key in fromTypes.RouteListNames]?: RouteListState;
};

export interface RouteListState {
  page: {
    currentRoutes: string[];
    totalItemsCount: number;
    pageLoading: boolean;
    error: fromTypes.WpError | null;
  };
  pagination: {
    currentPage: number;
    pageSize: number;
  };
  weekday: fromTypes.Weekday;
  filters: fromTypes.RouteFilter[];
  staticFilters: fromTypes.RouteFilter[];
  selectedRouteId: string | null;
  currentRouteStatusType: fromTypes.RouteStatusType;
}

export const initialRouteListState: RouteListState = {
  page: {
    currentRoutes: [],
    totalItemsCount: 0,
    pageLoading: false,
    error: null,
  },
  pagination: {
    currentPage: 0,
    pageSize: 10,
  },
  filters: [],
  staticFilters: [],
  selectedRouteId: null,
  currentRouteStatusType: fromTypes.ui.DEFAULT_ROUTE_STATUS,
  weekday: fromTypes.Weekday.MONDAY,
};

export const getInitialAllRouteLists = (): AllRouteListState => {
  const result = {};
  fromTypes.routeLists.forEach((routeList) => (result[routeList] = getInitialRouteList()));
  return result;
};

export const getInitialRouteList = () => {
  return {
    ...initialRouteListState,
    ...initialRouteListState.page,
    ...initialRouteListState.pagination,
    filters: [],
    staticFilters: [],
  };
};

export const routeListReducer = createReducer<AllRouteListState>(
  getInitialAllRouteLists(),
  on(fromTypes.cleanUpStore, getInitialAllRouteLists),
  on(fromActions.setRouteListFromSnapshot, (allState, action) => {
    const { listName, snapshot } = action;
    const state = allState[listName];
    const newState = {
      ...state,
      pagination: {
        ...state.pagination,
        currentPage: snapshot.currentPage,
        pageSize: snapshot.pageSize,
      },
      currentRouteStatusType: snapshot.statusType,
      filters: snapshot.filters,
      staticFilters: snapshot.staticFilters,
    } as RouteListState;
    return {
      ...allState,
      [listName]: newState,
    };
  }),
  on(fromActions.weekdaySelectedChanged, (allState, action) => {
    const { listName, weekday } = action;
    const state = allState[listName];
    const newState = {
      ...state,
      weekday,
    } as RouteListState;
    return {
      ...allState,
      [listName]: newState,
    };
  }),
  on(fromActions.selectedRouteIdChanged, (allState, action) => {
    const { listName, routeId } = action;
    const state = allState[listName];
    const newState = {
      ...state,
      selectedRouteId: routeId,
    } as RouteListState;
    return {
      ...allState,
      [listName]: newState,
    };
  }),
  on(fromActions.routeStatusTypeChanged, (allState, action) => {
    const statusType = action.statusType;
    const { listName } = action;
    const state = allState[listName];
    const newState = {
      ...state,
      pagination: {
        ...state.pagination,
        currentPage: 0,
      },
      currentRouteStatusType: statusType,
    } as RouteListState;
    return {
      ...allState,
      [listName]: newState,
    };
  }),
  on(fromActions.routeEntityFilterAddSelected, fromActions.routeSearchHitFilterAddSelected, (allState, action) => {
    const { filter, listName } = action;
    const state = allState[listName];
    const isDuplicate = state.filters.some((f) => f.filterId === filter.filterId);
    const newState = isDuplicate
      ? { ...state }
      : ({
          ...state,
          pagination: {
            ...state.pagination,
            currentPage: 0,
          },
          filters: [...state.filters, filter],
        } as RouteListState);
    return {
      ...allState,
      [listName]: newState,
    };
  }),
  on(fromActions.routeEntityFilterDeleteSelected, (allState, action) => {
    const { filterId, listName } = action;
    const state = allState[listName];
    const newFilters = state.filters.filter((f) => f.filterId !== filterId);
    const newState = {
      ...state,
      pagination: {
        ...state.pagination,
        currentPage: 0,
      },
      filters: [...newFilters],
    } as RouteListState;
    return {
      ...allState,
      [listName]: newState,
    };
  }),
  on(fromActions.routeFiltersRemoveAllSelected, (allState, action) => {
    const { listName } = action;
    const state = allState[listName];
    const newState = {
      ...state,
      pagination: {
        ...state.pagination,
        currentPage: 0,
      },
      filters: [],
    } as RouteListState;
    return {
      ...allState,
      [listName]: newState,
    };
  }),
  on(fromActions.setStaticFilters, (allState, action) => {
    const { listName, filters } = action;
    const state = allState[listName];
    if (areFiltersEqual(state.staticFilters, filters)) {
      return { ...allState, [listName]: { ...state } };
    } else {
      const newState = {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: 0,
        },
        staticFilters: filters,
      } as RouteListState;
      return {
        ...allState,
        [listName]: newState,
      };
    }
  }),
  on(fromActions.pageSizeChanged, (allState, action) => {
    const { pageSize, listName } = action;
    const state = allState[listName];
    const newState = {
      ...state,
      pagination: {
        ...state.pagination,
        currentPage: 0,
        pageSize,
      },
    } as RouteListState;
    return {
      ...allState,
      [listName]: newState,
    };
  }),
  on(fromActions.currentPageChanged, (allState, action) => {
    const { page, listName } = action;
    const state = allState[listName];
    const newState = {
      ...state,
      pagination: {
        ...state.pagination,
        currentPage: page,
      },
    } as RouteListState;
    return {
      ...allState,
      [listName]: newState,
    };
  }),
  /**
   * Set flag when the page is loading and the page size might be changed
   */
  on(
    fromActions.setStaticFilters,
    fromActions.initializedRouteList,
    fromActions.routeEntityFilterAddSelected,
    fromActions.routeEntityFilterDeleteSelected,
    fromActions.routeFiltersRemoveAllSelected,
    fromActions.routeStatusTypeChanged,
    (allState, action) => {
      const { listName } = action;
      const state = allState[listName];
      const newState = {
        ...state,
        page: {
          ...state.page,
          totalItemsCount: undefined,
        },
      };
      return {
        ...allState,
        [listName]: newState,
      };
    },
  ),
  // load route page
  on(fromActions.loadRouteListRequested, (allState, action) => {
    const { listName } = action;
    const state = allState[listName];
    const newState = {
      ...state,
      page: {
        ...state.page,
        pageLoading: true,
      },
    };
    return {
      ...allState,
      [listName]: newState,
    };
  }),
  on(fromActions.loadRouteListSuccess, (allState, action) => {
    const { listName } = action;
    const state = allState[listName];
    const newState: RouteListState = {
      ...state,
      page: {
        ...state.page,
        pageLoading: false,
        currentRoutes: action.response.routeIds,
        totalItemsCount: action.response.totalFound,
      },
    };
    return {
      ...allState,
      [listName]: newState,
    };
  }),
  on(fromActions.loadRouteListFailed, (allState, action) => {
    const { listName } = action;
    const state = allState[listName];
    const newState = {
      ...state,
      page: {
        ...initialRouteListState.page,
        pageLoading: false,
        error: action.error,
      },
    };
    return {
      ...allState,
      [listName]: newState,
    };
  }),
  // eof
);

function areFiltersEqual(filters1: fromTypes.RouteFilter[] = [], filters2: fromTypes.RouteFilter[] = []): boolean {
  const hashFn = (f: fromTypes.RouteFilter) => f.filterId;
  return fromTypes.utils.arraysEqual(filters1, filters2, hashFn);
}
