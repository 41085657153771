import { SmartFormFieldSelect, SmartFormFieldType } from '@apiEntities';
import { SmartSelect } from 'src/app/shared/smart-forms';

export const isSmartFormFieldSelect = (arg: any): arg is SmartFormFieldSelect => {
  return !!arg && typeof arg === 'object' && arg.type === SmartFormFieldType.SELECT;
};

export const isSmartFormFieldSelectValid = (select: SmartFormFieldSelect, required: boolean): boolean => {
  if (required && typeof select.selected?.value !== 'undefined') {
    return true;
  }
  return true;
};

export const smartFormFieldSelectToSmartSelect = (
  field: SmartFormFieldSelect,
  required?: boolean,
  disabled?: boolean,
): SmartSelect => {
  const value = field.selected
    ? {
        id: field.selected.value,
        label: field.selected.label,
      }
    : undefined;
  const fixed = field.lookup.fixed
    ? field.lookup.fixed.map(({ label, value }) => ({ value, displayLabel: label }))
    : undefined;
  return new SmartSelect({
    label: field.label,
    value,
    lookup: {
      entitySearch: field.lookup.entitySearch,
      fixed,
    },
    required,
    disabled,
  });
};
