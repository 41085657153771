import { createSelector } from '@ngrx/store';
import { getFeatureState } from './feature-selector';
import * as fromTypes from '../../types';

const getCalendarDataState = createSelector(getFeatureState, (state) => state.calendarData);

/**
 * Calendars by campus
 */
export const getAllCalendarsByCampus = createSelector(getCalendarDataState, (state) => state.calendarsByCampuses);

const getSingleCalendarByCampusState = createSelector(getAllCalendarsByCampus, (state, props: { campusId: string }) => {
  const { campusId } = props;
  return state[campusId];
});

export const getCalendarIdsByCampus = createSelector(
  getSingleCalendarByCampusState,
  (state): string[] => state?.entity,
);

export const getCalendarsCountByCampus = createSelector(
  getCalendarIdsByCampus,
  (state): number => (state || []).length,
);

export const getCalendarsByCampusLoading = createSelector(
  getSingleCalendarByCampusState,
  (state): boolean => state?.isLoading || false,
);

export const getCalendarsByCampusError = createSelector(
  getSingleCalendarByCampusState,
  (state): fromTypes.WpError => state?.error || undefined,
);

/**
 * Calendar
 */
export const getAllCalendarsState = createSelector(getCalendarDataState, (state) => state.calendars);

const getSingleCalendarState = createSelector(getAllCalendarsState, (state, props: { calendarId: string }) => {
  const { calendarId } = props;
  return state[calendarId];
});

export const getCalendar = createSelector(getSingleCalendarState, (state) => state?.entity);

export const getCalendarRemoving = createSelector(getSingleCalendarState, (state) => state.isRemoving);

/**
 * Default calendar
 */
export const getAllDefaultCalendarState = createSelector(
  getCalendarDataState,
  (state) => state.defaultCalendarsByCampuses,
);

/**
 * Schedules
 */
export const getAllSchedulesByCalendarsState = createSelector(
  getCalendarDataState,
  (state) => state.schedulesByCalendars,
);

export const getAllSchedulesState = createSelector(getCalendarDataState, (state) => state.schedules);

export const getScheduleIdsByCalendar = createSelector(
  getAllSchedulesByCalendarsState,
  (schedulesByCalendars, props: { calendarId: string }) => {
    const { calendarId } = props;
    const scheduleIds = schedulesByCalendars[calendarId] || [];
    return scheduleIds;
  },
);

const getSingleScheduleState = createSelector(getAllSchedulesState, (state, props: { scheduleId: string }) => {
  return state[props.scheduleId];
});

export const getSchedule = createSelector(getSingleScheduleState, (state) => state.entity);

export const getScheduleRemoving = createSelector(getSingleScheduleState, (state) => state.isRemoving);
