import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import { Observable } from 'rxjs';
import * as fromActions from '../actions/us-national-holidays.actions';
import * as fromSelectors from '../selectors/us-national-holidays.selectors';
import * as fromTypes from '../../types';
import { map } from 'rxjs/operators';

@Injectable()
export class USNationalHolidayFacade {
  constructor(private store: Store<State>) {}

  public onLoadUSNationalHolidays(startDate: fromTypes.YYYYMMDDString, endDate: fromTypes.YYYYMMDDString): void {
    this.store.dispatch(
      fromActions.getUSNationalHolidaysRequested({
        startDate,
        endDate,
      }),
    );
  }

  public getUSNationalHolidays$(): Observable<fromTypes.NationalHoliday[]> {
    return this.store.select(fromSelectors.getNationalHolidaysSorted);
  }

  public getHolidaysAsCalendarExclusions$(): Observable<fromTypes.districts.CalendarExclusion[]> {
    return this.getUSNationalHolidays$().pipe(
      map((holidays) => {
        return holidays.map((h) => {
          return {
            id: h.id,
            name: h.labelShort,
            isPublic: true,
            dates: [h.date],
          } as fromTypes.districts.CalendarExclusion;
        });
      }),
    );
  }

  public getUSHolidaysLoading$(): Observable<boolean> {
    return this.store.select(fromSelectors.getNationalHolidaysLoading);
  }

  public getUSHolidaysError$(): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getNationalHolidaysError);
  }
}
