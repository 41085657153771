<div class="day-wrap" #dayWrap>
  <div [matTooltip]="day.tooltip" [matTooltipDisabled]="!day.tooltip" [matTooltipPosition]="'above'">
    <!-- Disabled mask -->
    <div class="disabled" [ngClass]="{ 'shown': readonly || day.isDisabled }"></div>
    <!-- Actual Day -->
    <div [ngClass]="{ 'border-selection': borderSelectionStyle }" class="day {{ day.css }}" (mousedown)="onDayClick()">
      <span class="day-content">{{ day.label }}</span>
    </div>
  </div>
</div>
