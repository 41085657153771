import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnPrimaryComponent } from './btn-primary/btn-primary.component';
import { BtnSecondaryComponent } from './btn-secondary/btn-secondary.component';
import { BtnGhostComponent } from './btn-ghost/btn-ghost.component';
import { BtnIconComponent } from './btn-icon/btn-icon.component';
import { BtnCopyComponent } from './btn-copy/btn-copy.component';
import { TypographyModule } from '../typography/typography.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BtnSuffixComponent } from './btn-suffix/btn-suffix.component';
import { RemoveLinkBtnComponent } from './remove-link-btn/remove-link-btn.component';
import { BtnProgressGreenComponent } from './btn-progress-green/btn-progress-green.component';
import { BtnAddStandardComponent } from './btn-add-standard/btn-add-standard.component';
import { IconsModule } from '../icons/icons.module';
import { UndoLinkBtnComponent } from './undo-link-btn/undo-link-btn.component';
import { EditLinkBtnComponent } from './edit-link-btn/edit-link-btn.component';
import { RefreshButtonComponent } from './refresh-button/refresh-button.component';
import { ButtonEditStandardComponent } from './button-edit-standard/button-edit-standard.component';
import { BtnIncrementPlusComponent } from './btn-increment-plus/btn-increment-plus.component';
import { BtnIncrementMinusComponent } from './btn-increment-minus/btn-increment-minus.component';
import { BtnAddFormLineComponent } from './btn-add-form-line/btn-add-form-line.component';
import { AddLinkButtonComponent } from './add-link-button/add-link-button.component';
import { BtnLabelForInputComponent } from './btn-label-for-input/btn-label-for-input.component';

const components = [
  BtnPrimaryComponent,
  BtnSecondaryComponent,
  BtnGhostComponent,
  BtnIconComponent,
  BtnCopyComponent,
  BtnSuffixComponent,
  RemoveLinkBtnComponent,
  BtnAddStandardComponent,
  UndoLinkBtnComponent,
  EditLinkBtnComponent,
  RefreshButtonComponent,
  ButtonEditStandardComponent,
  BtnIncrementPlusComponent,
  BtnIncrementMinusComponent,
  BtnAddFormLineComponent,
  AddLinkButtonComponent,
  BtnLabelForInputComponent,
];

@NgModule({
  declarations: [...components, BtnProgressGreenComponent],
  imports: [CommonModule, MatTooltipModule, IconsModule, TypographyModule],
  exports: [...components],
})
export class ButtonsModule {}
