import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-icon-driver-bus-report',
  template: `
    <svg
      [ngClass]="{ green: green }"
      class="wrap"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fill-rule="evenodd">
        <g class="colored-stroke" transform="translate(10 11)" stroke="#47A540" stroke-width="2">
          <circle cx="5" cy="5" r="6" />
          <path stroke-linecap="round" stroke-linejoin="round" d="M5 1.667 4.998 5M4.997 5.32 6.534 6.84" />
        </g>
        <path
          d="M11.649 21H3.572C1.184 21.01.999 20.817 1 18.777V3.222C1.032 1.256 1.045 1.006 3.572 1h12.856c2.187.023 2.58.06 2.572 2.222v7.38"
          stroke="#47A540"
          stroke-width="2"
          stroke-linecap="round"
          class="colored-stroke"
        />
        <path class="colored-fill" fill="#47A540" d="M4 4h12v2H4zM4 8h6v2H4zM4 12h3v2H4z" />
      </g>
    </svg>
  `,
  styles: [
    `
      .wrap .colored-stroke {
        stroke: #979797;
      }

      .wrap .colored-fill {
        fill: #979797;
      }

      .wrap.green .colored-stroke {
        stroke: #47a540;
      }

      .wrap.green .colored-fill {
        fill: #47a540;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconDriverBusReportComponent implements OnInit {
  @Input() public green: boolean;

  constructor() {}

  ngOnInit(): void {}
}
