<wp-popup-page [nonScrollable]="true" wpFadeInOut [show]="true">
  <!-- header -->
  <wp-popup-page-top
    [isCloseBtn]="isCloseButton"
    (closeClicked)="onCloseClicked()"
  >
    <wp-popup-page-back-bar
      *ngIf="displayBackText"
      [displayBackText]="displayBackText"
      [pageTitle]="displayBackTitle"
      [callHistoryBackOnClick]="callHistoryBackOnDisplayBackTextClick"
      (backClicked)="onDisplayBackClicked()"
    >
    </wp-popup-page-back-bar>
    <wp-popup-page-title>
      <ng-content select=".title"></ng-content>
    </wp-popup-page-title>

    <wp-popup-page-subtitle [isBold]="false">
      <ng-content select=".subtitle"></ng-content>
    </wp-popup-page-subtitle>

    <wp-popup-page-top-right>
      <div *ngIf="!hideEditButtons" class="top-buttons">
        <div class="button">
          <wp-btn-secondary
            [height]="'42px'"
            [width]="'160px'"
            [disabled]="isConfirmButtonLoading"
            (clicked)="onCancelClicked()"
          >
            {{ cancelButtonText }}
          </wp-btn-secondary>
        </div>
        <div class="button">
          <wp-btn-primary
            [height]="'42px'"
            [width]="'160px'"
            [loading]="isConfirmButtonLoading"
            [disabled]="isConfirmButtonDisabled"
            (clicked)="onConfirmClicked()"
          >
            {{ confirmButtonText }}
          </wp-btn-primary>
        </div>
      </div>
    </wp-popup-page-top-right>
  </wp-popup-page-top>
  <!--/ header -->

  <wp-popup-body-top>
    <div class="content-wrap">
      <ng-content select=".content"></ng-content>
    </div>
  </wp-popup-body-top>
</wp-popup-page>
