import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';

import * as fromActions from '../actions/student-change-request-review.actions';
import { StudentChangeRequestMockApiService, StudentChangeRequestApiService } from '../../services';
import {
  SnackbarService,
  openErrorPopup,
  StudentChangeRequestPendingListByStudentRouterService,
  createApplyStudentChangeRequestData,
} from '../../dependencies';
import { getStudentId } from '../selectors/student-change-request-root.selectors';
import { getReviewState } from '../selectors/student-change-request-review.selectors';
import { workQueueStudentRequestsUpdatePendingListOnRequestResolve } from 'src/app/features/work-queue/store/actions';
import { loadStudentDetailsOnApplyChangeRequest } from '../../../students/store/actions';

@Injectable()
export class StudentChangeRequestReviewEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private api: StudentChangeRequestApiService,
    private mockApi: StudentChangeRequestMockApiService,
    private snackbar: SnackbarService,
    private listRouter: StudentChangeRequestPendingListByStudentRouterService,
  ) {}

  public loadRequestConfigRequested = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.studentChangeRequestReviewLoadRequestConfigRequested),
      mergeMap(({ requestId: changeRequestId }) => {
        return this.getApiService(changeRequestId)
          .getStudentChangeRequestConfig({ changeRequestId })
          .pipe(
            map((response) => {
              return fromActions.studentChangeRequestReviewLoadRequestConfigSuccess({ response });
            }),
            catchError((originalError) => {
              return of(
                fromActions.studentChangeRequestReviewLoadRequestConfigFailed({
                  error: {
                    text: 'Failed to load student change request',
                    originalError,
                  },
                }),
              );
            }),
          );
      }),
    ),
  );

  public confirmBtnClick = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.studentChangeRequestReviewConfirmBtnClick),
        withLatestFrom(this.store.select(getReviewState)),
        tap(([, state]) => {
          if (state.reviewForm.entity.isValidOnClientSide) {
            this.store.dispatch(
              fromActions.applyStudentChangeRequestRequested({
                request: createApplyStudentChangeRequestData(state.requestId, state.reviewForm.entity),
              }),
            );
          } else {
            this.snackbar.info('Please fill out the form to proceed');
          }
        }),
      ),
    { dispatch: false },
  );

  public applyChangeRequestRequested = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.applyStudentChangeRequestRequested),
      mergeMap(({ request }) => {
        return this.getApiService(request.changeRequestId)
          .applyStudentChangeRequest(request)
          .pipe(
            map((response) => {
              return fromActions.applyStudentChangeRequestSuccess({ response });
            }),
            catchError((originalError) => {
              return of(
                fromActions.applyStudentChangeRequestFailed({
                  error: {
                    text: 'Failed to apply student change request',
                    originalError,
                  },
                }),
              );
            }),
          );
      }),
    ),
  );

  public applyChangeRequestSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.applyStudentChangeRequestSuccess),
        filter(({ response }) => response.isValid),
        withLatestFrom(this.store.select(getStudentId)),
        tap(([, studentId]) => {
          this.store.dispatch(fromActions.studentChangeRequestReviewReloadPendingRequestsRequested({ studentId }));
          this.store.dispatch(workQueueStudentRequestsUpdatePendingListOnRequestResolve({ studentId }));
          this.store.dispatch(loadStudentDetailsOnApplyChangeRequest({ studentId }));
          window.history.back();
          setTimeout(() => {
            this.snackbar.success('Change request successfully applied');
          }, 1000);
        }),
      ),
    { dispatch: false },
  );

  public applyChangeRequestFailed = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.applyStudentChangeRequestFailed),
      map(({ error }) => openErrorPopup({ error })),
    ),
  );

  private getApiService(requestId: string): StudentChangeRequestApiService {
    return requestId === 'mock' ? (this.mockApi as StudentChangeRequestApiService) : this.api;
  }
}
