import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export const validTimeRegex = /^(0?[0-9]|1[012])(\s{0,1}:\s{0,1}[0-5]\d)\s{0,1}[APap][mM]$/;

@Directive({
  selector: '[wpInputTimeFormat]',
})
export class InputTimeFormatDirective {
  @Input() public bindControl: UntypedFormControl;
  private numberInputSub: BehaviorSubject<string>;

  constructor(private elRef: ElementRef) {}

  public ngAfterViewInit(): void {
    this.numberInputSub = new BehaviorSubject<string>(this.elRef.nativeElement.value);

    this.numberInputSub
      .asObservable()
      .pipe(
        startWith(this.elRef.nativeElement.value),
        map((rawValue: string) => {
          const formatted = this.formatTime(rawValue);
          this.elRef.nativeElement.value = formatted;
          if (this.bindControl) {
            this.bindControl.setValue(formatted);
          }
        }),
      )
      .subscribe();
  }

  /**
   * Formats input element value on change
   */
  @HostListener('input', ['$event']) public onInputChange(event: InputEvent): void {
    // do not emit if user is deleting content
    if (event.inputType === 'deleteContentBackward') {
      this.bindControl.setValue(this.elRef.nativeElement.value);
    } else {
      if (this.numberInputSub) {
        this.numberInputSub.next(this.elRef.nativeElement.value);
      }
    }
  }

  @HostListener('blur') public onBlurEvent(event: InputEvent): void {
    this.onBlur();
  }

  private onBlur(): void {
    const formattedTime = this.elRef.nativeElement.value;
    const timePart = this.getTimePart(formattedTime);
    const postTimePart = this.getPostTimePart(formattedTime);
    if (timePart) {
      const formattedFullTime = `${timePart} ${this.stringToAMPM(postTimePart)}`;
      this.elRef.nativeElement.value = formattedFullTime;
      this.bindControl.setValue(formattedFullTime);
    }
  }

  private stringToAMPM(s: string) {
    if (!s) {
      return '';
    }
    if (/(am|AM|A|a)/.test(s)) {
      return 'AM';
    }
    if (/(pm|PM|P|p)/.test(s)) {
      return 'PM';
    }
    return '';
  }

  private formatTime(source: string): string {
    if (this.isValidTime(source)) {
      return source;
    }
    const toLowerCase = source.toLowerCase();
    const stripped = toLowerCase.replace(/[^\d]/gi, '').substr(0, 4);
    const timeSegments = this.getTimeSegments(stripped);
    let timePart: string;
    if (timeSegments.length > 1) {
      const minutesCompleted = timeSegments[1].length >= 2;
      timePart = `${timeSegments[0]}:${timeSegments[1]}${minutesCompleted ? ' ' : ''}${
        this.getPostTimePart(source) || ''
      }`;
    } else {
      timePart = timeSegments[0];
    }
    return timePart;
  }

  private getPostTimePart(source: string): string {
    if (!source) {
      return null;
    }
    const allParts = source.match(/^(\d{2}[:]{1}\d{2})(.*)$/);

    if (!(allParts && allParts.length > 2)) {
      return null;
    }
    const rawpostTime = allParts[2];
    const strippedPostTime = rawpostTime.replace(/[^\sampAMP]/g, '');
    if (strippedPostTime.length === 0) {
      return '';
    }
    return this.stringToAMPM(strippedPostTime);
  }

  private getTimePart(source: string): string {
    if (!source) {
      return null;
    }
    const allParts = source.match(/^(\d{2}[:]{1}\d{2})(.*)$/);

    if (!(allParts && allParts.length > 1)) {
      return null;
    }
    const rawTime = allParts[1];
    return rawTime;
  }

  private getTimeSegments(source: string): string[] {
    if (this.isAmbiguousTimeString(source)) {
      return [source];
    }
    const padded = this.padTimeSegments(source);
    return [`${padded[0] || ''}${padded[1] || ''}`, `${padded[2] || ''}${padded[3] || ''}`];
  }

  private padTimeSegments(source: string): string {
    let temp = source;
    if (/^[2-9]{1}/.test(source) || /^[1]{1}[3-9]{1}/.test(source)) {
      temp = `0${temp}`;
    }
    let temp2 = temp.slice(2, 4);
    if (temp2 && temp2.length) {
      if (/^[6-9]{1}/.test(temp2)) {
        temp2 = `0${temp2}`;
        temp = `${temp.slice(0, 2)}${temp2.slice(0, 2)}`;
      }
    }
    return temp.slice(0, 4);
  }

  private isAmbiguousTimeString(source: string): boolean {
    if (!source) {
      return true;
    }
    if (source.length === 1 && /[01]{1}/.test(source)) {
      return true;
    }
    return false;
  }

  private isValidTime(source: string): boolean {
    if (!source) {
      return false;
    }
    const match = source.match(validTimeRegex);
    if (!match) {
      return false;
    }
    return !!match.length;
  }
}
