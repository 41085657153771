import { Injectable } from '@angular/core';
import { MapLocation } from './interfaces';

const INITIAL_RADIUS = 5;
const RADIUS_INCREMENT = 5;
const MAX_RADIUS = 200;

@Injectable()
export class StreetViewPanoService {
  private panoCache: { [id: string]: Promise<string> } = {};

  constructor() {}

  private streetViewService = new google.maps.StreetViewService();

  public findPanorama(location: MapLocation): Promise<string> {
    const locationId = this.getLocationId(location);
    if (!this.panoCache[locationId]) {
      this.panoCache[locationId] = this.findPanoramaRecursive(INITIAL_RADIUS, location);
    }
    return this.panoCache[locationId].catch((err) => {
      delete this.panoCache[locationId];
      console.log('Pano error: ', err);
      throw err;
    });
  }

  private findPanoramaRecursive(radius: number, location: MapLocation): Promise<string> {
    return new Promise((resolve, reject) => {
      const panoRequest = {
        location,
        radius,
        preference: google.maps.StreetViewPreference.NEAREST,
        source: google.maps.StreetViewSource.OUTDOOR,
      };
      this.streetViewService.getPanorama(panoRequest, (panoData, status) => {
        if (status === google.maps.StreetViewStatus.OK) {
          resolve(panoData.location.pano);
        } else {
          //Handle other statuses here
          if (radius > MAX_RADIUS) {
            reject('Street View is not available');
          } else {
            this.findPanoramaRecursive(radius + RADIUS_INCREMENT, location)
              .then((result) => resolve(result))
              .catch((err) => reject(err));
          }
        }
      });
    });
  }

  private getLocationId(loc: MapLocation): string {
    if (!loc) {
      return '';
    }
    return `${loc.lat}${loc.lng}`;
  }
}
