import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsInitService {
  private gmapsResolved: Promise<true>;

  constructor() {}

  public initialize(): Promise<true> {
    if (typeof this.gmapsResolved === 'undefined') {
      this.gmapsResolved = new Promise<true>((resolve, reject) => {
        this.initializeGmaps()
          .then(() => resolve(true))
          .catch((err) => reject(err));
      });
    }
    return this.gmapsResolved;
  }

  private initializeGmaps(): Promise<void> {
    if (wpEnvironment.apiLogsEnabled) {
      console.log('Load google maps requested');
    }
    return new Promise<void>((resolve, reject) => {
      // https://developers.google.com/maps/documentation/javascript/overview#Dynamic
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${wpEnvironment.googleMapsApiKey}&language=en&libraries=places,geometry,drawing&loading=async&callback=initGmaps`;
      // script.async = true;
      window['initGmaps'] = () => {
        if (wpEnvironment.apiLogsEnabled) {
          console.log('Load google maps success');
        }
        resolve();
      };
      script.onerror = () => {
        const error = new Error(`Unable to download google maps`);
        console.error(error); // This will also send the error to Rollbar
        reject(error);
      };
      document.head.appendChild(script);
    });
  }
}
