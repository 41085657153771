import { Injectable } from '@angular/core';
import { RouterOutlets } from '@rootTypes/entities/router';
import { PortalRoute } from '../../types/portal-route';
import { PortalRouterService } from '../../types/portal-router-service';
import { DateRange } from '@rootTypes';

export enum DriverDetailsTab {
  GENERAL = 'general',
  RUNS = 'runs',
  RIDES = 'rides',
  ACTIVITIES = 'activities',
  DOCUMENTS = 'documents',
  STATUS_LOG = 'status-log',
  DRIVER_BUS_REPORTS = 'driver-bus-reports',
  INSPECTIONS = 'inspections',
  EVENTS = 'events',
}

export interface DriverDetailsRouteData {
  driverId: string;
  tab?: DriverDetailsTab;

  // Query Params
  dateRange?: DateRange;
  displayBackText?: string;
}

export const driverDetailsRoutePath: string = ':driverId/details/:tab';

export function composeDriverDetailsRoutePath(data: DriverDetailsRouteData): string {
  return `_/drawer/drivers/${driverDetailsRoutePath}`
    .replace(':driverId', data.driverId)
    .replace(':tab', data.tab ?? DriverDetailsTab.GENERAL);
}

export const driverDetailsRoute: PortalRoute<DriverDetailsRouteData> = {
  path: driverDetailsRoutePath,

  getHref: composeDriverDetailsRoutePath,

  isOnRoute: (state) => {
    const data = {
      driverId: state.drawer.params.driverId,
      tab: state.drawer.params.tab,
    } as DriverDetailsRouteData;

    return state.drawer.url === composeDriverDetailsRoutePath(data);
  },

  parse: (state) => {
    const dateRange = state.queryParams.dateRange?.split(',');

    return {
      driverId: state.drawer.params.driverId,
      tab: state.drawer.params.tab,

      // Query Params
      dateRange: dateRange && { startDate: dateRange[0], endDate: dateRange[1] },
      displayBackText: state.queryParams.displayBackText,
    };
  },

  request: (data, queryParamsHandling, replaceUrl) => {
    return {
      path: composeDriverDetailsRoutePath(data).split('/'),

      extras: {
        queryParams: {
          dateRange: data.dateRange ? `${data.dateRange.startDate},${data.dateRange.endDate}` : undefined,
          displayBackText: data.displayBackText,
        },

        queryParamsHandling,
        replaceUrl,
      },
    };
  },
};

@Injectable({ providedIn: 'root' })
export class DriverDetailsRouterService extends PortalRouterService<DriverDetailsRouteData> {
  public readonly outlet = RouterOutlets.DRAWER;
  public readonly route = driverDetailsRoute;
}
