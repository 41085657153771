import { FilterDeserializer } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';
import { TransportationRequestStatusFilter } from '../types/transportation-request-list';
import { TransportationRegistrationStatus } from '@apiEntities/transportation-request';
import { createTransportationRequestStatusFilter } from './create-transportation-request-status-filter';

export const deserializeTransportationRequestStatusFilter: FilterDeserializer<TransportationRequestStatusFilter> = (
  str: string,
): TransportationRequestStatusFilter | undefined => {
  if (str && str.startsWith('registration-status')) {
    const value = str.split(',')[1] as TransportationRegistrationStatus;
    return createTransportationRequestStatusFilter(value);
  }
  return undefined;
};
