import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wp-page-bottom',
  templateUrl: './page-bottom.component.html',
  styleUrls: ['./page-bottom.component.scss'],
})
export class PageBottomComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
