import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wp-back-link-with-arrow',
  template: `
    <div class="back-link" (click)="onClick()">
      <div class="arrow">
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.03602 0.292927C6.4845 -0.0984297 7.21189 -0.097433 7.66123 0.29467C8.11371 0.689507 8.11147 1.32172 7.66323 1.71286L2.75083 5.99949L7.66327 10.2862C8.11175 10.6775 8.11061 11.3122 7.66127 11.7043C7.20879 12.0992 6.48429 12.0972 6.03605 11.7061L0.336805 6.73283C0.111011 6.5358 -0.00081593 6.27708 0.0011423 6.0187C-0.0127215 5.74761 0.0995842 5.47315 0.336769 5.26617L6.03602 0.292927Z"
            class="arrow-path"
            fill="#191919"
          />
        </svg>
      </div>
      <div class="back-text">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./back-link-with-arrow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackLinkWithArrowComponent implements OnInit {
  @Input() public navigateBackOnClick: boolean;
  @Output() public linkClicked = new EventEmitter<void>();
  constructor() {}

  ngOnInit(): void {}

  public onClick(): void {
    if (this.navigateBackOnClick) {
      history.back();
    } else {
      this.linkClicked.emit();
    }
  }
}
