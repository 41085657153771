import { HHMMTimeString } from '@rootTypes';
import { formatDate } from '@angular/common';
import { formattingConstants, hhmmToDate } from '@rootTypes/utils/common/date-time-fns';
import { isRegexMatch, PortalRegex } from '@rootTypes/utils/common/regex/portal-regex';

export const displayHHMMTimeString = (
  value?: HHMMTimeString,
  formatKey: keyof typeof formattingConstants = 'timeNoTimezone',
): string => {
  if (typeof value !== 'string' || value.length !== 4 || !isRegexMatch(PortalRegex.HHMM, value)) {
    return '--';
  }
  const date = hhmmToDate(value, new Date());
  return formatDate(date, formattingConstants[formatKey], 'en-US');
};
