import { createSelector } from '@ngrx/store';
import { getFeature } from './get-feature';
import { PortalEntityType } from '@rootTypes';

const getPortalEntityInfoState = createSelector(getFeature, (state) => state.portalEntityInfo);

const getPortalEntityState = (entityType: PortalEntityType, entityId: string) =>
  createSelector(getPortalEntityInfoState, (state) => (state[entityType] ? state[entityType][entityId] : undefined));

export const getPortalEntity = (entityType: PortalEntityType, entityId: string) => {
  return createSelector(getPortalEntityState(entityType, entityId), (state) => state?.entity);
};

export const getPortalEntityLabel = (entityType: PortalEntityType, entityId: string) => {
  return createSelector(getPortalEntity(entityType, entityId), (state) => state?.label);
};

export const isPortalEntityLoading = (entityType: PortalEntityType, entityId: string) => {
  return createSelector(getPortalEntityState(entityType, entityId), (state) => state?.isLoading || false);
};

export const getPortalEntityError = (entityType: PortalEntityType, entityId: string) => {
  return createSelector(getPortalEntityState(entityType, entityId), (state) => state?.error);
};
