import { State } from '@rootStore';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as fromActions from '../actions/home-ui-actions';

@Injectable()
export class HomeUIFacade {
  constructor(private store: Store<State>) {}

  public closeDrawer(): void {
    this.store.dispatch(fromActions.drawerClosedSelected());
  }
}
