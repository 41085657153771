import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-icon-upload',
  templateUrl: './icon-upload.component.html',
  styleUrls: ['./icon-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconUploadComponent implements OnInit {
  @Input() public disabled = false;
  @Input() public marginRight = '0px';
  @Input() public top = '0px';

  constructor() {}

  ngOnInit(): void {}
}
