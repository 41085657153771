<div
  class="wp-icon-upload"
  [ngStyle]="{'margin-right': marginRight, 'top': top}"
>
  <ng-container *ngIf="disabled else enabled">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
    >
      <g fill="none" fill-rule="evenodd">
        <g fill="#AEAEB0" fill-rule="nonzero">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          d="M3 10.366h1.979c.552 0 1-.448 1-1s-.448-1-1-1H3c-1.653 0-2.72 1.009-2.988 2.737L0 11.257v6.218c0 1.61 1.355 2.891 3 2.891h12c1.645 0 3-1.281 3-2.89v-6.22c0-1.609-1.355-2.89-3-2.89h-1.962c-.553 0-1 .448-1 1s.447 1 1 1H15c.564 0 1 .412 1 .89v6.22c0 .478-.436.89-1 .89H3c-.564 0-1-.412-1-.89v-6.138c.128-.736.397-.972 1-.972z"
                          transform="translate(-1241 -119) translate(483) translate(3 91) translate(734 20) translate(20 7) translate(1 .213)"
                        />
                        <path
                          d="M10.877 4v5c0 .552.447 1 1 1 .552 0 1-.448 1-1V3c0-.552-.448-1-1-1H5.962c-.553 0-1 .448-1 1s.447 1 1 1h4.915z"
                          transform="translate(-1241 -119) translate(483) translate(3 91) translate(734 20) translate(20 7) translate(1 .213) rotate(-45 8.92 6)"
                        />
                        <path
                          d="M11.852 3.256l-7.09 7.056c-.392.39-.393 1.022-.004 1.414.39.391 1.023.393 1.414.003l7.091-7.055c.392-.39.393-1.023.004-1.414-.39-.392-1.023-.393-1.415-.004z"
                          transform="translate(-1241 -119) translate(483) translate(3 91) translate(734 20) translate(20 7) translate(1 .213) rotate(-45 9.012 7.493)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </ng-container>

  <ng-template #enabled>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
    >
      <g fill="none" fill-rule="evenodd">
        <g fill="#47A540" fill-rule="nonzero">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          d="M3 10.366h1.979c.552 0 1-.448 1-1s-.448-1-1-1H3c-1.653 0-2.72 1.009-2.988 2.737L0 11.257v6.218c0 1.61 1.355 2.891 3 2.891h12c1.645 0 3-1.281 3-2.89v-6.22c0-1.609-1.355-2.89-3-2.89h-1.962c-.553 0-1 .448-1 1s.447 1 1 1H15c.564 0 1 .412 1 .89v6.22c0 .478-.436.89-1 .89H3c-.564 0-1-.412-1-.89v-6.138c.128-.736.397-.972 1-.972z"
                          transform="translate(-1241 -161) translate(483) translate(3 133) translate(734 20) translate(20 7) translate(1 .213)"
                        />
                        <path
                          d="M10.877 4v5c0 .552.447 1 1 1 .552 0 1-.448 1-1V3c0-.552-.448-1-1-1H5.962c-.553 0-1 .448-1 1s.447 1 1 1h4.915z"
                          transform="translate(-1241 -161) translate(483) translate(3 133) translate(734 20) translate(20 7) translate(1 .213) rotate(-45 8.92 6)"
                        />
                        <path
                          d="M11.852 3.256l-7.09 7.056c-.392.39-.393 1.022-.004 1.414.39.391 1.023.393 1.414.003l7.091-7.055c.392-.39.393-1.023.004-1.414-.39-.392-1.023-.393-1.415-.004z"
                          transform="translate(-1241 -161) translate(483) translate(3 133) translate(734 20) translate(20 7) translate(1 .213) rotate(-45 9.012 7.493)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </ng-template>
</div>
