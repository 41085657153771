export interface GetDriverRequest {
  driverId: string;
  _projections?: DriverProjection[];
}

export enum DriverProjection {
  VENDOR = 'vendor',
  VEHICLE = 'vehicle',
  YARD = 'yard',
  RATING = 'rating',
  STANDBY = 'standby',
}
