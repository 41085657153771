<div [hidden]="!(loginWithTokenLoading$ | async)">
  <wp-progress-bar></wp-progress-bar>
</div>

<wp-auth-wrapper [hidden]="loginWithTokenLoading$ | async">
  <section>
    <div
      class="wp-login-google-auth-error"
      *ngIf="loginWithGoogleUserNotFoundError"
    >
      <i class="wp-login-google-auth-error-icon"></i>
      <span
        >We do not recognize this user ID or password.<br />Please contact your
        administrator for help with access to this site.</span
      >
    </div>

    <div
      class="wp-login"
      [ngClass]="{'google-auth-error': loginWithGoogleUserNotFoundError}"
    >
      <div class="wp-login__container">
        <div class="wp-login__container__login-box">
          <img src="/assets/img/logo.svg" alt="Zūm" />
          <div class="welcome-lable">Welcome to Zūm</div>
          <div class="signin-lable">Please sign in</div>
          <div
            class="wp-login__alert-wrap"
            [ngClass]="{ error: loginError$ | async }"
          >
            <div class="alert" *ngIf="loginWithPasswordError$ | async">
              <wp-alert-critical [readonly]="true">
                Email or password is not correct.
              </wp-alert-critical>
            </div>
            <div class="alert" *ngIf="loginWithTokenError$ | async">
              <wp-alert-critical [readonly]="true">
                Google account not recognized
              </wp-alert-critical>
            </div>
          </div>

          <ng-container
            *ngIf="(isAuthProviderPassword$ | async) else enterEmail"
          >
            <form novalidate>
              <div class="email-preview-block">
                <wp-field-label-down-value
                  class="email-preview"
                  [label]="'Email'"
                  [value]="authEmail$ | async"
                ></wp-field-label-down-value>

                <wp-link-btn
                  [disabled]="loginWithPasswordLoading$ | async"
                  (clicked)="editAuthEmail()"
                  >Edit email
                </wp-link-btn>
              </div>

              <wp-input
                [hidden]="true"
                [label]="'Email'"
                [control]="email"
                [type]="'email'"
                [id]="'username'"
                [name]="'username'"
              ></wp-input>

              <wp-input
                [label]="'Password'"
                [control]="password"
                [type]="'password'"
                [name]="'password'"
                [id]="'password'"
              ></wp-input>

              <div class="submit-btn">
                <wp-btn-primary
                  (clicked)="loginWithPassword()"
                  [loading]="loginWithPasswordLoading$ | async"
                >
                  Log In
                </wp-btn-primary>
              </div>
            </form>

            <wp-link-btn
              class="reset-password"
              [disabled]="loginWithPasswordLoading$ | async"
              (clicked)="forgotPassword()"
              >Forgot your password?
            </wp-link-btn>
          </ng-container>

          <ng-template #enterEmail>
            <form novalidate>
              <wp-input
                [label]="'Email'"
                [control]="email"
                [type]="'email'"
                [id]="'username'"
                [name]="'username'"
              ></wp-input>

              <div class="submit-btn">
                <wp-btn-primary
                  (clicked)="requestAuthProvider()"
                  [loading]="authProviderLoading$ | async"
                >
                  Next
                </wp-btn-primary>
              </div>
            </form>
          </ng-template>
        </div>
      </div>
    </div>
  </section>
</wp-auth-wrapper>
