import { PortalRoute } from '../../types/portal-route';

export interface LoginRouteQueryParams {
  // google auth token for sign in
  token?: string;
  // defined by BE when user exists in Gsuite but does not exist in the DB
  error?: 'user-not-found';
  // destination page to enter the app after authentication is completed
  RelayState?: string;
}

export const login: PortalRoute<LoginRouteQueryParams> = {
  path: 'login',
  request: (data?: LoginRouteQueryParams) => {
    return {
      path: ['/login'],
      query: data,
    };
  },
};
