<div class="wrap">
  <wp-alert-critical *ngIf="error" [readonly]="true">
    <div>{{ error | errorGeneralMessage }}</div>
    <div class="error-message">{{ error | errorSpecialMessage}}</div>
    <div class="error-message">
      <wp-ellipsis [tooltipText]="error | errorReason"
        >{{ error | errorReason }}</wp-ellipsis
      >
    </div>
  </wp-alert-critical>
</div>
