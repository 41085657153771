import { ComponentStore } from '@ngrx/component-store';
import { PortalListBaseParams, PortalListBaseState } from './portal-list-base-state';
import { from, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, map, switchMap, take, tap } from 'rxjs/operators';
import { PortalEntity, PortalEntityType, WpError } from '@rootTypes';
import { PortalListEntityFilter, PortalListFilter, PortalListFilterType } from './portal-list-filter';

export type InitialListStateGetter<
  Filter extends PortalListFilter,
  Params extends PortalListBaseParams<Filter>,
  Item,
> = () => PortalListBaseState<Params, Item>;

export type ListStoreConfig<Filter extends PortalListFilter, Params extends PortalListBaseParams<Filter>, Item> = {
  initialStateGetter: InitialListStateGetter<Filter, Params, Item>;
  listReloadDebounceTime: number;
  orderFiltersByType?: Filter['type'][];
};

const defaultFilterOrdering: PortalListFilterType['type'][] = ['date', 'date-range', 'entity'];

export abstract class AbstractListComponentStore<
  Filter extends PortalListFilter,
  Status extends string,
  Params extends PortalListBaseParams<Filter, Status>,
  Item,
> extends ComponentStore<PortalListBaseState<Params, Item>> {
  protected subscriptions = new Subscription();
  private debouncedReloadListSubject = new Subject<Params>();
  private orderFiltersByTypeMap: any = {};

  // reload list effect
  public readonly reloadList: () => void;

  constructor(private listStoreConfig: ListStoreConfig<Filter, Params, Item>) {
    super(listStoreConfig.initialStateGetter());
    // init map for filter sorting
    this.orderFiltersByTypeMap = (
      (listStoreConfig.orderFiltersByType || defaultFilterOrdering) as Filter['type'][]
    ).reduce((p, c, i) => {
      return { ...p, [c]: i };
    }, {});
    this.reloadList = this.effect((origin) =>
      origin.pipe(
        tap(() => {
          this.patchState((state) => {
            return {
              ...state,
              api: {
                ...state.api,
                isLoading: true,
                error: undefined,
              },
            };
          });
        }),
        debounceTime(listStoreConfig.listReloadDebounceTime),
        switchMap(() => {
          return this.state$.pipe(
            map((state) => state.params),
            take(1),
          );
        }),
        switchMap((params) => {
          this.debouncedReloadListSubject.next(params);
          return from(this.loadListAPI(params)).pipe(
            tap(({ total, items }) => {
              this.patchState((state) => {
                return {
                  ...state,
                  api: {
                    ...state.api,
                    isLoading: false,
                    items,
                    total,
                  },
                };
              });
            }),
            catchError((originalError) => {
              const error: WpError = {
                text: 'Failed to load list',
                originalError,
                retryFn: () => this.reloadList(),
              };
              console.log(error);
              this.patchState((state) => {
                return {
                  ...state,
                  api: {
                    ...state.api,
                    isLoading: false,
                    error,
                  },
                };
              });
              return of(null);
            }),
          );
        }),
      ),
    );
  }

  /**
   * Use for your custom effects i.e. changing url params
   */
  public reloadListEvent$(): Observable<Params> {
    return this.debouncedReloadListSubject.asObservable();
  }

  /**
   * Selectors
   */
  public listParams$(): Observable<Params> {
    return this.state$.pipe(map((state) => state.params));
  }

  public searchQuery$(): Observable<string> {
    return this.listParams$().pipe(map((state) => state.searchQuery));
  }

  public selectedItemId$(): Observable<string | undefined> {
    return this.listParams$().pipe(map((state) => state.selectedItemId));
  }

  public listStatus$(): Observable<Status> {
    return this.listParams$().pipe(map((state) => state.status));
  }

  public page$(): Observable<number> {
    return this.listParams$().pipe(map((params) => params.page));
  }

  public pageSize$(): Observable<number> {
    return this.listParams$().pipe(map((params) => params.pageSize));
  }

  public filters$(): Observable<Filter[]> {
    return this.listParams$().pipe(
      map((params) => {
        const filterArr = [...params.filters];
        filterArr.sort((a, b) => this.orderFiltersByTypeMap[a.type] - this.orderFiltersByTypeMap[b.type]);
        return filterArr;
      }),
    );
  }

  public getFiltersOfType$<T = Filter>(type: Filter['type']): Observable<T[]> {
    return this.filters$().pipe(map((filters) => this.getFiltersOfType<T>(filters, type)));
  }

  public getOneFilterOfType$<T = Filter>(type: Filter['type']): Observable<T> {
    return this.filters$().pipe(map((filters) => this.getOneFilterOfType<T>(filters, type)));
  }

  public isAnyFilterSelected$(): Observable<boolean> {
    return this.filters$().pipe(map((ff) => ff?.length > 0));
  }

  public apiState$(): Observable<PortalListBaseState<Params, Item>['api']> {
    return this.state$.pipe(map((state) => state.api));
  }

  public isLoading$(): Observable<boolean> {
    return this.apiState$().pipe(map((api) => api.isLoading));
  }

  public isEmpty$(): Observable<boolean> {
    return this.apiState$().pipe(map((api) => !api.isLoading && api.total === 0));
  }

  public items$(): Observable<Item[]> {
    return this.apiState$().pipe(map((api) => api.items));
  }

  public error$(): Observable<WpError> {
    return this.apiState$().pipe(map((api) => api.error));
  }

  public total$(): Observable<number> {
    return this.apiState$().pipe(map((api) => api.total));
  }

  /**
   * Actions
   */
  public initialized(initParams: Params): void {
    this.setState({
      ...this.listStoreConfig.initialStateGetter(),
      params: {
        ...this.listStoreConfig.initialStateGetter().params,
        ...initParams,
      },
    });
  }

  public initializedFromUrlParams(urlParams: Partial<Params>): void {
    this.patchState((state) => {
      const targetParams = { ...state.params };
      if (urlParams) {
        for (const p in urlParams) {
          if (urlParams[p] !== undefined) {
            targetParams[p] = urlParams[p];
          }
        }
      }
      return {
        ...state,
        params: {
          ...targetParams,
        },
        api: {
          ...state.api,
          items: [],
        },
      };
    });
  }

  public setFilterByEntity(entity: PortalEntity): void {
    this.patchState((state) => ({
      ...state,
      params: {
        ...state.params,
        listByEntity: entity,
        page: 0,
      },
      api: {
        ...state.api,
        items: [],
      },
    }));
  }

  public statusChanged(status: string): void {
    this.patchState((state) => ({
      ...state,
      params: {
        ...state.params,
        status,
        page: 0,
      },
      api: {
        ...state.api,
        items: [],
      },
    }));
  }

  public pageChanged(page: number): void {
    this.patchState((state) => ({
      ...state,
      params: {
        ...state.params,
        page,
      },
      api: {
        ...state.api,
        items: [],
      },
    }));
  }

  public pageSizeChanged(pageSize: number): void {
    this.patchState((state) => ({
      ...state,
      params: {
        ...state.params,
        pageSize,
        page: 0,
      },
      api: {
        ...state.api,
        items: [],
      },
    }));
  }

  public searchQueryChanged(searchQuery: string): void {
    this.patchState((state) => ({
      ...state,
      params: {
        ...state.params,
        searchQuery,
        page: 0,
      },
      api: {
        ...state.api,
        items: [],
      },
    }));
  }

  public filterAdded(added: Filter): void {
    this.patchState((state) => {
      const prevList = state.params.filters;
      return {
        ...state,
        params: {
          ...state.params,
          page: 0,
          filters: [...prevList, added],
        } as PortalListBaseState<Params, Item>['params'],
        api: {
          ...state.api,
          items: [],
          total: undefined,
        },
      };
    });
  }

  public setFilterOfType(filterType: Filter['type'], filters: Filter[]): void {
    this.patchState((state) => {
      const prevList = state.params.filters.filter((e) => e.type !== filterType);
      return {
        ...state,
        params: {
          ...state.params,
          page: 0,
          filters: [...prevList, ...filters],
        } as PortalListBaseState<Params, Item>['params'],
        api: {
          ...state.api,
          items: [],
          total: undefined,
        },
      } as PortalListBaseState<Params, Item>;
    });
  }

  public setSearchQuery(query: string): void {
    this.patchState((state) => {
      return {
        ...state,
        params: {
          ...state.params,
          page: 0,
          searchQuery: query,
        } as PortalListBaseState<Params, Item>['params'],
        api: {
          ...state.api,
          items: [],
          total: undefined,
        },
      } as PortalListBaseState<Params, Item>;
    });
  }

  public filterRemoved(filterId: string): void {
    this.patchState((state) => {
      const newFilterList = state.params.filters.filter((e) => e.id !== filterId);
      return {
        ...state,
        params: {
          ...state.params,
          page: 0,
          filters: [...newFilterList],
        } as PortalListBaseState<Params, Item>['params'],
        api: {
          ...state.api,
          items: [],
          total: undefined,
        },
      };
    });
  }

  public clearedAllFilters(): void {
    this.patchState((state) => {
      return {
        ...state,
        params: {
          ...state.params,
          page: 0,
          filters: [],
        } as PortalListBaseState<Params, Item>['params'],
        api: {
          ...state.api,
          items: [],
          total: undefined,
        },
      };
    });
  }

  public selectedItemIdChanged(selectedItemId?: string): void {
    this.patchState((state) => {
      return {
        ...state,
        params: {
          ...state.params,
          selectedItemId,
        },
      };
    });
  }

  public abstract loadListAPI(params: Params): Promise<{ items: Item[]; total: number }>;

  public dispose(): void {
    this.subscriptions.unsubscribe();
  }

  protected getFiltersOfType<T>(filters: Filter[], type: Filter['type']): T[] {
    return (filters || []).filter((f) => f.type === type) as unknown as T[];
  }

  protected getOneFilterOfType<T>(filters: Filter[], type: Filter['type']): T {
    const matchingFilters = this.getFiltersOfType<T>(filters, type);
    return matchingFilters?.length ? matchingFilters[0] : undefined;
  }

  protected getEntityFiltersOfEntityType(filters: Filter[], entityType: PortalEntityType): PortalListEntityFilter[] {
    const entityFilters = this.getFiltersOfType<PortalListEntityFilter>(filters, 'entity');
    if (entityFilters?.length) {
      return entityFilters.filter((f) => f.payload.type === entityType);
    }
    return [];
  }
}
