import { createSelector } from '@ngrx/store';
import { CampusDataState } from '../reducers/campus-data.reducers';
import { getFeatureState } from './feature-selector';

export const getCampusDataState = createSelector(getFeatureState, (state) => state.campusData);

export const getAllCampuses = createSelector(getCampusDataState, (state) => state.campuses);

const getSingleCampusState = (campusId) =>
  createSelector(getAllCampuses, (state): CampusDataState['campuses'][string] => state[campusId]);

export const getCampusLoading = (campusId) =>
  createSelector(getSingleCampusState(campusId), (state) => (state ? state.isLoading : false));

export const getCampus = (campusId) =>
  createSelector(getSingleCampusState(campusId), (state) => (state ? state.entity : null));

export const getCampusRemoving = (campusId) =>
  createSelector(getSingleCampusState(campusId), (state) => (state ? state.isRemoving : false));

export const getCampusRemoved = (campusId) =>
  createSelector(getSingleCampusState(campusId), (state) => (state ? state.isRemoved : false));

export const getCampusError = (campusId) =>
  createSelector(getSingleCampusState(campusId), (state) => (state ? state.error : null));

export const getAllCheckState = createSelector(getCampusDataState, (state) => state.allCampusChecks);

export const getAllCampusChecks = createSelector(getAllCheckState, (state) => state.entity);

export const getAllCampusChecksLoading = createSelector(getAllCheckState, (state) => state.isLoading);

export const getAllCampusChecksError = createSelector(getAllCheckState, (state) => state.error);
