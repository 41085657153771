import * as fromTypes from '../../types';
import * as fromActions from '../actions/yard-list.actions';
import { createReducer, on } from '@ngrx/store';

export interface YardListState {
  page: {
    items: fromTypes.PortalEntity[];
    totalFound: number;
    isLoading: boolean;
    listError: fromTypes.WpError;
  };
  staticFilters: fromTypes.YardListFilter[];
  filters: fromTypes.YardListFilter[];
  status: fromTypes.EntityStatus;
  selectedYardId: string;
  currentSearch: string;
  pagination: {
    page: number;
    pageSize: number;
  };
}

export type AllYardListState = {
  [key in fromTypes.YardListName]?: YardListState;
};

export const initialYardListState: YardListState = {
  page: {
    items: [],
    totalFound: undefined,
    isLoading: false,
    listError: undefined,
  },
  staticFilters: [],
  status: fromTypes.ui.DEFAULT_YARD_LIST_STATUS,
  filters: [],
  selectedYardId: undefined,
  currentSearch: undefined,
  pagination: {
    page: 0,
    pageSize: fromTypes.ui.DEFAULT_YARD_LIST_PAGE_SIZE,
  },
};

export const getInitialAllYardListState = (): AllYardListState => {
  const result: AllYardListState = {};
  fromTypes.yardLists.forEach((listName) => {
    result[listName] = {
      ...initialYardListState,
      page: {
        ...initialYardListState.page,
      },
      staticFilters: [],
      filters: [],
      pagination: {
        ...initialYardListState.pagination,
      },
    };
  });
  return result;
};

export const yardListsReducer = createReducer<AllYardListState>(
  { ...getInitialAllYardListState() },
  on(fromTypes.cleanUpStore, () => ({ ...getInitialAllYardListState() })),
  /**
   * Initialized
   */
  on(fromActions.yardListInitialized, (allState, action) => {
    const { listName, snapshot } = action;
    const state = allState[listName];
    let resultState: YardListState;
    if (!snapshot) {
      resultState = { ...state };
    } else {
      resultState = {
        ...state,
        pagination: {
          ...state.pagination,
          page: snapshot?.page,
          pageSize: snapshot?.pageSize,
        },
        filters: snapshot?.filters || [],
        status: snapshot.status,
        page: {
          ...state.page,
          totalFound: undefined,
        },
        currentSearch: snapshot?.search,
      };
    }
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  /**
   * List changed
   */
  on(fromActions.pageChanged, (allState, action) => {
    const { listName, page } = action;
    const state = allState[listName];
    const resultState: YardListState = {
      ...state,
      pagination: {
        ...state.pagination,
        page,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.pageSizeChanged, (allState, action) => {
    const { listName, pageSize } = action;
    const state = allState[listName];
    const resultState: YardListState = {
      ...state,
      pagination: {
        ...state.pagination,
        pageSize,
        page: 0,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.searchChanged, (allState, action) => {
    const { listName, search } = action;
    const state = allState[listName];
    const resultState: YardListState = {
      ...state,
      pagination: {
        ...state.pagination,
        page: 0,
      },
      page: {
        ...state.page,
        totalFound: undefined,
      },
      currentSearch: search,
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.statusChanged, (allState, action) => {
    const { listName, status } = action;
    const state = allState[listName];
    const resultState: YardListState = {
      ...state,
      pagination: {
        ...state.pagination,
        page: 0,
      },
      page: {
        ...state.page,
        totalFound: undefined,
      },
      status,
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.filtersChanged, (allState, action) => {
    const { listName, filters } = action;
    const state = allState[listName];
    const resultState: YardListState = {
      ...state,
      pagination: {
        ...state.pagination,
        page: 0,
      },
      page: {
        ...state.page,
        totalFound: undefined,
      },
      filters,
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.staticFiltersChanged, (allState, action) => {
    const { listName, filters: staticFilters } = action;
    const state = allState[listName];
    let resultState: YardListState;
    if (areFiltersEqual(state.staticFilters, staticFilters)) {
      resultState = { ...state };
    } else {
      resultState = {
        ...initialYardListState,
        staticFilters,
      };
    }
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.selectedYardChanged, (allState, action) => {
    const { listName, yardId } = action;
    const state = allState[listName];
    const resultState: YardListState = {
      ...state,
      selectedYardId: yardId,
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  /**
   * Load yard list requested
   */
  on(fromActions.loadYardListRequested, (allState, action) => {
    const { listName } = action;
    const state = allState[listName];
    const resultState: YardListState = {
      ...state,
      page: {
        ...state.page,
        isLoading: true,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.loadYardListSuccess, (allState, action) => {
    const { listName, total, items } = action;
    const state = allState[listName];
    const resultState: YardListState = {
      ...state,
      page: {
        ...state.page,
        isLoading: false,
        totalFound: total,
        items,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.loadYardListFailed, (allState, action) => {
    const { listName, error } = action;
    const state = allState[listName];
    const resultState: YardListState = {
      ...state,
      page: {
        ...state.page,
        isLoading: false,
        listError: error,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
);

function areFiltersEqual(
  filters1: fromTypes.YardListFilter[] = [],
  filters2: fromTypes.YardListFilter[] = [],
): boolean {
  const hashFn = (f: fromTypes.YardListFilter) => f.id;
  return fromTypes.utils.arraysEqual(filters1, filters2, hashFn);
}
