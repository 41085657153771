<wp-icon-rides-green *ngIf="green"></wp-icon-rides-green>
<wp-icon-rides-grey *ngIf="!green"></wp-icon-rides-grey>
<!-- <svg
  class="icon"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  *ngIf="!green"
>
  <path
    fill="none"
    fill-rule="evenodd"
    stroke="#959597"
    stroke-width="2"
    d="M17.634 18.155L6.348 6.217l11.286 11.938C18.184 17.452 19.04 17 20 17c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3c0-.696.237-1.336.634-1.845zM4 7C2.343 7 1 5.657 1 4s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"
  />
</svg>

<svg
  width="24px"
  height="24px"
  viewBox="0 0 24 24"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  *ngIf="green"
>
  <g
    id="Phase-1-screens"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g
      id="2-element-/-icon-/-dashboard"
      transform="translate(1.000000, 1.000000)"
      stroke="#47A540"
      stroke-width="2"
    >
      <g id="2-element-/-icon-/-ic_ride">
        <path
          d="M19,16 C20.6568542,16 22,17.3431458 22,19 C22,20.6568542 20.6568542,22 19,22 C17.3431458,22 16,20.6568542 16,19 C16,17.3431458 17.3431458,16 19,16 Z M3,0 C4.65685425,0 6,1.34314575 6,3 C6,4.65685425 4.65685425,6 3,6 C1.34314575,6 0,4.65685425 0,3 C0,1.34314575 1.34314575,0 3,0 Z M5.34782609,5.2173913 C5.34782609,5.2173913 9.11594203,9.20289855 16.6521739,17.173913"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </g>
</svg> -->
