import { getFeatureState } from './feature-selector';
import { createSelector } from '@ngrx/store';

const getLocationsState = createSelector(getFeatureState, (state) => state.locationData);

/**
 * Location items
 */
export const getAllLocations = createSelector(getLocationsState, (state) => state.locations);

export const getSingleLocationState = (locationId) =>
  createSelector(getAllLocations, (state) => {
    return state[locationId];
  });

export const getLocation = (locationId) => createSelector(getSingleLocationState(locationId), (state) => state?.entity);

export const getLocationRemoving = (locationId) =>
  createSelector(getSingleLocationState(locationId), (state) => state?.isRemoving);

/**
 * Locations by campuses
 */
export const getAllLocationsByCampuses = createSelector(getLocationsState, (state) => state.locationsByCampuses);

export const getSingleLocationsByCampus = createSelector(
  getAllLocationsByCampuses,
  (state, props: { campusId: string }) => state[props.campusId],
);

export const getLocationIdsByCampus = createSelector(getSingleLocationsByCampus, (state): string[] => state?.entity);

export const getLocationsLoadingByCampus = createSelector(getSingleLocationsByCampus, (state) => state?.isLoading);

export const getLocationsErrorByCampus = createSelector(getSingleLocationsByCampus, (state) => state?.error);

/**
 * Locations by campuses
 */
export const getAllPrimaryLocations = createSelector(getLocationsState, (state) => state.defaultLocationsByCampuses);

const getPrimaryLocationStateByCampus = createSelector(
  getAllPrimaryLocations,
  (allPrimaryLocs, props: { campusId: string }) => {
    return allPrimaryLocs[props.campusId];
  },
);

export const getPrimaryLocationLoading = createSelector(
  getPrimaryLocationStateByCampus,
  (state) => state?.isLoading || false,
);
