import { Injectable } from '@angular/core';
import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { vehiclesRootPath } from './vehicles-root-path';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';
import { SerializedVehicleListSnapshot } from '@rootTypes/entities/vehicles';

const statePath = 'list';

export type VehicleListRouterData = Omit<SerializedVehicleListSnapshot, 'selectedVehicleId'>;

export const vehicleListRoute: PortalRoute<VehicleListRouterData> = {
  path: statePath,

  isOnRoute: (state: RouterStateUrl) => {
    return state && state.url && state.url.startsWith(`/${vehiclesRootPath}/${statePath}`);
  },

  parse: (state: RouterStateUrl): VehicleListRouterData => {
    const { page, pageSize, search, filters } = state?.queryParams || {};
    const result: VehicleListRouterData = {};
    result.page = page || '1';
    result.pageSize = pageSize || '10';
    result.search = search || '';
    result.filters = filters || null;
    return result;
  },

  request: (data?: VehicleListRouterData): NavigationRequest => {
    const request: NavigationRequest = {
      path: ['/', vehiclesRootPath, statePath],
    };
    if (data) {
      request.query = { ...data };
    }
    return request;
  },

  getHref(data?: VehicleListRouterData): string {
    return `/${vehiclesRootPath}/${statePath}`;
  },
};

@Injectable({
  providedIn: 'root',
})
export class VehicleListRouterService extends PortalRouterService<VehicleListRouterData> {
  outlet = RouterOutlets.PRIMARY;
  route = vehicleListRoute;
}
