import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as fromSelectors from '../../selectors/campus-details/students-tab.selectors';
import * as fromActions from '../../actions/campus-details/students-tab.actions';
import { State } from '../../../../../store';
import { Store } from '@ngrx/store';

@Injectable()
export class StudentsTabFacade {
  constructor(private store: Store<State>) {}

  public getSelectedStudentId$(): Observable<string> {
    return this.store.select(fromSelectors.getSelectedStudentId);
  }

  public onSelectStudentId(studentId: string): void {
    this.store.dispatch(fromActions.selectStudentId({ studentId }));
  }
}
