import { FilterDeserializer } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';
import { StudentRfidListRequestTypeFilter } from '../types/student-rfid-list';
import { StudentRfidRequestType } from '@apiEntities';
import { createStudentRfidListRequestTypeFilter } from './create-student-rfid-list-request-type-filter';

export const deserializeStudentRfidListRequestTypeFilter: FilterDeserializer<StudentRfidListRequestTypeFilter> = (
  str: string,
): StudentRfidListRequestTypeFilter | undefined => {
  if (str && str.startsWith('request-type')) {
    const value = str.split(',')[1] as StudentRfidRequestType;
    return createStudentRfidListRequestTypeFilter(value);
  }
  return undefined;
};
