import { MapBounds } from '@rootTypes';

export const GOOGLE_AUTOCOMPLETE_DEBOUNCE = 500;

// San Jose
export const GOOGLE_AUTOCOMPLETE_DEFAULT_BIAS: MapBounds = {
  southWest: {
    lat: 37.06895739769219,
    lng: -122.25214251093752,
  },
  northEast: {
    lat: 37.85813042281522,
    lng: -121.99396380000002,
  },
};
