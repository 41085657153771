import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WindowTitleService {
  constructor() {}

  public setTitle(titleText: string): void {
    const title = this.getTitle();
    title.innerText = titleText;
  }

  private getTitle(): HTMLElement {
    return document.querySelector('title');
  }
}
