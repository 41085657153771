import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import { Observable } from 'rxjs';
import * as fromActions from '../actions/employee-data.actions';
import * as fromSelectors from '../selectors/employee-data.selectors';
import * as fromTypes from '../../types';

@Injectable()
export class EmployeeDataFacade {
  constructor(private store: Store<State>) {}

  public getEmployee$(employeeId: string): Observable<fromTypes.VendorEmployee> {
    return this.store.select(fromSelectors.getEmployee, { employeeId });
  }

  public getEmployeeLoading$(employeeId: string): Observable<boolean> {
    return this.store.select(fromSelectors.getEmployeeLoading, { employeeId });
  }

  public getEmployeeError$(employeeId: string): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getEmployeeError, { employeeId });
  }

  public onLoadEmployeeRequested(employeeId: string): void {
    this.store.dispatch(fromActions.loadEmployeeRequested({ employeeId }));
  }
}
