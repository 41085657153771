import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, Subscription, timer } from 'rxjs';
import { map, shareReplay, startWith, take } from 'rxjs/operators';
import * as fromTypes from '@rootTypes';
import { DropdownComponent } from '../dropdown/dropdown/dropdown.component';
import { dateToYYYYMMDD, formattingConstants } from '@rootTypes/utils/common/date-time-fns';
import { ErrorStateMatcher } from '@angular/material/core';
import { isEnterEvent, isEscapeEvent } from '@rootTypes/utils/common/dom';

/**
 * Input control for single date selection. Binds to formControl of YYYYMMDD formatted string
 */
@Component({
  selector: 'wp-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputDateComponent implements OnInit, OnDestroy {
  @Input() public label: string;
  @Input() public control: UntypedFormControl = new UntypedFormControl();
  @Input() public controlType: 'yyyymmdd' | 'date' = 'yyyymmdd';
  @Input() public isIcon: boolean;
  @Input() public monthCount = 2;
  @Input() public displayDateFormat: keyof typeof formattingConstants = 'dateAndWeekdayMedium';
  @Input() public alignDropdown: 'left' | 'right' | 'center';
  @Input() public closeOnSelect: boolean;
  @Input() public disableDatesBefore: fromTypes.utils.date.YYYYMMDDString;
  @Input() public disableDatesAfter: fromTypes.utils.date.YYYYMMDDString;
  @Input() public widthStr = 'auto';
  @Input() public tabIndex = '0';
  @Input() public isStartFromMonday = true;
  /**
   * If the trigger is less than this distance from viewport bottom,
   * will display the datepicker at the top of the input
   */
  @Input() public dropdownNearBottomThreshold = 300;

  public dropdownVerticalAlignment: 'top' | 'bottom' = 'bottom';
  public dropdownVerticalOffset = 'calc(100% - 23px)';
  public selectedDate$: Observable<fromTypes.YYYYMMDDString>;
  public selectedDateArr$: BehaviorSubject<fromTypes.YYYYMMDDString[]> = new BehaviorSubject([]);
  public displayDateControl = new UntypedFormControl(null);
  public isDisabled$: Observable<boolean>;
  public highlighted$: Observable<boolean>;
  public subscripitions = new Subscription();
  public isDropdownFocused = false;
  public errorStateMatcher: ErrorStateMatcher = {
    isErrorState: () => {
      return this.control.invalid && this.control.touched;
    },
  };
  private closeDropdownTimerHandle: any;

  @ViewChild('maskEl') private triggerMaskEl: ElementRef;
  @ViewChild('inputElWrap') private inputEl: ElementRef;
  @ViewChild('dropdownEl') private dropdownEl: DropdownComponent;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.subscripitions.unsubscribe();
  }

  ngOnInit(): void {
    this.checkInputFormat();
    this.highlighted$ = this.getHighLightedObs();
    this.isDisabled$ = this.getDisabledObs$();
    this.selectedDate$ = this.control.valueChanges.pipe(
      startWith(this.control?.value),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
    const dateToArraySub = this.selectedDate$.subscribe((date) => {
      const dateArr: (string | Date)[] = date ? [date] : [];
      const yyyyMMDDArr = dateArr.map((s) => {
        if (typeof s === 'string') {
          return s;
        } else {
          return dateToYYYYMMDD(s);
        }
      });
      this.selectedDateArr$.next(yyyyMMDDArr);
    });
    this.subscripitions.add(dateToArraySub);
    if (this.control) {
      const sub = this.control.valueChanges.pipe(startWith(this.control.value)).subscribe((val) => {
        if (val) {
          const yyyyMMDD = this.getYYYYMMDDValue(val);
          const formatter = new fromTypes.utils.date.YearMonthDay(yyyyMMDD);
          this.displayDateControl.setValue(formatter.formatTo(this.displayDateFormat));
        } else {
          this.displayDateControl.setValue(null);
        }
      });
      const sub2 = this.control.statusChanges.subscribe((status) => {
        this.displayDateControl.setValidators(this.control.validator);
        if (status === 'INVALID') {
          this.displayDateControl.setErrors(this.control.errors);
          this.displayDateControl.markAsTouched();
        } else {
          this.displayDateControl.setErrors(null);
          this.displayDateControl.updateValueAndValidity();
        }
        this.cd.detectChanges();
      });
      const sub3 = this.isDisabled$.subscribe((isDisabled) => {
        if (isDisabled) {
          this.displayDateControl.disable();
        } else {
          this.displayDateControl.enable();
        }
      });
      this.subscripitions.add(sub);
      this.subscripitions.add(sub2);
    }
  }

  public onTriggerClick(event?: MouseEvent): void {
    this.isDisabled$.pipe(take(1)).subscribe((disabled) => {
      if (!disabled) {
        this.displayDateControl.markAsTouched();
        this.setDropdownVerticalAlignment();
      } else {
        if (event) {
          event.stopPropagation();
        }
      }
    });
  }

  public onCloseDropdown(): void {
    this.control.markAsTouched();
    this.displayDateControl.updateValueAndValidity();
    this.control.updateValueAndValidity();
  }

  public onApplyClicked(): void {
    if (this.dropdownEl) {
      this.dropdownEl.onCloseDropdown();
    }
  }

  public onValueChangedByUserAction(val: fromTypes.YYYYMMDDString[]): void {
    const sourceValue = (val || [])[0];
    const targetValue = this.getControlValueFromYYYYMMDD(sourceValue);
    this.control.setValue(targetValue);
    if (this.closeOnSelect) {
      this.onApplyClicked();
    }
  }

  private getHighLightedObs(): Observable<boolean> {
    const valid$ = this.control?.statusChanges.pipe(
      startWith('VALID'),
      map((status) => status !== 'INVALID'),
    );
    return combineLatest([valid$]).pipe(map(([valid]) => valid));
  }

  private checkInputFormat(): void {
    const value = this.control?.value;
    if (value !== null && value !== undefined) {
      if (value instanceof Date) {
        return;
      }
      if (!(typeof value === 'string')) {
        throw new Error(
          'Invalid value provided to input date. Should be YYYYMMDD string. Got ' + typeof value + ' instead.',
        );
      }
      if (!(value.length === 8)) {
        throw new Error('Invalid string provided to input date. Should be YYYYMMDD string. Got ' + value + ' instead.');
      }
    }
  }

  private getDisabledObs$(): Observable<boolean> {
    return timer(0, 600).pipe(
      map(() => this.control.disabled),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private getYYYYMMDDValue(source: fromTypes.YYYYMMDDString | Date): fromTypes.YYYYMMDDString {
    if (this.controlType === 'yyyymmdd') {
      return source as fromTypes.YYYYMMDDString;
    } else {
      return fromTypes.utils.date.YearMonthDay.fromDate(source as Date);
    }
  }

  private getControlValueFromYYYYMMDD(source: fromTypes.YYYYMMDDString): Date | fromTypes.YYYYMMDDString {
    if (!source) {
      return undefined;
    }
    if (this.controlType === 'yyyymmdd') {
      return source;
    } else {
      const formatter = new fromTypes.utils.date.YearMonthDay(source);
      return formatter.toDate();
    }
  }

  private setDropdownVerticalAlignment(): void {
    const distanceFromBottom = fromTypes.utils.dom.getDistanceFromWindowBottom(this.inputEl.nativeElement);
    if (distanceFromBottom > this.dropdownNearBottomThreshold) {
      this.dropdownVerticalAlignment = 'bottom';
      this.dropdownVerticalOffset = 'calc(100% - 23px)';
    } else {
      this.dropdownVerticalAlignment = 'top';
      this.dropdownVerticalOffset = 'calc(100% - 8px)';
    }
    this.cd.detectChanges();
  }

  onClearInput() {
    this.control.setValue(undefined);
    if (this.dropdownEl) {
      this.dropdownEl.onCloseDropdown();
    }
  }

  onInputFocusOut() {
    if (this.closeDropdownTimerHandle) {
      clearTimeout(this.closeDropdownTimerHandle);
    }
    this.closeDropdownTimerHandle = setTimeout(() => {
      if (!this.isDropdownFocused) {
        this.dropdownEl.closeDropdown();
      }
    }, 200);
  }

  onInputFocusIn() {
    if (this.closeDropdownTimerHandle) {
      clearTimeout(this.closeDropdownTimerHandle);
    }
  }

  onDropdownFocusIn() {
    if (this.closeDropdownTimerHandle) {
      clearTimeout(this.closeDropdownTimerHandle);
    }
    this.isDropdownFocused = true;
  }

  onDropdownFocusOut() {
    this.isDropdownFocused = false;
    if (this.closeDropdownTimerHandle) {
      clearTimeout(this.closeDropdownTimerHandle);
    }
    this.closeDropdownTimerHandle = setTimeout(() => {
      if (!this.isDropdownFocused) {
        this.dropdownEl.closeDropdown();
      }
    }, 200);
  }

  onInputKeyUp(event: KeyboardEvent) {
    event.stopPropagation();
    if (isEnterEvent(event) && !this.control?.disabled) {
      this.dropdownEl.openDropdown();
    } else if (isEscapeEvent(event)) {
      this.dropdownEl.closeDropdown();
    }
  }
}
