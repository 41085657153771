import * as fromCampus from './campuses';
import * as fromCommon from './common';
import * as fromDistricts from './districts';
import * as fromDrivers from './drivers';
import * as fromFlash from './flash';
import * as fromRides from './rides';
import * as fromRoutes from './routes';
import * as fromStudents from './students';
import * as fromVehicles from './vehicles';
import * as fromVendors from './vendors';
import * as fromEmployees from './employees-vendor';
import * as fromSchoolEmployees from './employees-school';
import * as fromYards from './yards';
import { DriverAggregationModule } from './driver-aggregation';
import { RideAssignmentStoreModule } from './rides/ride-assignment/ride-assignment-store.module';
import { StudentChangeRequestsStoreModule } from './student-change-requests/student-change-requests-store.module';
import { TimesheetEntryDataStoreModule } from './timesheet-entries/timesheet-entry-data-store.module';
import { VehicleInspectionsStoreModule } from './vehicle-inspections/vehicle-inspections-store.module';
import { RouteGroupDataModule } from './route-groups/route-group-data.module';
import { TripsDataModule } from './trips/trips-data.module';

export const eagerlyLoadedModules = [
  fromCampus.CampusesDataModule,
  fromCommon.CommonDataModule,
  fromDistricts.DistrictDataModule,
  fromFlash.FlashModule,
  fromDrivers.DriversDataModule,
  fromEmployees.EmployeeDataModule,
  fromSchoolEmployees.EmployeeSchoolDataModule,
  DriverAggregationModule,
  fromRides.RidesDataModule,
  fromRoutes.RoutesDataModule,
  fromStudents.StudentDataModule,
  fromVehicles.VehiclesDataModule,
  fromVendors.VendorsDataModule,
  fromYards.YardsDataModule,
  RideAssignmentStoreModule,
  StudentChangeRequestsStoreModule,
  RouteGroupDataModule,
  TimesheetEntryDataStoreModule,
  VehicleInspectionsStoreModule,
  TripsDataModule,
];
