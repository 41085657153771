import * as routeGroups from './route-group.reducer';
import * as fromScheduleChangeRequests from './route-group-schedule-change-request.reducer';
import * as fromRouteGroupOwnerHistory from './route-group-owner-history.reducer';
import * as fromPaymentConfigs from './route-group-activity-payment-config.reducer';
import { combineReducers } from '@ngrx/store';
export interface RouteGroupDataFeatureState {
  routeGroups: routeGroups.RouteGroupDataState;
  scheduleChangeRequests: fromScheduleChangeRequests.RouteGroupScheduleChangeRequestState;
  ownerHistory: fromRouteGroupOwnerHistory.RouteGroupOwnerHistoryState;
  paymentConfigs: fromPaymentConfigs.RouteGroupActivityPaymentConfigState;
}

export const routeGroupDataFeatureReducer = combineReducers<RouteGroupDataFeatureState>({
  routeGroups: routeGroups.routeGroupDataReducer,
  scheduleChangeRequests: fromScheduleChangeRequests.routeGroupScheduleChangeRequestReducer,
  ownerHistory: fromRouteGroupOwnerHistory.routeGroupOwnerHistoryReducer,
  paymentConfigs: fromPaymentConfigs.routeGroupActivityPaymentConfigReducer,
});
