import * as fromEntities from '../../../../types/entities/rider';
import { Rider } from '../rider';
import { getValidStudentClassGrade } from './get-valid-student-class-grade';
import { WithEquipment } from '../with-equipment';

export const getRiderEquipment = (source: WithEquipment): fromEntities.RiderEquipmentMap => {
  if (!source) {
    return null;
  }
  const target = {} as fromEntities.RiderEquipmentMap;
  if (source.equipment) {
    target.isCarSeat = source.equipment.car_seat;
    target.isBoosterSeat = source.equipment.booster_seat;
    target.isSeatBeltGuard = source.equipment.seat_belt_guard;
    target.isWalkerSupport = source.equipment.walker;
    target.isHarness = source.equipment.harness;
  }
  if (source.tags) {
    target.isSpecialNeeds = source.tags.special_care === 'true';
    target.isWheelchair = source.tags.wheelchair === 'true';
    target.isAide = source.tags.aide === true;
  }
  return target;
};

export const dbToRider = (source: Rider): fromEntities.Rider => {
  const rider = {
    riderId: source.rider_id,
    firstName: source.first_name,
    lastName: source.last_name,
    equipment: getRiderEquipment(source),
    grade: getValidStudentClassGrade(source.class_grade),
  } as fromEntities.Rider;
  if (source.profile_pic_url) {
    rider.photo = source.profile_pic_url;
  }
  return rider;
};
