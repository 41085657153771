import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromStore from './store';
import * as fromServices from './services';

@NgModule({
  declarations: [],
  providers: [...fromStore.facades.employeeFacades, ...fromServices.featureServices],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers.employeesReducer),
    EffectsModule.forFeature(fromStore.effects.employeeEffects),
  ],
})
export class EmployeeDataModule {}
