import { CampusLevel } from '../campus/campus-level';
import { EntitySearchResponseType as PortalEntityType, EntitySearchResult } from '@apiEntities/entity-search';
export { PortalEntityType };

type ResultOfType<T extends PortalEntityType> = Extract<EntitySearchResult, { type: T }>;

export type PortalEntity = {
  entityId: string;
  type: PortalEntityType;
  label?: string;
};

export type ExtendedPortalEntity<T extends PortalEntityType> = PortalEntity & ResultOfType<T>;

export enum EntityStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ANY = 'ANY',
}

/**
 * We can extend the base portal entity with additional data
 */
export type VendorPortalEntity = PortalEntity;

export interface VehiclePortalEntity extends PortalEntity {
  makeAndModel: string;
  licenseStateAndNumber: string;
  assignedDriverText: 'Assigned' | 'Unassigned';
  assignedDriverId?: string;
  imagePath?: string;
  vehicleDisplayId?: string;
  make?: string;
}

export interface CampusPortalEntity extends PortalEntity {
  districtId: string;
  campusLevels?: CampusLevel[]; // this field should be added by BE.
}

export interface SchoolEmployeePortalEntity extends PortalEntity {
  email: string;
  organizationName?: string;
}
