import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { iconPaths } from '@rootTypes/utils';

@Component({
  selector: 'wp-alert-with-icon-info',
  template: `
    <div class="change-request-review-warning" [ngStyle]="{ height: height }">
      <div class="warning-icon-wrap" [ngStyle]="{ paddingTop: iconPaddingTop }" [ngClass]="{ grey: isGreyTheme }">
        <wp-portal-icon *ngIf="largeIcon" [path]="blueIconPathLarge"></wp-portal-icon>
        <wp-portal-icon *ngIf="!largeIcon" [path]="isGreyTheme ? greyIcon : blueIcon"></wp-portal-icon>
      </div>
      <div class="warning-text" [ngStyle]="{ paddingTop: warningTextPaddingTop }">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      .change-request-review-warning {
        display: flex;
        padding: 4px 0;
        border-top: 1px solid #dfdfe3;
        border-bottom: 1px solid #dfdfe3;
        box-sizing: border-box;
      }

      .warning-icon-wrap {
        width: 32px;
        background-color: rgb(235, 242, 253);
        display: flex;
        justify-content: center;
        margin-right: 10px;
      }
      .warning-icon-wrap.grey {
        background-color: #f2f2f2;
      }
      .warning-text {
        width: fit-content;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertWithIconInfoComponent {
  @Input() public height = '68px';
  @Input() public iconPaddingTop = '16px';
  @Input() public warningTextPaddingTop = '8px';
  @Input() public largeIcon = false;
  @Input() public isGreyTheme = false;
  constructor() {}

  public blueIcon = iconPaths.INFO_CIRCLE;
  public blueIconPathLarge = iconPaths.INFO_CIRCLE_20;
  public greyIcon = iconPaths.INFO_CIRCLE_GREY;
  // TODO: Add large gray icon
}
