import { NgModule } from '@angular/core';
import * as fromStore from './store';
import * as fromServices from './services';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  providers: [...fromStore.facades.yardsDataFacades, ...fromServices.featureServices],
  imports: [
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers.yardFeatureReducer),
    EffectsModule.forFeature(fromStore.effects.yardsFeatureEffects),
  ],
})
export class YardsDataModule {}
