import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { NavigationRequest } from '../../types/navigation-request';
import { CampusDetailsTab } from '../../../types/entities/campus';

export interface CampusDetailsRouteData {
  campusId: string;
  tab?: CampusDetailsTab;
  displayBackText?: string;
}

export const campusDetailsRoute: PortalRoute<CampusDetailsRouteData> = {
  path: 'list',
  isOnRoute: (state: RouterStateUrl): boolean => {
    throw new Error('Not implemented');
  },
  parse: (state: RouterStateUrl): CampusDetailsRouteData => {
    let data: CampusDetailsRouteData = {
      campusId: state.drawer.params.campusId,
      tab: getTabIdFromFromUrl(state.drawer.url),
    };
    if (state.queryParams) {
      data = {
        ...data,
        ...state.queryParams,
      };
    }
    return data;
  },
  request: (
    data: CampusDetailsRouteData,
    queryParamStrategy?: QueryParamsHandlingStrategy,
    replaceUrl?,
  ): NavigationRequest => {
    const req: NavigationRequest = {
      path: ['_', 'drawer', 'campuses', data.campusId, 'details', data.tab || CampusDetailsTab.DEFAULT],
    };
    delete data.campusId;
    delete data.tab;
    const query = { ...data };
    if (Object.keys(query).length) {
      req.query = query;
    }
    if (queryParamStrategy) {
      req.extras = {
        queryParamsHandling: queryParamStrategy,
      };
    }
    if (replaceUrl) {
      req.extras = req.extras || {};
      req.extras.replaceUrl = true;
    }
    return req;
  },
};

function getTabIdFromFromUrl(url: string): CampusDetailsTab {
  if (!url) {
    return null;
  }
  const segments = url.split('/');
  return segments[5] as CampusDetailsTab;
}
