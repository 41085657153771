import { DateRange, YYYYMMDDString } from '@rootTypes';
import { datesGetSpan, dateToYYYYMMDD, yyyymmddToDate } from '@rootTypes/utils';

export const dateRangeToArray = (dateRange: DateRange): YYYYMMDDString[] => {
  if (dateRange && dateRange?.startDate && dateRange?.endDate) {
    return datesGetSpan(yyyymmddToDate(dateRange?.startDate), yyyymmddToDate(dateRange?.endDate)).map((date) =>
      dateToYYYYMMDD(date),
    );
  }
  return [];
};
