<div class="wp-input-phone">
  <mat-form-field style="width: 100%" [hideRequiredMarker]="true">
    <mat-label *ngIf="!!label">{{label}}</mat-label>
    <div matPrefix></div>
    <input
      matInput
      type="text"
      autocomplete="off"
      [(ngModel)]="displayValue"
      (ngModelChange)="onUserInputChange($event)"
      [required]="required"
      [disabled]="isDisabled"
      [mask]="'(000) 000 0000'"
      [errorStateMatcher]="errorMatcher"
      tabindex="{{tabIndex}}"
    />
    <div matSuffix></div>
    <mat-error *ngIf="control.invalid && control.hasError('required')">
      Required field
    </mat-error>
    <mat-error
      *ngIf="control.invalid && control.hasError('invalidPhoneNumber')"
    >
      Invalid phone number
    </mat-error>
  </mat-form-field>
</div>
