import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-no-view-permissions',
  templateUrl: './no-view-permissions.component.html',
  styleUrls: ['./no-view-permissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoViewPermissionsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
