import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { vendorListRoute, VendorListRouteData } from './vendor-list-routes';
import { RouterOutlets } from '../../../types/entities/router';

const route = vendorListRoute;

@Injectable({ providedIn: 'root' })
export class VendorListRouterService extends PortalRouterService<VendorListRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route = route;
}
