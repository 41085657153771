import * as fromTypes from '../../types';
import * as fromActions from '../actions/campus-calendar-data.actions';
import { createReducer, on } from '@ngrx/store';

export interface CampusCalendarDataState {
  calendars: {
    [calendarId: string]: {
      isRemoving?: boolean;
      isRemoved?: boolean;
      removeError?: fromTypes.WpError;
      entity: fromTypes.CampusCalendar;
    };
  };
  // load calendars by campus
  calendarsByCampuses: {
    [campusId: string]: fromTypes.EntityState<string[]>;
  };
  defaultCalendarsByCampuses: {
    [campusId: string]: {
      calendarId: string;
      isLoading: boolean;
      error?: fromTypes.WpError | undefined;
    };
  };
  schedulesByCalendars: {
    [calendarId: string]: string[];
  };
  schedules: {
    [scheduleId: string]: {
      isRemoving?: boolean;
      isRemoved?: boolean;
      removeError?: fromTypes.WpError;
      entity: fromTypes.CampusSchedule;
    };
  };
}

export const initialCampusCalendarDataState: CampusCalendarDataState = {
  calendars: {},
  calendarsByCampuses: {},
  defaultCalendarsByCampuses: {},
  schedules: {},
  schedulesByCalendars: {},
};

export const campusCalendarDataReducer = createReducer<CampusCalendarDataState>(
  { ...initialCampusCalendarDataState },
  on(
    fromActions.loadCampusCalendarsRequested,
    fromActions.loadCampusCalendarsRequestedFromCampusCalendarEditor,
    fromActions.loadCampusCalendarsRequestedFromCampusScheduleEditor,
    (state, action) => {
      const { campusId } = action;
      const entityState = state.calendarsByCampuses[campusId] || {
        isLoading: false,
        entity: null,
        error: null,
      };
      const res: CampusCalendarDataState = {
        ...state,
        calendarsByCampuses: {
          ...state.calendarsByCampuses,
          [campusId]: {
            ...entityState,
            isLoading: true,
          },
        },
      };
      return res;
    },
  ),
  on(fromActions.loadCampusCalendarsSuccess, (state, action) => {
    const { campusId, payload } = action;
    const { calendars, schedules, schedulesByCalendars } = payload;
    const calendarIds = calendars.map((c) => c.id);
    const defaultCalendarId = calendars.find((c) => c.isDefault)?.id;
    const newCalendars = calendars.reduce((prev, curr) => {
      return { ...prev, [curr.id]: { entity: curr } };
    }, {});
    const newSchedules = schedules.reduce((prev, curr) => {
      return { ...prev, [curr.id]: { entity: curr } };
    }, {});
    const res: CampusCalendarDataState = {
      ...state,
      defaultCalendarsByCampuses: {
        ...state.defaultCalendarsByCampuses,
        [campusId]: {
          calendarId: defaultCalendarId,
          isLoading: false,
        },
      },
      calendars: {
        ...state.calendars,
        ...newCalendars,
      },
      schedules: {
        ...state.schedules,
        ...newSchedules,
      },
      calendarsByCampuses: {
        ...state.calendarsByCampuses,
        [campusId]: {
          isLoading: false,
          entity: calendarIds,
          error: null,
        },
      },
      schedulesByCalendars: {
        ...state.schedulesByCalendars,
        ...schedulesByCalendars,
      },
    };
    return res;
  }),
  on(fromActions.loadCampusCalendarsFailed, (state, action) => {
    const { campusId, error } = action;
    return {
      ...state,
      calendarsByCampuses: {
        [campusId]: {
          isLoading: false,
          error,
        },
      },
    };
  }),
  on(fromActions.makeCalendarDefaultRequested, (state, action) => {
    const { campusId } = action;
    const defaultState = state.defaultCalendarsByCampuses[campusId] || { isLoading: false, calendarId: null };
    const res: CampusCalendarDataState = {
      ...state,
      defaultCalendarsByCampuses: {
        ...state.defaultCalendarsByCampuses,
        [campusId]: {
          ...defaultState,
          isLoading: true,
        },
      },
    };
    return res;
  }),
  on(fromActions.makeCalendarDefaultSuccess, (state, action) => {
    const { campusId, calendarId } = action;
    const defaultState = state.defaultCalendarsByCampuses[campusId] || { isLoading: false, calendarId: null };
    const res: CampusCalendarDataState = {
      ...state,
      defaultCalendarsByCampuses: {
        ...state.defaultCalendarsByCampuses,
        [campusId]: {
          ...defaultState,
          isLoading: false,
          calendarId,
        },
      },
    };
    return res;
  }),
  on(fromActions.makeCalendarDefaultFailed, (state, action) => {
    const { campusId, error } = action;
    const defaultState = state.defaultCalendarsByCampuses[campusId] || { isLoading: false, calendarId: null };
    const res: CampusCalendarDataState = {
      ...state,
      defaultCalendarsByCampuses: {
        ...state.defaultCalendarsByCampuses,
        [campusId]: {
          ...defaultState,
          isLoading: false,
          error,
        },
      },
    };
    return res;
  }),
  /**
   * Remove calendars
   */
  on(fromActions.removeCalendarRequested, (state, action) => {
    const { calendarId } = action;
    const calendarState = state.calendars[calendarId] || { entity: null, isRemoving: false };
    const res: CampusCalendarDataState = {
      ...state,
      calendars: {
        ...state.calendars,
        [calendarId]: {
          ...calendarState,
          isRemoving: true,
        },
      },
    };
    return res;
  }),
  on(fromActions.removeCalendarSuccess, (state, action) => {
    const { calendarId, campusId } = action;
    const calendarState = state.calendars[calendarId] || { entity: null, isRemoving: false };
    const calendarsByCampuses = state.calendarsByCampuses[campusId].entity;
    const newCalendarsByCampuses = calendarsByCampuses.filter((id) => id !== calendarId);
    const res: CampusCalendarDataState = {
      ...state,
      calendars: {
        ...state.calendars,
        [calendarId]: {
          ...calendarState,
          isRemoving: false,
          isRemoved: true,
        },
      },
      calendarsByCampuses: {
        ...state.calendarsByCampuses,
        [campusId]: {
          isLoading: false,
          entity: newCalendarsByCampuses,
        },
      },
    };
    return res;
  }),
  on(fromActions.removeCalendarFailed, (state, action) => {
    const { calendarId, error } = action;
    const calendarState = state.calendars[calendarId] || { entity: null, isRemoving: false };
    const res: CampusCalendarDataState = {
      ...state,
      calendars: {
        ...state.calendars,
        [calendarId]: {
          ...calendarState,
          isRemoving: false,
          isRemoved: false,
          removeError: error,
        },
      },
    };
    return res;
  }),
  /**
   * Remove schedule
   */
  on(fromActions.removeScheduleRequested, (state, action) => {
    const { scheduleId } = action;
    const scheduleState = state.schedules[scheduleId] || { entity: null, isRemoving: false };
    const res: CampusCalendarDataState = {
      ...state,
      schedules: {
        ...state.schedules,
        [scheduleId]: {
          ...scheduleState,
          isRemoving: true,
        },
      },
    };
    return res;
  }),
  on(fromActions.removeScheduleSuccess, (state, action) => {
    const { scheduleId, calendarId } = action;
    const scheduleState = state.schedules[scheduleId] || { entity: null, isRemoving: false };
    const schedulesByCalendar = state.schedulesByCalendars[calendarId];
    const newSchedulesByCalendar = schedulesByCalendar.filter((id) => id !== scheduleId);
    const res: CampusCalendarDataState = {
      ...state,
      schedules: {
        ...state.schedules,
        [scheduleId]: {
          ...scheduleState,
          isRemoving: false,
          isRemoved: true,
        },
      },
      schedulesByCalendars: {
        ...state.schedulesByCalendars,
        [calendarId]: newSchedulesByCalendar,
      },
    };
    return res;
  }),
  on(fromActions.removeScheduleFailed, (state, action) => {
    const { scheduleId, error } = action;
    const scheduleState = state.schedules[scheduleId] || { entity: null, isRemoving: false };
    const res: CampusCalendarDataState = {
      ...state,
      schedules: {
        ...state.schedules,
        [scheduleId]: {
          ...scheduleState,
          isRemoving: false,
          isRemoved: false,
          removeError: error,
        },
      },
    };
    return res;
  }),
);
