import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../actions/vehicle-data.actions';
import * as fromDriverDataActions from '../../../drivers/store/actions/driver-data.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { VehiclesApiService } from '../../services/vehicles-api.service';
import { of } from 'rxjs';

@Injectable()
export class VehicleDataEffects {
  constructor(
    private actions: Actions,
    private api: VehiclesApiService,
    private store: Store,
  ) {}

  public loadVehicleRequested$ = createEffect(() =>
    this.actions.pipe(
      ofType(fromActions.loadVehicleRequested),
      mergeMap((action) => {
        const { vehicleId, options } = action;
        return this.api.getVehicle(vehicleId, options).pipe(
          map((response) => {
            // It is important to update the associated store before the vehicle store
            if (options?.withDriver && !!response.driver) {
              this.store.dispatch(
                fromDriverDataActions.loadDriverSuccess({ driver: response.driver, requestOptions: {} }),
              );
            }

            return fromActions.loadVehicleSuccess({ ...response, options });
          }),
          catchError((err) => {
            console.log(err);
            return of(
              fromActions.loadVehicleFailed({
                vehicleId,
                error: {
                  text: 'Failed to load vehicle',
                  originalError: err,
                },
              }),
            );
          }),
        );
      }),
    ),
  );
}
