import { District } from '@rootTypes/entities/districts';
import { Contact } from '../common';
import { ContactPerson } from '@rootTypes';
import { CreateDistrictRequest } from './index';

export const getCreateDistrictRequest = (source: District): CreateDistrictRequest => {
  const target: CreateDistrictRequest = {
    name: source.name,
    address: source.address,
    websiteUrl: source.website,
  } as CreateDistrictRequest;
  if (source.photo) {
    target.logoPath = source.photo;
  }
  if (isAnyContactInfo(source.contactPerson)) {
    target.contact = {} as Contact;
    if (source.contactPerson.name) {
      target.contact.name = source.contactPerson.name;
    }
    if (source.contactPerson.phone) {
      target.contact.phoneNumber = source.contactPerson.phone;
    }
    if (source.contactPerson.position) {
      target.contact.position = source.contactPerson.position;
    }
  }
  target.grades = {
    selectedStandardGradeIds: source.grades.selectedStandardGradeIds,
    customGradeValues: source.grades.customGrades.map((g) => g.value),
  };
  return target;
};

function isAnyContactInfo(contactPerson: ContactPerson): boolean {
  return !!contactPerson && !!(contactPerson.phone || contactPerson.name || contactPerson.position);
}
