import { createAction, props } from '@ngrx/store';
import { PortalEntity, PortalEntityType, WpError } from '@rootTypes';

export const getPortalEntityForActivityList = createAction(
  '[ActivityList]: Get portal entity',
  props<{ entityType: PortalEntityType; entityId: string }>(),
);

export const getPortalEntityInfo = createAction(
  '[Common]: Get portal entity info',
  props<{ entityType: PortalEntityType; entityId: string }>(),
);

export const getPortalEntityActions = [getPortalEntityForActivityList, getPortalEntityInfo];

export const getPortalEntitySuccess = createAction(
  '[CommonDataEffects]: API get portal entity success',
  props<{ entity: PortalEntity }>(),
);

export const setPortalEntityInfo = createAction(
  '[CommonDataEffects]: Set portal entity info',
  props<{ entities: PortalEntity[] }>(),
);

export const getPortalEntityFailed = createAction(
  '[CommonDataEffects]: API get portal entity failed',
  props<{ error: WpError; entityId: string; entityType: PortalEntityType }>(),
);
