import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, Observable } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import { AuthApiService } from '../../services/auth-api.service';
import * as fromActions from '../actions';
import * as fromRootStore from '@rootStore';
import { commonRoutes } from '@router';
import { LocalStorageService } from 'src/app/core/services';
import * as fromApi from '../../../api';
import { AuthState } from '../reducers';

@Injectable()
export class AccountEffects {
  constructor(
    private authApiService: AuthApiService,
    private actions$: Actions,
    private localStorage: LocalStorageService,
    private store: Store<AuthState>,
    private router: Router,
  ) {}

  public homeGuardAccountRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.homeGuardAccountRequested),
      switchMap(() => {
        return this.authApiService.getAccount().pipe(
          map((res) => {
            return fromActions.homeGuardAccountSuccess({
              data: res.account,
              permissions: res.permissions,
              vendor: res.vendor,
              district: res.district,
            });
          }),
          catchError((error) => {
            return of(
              fromActions.homeGuardAccountFailed({
                error: {
                  text: 'Failed to get account',
                  originalError: error,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  public homeGuardAccountFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.homeGuardAccountFailed),
      map(() => fromActions.logoutOnHomeGuardAccountFailed()),
    ),
  );

  public selectAccountGuardAccountRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectAccountGuardAccountRequested),
      switchMap(() => {
        return this.authApiService.getAccount().pipe(
          map((res) => {
            return fromActions.selectAccountGuardAccountSuccess({
              data: res.account,
              permissions: res.permissions,
              vendor: res.vendor,
              district: res.district,
            });
          }),
          catchError((error) => {
            return of(
              fromActions.selectAccountGuardAccountFailed({
                error: {
                  text: 'Failed to get account',
                  originalError: error,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  public homeGuardAccountsRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.homeGuardAccountsRequested),
      switchMap(() => {
        return this.getAccounts().pipe(
          map((data) => {
            if (Array.isArray(data) && data.length) {
              return fromActions.homeGuardAccountsSuccess({ data });
            } else {
              return fromActions.homeGuardAccountsFailed({
                error: {
                  text: 'Failed to get accounts',
                  originalError: 'No accounts found for the user',
                },
              });
            }
          }),
          catchError((error) => {
            return of(
              fromActions.homeGuardAccountsFailed({
                error: {
                  text: 'Failed to get accounts',
                  originalError: error,
                },
              }),
            );
          }),
        );
      }),
    );
  });

  public selectAccountGuardAccountsRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.selectAccountGuardAccountsRequested),
      switchMap(() => {
        return this.getAccounts().pipe(
          map((data) => {
            if (Array.isArray(data) && data.length) {
              return fromActions.selectAccountGuardAccountsSuccess({ data });
            } else {
              return fromActions.selectAccountGuardAccountsFailed({
                error: {
                  text: 'Failed to get accounts',
                  originalError: 'No accounts found for the user',
                },
              });
            }
          }),
          catchError((error) => {
            return of(
              fromActions.selectAccountGuardAccountsFailed({
                error: {
                  text: 'Failed to get accounts',
                  originalError: error,
                },
              }),
            );
          }),
        );
      }),
    );
  });

  public switchAccountPopupAccountsRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.switchAccountPopupAccountsRequested),
      switchMap(() => {
        return this.getAccounts().pipe(
          map((data) => {
            return fromActions.switchAccountPopupAccountsSuccess({ data });
          }),
          catchError((error) => {
            return of(
              fromActions.switchAccountPopupAccountsFailed({
                error: {
                  text: 'Failed to get accounts',
                  originalError: error,
                },
              }),
            );
          }),
        );
      }),
    );
  });

  public homeGuardSetAccountRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.homeGuardSetAccountRequested),
      switchMap((action) => {
        return this.authApiService.setAccount(action.request).pipe(
          map((response) => {
            return fromActions.homeGuardSetAccountSuccess({ response });
          }),
          catchError((error) => {
            return of(
              fromActions.homeGuardSetAccountFailed({
                error: {
                  text: 'Failed to set account',
                  originalError: error,
                },
              }),
            );
          }),
        );
      }),
    );
  });

  public selectAccountGuardSetAccountRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.selectAccountGuardSetAccountRequested),
      switchMap((action) => {
        return this.authApiService.setAccount(action.request).pipe(
          map((response) => {
            return fromActions.selectAccountGuardSetAccountSuccess({ response });
          }),
          catchError((error) => {
            return of(
              fromActions.selectAccountGuardSetAccountFailed({
                error: {
                  text: 'Failed to set account',
                  originalError: error,
                },
              }),
            );
          }),
        );
      }),
    );
  });

  public selectAccountPageSetAccountRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.selectAccountPageSetAccountRequested),
      switchMap((action) => {
        return this.authApiService.setAccount(action.request).pipe(
          map((response) => {
            return fromActions.selectAccountPageSetAccountSuccess({ response });
          }),
          catchError((error) => {
            return of(
              fromActions.selectAccountPageSetAccountFailed({
                error: {
                  text: 'Failed to set account',
                  originalError: error,
                },
              }),
            );
          }),
        );
      }),
    );
  });

  public selectAccountPageSetAccountSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.selectAccountPageSetAccountSuccess),
        tap(() => this.router.navigateByUrl(commonRoutes.home.url)),
      ),
    { dispatch: false },
  );

  // TODO: show modal error on selectAccountPageSetAccountFailed

  public switchAccountPopupSetAccountRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.switchAccountPopupSetAccountRequested),
      switchMap((action) => {
        return this.authApiService.setAccount(action.request).pipe(
          map((response) => {
            return fromActions.switchAccountPopupSetAccountSuccess({ response: response });
          }),
          catchError((error) => {
            return of(
              fromActions.switchAccountPopupSetAccountFailed({
                error: {
                  text: 'Failed to set account',
                  originalError: error,
                },
              }),
            );
          }),
        );
      }),
    );
  });

  public switchAccountSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.switchAccountPopupSetAccountSuccess),
      map(() => fromRootStore.go({ path: [commonRoutes.accountSwitching.url] })),
    );
  });

  // TODO: show modal error on switchAccountPopupSetAccountFailed

  public checkAccountGuardFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        fromActions.homeGuardAccountFailed,
        fromActions.homeGuardAccountsFailed,
        fromActions.homeGuardSetAccountFailed,
        fromActions.selectAccountGuardAccountFailed,
        fromActions.selectAccountGuardAccountsFailed,
        fromActions.selectAccountGuardSetAccountFailed,
      ),
      map((action) => fromRootStore.goErrorPage({ error: action.error })),
    );
  });

  private getAccounts(): Observable<fromApi.account.UserAccount[] | null> {
    const accountsPreset = this.localStorage.get('accountsPreset', false);
    return this.authApiService.getAccounts(accountsPreset).pipe(map((res) => (res?.accounts ? res.accounts : null)));
  }
}
