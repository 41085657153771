import { createAction, props } from '@ngrx/store';
import { VehicleDetailsTab } from '@rootTypes/entities/vehicles';

const details = '[VehicleDetails]:';

export const initVehicleDetails = createAction(
  `${details} Initialize`,
  props<{ vehicleId: string; tab: VehicleDetailsTab }>(),
);
export const destroyVehicleDetails = createAction(`${details} Destroy`);

export const vehicleDetailsTabChanged = createAction(`${details} Tab changed`, props<{ tab: VehicleDetailsTab }>());

export const destroyVehicleDetailsDrawer = createAction('[VehicleDetailsDrawerContainer]: Destroy');
