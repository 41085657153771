import { VehicleInspectionStatusQueryParam } from '../types';
import { VehicleInspectionStatus } from '@apiEntities';

export const queryParamToVehicleInspectionStatus = (
  query?: VehicleInspectionStatusQueryParam,
): VehicleInspectionStatus => {
  const map = {
    [VehicleInspectionStatusQueryParam.DRAFT]: VehicleInspectionStatus.DRAFT,
    [VehicleInspectionStatusQueryParam.SATISFACTORY]: VehicleInspectionStatus.SATISFACTORY,
    [VehicleInspectionStatusQueryParam.SATISFACTORY_WITH_ISSUES]: VehicleInspectionStatus.SATISFACTORY_WITH_ISSUES,
    [VehicleInspectionStatusQueryParam.UNSATISFACTORY]: VehicleInspectionStatus.UNSATISFACTORY,
  };
  return map[query];
};
