import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StackDirection } from './types';

@Injectable()
export class StackLayoutService {
  public spacing$ = new BehaviorSubject<number>(0);
  public direction$ = new BehaviorSubject<StackDirection>('row');

  constructor() {}

  public setSpacing(spacing: number): void {
    this.spacing$.next(spacing);
  }

  public setDirection(direction: StackDirection): void {
    this.direction$.next(direction);
  }
}
