import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-three-dots',
  templateUrl: './three-dots.component.html',
  styleUrls: ['./three-dots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreeDotsComponent implements OnInit {
  @Input() public dotSize = 6;
  @Input() public color = '#959597';

  public spaceBetweenStr: string;
  public sizeStr: string;

  constructor() {}

  ngOnInit(): void {
    this.sizeStr = this.dotSize + 'px';
    this.spaceBetweenStr = Math.floor((this.dotSize * 2) / 3) + 'px';
  }
}
