import { createSelector } from '@ngrx/store';
import { studentChangeRequestsFeatureSelector } from './feature-selector';
import { getRequestedByUserDisplayFromChangeRequestSummary } from '@rootTypes/utils';

const getRootState = createSelector(studentChangeRequestsFeatureSelector, (state) => state.root);
export const getStudentId = createSelector(getRootState, (state) => state.studentId);
const getStudentState = createSelector(getRootState, (state) => state.student);
export const isStudentLoading = createSelector(getStudentState, (state) => state.isLoading);
export const getStudent = createSelector(getStudentState, (state) => state.entity);

const getPendingRequestsByStudentState = createSelector(getRootState, (state) => state.pendingRequestsByStudent);
export const isPendingRequestsByStudentLoading = createSelector(
  getPendingRequestsByStudentState,
  isStudentLoading,
  (state, isStudentLoading) => state.isLoading || isStudentLoading,
);
export const getPendingRequestsByStudentError = createSelector(
  getPendingRequestsByStudentState,
  (state) => state.error,
);

const getPendingRequestsEntity = createSelector(getPendingRequestsByStudentState, (state) => state.entity);

export const getPendingRequestsByStudentSummary = createSelector(getPendingRequestsEntity, (state) => state?.summary);
export const getStudentFullName = createSelector(getPendingRequestsByStudentSummary, (state) => state?.studentName);

export const getPendingRequestsByStudent = createSelector(
  getPendingRequestsEntity,
  (state) => state?.studentChangeRequests,
);

const getSpecificPendingRequest = createSelector(
  getPendingRequestsByStudent,
  (requests, props: { studentChangeRequestId: string }) => {
    if (!requests) {
      return;
    }
    return requests.find((item) => item.studentChangeRequestId === props.studentChangeRequestId);
  },
);
export const getNumberOfPendingRequestsByStudent = createSelector(
  getPendingRequestsByStudentSummary,
  (state) => state?.numberOfRequests || 0,
);
export const getRequestedByUserDisplay = createSelector(
  getSpecificPendingRequest,
  getRequestedByUserDisplayFromChangeRequestSummary,
);
export const getRequestedTimestamp = createSelector(getSpecificPendingRequest, (state) => state?.requested.timestamp);
