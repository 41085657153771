const DEFAULT_SEPARATOR = ',';

export const encodeDataComponents = (comps: string[], separator = DEFAULT_SEPARATOR): string => {
  if (!comps) {
    return null;
  }
  return comps.join(separator);
};

export const decodeDataComponents = (source: string, separator = DEFAULT_SEPARATOR): string[] => {
  if (!source) {
    return [];
  }
  return source.split(separator);
};
