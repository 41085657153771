import { EventEmitter } from '@angular/core';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'wp-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputSearchComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Input() placeholder: string;
  @Input() public clearControlOnClearClicked = true;
  @Output() public clearInputClicked = new EventEmitter<void>();
  @Output() public inputEvent = new EventEmitter<string>();

  public disabled$: Observable<boolean>;

  private disabled$$: BehaviorSubject<boolean>;

  constructor() {}

  ngOnInit(): void {
    this.disabled$$ = new BehaviorSubject(this.control.disabled);
    this.disabled$ = this.disabled$$.asObservable();
    this.control.registerOnDisabledChange((disabled) => this.disabled$$.next(disabled));
  }

  public clearInput(): void {
    if (this.clearControlOnClearClicked) {
      this.control.setValue('', { emitEvent: true });
    }
    this.clearInputClicked.emit();
  }
}
