import { Equipment } from '@rootTypes/entities/vehicles';

export const getDisplayVehicleEquipment = (equipment: Equipment): string => {
  const wheelchairCount = equipment?.wheelchairCount;
  const boosterSeatCount = equipment?.boosterSeatCount;
  const equipments = [];
  if (wheelchairCount) {
    equipments.push(`Wheelchair (${wheelchairCount})`);
  }
  if (boosterSeatCount) {
    equipments.push(`Booster seat (${boosterSeatCount})`);
  }
  if (!equipments.length) {
    return 'None';
  }
  return equipments.join(', ');
};
