<div
  class="wp-photo-display"
  [ngClass]="{ round: isRound, 'full-image': showFullImageWithinDimensions }"
  [ngStyle]="{ width: width, height: height }"
>
  <ng-container *ngIf="(!!imageBase64 || !!loadedImageData) else placeholder">
    <img
      *ngIf="showFullImageWithinDimensions; else focusOnImageCenter"
      [src]="imageBase64 || loadedImageData"
      width="auto"
      height="100%"
      class="wp-img"
    />

    <ng-template #focusOnImageCenter>
      <img
        [src]="imageBase64 || loadedImageData"
        [ngStyle]="{ width: width, height: height }"
        class="wp-img"
      />
    </ng-template>
  </ng-container>

  <ng-template #placeholder>
    <div
      class="placeholder-abbreviation"
      *ngIf="placeholderAbbreviation; else imagePlaceholder"
      [ngStyle]="{'font-size': placeholderAbbreviationFontSize}"
    >
      {{placeholderAbbreviation}}
    </div>
    <ng-template #imagePlaceholder>
      <wp-photo-placeholder
        [type]="placeholderType"
        [background]="placeholderBackground"
        [borderStyle]="placeholderBorderStyle"
        [width]="placeholderWidth"
      ></wp-photo-placeholder>
    </ng-template>
  </ng-template>
</div>
