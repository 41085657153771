import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { ApiService } from 'src/app/api/api.service';
import { SelectOption } from '..';
import { Yard } from 'src/app/api/endpoints/get-yards';

@Component({
  selector: 'wp-select-yard',
  templateUrl: './select-yard.component.html',
  styleUrls: ['./select-yard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectYardComponent implements OnChanges, OnInit, OnDestroy {
  /**
   * @example new FormControl(yardId, Validators.required) or
   * new FormControl({id: string, name: string}) if useYardObjAsValue set to true
   */
  @Input() public control: UntypedFormControl;
  @Input() public useYardObjAsValue = false;
  @Input() public label: string;
  @Input() public vendorId?: string;
  @Input() public controlStateChange?: Observable<any>;

  public options: SelectOption[];
  public compareWith?: (optionValue: any, selectedValue: any) => boolean;

  public isLoading = false;
  public isError = false;

  public isPlaceholder = false;
  public placeholderControl = new UntypedFormControl({ value: undefined, disabled: true });
  public placeholderOptions: SelectOption[] = [{ displayLabel: '', value: '' }];

  private sub = new Subscription();

  constructor(
    private api: ApiService,
    private cdRef: ChangeDetectorRef,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.vendorId && !changes.vendorId.isFirstChange()) {
      if (this.vendorId) {
        this.loadOptions();
      } else {
        this.isPlaceholder = true;
      }
    }
  }

  public ngOnInit(): void {
    if (this.useYardObjAsValue) {
      this.compareWith = (optionValue: Yard, selectedValue: Yard) => optionValue?.id === selectedValue?.id;
    }
    if (this.vendorId) {
      this.loadOptions();
    } else {
      this.isPlaceholder = true;
    }
    if (this.controlStateChange) {
      const onStateChangeSub = this.controlStateChange.subscribe(() => {
        this.cdRef.detectChanges();
      });
      this.sub.add(onStateChangeSub);
    }
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private async loadOptions(): Promise<void> {
    try {
      this.isLoading = true;
      this.isError = false;
      this.isPlaceholder = false;
      const res = await this.api.getYards({ vendorId: this.vendorId }).pipe(take(1)).toPromise();
      this.options = res.yards.map(({ name, id }) => {
        return {
          displayLabel: name,
          value: this.useYardObjAsValue ? { name, id } : id,
        };
      });
    } catch (err) {
      this.isError = true;
    } finally {
      this.isLoading = false;
      this.cdRef.detectChanges();
    }
  }
}
