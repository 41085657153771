import { Subject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { DrawerConfig } from './drawer-config';

export class DrawerRef {
  constructor(public config: DrawerConfig) {}

  private closedSubj: Subject<boolean> = new Subject<boolean>();

  public close(): void {
    this.closedSubj.next(true);
  }

  public closed$(): Observable<boolean> {
    return this.closedSubj.asObservable().pipe(
      filter((closed) => !!closed),
      take(1),
    );
  }
}
