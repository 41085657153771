import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { routeGroupDataFeatureKey } from './feature-key';
import { routeGroupDataFeatureReducer } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { routeGroupDataEffects } from './effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(routeGroupDataFeatureKey, routeGroupDataFeatureReducer),
    EffectsModule.forFeature(routeGroupDataEffects),
  ],
})
export class RouteGroupDataModule {}
