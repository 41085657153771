import { combineReducers } from '@ngrx/store';
import * as fromRideData from './ride-data.reducer';
import * as fromList from './ride-list.reducer';

export interface RideFeatureState {
  data: fromRideData.RideDataState;
  lists: fromList.AllRideListState;
}

export const rideFeatureReducer = combineReducers<RideFeatureState>({
  data: fromRideData.rideDataReducer,
  lists: fromList.rideListReducer,
});
