<wp-popup-page [nonScrollable]="false" wpFadeInOut [show]="true">
  <wp-popup-page-top [isCloseBtn]="true" (closeClicked)="closeDrawer()">
    <wp-popup-page-title>Access denied</wp-popup-page-title>
  </wp-popup-page-top>
  <wp-popup-body-top>
    <div class="content">
      <div class="instructions">
        You don't have permission to access this content.
      </div>
      <div class="instructions">
        Please contact your administrator for further instructions.
      </div>
      <wp-link-btn (clicked)="goBack()">
        <span class="go-back-text">Go back</span>
      </wp-link-btn>
    </div>
  </wp-popup-body-top>
</wp-popup-page>
