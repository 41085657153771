import { Pipe, PipeTransform } from '@angular/core';
import { capitalize } from '@rootTypes/utils/common/capitalize';

@Pipe({
  name: 'displayUnderscoreString',
})
export class DisplayUnderscoreStringPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      const lowercase = value.toLowerCase();
      const whitespace = lowercase.split('_').join(' ');
      return capitalize(whitespace);
    }
    return undefined;
  }
}
