// America/Los Angeles ->> PDT
import { dateFormat } from '@rootTypes/utils/common/date-time-fns';

/**
 *
 * @param source timezone like America/Los Angeles
 * @return timezone like PDT
 */
export const getShortTimezone = (source: string): string => {
  return dateFormat(new Date(), 'z', source);
};
