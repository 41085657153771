import { EntityState, WpError } from '../../entities/common';

export const createEntityState = <T>(entity?: T, error?: WpError): EntityState<T> => {
  const state: EntityState<T> = { isLoading: false };
  if (entity) {
    state.entity = entity;
    return state;
  }
  if (error) {
    state.error = error;
  }
  return state;
};
