import * as fromLists from './employee-list.reducer';
import * as fromData from './employee-data.reducer';
import * as fromDetails from './employee-details.reducer';
import * as fromProfileEditor from './employee-profile-editor.reducer';
import * as fromPermissionManager from './permissions-manager.reducer';
import { combineReducers } from '@ngrx/store';

export interface EmployeesFeatureState {
  lists: fromLists.AllEmployeeListState;
  data: fromData.EmployeeDataState;
  details: fromDetails.EmployeeDetailsState;
  profileEditor: fromProfileEditor.EmployeeProfileEditorState;
  permissionManager: fromPermissionManager.PermissionsManagerState;
}

export const employeesReducer = combineReducers<EmployeesFeatureState>({
  lists: fromLists.employeeListsReducer,
  data: fromData.employeeDataReducer,
  details: fromDetails.employeeDetailsReducer,
  profileEditor: fromProfileEditor.employeeProfileEditorReducer,
  permissionManager: fromPermissionManager.permissionsManagerReducer,
});
