<wp-auth-wrapper>
  <div class="select-account">
    <div class="select-account__wrap">
      <div class="select-account__content">
        <wp-select-account-form
          [accounts]="accounts$ | async"
          [isSubAccountRequired]="isSubAccountRequired$ | async"
          [isSubmitLoading]="isSetAccountLoading$ | async"
          (request)="goToAccount($event)"
        ></wp-select-account-form>

        <wp-btn-secondary
          class="logout-btn"
          (clicked)="logout()"
          [disabled]="isSetAccountLoading$ | async"
          >Logout
        </wp-btn-secondary>
      </div>
    </div>
  </div>
</wp-auth-wrapper>
