import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wp-add-edit-action-buttons',
  templateUrl: './add-edit-action-buttons.component.html',
  styleUrls: ['./add-edit-action-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditActionButtonsComponent {
  @Input() public shouldGoBackOnCancel: boolean;
  @Input() public isSaveBtnDisabled: boolean;
  @Input() public isSaveBtnLoading: boolean;
  @Input() public saveButtonText: string = 'Save';
  @Output() public saveBtnClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() public cancelBtnClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(private location: Location) {}

  public onSaveBtnClick(): void {
    this.saveBtnClicked.emit();
  }

  public onCancelBtnClick(): void {
    if (this.shouldGoBackOnCancel) {
      // call window history back directly
      // to fix Safari bug
      window.history.length ? window.history.back() : this.location.go('/');
    } else {
      this.cancelBtnClicked.emit();
    }
  }
}
