import { Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { filter, take, switchMap, map } from 'rxjs/operators';

import * as fromApi from '../../api';
import { AccountState } from '../store/reducers';
import * as fromSelectors from '../store/selectors';
import { State } from '../../store';
import * as fromActions from '../store/actions';
import { loadAccounts } from './load-accounts';

export const isSelectAccountRequired = (
  account: fromApi.account.SelectedAccount,
  store: Store<AccountState | State>,
  getAccountAction: fromActions.GetAccountsActionCreator,
  setAccountAction: fromActions.GuardSetAccountActionCreator,
): Observable<boolean> => {
  if (account.isAccountRequired && !account.isSubAccountsRequired) {
    if (account.accountId) {
      return of(false);
    }
    return loadAccounts(store, getAccountAction).pipe(
      switchMap((accounts) => {
        // Set the single account
        if (accounts.length === 1) {
          store.dispatch(setAccountAction({ request: { accountId: accounts[0].id } }));
          return store.select(fromSelectors.setAccountState).pipe(
            filter((state) => !state.isLoading),
            take(1),
            map((state) => {
              if (state.error) {
                return true;
              }
              return false;
            }),
          );
        }
        // More than 1 account
        return of(true);
      }),
    );
  }

  if (account.isAccountRequired && account.isSubAccountsRequired) {
    if (account.accountId && account.subAccountIds) {
      return of(false);
    }
    return loadAccounts(store, getAccountAction).pipe(
      switchMap((accounts) => {
        // Set the single account with the single subaccount
        if (accounts.length === 1 && accounts[0].subAccounts && accounts[0].subAccounts.length === 1) {
          const acc = accounts[0];
          const sub = acc.subAccounts[0];
          store.dispatch(
            setAccountAction({
              request: {
                accountId: acc.id,
                subAccountIds: [sub.id],
              },
            }),
          );
          return store.select(fromSelectors.setAccountState).pipe(
            filter((state) => !state.isLoading),
            take(1),
            map((state) => {
              if (state.error) {
                return true;
              }
              return false;
            }),
          );
        }

        // More than 1 account or several subaccounts
        return of(true);
      }),
    );
  }

  return of(false);
};
