import { Check } from '../common/check';

/**
 * Gets a list of check for driver, vehicles
 */
export interface GetChecksRequest {
  type: CheckType;
}

export type GetChecksResponse = Check[];

export enum CheckType {
  VENDOR = 'VENDOR',
  ZUMER = 'ZUMER',
  VENDOR_VEHICLE = 'VENDOR_VEHICLE',
  ZUMER_VEHICLE = 'ZUMER_VEHICLE',
  DISTRICT = 'DISTRICT',
  CAMPUS = 'CAMPUS',
}

export { Check };
