import { VendorDetailsTabs } from '../../../types/entities/vendor';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { NavigationRequest } from '../../types/navigation-request';
import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '../../../types/entities/router';

export interface VendorDetailsRouteData {
  vendorId: string;
  tab?: VendorDetailsTabs;
  displayBackText?: string;
}

export const vendorDetailsRoute: PortalRoute<VendorDetailsRouteData> = {
  path: 'list',
  isOnRoute: (state: RouterStateUrl): boolean => {
    throw new Error('Not implemented');
  },
  parse: (state: RouterStateUrl): VendorDetailsRouteData => {
    let data: VendorDetailsRouteData = {
      vendorId: state.drawer.params.vendorId,
      tab: getTabIdFromFromUrl(state.drawer.url),
    };
    if (state.queryParams) {
      data = {
        ...data,
        ...state.queryParams,
      };
    }
    return data;
  },
  request: (
    data: VendorDetailsRouteData,
    queryParamStrategy?: QueryParamsHandlingStrategy,
    replaceUrl?,
  ): NavigationRequest => {
    const req: NavigationRequest = {
      path: ['_', 'drawer', 'vendors', data.vendorId, 'details', data.tab || VendorDetailsTabs.DEFAULT],
    };
    delete data.vendorId;
    delete data.tab;
    const query = { ...data };
    if (Object.keys(query).length) {
      req.query = query;
    }
    if (queryParamStrategy) {
      req.extras = {
        queryParamsHandling: queryParamStrategy,
      };
    }
    if (replaceUrl) {
      req.extras = req.extras || {};
      req.extras.replaceUrl = true;
    }
    return req;
  },
};

function getTabIdFromFromUrl(url: string): VendorDetailsTabs {
  if (!url) {
    return null;
  }
  const segments = url.split('/');
  return segments[5] as VendorDetailsTabs;
}

@Injectable({ providedIn: 'root' })
export class VendorDetailsRouterService extends PortalRouterService<VendorDetailsRouteData> {
  outlet = RouterOutlets.DRAWER;
  route: PortalRoute<VendorDetailsRouteData> = vendorDetailsRoute;
}
