export const getAnimationBreakPointsForBearings = (originBearing: number, targetBearing, step: number): number[] => {
  const absoluteDiff = Math.abs(targetBearing - originBearing);
  if (absoluteDiff < 180) {
    return getAngleIncrementsBetweenTwoAngles(originBearing, targetBearing, step);
    // avoid turns more than 180 deg
  } else {
    if (originBearing > 0) {
      return [
        ...getAngleIncrementsBetweenTwoAngles(originBearing, 179, step),
        -180,
        ...getAngleIncrementsBetweenTwoAngles(-180, targetBearing, step),
      ];
    } else {
      return [
        ...getAngleIncrementsBetweenTwoAngles(originBearing, -180, step),
        179,
        ...getAngleIncrementsBetweenTwoAngles(179, targetBearing, step),
      ];
    }
  }
};

function getAngleIncrementsBetweenTwoAngles(currentAngle: number, targetAngle: number, step: number): number[] {
  const tasks = [];
  let currentTask: number = currentAngle;
  if (targetAngle > currentAngle) {
    while (currentTask !== targetAngle) {
      currentTask = Math.min(targetAngle, currentTask + step);
      tasks.push(currentTask);
    }
  }
  if (targetAngle < currentAngle) {
    while (currentTask !== targetAngle) {
      currentTask = Math.max(targetAngle, currentTask - step);
      tasks.push(currentTask);
    }
  }
  return tasks;
}
