<svg
  class="icon"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <g
    fill="none"
    fill-rule="evenodd"
    stroke="#959597"
    transform="translate(0 4)"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M13 1L19.429 1 23 5.913 23 14 13 14"
    />
    <rect width="15" height="13" x="1" y="1" stroke-width="2" rx="2" />
    <path fill="#D8D8D8" d="M17.5 7.5H21.5V8.5H17.5z" />
    <path
      fill="#FFF"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M17 16c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM6 16c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z"
    />
  </g>
</svg>
