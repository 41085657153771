import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import * as fromActions from '../actions/add-edit-district.actions';
import * as fromSelectors from '../selectors/add-edit-district.selectors';
import * as fromTypes from '../../types';
import { Observable } from 'rxjs';

@Injectable()
export class AddEditDistrictFacade {
  constructor(private store: Store<State>) {}

  public getEditedDistrictId$(): Observable<string> {
    return this.store.select(fromSelectors.getEditedDistrictId);
  }

  public getAddEditDistrictLoading$(): Observable<boolean> {
    return this.store.select(fromSelectors.getAddEditDistrictLoading);
  }

  public getAddEditDistrictSuccess$(): Observable<boolean> {
    return this.store.select(fromSelectors.getAddEditDistrictSuccess);
  }

  public getAddEditDistrictError$(): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getAddEditDistrictError);
  }

  /**
   * Resets district editor state. Mind that it doesn't load the edited district.
   * Use data facade to load it
   * @param districtId - pass if edit district, omit, if create.
   */
  public onInitializeDistrictEditor(districtId?: string): void {
    this.store.dispatch(fromActions.initializeDistrictEditor({ districtId }));
  }

  public onAddDistrict(district: fromTypes.District, profileImageBase64?: string): void {
    this.store.dispatch(fromActions.addDistrictRequested({ district, profileImageBase64 }));
  }

  public onEditDistrict(district: fromTypes.District, profileImageBase64: string): void {
    this.store.dispatch(fromActions.editDistrictRequested({ district, profileImageBase64 }));
  }
}
