import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingContentErrorComponent } from './loading-content-error/loading-content-error.component';

const components = [LoadingContentErrorComponent];

@NgModule({
  declarations: [...components],
  imports: [CommonModule],
  exports: [...components],
})
export class LoadingContentErrorModule {}
