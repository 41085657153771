import { RideTimetable } from '../entities';
import { RideListSnapshot } from '../ride-list-snapshot';
import { SerializedRideListSnapshot } from '../serialized-snapshot';
import { DEFAULT_PAGE_SIZE } from '../ui';
import { rideFiltersToFilterStr } from './ride-filter-utils';

export const serializeSnapshot = (source: RideListSnapshot): SerializedRideListSnapshot => {
  const target: SerializedRideListSnapshot = {
    currentPage: source.currentPage && source.currentPage !== 0 ? '' + (source.currentPage + 1) : undefined,
    pageSize: source.pageSize !== DEFAULT_PAGE_SIZE ? '' + source.pageSize : undefined,
    filters:
      source.dynamicFilters && source.dynamicFilters.length ? rideFiltersToFilterStr(source.dynamicFilters) : undefined,
    staticFilters:
      source.staticFilters && source.staticFilters.length ? rideFiltersToFilterStr(source.staticFilters) : undefined,
    timetable: source.timetable || RideTimetable.UPCOMING,
  };
  if (source.accountId) {
    target.accountId = source.accountId;
  }
  return target;
};
