import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, filter, map, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { State } from '@rootStore';
import * as fromActions from '../actions/yard-list.actions';
import * as fromSelectors from '../selectors/yard-lists.selectors';
import * as fromTypes from '../../types';
import { YardsApiService } from '../../services/yards-api.service';
import { Observable, of, timer } from 'rxjs';
import * as fromDataActions from '../actions/yard-data.actions';

@Injectable()
export class YardsListEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private apiService: YardsApiService,
  ) {
    this.createLoadListEffects();
  }

  public listChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.yardListInitialized,
        fromActions.staticFiltersChanged,
        fromActions.filtersChanged,
        fromActions.pageChanged,
        fromActions.pageSizeChanged,
        fromActions.statusChanged,
        fromActions.searchChanged,
      ),
      switchMap((action) => {
        const { listName } = action;
        return this.store.select(fromSelectors.getYardListState, { listName }).pipe(
          take(1),
          map((list) => {
            const snapshot: fromTypes.YardListSnapshot = {
              page: list.pagination.page || 0,
              pageSize: list.pagination.pageSize,
              filters: [...(list.filters || []), ...(list.staticFilters || [])],
              search: list.currentSearch,
              status: list.status,
            };
            return fromActions.loadYardListRequested({
              listName,
              snapshot,
            });
          }),
        );
      }),
    ),
  );

  /**
   * Yard list item initialized
   * Load yard details with debounce
   */
  public yardCardInitialized$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.listItemInitialized),
      mergeMap((action) => {
        const { yardId } = action;
        return timer(fromTypes.ui.YARD_LIST_LOAD_YARD_AFTER_CARD_INIT_MILLIS).pipe(
          takeUntil(
            this.actions$.pipe(
              ofType(fromActions.listItemDestroyed),
              filter((a) => a.yardId === yardId),
            ),
          ),
          map(() => action),
        );
      }),
      map((action) => {
        const { yardId, listName } = action;
        if (listName === fromTypes.YardListName.MAIN_YARD_LIST) {
          return fromDataActions.loadYardRequested({ yardId, options: { withVendor: true } });
        }
        return fromDataActions.loadYardRequested({ yardId });
      }),
    ),
  );

  private createLoadListEffects(): void {
    fromTypes.yardLists.forEach((listName) => {
      this[`loadListRequested_${listName}`] = createEffect(() =>
        this.actions$.pipe(
          ofType(fromActions.loadYardListRequested),
          filter((action) => action.listName === listName),
          debounceTime(fromTypes.ui.YARD_LIST_LOAD_DEBOUNCE_MILLIS),
          switchMap((action) => this.onLoadYardsListRequested(action.snapshot, action.listName)),
        ),
      );
    });
  }

  private onLoadYardsListRequested(
    snapshot: fromTypes.YardListSnapshot,
    listName: fromTypes.YardListName,
  ): Observable<Action> {
    const request = fromTypes.utils.getYardListRequest(snapshot);
    return this.apiService.entityFilter(request).pipe(
      map((resp) => {
        const { total, items } = resp;
        return fromActions.loadYardListSuccess({
          listName,
          total,
          items,
        });
      }),
      catchError((err) => {
        console.log(err);
        return of(
          fromActions.loadYardListFailed({
            listName,
            error: {
              text: 'Failed to load yard list',
              originalError: err,
            },
          }),
        );
      }),
    );
  }
}
