import { getDefaultDriverForWeekday } from './route-data.selectors';
import { getAllDrivers } from '../../../drivers/store/selectors/driver-data.selectors';
import { createSelector } from '@ngrx/store';

const getDefaultDriverByWeekdayEntity = createSelector(
  getDefaultDriverForWeekday,
  getAllDrivers,
  (defaultDriverId, allDrivers) => {
    if (!defaultDriverId) {
      return null;
    }
    return allDrivers[defaultDriverId];
  },
);

export const getDefaultDriver = createSelector(getDefaultDriverByWeekdayEntity, (state) =>
  state ? state.entity : null,
);

export const getDefaultDriverLoading = createSelector(getDefaultDriverByWeekdayEntity, (state) =>
  state ? state.isLoading : false,
);

export const getDefaultDriverError = createSelector(getDefaultDriverByWeekdayEntity, (state) =>
  state ? state.error : null,
);
