import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { DriverDataFacade, WpError, Yard, YardDataFacade } from '../types';

@Injectable()
export class DriverYardFacade {
  constructor(
    private driverFacade: DriverDataFacade,
    private yardFacade: YardDataFacade,
  ) {}

  public getYard(driverId: string): Observable<Yard> {
    return this.driverFacade.getDriverYardId(driverId).pipe(
      switchMap((yardId) => {
        if (yardId) {
          return this.yardFacade.getYard(yardId);
        }
        return of(undefined);
      }),
    );
  }

  public getYardId(driverId: string): Observable<string> {
    return this.driverFacade.getDriverYardId(driverId);
  }

  public getYardLoading(driverId: string): Observable<boolean> {
    return this.driverFacade.getDriverYardId(driverId).pipe(
      switchMap((yardId) => {
        if (yardId) {
          return this.yardFacade.getYardLoading(yardId);
        }
        return of(undefined);
      }),
    );
  }

  public getYardError(driverId: string): Observable<WpError> {
    return this.driverFacade.getDriverYardId(driverId).pipe(
      switchMap((yardId) => {
        if (yardId) {
          return this.yardFacade.getYardError(yardId);
        }
        return of(undefined);
      }),
    );
  }

  public getYardErrorMessage(driverId: string): Observable<string> {
    return this.driverFacade.getDriverYardId(driverId).pipe(
      switchMap((yardId) => {
        if (yardId) {
          return this.yardFacade.getYardErrorMessage(yardId);
        }
        return of(undefined);
      }),
    );
  }

  public getYardName(driverId: string): Observable<string> {
    return this.driverFacade.getDriverYardId(driverId).pipe(
      switchMap((yardId) => {
        if (yardId) {
          return this.yardFacade.getYardName(yardId);
        }
        return of(undefined);
      }),
    );
  }
}
