<div class="wp-dp-month">
  <div class="month-label">{{ month.label }}</div>
  <div class="vertical-line"></div>
  <div class="weekdays">
    <div class="weekday" *ngFor="let weekday of weekdays$ | async">
      {{ weekday.label }}
    </div>
  </div>
  <div class="days">
    <div class="day" *ngFor="let day of days$ | async">
      <wp-dp-day [day]="day"></wp-dp-day>
    </div>
  </div>
</div>
