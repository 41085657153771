<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <defs>
    <path
      id="te5yly6laa"
      d="M0 7.633h24v7c0 2.762-2.239 5-5 5H5c-2.761 0-5-2.238-5-5v-7zm6.971 0h10.05c-.225.497-.417.953-.577 1.367-.24.622-.21 1.516-1.447 2.043-1.396-.056-4.343-.027-5.865 0-1.574-.65-1.25-1.3-1.615-2.043-.243-.495-.425-.95-.546-1.367z"
    />
  </defs>
  <g fill="none" fill-rule="evenodd" class="icon">
    <g>
      <g>
        <g>
          <g>
            <g>
              <g
                transform="translate(-19 -674) translate(0 662) translate(19 12) translate(0 3)"
              >
                <path
                  stroke="#959597"
                  [ngClass]="{green: green}"
                  stroke-width="2"
                  d="M1 8.633v6c0 2.21 1.79 4 4 4h14c2.21 0 4-1.79 4-4v-6H1zm4.64-2h12.933l-.64 1.413c-.218.48-.404.919-.557 1.314-.02.056-.043.126-.079.256-.101.37-.124.447-.2.64-.298.762-.826 1.331-1.709 1.707l-.206.088-.225-.01c-1.15-.045-3.654-.037-5.808.001l-.207.004-.192-.079c-1.275-.526-1.74-1.107-2.008-2.14-.059-.23-.082-.304-.123-.387-.267-.544-.47-1.053-.608-1.528L5.64 6.633z"
                />
                <path
                  stroke="#959597"
                  [ngClass]="{green: green}"
                  stroke-width="2"
                  d="M3 7.633L3 3 21 3 21 7.633M5 3L5 0 19 0 19 3"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
