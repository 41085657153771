import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const moduleKey = 'Data';
const featureKey = 'Rides';
const subFeatureKey = 'List';

export const selectedRideIdChanged = createAction(
  `[${moduleKey}] [${featureKey}] [${subFeatureKey}]: Selected ride id changed`,
  props<{
    listName: fromTypes.RideListName;
    rideId: string | null;
  }>(),
);

export const rideListInitialized = createAction(
  `[${moduleKey}] [${featureKey}] [${subFeatureKey}]: Ride list initialized`,
  props<{
    listName: fromTypes.RideListName;
  }>(),
);

export const rideListDestroyed = createAction(
  `[${moduleKey}] [${featureKey}] [${subFeatureKey}]: Ride list destroyed`,
  props<{
    listName: fromTypes.RideListName;
  }>(),
);

export const setStateFromSerializedSnapshot = createAction(
  `[${moduleKey}] [${featureKey}] [${subFeatureKey}]: Set state from serialized snapshot`,
  props<{
    listName: fromTypes.RideListName;
    snapshot: fromTypes.SerializedRideListSnapshot;
  }>(),
);

// timetable
export const timetableChanged = createAction(
  `[${moduleKey}] [${featureKey}] [${subFeatureKey}]: Timetable changed`,
  props<{
    timetable: fromTypes.RideTimetable;
    listName: fromTypes.RideListName;
  }>(),
);

export const accountChanged = createAction(
  `[${moduleKey}] [${featureKey}] [${subFeatureKey}]: Account changed`,
  props<{
    accountId: string;
    listName: fromTypes.RideListName;
  }>(),
);

// filter rides
export const filterByEntitySelected = createAction(
  `[${moduleKey}] [${featureKey}] [${subFeatureKey}]: Filtered by entity`,
  props<{
    filter: fromTypes.filters.UserRideFilter;
    listName: fromTypes.RideListName;
  }>(),
);

export const searchHitFilterSelected = createAction(
  `[${moduleKey}] [${featureKey}] [${subFeatureKey}]: Search hit filter selected`,
  props<{
    filter: fromTypes.filters.SearchHitRideFilter;
    listName: fromTypes.RideListName;
  }>(),
);

export const filterByDatesSelected = createAction(
  `[${moduleKey}] [${featureKey}] [${subFeatureKey}]: Filtered by dates`,
  props<{
    filter: fromTypes.filters.DateRideFilter;
    listName: fromTypes.RideListName;
  }>(),
);

export const quickFilterSelected = createAction(
  `[${moduleKey}] [${featureKey}] [${subFeatureKey}]: Quick filter selected`,
  props<{
    filter: fromTypes.filters.RideQuickFilter;
    listName: fromTypes.RideListName;
  }>(),
);

export const filterRemoveSelected = createAction(
  `[${moduleKey}] [${featureKey}] [${subFeatureKey}]: Filter remove selected`,
  props<{ filterId: string; listName: fromTypes.RideListName }>(),
);

export const filtersChanged = createAction(
  `[${moduleKey}] [${featureKey}] [${subFeatureKey}]: Filters changed`,
  props<{
    filters: fromTypes.filters.RideFilter[];
    listName: fromTypes.RideListName;
  }>(),
);

export const staticFiltersChanged = createAction(
  `[${moduleKey}] [${featureKey}] [${subFeatureKey}]: Static filter changed`,
  props<{
    filters: fromTypes.filters.RideFilter[];
    listName: fromTypes.RideListName;
  }>(),
);

export const removeAllFilters = createAction(
  `[${moduleKey}] [${featureKey}] [${subFeatureKey}]: Remove all filters selected`,
  props<{ listName: fromTypes.RideListName }>(),
);

// pagination
export const currentPageChanged = createAction(
  `[${moduleKey}] [${featureKey}] [${subFeatureKey}]: Current page changed`,
  props<{ page: number; listName: fromTypes.RideListName }>(),
);

export const pageSizeChanged = createAction(
  `[${moduleKey}] [${featureKey}] [${subFeatureKey}]: Page size changed`,
  props<{ pageSize: number; listName: fromTypes.RideListName }>(),
);

//ride page
export const loadRidePageRequested = createAction(
  `[${moduleKey}] [${featureKey}] [${subFeatureKey}]: Load ride page requested`,
  props<{ snapshot: fromTypes.RideListSnapshot; listName: fromTypes.RideListName }>(),
);

export const loadRidePageSuccess = createAction(
  `[${moduleKey}] [${featureKey}] [${subFeatureKey}]: Load ride page success`,
  props<{ page: fromTypes.RideListResult; listName: fromTypes.RideListName }>(),
);

export const loadRidePageFailed = createAction(
  `[${moduleKey}] [${featureKey}] [${subFeatureKey}]: Load ride page failed`,
  props<{ error: fromTypes.WpError; listName: fromTypes.RideListName }>(),
);
