import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { DistrictApiService } from '../../services/districts-api.service';
import * as fromActions from '../actions/add-edit-district.actions';
import * as fromDataActions from '../actions/district-data.actions';
import * as fromDataSelectors from '../selectors/district-data.selectors';
import * as fromRootStore from '@rootStore';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';

@Injectable()
export class AddEditDistrictEffects {
  constructor(
    private actions$: Actions,
    private apiService: DistrictApiService,
    private store: Store<State>,
  ) {}

  public createDistrictRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addDistrictRequested),
      switchMap((action) => {
        const { district, profileImageBase64 } = action;
        if (profileImageBase64) {
          return this.apiService.uploadImage(profileImageBase64).pipe(
            map((imagePath) => {
              return { ...district, photo: imagePath };
            }),
          );
        } else {
          const districtCopy = { ...district };
          delete districtCopy.photo;
          return of(districtCopy);
        }
      }),
      switchMap((districtWithImagePath) => {
        return this.apiService.addDistrict(districtWithImagePath).pipe(
          map((res) => fromActions.addDistrictSuccess({ district: res })),
          catchError((err) => {
            console.log(err);
            return of(
              fromActions.addDistrictFailed({
                error: { text: 'Failed to add district', originalError: err },
              }),
            );
          }),
        );
      }),
    ),
  );

  public updateDistrictRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.editDistrictRequested),
      switchMap((action) => {
        const { district, profileImageBase64 } = action;
        if (profileImageBase64) {
          return this.apiService.uploadImage(profileImageBase64).pipe(
            map((imagePath) => {
              return { ...district, photo: imagePath };
            }),
          );
        } else {
          const districtCopy = { ...district };
          delete districtCopy.photo;
          return of(districtCopy);
        }
      }),
      switchMap((districtWithImagePath) => {
        return this.store.select(fromDataSelectors.getDistrict(districtWithImagePath.id)).pipe(
          take(1),
          switchMap((previous) => {
            return this.apiService.updateDistrict(previous, districtWithImagePath).pipe(
              map((res) => fromActions.editDistrictSuccess({ district: res })),
              catchError((err) => {
                console.log(err);
                return of(
                  fromActions.editDistrictFailed({
                    districtId: districtWithImagePath.id,
                    error: { text: 'Failed to edit district', originalError: err },
                  }),
                );
              }),
            );
          }),
        );
      }),
    ),
  );

  public updatedDistrict$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.editDistrictSuccess, fromActions.addDistrictSuccess),
      map((action) =>
        fromDataActions.loadDistrictSuccess({
          districtId: action.district.id,
          payload: { district: action.district },
        }),
      ),
    ),
  );

  public errorUpdatingDistrict$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.editDistrictFailed, fromActions.addDistrictFailed),
      map((action) => fromRootStore.openErrorPopup({ error: action.error })),
    ),
  );
}
