import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-icon-students-green',
  templateUrl: './icon-students-green.component.html',
  styleUrls: ['./icon-students-green.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconStudentsGreenComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
