import { createReducer, on } from '@ngrx/store';

import { ErrorState, WpError, ErrorPageData, isWebError } from '../../types';
import * as fromActions from '../actions';
import { logoutPageLogoutSuccess } from '../../auth/store/actions';

const initialErrorState: ErrorState = {
  errorPage: {
    data: null,
  },
};

const errorToErrorPageData = (error: WpError): ErrorPageData => {
  const result: ErrorPageData = {
    text: error.text,
    originalError: {},
  };
  if (isWebError(error.originalError)) {
    result.originalError.webError = error.originalError;
  } else {
    result.originalError.unknownError = error.originalError ? error.originalError : 'Unknown error';
  }
  return result;
};

export const errorReducer = createReducer<ErrorState>(
  initialErrorState,
  on(fromActions.goErrorPage, (state, action): ErrorState => {
    return {
      errorPage: {
        data: errorToErrorPageData(action.error),
      },
    };
  }),
  on(fromActions.destroyErrorPage, logoutPageLogoutSuccess, (state, action): ErrorState => {
    return {
      errorPage: {
        data: null,
      },
    };
  }),
);
