import { combineReducers } from '@ngrx/store';
import * as fromData from './route-data.reducers';
import * as fromLists from './route-lists.reducers';

export interface RoutesFeatureState {
  data: fromData.RouteDataState;
  lists: fromLists.AllRouteListState;
}

export const routeFeatureReducer = combineReducers<RoutesFeatureState>({
  data: fromData.routeDataReducer,
  lists: fromLists.routeListReducer,
});
