import { UntypedFormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { SmartInputConfig } from './smart-input';
import { SmartSelectValue } from './smart-select';
import { SelectOption } from '../../form-controls';
import { SmartInputModel } from './smart-input-model';

export interface SmartSelectAsyncConfig extends SmartInputConfig<SmartSelectValue> {
  loadOptionsFn?: () => Promise<SelectOption[]>;
  loadOptionsErrorMessage: string;
}

export class SmartSelectAsync implements SmartInputModel {
  public label: string;
  public control: UntypedFormControl; // SelectOption
  public controlStateChange$: Observable<void>;
  public options$: Observable<SelectOption[]>;
  public optionsLoading$: Observable<boolean>;
  public optionsErrorMessage$: Observable<string | undefined>;

  public compareWithFn = (option: SelectOption, selected: SelectOption): boolean => {
    if (option?.value && selected?.value) {
      return option.value === selected.value;
    }
    return false;
  };

  // We use Subject instead of BehaviorSubject to trigger change detection
  // inside input component only after it's initialization
  private controlStateChange$$: Subject<void>;

  private originalLabel: string;
  private initialValueId: string;
  private options$$: BehaviorSubject<SelectOption[]>;
  private optionsLoading$$: BehaviorSubject<boolean>;
  private optionsErrorMessage$$: BehaviorSubject<string | undefined>;
  private optionsErrorMessageText: string;
  private loadOptionsFn: () => Promise<SelectOption[]>;

  constructor(config: SmartSelectAsyncConfig) {
    this.originalLabel = config.label;
    this.label = config.required ? config.label : `${config.label} (optional)`;
    this.initialValueId = config.value?.id || '';
    const validator = config.required ? Validators.required : undefined;

    const initiallySelectedOption = this.selectValueToOption(config.value);
    this.control = new UntypedFormControl(initiallySelectedOption, validator);
    if (config.disabled) {
      this.control.disable({ emitEvent: false });
    }
    this.controlStateChange$$ = new Subject();
    this.controlStateChange$ = this.controlStateChange$$.asObservable();

    this.setOptionsLoader(config.loadOptionsFn);
    this.optionsErrorMessageText = config.loadOptionsErrorMessage;
    this.options$$ = new BehaviorSubject<SelectOption[]>(initiallySelectedOption ? [initiallySelectedOption] : []);
    this.options$ = this.options$$.asObservable();
    this.optionsLoading$$ = new BehaviorSubject<boolean>(false);
    this.optionsLoading$ = this.optionsLoading$$.asObservable();
    this.optionsErrorMessage$$ = new BehaviorSubject<string>(undefined);
    this.optionsErrorMessage$ = this.optionsErrorMessage$$.asObservable();
  }

  public setValue(value?: SmartSelectValue, emitEvent = false): void {
    this.control.setValue(this.selectValueToOption(value), { emitEvent });
  }

  public resetOptions(): void {
    this.options$$.next([]);
    this.optionsLoading$$.next(false);
    this.optionsErrorMessage$$.next(undefined);
    this.setOptionsLoader();
  }

  public setLoadOptionsFn(fn: () => Promise<SelectOption[]>, resetCurrentOptions = true): void {
    if (resetCurrentOptions) {
      this.options$$.next([]);
      this.optionsLoading$$.next(false);
      this.optionsErrorMessage$$.next(undefined);
    }
    this.setOptionsLoader(fn);
  }

  public async loadOptions(): Promise<void> {
    this.optionsErrorMessage$$.next(undefined);
    try {
      this.optionsLoading$$.next(true);
      const options = await this.loadOptionsFn();
      this.options$$.next(options);
    } catch (error) {
      this.optionsErrorMessage$$.next(this.optionsErrorMessageText);
    } finally {
      this.optionsLoading$$.next(false);
    }
  }

  public getValue(): SmartSelectValue | undefined {
    const selected: SelectOption = this.control.value;
    if (selected) {
      return {
        id: selected.value,
        label: selected.displayLabel,
      };
    }
    return undefined;
  }

  public hasChanges(): boolean {
    const selectedId = this.getValue()?.id || '';
    return selectedId !== this.initialValueId;
  }

  public isValid(): boolean {
    return this.control.valid;
  }

  public showErrorIfAny(): void {
    this.control.markAsTouched();
    this.controlStateChange$$.next();
  }

  public setDisabled(disabled: boolean): void {
    if (disabled) {
      this.control.disable({ emitEvent: false });
    } else {
      this.control.enable({ emitEvent: false });
    }
    this.controlStateChange$$.next();
  }

  public setRequired(required: boolean): void {
    if (required) {
      this.control.setValidators(Validators.required);
      this.label = this.originalLabel;
    } else {
      this.control.setValidators(null);
      this.label = this.originalLabel + ' (optional)';
    }
    this.control.updateValueAndValidity({ emitEvent: false });
    this.controlStateChange$$.next();
  }

  public getValueChanges(): Observable<any> {
    return this.control.valueChanges;
  }

  private setOptionsLoader(fn?: () => Promise<SelectOption[]>): void {
    this.loadOptionsFn = typeof fn === 'function' ? fn : () => Promise.resolve([]);
  }

  private selectValueToOption(value?: SmartSelectValue): SelectOption | undefined {
    if (value) {
      return {
        value: value.id,
        displayLabel: value.label,
      };
    }
    return undefined;
  }
}
