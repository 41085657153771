<svg
  class="icon"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <g fill="none" fill-rule="evenodd" transform="translate(3 1)">
    <path
      stroke="#959597"
      stroke-width="2"
      d="M1 9.99c.08 3.737.086 7.74.018 12.01h15.988c.026-4.742.024-8.74-.006-12 0-3.47-3.021-5.988-8-5.988-4.905 0-7.994 2.61-8 5.978z"
    />
    <rect width="9" height="6" x="4.5" y="11.5" stroke="#959597" rx="1" />
    <path
      stroke="#959597"
      stroke-width="2"
      d="M12 3.5C12 1.567 10.657 0 9 0S6 1.567 6 3.5"
    />
    <path class="filled-element" fill="#959597" d="M5 13H13V14H5z" />
    <path stroke="#959597" d="M10.5 14.5H11.5V15.5H10.5z" />
  </g>
</svg>
