<wp-square-border
  [highlighted]="isOpened"
  [width]="widthStr"
  [matMenuTriggerFor]="dropdownTemplate"
  (menuOpened)="onMenuOpenChanged(true)"
  (menuClosed)="onMenuOpenChanged(false)"
>
  <wp-dropdown-trigger>
    <ng-container *ngIf="selectedDate; else noValueTemplate;">
      <span class="bold"> {{selectedDate | displayYYYYMMDD }} </span>
    </ng-container>
    <ng-template #noValueTemplate> {{ label }} </ng-template>
  </wp-dropdown-trigger>
</wp-square-border>

<mat-menu #dropdownTemplate>
  <div class="date-select-wrap">
    <wp-multi-date-picker
      [selected]="selectedDate ? [selectedDate] : undefined"
      [isSingleSelect]="true"
      (selectedChanged)="selectedDate = $event[0]; selectedChanged.emit($event[0])"
    ></wp-multi-date-picker>
  </div>
</mat-menu>
