import { createAction, props } from '@ngrx/store';
import { WpError } from '../../types';

export const goErrorPage = createAction('[Router] Go error page', props<{ error: WpError }>());

export const destroyErrorPage = createAction('[ErrorPage] Destroy error page');

export const openErrorPopup = createAction(
  '[ErrorEffects] Open error popup',
  props<{ error: WpError; hideErrorHeader?: boolean; closePrevious?: boolean }>(),
);

export const openDefaultErrorPopup = createAction(
  '[ErrorEffects] Open default error popups',
  props<{ error: WpError; hideErrorHeader?: boolean; closePrevious?: boolean }>(),
);
