import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HomeUIState } from '../reducers/home-ui.reducer';

export const homeUIModuleKey = 'ui';
export const getHomeUIState = createFeatureSelector<HomeUIState>(homeUIModuleKey);

// section loading
export const sectionLoading = createSelector(getHomeUIState, (state) => state.sectionRouteLoading);

// ui
const getSideBarState = createSelector(getHomeUIState, (state) => state.sideBar);

export const sidebarExpanded = createSelector(getSideBarState, (state) => state.isExpanded);

const screenState = createSelector(getHomeUIState, (state) => state.screen);

export const xLargeScreen = createSelector(screenState, (state) => state.isXLarge);

export const homePageSidePadding = createSelector(
  sidebarExpanded,
  xLargeScreen,
  (sideBarExtended, isXLarge): string => {
    if (isXLarge) {
      return '90px';
    }
    return sideBarExtended ? '30px' : '90px';
  },
);

export const currentSideBarSection = createSelector(getSideBarState, (state) => state.selectedSection);

// drawer
const getDrawerState = createSelector(getHomeUIState, (state) => state.drawer);

export const drawerOpened = createSelector(getDrawerState, (state) => state.isOpened);
export const isHalfDrawer = createSelector(getDrawerState, (state) => state.isHalfDrawer);
