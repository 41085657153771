<div
  class="input-time-increment"
  wpKeyboardListener
  (arrowUpPressed)="onIncrement()"
  (arrowDownPressed)="onDecrement()"
>
  <div
    class="increment minus"
    tabindex="-1"
    (click)="onDecrement()"
    wpLongPress
    (longPressed)="onDecrement()"
  >
    <wp-btn-increment-minus></wp-btn-increment-minus>
  </div>
  <div class="input-time" [ngStyle]="{ width: inputWidthStr }">
    <mat-form-field style="width: 100%" [hideRequiredMarker]="true">
      <input
        matInput
        type="text"
        autocomplete="off"
        class="input"
        [(ngModel)]="displayInputValue"
        [disabled]="control?.disabled"
        (blur)="onInputBlurEvent()"
        [readonly]="!!preventManualInput"
        tabindex="0"
      />
    </mat-form-field>
  </div>
  <div
    class="increment plus"
    wpLongPress
    (click)="onIncrement()"
    (longPressed)="onIncrement()"
  >
    <wp-btn-increment-plus></wp-btn-increment-plus>
  </div>
</div>
<mat-error class="error" *ngIf="control.errors?.['required']">
  this is required
</mat-error>
<mat-error class="error" *ngIf="control.errors?.['startHHMM']">
  start time is always after
</mat-error>
