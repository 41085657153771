import { Injectable } from '@angular/core';
import * as fromTypes from '.';
import { PortalEntityType } from '.';
import { Observable, of } from 'rxjs';
import { ApiService } from 'src/app/api/api.service';
import { map, tap } from 'rxjs/operators';
import { getCrossEntitySearchRequest } from '@rootTypes/utils/portal-entity/get-cross-entity-search-request';
import { entitySearchResultToPortalEntity } from '@rootTypes/utils/portal-entity/entity-search-result-to-portal-enity';

interface CacheState {
  [entityId: string]: string;
}

@Injectable()
export class CrossEntitySearchApiService {
  private cache: CacheState = {};

  constructor(private api: ApiService) {}

  public entitySearch(
    query: string,
    skip: number,
    limit: number,
    types: fromTypes.PortalEntityType[],
  ): Observable<fromTypes.SearchResponseWithSearhTerm> {
    const request = getCrossEntitySearchRequest(query, types, skip, limit);
    return this.api.entitySearch(request).pipe(
      map((resp) => {
        const result: fromTypes.SearchResponseWithSearhTerm = {
          results: resp.results.map((r) => entitySearchResultToPortalEntity(r)),
          hits: resp.hits,
          nextSkip: resp.nextSkip,
          searchTerm: query,
          error: null,
        };
        result.results.forEach((r) => {
          let label = r.label;
          if (r.type === PortalEntityType.RIDE) {
            label = `Ride ${r.label}`;
          }
          if (r.type === PortalEntityType.ROUTE) {
            label = `Route ${r.label}`;
          }
          this.cache[r.entityId] = label;
        });
        return result;
      }),
    );
  }

  public getDisplayEntityName(entityId: string, entityType: fromTypes.PortalEntityType): Observable<string> {
    const cached = this.cache[entityId];
    if (cached) {
      return of(cached);
    }
    return this.getDisplayEntityObs(entityId, entityType).pipe(
      tap((displayName) => (this.cache[entityId] = displayName)),
    );
  }

  private getDisplayEntityObs(entityId: string, entityType: fromTypes.PortalEntityType): Observable<string> {
    switch (entityType) {
      case fromTypes.PortalEntityType.CAMPUS:
        return this.getCampusDisplayName(entityId);
      case fromTypes.PortalEntityType.DISTRICT:
        return this.getDistrictDisplayName(entityId);
      case fromTypes.PortalEntityType.STUDENT:
        return this.getStudentDisplayName(entityId);
      case fromTypes.PortalEntityType.PARENT:
        return this.getParentDisplayName(entityId);
      case fromTypes.PortalEntityType.DRIVER:
        return this.getDriverDisplayName(entityId);
      case fromTypes.PortalEntityType.VENDOR:
        return this.getVendorDisplayName(entityId);
      case fromTypes.PortalEntityType.YARD:
        return this.getYardDisplayName(entityId);
      case fromTypes.PortalEntityType.RIDE:
        return this.getRideDisplayName(entityId);
      case fromTypes.PortalEntityType.ROUTE:
        return this.getRouteDisplayName(entityId);
      case fromTypes.PortalEntityType.VEHICLE:
        return this.getVehicleDisplayName(entityId);
      case fromTypes.PortalEntityType.VENDOR_EMPLOYEE:
        return this.getVendorEmployeeDisplayName(entityId);
      case fromTypes.PortalEntityType.SCHOOL_EMPLOYEE:
        return this.getSchoolEmployeeDisplayName(entityId);
      case PortalEntityType.ROUTE_GROUP:
        return this.getRouteGroupDisplayName(entityId);
      case PortalEntityType.CHARTER_TRIP:
        return this.getCharterTripDisplayName(entityId);
      case PortalEntityType.TRANSPORTATION_REGISTRATION:
        return this.getTransportationRegistrationDisplayName(entityId);
      case PortalEntityType.STUDENT_RFID_ORDER:
        return this.getStudentRfidOrderDisplayName(entityId);
      default: {
        console.warn('Unknown entity type ', entityType);
        return of('Unknown');
      }
    }
  }

  private getRouteGroupDisplayName(routeGroupId: string): Observable<string> {
    return this.api.getRouteGroup({ routeGroupId }).pipe(map((res) => `Route: ${res.routeGroupName}`));
  }

  private getCampusDisplayName(campusId: string): Observable<string> {
    return this.api.getCampus({ campusId }).pipe(map((res) => res.campus.name));
  }

  private getDistrictDisplayName(districtId: string): Observable<string> {
    return this.api.getDistrict({ districtId }).pipe(map((res) => res?.district?.name));
  }

  private getStudentDisplayName(studentId: string): Observable<string> {
    return this.api.getStudentV2({ studentId }).pipe(map((res) => `${res.profile.firstName} ${res.profile.lastName}`));
  }

  private getDriverDisplayName(driverId: string): Observable<string> {
    return this.api
      .getDriverProfile(driverId)
      .pipe(map((driver) => `${driver.profile.first_name} ${driver.profile.last_name}`));
  }

  private getVendorDisplayName(vendorId: string): Observable<string> {
    return this.api
      .getVendorProfile({ vendor_id: vendorId })
      .pipe(map((vendor) => `${vendor?.vendor_info?.company_name}`));
  }

  private getParentDisplayName(parentId: string): Observable<string> {
    return this.api
      .getUserProfile({ owner_id: parentId, role: 'customer' })
      .pipe(map((res) => `${res.first_name} ${res.last_name}`));
  }

  private getRideDisplayName(rideId: string): Observable<string> {
    return this.api.getRide({ rideId }).pipe(map((res) => `Ride ${res.ride.displayId}`));
  }

  private getRouteDisplayName(routeId: string): Observable<string> {
    return this.api.getRoute({ route_id: routeId }).pipe(map((res) => `Run ${res.display_id}`));
  }

  private getSchoolEmployeeDisplayName(schoolEmployeeId: string): Observable<string> {
    return this.api
      .getSchoolEmployee({ schoolEmployeeId })
      .pipe(map((r) => `${r.schoolEmployee?.firstName} ${r.schoolEmployee?.lastName}`));
  }

  private getVehicleDisplayName(vehicleId: string): Observable<string> {
    return this.api
      .getVehicle({ vehicleId })
      .pipe(map((res) => `${res.vehicle.make} ${res.vehicle.model}, ${res.vehicle.vehicleDisplayId}`));
  }

  private getYardDisplayName(yardId: string): Observable<string> {
    return this.api.getYard({ yardId }).pipe(map((res) => res?.yard?.name));
  }

  private getVendorEmployeeDisplayName(vendorEmployeeId: string): Observable<string> {
    return this.api
      .getVendorEmployee({ vendorEmployeeId })
      .pipe(map((res) => `${res.vendorEmployee.firstName} ${res.vendorEmployee.lastName}`));
  }

  public getCharterTripDisplayName(charterTripId: string): Observable<string> {
    return this.api.getCharterTrip({ tripId: charterTripId }).pipe(map((res) => res.tripDisplayId));
  }

  private getTransportationRegistrationDisplayName(registrationId: string): Observable<string> {
    return this.api.getTransportationRegistrationDetail({ registrationId }).pipe(
      map((res) => {
        let studentDisplayId: string;
        if (res.transportationRegistrationDetails.new) {
          studentDisplayId = res.transportationRegistrationDetails.new.onFileDetails.externalStudent.id;
        } else {
          studentDisplayId = res.transportationRegistrationDetails.active.onFileDetails.externalStudent.id;
        }
        const { studentFirstName, studentLastName } = res.transportationRegistrationDetails;
        return `${studentDisplayId} - ${studentFirstName} ${studentLastName}`;
      }),
    );
  }

  private getStudentRfidOrderDisplayName(orderId: string): Observable<string> {
    return this.api.getStudentRfidOrder({ orderId }).pipe(map((res) => res.order.label));
  }
}
