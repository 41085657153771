import { createSelector } from '@ngrx/store';
import { getEmployeesFeature } from './get-feature';
import { getAllEmployeeEntities, getAllEmployeePermissions } from './employee-data.selectors';

export const getEmployeeDetailsState = createSelector(getEmployeesFeature, (state) => state.details);

export const getEmployeeId = createSelector(getEmployeeDetailsState, (state) => state.employeeId);

export const getTab = createSelector(getEmployeeDetailsState, (state) => state.tab);

const getOpenedEmployeeEntityState = createSelector(
  getEmployeeId,
  getAllEmployeeEntities,
  (employeeId, allEmployees) => {
    if (!employeeId) {
      return undefined;
    }
    return allEmployees[employeeId];
  },
);

export const getOpenedEmployee = createSelector(getOpenedEmployeeEntityState, (state) => state?.entity);
export const getDistrictIdByOpenedEmployee = createSelector(getOpenedEmployee, (state) => state?.parentEntityId);
export const getOpenedEmployeeLoading = createSelector(getOpenedEmployeeEntityState, (state) => state?.isLoading);

export const getOpenedEmployeeError = createSelector(getOpenedEmployeeEntityState, (state) => state?.error);

export const getOpenEmployeePermissions = createSelector(
  getEmployeeId,
  getAllEmployeePermissions,
  (employeeId, allPermissions) => allPermissions[employeeId],
);
