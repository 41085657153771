import { ActivityStatus } from '@apiEntities/timesheet-entries';

export const activityStatuses = [
  ActivityStatus.NEW,
  ActivityStatus.ASSIGNED,
  ActivityStatus.STARTED,
  ActivityStatus.COMPLETED,
  ActivityStatus.PENDING_REVIEW,
  ActivityStatus.APPROVED,
  ActivityStatus.REJECTED,
  ActivityStatus.SUBMITTED_FOR_PAYMENT,
  ActivityStatus.UNPAID,
  ActivityStatus.CANCELED,
];

export const activityDisplayLabels = {
  [ActivityStatus.NEW]: { long: 'Unassigned', short: 'Unassigned' },
  [ActivityStatus.PENDING_REVIEW]: { long: 'Pending review', short: 'Pending' },
  [ActivityStatus.SUBMITTED_FOR_PAYMENT]: { long: 'Submitted for payment', short: 'Submitted for payment' },
  [ActivityStatus.UNPAID]: { long: 'Unpaid', short: 'Unpaid' },
  [ActivityStatus.ASSIGNED]: { long: 'Assigned', short: 'Assigned' },
  [ActivityStatus.APPROVED]: { long: 'Approved', short: 'Approved' },
  [ActivityStatus.REJECTED]: { long: 'Rejected', short: 'Rejected' },
  [ActivityStatus.STARTED]: { long: 'Incomplete', short: 'Incomplete' },
  [ActivityStatus.COMPLETED]: { long: 'Completed', short: 'Completed' },
  [ActivityStatus.CANCELED]: { long: 'Canceled', short: 'Canceled' },
};

// 'red' | 'green' | 'orange' | 'grey-dark' | 'grey-light' | 'grey-circle'
export const activityStatusColors = {
  [ActivityStatus.PENDING_REVIEW]: 'orange',
  [ActivityStatus.SUBMITTED_FOR_PAYMENT]: 'grey-dark',
  [ActivityStatus.UNPAID]: 'grey-dark',
  [ActivityStatus.APPROVED]: 'green',
  [ActivityStatus.REJECTED]: 'red',
  [ActivityStatus.STARTED]: 'grey-circle',
  [ActivityStatus.COMPLETED]: 'grey-light',
};
