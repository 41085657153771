import { isSameDay, isSameWeek, isSameISOWeek, isSameMonth, isSameYear } from 'date-fns';
export const datesIsSame = (
  date1: Date,
  date2: Date,
  granularity?: 'day' | 'week' | 'month' | 'year',
  isMondayFirstDay?: boolean,
): boolean => {
  if (!granularity) {
    return date1.getTime() === date2.getTime();
  }
  switch (granularity) {
    case 'day':
      return isSameDay(date1, date2);
    case 'week':
      return isMondayFirstDay ? isSameISOWeek(date1, date2) : isSameWeek(date1, date2);
    case 'month':
      return isSameMonth(date1, date2);
    case 'year':
      return isSameYear(date1, date2);
  }
};
