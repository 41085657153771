import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchoolEmployeeApiService } from './services/school-employee-api.service';
import * as fromStore from './store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers.employeesReducer),
    EffectsModule.forFeature(fromStore.effects.employeeEffects),
  ],
  providers: [SchoolEmployeeApiService, ...fromStore.facades.employeeFacades],
})
export class EmployeeSchoolDataModule {}
