import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-icon-campus-light-green',
  templateUrl: './icon-campus-light-green.component.html',
  styleUrls: ['./icon-campus-light-green.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconCampusLightGreenComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
