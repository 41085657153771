import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { CampusApiService } from '../../services/campus-api.service';
import * as fromActions from '../actions/campus-data.actions';
import * as fromDistrictActions from '../../../districts/store/actions/district-data.actions';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';

@Injectable()
export class CampusDataEffects {
  constructor(
    private actions$: Actions,
    private api: CampusApiService,
    private store: Store<State>,
  ) {}

  public loadCampusRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.loadCampusDetailsRequested,
        fromActions.loadCampusDetailsRequestedFromCampusEditor,
        fromActions.loadCampusDetailsRequestedFromCampusCalendarEditor,
        fromActions.loadCampusDetailsRequestedFromCampusScheduleEditor,
        fromActions.loadCampusDetailsRequestedFromStudentDetails,
      ),
      mergeMap((action) => {
        const { campusId, options } = action;
        return this.api.getCampus(campusId).pipe(
          map((campus) => {
            if (options && options.withDistrict) {
              this.store.dispatch(fromDistrictActions.loadDistrictRequested({ districtId: campus.districtId }));
            }
            return fromActions.loadCampusDetailsSuccess({
              campus,
            });
          }),
          catchError((err) => {
            console.log(err);
            return of(
              fromActions.loadCampusDetailsFailed({
                campusId,
                error: {
                  text: 'Failed to load campus',
                  originalError: err,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  public loadAllCampusChecksRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAllChecksForCampusesRequested),
      switchMap(() => {
        return this.api.getAllChecksForCampus().pipe(
          map((checks) => fromActions.loadAllChecksForCampusesSuccess({ checks })),
          catchError((err) => {
            console.log(err);
            return of(
              fromActions.loadAllChecksForCampusesFailed({
                error: {
                  text: 'Failed to load checks for campuses',
                  originalError: err,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  public removeCampusRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeCampusRequested),
      mergeMap((action) => {
        const { campusId, districtId } = action;
        return this.api.deactivateCampus(campusId, districtId).pipe(
          map(() => fromActions.removeCampusSuccess({ campusId, districtId })),
          catchError((err) => {
            console.log(err);
            return of(
              fromActions.removeCampusFailed({
                campusId,
                districtId,
                error: { text: 'Failed to remove campus', originalError: err },
              }),
            );
          }),
        );
      }),
    ),
  );
}
