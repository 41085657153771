import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import {
  getContextActivityError,
  getContextActivityItems,
  getSpecificContextError,
  getSpecificContextItems,
  getSpecificContextTitle,
  isContextLoading,
} from './timesheet-entry-context.selectors';
import { PortalEntity, TimesheetEntryListItem, WpError } from '@rootTypes';
import { getActivityDisplayDate } from './selectors';

@Injectable()
export class TimesheetEntryContextFacade {
  constructor(private store: Store) {}

  public isContextLoading$(activityId: string): Observable<boolean> {
    return this.store.select(isContextLoading(activityId));
  }

  public getContextActivityItems$(activityId: string): Observable<TimesheetEntryListItem[]> {
    return this.store.select(getContextActivityItems(activityId));
  }

  public getContextActivityError$(activityId: string): Observable<WpError> {
    return this.store.select(getContextActivityError(activityId));
  }

  public getContextSpecificItems$(activityId: string): Observable<PortalEntity[]> {
    return this.store.select(getSpecificContextItems(activityId));
  }

  public getSpecificContextError$(activityId: string): Observable<WpError> {
    return this.store.select(getSpecificContextError(activityId));
  }

  public getSpecificContextTitle$(activityId: string): Observable<string> {
    return this.store.select(getSpecificContextTitle(activityId));
  }

  public getDisplayOpenedActivityDate$(activityId: string): Observable<string> {
    return this.store.select(getActivityDisplayDate(activityId));
  }
}
