import { Pipe, PipeTransform } from '@angular/core';
import { getDisplayQuantity } from '@rootTypes/utils/common/get-display-quantity';

@Pipe({
  name: 'displayQuantity',
})
export class DisplayQuantityPipe implements PipeTransform {
  transform(value: number, entityName: string, customPluralForm?: string): string {
    return getDisplayQuantity(value, entityName, customPluralForm);
  }
}
