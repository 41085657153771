const driverGenderToDisplayMap = {
  MALE: 'Male',
  FEMALE: 'Female',
  OTHER: 'Other',
};

export const driverGenderToDisplay = (gender?: string): string => {
  if (!gender) {
    return undefined;
  }
  const displayGender = driverGenderToDisplayMap[gender];
  if (displayGender) {
    return displayGender;
  }
  return 'N/A';
};
