import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SideBarService } from '../side-bar.service';

@Component({
  selector: 'wp-side-bar-text',
  templateUrl: './side-bar-text.component.html',
  styleUrls: ['./side-bar-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideBarTextComponent implements OnInit, OnChanges {
  @Input() public text: string;
  @Input() public id: string;
  @Input() public smallFont: boolean;
  @Input() public isShown: boolean;

  public isActive$: Observable<boolean>;
  public textWithEllipsis: string;

  private maxTextLength = 15;

  constructor(private sideBarService: SideBarService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.text) {
      this.setTextWithEllipsis();
    }
  }

  public ngOnInit(): void {
    this.isActive$ = this.sideBarService.active$.pipe(map((id) => id === this.id));
  }

  // TODO: replace with CSS solution
  // https://css-tricks.com/line-clampin/
  public setTextWithEllipsis(): void {
    if (!this.text) {
      this.textWithEllipsis = '';
      return;
    }
    if (this.text.length > this.maxTextLength) {
      this.textWithEllipsis = this.text.slice(0, this.maxTextLength) + '...';
    } else {
      this.textWithEllipsis = this.text;
    }
  }
}
