import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-status-with-label',
  templateUrl: './status-with-label.component.html',
  styleUrls: ['./status-with-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusWithLabelComponent implements OnInit {
  @Input() public color: 'red' | 'green' | 'orange' | 'grey-dark' | 'grey-light' | 'grey-circle' | string;
  @Input() public tooltip: string;
  @Input() public alignLeftToParent: boolean;

  /**
   * &.orange {
   *     background-color: $orange;
   *   }
   *   &.green {
   *     background-color: $rich-green;
   *   }
   *   &.red {
   *     background-color: #e24028;
   *   }
   *   &.grey-dark {
   *     background-color: $elephant-grey;
   *   }
   *   &.grey-light {
   *     background-color: $disabled-grey;
   *   }
   *   &.grey-circle {
   *     background-color: white;
   *     border: 2px solid $disabled-grey;
   *   }
   */
  private standardBGColors = {
    ['red']: '#e24028',
    ['orange']: '#ff9600',
    ['green']: '#47a540',
    ['grey-dark']: '#7c7c7e',
    ['grey-light']: '#c6c6ca',
    ['grey-circle']: 'white',
  };
  private standardBorders = {
    ['red']: 'none',
    ['orange']: 'none',
    ['green']: 'none',
    ['grey-dark']: 'none',
    ['grey-light']: 'none',
    ['grey-circle']: '2px solid #c6c6ca',
  };

  constructor() {}

  ngOnInit(): void {}

  public get bgColor(): string {
    return this.standardBGColors[this.color] ?? this.color;
  }

  public get border(): string {
    return this.standardBorders[this.color] ?? 'none';
  }
}
