import { getCampusId } from './campus-details.selectors';
import { createSelector } from '@ngrx/store';
import { getAllLocationsByCampuses, getAllPrimaryLocations, getAllLocations } from '../locations-data.selectors';
import * as fromTypes from '../../../types';
import { getFeatureState } from '../feature-selector';

/**
 * State for locations tab (like view mode, selected location)
 */
const getLocationsTabState = createSelector(getFeatureState, (state) => state.details.locations);

export const getViewMode = createSelector(getLocationsTabState, (state) => state.viewMode);

export const getSelectedLocationId = createSelector(getLocationsTabState, (state) => state.selectedLocationId);

const getSelectedLocationState = createSelector(getSelectedLocationId, getAllLocations, (selectedId, allLocations) => {
  if (!selectedId) {
    return undefined;
  }
  return allLocations[selectedId];
});

export const getSelectedLocation = createSelector(getSelectedLocationState, (state) => {
  if (!state) {
    return undefined;
  }
  if (state.isRemoved) {
    return undefined;
  }
  return state.entity;
});

/**
 * Location data for open campus
 */
const getLocationStateForOpenCampus = createSelector(
  getCampusId,
  getAllLocationsByCampuses,
  (campusId, allLocsByCampuses) => allLocsByCampuses[campusId],
);

export const getLocationIdsForOpenCampus = createSelector(
  getLocationStateForOpenCampus,
  (state): string[] => state?.entity,
);

export const getLocationsForOpenCampus = createSelector(
  getLocationIdsForOpenCampus,
  getAllLocations,
  (locationIds, allLocations): fromTypes.CampusLocation[] => {
    if (!locationIds) {
      return [];
    }
    return locationIds
      .map((id) => allLocations[id])
      .filter((item) => !!item && !item.isRemoved)
      .map((item) => item.entity);
  },
);

export const getLocationsForPhotos = createSelector(getSelectedLocation, getLocationsForOpenCampus, (selected, all) => {
  const locations = selected ? [selected] : all;
  return locations.filter((l) => l.photos && l.photos.length);
});

export const getIsAnyPhotosForOpenCampusLocations = createSelector(getLocationsForPhotos, (locs) => !!locs.length);

export const getLocationsLoadingForOpenCampus = createSelector(
  getLocationStateForOpenCampus,
  (state): boolean => state?.isLoading,
);

export const getLocationsEmptyForOpenCampus = createSelector(
  getLocationStateForOpenCampus,
  (state): boolean => !!state && !state.isLoading && (state.entity || []).length === 0,
);

export const getLocationsErrorForOpenCampus = createSelector(
  getLocationStateForOpenCampus,
  (state): fromTypes.WpError => state?.error,
);

export const getLocationsCountForOpenCampus = createSelector(
  getLocationIdsForOpenCampus,
  (locIds) => (locIds || []).length,
);

const getPrimaryLocationStateForOpenCampus = createSelector(
  getCampusId,
  getAllPrimaryLocations,
  (campusId, allPrimaryLocs) => allPrimaryLocs[campusId],
);

export const getPrimaryLocationIdForOpenCampus = createSelector(
  getPrimaryLocationStateForOpenCampus,
  (state) => state?.locationId,
);

export const getPrimaryLocationLoadingForOpenCampus = createSelector(
  getPrimaryLocationStateForOpenCampus,
  (state) => state?.isLoading || false,
);
