import { ChangeDetectionOptimizer } from '@rootTypes/utils/map-tracker/change-detection-optimizer';

export class DefaultChangeDetectionOptimizer implements ChangeDetectionOptimizer {
  runInsideChangeDetection(cb: () => void): void {
    cb();
  }

  runOutsideChangeDetection(cb: () => void): void {
    cb();
  }
}
