import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SmartSelectAsync } from '../../../models';
import { SmartInputStudentSchoolInfoService } from '../../../services';

@Component({
  selector: 'wp-smart-input-student-school-info-grade',
  templateUrl: './smart-input-student-school-info-grade.component.html',
  styleUrls: ['./smart-input-student-school-info-grade.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartInputStudentSchoolInfoGradeComponent implements OnInit {
  public model: SmartSelectAsync;

  constructor(private service: SmartInputStudentSchoolInfoService) {}

  public ngOnInit(): void {
    this.model = this.service.grade;
  }
}
