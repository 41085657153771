<wp-btn-secondary
  [width]="widthStr"
  [slim]="true"
  [disabled]="disabled"
  (clicked)="btnClicked.emit()"
>
  <wp-btn-icon [marginRight]="'5px'" [bottom]="'-2px'">
    <wp-icon-plus-circle [grey]="disabled"></wp-icon-plus-circle>
  </wp-btn-icon>
  <ng-content></ng-content>
</wp-btn-secondary>
