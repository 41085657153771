import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { StudentDetailsTab } from '@rootTypes/entities/rider';

export interface StudentDetailsRouteData {
  studentId: string;
  tabId?: StudentDetailsTab;
  displayBackText?: string;
}

export const studentDetailsDrawer: PortalRoute<StudentDetailsRouteData> = {
  path: 'details/:studentId/:tabId',
  isOnRoute: (state: RouterStateUrl) => {
    return state.drawer && state.drawer.url && state.drawer.url.startsWith('_/drawer/students/details');
  },
  parse: (state: RouterStateUrl): StudentDetailsRouteData => {
    let res = {
      studentId: state.drawer.params.studentId,
      tabId: state.drawer.params.tabId,
    } as StudentDetailsRouteData;
    if (state.queryParams) {
      res = {
        ...res,
        ...state.queryParams,
      };
    }
    return res;
  },
  request: (
    data: StudentDetailsRouteData,
    queryParamStrategy: QueryParamsHandlingStrategy,
    replaceUrl: boolean = false,
  ): NavigationRequest => {
    // do not mutate the original arg
    const copy = { ...data };
    const { studentId, tabId } = copy;
    const res = {
      path: ['_', 'drawer', 'students', 'details', studentId, tabId || StudentDetailsTab.DEFAULT],
    } as NavigationRequest;
    delete data.studentId;
    delete data.tabId;
    // extract additional data fields as query params
    if (Object.keys(data).length) {
      res.query = { ...data };
    }
    if (queryParamStrategy) {
      res.extras = {
        queryParamsHandling: queryParamStrategy,
        replaceUrl,
      };
    }
    return res;
  },
};
