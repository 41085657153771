import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as fromTypes from '../types';
import { EntityFilterComponent } from '../entity-filter/entity-filter.component';
import { EntityStatus } from '../types';

@Component({
  selector: 'wp-driver-select',
  templateUrl: './driver-select.component.html',
  styleUrls: ['./driver-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverSelectComponent implements OnInit {
  // Contains PortalEntity or undefined
  @Input() public control: UntypedFormControl;

  @Input() public label: string;

  // Show only drivers of these vendors
  @Input() public vendorIds: string[];

  // Show only drivers of these vendor's yards
  @Input() public yardIds: string[];
  @Input() public operatorIds: string[];

  @Input() public entityStatus: fromTypes.EntityStatus = fromTypes.EntityStatus.ANY;

  public searchByType = fromTypes.PortalEntityType.DRIVER;
  public filterByStatus = EntityStatus.ACTIVE;
  public initialDisplayValue: string;
  public disabled: boolean;

  @ViewChild('entityFilter') private entityFilter: EntityFilterComponent;

  constructor(private cdRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.initialDisplayValue = this?.control?.value?.label || null;

    this.control.registerOnDisabledChange((disabled) => {
      this.disabled = disabled;
      this.cdRef.detectChanges();
    });
    this.disabled = this.control.disabled;
  }

  public onOptionSelect(option: fromTypes.PortalEntity): void {
    this.control.setValue(option);
  }

  public clearSearchInput(): void {
    if (this.entityFilter?.searchInputControl.value) {
      this.entityFilter.clearInput();
    }
  }
}
