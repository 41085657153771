import { Pipe, PipeTransform } from '@angular/core';
import { durationMinutesToHHMM } from '@rootTypes/utils/common/time';

@Pipe({
  name: 'durationMinutesToHHMM',
})
export class DurationMinutesToHHMMPipe implements PipeTransform {
  public transform(durationMinutes?: number): string {
    if (typeof durationMinutes !== 'number') {
      return '--';
    }
    return durationMinutesToHHMM(durationMinutes);
  }
}
