import { createSelector } from '@ngrx/store';
import { getFeatureState } from '../feature-selector';
import { getAllDistrictsState } from '../../../../districts/store/selectors/district-data.selectors';
import { CampusDetailsState } from '../../reducers/campus-details/campus-details.reducer';
import * as fromData from '../campus-data.selectors';

export const getDetailsState = createSelector(getFeatureState, (state): CampusDetailsState => state.details.root);

export const getCampusId = createSelector(getDetailsState, (state) => state.campusId);

export const getTab = createSelector(getDetailsState, (state) => state.tab);

const getCampusEntityState = createSelector(
  getCampusId,
  fromData.getAllCampuses,
  (campusId, allCampuses) => allCampuses[campusId],
);

export const getCampus = createSelector(getCampusEntityState, (state) => state?.entity);

export const getCampusLoading = createSelector(getCampusEntityState, (state) => state?.isLoading);

export const getCampusRemoving = createSelector(getCampusEntityState, (state) => state?.isRemoving || false);

export const getCampusRemoved = createSelector(getCampusEntityState, (state) => state?.isRemoved || false);

export const getCampusError = createSelector(getCampusEntityState, (state) => state?.error);

const getDistrictByCampusEntity = createSelector(getAllDistrictsState, getCampus, (allDistricts, campus) => {
  if (!(campus && campus.districtId)) {
    return null;
  }
  return allDistricts[campus.districtId];
});

export const getDistrict = createSelector(getDistrictByCampusEntity, (state) => state?.entity);

export const getDistrictLoading = createSelector(getDistrictByCampusEntity, (state) => state?.isLoading);

export const getDistrictError = createSelector(getDistrictByCampusEntity, (state) => state?.error);
