<div
  class="wrap"
  wpDoubleRangeSelectColor
  wpDoubleRangeSelectColorTick
  [colorLow]="colorLow"
  [colorHigh]="colorHigh"
  [doubleColorFloor]="options?.floor"
  [doubleColorCeil]="options?.ceil"
  [doubleColorLowValue]="currentValueFrom"
  [doubleColorHighValue]="currentValueTo"
  [doubleColorStep]="step"
  [colorNonSelected]="'#dfdfe3'"
>
  <ngx-slider
    [(value)]="currentValueFrom"
    [(highValue)]="currentValueTo"
    [options]="options"
    (valueChange)="emit()"
    (highValueChange)="emit()"
  ></ngx-slider>
</div>
