import { createSelector } from '@ngrx/store';
import { getFeatureState } from './feature-selector';
import * as fromReducer from '../reducers/student-data.reducer';
import { ApiStudentWithProjections, StudentProjections } from '@apiEntities';
import { StudentProgramCard } from '../../types/student-program-card';
import { getStudentPastPrograms, getStudentPrograms } from '../../types/utils/student-programs-mapper';

const getDataState = createSelector(getFeatureState, (state) => state.data);

// students
export const getAllStudentsState = createSelector(getDataState, (state) => state.students);

export const getSingleStudentItemState = (studentId) =>
  createSelector(getAllStudentsState, (state): fromReducer.StudentItemState => state[studentId]);

// projections

export const getAllProjectionState = createSelector(getDataState, (state) => state.projections);

const getStudentProjections = (studentId) => createSelector(getAllProjectionState, (state) => state[studentId]);

export const getStudent = (studentId) =>
  createSelector(getSingleStudentItemState(studentId), getStudentProjections(studentId), (student, projections) =>
    student && student.entity ? ({ ...student.entity, _projections: projections } as ApiStudentWithProjections) : null,
  );

export const getPrimaryParentForStudent = (studentId) =>
  createSelector(getStudent(studentId), (student) => {
    if (!(student && student.guardians)) {
      return null;
    }
    return student.guardians.find((p) => p.isPrimary);
  });

export const getStudentLoading = (studentId) =>
  createSelector(getSingleStudentItemState(studentId), (state) => (state ? state.isLoading : false));

export const getStudentError = (studentId) =>
  createSelector(getSingleStudentItemState(studentId), (state) => (state ? state.error : null));

// verification statuses
export const getAllVerificationStatusState = createSelector(
  getDataState,
  (state) => state.studentsVerificationStatuses,
);

export const getSingleVerificationEntityState = (studentId) =>
  createSelector(
    getAllVerificationStatusState,
    (state): fromReducer.StudentDataState['studentsVerificationStatuses'][string] => state[studentId],
  );

export const getStudentVerificationStatuses = (studentId) =>
  createSelector(getSingleVerificationEntityState(studentId), (state) => (state ? state.entity : null));

export const getStudentVerificationStatusLoading = (studentId) =>
  createSelector(getSingleVerificationEntityState(studentId), (state) => (state ? state.isLoading : false));

export const getPrimaryStudentVerificationStatus = (studentId) =>
  createSelector(getStudentVerificationStatuses(studentId), (statuses) => {
    if (statuses) {
      return statuses.find((s) => s.isPrimary);
    }
    return null;
  });

export const getStudentVerificationStatusForRoutesDistrict = (studentId) =>
  createSelector(getStudent(studentId), getStudentVerificationStatuses(studentId), (student, statuses) => {
    if (!(student && statuses)) {
      return null;
    }
    const districtId = student.campuses?.length ? student.campuses[0]?.districtId : null;
    return statuses.find((s) => s.districtId === districtId);
  });

export const getStudentProgramCards = (studentId: string) => {
  return createSelector(getStudent(studentId), (student): StudentProgramCard[] => {
    if (!student?._projections) {
      return undefined;
    }
    if (student._projections && !student._projections[StudentProjections.PROGRAMS]) {
      return [];
    }
    const programs = student._projections[StudentProjections.PROGRAMS].associatedDistrictPrograms;
    if (!programs?.length) {
      return [];
    }
    return getStudentPrograms(student.campuses, programs, student);
  });
};

export const getStudentPastProgramCards = (studentId: string) => {
  return createSelector(getStudent(studentId), (student): StudentProgramCard[] => {
    if (!student?._projections) {
      return undefined;
    }
    if (student._projections && !student._projections[StudentProjections.PAST_PROGRAMS]) {
      return [];
    }
    const programs = student._projections[StudentProjections.PAST_PROGRAMS];
    if (!programs?.length) {
      return [];
    }
    return getStudentPastPrograms(programs, student);
  });
};
