import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-popup-page-title',
  templateUrl: './popup-page-title.component.html',
  styleUrls: ['./popup-page-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupPageTitleComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
