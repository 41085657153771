import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { SessionService } from './api/session.service';
import { YYYYMMDDString } from '@rootTypes';
import { BaseApiService } from './api/base-api.service';
import { firstValueFrom } from 'rxjs';

export interface ExportRouteSheetData {
  fileName: string;
  fileBase64: string;
}

export interface ExportRouteOptions {
  includeStudentDetails?: boolean;
  includeDrivingDirections?: boolean;
  includeBellTimeException: boolean;
  dateRange: {
    from: YYYYMMDDString;
    to: YYYYMMDDString;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ExportRouteApiService {
  constructor(
    private http: HttpClient,
    private session: SessionService,
    private baseApiService: BaseApiService,
  ) {}

  public async exportRoute(routeId: string, options?: ExportRouteOptions): Promise<ExportRouteSheetData> {
    const res = await firstValueFrom(this.baseApiService.postBlob('exportRoute', { routeId, ...options }));
    const fileName = this.getFileNameFromResponse(res);
    console.log(fileName);
    return {
      fileName,
      fileBase64: await this.blobToBase64(res.data.blob),
    };
  }

  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const dataUrl = reader.result as string;
        resolve(dataUrl);
      };
      reader.onerror = () => {
        reject(new Error('Unable to read Blob'));
      };
      reader.readAsDataURL(blob);
    });
  }

  private getFileNameFromResponse(res: HttpResponse<Blob>): string {
    // An example of the header value: 'attachment; filename=John Smith (AM).pdf'
    return res.headers.get('content-disposition').split('filename=')[1];
  }
}
