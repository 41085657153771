import { createReducer, on } from '@ngrx/store';

import * as fromActions from '../actions/student-change-request-root.actions';
import {
  EntityState,
  createEntityState,
  GetPendingStudentChangeRequestsByStudentResponse,
  WpError,
} from '../../dependencies';
import { studentChangeRequestReviewReloadPendingRequestsRequested } from '../actions';
import { ApiStudent } from '@apiEntities';

export interface StudentChangeRequestRootState {
  studentId?: string;
  student: {
    isLoading: boolean;
    entity: ApiStudent | null;
    error?: WpError;
  };
  pendingRequestsByStudent: EntityState<GetPendingStudentChangeRequestsByStudentResponse>;
}

const createInitialStudentChangeRequestRootState = (): StudentChangeRequestRootState => {
  return {
    student: {
      isLoading: false,
      entity: null,
    },
    pendingRequestsByStudent: createEntityState(),
  };
};

export const studentChangeRequestRootReducer = createReducer(
  createInitialStudentChangeRequestRootState(),
  on(fromActions.destroyStudentChangeRequestRoot, createInitialStudentChangeRequestRootState),
  on(fromActions.initStudentChangeRequestRoot, (state, { studentId }): StudentChangeRequestRootState => {
    return {
      ...state,
      studentId,
    };
  }),
  on(
    fromActions.studentChangeRequestRootLoadPendingRequestsRequested,
    studentChangeRequestReviewReloadPendingRequestsRequested,
    (state): StudentChangeRequestRootState => {
      return {
        ...state,
        pendingRequestsByStudent: {
          isLoading: true,
        },
      };
    },
  ),
  on(
    fromActions.studentChangeRequestRootLoadPendingRequestsSuccess,
    (state, { response }): StudentChangeRequestRootState => {
      return {
        ...state,
        pendingRequestsByStudent: createEntityState(response),
      };
    },
  ),
  on(
    fromActions.studentChangeRequestRootLoadPendingRequestsFailed,
    (state, { error }): StudentChangeRequestRootState => {
      return {
        ...state,
        pendingRequestsByStudent: createEntityState(undefined, error),
      };
    },
  ),
  on(fromActions.loadStudentRequested, (state, action) => {
    return {
      ...state,
      student: {
        isLoading: true,
        entity: null,
        error: undefined,
      },
    };
  }),
  on(fromActions.loadStudentSuccess, (state, action) => {
    return {
      ...state,
      student: {
        isLoading: false,
        entity: action.student,
      },
    };
  }),
  on(fromActions.loadStudentFailed, (state, action) => {
    return {
      ...state,
      student: {
        isLoading: false,
        entity: null,
        error: action.error,
      },
    };
  }),
);
