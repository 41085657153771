import { MapCustomControlConfig } from '../interfaces';

export class CustomControlRenderer {
  constructor(private map: google.maps.Map) {}

  public setControls(buttonConfigs: MapCustomControlConfig[]): void {
    if (!buttonConfigs) {
      return;
    }
    Object.values(google.maps.ControlPosition).forEach((position) => {
      this.map.controls[position].clear();
    });
    buttonConfigs.forEach((buttonConfig) => {
      // Create the DIV to hold the control.
      const controlDiv = document.createElement('div');
      // Create the control.
      const control = this.createControl(this.map, buttonConfig);
      // Append the control to the DIV.
      controlDiv.appendChild(control);

      this.map.controls[buttonConfig.controlPosition].push(controlDiv);
    });
  }

  public updateCustomControlIcon(controlId: string, icon: string): void {
    const controlDiv = document.getElementById(controlId);
    if (controlDiv) {
      controlDiv.getElementsByTagName('img')[0].src = icon;
    }
  }

  public updateCustomControlState(controlId: string, disabled: boolean, hoverColor?: string): void {
    const controlDiv = document.getElementById(controlId) as HTMLButtonElement;
    if (controlDiv) {
      controlDiv.disabled = disabled;
      if (disabled) {
        controlDiv.style.cursor = 'default';
        controlDiv.eventListeners('mouseover').forEach((listener) => {
          controlDiv.removeEventListener('mouseover', listener);
        });
      } else {
        controlDiv.style.cursor = 'pointer';
        controlDiv.addEventListener('mouseover', () => {
          controlDiv.style.backgroundColor = hoverColor || '#F1F8F0';
        });
      }
    }
  }

  private createControl(map: google.maps.Map, buttonConfig: MapCustomControlConfig) {
    const controlButton = document.createElement('button');

    // Set CSS for the control.
    controlButton.id = buttonConfig.id;
    controlButton.style.backgroundColor = buttonConfig.backgroundColor;
    controlButton.style.border = buttonConfig.border;
    controlButton.style.borderLeft = buttonConfig.borderLeft;
    controlButton.style.borderRadius = buttonConfig.borderRadius;
    controlButton.style.boxShadow = buttonConfig.boxShadow;
    controlButton.style.color = buttonConfig.color;
    controlButton.style.cursor = buttonConfig.cursor;
    controlButton.style.fontFamily = buttonConfig.fontFamily;
    controlButton.style.fontSize = buttonConfig.fontSize;
    controlButton.style.lineHeight = buttonConfig.lineHeight;
    controlButton.style.margin = buttonConfig.margin;
    controlButton.style.padding = buttonConfig.padding;
    controlButton.style.textAlign = buttonConfig.textAlign;
    controlButton.textContent = buttonConfig.textContent;
    controlButton.title = buttonConfig.title;
    controlButton.type = buttonConfig.type;
    if (buttonConfig.icon) {
      controlButton.innerHTML = `<div style="display: flex; ${
        buttonConfig?.textContent ? 'gap: 6px;' : ''
      } align-items: center;"><img src="${buttonConfig.icon}" width="${buttonConfig.iconWidth}" height="${
        buttonConfig.iconHeight
      }" alt="${buttonConfig?.textContent || ''}" style="display: flex" /> <span>${
        buttonConfig?.textContent || ''
      }</span></div>`;
    }

    // Setup the click event listeners
    controlButton.addEventListener('click', () => {
      buttonConfig.onClick(controlButton);
    });

    controlButton.addEventListener('mouseover', () => {
      buttonConfig.onHover(controlButton);
    });

    controlButton.addEventListener('mouseout', () => {
      buttonConfig.onHoverOut(controlButton);
    });

    return controlButton;
  }
}
