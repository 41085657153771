import { Injectable } from '@angular/core';

// References:
// http://dev-test.nemikor.com/web-storage/support-test/
// https://developer.mozilla.org/en-US/docs/Web/API/Storage
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
// https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage

class Storage {
  private appPrefix = 'portal';
  private separator = '/';

  constructor(private storageType: 'sessionStorage' | 'localStorage') {}

  public clear(): void {
    if (this.isStorageAvailable()) {
      window[this.storageType].clear();
    }
  }

  public generateKey(name?: string): string {
    const length = 10;
    // Solution from: https://github.com/MatthewMueller/uid
    const hash: string = Math.random().toString(35).substr(2, length);
    if (name) {
      return `${name}-${hash}`;
    }
    return hash;
  }

  public get(key: string, isObj: boolean): any | null {
    if (!this.isStorageAvailable()) {
      return null;
    }
    const path = `${this.appPrefix}${this.separator}${key}`;
    const data: any = window[this.storageType].getItem(path);
    if (!data) {
      return null; // not found
    }
    if (isObj) {
      return JSON.parse(data);
    }
    // otherwise - there is a string
    return data;
  }

  public remove(key: string): void {
    if (this.isStorageAvailable()) {
      const path = `${this.appPrefix}${this.separator}${key}`;
      window[this.storageType].removeItem(path);
    }
  }

  /**
   * @param name a property name
   * @param data data to store
   * @param isGenerated whether to add a generated id to the property name or not
   * @return the stored property name
   */
  public set(name: string, data: any, isGenerated?: boolean): string | null {
    if (!this.isStorageAvailable()) {
      return null;
    }
    if (typeof data === 'object') {
      data = JSON.stringify(data);
    }
    if (isGenerated) {
      const key: string = this.generateKey(name);
      const storagePath = `${this.appPrefix}${this.separator}${key}`;
      window[this.storageType].setItem(storagePath, data);
      return key;
    }
    const path = `${this.appPrefix}${this.separator}${name}`;
    window[this.storageType].setItem(path, data);
    return name;
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  private isStorageAvailable(): boolean {
    try {
      const storage = window[this.storageType];
      const x = 'm__storage_test__m';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      console.error(new Error(`StorageService: ${this.storageType} not available`), e);
      return false;
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService extends Storage {
  constructor() {
    super('localStorage');
  }
}

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService extends Storage {
  constructor() {
    super('sessionStorage');
  }
}
