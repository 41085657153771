<div class="container" [ngStyle]="{'top': top, 'margin-right': marginRight}">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g
                  transform="translate(-968 -626) translate(486) translate(0 200) translate(26 410) translate(456 16)"
                >
                  <circle cx="8" cy="8" r="8" fill="#FF9600" />
                  <g fill="#FFF">
                    <path
                      d="M2.215.582v4.883c0 .383-.02.763-.058 1.14-.04.376-.091.76-.155 1.152H.214C.149 7.365.098 6.98.059 6.604.019 6.228 0 5.848 0 5.464V.583C.18.38.365.186.554.003c.504-.005.61-.003 1.068 0 .221.219.42.412.593.58zM0 10.338c0-.155.028-.3.085-.434.057-.133.134-.25.233-.35.098-.099.215-.177.35-.236.133-.058.28-.087.44-.087.155 0 .3.029.434.087.134.059.252.137.352.237.101.099.18.216.236.35.057.133.085.278.085.433 0 .156-.028.302-.085.438-.056.136-.135.253-.236.353-.1.1-.218.177-.352.233-.134.056-.28.084-.434.084-.16 0-.307-.028-.44-.084-.135-.056-.252-.134-.35-.233-.099-.1-.176-.217-.233-.353C.028 10.64 0 10.494 0 10.338z"
                      transform="translate(7 2)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</div>
