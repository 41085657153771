import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImageApiUtilsService {
  constructor(private http: HttpClient) {}

  public getImageArrayBuffer(url: string): Promise<ArrayBuffer> {
    return this.http
      .get(url, {
        responseType: 'arraybuffer',
        withCredentials: true,
      })
      .toPromise();
  }

  public arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    new Uint8Array(buffer).forEach((b) => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  }
}
