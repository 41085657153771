import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { accountTimezoneErrorLogoutRequested } from '../../auth/store';

// This component was introduced to handle Missing account timezone error (BD-3517)
@Component({
  selector: 'wp-account-timezone-error',
  templateUrl: './account-timezone-error.component.html',
  styleUrl: './account-timezone-error.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountTimezoneErrorComponent {
  constructor(private store: Store) {}

  public onLogout(): void {
    this.store.dispatch(accountTimezoneErrorLogoutRequested());
  }
}
