import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-title-five',
  templateUrl: './title-five.component.html',
  styleUrls: ['./title-five.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleFiveComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
