import { Pipe, PipeTransform } from '@angular/core';
import { UTCTime } from '@rootTypes/utils/common/date';
import { formattingConstants } from '@rootTypes/utils/common/date-time-fns/formatting-tokens';

@Pipe({
  name: 'utcStampToLocalDisplayDate',
})
export class UtcStampToLocalDisplayDatePipe implements PipeTransform {
  transform(
    regularTimestamp?: number,
    timezone?: string,
    format: keyof typeof formattingConstants = 'dateAndWeekdayMedium',
  ): string {
    if (regularTimestamp) {
      return new UTCTime(regularTimestamp, timezone).formatTo(formattingConstants[format]);
    }
    return '--';
  }
}
