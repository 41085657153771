import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-icon-checked-circle',
  templateUrl: './icon-checked-circle.component.html',
  styleUrls: ['./icon-checked-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconCheckedCircleComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
