import { ApiGrade } from '../../../../api/endpoints/common';
import { WpError } from '@rootTypes';
import { createReducer, on } from '@ngrx/store';
import {
  getDefaultDistrictGradesFailed,
  getDefaultDistrictGradesRequested,
  getDefaultDistrictGradesSuccess,
} from '../actions/default-district-grades.actions';

export interface DefaultDistrictGradeState {
  grades: ApiGrade[];
  isLoading: boolean;
  error: WpError | null;
}

const initialState: DefaultDistrictGradeState = {
  grades: [],
  isLoading: false,
  error: null,
};

export const defaultDistrictGradesReducer = createReducer<DefaultDistrictGradeState>(
  initialState,
  on(getDefaultDistrictGradesRequested, (state) => ({ isLoading: true, grades: state.grades, error: null })),
  on(getDefaultDistrictGradesSuccess, (state, { standardGrades }) => ({
    isLoading: false,
    grades: standardGrades,
    error: null,
  })),
  on(getDefaultDistrictGradesFailed, (state, { error }) => ({ isLoading: false, grades: state.grades, error })),
);
