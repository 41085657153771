import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { SideBarService } from '../side-bar.service';

import { iconPaths } from '@rootTypes/utils';
import { accessManagementRootPath } from '../../../router/portal-routes';
import { tripsPath } from '../../../router/portal-routes/trips/trips-path';
import { invoicingPath } from '../../../router/portal-routes/invoicing/invoicing-path';
import { transportationRequestsPath } from '../../../router/portal-routes/transportation-requests/transportation-requests-path';
import { dashboardRootPath } from '../../../router/portal-routes/dashboard/dashboard-root-path';

@Component({
  selector: 'wp-side-bar-icon',
  templateUrl: './side-bar-icon.component.html',
  styleUrls: ['./side-bar-icon.component.scss'],
})
export class SideBarIconComponent implements OnInit {
  @Input() public type!: string;

  public isActive$: Observable<string>;

  public dashboardPath = dashboardRootPath;
  public activitiesActive = iconPaths.ACTIVITIES_GREEN;
  public activitiesInactive = iconPaths.ACTIVITIES_GREY;
  public routeManagementIconPath = iconPaths.COMPASS_GREY;
  public reportsIconPathInactive = iconPaths.ANALYTICS_GREY;
  public reportsIconPathActive = iconPaths.ANALYTICS_GREEN;
  public vehicleInspectionsIconInactive = iconPaths.VEHICLE_INSPECTIONS_GREY;
  public vehicleInspectionsIconActive = iconPaths.VEHICLE_INSPECTIONS_GREEN;

  public accessManagementId = accessManagementRootPath;
  public accessManagementActive = iconPaths.ACCESS_LOCK_GREEN;
  public accessManagementInactive = iconPaths.ACCESS_LOCK_GREY;

  public tripsUrlPath = tripsPath;
  public invoicingUrlPath = invoicingPath;

  public transportationRequestsId = transportationRequestsPath;
  public transportationRequestsActive = iconPaths.TRANSPORTATION_REQUESTS_GREEN;
  public transportationRequestsInactive = iconPaths.TRANSPORTATION_REQUESTS_GREY;

  constructor(private sideBarService: SideBarService) {
    this.isActive$ = this.sideBarService.active$;
  }

  ngOnInit(): void {}
}
