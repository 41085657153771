import { Pipe, PipeTransform } from '@angular/core';
import { Weekday } from '@rootTypes';
import { getShortWeekday } from '@rootTypes/utils/common/date';

@Pipe({
  name: 'displayWeekdaysCommaShort',
})
export class DisplayWeekdaysCommaShortPipe implements PipeTransform {
  transform(value: Weekday[]): string {
    if (!value?.length) {
      return '--';
    }
    return value.map((w) => getShortWeekday(w as Weekday)).join(', ');
  }
}
