import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconArrowRightComponent } from './icon-arrow-right/icon-arrow-right.component';
import { IconArrowLeftComponent } from './icon-arrow-left/icon-arrow-left.component';
import { IconArrowDownComponent } from './icon-arrow-down/icon-arrow-down.component';
import { IconArrowLeftDoubleComponent } from './icon-arrow-left-double/icon-arrow-left-double.component';
import { IconArrowUpComponent } from './icon-arrow-up/icon-arrow-up.component';
import { IconArrowRightDoubleComponent } from './icon-arrow-right-double/icon-arrow-right-double.component';
import { IconArrowDownLargeComponent } from './icon-arrow-down-large/icon-arrow-down-large.component';

const components = [
  IconArrowRightComponent,
  IconArrowLeftComponent,
  IconArrowDownComponent,
  IconArrowLeftDoubleComponent,
  IconArrowUpComponent,
  IconArrowRightDoubleComponent,
  IconArrowDownLargeComponent,
];

@NgModule({
  declarations: [...components],
  imports: [CommonModule],
  exports: [...components],
})
export class ArrowIconsModule {}
