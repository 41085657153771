import { CalendarExclusionBase } from '../../../../../types';
import { ApiCalendarExclusion } from '../../calendar-exclusion';

export const getCalendarExclusionFromApi = (source: ApiCalendarExclusion): CalendarExclusionBase => {
  const ex: CalendarExclusionBase = {
    id: source.calendarExclusionId,
    dates: [...(source.dates || [])],
    name: source.displayName,
    isPublic: source.isPublic,
  };
  return ex;
};
