import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const modulePrefix = 'Data';
const sectionPrefix = 'Campus';

export const loadCampusDetailsRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load campus details requested`,
  props<{ campusId: string; options?: fromTypes.CampusLoadOptions }>(),
);

export const loadCampusDetailsRequestedFromCampusEditor = createAction(
  `[CampusEditorEffects]: API Load campus details requested`,
  props<{ campusId: string; options?: fromTypes.CampusLoadOptions }>(),
);

export const loadCampusDetailsRequestedFromCampusCalendarEditor = createAction(
  `[CampusCalendarEditorEffects]: API Load campus details requested`,
  props<{ campusId: string; options?: fromTypes.CampusLoadOptions }>(),
);

export const loadCampusDetailsRequestedFromCampusScheduleEditor = createAction(
  `[CampusScheduleEditorEffects]: API Load campus details requested`,
  props<{ campusId: string; options?: fromTypes.CampusLoadOptions }>(),
);

export const loadCampusDetailsRequestedFromStudentDetails = createAction(
  `[CampusScheduleEditorEffects]: API Load campus details requested from student details`,
  props<{ campusId: string; options?: fromTypes.CampusLoadOptions }>(),
);

export const loadCampusDetailsSuccess = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load campus details success`,
  props<{ campus: fromTypes.Campus }>(),
);

export const loadCampusDetailsFailed = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load campus details failed`,
  props<{ campusId: string; error: fromTypes.WpError }>(),
);

export const loadAllChecksForCampusesRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load all checks for campuses requested`,
);

export const loadAllChecksForCampusesSuccess = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load all checks for campuses success`,
  props<{ checks: fromTypes.Check[] }>(),
);

export const loadAllChecksForCampusesFailed = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load all checks for campuses failed`,
  props<{ error: fromTypes.WpError }>(),
);

export const removeCampusRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Remove campus requested`,
  props<{ campusId: string; districtId: string }>(),
);

export const removeCampusSuccess = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Remove campus success`,
  props<{ campusId: string; districtId: string }>(),
);

export const removeCampusFailed = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Remove campus failed`,
  props<{ campusId: string; districtId: string; error: fromTypes.WpError }>(),
);
