import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormControl } from '@angular/forms';

export class PhoneInputErrorStateMatcher implements ErrorStateMatcher {
  constructor(private control: UntypedFormControl) {}

  isErrorState(control: UntypedFormControl): boolean {
    return (
      this.control?.touched && this.control?.status !== 'VALID' && Object.keys(this.control?.errors || {}).length > 0
    );
  }
}
