import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import { Observable } from 'rxjs';
import * as fromTypes from '../../types';
import * as fromListSelectors from '../selectors/campus-list.selectors';
import * as fromListActions from '../actions/campus-list.actions';
import { Actions, ofType } from '@ngrx/effects';
import { debounceTime, filter, map, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class CampusListFacade {
  constructor(
    private store: Store<State>,
    private actions$: Actions,
  ) {}

  public getSelectedCampusId$(listName: fromTypes.CampusListName): Observable<string> {
    return this.store.select(fromListSelectors.getSelectedCampusId, { listName });
  }

  public totalCampusesFound$(listName: fromTypes.CampusListName): Observable<number> {
    return this.store.select(fromListSelectors.getListTotalFound, { listName });
  }

  public pageSize$(listName: fromTypes.CampusListName): Observable<number> {
    return this.store.select(fromListSelectors.getPageSize, { listName });
  }

  public getCampusListSerializedSnapshot$(
    listName: fromTypes.CampusListName,
  ): Observable<fromTypes.CampusListSerializedSnapshot> {
    return this.actions$.pipe(
      ofType(fromListActions.campusListLoadRequested),
      filter((a) => a.listName === listName),
      debounceTime(200),
      withLatestFrom(this.store.select(fromListSelectors.getCampusListState, { listName })),
      map(([a, list]) => {
        return fromTypes.utils.campusListStateToSerialized(
          list.pagination.pageSize,
          list.pagination.currentPage,
          list.selectedCampusStatus,
          list.currentSearchQuery,
          list.filters,
        );
      }),
    );
  }

  public currentPageNumber$(listName: fromTypes.CampusListName): Observable<number> {
    return this.store.select(fromListSelectors.getCurrentPage, { listName });
  }

  public currentSearchQuery$(listName: fromTypes.CampusListName): Observable<string> {
    return this.store.select(fromListSelectors.getSearchQuery, { listName });
  }

  public selectedListStatus$(listName: fromTypes.CampusListName): Observable<fromTypes.EntityStatus> {
    return this.store.select(fromListSelectors.getSelectedCampusStatus, {
      listName,
    });
  }

  public campusListLoading$(listName: fromTypes.CampusListName): Observable<boolean> {
    return this.store.select(fromListSelectors.getListLoading, { listName });
  }

  public campusListIds$(listName: fromTypes.CampusListName): Observable<fromTypes.PortalEntity[]> {
    return this.store.select(fromListSelectors.getListCampusIds, { listName });
  }

  public campusListErrors$(listName: fromTypes.CampusListName): Observable<fromTypes.WpError> {
    return this.store.select(fromListSelectors.getListError, { listName });
  }

  public onCampusCardInitialized(listName: fromTypes.CampusListName, campusId: string): void {
    this.store.dispatch(fromListActions.campusCardInitialized({ campusId, listName }));
  }

  public onSetCampusListState(
    listName: fromTypes.CampusListName,
    serialized: fromTypes.CampusListSerializedSnapshot,
  ): void {
    const snapshot = fromTypes.utils.getCampusListSnapshotFromSerialized(serialized);
    this.store.dispatch(fromListActions.setCampusListState({ listName, snapshot }));
  }

  public onCleanCampusListState(listName: fromTypes.CampusListName): void {
    this.store.dispatch(fromListActions.cleanCampusListState({ listName }));
  }

  public onCampusCardDestroyed(listName: fromTypes.CampusListName, campusId: string): void {
    this.store.dispatch(fromListActions.campusCardDestroyed({ campusId, listName }));
  }

  public onCampusInputChanged(listName: fromTypes.CampusListName, input: string): void {
    this.store.dispatch(fromListActions.campusSearchInputChanged({ input, listName }));
  }

  public onSetStaticFilters(listName: fromTypes.CampusListName, filters: fromTypes.PortalEntity[]): void {
    const campusEntityFilters = filters.map((s) => fromTypes.utils.getEntityFilter(s.type, s.entityId));
    this.store.dispatch(fromListActions.staticFiltersChanged({ listName, filters: campusEntityFilters }));
  }

  public onCampusStatusChanged(listName: fromTypes.CampusListName, status: fromTypes.EntityStatus): void {
    this.store.dispatch(fromListActions.campusStatusChanged({ status, listName }));
  }

  public onCurrentPageChanged(listName: fromTypes.CampusListName, page: number): void {
    this.store.dispatch(fromListActions.currentPageChanged({ page, listName }));
  }

  public onPageSizeChanged(listName: fromTypes.CampusListName, pageSize: number): void {
    this.store.dispatch(fromListActions.pageSizeChanged({ pageSize, listName }));
  }

  public onSelectCampusId(listName: fromTypes.CampusListName, campusId: string): void {
    this.store.dispatch(fromListActions.selectedCampusId({ campusId, listName }));
  }

  public onAddCampusClicked(): void {
    this.store.dispatch(fromListActions.addCampusClicked());
  }
}
