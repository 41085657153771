<div style="width: 14px; height: 14px">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="16"
    viewBox="0 0 12 16"
  >
    <path
      fill="none"
      fill-rule="evenodd"
      stroke="#F26E10"
      d="M6 7.923c-1.062 0-1.923-.861-1.923-1.923S4.937 4.077 6 4.077c1.062 0 1.923.86 1.923 1.923 0 1.062-.861 1.923-1.923 1.923zM6 1C3.238 1 1 3.214 1 5.945c0 4.945 5 8.901 5 8.901s5-3.956 5-8.901C11 3.214 8.762 1 6 1z"
    />
  </svg>
</div>
