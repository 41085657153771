import {
  PortalListDateRangeFilter,
  PortalListEntityFilter,
  PortalListFilter,
  PortalListMultiAccountManagementFilter,
} from '@rootTypes/utils/list-store-utils/portal-list-filter';
import { PortalListBaseParams, PortalListBaseState } from '@rootTypes/utils/list-store-utils/portal-list-base-state';
import { TripStatus, TripType } from '@rootTypes';
import { TripPaymentStatus } from '@rootTypes/entities/trips/trip-payment-status';

export type TripListFilter =
  | PortalListDateRangeFilter
  | PortalListEntityFilter
  | TripListQuickFilter
  | TripListStatusFilter
  | TripListLocationFilter
  | TripListTypeFilter
  | TripListVehicleTypeFilter
  | TripListPaymentStatusFilter
  | TripListModificationFilter
  | PortalListMultiAccountManagementFilter;
export type TripListParams = PortalListBaseParams<TripListFilter, string>;
export type TripListItem = any;
export type TripListState = PortalListBaseState<TripListParams, TripListItem>;

export enum TripListQuickFilterType {
  STARTING_TODAY = 'starting_today',
  MY_CAMPUS = 'my_campus',
  MY_TRIPS = 'my_trips',
}

export interface TripListQuickFilter extends PortalListFilter {
  id: string;
  type: 'quick-filter';
  payload: TripListQuickFilterType;
}

export interface TripListStatusFilter extends PortalListFilter {
  id: string;
  type: 'status';
  payload: TripStatus;
}

export interface TripListLocationFilter extends PortalListFilter {
  id: string;
  type: 'location';
  payload: string;
}

export interface TripListTypeFilter extends PortalListFilter {
  id: string;
  type: 'trip-type';
  payload: TripType;
}

export interface TripListVehicleTypeFilter extends PortalListFilter {
  id: string;
  type: 'vehicle-type';
  payload: string;
}

export interface TripListPaymentStatusFilter extends PortalListFilter {
  id: string;
  type: 'payment-status';
  payload: TripPaymentStatus;
}

export interface TripListModificationFilter extends PortalListFilter {
  id: string;
  type: 'modified';
  payload: true;
}

export type AdvancedFilterChangeEvent = {
  statuses: TripStatus[];
  locations: string[];
  types: TripType[];
  paymentStatuses: TripPaymentStatus[];
  vehicleTypes: string[];
  isModified?: boolean;
};
