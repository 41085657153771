import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, mergeMap, take, map } from 'rxjs/operators';

import {
  ApiService,
  submitRideAssignment,
  AccountsFacade,
  EntityFilterRequest,
  EntityFilterRequestType,
  YardResult,
  RideAssignmentInitialVendor,
} from './dependencies';

@Injectable({
  providedIn: 'root',
})
export class RideAssignmentApiService {
  constructor(
    private api: ApiService,
    private accountsFacade: AccountsFacade,
  ) {}

  public submitRideAssignment(
    request: submitRideAssignment.SubmitRideAssignmentRequest,
  ): Observable<submitRideAssignment.SubmitRideAssignmentResponse> {
    return this.api.submitRideAssignment(request);
  }

  public getLoggedInVendorInfo(): Observable<RideAssignmentInitialVendor> {
    if (wpEnvironment.userRole !== 'vendor') {
      throw Error('getLoggedInVendorInfo() is only callable in Vendor portal');
    }
    return this.accountsFacade.getSelectedAccount().pipe(
      filter((account) => !!account),
      take(1),
      mergeMap((account) => {
        // This api always returns only one yard depending on sub account that vendor user logged in
        const request: EntityFilterRequest = {
          type: EntityFilterRequestType.YARD,
          vendorIds: [account.accountId as string],
        };
        return this.api.entityFilter(request).pipe(
          map((res) => {
            const yard = res.results[0] as YardResult;
            return {
              vendorId: account.accountId as string,
              vendorName: account.name,
              yardId: yard.yardId,
            };
          }),
        );
      }),
    );
  }
}
