import { YYYYMMDDString } from '@rootTypes';
import { yyyymmddToDate } from '@rootTypes/utils/common/date-time-fns/yyyymmdd-to-date';
import { dateStartOfWeek } from '@rootTypes/utils/common/date-time-fns/date-start-of-week';
import { dateToYYYYMMDD } from '@rootTypes/utils/common/date-time-fns/date-to-yyyymmdd';

export const yyyymmddStartOfWeek = (yyyymmdd: YYYYMMDDString, isMondayFirstDayOfWeek: boolean): YYYYMMDDString => {
  const date = yyyymmddToDate(yyyymmdd);
  const startOfWeekDate = dateStartOfWeek(date, isMondayFirstDayOfWeek);
  return dateToYYYYMMDD(startOfWeekDate);
};
