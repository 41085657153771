import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { SideMenuService } from '../home/side-menu.service';
import { Store } from '@ngrx/store';
import { getPathToEnterAppAfterAuth } from '../auth/utils';
import { loginRelayState } from '../auth/store';
import { firstValueFrom } from 'rxjs';
import { commonRoutes } from '@router';

@Injectable({
  providedIn: 'root',
})
export class HomePageResolverGuard {
  constructor(
    private sideMenuService: SideMenuService,
    private router: Router,
    private store: Store,
  ) {}

  public async canActivate(): Promise<UrlTree> {
    const relayState = await firstValueFrom(this.store.select(loginRelayState));
    const landingPage = getPathToEnterAppAfterAuth(relayState);
    if (landingPage && landingPage !== commonRoutes.home.url) {
      return this.router.parseUrl(landingPage);
    }
    const defaultLandingPage = await this.sideMenuService.getHomePageLink();
    if (!defaultLandingPage) {
      return this.router.parseUrl(commonRoutes.noViewPermissions.url);
    }
    return this.router.parseUrl(defaultLandingPage);
  }
}
