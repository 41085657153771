import * as fromTypes from '../../types';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from '../actions/employee-list.actions';

export type AllEmployeeListState = {
  [key in fromTypes.EmployeeListName]?: EmployeeListState;
};

export interface EmployeeListState {
  isActive: boolean;
  page: {
    items: fromTypes.PortalEntity[];
    totalFound: number;
    isLoading: boolean;
    listError: fromTypes.WpError;
  };
  staticFilters: fromTypes.EmployeeListFilter[];
  filters: fromTypes.EmployeeListFilter[];
  status: fromTypes.EntityStatus;
  selectedEmployeeId: string;
  currentSearch: string;
  pagination: {
    page: number;
    pageSize: number;
  };
}

export const initialEmployeeListState: EmployeeListState = {
  isActive: false,
  page: {
    items: [],
    totalFound: undefined,
    isLoading: false,
    listError: undefined,
  },
  staticFilters: [],
  status: fromTypes.ui.DEFAULT_EMPLOYEE_LIST_STATUS,
  filters: [],
  selectedEmployeeId: undefined,
  currentSearch: undefined,
  pagination: {
    page: 0,
    pageSize: fromTypes.ui.DEFAULT_EMPLOYEE_LIST_PAGE_SIZE,
  },
};

export const getInitialAllEmployeeListState = (): AllEmployeeListState => {
  const result: AllEmployeeListState = {};
  fromTypes.employeeLists.forEach((listName) => {
    result[listName] = {
      ...initialEmployeeListState,
      page: {
        ...initialEmployeeListState.page,
      },
      staticFilters: [],
      filters: [],
      pagination: {
        ...initialEmployeeListState.pagination,
      },
    };
  });
  return result;
};

export const employeeListsReducer = createReducer<AllEmployeeListState>(
  { ...getInitialAllEmployeeListState() },
  /**
   * Initialized
   */
  on(fromActions.initializedList, (allState, action) => {
    const { listName, snapshot } = action;
    const state = allState[listName];
    let resultState: EmployeeListState;
    if (!snapshot) {
      resultState = {
        ...state,
        isActive: true,
        page: {
          ...state.page,
          totalFound: undefined,
        },
      };
    } else {
      resultState = {
        ...state,
        isActive: true,
        pagination: {
          ...state.pagination,
          page: snapshot?.page,
          pageSize: snapshot?.pageSize,
        },
        filters: snapshot?.filters || [],
        page: {
          ...state.page,
          totalFound: undefined,
        },
        currentSearch: snapshot?.search,
      };
    }
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.destroyedList, (allState, action) => {
    const { listName } = action;
    const state = allState[listName];
    const resultState: EmployeeListState = {
      ...state,
      isActive: false,
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  /**
   * List changed
   */
  on(fromActions.pageChanged, (allState, action) => {
    const { listName, page } = action;
    const state = allState[listName];
    const resultState: EmployeeListState = {
      ...state,
      pagination: {
        ...state.pagination,
        page,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.pageSizeChanged, (allState, action) => {
    const { listName, pageSize } = action;
    const state = allState[listName];
    const resultState: EmployeeListState = {
      ...state,
      pagination: {
        ...state.pagination,
        pageSize,
        page: 0,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.searchChanged, (allState, action) => {
    const { listName, search } = action;
    const state = allState[listName];
    const resultState: EmployeeListState = {
      ...state,
      pagination: {
        ...state.pagination,
        page: 0,
      },
      page: {
        ...state.page,
        totalFound: undefined,
      },
      currentSearch: search,
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),

  on(fromActions.statusChanged, (allState, action) => {
    const { listName, status } = action;
    const state = allState[listName];
    const resultState: EmployeeListState = {
      ...state,
      pagination: {
        ...state.pagination,
        page: 0,
      },
      page: {
        ...state.page,
        totalFound: undefined,
      },
      status,
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.filtersChanged, (allState, action) => {
    const { listName, filters } = action;
    const state = allState[listName];
    const resultState: EmployeeListState = {
      ...state,
      pagination: {
        ...state.pagination,
        page: 0,
      },
      page: {
        ...state.page,
        totalFound: undefined,
      },
      filters,
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.staticFiltersChanged, (allState, action) => {
    const { listName, filters: staticFilters } = action;
    const state = allState[listName];
    let resultState: EmployeeListState;
    if (areFiltersEqual(state.staticFilters, staticFilters)) {
      resultState = { ...state };
    } else {
      resultState = {
        ...initialEmployeeListState,
        staticFilters,
      };
    }
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.employeeItemSelected, (allState, action) => {
    const { listName, employeeId } = action;
    const state = allState[listName];
    const resultState: EmployeeListState = {
      ...state,
      selectedEmployeeId: employeeId,
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  /**
   * Load yard list requested
   */
  on(fromActions.employeeLoadListRequested, (allState, action) => {
    const { listName } = action;
    const state = allState[listName];
    const resultState: EmployeeListState = {
      ...state,
      page: {
        ...state.page,
        isLoading: true,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.employeeLoadListSuccess, (allState, action) => {
    const { listName, total, items } = action;
    const state = allState[listName];
    const resultState: EmployeeListState = {
      ...state,
      page: {
        ...state.page,
        isLoading: false,
        totalFound: total,
        items,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.employeeLoadListFailed, (allState, action) => {
    const { listName, error } = action;
    const state = allState[listName];
    const resultState: EmployeeListState = {
      ...state,
      page: {
        ...state.page,
        isLoading: false,
        listError: error,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
);

function areFiltersEqual(
  filters1: fromTypes.EmployeeListFilter[] = [],
  filters2: fromTypes.EmployeeListFilter[] = [],
): boolean {
  const hashFn = (f: fromTypes.EmployeeListFilter) => f.id;
  return fromTypes.utils.arraysEqual(filters1, filters2, hashFn);
}
