export enum CrossEntitySearchRequestType {
  DRIVER = 'driver',
  CUSTOMER = 'customer',
  CAMPUS = 'campus',
  DISTRICT = 'zum_enterprise',
  RIDER = 'rider',
  VENDOR = 'vendor',
  VEHICLE = 'vehicle',
  DRIVER_DOCUMENT = 'driver_document',
  ROUTE_GROUP = 'route_group',
  ZUM_EMPLOYEE = 'zum_employee',
  VENDOR_EMPLOYEE = 'vendor_employee',
  SCHOOL_EMPLOYEE = 'school_employee',
  CHARTER_TRIP = 'charter_trip',
  TRANSPORTATION_REGISTRATION = 'transportation_registration',
  STUDENT_RFID_ORDER = 'student_rfid_order',
}

export interface EntitySearchRequest {
  query: string;
  skip: number;
  limit: number;
  types?: CrossEntitySearchRequestType[];
}
