import { createSelector } from '@ngrx/store';
import { getRidesFeature } from './get-feature';
import { RideListState } from '../reducers/ride-list.reducer';
import * as fromTypes from '../../types';

const getAllRideLists = createSelector(getRidesFeature, (state) => state.lists);

export const getRidesListState = (listName) =>
  createSelector(getAllRideLists, (state): RideListState => state[listName]);

export const getRideListSnapshot = (listName) =>
  createSelector(getRidesListState(listName), (state): fromTypes.RideListSnapshot => {
    if (!state) {
      return null;
    }
    const snapshot = {
      currentPage: state.pagination.currentPageNumber,
      pageSize: state.pagination.pageSize,
      staticFilters: state.staticFilters,
      dynamicFilters: state.filters,
      timetable: state.timetable,
      accountId: state.accountId,
    } as fromTypes.RideListSnapshot;
    return snapshot;
  });

export const getRideListSerializedSnapshot = (listName) =>
  createSelector(getRideListSnapshot(listName), (state): fromTypes.SerializedRideListSnapshot => {
    if (!state) {
      return null;
    }
    return fromTypes.utils.serializeSnapshot(state);
  });

export const getRidesListPageState = (listName) => createSelector(getRidesListState(listName), (state) => state.page);

export const currentPageLoading = (listName) =>
  createSelector(getRidesListPageState(listName), (page) => page.pageLoading);

export const currentPageRideIds = (listName) =>
  createSelector(getRidesListPageState(listName), (page) => page.currentRideIds);

export const currentPageError = (listName) => createSelector(getRidesListPageState(listName), (page) => page.error);

export const currentRideFilters = (listName) =>
  createSelector(getRidesListState(listName), (state) => fromTypes.utils.sortFilters(state.filters));

export const getSelectedQuickRideFilter = (listName) =>
  createSelector(getRidesListState(listName), (state): fromTypes.filters.RideQuickFilterType => {
    const quickFilter = (state.filters || []).find((f) => f.type === fromTypes.filters.RideFilterType.QUICK_FILTER);
    return quickFilter?.payload;
  });

export const totalItems = (listName) =>
  createSelector(getRidesListPageState(listName), (state) => state.totalItemsCount);

export const getCurrentRideTimetable = (listName) =>
  createSelector(getRidesListState(listName), (state) => state.timetable);

export const getPaginationState = (listName) =>
  createSelector(getRidesListState(listName), (state) => state.pagination);

export const pageSize = (listName) => createSelector(getPaginationState(listName), (state) => state.pageSize);

export const currentPageNumber = (listName) =>
  createSelector(getPaginationState(listName), (state) => state.currentPageNumber);

export const selectedRideId = (listName) =>
  createSelector(getRidesListState(listName), (state) => state.selectedRideId);

export const getRideListAccountId = (listName) =>
  createSelector(getRidesListState(listName), (state) => state.accountId);
