import * as fromApi from '../index';
import * as fromTypes from '../../../../data/routes/types';
import { dbToRoute } from './db-to-route';
import { dbToDriverIdsByWeekdays } from './db-to-driver-ids-by-weekdays';
import { dbToRiderIdsByEventsByWeekdays } from './db-to-rider-ids-by-events-by-weekdays';
import { dbToEventIdsByRidersByWeekdays } from './db-to-event-ids-by-riders-by-weekdays';
import { dbRouteToRiderIds } from './db-route-to-rider-ids';
import { dbToRouteStops } from './db-to-route-stops';
import { getRiderDowSelection } from './get-rider-dow-selection';

export const dbToGetRoutePayload = (source: fromApi.GetRouteResponse): fromTypes.GetRoutePayload => {
  const response: fromTypes.GetRoutePayload = {
    route: dbToRoute(source),
    routeDrivers: dbToDriverIdsByWeekdays((source as fromApi.GetRouteResponseActive).drivers),
    riderIdsByEvents: dbToRiderIdsByEventsByWeekdays(source),
    eventIdsByRiders: dbToEventIdsByRidersByWeekdays(source),
    riderIds: dbRouteToRiderIds(source),
    stops: dbToRouteStops(source),
    riderDOWSelection: getRiderDowSelection(source),
    districtProgram: undefined,
  };
  if (source._projections?.[fromApi.RouteProjection.polyline]?.polyline) {
    response.polyline = source._projections[fromApi.RouteProjection.polyline]?.polyline;
  }
  if (source._projections?.[fromApi.RouteProjection.districtProgram]) {
    response.districtProgram = source._projections[fromApi.RouteProjection.districtProgram];
  }
  return response;
};
