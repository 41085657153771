import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-icon-search',
  templateUrl: './icon-search.component.html',
  styleUrls: ['./icon-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconSearchComponent implements OnInit {
  @Input() disabled = false;

  constructor() {}

  ngOnInit(): void {}
}
