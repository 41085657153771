<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="21"
  viewBox="0 0 24 21"
>
  <g fill="none" fill-rule="evenodd">
    <g class="icon" [ngClass]="{ green: green }" stroke="#47A540">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      stroke-width="2"
                      d="M2 3H20V16H2z"
                      transform="translate(-19 -485) translate(0 85) translate(0 386) translate(19 12) translate(1 3)"
                    />
                    <path
                      d="M9.5 8.5H12.5V15.5H9.5zM4.5 8.5H7.5V11.5H4.5zM14.5 8.5H17.5V11.5H14.5z"
                      transform="translate(-19 -485) translate(0 85) translate(0 386) translate(19 12) translate(1 3)"
                    />
                    <path
                      stroke-width="2"
                      d="M4 0H18V3H4zM0 16H22V19H0z"
                      transform="translate(-19 -485) translate(0 85) translate(0 386) translate(19 12) translate(1 3)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
