import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { DriversApiService } from '../../services/drivers-api.service';
import * as fromActions from '../actions/driver-data.actions';

import { loadVehicleSuccess, loadVendorSuccess, loadYardSuccess, PortalEntityType, WpError } from '../../types';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import { getPortalEntitySuccess } from '../../../common/store/actions/portal-entities-info.actions';

@Injectable()
export class DriversDataEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private apiService: DriversApiService,
  ) {}

  public loadDriverRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.loadDriverRequested,
        fromActions.loadDriverRequestedFromDBRListItem,
        fromActions.loadDriverRequestedFromTimesheetChangeRequestItem,
        fromActions.loadDriverRequestedFromTimesheetChangeRequestDetails,
        fromActions.loadDriverRequestedFromCreateTimesheetEntry,
      ),
      mergeMap((action) => {
        const { driverId, options } = action;
        return this.apiService.getDriver(driverId, options).pipe(
          map((resp) => {
            const { vendor, yard, rating, vehicle, standby } = resp?._projections || {};
            // It is important to update the associated stores before the driver store
            if (options.withVehicle && !!vehicle) {
              this.store.dispatch(loadVehicleSuccess({ vehicle }));
            }
            if (options.withVendor && !!vendor) {
              this.store.dispatch(loadVendorSuccess({ vendorId: vendor.id, vendor }));
            }
            if (options.withYard && !!yard) {
              this.store.dispatch(loadYardSuccess({ yardId: yard.id, yard }));
            }

            this.store.dispatch(
              getPortalEntitySuccess({
                entity: {
                  type: PortalEntityType.DRIVER,
                  entityId: driverId,
                  label: resp.driver.firstName + ' ' + resp.driver.lastName,
                },
              }),
            );

            return fromActions.loadDriverSuccess({
              requestOptions: options,
              driver: resp.driver,
              vendor,
              yard,
              rating,
              vehicle,
              standby,
            });
          }),
          catchError((err) => {
            return of(
              fromActions.loadDriverFailed({
                driverId,
                error: {
                  text: 'Failed to load driver',
                  originalError: err,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  public getDriverSecuritySettingsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getSecuritySettingsForDriverRequested),
      mergeMap((action) => {
        const { driverId } = action;
        return this.apiService.getDriverSecuritySettings(driverId).pipe(
          map((settings) =>
            fromActions.getSecuritySettingsForDriverSuccess({
              driverId,
              settings,
            }),
          ),
          catchError((originalError) => {
            console.log(originalError);
            const error: WpError = {
              text: 'Failed to load driver security settings',
              originalError,
            };
            return of(fromActions.getSecuritySettingsForDriverFailed({ driverId, error }));
          }),
        );
      }),
    ),
  );
}
