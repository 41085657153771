<div class="icon" [ngClass]="{ selected: selected }">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g fill="none" fill-rule="evenodd">
      <circle cx="16" cy="16" r="15.5" fill="#F8F8FC" stroke="#636365" />
      <path
        fill="#99CA41"
        d="M16 29c1.569-2.215 2.566-3.337 2.992-3.366C23.568 25.326 26 23.973 26 22.35 26 20.5 21.523 19 16 19S6 20.5 6 22.35c0 1.618 2.407 2.971 6.963 3.284.434.03 1.446 1.152 3.037 3.366z"
      />
      <path
        fill="#FFF"
        stroke="#5DBA4E"
        stroke-width="2"
        d="M11 22h10v-5c0-1.657-1.343-3-3-3h-4c-1.657 0-3 1.343-3 3v5z"
      />
      <circle
        cx="16"
        cy="8"
        r="3"
        fill="#FFF"
        stroke="#5DBA4E"
        stroke-width="2"
      />
    </g>
  </svg>
</div>
