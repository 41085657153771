<div class="smart-address-input">
  <div class="location-type">
    <wp-smart-select [model]="model.locationTypeSelect"></wp-smart-select>
  </div>
  <div class="address">
    <ng-container *ngIf="(selectedLocationType$ | async) === 'home'">
      <wp-smart-input-address
        [model]="model.homeAddressSelect"
      ></wp-smart-input-address>
    </ng-container>
  </div>
</div>
<!--Exact location for home addresses (door-to-door)-->
<ng-container *ngIf="(selectedLocationType$ | async) === 'home'">
  <div class="exact-location-wrap">
    <div class="title">
      <wp-title-five>
        <span
          [ngClass]="{
      disabled: model.config.disabled
      }"
        >
          Exact location
        </span>
      </wp-title-five>
    </div>
    <ng-container
      *ngIf="model.homeAddressSelect?.control?.value; then exactLocTempl; else noExactLocation"
    ></ng-container>

    <ng-template #exactLocTempl>
      <ng-container
        *ngIf="isMapLoading$ | async; then spinner; else loadedMap"
      ></ng-container>

      <ng-template #loadedMap>
        <div class="content">
          <div class="loc-result">
            <ng-container
              *ngIf="isExactLocationValueLoading$ | async; then spinner; else loadedValue"
            ></ng-container>

            <ng-template #loadedValue>
              <ng-container
                *ngIf="exactLocationValueLoadError$ | async; let error; else noErrorLocValue"
              >
                <wp-section-error>{{ error.text }}</wp-section-error>
              </ng-container>
              <ng-template #noErrorLocValue>
                <div style="margin-bottom: 20px; position: relative">
                  <wp-disabled-mask
                    *ngIf="model.config.disabled"
                  ></wp-disabled-mask>
                  <wp-display-exact-location
                    [exactLocation]="exactValue$ | async"
                  ></wp-display-exact-location>
                  <ng-container *ngIf="exactValue$ | async">
                    <div
                      *ngIf="!model.config.disabled"
                      style="margin-top: 15px"
                    >
                      <wp-link-btn (clicked)="onRemoveExactLocation()"
                        >Remove</wp-link-btn
                      >
                    </div>
                  </ng-container>
                </div>
              </ng-template>
            </ng-template>
          </div>
          <div class="map">
            <wp-smart-input-address-map
              [model]="model.exactHomeAddressSelect"
            ></wp-smart-input-address-map>
          </div>
        </div>
      </ng-template>
    </ng-template>

    <ng-template #noExactLocation> N/A </ng-template>
  </div>
</ng-container>

<!--Closest bus stop select for bus addresses-->
<ng-container *ngIf="(selectedLocationType$ | async) === 'bus'">
  <div class="bus-select-from-map-wrap">
    <wp-closest-bus-address-lookup-editor
      [model]="model"
      [isCompactView]="isCompactView"
    ></wp-closest-bus-address-lookup-editor>
  </div>
</ng-container>

<ng-template #spinner>
  <wp-section-spinner [spinnerSize]="37"></wp-section-spinner>
</ng-template>
