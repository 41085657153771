import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface PaginationControlState {
  currentPage: number;
  totalPages: number;
}

const initialPaginationControlState: PaginationControlState = {
  currentPage: 0,
  totalPages: 1,
};

const fastSkipCount = 10;

const defaultPageSizeOptions = [10, 20, 50];

@Component({
  selector: 'wp-pagination-controls',
  templateUrl: './pagination-controls.component.html',
  styleUrls: ['./pagination-controls.component.scss'],
})
export class PaginationControlsComponent implements OnInit, OnChanges {
  @Input() public totalItems: number | null;
  @Input() public currentPage = 0;
  @Input() public pageSize: number;
  @Input() public pageSizeOptions: number[] = defaultPageSizeOptions;
  @Input() public visiblePagesCount = 10;
  @Input() public isPageSizeSelector = true;
  @Input() public marginBottom = '25px';
  @Input() public marginTop: string;

  @Output() public currentPageChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() public pageSizeChanged: EventEmitter<number> = new EventEmitter<number>();

  public currentPageSection$!: Observable<number[]>;
  public currentPage$!: Observable<number>;
  public isShowPagination$: Observable<boolean>;
  public isNextPageBtnDisabled: Observable<boolean>;
  public isPreviousPageBtnDisabled: Observable<boolean>;
  public isFastForwardBtnDisabled: Observable<boolean>;
  public isFastBackwardBtnDisabled: Observable<boolean>;
  public marginBottom$: Observable<string>;
  public marginTop$: Observable<string>;

  private state$: BehaviorSubject<PaginationControlState> = new BehaviorSubject<PaginationControlState>(
    initialPaginationControlState,
  );

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalItems) {
      this.state$.next({
        ...this.state$.value,
        totalPages: this.getTotalPages(changes.totalItems.currentValue || 0, this.pageSize),
      });
    }
    if (changes.pageSize) {
      this.state$.next({
        ...this.state$.value,
        totalPages: this.getTotalPages(this.totalItems || 0, changes.pageSize.currentValue),
      });
    }
    if (changes.currentPage) {
      this.state$.next({
        ...this.state$.value,
        currentPage: parseInt('' + (changes.currentPage.currentValue || 0), 10),
      });
    }
  }

  ngOnInit(): void {
    this.currentPageSection$ = this.state$.asObservable().pipe(
      map((state) => {
        const pageSectionStart = Math.floor(state.currentPage / this.visiblePagesCount) * this.visiblePagesCount;
        const section = [];
        for (let i = 0; i < this.visiblePagesCount; i++) {
          const pageToAdd = pageSectionStart + i;
          if (pageToAdd <= state.totalPages) {
            section.push(pageToAdd);
          }
        }
        return section;
      }),
    );

    this.currentPage$ = this.state$.asObservable().pipe(map((state) => state.currentPage));

    this.isShowPagination$ = this.state$.asObservable().pipe(map((state) => state.totalPages >= 1));

    this.isPreviousPageBtnDisabled = this.state$.pipe(map(({ currentPage }) => currentPage === 0));

    this.isFastBackwardBtnDisabled = this.state$.pipe(map(({ currentPage }) => currentPage <= 1));

    this.isNextPageBtnDisabled = this.state$.pipe(map(({ currentPage, totalPages }) => currentPage === totalPages));

    this.isFastForwardBtnDisabled = this.state$.pipe(
      map(({ currentPage, totalPages }) => totalPages - currentPage <= 2),
    );
    this.marginBottom$ = this.isShowPagination$.pipe(
      map((isShown) => {
        if (isShown) {
          return this.marginBottom ?? '0px';
        } else {
          return '0px';
        }
      }),
    );
    this.marginTop$ = this.isShowPagination$.pipe(
      map((isShown) => {
        if (isShown) {
          return this.marginTop ?? '0px';
        } else {
          return '0px';
        }
      }),
    );
  }

  public onPageSizeChanged(newPageSize: number): void {
    this.pageSizeChanged.emit(newPageSize);
  }

  public onPageClick(page: number): void {
    this.state$.next({
      ...this.state$.value,
      currentPage: page,
    });
    this.currentPageChanged.emit(page);
  }

  public onNextPageClick(): void {
    const { currentPage, totalPages } = this.state$.value;
    const nextPage = Math.min(currentPage + 1, totalPages);
    this.state$.next({
      ...this.state$.value,
      currentPage: nextPage,
    });
    this.currentPageChanged.emit(nextPage);
  }

  public onPrevPageClick(): void {
    const { currentPage } = this.state$.value;
    const newPage = Math.max(0, currentPage - 1);
    this.state$.next({
      ...this.state$.value,
      currentPage: newPage,
    });
    this.currentPageChanged.emit(newPage);
  }

  public onFastForwardClick(): void {
    const { currentPage, totalPages } = this.state$.value;
    const nextPage = Math.min(currentPage + fastSkipCount, totalPages);
    this.state$.next({
      ...this.state$.value,
      currentPage: nextPage,
    });
    this.currentPageChanged.emit(nextPage);
  }

  public onFastBackwardClick(): void {
    const { currentPage } = this.state$.value;
    const newPage = Math.max(0, currentPage - fastSkipCount);
    this.state$.next({
      ...this.state$.value,
      currentPage: newPage,
    });
    this.currentPageChanged.emit(newPage);
  }

  private getTotalPages(totalItems: number, pageSize: number): number {
    const itemsFitExactlyIntoPages = totalItems % pageSize === 0;
    if (itemsFitExactlyIntoPages) {
      return Math.floor(totalItems / pageSize) - 1;
    } else {
      return Math.floor(totalItems / pageSize);
    }
  }
}
