import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-link-external',
  templateUrl: './link-external.component.html',
  styleUrls: ['./link-external.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkExternalComponent {
  @Input() public href: string;
  @Input() public isBigIcon: boolean;

  constructor() {}
}
