import { Inject, Injectable, InjectionToken } from '@angular/core';
import { AbstractListComponentStore } from '@rootTypes/utils/list-store-utils/abstract-list-component-store';
import {
  DriverBusReportListItem,
  DriverBusReportsListConfig,
  DriverBusReportsListFilter,
  DriverBusReportsListParams,
  DriverBusReportsListState,
} from './types';
import { ApiService } from '../../../../api/api.service';
import { EntityFilterRequest, EntityFilterRequestType } from '../../../../api/endpoints/entity-filter';
import { DriverBusReportStatus } from '@apiEntities';
import {
  PortalListDateRangeFilter,
  PortalListEntityFilter,
} from '@rootTypes/utils/list-store-utils/portal-list-filter';
import { PortalEntityType } from '@rootTypes';
import { Store } from '@ngrx/store';
import { getUniqueArrayValues } from '@rootTypes/utils';
import { loadDriverRequestedFromDBRListItem } from '../../../../data/drivers/store/actions';
import { loadYardRequested } from '../../../../data/yards/store/actions';
import { DriverBusReportDataStore } from '../../../driver-bus-reports/services/driver-bus-report-data.store';

export const DRIVER_BUS_REPORT_LIST_CONFIG = new InjectionToken<DriverBusReportsListConfig>(
  'driverBusReportListConfig',
);

export const DEFAULT_PAGE_SIZE = 10;

export const driverBusReportsInitialStateGetter = () => {
  return {
    params: {
      filters: [],
      page: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      status: DriverBusReportStatus.OCR_REVIEW_PENDING,
    },
    api: {
      isLoading: false,
    },
  } as DriverBusReportsListState;
};

export const defaultDriverBusListConfig: DriverBusReportsListConfig = {
  initialStateGetter: driverBusReportsInitialStateGetter,
  listReloadDebounceTime: 1000,
};

@Injectable()
export class DriverBusReportListStoreService extends AbstractListComponentStore<
  DriverBusReportsListFilter,
  DriverBusReportStatus,
  DriverBusReportsListParams,
  DriverBusReportListItem
> {
  constructor(
    @Inject(DRIVER_BUS_REPORT_LIST_CONFIG) private listConfig: DriverBusReportsListConfig,
    private dbrDataStore: DriverBusReportDataStore,
    private api: ApiService,
    private store: Store,
  ) {
    super(listConfig);
  }
  loadListAPI(params: DriverBusReportsListParams): Promise<{ items: DriverBusReportListItem[]; total: number }> {
    let driverIds: string[] = [];
    let yardIds: string[] = [];
    let routeGroupIds: string[] = [];
    const entityFilers: PortalListEntityFilter[] = params.filters.filter(
      (f) => f.type === 'entity',
    ) as PortalListEntityFilter[];
    if (entityFilers.length) {
      driverIds = entityFilers.filter((f) => f.payload.type === PortalEntityType.DRIVER).map((f) => f.payload.entityId);
      yardIds = entityFilers.filter((f) => f.payload.type === PortalEntityType.YARD).map((f) => f.payload.entityId);
      routeGroupIds = entityFilers
        .filter((f) => f.payload.type === PortalEntityType.ROUTE_GROUP)
        .map((f) => f.payload.entityId);
    }
    if (params.listByEntity && params.listByEntity.type === PortalEntityType.DRIVER) {
      driverIds.push(params.listByEntity.entityId);
    }
    const request: EntityFilterRequest = {
      type: EntityFilterRequestType.DRIVER_BUS_REPORT,
      skip: params.page * params.pageSize,
      limit: params.pageSize,
      extraFilters: {
        report_status: [params.status],
      },
    };
    if (params.searchQuery) {
      request.query = params.searchQuery;
    }
    if (driverIds.length) {
      request.driverIds = driverIds;
    }
    if (yardIds.length) {
      request.yardIds = yardIds;
    }
    if (routeGroupIds.length) {
      request.extraFilters = {
        ...request.extraFilters,
        route_group_id: routeGroupIds,
      };
    }

    const dateFilter: PortalListDateRangeFilter = params.filters.find(
      (f) => f.type === 'date-range',
    ) as PortalListDateRangeFilter;
    if (dateFilter) {
      request.date = {
        fieldName: 'report_date',
        from: dateFilter.payload.startDate,
        to: dateFilter.payload.endDate,
      };
    }
    return this.api
      .entityFilter(request)
      .toPromise()
      .then(({ results, total }) => {
        const reportIds = (results as DriverBusReportListItem[]).map((r) => r.driverBusReportId);
        this.dbrDataStore.onSubscribeDBRList(reportIds);
        const driverIds = (results as DriverBusReportListItem[]).map((it) => it.driverId);
        const yardIds = (results as DriverBusReportListItem[]).map((it) => it.yardId);
        getUniqueArrayValues(driverIds).forEach((driverId) => {
          this.store.dispatch(loadDriverRequestedFromDBRListItem({ driverId, options: {} }));
        });
        getUniqueArrayValues(yardIds).forEach((yardId) => {
          this.store.dispatch(loadYardRequested({ yardId }));
        });
        return {
          total,
          items: results as DriverBusReportListItem[],
        };
      });
  }

  public onDestroy(): void {
    this.dbrDataStore.onUnsubscribeDBRList();
  }
}
