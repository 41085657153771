import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { InputXPosition, InputYPosition } from '../custom-overlay';
import { ConnectedPosition, OverlayRef } from '@angular/cdk/overlay';
import { getPositionsFromInputParameters } from '../get-positions-from-input-parameters';

@Component({
  selector: 'wp-custom-tooltip-expanding-dropdown',
  template: `
    <div
      class="custom-html-tooltip"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      #triggerEl
      (mouseenter)="mouseEnter()"
      (mouseleave)="mouseLeave()"
      (click)="triggerClick()"
    >
      <ng-template [ngTemplateOutlet]="triggerContentRef"></ng-template>
    </div>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isTooltipOpen"
      [cdkConnectedOverlayPositions]="positions"
      (overlayOutsideClick)="outsideClick()"
      #connectedOverlayRef="cdkConnectedOverlay"
    >
      <div (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" (click)="triggerClick()">
        <div class="elevated" *ngIf="isTooltipOpen">
          <ng-template [ngTemplateOutlet]="tooltipContentRef"></ng-template>
          <div *ngIf="isDropdownOpen" wpExpand [show]="true" class="dropdown">
            <ng-template [ngTemplateOutlet]="dropdownContentRef"></ng-template>
          </div>
        </div>
      </div>
    </ng-template>
  `,
  styles: `
    .elevated {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTooltipExpandingDropdownComponent implements OnInit {
  @Input() public triggerContentRef: TemplateRef<any>;
  @Input() public tooltipContentRef: TemplateRef<any>;
  @Input() public dropdownContentRef: TemplateRef<any>;
  @Input() public yPosition: InputYPosition = 'below';
  @Input() public xPosition: InputXPosition = 'center';
  @Input() public disabled: boolean;
  @Output() public tooltipOpenChanged = new EventEmitter<boolean>();
  @Output() public dropdownOpenChanged = new EventEmitter<boolean>();
  @ViewChild('connectedOverlayRef') connectedOverlayRef: any;
  timedOutCloser;
  public isTooltipOpen = false;
  public isDropdownOpen = false;
  public positions: ConnectedPosition[];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.positions = getPositionsFromInputParameters(this.xPosition, this.yPosition);
  }

  triggerClick() {
    if (this.disabled) {
      return;
    }
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    this.isDropdownOpen = true;
    this.isTooltipOpen = true;
    this.dropdownOpenChanged.emit(this.isDropdownOpen);
    setTimeout(() => {
      if (this.overlayRef) {
        this.overlayRef.updatePosition();
      }
    }, 400);
  }

  outsideClick() {
    if (this.isDropdownOpen) {
      this.isDropdownOpen = false;
      this.isTooltipOpen = false;
      this.dropdownOpenChanged.emit(this.isDropdownOpen);
      this.tooltipOpenChanged.emit(this.isTooltipOpen);
    }
  }

  mouseEnter() {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    if (this.disabled) {
      return;
    }
    if (this.isDropdownOpen) {
      return;
    }
    this.isTooltipOpen = true;
    this.tooltipOpenChanged.emit(this.isTooltipOpen);
  }

  mouseLeave() {
    if (this.disabled) {
      return;
    }
    if (this.isDropdownOpen) {
      return;
    }
    this.timedOutCloser = setTimeout(() => {
      this.closeTooltip();
      this.cd.detectChanges();
    }, 50);
  }

  closeTooltip(): void {
    this.isTooltipOpen = false;
    this.tooltipOpenChanged.emit(this.isTooltipOpen);
    this.isDropdownOpen = false;
    this.dropdownOpenChanged.emit(this.isDropdownOpen);
  }

  private get overlayRef(): OverlayRef | undefined {
    return this.connectedOverlayRef?.overlayRef;
  }
}
