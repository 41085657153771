import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SelectOption } from '../..';
import { AbstractDropdown } from '../abstract-dropdown';

interface DropdownState {
  isOpen: boolean;
  currentOption: SelectOption | null;
}

const initialDropdownState: DropdownState = {
  isOpen: false,
  currentOption: null,
};

@Component({
  selector: 'wp-inline-dropdown',
  templateUrl: './inline-dropdown.component.html',
  styleUrls: ['./inline-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineDropdownComponent extends AbstractDropdown implements OnInit {
  @Input() public disabled = false;
  @Input() public sideLabel: string | undefined;
  @Input() public label!: string;
  @Input() public control!: UntypedFormControl;
  @Input() public options!: SelectOption[];

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.onNgInit();
  }
}
