<wp-dropdown
  [triggerTemplate]="triggerTemplate"
  [dropdownTemplate]="dropdownTemplate"
  [alignDropdownVertical]="alignDropdownVertical"
  [alignDropdown]="alignDropdown"
  [isDropdownPositionRelative]="isDropdownPositionRelative"
  [dropdownVerticalOffset]="'10px'"
  [disabled]="disabled"
  (closedDropdown)="onDropdownClose()"
  #dropdownComponent
></wp-dropdown>

<ng-template #triggerTemplate>
  <wp-disabled-mask *ngIf="disabled"></wp-disabled-mask>
  <div
    class="quick-filter-trigger"
    [ngStyle]="{ width: triggerWidthStr, height: triggerHeightStr }"
    [ngClass]="{
    'selected': isSelected && !showRegularTemplateOnSelect,
    'open': isDropdownOpen,
    'disabled': disabled
  }"
    (click)="onTriggerClick()"
  >
    <div class="icon">
      <ng-container
        *ngIf="(isSelected && !showRegularTemplateOnSelect) || isDropdownOpen; else notSelectedTemplate;"
      >
        <ng-template
          [ngTemplateOutlet]="triggerIconSelectedTemplate"
        ></ng-template>
      </ng-container>
      <ng-template #notSelectedTemplate>
        <ng-template [ngTemplateOutlet]="triggerIconTemplate"></ng-template>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #dropdownTemplate>
  <ng-container *ngIf="customDropdownTemplate; else noCustomTemplate;">
    <ng-template [ngTemplateOutlet]="customDropdownTemplate"></ng-template>
  </ng-container>
  <ng-template #noCustomTemplate>
    <div class="dropdown-wrap">
      <wp-dropdown-pane>
        <wp-dropdown-option
          *ngFor="let option of options"
          [disabled]="option.disabled"
          (selectedWithKeyboard)="onOptionSelectWithKeyboard(option)"
          (click)="onOptionClick(option)"
        >
          <div class="option">
            <div class="option-label">{{ option.displayLabel }}</div>
            <div class="checked-icon">
              <wp-portal-icon
                *ngIf="selectedId === option.value"
                [path]="iconPaths.CHECK_SMALL_STROKE"
              ></wp-portal-icon>
            </div>
          </div>
        </wp-dropdown-option>
      </wp-dropdown-pane>
    </div>
  </ng-template>
</ng-template>
