import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { SmartInputDistrict } from '../../models';

@Component({
  selector: 'wp-smart-input-district',
  templateUrl: './smart-input-district.component.html',
  styleUrls: ['./smart-input-district.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartInputDistrictComponent {
  @Input() model: SmartInputDistrict;
}
