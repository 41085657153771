import { RouteStatusType } from '../../../types/entities/route';
import { RouteFilterRequestStatuses } from './request';

export function getFilterRequestStatus(source: RouteStatusType): RouteFilterRequestStatuses {
  switch (source) {
    case RouteStatusType.ACTIVE:
      return RouteFilterRequestStatuses.ACTIVE;
    case RouteStatusType.DRAFT:
      return RouteFilterRequestStatuses.DRAFT;
    case RouteStatusType.INACTIVE:
      return RouteFilterRequestStatuses.INACTIVE;
  }
}
