import { EntityState } from '@rootTypes';
import { ChangeRequestsByStudentState } from '@rootTypes/entities/student-change-requests';
import { createReducer, on } from '@ngrx/store';
import {
  getChangeRequestsForStudentFailed,
  getChangeRequestsForStudentSuccess,
  loadChangeRequestsActions,
} from '../actions/student-change-requests-data.actions';

export interface StudentChangeRequestsDataState {
  changeRequests: {
    [studentId: string]: EntityState<ChangeRequestsByStudentState>;
  };
}

export const initialStudentChangeRequestsDataState = (): StudentChangeRequestsDataState => {
  return {
    changeRequests: {},
  };
};

export const studentChangeRequestsDataReducer = createReducer<StudentChangeRequestsDataState>(
  { ...initialStudentChangeRequestsDataState() },
  on(...loadChangeRequestsActions, (state, action) => {
    const { studentId } = action;
    const initialState = state.changeRequests[studentId] || { isLoading: false };
    return {
      ...state,
      changeRequests: {
        ...state.changeRequests,
        [studentId]: {
          ...initialState,
          isLoading: true,
        },
      },
    };
  }),
  on(getChangeRequestsForStudentSuccess, (state, action) => {
    const { studentId, result } = action;
    const initialState = state.changeRequests[studentId] || { isLoading: false };
    return {
      ...state,
      changeRequests: {
        ...state.changeRequests,
        [studentId]: {
          ...initialState,
          isLoading: false,
          entity: result,
        },
      },
    };
  }),
  on(getChangeRequestsForStudentFailed, (state, action) => {
    const { studentId, error } = action;
    const initialState = state.changeRequests[studentId] || { isLoading: false };
    return {
      ...state,
      changeRequests: {
        ...state.changeRequests,
        [studentId]: {
          ...initialState,
          isLoading: false,
          error,
        },
      },
    };
  }),
);
