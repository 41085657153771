import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute } from '../../types/portal-route';

export const resetPasswordSuccessRoute: PortalRoute<void> = {
  path: 'password-reset-success',
  request(): NavigationRequest {
    return {
      path: [`/${this.path}`],
    };
  },
};
