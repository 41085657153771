import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-title-two',
  templateUrl: './title-two.component.html',
  styleUrls: ['./title-two.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleTwoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
