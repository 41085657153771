<div
  class="editor-wrap"
  [ngStyle]="{ width: maxWidth + 'px', height: maxHeight + 40 + 'px'}"
>
  <div *ngIf="!isOpen" class="open-edit-control">
    <button class="edit-button" (click)="openImageEditor()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="20"
        viewBox="0 0 19 20"
      >
        <g fill="none" fill-rule="evenodd">
          <g stroke="#FFFFFF">
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <g>
                            <path
                              stroke-width="2"
                              d="M2.968.743h.21c1.105 0 2 .895 2 2v.003l-.017 11.67h0l-2.378 3.305L.95 14.416.968 2.74c.002-1.104.896-1.997 2-1.997z"
                              transform="translate(-1148.000000, -524.000000) translate(487.000000, 0.000000) translate(30.000000, 363.000000) translate(0.000000, 149.000000) translate(631.000000, 10.400000) translate(0.000000, 0.100000) translate(1.000000, 0.000000) translate(10.390222, 9.000000) rotate(45.000000) translate(-10.390222, -9.000000) translate(7.390222, 0.000000)"
                            />
                            <path
                              stroke-linecap="square"
                              d="M.693 13.444L4.904 13.444M1.4 4.252L5.611 4.252"
                              transform="translate(-1148.000000, -524.000000) translate(487.000000, 0.000000) translate(30.000000, 363.000000) translate(0.000000, 149.000000) translate(631.000000, 10.400000) translate(0.000000, 0.100000) translate(1.000000, 0.000000) translate(10.390222, 9.000000) rotate(45.000000) translate(-10.390222, -9.000000) translate(7.390222, 0.000000)"
                            />
                          </g>
                          <path
                            stroke-width="2"
                            d="M7.843 4.177H3.768C1.258 4.051.004 5.29.004 7.89c.03 2.84.03 7.376 0 9.434-.03 2.058 0 2.772 3.764 2.772h8.406c2.42 0 3.696-1.212 3.826-3.636V11"
                            transform="translate(-1148.000000, -524.000000) translate(487.000000, 0.000000) translate(30.000000, 363.000000) translate(0.000000, 149.000000) translate(631.000000, 10.400000) translate(0.000000, 0.100000) translate(1.000000, 0.000000)"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </button>
  </div>
  <img
    id="edit-image"
    class="edit-image"
    [hidden]="!imageBase64"
    [src]="imageBase64"
    [alt]="alt"
    [ngStyle]="{ zIndex }"
    [width]="widthStr"
    [height]="heightStr"
    #editImage
  />
</div>
