<wp-page>
  <wp-page-body>
    <div class="work-in-progress">
      <div class="work-in-progress__content">
        <div class="header-one">This section is a work in progress</div>
        <div class="header-two">Please check in later</div>
      </div>
    </div>
  </wp-page-body>
</wp-page>
