import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-square-border',
  templateUrl: './square-border.component.html',
  styleUrls: ['./square-border.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SquareBorderComponent implements OnInit {
  @Input() public width = 'auto';
  @Input() public highlighted: boolean;
  @Input() public disabled: boolean;

  constructor() {}

  ngOnInit(): void {}
}
