import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

import { SegmentAnalytics } from './utils/segment-analytics';

/**
 * This service provides Segment analytics library to the app
 * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
 */
@Injectable({
  providedIn: 'root',
})
export class SegmentAnalyticsService {
  private libraryLoader: Promise<SegmentAnalytics>;

  constructor() {}

  /**
   * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#debug
   */
  public async debug(enabled = true): Promise<void> {
    const lib = await this.libraryLoader;
    lib.debug(enabled);
  }

  /**
   * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
   */
  public identify(userId?: string, traits?: any, options?: any): Promise<void> {
    return new Promise(async (resolve) => {
      const lib = await this.libraryLoader;
      lib.identify(userId, traits, options, () => {
        resolve();
      });
    });
  }

  public identify$(userId?: string, traits?: any, options?: any): Observable<void> {
    return from(this.identify(...arguments));
  }

  public initialize(segmentId: string): Promise<SegmentAnalytics> {
    this.libraryLoader = new Promise<SegmentAnalytics>((resolve) => {
      const lib = this.loadLibrary(segmentId);
      // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#ready
      lib.ready(() => {
        // We should return a fresh refference here, otherwise the lib does nothing
        // tslint:disable-next-line: no-string-literal
        resolve(window['analytics'] as SegmentAnalytics);
      });
    });
    return this.libraryLoader;
  }

  public initializeMock(): Promise<SegmentAnalytics> {
    this.libraryLoader = new Promise<SegmentAnalytics>((resolve) => {
      return resolve(new SegmentAnalytics());
    });
    return this.libraryLoader;
  }

  /**
   * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page
   */
  public page(category?: string, name?: string, properties?: any, options?: any): Promise<void> {
    return new Promise(async (resolve) => {
      const lib = await this.libraryLoader;
      lib.page(category, name, properties, options, () => resolve());
    });
  }

  public page$(category?: string, name?: string, properties?: any, options?: any): Observable<void> {
    return from(this.page(...arguments));
  }

  /**
   * Reset identified user
   * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#reset--logout
   */
  public async reset(): Promise<void> {
    const lib = await this.libraryLoader;
    lib.reset();
  }

  public reset$(): Observable<void> {
    return from(this.reset());
  }

  /**
   * Records any action user performs
   * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
   */
  public track(event: string, properties?: any, options?: any): Promise<void> {
    return new Promise(async (resolve) => {
      const lib = await this.libraryLoader;
      lib.track(event, properties, options, () => resolve());
    });
  }

  public track$(event: string, properties?: any, options?: any): Observable<void> {
    return from(this.track(event, ...arguments));
  }

  // Private methods

  /**
   * Adds the source lib code to window.analytics and returns the refference
   * @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/
   */
  private loadLibrary(segmentId: string): SegmentAnalytics {
    // Create a queue, but don't obliterate an existing one!
    // tslint:disable-next-line: no-string-literal
    const analytics = (window['analytics'] = window['analytics'] || []);

    // If the real analytics.js is already on the page return.
    if (analytics.initialize) {
      return undefined;
    }

    // If the snippet was invoked already show an error.
    if (analytics.invoked) {
      if (window.console && console.error) {
        console.error('Segment snippet included twice.');
      }
      return undefined;
    }

    // Invoked flag, to make sure the snippet
    // is never invoked twice.
    analytics.invoked = true;

    // A list of the methods in Analytics.js to stub.
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
    ];

    // Define a factory to create stubs. These are placeholders
    // for methods in Analytics.js so that you never have to wait
    // for it to load to actually record data. The `method` is
    // stored as the first argument, so we can replay the data.
    // tslint:disable-next-line: only-arrow-functions
    analytics.factory = function (method): any {
      // tslint:disable-next-line: only-arrow-functions
      return function (): any {
        const args = Array.prototype.slice.call(arguments);
        args.unshift(method);
        analytics.push(args);
        return analytics;
      };
    };

    // For each of our methods, generate a queueing stub.
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < analytics.methods.length; i++) {
      const key = analytics.methods[i];
      analytics[key] = analytics.factory(key);
    }

    // Define a method to load Analytics.js from our CDN,
    // and that will be sure to only ever load it once.
    // tslint:disable-next-line: only-arrow-functions
    analytics.load = function (key, options): any {
      // Create an async script element based on your key.
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';

      // Insert our script next to the first script element.
      const first = document.getElementsByTagName('script')[0];
      first.parentNode.insertBefore(script, first);
      analytics._loadOptions = options;
    };

    // Add a version to keep track of what's in the wild.
    analytics.SNIPPET_VERSION = '4.1.0';

    // Load Analytics.js with your key, which will automatically
    // load the tools you've enabled for your account. Boosh!
    analytics.load(segmentId);

    // Make the first page call to load the integrations. If
    // you'd like to manually name or tag the page, edit or
    // move this call however you'd like.
    // analytics.page();

    return analytics;
  }
}
