import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { MenuOption } from './menu-option';

@Component({
  selector: 'wp-menu-inline',
  template: `
    <div class="trigger" [matMenuTriggerFor]="menu" [ngClass]="{ disabled: disabled }">
      <span><ng-content></ng-content></span>
      <span class="icon"><wp-icon-arrow-right [rotateDown]="true" [disabled]="disabled"></wp-icon-arrow-right></span>
    </div>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let opt of options" (click)="opt.onclick(data)" [disabled]="opt.disabled">
        {{ opt.label }}
      </button>
    </mat-menu>
  `,
  styles: [
    `
      .trigger {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .trigger > .icon {
        margin-left: 6px;
        position: relative;
        bottom: -1px;
      }

      .trigger.disabled {
        color: #c6c6ca;
        pointer-events: none;
      }
      .trigger:not(.disabled) {
        color: black;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuInlineComponent implements OnInit {
  @Input() data: any;
  @Input() public options: MenuOption[];
  @Input() disabled: boolean;
  constructor() {}

  ngOnInit(): void {}
}
