import * as fromTypes from '../../types';
import * as fromActions from '../actions/vehicle-list.actions';
import { createReducer, on } from '@ngrx/store';

export interface VehicleListState {
  isVehiclesSectionInitialized?: boolean;
  page: {
    items: fromTypes.PortalEntity[];
    totalFound: number;
    isLoading: boolean;
    listError: fromTypes.WpError;
  };
  staticFilters: fromTypes.VehicleListFilter[];
  filters: fromTypes.VehicleListFilter[];
  status: fromTypes.EntityStatus;
  selectedVehicleId?: string;
  currentSearch: string;
  pagination: {
    page: number;
    pageSize: number;
  };
}

export type AllVehicleListState = {
  [key in fromTypes.VehicleListName]?: VehicleListState;
};

export const initialVehicleListState: VehicleListState = {
  page: {
    items: [],
    totalFound: undefined,
    isLoading: false,
    listError: undefined,
  },
  staticFilters: [],
  status: fromTypes.ui.DEFAULT_VEHICLE_LIST_STATUS,
  filters: [],
  selectedVehicleId: undefined,
  currentSearch: undefined,
  pagination: {
    page: 0,
    pageSize: fromTypes.ui.DEFAULT_VEHICLE_LIST_PAGE_SIZE,
  },
};

export const getInitialAllVehicleListState = (): AllVehicleListState => {
  const result: AllVehicleListState = {};
  fromTypes.vehicleLists.forEach((listName) => {
    result[listName] = {
      ...initialVehicleListState,
      page: {
        ...initialVehicleListState.page,
      },
      staticFilters: [],
      filters: [],
      pagination: {
        ...initialVehicleListState.pagination,
      },
    };
  });
  return result;
};

export const vehicleListsReducer = createReducer<AllVehicleListState>(
  { ...getInitialAllVehicleListState() },
  on(fromTypes.cleanUpStore, () => ({ ...getInitialAllVehicleListState() })),
  /**
   * Initialized
   */
  on(fromActions.vehicleListInitialized, (allState, action) => {
    const { listName, snapshot, staticFilters, selectedMultiAccountManagementEntity } = action;
    const state = allState[listName];
    let resultState: VehicleListState;
    if (!snapshot) {
      resultState = {
        ...state,
        staticFilters: staticFilters || [],
      };
    } else {
      const filters = snapshot?.filters?.length
        ? snapshot.filters
        : selectedMultiAccountManagementEntity
          ? [selectedMultiAccountManagementEntity]
          : [];
      resultState = {
        ...state,
        pagination: {
          ...state.pagination,
          page: snapshot?.page,
          pageSize: snapshot?.pageSize,
        },
        filters,
        staticFilters: staticFilters || [],
        status: snapshot.status,
        page: {
          ...state.page,
          totalFound: undefined,
        },
        currentSearch: snapshot?.search,
        selectedVehicleId: snapshot.selectedVehicleId,
      };
    }
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.initVehiclesSection, (allState) => {
    const state = allState[fromTypes.VehicleListName.VEHICLES];
    return {
      ...allState,
      [fromTypes.VehicleListName.VEHICLES]: {
        ...state,
        isVehiclesSectionInitialized: true,
      },
    };
  }),
  on(fromActions.destroyVehiclesSection, (allState) => {
    const state = allState[fromTypes.VehicleListName.VEHICLES];
    return {
      ...allState,
      [fromTypes.VehicleListName.VEHICLES]: {
        ...state,
        isVehiclesSectionInitialized: false,
      },
    };
  }),
  // Select item if vehicle details is opened outside the list
  on(fromActions.vehiclesSectionVehicleDetailsOpened, (allState, { vehicleId }) => {
    const state = allState[fromTypes.VehicleListName.VEHICLES];
    return {
      ...allState,
      [fromTypes.VehicleListName.VEHICLES]: {
        ...state,
        selectedVehicleId: vehicleId,
      },
    };
  }),
  on(fromActions.vehiclesSectionVehicleDetailsClosed, (allState) => {
    const state = allState[fromTypes.VehicleListName.VEHICLES];
    return {
      ...allState,
      [fromTypes.VehicleListName.VEHICLES]: {
        ...state,
        selectedVehicleId: undefined,
      },
    };
  }),
  /**
   * List changed
   */
  on(fromActions.pageChanged, (allState, action) => {
    const { listName, page } = action;
    const state = allState[listName];
    const resultState: VehicleListState = {
      ...state,
      pagination: {
        ...state.pagination,
        page,
      },
      selectedVehicleId: undefined,
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.pageSizeChanged, (allState, action) => {
    const { listName, pageSize } = action;
    const state = allState[listName];
    const resultState: VehicleListState = {
      ...state,
      pagination: {
        ...state.pagination,
        pageSize,
        page: 0,
      },
      selectedVehicleId: undefined,
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.searchChanged, (allState, action) => {
    const { listName, search } = action;
    const state = allState[listName];
    const resultState: VehicleListState = {
      ...state,
      pagination: {
        ...state.pagination,
        page: 0,
      },
      page: {
        ...state.page,
        totalFound: undefined,
      },
      currentSearch: search,
      selectedVehicleId: undefined,
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.statusChanged, (allState, action) => {
    const { listName, status } = action;
    const state = allState[listName];
    const resultState: VehicleListState = {
      ...state,
      pagination: {
        ...state.pagination,
        page: 0,
      },
      page: {
        ...state.page,
        totalFound: undefined,
      },
      status,
      selectedVehicleId: undefined,
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.filtersChanged, (allState, action) => {
    const { listName, filters } = action;
    const state = allState[listName];
    const resultState: VehicleListState = {
      ...state,
      pagination: {
        ...state.pagination,
        page: 0,
      },
      page: {
        ...state.page,
        totalFound: undefined,
      },
      filters,
      selectedVehicleId: undefined,
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.selectedVehicleChanged, (allState, action) => {
    const { listName, VehicleId } = action;
    const state = allState[listName];
    const resultState: VehicleListState = {
      ...state,
      selectedVehicleId: VehicleId,
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  /**
   * Load Vehicle list requested
   */
  on(fromActions.loadVehicleListRequested, (allState, action) => {
    const { listName } = action;
    const state = allState[listName];
    const resultState: VehicleListState = {
      ...state,
      page: {
        ...state.page,
        isLoading: true,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.loadVehicleListSuccess, (allState, action) => {
    const { listName, total, items } = action;
    const state = allState[listName];
    const resultState: VehicleListState = {
      ...state,
      page: {
        ...state.page,
        isLoading: false,
        totalFound: total,
        items,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.loadVehicleListFailed, (allState, action) => {
    const { listName, error } = action;
    const state = allState[listName];
    const resultState: VehicleListState = {
      ...state,
      page: {
        ...state.page,
        isLoading: false,
        listError: error,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
);

function areFiltersEqual(
  filters1: fromTypes.VehicleListFilter[] = [],
  filters2: fromTypes.VehicleListFilter[] = [],
): boolean {
  const hashFn = (f: fromTypes.VehicleListFilter) => f.id;
  return fromTypes.utils.arraysEqual(filters1, filters2, hashFn);
}
