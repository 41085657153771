import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { SmartAutocomplete } from '../../models/smart-autocomplete';

@Component({
  selector: 'wp-smart-autocomplete',
  templateUrl: './smart-autocomplete.component.html',
  styleUrls: ['./smart-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartAutocompleteComponent {
  @Input() model: SmartAutocomplete;
}
