import { Injectable } from '@angular/core';
import { RouterOutlets } from '@rootTypes/entities/router';
import { PortalRoute } from '../../types/portal-route';
import { PortalRouterService } from '../../types/portal-router-service';

export interface DriverListRouteData {
  // Query Params
  query?: string;
}

export const driverListRoutePath = 'all';

export function composeDriverListRoutePath(): string {
  return `/drivers/${driverListRoutePath}`;
}

export const driverListRoute: PortalRoute<DriverListRouteData> = {
  path: driverListRoutePath,

  getHref: composeDriverListRoutePath,

  isOnRoute: (state) => {
    return state.url.split('?')[0] === composeDriverListRoutePath();
  },

  parse: (state) => {
    return {
      // Query Params
      query: state.queryParams.driverList,
    };
  },

  request: (data, queryParamsHandling, replaceUrl) => {
    return {
      path: composeDriverListRoutePath().split('/'),

      extras: {
        queryParams: {
          driverList: data.query,
        },

        queryParamsHandling,
        replaceUrl,
      },
    };
  },
};

@Injectable({ providedIn: 'root' })
export class DriverListRouterService extends PortalRouterService<DriverListRouteData> {
  public readonly outlet = RouterOutlets.PRIMARY;
  public readonly route: PortalRoute<DriverListRouteData> = driverListRoute;
}
