<svg
  *ngIf="green"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <g fill="none" fill-rule="evenodd">
    <g>
      <g>
        <path
          stroke="#47A540"
          stroke-linecap="round"
          stroke-width="2"
          d="M15.5 5h4.667C21.179 5 22 5.846 22 6.889V20.11C22 21.154 21.18 22 20.167 22H1.833C.821 22 0 21.154 0 20.111V6.89C.15 5.629.762 5 1.833 5h4.674"
          transform="translate(1)"
        />
        <path
          stroke="#47A540"
          stroke-width="2"
          d="M9 1v5c0 1.105.895 2 2 2s2-.895 2-2V1H9zM4 11H10V19H4z"
          transform="translate(1)"
        />
        <path
          fill="#47A540"
          d="M12 12H19V14H12zM12 16H19V18H12z"
          transform="translate(1)"
        />
      </g>
    </g>
  </g>
</svg>

<svg
  *ngIf="!green"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <g fill="none" fill-rule="evenodd">
    <g>
      <g>
        <path
          stroke="#979797"
          stroke-linecap="round"
          stroke-width="2"
          d="M15.5 5h4.667C21.179 5 22 5.846 22 6.889V20.11C22 21.154 21.18 22 20.167 22H1.833C.821 22 0 21.154 0 20.111V6.89C.15 5.629.762 5 1.833 5h4.674"
          transform="translate(1)"
        />
        <path
          stroke="#979797"
          stroke-width="2"
          d="M9 1v5c0 1.105.895 2 2 2s2-.895 2-2V1H9zM4 11H10V19H4z"
          transform="translate(1)"
        />
        <path
          fill="#979797"
          d="M12 12H19V14H12zM12 16H19V18H12z"
          transform="translate(1)"
        />
      </g>
    </g>
  </g>
</svg>
