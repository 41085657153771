import { MechanicAssignmentQueryParam, MechanicAssignmentStatusQueryParam } from '../types';
import { VehicleInspectionAssignedMechanicFilter, VehicleInspectionAssignedMechanicStatusFilter } from '@apiEntities';

export const queryParamToMechanicAssignment = (
  value: MechanicAssignmentQueryParam,
): VehicleInspectionAssignedMechanicFilter => {
  if (value === MechanicAssignmentStatusQueryParam.ASSIGNED) {
    return VehicleInspectionAssignedMechanicStatusFilter.ASSIGNED;
  }
  if (value === MechanicAssignmentStatusQueryParam.UNASSIGNED) {
    return VehicleInspectionAssignedMechanicStatusFilter.UNASSIGNED;
  }
  return value;
};
