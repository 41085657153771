import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import * as fromTypes from '../types';
import { Observable, fromEvent, Subscription } from 'rxjs';
import { DateRangePickerService } from '../date-range-picker.service';
import { take, filter } from 'rxjs/operators';

@Component({
  selector: 'wp-dp-day',
  templateUrl: './dp-day.component.html',
  styleUrls: ['./dp-day.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DpDayComponent implements AfterViewInit, OnInit, OnDestroy {
  // @Input() public readonly!: boolean;
  @Input() public day!: fromTypes.RangePickerDay;
  @Output() public hoveredIn: EventEmitter<void> = new EventEmitter<void>();
  @Output() public hoveredOut: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('dayWrap') private dayWrap!: ElementRef;

  public readonly$!: Observable<boolean>;
  public day$!: Observable<fromTypes.RangePickerDay>;

  private subscriptions: Subscription = new Subscription();

  constructor(private store: DateRangePickerService) {}

  ngAfterViewInit(): void {
    if (this.day.isDisplayed) {
      const sub = this.readonly$
        .pipe(
          take(1),
          filter((data) => !data),
        )
        .subscribe(() => {
          this.initMouseEnterObservables();
        });
      this.subscriptions.add(sub);
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  ngOnInit(): void {
    if (this.day.isDisplayed) {
      this.day$ = this.store.dayChanges$(this.day);
    }

    this.readonly$ = this.store.isReadOnly();
  }

  public onDayClick(): void {
    this.store.dayClicked(this.day);
  }

  private initMouseEnterObservables(): void {
    const sub1 = fromEvent(this.dayWrap.nativeElement, 'mouseenter').subscribe(() =>
      this.store.setHovered(this.day.moment),
    );

    const sub2 = fromEvent(this.dayWrap.nativeElement, 'mouseleave').subscribe(() => this.store.setHovered(null));
    this.subscriptions.add(sub1);
    this.subscriptions.add(sub2);
  }
}
