<div class="icon">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <g fill="none" fill-rule="evenodd">
      <circle cx="7" cy="7" r="7" fill="#47A540" />
      <g stroke="#FFF" stroke-linecap="round" stroke-width="2">
        <path
          d="M2.406 4.574L6.98 0M.219 2.386l2.187 2.188"
          transform="translate(3.281 4.614)"
        />
      </g>
    </g>
  </svg>
</div>
