import { createSelector } from '@ngrx/store';
import {
  CalendarEntityState,
  RiderEntityState,
  RouteDataState,
  StopEntityState,
} from '../reducers/route-data.reducers';
import { getFeature } from './get-feature';
import * as fromTypes from '../../types';
import { GetStudentSnapshotResponse } from '../../../../api/endpoints/get-student-snapshot';
import { studentsToEquipmentSummaryV2 } from '@rootTypes/utils/rider';

export const displayedWeekdayPills = [
  fromTypes.Weekday.SUNDAY,
  fromTypes.Weekday.MONDAY,
  fromTypes.Weekday.TUESDAY,
  fromTypes.Weekday.WEDNESDAY,
  fromTypes.Weekday.THURSDAY,
  fromTypes.Weekday.FRIDAY,
  fromTypes.Weekday.SATURDAY,
];

export const getRouteDataState = createSelector<any, any, RouteDataState>(getFeature, (state) => state.data);

export const getAllRoutes = createSelector(getRouteDataState, (state) => state.routes);

const getRouteEntityState = createSelector(getAllRoutes, (state, props: { routeId: string }) => {
  const { routeId } = props;
  return state[routeId];
});

// single route
export const getRoute = createSelector<any, any, any, fromTypes.Route>(getRouteEntityState, (state) =>
  state ? state.entity : null,
);

export const getRouteLoading = createSelector<any, any, any, boolean>(getRouteEntityState, (state) =>
  state ? state.isLoading : false,
);

export const getRouteError = createSelector<any, any, any, fromTypes.WpError>(getRouteEntityState, (state) =>
  state ? state.error : null,
);

export const getRouteTimezoneAbbreviation = createSelector(getRoute, (state) =>
  state ? state.timezoneAbbreviation : null,
);

// stops state
export const getStopsState = createSelector(getRouteDataState, (state) => state.stops);

const getStopEntityState = createSelector<any, any, any, StopEntityState>(
  getStopsState,
  (state, props: { stopId: string }) => {
    if (!state) {
      return null;
    }
    const { stopId } = props;
    return state[stopId];
  },
);

export const routeStop = createSelector(getStopEntityState, (state) => (state ? state.entity : null));

// route stops
export const getStopsByRouteState = createSelector(getRouteDataState, (state) => state.routeStops);

export const getStopIdsByRouteId = createSelector(getStopsByRouteState, (state, props: { routeId: string }) =>
  state[props.routeId] ? Object.keys(state[props.routeId]) : [],
);

export const getStopsByRoute = createSelector(getStopIdsByRouteId, getStopsState, (stopIdsByRoute, allStops) => {
  const stops = stopIdsByRoute.map((stopId) => allStops[stopId].entity).filter((s) => !!s);
  stops.sort((a, b) => a.sequence - b.sequence);
  return stops;
});

/**
 * Returns only stops for the route, on which there is 1 or more riders for the given weekday
 */
export const getActiveStopsByRouteByWeekday = (weekday: fromTypes.Weekday) => {
  return createSelector(
    getStopsByRoute,
    getRidersByStopsState,
    (stopsByRoute, ridersByStops): fromTypes.RouteStop[] => {
      if (!stopsByRoute) {
        return [];
      }
      return stopsByRoute.filter(
        (stop) =>
          ridersByStops[stop.stopId] &&
          ridersByStops[stop.stopId][weekday] &&
          Object.keys(ridersByStops[stop.stopId][weekday]).length > 0,
      );
    },
  );
};

// stops by route by rider (by weekdays)
export const getStopsByRouteByRiderByWeekdayState = createSelector(
  getRouteDataState,
  (state) => state.riderStopsByWeekdays,
);

export const stopIdsByRouteByRiderByWeekday = createSelector(
  getStopsByRouteByRiderByWeekdayState,
  (state, props: { routeId: string; riderId: string; weekday: fromTypes.Weekday }) => {
    const { routeId, riderId, weekday } = props;
    if (!(state && state[routeId] && state[routeId][riderId] && state[routeId][riderId][weekday])) {
      return [];
    }
    return [state[routeId][riderId][weekday].pickupId, state[routeId][riderId][weekday].dropoffId];
  },
);

export const stopsByRouteByRiderByWeekday = createSelector(
  stopIdsByRouteByRiderByWeekday,
  getStopsState,
  (stopIds, stopState) => {
    if (!(stopIds && stopState)) {
      return [];
    }
    return stopIds
      .filter((stopId) => stopState[stopId] && stopState[stopId].entity)
      .map((stopId) => stopState[stopId].entity);
  },
);

export const weekdayPillTabsForRoute = createSelector<any, any, any, any, fromTypes.WeekdayPillState[]>(
  getStopsByRouteState,
  getStopsState,
  (stopsByRouteParam, stopsParam, props: { routeId: string }) => {
    const openedRouteId = props.routeId;
    if (!openedRouteId) {
      return [];
    }
    // get stops by current opened route,
    // if any, get operating weekdays for the route
    const stopsByCurrRouteMap = stopsByRouteParam[openedRouteId];
    if (stopsByCurrRouteMap) {
      const stopIdsByCurrRoute = Object.keys(stopsByCurrRouteMap);
      const stopsByCurrRoute = stopIdsByCurrRoute
        .map((stopId) => stopsParam[stopId])
        .filter((stopEntityState) => !!stopEntityState && !!stopEntityState.entity)
        .map((stopEntityState) => stopEntityState.entity);
      const operatingWeekdays = fromTypes.utils.getOperatingWeekdaysForRouteStops(stopsByCurrRoute);
      return displayedWeekdayPills.map((weekday) => {
        return {
          weekday,
          disabled: !operatingWeekdays.some((opDay) => opDay === weekday),
        } as fromTypes.WeekdayPillState;
      });
    }
    return [];
  },
);

// rider state
export const getRiderState = createSelector(getRouteDataState, (state) => state.riders);

const riderEntityState = (riderId) =>
  createSelector(getRiderState, (state): RiderEntityState => {
    if (!state) {
      return null;
    }
    return state[riderId];
  });

export const getRider = (riderId) =>
  createSelector(riderEntityState(riderId), (state) => (state ? state.entity : null));

export const getRiderLoading = (riderId) =>
  createSelector(riderEntityState(riderId), (state) => (state ? state.isLoading : null));

export const getRiderError = (riderId) =>
  createSelector(riderEntityState(riderId), (state) => (state ? state.error : null));

// route riders && rider equipment summary
export const getRidersByRouteState = createSelector(getRouteDataState, (state) => state.routeRiders);

export const getRiderIdsByRoute = createSelector(getRidersByRouteState, (state, props: { routeId: string }) => {
  const { routeId } = props;
  const stateByRoute = state[routeId];
  if (!stateByRoute) {
    return [];
  }
  return Object.keys(stateByRoute);
});

/**
 * Returns only rider ids for the riders that have stops on the route for the weekday
 */
const getActiveRiderIdsByRouteByWeekday = (weekday) => {
  return createSelector(
    getRiderIdsByRoute,
    getStopsByRouteByRiderByWeekdayState,
    (riderIds, stopsByRiders, props: { routeId: string }): string[] => {
      const { routeId } = props;
      if (!riderIds) {
        return [];
      }
      return riderIds.filter((riderId) => {
        return !!(
          stopsByRiders[routeId] &&
          stopsByRiders[routeId][riderId] &&
          stopsByRiders[routeId][riderId][weekday] &&
          stopsByRiders[routeId][riderId][weekday].pickupId
        );
      });
    },
  );
};

export const getActiveRidersByRouteByWeekday = (weekday) => {
  return createSelector(
    getActiveRiderIdsByRouteByWeekday(weekday),
    getRiderState,
    (riderIds, allRiders): GetStudentSnapshotResponse[] => {
      if (!riderIds) {
        return [];
      }
      return riderIds
        .map((riderId) => {
          return allRiders[riderId] ? allRiders[riderId].entity : null;
        })
        .filter((r) => !!r);
    },
  );
};

export const getRidersByRoute = createSelector(
  getRiderIdsByRoute,
  getRiderState,
  (riderIds, riderState): GetStudentSnapshotResponse[] => {
    return riderIds
      .map((riderId) => riderState[riderId])
      .filter((entityState) => !!(entityState && entityState.entity))
      .map((entityState) => entityState.entity);
  },
);

export const riderEquipmentByRoute = createSelector(getRidersByRoute, (riders) => studentsToEquipmentSummaryV2(riders));

// riders by stops (and by weekdays)
const getRidersByStopsState = createSelector(getRouteDataState, (state) => state.stopRidersByWeekdays);

export const getRiderIdsByStopsByWeekdays = (stopId: string, weekday: fromTypes.Weekday) =>
  createSelector(getRidersByStopsState, (state) => {
    const stateByStop = state[stopId];
    if (!stateByStop) {
      return [];
    }
    const stateByStopByWeekday = stateByStop[weekday];
    if (!stateByStopByWeekday) {
      return [];
    }
    return Object.keys(stateByStopByWeekday);
  });

export interface RouteRidersByStopsByWeekdaysMeta {
  firstRiderId?: string;
  restRiderIds: string[];
  riderCount: number;
}

export const getRidersByStopsByWeekdaysMeta = (stopId: string, weekday: fromTypes.Weekday) =>
  createSelector(getRiderIdsByStopsByWeekdays(stopId, weekday), (riderIds): RouteRidersByStopsByWeekdaysMeta => {
    const result: RouteRidersByStopsByWeekdaysMeta = {
      firstRiderId: undefined,
      restRiderIds: [],
      riderCount: 0,
    };

    if (!riderIds.length) {
      return result;
    }

    result.riderCount = riderIds.length;
    result.firstRiderId = riderIds.shift();
    result.restRiderIds = riderIds;
    return result;
  });

// calendars by routes
export const getCalendarsByRoutesState = createSelector(getRouteDataState, (state) => state.routeCalendars);

const getSingleCalendarByRouteState = createSelector<any, any, any, CalendarEntityState>(
  getCalendarsByRoutesState,
  (state, props: { routeId: string }) => state[props.routeId],
);

export const getCalendarLoading = createSelector(getSingleCalendarByRouteState, (state) => state.isLoading);

export const getCalendar = createSelector(getSingleCalendarByRouteState, (state) => state.entity);

export const getCalendarError = createSelector(getSingleCalendarByRouteState, (state) => state.error);

// route drivers
export const getAllDriversByRouteState = createSelector(getRouteDataState, (state) => state.routeDriversByWeekdays);

export const getRouteDriversByWeekdays = createSelector<
  any,
  any,
  any,
  RouteDataState['routeDriversByWeekdays'][string]
>(getAllDriversByRouteState, (state, props: { routeId: string }) => state[props.routeId]);

export const getDefaultDriverForWeekday = createSelector(
  getAllDriversByRouteState,
  (allState, props: { routeId: string; weekday: fromTypes.Weekday }) => {
    const { weekday, routeId } = props;
    const state = allState[routeId];
    if (!(state && state[weekday])) {
      return null;
    }
    const driversByWeekday = state[weekday] as fromTypes.RouteDrivers;
    return Object.keys(driversByWeekday).find((driverId) => {
      return driversByWeekday[driverId].isDefault;
    });
  },
);

// route polyline
const getAllPolylines = createSelector(getRouteDataState, (state) => state.polylines);
export const getRoutePolyline = (routeId: string) => createSelector(getAllPolylines, (state) => state[routeId]);

// student DOW variability by route
const getRiderDOWSelectionState = createSelector(getRouteDataState, (state) => state.routeRiderDOWSelection);

export const getRiderDOWSelectionByRoute = (routeId) =>
  createSelector(getRiderDOWSelectionState, (state) => state[routeId]);

export const getRiderDOWSelectionByRouteByRider = (routeId, riderId) =>
  createSelector(getRiderDOWSelectionByRoute(routeId), (state) => (state ? state[riderId] : undefined));

export const getRiderDOWSelectionWeekdays = (routeId, riderId) =>
  createSelector(getRiderDOWSelectionByRouteByRider(routeId, riderId), (state) => {
    if (!state) {
      return undefined;
    }
    return state.attendingWeekdays;
  });

export const isStopWithRiderDOWVariability = (routeId: string, stopId: string, weekday: fromTypes.Weekday) =>
  createSelector(
    getRiderDOWSelectionByRoute(routeId),
    getRiderIdsByStopsByWeekdays(stopId, weekday),
    (riderDOWSelection, riderIds) => {
      if (!riderDOWSelection) {
        return false;
      }
      return riderIds.some((riderId) => !!riderDOWSelection[riderId]);
    },
  );

// District programs by routes
export const getAllDistrictProgramsStateByRoute = createSelector(
  getRouteDataState,
  (state) => state.districtProgramsByRoutes,
);
