import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-removed-badge',
  templateUrl: './removed-badge.component.html',
  styleUrls: ['./removed-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemovedBadgeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
