import { groupDates } from './group-dates';
import { YYYYMMDDString } from './formatters';

export const yyyyMMDDDisplayMulti = (dates: YYYYMMDDString[]): string => {
  if (!(dates && dates.length)) {
    return null;
  }
  const input = dates.map((d) => ({ date: d, groupId: 'g' }));
  return groupDates(input, 'short')
    .map((g) => g.displayDateInterval)
    .join(', ');
};
