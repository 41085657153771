import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RouteConfigLoadStart, Router } from '@angular/router';
import { Location } from '@angular/common';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as fromActions from '../actions';
import { Store } from '@ngrx/store';
import { RouterStateUrl, State } from '../reducers';
import { routerNavigatedAction } from '@ngrx/router-store';
import { getPortalFeatures } from '@rootTypes/utils';

@Injectable({
  providedIn: 'root',
})
export class RouterEffects {
  private timeOutHandle: any;

  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location,
    private store: Store<State>,
  ) {
    const menuFeatures: { [path: string]: true } = getPortalFeatures()
      .filter((feature) => feature.isInMenu)
      .reduce((prev, curr) => {
        return { ...prev, [curr.path]: true };
      }, {});
    router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart && !!menuFeatures[event.route.path]) {
        this.store.dispatch(fromActions.lazyLoadingStart({ url: '/' + event.route.path }));
      }
    });
  }

  public navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.go),
        switchMap(({ path, query: queryParams, extras }) => {
          return this.router.navigate(path, { queryParams, ...extras });
        }),
      ),
    { dispatch: false },
  );

  public navigateAndCloseDrawer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.goAndCloseDrawer),
        // force re-load the route
        switchMap((action) => {
          return this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => action);
        }),
        switchMap(({ path, query: queryParams, extras }) => {
          return this.router.navigate([{ outlets: { drawer: null, primary: path } }], { queryParams, ...extras });
        }),
      ),
    { dispatch: false },
  );

  public openInDrawer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.openInDrawer),
        tap(({ path, query: queryParams, extras, displayBackText, isHalfDrawer }) => {
          const targetQueryParams = {
            ...(queryParams || {}),
          };
          if (isHalfDrawer) {
            targetQueryParams['isHalfDrawer'] = true;
          } else {
            targetQueryParams['isHalfDrawer'] = undefined;
          }
          if (displayBackText) {
            targetQueryParams['displayBackText'] = displayBackText;
          }
          this.router.navigate([{ outlets: { drawer: path } }], { queryParams: targetQueryParams, ...extras });
        }),
      ),
    { dispatch: false },
  );

  public drawerUrlChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      map((action) => action.payload.routerState as any as RouterStateUrl),
      distinctUntilChanged((prev, curr) => prev?.drawer?.url === curr?.drawer?.url),
      map((routerState) => {
        const isDrawerUrl = !!routerState?.drawer?.url;
        if (isDrawerUrl) {
          const isHalfDrawer = !!routerState?.queryParams?.isHalfDrawer;
          return fromActions.drawerOpenedChanged({ isHalfDrawer });
        } else {
          return fromActions.drawerClosedChanged();
        }
      }),
    ),
  );

  public drawerCloseSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.drawerClosedSelected),
      map(() => {
        return fromActions.go({
          path: [
            '',
            {
              outlets: {
                drawer: null,
              },
            },
          ],
          query: {
            displayBackText: undefined,
            isHalfDrawer: undefined,
          },
          extras: {
            queryParamsHandling: 'merge',
          },
        });
      }),
    ),
  );

  public replaceQueryParamsWithoutReloading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.replaceUrlWithoutReloading),
        tap((action) => {
          const urlTree = this.router.createUrlTree([...action.path], {
            queryParams: action.query,
            queryParamsHandling: 'merge',
          });
          const serializedUrl = this.router.serializeUrl(urlTree);
          window.history.pushState({}, '', serializedUrl);
          console.log(serializedUrl);
        }),
        // tap(action => {
        //     this.router.navigate()
        // })
      ),
    { dispatch: false },
  );

  public navigateBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.back),
        tap((action) => {
          const targetCount = -(action.count || 1);
          this.timeOutHandle = setTimeout(() => {
            window.history.length ? window.history.go(targetCount) : this.location.go('/');
          }, 100);
        }),
      ),
    { dispatch: false },
  );

  public navigateForward$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.back),
        tap(() => this.location.forward()),
      ),
    { dispatch: false },
  );
}
