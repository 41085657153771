import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { StudentsApiService } from '../../services/students-api.service';
import * as fromDataActions from '../actions/student-data.actions';
import { GetStudentV2Request } from '../../../../api/endpoints/get-student-v2';
import { StudentProjections } from '@apiEntities';

@Injectable()
export class StudentDataEffects {
  constructor(
    private actions$: Actions,
    private studentApi: StudentsApiService,
  ) {}

  public loadStudentRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...fromDataActions.loadStudentDetailsActions),
      mergeMap(({ studentId, options }) => {
        const request = { studentId } as GetStudentV2Request;
        const projections = [];
        if (options?.withRouteReadiness) {
          projections.push(StudentProjections.ROUTE_READINESS);
        }
        if (options?.withRoutes) {
          projections.push(StudentProjections.ROUTES);
        }
        if (options?.withParentAccounts) {
          projections.push(StudentProjections.PARENT_ACCOUNTS);
        }
        if (options?.withPrograms) {
          projections.push(StudentProjections.PROGRAMS);
        }
        if (options?.withPastPrograms) {
          projections.push(StudentProjections.PAST_PROGRAMS);
        }
        if (projections.length) {
          request._projections = projections;
        }
        return this.studentApi.getStudent(request).pipe(
          map((student) =>
            fromDataActions.loadStudentDetailsSuccess({
              studentId,
              data: student,
            }),
          ),
          catchError((err) => {
            console.log(err);
            return of(
              fromDataActions.loadStudentDetailsFailed({
                studentId,
                error: {
                  text: 'Failed to load student details',
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  public getStudentVerificationStatusRequested = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromDataActions.getStudentVerificationStatusRequested,
        fromDataActions.getStudentVerificationStatusRequestedFromList,
      ),
      map((action) => action.studentId),
      mergeMap((studentId) => {
        return this.studentApi.getStudentVerificationStatus(studentId).pipe(
          map((response) =>
            fromDataActions.getStudentVerificationStatusSuccess({
              studentId,
              data: response,
            }),
          ),
          catchError((err) => {
            console.log(err);
            return of(
              fromDataActions.getStudentVerificationStatusFailed({
                studentId,
                error: {
                  text: 'Failed to load student verification status',
                },
              }),
            );
          }),
        );
      }),
    ),
  );
}
