import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../../types';

const moduleName = 'Data';
const submoduleName = 'Campus Details';

export const setCampusId = createAction(
  `[${moduleName}] [${submoduleName}]: Set campus id`,
  props<{ campusId: string | undefined }>(),
);

export const setTab = createAction(
  `[${moduleName}] [${submoduleName}]: Set tab`,
  props<{ tab: fromTypes.CampusDetailsTab }>(),
);

export const cleanCampusDetails = createAction(`[${moduleName}] [${submoduleName}]: Clean campus details`);

export const removeCampusClicked = createAction(`[${moduleName}] [${submoduleName}]: Remove campus clicked`);
export const editCampusClicked = createAction(`[CampusDetailsComponent]: Edit campus clicked`);
