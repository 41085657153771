import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { DateRangePickerService } from '../date-range-picker.service';
import * as fromTypes from '../types';

@Component({
  selector: 'wp-dp-year',
  templateUrl: './dp-year.component.html',
  styleUrls: ['./dp-year.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DpYearComponent implements OnInit {
  @Input() public year: fromTypes.RangePickerYear;

  public isCurrentlySelected$: Observable<boolean>;

  constructor(private store: DateRangePickerService) {}

  ngOnInit(): void {
    this.isCurrentlySelected$ = this.store.isCurrentlySelectedYear(this.year);
  }

  public onYearClicked(): void {
    this.store.onYearClicked(this.year);
  }
}
