<svg
  xmlns="http://www.w3.org/2000/svg"
  width="22"
  height="22"
  viewBox="0 0 22 22"
>
  <g fill="none" fill-rule="evenodd">
    <g stroke="#47A540" stroke-width="2">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g
                        transform="translate(-600 -680) translate(486) translate(0 230) translate(0 67) translate(0 50) translate(0 285) translate(0 46) translate(115.833 3.833)"
                      >
                        <circle cx="9.167" cy="9.167" r="9.167" />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9.167 3.333V10"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M10.833 9.31v4.714"
                          transform="rotate(-45 10.833 11.667)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
