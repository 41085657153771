import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-btn-progress-green',
  templateUrl: './btn-progress-green.component.html',
  styleUrls: ['./btn-progress-green.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnProgressGreenComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
