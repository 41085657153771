<div class="action-btns">
  <div class="btn-wrap">
    <wp-btn-ghost
      [height]="'36px'"
      (clicked)="onCancelBtnClick()"
      [disabled]="isSaveBtnLoading"
    >
      <span class="link">Cancel</span>
    </wp-btn-ghost>
  </div>
  <div class="btn-wrap">
    <wp-btn-primary
      [slim]="true"
      [width]="'110px'"
      [disabled]="isSaveBtnDisabled"
      [loading]="isSaveBtnLoading"
      (clicked)="onSaveBtnClick()"
      >{{ saveBtnText }}</wp-btn-primary
    >
  </div>
</div>
