import { HardCodedGotoOption } from '../types/goto-option';
import { EnvironmentInjector, inject, runInInjectionContext } from '@angular/core';
import { PortalPermissionsFacade } from '../../../auth/store/facades/portal-permissions.facade';
import { RideListRouterService } from '../../../router/portal-routes/rides';
import { StudentListRouterService } from '../../../router/portal-routes/students';
import { RouteListRouterService } from '../../../router/portal-routes/routes';
import { MainRouteGroupListRouterService } from '../../../router/portal-routes/route-group/route-group-main-list';
import { DriverListRouterService } from '../../../router/portal-routes/drivers';
import { DistrictListRouterService } from '../../../router/portal-routes/districts';
import { CampusListRouterService } from '../../../router/portal-routes/campuses';
import { VendorListRouterService } from '../../../router/portal-routes/vendors';
import { YardListRouterService } from '../../../router/portal-routes/yards';
import { VehicleListRouterService } from '../../../router/portal-routes/vehicles';
import { TripListRouterService } from '../../../router/portal-routes/trips/trip-list';

export const getHardCodedGotoOptions = (): HardCodedGotoOption[] => {
  const permissions = inject(PortalPermissionsFacade);
  const environmentInjector = inject(EnvironmentInjector);
  return [
    {
      id: 'rides-list',
      label: 'Rides list',
      permissionGetter: () => permissions.isRideView$(),
      urlTreeGetter: () =>
        runInInjectionContext(environmentInjector, () =>
          inject(RideListRouterService).getUrlTree({ timetable: 'upcoming' }),
        ),
      searchBy: 'rides list rides',
    },
    {
      id: 'run-list',
      label: 'Run list',
      permissionGetter: () => permissions.isRouteView$(),
      urlTreeGetter: () =>
        runInInjectionContext(environmentInjector, () =>
          inject(RouteListRouterService).getUrlTree({ statusType: 'active' }, 'preserve'),
        ),
      searchBy: 'run list runs',
    },
    {
      id: 'trip-list',
      label: 'Trip list',
      permissionGetter: () => permissions.isCharterTripRideView$(),
      urlTreeGetter: () =>
        runInInjectionContext(environmentInjector, () => inject(TripListRouterService).getUrlTree({}, 'preserve')),
      searchBy: 'trip list trips',
    },
    {
      id: 'route-list',
      label: 'Route list',
      permissionGetter: () => permissions.isRouteGroupView$(),
      urlTreeGetter: () =>
        runInInjectionContext(environmentInjector, () => inject(MainRouteGroupListRouterService).getUrlTree({})),
      searchBy: 'route list routes',
    },
    {
      id: 'drivers-list',
      label: 'Driver list',
      permissionGetter: () => permissions.isDriverView$(),
      urlTreeGetter: () =>
        runInInjectionContext(environmentInjector, () => inject(DriverListRouterService).getUrlTree({})),
      searchBy: 'drivers driver list',
    },
    {
      id: 'districts-list',
      label: 'District list',
      permissionGetter: () => permissions.isDistrictView$(),
      urlTreeGetter: () =>
        runInInjectionContext(environmentInjector, () => inject(DistrictListRouterService).getUrlTree({})),
      searchBy: 'district list',
    },
    {
      id: 'campus-list',
      label: 'Campus list',
      permissionGetter: () => permissions.isCampusView$(),
      urlTreeGetter: () =>
        runInInjectionContext(environmentInjector, () => inject(CampusListRouterService).getUrlTree({})),
      searchBy: 'campuses campus list',
    },
    {
      id: 'student-list',
      label: 'Student list',
      permissionGetter: () => permissions.isStudentView$(),
      urlTreeGetter: () =>
        runInInjectionContext(environmentInjector, () => inject(StudentListRouterService).getUrlTree({})),
      searchBy: 'student list students',
    },
    {
      id: 'vendor-list',
      label: 'Vendor list',
      permissionGetter: () => permissions.isVendorView$(),
      urlTreeGetter: () =>
        runInInjectionContext(environmentInjector, () => inject(VendorListRouterService).getUrlTree({})),
      searchBy: 'vendor list vendors',
    },
    {
      id: 'yard-list',
      label: 'Yard list',
      permissionGetter: () => permissions.isYardView$(),
      urlTreeGetter: () =>
        runInInjectionContext(environmentInjector, () => inject(YardListRouterService).getUrlTree({})),
      searchBy: 'yard list yards',
    },
    {
      id: 'vehicle-list',
      label: 'Vehicle list',
      permissionGetter: () => permissions.isVehicleView$(),
      urlTreeGetter: () =>
        runInInjectionContext(environmentInjector, () => inject(VehicleListRouterService).getUrlTree({})),
      searchBy: 'vehicle list vehicles',
    },
  ];
};
