import { createAction, props } from '@ngrx/store';

const moduleName = 'Data';
const submoduleName = 'Campus Details';
const subSubModuleName = 'Students';

export const selectStudentId = createAction(
  `[${moduleName}] [${submoduleName}] [${subSubModuleName}]: Select student id`,
  props<{ studentId: string | undefined }>(),
);
