import { NgZone } from '@angular/core';

interface CacheRecord {
  value: any;
  expiresTimestamp: number;
}

export class InMemoryCache {
  private cache: Map<string, CacheRecord> = new Map();
  private intervalId: any;

  constructor(
    private zone: NgZone,
    private defaultExpiresInMins: number,
    private clearDigestIntervalMins: number,
  ) {}

  public has(id: string): boolean {
    return this.cache.has(id);
  }

  public get(id: string): any | undefined {
    return this.cache.get(id)?.value;
  }

  public set(id: string, value: any, expiresInMins?: number): void {
    const expiresInMs = (expiresInMins || this.defaultExpiresInMins) * 60000;
    const record: CacheRecord = {
      value,
      expiresTimestamp: Date.now() + expiresInMs,
    };
    this.cache.set(id, record);
    if (!this.intervalId) {
      this.startDigest();
    }
  }

  public remove(id: string): void {
    this.cache.delete(id);
  }

  public clear(): void {
    this.stopDigest();
    this.cache.clear();
  }

  private startDigest(): void {
    this.zone.runOutsideAngular(() => {
      this.intervalId = setInterval(() => {
        const now = Date.now();
        this.cache.forEach((record, id) => {
          if (now > record.expiresTimestamp) {
            this.cache.delete(id);
          }
        });
      }, this.clearDigestIntervalMins * 60000);
    });
  }

  private stopDigest(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  }
}
