import { PortalRouteTracePoint } from '../../entities/ride';
import { UTCTime } from '../common/date';

/**
 *Returns display time in ride timezone
 */
export const getDisplayTimeForRouteTrace = (trace: PortalRouteTracePoint, timezone: string): string => {
  const timestamp = trace.collected_timestamp;
  const formatter = new UTCTime(timestamp, timezone);
  return formatter.getDisplayTimeStr();
};

/**
 *Returns display time in user timezone
 */
export const getDisplayTimeForRouteTraceUserTimezone = (trace: PortalRouteTracePoint): string => {
  const timestamp = trace.collected_timestamp;
  const formatter = new UTCTime(timestamp);
  return formatter.getDisplayTimeStr();
};
