<div class="wp-display-photo-wrap">
  <div *ngFor="let photo of photos" class="wp-display-photo">
    <wp-display-photo
      [width]="'184px'"
      [height]="'184px'"
      [placeholderType]="placeholder.BUILDING"
      [isRound]="false"
      [imagePath]="photo"
    >
    </wp-display-photo>
  </div>
</div>
