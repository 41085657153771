import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'metersToDisplayDistanceMiles',
})
export class MetersToDisplayDistanceMilesPipe implements PipeTransform {
  transform(distanceMeters: number): string | null {
    if (!distanceMeters) {
      return '0 mi';
    }
    return `${(distanceMeters * 0.000621371192).toFixed(1)} mi`;
  }
}
