import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { openRideVideoRequested, rideVideoClosed } from '../actions/ride-video.actions';
import { map, switchMap } from 'rxjs/operators';
import { PopupableService } from '../../../../core/popupable/popupable.service';
import { RideVideoPopupComponent } from '../../../../features/common/rides-common/ride-video-popup/ride-video-popup.component';
import { OpenRideVideoEvent } from '@rootTypes/entities/ride/open-ride-video-event';

@Injectable()
export class RideVideoEffects {
  constructor(
    private actions$: Actions,
    private popupService: PopupableService,
  ) {}

  public openRideVideoRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openRideVideoRequested),
      switchMap((action) => {
        return this.popupService
          .openPopup<any, OpenRideVideoEvent, any>(RideVideoPopupComponent, action.event, {
            pageMaskZIndex: 14,
            contentZIndex: 15,
          })
          .pipe(map(() => rideVideoClosed()));
      }),
    ),
  );
}
