import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, map, mergeMap, filter } from 'rxjs';
import { VendorApiService } from '../../services/vendor-api.service';
import { of } from 'rxjs';
import * as fromActions from '../actions/vendor-data.actions';
import { Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { getVendorState } from '../selectors';

@Injectable()
export class VendorDataEffects {
  constructor(
    private actions$: Actions,
    private apiService: VendorApiService,
    private store: Store,
  ) {}

  public loadVendorRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadVendorRequested),
      mergeMap((action) => {
        const { vendorId } = action;
        return this.onLoadVendorProfileRequested(vendorId);
      }),
    ),
  );

  public lazyLoadVendorRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.lazyLoadVendorRequested),
      concatLatestFrom(({ vendorId }) => this.store.select(getVendorState(vendorId))),
      filter(([, state]) => !state.isLoading && !state.entity),
      map(([{ vendorId }]) => {
        return fromActions.loadVendorRequested({ vendorId });
      }),
    ),
  );

  private onLoadVendorProfileRequested(vendorId: string): Observable<Action> {
    return this.apiService.getVendorProfile(vendorId).pipe(
      map((vendor) =>
        fromActions.loadVendorSuccess({
          vendorId,
          vendor,
        }),
      ),
      catchError((err) => {
        console.log(err);
        return of(
          fromActions.loadVendorFailed({
            vendorId,
            error: {
              text: 'Failed to load vendor profile',
              originalError: err,
            },
          }),
        );
      }),
    );
  }
}
