import { Injectable } from '@angular/core';
import { RouterOutlets } from '@rootTypes/entities/router';
import { PortalRoute } from '../../types/portal-route';
import { PortalRouterService } from '../../types/portal-router-service';

export interface VehicleStatusEditorRouteData {
  vehicleId: string;
}

export const vehicleStatusEditorPath = 'change-vehicle-status';

export const vehicleStatusEditorRouteRegExp = new RegExp(`^_\/drawer\/${vehicleStatusEditorPath}\/(?<vehicleId>\S+)$`);

export const vehicleStatusEditorRoute: PortalRoute<VehicleStatusEditorRouteData> = {
  path: `:vehicleId`,

  isOnRoute: (state) => {
    return vehicleStatusEditorRouteRegExp.test(state.drawer.url);
  },

  parse: (state) => {
    const { vehicleId } = state.drawer.params;

    return {
      vehicleId,
    };
  },

  request: (data, queryParamsHandling, replaceUrl) => {
    const { vehicleId } = data;

    return {
      path: ['_', 'drawer', vehicleStatusEditorPath, vehicleId],

      extras: {
        ...(queryParamsHandling ? { queryParamsHandling } : {}),
        ...(replaceUrl ? { replaceUrl } : {}),
      },
    };
  },
};

@Injectable({ providedIn: 'root' })
export class VehicleStatusEditorRouterService extends PortalRouterService<VehicleStatusEditorRouteData> {
  public readonly outlet = RouterOutlets.DRAWER;
  public readonly route = vehicleStatusEditorRoute;
}
