import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl } from '@angular/forms';
import { millisToDisplayDuration } from '@rootTypes/utils';

@Component({
  selector: 'wp-duration-input',
  template: `
    <div class="decrement">
      <wp-btn-increment-minus class="btn-inner" (btnClick)="onDecrement()"></wp-btn-increment-minus>
    </div>
    <div class="input-wrap">
      <mat-form-field [hideRequiredMarker]="true">
        @if (!!label) {
          <mat-label>{{ label }}</mat-label>
        }
        <input
          #inputEl
          matInput
          autocomplete="off"
          readonly
          [formControl]="control"
          [errorStateMatcher]="errorStateMatcher"
          [type]="'text'"
          (input)="onInputEvent($event)"
        />

        <mat-error *ngIf="error">
          {{ error }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="increment">
      <wp-btn-increment-plus class="btn-inner" (btnClick)="onIncrement()"></wp-btn-increment-plus>
    </div>
  `,
  styleUrl: './duration-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DurationInputComponent implements OnChanges, AfterViewInit {
  @Input() public label: string;
  @Input() public valueMinutes: number;
  @Input() public changeStep: number = 1;
  @Input() public min: number;
  @Input() public max: number;
  @Input() public error: string;
  @Output() public valueChanged: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('inputEl') inputEl: ElementRef;

  public errorStateMatcher: ErrorStateMatcher = new (class extends ErrorStateMatcher {
    constructor(private comp: any) {
      super();
    }
    isErrorState(): boolean {
      return !!this.comp.errors?.length;
    }
  })(this);
  public control = new FormControl();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.valueMinutes && this.inputEl) {
      this.updateInput();
    }
  }

  ngAfterViewInit(): void {
    this.updateInput();
  }

  onInputEvent(event) {
    console.log(event);
  }

  onDecrement(): void {
    const value = Math.max(this.min ?? 0, (this.valueMinutes ?? 0) - this.changeStep);
    this.valueChanged.emit(value);
  }

  onIncrement(): void {
    const value = Math.min(this.max ?? Number.MAX_VALUE, (this.valueMinutes ?? 0) + this.changeStep);
    this.valueChanged.emit(value);
  }

  private updateInput(): void {
    this.inputEl.nativeElement.value = this.getDisplayValue(this.valueMinutes);
  }

  private getDisplayValue(sourceMinutes: number): string {
    const millis = (sourceMinutes ?? 0) * 60 * 1000;
    return millisToDisplayDuration(millis, true);
  }
}
