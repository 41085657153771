import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from 'src/app/api/api.service';
import { GetDriverRequest, GetDriverResponse, DriverProjection } from 'src/app/api/endpoints/get-driver';

export interface LoadDriverOptions {
  withVendor?: boolean;
  withVehicle?: boolean;
  withYard?: boolean;
  withRating?: boolean;
  withStandbyStatus?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class GetDriverApiService {
  constructor(private api: ApiService) {}

  public getDriver(driverId: string, options: LoadDriverOptions = {}): Observable<GetDriverResponse> {
    const request: GetDriverRequest = {
      driverId,
    };
    let projections: DriverProjection[];
    if (options.withVehicle) {
      projections = [...(projections || []), DriverProjection.VEHICLE];
    }
    if (options.withVendor) {
      projections = [...(projections || []), DriverProjection.VENDOR];
    }
    if (options.withYard) {
      projections = [...(projections || []), DriverProjection.YARD];
    }
    if (options.withRating) {
      projections = [...(projections || []), DriverProjection.RATING];
    }
    if (options.withStandbyStatus) {
      projections = [...(projections || []), DriverProjection.STANDBY];
    }
    if (projections) {
      request._projections = projections;
    }
    return this.api.getDriver(request);
  }
}
