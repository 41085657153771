import { PortalEntityType } from '@rootTypes';
import { CrossEntitySearchRequestType, EntitySearchRequest } from '../../../api/endpoints/entity-search';
import { entitySearchConfig } from '@rootTypes/utils/portal-entity/entity-search-config';

export const getCrossEntitySearchRequest = (
  query: string,
  types: PortalEntityType[],
  skip: number,
  limit: number,
): EntitySearchRequest => {
  const requestTypes = types.map(
    (s) => entitySearchConfig[s]?.crossEntitySearchRequestType ?? (s as unknown as CrossEntitySearchRequestType),
  );
  return {
    query,
    types: [...requestTypes],
    skip,
    limit,
  };
};
