import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const modulePrefix = 'Data';
const sectionPrefix = 'Student List';

export const setStudentListState = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Set student list state`,
  props<{
    snapshot: fromTypes.StudentListStateSnapshot;
    listName: fromTypes.StudentListName;
  }>(),
);

export const cleanStudentListState = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Clean student list state`,
  props<{ listName: fromTypes.StudentListName }>(),
);

export const studentSearchInputChanged = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Student search input changed`,
  props<{ input: string; listName: fromTypes.StudentListName }>(),
);

export const studentStatusChanged = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Student status changed`,
  props<{ status: fromTypes.rider.StudentListStatus; listName: fromTypes.StudentListName }>(),
);

export const setStaticFilters = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Set static filters`,
  props<{ filters: fromTypes.StudentListFilter[]; listName: fromTypes.StudentListName }>(),
);

export const currentPageChanged = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Current page changed`,
  props<{ page: number; listName: fromTypes.StudentListName }>(),
);

export const pageSizeChanged = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Page size changed`,
  props<{ pageSize: number; listName: fromTypes.StudentListName }>(),
);

export const studentCardInitialized = createAction(
  `[${modulePrefix}] [${sectionPrefix}] [Student Card]: Initialized`,
  props<{ studentId: string; listName: fromTypes.StudentListName }>(),
);

export const studentCardDestroyed = createAction(
  `[${modulePrefix}] [${sectionPrefix}] [Student Card]: Destroyed`,
  props<{ studentId: string; listName: fromTypes.StudentListName }>(),
);

export const studentListLoadRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load list requested`,
  props<{ request: fromTypes.StudentListStateSnapshot; listName: fromTypes.StudentListName }>(),
);

export const studentListLoadSuccess = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load list success`,
  props<{ studentIds: fromTypes.PortalEntity[]; totalFound: number; listName: fromTypes.StudentListName }>(),
);

export const studentListLoadFailed = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load list failed`,
  props<{ error: fromTypes.WpError; listName: fromTypes.StudentListName }>(),
);
