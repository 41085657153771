import { createAction, props } from '@ngrx/store';
import { GetRouteGroupActivityPaymentConfigsResponse } from '../../../api/endpoints/get-route-group-activity-payment-configs';
import { WpError } from '@rootTypes';

export const getRouteGroupActivityPaymentConfigRequestedFromRouteGroupDetails = createAction(
  '[RouteGroupDetailsEffects]: API Get activity payment configs requested',
  props<{ routeGroupId: string }>(),
);

export const getRouteGroupActivityPaymentConfigRequestedFromRouteGroupPaymentEditor = createAction(
  '[RouteGroupDetailsPaymentEditor]: API Get activity payment configs requested',
  props<{ routeGroupId: string }>(),
);

export const getRouteGroupActivityPaymentConfigSuccess = createAction(
  '[RouteGroupDataEffects]: API Get route group activity payment config success',
  props<{ routeGroupId: string; config: GetRouteGroupActivityPaymentConfigsResponse }>(),
);

export const getRouteGroupActivityPaymentConfigFailed = createAction(
  '[RouteGroupDataEffects]: API API Get route group activity payment config failed',
  props<{ routeGroupId: string; error: WpError }>(),
);

export const getRouteGroupActivityPaymentConfigActions = [
  getRouteGroupActivityPaymentConfigRequestedFromRouteGroupDetails,
  getRouteGroupActivityPaymentConfigRequestedFromRouteGroupPaymentEditor,
];
