import * as fromEntities from '../../entities/rider';
import { ApiStudent } from '@apiEntities';

export const ridersToEquipmentSummary = (riders: fromEntities.Rider[]): fromEntities.RiderEquipmentSummary => {
  const initialEquipmentSummary: fromEntities.RiderEquipmentSummary = {
    harness: 0,
    boosterSeat: 0,
    seatBeltGuard: 0,
    walkerSupport: 0,
    wheelChair: 0,
    carSeat: 0,
    specialNeeds: 0,
    aide: 0,
    heartProgram: 0,
    fosterProgram: 0,
    doorToDoorStop: 0,
    cornerStop: 0,
    attendant: 0,
  };
  const result = riders.reduce((prev, curr) => {
    const newSummary = { ...prev };
    newSummary.boosterSeat += curr.equipment.isBoosterSeat ? 1 : 0;
    newSummary.harness += curr.equipment.isHarness ? 1 : 0;
    newSummary.seatBeltGuard += curr.equipment.isSeatBeltGuard ? 1 : 0;
    newSummary.walkerSupport += curr.equipment.isWalkerSupport ? 1 : 0;
    newSummary.wheelChair += curr.equipment.isWheelchair ? 1 : 0;
    newSummary.carSeat += curr.equipment.isCarSeat ? 1 : 0;
    newSummary.specialNeeds += curr.equipment.isSpecialNeeds ? 1 : 0;
    newSummary.aide += curr.equipment.isAide ? 1 : 0;
    newSummary.heartProgram += curr.equipment.heartProgram ? 1 : 0;
    newSummary.fosterProgram += curr.equipment.fosterProgram ? 1 : 0;
    newSummary.doorToDoorStop += curr.equipment.doorToDoorStop ? 1 : 0;
    newSummary.cornerStop += curr.equipment.cornerStop ? 1 : 0;
    newSummary.attendant += curr.equipment.isAttendant ? 1 : 0;
    return newSummary;
  }, initialEquipmentSummary);
  return result;
};

export const studentsToEquipmentSummaryV2 = (students: ApiStudent[]): fromEntities.RiderEquipmentSummary => {
  const initialEquipmentSummary: fromEntities.RiderEquipmentSummary = {
    harness: 0,
    boosterSeat: 0,
    seatBeltGuard: 0,
    walkerSupport: 0,
    wheelChair: 0,
    carSeat: 0,
    specialNeeds: 0,
    aide: 0,
    heartProgram: 0,
    fosterProgram: 0,
    doorToDoorStop: 0,
    cornerStop: 0,
    attendant: 0,
  };
  const result = (students || []).reduce((prev, curr) => {
    const newSummary = { ...prev };
    newSummary.boosterSeat += curr.profile.equipmentNeeds.isBoosterSeatRequired ? 1 : 0;
    newSummary.harness += curr.profile.equipmentNeeds.isHarnessRequired ? 1 : 0;
    newSummary.seatBeltGuard += curr.profile.equipmentNeeds.isSeatBeltGuardRequired ? 1 : 0;
    newSummary.walkerSupport += curr.profile.equipmentNeeds.isWalkerSupportRequired ? 1 : 0;
    newSummary.wheelChair += curr.profile.equipmentNeeds.isWheelchairSupportRequired ? 1 : 0;
    newSummary.carSeat += curr.profile.equipmentNeeds.isCarSeatRequired ? 1 : 0;
    newSummary.specialNeeds += curr.profile.specialNeeds.isSpedStudent ? 1 : 0;
    newSummary.aide += curr.profile.specialNeeds.isAideRequired ? 1 : 0;
    newSummary.heartProgram += curr.profile.specialNeeds.heartProgram ? 1 : 0;
    newSummary.fosterProgram += curr.profile.specialNeeds.fosterProgram ? 1 : 0;
    newSummary.doorToDoorStop += curr.profile.specialNeeds.doorToDoorStop ? 1 : 0;
    newSummary.cornerStop += curr.profile.specialNeeds.cornerStop ? 1 : 0;
    newSummary.attendant += curr.profile.specialNeeds.isAttendantRequired ? 1 : 0;
    return newSummary;
  }, initialEquipmentSummary);
  return result;
};
