import { createReducer, on } from '@ngrx/store';

import { WpError } from '@rootTypes';
import {
  changePasswordFailed,
  changePasswordRequested,
  changePasswordSuccess,
  initChangePasswordState,
  initUserProfileState,
} from '../actions';

export interface ChangePasswordState {
  isLoading: boolean;
  isChangedSuccessfully: boolean;
  error: WpError;
}

export const getChangePasswordInitialState = (): ChangePasswordState => {
  return {
    isLoading: false,
    isChangedSuccessfully: false,
    error: undefined,
  };
};

export const changePasswordReducer = createReducer(
  getChangePasswordInitialState(),
  on(initUserProfileState, initChangePasswordState, getChangePasswordInitialState),
  on(changePasswordRequested, () => {
    return {
      isLoading: true,
      isChangedSuccessfully: false,
      error: undefined,
    };
  }),
  on(changePasswordSuccess, () => {
    return {
      isLoading: false,
      isChangedSuccessfully: true,
      error: undefined,
    };
  }),
  on(changePasswordFailed, (state, action) => {
    return {
      isLoading: false,
      isChangedSuccessfully: false,
      error: action.error,
    };
  }),
);
