<mat-form-field>
  <mat-label>{{label}}</mat-label>
  <mat-select [formControl]="control" multiple>
    <mat-select-trigger>
      {{control.value?.length + ' items selected'}}
    </mat-select-trigger>
    <ng-container *ngFor="let option of options">
      <mat-option [value]="option.value">{{option.displayLabel}}</mat-option>
    </ng-container>
  </mat-select>
  <mat-error *ngIf="control.invalid && control.hasError('required')">
    Required field
  </mat-error>
  <mat-error
    *ngIf="control.invalid && control.hasError(notSelectedFromTheListError)"
  >
    Please select value from the list
  </mat-error>
  <mat-error
    *ngIf="customErrorMessageKey && control.invalid && control.hasError(customErrorMessageKey)"
  >
    {{control.errors[customErrorMessageKey]}}
  </mat-error>
</mat-form-field>
