import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-title-three',
  templateUrl: './title-three.component.html',
  styleUrls: ['./title-three.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleThreeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
