import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import * as fromActions from '../actions/vehicle-inspections.actions';
import * as fromSelectors from '../selectors/vehicle-inspections.selectors';
import { VehicleInspectionListName, VehicleInspectionQuickFilter } from '../../types';
import { VehicleInspectionListRouterService } from '../../../../router/portal-routes/vehicle-inspections';
import { GetMissingVehicleInspectionsRequest } from '../../../../api/endpoints/get-missing-vehicle-inspections';
import { driverDetailsDestroyed } from '../../../../features/drivers/store';
import { VehicleInspectionApiService } from '../../services';

@Injectable()
export class VehicleInspectionsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private api: VehicleInspectionApiService,
    private mainListRouter: VehicleInspectionListRouterService,
  ) {}

  public initList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initVehicleInspectionList, fromActions.initDriverInspectionList),
      mergeMap(({ listName }) => {
        return this.store.select(fromSelectors.getListItems(listName)).pipe(
          take(1),
          filter((items) => !items?.length),
          map(() => fromActions.loadVehicleInspectionListRequested({ listName })),
        );
      }),
    ),
  );

  public initMainList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initVehicleInspectionMainList),
      withLatestFrom(this.store.select(fromSelectors.getListParams(VehicleInspectionListName.MAIN))),
      map(([, params]) => {
        if (params.quickFilter === VehicleInspectionQuickFilter.MISSING_INSPECTIONS) {
          return fromActions.loadMissingInspectionsRequested();
        }
        return fromActions.loadVehicleInspectionListRequested({ listName: VehicleInspectionListName.MAIN });
      }),
    ),
  );

  public listChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.vehicleInspectionsSingleStatusChanged,
        fromActions.vehicleInspectionsPageChanged,
        fromActions.vehicleInspectionsPageSizeChanged,
        fromActions.vehicleInspectionsDateRangeChanged,
      ),
      mergeMap(({ listName }) => {
        return this.store.select(fromSelectors.getListParams(listName)).pipe(
          take(1),
          filter((params) => params.quickFilter !== VehicleInspectionQuickFilter.MISSING_INSPECTIONS),
          map((params) => fromActions.loadVehicleInspectionListRequested({ listName })),
        );
      }),
    ),
  );

  public mainListFiltersChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromActions.addVehicleFilter,
          fromActions.removeVehicleFilter,
          fromActions.addDriverFilter,
          fromActions.removeDriverFilter,
          fromActions.clearAllFilters,
          fromActions.setAdvancedFilters,
          fromActions.removeDriverReviewStatusFilter,
          fromActions.removeMechanicAssessmentStatusFilter,
          fromActions.removeInspectionStatusFilter,
          fromActions.removeMechanicAssignmentStatusFilter,
          fromActions.removeAssignedMechanicFilter,
          fromActions.inspectionQuickFilterChanged,
          fromActions.removeInspectionQuickFilter,
        ),
        withLatestFrom(this.store.select(fromSelectors.getListParams(VehicleInspectionListName.MAIN))),
        filter(([, params]) => params.quickFilter !== VehicleInspectionQuickFilter.MISSING_INSPECTIONS),
        tap(([, params]) => {
          this.store.dispatch(
            fromActions.loadVehicleInspectionListRequested({ listName: VehicleInspectionListName.MAIN }),
          );
          this.mainListRouter.navigate(params, 'merge', true);
        }),
      ),
    {
      dispatch: false,
    },
  );

  public loadMissingInspectionsTrigger$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromActions.vehicleInspectionsDateRangeChanged,
          fromActions.inspectionQuickFilterChanged,
          fromActions.removeInspectionQuickFilter,
        ),
        withLatestFrom(this.store.select(fromSelectors.getListParams(VehicleInspectionListName.MAIN))),
        filter(([, params]) => params.quickFilter === VehicleInspectionQuickFilter.MISSING_INSPECTIONS),
        tap(([, params]) => {
          this.store.dispatch(fromActions.loadMissingInspectionsRequested());
          this.mainListRouter.navigate(params);
        }),
      ),
    {
      dispatch: false,
    },
  );

  public onLoadMissingInspectionsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadMissingInspectionsRequested),
      withLatestFrom(this.store.select(fromSelectors.getListParams(VehicleInspectionListName.MAIN))),
      mergeMap(([, { dateRange }]) => {
        const request: GetMissingVehicleInspectionsRequest = {
          dateRange: { ...dateRange },
        };
        return this.api.getMissingVehicleInspections(request).pipe(
          map((res) => fromActions.loadMissingInspectionsSuccess({ data: res.missingVehicleInspections || [] })),
          catchError((originalError) => {
            return of(
              fromActions.loadMissingInspectionsFailed({
                error: {
                  text: 'Failed to load missing inspections',
                  originalError,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  public updateUrlOnMainListChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromActions.vehicleInspectionsPageChanged,
          fromActions.vehicleInspectionsPageSizeChanged,
          fromActions.vehicleInspectionsDateRangeChanged,
        ),
        filter(({ listName }) => listName === VehicleInspectionListName.MAIN),
        withLatestFrom(this.store.select(fromSelectors.getListParams(VehicleInspectionListName.MAIN))),
        tap(([, params]) => {
          this.mainListRouter.navigate(params);
        }),
      ),
    {
      dispatch: false,
    },
  );

  public loadList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadVehicleInspectionListRequested),
      switchMap(({ listName }) => {
        return this.store.select(fromSelectors.getListParams(listName)).pipe(
          take(1),
          mergeMap((params) => {
            return this.api.getVehicleInspectionList(params).pipe(
              map((response) => fromActions.loadVehicleInspectionListSuccess({ listName, response })),
              catchError((originalError) => {
                return of(
                  fromActions.loadVehicleInspectionListFailed({
                    listName,
                    error: {
                      text: 'Failed to load vehicle inspections',
                      originalError,
                    },
                  }),
                );
              }),
            );
          }),
        );
      }),
    ),
  );

  public onLoadListSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.loadVehicleInspectionListSuccess),
        tap(({ response }) => {
          response.results.forEach(({ vehicleInspectionId }) => {
            this.store.dispatch(fromActions.getVehicleInspectionRequested({ vehicleInspectionId }));
          });
        }),
      ),
    { dispatch: false },
  );

  public onReloadItemDetailsOnAssignmentUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.reloadVehicleInspectionDetailsOnAssignmentUpdate),
        tap(({ inspectionIds }) => {
          inspectionIds.forEach((vehicleInspectionId) => {
            this.store.dispatch(fromActions.getVehicleInspectionRequested({ vehicleInspectionId }));
          });
        }),
      ),
    { dispatch: false },
  );

  public loadListItemDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getVehicleInspectionRequested),
      mergeMap(({ vehicleInspectionId }) => {
        return this.api.getVehicleInspectionDetails(vehicleInspectionId).pipe(
          map((data) => {
            return fromActions.getVehicleInspectionSuccess({ vehicleInspectionId, data });
          }),
          catchError((originalError) => {
            return of(
              fromActions.getVehicleInspectionFailed({
                vehicleInspectionId,
                error: {
                  text: 'Failed to load inspection details',
                  originalError,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  public onDriverDetailsClosed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(driverDetailsDestroyed),
      withLatestFrom(this.store.select(fromSelectors.getSelectedMissingInspectionDriverId)),
      filter(([, driverId]) => !!driverId),
      map(() => fromActions.missingInspectionDriverDetailsClosed()),
    ),
  );
}
