import { createSelector } from '@ngrx/store';

import { currentUser, getUserProfileState } from './auth.selectors';

export const currentUserFirstName = createSelector(currentUser, (user) => user?.name.first);

export const currentUserLastName = createSelector(currentUser, (user) => user?.name.last);

export const currentUserEmail = createSelector(currentUser, (user) => user?.email);

export const currentUserPhone = createSelector(currentUser, (user) => user?.phoneNumber);

export const currentUserInitials = createSelector(currentUser, (user) => {
  if (!user) {
    return '';
  }
  let initials = '';
  if (user.name.first) {
    initials = user.name.first[0].toUpperCase();
  }
  if (user.name.last) {
    initials += user.name.last[0].toUpperCase();
  }
  return initials;
});

const currentUserPhotoState = createSelector(getUserProfileState, (state) => state.userPhoto);

export const currentUserPhotoBase64 = createSelector(currentUserPhotoState, (state) => state.imageBase64);

export const isCurrentUserPhotoLoadingOrUpdating = createSelector(
  currentUserPhotoState,
  (state) => state.isLoading || state.isUpdating,
);
