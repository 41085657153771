import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StackLayoutComponent } from './stack-layout/stack-layout.component';
import { StackLayoutItemComponent } from './stack-layout-item/stack-layout-item.component';

@NgModule({
  declarations: [StackLayoutComponent, StackLayoutItemComponent],
  imports: [CommonModule],
  exports: [StackLayoutComponent, StackLayoutItemComponent],
})
export class StackLayoutModule {}
