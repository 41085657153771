import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { State } from '@rootStore';
import { Store } from '@ngrx/store';
import * as fromAuthActions from '../auth/store/actions';
import * as fromAuthSelectors from '../auth/store/selectors';
import { filter, take, switchMap, map } from 'rxjs/operators';
import { commonRoutes } from '@router';
import { loadAccount, isSelectAccountRequired } from '../auth/utils';
import { userProfileRequested } from '../auth/store/actions';
import { isCurrentUserProfileLoaded } from '../auth/store/selectors';

@Injectable({
  providedIn: 'root',
})
export class HomeGuardService {
  constructor(
    private store: Store<State>,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    this.store.dispatch(fromAuthActions.homeGuardCheckAccountStart());
    this.store.dispatch(fromAuthActions.checkAuthRequested());
    return this.store.select(fromAuthSelectors.authStatus).pipe(
      filter((auth) => !auth.isLoading),
      take(1),
      switchMap((auth) => {
        if (!auth.isUserAuthenticated) {
          this.store.dispatch(fromAuthActions.homeGuardCheckAccountEnd());
          return of(this.getRedirectUrlTree(state, commonRoutes.login.url));
        }
        return this.isAccountSelected().pipe(
          switchMap((selected) => {
            if (selected) {
              return this.loadUserProfile().pipe(
                take(1),
                map(() => {
                  this.store.dispatch(fromAuthActions.homeGuardCheckAccountEnd());
                  return true;
                }),
              );
            }
            // account selection is required
            this.store.dispatch(fromAuthActions.homeGuardCheckAccountEnd());
            return of(this.getRedirectUrlTree(state, commonRoutes.selectAccount.url));
          }),
        );
      }),
    );
  }

  private isAccountSelected(): Observable<boolean | UrlTree> {
    return loadAccount(this.store, fromAuthActions.homeGuardAccountRequested).pipe(
      take(1),
      switchMap((account) => {
        return isSelectAccountRequired(
          account,
          this.store,
          fromAuthActions.homeGuardAccountsRequested,
          fromAuthActions.homeGuardSetAccountRequested,
        ).pipe(
          take(1),
          map((required) => !required),
        );
      }),
    );
  }

  private loadUserProfile(): Observable<void> {
    this.store.dispatch(userProfileRequested());
    return this.store.select(isCurrentUserProfileLoaded).pipe(
      filter((successfullyLoaded) => successfullyLoaded === true),
      take(1),
      map(() => undefined),
    );
  }

  private getRedirectUrlTree(state: RouterStateSnapshot, destinationPath: string): UrlTree {
    if (state.url === commonRoutes.home.url) {
      return this.router.parseUrl(destinationPath);
    }
    const relayState = encodeURIComponent(state.url);
    const pathWithRelayState = `${destinationPath}?RelayState=${relayState}`;
    return this.router.parseUrl(pathWithRelayState);
  }
}
