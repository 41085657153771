<div class="three-dots">
  <span
    class="dot"
    [ngStyle]="{ width: sizeStr, height: sizeStr, backgroundColor: color, marginRight: spaceBetweenStr }"
  ></span>
  <span
    class="dot"
    [ngStyle]="{ width: sizeStr, height: sizeStr, backgroundColor: color, marginRight: spaceBetweenStr }"
  ></span>
  <span
    class="dot"
    [ngStyle]="{ width: sizeStr, height: sizeStr, backgroundColor: color, marginRight: spaceBetweenStr }"
  ></span>
</div>
