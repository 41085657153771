import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getRequestedByName, getRequestedByRole, getRequestedDisplayDateTime } from './selectors';
import { Injectable } from '@angular/core';

@Injectable()
export class ActivityTimesheetEntryDataFacade {
  constructor(private store: Store) {}

  public getRequestedByName$(activityId: string): Observable<string> {
    return this.store.select(getRequestedByName(activityId));
  }

  public getRequestedByRole$(activityId: string): Observable<string> {
    return this.store.select(getRequestedByRole(activityId));
  }

  public getRequestedDisplayDateTime$(activityId: string): Observable<string> {
    return this.store.select(getRequestedDisplayDateTime(activityId));
  }
}
