import { Injectable } from '@angular/core';
import * as fromTypes from '.';
import { Observable, BehaviorSubject, Subscription, of, timer, combineLatest } from 'rxjs';
import { map, distinctUntilChanged, tap, switchMap, debounceTime, retry, catchError } from 'rxjs/operators';
import { CrossEntitySearchApiService } from './cross-entity-search.api.service';
import { WpError } from '.';

const pageSize = 10;

@Injectable()
export class CrossEntitySearchStoreService implements fromTypes.CrossEntitySearchStore {
  private state$: BehaviorSubject<fromTypes.CrossEntitySearchState> =
    new BehaviorSubject<fromTypes.CrossEntitySearchState>(fromTypes.initialCrossEntitySearchState);

  private mainSubscription: Subscription;

  constructor(private api: CrossEntitySearchApiService) {
    this.setMainSubscription();
  }
  error$(): Observable<any> {
    return this.state$.asObservable().pipe(
      map((state) => state.results.error),
      distinctUntilChanged(),
    );
  }

  hasNext$(): Observable<boolean> {
    return this.state$
      .asObservable()
      .pipe(
        map(
          (state) => !!(state.searchTerm && state.searchTerm.length) && !state.results.pageLoading && !!state.nextSkip,
        ),
      );
  }

  empty$(): Observable<boolean> {
    return this.state$.asObservable().pipe(
      map((state) => {
        return (
          !!(state.searchTerm && state.searchTerm.length) &&
          !state.results.pageLoading &&
          state.results.items.length === 0
        );
      }),
    );
  }

  dispose(): void {
    if (this.mainSubscription) {
      this.mainSubscription.unsubscribe();
    }
  }

  hits$(): Observable<fromTypes.PortalEntitySearchHit[]> {
    return this.state$.asObservable().pipe(
      map((state) => {
        return state.results.hits && state.results.hits.length ? state.results.hits : undefined;
      }),
      distinctUntilChanged(),
    );
  }

  items$(): Observable<fromTypes.PortalEntity[]> {
    return this.state$.asObservable().pipe(
      map((state) => state.results.items),
      distinctUntilChanged(),
    );
  }

  dropdownOpen$(): Observable<boolean> {
    return this.state$.asObservable().pipe(
      map((state) => state.isDropdownOpen),
      distinctUntilChanged(),
    );
  }

  closeDropdown(): void {
    this.state$.next({
      ...this.state$.value,
      isDropdownOpen: false,
    });
  }

  loadNextPage(): void {
    const currentState = this.state$.value;
    if (currentState.nextSkip) {
      const { currentPage } = currentState;
      this.state$.next({
        ...currentState,
        currentPage: currentPage + 1,
      });
    }
  }

  openDropdown(): void {
    this.state$.next({
      ...this.state$.value,
      isDropdownOpen: true,
    });
  }

  pageLoading(): Observable<boolean> {
    return this.state$.asObservable().pipe(
      map((state) => state.results.pageLoading),
      distinctUntilChanged(),
    );
  }

  search(searchTerm: string): void {
    this.state$.next({
      ...this.state$.value,
      currentPage: 0,
      nextSkip: 0,
      isDropdownOpen: true,
      results: {
        ...this.state$.value.results,
        pageLoading: true,
        items: [],
        error: null,
      },
      searchTerm,
    });
  }

  /**
   * Will search by @param searchByTypes only.
   * If omitted, will search across all entities
   */
  setSearchByTypes(searchByTypes: fromTypes.PortalEntityType[] = []): void {
    this.state$.next({
      ...this.state$.value,
      searchByTypes: [...searchByTypes],
      currentPage: 0,
      nextSkip: 0,
      results: {
        ...this.state$.value.results,
        pageLoading: true,
        items: [],
        error: null,
      },
    });
  }

  private setMainSubscription(): void {
    const searchTermChanges$ = this.state$.asObservable().pipe(
      map((state) => state.searchTerm),
      distinctUntilChanged(),
    );
    const searchByChanges$ = this.state$.asObservable().pipe(
      map((state) => state.searchByTypes),
      distinctUntilChanged(),
    );
    const pageChanges$ = this.state$.asObservable().pipe(
      map((state) => state.currentPage),
      distinctUntilChanged(),
    );

    this.mainSubscription = combineLatest([searchTermChanges$, searchByChanges$])
      .pipe(
        debounceTime(200),
        switchMap(([searchTerm]) => {
          return pageChanges$.pipe(
            tap(() => this.emitPageLoading(true)),
            switchMap((currentPage) => this.doSearch()),
            retry(1),
            catchError((err, caught) => {
              const error: WpError = {
                originalError: err,
                text: 'Failed to load search results',
                retryFn: () => this.doSearch(),
              };
              return of({ searchTerm, results: [], nextSkip: 0, error });
            }),
          );
        }),
      )
      .subscribe((res) => this.onSearchResult(res));
  }

  private emitPageLoading(isLoading: boolean): void {
    this.state$.next({
      emitLine: 154,
      ...this.state$.value,
      results: {
        ...this.state$.value.results,
        pageLoading: isLoading,
      },
    });
  }

  private doSearch(): Observable<fromTypes.SearchResponseWithSearhTerm> {
    const { searchTerm, nextSkip, searchByTypes } = this.state$.value;
    // no more items
    if (nextSkip === null || searchTerm === null || searchTerm.length === 0) {
      return timer(200).pipe(switchMap(() => of({ nextSkip: null, results: [], searchTerm })));
    }
    return this.api
      .entitySearch(searchTerm, nextSkip, pageSize, searchByTypes)
      .pipe(map((res) => ({ ...res, searchTerm })));
  }

  private onSearchResult(res: fromTypes.SearchResponseWithSearhTerm | null): void {
    if (res) {
      const { nextSkip, results, searchTerm, hits, error } = res;
      const prevState = this.state$.value;
      if (prevState.searchTerm !== searchTerm) {
        return;
      }
      if (error) {
        console.log(error);
        this.state$.next({
          emitLine: 182,
          ...prevState,
          results: {
            ...prevState.results,
            items: [],
            pageLoading: false,
            error,
          },
        });
      } else {
        this.state$.next({
          emitLine: 182,
          ...prevState,
          nextSkip,
          results: {
            ...prevState.results,
            items: [...prevState.results.items, ...results] as fromTypes.PortalEntity[],
            hits,
            pageLoading: false,
            error: null,
          },
        });
      }
    }
  }
}
