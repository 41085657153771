import { Injectable } from '@angular/core';

import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';
import { deserializeBaseParamsFromUrl } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';
import { serializeBaseParamsToUrl } from '@rootTypes/utils/list-store-utils/serialize-base-params-to-url';
import { getRoutingRequest } from '@rootTypes/utils/router/get-routing-request';
import { transportationRequestsPath } from './transportation-requests-path';
import { TransportationRequestListParams } from '../../../features/transportation-requests/types/transportation-request-list';
import { deserializeTransportationRequestStatusFilter } from '../../../features/transportation-requests/utils/deserialize-transportation-request-status-filter';
import { deserializeTransportationRequestProgramFilter } from '../../../features/transportation-requests/utils/deserialize-transportation-request-program-filter';
import { createInitialTransportationRequestListState } from '../../../features/transportation-requests/utils/create-initial-transportation-request-list-state';

const queryPrefix = 'tr';

export type TransportationRequestRouteData = Partial<TransportationRequestListParams>;

export const transportationRequestListRoute: PortalRoute<TransportationRequestRouteData> = {
  path: '',

  isOnRoute: (state: RouterStateUrl) => {
    return state && state.url && state.url.startsWith(`/${transportationRequestsPath}`);
  },

  parse: (state: RouterStateUrl): TransportationRequestRouteData => {
    const query = state?.queryParams || {};
    const data = deserializeBaseParamsFromUrl<TransportationRequestListParams>(query, queryPrefix, [
      deserializeTransportationRequestStatusFilter,
      deserializeTransportationRequestProgramFilter,
    ]);
    if (!data.filters) {
      data.filters = [];
    }
    return data;
  },

  request: (
    data?: TransportationRequestRouteData,
    queryParamStrategy?: QueryParamsHandlingStrategy,
    replaceUrl?: boolean,
  ): NavigationRequest => {
    const path = ['/', transportationRequestsPath];
    const query = data
      ? serializeBaseParamsToUrl(data, createInitialTransportationRequestListState().params, queryPrefix)
      : {};
    return getRoutingRequest(path, query, queryParamStrategy, replaceUrl);
  },

  getHref(data?: TransportationRequestRouteData): string {
    return `/${transportationRequestsPath}`;
  },
};

@Injectable({
  providedIn: 'root',
})
export class TransportationRequestListRouterService extends PortalRouterService<TransportationRequestRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route = transportationRequestListRoute;
}
