import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ApiService } from '../../../../api/api.service';
import {
  getCatalogDisplayInfoFailed,
  getCatalogDisplayInfoRequested,
  getCatalogDisplayInfoSuccess,
} from '../actions/catalog-display-info.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { WpError } from '@rootTypes';

@Injectable()
export class CatalogDisplayInfoEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}

  public getCatalogDisplayInfoRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCatalogDisplayInfoRequested),
      mergeMap(({ catalogId }) => {
        return this.api.getCatalogDisplayInfo({ catalogId }).pipe(
          map((response) => {
            return getCatalogDisplayInfoSuccess({ catalogId, response });
          }),
          catchError((originalError) => {
            const error: WpError = {
              originalError,
              text: 'Failed to get catalog display info',
            };
            return of(getCatalogDisplayInfoFailed({ catalogId, error }));
          }),
        );
      }),
    ),
  );
}
