import { getFirstNSymbolsFromString } from '@rootTypes/utils';
import { StudentChangeRequestSummary } from '@apiEntities';

export const getRequestedByUserDisplayFromChangeRequestSummary = (summary?: StudentChangeRequestSummary): string => {
  const requested = summary?.requested;
  if (!requested) {
    return '--';
  }
  return `${requested.userFirstName} ${getFirstNSymbolsFromString(requested.userLastName)} - ${requested.userRole}`;
};
