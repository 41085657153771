import { createReducer, on } from '@ngrx/store';
import * as fromTypes from '../../types';
import * as fromActions from '../actions/campus-list.actions';

export type AllCampusListsState = {
  [key in fromTypes.CampusListName]?: CampusListState;
};

export interface CampusListState {
  page: {
    listCampusIds: fromTypes.PortalEntity[];
    totalFound: number;
    isLoading: boolean;
    listError: fromTypes.WpError;
  };
  filters: fromTypes.CampusListFilter[];
  staticFilters: fromTypes.CampusListFilter[];
  selectedCampusId: string;
  selectedCampusStatus: fromTypes.EntityStatus;
  currentSearchQuery: string;
  pagination: {
    currentPage: number;
    pageSize: number;
  };
}

const getInitialCampusListState = (): CampusListState => {
  return {
    page: {
      listCampusIds: [],
      totalFound: undefined,
      isLoading: false,
      listError: undefined,
    },
    filters: [],
    staticFilters: [],
    selectedCampusId: undefined,
    currentSearchQuery: undefined,
    selectedCampusStatus: fromTypes.EntityStatus.ACTIVE,
    pagination: {
      currentPage: 0,
      pageSize: fromTypes.ui.DEFAULT_PAGE_SIZE,
    },
  };
};

const getInitialAllCampusListsState = (): AllCampusListsState => {
  const result: AllCampusListsState = {};
  fromTypes.campusLists.forEach((listName) => {
    result[listName] = getInitialCampusListState();
  });
  return result;
};

export const campusListReducer = createReducer<AllCampusListsState>(
  getInitialAllCampusListsState(),
  on(fromTypes.cleanUpStore, getInitialAllCampusListsState),
  // set campus list state
  on(fromActions.setCampusListState, (allState, action) => {
    const { listName, snapshot } = action;
    const { searchQuery, listStatus, pageSize, currentPage } = snapshot;
    const state = allState[listName];
    const res: AllCampusListsState = {
      ...allState,
      [listName]: {
        ...state,
        currentSearchQuery: searchQuery,
        selectedCampusStatus: listStatus,
        pagination: {
          ...state.pagination,
          pageSize,
          currentPage,
        },
        page: {
          ...state.page,
          totalFound: undefined,
        },
      },
    };
    return res;
  }),
  // select campus
  on(fromActions.selectedCampusId, (allState, action) => {
    const { listName, campusId } = action;
    const state = allState[listName];
    const res: AllCampusListsState = {
      ...allState,
      [listName]: {
        ...state,
        selectedCampusId: campusId,
      },
    };
    return res;
  }),
  // clean campus list state
  on(fromActions.cleanCampusListState, (allState, action) => {
    const { listName } = action;
    const initialListState = getInitialAllCampusListsState()[listName];
    const res: AllCampusListsState = {
      ...allState,
      [listName]: initialListState,
    };
    return res;
  }),
  // list filter controls
  on(fromActions.campusSearchInputChanged, (allState, action) => {
    const { listName, input } = action;
    const state = allState[listName];
    const res: AllCampusListsState = {
      ...allState,
      [listName]: {
        ...state,
        currentSearchQuery: input,
        pagination: {
          ...state.pagination,
          currentPage: 0,
        },
        page: {
          ...state.page,
          totalFound: undefined,
        },
      },
    };
    return res;
  }),
  // entity filters
  on(fromActions.filtersChanged, (allState, action) => {
    const { listName, filters } = action;
    const state = allState[listName];
    const res: AllCampusListsState = {
      ...allState,
      [listName]: {
        ...state,
        filters: [...(filters || [])],
        pagination: {
          ...state.pagination,
          currentPage: 0,
        },
        page: {
          ...state.page,
          totalFound: undefined,
        },
      },
    };
    return res;
  }),
  on(fromActions.staticFiltersChanged, (allState, action) => {
    const { listName, filters } = action;
    const state = allState[listName];
    const oldFilters = state.staticFilters;
    let newState: CampusListState;
    if (fromTypes.utils.arraysEqual(oldFilters, filters)) {
      newState = {
        ...state,
        page: {
          ...state.page,
          totalFound: undefined,
        },
      };
    } else {
      newState = {
        ...getInitialCampusListState(),
        staticFilters: filters,
        page: {
          ...state.page,
          totalFound: undefined,
        },
      };
    }
    const res: AllCampusListsState = {
      ...allState,
      [listName]: newState,
    };
    return res;
  }),
  on(fromActions.campusStatusChanged, (allState, action) => {
    const { status, listName } = action;
    const state = allState[listName];
    const res: AllCampusListsState = {
      ...allState,
      [listName]: {
        ...state,
        selectedCampusStatus: status,
        pagination: {
          ...state.pagination,
          currentPage: 0,
        },
        page: {
          ...state.page,
          totalFound: undefined,
        },
      },
    };
    return res;
  }),
  // pagination
  on(fromActions.currentPageChanged, (allState, action) => {
    const { listName, page } = action;
    const state = allState[listName];
    const res: AllCampusListsState = {
      ...allState,
      [listName]: {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: page,
        },
      },
    };
    return res;
  }),
  on(fromActions.pageSizeChanged, (allState, action) => {
    const { pageSize, listName } = action;
    const state = allState[listName];
    const res: AllCampusListsState = {
      ...allState,
      [listName]: {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: 0,
          pageSize,
        },
      },
    };
    return res;
  }),
  // load list data
  on(fromActions.campusListLoadRequested, (allState, action) => {
    const { listName } = action;
    const state = allState[listName];
    const res: AllCampusListsState = {
      ...allState,
      [listName]: {
        ...state,
        page: {
          ...state.page,
          listCampusIds: [],
          isLoading: true,
        },
      },
    };
    return res;
  }),
  on(fromActions.campusListLoadSuccess, (allState, action) => {
    const { listName, campusIds, totalFound } = action;
    const state = allState[listName];
    const res: AllCampusListsState = {
      ...allState,
      [listName]: {
        ...state,
        page: {
          ...state.page,
          listCampusIds: campusIds,
          totalFound,
          isLoading: false,
        },
      },
    };
    return res;
  }),
  on(fromActions.campusListLoadFailed, (allState, action) => {
    const { listName, error } = action;
    const state = allState[listName];
    const res: AllCampusListsState = {
      ...allState,
      [listName]: {
        ...state,
        page: {
          ...state.page,
          listCampusIds: [],
          totalFound: 0,
          listError: error,
          isLoading: false,
        },
      },
    };
    return res;
  }),
);
