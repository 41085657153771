<div class="page-container" *ngIf="isOpened$ | async">
  <div class="popup" (click)="$event.stopPropagation()">
    <div class="header">New version of the app is available</div>
    <div>
      Please, refresh the page to apply the new version or delay the update for
      1 hour.
    </div>
    <div class="buttons">
      <wp-btn-secondary
        [width]="'132px'"
        [height]="'36px'"
        [slim]="true"
        (clicked)="onDelay()"
        >Delay
      </wp-btn-secondary>
      <wp-btn-primary
        class="apply-btn"
        [width]="'132px'"
        [height]="'36px'"
        [slim]="true"
        (clicked)="onApply()"
        >Refresh
      </wp-btn-primary>
    </div>
  </div>
</div>
