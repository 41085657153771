<div
  class="vertical-line-container"
  [ngStyle]="{height: containerHeight, margin: margin}"
>
  <div
    class="vertical-line"
    [ngStyle]="{height: height}"
    [ngClass]="{grey: color === 'grey', haze: color === 'haze'}"
  ></div>
</div>
