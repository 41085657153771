import { TripListVehicleTypeFilter } from '../types/trip-list';

export const getTripListVehicleTypeFilter = (vehicleType: string): TripListVehicleTypeFilter => {
  return {
    id: `vehicle-type,${vehicleType}`,
    type: 'vehicle-type',
    payload: vehicleType,
  };
};

export const deserializeVehicleTypeFilter = (s: string): TripListVehicleTypeFilter | undefined => {
  if (s && s.startsWith('vehicle-type')) {
    const value = s.split(',')[1];
    return getTripListVehicleTypeFilter(value);
  }
  return undefined;
};
