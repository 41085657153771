import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DpMonthComponent } from './dp-month/dp-month.component';
import { DpDayComponent } from './dp-day/dp-day.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DateRangePickerComponent, DpMonthComponent, DpDayComponent],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [DateRangePickerComponent],
})
export class DateRangePickerModule {}
