import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface ToggleState {
  isOn: boolean;
  isDisabled: boolean;
}

const initialToggleState: ToggleState = {
  isOn: false,
  isDisabled: false,
};

@Component({
  selector: 'wp-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent implements OnInit, OnChanges {
  @Input() public selected: boolean;
  @Input() public disabled: boolean;
  @Output() public selectedChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isOn$: Observable<boolean>;
  public isDisabled$: Observable<boolean>;
  private state$: BehaviorSubject<ToggleState> = new BehaviorSubject<ToggleState>({ ...initialToggleState });

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected) {
      this.state$.next({
        ...this.state$.value,
        isOn: changes.selected.currentValue,
      });
    }
    if (changes.disabled) {
      this.state$.next({
        ...this.state$.value,
        isDisabled: changes.disabled.currentValue,
      });
    }
  }

  ngOnInit(): void {
    this.isOn$ = this.state$.pipe(map((state) => state.isOn));
    this.isDisabled$ = this.state$.pipe(map((state) => state.isDisabled));
  }

  public onToggleClick(): void {
    this.state$.next({
      ...this.state$.value,
      isOn: !this.state$.value.isOn,
    });
    this.selectedChanged.emit(this.state$.value.isOn);
  }
}
