import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { changePasswordRequested, changePasswordSuccess, changePasswordFailed } from '../actions';
import { AuthApiService } from '../../../auth/services/auth-api.service';
import { go } from '@rootStore';
import { userProfileRoute } from 'src/app/router/portal-routes/home';

@Injectable()
export class ChangePasswordEffects {
  constructor(
    private actions$: Actions,
    private auth: AuthApiService,
  ) {}

  public changePasswordRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(changePasswordRequested),
      switchMap(({ oldPassword, newPassword }) => {
        return this.auth.changePassword(oldPassword, newPassword).pipe(
          map(() => changePasswordSuccess()),
          catchError((error) => {
            let text: string;
            if (error?.code === 'auth/wrong-password') {
              text = 'Current password is not correct.';
            } else {
              text = 'Unable to change password.';
            }
            return of(
              changePasswordFailed({
                error: {
                  text,
                  originalError: error,
                },
              }),
            );
          }),
        );
      }),
    );
  });

  public changePasswordSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(changePasswordSuccess),
      map(() => {
        return go(userProfileRoute.request());
      }),
    );
  });
}
