import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupableModule } from './popupable/popupable.module';
import { HttpClientModule } from '@angular/common/http';
import { NewAppVersionAvailablePopupComponent } from './new-app-version-available-popup/new-app-version-available-popup.component';
import { ButtonsModule } from '../shared/buttons/buttons.module';

@NgModule({
  declarations: [NewAppVersionAvailablePopupComponent],
  imports: [CommonModule, PopupableModule, HttpClientModule, ButtonsModule],
  exports: [PopupableModule, NewAppVersionAvailablePopupComponent],
})
export class CoreModule {}
