export enum StudentListStatus {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DEFAULT = 'ALL',
  REQUIRES_VERIFICATION = 'REQUIRES_VERIFICATION',
  NEW = 'NEW',
  HOLD = 'HOLD',
  NO_STATUS = 'NO_STATUS',
}
