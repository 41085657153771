<svg
  xmlns="http://www.w3.org/2000/svg"
  width="16"
  height="20"
  viewBox="0 0 16 20"
>
  <g fill="none" fill-rule="evenodd" stroke="#959597">
    <path
      stroke-width="1.6"
      d="M7.497 19.15l7.703-.063V6.474l-.821-1.215L9.978.8H.8v18.35h6.697z"
    />
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M10 1.008L10 6 16 6"
    />
    <path stroke-linejoin="round" d="M4 9.5h8M4 12.5h8M4 15.5h8" />
  </g>
</svg>
