<div class="wp-side-bar-dropdown__wrap">
  <div class="wp-side-bar-dropdown">
    <div
      class="wp-side-bar-dropdown__trigger"
      (click)="$event.stopPropagation(); onTriggerClick();"
    >
      <div class="wp-side-bar-dropdown__icon">
        <ng-content select="wp-side-bar-icon-wrap"></ng-content>
      </div>
      <div class="wp-side-bar-dropdown__text">
        <ng-content select="wp-side-bar-text"></ng-content>
      </div>
    </div>
    <div
      *ngIf="isOpen$ | async"
      class="wp-side-bar-dropdown__pane-wrap"
      wpClickOutside
      (clickedOutside)="onOutsideClick()"
    >
      <div class="wp-side-bar-dropdown__pane" (click)="onTriggerClick()">
        <ng-content select="wp-side-bar-dropdown-option"></ng-content>
      </div>
    </div>
  </div>
</div>
