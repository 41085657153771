export const getHtmlBlockHeight = (htmlString: string): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const node: HTMLDivElement = document.createElement('div');
    node.style.opacity = '0';
    const id = 'get-height-' + Date.now();
    node.id = id;
    node.innerHTML = htmlString;
    document.body.appendChild(node);
    setTimeout(() => {
      const el = document.getElementById(id);
      const height = el.offsetHeight;
      document.body.removeChild(node);
      resolve(height + 'px');
    });
  });
};
