import { Injectable } from '@angular/core';
import { SmartDateRange } from '../../models';

@Injectable()
export class SmartInputDateRangeService {
  private model: SmartDateRange;
  constructor() {}

  public initModel(model: SmartDateRange): void {
    this.model = model;
  }

  public getModel(): SmartDateRange {
    return this.model;
  }

  public onDestroy(): void {
    this.model.onComponentDestroy();
  }
}
