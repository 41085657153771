import { Pipe, PipeTransform } from '@angular/core';
import * as fromTypes from '../../types';

@Pipe({
  name: 'vehicleLicenseName',
})
export class VehicleLicenseNamePipe implements PipeTransform {
  transform(value: fromTypes.vehicle.VehicleLicense): string {
    return fromTypes.utils.vehicle.getVehicleLicenseName(value);
  }
}
