<div class="wp-photo-input-sync">
  <div class="label" [ngClass]="{error: isRequiredErrorShown}">{{label}}</div>

  <wp-display-photo
    [width]="previewWidth"
    [height]="previewHeight"
    [isRound]="isRoundPreview"
    [imageBase64]="previewImageBase64"
    [placeholderType]="imagePlaceholder"
  >
  </wp-display-photo>

  <wp-link-btn class="button" (clicked)="open()">{{buttonText}} </wp-link-btn>

  <div *ngIf="isRequiredErrorShown" class="error-message">
    Image is required
  </div>
</div>
