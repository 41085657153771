import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './containers/login/login.component';
import { SelectAccountComponent } from './containers/select-account/select-account.component';
import { SelectAccountGuard } from './guards/select-account.guard';
import { LogoutComponent } from './containers/logout/logout.component';
import { ResetPasswordComponent } from './containers/reset-password/reset-password.component';
import { ResetPasswordEmailSentComponent } from './containers/reset-password-email-sent/reset-password-email-sent.component';
import { commonRoutes, portalRoutes } from '@router';
import { ResetPasswordSetupComponent } from './containers/reset-password-setup/reset-password-setup.component';
import { ResetPasswordSuccessComponent } from './containers/reset-password-success/reset-password-success.component';

const routes: Routes = [
  {
    path: commonRoutes.login.path,
    component: LoginComponent,
  },
  {
    path: commonRoutes.selectAccount.path,
    component: SelectAccountComponent,
    canActivate: [SelectAccountGuard],
  },
  {
    path: commonRoutes.logout.path,
    component: LogoutComponent,
  },
  {
    path: portalRoutes.auth.forgotPasswordRoute.path,
    component: ResetPasswordComponent,
  },
  {
    path: portalRoutes.auth.resetPasswordEmailSentRoute.path,
    component: ResetPasswordEmailSentComponent,
  },
  // This route will be in use once BE to update firebase emails,
  // see PORTAL-65
  {
    path: portalRoutes.auth.resetPasswordSetupRoute.path,
    component: ResetPasswordSetupComponent,
  },
  {
    path: portalRoutes.auth.resetPasswordSuccessRoute.path,
    component: ResetPasswordSuccessComponent,
  },
  {
    path: portalRoutes.auth.newUserPasswordSetupRoute.path,
    component: ResetPasswordSetupComponent,
  },
  {
    path: portalRoutes.auth.newUserPasswordSuccessRoute.path,
    component: ResetPasswordSuccessComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
