import { DayConfigs, RangePickerDay } from '../entities';
import { RangePickerConfig } from '../entities/range-picker-config';
import { YYYYMMDDString } from '@rootTypes';
import { dateToYYYYMMDD } from '@rootTypes/utils/common/date-time-fns';

export enum ViewType {
  YEAR = 'YEARS',
  DAY = 'DAYS',
}

export interface RangePickerState {
  config: {
    entity: RangePickerConfig;
    readonly: boolean;
    updatedAt: number;
  };
  isMouseDown: boolean;
  dayConfigs: {
    entity: DayConfigs;
    updatedAt: number;
  };
  hovered: {
    dates: {
      [dayId: string]: YYYYMMDDString;
    };
    updatedAt: number;
  };
  currentView: {
    viewType: ViewType;
    date: YYYYMMDDString;
    yearViewDate: YYYYMMDDString; // separate date for year view to support cancel
    updatedAt: number;
  };
  selected: {
    dates: {
      [dayId: string]: YYYYMMDDString;
    };
    updatedAt: number;
    userUpdatedAt: number;
  };
  monthsDaysCache: {
    [monthId: string]: RangePickerDay[]; // days
  };
}

export const initialRangePickerStore: RangePickerState = {
  config: {
    readonly: false,
    entity: {
      isStartWeekFromMonday: true,
      numMonthsDisplayed: 2,
      isSingleSelect: false,
      weekSelectCount: 0,
      isStrictDisableWeek: false,
      isDeselectOnClick: true,
      disableDatesBefore: null,
      disableDatesAfter: null,
    },
    updatedAt: 0,
  },
  isMouseDown: false,
  dayConfigs: {
    entity: {},
    updatedAt: 0,
  },
  hovered: {
    dates: {},
    updatedAt: 0,
  },
  currentView: {
    viewType: ViewType.DAY,
    date: dateToYYYYMMDD(new Date()),
    yearViewDate: null,
    updatedAt: 0,
  },
  selected: {
    dates: {},
    updatedAt: 0,
    userUpdatedAt: 0,
  },
  monthsDaysCache: {},
};
