import { createAction, props } from '@ngrx/store';

import { WpError } from '@rootTypes';
import { SetPasswordForTokenRequest } from 'src/app/api/endpoints/password-reset';

export const sendPasswordResetEmailRequested = createAction(
  '[ResetPassword]: Send password reset email requested',
  props<{ email: string }>(),
);

export const sendPasswordResetEmailSuccess = createAction('[ResetPassword]: Send password reset email success');

export const sendPasswordResetEmailFailed = createAction(
  '[ResetPassword]: Send password reset email failed',
  props<{ error: WpError }>(),
);

export const setNewPasswordRequested = createAction(
  '[ResetPasswordSetup]: Set new password requested',
  props<{ request: SetPasswordForTokenRequest }>(),
);
export const setNewPasswordSuccess = createAction('[ResetPasswordSetup]: Set new password success');
export const setNewPasswordFailed = createAction(
  '[ResetPasswordSetup]: Set new password failed',
  props<{ error: WpError }>(),
);
