import { EntityFilterRequest, EntityFilterRequestType } from '../../../../api/endpoints/entity-filter';

export const getPermissionOptionsRequest = (districtId: string): EntityFilterRequest => {
  return {
    type: EntityFilterRequestType.CAMPUS,
    districtIds: [districtId],
    skip: 0,
    limit: 1000,
  };
};
