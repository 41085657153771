import { EmployeeListFacade } from './employee-list.facade';
import { EmployeeDataFacade } from './employee-data.facade';
import { EmployeeDetailsFacade } from './employee-details-facade';
import { EmployeeProfileEditorFacade } from './employee-profile-editor.facade';
import { PermissionManagerFacade } from './permission-manager.facade';

export const employeeFacades = [
  EmployeeListFacade,
  EmployeeDataFacade,
  EmployeeDetailsFacade,
  EmployeeProfileEditorFacade,
  PermissionManagerFacade,
];

export {
  EmployeeListFacade,
  EmployeeDataFacade,
  EmployeeDetailsFacade,
  EmployeeProfileEditorFacade,
  PermissionManagerFacade,
};
