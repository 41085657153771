<div class="checklist-item">
  <div class="checklist-item__icon">
    <div
      *ngIf="isChecked; else notChecked;"
      class="checklist-item__icon__checked"
    >
      <wp-portal-icon [path]="iconPaths.CHECKED_CIRCLE_LARGE"></wp-portal-icon>
    </div>
    <ng-template #notChecked>
      <div class="checklist-item__icon__not-checked"></div>
    </ng-template>
  </div>
  <div class="checklist-item__text">
    <ng-content></ng-content>
  </div>
</div>
