import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const moduleKey = 'Data';
const submoduleKey = 'District Calendar';

export const initializeCalendarEditor = createAction(`[${moduleKey}] [${submoduleKey}]: Initialize calendar editor`);

export const updateCalendarRequested = createAction(
  `[${moduleKey}] [${submoduleKey}]: Update district calendar requested`,
  props<{
    districtId: string;
    previousCalendar: fromTypes.DistrictCalendar;
    updatedCalendar: fromTypes.DistrictCalendar;
  }>(),
);

export const updateCalendarSuccess = createAction(
  `[${moduleKey}] [${submoduleKey}]: Update district calendar success`,
  props<{
    districtId: string;
    calendar: fromTypes.DistrictCalendar;
  }>(),
);

export const updateCalendarFailed = createAction(
  `[${moduleKey}] [${submoduleKey}]: Update district calendar failed`,
  props<{
    districtId: string;
    error: fromTypes.WpError;
  }>(),
);
