import { RideFilter } from './ride-filter';

export enum RideQuickFilterType {
  TODAY_RIDES_BY_ROUTE = 'today_rides_by_route',
  CHARTER_RIDES = 'charter_rides',
  NONE = 'none',
}

export interface RideQuickFilter extends RideFilter {
  payload: RideQuickFilterType;
}
