import { createSelector } from '@ngrx/store';
import { getVendorFeature } from './get-feature';
import * as fromTypes from '../../types';
import { VendorListState } from '../reducers/vendor-list.reducer';

const getAllLists = createSelector(getVendorFeature, (state) => state.lists);

export const getListState = createSelector(
  getAllLists,
  (state, props: { listName: fromTypes.VendorListName }): VendorListState => {
    return state[props.listName];
  },
);

/**
 * Page state
 */
const getPageState = createSelector(getListState, (state) => state.page);

export const getPageLoading = createSelector(getPageState, (state) => state.isLoading);

export const getPageError = createSelector(getPageState, (state) => state.listError);

export const getPageItems = createSelector(getPageState, (state) => state.items);

export const getTotalCount = createSelector(getPageState, (state) => state.totalFound);

/**
 * Pagination state
 */
const getPaginationState = createSelector(getListState, (state) => state.pagination);

export const getCurrentPage = createSelector(getPaginationState, (state) => state.page);

export const getPageSize = createSelector(getPaginationState, (state) => state.pageSize);

/**
 * Search query
 */
export const getCurrentSearch = createSelector(getListState, (state) => state.currentSearch);

export const getSelectedVendorId = createSelector(getListState, (state) => state.selectedVendorId);
