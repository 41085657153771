import { Store } from '@ngrx/store';
import { State } from '../../../../../store';
import { Observable } from 'rxjs/internal/Observable';
import * as fromTabActions from '../../actions/campus-details/calendars-tab.actions';
import * as fromSelectors from '../../selectors/campus-details/calendars-tab.selectors';
import * as fromCalendarDataSelectors from '../../selectors/calendar-data.selectors';
import * as fromTypes from '../../../types';
import { Injectable } from '@angular/core';

@Injectable()
export class CalendarsTabFacade {
  constructor(private store: Store<State>) {}

  public getCalendarIds(): Observable<string[]> {
    return this.store.select(fromSelectors.getCalendarIdsForOpenCampus);
  }

  public getCalendarsLoading(): Observable<boolean> {
    return this.store.select(fromSelectors.getCalendarsLoadingForOpenCampus);
  }

  public getCalendarsCount(): Observable<number> {
    return this.store.select(fromSelectors.getCalendarsCountForOpenCampus);
  }

  public getCalendarsError(): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getCalendarsErrorForOpenCampus);
  }

  public getCalendarsEmpty(): Observable<boolean> {
    return this.store.select(fromSelectors.getCalendarsEmptyForOpenCampus);
  }

  public getCalendar(calendarId: string): Observable<fromTypes.CampusCalendar> {
    return this.store.select(fromCalendarDataSelectors.getCalendar, { calendarId });
  }

  public getCalendarExpanded(calendarId: string): Observable<boolean> {
    return this.store.select(fromSelectors.getCalendarExpanded, { calendarId });
  }

  public getHolidaysExpanded(calendarId: string): Observable<boolean> {
    return this.store.select(fromSelectors.getHolidaysExpanded, { calendarId });
  }

  public getCalendarRemoving(calendarId: string): Observable<boolean> {
    return this.store.select(fromCalendarDataSelectors.getCalendarRemoving, { calendarId });
  }

  public getDefaultCalendarId(): Observable<string> {
    return this.store.select(fromSelectors.getDefaultCalendarId);
  }

  public getDefaultCalendarLoading(): Observable<boolean> {
    return this.store.select(fromSelectors.getDefaultCalendarLoading);
  }

  public getDefaultDisabled(): Observable<boolean> {
    return this.store.select(fromSelectors.getDefaultCalendarDisabled);
  }

  public getRemoveCalendarDisabled(calendarId: string): Observable<boolean> {
    return this.store.select(fromSelectors.getRemoveCalendarDisabled, { calendarId });
  }

  public getRemoveScheduleDisabled(calendarId: string): Observable<boolean> {
    return this.store.select(fromSelectors.getRemoveScheduleDisabled, { calendarId });
  }

  public getScheduleIds(calendarId: string): Observable<string[]> {
    return this.store.select(fromCalendarDataSelectors.getScheduleIdsByCalendar, { calendarId });
  }

  public getSchedule(scheduleId: string): Observable<fromTypes.CampusSchedule> {
    return this.store.select(fromCalendarDataSelectors.getSchedule, { scheduleId });
  }

  public getScheduleRemoving(scheduleId: string): Observable<boolean> {
    return this.store.select(fromCalendarDataSelectors.getScheduleRemoving, { scheduleId });
  }

  public onMakeCalendarDefault(calendarId: string): void {
    this.store.dispatch(fromTabActions.makeCalendarDefaultClicked({ calendarId }));
  }

  public onRemoveCalendar(calendarId: string): void {
    this.store.dispatch(fromTabActions.removeCalendarClicked({ calendarId }));
  }

  public onAddCalendarClicked(): void {
    this.store.dispatch(fromTabActions.addNewCalendarClicked());
  }

  public onEditCalendarClicked(calendarId: string): void {
    this.store.dispatch(fromTabActions.editCalendarClicked({ calendarId }));
  }

  public onRemoveSchedule(scheduleId: string, calendarId: string): void {
    this.store.dispatch(fromTabActions.removeScheduleClicked({ calendarId, scheduleId }));
  }

  public onToggleCalendarExpanded(calendarId: string): void {
    this.store.dispatch(fromTabActions.toggleCalendarExpanded({ calendarId }));
  }

  public onToggleHolidaysExpanded(calendarId: string): void {
    this.store.dispatch(fromTabActions.toggleHolidaysExpanded({ calendarId }));
  }

  public onAddScheduleClicked(calendarId: string): void {
    this.store.dispatch(fromTabActions.addScheduleClicked({ calendarId }));
  }

  public onEditScheduleClicked(calendarId: string, scheduleId: string): void {
    this.store.dispatch(fromTabActions.editScheduleClicked({ calendarId, scheduleId }));
  }
}
