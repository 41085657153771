import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ExactLocationValue } from '@apiEntities';

@Component({
  selector: 'wp-display-exact-location',
  templateUrl: './display-exact-location.component.html',
  styleUrls: ['./display-exact-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayExactLocationComponent implements OnInit {
  @Input() public exactLocation: ExactLocationValue;
  @Input() public short: boolean;

  constructor() {}

  ngOnInit(): void {}
}
