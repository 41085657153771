import { MapLocation } from '@rootTypes';

export const pointsOnSameLineRatio = (start: MapLocation, end: MapLocation, point: MapLocation): number => {
  const diff =
    distanceBetweenPoints(start, point) + distanceBetweenPoints(point, end) - distanceBetweenPoints(start, end);
  return diff;
};

export const distanceBetweenPoints = (point1: MapLocation, point2: MapLocation): number => {
  return Math.sqrt(Math.pow(point1.lat - point2.lat, 2) + Math.pow(point1.lng - point2.lng, 2));
};

const squareDistanceBetweenPoints = (point1: MapLocation, point2: MapLocation): number => {
  return Math.pow(point1.lat - point2.lat, 2) + Math.pow(point1.lng - point2.lng, 2);
};
