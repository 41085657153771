import { createReducer, on } from '@ngrx/store';
import * as fromActions from '../actions/permissions-manager.actions';
import * as fromTypes from '../../types';

export interface PermissionsManagerState {
  employeeId: string;
  isLoading: boolean;
  isLoaded: boolean;
  error?: fromTypes.WpError;
}

export const initialPermissionManagerState: PermissionsManagerState = {
  employeeId: undefined,
  isLoading: false,
  isLoaded: false,
};

export const permissionsManagerReducer = createReducer<PermissionsManagerState>(
  { ...initialPermissionManagerState },
  on(fromActions.initPermissionsEditor, (state, action) => {
    const { employeeId } = action;
    return {
      ...initialPermissionManagerState,
      employeeId,
    };
  }),
  on(fromActions.updateEmployeePermissionsRequested, (state, action) => {
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      error: undefined,
    };
  }),
  on(fromActions.updateEmployeePermissionsSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      error: undefined,
    };
  }),
  on(fromActions.updateEmployeePermissionsFailed, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      error: action.error,
    };
  }),
  on(fromActions.updateEmployeePermissionsCanceled, (state, action) => {
    return {
      ...state,
      isLoading: false,
      isLoaded: false,
      error: undefined,
    };
  }),
);
