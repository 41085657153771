import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GotoPageService } from '../../goto-page.service';
import { Observable } from 'rxjs';
import { GotoOption, GotoOptionCategory } from '../../types/goto-option';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';

@Component({
  selector: 'wp-goto-page',
  template: `
    <div role="dialog" tabindex="1" (keyup.escape)="store.close()" class="goto-page">
      <div class="top-bar">
        <div class="logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="75" height="32" viewBox="0 0 75 32">
            <g fill="none">
              <path
                fill="#303538"
                d="M47.348 10.3h7.958c.11 0 .198-.088.2-.197V8.806c0-.11-.09-.198-.2-.198h-7.958c-.11 0-.199.089-.199.198v1.298c.002.109.09.196.2.197zm-3.571 10.743h-7.599c-.05 0-.097-.028-.12-.073-.023-.045-.018-.098.013-.138l7.808-10.444c.026-.034.04-.075.04-.118V8.492c0-.11-.09-.198-.199-.198H33.696c-.11 0-.198.089-.198.198v1.65c0 .11.09.198.199.198h7.268c.05-.001.097.027.12.072.023.045.018.099-.013.139L33.31 20.859c-.026.035-.04.076-.04.12v1.936c.001.108.09.197.2.197h10.307c.052 0 .103-.02.14-.057.038-.037.059-.088.059-.14v-1.672c0-.107-.085-.195-.193-.198l-.006-.002zm27.475-9.059c-1.325 0-2.671.456-3.536 1.868-.02.034-.058.053-.098.052-.04-.002-.075-.026-.092-.061-.622-1.266-1.875-1.86-3.17-1.86-1.185 0-2.455.495-3.167 1.54-.018.026-.05.037-.08.028-.03-.01-.05-.036-.052-.067v-.99c0-.108-.089-.196-.199-.197H59.22c-.11 0-.199.089-.199.198v10.442c0 .047.019.092.052.125s.079.052.126.052h1.748c.047 0 .092-.019.125-.052s.052-.078.051-.125v-6.342c0-1.619 1.018-2.743 2.533-2.743 1.627 0 2.306 1.08 2.306 2.473v6.595c0 .109.089.198.198.198h1.723c.11-.001.198-.09.199-.198v-6.347c0-1.551 1.018-2.72 2.51-2.72 1.605 0 2.306 1.057 2.306 2.474v6.593c0 .052.02.103.058.14.037.037.088.058.14.058h1.705c.11 0 .199-.089.199-.198v-6.817c.004-2.834-1.85-4.116-3.748-4.116v-.003zm-15.485.314h-1.721c-.053 0-.104.02-.141.058-.037.037-.058.088-.058.14v6.301c0 1.484-.736 2.641-2.522 2.641-1.785 0-2.524-1.157-2.523-2.64v-6.302c0-.052-.02-.103-.058-.14-.037-.037-.088-.058-.14-.058h-1.727c-.053 0-.104.02-.14.057-.038.037-.06.088-.06.14v6.617c0 2.45 1.37 4.34 4.648 4.34 3.278 0 4.648-1.89 4.648-4.34v-6.617c0-.054-.022-.105-.061-.142-.039-.037-.091-.057-.145-.055z"
              />
              <path
                fill="#009461"
                d="M12.918 8.593l4.987-6.76c1.186-1.67 3.4-2.288 5.3-1.481 1.9.807 2.962 2.818 2.544 4.813-.42 1.996-2.204 3.427-4.273 3.426l-8.558.002z"
              />
              <path
                fill="#DCDC22"
                d="M7.93 1.833l4.988 6.76 4.987-6.76C18.72.684 20.053 0 21.475.002H4.362C5.783 0 7.116.685 7.93 1.832z"
              />
              <path
                fill="#93C90E"
                d="M12.918 8.593l-4.987-6.76C6.744.165 4.53-.453 2.632.355.734 1.162-.328 3.172.091 5.166c.418 1.995 2.202 3.426 4.27 3.425l8.557.002z"
              />
              <path
                fill="#009461"
                d="M12.918 23.202l-4.987 6.76c-1.187 1.668-3.4 2.285-5.299 1.478-1.898-.808-2.96-2.817-2.541-4.812.418-1.995 2.202-3.425 4.27-3.425l8.557-.001z"
              />
              <path
                fill="#DCDC22"
                d="M17.905 29.962l-4.987-6.76-4.987 6.76c-.815 1.148-2.148 1.831-3.57 1.83h17.115c-1.423.002-2.756-.682-3.571-1.83z"
              />
              <path
                fill="#93C90E"
                d="M12.918 23.202l4.987 6.76c1.186 1.67 3.4 2.288 5.3 1.48 1.9-.807 2.962-2.817 2.544-4.813-.42-1.996-2.204-3.427-4.273-3.426l-8.558-.001z"
              />
              <path
                fill="#93C90E"
                d="M.833 24.972c.82-1.113 2.132-1.77 3.528-1.769h8.557l12.086-16.38c-.82 1.112-2.132 1.77-3.528 1.768h-8.558L.833 24.972z"
              />
            </g>
          </svg>
        </div>
        <div (click)="store.close()" class="close">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" class="close-icon">
            <g fill="none" fill-rule="evenodd" stroke="#7C7C7E" stroke-linecap="round" stroke-width="2.4">
              <path d="M26.296 5.931L5.931 26.296M26.296 26.296L5.931 5.931" />
            </g>
          </svg>
        </div>
      </div>
      <div class="search-bar">
        <div class="search">
          <div class="label">Go to:</div>
          <mat-form-field>
            <mat-label>Start typing...</mat-label>
            <input
              #inputElement
              type="text"
              matInput
              [formControl]="inputControl"
              [matAutocomplete]="auto"
              (input)="onInput($event)"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              [autoActiveFirstOption]="true"
              (optionSelected)="onOptionSelected($event)"
            >
              @if (isLoading | async) {
                <mat-option class="info-option" [disabled]="true">
                  <div class="info-option-inner">
                    <wp-section-spinner [spinnerSize]="33" [sectionHeight]="'50px'"></wp-section-spinner>
                  </div>
                </mat-option>
              }
              @for (option of options | async; track option.id) {
                <mat-option [value]="option">
                  {{ option.label }}
                  <div *ngIf="option.category === categories.RECENTLY_VIEWED" class="option-subtext">
                    Recently viewed
                  </div>
                </mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </div>
  `,
  styleUrl: './goto-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GotoPageComponent implements AfterViewInit {
  @ViewChild('inputElement') inputElement: ElementRef;
  public options: Observable<GotoOption[]>;
  public isLoading: Observable<boolean>;
  public inputControl = new FormControl();
  public categories = GotoOptionCategory;

  constructor(
    public store: GotoPageService,
    private router: Router,
  ) {
    this.options = store.options;
    this.isLoading = store.isOptionsLoading$;
  }

  ngAfterViewInit(): void {
    if (this.inputElement?.nativeElement) {
      this.inputElement.nativeElement.focus();
    }
  }

  onInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.store.onSearch(value);
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    const option = event.option.value as GotoOption;
    this.store.selectOption(option);
  }
}
