import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { SimplePaginationService } from '../../services/simple-pagination.service';

@Component({
  selector: 'wp-simple-pagination-control',
  templateUrl: './simple-pagination-control.component.html',
  styleUrls: ['./simple-pagination-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimplePaginationControlComponent {
  @Input() public pagination: SimplePaginationService;
  @Input() public isNext: boolean;
  @Input() public isCurrentPageShown: boolean;
}
