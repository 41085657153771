/**
 * Basic easing functions taken from https://easings.net
 */
import { roundToOne } from '@rootTypes/utils/map-tracker/utils/round-to-one';

export class Easings {
  public static easeInQuad(x: number) {
    return x * x;
  }

  public static easeInSine(x: number): number {
    return roundToOne(1 - Math.cos((x * Math.PI) / 2));
  }

  public static easeInOutSine(x: number): number {
    return -(Math.cos(Math.PI * x) - 1) / 2;
  }

  public static easeOutQuad(x: number): number {
    return 1 - (1 - x) * (1 - x);
  }

  public static easeInOutQuad(x) {
    return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
  }

  public static easeInOutCubic(x: number): number {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
  }

  public static easeOutCubic(x: number): number {
    return 1 - Math.pow(1 - x, 3);
  }

  public static easeLinear(x) {
    return x;
  }
}
