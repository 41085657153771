<wp-side-bar-dropdown>
  <wp-side-bar-icon-wrap>
    <wp-display-photo
      [placeholderAbbreviation]="userInitials$ | async"
      [placeholderAbbreviationFontSize]="'14px'"
      [width]="'32px'"
      [height]="'32px'"
      [isRound]="true"
      [imageBase64]="userPhotoBase64$ | async"
    ></wp-display-photo>
  </wp-side-bar-icon-wrap>

  <wp-side-bar-text
    [text]="userName$ | async"
    [smallFont]="true"
    [isShown]="isTextShown"
  >
  </wp-side-bar-text>

  <wp-side-bar-dropdown-option (click)="onUserProfile()"
    >Profile settings
  </wp-side-bar-dropdown-option>

  <wp-side-bar-dropdown-option
    *ngIf="isSwitchAccountPossible$ | async"
    (click)="onSwitchAccount()"
    >Switch account
  </wp-side-bar-dropdown-option>

  <wp-side-bar-dropdown-option (click)="onLogoutClick()"
    >Logout
  </wp-side-bar-dropdown-option>
</wp-side-bar-dropdown>
