import { Pipe, PipeTransform } from '@angular/core';
import { YYYYMMDDString } from '@rootTypes';
import { getNumYearsSince } from '@rootTypes/utils/common/date/get-age-from-dob';

@Pipe({
  name: 'dobToAge',
})
export class DobToAgePipe implements PipeTransform {
  transform(dob: YYYYMMDDString): string {
    if (!dob) {
      return '--';
    }
    const numYears = getNumYearsSince(dob);
    return `${numYears}`;
  }
}
