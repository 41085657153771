import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../../../api/api.service';
import { VehicleInspectionListParams, VehicleInspectionQuickFilter } from '../types';
import {
  EntityFilterVehicleInspectionsRequest,
  EntityFilterVehicleInspectionsResponse,
} from '../../../api/endpoints/entity-filter';
import {
  GetMissingVehicleInspectionsRequest,
  GetMissingVehicleInspectionsResponse,
} from '../../../api/endpoints/get-missing-vehicle-inspections';
import { VehicleInspectionDetails } from '../../../api/endpoints/get-vehicle-inspection';
import { VehicleInspectionProjection } from '@apiEntities';
import {
  GetVehicleInspectionReportsRequest,
  GetVehicleInspectionReportsResponse,
} from '../../../api/endpoints/get-vehicle-inspection-reports';
import { downloadBlob } from '@rootTypes/utils/common/dom/download-blob';
import {
  SubmitVehicleInspectionMechanicAssignmentRequest,
  SubmitVehicleInspectionMechanicAssignmentResponse,
} from '../../../api/endpoints/submit-vehicle-inspection-mechanic-assignment';
import { UpdateVehicleInspectionMechanicReviewRequest } from '../../../api/endpoints/update-vehicle-inspection-mechanic-review';
import { VehicleInspectionDetailsWithPermission } from '@rootTypes';
import { GetVehicleInspectionReportTemplateRequest } from '../../../api/endpoints/get-vehicle-inspection-report-template';

@Injectable({
  providedIn: 'root',
})
export class VehicleInspectionApiService {
  constructor(private api: ApiService) {}

  public getVehicleInspectionList(
    params: VehicleInspectionListParams,
  ): Observable<EntityFilterVehicleInspectionsResponse> {
    const {
      pageIndex,
      pageSize,
      dateRange,
      staticVehicle,
      staticDriverId,
      vehicles,
      driverIds,
      mechanicAssessmentStatus,
      assignedMechanicIds,
      driverReviewStatus,
      inspectionStatus,
      quickFilter,
    } = params;
    const request: EntityFilterVehicleInspectionsRequest = {
      type: 'vehicle_inspection',
      vehicleDisplayIds: [...vehicles.map((v) => v.vehicleDisplayId).filter((id) => !!id)],
      driverIds: [...driverIds, ...(staticDriverId ? [staticDriverId] : [])],
      vehicleIds: [],
      date: {
        fieldName: 'inspection_date',
      },
      skip: pageIndex * pageSize,
      limit: pageSize,
      extraFilters: {
        mechanic_assessment_status: [...mechanicAssessmentStatus],
        assigned_mechanic_vendor_employee_id: [...assignedMechanicIds],
        driver_review_status: [...driverReviewStatus],
        inspection_status: [...inspectionStatus],
      },
    };
    if (staticVehicle) {
      if (staticVehicle.vehicleDisplayId) {
        request.vehicleDisplayIds.push(staticVehicle.vehicleDisplayId);
      } else if (staticVehicle.vehicleId) {
        request.vehicleIds.push(staticVehicle.vehicleId);
      }
    }
    if (dateRange) {
      request.date = {
        ...request.date,
        from: dateRange.from,
        to: dateRange.to,
      };
    }
    if (quickFilter === VehicleInspectionQuickFilter.LATE_INSPECTIONS) {
      request.extraFilters.is_late = ['true'];
    }
    return this.api.entityFilterVehicleInspections(request);
  }

  public getMissingVehicleInspections(
    request: GetMissingVehicleInspectionsRequest,
  ): Observable<GetMissingVehicleInspectionsResponse> {
    return this.api.getMissingVehicleInspections(request);
  }

  public getVehicleInspectionDetails(vehicleInspectionId: string): Observable<VehicleInspectionDetails> {
    return this.api.getVehicleInspection({ vehicleInspectionId }).pipe(map((res) => res.vehicleInspection));
  }

  public getVehicleInspectionDetailsWithPermission(
    vehicleInspectionId: string,
  ): Observable<VehicleInspectionDetailsWithPermission> {
    return this.api
      .getVehicleInspection({
        vehicleInspectionId,
        _projections: [VehicleInspectionProjection.MECHANIC_PERMISSION],
      })
      .pipe(
        map(({ vehicleInspection, _projections }) => {
          return {
            details: vehicleInspection,
            permission: _projections[VehicleInspectionProjection.MECHANIC_PERMISSION],
          };
        }),
      );
  }

  public async downloadVehicleInspectionReports(
    request: GetVehicleInspectionReportsRequest,
    fileName: string,
  ): Promise<void> {
    const res = await firstValueFrom(this.api.getVehicleInspectionReports(request));
    return await downloadBlob(res.blob, res.fileName || fileName);
  }

  public async downloadVehicleInspectionReportTemplate(
    request: GetVehicleInspectionReportTemplateRequest,
  ): Promise<void> {
    const res = await firstValueFrom(this.api.getVehicleInspectionReportTemplate(request));
    const fileName = res.fileName || `vehicle_inspection_template_${request.vehicleDisplayId}.pdf`;
    return await downloadBlob(res.blob, fileName);
  }

  public getVehicleInspectionReportForPreview(
    vehicleInspectionId: string,
  ): Observable<GetVehicleInspectionReportsResponse> {
    return this.api.getVehicleInspectionReports({ vehicleInspectionIds: [vehicleInspectionId], isInline: false });
  }

  public submitVehicleInspectionAssignment(
    request: SubmitVehicleInspectionMechanicAssignmentRequest,
  ): Observable<SubmitVehicleInspectionMechanicAssignmentResponse> {
    return this.api.submitVehicleInspectionMechanicAssignment(request);
  }

  public updateVehicleInspectionMechanicReview(
    request: UpdateVehicleInspectionMechanicReviewRequest,
  ): Observable<void> {
    return this.api.updateVehicleInspectionMechanicReview(request);
  }
}
