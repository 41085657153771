import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { State } from '@rootStore';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';
import { RoutesApiService } from '../../services/routes-api.service';
import * as fromActions from '../actions/routes-data.actions';
import * as fromDriverActions from '../../../drivers/store/actions/driver-data.actions';
import * as fromSelectors from '../selectors/route-data.selectors';
import { ApiService } from '../../../../api/api.service';

@Injectable()
export class RouteDataEffects {
  constructor(
    private actions$: Actions,
    private apiService: RoutesApiService,
    private api: ApiService,
    private store: Store<State>,
  ) {}

  public loadRouteRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRouteRequested),
      mergeMap((action) => {
        const { routeId, withPolyline, withDistrictProgram } = action;
        return this.apiService.getRoute({ routeId, withPolyline, withDistrictProgram }).pipe(
          map((payload) => fromActions.loadedRouteSuccess({ routeId, payload })),
          catchError((err) => {
            console.log(err);
            return of(
              fromActions.loadRouteFailed({
                routeId,
                error: { text: 'Failed to load route' },
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * Load route calendar on request (if empty)
   */
  public loadRouteCalendarRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadCalendarForRouteRequested),
      mergeMap((action) => {
        const { routeId } = action;
        return this.apiService.getRouteCalendar(routeId).pipe(
          map((calendar) =>
            fromActions.loadedCalendarForRouteSuccessfully({
              calendar,
              routeId,
            }),
          ),
          catchError((error) => {
            console.log(error);
            return of(
              fromActions.loadCalendarForRouteFailed({
                routeId,
                error: { text: 'Failed to load calendar for route ' },
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * Loads all drivers for this route.
   */
  public loadDriversForRouteRequested$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.loadDriverForRouteRequested),
        mergeMap((action) => {
          const { routeId } = action;
          return this.store.select(fromSelectors.getRouteDriversByWeekdays, { routeId }).pipe(
            filter((state) => !!state),
            take(1),
            map((driverByRouteState) => {
              const driverIdsByRoute: string[] = Object.keys(driverByRouteState)
                .map((weekday) => driverByRouteState[weekday])
                .filter((driversByWeekday) => !!driversByWeekday)
                .map((driversByWeekday) => Object.keys(driversByWeekday))
                .reduce((prev, curr) => {
                  return [...prev, ...curr];
                }, []);
              const uniqueDriverId = new Set(driverIdsByRoute);
              uniqueDriverId.forEach((driverId) =>
                this.store.dispatch(
                  fromDriverActions.loadDriverRequested({ driverId, options: { withVehicle: true } }),
                ),
              );
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  /**
   * Loads all riders for the route
   */
  public loadRidersForRouteRequested$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.loadRidersForRouteRequested),
        map((action) => {
          const { routeId } = action;
          this.store
            .select(fromSelectors.getRiderIdsByRoute, { routeId })
            .pipe(
              filter((state) => !!(state && state.length)),
              take(1),
              map((riderIds) => {
                const uniqueRiderIds = new Set(riderIds);
                uniqueRiderIds.forEach((riderId) =>
                  this.store.dispatch(fromActions.loadRiderIfEmptyRequested({ riderId, routeId })),
                );
              }),
            )
            .subscribe();
        }),
      ),
    { dispatch: false },
  );

  /**
   * Load rider on request (if empty)
   */
  public loadRiderRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRiderIfEmptyRequested),
      mergeMap((action) => {
        const { riderId, routeId } = action;
        return this.doLoadRider(riderId, routeId);
      }),
    ),
  );

  private doLoadRider(riderId: string, routeId: string): Observable<Action> {
    return this.api.getStudentSnapshot({ studentId: riderId, entity: { id: routeId, type: 'ROUTE' } }).pipe(
      map((rider) =>
        fromActions.loadedRiderIfEmptySuccess({
          rider,
        }),
      ),
      catchError((error) => {
        console.log(error);
        return of(
          fromActions.loadedRiderIfEmptyFailed({
            riderId,
            error: { text: 'Failed to load rider profile ' },
          }),
        );
      }),
    );
  }
}
