import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApplyClassOnDiffDirective } from './apply-class-on-diff.directive';
import { ApplyClassOnStickyDirective } from './apply-class-on-sticky.directive';
import { ApplyClassOnWrapDirective } from './apply-class-on-wrap.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { HardCodedDirective } from './hard-coded.directive';
import { HoverDirective } from './hover.directive';
import { InputTimeFormatDirective } from './input-time-format.directive';
import { IsInViewportDirective } from './is-in-viewport.directive';
import { KeyboardListenerDirective } from './keyboard-listener.directive';
import { LongPressDirective } from './long-press.directive';
import { ScrollIntoViewDirective } from './scroll-into-view.directive';
import { SelectedItemDirective } from './selected-item.directive';
import { TypedDirective } from './typed.directive';
import { UnloadGuardDirective } from './unload-guard.directive';
import { WidthChangedDirective } from './width-changed.directive';

const directives = [
  ApplyClassOnDiffDirective,
  ApplyClassOnStickyDirective,
  ApplyClassOnStickyDirective,
  ApplyClassOnWrapDirective,
  ClickOutsideDirective,
  HardCodedDirective,
  HoverDirective,
  InputTimeFormatDirective,
  IsInViewportDirective,
  KeyboardListenerDirective,
  LongPressDirective,
  ScrollIntoViewDirective,
  SelectedItemDirective,
  TypedDirective,
  UnloadGuardDirective,
  WidthChangedDirective,
  SelectedItemDirective,
];

@NgModule({
  imports: [CommonModule],
  exports: [...directives],
  declarations: [...directives],
})
export class DirectivesModule {}
