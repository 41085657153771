import {
  Component,
  OnInit,
  Input,
  Output,
  ChangeDetectionStrategy,
  EventEmitter,
  OnDestroy,
  HostListener,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { SetAccountRequest, UserAccount } from '../../../api/endpoints/account';
import { SmartAutocomplete } from '../../../shared/smart-forms/models/smart-autocomplete';
import { SelectOption } from '../../../shared/form-controls';
import { SnackbarService } from '../../../shared/snackbar/snackbar.service';
import { autocompleteSubsequenceOptionSearchFn } from '../../../shared/form-controls/autocomplete/autocomplete.component';

@Component({
  selector: 'wp-select-account-form',
  templateUrl: './select-account-form.component.html',
  styleUrls: ['./select-account-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectAccountFormComponent implements OnInit, OnDestroy {
  @Input() public accounts: UserAccount[];
  @Input() public isSubAccountRequired: boolean;
  @Input() public isSubmitLoading: boolean;
  @Input() public initialAccountId?: string | number;
  @Input() public initialSubAccountId?: string | number;
  @Input() public parentSelector?: string;

  @Output() public request = new EventEmitter<SetAccountRequest>();

  public account: SmartAutocomplete;
  public subAccount?: SmartAutocomplete;

  private sub = new Subscription();

  constructor(private snackbar: SnackbarService) {}

  public ngOnInit(): void {
    const accountOptions: SelectOption[] = this.accounts.map((account) => ({
      value: account.id,
      displayLabel: account.name,
    }));
    this.account = new SmartAutocomplete({
      label: 'Account',
      value: this.initialAccountId,
      options: accountOptions,
      required: true,
      parentSelectorToCloseByClick: this.parentSelector,
      optionSearchFn: autocompleteSubsequenceOptionSearchFn,
    });

    const accountChangeSub = this.account.control.valueChanges.subscribe((accountId) => {
      this.setSubAccount(accountId);
    });
    this.sub.add(accountChangeSub);
    this.setSubAccount(this.initialAccountId, this.initialSubAccountId);
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  @HostListener('window:keydown', ['$event'])
  public onEnterKey(event: KeyboardEvent): void {
    if (event.code === 'Enter') {
      event.preventDefault(); // prevent page reloading
      if (!this.isSubmitLoading) {
        this.onSubmit();
      }
    }
  }

  public onSubmit(): void {
    if (!this.account.isValid()) {
      this.account.showErrorIfAny();
      this.snackbar.info('Please select an account to proceed');
      return;
    }
    if (this.subAccount && !this.subAccount.isValid()) {
      this.subAccount.showErrorIfAny();
      this.snackbar.info('Please select a subaccount to proceed');
      return;
    }

    const request: SetAccountRequest = {
      accountId: this.account.getValue(),
    };
    if (this.subAccount) {
      const subAccountId = this.subAccount.getValue();
      if (subAccountId) {
        request.subAccountIds = [subAccountId];
      }
    }
    this.request.emit(request);
  }

  private setSubAccount(accountId?: string | number, subAccountId?: string | number): void {
    const account = accountId ? this.accounts.find((account) => account.id === accountId) : undefined;
    if (!account || !account.subAccounts || account.subAccounts.length === 0) {
      this.subAccount = undefined;
      return;
    }

    const options: SelectOption[] = account.subAccounts.map((item) => {
      return {
        value: item.id,
        displayLabel: item.name,
      };
    });
    if (this.subAccount) {
      this.subAccount.setOptions(options);
      return;
    }
    this.subAccount = new SmartAutocomplete({
      label: 'Subaccount',
      value: subAccountId,
      options,
      required: this.isSubAccountRequired,
      parentSelectorToCloseByClick: this.parentSelector,
      optionSearchFn: autocompleteSubsequenceOptionSearchFn,
    });
  }
}
