<div class="wp-cross-entity-search">
  <div class="wp-cross-entity-search__input" (click)="onSearchInputClick($event)">
    <wp-input
      [id]="id"
      [control]="searchInputControl"
      [controlStateChange]="controlStateChange"
      [label]="placeholder"
      [tabIndex]="tabIndex"
      [autofocus]="autofocus"
      [customErrorMessageKey]="inputCustomErrorMessageKey"
      (inputEvent)="onInputEvent($event)"
      (blurEvent)="inputBlur.emit()"
    >
      <ng-content select="wp-input-prefix"></ng-content>
      <wp-input-prefix *ngIf="isSearchGlassIcon" [marginRight]="'20px'" moveDown="3px" moveRight="10px">
        <wp-icon-search [disabled]="disabled"></wp-icon-search>
      </wp-input-prefix>
      <wp-input-prefix *ngIf="!isSearchGlassIcon">
        <ng-content select=".input-prefix"></ng-content>
      </wp-input-prefix>
      <wp-input-suffix
        [marginRight]="'10px'"
        *ngIf="!!searchInputControl.value && !(disabled || searchInputControl?.disabled) && !hideClearButton"
        (onClicked)="clearInput()"
      >
        <wp-icon-close [size]="'small'"></wp-icon-close>
      </wp-input-suffix>
      <wp-input-suffix *ngIf="!searchInputControl.value">
        <ng-content select=".input-suffix"></ng-content>
      </wp-input-suffix>
    </wp-input>
  </div>
  <ng-container *ngIf="!!searchInputControl.value">
    <div
      class="wp-cross-entity-search__dropdown customScroll"
      *ngIf="dropdownOpen$ | async"
      wpClickOutside
      (clickedOutside)="onClickOutside()"
      infiniteScroll
      [scrollWindow]="false"
      [infiniteScrollDisabled]="!(hasNext$ | async)"
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScrolled()"
    >
      <wp-dropdown-pane class="wp-cross-entity-search__dropdown__dropdown-plane">
        <div *ngIf="error$ | async; else searchResultsTemplate" class="empty-page">Search error</div>
        <ng-template #searchResultsTemplate>
          <ng-container *ngIf="defaultOption">
            <wp-dropdown-option
              (click)="onDefaultOptionClick(defaultOption)"
              (selectedWithKeyboard)="onDefaultOptionClick(defaultOption)"
            >
              {{ defaultOption.displayLabel }}
            </wp-dropdown-option>
          </ng-container>
          <!-- standard options -->
          <ng-container *ngIf="!optionTemplate; else customOptions">
            <ng-container *ngFor="let item of items$ | async">
              <wp-dropdown-option (click)="onOptionClick(item)" (selectedWithKeyboard)="onOptionClick(item)">
                <wp-search-option [entity]="item"></wp-search-option>
              </wp-dropdown-option>
            </ng-container>
          </ng-container>
          <!-- custom options -->
          <ng-template #customOptions>
            <ng-container *ngFor="let item of items$ | async">
              <div class="option-wrap" (click)="onOptionClick(item)">
                <ng-template
                  [ngTemplateOutlet]="optionTemplate"
                  [ngTemplateOutletContext]="{ entity: item }"
                ></ng-template>
              </div>
            </ng-container>
          </ng-template>
          <div></div>
          <div *ngIf="empty$ | async" class="empty-page">
            <ng-container *ngIf="!defaultOption"> No results </ng-container>
          </div>
        </ng-template>

        <div *ngIf="nextPageLoading$ | async">
          <wp-section-spinner [sectionHeight]="'60px'" [spinnerSize]="30"></wp-section-spinner>
        </div>
      </wp-dropdown-pane>
    </div>
  </ng-container>
</div>
