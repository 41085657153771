import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  getDriverFeeByRideFailed,
  getDriverFeeByRideRequested,
  getDriverFeeByRideSuccess,
  getEndOfRideInspectionFailed,
  getEndOfRideInspectionRequested,
  getEndOfRideInspectionSuccess,
  getRidePolylineFailed,
  getRidePolylineRequested,
  getRidePolylineSuccess,
  getRideV2Failed,
  getRideV2Requested,
  getRideV2RequestedOnFlashUpdate,
  getRideV2Success,
} from '../actions/rides-data.actions';
import { ApiService } from '../../../../api/api.service';
import { WpError } from '@rootTypes';
import { Store } from '@ngrx/store';

@Injectable()
export class RideDataEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store,
  ) {}

  public getRideV2Requested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRideV2Requested, getRideV2RequestedOnFlashUpdate),
      mergeMap(({ request }) => {
        return this.api.getRide(request).pipe(
          map((response) => {
            return getRideV2Success({ rideId: request.rideId, response, requestProjections: request._projections });
          }),
          catchError((originalError) => {
            console.log(originalError);
            const error: WpError = {
              originalError,
              text: 'Failed to execute getRide',
            };
            return of(getRideV2Failed({ rideId: request.rideId, error }));
          }),
        );
      }),
    ),
  );

  public getRidePolylineRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRidePolylineRequested),
      mergeMap(({ rideId }) => {
        return this.api.getRidePolyline({ rideId }).pipe(
          map((response) => getRidePolylineSuccess({ rideId, response })),
          catchError((originalError) => {
            const error: WpError = {
              originalError,
              text: 'Failed to get ride polyline',
            };
            return of(getRidePolylineFailed({ rideId, error }));
          }),
        );
      }),
    ),
  );

  public getDriverFeeByRideRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDriverFeeByRideRequested),
      mergeMap(({ rideId }) => {
        return this.api.getRideDriverFee({ rideId }).pipe(
          map((response) => getDriverFeeByRideSuccess({ rideId, response })),
          catchError((originalError) => {
            const error: WpError = {
              originalError,
              text: 'Failed to get ride driver fee',
            };
            return of(getDriverFeeByRideFailed({ rideId, error }));
          }),
        );
      }),
    ),
  );

  public getEndOfRideInspectionRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEndOfRideInspectionRequested),
      mergeMap(({ rideId }) => {
        return this.api.getEndOfRideInspectionAbstractByRide({ rideId }).pipe(
          map((response) => getEndOfRideInspectionSuccess({ rideId, response })),
          catchError((originalError) => {
            const error: WpError = {
              originalError,
              text: 'Failed to get ride polyline',
            };
            return of(getEndOfRideInspectionFailed({ rideId, error }));
          }),
        );
      }),
    ),
  );
}
