<div class="wrap">
  <div *ngIf="isDisabled$ | async" class="disabled-mask"></div>
  <div
    class="toggle-wrap"
    [ngClass]="{ selected: isOn$ | async }"
    (click)="onToggleClick()"
  >
    <div class="toggle-circle"></div>
  </div>
</div>
