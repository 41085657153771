import { parse } from 'date-fns';
import { HHMMTimeString } from '@rootTypes';
import { formattingConstants } from './formatting-tokens';

/**
 * Converts a HHMMTimeString to a Date object. Date is set to today.
 * @param hhmm
 * @param refDate - defaults to today. Used to set the year, month, and day of the returned Date object.
 */
export const hhmmToDate = (hhmm: HHMMTimeString, refDate = new Date()): Date => {
  return parse(hhmm, formattingConstants.hhMM, refDate);
};
