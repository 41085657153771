import { RangePickerDay } from '../entities/range-picker-day';
import { getMomentDayLabel } from './get-moment-day-label';
import {
  dateEndOfMonth,
  dateEndOfWeek,
  datesGetSpan,
  datesIsSame,
  dateStartOfMonth,
  dateStartOfWeek,
  dateToYYYYMMDD,
} from '@rootTypes/utils/common/date-time-fns';

export const getDaysForMonth = (month: Date, isMondayFirstDay: boolean): RangePickerDay[] => {
  const startOfMonth = dateStartOfMonth(month);
  const startOfWeek = dateStartOfWeek(startOfMonth, isMondayFirstDay);
  const endOfMonth = dateEndOfMonth(month);
  const endOfWeek = dateEndOfWeek(endOfMonth, isMondayFirstDay);

  const resultDates = datesGetSpan(startOfWeek, endOfWeek);
  return resultDates.map((d) => {
    const isDisplayed = datesIsSame(d, month, 'month');
    return {
      moment: dateToYYYYMMDD(d),
      isDisplayed,
      label: isDisplayed ? getMomentDayLabel(d) : null,
    } as RangePickerDay;
  });
};
