import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HttpResponseStatus } from '../../services/api/http-response-status';
import { SessionService } from '../../services/api/session.service';
import { ImageApiUtilsService } from './image-api-utils.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadDriverDocumentApiService {
  constructor(
    private session: SessionService,
    private imageUtils: ImageApiUtilsService,
  ) {}

  /**
   * @returns Image data as stream
   */
  public async downloadDocument(documentId: string): Promise<string> {
    const docUrl = `${wpEnvironment.apiBaseUrl}/${wpEnvironment.userRole}/downloadDriverDocument?driverDocumentId=${documentId}`;
    let imageBuffer: ArrayBuffer;
    try {
      imageBuffer = await this.imageUtils.getImageArrayBuffer(docUrl);
    } catch (error) {
      const errorRes = error as HttpErrorResponse;
      if (errorRes.status === HttpResponseStatus.UNAUTHORIZED) {
        try {
          imageBuffer = await this.session.refreshSessionAndRetry(() => this.imageUtils.getImageArrayBuffer(docUrl));
        } catch (retryError) {
          console.error(retryError);
          throw retryError;
        }
      } else {
        console.error(errorRes);
        throw errorRes;
      }
    }
    // Source: https://medium.com/front-end-weekly/fetching-images-with-the-fetch-api-fb8761ed27b2
    const imageBase64 = 'data:image/jpeg;base64,' + this.imageUtils.arrayBufferToBase64(imageBuffer);
    return imageBase64;
  }
}
