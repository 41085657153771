import { createSelector } from '@ngrx/store';
import { studentChangeRequestsFeatureSelector } from './feature-selector';
import { StudentChangeRequestOperation } from '@apiEntities';

export const getReviewState = createSelector(studentChangeRequestsFeatureSelector, (state) => state.review);
// export const getStudentChangeRequestId = createSelector(getReviewState, (state) => state.requestId);

export const getReviewDistrictId = createSelector(getReviewState, (state) => state.districtId);
export const getReviewDistrictProgramId = createSelector(getReviewState, (state) => state.districtProgramId);

const getReviewFormState = createSelector(getReviewState, (state) => state.reviewForm);
export const getReviewForm = createSelector(getReviewFormState, (state) => state.entity);
export const isReviewFormLoading = createSelector(getReviewFormState, (state) => state.isLoading);
export const getReviewFormLoadingError = createSelector(getReviewFormState, (state) => state.error);
export const getFormLabel = createSelector(getReviewForm, (state) => state?.label);
export const getFormRows = createSelector(getReviewForm, (state) => state?.rows);
export const hasUnsavedChanges = createSelector(getReviewForm, (state) => state?.hasUnsavedChanges || false);
export const getEntityDeleteLabel = createSelector(getReviewFormState, (state) => {
  if (state.entity.operation !== StudentChangeRequestOperation.DELETE) {
    return undefined;
  }
  return state?.entity?.deleteEntityLabel;
});

export const getChooseActionState = createSelector(getReviewForm, (state) => state?.chooseAction);
export const isEntitySelectDisabled = createSelector(
  getChooseActionState,
  (state) => state?.isEntitySelectDisabled || false,
);
export const isUpdateOperation = createSelector(getChooseActionState, (state) => state?.isUpdate);

const getApplyChangeRequestState = createSelector(getReviewState, (state) => state.applyChangeRequest);
export const isApplyChangeRequestLoading = createSelector(getApplyChangeRequestState, (state) => state.isLoading);
export const getApplyChangeRequestValidationErrors = createSelector(
  getApplyChangeRequestState,
  (state) => state.entity?.errors || [],
);
