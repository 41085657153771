import { Pipe, PipeTransform } from '@angular/core';
import { getDownloadUrlFromPath } from '@rootTypes/utils/common/get-download-url-from-path';

@Pipe({
  name: 'pathToDownloadUrl',
})
export class PathToDownloadUrlPipe implements PipeTransform {
  transform(path: string, prefix?: string): string {
    return path?.length ? getDownloadUrlFromPath(path, prefix) : undefined;
  }
}
