import { PortalEntityType } from '../dependencies';
import { SmartInputConfig } from './smart-input';
import { AbstractControl, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { SelectOption } from '../../form-controls';
import { EntityStatus, PortalEntity } from '@rootTypes';
import { Observable } from 'rxjs/internal/Observable';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { EntityFilterOptions } from '../../../api/endpoints/entity-filter';
import { SmartInputModel } from './smart-input-model';
import { SmartInputCustomValidator } from '../types';

export type SmartSelectEntitySearchParams = EntityFilterOptions;

export type SmartSelectValue = {
  id: string;
  label?: string;
};

export interface SmartSelectConfig extends SmartInputConfig<SmartSelectValue> {
  lookup: {
    entitySearch?: {
      params?: SmartSelectEntitySearchParams & { status?: EntityStatus }; // filter
      type: PortalEntityType; // entity types example campus | customer etc
    };
    fixed?: SelectOption[]; // fixed value won't have id's, example student statuses?
  }; // correspond to id, example campus id (should/will be missing in case of enums?)
  customValidator?: SmartInputCustomValidator;
  allowSetValueNotFromList?: boolean;
  hideClearButton?: boolean;
}

export type EntityNameGetter = (entityId: string, entityType: PortalEntityType) => Observable<string>;

export class SmartSelect implements SmartInputModel {
  public control: UntypedFormControl; // form control with the "real" value (SmartSelectValue)
  public displayFormControl: UntypedFormControl; // form control with string display value, bind errors to this one
  public isEntitySearch: boolean;
  public label: string;
  public hideClearButton: boolean;
  /**
   * Options for fixed select
   */
  public fixedOptions: (SelectOption & { isInvalidOption?: boolean })[];

  /**
   * Options for entity search
   */
  public entitySearchType: PortalEntityType;
  public entitySearchParams: EntityFilterOptions;
  public entitySearchStatus: EntityStatus;

  /**
   * Constants
   */
  public SELECT_VALUE_FROM_THE_LIST_ERROR_KEY = 'notSelectedFromList';
  public customErrorMessageKey?: string;

  private required: boolean;
  private disabled: boolean;
  private initialValue: SmartSelectValue; // id
  public allowSetValueNotFromList: boolean;

  /**
   * Cache entity search entity names
   */
  private static _entityNameCache: { [entityId: string]: string } = {};
  /**
   * Needed for setting display labels of portal entities
   */
  private entityNameGetter$$ = new BehaviorSubject<EntityNameGetter>(undefined);
  private setEntityNameTasks$$ = new BehaviorSubject<{ id: string; type: PortalEntityType }>(undefined);
  private subscriptions = new Subscription();
  private currentConfig: SmartSelectConfig;

  constructor(config: SmartSelectConfig) {
    this.currentConfig = config;
    this.applyCurrentConfig();
    if (this.isEntitySearch) {
      this.setEntityDisplayNameSubscription();
    }
  }

  isValid(): boolean {
    if (this.displayFormControl) {
      return this.displayFormControl.valid;
    }
    return this.control.valid;
  }

  getValue(): SmartSelectValue {
    return this.control.value;
  }

  hasChanges(): boolean {
    const initialId = this.initialValue?.id || '';
    const currId = this.getValue()?.id || '';
    return currId !== initialId;
  }

  /**
   * Use this method instead of control's setValue to sync with display control,
   * and load the label if missing
   */
  setValue(value: SmartSelectValue): void {
    this.currentConfig = {
      ...this.currentConfig,
      value,
    };
    this.applyCurrentConfig();
  }

  setDisabled(disabled: boolean): void {
    this.currentConfig = {
      ...this.currentConfig,
      disabled,
      value: this.control.value,
    };
    this.applyCurrentConfig();
  }

  setRequired(required: boolean): void {
    this.currentConfig = {
      ...this.currentConfig,
      value: this.control.value,
      required,
    };
    this.applyCurrentConfig();
  }

  setEntitySearchType(type: PortalEntityType): void {
    this.currentConfig = {
      ...this.currentConfig,
      lookup: {
        entitySearch: {
          ...(this.currentConfig.lookup?.entitySearch || {}),
          type,
        },
      },
    };
    this.applyCurrentConfig();
  }

  setEntitySearchParams(params: any): void {
    this.currentConfig = {
      ...this.currentConfig,
      lookup: {
        entitySearch: {
          ...(this.currentConfig.lookup?.entitySearch || ({} as any)),
          params,
        },
      },
    };
    this.applyCurrentConfig();
  }

  getEntitySearchParams(): any {
    return this.currentConfig?.lookup?.entitySearch?.params;
  }

  setFixedSelectOptions(options: SelectOption[]): void {
    this.currentConfig = {
      ...this.currentConfig,
      lookup: {
        fixed: options,
      },
    };
    this.applyCurrentConfig();
  }

  setConfig(config: SmartSelectConfig): void {
    this.currentConfig = config;
    this.applyCurrentConfig();
  }

  getValueChanges(): Observable<SmartSelectValue> {
    return this.control.valueChanges;
  }

  private applyCurrentConfig(): void {
    this.required = this.currentConfig.required;
    this.label =
      this.required || this.currentConfig.noOptionalLabel
        ? this.currentConfig.label
        : `${this.currentConfig.label} (optional)`;
    this.disabled = this.currentConfig.disabled;
    this.isEntitySearch = !!this.currentConfig.lookup.entitySearch;
    this.fixedOptions = this.currentConfig.lookup?.fixed || [];
    this.entitySearchType = this.currentConfig.lookup.entitySearch?.type;
    this.entitySearchParams = this.currentConfig.lookup.entitySearch?.params;
    this.entitySearchStatus = this.currentConfig.lookup.entitySearch?.params?.status;
    this.initialValue = this.currentConfig.value;
    this.customErrorMessageKey = this.currentConfig.customValidator?.errorMessageKey;
    this.allowSetValueNotFromList = this.currentConfig.allowSetValueNotFromList;
    this.hideClearButton = this.currentConfig.hideClearButton;
    if (this.isEntitySearch) {
      this.initEntitySearchControls();
    } else {
      this.initFixedSelectControls();
    }
  }

  public setEntityNameGetter(getterFn: EntityNameGetter): void {
    this.entityNameGetter$$.next(getterFn);
  }

  public onEntitySelect(value: PortalEntity): void {
    if (value) {
      SmartSelect._entityNameCache = {
        ...SmartSelect._entityNameCache,
        [value.entityId]: value.label,
      };
    }
    this.control.setValue(value ? { id: value.entityId, label: value.label } : undefined);
    this.displayFormControl.setValue(value?.label || null);
  }

  public onOptionSelect(option: SelectOption | undefined): void {
    this.control.setValue(option ? { id: option?.value, label: option?.displayLabel } : undefined);
    if (this.displayFormControl) {
      this.displayFormControl.setValue(option?.displayLabel || null);
    }
  }

  public onInputEvent(): void {
    if (this.control.value) {
      this.control.setValue(undefined);
    }
  }

  public showErrorIfAny(): void {
    this.control.markAsTouched();
    this.control.updateValueAndValidity();
    if (this.displayFormControl) {
      this.displayFormControl.markAsTouched();
      this.displayFormControl.updateValueAndValidity();
    }
  }

  public dispose(): void {
    this.subscriptions.unsubscribe();
  }

  private initFixedSelectControls(): void {
    // init validators
    const validators = [];
    // select from the list validator
    validators.push((control) => {
      if (!control?.value?.id) {
        return null;
      }
      if (this.fixedOptions.some((o) => o.value === control.value?.id && !o.isInvalidOption)) {
        return null;
      }
      return {
        [this.SELECT_VALUE_FROM_THE_LIST_ERROR_KEY]: 'Please select the value from the list',
      };
    });
    if (this.required) {
      validators.push(this.fixedSelectControlRequiredFn.bind(this));
    }
    if (this.currentConfig.customValidator?.validatorFn) {
      validators.push(this.currentConfig.customValidator.validatorFn);
    }
    const isValueFromList = this.fixedOptions.some((option) => option.value === this.initialValue?.id);
    if (this.initialValue?.id && !isValueFromList && this.allowSetValueNotFromList) {
      this.fixedOptions = [
        ...this.fixedOptions,
        { value: this.initialValue.id, displayLabel: this.initialValue.label, isInvalidOption: true },
      ];
    }
    const initialValueOption = this.fixedOptions.find((option) => option.value === this.initialValue?.id);
    const initialDisplayValue = this.initialValue?.label || initialValueOption?.displayLabel;
    // init or update the real control
    const initialValueWithLabel = initialValueOption ? { ...this.initialValue, label: initialDisplayValue } : undefined;
    if (!this.control) {
      this.control = new UntypedFormControl({ value: initialValueWithLabel, disabled: this.disabled }, validators);
    } else {
      this.control.setValue(initialValueWithLabel);
      this.control.setValidators(validators);
      this.updateControlDisabled(this.control, this.disabled);
    }
  }

  private fixedSelectControlRequiredFn(control: AbstractControl): null | any {
    const value = control?.value;
    if (value?.id) {
      return null;
    } else {
      return {
        required: true,
      };
    }
  }

  private initEntitySearchControls(): void {
    // init validators
    const validators = [];
    validators.push(this.selectOptionFromListValidatorFn.bind(this));
    if (this.required) {
      validators.push(Validators.required);
    }
    if (this.currentConfig.customValidator?.validatorFn) {
      validators.push(this.currentConfig.customValidator.validatorFn);
    }
    // init or update display control
    const initialDisplayValue = this.initialValue?.label;
    if (!this.displayFormControl) {
      this.displayFormControl = new UntypedFormControl(
        { value: initialDisplayValue, disabled: this.disabled },
        validators,
      );
    } else {
      this.displayFormControl.setValue(initialDisplayValue);
      this.displayFormControl.setValidators(validators);
      this.updateControlDisabled(this.displayFormControl, this.disabled);
    }
    // init or update the "real" control
    if (!this.control) {
      this.control = new UntypedFormControl({ value: this.initialValue, disabled: this.disabled }, [
        this.syncWithDisplayControlFn.bind(this),
      ]);
    } else {
      this.control.setValue(this.initialValue);
      this.updateControlDisabled(this.control, this.disabled);
    }
    // get display entity name, once the entity name getter is available
    if (this.initialValue) {
      this.setEntityNameTasks$$.next({
        id: this.initialValue.id,
        type: this.entitySearchType,
      });
    }
  }

  /**
   * Validate if the option was selected from the list
   */
  private selectOptionFromListValidatorFn: ValidatorFn = (displayControl): null | any => {
    if (!displayControl) {
      return null;
    }
    if (!!displayControl.value && !this.control?.value) {
      return {
        [this.SELECT_VALUE_FROM_THE_LIST_ERROR_KEY]: 'Please select the value from the list',
      };
    }
    return null;
  };

  private syncWithDisplayControlFn: ValidatorFn = (realControl): null | any => {
    if (!!this.displayFormControl.value && !realControl?.value) {
      return {
        [this.SELECT_VALUE_FROM_THE_LIST_ERROR_KEY]: 'Please select the value from the list',
      };
    }
    if (this.currentConfig.required && !realControl?.value?.id) {
      return {
        required: true,
      };
    }
    return null;
  };

  private updateControlDisabled(control: UntypedFormControl, disabled: boolean): void {
    if (disabled) {
      control.disable();
    } else {
      control.enable();
    }
  }

  /**
   *Sets display entity name, if entity search was initiated from id
   */
  private setEntityDisplayNameSubscription(): void {
    const getterFn$ = this.entityNameGetter$$.pipe(
      filter((getter) => !!getter),
      take(1),
    );
    const tasks$ = this.setEntityNameTasks$$.pipe(filter((task) => !!(task && task.id)));
    const sub = getterFn$
      .pipe(
        switchMap((getterFn) => {
          return tasks$.pipe(
            switchMap((task) => {
              let result$: Observable<string>;
              if (SmartSelect._entityNameCache[task.id]) {
                result$ = of(SmartSelect._entityNameCache[task.id]);
              } else {
                result$ = getterFn(task.id, task.type);
              }
              return result$.pipe(
                map((entityName) => {
                  if (this.entitySearchType === task.type && this.control.value?.id === task.id) {
                    this.displayFormControl.setValue(entityName);
                    if (!this.control.value?.label) {
                      const id = this.control.value?.id;
                      this.control.setValue({ id, label: entityName }, { emitEvent: false });
                    }
                  }
                }),
              );
            }),
          );
        }),
      )
      .subscribe();
    this.subscriptions.add(sub);
  }
}
