import { Pipe, PipeTransform } from '@angular/core';
import { formatPhone } from '@rootTypes/utils';

@Pipe({
  name: 'phoneFormat',
})
export class PhoneFormatPipe implements PipeTransform {
  transform(value: string): string {
    return formatPhone(value);
  }
}
