import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { MapLocation } from '../interfaces';
import { StreetViewPanoService } from '../street-view-pano.service';

@Component({
  selector: 'wp-google-street-view',
  templateUrl: './street-view.component.html',
  styleUrls: ['./street-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StreetViewComponent implements AfterViewInit, OnInit, OnChanges {
  @Input() public height = '250px';
  @Input() public width = '405px';
  @Input() public location: MapLocation;
  public error: string;

  @ViewChild('streetView') private streetView: ElementRef;
  private panorama: google.maps.StreetViewPanorama;

  constructor(
    private panoService: StreetViewPanoService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.location && changes.location.currentValue) {
      if (this.streetView) {
        this.initStreetView();
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initStreetView();
      this.cd.detectChanges();
    });
  }

  ngOnInit(): void {}

  private async initStreetView(): Promise<void> {
    this.error = null;
    try {
      if (!this.location) {
        return;
      }
      const pano = await this.panoService.findPanorama(this.location);
      this.panorama = new google.maps.StreetViewPanorama(this.streetView.nativeElement as HTMLElement, {
        pov: { heading: 165, pitch: 0 },
        pano,
        zoom: 1,
      });
    } catch (err) {
      this.error = 'Failed to load street view';
    }
    this.cd.detectChanges();
  }
}
