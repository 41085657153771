import { createSelector } from '@ngrx/store';
import { getPrimaryDriverIdByVehicle } from './vehicle-data.selectors';
import { getAllDrivers } from '../../../drivers/store/selectors/driver-data.selectors';

const getDriverEntityStateByVehicle = createSelector(
  getPrimaryDriverIdByVehicle,
  getAllDrivers,
  (driverId, allDrivers) => {
    if (driverId) {
      return allDrivers[driverId];
    }
    return undefined;
  },
);

export const getDriverByVehicle = createSelector(getDriverEntityStateByVehicle, (state) => state?.entity);
