import { Component, OnInit, ChangeDetectionStrategy, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'wp-btn-add-standard',
  templateUrl: './btn-add-standard.component.html',
  styleUrls: ['./btn-add-standard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnAddStandardComponent implements OnInit {
  @Input() public widthStr = 'auto';
  @Input() public disabled: boolean;

  @Output() public btnClicked = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
