import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AbstractPopupComponent } from '../../../core/popupable/types';
import { PopupRef } from '../../../core/popupable/types/popup-ref';
import { PortalEntity, PortalEntityType } from '@rootTypes';
import { ApiService } from '../../../api/api.service';
import { firstValueFrom } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'wp-school-campus-account-select-popup',
  templateUrl: './school-campus-account-select-popup.component.html',
  styleUrls: ['./school-campus-account-select-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolCampusAccountSelectPopupComponent
  implements OnInit, AbstractPopupComponent<{ preSelectDistrictId?: string; preSelectedHint?: string }, PortalEntity>
{
  popupRef: PopupRef<any, any>;

  public searchByTypes = [PortalEntityType.DISTRICT];

  public selectedEntity: PortalEntity;
  public searchInputControl = new FormControl<string>(null);
  public isDistrictLoading = false;

  constructor(
    private api: ApiService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.popupRef.data?.preSelectDistrictId) {
      this.loadSelectedEntityFromStorage();
    }
  }

  public get isDistrictPreselected(): boolean {
    return !!this.selectedEntity && this.popupRef.data?.preSelectDistrictId === this.selectedEntity?.entityId;
  }

  public onCloseClick(): void {
    this.popupRef.close();
  }

  public onConfirmClick(): void {
    this.popupRef.close(this.selectedEntity);
  }

  public onSearchSelect(event: PortalEntity): void {
    this.selectedEntity = event;
  }

  public onSearchCleared(): void {
    this.selectedEntity = undefined;
  }

  private async loadSelectedEntityFromStorage(): Promise<void> {
    this.isDistrictLoading = true;
    try {
      const district = await firstValueFrom(
        this.api.getDistrict({ districtId: this.popupRef.data.preSelectDistrictId }),
      );
      this.selectedEntity = {
        entityId: this.popupRef.data.preSelectDistrictId,
        type: PortalEntityType.DISTRICT,
        label: district.district.name,
      };
      this.searchInputControl.setValue(district.district.name);
    } catch (e) {
      console.log(e);
    } finally {
      this.isDistrictLoading = false;
      this.cd.detectChanges();
    }
  }
}
