export interface VehicleInspectionListAdvancedFilterPill {
  type: VehicleInspectionListAdvancedFilterType;
  label: string;
  value: any;
}

export enum VehicleInspectionListAdvancedFilterType {
  MECHANIC_ASSIGNMENT_STATUS = 'MECHANIC_ASSIGNMENT_STATUS',
  DRIVER_REVIEW_STATUS = 'DRIVER_REVIEW_STATUS',
  INSPECTION_STATUS = 'INSPECTION_STATUS',
  MECHANIC_ASSESSMENT_STATUS = 'MECHANIC_ASSESSMENT_STATUS',
}
