import { ChangeDetectionStrategy, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Store } from '@ngrx/store';
import { interval, Observable, of } from 'rxjs';
import { filter, map, mergeMap, shareReplay, startWith, take, takeWhile } from 'rxjs/operators';

import { AuthApiService } from '../../services/auth-api.service';
import { logoutOnEmployeeImpersonationSessionEnd } from '../../store';

@Component({
  selector: 'wp-employee-impersonation-session-timer',
  template: `
    <div *ngIf="isTimerShown$ | async" class="timer" [ngClass]="{ warning: isWarning$ | async }">
      Session ends in {{ timerHHMMSS$ | async }}
    </div>
  `,
  styles: [
    `
      .timer {
        position: fixed;
        top: 0;
        left: 0;
        padding: 2px 8px;
        font-size: 12px;
        font-weight: 600;
        border-radius: 2px;
        background-color: rgba(220, 220, 34, 0.5);
      }
      .timer.warning {
        background-color: rgba(226, 64, 40, 0.4);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgClass, NgIf],
})
export class EmployeeImpersonationSessionTimerComponent implements OnInit {
  public isTimerShown$: Observable<boolean>;
  public timerHHMMSS$: Observable<string>;
  public isWarning$: Observable<boolean>;

  constructor(
    private store: Store,
    private auth: AuthApiService,
    private destroyRef: DestroyRef,
  ) {}

  public ngOnInit(): void {
    const impersonationSession = this.auth.getEmployeeImpersonationSessionDetails().pipe(take(1), shareReplay(1));
    this.isTimerShown$ = impersonationSession.pipe(map((session) => !!session));

    const secondsLeft$: Observable<number> = impersonationSession.pipe(
      mergeMap((session) => {
        if (!session) {
          return of(null);
        }
        const durationInSeconds = Math.floor((session.authenticationExpirationTimestamp - Date.now()) / 1000);
        return interval(1000).pipe(
          map((value) => durationInSeconds - (value + 1)),
          takeWhile((secondsLeft) => secondsLeft >= 0),
          startWith(durationInSeconds),
          shareReplay(1),
        );
      }),
    );

    this.timerHHMMSS$ = secondsLeft$.pipe(
      map((secondsLeft) => {
        return new Date(new Date(null).setSeconds(secondsLeft)).toISOString().slice(11, 19);
      }),
    );
    this.isWarning$ = secondsLeft$.pipe(map((secondsLeft) => secondsLeft <= 120));

    secondsLeft$
      .pipe(
        filter((secondsLeft) => secondsLeft === 0),
        take(1),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.store.dispatch(logoutOnEmployeeImpersonationSessionEnd());
      });
  }
}
