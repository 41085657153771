import { createSelector } from '@ngrx/store';
import { getVehicleFeature } from './get-feature';
import * as fromTypes from '../../types';
import { VehicleListState } from '../reducers/vehicle-lists.reducer';
import { PortalEntity, VehicleListFilterType } from '../../types';

const getAllVehiclesListState = createSelector(getVehicleFeature, (state) => state.lists);

export const getVehicleListState = createSelector(
  getAllVehiclesListState,
  (state, props: { listName: fromTypes.VehicleListName }): VehicleListState => state[props.listName],
);

export const isVehiclesSectionInitialized = createSelector(getAllVehiclesListState, (allStates) => {
  const state = allStates[fromTypes.VehicleListName.VEHICLES];
  return state?.isVehiclesSectionInitialized || false;
});

/**
 * Pagination
 */
export const getPaginationState = createSelector(getVehicleListState, (state) => state.pagination);

export const getPage = createSelector(getPaginationState, (state) => state.page);

export const getPageSize = createSelector(getPaginationState, (state) => state.pageSize);

/**
 * Page load
 */
export const getPageState = createSelector(getVehicleListState, (state) => state.page);

export const getListLoading = createSelector(getPageState, (state) => state.isLoading);

export const getListItems = createSelector(getPageState, (state) => state.items);

export const getListError = createSelector(getPageState, (state) => state.listError);

export const getTotalFound = createSelector(getPageState, (state) => state.totalFound);

/**
 * Search
 */
export const getCurrentSearch = createSelector(getVehicleListState, (state) => state.currentSearch);

/**
 * Status
 */
export const getCurrentStatus = createSelector(getVehicleListState, (state) => state.status);

/**
 * Selected Vehicle
 */
export const getSelectedVehicleId = createSelector(getVehicleListState, (state) => state.selectedVehicleId);

/**
 * Multi account option id
 */
export const getSelectedMultiAccountOptionId = createSelector(getVehicleListState, (state) => {
  return (state.filters.find((f) => f.type === VehicleListFilterType.MULTI_ACCOUNT)?.payload as PortalEntity)?.entityId;
});
