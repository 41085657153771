<div class="icon-success" style="width: 4px; height: 20px">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g fill="none" fill-rule="evenodd" stroke="#47A540" stroke-width="2">
      <g stroke-linecap="square">
        <path
          d="M3.668 6.904L9.462 1M1.435 4.111l2.233 2.29"
          transform="translate(4.77 6.778)"
        />
      </g>
      <circle cx="10" cy="10" r="9" />
    </g>
  </svg>
</div>
