import { EntityStatus, PortalEntityType } from '@rootTypes';
import {
  EntityFilterOptions,
  EntityFilterRequest,
  EntityFilterRequestType,
  EntityFilterStatus,
} from '../../../api/endpoints/entity-filter';
import { entitySearchConfig } from '@rootTypes/utils/portal-entity/entity-search-config';

export const getEntityFilterRequest = (
  query: string,
  type: PortalEntityType,
  status: EntityStatus,
  skip: number,
  limit: number,
  options?: EntityFilterOptions,
): EntityFilterRequest => {
  let request: EntityFilterRequest = {
    query,
    type: entitySearchConfig[type].entityFilterRequestType || (type as unknown as EntityFilterRequestType),
    skip,
    limit,
  };
  const requestStatus = portalEntityStatusToEntityFilterStatus(status);
  if (status) {
    request.status = requestStatus;
  }
  if (options) {
    request = { ...request, ...options };
  }
  return request;
};

export const portalEntityStatusToEntityFilterStatus = (status: EntityStatus): EntityFilterStatus | undefined => {
  if (status === EntityStatus.ANY) {
    return undefined;
  } else if (!status) {
    return undefined;
  }
  return status === EntityStatus.ACTIVE ? EntityFilterStatus.ACTIVE : EntityFilterStatus.INACTIVE;
};
