import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SelectOption } from '../index';

@Component({
  selector: 'wp-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrl: './multi-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent {
  @Input() public label: string;
  @Input() public control: UntypedFormControl;
  @Input() public options?: SelectOption[];
  @Input() public customErrorMessageKey?: string;
  @Input() public notSelectedFromTheListError?: string;
}
