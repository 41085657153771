<div class="wp-autocomplete">
  <mat-form-field [hideRequiredMarker]="true" class="form-field">
    <mat-label>{{label}}</mat-label>

    <input
      type="text"
      matInput
      [formControl]="displayControl"
      [matAutocomplete]="autocomplete"
      [tabIndex]="tabIndex"
      #autocompleteInput
      (click)="$event.stopPropagation()"
    />
    <div matPrefix>
      <ng-content select=".input-prefix"></ng-content>
    </div>
    <div matSuffix *ngIf="!displayControl.value">
      <ng-content select=".input-suffix"></ng-content>
    </div>
    <div matSuffix *ngIf="displayControl.value && !displayControl.disabled">
      <div style="cursor: pointer" (click)="onClearInput($event)">
        <wp-icon-close [size]="'small'"></wp-icon-close>
      </div>
    </div>
    <mat-autocomplete #autocomplete="matAutocomplete" (optionSelected)="onOptionSelected($event)">
      <ng-container *ngIf="isOptionGroups$ | async; else ungroupedOptions;">
        <mat-optgroup *ngFor="let group of searchedOptionGroups$ | async" [label]="group.label">
          <mat-option *ngFor="let option of group.options" [value]="option.displayLabel" [disabled]="option.disabled">
            <div class="option">{{ option.displayLabel }}</div>
          </mat-option>
        </mat-optgroup>
      </ng-container>
      <ng-template #ungroupedOptions>
        <mat-option
          *ngFor="let option of searchedOptions$ | async"
          [value]="option.displayLabel"
          [disabled]="option.disabled"
        >
          <div class="option">{{ option.displayLabel }}</div>
        </mat-option>
      </ng-template>
    </mat-autocomplete>
    <mat-error *ngIf="displayControl.hasError('required')"> {{requiredErrorText}} </mat-error>
  </mat-form-field>
</div>
