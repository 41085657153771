import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as fromTypes from '../types';
import * as fromApi from '../../../api';
import { ApiService } from '../../../api/api.service';
import { map } from 'rxjs/operators';
import { entitySearchResultToPortalEntity } from '@rootTypes/utils/portal-entity/entity-search-result-to-portal-enity';

interface LoadListResponse {
  total: number;
  items: fromTypes.PortalEntity[];
}

interface LoadYardResponse {
  yard: fromTypes.Yard;
  vendor?: fromTypes.Vendor;
}

@Injectable()
export class YardsApiService {
  constructor(private apiService: ApiService) {}

  public getYard(yardId: string, options?: fromTypes.LoadYardOptions): Observable<LoadYardResponse> {
    const withVendor = !!options?.withVendor;
    const request = {
      yardId,
    } as fromApi.getYard.GetYardRequest;
    if (withVendor) {
      request._projections = [fromApi.getYard.YardProjection.vendor];
    }
    return this.apiService.getYard(request).pipe(
      map((resp) => {
        const { yard, _projections } = resp;
        const apiVendor = _projections?.vendor;
        return {
          yard,
          vendor: apiVendor ? fromApi.getVendorProfile.convertors.getVendorFromApi(apiVendor) : undefined,
        };
      }),
    );
  }

  public entityFilter(request: fromApi.entityFilter.EntityFilterRequest): Observable<LoadListResponse> {
    return this.apiService.entityFilter(request).pipe(
      map((resp) => {
        return {
          total: resp.total,
          items: (resp.results || []).map((source) => entitySearchResultToPortalEntity(source)),
        };
      }),
    );
  }
}
