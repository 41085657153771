import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-portal-icon-fit',
  templateUrl: './portal-icon-fit.component.html',
  styleUrls: ['./portal-icon-fit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalIconFitComponent implements OnInit {
  @Input() public path: string;
  @Input() public widthStr = 'auto';
  @Input() public heightStr = 'auto';
  @Input() public alt: string;

  // public backgroundStyle: string;

  constructor() {}

  ngOnInit(): void {}
}
