<div class="wp-photo-input-popup">
  <input
    id="upload-area-input"
    #imageFileInput
    type="file"
    accept="image/png, image/jpeg"
    (change)="onImageFileChange($event)"
  />

  <div class="header">
    <span>Upload image</span>
    <wp-icon-close class="header-close-btn" (click)="onClose()">
    </wp-icon-close>
  </div>

  <div
    *ngIf="isSourceImageLoading; else content"
    [ngStyle]="sourceImageLoadingContainerStyles"
  >
    <wp-section-spinner [sectionHeight]="'100%'"></wp-section-spinner>
  </div>

  <ng-template #content>
    <div class="content">
      <wp-alert-critical
        *ngIf="isSourceImageLoadError"
        class="source-image-error"
        (closed)="isSourceImageLoadError = false"
        >Failed to load image
      </wp-alert-critical>

      <div class="cropper-container">
        <div class="image-area" [ngStyle]="uploadAreaStyles">
          <label
            class="upload-area-btn"
            *ngIf="!imageLoaded && !imageLoading"
            for="upload-area-input"
          >
            <i
              class="upload-area-btn-icon"
              [ngStyle]="uploadAreaIconStyles"
            ></i>
            <div>Upload an image</div>

            <span class="image-error" *ngIf="imageLoadError">
              Failed to load the image
            </span>
          </label>

          <div
            class="image-loading"
            [style.display]="imageLoading ? null : 'none'"
          >
            Loading ...
          </div>

          <image-cropper
            [style.display]="imageLoaded ? null : 'none'"
            [maintainAspectRatio]="true"
            [containWithinAspectRatio]="true"
            [aspectRatio]="aspectRatio"
            [roundCropper]="false"
            [alignImage]="'center'"
            [backgroundColor]="'#dfdfe3'"
            [format]="'jpeg'"
            [output]="'base64'"
            [resizeToWidth]="resizeToWidth"
            [imageBase64]="sourceImageBase64"
            [imageChangedEvent]="imageFileChangeEvent"
            (imageLoaded)="onImageLoaded()"
            (loadImageFailed)="onLoadImageFailed()"
            (imageCropped)="onImageCropped($event)"
          >
          </image-cropper>
        </div>

        <div
          class="cropper-vertical-line"
          [ngStyle]="{height: verticalLineHeight}"
        ></div>

        <div class="image-result" [ngStyle]="previewStyles">
          <div class="image-result-header">
            <span *ngIf="imageLoaded; else noImage"> Image preview </span>
            <ng-template #noImage>
              <span>No image to preview </span>
            </ng-template>
          </div>

          <wp-display-photo
            [width]="previewWidth"
            [height]="previewHeight"
            [isRound]="isRoundPreview"
            [placeholderType]="placeholder"
            [imageBase64]="resultImageBase64"
          >
          </wp-display-photo>
        </div>
      </div>

      <div class="buttons-panel">
        <ng-container *ngIf="imageLoaded">
          <label for="upload-area-input">
            <wp-text-formatted-as-link>
              <i class="upload-btn-icon"></i>
              <span>Upload image</span>
            </wp-text-formatted-as-link>
          </label>

          <!-- <div class="vertical-line"></div>
          
        <wp-link-btn
          (clicked)="onRemoveImage()"
          >
          <i class="remove-btn-icon"></i>
          <span>Remove image</span>
        </wp-link-btn> -->
        </ng-container>
      </div>
    </div>
  </ng-template>

  <div class="footer">
    <wp-btn-primary
      [width]="'175px'"
      [disabled]="imageLoading || !imageLoaded"
      (clicked)="onSave()"
      >Save
    </wp-btn-primary>
  </div>
</div>
