<mat-form-field class="wp-input-number" [hideRequiredMarker]="true">
  <mat-label *ngIf="!!label">{{label}}</mat-label>
  <div
    class="prefix-dollar"
    *ngIf="isPrefixDollar"
    matPrefix
    [ngClass]="{disabled: disabled}"
  >
    $
  </div>
  <input matInput type="number" [formControl]="control" [step]="step" />
  <mat-error *ngIf="control.hasError('required')">Required field</mat-error>
  <mat-error *ngIf="control.hasError('min')"
    >Should not be less than {{control?.errors?.min?.min}}
  </mat-error>
  <mat-error *ngIf="control.hasError('max')"
    >Should not exceed {{control?.errors?.max?.max}}
  </mat-error>
</mat-form-field>
