<div
  class="stack-layout"
  [ngStyle]="{
    'flex-direction': direction,
    'align-items': secondaryAxisAlignment,
    'justify-content': mainAxisAlignment
  }"
>
  <ng-content
    select="wp-stack-layout-item"
    class="stack-layout-item"
  ></ng-content>
</div>
