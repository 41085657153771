import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import { Observable } from 'rxjs';
import * as fromDataSelectors from '../selectors/student-data.selectors';
import * as fromDataActions from '../actions/student-data.actions';
import * as fromTypes from '../../types';
import { Injectable } from '@angular/core';
import { loadDistrictRequestedFromStudentDetails } from '../../../districts/store/actions';
import { getDistrict } from '../../../districts/store/selectors';
import { District } from '@rootTypes/entities/districts';
import { getCampus } from 'src/app/data/campuses/store/selectors/campus-data.selectors';
import { loadCampusDetailsRequestedFromStudentDetails } from '../../../campuses/store/actions';
import { getCampusLocationsRequestedFromStudentDetails } from '../../../campuses/store/actions/campus-location-data.actions';
import { CampusLocation } from '@rootTypes/entities/campus';
import { getLocation } from '../../../campuses/store/selectors/locations-data.selectors';
import { ApiStudentWithProjections } from '@apiEntities';
import { StudentLoadOptions } from '../../types/student-load-options';
import { StudentProgramCard } from '../../types/student-program-card';
import { getStudentProgramCards } from '../selectors/student-data.selectors';

@Injectable()
export class StudentDataFacadeService {
  constructor(private store: Store<State>) {}

  public onLoadStudent(studentId: string, options?: StudentLoadOptions): void {
    this.store.dispatch(
      fromDataActions.loadStudentDetailsRequested({
        studentId,
        options,
      }),
    );
  }

  public onLoadStudentVerificationStatusRequested(studentId: string): void {
    this.store.dispatch(fromDataActions.getStudentVerificationStatusRequested({ studentId }));
  }

  public getStudent$(studentId: string): Observable<ApiStudentWithProjections> {
    return this.store.select(fromDataSelectors.getStudent(studentId));
  }

  public getStudentLoading$(studentId: string): Observable<boolean> {
    return this.store.select(fromDataSelectors.getStudentLoading(studentId));
  }

  public getStudentError$(studentId: string): Observable<fromTypes.WpError | null> {
    return this.store.select(fromDataSelectors.getStudentError(studentId));
  }

  public getStudentVerificationStatusForRoutesDistrict$(
    studentId: string,
  ): Observable<fromTypes.rider.VerificationStatus> {
    return this.store.select(fromDataSelectors.getStudentVerificationStatusForRoutesDistrict(studentId));
  }

  public getStudentPrimaryVerificationStatus(studentId: string): Observable<fromTypes.rider.VerificationStatus> {
    return this.store.select(fromDataSelectors.getPrimaryStudentVerificationStatus(studentId));
  }

  public getStudentVerificationStatusLoading$(studentId: string): Observable<boolean> {
    return this.store.select(fromDataSelectors.getStudentVerificationStatusLoading(studentId));
  }

  // school and campuses
  public onGetSchoolRequested(districtId: string): void {
    this.store.dispatch(loadDistrictRequestedFromStudentDetails({ districtId, options: {} }));
  }

  public onGetCampusRequested(campusId: string): void {
    this.store.dispatch(loadCampusDetailsRequestedFromStudentDetails({ campusId }));
  }

  public onGetLocationsRequested(districtId: string, campusId: string): void {
    this.store.dispatch(getCampusLocationsRequestedFromStudentDetails({ districtId, campusId }));
  }

  public getSchool(schoolId: string): Observable<District> {
    return this.store.select(getDistrict(schoolId));
  }

  public getCampus(campusId: string): Observable<fromTypes.campus.Campus> {
    return this.store.select(getCampus(campusId), { campusId });
  }

  public getLocation(locationId: string): Observable<CampusLocation> {
    return this.store.select(getLocation(locationId));
  }

  public getStudentProgramCards(studentId: string): Observable<StudentProgramCard[]> {
    return this.store.select(getStudentProgramCards(studentId));
  }
}
