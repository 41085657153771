import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { go } from '@rootStore';
import { userProfileRoute } from 'src/app/router/portal-routes/home';
import { changePasswordRequested, initChangePasswordState } from '../actions';
import { changePasswordErrorMsg, isChangePasswordLoading } from '../selectors';

function confirmNewPasswordValidator(control: AbstractControl): ValidationErrors | null {
  if (!control.value || this.newPassword.value === control.value) {
    return null;
  }
  return {
    [this.confirmNewPasswordControlErrorKey]: 'New passwords do not match',
  };
}

@Injectable()
export class ChangePasswordFacade {
  public currentPassword: UntypedFormControl;
  public newPassword: UntypedFormControl;
  public confirmNewPassword: UntypedFormControl;
  public confirmNewPasswordControlErrorKey = 'confirmNewPassword';
  public formValid$: Observable<boolean>;

  private form: UntypedFormGroup;
  private sub = new Subscription();

  constructor(private store: Store) {}

  public init(): void {
    this.store.dispatch(initChangePasswordState());

    this.currentPassword = new UntypedFormControl('', [Validators.required]);
    this.newPassword = new UntypedFormControl('', [Validators.required, Validators.minLength(6)]);
    this.confirmNewPassword = new UntypedFormControl('', [Validators.required, confirmNewPasswordValidator.bind(this)]);

    const newPasswordSub = this.newPassword.valueChanges.subscribe(() => {
      if (this.confirmNewPassword.value) {
        this.confirmNewPassword.updateValueAndValidity();
      }
    });
    this.sub.add(newPasswordSub);

    this.form = new UntypedFormGroup({
      currentPassword: this.currentPassword,
      newPassword: this.newPassword,
      confirmNewPassword: this.confirmNewPassword,
    });

    this.formValid$ = this.form.statusChanges.pipe(
      map((status) => {
        return status === 'VALID';
      }),
      startWith(false),
    );
  }

  public unsubscribe(): void {
    this.sub.unsubscribe();
  }

  public changePassword(): void {
    if (!this.form.valid) {
      return;
    }
    this.store.dispatch(
      changePasswordRequested({
        oldPassword: this.currentPassword.value,
        newPassword: this.newPassword.value,
      }),
    );
  }

  public goUserProfile(): void {
    return this.store.dispatch(go(userProfileRoute.request()));
  }

  public getIsLoading$(): Observable<boolean> {
    return this.store.select(isChangePasswordLoading);
  }

  public getErrorMsg$(): Observable<string> {
    return this.store.select(changePasswordErrorMsg);
  }
}
