<div class="toggle-expand-btn">
  <ng-container *ngIf="isExpanded; then hide; else show"></ng-container>

  <ng-template #hide>
    <span (click)="toggle()">
      <span class="toggle-equipment">
        <span class="label" [ngStyle]="{ width: fixLabelWidth }"
          >{{ expandedText }}</span
        >
        <wp-icon-arrow-down class="icon rotate"></wp-icon-arrow-down>
      </span>
    </span>
  </ng-template>

  <ng-template #show>
    <span (click)="toggle()">
      <span class="toggle-equipment">
        <span class="label" [ngStyle]="{ width: fixLabelWidth }"
          >{{ collapsedText }}</span
        >
        <wp-icon-arrow-down class="icon"></wp-icon-arrow-down>
      </span>
    </span>
  </ng-template>
</div>
