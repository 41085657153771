import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-option-group',
  templateUrl: './option-group.component.html',
  styleUrls: ['./option-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionGroupComponent implements OnInit {
  @Input() public groupName: string;

  constructor() {}

  ngOnInit(): void {}
}
