import { CampusListFacade } from './campus-list.facade';
export { CampusListFacade };

import { CampusDataFacade } from './campus-data.facade';
export { CampusDataFacade };

import { detailsFacades } from './campus-details';

import { CampusCalendarDataFacade } from './campus-calendar-data.facade';

export const facadesArray = [CampusListFacade, CampusDataFacade, CampusCalendarDataFacade, ...detailsFacades];
