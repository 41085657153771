import { createReducer, on } from '@ngrx/store';
import * as fromTypes from '@rootTypes';
import * as fromActions from '../actions';
import { cleanUpStore } from '@rootStore';
import { BusAddress } from '@apiEntities/district/bus-address';

export interface DistrictDataState {
  allDistrictChecks: {
    isLoading: boolean;
    entity: fromTypes.Check[];
    error: fromTypes.WpError;
  };
  districts: {
    [districtId: string]: {
      isLoading: boolean;
      error: fromTypes.WpError;
      entity: fromTypes.district.District;
    };
  };
  calendars: {
    [districtId: string]: {
      isLoading: boolean;
      error: fromTypes.WpError;
      entity: fromTypes.district.DistrictCalendar;
    };
  };
  busAddresses: {
    [districtId: string]: {
      isLoading: boolean;
      error: fromTypes.WpError;
      entity: BusAddress[];
    };
  };
}

export const getInitialDistrictDataState = () => ({
  ...initialDistrictDataState,
});

const initialDistrictDataState: DistrictDataState = {
  allDistrictChecks: {
    isLoading: false,
    entity: null,
    error: null,
  },
  districts: {},
  calendars: {},
  busAddresses: {},
};

export const districtDataReducer = createReducer<DistrictDataState>(
  getInitialDistrictDataState(),
  on(cleanUpStore, getInitialDistrictDataState),
  on(
    fromActions.loadDistrictRequested,
    fromActions.loadDistrictRequestedOnCreateCampusDistrictChange,
    fromActions.loadDistrictRequestedOnInitCampusCalendarEditor,
    fromActions.loadDistrictRequestedFromStudentDetails,
    (state, action) => {
      const { districtId } = action;
      const districtEntityState: DistrictDataState['districts'][string] = state.districts[districtId] || {
        error: null,
        entity: null,
        isLoading: false,
      };
      return {
        ...state,
        districts: {
          ...state.districts,
          [districtId]: {
            ...districtEntityState,
            isLoading: !districtEntityState.entity || action.forceLoading,
          },
        },
      };
    },
  ),
  on(fromActions.loadDistrictSuccess, (state, action) => {
    const { districtId, payload } = action;
    const { district } = payload;
    const districtEntityState: DistrictDataState['districts'][string] = state.districts[districtId] || {
      error: null,
      entity: null,
      isLoading: false,
    };
    const result: DistrictDataState = {
      ...state,
      districts: {
        ...state.districts,
        [districtId]: {
          ...districtEntityState,
          isLoading: false,
          entity: district,
          error: null,
        },
      },
    };
    return result;
  }),
  on(fromActions.loadDistrictFailed, (state, action) => {
    const { districtId, error } = action;
    const districtEntityState: DistrictDataState['districts'][string] = state.districts[districtId] || {
      error: null,
      entity: null,
      isLoading: false,
    };
    return {
      ...state,
      districts: {
        ...state.districts,
        [districtId]: {
          ...districtEntityState,
          isLoading: false,
          error,
        },
      },
    };
  }),
  /**
   * Load all checks
   */
  on(fromActions.loadAllDistrictChecksRequested, (state, action) => {
    return {
      ...state,
      allDistrictChecks: {
        ...state.allDistrictChecks,
        isLoading: !state.allDistrictChecks.entity,
      },
    };
  }),
  on(fromActions.loadAllDistrictChecksSuccess, (state, action) => {
    return {
      ...state,
      allDistrictChecks: {
        ...state.allDistrictChecks,
        isLoading: false,
        entity: action.checks,
      },
    };
  }),
  on(fromActions.loadAllDistrictChecksFailed, (state, action) => {
    return {
      ...state,
      allDistrictChecks: {
        ...state.allDistrictChecks,
        isLoading: false,
        error: action.error,
      },
    };
  }),
  // on(fromActions.getBusAddressesRequested, (state, action) => {
  //   const { districtId } = action;
  //   return {
  //     ...state,
  //     busAddresses: {
  //       ...state.busAddresses,
  //       [districtId]: {
  //         isLoading: true,
  //         entity: undefined,
  //         error: undefined,
  //       },
  //     },
  //   };
  // }),
  on(fromActions.getBusAddressesSuccess, (state, action) => {
    const { districtId, addresses } = action;
    return {
      ...state,
      busAddresses: {
        ...state.busAddresses,
        [districtId]: {
          isLoading: false,
          entity: addresses,
          error: undefined,
        },
      },
    };
  }),
  on(fromActions.getBusAddressesFailed, (state, action) => {
    const { districtId, error } = action;
    return {
      ...state,
      busAddresses: {
        ...state.busAddresses,
        [districtId]: {
          isLoading: false,
          entity: undefined,
          error,
        },
      },
    };
  }),
);
