import { GetRouteResponse } from '../get-route';
import { EventsByRidersWeekdayDependent } from 'src/app/types/entities/route';
import * as fromRootUtils from '../../../../types/utils';
import { PrefixedDayOfWeek } from '../../common/day-of-week';

export const dbToEventIdsByRidersByWeekdays = (src: GetRouteResponse): EventsByRidersWeekdayDependent => {
  const target = {} as EventsByRidersWeekdayDependent;
  if (src && src.riders) {
    Object.keys(src.riders).forEach((riderId) => {
      target[riderId] = {};
      const riderItem = src.riders[riderId];
      if (riderItem.active_days) {
        Object.keys(riderItem.active_days).forEach((dayId: PrefixedDayOfWeek) => {
          const weekday = fromRootUtils.date.getWeekdayFromDayPrefixedWeekday(dayId);
          const activeDayItem = riderItem.active_days[dayId];
          target[riderId][weekday] = {
            pickupId: activeDayItem.events.pickup_event_id,
            dropoffId: activeDayItem.events.dropoff_event_id,
          };
        });
      }
    });
  }
  return target;
};
