import { MapLocation } from '@rootTypes';
import { pointsOnSameLineRatio } from './utils';

export class NearestPointOnPolylinePathNaive {
  constructor(private path: MapLocation[]) {}

  findNearestPoints(point: MapLocation): { start: MapLocation; end: MapLocation } {
    let minRatio = Number.MAX_VALUE;
    let minStart: MapLocation;
    let minEnd: MapLocation;
    for (let i = this.path.length - 1; i > 0; i--) {
      const start = this.path[i - 1];
      const end = this.path[i];
      const ratio = pointsOnSameLineRatio(start, end, point);
      if (ratio < minRatio) {
        minRatio = ratio;
        minStart = start;
        minEnd = end;
      }
    }
    return { start: minStart, end: minEnd };
  }
}
