import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import { Observable } from 'rxjs/internal/Observable';
import * as fromActions from '../actions/employee-profile-editor.actions';
import * as fromSelectors from '../selectors/employee-profile-editor.selector';
import * as fromTypes from '../../types';

@Injectable()
export class EmployeeProfileEditorFacade {
  constructor(private store: Store<State>) {}

  public getCreateForDistrict$(): Observable<fromTypes.District> {
    return this.store.select(fromSelectors.getCreateForDistrict);
  }

  public getCreateForDistrictLoading$(): Observable<boolean> {
    return this.store.select(fromSelectors.getCreateForDistrictLoading);
  }

  public getCreateForDistrictError$(): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getCreateForDistrictError);
  }

  public getOpenEmployeeEditModel$(): Observable<fromTypes.EmployeeProfileEditModel> {
    return this.store.select(fromSelectors.getOpenEmployeeEditModel);
  }

  public getOpenEmployeeLoading$(): Observable<boolean> {
    return this.store.select(fromSelectors.getOpenEmployeeLoading);
  }

  public getCreateOrUpdateRequestLoading$(): Observable<boolean> {
    return this.store.select(fromSelectors.getRequestLoading);
  }

  public getUpdateEmployeeModel$(): Observable<fromTypes.EmployeeProfileEditModel> {
    return this.store.select(fromSelectors.getCurrentUpdatedModel);
  }

  public getUpdateEmployeeModelValid$(): Observable<boolean> {
    return this.store.select(fromSelectors.getCurrentUpdatedModelValid);
  }

  public onInitEmployeeProfileEditor(employeeId?: string, createForDistrictId?: string): void {
    this.store.dispatch(fromActions.initEmployeeProfileEditor({ employeeId, createForDistrictId }));
  }

  public onUpdatedEmployeeModel(model: fromTypes.EmployeeProfileEditModel): void {
    this.store.dispatch(fromActions.updatedEmployeeModel({ model }));
  }

  public onUpdatedEmployeeModelValid(valid: boolean): void {
    this.store.dispatch(fromActions.updatedEmployeeModelValid({ valid }));
  }

  public onCreateEmployee(): void {
    this.store.dispatch(fromActions.createEmployeeRequested());
  }

  public onUpdateEmployee(): void {
    this.store.dispatch(fromActions.updateEmployeeRequested());
  }
}
