import { CampusCalendar } from '../../../../types/entities/campus';
import { ApiCampusCalendar } from '../../common';
import { getCalendarExclusionFromApi } from '../../common/convertors';

export const getCampusCalendarFromApi = (source: ApiCampusCalendar): CampusCalendar => {
  const res: CampusCalendar = {
    id: source.calendarId,
    isDefault: source.isDefault,
    name: source.name,
    startDate: source.startDate,
    endDate: source.endDate,
    exclusions: (source.exclusions || []).map((s) => getCalendarExclusionFromApi(s)),
  };
  return res;
};
