import { createSelector } from '@ngrx/store';
import { PortalEntityType } from '@rootTypes';

import { getActivityDisplayDate, getActivityEntityType, timesheetEntryDataFeature } from './selectors';

/**
 * Context
 */
// activities
export const getAllActivityContextState = createSelector(
  timesheetEntryDataFeature,
  (state) => state.dayContextActivities,
);

export const getActivityContextState = (activityId) =>
  createSelector(getAllActivityContextState, (state) => state[activityId]);

export const isContextActivitiesLoading = (activityId) =>
  createSelector(getActivityContextState(activityId), (state) => state?.isLoading);

export const getContextActivityItems = (activityId) =>
  createSelector(getActivityContextState(activityId), (state) => state?.entity);

export const getContextActivityError = (activityId) =>
  createSelector(getActivityContextState(activityId), (state) => state?.error);

// specific items (i.e rides for a driver);

export const getAllSpecificContextState = createSelector(
  timesheetEntryDataFeature,
  (state) => state.dayContextSpecific,
);

export const getSpecificContextState = (activityId) =>
  createSelector(getAllSpecificContextState, (state) => state[activityId]);

export const getSpecificContextEntityType = (activityId) =>
  createSelector(getActivityEntityType(activityId), (entityType) => {
    if (!entityType) {
      return undefined;
    }
    switch (entityType) {
      case PortalEntityType.DRIVER:
        return PortalEntityType.RIDE;
      default:
        throw new Error('Unknown activity user entity type');
    }
  });

export const getDisplaySpecificContextEntityTypeLabel = (activityId) =>
  createSelector(getSpecificContextEntityType(activityId), (state) => {
    if (!state) {
      return undefined;
    }
    return getDisplayEntityTypeLabelFn(state);
  });

export const getSpecificContextTitle = (activityId) =>
  createSelector(
    getDisplaySpecificContextEntityTypeLabel(activityId),
    getActivityDisplayDate(activityId),
    (entityLabel, displayDate) => {
      return `Scheduled ${entityLabel}s for ${displayDate}`;
    },
  );

export const isSpecificContextLoading = (activityId) =>
  createSelector(getSpecificContextState(activityId), (state) => state?.isLoading);

export const getSpecificContextItems = (activityId) =>
  createSelector(getSpecificContextState(activityId), (state) => state?.entity);
export const getSpecificContextError = (activityId) =>
  createSelector(getSpecificContextState(activityId), (state) => state?.error);

export const isContextLoading = (activityId) =>
  createSelector(
    isContextActivitiesLoading(activityId),
    isSpecificContextLoading(activityId),
    (isActivitiesLoading, isSpecificLoading) => isActivitiesLoading || isSpecificLoading,
  );

function getDisplayEntityTypeLabelFn(entityType: PortalEntityType): string {
  switch (entityType) {
    case PortalEntityType.RIDE:
      return 'ride';
    default:
      throw new Error('Unknown entity type');
  }
}
