import { createAction, props } from '@ngrx/store';

import { SegmentIdentifyInfo } from '../types';

export const enableSegment = createAction('[App] Enable Segment');

const segmentEffects = '[SegmentEffects]';

export const segmentIdentifyUserRequested = createAction(
  `${segmentEffects} Identify user requested`,
  props<{ data: SegmentIdentifyInfo; userId: string }>(),
);
// Since Segment does not have error callbacks, we can't handle errors
export const segmentIdentifyUserSuccess = createAction(`${segmentEffects} Identify user success`);

export const segmentResetUserSuccess = createAction(`${segmentEffects} Reset user success`);
