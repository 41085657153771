import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const modulePrefix = 'Data';
const sectionPrefix = 'Route List';

export const initializedRouteList = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Initialized route list`,
  props<{ listName: fromTypes.RouteListNames }>(),
);

export const setRouteListFromSnapshot = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Set route list state from snapshot`,
  props<{ listName: fromTypes.RouteListNames; snapshot: fromTypes.RouteListSnapshot }>(),
);

export const weekdaySelectedChanged = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Weekday selected changed`,
  props<{ listName: fromTypes.RouteListNames; weekday: fromTypes.Weekday }>(),
);

export const selectedRouteIdChanged = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Selected route id changed`,
  props<{ listName: fromTypes.RouteListNames; routeId: string }>(),
);

/**
 * Filter routes
 */
export const routeStatusTypeChanged = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Route status type changed`,
  props<{ listName: fromTypes.RouteListNames; statusType: fromTypes.RouteStatusType }>(),
);

export const routeEntityFilterAddSelected = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Route entity filter add selected`,
  props<{ listName: fromTypes.RouteListNames; filter: fromTypes.RouteEntityFilter }>(),
);

export const routeSearchHitFilterAddSelected = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Route search hit filter add selected`,
  props<{ listName: fromTypes.RouteListNames; filter: fromTypes.SearchHitFilter }>(),
);

export const routeEntityFilterDeleteSelected = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Route entity filter delete selected`,
  props<{ listName: fromTypes.RouteListNames; filterId: string }>(),
);

export const routeFiltersChanged = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Route entity filter url changed`,
  props<{ listName: fromTypes.RouteListNames; filters: fromTypes.RouteFilter[] }>(),
);

export const routeFiltersRemoveAllSelected = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Filters remove all selected`,
  props<{ listName: fromTypes.RouteListNames }>(),
);

export const setStaticFilters = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Set static filters selected`,
  props<{ listName: fromTypes.RouteListNames; filters: fromTypes.RouteFilter[] }>(),
);

/**
 * Pagination
 */
export const currentPageChanged = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Current page changed`,
  props<{ listName: fromTypes.RouteListNames; page: number }>(),
);

export const pageSizeChanged = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Page size selected`,
  props<{ listName: fromTypes.RouteListNames; pageSize: number }>(),
);

/**
 * Route page
 */
export const loadRouteListRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load route page requested`,
  props<{ listName: fromTypes.RouteListNames; snapshot: fromTypes.RouteListSnapshot }>(),
);

export const loadRouteListSuccess = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load route page success`,
  props<{ listName: fromTypes.RouteListNames; response: { routeIds: string[]; totalFound: number } }>(),
);

export const loadRouteListFailed = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load route page failed`,
  props<{ listName: fromTypes.RouteListNames; error: fromTypes.WpError }>(),
);
