import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { studentChangeRequestRootPath } from './student-change-request-root-path';

export interface StudentChangeRequestPendingListByStudentRouteData {
  studentId: string;
  displayBackText?: string;
}

export const getStudentChangeRequestRootRouterLink = (
  data: StudentChangeRequestPendingListByStudentRouteData,
): string[] => {
  return ['_', 'drawer', studentChangeRequestRootPath, 'pending', data.studentId];
};

export const studentChangeRequestPendingListByStudent: PortalRoute<StudentChangeRequestPendingListByStudentRouteData> =
  {
    path: `pending/:studentId`,
    isOnRoute: (state: RouterStateUrl) => {
      return (
        state.drawer &&
        state.drawer.url &&
        state.drawer.url.startsWith(`_/drawer/${studentChangeRequestRootPath}/pending/`)
      );
    },
    parse: (state: RouterStateUrl): StudentChangeRequestPendingListByStudentRouteData => {
      return {
        studentId: state.drawer.params.studentId,
        displayBackText: state.queryParams?.displayBackText,
      };
    },
    request: (
      data: StudentChangeRequestPendingListByStudentRouteData,
      queryParamsHandling: QueryParamsHandlingStrategy = 'merge',
      replaceUrl = false,
    ): NavigationRequest => {
      const queryData = data.displayBackText ? { query: { displayBackText: data.displayBackText } } : {};
      return {
        path: getStudentChangeRequestRootRouterLink(data),
        extras: { queryParamsHandling, replaceUrl },
        ...queryData,
      };
    },
  };
