import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-popup-body-bottom',
  templateUrl: './popup-body-bottom.component.html',
  styleUrls: ['./popup-body-bottom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupBodyBottomComponent implements OnInit {
  @Input() public marginTop = '0px';
  constructor() {}

  ngOnInit(): void {}
}
