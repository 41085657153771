import { SmartFormFieldPhoneNumber, SmartFormFieldType } from '@apiEntities/smart-forms';

export const isSmartFormFieldPhoneNumber = (arg: any): arg is SmartFormFieldPhoneNumber => {
  return !!arg && typeof arg === 'object' && arg.type === SmartFormFieldType.PHONE_NUMBER;
};

export const isSmartFormFieldPhoneNumberValid = (
  phoneNumber: SmartFormFieldPhoneNumber,
  required: boolean,
): boolean => {
  if (!required && !phoneNumber.value) {
    return true;
  }
  return typeof phoneNumber.value === 'string' && phoneNumber.value.length && phoneNumber.value !== '--';
};
