import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconCloseComponent } from './icon-close/icon-close.component';
import { IconCloseHoverCircleComponent } from './icon-close-hover-circle/icon-close-hover-circle.component';

@NgModule({
  declarations: [IconCloseComponent, IconCloseHoverCircleComponent],
  imports: [CommonModule],
  exports: [IconCloseComponent, IconCloseHoverCircleComponent],
})
export class IconCloseModule {}
