import { RouteGroupListItem, RouteGroupListParams, RouteGroupListState } from '../types';
import { combineReducers, createReducer, on } from '@ngrx/store';
import { PortalListReducer } from '@rootTypes/utils/list-store-utils/portal-list-reducer';
import { PortalListFilter } from '@rootTypes/utils/list-store-utils/portal-list-filter';
import {
  mainOutletRouteGroupListActions,
  quickFilterSelectedMainOutlet,
  secondaryOutletRouteGroupListActions,
} from './actions';
import { RouterOutlets } from '@rootTypes/entities/router';
import { getDateRangePortalListFilter } from '@rootTypes/utils/list-store-utils/get-portal-filter';
import { YearMonthDay } from '@rootTypes/utils/common/date';

export const DEFAULT_ROUTE_GROUP_PAGE_SIZE = 10;
export const DEFAULT_ROUTE_GROUP_PAGE = 0;

export const getInitialRouteGroupListState = (): RouteGroupListState => {
  const todayFormatter = new YearMonthDay(YearMonthDay.today());
  return {
    params: {
      page: DEFAULT_ROUTE_GROUP_PAGE,
      pageSize: DEFAULT_ROUTE_GROUP_PAGE_SIZE,
      filters: [getDateRangePortalListFilter(todayFormatter.startMondayOfWeek(), todayFormatter.endSundayOfWeek())],
      selectedItemId: undefined,
      listByEntity: undefined,
      searchQuery: undefined,
      status: undefined,
    },
    api: {
      isLoading: false,
      total: undefined,
      items: [],
      error: undefined,
    },
  };
};

const mainOutletRouteGroupListReducerUtils = new PortalListReducer<
  RouteGroupListParams,
  PortalListFilter,
  RouteGroupListItem
>(mainOutletRouteGroupListActions, getInitialRouteGroupListState);

const mainOutletRouteGroupListReducer = createReducer<RouteGroupListState>(
  getInitialRouteGroupListState(),
  ...mainOutletRouteGroupListReducerUtils.getProcessors(),
  on(quickFilterSelectedMainOutlet, (state, action) => {
    return {
      ...state,
      params: {
        ...state.params,
        quickFilter: action.filter,
      },
    } as RouteGroupListState;
  }),
);

const secondaryOutletRouteGroupListReducerUtils = new PortalListReducer<
  RouteGroupListParams,
  PortalListFilter,
  RouteGroupListItem
>(secondaryOutletRouteGroupListActions, getInitialRouteGroupListState);

const secondaryOutletRouteGroupListReducer = createReducer<RouteGroupListState>(
  getInitialRouteGroupListState(),
  ...secondaryOutletRouteGroupListReducerUtils.getProcessors(),
);

export type AllRouteGroupListState = {
  [RouterOutlets.PRIMARY]: RouteGroupListState;
  [RouterOutlets.DRAWER]: RouteGroupListState;
};

export const routeGroupListReducer = combineReducers<AllRouteGroupListState>({
  [RouterOutlets.PRIMARY]: mainOutletRouteGroupListReducer,
  [RouterOutlets.DRAWER]: secondaryOutletRouteGroupListReducer,
});
