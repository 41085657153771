import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromStore from './store';
import * as fromServices from './services';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [],
  providers: [...fromServices.featureServices, ...fromStore.facades.driversDataFacades],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers.featureReducer),
    EffectsModule.forFeature(fromStore.effects.featureEffects),
  ],
})
export class DriversDataModule {}
