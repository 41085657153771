<div
  class="wp-popup-back-bar"
  [ngClass]="{ 'visible': !!displayBackText, 'half-drawer': isHalfDrawer$ | async }"
>
  <div class="wp-popup-back-bar__display-back-text">
    <div
      class="wp-popup-back-bar__display-back-text__content"
      (click)="onBackClicked($event);"
    >
      <div class="wp-popup-back-bar__display-back-text__back-icon">
        <wp-icon-arrow-left></wp-icon-arrow-left>
      </div>
      <div class="wp-popup-back-bar__display-back-text__text">
        {{displayBackText}}
      </div>
    </div>
  </div>
  <div class="wp-popup-back-bar__page-title">
    <div>{{ pageTitle }}</div>
  </div>
</div>
