<svg
  xmlns="http://www.w3.org/2000/svg"
  width="100%"
  height="100%"
  viewBox="0 0 32 40"
>
  <g fill="none" fill-rule="evenodd">
    <g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    stroke="#C6C6CA"
                    stroke-width="2"
                    d="M14.976 39c9.444-.087 14.495-.13 15.18-.13.655 0 .762-.093.805-.847.052-.915.052-10.086 0-27.274L20.84 1H1.79c-.335 0-.79.435-.79.678V38.3c0 .74-.054.701.788.701h13.188zm0 0h.005-.01.005z"
                    transform="translate(-655 -669) translate(486) translate(0 200) translate(26 413) translate(79 20) translate(64 36)"
                  />
                  <path
                    fill="#C6C6CA"
                    d="M19.655.517v9.135c0 1.697.469 1.865 1.83 1.865h9.17"
                    transform="translate(-655 -669) translate(486) translate(0 200) translate(26 413) translate(79 20) translate(64 36)"
                  />
                  <path
                    stroke="#C6C6CA"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 16.5h20M6 23.5h20M6 29.5h20"
                    transform="translate(-655 -669) translate(486) translate(0 200) translate(26 413) translate(79 20) translate(64 36)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
