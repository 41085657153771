import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { FlashEffects } from './store/flash.effects';

@NgModule({
  declarations: [],
  imports: [CommonModule, EffectsModule.forFeature([FlashEffects])],
})
export class FlashModule {}
