<div
  class="wp-input-prefix"
  [ngStyle]="{
    marginRight: marginRight,
    top: moveDown,
    left: moveRight
  }"
>
  <ng-content></ng-content>
</div>
