import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { NavigationRequest } from '../../types/navigation-request';
import { getRoutingRequest } from '@rootTypes/utils/router/get-routing-request';
import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';
import { createNewEntityPath } from '@rootTypes/utils/campus-info-editor';

export type CampusEditorRouteData = {
  campusId?: string;
  displayBackText?: string;
  createCampusForDistrictId?: string;
  createCampusDisableDistrict?: boolean;
};

export const campusEditorRoute: PortalRoute<CampusEditorRouteData> = {
  path: undefined,
  isOnRoute: (state: RouterStateUrl): boolean => {
    throw new Error('Not implemented');
  },
  parse: (state: RouterStateUrl): CampusEditorRouteData => {
    let data: CampusEditorRouteData = {
      campusId: state.drawer?.params?.campusId,
    } as CampusEditorRouteData;
    if (state.queryParams) {
      data = {
        ...data,
        ...state.queryParams,
        createCampusDisableDistrict: state?.queryParams?.createCampusDisableDistrict === 'true',
      };
    }
    return data;
  },
  request: (
    data: CampusEditorRouteData,
    queryParamStrategy?: QueryParamsHandlingStrategy,
    replaceUrl?,
  ): NavigationRequest => {
    data.campusId = data.campusId || createNewEntityPath;
    const path = ['_', 'drawer', 'campuses', 'editor', 'campus', ':campusId'];
    return getRoutingRequest(path, data, queryParamStrategy, replaceUrl);
  },
};

@Injectable({ providedIn: 'root' })
export class CampusEditorRouterService extends PortalRouterService<CampusEditorRouteData> {
  outlet = RouterOutlets.DRAWER;
  route: PortalRoute<CampusEditorRouteData> = campusEditorRoute;
}
