import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import { Observable } from 'rxjs';
import * as fromTypes from '../../types';
import * as fromListSelectors from '../selectors/student-list.selectors';
import * as fromListActions from '../actions/student-list.actions';
import { Injectable } from '@angular/core';

@Injectable()
export class StudentListDataFacadeService {
  constructor(private store: Store<State>) {}

  public totalStudentsFound$(listName: fromTypes.StudentListName): Observable<number> {
    return this.store.select(fromListSelectors.getListTotalFound, { listName });
  }

  public pageSize$(listName: fromTypes.StudentListName): Observable<number> {
    return this.store.select(fromListSelectors.getPageSize, { listName });
  }

  public currentPageNumber$(listName: fromTypes.StudentListName): Observable<number> {
    return this.store.select(fromListSelectors.getCurrentPage, { listName });
  }

  public currentSearchQuery$(listName: fromTypes.StudentListName): Observable<string> {
    return this.store.select(fromListSelectors.getSearchQuery, { listName });
  }

  public selectedListStatus$(listName: fromTypes.StudentListName): Observable<fromTypes.rider.StudentListStatus> {
    return this.store.select(fromListSelectors.getSelectedStudentStatus, {
      listName,
    });
  }

  public studentListLoading$(listName: fromTypes.StudentListName): Observable<boolean> {
    return this.store.select(fromListSelectors.getListLoading, { listName });
  }

  public studentListIds$(listName: fromTypes.StudentListName): Observable<fromTypes.PortalEntity[]> {
    return this.store.select(fromListSelectors.getListStudentIds, { listName });
  }

  public studentListErrors$(listName: fromTypes.StudentListName): Observable<fromTypes.WpError> {
    return this.store.select(fromListSelectors.getListError, { listName });
  }

  public onStudentCardInitialized(listName: fromTypes.StudentListName, studentId: string): void {
    this.store.dispatch(fromListActions.studentCardInitialized({ studentId, listName }));
  }

  public onSetStudentListState(
    listName: fromTypes.StudentListName,
    snapshot: fromTypes.StudentListStateSnapshot,
  ): void {
    this.store.dispatch(fromListActions.setStudentListState({ listName, snapshot }));
  }

  public onCleanStudentListState(listName: fromTypes.StudentListName): void {
    this.store.dispatch(fromListActions.cleanStudentListState({ listName }));
  }

  public onStudentCardDestroyed(listName: fromTypes.StudentListName, studentId: string): void {
    this.store.dispatch(fromListActions.studentCardDestroyed({ studentId, listName }));
  }

  public onStudentInputChanged(listName: fromTypes.StudentListName, input: string): void {
    this.store.dispatch(fromListActions.studentSearchInputChanged({ input, listName }));
  }

  public onSetStaticEntityFilters(listName: fromTypes.StudentListName, entities: fromTypes.PortalEntity[]): void {
    const filters = entities.map((e) => fromTypes.utils.getEntityFilter(e.type, e.entityId));
    this.store.dispatch(fromListActions.setStaticFilters({ listName, filters }));
  }

  public onStudentStatusChanged(listName: fromTypes.StudentListName, status: fromTypes.rider.StudentListStatus): void {
    this.store.dispatch(fromListActions.studentStatusChanged({ status, listName }));
  }

  public onCurrentPageChanged(listName: fromTypes.StudentListName, page: number): void {
    this.store.dispatch(fromListActions.currentPageChanged({ page, listName }));
  }

  public onPageSizeChanged(listName: fromTypes.StudentListName, pageSize: number): void {
    this.store.dispatch(fromListActions.pageSizeChanged({ pageSize, listName }));
  }
}
