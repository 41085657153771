export const getNumericValueFromString = (source: string, context?: string): number | null => {
  if (!source) {
    return null;
  }
  const parsed = parseInt(source, 10);
  if (isNaN(parsed)) {
    console.log('Bad value provided for ' + context);
    return null;
  }
  return parsed;
};
