<div
  class="wp-round-number"
  [ngStyle]="{
    'background-color': bgColor,
    width: sizeStr,
    height: sizeStr,
    color: fontColor,
    fontSize: fontSize,
    borderWidth: borderWidth,
    'border-color': borderColor
  }"
>
  <ng-content></ng-content>
</div>
