import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/api/api.service';
import * as fromTypes from '../types';
import * as fromApi from '../../../api';
import { ApiStudent } from '@apiEntities';
import { entitySearchResultToPortalEntity } from '@rootTypes/utils/portal-entity/entity-search-result-to-portal-enity';

@Injectable()
export class StudentsApiService {
  constructor(private api: ApiService) {}

  public getStudent(request: fromApi.getStudentV2.GetStudentV2Request): Observable<ApiStudent> {
    return this.api.getStudentV2(request);
  }

  public getStudentVerificationStatus(
    studentId: string,
  ): Observable<fromApi.getStudentVerificationStatus.GetVerificationStatusResponse> {
    return this.api.getStudentVerificationStatus(studentId);
  }

  public entityFilter(
    request: fromApi.entityFilter.EntityFilterRequest,
  ): Observable<{ totalFound: number; items: fromTypes.PortalEntity[] }> {
    return this.api.entityFilter(request).pipe(
      map((resp) => {
        return {
          totalFound: resp.total,
          items: resp.results.map((r) => entitySearchResultToPortalEntity(r)),
        };
      }),
    );
  }

  public getPendingStudentChangeRequestsByStudent(
    studentId: string,
  ): Observable<fromApi.getPendingStudentChangeRequestsByStudent.GetPendingStudentChangeRequestsByStudentResponse> {
    return this.api.getPendingStudentChangeRequestsByStudent({ studentId });
  }

  public getStudentChangeRequestLogs(
    studentId: string,
  ): Observable<fromApi.getStudentChangeRequestLogs.GetStudentChangeRequestLogsResponse> {
    return this.api.getStudentChangeRequestLogs({ studentId });
  }
}
