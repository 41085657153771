import { SmartInput, SmartInputConfig } from './smart-input';
import { Address, getAddressId } from '../dependencies';
import { UntypedFormControl } from '@angular/forms';

export type SmartInputAddressConfig = SmartInputConfig<Address>;

export class SmartInputAddress extends SmartInput<Address, Address> {
  public displayAddressControl = new UntypedFormControl();
  constructor(config: SmartInputAddressConfig) {
    super(config);
  }

  public clear(): void {
    this.displayAddressControl.setValue(null);
    this.control.setValue(null);
  }

  public getValue(): Address {
    return this.control.value;
  }

  public setValue(address?: Address, isResetControlValidationToInitialState = false): void {
    if (!address) {
      this.displayAddressControl.setValue(address);
    }
    if (isResetControlValidationToInitialState) {
      this.control.reset(address);
    } else {
      this.control.setValue(address, { emitEvent: true });
    }
  }

  protected hasChangesFn(curr: Address, init: Address): boolean {
    return getAddressId(curr) !== getAddressId(init);
  }
}
