import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { WpError } from '@rootTypes';

@Component({
  selector: 'wp-loaded-label',
  template: `
    <ng-container *ngIf="label; else noLabel">
      <div class="label-wrap" [ngClass]="{ link: isLink }">
        <wp-ellipsis [tooltipText]="label">{{ label }}</wp-ellipsis>
      </div>
    </ng-container>
    <ng-template #noLabel>
      <ng-container *ngIf="isLoading; else loaded">
        <div class="placeholder-box"></div>
      </ng-container>
      <ng-template #loaded>
        <ng-container *ngIf="error">
          <span class="error">{{ error }}</span>
        </ng-container>
      </ng-template>
    </ng-template>
  `,
  styles: [
    `
      :host {
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
      }

      .placeholder-box {
        width: 100%;
        height: 100%;
        display: inline-block;
        min-height: 1em;
        animation: shimmer 2s infinite;
        background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
        background-size: 1000px 100%;
      }

      .label-wrap {
        display: inline-block;
        width: 100%;
        height: 100%;
      }

      .link {
        cursor: pointer !important;
        text-decoration: underline;
      }

      .link:hover {
        color: #47a540 !important;
      }

      .error {
        color: red;
      }

      @keyframes shimmer {
        0% {
          background-position: -1000px 0;
        }
        100% {
          background-position: 1000px 0;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadedLabelComponent implements OnInit {
  @Input() public label: string;
  @Input() public isLink: boolean;
  @Input() public isLoading: boolean;
  @Input() public error: WpError;

  constructor() {}

  ngOnInit(): void {}
}
