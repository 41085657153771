import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-icon-invoicing-menu',
  template: `
    <ng-container *ngIf="green; else grayTemplate">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.276 15.0573C11.1653 15.9467 11.996 15.9987 12.0307 16C12.7347 16 13.3333 15.4027 13.3333 14.6667C13.3333 13.8053 13.1627 13.6653 11.676 13.2933L11.6743 13.2929C10.2995 12.9489 8 12.3734 8 9.33333C8.00234 8.50889 8.25996 7.7054 8.73747 7.03332C9.21497 6.36123 9.88892 5.85353 10.6667 5.58V5.33333C10.6667 4.97971 10.8071 4.64057 11.0572 4.39052C11.3072 4.14048 11.6464 4 12 4C12.3536 4 12.6928 4.14048 12.9428 4.39052C13.1929 4.64057 13.3333 4.97971 13.3333 5.33333V5.6C14.1928 5.90662 14.9712 6.40501 15.6093 7.05733C15.8593 7.30734 15.9998 7.64643 15.9998 8C15.9998 8.35357 15.8593 8.69266 15.6093 8.94267C15.3593 9.19268 15.0202 9.33313 14.6667 9.33313C14.3131 9.33313 13.974 9.19268 13.724 8.94267C12.8347 8.05333 12.0027 8.00133 11.968 8C11.264 8 10.6667 8.59733 10.6667 9.33333C10.6667 10.1947 10.836 10.3347 12.3227 10.7067L12.3248 10.7072C13.6997 11.0513 16 11.6269 16 14.6667C15.9973 15.491 15.7395 16.2943 15.262 16.9663C14.7846 17.6384 14.1109 18.1461 13.3333 18.42V18.6667C13.3333 19.0203 13.1929 19.3594 12.9428 19.6095C12.6928 19.8595 12.3536 20 12 20C11.6464 20 11.3072 19.8595 11.0572 19.6095C10.8071 19.3594 10.6667 19.0203 10.6667 18.6667V18.4C9.80686 18.094 9.02836 17.5955 8.39067 16.9427C8.26687 16.8189 8.16868 16.6719 8.10168 16.5102C8.03468 16.3484 8.0002 16.1751 8.0002 16C8.0002 15.6464 8.14066 15.3073 8.39067 15.0573C8.64068 14.8073 8.97976 14.6669 9.33333 14.6669C9.6869 14.6669 10.026 14.8073 10.276 15.0573Z"
          fill="#47A540"
        />
        <rect x="2" y="1" width="20" height="22" rx="1" stroke="#47A540" stroke-width="2" />
      </svg>
    </ng-container>
    <ng-template #grayTemplate>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.276 15.0573C11.1653 15.9467 11.996 15.9987 12.0307 16C12.7347 16 13.3333 15.4027 13.3333 14.6667C13.3333 13.8053 13.1627 13.6653 11.676 13.2933L11.6743 13.2929C10.2995 12.9489 8 12.3734 8 9.33333C8.00234 8.50889 8.25996 7.7054 8.73747 7.03332C9.21497 6.36123 9.88892 5.85353 10.6667 5.58V5.33333C10.6667 4.97971 10.8071 4.64057 11.0572 4.39052C11.3072 4.14048 11.6464 4 12 4C12.3536 4 12.6928 4.14048 12.9428 4.39052C13.1929 4.64057 13.3333 4.97971 13.3333 5.33333V5.6C14.1928 5.90662 14.9712 6.40501 15.6093 7.05733C15.8593 7.30734 15.9998 7.64643 15.9998 8C15.9998 8.35357 15.8593 8.69266 15.6093 8.94267C15.3593 9.19268 15.0202 9.33313 14.6667 9.33313C14.3131 9.33313 13.974 9.19268 13.724 8.94267C12.8347 8.05333 12.0027 8.00133 11.968 8C11.264 8 10.6667 8.59733 10.6667 9.33333C10.6667 10.1947 10.836 10.3347 12.3227 10.7067L12.3248 10.7072C13.6997 11.0513 16 11.6269 16 14.6667C15.9973 15.491 15.7395 16.2943 15.262 16.9663C14.7846 17.6384 14.1109 18.1461 13.3333 18.42V18.6667C13.3333 19.0203 13.1929 19.3594 12.9428 19.6095C12.6928 19.8595 12.3536 20 12 20C11.6464 20 11.3072 19.8595 11.0572 19.6095C10.8071 19.3594 10.6667 19.0203 10.6667 18.6667V18.4C9.80686 18.094 9.02836 17.5955 8.39067 16.9427C8.26687 16.8189 8.16868 16.6719 8.10168 16.5102C8.03468 16.3484 8.0002 16.1751 8.0002 16C8.0002 15.6464 8.14066 15.3073 8.39067 15.0573C8.64068 14.8073 8.97976 14.6669 9.33333 14.6669C9.6869 14.6669 10.026 14.8073 10.276 15.0573Z"
          fill="#959597"
        />
        <rect x="2" y="1" width="20" height="22" rx="1" stroke="#959597" stroke-width="2" />
      </svg>
    </ng-template>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconInvoicingMenuComponent {
  @Input() public green: boolean;

  constructor() {}
}
