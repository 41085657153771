import { Vehicle, Driver, RideAssignmentVehicle } from '../types';

export const vehicleToAssignmentVehicle = (vehicle: Vehicle, defaultDriver?: Driver): RideAssignmentVehicle => {
  return {
    id: vehicle.id,
    name: `${vehicle.make} ${vehicle.model}`,
    number: `${vehicle.license.state}${vehicle.license.number}`,
    imagePath: vehicle.vehicleImagePath,
    defaultDriverFullName: defaultDriver ? `${defaultDriver.firstName} ${defaultDriver.lastName}` : undefined,
    vendor: vehicle.vendorId ? { vendorId: vehicle.vendorId, yardId: vehicle.yardId } : undefined,
  };
};
