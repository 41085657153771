import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-icon-schools-grey',
  templateUrl: './icon-schools-grey.component.html',
  styleUrls: ['./icon-schools-grey.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconSchoolsGreyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
