import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { GoogleMapsInitService } from '../core/services/google-maps-init.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleInitGuardService {
  constructor(private googleInitService: GoogleMapsInitService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.googleInitService.initialize();
  }
}
