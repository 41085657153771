import { createReducer, on } from '@ngrx/store';
import {
  driverListPageSelected,
  driverListPageSizeSelected,
  driverListSearchTermChanged,
  driverListStatusSelected,
  driverListPageItemsFailed,
  driverListPageItemsRequested,
  driverListPageItemsSuccess,
  selectedDriverIdChanged,
  driverListStaticFiltersChanged,
  driverListMultiAccountFilterChanged,
} from '../actions/driver-list.actions';
import { DriverListFilterType, DriverListMultiAccountFilter, EntityStatus, WpError } from '../../types';
import * as fromTypes from '../../types';

export const getDriverListInitialState = (): DriverListState => {
  return {
    params: {
      search: '',
      pageIndex: 0,
      pageSize: fromTypes.driverListDefaultPageSize,
      driverStatus: fromTypes.driverListDefaultStatus,
      selectedDriverId: undefined,
      filters: [],
      staticFilters: [],
    },
    pageItems: {
      isLoading: false,
      isEmpty: false,
      data: [],
      totalFound: 0,
      error: undefined,
    },
  };
};

export const getAllDriverListState = (): AllDriverListState => {
  const target = {} as AllDriverListState;
  fromTypes.driverLists.forEach((listName) => {
    target[listName] = getDriverListInitialState();
  });
  return target;
};

export type AllDriverListState = {
  [key in fromTypes.DriverListName]?: DriverListState;
};

export interface DriverListState {
  params: DriverListStateParams;
  pageItems: {
    isLoading: boolean;
    isEmpty: boolean;
    data: fromTypes.PortalEntity[];
    totalFound: number;
    error: WpError;
  };
}

export interface DriverListStateParams {
  search: string;
  pageIndex: number;
  pageSize: number;
  driverStatus: EntityStatus;
  selectedDriverId?: string;
  filters: fromTypes.DriverListFilter[];
  staticFilters: fromTypes.DriverListFilter[];
}

export interface DriverListItem {
  driverId: string;
  label: string; // driver full name
}

export const driverListsReducer = createReducer<AllDriverListState>(
  { ...getAllDriverListState() },
  // Filters
  on(driverListStatusSelected, (allState, action): AllDriverListState => {
    const { listName, driverStatus } = action;
    const state = allState[listName];
    const resultState: DriverListState = {
      ...state,
      pageItems: {
        ...state.pageItems,
        totalFound: undefined,
      },
      params: {
        ...state.params,
        driverStatus,
        pageIndex: 0,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(driverListMultiAccountFilterChanged, (allState, action) => {
    const { listName, entity, operatorIds } = action;
    const state = allState[listName];
    const oldFilters = state.params.filters.filter((f) => f.type !== DriverListFilterType.MULTI_ACCOUNT_FILTER);
    const newFilters = entity
      ? [
          ...oldFilters,
          {
            id: `${DriverListFilterType.MULTI_ACCOUNT_FILTER},${entity.type},${entity.entityId},${operatorIds.join(
              '$',
            )}`,
            type: DriverListFilterType.MULTI_ACCOUNT_FILTER,
            payload: { ...entity, operatorIds },
          } as DriverListMultiAccountFilter,
        ]
      : oldFilters;
    const resultState = {
      ...state,
      params: {
        ...state.params,
        filters: newFilters,
      },
      pageItems: {
        ...state.pageItems,
        totalFound: undefined,
      },
    } satisfies DriverListState;
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(driverListStaticFiltersChanged, (allState, action): AllDriverListState => {
    const { listName, filters } = action;
    const state = allState[listName];
    const previousStaticFilters = state.params.staticFilters;
    const isSameFilters = fromTypes.utils.arraysEqual(previousStaticFilters, filters);
    let resultState: DriverListState;
    if (isSameFilters) {
      resultState = {
        ...state,
        pageItems: {
          ...state.pageItems,
          totalFound: undefined,
        },
        params: {
          ...state.params,
          staticFilters: filters,
        },
      };
    } else {
      const initial = getDriverListInitialState();
      resultState = {
        ...initial,
        params: {
          ...state.params,
          staticFilters: filters,
        },
      };
    }
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  // selected driver id
  on(selectedDriverIdChanged, (allState, action): AllDriverListState => {
    const { listName, driverId } = action;
    const state = allState[listName];
    const resultState: DriverListState = {
      ...state,
      params: {
        ...state.params,
        selectedDriverId: driverId,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(driverListSearchTermChanged, (allState, action): AllDriverListState => {
    const { listName, search } = action;
    const state = allState[listName];
    const resultState: DriverListState = {
      ...state,
      pageItems: {
        ...state.pageItems,
        totalFound: undefined,
      },
      params: {
        ...state.params,
        search,
        pageIndex: 0,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  // Pagination
  on(driverListPageSelected, (allState, action): AllDriverListState => {
    const { listName, pageIndex } = action;
    const state = allState[listName];
    const resultState: DriverListState = {
      ...state,
      params: {
        ...state.params,
        pageIndex,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(driverListPageSizeSelected, (allState, action): AllDriverListState => {
    const { listName, pageSize } = action;
    const state = allState[listName];
    const resultState: DriverListState = {
      ...state,
      params: {
        ...state.params,
        pageSize,
        pageIndex: 0,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  // on(driverListInitialize, (allState, action): AllDriverListState => {
  //   const { listName } = action;
  //   const state = allState[listName];
  //   let resultState: DriverListState;
  //   if (action.data) {
  //     const { search, page, pageSize, driverStatus, selectedDriverId, multiAccountManagementFilter } = action.data;
  //     const pageIndex = page ? parseInt(page, 10) - 1 : 0;
  //     const filters = multiAccountManagementFilter
  //       ? [
  //           {
  //             id: `${DriverListFilterType.MULTI_ACCOUNT_FILTER},${multiAccountManagementFilter.type},${multiAccountManagementFilter.entityId}`,
  //             type: DriverListFilterType.MULTI_ACCOUNT_FILTER,
  //             payload: multiAccountManagementFilter,
  //           } as DriverListMultiAccountFilter,
  //         ]
  //       : [];
  //     resultState = {
  //       ...state,
  //       pageItems: {
  //         ...state.pageItems,
  //         totalFound: undefined,
  //       },
  //       params: {
  //         ...state.params,
  //         search: search || '',
  //         pageIndex: pageIndex || 0,
  //         pageSize: pageSize ? parseInt(pageSize, 10) : driverListDefaultPageSize,
  //         driverStatus: driverStatus || driverListDefaultStatus,
  //         selectedDriverId,
  //         filters,
  //       },
  //     };
  //   } else {
  //     resultState = { ...state };
  //   }
  //   return {
  //     ...allState,
  //     [listName]: resultState,
  //   };
  // }),
  // Page items
  on(driverListPageItemsRequested, (allState, action): AllDriverListState => {
    const { listName } = action;
    const state = allState[listName];
    const resultState: DriverListState = {
      ...state,
      pageItems: {
        ...state.pageItems,
        isLoading: true,
        error: undefined,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(driverListPageItemsSuccess, (allState, action): AllDriverListState => {
    const { listName } = action;
    const { pageItems, totalFound } = action.data;
    const state = allState[listName];
    const resultState: DriverListState = {
      ...state,
      pageItems: {
        isLoading: false,
        isEmpty: pageItems.length === 0,
        data: pageItems,
        totalFound,
        error: undefined,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(driverListPageItemsFailed, (allState, action): AllDriverListState => {
    const { listName, error } = action;
    const state = allState[listName];
    const resultState: DriverListState = {
      ...state,
      pageItems: {
        isLoading: false,
        isEmpty: true,
        data: [],
        totalFound: 0,
        error,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
);
