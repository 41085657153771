import { SmartFormFieldString, SmartFormFieldType } from '@apiEntities/smart-forms';

export const isSmartFormFieldString = (arg: any): arg is SmartFormFieldString => {
  return !!arg && typeof arg === 'object' && arg.type === SmartFormFieldType.STRING;
};

export const isSmartFormFieldStringValid = (field: SmartFormFieldString, required: boolean): boolean => {
  if (required) {
    return typeof field.value === 'string' && field.value.length && field.value !== '--';
  }
  return true;
};
