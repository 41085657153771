import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { NavigationRequest } from '../../types/navigation-request';
import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '../../../types/entities/router';
import { getRoutingRequest } from '@rootTypes/utils/router/get-routing-request';
import { invoicingPath } from './invoicing-path';
import {
  AccountSelectListParams,
  accountSelectQuickFilterDeserializer,
} from '../../../features/invoicing/types/account-select-list';
import { deserializeBaseParamsFromUrl } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';
import { serializeBaseParamsToUrl } from '@rootTypes/utils/list-store-utils/serialize-base-params-to-url';
import { getInitialAccountSelectListState } from '../../../features/invoicing/store/account-select.store';

export type AccountSelectRouteData = Partial<AccountSelectListParams>;
export const accountSelectPath = 'accounts';

export const accountSelectRoute: PortalRoute<AccountSelectRouteData> = {
  path: accountSelectPath,
  isOnRoute: (state: RouterStateUrl): boolean => {
    return state.url.includes(accountSelectPath);
  },
  parse: (state: RouterStateUrl): AccountSelectRouteData => {
    return deserializeBaseParamsFromUrl(state.queryParams, 'iacs', [accountSelectQuickFilterDeserializer]);
  },
  request: (
    data: AccountSelectRouteData = {},
    queryParamStrategy?: QueryParamsHandlingStrategy,
    replaceUrl = false,
  ): NavigationRequest => {
    const path = [invoicingPath, accountSelectPath];
    const routeData = serializeBaseParamsToUrl({ ...data }, getInitialAccountSelectListState().params, 'iacs');
    return getRoutingRequest(path, routeData, queryParamStrategy, replaceUrl);
  },
  getHref(data?: AccountSelectRouteData): string {
    return `/${invoicingPath}/${accountSelectPath}`;
  },
};

@Injectable({ providedIn: 'root' })
export class AccountSelectRouterService extends PortalRouterService<AccountSelectRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route: PortalRoute<AccountSelectRouteData> = accountSelectRoute;
}
