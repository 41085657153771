import { CharterVendorAssignmentStatus } from '@apiEntities/trips';
import { TripAssignmentListStatusFilter } from '../../entities';

export const createTripAssignmentStatusFilter = (
  status?: CharterVendorAssignmentStatus,
): TripAssignmentListStatusFilter => {
  if (!status) {
    return undefined;
  }
  return {
    id: `assignment-status,${status}`,
    type: 'assignment-status',
    payload: status,
  };
};
