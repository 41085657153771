<div class="container">
  <div class="status-date-row">
    <wp-smart-select
      class="status"
      *ngIf="model.isStatusInputShown"
      [model]="model.status"
    ></wp-smart-select>

    <wp-smart-input-date
      class="date"
      *ngIf="model.isCancellationDetailsShown$ | async"
      [model]="model.cancellationEffectiveDate"
    >
    </wp-smart-input-date>
  </div>

  <ng-container *ngIf="model.isCancellationDetailsShown$ | async">
    <wp-smart-select
      class="reason"
      [model]="model.cancellationReason"
    ></wp-smart-select>
    <wp-smart-textarea
      class="comment"
      [model]="model.cancellationComment"
    ></wp-smart-textarea>
  </ng-container>
</div>
