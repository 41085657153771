import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { WpError } from '@rootTypes';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  assignRouteGroupDriversSuccess,
  unassignRouteGroupDriversSuccess,
} from 'src/app/features/route-group/store/actions/manage-route-owners.actions';
import { ApiService } from '../../../api/api.service';
import {
  getRouteGroupOwnerHistoryFailed,
  getRouteGroupOwnerHistorySuccess,
  loadRouteGroupOwnerHistoryActions,
} from '../actions/route-group-owner-history.actions';

@Injectable()
export class RouteGroupOwnerHistoryEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
  ) {}

  public loadRouteGroupOwnerHistoryRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...loadRouteGroupOwnerHistoryActions, assignRouteGroupDriversSuccess, unassignRouteGroupDriversSuccess),
      mergeMap(({ routeGroupId }) => {
        return this.api.getRouteGroupOwnerHistory({ routeGroupId }).pipe(
          map((response) => {
            return getRouteGroupOwnerHistorySuccess({ routeGroupId, ownersHistory: response.ownerHistories });
          }),
          catchError((originalError) => {
            console.error(originalError);
            const error: WpError = {
              originalError,
              text: 'Failed to load route owner history',
            };
            return of(getRouteGroupOwnerHistoryFailed({ routeGroupId, error }));
          }),
        );
      }),
    ),
  );
}
