import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import { EmployeesApiService } from '../../services/employees-api.service';
import * as fromActions from '../actions/employee-data.actions';
import * as fromVendorDataActions from '../../../vendors/store/actions/vendor-data.actions';
import * as fromSelectors from '../selectors/employee-data.selectors';
import * as fromTypes from '../../types';

@Injectable()
export class EmployeeDataEffects {
  constructor(
    private actions$: Actions,
    private apiService: EmployeesApiService,
    private store: Store<State>,
  ) {}

  public loadEmployeeRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadEmployeeRequested),
      mergeMap((action) => {
        const { employeeId, options } = action;
        return this.apiService.getEmployee(employeeId, options).pipe(
          map((response) =>
            fromActions.loadEmployeeSuccess({
              employeeId,
              ...response,
            }),
          ),
          catchError((err) => {
            console.log(err);
            return of(
              fromActions.loadEmployeeFailed({
                employeeId,
                error: {
                  text: 'Failed to load employee',
                  originalError: err,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  public loadEmployeePermissionOptionsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadPermissionOptionsRequested),
      switchMap((action) => {
        const { employeeId } = action;
        return this.store.select(fromSelectors.getEmployee, { employeeId }).pipe(
          filter((employee) => !!employee),
          take(1),
          switchMap((employee) => {
            const { parentEntityId } = employee;
            const request = fromTypes.utils.getPermissionOptionsRequest(parentEntityId);
            return this.apiService.getPermissionOptions(request).pipe(
              map((options) => {
                return fromActions.loadPermissionOptionsSuccess({ employeeId, options });
              }),
              catchError((err) => {
                console.log(err);
                return of(
                  fromActions.loadPermissionOptionsFailed({
                    employeeId,
                    error: {
                      text: 'Failed to load permission options (entities)',
                      originalError: err,
                    },
                  }),
                );
              }),
            );
          }),
        );
      }),
    ),
  );

  public loadVendorOnEmployeeRequested = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadPermissionOptionsRequested),
      switchMap((action) => {
        const { employeeId } = action;
        return this.store.select(fromSelectors.getEmployee, { employeeId }).pipe(
          filter((employee) => !!employee),
          take(1),
          map((employee) => {
            const { parentEntityId } = employee;
            return fromVendorDataActions.loadVendorRequested({ vendorId: parentEntityId });
          }),
        );
      }),
    ),
  );
}
