import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractPopupComponent } from '../types/abstract-popup-component';
import { PopupRef } from '../types/popup-ref';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ConfirmPopupConfig } from '../types';

@Component({
  selector: 'wp-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss'],
})
export class ConfirmPopupComponent
  implements AbstractPopupComponent<ConfirmPopupConfig, boolean>, OnInit, AfterViewInit
{
  public popupRef: PopupRef<ConfirmPopupConfig, boolean>;

  public headerText: string;
  public bodyText: string | undefined;
  public bodyHtml: SafeHtml | undefined;
  public confirmBtnText: string | undefined;
  public confirmBtnColor: 'green' | 'red';
  public cancelBtnText: string | undefined;
  public confirmBtnWidth = '175px';
  public cancelBtnWidth = '120px';
  public popupWidth = '450px';
  @ViewChild('container') private container: ElementRef<HTMLDivElement>;

  constructor(private sanitizer: DomSanitizer) {}

  ngAfterViewInit(): void {
    const container = this.container?.nativeElement;
    if (container) {
      container.focus();
    }
  }

  public ngOnInit(): void {
    this.setData();
  }

  public confirm(): void {
    this.popupRef.close(true);
  }

  public cancel(): void {
    this.popupRef.close(false);
  }

  private setData(): void {
    if (this.popupRef) {
      const data = this.popupRef.data;
      if (!data) {
        throw new Error('No config found for confirm popup');
      }
      if (data.html) {
        this.bodyHtml = this.sanitizer.bypassSecurityTrustHtml(data.html);
      }
      if (data.text) {
        this.bodyText = data.text;
      }
      if (data.header) {
        this.headerText = data.header;
      }
      if (data.confirmBtnText) {
        this.confirmBtnText = data.confirmBtnText;
      }
      if (data.confirmBtnColor) {
        this.confirmBtnColor = data.confirmBtnColor || 'green';
      }
      if (data.cancelBtnText) {
        this.cancelBtnText = data.cancelBtnText;
      }
      if (data.confirmBtnWidthPx) {
        this.confirmBtnWidth = `${data.confirmBtnWidthPx}px`;
      }
      if (data.cancelBtnWidthPx) {
        this.cancelBtnWidth = `${data.cancelBtnWidthPx}px`;
      }
      if (data.popupWidthPx) {
        this.popupWidth = `${data.popupWidthPx}px`;
      }
    }
  }
}
