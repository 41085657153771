import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CrossEntitySearchStoreService } from '../cross-entity-search-store.service';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import * as fromTypes from '../types';

@Component({
  selector: 'wp-cross-entity-search',
  templateUrl: './cross-entity-search.component.html',
  styleUrls: ['./cross-entity-search.component.scss'],
  providers: [CrossEntitySearchStoreService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrossEntitySearchComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public placeholder: string;
  @Input() public searchByTypes: fromTypes.PortalEntityType[] = [];
  @Input() public dropdownAlignment: 'left' | 'right' | 'center' = 'right';
  @Input() public isSetInputOnSelect: boolean;
  @Input() public disabled = false;
  @Input() public searchInputPreprocessor: (searchTerm: string) => string;
  @Input() public searchInputControl: UntypedFormControl;

  @Output() public selected = new EventEmitter<fromTypes.PortalEntity>();
  @Output() public clearInputClicked = new EventEmitter<void>();
  @Output() public hitClicked = new EventEmitter<fromTypes.PortalEntitySearchHit>();

  public items$: Observable<fromTypes.PortalEntity[]>;
  public hits$: Observable<fromTypes.PortalEntitySearchHit[] | undefined>;
  public empty$: Observable<boolean>;
  public error$: Observable<any | null>;
  public searchTermLoading$: Observable<boolean>;
  public hasNext$: Observable<boolean>;
  public nextPageLoading$: Observable<boolean>;
  public dropdownOpen$: Observable<boolean>;
  // string
  public minDropDownWidthStr: string;
  @ViewChild('wrapper') private wrapperElement;

  constructor(private store: CrossEntitySearchStoreService) {}

  ngOnDestroy(): void {
    this.store.dispose();
  }

  ngOnInit(): void {
    this.store.setSearchByTypes(this.searchByTypes);
    this.initSearchControl();
    this.hits$ = this.store.hits$();
    this.items$ = this.store.items$();
    this.empty$ = this.store.empty$();
    this.error$ = this.store.error$();
    this.nextPageLoading$ = this.store.pageLoading();
    this.hasNext$ = this.store.hasNext$();
    this.dropdownOpen$ = this.store.dropdownOpen$();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && !changes.disabled.isFirstChange()) {
      if (this.disabled) {
        this.searchInputControl.disable({ emitEvent: false });
      } else {
        this.searchInputControl.enable({ emitEvent: false });
      }
    }
    if (changes.searchByTypes && !changes.searchByTypes.isFirstChange()) {
      this.store.setSearchByTypes(this.searchByTypes);
    }
    if (changes.searchInputPreprocessor && !changes.searchInputPreprocessor.isFirstChange()) {
      let targetSearchTerm = this.searchInputControl?.value;
      if (this.searchInputPreprocessor) {
        targetSearchTerm = this.searchInputPreprocessor(targetSearchTerm);
      }
      this.store.search(targetSearchTerm);
    }
  }

  public onSearchInputClick(event: MouseEvent): void {
    document.body.click();
    event.stopPropagation();
    this.store.openDropdown();
  }

  public onOptionClick(o: fromTypes.PortalEntity): void {
    this.selected.next(o);
    if (this.isSetInputOnSelect) {
      this.searchInputControl.setValue(o.label, { emitEvent: false });
    }
    this.store.closeDropdown();
  }

  public onHitClicked(hit: fromTypes.PortalEntitySearchHit): void {
    this.hitClicked.emit(hit);
    this.store.closeDropdown();
  }

  public clearInput(): void {
    this.store.closeDropdown();
    this.clearInputClicked.emit();
    this.searchInputControl.setValue(null);
  }

  public onLoadNextPage(): void {
    this.store.loadNextPage();
  }

  public onClickOutside(): void {
    this.store.closeDropdown();
  }

  public onScrolled(): void {
    this.store.loadNextPage();
  }

  private onSearchTermChange(searchTerm: string): void {
    let targetSearchTerm = searchTerm;
    if (this.searchInputPreprocessor) {
      targetSearchTerm = this.searchInputPreprocessor(searchTerm);
    }
    this.store.search(targetSearchTerm);
  }

  private initSearchControl(): void {
    this.searchInputControl = this.searchInputControl ?? new UntypedFormControl();
    this.searchInputControl.valueChanges.pipe().subscribe((val) => this.onSearchTermChange(val));
    if (this.disabled) {
      this.searchInputControl.disable({ emitEvent: false });
    }
  }
}
