<svg
  xmlns="http://www.w3.org/2000/svg"
  width="32"
  height="32"
  class="close-icon"
  *ngIf="size === 'large'"
>
  <g
    fill="none"
    fill-rule="evenodd"
    stroke="#7C7C7E"
    stroke-linecap="round"
    stroke-width="2.4"
  >
    <path d="M26.296 5.931L5.931 26.296M26.296 26.296L5.931 5.931" />
  </g>
</svg>

<svg
  *ngIf="size === 'small'"
  xmlns="http://www.w3.org/2000/svg"
  width="16"
  height="16"
  viewBox="0 0 16 16"
>
  <g
    fill="none"
    fill-rule="evenodd"
    stroke="#7C7C7E"
    stroke-linecap="round"
    stroke-width="2.4"
  >
    <path d="M13.148 2.966L2.966 13.148M13.148 13.148L2.966 2.966" />
  </g>
</svg>

<svg
  *ngIf="size === 'xsmall'"
  xmlns="http://www.w3.org/2000/svg"
  width="12"
  height="12"
  viewBox="0 0 16 16"
>
  <g
    fill="none"
    fill-rule="evenodd"
    stroke="#7C7C7E"
    stroke-linecap="round"
    stroke-width="3"
  >
    <path d="M13.148 2.966L2.966 13.148M13.148 13.148L2.966 2.966" />
  </g>
</svg>
