import { YYYYMMDDString } from '@rootTypes';
import { dateParse } from '@rootTypes/utils/common/date-time-fns/date-parse';
import { formattingConstants } from './formatting-tokens';

export const yyyymmddToDate = (source: YYYYMMDDString, timezone: string = null): Date => {
  if (!source) {
    return undefined;
  }
  return dateParse(source, formattingConstants.yyyyMMDD, timezone);
};
