export enum VehicleInspectionListName {
  VEHICLE_DETAILS_INSPECTIONS = 'vehicleDetailsInspections',
  DRIVER_DETAILS_INSPECTIONS = 'driverDetailsInspections',
  MAIN = 'main',
}

export const vehicleInspectionLists: VehicleInspectionListName[] = [
  VehicleInspectionListName.MAIN,
  VehicleInspectionListName.VEHICLE_DETAILS_INSPECTIONS,
  VehicleInspectionListName.DRIVER_DETAILS_INSPECTIONS,
];
