import { VehicleInspectionDriverReviewStatus } from '@apiEntities';

enum DisplayStatus {
  PENDING = 'Pending review',
  REVIEWED = 'Review completed',
}

export const displayVehicleInspectionDriverReviewStatus = (status: VehicleInspectionDriverReviewStatus): string => {
  if (status === VehicleInspectionDriverReviewStatus.PENDING) {
    return DisplayStatus.PENDING;
  }
  if (status === VehicleInspectionDriverReviewStatus.REVIEWED) {
    return DisplayStatus.REVIEWED;
  }
  return undefined;
};
