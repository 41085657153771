import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { MainAxisStackAlignment, SecondaryAxisStackAlignment, StackDirection } from '../types';
import { Subscription } from 'rxjs';
import { StackLayoutItemComponent } from '../stack-layout-item/stack-layout-item.component';
import { startWith } from 'rxjs/operators';
import { StackLayoutService } from '../stack-layout.service';

@Component({
  selector: 'wp-stack-layout',
  templateUrl: './stack-layout.component.html',
  styleUrls: ['./stack-layout.component.scss'],
  providers: [StackLayoutService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StackLayoutComponent implements OnInit, OnDestroy, AfterContentInit, OnChanges {
  @Input() public direction: StackDirection = 'row';
  @Input() public spacing = 0;
  // justify-content
  @Input() public mainAxisAlignment: MainAxisStackAlignment = 'flex-start';
  // align-items
  @Input() public secondaryAxisAlignment: SecondaryAxisStackAlignment = 'flex-start';
  @ContentChildren(StackLayoutItemComponent) private items$: QueryList<StackLayoutItemComponent>;

  private subscription: Subscription = new Subscription();

  constructor(private stateService: StackLayoutService) {}

  ngAfterContentInit(): void {
    const itemsChanged$ = this.items$.changes.pipe(startWith(this.items$));
    const sub = itemsChanged$.subscribe(() => {
      const lastIndex = this.items$.length - 1;
      this.items$.forEach((item, index) => {
        item.first$.next(index === 0);
        item.last$.next(index === lastIndex);
      });
    });
    this.subscription.add(sub);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.spacing) {
      this.stateService.setSpacing(changes.spacing.currentValue);
    }
    if (changes.direction) {
      this.stateService.setDirection(changes.direction.currentValue);
    }
  }
}
