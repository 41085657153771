import { Pipe, PipeTransform } from '@angular/core';
import { Weekday } from '@rootTypes';
import { getXSWeekday } from '@rootTypes/utils/common/date';

@Pipe({
  name: 'xsWeekday',
})
export class XsWeekdayPipe implements PipeTransform {
  /**
   * Display weekday as a single letter
   */
  transform(value: Weekday): string {
    return getXSWeekday(value);
  }
}
