import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import {
  GetStudentChangeRequestConfigRequest,
  GetStudentChangeRequestConfigResponse,
  GetPendingStudentChangeRequestsByStudentRequest,
  GetPendingStudentChangeRequestsByStudentResponse,
  GetStudentChangeRequestSummaryRequest,
  GetStudentChangeRequestSummaryResponse,
  ApplyStudentChangeRequestRequest,
  ApplyStudentChangeRequestResponse,
} from '../dependencies';

import { getStudentChangeRequestConfigMockResponse } from '../mocks';

@Injectable({
  providedIn: 'root',
})
export class StudentChangeRequestMockApiService {
  constructor() {}

  public getStudentChangeRequestConfig(
    request: GetStudentChangeRequestConfigRequest,
  ): Observable<GetStudentChangeRequestConfigResponse> {
    console.log('getStudentChangeRequestConfig mock', getStudentChangeRequestConfigMockResponse);
    return of(getStudentChangeRequestConfigMockResponse).pipe(delay(500));
  }

  public getPendingStudentChangeRequestsByStudent(
    request: GetPendingStudentChangeRequestsByStudentRequest,
  ): Observable<GetPendingStudentChangeRequestsByStudentResponse> {
    return of(undefined);
  }

  public getStudentChangeRequestSummary(
    request: GetStudentChangeRequestSummaryRequest,
  ): Observable<GetStudentChangeRequestSummaryResponse> {
    return of(undefined);
  }

  public applyStudentChangeRequest(
    request: ApplyStudentChangeRequestRequest,
  ): Observable<ApplyStudentChangeRequestResponse> {
    const response: ApplyStudentChangeRequestResponse = {
      isValid: false,
      errors: [
        {
          field: 'transportationDates',
          label: 'Transportation dates',
          error: 'Transportation dates do not match with the selected school year',
        },
      ],
    };
    return of(response).pipe(delay(1000));
  }
}
