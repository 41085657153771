import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';
import { SchoolEmployeeDetailsTab } from '../../types';

const moduleKey = 'School Employee';
const submoduleKey = 'Details';

export const setEmployeeId = createAction(
  `[${moduleKey}] [${submoduleKey}]: Set employee id`,
  props<{ employeeId: string | undefined }>(),
);

export const setTab = createAction(
  `[${moduleKey}] [${submoduleKey}]: Set tab`,
  props<{ tab: SchoolEmployeeDetailsTab }>(),
);

export const employeePermissionLinkClicked = createAction(
  `[${moduleKey}] [${submoduleKey}]: Employee permission link clicked`,
  props<{ permission: fromTypes.EmployeePermission }>(),
);
