<div
  class="recenter-button"
  [matTooltip]="'Re-center'"
  [matTooltipPosition]="'above'"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g>
                        <g>
                          <path
                            class="hoverable-fill"
                            fill="#959597"
                            d="M18 6L23 6 23 9 18 9z"
                            transform="rotate(90 20.5 7.5)"
                          />
                          <path
                            class="hoverable-fill"
                            fill="#959597"
                            d="M18 31L23 31 23 34 18 34z"
                            transform="rotate(90 20.5 32.5)"
                          />
                          <path
                            class="hoverable-fill"
                            fill="#959597"
                            d="M5 19H10V22H5zM31 19H36V22H31z"
                          />
                          <circle
                            cx="20.5"
                            cy="20.5"
                            r="10.5"
                            class="hoverable-stroke"
                            stroke="#959597"
                            stroke-width="3"
                          />
                          <circle
                            class="hoverable-fill"
                            cx="20.5"
                            cy="20.5"
                            r="4.5"
                            fill="#959597"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</div>
