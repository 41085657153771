import * as fromTypes from '../../types/entities/common';
import * as utils from '../../types/utils/common';
export { utils };
export * from '../../types/entities/common';
import { Observable } from 'rxjs';

export type SearchResponseWithSearhTerm = {
  searchTerm: string;
  results: fromTypes.PortalEntity[];
  hits?: fromTypes.PortalEntitySearchHit[];
  nextSkip: number;
  error?: any | null;
};

export interface CrossEntitySearchStore {
  closeDropdown(): void;
  dropdownOpen$(): Observable<boolean>;
  loadNextPage(): void;
  items$(): Observable<fromTypes.PortalEntity[]>;
  empty$(): Observable<boolean>;
  error$(): Observable<any>;
  hasNext$(): Observable<boolean>;
  openDropdown(): void;
  pageLoading(): Observable<boolean>;
  search(searchTerm: string): void;
  setSearchByTypes(types: fromTypes.PortalEntityType[]): void;
}

export interface CrossEntitySearchState {
  emitLine?: number; // remove on test
  isDropdownOpen: boolean;
  searchTerm: string;
  currentPage: number;
  nextSkip: number;
  searchByTypes: fromTypes.PortalEntityType[];
  results: {
    pageLoading: boolean;
    items: fromTypes.PortalEntity[];
    hits: fromTypes.PortalEntitySearchHit[];
    error: any;
  };
}

export const initialCrossEntitySearchState: CrossEntitySearchState = {
  isDropdownOpen: false,
  searchTerm: null,
  currentPage: 0,
  nextSkip: 0,
  searchByTypes: [],
  results: {
    pageLoading: false,
    items: [],
    hits: [],
    error: null,
  },
};
