import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-input-prefix',
  templateUrl: './input-prefix.component.html',
  styleUrls: ['./input-prefix.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputPrefixComponent implements OnInit {
  @Input() public marginRight = '0px';
  @Input() public moveDown = '0px';
  @Input() public moveRight = '0px';

  constructor() {}

  ngOnInit(): void {}
}
