import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isPdfFile',
})
export class IsPdfFilePipe implements PipeTransform {
  transform(value: File | null): boolean {
    if (!value) {
      return false;
    }
    return value.type === 'application/pdf';
  }
}
