import { createReducer, on } from '@ngrx/store';
import * as fromActions from '../actions/student-change-request-review.actions';
import {
  EntityState,
  createEntityState,
  StudentChangeRequestLabel,
  StudentChangeRequestOperation,
  StudentChangeRequestReviewFormRow,
  StudentChangeRequestReviewChooseActionState,
  StudentChangeRequestExistingEntity,
  createStudentChangeRequestFormRows,
  StudentChangeRequestField,
  StudentChangeRequestExistingDataField,
  StudentChangeRequestAllFieldsItem,
  SelectOption,
  ApplyStudentChangeRequestResponse,
  ApplyStudentChangeRequestValidationError,
  validateStudentChangeRequestFormRows,
} from '../../dependencies';

export interface StudentChangeRequestReviewState {
  requestId?: string;
  districtId?: string;
  districtProgramId?: string;
  reviewForm: EntityState<StudentChangeRequestReviewForm>;
  applyChangeRequest: EntityState<ApplyStudentChangeRequestResponse>;
}

export interface StudentChangeRequestReviewForm {
  label: StudentChangeRequestLabel;
  operation: StudentChangeRequestOperation;
  allFields: StudentChangeRequestAllFieldsItem[];
  existingEntities: StudentChangeRequestReviewExistingEntityMap;
  changeRequestFieldsOriginal: {
    [fieldName: string]: StudentChangeRequestField;
  };
  chooseAction: StudentChangeRequestReviewChooseActionState;
  rows: StudentChangeRequestReviewFormRow[];
  hasUnsavedChanges: boolean;
  isValidOnClientSide: boolean; // if user resolved all conflicts with valid data
  deleteEntityLabel?: string; // show warning if the entity will be achieved
}

interface StudentChangeRequestReviewExistingEntityMap {
  [entityId: string]: StudentChangeRequestExistingEntity;
}

const createInitialStudentChangeRequestReviewState = (): StudentChangeRequestReviewState => {
  return {
    reviewForm: createEntityState(),
    applyChangeRequest: createEntityState(),
  };
};

export const studentChangeRequestReviewReducer = createReducer<StudentChangeRequestReviewState>(
  createInitialStudentChangeRequestReviewState(),
  on(fromActions.destroyStudentChangeRequestReview, createInitialStudentChangeRequestReviewState),
  on(fromActions.initStudentChangeRequestReview, (state, { requestId }): StudentChangeRequestReviewState => {
    return {
      ...state,
      requestId,
    };
  }),
  on(fromActions.studentChangeRequestReviewLoadRequestConfigRequested, (state): StudentChangeRequestReviewState => {
    return {
      ...state,
      reviewForm: {
        isLoading: true,
      },
    };
  }),
  on(
    fromActions.studentChangeRequestReviewLoadRequestConfigSuccess,
    (state, { response }): StudentChangeRequestReviewState => {
      const existingEntities: StudentChangeRequestReviewExistingEntityMap = {};
      const selectOptions: SelectOption[] = [];
      response.existingEntities.forEach((entity) => {
        existingEntities[entity.entityId] = entity;
        selectOptions.push({
          displayLabel: entity.label,
          value: entity.entityId,
        });
      });
      let selectedOption: SelectOption | undefined;
      if (response.suggestedComparisonEntityId) {
        selectedOption = selectOptions.find((opt) => opt.value === response.suggestedComparisonEntityId);
      }
      const isUpdate = response.operation === StudentChangeRequestOperation.UPDATE;
      const chooseAction: StudentChangeRequestReviewChooseActionState = {
        entitySelectLabel: response.updateExistingDataForLabel,
        selectedEntityId: selectedOption?.value,
        entitySelectOptions: selectOptions,
        isUpdate,
        isEntitySelectDisabled: !isUpdate,
        isUpdateDisabled: !response.existingEntities.length,
        isCreateDisabled:
          response.isMaxEntityCountReached || response.operation === StudentChangeRequestOperation.DELETE,
        isMaxEntityCountReached: response.isMaxEntityCountReached,
      };
      let selectedExistingFields: { [fieldName: string]: StudentChangeRequestExistingDataField };
      if (selectedOption && existingEntities[selectedOption.value]) {
        selectedExistingFields = existingEntities[selectedOption.value].dataFields;
      } else {
        selectedExistingFields = {};
      }
      const form: StudentChangeRequestReviewForm = {
        label: response.label,
        operation: response.operation,
        chooseAction,
        existingEntities,
        allFields: response.allFields,
        changeRequestFieldsOriginal: response.changeRequestFields,
        hasUnsavedChanges: false,
        isValidOnClientSide: false,
        deleteEntityLabel: response.deleteEntityLabel,
        rows: createStudentChangeRequestFormRows(
          response.operation,
          false,
          response.allFields,
          selectedExistingFields,
          response.changeRequestFields,
        ),
      };
      return {
        ...state,
        districtId: response.districtId,
        districtProgramId: response?.districtProgram?.id,
        reviewForm: createEntityState(form),
      };
    },
  ),
  on(
    fromActions.studentChangeRequestReviewLoadRequestConfigFailed,
    (state, { error }): StudentChangeRequestReviewState => {
      return {
        ...state,
        reviewForm: createEntityState(undefined, error),
      };
    },
  ),
  // Choose action
  on(
    fromActions.studentChangeRequestReviewChooseEntityToUpdate,
    (state, { entityId }): StudentChangeRequestReviewState => {
      const form = state.reviewForm.entity;
      return {
        ...state,
        reviewForm: {
          ...state.reviewForm,
          entity: {
            ...form,
            chooseAction: {
              ...form.chooseAction,
              selectedEntityId: entityId,
            },
            hasUnsavedChanges: false,
            rows: createStudentChangeRequestFormRows(
              form.operation,
              false,
              form.allFields,
              form.existingEntities[entityId].dataFields,
              form.changeRequestFieldsOriginal,
            ),
          },
        },
      };
    },
  ),
  on(fromActions.studentChangeRequestReviewChooseOperation, (state, { isUpdate }): StudentChangeRequestReviewState => {
    const operation = isUpdate ? StudentChangeRequestOperation.UPDATE : StudentChangeRequestOperation.CREATE;
    const form = state.reviewForm.entity;
    const chooseAction = {
      ...form.chooseAction,
      isUpdate,
      isEntitySelectDisabled: !isUpdate,
    };
    const selectedExistingFields =
      isUpdate && chooseAction.selectedEntityId ? form.existingEntities[chooseAction.selectedEntityId].dataFields : {};

    return {
      ...state,
      reviewForm: {
        ...state.reviewForm,
        entity: {
          ...form,
          operation,
          chooseAction,
          hasUnsavedChanges: false,
          rows: createStudentChangeRequestFormRows(
            operation,
            true,
            form.allFields,
            selectedExistingFields,
            form.changeRequestFieldsOriginal,
          ),
        },
      },
    };
  }),
  // Edit/Apply/Revert data
  on(fromActions.studentChangeRequestReviewUpdateRevisedData, (state, { event }): StudentChangeRequestReviewState => {
    const rowToUpdateIndex = state.reviewForm.entity.rows.findIndex((row) => row.fieldName === event.fieldName);
    const rowToUpdate = state.reviewForm.entity.rows[rowToUpdateIndex];
    const newRow: StudentChangeRequestReviewFormRow = {
      ...rowToUpdate,
      isEdited: true,
      isRevisedDataHighlighted: false,
      revisedDataCurrent: event.revisedData,
    };
    const newRows = [...state.reviewForm.entity.rows];
    newRows[rowToUpdateIndex] = newRow;
    return {
      ...state,
      reviewForm: {
        ...state.reviewForm,
        entity: {
          ...state.reviewForm.entity,
          rows: newRows,
          hasUnsavedChanges: true,
        },
      },
    };
  }),
  on(
    fromActions.studentChangeRequestReviewRevertRevisedData,
    (state, { fieldName }): StudentChangeRequestReviewState => {
      const rowToUpdateIndex = state.reviewForm.entity.rows.findIndex((row) => row.fieldName === fieldName);
      const rowToUpdate = state.reviewForm.entity.rows[rowToUpdateIndex];
      const newRow: StudentChangeRequestReviewFormRow = {
        ...rowToUpdate,
        isEdited: false,
        isRevisedDataHighlighted: false,
        revisedDataCurrent: { ...rowToUpdate.revisedDataOriginal },
      };
      const newRows = [...state.reviewForm.entity.rows];
      newRows[rowToUpdateIndex] = newRow;
      return {
        ...state,
        reviewForm: {
          ...state.reviewForm,
          entity: {
            ...state.reviewForm.entity,
            rows: newRows,
          },
        },
      };
    },
  ),
  on(fromActions.studentChangeRequestReviewApplyData, (state, { payload }): StudentChangeRequestReviewState => {
    const rowToUpdateIndex = state.reviewForm.entity.rows.findIndex((row) => row.fieldName === payload.fieldName);
    const rowToUpdate = state.reviewForm.entity.rows[rowToUpdateIndex];
    const newRow: StudentChangeRequestReviewFormRow = {
      ...rowToUpdate,
      isKeepExistingDataSelected: payload.isKeepExistingData,
    };
    const newRows = [...state.reviewForm.entity.rows];
    newRows[rowToUpdateIndex] = newRow;
    return {
      ...state,
      reviewForm: {
        ...state.reviewForm,
        entity: {
          ...state.reviewForm.entity,
          rows: newRows,
          hasUnsavedChanges: true,
        },
      },
    };
  }),
  // Apply change request
  on(fromActions.studentChangeRequestReviewConfirmBtnClick, (state): StudentChangeRequestReviewState => {
    const { isValidOnClientSide, validatedRows } = validateStudentChangeRequestFormRows(
      state.reviewForm.entity.operation,
      state.reviewForm.entity.rows,
    );
    return {
      ...state,
      reviewForm: {
        ...state.reviewForm,
        entity: {
          ...state.reviewForm.entity,
          rows: validatedRows,
          isValidOnClientSide,
        },
      },
    };
  }),
  on(fromActions.applyStudentChangeRequestRequested, (state): StudentChangeRequestReviewState => {
    return {
      ...state,
      applyChangeRequest: {
        isLoading: true,
      },
    };
  }),
  on(fromActions.applyStudentChangeRequestSuccess, (state, { response }): StudentChangeRequestReviewState => {
    const { isValid, errors } = response;
    if (isValid) {
      return {
        ...state,
        reviewForm: {
          ...state.reviewForm,
          entity: {
            ...state.reviewForm.entity,
            hasUnsavedChanges: false,
          },
        },
        // do not stop Apply change request spinner
      };
    }

    const errorsMap: { [fieldName: string]: ApplyStudentChangeRequestValidationError } = {};
    errors.forEach((error) => {
      errorsMap[error.field] = error;
    });
    const newRows = state.reviewForm.entity.rows.map((row) => {
      if (errorsMap[row.fieldName]) {
        return {
          ...row,
          isRevisedDataHighlighted: true,
        };
      }
      return row;
    });
    return {
      ...state,
      reviewForm: {
        ...state.reviewForm,
        entity: {
          ...state.reviewForm.entity,
          rows: newRows,
        },
      },
      applyChangeRequest: createEntityState(response),
    };
  }),
  on(fromActions.applyStudentChangeRequestFailed, (state, { error }): StudentChangeRequestReviewState => {
    return {
      ...state,
      applyChangeRequest: createEntityState(undefined, error),
    };
  }),
);
