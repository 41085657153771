import { combineReducers } from '@ngrx/store';
import * as fromUSNationalHolidays from './us-national-holidays.reducer';
import * as fromPortalEntityInfo from './portal-entity-info.reducer';
import * as fromCatalogDisplayInfo from './catalog-display-info.reducer';
import * as fromDefaultDistrictGrades from './default-district-grades.reducer';
import { routeGroupShiftsReducer, RouteGroupShiftsState } from './route-group-shifts.reducer';

export interface CommonDataState {
  nationalHolidays: fromUSNationalHolidays.USNationalHolidayState;
  portalEntityInfo: fromPortalEntityInfo.PortalEntityInfoState;
  catalogDisplayInfo: fromCatalogDisplayInfo.CatalogDisplayInfoState;
  defaultDistrictGrades: fromDefaultDistrictGrades.DefaultDistrictGradeState;
  routeGroupShifts: RouteGroupShiftsState;
}

export const featureReducer = combineReducers<CommonDataState>({
  nationalHolidays: fromUSNationalHolidays.nationalHolidayReducer,
  portalEntityInfo: fromPortalEntityInfo.portalEntityInfoReducer,
  catalogDisplayInfo: fromCatalogDisplayInfo.catalogDisplayInfoReducer,
  defaultDistrictGrades: fromDefaultDistrictGrades.defaultDistrictGradesReducer,
  routeGroupShifts: routeGroupShiftsReducer,
});
