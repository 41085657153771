import { createReducer, on } from '@ngrx/store';
import * as fromTypes from '../../types';
import * as fromActions from '../actions/vendor-list.actions';

export type AllVendorListState = {
  [key in fromTypes.VendorListName]?: VendorListState;
};

export interface VendorListState {
  page: {
    items: fromTypes.PortalEntity[];
    totalFound: number;
    isLoading: boolean;
    listError: fromTypes.WpError;
  };
  isFirstInitialized: boolean;
  staticFilters: fromTypes.VendorListFilter[];
  selectedVendorId: string;
  currentSearch: string;
  pagination: {
    page: number;
    pageSize: number;
  };
}

export const initialVendorListState: VendorListState = {
  page: {
    items: [],
    totalFound: undefined,
    isLoading: false,
    listError: undefined,
  },
  isFirstInitialized: false,
  staticFilters: [],
  selectedVendorId: undefined,
  currentSearch: undefined,
  pagination: {
    page: 0,
    pageSize: fromTypes.ui.DEFAULT_VENDOR_LIST_PAGE_SIZE,
  },
};

export const getInitialAllVendorLists = (): AllVendorListState => {
  const target = {} as AllVendorListState;
  fromTypes.vendorLists.forEach((listName) => {
    target[listName] = getInitialVendorListState();
  });
  return target;
};

export const getInitialVendorListState = (): VendorListState => {
  return {
    ...initialVendorListState,
    page: {
      ...initialVendorListState.page,
    },
    staticFilters: [],
    pagination: {
      ...initialVendorListState.pagination,
    },
  };
};

export const vendorListReducer = createReducer<AllVendorListState>(
  { ...getInitialAllVendorLists() },
  on(fromTypes.cleanUpStore, () => ({ ...getInitialAllVendorLists() })),
  /**
   * Initialize list
   */
  on(fromActions.listInitialized, (allState, action) => {
    const { listName, snapshot } = action;
    const state = allState[listName];
    const resultState = {
      ...state,
      isFirstInitialized: true,
      pagination: {
        ...state.pagination,
        page: snapshot.page,
        pageSize: snapshot.pageSize,
      },
      page: {
        ...state.page,
        totalFound: undefined,
      },
      currentSearch: snapshot.search,
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  /**
   * List changed
   */
  on(fromActions.searchChanged, (allState, action) => {
    const { listName, searchTerm } = action;
    const state = allState[listName];
    const resultState: VendorListState = {
      ...state,
      page: {
        ...state.page,
        totalFound: undefined,
      },
      pagination: {
        ...state.pagination,
        page: 0,
      },
      currentSearch: searchTerm,
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.pageChanged, (allState, action) => {
    const { listName, page } = action;
    const state = allState[listName];
    const resultState: VendorListState = {
      ...state,
      pagination: {
        ...state.pagination,
        page,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.pageSizeChanged, (allState, action) => {
    const { listName, pageSize } = action;
    const state = allState[listName];
    const resultState: VendorListState = {
      ...state,
      pagination: {
        ...state.pagination,
        page: 0,
        pageSize,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.vendorItemSelected, (allState, action) => {
    const { listName, vendorId } = action;
    const state = allState[listName];
    const resultState: VendorListState = {
      ...state,
      selectedVendorId: vendorId,
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  /**
   * Load vendor list
   */
  on(fromActions.vendorLoadListRequested, (allState, action) => {
    const { listName } = action;
    const state = allState[listName];
    const resultState: VendorListState = {
      ...state,
      page: {
        ...state.page,
        isLoading: true,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.vendorLoadListSuccess, (allState, action) => {
    const { listName, items, totalFound } = action;
    const state = allState[listName];
    const resultState: VendorListState = {
      ...state,
      page: {
        ...state.page,
        isLoading: false,
        totalFound,
        items,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
  on(fromActions.vendorLoadListFailed, (allState, action) => {
    const { listName, error } = action;
    const state = allState[listName];
    const resultState: VendorListState = {
      ...state,
      page: {
        ...state.page,
        isLoading: false,
        listError: error,
      },
    };
    return {
      ...allState,
      [listName]: resultState,
    };
  }),
);
