export class MapDrawingManager {
  existingPolygons: google.maps.Polygon[] = [];

  private drawingManager: google.maps.drawing.DrawingManager;

  constructor(private map: google.maps.Map) {}

  public setDrawingManager(mapDrawingOptions: google.maps.drawing.DrawingManagerOptions): void {
    this.drawingManager = new google.maps.drawing.DrawingManager(mapDrawingOptions);
    this.drawingManager.setMap(this.map);
    // this.setNewPolygons();
  }

  public getDrawerManagerInstance(): google.maps.drawing.DrawingManager {
    return this.drawingManager;
  }

  public setDrawingMode(drawingMode: google.maps.drawing.OverlayType): void {
    this.drawingManager.setDrawingMode(drawingMode);
  }

  public getDrawingMode(): google.maps.drawing.OverlayType {
    return this.drawingManager.getDrawingMode();
  }

  // private setNewPolygons(): void {
  //   google.maps.event.addListener(
  //     this.drawingManager,
  //     'overlaycomplete',
  //     (event: { type: google.maps.drawing.OverlayType; overlay: google.maps.Polygon }) => {
  //       if (event.type === google.maps.drawing.OverlayType.POLYGON) {
  //         const newPolygon = event.overlay;
  //
  //         this.existingPolygons.push(newPolygon);
  //       }
  //     },
  //   );
  // }
}
