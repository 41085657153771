import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-edit-link-btn',
  templateUrl: './edit-link-btn.component.html',
  styleUrls: ['./edit-link-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditLinkBtnComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
