import { createAction, props } from '@ngrx/store';
import { SideBarTabId } from '@rootTypes';

export const screenXLargeChanged = createAction('[UI]: Screen xlarge changed', props<{ isXLarge: boolean }>());

export const sectionLoadStart = createAction('[UI]: Section load start');

export const sectionLoadEnd = createAction('[UI]: Section load end');

export const sideBarExpandToggled = createAction('[UI]: Side bar expand toggled');

export const setInitialSideBarExpand = createAction(
  '[UI]: Set initial side bar expanded',
  props<{ isExpanded: boolean }>(),
);

export const sideBarSectionChanged = createAction('[UI]: Side bar section changed', props<{ section: SideBarTabId }>());

export const drawerOpenedChanged = createAction('[UI]: Drawer opened changed', props<{ isHalfDrawer: boolean }>());

export const drawerClosedChanged = createAction('[UI]: Drawer closed changed');

export const drawerClosedSelected = createAction('[UI]: Drawer closed selected');
