<div
  class="wp-dropdown-option"
  #optionElement
  [ngStyle]="{height: height}"
  [ngClass]="{ 'disabled': disabled, 'selected': selected, highlighted: highlighted, 'top-border': isTopBorder }"
  tabindex="0"
  (keydown.enter)="optionElement.click()"
>
  <div class="wp-dropdown-option__text">
    <ng-content></ng-content>
  </div>
  <div *ngIf="showTickWhenSelected && selected" class="tick">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="10"
      viewBox="0 0 13 10"
    >
      <g fill="none" fill-rule="evenodd" stroke-linecap="square">
        <g stroke="#47A540" stroke-width="2">
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M3.999 7.011L10.489.476M.565 4L3.762 7.307"
                    transform="translate(-947 -96) translate(771 36) translate(176 60) translate(1 1)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</div>
