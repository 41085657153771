import { Injectable } from '@angular/core';
import { SmartInputDistrictStudent, SmartSelect } from '../models';

@Injectable()
export class SmartInputStudentSearchService {
  public district: SmartSelect;
  public student: SmartSelect;

  public initialize(model: SmartInputDistrictStudent): void {
    this.district = model.district;
    this.student = model.student;
  }
}
