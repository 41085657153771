import { CampusListFilter, CampusListFilterType } from '../campus-list-entity-filter';
import { PortalEntityType } from '../../../../types';
import { encodeDataComponents } from '../../../../types/utils';

const OUTER_FILTER_SEPARATOR = ';';
const INNER_FILTER_SEPARATOR = ',';

export function getCampusFiltersFromSerialized(filterStr: string): CampusListFilter[] {
  if (!(filterStr && filterStr.length)) {
    return [];
  }
  const filterItems: string[] = filterStr.split(OUTER_FILTER_SEPARATOR);
  const arrayedFilterItems: string[][] = filterItems.map((s) => s.split(INNER_FILTER_SEPARATOR));
  return arrayedFilterItems.map((arrayedFilter) => {
    const [type, ...rest] = arrayedFilter;
    switch (type as CampusListFilterType) {
      case CampusListFilterType.ENTITY: {
        const entityType = rest[0] as PortalEntityType;
        const entityId = rest[1];
        return getEntityFilter(entityType, entityId);
      }
    }
  });
}

export function getEntityFilter(entityType: PortalEntityType, entityId: string): CampusListFilter {
  return {
    id: encodeDataComponents([CampusListFilterType.ENTITY, entityType, entityId]),
    type: CampusListFilterType.ENTITY,
    payload: {
      entityId,
      type: entityType,
    },
  };
}

export function serializeCampusFilters(filters: CampusListFilter[]): string {
  if (!(filters && filters.length)) {
    return undefined;
  }
  return encodeDataComponents(
    filters.map((f) => f.id),
    ';',
  );
}
