import { EmployeeListSnapshot, EntityStatus, SerializedEmployeeListSnapshot } from '../entities';
import * as ui from '../ui';

export const getSnapshotFromSerialized = (source: SerializedEmployeeListSnapshot): EmployeeListSnapshot => {
  if (!source) {
    return undefined;
  }
  const { search, page, status, pageSize } = source;
  const res: EmployeeListSnapshot = {
    search,
    page: page ? parseInt(page, 10) - 1 : ui.DEFAULT_EMPLOYEE_LIST_PAGE_NUMBER,
    status: (status as EntityStatus) || ui.DEFAULT_EMPLOYEE_LIST_STATUS,
    pageSize: pageSize ? parseInt(pageSize, 10) : ui.DEFAULT_EMPLOYEE_LIST_PAGE_SIZE,
    filters: [], // no filters for now
  };
  return res;
};
