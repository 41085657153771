/**
 * An instance of this class is used when Segment feature
 * is disabled by configuration (defined in .env file).
 * The class is also used as a type definition for the library,
 * it defines methods used by the app.
 */
export class SegmentAnalytics {
  public debug(enabled: boolean): void {}

  public identify(userId?: string, traits?: any, options?: any, callback?: () => void): void {
    if (typeof callback === 'function') {
      callback();
    }
  }

  public page(category?: string, name?: string, properties?: any, options?: any, callback?: () => void): void {
    if (typeof callback === 'function') {
      callback();
    }
  }

  public ready(callback: () => void): void {
    callback();
  }

  public reset(): void {}

  public track(event: string, properties?: any, options?: any, callback?: () => void): void {
    if (typeof callback === 'function') {
      callback();
    }
  }
}
