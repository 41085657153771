import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, filter, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import * as fromListActions from '../actions/campus-list.actions';
import * as fromDataActions from '../actions/campus-data.actions';
import * as fromListSelectors from '../selectors/campus-list.selectors';
import * as fromRoutes from '../../routes';
import * as fromTypes from '../../types';
import { UserRoles } from '../../types';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { State } from '@rootStore';
import { Observable, of, timer } from 'rxjs';
import { CampusApiService } from '../../services/campus-api.service';
import { getUserRole } from '@rootTypes/utils';
import { AccountsFacade } from '../../../../auth/store';

@Injectable()
export class CampusListEffects {
  constructor(
    private actions$: Actions,
    private apiService: CampusApiService,
    private store: Store<State>,
    private campusEditorRouter: fromRoutes.CampusEditorRouterService,
    private accountsFacade: AccountsFacade,
  ) {}

  public listControlsChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromListActions.setCampusListState,
        fromListActions.campusSearchInputChanged,
        fromListActions.campusStatusChanged,
        fromListActions.filtersChanged,
        fromListActions.staticFiltersChanged,
        fromListActions.currentPageChanged,
        fromListActions.pageSizeChanged,
      ),
      map((action) => action.listName),
      switchMap((listName) => {
        return this.store.select(fromListSelectors.getCampusListState, { listName }).pipe(
          take(1),
          map((state) => {
            return fromListActions.campusListLoadRequested({
              listName,
              request: {
                searchQuery: state.currentSearchQuery || undefined,
                currentPage: state.pagination.currentPage,
                filters: [...state.filters, ...state.staticFilters],
                pageSize: state.pagination.pageSize,
                listStatus: state.selectedCampusStatus,
              },
            });
          }),
        );
      }),
    ),
  );

  public loadListRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromListActions.campusListLoadRequested),
      debounceTime(200),
      switchMap((action) => {
        return this.loadCampusList(action.listName, action.request);
      }),
    ),
  );

  // load campus details with debounce,
  // quit, if the card was destroyed
  public campusCardInitialized$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromListActions.campusCardInitialized),
      mergeMap((action) => {
        const { campusId } = action;
        return timer(1200).pipe(
          takeUntil(
            this.actions$.pipe(
              ofType(fromListActions.campusCardDestroyed),
              filter((a) => a.campusId === campusId),
            ),
          ),
          map(() => action),
        );
      }),
      map((action) => {
        const { campusId } = action;
        return fromDataActions.loadCampusDetailsRequested({ campusId });
      }),
    ),
  );

  public createCampusClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromListActions.addCampusClicked),
        switchMap(() => {
          if (getUserRole() === UserRoles.ENTERPRISE) {
            return this.accountsFacade.getSelectedAccountId().pipe(
              take(1),
              map((accountId) => ({
                createForDistrictId: accountId,
                createCampusDisableDistrict: true,
              })),
            );
          }
          return of({ createForDistrictId: undefined, createCampusDisableDistrict: false });
        }),
        tap(({ createForDistrictId, createCampusDisableDistrict }) => {
          this.campusEditorRouter.navigate({
            createCampusForDistrictId: createForDistrictId,
            createCampusDisableDistrict,
          });
        }),
      ),
    { dispatch: false },
  );

  private loadCampusList(
    listName: fromTypes.CampusListName,
    snapshot: fromTypes.CampusListStateSnapshot,
  ): Observable<Action> {
    const apiRequest = fromTypes.utils.campusListStateSnapshotToRequest(snapshot);
    return this.apiService.entityFilter(apiRequest).pipe(
      map((res) =>
        fromListActions.campusListLoadSuccess({
          listName,
          campusIds: res.items as fromTypes.PortalEntity[],
          totalFound: res.total,
        }),
      ),
      catchError((err) => {
        console.log(err);
        return of(
          fromListActions.campusListLoadFailed({
            listName,
            error: {
              text: 'Failed to load campus list',
            },
          }),
        );
      }),
    );
  }
}
