import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { Address, MapBounds, MapLocation } from '@rootTypes';
import { GeocoderService } from '../../../../core/services';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getAddressFromPlace } from '@rootTypes/utils';
import { getCoordinatesFromString } from '../utils/get-coordinates-from-string';
import { GOOGLE_AUTOCOMPLETE_DEBOUNCE, GOOGLE_AUTOCOMPLETE_DEFAULT_BIAS } from '../utils/config';

export type AddressAutocompleteOption =
  | (Pick<Address, 'formatted_address'> & { placeId?: string; geometry?: Address['geometry'] })
  | Address;

@Injectable()
export class GooglePlaceAutocompleteCustomUIStore {
  public isLoading$: Observable<boolean>;
  public isEmpty$: Observable<boolean>;
  public options$: Observable<AddressAutocompleteOption[]>;
  public loadError$: Observable<string | null>;

  private inputValue$$ = new BehaviorSubject<string>(null);
  private isLoading$$ = new BehaviorSubject<boolean>(false);
  private loadError$$ = new BehaviorSubject<string | null>(null);
  private options$$ = new BehaviorSubject<AddressAutocompleteOption[]>([]);
  private placesService: google.maps.places.PlacesService;
  private autocompleteService = new google.maps.places.AutocompleteService();
  private currentToken: google.maps.places.AutocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
  private lastSelectedAddress: Address | null = null;
  private currentGoogleAutocompleteBias: MapBounds = GOOGLE_AUTOCOMPLETE_DEFAULT_BIAS;

  constructor(private geocoderService: GeocoderService) {
    this.isLoading$ = this.isLoading$$.asObservable();
    this.options$ = this.options$$.asObservable();
    this.loadError$ = this.loadError$$.asObservable();
    this.isEmpty$ = combineLatest([this.isLoading$$, this.options$$]).pipe(
      map(([loading, options]) => !loading && !options?.length),
    );
    this.inputValue$$
      .pipe(
        tap((v) => {
          this.loadError$$.next(null);
          if (v?.length) {
            this.isLoading$$.next(true);
          } else {
            this.isLoading$$.next(false);
            this.options$$.next([]);
          }
        }),
        filter((v) => !!v?.length),
        debounceTime(GOOGLE_AUTOCOMPLETE_DEBOUNCE),
        switchMap((v) => this.getOptionsForInput(v)),
        filter(({ inputValue }) => inputValue === this.inputValue$$.value),
        tap(({ options, error }) => {
          this.isLoading$$.next(false);
          this.options$$.next(options);
          this.loadError$$.next(error ?? null);
        }),
        takeUntilDestroyed(),
      )
      .subscribe({
        complete: () => this.onDestroy(),
      });
  }

  public setGoogleAutocompleteBias(bounds: MapBounds): void {
    this.currentGoogleAutocompleteBias = bounds ?? GOOGLE_AUTOCOMPLETE_DEFAULT_BIAS;
  }

  public getLastSelectedAddress(): Address | null {
    return this.lastSelectedAddress;
  }

  public setLastSelectedAddress(address: Address | null): void {
    this.lastSelectedAddress = address;
  }

  public resetSearch(): void {
    this.inputValue$$.next(null);
    this.options$$.next(null);
    this.isLoading$$.next(false);
    this.loadError$$.next(null);
  }

  public onInputValueChanged(value: string): void {
    this.inputValue$$.next(value);
  }

  public initPlacesServiceForElement(element: HTMLDivElement | google.maps.Map) {
    this.placesService = new google.maps.places.PlacesService(element);
  }

  /**
   * Use this method to get address from option on click
   * @param option
   */
  public async onOptionClick(option: AddressAutocompleteOption): Promise<Address> {
    if (!this.placesService) {
      throw new Error('No place service found. Call initPlacesServiceForElement first');
    }
    if (this.isFullAddressOption(option)) {
      return option;
    }
    const address = await this.getAddressFromPlaceId(option.placeId);
    this.currentToken = new google.maps.places.AutocompleteSessionToken();
    this.lastSelectedAddress = address;
    return address;
  }

  private async getOptionsForInput(inputValue: string | null): Promise<{
    inputValue: string | null;
    options: AddressAutocompleteOption[];
    error?: string;
  }> {
    try {
      const coordsFromInput = getCoordinatesFromString(inputValue);
      const options = coordsFromInput
        ? await this.getOptionsFromCoordinates(coordsFromInput)
        : await this.getOptionsFromPlacePredictions(inputValue);
      return {
        options,
        inputValue,
      };
    } catch (error) {
      return {
        inputValue,
        options: [],
        error: error?.message ?? error,
      };
    }
  }

  private getOptionsFromPlacePredictions(inputValue: string | null): Promise<AddressAutocompleteOption[]> {
    const locationBias = new google.maps.LatLngBounds(
      this.currentGoogleAutocompleteBias.southWest,
      this.currentGoogleAutocompleteBias.northEast,
    );
    return this.autocompleteService
      .getPlacePredictions({
        input: inputValue,
        sessionToken: this.currentToken,
        locationBias,
        language: 'en',
        region: 'us',
      })
      .then((response) => {
        return (response?.predictions ?? [])
          .filter((p) => !!p.place_id)
          .map((p) => {
            return {
              placeId: p.place_id,
              formatted_address: p.description,
            } satisfies AddressAutocompleteOption;
          });
      });
  }

  private getOptionsFromCoordinates(coords: MapLocation): Promise<AddressAutocompleteOption[]> {
    const latLng = new google.maps.LatLng(coords.lat, coords.lng);
    return this.geocoderService.getAddressFromLocation(latLng).then((res) => (res ? [res] : []));
  }

  private isFullAddressOption(option: AddressAutocompleteOption): option is Address {
    return !!(option as Address).geometry?.location;
  }

  private getAddressFromPlaceId(placeId: string): Promise<Address> {
    return new Promise<Address>((resolve, reject) => {
      this.placesService.getDetails(
        {
          placeId,
          fields: ['address_components', 'geometry', 'formatted_address', 'name'],
          language: 'en',
          region: 'us',
          sessionToken: this.currentToken,
        },
        (result, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && !!result.geometry) {
            resolve(getAddressFromPlace(result));
          } else {
            reject('Failed to get place details');
          }
        },
      );
    });
  }

  private onDestroy(): void {}
}
