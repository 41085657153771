import { createAction, props } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

const GO = '[Router] GO';
const GO_AND_CLOSE_DRAWER = '[Router] GO and close drawer';
const REPLACE_URL_WITHOUT_RELOADING = '[Router] Replace url without reloading';
const OPEN_IN_DRAWER = '[Router] Open in drawer';
const BACK = '[Router] BACK';
const FORWARD = '[Router] Forward';

export const lazyLoadingStart = createAction('[Router] Lazy loading start', props<{ url: string }>());

export const go = createAction(
  GO,
  props<{
    path: any[];
    query?: {};
    extras?: NavigationExtras;
  }>(),
);

export const goAndCloseDrawer = createAction(
  GO_AND_CLOSE_DRAWER,
  props<{
    path: any[];
    query?: {};
    extras?: NavigationExtras;
  }>(),
);

export const replaceUrlWithoutReloading = createAction(
  REPLACE_URL_WITHOUT_RELOADING,
  props<{
    path: any[];
    query?: {};
    extras?: NavigationExtras;
  }>(),
);

export const openInDrawer = createAction(
  OPEN_IN_DRAWER,
  props<{
    path: any[];
    query?: {};
    extras?: NavigationExtras;
    displayBackText?: string;
    isHalfDrawer?: boolean;
  }>(),
);

/**
 * count - how many states back
 */
export const back = createAction(BACK, props<{ count?: number }>());

export const forward = createAction(FORWARD, props<any>());
