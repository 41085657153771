import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

export type AcceptPDF = 'application/pdf';
export type AcceptImage = 'image/*';

@Component({
  selector: 'wp-file-input-button',
  template: `
    <div class="file-input-wrap">
      <div class="trigger">
        <input
          [id]="inputId"
          type="file"
          class="file-input"
          [accept]="acceptFileType"
          [disabled]="disabled"
          [hidden]="true"
          [multiple]="multiple"
          (change)="onFileInputChange($event)"
        />
        <label [for]="inputId">
          <wp-btn-label-for-input
            [labelFor]="inputId"
            [slim]="true"
            [disabled]="disabled"
            [height]="'36px'"
            [width]="buttonWidthPx + 'px'"
          >
            <wp-btn-icon [marginRight]="'4px'">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4 9.57861H5.97894C6.53123 9.57861 6.97894 9.1309 6.97894 8.57861C6.97894 8.02633 6.53123 7.57861 5.97894 7.57861H4C2.34732 7.57861 1.28018 8.58739 1.01184 10.3158L1 10.4692V16.688C1 18.2974 2.35533 19.5786 4 19.5786H16C17.6447 19.5786 19 18.2974 19 16.688V10.4692C19 8.85981 17.6447 7.57861 16 7.57861H14.0376C13.4853 7.57861 13.0376 8.02633 13.0376 8.57861C13.0376 9.1309 13.4853 9.57861 14.0376 9.57861H16C16.5645 9.57861 17 9.99032 17 10.4692V16.688C17 17.1669 16.5645 17.5786 16 17.5786H4C3.43553 17.5786 3 17.1669 3 16.688V10.5506C3.128 9.81491 3.39664 9.57861 4 9.57861Z"
                  fill="#47A540"
                />
                <path
                  d="M9.88913 2.4143L13.4247 5.94984C13.8152 6.34036 14.4484 6.34036 14.8389 5.94984C15.2294 5.55931 15.2294 4.92615 14.8389 4.53562L10.5962 0.292982C10.2057 -0.0975423 9.57255 -0.0975423 9.18203 0.292982L4.99938 4.47563C4.60885 4.86616 4.60885 5.49932 4.99938 5.88985C5.3899 6.28037 6.02307 6.28037 6.41359 5.88985L9.88913 2.4143Z"
                  fill="#47A540"
                />
                <path
                  d="M9.02493 1.70141L9 11.7042C8.99863 12.2565 9.44522 12.7053 9.99751 12.7067C10.5498 12.7081 10.9986 12.2615 11 11.7092L11.0249 1.7064C11.0263 1.15411 10.5797 0.705285 10.0274 0.703909C9.47514 0.702533 9.02631 1.14913 9.02493 1.70141Z"
                  fill="#47A540"
                />
              </svg>
            </wp-btn-icon>
            {{ label }}
          </wp-btn-label-for-input>
        </label>
      </div>
      <div *ngIf="files?.length; else empty" class="preview">
        <ng-container *ngFor="let f of files">
          <div class="name">{{ f.name }}</div>
          <div class="details">
            <div class="details-item">{{ f.size | fileSizeDisplay }}</div>
            <div *ngIf="(f | isPdfFile) && f | pdfFilePageCount | async; let pageCount" class="details-item">
              {{ pageCount | displayQuantity: 'page' }}
            </div>
          </div>
        </ng-container>
      </div>
      <ng-template #empty>
        <div class="required-error-message" *ngIf="isRequired">{{ requiredErrorMessage }}</div>
      </ng-template>
    </div>
  `,
  styles: [
    `
      .file-input-wrap {
        display: flex;
      }

      .trigger {
        position: relative;
      }

      .preview {
        margin-left: 20px;
        position: relative;
        top: -3px;
      }

      .preview > .details {
        color: rgba(99, 99, 101, 1);
        display: flex;
      }

      .details-item:not(:last-child) {
        margin-right: 4px;
      }

      .details-item:not(:last-child):after {
        content: ',';
      }
      .required-error-message {
        display: flex;
        align-items: center;
        margin-left: 12px;
        color: #d52504;
        font-size: 12px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInputButtonComponent implements OnInit, OnDestroy {
  @Input() public label = 'Upload';
  @Input() public buttonWidthPx = 150;
  @Input() public acceptFileType: AcceptPDF | AcceptImage | string = '*/*';
  @Input() public multiple = false;
  @Input() public disabled: boolean;
  @Input() public files: File[];
  @Input() public required = false;
  @Input() public requiredErrorMessage = 'File is required';
  @Input() public requiredValidationRequest?: Observable<void>;

  @Output() public fileChange = new EventEmitter<File[] | null>();

  inputId = `file-input-${Math.random().toString(36).substr(2, 9)}`;
  isRequired = false;

  private sub = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.requiredValidationRequest) {
      this.sub.add(
        this.requiredValidationRequest.subscribe(() => {
          this.setIsRequired();
          this.cdRef.detectChanges();
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onFileInputChange(event: any): void {
    const files = event.target.files;
    if (files.length) {
      this.files = Array.from(files);
      this.fileChange.emit(this.files);
    }
    this.setIsRequired();
  }

  private setIsRequired(): void {
    this.isRequired = !!this.required && !this.files?.length;
  }
}
