import { PortalCircleOptions } from '../types/portal-map-circle';
import LatLngLiteral = google.maps.LatLngLiteral;
import { Observable, Subject, Subscription } from 'rxjs';

export class MapCircleRenderer {
  private gCircles: RenderedCircle[] = [];
  private subs: Subscription;

  constructor(private map: google.maps.Map) {}

  public async setCircles(circles: PortalCircleOptions[]): Promise<void> {
    if (!circles) {
      return;
    }
    const removedCircles = this.getRemovedCircles(this.gCircles, circles);
    const addCircles = this.getAddedCircles(this.gCircles, circles);
    removedCircles.forEach((removed) => {
      removed.remove();
      const removedIndex = this.gCircles.findIndex((gc) => gc === removed);
      this.gCircles.splice(removedIndex, 1);
    });
    this.gCircles.push(...(addCircles || []));
    const promises = addCircles.map((c) => c.draw());
    await Promise.all(promises);
    if (this.subs) {
      this.subs.unsubscribe();
    }
    this.subs = new Subscription();
    this.gCircles.forEach((c) => {
      if (c.options.tooltip?.closeOtherTooltipsOnOpen) {
        const s = c.openTooltipEvent$.subscribe(() => {
          this.gCircles.forEach((other) => {
            if (other !== c) {
              other.closeTooltip();
            }
          });
        });
        this.subs.add(s);
      }
    });
  }

  public destroy(): void {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  private getAddedCircles(previous: RenderedCircle[], newCircles: PortalCircleOptions[]): RenderedCircle[] {
    const result: RenderedCircle[] = [];
    newCircles.forEach((newCircle) => {
      if (!newCircle.shape.compareId) {
        result.push(new RenderedCircle(newCircle, this.map));
      } else {
        const wasAdded = previous.some((prevCircle) => prevCircle.compareId === newCircle.shape.compareId);
        if (!wasAdded) {
          result.push(new RenderedCircle(newCircle, this.map));
        }
      }
    });
    return result;
  }

  private getRemovedCircles(previous: RenderedCircle[], newCircles: PortalCircleOptions[]): RenderedCircle[] {
    const result = [];
    previous.forEach((prevCircle) => {
      const prevCompareId = prevCircle.compareId;
      if (!prevCompareId) {
        result.push(prevCircle);
      } else {
        const isPresentInTheNewBatch = newCircles.some((newCircle) => newCircle.shape.compareId === prevCompareId);
        if (!isPresentInTheNewBatch) {
          result.push(prevCircle);
        }
      }
    });
    return result;
  }
}

export class RenderedCircle {
  public readonly compareId: string;
  public openTooltipEvent$: Observable<void>;
  private gCircle: google.maps.Circle;
  private circleMarker: google.maps.Marker;
  private tooltip: any;
  private mapClickListener: google.maps.MapsEventListener;
  private centerChangedListener: google.maps.MapsEventListener;
  private radiusChangedListener: google.maps.MapsEventListener;
  private openTooltipEvent$$ = new Subject<void>();
  constructor(
    public options: PortalCircleOptions,
    private map: google.maps.Map,
  ) {
    this.compareId = options.shape.compareId;
    this.openTooltipEvent$ = this.openTooltipEvent$$.asObservable();
  }

  public async draw(): Promise<void> {
    this.gCircle = new google.maps.Circle({
      center: this.options.shape.center,
      radius: this.options.shape.radius,
      fillColor: this.options.shape.fillColor,
      fillOpacity: this.options.shape.fillOpacity,
      strokeWeight: this.options.shape.strokeWeight,
      strokeColor: this.options.shape.strokeColor,
      clickable: this.options.shape.clickable,
      editable: this.options.shape.editable,
      zIndex: this.options.shape.zIndex | 100,
      map: this.map,
    });
    if (this.options.marker) {
      this.circleMarker = new google.maps.Marker({
        ...this.options.marker,
        position: this.options.marker.position
          ? ({ lat: this.options.marker.position.lat, lng: this.options.marker.position.lng } as LatLngLiteral)
          : this.options.shape.center,
      });
      this.circleMarker.setMap(this.map);
    }

    if (this.options.tooltip) {
      this.gCircle.addListener('click', async () => {
        const cm = await import('./custom-html-marker');
        if (!this.tooltip) {
          this.tooltip = new cm.CustomHtmlMarker(
            new google.maps.LatLng(this.options.shape.center.lat as number, this.options.shape.center.lng as number),
            this.map,
            '',
            '0px',
            '0px',
            (this.options.shape.zIndex || 0) + 1,
            {
              tooltip: {
                fix: true,
                html: this.options.tooltip.html,
              },
              htmlListeners: this.options.tooltip.htmlListeners ?? [],
            },
          );
        } else {
          this.tooltip.show();
        }
        this.openTooltipEvent$$.next();
      });

      this.mapClickListener = this.map.addListener('click', () => {
        this.closeTooltip();
      });
    }

    this.gCircle.setMap(this.map);
    if (this.options.shape.onCenterChanged) {
      this.centerChangedListener = google.maps.event.addListener(this.gCircle, 'center_changed', () => {
        const newCenter = this.gCircle.getCenter();
        this.options.shape.onCenterChanged(newCenter);
      });
    }
    if (this.options.shape.onRadiusChanged) {
      this.radiusChangedListener = google.maps.event.addListener(this.gCircle, 'radius_changed', () => {
        const newRadius = this.gCircle.getRadius();
        this.options.shape.onRadiusChanged(newRadius);
      });
    }
  }

  public remove(): void {
    google.maps.event.clearInstanceListeners(this.gCircle);
    this.gCircle.setMap(null);
    if (this.circleMarker) {
      this.circleMarker.setMap(null);
    }
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
    if (this.centerChangedListener) {
      this.centerChangedListener.remove();
    }
    if (this.tooltip) {
      this.tooltip.remove();
    }
    if (this.radiusChangedListener) {
      this.radiusChangedListener.remove();
    }
  }

  public closeTooltip(): void {
    if (this.tooltip) {
      this.tooltip.hide();
    }
  }
}
