import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractPopupComponent } from '../../../../core/popupable/types';
import { OpenRideVideoEvent } from '@rootTypes/entities/ride/open-ride-video-event';
import { PopupRef } from '../../../../core/popupable/types/popup-ref';
import { WpError } from '@rootTypes';
import { ApiService } from '../../../../api/api.service';
import { map, take } from 'rxjs/operators';
import { GetRideVideoRequest } from '../../../../api/endpoints/get-ride-video';
import { BehaviorSubject } from 'rxjs';

interface RideVideoPopupState {
  isLoading: boolean;
  error: WpError | null;
  videoUrl: string | null;
  videoWidthStr: string;
}
@Component({
  selector: 'wp-ride-video-popup',
  template: `
    <wp-common-popup-page [isDarkMode]="isDarkMode$ | async">
      <wp-common-popup-page-top (closeClicked)="onClose()">
        <wp-common-popup-page-title [isDarkMode]="isDarkMode$ | async"> Ride video </wp-common-popup-page-title>
      </wp-common-popup-page-top>
      <wp-common-popup-page-body>
        <div class="ride-video-popup">
          <ng-container *ngIf="isLoading$ | async; else loaded">
            <wp-section-spinner [sectionHeight]="'100%'"></wp-section-spinner>
          </ng-container>
          <ng-template #loaded>
            <ng-container *ngIf="error$ | async; else notError">
              <wp-section-error [sectionHeightStr]="'100%'" [error]="error$ | async"></wp-section-error>
            </ng-container>
            <ng-template #notError>
              <div style="width: 100%">
                <video id="ride-video-element" width="525px" height="480px" controls autoplay>
                  <source [src]="videoUrl$ | async" />
                </video>
              </div>
            </ng-template>
          </ng-template>
        </div>
      </wp-common-popup-page-body>
    </wp-common-popup-page>
  `,
  styles: [
    `
      .ride-video-popup {
        height: 500px;
        min-width: 525px;
        position: relative;
      }

      .hidden {
        opacity: 0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RideVideoPopupComponent implements AbstractPopupComponent<OpenRideVideoEvent, any>, OnInit {
  private state$ = new BehaviorSubject<RideVideoPopupState>({
    isLoading: true,
    error: null,
    videoUrl: null,
    videoWidthStr: '525px',
  });
  public isLoading$ = this.state$.pipe(map((s) => s.isLoading));
  public error$ = this.state$.pipe(map((s) => s.error));
  public videoUrl$ = this.state$.pipe(map((s) => s.videoUrl));
  public isDarkMode$ = this.videoUrl$.pipe(map((videoUrl) => !!videoUrl));
  public isVideoHidden$ = new BehaviorSubject<boolean>(true);
  popupRef: PopupRef<OpenRideVideoEvent, any>;

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.loadRideVideo();
  }

  onClose(): void {
    this.popupRef.close();
  }

  private loadRideVideo(): void {
    this.state$.next({ isLoading: true, error: null, videoUrl: null, videoWidthStr: '525px' });
    const request: GetRideVideoRequest = {
      rideId: this.popupRef.data.rideId,
      type: this.popupRef.data.type === 'all' ? 'ALL' : 'DRIVER',
      timestamp: this.popupRef.data.timestamp,
    };
    this.api
      .getRideVideo(request)
      .pipe(take(1))
      .subscribe(
        (resp) => {
          this.state$.next({ isLoading: false, error: null, videoUrl: resp.videoUrl, videoWidthStr: '525px' });
          setTimeout(() => {
            const videoElement: HTMLVideoElement = document.querySelector('#ride-video-element');
            if (videoElement) {
              // set width to auto to make sure video is not stretched
              videoElement.style.width = 'auto';
            }
          }, 1200);
        },
        (originalError) => {
          const error: WpError = {
            text: 'Failed to load ride video',
            originalError,
            retryFn: () => this.loadRideVideo(),
          };
          this.state$.next({ isLoading: false, error, videoUrl: null, videoWidthStr: '525px' });
        },
      );
  }
}
