import * as fromRootTypes from '@rootTypes';
import { DriverByWeekday, DriverByWeekdayMap, DriverType } from '../driver-by-weekday';

interface RouteDrivers {
  [driverId: string]: fromRootTypes.route.RouteDriver;
}

export const dbToDriverIdsByWeekdays = (
  dbRouteDrivers: DriverByWeekdayMap,
): fromRootTypes.WeekdayDependentValue<RouteDrivers> => {
  const result = {} as fromRootTypes.WeekdayDependentValue<RouteDrivers>;
  if (dbRouteDrivers) {
    Object.keys(dbRouteDrivers).forEach((dbWeekday) => {
      const weekdayObj = dbRouteDrivers[dbWeekday] as DriverByWeekday;
      const driverId =
        weekdayObj.type === DriverType.VENDOR
          ? getDriverIdForVendor(weekdayObj.vendor)
          : getDriverIdForZumer(weekdayObj.zumer);
      result[dbWeekday] = driverId;
    });
  }
  return result;
};

function getDriverIdForZumer(driverObj: DriverByWeekday['zumer']): RouteDrivers {
  if (!driverObj) {
    return null;
  }
  if (!driverObj.drivers) {
    return null;
  }
  return Object.keys(driverObj.drivers).reduce((prev, currDriverId) => {
    return {
      ...prev,
      [currDriverId]: {
        driverId: currDriverId,
        type: fromRootTypes.route.RouteDriverType.ZUMER,
        isDefault: driverObj.defaultDriverId === currDriverId,
      } as fromRootTypes.route.RouteDriver,
    };
  }, {});
}

function getDriverIdForVendor(driverObj: DriverByWeekday['vendor']): RouteDrivers {
  if (!driverObj) {
    return null;
  }
  return {
    [driverObj.driverId]: {
      driverId: driverObj.driverId,
      type: fromRootTypes.route.RouteDriverType.VENDOR,
      isDefault: true,
    },
  };
}
