import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayNumber',
})
export class DisplayNumberPipe implements PipeTransform {
  transform(value?: number): string {
    if (typeof value === 'number') {
      return value + '';
    }
    return '--';
  }
}
