import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { WpError } from '@rootTypes';
import { ErrorParserService, ErrorStatements } from '../../../store/services/error-parser.service';

@Component({
  selector: 'wp-section-error',
  templateUrl: './section-error.component.html',
  styleUrls: ['./section-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionErrorComponent implements OnInit {
  @Input() public sectionHeightStr = '100px';
  @Input() public error: WpError;
  @Input() public isGeneralMessage = true;
  @Input() public isContextId = true;

  public errorStatements: ErrorStatements;

  constructor(private errorParser: ErrorParserService) {}

  ngOnInit(): void {
    if (this.error) {
      this.errorStatements = this.errorParser.getErrorStatements(this.error);
    }
  }
}
