import { ChangeDetectionStrategy, Component, OnInit, SecurityContext } from '@angular/core';
import { AbstractPopupComponent } from '../../core/popupable/types';
import { PopupRef } from '../../core/popupable/types/popup-ref';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ConfirmationOption, ConfirmationResponse } from '../../api/prompt-api/types';

@Component({
  selector: 'wp-prompt-confirmation-popup',
  templateUrl: './prompt-confirmation-popup.component.html',
  styleUrls: ['./prompt-confirmation-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromptConfirmationPopupComponent
  implements OnInit, AbstractPopupComponent<ConfirmationResponse, ConfirmationOption>
{
  popupRef: PopupRef<any, any>;
  public title: string;
  public messageHTML: SafeHtml;
  public options: ConfirmationOption[];
  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    const data = this.popupRef.data as ConfirmationResponse;
    this.title = data.apiPrompt.title?.value;
    this.messageHTML = this.sanitizer.sanitize(SecurityContext.HTML, data.apiPrompt.message.value);
    this.options = data.apiPrompt.options;
  }

  public onCancel(): void {
    this.popupRef.close();
  }

  public onOptionClick(option: ConfirmationOption): void {
    this.popupRef.close(option);
  }
}
