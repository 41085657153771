import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';
import { DistrictDetailsRouteData } from './district-details';

export interface EditDistrictRouteData {
  districtId: string;
}

export const editDistrictRoute: PortalRoute<EditDistrictRouteData> = {
  path: ':districtId/edit',
  isOnRoute: (state: RouterStateUrl): boolean => {
    throw new Error('Not implemented');
  },
  parse: (state: RouterStateUrl): EditDistrictRouteData => {
    let data: EditDistrictRouteData = {} as EditDistrictRouteData;
    if (state.drawer && state.drawer.params) {
      data.districtId = state.drawer.params.districtId;
    }
    if (state.queryParams) {
      data = {
        ...data,
        ...state.queryParams,
      };
    }
    return data;
  },
  request: (
    data: EditDistrictRouteData = {} as EditDistrictRouteData,
    queryParamStrategy?: QueryParamsHandlingStrategy,
  ): NavigationRequest => {
    const req: NavigationRequest = {
      path: ['_', 'drawer', 'districts', data.districtId, 'edit'],
    };
    delete data.districtId;
    const query = { ...data };
    if (Object.keys(query).length) {
      req.query = query;
    }
    if (queryParamStrategy) {
      req.extras = {
        queryParamsHandling: queryParamStrategy,
      };
    }
    return req;
  },
};

@Injectable({ providedIn: 'root' })
export class EditDistrictRouterService extends PortalRouterService<EditDistrictRouteData> {
  outlet = RouterOutlets.DRAWER;
  route: PortalRoute<DistrictDetailsRouteData> = editDistrictRoute;
}
