import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const moduleName = 'Vendors';
const submoduleName = 'List';

/**
 * If snapshot is passed
 * will initialize with snapshot values
 */
export const listInitialized = createAction(
  `[${moduleName}] [${submoduleName}]: List initialized`,
  props<{ listName: fromTypes.VendorListName; snapshot?: fromTypes.VendorListSnapshot }>(),
);

export const searchChanged = createAction(
  `[${moduleName}] [${submoduleName}]: Search changed`,
  props<{ searchTerm: string; listName: fromTypes.VendorListName }>(),
);

export const pageChanged = createAction(
  `[${moduleName}] [${submoduleName}]: Page changed`,
  props<{ page: number; listName: fromTypes.VendorListName }>(),
);

export const pageSizeChanged = createAction(
  `[${moduleName}] [${submoduleName}]: Page size changed`,
  props<{ pageSize: number; listName: fromTypes.VendorListName }>(),
);

export const vendorLoadListRequested = createAction(
  `[${moduleName}] [${submoduleName}] [Data]: Load list requested`,
  props<{ snapshot: fromTypes.VendorListSnapshot; listName: fromTypes.VendorListName }>(),
);

export const vendorLoadListSuccess = createAction(
  `[${moduleName}] [${submoduleName}] [Data]: Load list success`,
  props<{ listName: fromTypes.VendorListName; totalFound: number; items: fromTypes.PortalEntity[] }>(),
);

export const vendorLoadListFailed = createAction(
  `[${moduleName}] [${submoduleName}] [Data]: Load list failed`,
  props<{ listName: fromTypes.VendorListName; error: fromTypes.WpError }>(),
);

/**
 * Vendor list item
 */
export const vendorListItemInitialized = createAction(
  `[${moduleName}] [${submoduleName}]: List item initialized`,
  props<{ vendorId: string }>(),
);

export const vendorListItemDestroyed = createAction(
  `[${moduleName}] [${submoduleName}]: List item destroyed`,
  props<{ vendorId: string }>(),
);

export const vendorItemSelected = createAction(
  `[${moduleName}] [${submoduleName}]: Vendor item selected`,
  props<{ vendorId: string; listName: fromTypes.VendorListName }>(),
);
