import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';

import { AuthState, logoutPageLogoutRequested } from '../../store';

@Component({
  selector: 'wp-logout',
  templateUrl: './logout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements OnInit {
  constructor(private store: Store<AuthState>) {}

  public ngOnInit(): void {
    this.store.dispatch(logoutPageLogoutRequested());
  }
}
