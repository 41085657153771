export enum EmployeeListName {
  MAIN_EMPLOYEE_LIST = 'MAIN_EMPLOYEE_LIST',
  VENDOR_DETAILS_EMPLOYEE_LIST = 'VENDOR_DETAILS_EMPLOYEE_LIST',
  YARD_DETAILS_EMPLOYEE_LIST = 'YARD_DETAILS_EMPLOYEE_LIST',
}

export const employeeLists: EmployeeListName[] = [
  EmployeeListName.MAIN_EMPLOYEE_LIST,
  EmployeeListName.VENDOR_DETAILS_EMPLOYEE_LIST,
  EmployeeListName.YARD_DETAILS_EMPLOYEE_LIST,
];
