import { CampusListStateSnapshot } from '../campus-list-state-snapshot';
import * as fromApi from '../../../../api/endpoints/entity-filter';
import { PortalEntityType } from '@rootTypes';
import { CampusListFilterType } from '../campus-list-entity-filter';
import { getEntityFilterRequest } from '@rootTypes/utils/portal-entity/get-entity-filter-request';
import { EntitySearchResponseType } from '@apiEntities/entity-search';

export const campusListStateSnapshotToRequest = ({
  searchQuery,
  currentPage,
  pageSize,
  filters,
  listStatus,
}: CampusListStateSnapshot): fromApi.EntityFilterRequest => {
  const baseRequest = getEntityFilterRequest(
    searchQuery,
    EntitySearchResponseType.CAMPUS,
    listStatus,
    currentPage * pageSize,
    pageSize,
  );
  if (filters && filters.length) {
    const districtIds = filters
      .filter((f) => f.type === CampusListFilterType.ENTITY && f.payload.type === PortalEntityType.DISTRICT)
      .map((f) => f.payload.entityId);
    baseRequest.districtIds = districtIds;
  }
  return baseRequest;
};
