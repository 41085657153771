import { createSelector } from '@ngrx/store';
import { getFeatureState } from './feature-selector';
import * as fromTypes from '../../types';
import * as fromReducer from '../reducers/student-lists.reducer';

const getLists = createSelector(getFeatureState, (state) => state.lists);

export const getStudentListState = createSelector<any, any, any, fromReducer.StudentListState>(
  getLists,
  (state, props: { listName: fromTypes.StudentListName }) => state[props.listName],
);

export const getSearchQuery = createSelector(getStudentListState, (state) => state.currentSearchQuery);

export const getSelectedStudentStatus = createSelector(getStudentListState, (state) => state.selectedStudentStatus);

export const getPageState = createSelector(getStudentListState, (state) => state.page);

export const getListStudentIds = createSelector(getPageState, (state) => state.listStudentIds);

export const getListLoading = createSelector(getPageState, (state) => state.isLoading);

export const getListTotalFound = createSelector(getPageState, (state) => state.totalFound);

export const getListError = createSelector(getPageState, (state) => state.listError);

export const getPaginationState = createSelector(getStudentListState, (state) => state.pagination);

export const getCurrentPage = createSelector(getPaginationState, (state) => state.currentPage);

export const getPageSize = createSelector(getPaginationState, (state) => state.pageSize);
