import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'wp-portal-basic-editor-popup',
  templateUrl: './portal-basic-editor-popup.component.html',
  styleUrls: ['./portal-basic-editor-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalBasicEditorPopupComponent implements OnInit {
  @Input() public confirmButtonLabel = 'OK';
  @Input() public confirmButtonColor: 'green' | 'red' = 'green';
  @Input() public cancelButtonLabel = 'Cancel';
  @Input() public isCancelButton = true;
  @Input() public isConfirmLoading: boolean;
  @Input() public isConfirmButtonDisabled: boolean;
  @Input() public isSeparatorLine = true;

  @Output() public closeClicked = new EventEmitter<void>();
  @Output() public confirmButtonClicked = new EventEmitter<void>();
  @Output() public cancelButtonClicked = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
