import { TripListModificationFilter } from '../types/trip-list';
import { FilterDeserializer } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';

export const getTripListModificationFilter = (): TripListModificationFilter => {
  return {
    id: `modified`,
    type: 'modified',
    payload: true,
  };
};

export const deserializeTripListModificationFilter: FilterDeserializer<TripListModificationFilter> = (
  s: string,
): TripListModificationFilter | undefined => {
  if (s && s.startsWith('modified')) {
    return getTripListModificationFilter();
  }
  return undefined;
};
