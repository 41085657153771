<svg
  class="icon"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <g fill="none" fill-rule="evenodd" stroke="#959597">
    <path stroke-width="2" d="M2 3H20V16H2z" transform="translate(1 3)" />
    <path
      d="M9.5 8.5H12.5V15.5H9.5zM4.5 8.5H7.5V11.5H4.5zM14.5 8.5H17.5V11.5H14.5z"
      transform="translate(1 3)"
    />
    <path
      stroke-width="2"
      d="M4 0H18V3H4zM0 16H22V19H0z"
      transform="translate(1 3)"
    />
  </g>
</svg>
