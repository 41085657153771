import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-empty-folder-image',
  templateUrl: './empty-folder-image.component.html',
  styleUrls: ['./empty-folder-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyFolderImageComponent implements OnInit {
  @Input() public height = 128;
  @Input() public alignAtCenter: boolean;
  @Input() public marginTopPx = 30;

  public marginTopStr: string;
  constructor() {}

  ngOnInit(): void {
    this.marginTopStr = this.alignAtCenter ? '0px' : `${this.marginTopPx}px`;
  }
}
