import { EntityState } from '@rootTypes';
import { createReducer, on } from '@ngrx/store';
import { getCharterConfigFailed, getCharterConfigRequested, getCharterConfigSuccess } from '../actions';
import { GetCharterConfigResponse } from '../../../api/endpoints/get-charter-config';
import { cleanUpStore } from '@rootStore';

export type TripsConfigState = EntityState<GetCharterConfigResponse>;

export const getInitialTripsConfigState = (): TripsConfigState => ({
  entity: undefined,
  error: undefined,
  isLoading: false,
});

export const tripsConfigReducer = createReducer<TripsConfigState>(
  getInitialTripsConfigState(),
  on(cleanUpStore, getInitialTripsConfigState),
  on(getCharterConfigRequested, (state, action): TripsConfigState => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(getCharterConfigSuccess, (state, action): TripsConfigState => {
    return {
      ...state,
      isLoading: false,
      entity: action.data,
    };
  }),
  on(getCharterConfigFailed, (state, action): TripsConfigState => {
    return {
      ...state,
      isLoading: false,
      error: action.error,
    };
  }),
);
