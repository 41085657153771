import { Pipe, PipeTransform } from '@angular/core';
import { millisToDisplayDuration } from '@rootTypes/utils';

@Pipe({
  name: 'minutesDisplayDuration',
})
export class MinutesDisplayDurationPipe implements PipeTransform {
  transform(value: number, format: 'long' | 'short' = 'long', defaultValue = ''): string {
    if (value === undefined || value === null) {
      return defaultValue;
    }
    const isShort = format === 'short';
    return millisToDisplayDuration(value * 1000 * 60, isShort);
  }
}
