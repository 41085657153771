import { isSameDay } from 'date-fns';
/**
 * Returns true if the first date is after the second date.
 * @param date
 * @param dateToCompare
 */
export const dateIsAfter = (date: Date, dateToCompare: Date, granularity?: 'day'): boolean => {
  if (!granularity) {
    return date.getTime() > dateToCompare.getTime();
  }
  switch (granularity) {
    case 'day': {
      return !isSameDay(date, dateToCompare) && date.getTime() > dateToCompare.getTime();
    }
  }
};
