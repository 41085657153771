import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const moduleKey = 'Vehicles';
const submoduleKey = 'List';

export const vehicleListInitialized = createAction(
  `[${moduleKey}] [${submoduleKey}]: Initialized`,
  props<{
    listName: fromTypes.VehicleListName;
    snapshot?: fromTypes.VehicleListSnapshot;
    staticFilters: fromTypes.VehicleListFilter[];
    selectedMultiAccountManagementEntity: fromTypes.VehicleListMultiAccountFilter;
  }>(),
);

export const searchChanged = createAction(
  `[${moduleKey}] [${submoduleKey}]: Search changed`,
  props<{ listName: fromTypes.VehicleListName; search: string }>(),
);

export const statusChanged = createAction(
  `[${moduleKey}] [${submoduleKey}]: Status changed`,
  props<{ listName: fromTypes.VehicleListName; status: fromTypes.EntityStatus }>(),
);

export const filtersChanged = createAction(
  `[${moduleKey}] [${submoduleKey}]: Filters changed`,
  props<{ listName: fromTypes.VehicleListName; filters: fromTypes.VehicleListFilter[] }>(),
);

export const pageChanged = createAction(
  `[${moduleKey}] [${submoduleKey}]: Page changed`,
  props<{ listName: fromTypes.VehicleListName; page: number }>(),
);

export const pageSizeChanged = createAction(
  `[${moduleKey}] [${submoduleKey}]: Page size changed`,
  props<{ listName: fromTypes.VehicleListName; pageSize: number }>(),
);

export const selectedVehicleChanged = createAction(
  `[${moduleKey}] [${submoduleKey}]: Selected vehicle changed`,
  props<{ listName: fromTypes.VehicleListName; VehicleId: string }>(),
);

/**
 * Vehicle list item
 */
export const listItemInitialized = createAction(
  `[${moduleKey}] [${submoduleKey}]: List item initialized`,
  props<{ listName: fromTypes.VehicleListName; vehicleId: string }>(),
);

export const listItemDestroyed = createAction(
  `[${moduleKey}] [${submoduleKey}]: List item destroyed`,
  props<{ listName: fromTypes.VehicleListName; vehicleId: string }>(),
);

/**
 * Load Vehicle list requested
 */
export const loadVehicleListRequested = createAction(
  `[${moduleKey}] [${submoduleKey}] [Data]: Load vehicle list requested`,
  props<{ listName: fromTypes.VehicleListName; snapshot: fromTypes.VehicleListSnapshot }>(),
);

export const loadVehicleListSuccess = createAction(
  `[${moduleKey}] [${submoduleKey}] [Data]: Load vehicle list success`,
  props<{ listName: fromTypes.VehicleListName; total: number; items: fromTypes.PortalEntity[] }>(),
);

export const loadVehicleListFailed = createAction(
  `[${moduleKey}] [${submoduleKey}] [Data]: Load vehicle list failed`,
  props<{ listName: fromTypes.VehicleListName; error: fromTypes.WpError }>(),
);

const vehiclesSectionEffects = '[VehiclesRouterEffects]';
export const vehiclesSectionVehicleDetailsOpened = createAction(
  `${vehiclesSectionEffects}: Vehicle details opened`,
  props<{ vehicleId: string }>(),
);
export const vehiclesSectionVehicleDetailsClosed = createAction(`${vehiclesSectionEffects}: Vehicle details closed`);

const vehiclesSection = '[Vehicles]';
export const initVehiclesSection = createAction(`${vehiclesSection}: Initialize`);
export const destroyVehiclesSection = createAction(`${vehiclesSection}: Destroy`);
