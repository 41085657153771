import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownPaneComponent } from './dropdown-pane/dropdown-pane.component';
import { DropdownOptionComponent } from './dropdown-option/dropdown-option.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { FilterDropdownComponent } from './filter-dropdown/filter-dropdown.component';
import { InlineDropdownComponent } from './inline-dropdown/inline-dropdown.component';
import { DropdownTriggerComponent } from './dropdown-trigger/dropdown-trigger.component';
import { OptionGroupComponent } from './option-group/option-group.component';

const components = [
  DropdownComponent,
  DropdownPaneComponent,
  DropdownOptionComponent,
  FilterDropdownComponent,
  InlineDropdownComponent,
  DropdownTriggerComponent,
  OptionGroupComponent,
];

@NgModule({
  declarations: [...components, ClickOutsideDirective],
  imports: [CommonModule, MatInputModule, ReactiveFormsModule],
  exports: [...components],
})
export class DropdownModule {}
