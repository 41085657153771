import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wp-btn-increment-plus',
  templateUrl: './btn-increment-plus.component.html',
  styleUrls: ['./btn-increment-plus.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnIncrementPlusComponent implements OnInit {
  @Input() public disabled: boolean;
  @Output() public btnClick = new EventEmitter<void>();
  constructor() {}

  ngOnInit(): void {}

  public onClick(): void {
    if (!this.disabled) {
      this.btnClick.emit();
    }
  }
}
