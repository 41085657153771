import { Injectable } from '@angular/core';
import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { accessManagementRootPath } from './access-management-root-path';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';

export type AccessUserListRouteData = {};

const routeSegment = 'users';
export const accessUserListRoute: PortalRoute<AccessUserListRouteData> = {
  path: routeSegment,
  isOnRoute: (state: RouterStateUrl) => {
    return state && state.url && state.url.startsWith(`/${accessManagementRootPath}/${routeSegment}`);
  },
  parse: (state: RouterStateUrl): AccessUserListRouteData => {
    return {};
  },

  request: (
    data: AccessUserListRouteData,
    queryParamsHandling: QueryParamsHandlingStrategy = 'merge',
    replaceUrl = false,
  ): NavigationRequest => {
    return {
      path: [`/${accessManagementRootPath}`, routeSegment],
      extras: { queryParamsHandling, replaceUrl },
    };
  },
  getHref(data?: AccessUserListRouteData): string {
    return `/${accessManagementRootPath}/${routeSegment}`;
  },
};

@Injectable({ providedIn: 'root' })
export class AccessUserListRouterService extends PortalRouterService<AccessUserListRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route: PortalRoute<AccessUserListRouteData> = accessUserListRoute;
}
