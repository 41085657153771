import { DriverVehicleFacade } from './driver-vehicle.facade';
export { DriverVehicleFacade };

import { DriverVendorFacade } from './driver-vendor.facade';
export { DriverVendorFacade };

import { DriverYardFacade } from './driver-yard.facade';
export { DriverYardFacade };

export const driverAggregationFacades = [DriverVehicleFacade, DriverVendorFacade, DriverYardFacade];
