import { SmartFormField } from '@apiEntities/smart-forms';
import { isSmartFormFieldAddress, isSmartFormFieldAddressValid } from './smart-form-field-address';
import { isAtLeastOneCheckboxInGroupValid, isSmartFormFieldCheckboxes } from './smart-form-field-checkboxes';
import { isSmartFormFieldDate, isSmartFormFieldDateValueValid } from './smart-form-field-date';
import { isSmartFormFieldDateRange, isSmartFormFieldDateRangeValid } from './smart-form-field-date-range';
import { isSmartFormFieldEmail, isSmartFormFieldEmailValid } from './smart-form-field-email';
import { isSmartFormFieldPhoneNumber, isSmartFormFieldPhoneNumberValid } from './smart-form-field-phone-number';
import { isSmartFormFieldSelect, isSmartFormFieldSelectValid } from './smart-form-field-select';
import { isSmartFormFieldString, isSmartFormFieldStringValid } from './smart-form-field-string';
import {
  isSmartFormFieldStudentSchoolInfo,
  isSmartFormFieldStudentSchoolInfoValid,
} from './smart-form-field-student-school-info';
import {
  isSmartFormFieldStudentTransportationStatus,
  isSmartFormFieldStudentTransportationStatusValid,
} from './smart-form-field-student-transportation-status';
import {
  isSmartFormFieldTransportationProvider,
  isSmartFormFieldTransportationProviderValid,
} from './smart-form-field-transportation-provider';
import { isSmartFormFieldImage, isSmartFormFieldImageValid } from '@rootTypes/utils/smart-forms/smart-form-field-image';
import {
  isSmartFormFieldAddressWithExactLocation,
  isSmartFormFieldAddressWithExactLocationValid,
} from '@rootTypes/utils/smart-forms/smart-form-field-address-with-exact-location';
import {
  isSmartFormFieldHomeAddress,
  isSmartFormFieldHomeAddressValid,
} from '@rootTypes/utils/smart-forms/smart-form-field-home-address';

export const isSmartFormFieldValid = (field: SmartFormField, required: boolean): boolean => {
  if (isSmartFormFieldAddress(field)) {
    return isSmartFormFieldAddressValid(field, required);
  }
  if (isSmartFormFieldCheckboxes(field)) {
    return isAtLeastOneCheckboxInGroupValid(field);
  }
  if (isSmartFormFieldDate(field)) {
    return isSmartFormFieldDateValueValid(required, field.value);
  }
  if (isSmartFormFieldDateRange(field)) {
    return isSmartFormFieldDateRangeValid(field, required);
  }
  if (isSmartFormFieldEmail(field)) {
    return isSmartFormFieldEmailValid(field, required);
  }
  if (isSmartFormFieldPhoneNumber(field)) {
    return isSmartFormFieldPhoneNumberValid(field, required);
  }
  if (isSmartFormFieldSelect(field)) {
    return isSmartFormFieldSelectValid(field, required);
  }
  if (isSmartFormFieldString(field)) {
    return isSmartFormFieldStringValid(field, required);
  }
  if (isSmartFormFieldStudentSchoolInfo(field)) {
    return isSmartFormFieldStudentSchoolInfoValid(field, required);
  }
  if (isSmartFormFieldStudentTransportationStatus(field)) {
    return isSmartFormFieldStudentTransportationStatusValid(field, required);
  }
  if (isSmartFormFieldTransportationProvider(field)) {
    return isSmartFormFieldTransportationProviderValid(field, required);
  }
  if (isSmartFormFieldImage(field)) {
    return isSmartFormFieldImageValid(field, required);
  }
  if (isSmartFormFieldAddressWithExactLocation(field)) {
    return isSmartFormFieldAddressWithExactLocationValid(field, required);
  }
  if (isSmartFormFieldHomeAddress(field)) {
    return isSmartFormFieldHomeAddressValid(field, required);
  }
  console.warn('Unknown field type: ', field.type);
  return false;
};
