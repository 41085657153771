import { createReducer, on } from '@ngrx/store';

import { enableSegment } from '../actions';

export interface SegmentState {
  isSegmentEnabled: boolean;
}

export const createInitialState = (): SegmentState => {
  return {
    isSegmentEnabled: false,
  };
};

export const segmentFeatureReducer = createReducer(
  createInitialState(),
  on(enableSegment, (state) => {
    return {
      ...state,
      isSegmentEnabled: true,
    };
  }),
);
