/**
 *
 * Handles all undefined cases
 * @param arr1
 * @param arr2
 * @param comparator - should return true if items are equal, by default it's a === b, all undefined cases are handled
 */
export function isArrayDeepDiff<T = any>(
  arr1: T[],
  arr2: T[],
  comparator: (a: T, b: T) => boolean = (a, b) => a === b,
): boolean {
  if (arr1 && !arr2) {
    return true;
  }
  if (!arr1 && arr2) {
    return true;
  }
  if (!arr1 && !arr2) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return true;
  }
  for (let i = 0, len = arr1.length; i < len; i++) {
    const item1 = arr1[i],
      item2 = arr2[i];
    if (!item1 && item2) {
      return true;
    }
    if (item1 && !item2) {
      return true;
    }
    if (!item1 && !item2) {
      continue;
    }
    if (!comparator(item1, item2)) {
      return true;
    }
  }
  return false;
}
