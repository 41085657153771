import { PortalRouterService } from '../../types/portal-router-service';
import { studentDetailsDrawer, StudentDetailsRouteData } from './student-details-drawer';
import { Injectable } from '@angular/core';
import { RouterOutlets } from '../../../types/entities/router';

const route = studentDetailsDrawer;

@Injectable({ providedIn: 'root' })
export class StudentDetailsDrawerRouterService extends PortalRouterService<StudentDetailsRouteData> {
  outlet = RouterOutlets.DRAWER;
  route = route;
}
