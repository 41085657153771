/**
 * @author Roman Pashkovsky
 * @email petrodzher@gmail.com
 * @create date 2019-07-29 11:27:26
 * @modify date 2019-07-29 11:27:26
 * @desc Encapsulates all firebase oprations
 */
import { Injectable } from '@angular/core';

/** Import firebase */
import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  signInWithCustomToken,
  updatePassword,
  User,
  UserCredential,
  signOut,
  getIdToken,
  getIdTokenResult,
  IdTokenResult,
  Unsubscribe,
} from 'firebase/auth';

import { SessionUser } from '@rootTypes/entities/auth';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor() {
    this.initializeApp();
  }

  public async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    const user = getAuth().currentUser;
    const credentials = EmailAuthProvider.credential(user.email, oldPassword);
    await reauthenticateWithCredential(user, credentials);
    return updatePassword(user, newPassword);
  }

  public createUserWithEmailAndPassword(email: string, password: string): Promise<string> {
    return createUserWithEmailAndPassword(getAuth(), email, password).then((user: UserCredential) => {
      return user!.user!.uid;
    });
  }

  public currentUser(): User {
    return getAuth().currentUser;
  }

  public initializeApp(): void {
    const config = {
      apiKey: wpEnvironment.fbApiKey,
      authDomain: wpEnvironment.fbAuthDomain,
      databaseURL: wpEnvironment.fbDatabaseURL,
      projectId: wpEnvironment.fbProjectId,
    };
    initializeApp(config);
  }

  public uid(): string | null {
    return getAuth().currentUser ? getAuth().currentUser.uid : null;
  }

  public signInWithLoginToken(token: string): Promise<SessionUser> {
    return signInWithCustomToken(getAuth(), token).then((user: UserCredential) => {
      return {
        userId: user!.user!.uid,
      };
    });
  }

  public signOut(): Promise<void> {
    return signOut(getAuth());
  }

  public token(isRefresh = false): Promise<string> {
    return getAuth().currentUser ? getIdToken(getAuth().currentUser, isRefresh) : Promise.resolve(null);
  }

  public getIdTokenResult(isRefresh = false): Promise<IdTokenResult> {
    return getIdTokenResult(getAuth()?.currentUser, isRefresh);
  }

  public onAuthStateChanged(observer: (user: User | null) => any, error?: (a: Error) => any): Unsubscribe {
    return getAuth().onAuthStateChanged(observer, error);
  }
}
