export type VehicleInspectionAssignedMechanicFilter =
  | VehicleInspectionAssignedMechanicStatusFilter
  | VehicleInspectionAssignedMechanicId;

export enum VehicleInspectionAssignedMechanicStatusFilter {
  UNASSIGNED = '',
  ASSIGNED = 'Assigned',
}

type VehicleInspectionAssignedMechanicId = string;
