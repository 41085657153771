import { Pipe, PipeTransform } from '@angular/core';
import { bytesToKilobytes } from '@rootTypes/utils/common/bytes-to-kilobytes';
import { bytesToMegabytes } from '@rootTypes/utils/common/bytes-to-megabytes';

@Pipe({
  name: 'fileSizeDisplay',
})
export class FileSizeDisplayPipe implements PipeTransform {
  transform(value: number): string {
    if (!value) {
      return '0 KB';
    }
    const numMB = bytesToMegabytes(value);
    if (numMB < 1) {
      return this.bytesToKBDisplay(value);
    } else {
      return this.bytesToMBDisplay(value);
    }
  }

  private bytesToKBDisplay(bytes: number) {
    return bytesToKilobytes(bytes).toFixed(2) + ' KB';
  }

  private bytesToMBDisplay(bytes: number) {
    return bytesToMegabytes(bytes).toFixed(2) + ' MB';
  }
}
