import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { reportsRootPath } from './reports-root-path';

export type ReportsRouteData = {};

export const reportsRoute: PortalRoute<ReportsRouteData> = {
  path: '',

  isOnRoute: (state: RouterStateUrl) => {
    return state && state.url && state.url.startsWith(`/${reportsRootPath}`);
  },

  parse: (state: RouterStateUrl): ReportsRouteData => {
    return {};
  },

  request: (
    data: ReportsRouteData,
    queryParamsHandling: QueryParamsHandlingStrategy = 'merge',
    replaceUrl = false,
  ): NavigationRequest => {
    return {
      path: [`/${reportsRootPath}`],
      extras: { queryParamsHandling, replaceUrl },
    };
  },
  getHref(data?: ReportsRouteData): string {
    return `/${reportsRootPath}`;
  },
};
