import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';

import { BaseApiService } from './api/base-api.service';
import { GetCharterCatalogRequest, GetCharterCatalogResponse } from '../endpoints/get-charter-catalog';

@Injectable({
  providedIn: 'root',
})
export class CharterCatalogApiService {
  private cache: { [catalogId: string]: Promise<GetCharterCatalogResponse> } = {};

  private prefixEndpoint = `charter/${wpEnvironment.userRole}`;

  constructor(private baseApi: BaseApiService) {}

  public getCharterCatalog(request: GetCharterCatalogRequest): Promise<GetCharterCatalogResponse> {
    if (this.cache[request.catalogId]) {
      return this.cache[request.catalogId];
    }
    this.cache[request.catalogId] = this.baseApi
      .post('getCharterCatalog', { ...request }, this.prefixEndpoint)
      .pipe(take(1))
      .toPromise();
    return this.cache[request.catalogId];
  }
}
