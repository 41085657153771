import { PolylineOptions } from './car-mover/polyline-options';
import { Observable } from 'rxjs';

interface MapConfig {
  traceGapMeters: number;
  tracePolyline: PolylineOptions;
  gapPolyline: PolylineOptions;
}

const initialMapConfig: MapConfig = {
  traceGapMeters: 30,
  tracePolyline: {
    strokeOpacity: 0.9,
    strokeColor: '#7fb2da',
    strokeWeight: 8,
    zIndex: 7999,
  },
  gapPolyline: {
    strokeOpacity: 0,
    icons: [
      {
        icon: {
          path: 'M 0,-1 0,1',
          strokeOpacity: 0.9,
          scale: 4,
          strokeColor: '#add2ee',
          strokeWeight: 8,
        },
        offset: '0',
        repeat: '20px',
      },
    ],
  },
};

/**
 * This is a test class to fine-tune map polyline. It will be removed after fine-tuning
 */
export class MapConfigObserver {
  private static notifierCount = 0;
  private static window = window as any;

  private static setUp(): void {
    if (!MapConfigObserver.window.mapConfig) {
      MapConfigObserver.window.mapNotifiers = {} as {
        [notifierId: string]: Function;
      };
      const callback = () => {
        const notifiers = Object.keys(MapConfigObserver.window.mapNotifiers).map(
          (notifierId) => MapConfigObserver.window.mapNotifiers[notifierId],
        );
        console.log('Map config changed...');
        notifiers.forEach((notifier) => notifier());
      };
      MapConfigObserver.window.mapConfig = proxify(initialMapConfig, callback);
    }
  }

  constructor() {
    MapConfigObserver.setUp();
  }

  public configChanged$(): Observable<MapConfig> {
    const id = `notifier_${MapConfigObserver.notifierCount++}`;
    return new Observable((observer) => {
      const cb = () => observer.next(MapConfigObserver.window.mapConfig);
      MapConfigObserver.window.mapNotifiers[id] = cb;

      return () => delete MapConfigObserver.window.mapNotifiers[id];
    });
  }
}

function proxify(object: any, change: (obj: any, name: string | number | symbol, old: any, val: any) => void) {
  // we use unique field to determine if object is proxy
  // we can't test this otherwise because typeof and
  // instanceof is used on original object
  if (object && object.__proxy__) {
    return object;
  }
  var proxy = new Proxy(object, {
    get: function (object, name) {
      if (name == '__proxy__') {
        return true;
      }
      return object[name];
    },
    set: function (object, name, value) {
      var old = object[name];
      if (value && typeof value == 'object') {
        // new object need to be proxified as well
        value = proxify(value, change);
      }
      object[name] = value;
      change(object, name, old, value);
      return true;
    },
  });
  for (var prop in object) {
    if (object.hasOwnProperty(prop) && object[prop] && typeof object[prop] == 'object') {
      // proxify all child objects
      object[prop] = proxify(object[prop], change);
    }
  }
  return proxy;
}
