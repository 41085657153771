import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, OnDestroy, Output } from '@angular/core';
import { getAddressFromPlace } from '@rootTypes/utils';
import { Address, MapLocation } from '@rootTypes';
import { GeocoderService } from '../../../../core/services';
import { getCoordinatesFromString } from '../utils/get-coordinates-from-string';
import { GOOGLE_AUTOCOMPLETE_DEFAULT_BIAS } from '../utils/config';

@Directive({
  selector: '[wpGoogleAddressAutocompleteInput]',
})
export class GoogleAddressAutocompleteInputDirective implements AfterViewInit, OnDestroy {
  @Output() public addressSelected = new EventEmitter<Address>();

  private mapsEventListener: google.maps.MapsEventListener;
  private bounds = GOOGLE_AUTOCOMPLETE_DEFAULT_BIAS;
  constructor(
    private el: ElementRef,
    private geocoderService: GeocoderService,
  ) {}

  @HostListener('input', ['$event']) private onInput(event: Event) {
    const value: string = (event.target as any).value;
    const coords = getCoordinatesFromString(value);
    if (coords) {
      this.handleCoordinatesInput(coords);
    }
  }

  ngOnDestroy(): void {
    if (this.mapsEventListener) {
      google.maps.event.removeListener(this.mapsEventListener);
    }
  }

  ngAfterViewInit(): void {
    this.initGoogleAutocomplete();
  }

  private handleCoordinatesInput(location: MapLocation): void {
    this.geocoderService.getAddressFromLocation(new google.maps.LatLng(location.lat, location.lng)).then((address) => {
      if (address) {
        this.addressSelected.emit({ ...address });
      }
    });
  }

  private initGoogleAutocomplete(): void {
    try {
      const inputElement = this.el.nativeElement as HTMLInputElement;
      const options = {
        bounds: new google.maps.LatLngBounds(this.bounds.southWest, this.bounds.northEast),
        fields: ['address_components', 'geometry', 'formatted_address', 'name'],
        strictBounds: false,
      } as google.maps.places.AutocompleteOptions;
      const autocomplete = new google.maps.places.Autocomplete(inputElement, options);
      const emitAddress = () => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        const placeWithAddressComponents = place?.address_components ? place : null;
        if (!placeWithAddressComponents) {
          console.error("Can't find address_components on selected address");
        }

        const address = placeWithAddressComponents ? getAddressFromPlace(placeWithAddressComponents) : null;
        this.addressSelected.emit(address);
      };
      this.mapsEventListener = autocomplete.addListener('place_changed', emitAddress.bind(this));
    } catch (err) {
      console.error(err);
    }
  }
}
