import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-icon-work-queue',
  templateUrl: './icon-work-queue.component.html',
  styleUrls: ['./icon-work-queue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconWorkQueueComponent implements OnInit {
  @Input() public green: boolean;

  constructor() {}

  ngOnInit(): void {}
}
