import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { back, getDisplayBackText, State } from '@rootStore';
import { combineLatest, Observable } from 'rxjs';
import * as fromActions from '../actions/permissions-manager.actions';
import * as fromTypes from '../../types';
import * as fromSelectors from '../selectors/permissions-manager.selectors';
import { filter, map, take } from 'rxjs/operators';

@Injectable()
export class PermissionManagerFacade {
  constructor(private store: Store<State>) {}

  public getOpenEmployee(): Observable<fromTypes.VendorEmployee> {
    return this.store.select(fromSelectors.getOpenedEmployee);
  }

  public getPermissions(): Observable<fromTypes.EmployeePermission[]> {
    return this.store.select(fromSelectors.getOpenEmployeePermissions);
  }

  public getPermissionOptions(): Observable<fromTypes.PortalEntity[]> {
    return this.store.select(fromSelectors.getOpenEmployeePermissionOptions);
  }

  public getDistrictEntity(): Observable<fromTypes.PortalEntity> {
    return this.store.select(fromSelectors.getDistrictByOpenEmployee).pipe(
      filter((v) => !!v),
      map((vendor) => {
        return {
          entityId: vendor.id,
          type: fromTypes.PortalEntityType.VENDOR,
          label: vendor.name,
        };
      }),
    );
  }

  public getEditorLoading$(): Observable<boolean> {
    const optionsLoading$ = this.store.select(fromSelectors.getOpenEmployeePermissionOptionsLoading);
    const vendorLoading$ = this.store.select(fromSelectors.getDistrictLoadingByOpenEmployee);
    return combineLatest([optionsLoading$, vendorLoading$]).pipe(
      map(([optionsLoading, vendorLoading]) => optionsLoading || vendorLoading),
    );
  }

  public getEditorLoadError$(): Observable<fromTypes.WpError> {
    const optionsError$ = this.store.select(fromSelectors.getOpenEmployeePermissionOptionsError);
    const vendorError$ = this.store.select(fromSelectors.getDistrictErrorByOpenEmployee);
    return combineLatest([optionsError$, vendorError$]).pipe(
      map(([optionsError, vendorError]) => optionsError || vendorError),
    );
  }

  public getUpdateRequestLoading(): Observable<boolean> {
    return this.store.select(fromSelectors.getUpdatePermissionsLoading);
  }

  public getUpdateRequestLoaded(): Observable<void> {
    return this.store.select(fromSelectors.getUpdatePermissionsLoaded).pipe(
      filter((loaded) => !!loaded),
      take(1),
      map(() => {}),
    );
  }

  public getDisplayBackText(): Observable<string> {
    return this.store.select(getDisplayBackText);
  }

  public onInitPermissionManager(employeeId: string): void {
    this.store.dispatch(fromActions.initPermissionsEditor({ employeeId }));
  }

  public onUpdatePermissions(target: fromTypes.EmployeePermission[]): void {
    this.store.dispatch(fromActions.updateEmployeePermissionsRequested({ target }));
  }

  public onCancel(): void {
    this.getDisplayBackText()
      .pipe(take(1))
      .subscribe((displayBackText) => {
        if (displayBackText) {
          this.store.dispatch(back({}));
        } else {
          this.store.dispatch(back({ count: 2 }));
        }
      });
  }
}
