import { TripListQuickFilter, TripListQuickFilterType } from '../types/trip-list';
import { FilterDeserializer } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';

export const tripListQuickFilterOptions = [
  {
    displayLabel: 'Trips starting today & beyond',
    value: TripListQuickFilterType.STARTING_TODAY,
  },
  {
    displayLabel: 'All trips booked for my campus(es)',
    value: TripListQuickFilterType.MY_CAMPUS,
  },
  {
    displayLabel: 'All trips created by me',
    value: TripListQuickFilterType.MY_TRIPS,
  },
];

export const getDisplayLabelForTripListQuickFilter = (v: TripListQuickFilterType): string => {
  const option = tripListQuickFilterOptions.find((o) => o.value === v);
  if (!option) {
    console.warn(`No display label found for trip list quick filter type ${v}`);
  }
  return option?.displayLabel || '';
};

export const getTripListQuickFilter = (value: TripListQuickFilterType): TripListQuickFilter => {
  return {
    id: `quick-filter,${value}`,
    type: 'quick-filter',
    payload: value,
  };
};

export const deserializeQuickFilter: FilterDeserializer<TripListQuickFilter> = (
  s: string,
): TripListQuickFilter | undefined => {
  if (s && s.startsWith('quick-filter')) {
    const value = s.split(',')[1];
    return getTripListQuickFilter(value as TripListQuickFilterType);
  }
  return undefined;
};
