import { SmartFormFieldAddress, SmartFormFieldType } from '@apiEntities/smart-forms';

export const isSmartFormFieldAddress = (arg: any): arg is SmartFormFieldAddress => {
  return !!arg && typeof arg === 'object' && arg.type === SmartFormFieldType.ADDRESS;
};

export const isSmartFormFieldAddressValid = (address: SmartFormFieldAddress, required: boolean): boolean => {
  if (!required && !address.value) {
    return true;
  }
  return Array.isArray(address.value?.address_components) && !!address.value.address_components.length;
};
