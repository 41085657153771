<div class="wp-sidebar" [ngClass]="{ 'expanded': isExpanded}">
  <div class="wp-sidebar__logo">
    <div [ngClass]="{'expanded': isExpanded}" class="logo-icon-wrap">
      <svg xmlns="http://www.w3.org/2000/svg" width="75" height="32" viewBox="0 0 75 32">
        <g fill="none">
          <path
            fill="#303538"
            d="M47.348 10.3h7.958c.11 0 .198-.088.2-.197V8.806c0-.11-.09-.198-.2-.198h-7.958c-.11 0-.199.089-.199.198v1.298c.002.109.09.196.2.197zm-3.571 10.743h-7.599c-.05 0-.097-.028-.12-.073-.023-.045-.018-.098.013-.138l7.808-10.444c.026-.034.04-.075.04-.118V8.492c0-.11-.09-.198-.199-.198H33.696c-.11 0-.198.089-.198.198v1.65c0 .11.09.198.199.198h7.268c.05-.001.097.027.12.072.023.045.018.099-.013.139L33.31 20.859c-.026.035-.04.076-.04.12v1.936c.001.108.09.197.2.197h10.307c.052 0 .103-.02.14-.057.038-.037.059-.088.059-.14v-1.672c0-.107-.085-.195-.193-.198l-.006-.002zm27.475-9.059c-1.325 0-2.671.456-3.536 1.868-.02.034-.058.053-.098.052-.04-.002-.075-.026-.092-.061-.622-1.266-1.875-1.86-3.17-1.86-1.185 0-2.455.495-3.167 1.54-.018.026-.05.037-.08.028-.03-.01-.05-.036-.052-.067v-.99c0-.108-.089-.196-.199-.197H59.22c-.11 0-.199.089-.199.198v10.442c0 .047.019.092.052.125s.079.052.126.052h1.748c.047 0 .092-.019.125-.052s.052-.078.051-.125v-6.342c0-1.619 1.018-2.743 2.533-2.743 1.627 0 2.306 1.08 2.306 2.473v6.595c0 .109.089.198.198.198h1.723c.11-.001.198-.09.199-.198v-6.347c0-1.551 1.018-2.72 2.51-2.72 1.605 0 2.306 1.057 2.306 2.474v6.593c0 .052.02.103.058.14.037.037.088.058.14.058h1.705c.11 0 .199-.089.199-.198v-6.817c.004-2.834-1.85-4.116-3.748-4.116v-.003zm-15.485.314h-1.721c-.053 0-.104.02-.141.058-.037.037-.058.088-.058.14v6.301c0 1.484-.736 2.641-2.522 2.641-1.785 0-2.524-1.157-2.523-2.64v-6.302c0-.052-.02-.103-.058-.14-.037-.037-.088-.058-.14-.058h-1.727c-.053 0-.104.02-.14.057-.038.037-.06.088-.06.14v6.617c0 2.45 1.37 4.34 4.648 4.34 3.278 0 4.648-1.89 4.648-4.34v-6.617c0-.054-.022-.105-.061-.142-.039-.037-.091-.057-.145-.055z"
          />
          <path
            fill="#009461"
            d="M12.918 8.593l4.987-6.76c1.186-1.67 3.4-2.288 5.3-1.481 1.9.807 2.962 2.818 2.544 4.813-.42 1.996-2.204 3.427-4.273 3.426l-8.558.002z"
          />
          <path
            fill="#DCDC22"
            d="M7.93 1.833l4.988 6.76 4.987-6.76C18.72.684 20.053 0 21.475.002H4.362C5.783 0 7.116.685 7.93 1.832z"
          />
          <path
            fill="#93C90E"
            d="M12.918 8.593l-4.987-6.76C6.744.165 4.53-.453 2.632.355.734 1.162-.328 3.172.091 5.166c.418 1.995 2.202 3.426 4.27 3.425l8.557.002z"
          />
          <path
            fill="#009461"
            d="M12.918 23.202l-4.987 6.76c-1.187 1.668-3.4 2.285-5.299 1.478-1.898-.808-2.96-2.817-2.541-4.812.418-1.995 2.202-3.425 4.27-3.425l8.557-.001z"
          />
          <path
            fill="#DCDC22"
            d="M17.905 29.962l-4.987-6.76-4.987 6.76c-.815 1.148-2.148 1.831-3.57 1.83h17.115c-1.423.002-2.756-.682-3.571-1.83z"
          />
          <path
            fill="#93C90E"
            d="M12.918 23.202l4.987 6.76c1.186 1.67 3.4 2.288 5.3 1.48 1.9-.807 2.962-2.817 2.544-4.813-.42-1.996-2.204-3.427-4.273-3.426l-8.558-.001z"
          />
          <path
            fill="#93C90E"
            d="M.833 24.972c.82-1.113 2.132-1.77 3.528-1.769h8.557l12.086-16.38c-.82 1.112-2.132 1.77-3.528 1.768h-8.558L.833 24.972z"
          />
        </g>
      </svg>
    </div>

    <!-- <div class="logo-icon"  [ngClass]="{ 'visible': !(isExpanded$ | async) }">
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="32" viewBox="0 0 26 32" >
        <g fill="none">
          <path fill="#009461" d="M12.948 8.593l4.998-6.76C19.136.163 21.355-.455 23.26.352c1.904.807 2.97 2.818 2.55 4.813-.42 1.996-2.21 3.427-4.283 3.426l-8.578.002z"/>
          <path fill="#DCDC22" d="M7.95 1.833l4.998 6.76 4.998-6.76C18.763.684 20.1 0 21.526.002H4.37C5.797 0 7.132.685 7.949 1.832z"/>
          <path fill="#93C90E" d="M12.948 8.593L7.95 1.833C6.76.165 4.541-.453 2.64.355.734 1.162-.33 3.172.09 5.166c.42 1.995 2.207 3.426 4.28 3.425l8.577.002z"/>
          <path fill="#009461" d="M12.948 23.202l-4.999 6.76c-1.19 1.668-3.408 2.285-5.31 1.478C.734 30.632-.33 28.623.09 26.628c.42-1.995 2.207-3.425 4.28-3.425l8.577-.001z"/>
          <path fill="#DCDC22" d="M17.946 29.962l-4.998-6.76-4.999 6.76c-.817 1.148-2.152 1.831-3.578 1.83h17.155c-1.426.002-2.763-.682-3.58-1.83z"/>
          <path fill="#93C90E" d="M12.948 23.202l4.998 6.76c1.19 1.67 3.409 2.288 5.313 1.48 1.904-.807 2.97-2.817 2.55-4.813-.42-1.996-2.21-3.427-4.283-3.426l-8.578-.001z"/>
          <path fill="#93C90E" d="M.835 24.972c.822-1.113 2.137-1.77 3.536-1.769h8.577l12.114-16.38c-.822 1.112-2.137 1.77-3.536 1.768h-8.578L.835 24.972z"/>
        </g>
      </svg>
    </div> -->
  </div>
  <div class="wp-sidebar__body">
    <div class="wp-sidebar__top customScroll">
      <ng-content select="wp-side-bar-top"></ng-content>
    </div>
    <div class="wp-sidebar__bottom">
      <ng-content select="wp-side-bar-bottom"></ng-content>
    </div>
    <div class="wp-sidebar__expanded-icon">
      <div class="expand-icon-wrap" (click)="change()">
        <i class="icon" [ngClass]="isExpanded ? 'expand' : 'narrow'"></i>
      </div>
    </div>
  </div>
</div>
