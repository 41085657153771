import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { PlaceholderType } from 'src/app/shared/photos/types';
import { go } from '@rootStore';
import * as fromAuthStore from '../../../auth/store';
import { PopupableService } from 'src/app/core/popupable/popupable.service';
import { SwitchAccountPopupComponent } from './../switch-account-popup/switch-account-popup.component';
import { userProfileRoute } from 'src/app/router/portal-routes/home';
import { currentUserInitials, currentUserPhotoBase64 } from '../../../auth/store';

@Component({
  selector: 'wp-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileMenuComponent implements OnInit {
  @Input() public isTextShown: boolean;
  public placeholderTypes = PlaceholderType;
  public isSwitchAccountPossible$: Observable<boolean>;
  public userName$: Observable<string>;
  public userInitials$: Observable<string>;
  public userPhotoBase64$: Observable<string>;

  constructor(
    private store: Store,
    private popupableService: PopupableService,
  ) {}

  public ngOnInit(): void {
    this.isSwitchAccountPossible$ = this.store.select(fromAuthStore.isSwitchAccountPossible);

    this.userName$ = this.store.select(fromAuthStore.currentUserFullName);
    this.userInitials$ = this.store.select(currentUserInitials);
    this.userPhotoBase64$ = this.store.select(currentUserPhotoBase64);
  }

  public onLogoutClick(): void {
    this.store.dispatch(fromAuthStore.logoutPageRequested());
  }

  public onSwitchAccount(): void {
    this.popupableService.openPopup<any, {}, void>(SwitchAccountPopupComponent, {});
  }

  public onUserProfile(): void {
    this.store.dispatch(go(userProfileRoute.request()));
  }
}
