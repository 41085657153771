import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-icon-trips',
  template: `
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g class="icon" [ngClass]="{ green }" fill-rule="nonzero">
        <path
          d="M9 3.5v2h9c.822 0 1.428.057 2.082.267C21.329 6.167 22 6.967 22 8.5c0 2.04-.77 2.753-2.485 2.944l-.274.025-.247.016c-.258.012-.56.015-.994.015l-12.114-.002-.197-.006c-.926-.028-1.578.007-2.323.196C1.287 12.218 0 13.788 0 16.5s1.287 4.283 3.366 4.812c.662.168 1.25.214 2.024.203L6 21.5h7.06v-2l-7.206.002-.226.007c-.753.023-1.252-.004-1.77-.136C2.648 19.065 2 18.276 2 16.5c0-1.776.647-2.565 1.859-2.873.452-.116.891-.15 1.498-.142L6 13.5h12c.818 0 1.222-.012 1.735-.069C22.393 13.136 24 11.648 24 8.5c0-2.463-1.28-3.986-3.306-4.637C19.79 3.573 19.015 3.5 18 3.5H9z"
        />
        <path
          d="M7 0a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm0 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4zM16 16a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm0 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"
        />
      </g>
    </svg>
  `,
  styles: [
    `
      .icon {
        fill: #959597;
      }
      .icon.green {
        fill: #47a540;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconTripsComponent implements OnInit {
  @Input() public green = false;
  constructor() {}

  ngOnInit(): void {}
}
