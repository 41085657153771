import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wp-button-edit-standard',
  templateUrl: './button-edit-standard.component.html',
  styleUrls: ['./button-edit-standard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonEditStandardComponent implements OnInit {
  @Input() public widthStr = 'auto';
  @Input() public disabled: false;

  @Output() public btnClicked = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
