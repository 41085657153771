import { RouteFilterRequest } from 'src/app/api/endpoints/routes';
import { RouteEntityFilter, RouteFilter, RouteFilterType, SearchHitFilter } from '../entities/filters';
import { getFilterRequestStatus } from 'src/app/api/endpoints/routes/convertors';
import { PortalEntityType } from '@rootTypes';
import { RouteStatusType } from '../../../../types/entities/route';

export const getRouteListRequest = (
  statusType: RouteStatusType,
  currentPage: number,
  pageSize: number,
  filters: RouteFilter[] = [],
): RouteFilterRequest => {
  const limit = pageSize;
  const skip = currentPage * pageSize;

  const campusIds: string[] = getFiltersByEntityType(filters, PortalEntityType.CAMPUS).map(
    (f: RouteEntityFilter) => f.payload.entityId,
  );

  const districtIds: string[] = getFiltersByEntityType(filters, PortalEntityType.DISTRICT).map(
    (f: RouteEntityFilter) => f.payload.entityId,
  );

  const studentIds: string[] = getFiltersByEntityType(filters, PortalEntityType.STUDENT).map(
    (f: RouteEntityFilter) => f.payload.entityId,
  );

  const driverIds: string[] = getFiltersByEntityType(filters, PortalEntityType.DRIVER).map(
    (f: RouteEntityFilter) => f.payload.entityId,
  );

  const parentIds: string[] = getFiltersByEntityType(filters, PortalEntityType.PARENT).map(
    (f: RouteEntityFilter) => f.payload.entityId,
  );

  const yardIds: string[] = getFiltersByEntityType(filters, PortalEntityType.YARD).map(
    (f: RouteEntityFilter) => f.payload.entityId,
  );

  const routeIds: string[] = getFiltersByEntityType(filters, PortalEntityType.ROUTE).map(
    (f: RouteEntityFilter) => f.payload.entityId,
  );

  const rideIds: string[] = getFiltersByEntityType(filters, PortalEntityType.RIDE).map(
    (f: RouteEntityFilter) => f.payload.entityId,
  );

  const routeGroupIds: string[] = getFiltersByEntityType(filters, PortalEntityType.ROUTE_GROUP).map(
    (f: RouteEntityFilter) => f.payload.entityId,
  );

  const searchHitFilters = getSearchHitFilters(filters);
  const searchHitFilterValueMap = searchHitFilters.reduce((prev, curr) => {
    const valuesForField = prev[curr.payload.field] || [];
    valuesForField.push(curr.payload.value);
    return { ...prev, [curr.payload.field]: valuesForField };
  }, {});

  const target: RouteFilterRequest = {
    campusIds,
    districtIds,
    driverIds,
    parentIds,
    studentIds,
    yardIds,
    routeIds,
    rideIds,
    routeGroupIds,
    routeStatuses: [getFilterRequestStatus(statusType)],
    skip,
    limit,
    ...searchHitFilterValueMap,
  };
  return target;
};

function getFiltersByEntityType(filters: RouteFilter[], entityType: PortalEntityType): RouteEntityFilter[] {
  return filters
    .filter((f) => f.type === RouteFilterType.ENTITY)
    .filter((f: RouteEntityFilter) => f.payload.entityType === entityType) as RouteEntityFilter[];
}

function getSearchHitFilters(filters: RouteFilter[]): SearchHitFilter[] {
  return filters.filter((f) => f.type === RouteFilterType.SEARCH_HIT_FILTER) as SearchHitFilter[];
}
