import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wp-page-top-right-block',
  templateUrl: './page-top-right-block.component.html',
  styleUrls: ['./page-top-right-block.component.scss'],
})
export class PageTopRightBlockComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
