import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';

export const switchAccountTransitionRoute: PortalRoute<void> = {
  path: 'account-switching',
  isOnRoute(state: RouterStateUrl): boolean {
    return state.url === `/${this.path}`;
  },
  request(): NavigationRequest {
    return {
      path: [`/${this.path}`],
    };
  },
};
