import { TripStatus } from '@rootTypes';
import { TripListStatusFilter } from '../types/trip-list';
import { FilterDeserializer } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';

export const getTripListStatusFilter = (status: TripStatus): TripListStatusFilter => {
  return {
    id: `status,${status}`,
    type: 'status',
    payload: status,
  };
};

export const deserializeStatusFilter: FilterDeserializer<TripListStatusFilter> = (
  s: string,
): TripListStatusFilter | undefined => {
  if (s && s.startsWith('status')) {
    const value = s.split(',')[1];
    return getTripListStatusFilter(value as TripStatus);
  }
  return undefined;
};
