<div
  class="column-warning"
  [ngStyle]="{width: width, height: height, margin: margin}"
>
  <div class="background"></div>

  <div class="warn-icon-container">
    <img
      class="warn-icon"
      [src]="warnIcon"
      [ngStyle]="{ height: iconHeight }"
    />
  </div>
</div>
