import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ManageEntityOption } from './manage-entity-option';

@Component({
  selector: 'wp-manage-entity-button-with-dropdown',
  template: `
    <wp-dropdown
      *ngIf="options?.length"
      [triggerTemplate]="triggerTemplate"
      [dropdownTemplate]="optionsTemplate"
      [alignDropdown]="dropdownHorizontalAlign"
    >
    </wp-dropdown>

    <ng-template #triggerTemplate>
      <ng-container *ngIf="isSecondary; else primaryTemplate">
        <wp-btn-secondary [height]="'36px'" [width]="buttonWidthStr" [slim]="true">
          {{ buttonLabel }}
        </wp-btn-secondary>
      </ng-container>
      <ng-template #primaryTemplate>
        <wp-btn-primary class="dropdown-trigger" [width]="buttonWidthStr" [height]="'42px'">
          {{ buttonLabel }}
          <wp-btn-suffix>
            <div class="arrow-suffix-wrap">
              <wp-icon-arrow-down [white]="true"></wp-icon-arrow-down>
            </div>
          </wp-btn-suffix>
        </wp-btn-primary>
      </ng-template>
    </ng-template>
    <ng-template #optionsTemplate>
      <wp-dropdown-pane class="dropdown-content">
        <ng-container *ngFor="let option of options">
          <wp-divider-horizontal-line *ngIf="option.separatorBefore"></wp-divider-horizontal-line>
          <wp-dropdown-option
            [disabled]="option.disabled"
            class="option"
            (selectedWithKeyboard)="onOptionClick(option)"
            (click)="onOptionClick(option)"
            >{{ option.label }}</wp-dropdown-option
          >
          <wp-divider-horizontal-line *ngIf="option.separatorAfter"></wp-divider-horizontal-line>
        </ng-container>
      </wp-dropdown-pane>
    </ng-template>
  `,
  styles: [
    `
      .arrow-suffix-wrap {
        margin-left: 10px;
        position: relative;
        top: -4px;
      }

      .option {
        font-size: 14px;
        font-weight: 400;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageEntityButtonWithDropdownComponent implements OnInit {
  @Input() public buttonWidthStr = 'auto';
  @Input() public buttonLabel = 'Manage';
  @Input() public isSecondary = false;
  // use align right, when the button is on the right side of the page, and the dropdown is wider than the button
  @Input() public dropdownHorizontalAlign: 'center' | 'right' | 'left' | 'full-width' = 'full-width';
  @Input() public options: ManageEntityOption[];
  @Output() public optionClicked = new EventEmitter<ManageEntityOption>();
  constructor() {}

  ngOnInit(): void {}

  onOptionClick(option: ManageEntityOption) {
    if (!option.disabled) {
      this.optionClicked.emit(option);
      if ('onclick' in option) option.onclick();
    }
  }
}
