import { Pipe, PipeTransform } from '@angular/core';
import { PdfUtilsService } from '../pdf-utils.service';

@Pipe({
  name: 'pdfFilePageCount',
})
export class PdfFilePageCountPipe implements PipeTransform {
  constructor(private pdfUtilsService: PdfUtilsService) {}

  transform(value: File | null): Promise<number> {
    if (!value) {
      return Promise.resolve(0);
    }
    return this.pdfUtilsService.getPDFPageCount(value);
  }
}
