import { EntityFilterVehicleInspectionsResponse } from '../../../../api/endpoints/entity-filter';
import { VehicleInspectionListResults } from '../entities';

export const inspectionListResponseToResults = (
  response: EntityFilterVehicleInspectionsResponse,
  pageIndex: number,
  pageSize: number,
): VehicleInspectionListResults => {
  return {
    totalFound: response.total,
    items: response.results.map((data, index) => {
      let itemNumber: number;
      if (pageIndex === 0) {
        itemNumber = index + 1;
      } else {
        itemNumber = pageIndex * pageSize + index + 1;
      }
      return {
        data,
        itemNumber,
      };
    }),
  };
};
