import { HasValue } from '../common/has-value';
import { StartTimeExceptionByDateMap } from '../common/start-time-exception-by-date';
import { RouteEvent } from '../common/route-event';
import { RouteCalendar, RouteCalendarLegacy } from '../common/route-calendar';
import { RouteRider } from './route-rider';
import { DriverByWeekdayMap } from './driver-by-weekday';
import { RouteDirection } from './route-direction';
import { Collection, YYYYMMDDString } from '../common';
import { RouteRepeat } from '@apiEntities/routes/route-repeat';
import { RouteProjection } from './route-projection';
import * as fromTypes from '@rootTypes';
import { DistrictProgram } from '@apiEntities/district/district-programs';

export enum RouteStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  INACTIVE = 'INACTIVE',
}

export enum RideType {
  RECURRING = 'RECURRING',
  FIELD_TRIP = 'FIELD_TRIP',
  SPECIAL_ED = 'SPECIAL_ED',
  OTHER = 'OTHER',
}

export interface GetRouteRequest {
  route_id: string;
  _projections?: RouteProjection[];
}

interface GetRouteResponseGeneral {
  zum_enterprise_id: string;
  external_name: string;
  owner_id: string;
  name: string;
  route_id: string;
  group_id?: string;
  ride_type: HasValue<RideType>; // 'OTHER'
  exceptions: StartTimeExceptionByDateMap;
  riders: Collection<RouteRider>;
  route_direction: RouteDirection;
  timezone: string;
  events: Collection<RouteEvent>;
  display_id: string;
  display_name: string;
  repeat?: RouteRepeat;
  start_date?: YYYYMMDDString; // effective date
  end_date?: YYYYMMDDString; // effective date
  group?: { id: string; name: string };
  version: string;
  _projections?: RouteProjectionsResponse;
}

export interface GetRouteResponseActive extends GetRouteResponseGeneral {
  status: HasValue<RouteStatus.ACTIVE | RouteStatus.INACTIVE>;
  route_calendar: RouteCalendarLegacy;
  drivers: DriverByWeekdayMap;
}

export interface GetRouteResponseDraft extends GetRouteResponseGeneral {
  status: HasValue<RouteStatus.DRAFT>;
  group_name?: string;
  route_calendar?: RouteCalendar;
}

export type GetRouteResponse = GetRouteResponseActive | GetRouteResponseDraft;

export type RouteProjectionsResponse = {
  [RouteProjection.polyline]?: PolyLineProjection;
  [RouteProjection.districtProgram]?: DistrictProgram;
};

type PolyLineProjection = {
  polyline?: fromTypes.EncodedPolyline;
  errorMessage?: string;
};
