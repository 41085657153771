<svg
  width="21"
  height="23"
  viewBox="0 0 21 23"
  xmlns="http://www.w3.org/2000/svg"
>
  <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
    <rect
      stroke-width="2"
      x="1"
      y="3"
      width="16"
      height="18"
      rx="2"
      [ngClass]="{ 'active':  isActive, 'default': !isActive}"
    />
    <path
      d="M16.032 18H18c1.857.01 2.001-.164 2-2V2c-.025-1.77-.035-1.995-2-2H6C4.3.02 3.994.055 4 2l.032 1.951"
      [ngClass]="{ 'active':  isActive, 'default': !isActive}"
      stroke-width="2"
    />
    <path
      [ngClass]="{ 'active':  isActive, 'default': !isActive}"
      d="M9 6c-1.6 0-4 .857-4 4.286C5 14.57 9 18 9 18s4-3.429 4-7.714C13 6.857 10.6 6 9 6z"
      stroke-width="2"
    />
    <path
      [ngClass]="{ 'active':  isActive, 'default': !isActive}"
      d="M9 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
    />
  </g>
</svg>
