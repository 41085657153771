import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-alert-success',
  templateUrl: './alert-success.component.html',
  styleUrls: ['./alert-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertSuccessComponent implements OnInit {
  @Input() readonly = false;
  constructor() {}

  ngOnInit(): void {}
}
