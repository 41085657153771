import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromStore from './store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CampusApiService } from './services/campus-api.service';
import { CampusLocationsMapService } from './services/campus-locations-map.service';

@NgModule({
  declarations: [],
  providers: [CampusApiService, CampusLocationsMapService, ...fromStore.facades.facadesArray],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers.campusesReducers),
    EffectsModule.forFeature(fromStore.effects.featureEffectsArray),
  ],
})
export class CampusesDataModule {}
