import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { ChangePasswordFacade } from '../../store';

@Component({
  selector: 'wp-user-profile-change-password',
  templateUrl: './user-profile-change-password.component.html',
  styleUrls: ['./user-profile-change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChangePasswordFacade],
})
export class UserProfileChangePasswordComponent implements OnInit, OnDestroy {
  public currentPassword: UntypedFormControl;
  public newPassword: UntypedFormControl;
  public confirmNewPassword: UntypedFormControl;
  public confirmNewPasswordErrorKey: string;
  public formValid$: Observable<boolean>;
  public isLoading$: Observable<boolean>;
  public errorMessage$: Observable<string>;

  constructor(private facade: ChangePasswordFacade) {}

  public ngOnInit(): void {
    this.facade.init();
    this.currentPassword = this.facade.currentPassword;
    this.newPassword = this.facade.newPassword;
    this.confirmNewPassword = this.facade.confirmNewPassword;
    this.confirmNewPasswordErrorKey = this.facade.confirmNewPasswordControlErrorKey;
    this.formValid$ = this.facade.formValid$;
    this.isLoading$ = this.facade.getIsLoading$();
    this.errorMessage$ = this.facade.getErrorMsg$();
  }

  public ngOnDestroy(): void {
    this.facade.unsubscribe();
  }

  public changePassword(): void {
    this.facade.changePassword();
  }

  public goUserProfile(): void {
    this.facade.goUserProfile();
  }
}
