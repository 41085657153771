export const millisToDisplayDuration = (millis: number, isShort: boolean) => {
  if (millis === 0) {
    return `0 min`;
  }
  const seconds = Math.abs(parseInt('' + millis, 10)) / 1000;
  const durInMins = Math.floor(seconds / 60);
  const hours = Math.floor(durInMins / 60);
  const mins = durInMins % 60;
  if (hours <= 0) {
    return `${durInMins} min`;
  }
  const hourLabel = isShort ? (hours === 1 ? 'h' : 'hrs') : hours === 1 ? 'hour' : 'hours';
  return `${hours} ${hourLabel} ${mins > 0 ? `${mins} min` : ''}`;
};
