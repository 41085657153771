import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';
import { FloatLabelType } from '@angular/material/form-field';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';

@Component({
  selector: 'wp-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements OnInit, OnDestroy {
  @Input() control: UntypedFormControl;

  /**
   * Floating strategy
   * @see https://material.angular.io/components/form-field/overview#floating-label
   */
  @Input() floatLabel?: FloatLabelType = 'auto';

  /**
   * If passed, displays hint under the input,
   * hintFn is ignored
   */
  @Input() hint?: string;

  /**
   * Function to generate the hint dynamically
   * on control value change
   */
  @Input() hintFn?: (controlValue?: string) => string | '';

  /**
   * Floating label.
   * Will be hidden if omitted.
   */
  @Input() label?: string = '';
  @Input() readonly?: boolean = false;

  /**
   * This param should be passed to limit
   * text entering by HTML5
   */
  @Input() maxLength?: number = 500;
  @Input() characterCounter?: boolean = false;

  /**
   * Text to be shown when user focuses the input
   */
  @Input() placeholder?: string;
  @Input() required?: boolean = false;
  @Input() public tabIndex = '0';
  @Input() public customErrorMessageKey?: string;

  // Should be passed when control state (valid, dirty, touched) is updated outside
  @Input() public controlStateChange?: Observable<void>;

  @Output() public inputEvent = new EventEmitter<string>();
  @Output() public focusEvent = new EventEmitter<void>();

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild('textAreaElement') textAreaElement: ElementRef<HTMLTextAreaElement>;
  hintMsg: string;

  private subs: Subscription[] = [];

  constructor(
    private cdRef: ChangeDetectorRef,
    private _ngZone: NgZone,
  ) {}

  public ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  public ngOnInit(): void {
    this._ngZone.onStable
      .asObservable()
      .pipe(take(1)) // added take(1) to prevent scroll issue in page, if textarea content lager than page height
      .subscribe(() => {
        this.triggerResize();
      });
    // set hintMsg
    if (this.hint) {
      this.hintMsg = this.hint;
    } else if (this.hintFn) {
      this.hintMsg = this.hintFn(this.control.value);
      const sub = this.control.valueChanges.subscribe((value) => {
        this.hintMsg = this.hintFn(value);
      });
      this.subs.push(sub);
    }

    if (this.controlStateChange) {
      const controlStateSub = this.controlStateChange.subscribe(() => {
        this.cdRef.detectChanges();
      });
      this.subs.push(controlStateSub);
    }
  }

  onInputChange(event: Event) {
    this.inputEvent.emit(event.target['value']);
    this.triggerResize();
  }

  onTextAreaFocus() {
    this.focusEvent.emit();
  }

  private triggerResize() {
    this.textAreaElement.nativeElement.style.height = 'auto';
    this.textAreaElement.nativeElement.style.height = this.textAreaElement.nativeElement.scrollHeight + 'px';
  }
}
