import {
  Activity,
  ActivityLog,
  ActivityStatus,
  EntityState,
  PortalEntity,
  TimesheetEntryListItem,
  WpError,
} from '@rootTypes';
import { createReducer, on } from '@ngrx/store';
import {
  dayActivityContextLoadFailed,
  dayActivityContextLoadRequested,
  dayActivityContextLoadSuccess,
  daySpecificContextLoadFailed,
  daySpecificContextLoadRequested,
  daySpecificContextLoadSuccess,
  getActivityLogsFailed,
  getActivityLogsRequested,
  getActivityLogsSuccess,
  getActivityTypesFailed,
  getActivityTypesRequested,
  getActivityTypesSuccess,
  getPendingTimesheetsCountByUserSuccess,
  loadActivityActions,
  loadActivityFailed,
  loadActivitySuccess,
  reportedActivityStatusesFromContextActivities,
  reportedActivityStatusesFromTimesheetEntryList,
  reportedRemoveActivity,
} from './actions';
import { ActivityTypeMap } from '@rootTypes/entities/activities/activity-type-map';
import { GetDriverBusReportResponse } from '../../api/endpoints/get-driver-bus-report';

export interface TimesheetEntriesDataState {
  activities: {
    [activityId: string]: EntityState<Activity>;
  };
  activityStatuses: {
    [activityId: string]: ActivityStatus;
  };
  pendingReviewTimesheetEntriesCounts: {
    [userId: string]: {
      count: number;
    };
  };
  activityLogs: {
    [activityId: string]: {
      isLoading: boolean;
      entity?: ActivityLog[];
      error?: WpError;
    };
  };
  activityTypes: {
    isLoading?: boolean;
    entity?: ActivityTypeMap;
    // for quick lookup of subtype labels
    allSubtypes?: {
      [subTypeId: string]: string;
    };
    error?: WpError;
  };
  dayContextActivities: {
    [activityId: string]: {
      isLoading: boolean;
      entity?: TimesheetEntryListItem[];
      error?: WpError;
    };
  };
  /**
   * E.g. rides for driver
   */
  dayContextSpecific: {
    [activityId: string]: {
      isLoading: boolean;
      entity?: PortalEntity[];
      error?: WpError;
    };
  };
  removedActivities: {
    [activityId: string]: boolean;
  };
  driverBusReportsByActivities: {
    [activityId: string]: {
      entity: GetDriverBusReportResponse;
    };
  };
}

export const getInitialTimesheetEntriesDataState = (): TimesheetEntriesDataState => {
  return {
    activities: {},
    activityStatuses: {},
    pendingReviewTimesheetEntriesCounts: {},
    activityLogs: {},
    activityTypes: {},
    dayContextActivities: {},
    dayContextSpecific: {},
    removedActivities: {},
    driverBusReportsByActivities: {},
  };
};

export const timesheetEntryDataReducer = createReducer<TimesheetEntriesDataState>(
  getInitialTimesheetEntriesDataState(),
  on(...loadActivityActions, (state, action) => {
    const { activityId } = action;
    const prevState = state.activities[activityId] || { isLoading: true };
    return {
      ...state,
      activities: {
        ...state.activities,
        [activityId]: {
          ...prevState,
          isLoading: true,
        },
      },
    };
  }),
  on(loadActivitySuccess, (state, action) => {
    const { id: activityId } = action.activity;
    const dbr = action.driverBusReport;
    const prevState = state.activities[activityId] || { isLoading: true };
    const dbrByActivityState = dbr
      ? {
          ...state.driverBusReportsByActivities,
          [activityId]: {
            entity: dbr,
          },
        }
      : state.driverBusReportsByActivities;
    return {
      ...state,
      activities: {
        ...state.activities,
        [activityId]: {
          ...prevState,
          entity: action.activity,
          isLoading: false,
        },
      },
      activityStatuses: {
        ...state.activityStatuses,
        [activityId]: action.activity.status,
      },
      driverBusReportsByActivities: {
        ...dbrByActivityState,
      },
    };
  }),
  on(loadActivityFailed, (state, action) => {
    const { activityId } = action;
    const prevState = state.activities[activityId] || { isLoading: true };
    return {
      ...state,
      activities: {
        ...state.activities,
        [activityId]: {
          ...prevState,
          error: action.error,
          isLoading: false,
        },
      },
    };
  }),
  on(reportedActivityStatusesFromTimesheetEntryList, reportedActivityStatusesFromContextActivities, (state, action) => {
    const { statuses } = action;
    return {
      ...state,
      activityStatuses: {
        ...state.activityStatuses,
        ...statuses,
      },
    };
  }),
  /**
   * Pending review timesheet entries
   */
  on(getPendingTimesheetsCountByUserSuccess, (state, action) => {
    return {
      ...state,
      pendingReviewTimesheetEntriesCounts: {
        ...state.pendingReviewTimesheetEntriesCounts,
        [action.entityId]: {
          count: action.count,
        },
      },
    };
  }),
  /**
   * Activity logs
   */
  on(getActivityLogsRequested, (state, action) => {
    const { activityId } = action;
    const entityState = state.activityLogs[activityId] || { isLoading: true };
    return {
      ...state,
      activityLogs: {
        ...state.activityLogs,
        [activityId]: {
          ...entityState,
          isLoading: true,
        },
      },
    };
  }),
  on(getActivityLogsSuccess, (state, action) => {
    const { activityId, logs } = action;
    const entityState = state.activityLogs[activityId] || { isLoading: true };
    return {
      ...state,
      activityLogs: {
        ...state.activityLogs,
        [activityId]: {
          ...entityState,
          isLoading: false,
          entity: logs,
        },
      },
    };
  }),
  on(getActivityLogsFailed, (state, action) => {
    const { activityId, error } = action;
    const entityState = state.activityLogs[activityId] || { isLoading: true };
    return {
      ...state,
      activityLogs: {
        ...state.activityLogs,
        [activityId]: {
          ...entityState,
          isLoading: false,
          error,
        },
      },
    };
  }),
  /**
   * Get activity types
   */
  on(getActivityTypesRequested, (state, action) => {
    return {
      ...state,
      activityTypes: {
        ...state.activityTypes,
        isLoading: true,
      },
    };
  }),
  on(getActivityTypesSuccess, (state, action) => {
    const { activityTypes } = action;
    const allSubtypes = {};
    const entity: TimesheetEntriesDataState['activityTypes']['entity'] = activityTypes.reduce((prev, curr, index) => {
      const subEntities = curr.subTypes.reduce((pprev, ccurr, iind) => {
        allSubtypes[ccurr.value] = ccurr.displayLabel;
        return {
          ...pprev,
          [ccurr.value]: {
            index: iind,
            subType: ccurr,
          },
        };
      }, {});
      return {
        ...prev,
        [curr.type.value]: {
          index,
          type: curr.type,
          subTypes: subEntities,
        },
      };
    }, {});
    return {
      ...state,
      activityTypes: {
        ...state.activityTypes,
        isLoading: false,
        entity,
        allSubtypes,
      },
    };
  }),
  on(getActivityTypesFailed, (state, action) => {
    return {
      ...state,
      activityTypes: {
        ...state.activityTypes,
        error: action.error,
      },
    };
  }),
  /**
   * Context
   */
  on(dayActivityContextLoadRequested, (state, action) => {
    const { activityId } = action;
    const perItemState = state.dayContextActivities[activityId] || {};
    return {
      ...state,
      dayContextActivities: {
        [activityId]: {
          ...perItemState,
          isLoading: true,
        },
      },
    };
  }),
  on(dayActivityContextLoadSuccess, (state, action) => {
    const { activityId } = action;
    const perItemState = state.dayContextActivities[activityId] || {};
    return {
      ...state,
      dayContextActivities: {
        [activityId]: {
          ...perItemState,
          isLoading: false,
          entity: action.activities,
        },
      },
    };
  }),
  on(dayActivityContextLoadFailed, (state, action) => {
    const { activityId, error } = action;
    const perItemState = state.dayContextActivities[activityId] || {};
    return {
      ...state,
      dayContextActivities: {
        [activityId]: {
          ...perItemState,
          isLoading: false,
          error,
        },
      },
    };
  }),
  on(daySpecificContextLoadRequested, (state, action) => {
    const { activityId } = action;
    const perItemState = state.dayContextSpecific[activityId];
    return {
      ...state,
      dayContextSpecific: {
        [activityId]: {
          ...perItemState,
          isLoading: true,
        },
      },
    };
  }),
  on(daySpecificContextLoadSuccess, (state, action) => {
    const { activityId } = action;
    const perItemState = state.dayContextSpecific[activityId];
    return {
      ...state,
      dayContextSpecific: {
        [activityId]: {
          ...perItemState,
          isLoading: false,
          entity: action.items,
        },
      },
    };
  }),
  on(daySpecificContextLoadFailed, (state, action) => {
    const { activityId } = action;
    const perItemState = state.dayContextSpecific[activityId];
    return {
      ...state,
      dayContextSpecific: {
        [activityId]: {
          ...perItemState,
          isLoading: false,
          error: action.error,
        },
      },
    };
  }),
  on(reportedRemoveActivity, (state, action) => {
    const { activityId } = action;
    return {
      ...state,
      removedActivities: {
        ...state.removedActivities,
        [activityId]: true,
      },
    };
  }),
);
