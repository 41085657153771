import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-icon-yard',
  templateUrl: './icon-yard.component.html',
  styleUrls: ['./icon-yard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconYardComponent implements OnInit {
  @Input() public green: boolean;

  constructor() {}

  ngOnInit(): void {}
}
