import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-btn-icon',
  templateUrl: './btn-icon.component.html',
  styleUrls: ['./btn-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnIconComponent {
  @Input() public marginRight = '0px';
  @Input() public bottom = '0px';

  constructor() {}
}
