import { createReducer, on } from '@ngrx/store';
import * as fromActions from '../../actions/campus-details/students-tab.actions';
import * as fromRootActions from '../../actions/campus-details/campus-details.actions';

export interface StudentsTabState {
  studentId: string;
}

export const initialStudentTabState: StudentsTabState = {
  studentId: undefined,
};

export const studentsTabReducer = createReducer<StudentsTabState>(
  { ...initialStudentTabState },
  on(fromActions.selectStudentId, (state, action) => {
    const { studentId } = action;
    return {
      ...state,
      studentId,
    };
  }),
  on(fromRootActions.cleanCampusDetails, (state, action) => {
    return { ...initialStudentTabState };
  }),
);
