<!--matPrefix and matSuffix are for consistent paddings-->
<ng-container *ngIf="model">
  <!--  Fixed select-->
  <div *ngIf="!model.isEntitySearch">
    <wp-multi-select
      [label]="model.label"
      [control]="model.control"
      [options]="model.fixedOptions"
      [customErrorMessageKey]="model.customErrorMessageKey"
      [notSelectedFromTheListError]="model.SELECT_VALUE_FROM_THE_LIST_ERROR_KEY"
    ></wp-multi-select>
  </div>
  <!--  Entity search-->
  <ng-container *ngIf="model.isEntitySearch">
    <wp-entity-filter
      [id]="id"
      [searchInputControl]="model.displayFormControl"
      [searchByType]="model.entitySearchType"
      [placeholder]="model.label"
      [isSearchGlassIcon]="false"
      [inputCustomErrorMessageKey]="model?.SELECT_VALUE_FROM_THE_LIST_ERROR_KEY"
      [entityStatus]="entityFilterStatus"
      [districtIds]="model?.entitySearchParams?.districtIds || []"
      [campusIds]="model?.entitySearchParams?.campusIds || []"
      [operatorIds]="model?.entitySearchParams?.operatorIds || []"
      [parentIds]="model?.entitySearchParams?.parentIds || []"
      [driverIds]="model?.entitySearchParams?.driverIds || []"
      [vendorIds]="model?.entitySearchParams?.vendorIds || []"
      [yardIds]="model?.entitySearchParams?.yardIds || []"
      [districtProgramIds]="model?.entitySearchParams?.districtProgramIds || []"
      [extraFilters]="model?.entitySearchParams?.extraFilters"
      (inputEvent)="onInputEvent()"
      (inputCleared)="clearClicked()"
      (selected)="onEntityOptionSelect($event)"
    >
      <div *ngIf="prefixTemplate" class="input-prefix">
        <ng-template [ngTemplateOutlet]="prefixTemplate"></ng-template>
      </div>
      <div *ngIf="suffixTemplate" class="input-suffix">
        <ng-template [ngTemplateOutlet]="suffixTemplate"></ng-template>
      </div>
    </wp-entity-filter>
  </ng-container>
</ng-container>
