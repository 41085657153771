import { State } from '../../../../store';
import { Injectable } from '@angular/core';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import {
  driverListInitialize,
  driverListPageSelected,
  driverListPageSizeSelected,
  driverListSearchTermChanged,
  driverListStatusSelected,
  driverListPageItemsSuccess,
  selectedDriverIdChanged,
  driverListStaticFiltersChanged,
  driverListMultiAccountFilterChanged,
} from '../actions/driver-list.actions';
import { Observable } from 'rxjs';
import {
  driverListPageIndex,
  driverListPageItems,
  driverListPageItemsLoading,
  driverListPageSize,
  driverListTotalItemsFound,
  isDriverListEmpty,
  driverListDriverStatus,
  driverListSearchTerm,
  driverListSelectedDriverId,
  driverListParams,
  driverListMultiAccountSelectedEntityId,
} from '../selectors/driver-lists.selectors';
import { Store } from '@ngrx/store';
import { DriverListRouteData } from '../../../../router/portal-routes/drivers';
import * as fromTypes from '../../types';
import { Actions, ofType } from '@ngrx/effects';
import { PortalEntity } from '../../types';

@Injectable()
export class DriverListFacade {
  constructor(
    private store: Store<State>,
    private actions$: Actions,
  ) {}

  public initialize(listName: fromTypes.DriverListName, data?: DriverListRouteData): void {
    this.store.dispatch(driverListInitialize({ listName, data }));
  }

  public getListChanged$(listName: fromTypes.DriverListName): Observable<fromTypes.SerializedDriverListSnapshot> {
    return this.actions$.pipe(
      ofType(driverListPageItemsSuccess),
      filter((a) => a.listName === listName),
      switchMap(() => {
        return this.store.select(driverListParams, { listName }).pipe(
          take(1),
          map((driverParams) => {
            return fromTypes.utils.getSerializedSnapshot(driverParams);
          }),
        );
      }),
    );
  }

  public getMultiAccountManagementEntityId$(listName: fromTypes.DriverListName): Observable<string | null> {
    return this.store.select(driverListMultiAccountSelectedEntityId, { listName });
  }

  public getPageIndex$(listName: fromTypes.DriverListName): Observable<number> {
    return this.store.select(driverListPageIndex, { listName }).pipe(distinctUntilChanged());
  }

  public getStatus(listName: fromTypes.DriverListName): Observable<fromTypes.EntityStatus> {
    return this.store.select(driverListDriverStatus, { listName });
  }

  public getSearchTerm(listName: fromTypes.DriverListName): Observable<string> {
    return this.store.select(driverListSearchTerm, { listName });
  }

  public getSelectedDriverId(listName: fromTypes.DriverListName): Observable<string> {
    return this.store.select(driverListSelectedDriverId, { listName });
  }

  public setPageIndex(listName: fromTypes.DriverListName, pageIndex: number): void {
    this.store.dispatch(driverListPageSelected({ pageIndex, listName }));
  }

  public getPageSize$(listName: fromTypes.DriverListName): Observable<number> {
    return this.store.select(driverListPageSize, { listName });
  }

  public setPageSize(listName: fromTypes.DriverListName, pageSize: number): void {
    this.store.dispatch(driverListPageSizeSelected({ pageSize, listName }));
  }

  public getPageLoading$(listName: fromTypes.DriverListName): Observable<boolean> {
    return this.store.select(driverListPageItemsLoading, { listName });
  }

  public getPageItems$(listName: fromTypes.DriverListName): Observable<fromTypes.PortalEntity[]> {
    return this.store.select(driverListPageItems, { listName });
  }

  public getTotalItemsFound$(listName: fromTypes.DriverListName): Observable<number> {
    return this.store.select(driverListTotalItemsFound, { listName });
  }

  public getIsListEmpty$(listName: fromTypes.DriverListName): Observable<boolean> {
    return this.store.select(isDriverListEmpty, { listName });
  }

  public setStatus(listName: fromTypes.DriverListName, driverStatus: fromTypes.EntityStatus): void {
    this.store.dispatch(driverListStatusSelected({ driverStatus, listName }));
  }

  public setMultiAccountFilter(listName: fromTypes.DriverListName, entity: PortalEntity, operatorIds: string[]): void {
    this.store.dispatch(driverListMultiAccountFilterChanged({ listName, entity, operatorIds }));
  }

  public setSearchTerm(listName: fromTypes.DriverListName, search: string): void {
    this.store.dispatch(driverListSearchTermChanged({ search, listName }));
  }

  public setSelectedDriverId(listName: fromTypes.DriverListName, driverId: string): void {
    this.store.dispatch(selectedDriverIdChanged({ listName, driverId }));
  }

  public setStaticEntityFilters(listName: fromTypes.DriverListName, entities: fromTypes.PortalEntity[] = []): void {
    const filters = entities.map((entity) => {
      return {
        id: fromTypes.utils.encodeDataComponents([fromTypes.DriverListFilterType.ENTITY, entity.type, entity.entityId]),
        type: fromTypes.DriverListFilterType.ENTITY,
        payload: entity,
      };
    });
    this.store.dispatch(driverListStaticFiltersChanged({ listName, filters }));
  }
}
