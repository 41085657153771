import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import { catchError, debounceTime, filter, map, switchMap, take } from 'rxjs';
import * as fromActions from '../actions/ride-list.actions';
import * as fromSelectors from '../selectors/ride-lists.selectors';
import * as fromFlash from '../actions/ride-data-flash.actions';
import { getRideV2Requested } from '../actions/rides-data.actions';
import * as fromTypes from '../../types';
import { Observable, of } from 'rxjs';
import { ApiService } from '../../../../api/api.service';
import { RideV2ProjectionKey } from '@apiEntities/rides/ride-v2-projection';
import { rideListConfigs } from '../../types';
import { selectMultiAccountManagementOptionById } from '../../../../auth/store/selectors/multi-account-management.selectors';

@Injectable()
export class RideListsEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private ridesApiService: ApiService,
  ) {
    fromTypes.rideListNames.forEach((rideListName) => this.createRideListEffect(rideListName));
  }

  public onDateFilterSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.filterByDatesSelected),
      switchMap((action) => {
        const { listName, filter: filterProp } = action;
        return this.getCurrentFilters$(listName).pipe(
          map((filters) => {
            // remove other date filters, and quick filter 'Today's rides by route'
            const filtersNoDate = filters.filter((f) => {
              if (f.type === fromTypes.filters.RideFilterType.DATE) {
                return false;
              }
              if (f.type === fromTypes.filters.RideFilterType.QUICK_FILTER) {
                return (
                  (f as fromTypes.filters.RideQuickFilter).payload !==
                  fromTypes.filters.RideQuickFilterType.TODAY_RIDES_BY_ROUTE
                );
              }
              return true;
            });
            let newFilters: fromTypes.filters.RideFilter[];
            if (filterProp.payload.startDate && filterProp.payload.endDate) {
              newFilters = [filterProp, ...filtersNoDate];
            } else {
              newFilters = [...filtersNoDate];
            }
            return fromActions.filtersChanged({
              listName,
              filters: newFilters,
            });
          }),
        );
      }),
    ),
  );

  public onEntityFilterAddSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.filterByEntitySelected, fromActions.searchHitFilterSelected),
      switchMap((action) => {
        const { listName, filter: filterProp } = action;
        return this.getCurrentFilters$(listName).pipe(
          map((filters) => {
            const otherFilters = filters.filter((f) => f.filterId !== filterProp.filterId);
            const newFilters = [filterProp, ...otherFilters];
            return fromActions.filtersChanged({
              listName,
              filters: newFilters,
            });
          }),
        );
      }),
    ),
  );

  /**
   * On quick filter selected, remove all other filters.
   * If type = none, remove all quick filters
   */
  public onQuickFilterAddSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.quickFilterSelected),
      switchMap((action) => {
        const { listName, filter: filterProp } = action;
        return this.getCurrentFilters$(listName).pipe(
          map((existing) => {
            let newFilters = [];
            if (filterProp.payload === fromTypes.filters.RideQuickFilterType.NONE) {
              newFilters = existing.filter((f) => f.type !== fromTypes.filters.RideFilterType.QUICK_FILTER);
            } else {
              const filtersNoDate = existing.filter((f) => {
                return !(
                  f.type === fromTypes.filters.RideFilterType.DATE ||
                  f.type === fromTypes.filters.RideFilterType.QUICK_FILTER
                );
              });
              newFilters = [filterProp, ...filtersNoDate];
            }
            return fromActions.filtersChanged({
              listName,
              filters: newFilters,
            });
          }),
        );
      }),
    ),
  );

  public onFilterRemoveSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.filterRemoveSelected),
      switchMap((action) => {
        const { listName, filterId } = action;
        return this.getCurrentFilters$(listName).pipe(
          map((filters) => {
            const otherFilters = filters.filter((f) => f.filterId !== filterId);
            const newFilters = [...otherFilters];
            return fromActions.filtersChanged({
              listName,
              filters: newFilters,
            });
          }),
        );
      }),
    ),
  );

  public onRemoveAllFiltersSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeAllFilters),
      map((action) => {
        const { listName } = action;
        return fromActions.filtersChanged({ listName, filters: [] });
      }),
    ),
  );

  /**
   * Request load rides page on the corresponding ride list state change
   */
  public rideListParametersChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.rideListInitialized,
        fromActions.setStateFromSerializedSnapshot,
        fromActions.filtersChanged,
        fromActions.staticFiltersChanged,
        fromActions.timetableChanged,
        fromActions.accountChanged,
        fromActions.pageSizeChanged,
        fromActions.currentPageChanged,
      ),
      switchMap((action) => {
        const { listName } = action;
        return this.store$.select(fromSelectors.getRideListSnapshot(listName)).pipe(
          take(1),
          map((snapshot) => {
            return fromActions.loadRidePageRequested({
              snapshot,
              listName,
            });
          }),
        );
      }),
    ),
  );

  /**
   * Select first ride on load ride success, except for main ride list
   */
  public loadedRides$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRidePageSuccess),
      filter((a) => a.listName !== fromTypes.RideListName.MAIN_RIDE_LIST),
      switchMap((action) => {
        const list = action.page.rideIds;
        return this.store$.select(fromSelectors.selectedRideId(action.listName)).pipe(
          take(1),
          map((currentSelectedId) => {
            let newSelectedId: string | null;
            if (list.indexOf(currentSelectedId) >= 0) {
              newSelectedId = currentSelectedId;
            } else {
              newSelectedId = list[0] || null;
            }
            return fromActions.selectedRideIdChanged({
              listName: action.listName,
              rideId: newSelectedId,
            });
          }),
        );
        // select either existing selected ride, if it's in the list,
        // otherwise first item in the list
      }),
    ),
  );

  /**
   * Subscribe rides to flash updates after ride list loaded
   */
  public ridesLoadedForFlash$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.loadRidePageSuccess),
        map((action) => {
          const { listName, page } = action;
          const projections = rideListConfigs[listName].projections;
          if (listName === fromTypes.RideListName.MAIN_RIDE_LIST) {
            this.store$.dispatch(fromFlash.subscribeRideDetailsPrimary({ rideIds: page.rideIds, projections }));
          } else {
            this.store$.dispatch(fromFlash.subscribeRideDetailsSecondary({ rideIds: page.rideIds, projections }));
          }
        }),
      ),
    { dispatch: false },
  );

  /**
   * Load ride details once for the list
   */
  public ridesLoadedForData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.loadRidePageSuccess),
        map((action) => {
          const { page, listName } = action;
          const projections: RideV2ProjectionKey[] = rideListConfigs[listName].projections;
          page.rideIds.forEach((rideId) => {
            this.store$.dispatch(getRideV2Requested({ request: { rideId, _projections: projections } }));
          });
        }),
      ),
    { dispatch: false },
  );

  /**
   * Unsubscribe rides when list destroyed
   */
  public rideListDestroyedForFlash$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.rideListDestroyed),
        map((action) => {
          const { listName } = action;
          if (listName === fromTypes.RideListName.MAIN_RIDE_LIST) {
            this.store$.dispatch(fromFlash.unsubscribeRideDetailsPrimary());
          } else {
            this.store$.dispatch(fromFlash.unsubscribeRideDetailsSecondary());
            this.store$.dispatch(fromFlash.unsubscribeRouteTraces());
          }
        }),
      ),
    { dispatch: false },
  );

  private getCurrentFilters$(listName: fromTypes.RideListName): Observable<fromTypes.filters.RideFilter[]> {
    return this.store$.select(fromSelectors.getRideListSnapshot(listName)).pipe(
      take(1),
      map((snapshot) => snapshot.dynamicFilters),
    );
  }

  private createRideListEffect(listNameP: fromTypes.RideListName): void {
    this[`load_${listNameP}`] = createEffect(() =>
      this.actions$.pipe(
        ofType(fromActions.loadRidePageRequested),
        filter((a) => a.listName === listNameP),
        debounceTime(800),
        concatLatestFrom((action) =>
          this.store$.select(selectMultiAccountManagementOptionById(action.snapshot.accountId)),
        ),
        switchMap(([action, account]) => {
          const { listName, snapshot } = action;
          const request = fromTypes.utils.getRidesListRequest(snapshot, account);
          return this.ridesApiService.rides(request).pipe(
            map((res) => fromActions.loadRidePageSuccess({ page: res, listName })),
            catchError((err) => {
              console.log(err);
              return of(fromActions.loadRidePageFailed({ error: err, listName }));
            }),
          );
        }),
      ),
    );
  }
}
