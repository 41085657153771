import { SmartFormFieldCheckbox, SmartFormFieldCheckboxes, SmartFormFieldType } from '@apiEntities/smart-forms';
import { SmartCheckboxConfig, SmartCheckboxGroup, SmartCheckboxGroupValue } from 'src/app/shared/smart-forms';

export const isSmartFormFieldCheckboxes = (arg: any): arg is SmartFormFieldCheckboxes => {
  return !!arg && typeof arg === 'object' && arg.type === SmartFormFieldType.CHECKBOXES;
};

export const isAtLeastOneCheckboxInGroupValid = (group: SmartFormFieldCheckboxes): boolean => {
  let isAtLeastOneValid = false;
  for (const checkbox of group.values) {
    if (typeof checkbox.isChecked === 'boolean') {
      isAtLeastOneValid = true;
      break;
    }
  }
  return isAtLeastOneValid;
};

export const smartFormFieldCheckboxesToCheckboxGroup = (
  checkboxes: SmartFormFieldCheckbox[],
  groupDisabled = false,
): SmartCheckboxGroup<string, undefined> => {
  const smartCheckboxes: SmartCheckboxConfig<string, undefined>[] = checkboxes.map((item) => {
    return {
      id: item.name,
      label: item.checkedLabel,
      uncheckedLabel: item.uncheckedLabel,
      checked: item.isChecked,
      checkedValue: item.name,
      disabled: groupDisabled,
    };
  });
  return new SmartCheckboxGroup(smartCheckboxes);
};

export const checkboxGroupValueToSmartFormFieldCheckboxes = (
  value: SmartCheckboxGroupValue<any, any>,
): SmartFormFieldCheckboxes => {
  return {
    type: SmartFormFieldType.CHECKBOXES,
    values: value.all.map((item) => {
      const checkbox: SmartFormFieldCheckbox = {
        name: item.id,
        isChecked: item.checked,
        checkedLabel: item.label,
      };
      if (item.uncheckedValue) {
        checkbox.uncheckedLabel = item.uncheckedValue;
      }
      return checkbox;
    }),
  };
};
