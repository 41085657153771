import { PortalEntitySearchHit, PortalEntityType } from '../entities';
import {
  DateRideFilter,
  filterDataEntrySeparator,
  filterSeparator,
  RideFilter,
  RideFilterType,
  RideQuickFilter,
  RideQuickFilterType,
  UserRideFilter,
} from '../filters';
import { encodeDataComponents } from '@rootTypes/utils';
import { SearchHitRideFilter } from '../filters/search-hit-ride-filter';

export const filterStrToRidesFilters = (filterString: string): RideFilter[] => {
  if (!filterString) {
    return [];
  }
  const filterSegments = filterString.split(filterSeparator);
  return filterSegments.reduce((filters, currStr) => {
    const filterDataSegments = currStr.split(filterDataEntrySeparator);
    const type = filterDataSegments[0] as RideFilterType;
    switch (type) {
      case RideFilterType.DATE:
        return [...filters, dateFilterStrToDatesFilter(currStr)];
      case RideFilterType.USER:
        return [...filters, userFilterStrToUserFilter(currStr)];
      case RideFilterType.QUICK_FILTER:
        return [...filters, quickFilterStrToUserFilter(currStr)];
      case RideFilterType.SEARCH_HIT_FILTER:
        return [...filters, searchHitFilterStrToFilter(currStr)];
      default:
        return [...filters];
    }
  }, [] as RideFilter[]);
};

export const rideFiltersToFilterStr = (filters: RideFilter[]): string | undefined => {
  if (!(filters && filters.length)) {
    return undefined;
  }
  const filterStrArr: string[] = filters.reduce((prev, filter) => {
    switch (filter.type) {
      case RideFilterType.DATE: {
        const target = filter as DateRideFilter;
        return [
          ...prev,
          `${RideFilterType.DATE}${filterDataEntrySeparator}${
            target.payload.startDate || ''
          }${filterDataEntrySeparator}${target.payload.endDate || ''}`,
        ];
      }
      case RideFilterType.USER: {
        const target = filter as UserRideFilter;
        return [
          ...prev,
          `${RideFilterType.USER}${filterDataEntrySeparator}${target.payload.userType}${filterDataEntrySeparator}${target.payload.userId}`,
        ];
      }
      case RideFilterType.QUICK_FILTER: {
        const target = filter as RideQuickFilter;
        return [...prev, `${RideFilterType.QUICK_FILTER}${filterDataEntrySeparator}${target.payload}`];
      }
      case RideFilterType.SEARCH_HIT_FILTER: {
        const target = filter as SearchHitRideFilter;
        const { pillLabel, field, value } = target.payload;
        return [
          ...prev,
          `${RideFilterType.SEARCH_HIT_FILTER}${filterDataEntrySeparator}${pillLabel}${filterDataEntrySeparator}${field}${filterDataEntrySeparator}${value}`,
        ];
      }
    }
  }, [] as string[]);
  const targetStr = filterStrArr.join(filterSeparator);
  return targetStr;
};

export const getDateFilterId = (startDate: string, endDate: string): string => {
  return `${startDate}_${endDate}`;
};

export const getUserFilterId = (userType: PortalEntityType, userId: string): string => {
  return `${userType}_${userId}`;
};

export const getSearchHitFilterId = (hit: PortalEntitySearchHit): string => {
  return encodeDataComponents([RideFilterType.SEARCH_HIT_FILTER, hit.pillLabel, hit.field, hit.label]);
};

export const sortFilters = (source: RideFilter[]): RideFilter[] => {
  const ff = source.slice();
  ff.sort((a, b) => {
    const aCount = a.type === RideFilterType.DATE ? 1 : 0;
    const bCount = b.type === RideFilterType.DATE ? 1 : 0;
    return bCount - aCount;
  });
  return ff;
};

function dateFilterStrToDatesFilter(dateFilterString: string): DateRideFilter {
  const filterSegments = dateFilterString.split(filterDataEntrySeparator);
  const startDate = filterSegments[1];
  const endDate = filterSegments[2];
  return {
    filterId: getDateFilterId(startDate, endDate),
    type: RideFilterType.DATE,
    payload: {
      startDate,
      endDate,
    },
  };
}

function userFilterStrToUserFilter(userFilterString: string): UserRideFilter {
  const filterSegments = userFilterString.split(filterDataEntrySeparator);
  const userType = filterSegments[1] as PortalEntityType;
  const userId = filterSegments[2];
  return {
    filterId: getUserFilterId(userType, userId),
    type: RideFilterType.USER,
    payload: {
      userType,
      userId,
    },
  };
}

function quickFilterStrToUserFilter(userFilterString: string): RideQuickFilter {
  const filterSegments = userFilterString.split(filterDataEntrySeparator);
  const filterType = filterSegments[1] as RideQuickFilterType;
  return {
    filterId: encodeDataComponents([RideFilterType.QUICK_FILTER, filterType]),
    type: RideFilterType.QUICK_FILTER,
    payload: filterType,
  };
}

function searchHitFilterStrToFilter(filterString: string): SearchHitRideFilter {
  const [filterType, pillLabel, field, value] = filterString.split(filterDataEntrySeparator);
  return {
    filterId: encodeDataComponents([RideFilterType.SEARCH_HIT_FILTER, pillLabel, field, value]),
    type: RideFilterType.SEARCH_HIT_FILTER,
    payload: {
      pillLabel,
      field,
      value,
    },
  };
}
