import { Directive, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[wpHover]',
})
export class HoverDirective implements OnChanges {
  @Input() public disableHover = false;
  @Output() public hover = new EventEmitter<boolean>();

  @HostListener('mouseenter') onMouseEnter(eve) {
    if (!this.disableHover) {
      this.hover.emit(true);
    }
  }
  @HostListener('mouseleave') onMouseLeave() {
    if (!this.disableHover) {
      this.hover.emit(false);
    }
  }

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disableHover) {
      this.hover.emit(!this.disableHover);
    }
  }
}
