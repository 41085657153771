import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { NavigationRequest } from '../../types/navigation-request';
import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '../../../types/entities/router';
import { getRoutingRequest } from '@rootTypes/utils/router/get-routing-request';
import { StudentEditorStep } from '@rootTypes/entities/student-editor';

export type EditStudentRouteData = {
  studentId: string;
  step?: StudentEditorStep | '';
  displayBackText?: string;
};

export const editStudentRoute: PortalRoute<EditStudentRouteData> = {
  path: undefined,
  isOnRoute: (state: RouterStateUrl): boolean => {
    throw new Error('Not implemented');
  },
  parse: (state: RouterStateUrl): EditStudentRouteData => {
    let data: EditStudentRouteData = {
      studentId: state.drawer.params.studentId,
      step: getEditorStepFromUrl(state.drawer.url),
    } as EditStudentRouteData;
    if (state.queryParams) {
      data = {
        ...data,
        ...state.queryParams,
      };
    }
    return data;
  },
  request: (
    data: EditStudentRouteData,
    queryParamStrategy?: QueryParamsHandlingStrategy,
    replaceUrl?,
  ): NavigationRequest => {
    const path = data.step
      ? ['_', 'drawer', 'students', 'editor', 'edit', ':studentId', ':step']
      : ['_', 'drawer', 'students', 'editor', 'edit', ':studentId'];
    return getRoutingRequest(path, data, queryParamStrategy, replaceUrl);
  },
};

function getEditorStepFromUrl(url: string): StudentEditorStep {
  if (!url) {
    return null;
  }
  const segments = url.split('/');
  return (segments[5] as StudentEditorStep) || StudentEditorStep.DEFAULT;
}

@Injectable({ providedIn: 'root' })
export class EditStudentRouterService extends PortalRouterService<EditStudentRouteData> {
  outlet = RouterOutlets.DRAWER;
  route: PortalRoute<EditStudentRouteData> = editStudentRoute;
}
