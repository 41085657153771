import { PortalListStoreActions } from '@rootTypes/utils/list-store-utils/portal-list-store-actions';
import { PortalListBaseParams, PortalListBaseState } from '@rootTypes/utils/list-store-utils/portal-list-base-state';
import { on } from '@ngrx/store';
import { OnReducer, ReducerTypes } from '@ngrx/store/src/reducer_creator';
import { PortalListFilter } from '@rootTypes/utils/list-store-utils/portal-list-filter';

export interface ActionOverrides<ListState> {
  [actionType: string]: OnReducer<ListState, any>;
}

export class PortalListReducer<Params extends PortalListBaseParams<Filter>, Filter extends PortalListFilter, Item> {
  constructor(
    private listActions: PortalListStoreActions<Params, Filter, Item>,
    private initialStateGetter: () => PortalListBaseState<Params, Item>,
    private actionOverrides: ActionOverrides<PortalListBaseState<Params, Item>> = {},
  ) {}

  public getProcessors(): ReducerTypes<PortalListBaseState<Params, Item>, any>[] {
    return [
      this.initializedProcessor(),
      this.receivedParamsFromURLProcessor(),
      this.selectedItemIdChangedProcessor(),
      this.pageChangedProcessor(),
      this.pageSizeChangedProcessor(),
      this.searchQueryChangedProcessor(),
      this.filterAddedProcessor(),
      this.setFiltersOfTypeProcessor(),
      this.filterRemovedProcessor(),
      this.clearAllFiltersProcessor(),
      this.loadListRequestedProcessor(),
      this.loadListSuccessProcessor(),
      this.loadListFailedProcessor(),
    ];
  }

  private initializedProcessor(): ReducerTypes<PortalListBaseState<Params, Item>, any> {
    if (this.actionOverrides[this.listActions.initialized.type]) {
      return on(this.listActions.initialized, this.actionOverrides[this.listActions.initialized.type]);
    }
    return on<any, any[]>(
      this.listActions.initialized,
      (state: PortalListBaseState<Params, Item>, action): PortalListBaseState<Params, Item> => {
        return {
          ...this.initialStateGetter(),
          params: {
            ...this.initialStateGetter().params,
            ...action.initParams,
          },
        } as PortalListBaseState<Params, Item>;
      },
    );
  }

  private receivedParamsFromURLProcessor(): ReducerTypes<PortalListBaseState<Params, Item>, any> {
    const processedAction = this.listActions.receivedParamsFromUrl;
    if (this.actionOverrides[processedAction.type]) {
      return on(processedAction, this.actionOverrides[processedAction.type]);
    }
    return on(
      processedAction,
      (state: PortalListBaseState<Params, Item>, action): PortalListBaseState<Params, Item> => {
        const targetParams = { ...state.params };
        const { params: urlParams } = action;
        if (urlParams) {
          for (const p in urlParams) {
            if (urlParams[p] !== undefined) {
              targetParams[p] = urlParams[p];
            }
          }
        }
        return {
          ...state,
          params: {
            ...state.params,
            ...targetParams,
          },
          api: {
            ...state.api,
            items: [],
          },
        };
      },
    );
  }

  private pageChangedProcessor(): ReducerTypes<PortalListBaseState<Params, Item>, any> {
    const processedAction = this.listActions.pageChanged;
    if (this.actionOverrides[processedAction.type]) {
      return on(processedAction, this.actionOverrides[processedAction.type]);
    }
    return on(
      processedAction,
      (state: PortalListBaseState<Params, Item>, action): PortalListBaseState<Params, Item> => {
        return {
          ...state,
          params: {
            ...state.params,
            page: action.page,
          },
          api: {
            ...state.api,
            items: [],
          },
        };
      },
    );
  }

  private pageSizeChangedProcessor(): ReducerTypes<PortalListBaseState<Params, Item>, any> {
    const processedAction = this.listActions.pageSizeChanged;
    if (this.actionOverrides[processedAction.type]) {
      return on(processedAction, this.actionOverrides[processedAction.type]);
    }
    return on(processedAction, (state: PortalListBaseState<Params, Item>, action) => {
      return {
        ...state,
        params: {
          ...state.params,
          pageSize: action.pageSize,
          page: 0,
        },
        listAPI: {
          ...state.api,
          items: [],
        },
      };
    });
  }

  private searchQueryChangedProcessor(): ReducerTypes<PortalListBaseState<Params, Item>, any> {
    const processedAction = this.listActions.searchQueryChanged;
    if (this.actionOverrides[processedAction.type]) {
      return on(processedAction, this.actionOverrides[processedAction.type]);
    }
    return on(processedAction, (state: PortalListBaseState<Params, Item>, action) => {
      return {
        ...state,
        params: {
          ...state.params,
          searchQuery: action.searchQuery,
          page: 0,
        },
        listAPI: {
          ...state.api,
          items: [],
        },
      };
    });
  }

  private filterAddedProcessor(): ReducerTypes<PortalListBaseState<Params, Item>, any> {
    const processedAction = this.listActions.addedFilter;
    if (this.actionOverrides[processedAction.type]) {
      return on(processedAction, this.actionOverrides[processedAction.type]);
    }
    return on(
      processedAction,
      (state: PortalListBaseState<Params, Item>, action): PortalListBaseState<Params, Item> => {
        const { filter: addedFilter } = action;
        const prevList = state.params.filters.filter((e) => e.id !== addedFilter.id);
        return {
          ...state,
          params: {
            ...state.params,
            page: 0,
            filters: [...prevList, addedFilter],
          } as PortalListBaseState<Params, Item>['params'],
          api: {
            ...state.api,
            items: [],
            total: undefined,
          },
        } as PortalListBaseState<Params, Item>;
      },
    );
  }

  private setFiltersOfTypeProcessor(): ReducerTypes<PortalListBaseState<Params, Item>, any> {
    const processedAction = this.listActions.setFiltersOfType;
    if (this.actionOverrides[processedAction.type]) {
      return on(processedAction, this.actionOverrides[processedAction.type]);
    }
    return on(
      processedAction,
      (state: PortalListBaseState<Params, Item>, action): PortalListBaseState<Params, Item> => {
        const { filterType, filters } = action;
        const prevList = state.params.filters.filter((e) => e.type !== filterType);
        return {
          ...state,
          params: {
            ...state.params,
            page: 0,
            filters: [...prevList, ...filters],
          } as PortalListBaseState<Params, Item>['params'],
          api: {
            ...state.api,
            items: [],
            total: undefined,
          },
        } as PortalListBaseState<Params, Item>;
      },
    );
  }

  private filterRemovedProcessor(): ReducerTypes<PortalListBaseState<Params, Item>, any> {
    const processedAction = this.listActions.removedFilter;
    if (this.actionOverrides[processedAction.type]) {
      return on(processedAction, this.actionOverrides[processedAction.type]);
    }
    return on(
      processedAction,
      (state: PortalListBaseState<Params, Item>, action): PortalListBaseState<Params, Item> => {
        const { filterId: removedId } = action;
        const newFilterList = state.params.filters.filter((e) => e.id !== removedId);
        return {
          ...state,
          params: {
            ...state.params,
            page: 0,
            filters: [...newFilterList],
          } as PortalListBaseState<Params, Item>['params'],
          api: {
            ...state.api,
            items: [],
            total: undefined,
          },
        } as PortalListBaseState<Params, Item>;
      },
    );
  }

  private clearAllFiltersProcessor(): ReducerTypes<PortalListBaseState<Params, Item>, any> {
    const processedAction = this.listActions.clearedAllFilters;
    if (this.actionOverrides[processedAction.type]) {
      return on(processedAction, this.actionOverrides[processedAction.type]);
    }
    return on(
      processedAction,
      (state: PortalListBaseState<Params, Item>, action): PortalListBaseState<Params, Item> => {
        return {
          ...state,
          params: {
            ...state.params,
            page: 0,
            filters: state.params.filters.filter((filter) => filter.type === 'date-range'),
          } as PortalListBaseState<Params, Item>['params'],
          api: {
            ...state.api,
            items: [],
            total: undefined,
          },
        } as PortalListBaseState<Params, Item>;
      },
    );
  }

  private selectedItemIdChangedProcessor(): ReducerTypes<PortalListBaseState<Params, Item>, any> {
    const processedAction = this.listActions.selectedItemIdChanged;
    if (this.actionOverrides[processedAction.type]) {
      return on(processedAction, this.actionOverrides[processedAction.type]);
    }
    return on(
      processedAction,
      (state: PortalListBaseState<Params, Item>, action): PortalListBaseState<Params, Item> => {
        return {
          ...state,
          params: {
            ...state.params,
            selectedItemId: action.itemId,
          } as PortalListBaseState<Params, Item>['params'],
        } as PortalListBaseState<Params, Item>;
      },
    );
  }

  private loadListRequestedProcessor(): ReducerTypes<PortalListBaseState<Params, Item>, any> {
    const processedAction = this.listActions.loadRequested;
    if (this.actionOverrides[processedAction.type]) {
      return on(processedAction, this.actionOverrides[processedAction.type]);
    }
    return on(
      processedAction,
      (state: PortalListBaseState<Params, Item>, action): PortalListBaseState<Params, Item> => {
        return {
          ...state,
          api: {
            ...state.api,
            isLoading: true,
            error: undefined,
          },
        } as PortalListBaseState<Params, Item>;
      },
    );
  }

  private loadListSuccessProcessor(): ReducerTypes<PortalListBaseState<Params, Item>, any> {
    const processedAction = this.listActions.loadSuccess;
    if (this.actionOverrides[processedAction.type]) {
      return on(processedAction, this.actionOverrides[processedAction.type]);
    }
    return on(
      processedAction,
      (state: PortalListBaseState<Params, Item>, action): PortalListBaseState<Params, Item> => {
        return {
          ...state,
          api: {
            ...state.api,
            isLoading: false,
            items: action.items,
            total: action.total,
          },
        } as PortalListBaseState<Params, Item>;
      },
    );
  }

  private loadListFailedProcessor(): ReducerTypes<PortalListBaseState<Params, Item>, any> {
    const processedAction = this.listActions.loadFailed;
    if (this.actionOverrides[processedAction.type]) {
      return on(processedAction, this.actionOverrides[processedAction.type]);
    }
    return on(
      processedAction,
      (state: PortalListBaseState<Params, Item>, action): PortalListBaseState<Params, Item> => {
        return {
          ...state,
          api: {
            ...state.api,
            isLoading: false,
            error: action.error,
          },
        } as PortalListBaseState<Params, Item>;
      },
    );
  }
}
