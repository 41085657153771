import { ConnectedPosition } from '@angular/cdk/overlay';
import { InputXPosition, InputYPosition } from './custom-overlay';

export const getPositionsFromInputParameters = (
  xPosition: InputXPosition,
  yPosition: InputYPosition,
): ConnectedPosition[] => {
  const primaryPosition = getPrimaryPositionFromInputParameters(xPosition, yPosition);
  return [primaryPosition, ...getFallBackPositionsForPrimaryPosition(primaryPosition)];
};

export function getPrimaryPositionFromInputParameters(
  xPosition: InputXPosition,
  yPosition: InputYPosition,
): ConnectedPosition {
  return {
    ...connectXPosition(xPosition),
    ...connectYPosition(yPosition),
  } as ConnectedPosition;
}

function connectXPosition(xPosition: InputXPosition): Partial<ConnectedPosition> {
  switch (xPosition) {
    case 'before': {
      return {
        originX: 'start',
        overlayX: 'end',
      };
    }
    case 'align-start': {
      return {
        originX: 'start',
        overlayX: 'start',
      };
    }
    case 'center': {
      return {
        originX: 'center',
        overlayX: 'center',
      };
    }
    case 'align-end': {
      return {
        originX: 'end',
        overlayX: 'end',
      };
    }
    case 'after': {
      return {
        originX: 'end',
        overlayX: 'start',
      };
    }
    default: {
      throw new Error('Unknown tooltip X position: ' + xPosition);
    }
  }
}

function connectYPosition(yPosition: InputYPosition): Partial<ConnectedPosition> {
  switch (yPosition) {
    case 'below': {
      return {
        originY: 'bottom',
        overlayY: 'top',
      };
    }
    case 'center': {
      return {
        originY: 'center',
        overlayY: 'center',
      };
    }
    case 'above': {
      return {
        originY: 'top',
        overlayY: 'bottom',
      };
    }
    default: {
      throw new Error('Unknown tooltip Y position: ' + yPosition);
    }
  }
}

// TODO: Add smart logic for ordering fallback positions
export function getFallBackPositionsForPrimaryPosition(primaryPosition: ConnectedPosition): ConnectedPosition[] {
  return [
    { originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' },
    { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' },
    { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' },
    { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' },
  ];
}
