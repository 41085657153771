import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { SmartDateRange } from '../../models';
import { SmartInputDateRangeService } from '../smart-input-date-range/smart-input-date-range.service';

@Component({
  selector: 'wp-smart-input-date-range-to',
  template: `
    <wp-input-date
      [label]="model?.endDate.label"
      [control]="model?.endDate.control"
      [controlType]="'yyyymmdd'"
      [monthCount]="1"
      [disableDatesBefore]="model?.endDate.min$ | async"
      [disableDatesAfter]="model?.endDate.max$ | async"
      [closeOnSelect]="true"
      [isIcon]="true"
      [tabIndex]="tabIndex"
    ></wp-input-date>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartInputDateRangeToComponent implements OnInit {
  @Input() public tabIndex: string;
  public model: SmartDateRange;
  constructor(private dateRangeInputService: SmartInputDateRangeService) {}

  ngOnInit(): void {
    this.model = this.dateRangeInputService.getModel();
  }
}
