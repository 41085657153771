<wp-icon-vehicles-green *ngIf="green"></wp-icon-vehicles-green>
<wp-icon-vehicles-grey *ngIf="!green"></wp-icon-vehicles-grey>

<!-- <svg
  class="icon"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  *ngIf="!green"
>
  <g
    fill="none"
    fill-rule="evenodd"
    stroke="#959597"
    transform="translate(0 4)"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M13 1L19.429 1 23 5.913 23 14 13 14"
    />
    <rect width="15" height="13" x="1" y="1" stroke-width="2" rx="2" />
    <path fill="#D8D8D8" d="M17.5 7.5H21.5V8.5H17.5z" />
    <path
      fill="#FFF"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M17 16c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM6 16c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z"
    />
  </g>
</svg>

<svg
  width="24px"
  height="24px"
  viewBox="0 0 24 24"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  *ngIf="green"
>
  <g
    id="2-element-/-icon-/-ic_vehicle_brand"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g transform="translate(0.000000, 4.000000)" stroke="#47A540">
      <polyline
        id="Stroke-1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        points="13 1 19.4285714 1 23 5.91308594 23 14 13 14"
      ></polyline>
      <rect
        id="Rectangle"
        stroke-width="2"
        x="1"
        y="1"
        width="15"
        height="13"
        rx="2"
      ></rect>
      <rect
        id="Rectangle"
        fill="#D8D8D8"
        x="17.5"
        y="7.5"
        width="4"
        height="1"
      ></rect>
      <path
        d="M17,16 C18.1045614,16 19,15.1050291 19,13.9993158 C19,12.8949709 18.1045614,12 17,12 C15.8954386,12 15,12.8949709 15,13.9993158 C15,15.1050291 15.8954386,16 17,16 Z"
        id="Stroke-7"
        stroke-width="2"
        fill="#FFFFFF"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M6,16 C7.1045614,16 8,15.1050291 8,13.9993158 C8,12.8949709 7.1045614,12 6,12 C4.8954386,12 4,12.8949709 4,13.9993158 C4,15.1050291 4.8954386,16 6,16 Z"
        id="Stroke-9"
        stroke-width="2"
        fill="#FFFFFF"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </g>
  </g>
</svg> -->
