export interface PhotoInputPopupConfigStyles {
  uploadAreaWidthPx: number;
  uploadAreaHeightPx: number;
  uploadAreaMarginLeftPx: number;
  uploadAreaMarginRightPx: number;
  uploadAreaIconWidthPx: number;
  uploadAreaIconHeightPx: number;
  uploadAreaMarginBottomPx: number;
  previewWidthPx: number;
  previewHeightPx: number;
  previewMarginLeftPx: number;
  previewMarginRightPx: number;
}

export const getPhotoInputPopupSquareImageStyles = (): PhotoInputPopupConfigStyles => {
  return {
    uploadAreaWidthPx: 225,
    uploadAreaHeightPx: 225,
    uploadAreaMarginLeftPx: 34,
    uploadAreaMarginRightPx: 88,
    uploadAreaIconWidthPx: 50,
    uploadAreaIconHeightPx: 59,
    uploadAreaMarginBottomPx: 5,
    previewWidthPx: 132,
    previewHeightPx: 132,
    previewMarginLeftPx: 80,
    previewMarginRightPx: 24,
  };
};

export const getPhotoInputPopupDefaultImageStyles = (): PhotoInputPopupConfigStyles => {
  return {
    uploadAreaWidthPx: 300,
    uploadAreaHeightPx: 225,
    uploadAreaMarginLeftPx: 0,
    uploadAreaMarginRightPx: 46,
    uploadAreaIconWidthPx: 50,
    uploadAreaIconHeightPx: 59,
    uploadAreaMarginBottomPx: 5,
    previewWidthPx: 184,
    previewHeightPx: 138,
    previewMarginLeftPx: 52,
    previewMarginRightPx: 0,
  };
};

export const getPhotoInputPopupDocumentStyles = (): PhotoInputPopupConfigStyles => {
  return {
    uploadAreaWidthPx: 420,
    uploadAreaHeightPx: 284,
    uploadAreaMarginLeftPx: 0,
    uploadAreaMarginRightPx: 60,
    uploadAreaIconWidthPx: 50,
    uploadAreaIconHeightPx: 59,
    uploadAreaMarginBottomPx: 5,
    previewWidthPx: 259,
    previewHeightPx: 175,
    previewMarginLeftPx: 60,
    previewMarginRightPx: 0,
  };
};
