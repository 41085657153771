import { UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { SmartInputModel } from './smart-input-model';

export interface SmartInputConfig<InputValue> {
  label: string;
  value?: InputValue;
  disabled?: boolean;
  required?: boolean;
  noOptionalLabel?: boolean;
  autofocus?: boolean;
}

export abstract class SmartInput<InputValue, OutputValue> implements SmartInputModel {
  public label: string;
  public control: UntypedFormControl;
  public controlStateChange$: Observable<void>;
  public autofocus?: boolean;
  public valueChangesByUser$: Observable<OutputValue>;

  protected valueChangesByUser$$: Subject<OutputValue>;
  // We use Subject instead of BehaviorSubject to trigger change detection
  // inside input component only after it's initialization
  private controlStateChange$$: Subject<void>;
  private initialValue: InputValue;

  protected constructor(config: SmartInputConfig<InputValue>, extraValidators: ValidatorFn[] = []) {
    this.label = config.required || config.noOptionalLabel ? config.label : `${config.label} (optional)`;
    this.autofocus = config.autofocus;
    this.initialValue = config.value;
    const validators = config.required ? [Validators.required, ...extraValidators] : extraValidators;
    this.control = new UntypedFormControl(config.value, validators);
    if (config.disabled) {
      this.control.disable();
    }
    this.controlStateChange$$ = new Subject();
    this.controlStateChange$ = this.controlStateChange$$.asObservable();
    this.valueChangesByUser$$ = new Subject();
    this.valueChangesByUser$ = this.valueChangesByUser$$.asObservable();
  }

  public showErrorIfAny(): void {
    this.control.markAsTouched();
    this.control.updateValueAndValidity();
    this.controlStateChange$$.next();
  }

  public hasChanges(): boolean {
    return this.hasChangesFn(this.control.value, this.initialValue);
  }

  public isValid(): boolean {
    return this.control.valid;
  }

  public abstract getValue(): OutputValue;

  public getValueChanges(): Observable<any> {
    return this.control.valueChanges;
  }

  public emitValueChangeByUser(value?: OutputValue): void {
    this.valueChangesByUser$$.next(value);
  }

  protected abstract hasChangesFn(currVal: InputValue, initVal: InputValue): boolean;
}
