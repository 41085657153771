import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { getRouterNavigationState, go } from '@rootStore';
import { login, newUserPasswordSuccessRoute } from 'src/app/router/portal-routes/auth';

@Component({
  selector: 'wp-reset-password-success',
  templateUrl: './reset-password-success.component.html',
  styleUrls: ['./reset-password-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordSuccessComponent implements OnInit {
  public headerText$: Observable<string>;

  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.headerText$ = this.store.select(getRouterNavigationState).pipe(
      map((state) => {
        if (newUserPasswordSuccessRoute.isOnRoute(state)) {
          return 'Account has been created';
        }
        return 'Your password has been reset';
      }),
      take(1),
    );
  }

  public goLoginPage(): void {
    this.store.dispatch(go(login.request()));
  }
}
