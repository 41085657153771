import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { getPositionsFromInputParameters } from '../get-positions-from-input-parameters';
import { InputXPosition, InputYPosition } from '../custom-overlay';

@Component({
  selector: 'wp-custom-html-tooltip',
  template: `
    <div
      class="custom-html-tooltip"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      #triggerEl
      (mouseenter)="mouseEnter()"
      (mouseleave)="mouseLeave()"
    >
      <ng-content select="[trigger]"></ng-content>
    </div>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="isOpen"
      [cdkConnectedOverlayPositions]="positions"
    >
      <div *ngIf="isOpen" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
        <div class="elevated">
          <ng-template [ngTemplateOutlet]="tooltipContentRef"></ng-template>
        </div>
      </div>
    </ng-template>
  `,
  styles: [
    `
      .elevated {
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomHtmlTooltipComponent implements OnInit {
  @Input() public yPosition: InputYPosition = 'below';
  @Input() public xPosition: InputXPosition = 'center';
  @Input() public disabled: boolean;
  @Output() public openChanged = new EventEmitter<boolean>();
  @ContentChild('tooltipContent', { read: TemplateRef }) public tooltipContentRef: TemplateRef<any>;
  timedOutCloser;
  public isOpen = false;
  public positions: ConnectedPosition[];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.positions = getPositionsFromInputParameters(this.xPosition, this.yPosition);
  }

  mouseEnter() {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    if (this.disabled) {
      return;
    }
    this.isOpen = true;
    this.openChanged.emit(this.isOpen);
  }

  mouseLeave() {
    if (this.disabled) {
      return;
    }
    this.timedOutCloser = setTimeout(() => {
      this.close();
      this.cd.detectChanges();
    }, 50);
  }

  close(): void {
    this.isOpen = false;
    this.openChanged.emit(this.isOpen);
  }
}
