import { createSelector } from '@ngrx/store';
import {
  Check,
  EntityState,
  getVehicleDisplayName,
  getVehicleLicenseName,
  Vehicle,
  VehicleDataState,
  VehicleType,
  VendorVehicleIds,
  yyyymmddToDate,
} from '../../types';
import { getVehicleFeature } from './get-feature';

const getVehicleDataState = createSelector(getVehicleFeature, (state): VehicleDataState => state.data);

export const getAllVehicleEntities = createSelector(getVehicleDataState, (state) => state.vehicles);

export const getVehicleEntity = createSelector(
  getAllVehicleEntities,
  (state, props: { vehicleId: string }): EntityState<Vehicle> | undefined => {
    return state[props.vehicleId];
  },
);

export const getVehicle = createSelector(getVehicleEntity, (state) => state?.entity);

export const getVehicleLoading = createSelector(getVehicleEntity, (state) => (state ? state.isLoading : false));

export const isVehicleInitiallyLoading = createSelector(getVehicleEntity, (state) => !state.entity && !state.error);

export const getVehicleError = createSelector(getVehicleEntity, (state) => state?.error);

export const getVehicleErrorMessage = createSelector(getVehicleEntity, (state) => state?.error?.text);

export const vehicleDisplayName = createSelector(getVehicle, (state) => getVehicleDisplayName(state));

export const vehicleDisplayId = createSelector(getVehicle, (state) => state?.vehicleDisplayId);

export const vehicleStatus = createSelector(getVehicle, (state) => state?.status);

export const vehicleIsInMaintenance = createSelector(getVehicle, (state) => state?.isInMaintenance);

export const getVIN = createSelector(getVehicle, (state) => state?.VIN);

export const vehicleLicenseName = createSelector(getVehicle, (state) =>
  state ? getVehicleLicenseName(state.license) : undefined,
);

export const vehicleColor = createSelector(getVehicle, (state) => state?.color);

export const vehicleRouteAssignmentStatus = createSelector(getVehicle, (state) => state?.routeAssignmentStatus);

export const vehicleFuelType = createSelector(getVehicle, (state) => state?.fuelType);

export const vehicleRangeMiles = createSelector(getVehicle, (state) => state?.rangeMiles);

export const vehicleSeatingCapacity = createSelector(getVehicle, (state) => state?.seatingCapacity);

export const vehicleImagePath = createSelector(getVehicle, (state) => state?.vehicleImagePath);

export const vehicleRegistrationExpDate = createSelector(getVehicle, (state) => {
  if (state && state.registration?.expirationDate) {
    return yyyymmddToDate(state.registration.expirationDate);
  }
  return undefined;
});

export const vehicleInsuranceExpDate = createSelector(getVehicle, (state) => {
  if (state && state.insurance?.expirationDate) {
    return yyyymmddToDate(state.insurance.expirationDate);
  }
  return undefined;
});

export const vehicleBackgroundChecks = createSelector(getVehicle, (vehicle): Check[] => {
  if (vehicle?.checks) {
    return vehicle.checks;
  }
  return [];
});

export const vehicleBackgroundCheckCodes = createSelector(getVehicle, (vehicle): string[] => {
  if (vehicle?.checks?.length) {
    return vehicle.checks.map((check) => check.code);
  }
  return [];
});

export const vehicleBackgroundCheckNames = createSelector(getVehicle, (vehicle): string[] => {
  if (vehicle?.checks?.length) {
    return vehicle.checks.map((check) => check.description);
  }
  return [];
});

export const vehicleYear = createSelector(getVehicle, (vehicle): number => vehicle?.year);

export const vehicleComments = createSelector(getVehicle, (vehicle): string => vehicle?.comments);

// primary driver id by vehicle
const getAllPrimaryDriverIdsState = createSelector(getVehicleDataState, (state) => state.primaryDriversByVehicles);

const getAllEvStatusesState = createSelector(getVehicleDataState, (state) => state.evStatusesByVehicles);

export const getPrimaryDriverIdByVehicle = createSelector(
  getAllPrimaryDriverIdsState,
  (state, props: { vehicleId: string }) => {
    return state[props.vehicleId]?.driverId;
  },
);

export const getEvStatusByVehicle = createSelector(getAllEvStatusesState, (state, props: { vehicleId: string }) => {
  return state[props.vehicleId];
});

export const isVendorVehicle = createSelector(getVehicle, (vehicle) => {
  if (vehicle) {
    return vehicle.type === VehicleType.VENDOR_VEHICLE;
  }
  return undefined;
});

export const getVendorVehicleIds = createSelector(getVehicle, (vehicle): VendorVehicleIds => {
  if (!vehicle || vehicle.type === VehicleType.ZUMER_VEHICLE) {
    return undefined;
  }
  return {
    vendorId: vehicle.vendorId,
    yardId: vehicle.yardId,
  };
});
