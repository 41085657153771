import { createReducer, on } from '@ngrx/store';
import * as fromTypes from '../../types';
import * as fromActions from '../actions/campus-data.actions';

export interface CampusDataState {
  allCampusChecks: fromTypes.EntityState<fromTypes.Check[]>;
  campuses: {
    [campusId: string]: {
      isLoading?: boolean;
      entity?: fromTypes.Campus;
      error?: fromTypes.WpError;
      isRemoving?: boolean;
      removeError?: fromTypes.WpError;
      isRemoved?: boolean;
    };
  };
}

export const createInitialCampusDataState = (): CampusDataState => {
  return {
    allCampusChecks: {
      isLoading: false,
      entity: null,
      error: null,
    },
    campuses: {},
  };
};

export const campusDataReducer = createReducer<CampusDataState>(
  createInitialCampusDataState(),
  on(fromTypes.cleanUpStore, createInitialCampusDataState),
  on(
    fromActions.loadCampusDetailsRequested,
    fromActions.loadCampusDetailsRequestedFromCampusEditor,
    fromActions.loadCampusDetailsRequestedFromCampusCalendarEditor,
    fromActions.loadCampusDetailsRequestedFromCampusScheduleEditor,
    fromActions.loadCampusDetailsRequestedFromStudentDetails,
    (state, action) => {
      const { campusId } = action;
      const prevCampusEntity = state.campuses[campusId] || {
        isLoading: true,
        error: null,
        entity: null,
      };
      return {
        ...state,
        campuses: {
          ...state.campuses,
          [campusId]: {
            ...prevCampusEntity,
            isLoading: !prevCampusEntity.entity,
          },
        },
      };
    },
  ),
  on(fromActions.loadCampusDetailsSuccess, (state, action) => {
    const { campus } = action;
    const campusId = campus.id;
    const prevCampusEntity = state.campuses[campusId] || {
      isLoading: true,
      error: null,
      entity: null,
    };
    return {
      ...state,
      campuses: {
        ...state.campuses,
        [campusId]: {
          ...prevCampusEntity,
          isLoading: false,
          entity: campus,
        },
      },
    };
  }),
  on(fromActions.loadCampusDetailsFailed, (state, action) => {
    const { campusId, error } = action;
    const prevCampusEntity = state.campuses[campusId] || {
      isLoading: true,
      error: null,
      entity: null,
    };
    return {
      ...state,
      campuses: {
        ...state.campuses,
        [campusId]: {
          ...prevCampusEntity,
          isLoading: false,
          error,
        },
      },
    };
  }),
  /**
   * Load all campus checks
   */
  on(fromActions.loadAllChecksForCampusesRequested, (state, action) => {
    return {
      ...state,
      allCampusChecks: {
        ...state.allCampusChecks,
        isLoading: !state.allCampusChecks.entity,
      },
    };
  }),
  on(fromActions.loadAllChecksForCampusesSuccess, (state, action) => {
    const { checks } = action;
    return {
      ...state,
      allCampusChecks: {
        ...state.allCampusChecks,
        isLoading: false,
        entity: checks,
        error: null,
      },
    };
  }),
  on(fromActions.loadAllChecksForCampusesFailed, (state, action) => {
    const { error } = action;
    return {
      ...state,
      allCampusChecks: {
        ...state.allCampusChecks,
        isLoading: false,
        error,
      },
    };
  }),
  /**
   * Remove campus
   */
  on(fromActions.removeCampusRequested, (state, action) => {
    const { campusId } = action;
    const campusEntity = state.campuses[campusId] || { entity: null, isLoading: false };
    return {
      ...state,
      campuses: {
        ...state.campuses,
        [campusId]: {
          ...campusEntity,
          isRemoving: true,
          removeError: undefined,
          isRemoved: false,
        },
      },
    };
  }),
  on(fromActions.removeCampusSuccess, (state, action) => {
    const { campusId } = action;
    const campusEntity = state.campuses[campusId] || {};
    return {
      ...state,
      campuses: {
        ...state.campuses,
        [campusId]: {
          ...campusEntity,
          isRemoving: false,
          removeError: undefined,
          isRemoved: true,
        },
      },
    };
  }),
  on(fromActions.removeCampusFailed, (state, action) => {
    const { campusId, error } = action;
    const campusEntity = state.campuses[campusId] || {};
    return {
      ...state,
      campuses: {
        ...state.campuses,
        [campusId]: {
          ...campusEntity,
          isRemoving: false,
          removeError: error,
          isRemoved: false,
        },
      },
    };
  }),
);
