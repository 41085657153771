import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { SmartMultiSelect } from '../../models/smart-multi-select';
import { EntityStatus, PortalEntity } from '@rootTypes';

@Component({
  selector: 'wp-smart-multi-select',
  templateUrl: './smart-multi-select.component.html',
  styleUrl: './smart-multi-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartMultiSelectComponent implements OnDestroy {
  @Input() public model: SmartMultiSelect;
  @Input() public id: string;
  @Input() public entityFilterStatus = EntityStatus.ANY;
  @Input() public prefixTemplate: any;
  @Input() public suffixTemplate: any;
  @Output() public inputEvent: EventEmitter<void> = new EventEmitter<void>();

  ngOnDestroy(): void {
    if (this.model) {
      this.model.dispose();
    }
  }

  public onInputEvent(): void {
    // this.model.onInputEvent();
  }

  public onEntityOptionSelect(entity: PortalEntity): void {
    this.model.onEntitySelect(entity);
    this.inputEvent.emit();
  }

  public clearClicked(): void {
    this.model.onClearClicked();
    this.inputEvent.emit();
  }
}
