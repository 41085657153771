import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'wp-ellipsis',
  templateUrl: './ellipsis.component.html',
  styleUrl: './ellipsis.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,

  hostDirectives: [
    {
      directive: MatTooltip,
      inputs: ['matTooltip: tooltipText', 'matTooltipPosition: tooltipPosition'],
    },
  ],
})
export class EllipsisComponent implements OnInit, OnDestroy {
  @Input()
  public tooltipText: string;

  @Input()
  public tooltipPosition: TooltipPosition = 'above';

  @Input()
  @HostBinding('style.textAlign')
  public textAlign: string = 'auto';

  @Input()
  @HostBinding('class.link')
  public isLink: boolean = false;

  private readonly resizeObserver = new ResizeObserver(([{ target }]) => {
    this.tooltip.disabled = target.clientWidth === target.scrollWidth;
  });

  constructor(
    private readonly elementRef: ElementRef,
    private readonly tooltip: MatTooltip,
  ) {}

  public ngOnInit(): void {
    this.resizeObserver.observe(this.elementRef.nativeElement);
  }

  public ngOnDestroy(): void {
    this.resizeObserver.disconnect();
  }
}
