import { createAction, props } from '@ngrx/store';
import { PortalEntity, WpError } from '../../../../types';
import { DriverListRouteData } from '../../../../router/portal-routes/drivers';
import * as fromTypes from '../../types';

const moduleName = 'Drivers';
const submoduleName = 'List';

export const driverListInitialize = createAction(
  `[${moduleName}] [${submoduleName}]: Initialize`,
  props<{ listName: fromTypes.DriverListName; data: DriverListRouteData }>(),
);

// selected driver id
export const selectedDriverIdChanged = createAction(
  `[${moduleName}] [${submoduleName}]: Selected driver id`,
  props<{ listName: fromTypes.DriverListName; driverId: string }>(),
);

// Filters
const filtersSource = '[Filters]';
export const driverListStatusSelected = createAction(
  `[${moduleName}] [${submoduleName}] ${filtersSource}: Driver status selected`,
  props<{ listName: fromTypes.DriverListName; driverStatus: fromTypes.EntityStatus }>(),
);
export const driverListSearchTermChanged = createAction(
  `[${moduleName}] [${submoduleName}] ${filtersSource}: Search term changed`,
  props<{ listName: fromTypes.DriverListName; search: string }>(),
);
export const driverListMultiAccountFilterChanged = createAction(
  `[${moduleName}] [${submoduleName}] ${filtersSource}: Multi-account filter changed`,
  props<{ listName: fromTypes.DriverListName; entity: PortalEntity; operatorIds: string[] }>(),
);
export const driverListStaticFiltersChanged = createAction(
  `[${moduleName}] [${submoduleName}] ${filtersSource}: Static filters changed`,
  props<{ listName: fromTypes.DriverListName; filters: fromTypes.DriverListFilter[] }>(),
);

// Pagination
export const driverListPageSelected = createAction(
  `[${moduleName}] [${submoduleName}]: Page selected`,
  props<{ listName: fromTypes.DriverListName; pageIndex: number }>(),
);
export const driverListPageSizeSelected = createAction(
  `[${moduleName}] [${submoduleName}]: Page size selected`,
  props<{ listName: fromTypes.DriverListName; pageSize: number }>(),
);

export const driverListPageItemsRequested = createAction(
  `[${moduleName}] [${submoduleName}]: Page items requested`,
  props<{ listName: fromTypes.DriverListName; request: fromTypes.DriverListSnapshot }>(),
);

export const driverListPageItemsSuccess = createAction(
  `[${moduleName}] [${submoduleName}]: Page items success`,
  props<{ listName: fromTypes.DriverListName; data: { pageItems: fromTypes.PortalEntity[]; totalFound: number } }>(),
);

export const driverListPageItemsFailed = createAction(
  `[${moduleName}] [${submoduleName}]: Page items failed`,
  props<{ listName: fromTypes.DriverListName; error: WpError }>(),
);
