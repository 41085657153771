import * as fromApi from '../get-route';
import * as fromCommonApi from '../../common';
import * as fromEntities from '../../../../types/entities/route';
import { DisplayDateTime, getShortTimezone, UTCTime } from 'src/app/types/utils/common/date';
import { WeekdayDependentValue } from '../../../../types/entities/common';
import * as fromRoot from '@rootTypes';
import { millisToDisplayDuration } from 'src/app/types/utils';
import { HHMMTimeString } from '@apiEntities';
import { getUserTimezone } from '@rootTypes/utils/common/date-time-fns';

const getStopDisplayStartTime = (dbStop: fromCommonApi.RouteEvent): string => {
  if (dbStop && dbStop.start_times) {
    const firstDayId = Object.keys(dbStop.start_times)[0];
    const firstDayStartTime = dbStop.start_times[firstDayId].start_time;
    const df = new DisplayDateTime(firstDayStartTime);
    return df.getDisplayTimeStr('timeNoTimezone');
  }
  return null;
};

const getStopDisplayStartTimeUserTimezone = (dbStop: fromCommonApi.RouteEvent, timezone: string): string => {
  if (dbStop && dbStop.start_times) {
    const firstDayId = Object.keys(dbStop.start_times)[0];
    const firstDayStartTime = dbStop.start_times[firstDayId].start_time;
    const df = new DisplayDateTime(firstDayStartTime, timezone);
    return new UTCTime(df.toUTCTimestamp()).getDisplayTimeStr();
  }
  return null;
};

const getStopStartTimes = (dbStop: fromCommonApi.RouteEvent): WeekdayDependentValue<HHMMTimeString> => {
  const result = {} as WeekdayDependentValue<HHMMTimeString>;
  if (dbStop.start_times) {
    Object.keys(dbStop.start_times)
      .map((dayId) => dbStop.start_times[dayId])
      .forEach((startTimeObj: fromCommonApi.RouteEventTime) => {
        const weekday = fromRoot.utils.date.getWeekdayFromDayNumber(startTimeObj.day);
        const startTimeHHMM = startTimeObj.start_time_hhmm;
        result[weekday] = startTimeHHMM;
      });
  }
  return result;
};

const getStopDisplayStartTimes = (dbStop: fromCommonApi.RouteEvent): WeekdayDependentValue<string> => {
  const result = {} as WeekdayDependentValue<string>;
  if (dbStop.start_times) {
    Object.keys(dbStop.start_times)
      .map((dayId) => dbStop.start_times[dayId])
      .forEach((startTimeObj: fromCommonApi.RouteEventTime) => {
        const weekday = fromRoot.utils.date.getWeekdayFromDayNumber(startTimeObj.day);
        const startTimestamp = startTimeObj.start_time;
        const d = new fromRoot.utils.date.DisplayDateTime(startTimestamp);
        result[weekday] = d.getDisplayTimeStr('timeNoTimezone');
      });
  }
  return result;
};

const getStopDisplayStartTimesUserTimezone = (
  dbStop: fromCommonApi.RouteEvent,
  timezoneStr: string,
): WeekdayDependentValue<string> => {
  const result = {} as WeekdayDependentValue<string>;
  if (dbStop.start_times) {
    Object.keys(dbStop.start_times)
      .map((dayId) => dbStop.start_times[dayId])
      .forEach((startTimeObj: fromCommonApi.RouteEventTime) => {
        const weekday = fromRoot.utils.date.getWeekdayFromDayNumber(startTimeObj.day);
        const startTimestamp = startTimeObj.start_time;
        const d = new fromRoot.utils.date.DisplayDateTime(startTimestamp, timezoneStr);
        result[weekday] = new UTCTime(d.toUTCTimestamp()).getDisplayTimeStr();
      });
  }
  return result;
};

const getStopEndTimes = (dbStop: fromCommonApi.RouteEvent): WeekdayDependentValue<HHMMTimeString> => {
  const result = {} as WeekdayDependentValue<HHMMTimeString>;
  if (dbStop.start_times) {
    Object.keys(dbStop.start_times)
      .map((dayId) => dbStop.start_times[dayId])
      .forEach((startTimeObj: fromCommonApi.RouteEventTime) => {
        const weekday = fromRoot.utils.date.getWeekdayFromDayNumber(startTimeObj.day);
        const startTimestamp = startTimeObj.start_time;
        const endTimestamp = startTimestamp + (dbStop.duration_wait_time || 0) * 1000;
        result[weekday] = new DisplayDateTime(endTimestamp).toHHmm();
      });
  }
  return result;
};

const getStopDisplayEndTimes = (dbStop: fromCommonApi.RouteEvent): WeekdayDependentValue<string> => {
  const result = {} as WeekdayDependentValue<string>;
  if (dbStop.start_times) {
    Object.keys(dbStop.start_times)
      .map((dayId) => dbStop.start_times[dayId])
      .forEach((startTimeObj: fromCommonApi.RouteEventTime) => {
        const weekday = fromRoot.utils.date.getWeekdayFromDayNumber(startTimeObj.day);
        const startTimestamp = startTimeObj.start_time;
        const endTimestamp = startTimestamp + (dbStop.duration_wait_time || 0) * 1000;
        const d = new fromRoot.utils.date.DisplayDateTime(endTimestamp, fromRoot.utils.date.CommonTimezones.UTC);
        result[weekday] = d.getDisplayTimeStr('timeNoTimezone');
      });
  }
  return result;
};

const dbEventToRouteStop = (
  source: fromCommonApi.RouteEvent,
  eventId: string,
  timezoneStr: string,
): fromEntities.RouteStop => {
  const target = {
    stopId: eventId,
    displayStopId: source.event_display_id,
    address: source.address,
    sequence: source.event_sequence,
    instructions: source.comments || null,
    displayAddress: source.address.formatted_address,
    displayScheduledStartTime: getStopDisplayStartTime(source),
    displayScheduledStartTimeUserTimezone: getStopDisplayStartTimeUserTimezone(source, timezoneStr),
    startTimes: getStopStartTimes(source),
    endTimes: getStopEndTimes(source),
    orderSequence: source.event_sequence,
    displayStartTimes: getStopDisplayStartTimes(source),
    displayStartTimesUserTimezone: getStopDisplayStartTimesUserTimezone(source, timezoneStr),
    displayDuration: millisToDisplayDuration(source.duration || 0, true),
    displayDistance: fromRoot.utils.metersToDisplayDistance(source.distance),
    displayEndTimes: getStopDisplayEndTimes(source),
    type: source.event_type as any as fromEntities.RouteStopType,
    timezoneAbbreviation: getShortTimezone(timezoneStr),
    isInUserTimezone: timezoneStr === getUserTimezone(),
  } as fromEntities.RouteStop;
  if (source.duration_wait_time) {
    target.displayWaitTime = millisToDisplayDuration(source.duration_wait_time * 1000, true);
  }
  if (source.image_urls) {
    target.photos = Object.keys(source.image_urls).map((imgId) => source.image_urls[imgId]);
  }
  if (source.campus) {
    target.campus = source.campus;
  }
  return target;
};

export const dbToRouteStops = (source: fromApi.GetRouteResponse): fromEntities.RouteStop[] => {
  if (!(source && source.events)) {
    return [];
  }
  return Object.keys(source.events).map((eventId) =>
    dbEventToRouteStop(source.events[eventId], eventId, source.timezone),
  );
};
