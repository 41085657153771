import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as fromStore from './store';
import { StoreModule } from '@ngrx/store';
import { StudentsApiService } from './services/students-api.service';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers.studentDataReducer),
    EffectsModule.forFeature(fromStore.effects.featureEffectsArray),
  ],
  providers: [StudentsApiService, ...fromStore.facades.featureFacadesArray],
})
export class StudentDataModule {}
