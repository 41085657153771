import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wp-dropdown-pane-with-buttons',
  template: `
    <div
      tabindex="0"
      class="dropdown-pane-wrap"
      (click)="$event.stopPropagation()"
      (keydown.enter)="applyButtonClicked.emit()"
    >
      <div class="content" [ngStyle]="{ padding: contentPadding }">
        <ng-content></ng-content>
      </div>
      <div class="buttons" [ngStyle]="{ padding: actionButtonsPadding }">
        <wp-btn-secondary [width]="clearButtonWidth" [slim]="true" (clicked)="clearButtonClicked.emit()">
          {{ clearButtonText }}
        </wp-btn-secondary>

        <wp-btn-primary
          class="apply-btn"
          [width]="applyButtonWidth"
          [slim]="true"
          (clicked)="applyButtonClicked.emit()"
        >
          {{ applyButtonText }}
        </wp-btn-primary>
      </div>
    </div>
  `,
  styleUrls: ['./dropdown-pane-with-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownPaneWithButtonsComponent implements OnInit {
  @Input() public applyButtonText = 'Apply';
  @Input() public applyButtonWidth = '140px';
  @Input() public clearButtonText = 'Clear';
  @Input() public clearButtonWidth = '140px';
  @Input() public actionButtonsPadding = '20px 42px 12px';
  @Input() public contentPadding = '30px 42px 22px';
  @Input() public isCloseButton: boolean;

  @Output() public applyButtonClicked = new EventEmitter<void>();
  @Output() public clearButtonClicked = new EventEmitter<void>();
  @Output() public closeClicked = new EventEmitter<void>();
  constructor() {}

  ngOnInit(): void {}
}
