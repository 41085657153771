<wp-icon-schools-green *ngIf="green"></wp-icon-schools-green>
<wp-icon-schools-grey *ngIf="!green"></wp-icon-schools-grey>
<!-- <svg
  class="icon"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  *ngIf="!green"
>
  <g fill="none" fill-rule="evenodd" stroke="#959597">
    <path stroke-width="2" d="M2 3H20V16H2z" transform="translate(1 3)" />
    <path
      d="M9.5 8.5H12.5V15.5H9.5zM4.5 8.5H7.5V11.5H4.5zM14.5 8.5H17.5V11.5H14.5z"
      transform="translate(1 3)"
    />
    <path
      stroke-width="2"
      d="M4 0H18V3H4zM0 16H22V19H0z"
      transform="translate(1 3)"
    />
  </g>
</svg>

<svg
  width="24px"
  height="24px"
  viewBox="0 0 24 24"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  *ngIf="green"
>
  <g
    id="2-element-/-icon-/-ic_school_brand"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g
      transform="translate(1.000000, 3.000000)"
      id="Rectangle"
      stroke="#47A540"
    >
      <rect stroke-width="2" x="2" y="3" width="18" height="13"></rect>
      <rect x="9.5" y="8.5" width="3" height="7"></rect>
      <rect x="4.5" y="8.5" width="3" height="3"></rect>
      <rect x="14.5" y="8.5" width="3" height="3"></rect>
      <rect stroke-width="2" x="4" y="0" width="14" height="3"></rect>
      <rect stroke-width="2" x="0" y="16" width="22" height="3"></rect>
    </g>
  </g>
</svg> -->
