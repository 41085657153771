import { SmartFormFieldDate, SmartFormFieldType } from '@apiEntities/smart-forms';
import { YYYYMMDDString } from '@rootTypes';

export const isSmartFormFieldDate = (arg: any): arg is SmartFormFieldDate => {
  return !!arg && typeof arg === 'object' && arg.type === SmartFormFieldType.DATE;
};

export const isSmartFormFieldDateValueValid = (required: boolean, value?: YYYYMMDDString): boolean => {
  if (!required && !value) {
    return true;
  }
  return typeof value === 'string' && value.length === 8;
};
