import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { getImageNaturalSize } from '@rootTypes/utils/common/dom';
import { take } from 'rxjs/operators';

const markerjs2 = (window as any).markerjs2;

const toolbarHeight = 40;

@Component({
  selector: 'wp-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public imageBase64: string;
  // pixels
  @Input() public maxWidth = 500;
  // pixels
  @Input() public maxHeight = 500;
  // set this property, when displaying in a popup
  @Input() public zIndex = 5;
  public alt = 'Edited image';
  @Input() public isOpenOnInit = true;
  @Output() public imageChanged = new EventEmitter<void>();
  @Output() public imageSaved = new EventEmitter<string>();
  // call event.preventDefault() to cancel closing the editor
  @Output() public beforeClose = new EventEmitter<Event>();
  @Output() public closed = new EventEmitter<void>();
  @ViewChild('editImage') private editImage: ElementRef<HTMLImageElement>;

  public isOpen = false;

  public isInitialized = false;
  public widthStr = 'auto';
  public heightStr = 'auto';

  private markerArea: any;
  private changeCount = 0;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.init();
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.markerArea) {
      this.markerArea.removeEventListener('render');
      this.markerArea.removeEventListener('beforeclose');
      this.markerArea.removeEventListener('statechange');
      this.markerArea.removeEventListener('show');
      this.markerArea.removeEventListener('close');
    }
  }

  public openImageEditor(): void {
    if (this.markerArea) {
      this.markerArea.show();
    }
  }

  public closeImageEditor(): void {
    if (this.markerArea && this.markerArea.isOpen) {
      this.markerArea.close();
    }
  }

  private async init(): Promise<void> {
    await this.setImageDimensions();
    this.isInitialized = true;
    this.initForImage();
    this.cd.detectChanges();
  }

  private initForImage(): void {
    if (this.imageBase64 && this.editImage) {
      const imageEl = this.editImage.nativeElement;
      // create an instance of MarkerArea and pass the target image reference as a parameter
      this.markerArea = new markerjs2.MarkerArea(imageEl);
      this.markerArea.availableMarkerTypes = ['TextMarker', 'CoverMarker'];
      this.markerArea.settings.defaultColorSet = ['white', 'black'];
      this.markerArea.settings.defaultColor = 'black';
      this.markerArea.settings.defaultFillColor = 'white';
      this.markerArea.settings.defaultFontFamilies = ['cursive'];
      this.markerArea.settings.defaultFontFamily = 'cursive';
      this.markerArea.uiStyleSettings.zIndex = this.zIndex;
      this.markerArea.uiStyleSettings.zoomButtonVisible = true;
      this.markerArea.uiStyleSettings.zoomOutButtonVisible = true;
      this.markerArea.settings.uiOffsetTop = -40;
      this.markerArea.renderImageType = 'image/jpeg';
      this.markerArea.renderImageQuality = 1;
      this.markerArea.renderAtNaturalSize = true;
      // register an event listener for when user clicks OK/save in the marker.js UI
      this.markerArea.addEventListener('render', (event) => {
        console.log('render evt');
        // we are setting the markup result to replace our original image on the page
        this.editImage.nativeElement.src = event.dataUrl;
        this.imageSaved.emit(event.dataUrl);
        this.changeCount = 0;
      });

      this.markerArea.addEventListener('statechange', () => {
        // it emits one state change event before close anyway, so for the purpose of
        // checking if any changes were made, you should skip first event
        console.log('state change evt');
        this.changeCount++;
        if (this.changeCount > 1) {
          this.imageChanged.emit();
        }
      });
      this.markerArea.addEventListener('beforeclose', (event) => {
        console.log('before close evt');
        this.beforeClose.emit(event);
      });
      this.markerArea.addEventListener('close', () => {
        console.log('close evt');
        this.isOpen = false;
        this.changeCount = 0;
        this.cd.detectChanges();
        this.closed.emit();
      });
      this.markerArea.addEventListener('show', () => {
        console.log('show evt');
        this.isOpen = true;
        this.changeCount = 0;
        // this.setZIndex();
        this.cd.detectChanges();
      });

      // finally, call the show() method and marker.js UI opens
      if (this.isOpenOnInit) {
        this.markerArea.show();
      }
    }
  }

  private async setImageDimensions(): Promise<void> {
    if (this.imageBase64) {
      const { width, height } = await getImageNaturalSize(this.imageBase64).pipe(take(1)).toPromise();
      const maxHeightAdjustedForToolPanel = this.maxHeight ? this.maxHeight - toolbarHeight : undefined;
      const aspect = width / height;
      let resultWidth = width;
      let resultHeight = height;
      if (maxHeightAdjustedForToolPanel && this.maxWidth) {
        // image is larger than the editor limits, otherwise no actions needed
        if (width > this.maxWidth || height > maxHeightAdjustedForToolPanel) {
          const w = Math.min(width, this.maxWidth);
          const h = w / aspect;
          if (h <= maxHeightAdjustedForToolPanel) {
            resultWidth = w;
            resultHeight = h;
          } else {
            resultHeight = Math.min(height, maxHeightAdjustedForToolPanel);
            resultWidth = aspect * resultHeight;
          }
        }
      } else if (this.maxHeight && height > maxHeightAdjustedForToolPanel) {
        resultHeight = maxHeightAdjustedForToolPanel;
        resultWidth = aspect * resultHeight;
      } else if (this.maxWidth && width > this.maxWidth) {
        resultWidth = this.maxWidth;
        resultHeight = resultWidth / aspect;
      }
      this.widthStr = '' + resultWidth;
      this.heightStr = '' + resultHeight;
    }
  }
}
