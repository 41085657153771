import { YearMonthDay, YYYYMMDDString } from './formatters/year-month-day';
import { dateAddDay, datesIsSame } from '@rootTypes/utils/common/date-time-fns';

export interface GroupDatesInput {
  date: YYYYMMDDString;
  groupId: string;
}

export interface GroupDatesOutput {
  displayDateInterval?: string;
  dates: YYYYMMDDString[];
  groupId: string;
}

export type DateFormat = 'long' | 'short';

/**
 * Sorts and groups dates that are next day from each other and have same groupId
 */
export const groupDates = (source: GroupDatesInput[], format: DateFormat = 'long'): GroupDatesOutput[] => {
  if (!(source && source.length)) {
    return [];
  }
  source.sort((a, b) => {
    const compareStr1 = `${a.date}${a.groupId}`;
    const compareStr2 = `${b.date}${b.groupId}`;
    if (compareStr1 < compareStr2) {
      return -1;
    }
    return 1;
  });
  const groupsWithoutNames: GroupDatesOutput[] = source.reduce((prev, curr) => {
    const currentDateYYYYMMDD = curr.date;
    const currGroupId = curr.groupId;
    const prevGroup = findLast<GroupDatesOutput>(prev, (item) => item.groupId === currGroupId);
    if (prevGroup) {
      const prevGroupLastDate = prevGroup.dates[prevGroup.dates.length - 1];
      if (areYYYYMMDDNextDayFromEachOther(prevGroupLastDate, currentDateYYYYMMDD)) {
        prevGroup.dates.push(currentDateYYYYMMDD);
        return [...prev];
      }
    }
    return [...prev, { groupId: currGroupId, dates: [currentDateYYYYMMDD] }];
  }, []);
  return groupsWithoutNames.map((groupWithoutName) => {
    const firstDate = groupWithoutName.dates[0];
    const lastDate = groupWithoutName.dates[groupWithoutName.dates.length - 1];
    return {
      ...groupWithoutName,
      displayDateInterval: getDisplayIntervalNameForDates(firstDate, lastDate, format),
    } as GroupDatesOutput;
  });
};

function areYYYYMMDDNextDayFromEachOther(date1: YYYYMMDDString, date2: YYYYMMDDString): boolean {
  const formatter1 = new YearMonthDay(date1);
  const dayAfterDate1 = dateAddDay(formatter1.toDate(), 1);
  const formatter2 = new YearMonthDay(date2);
  return datesIsSame(dayAfterDate1, formatter2.toDate(), 'day');
}

function getDisplayIntervalNameForDates(date1: YYYYMMDDString, date2: YYYYMMDDString, format: DateFormat): string {
  if (format === 'long') {
    return getDisplayIntervalNameForDatesLong(date1, date2);
  }
  return getDisplayIntervalNameForDatesShort(date1, date2);
}

function getDisplayIntervalNameForDatesLong(date1: YYYYMMDDString, date2: YYYYMMDDString) {
  const formatter1 = new YearMonthDay(date1);
  const formatter2 = new YearMonthDay(date2);
  // same date
  if (date1 === date2) {
    return formatter1.formatTo('monthAndDayNoPadding');
  }
  // same month
  if (datesIsSame(formatter1.toDate(), formatter2.toDate(), 'month')) {
    return `${formatter1.formatTo('monthShort')} ${formatter1.formatTo('dayNoPadding')} - ${formatter2.formatTo(
      'dayNoPadding',
    )}`;
  }
  return `${formatter1.formatTo('monthAndDayNoPadding')} - ${formatter2.formatTo('monthAndDayNoPadding')}`;
}

function getDisplayIntervalNameForDatesShort(date1: YYYYMMDDString, date2: YYYYMMDDString) {
  const formatter1 = new YearMonthDay(date1);
  const formatter2 = new YearMonthDay(date2);
  // same date
  if (date1 === date2) {
    return formatter1.formatTo('mm_slash_dd');
  }
  return `${formatter1.formatTo('mm_slash_dd')} - ${formatter2.formatTo('mm_slash_dd')}`;
}

function findLast<T>(arr: T[], cb: (item: T) => boolean): T | null {
  if (!(arr || arr.length)) {
    return null;
  }
  for (let i = arr.length - 1; i >= 0; i--) {
    const item = arr[i];
    if (cb(item)) {
      return item;
    }
  }
  return null;
}
