import { Injectable } from '@angular/core';
import { EvStatus } from '@rootTypes/entities';
import { ApiService } from '../../../api/api.service';
import { Observable, of } from 'rxjs';
import * as fromApi from '../../../api';
import * as fromTypes from '../types';
import { getVendorFromApi } from '../types/utils';
import { map, mergeMap } from 'rxjs/operators';
import { entitySearchResultToPortalEntity } from '@rootTypes/utils/portal-entity/entity-search-result-to-portal-enity';

interface ListResponse {
  items: fromTypes.PortalEntity[];
  total: number;
}

export interface VehicleDataResponse {
  vehicle: fromTypes.Vehicle;
  evStatus?: EvStatus;
  driver?: fromTypes.Driver;
  vendor?: fromTypes.Vendor;
}

@Injectable()
export class VehiclesApiService {
  constructor(private api: ApiService) {}

  public entityFilter(request: fromApi.entityFilter.EntityFilterRequest): Observable<ListResponse> {
    return this.api.entityFilter(request).pipe(
      map((response) => {
        return {
          items: response.results.map((s) => entitySearchResultToPortalEntity(s)),
          total: response.total,
        };
      }),
    );
  }

  public getVehicle(vehicleId: string, options?: fromTypes.LoadVehicleOptions): Observable<VehicleDataResponse> {
    const projections = [] as fromApi.getVehicle.GetVehicleProjections[];

    if (options?.withGeotab) {
      projections.push(fromApi.getVehicle.GetVehicleProjections.GEOTAB);
    }

    if (options?.withDriver) {
      projections.push(fromApi.getVehicle.GetVehicleProjections.DRIVER);
    }

    if (options?.withVendor) {
      return this.api.getVehicle({ vehicleId, _projections: projections }).pipe(
        mergeMap((vehicleResponse) => {
          if (vehicleResponse.vehicle.vendorId) {
            return this.api.getVendorProfile({ vendor_id: vehicleResponse.vehicle.vendorId }).pipe(
              map((vendorResponse) => {
                const result = this.vehicleResponseToVehicleData(vehicleResponse);
                result.vendor = getVendorFromApi(vendorResponse);
                return result;
              }),
            );
          }

          return of(this.vehicleResponseToVehicleData(vehicleResponse));
        }),
      );
    }

    return this.api.getVehicle({ vehicleId, _projections: projections }).pipe(
      map((response) => {
        return this.vehicleResponseToVehicleData(response);
      }),
    );
  }

  private vehicleResponseToVehicleData(response: fromTypes.GetVehicleResponse): VehicleDataResponse {
    const result = {
      vehicle: response.vehicle,
    } as VehicleDataResponse;

    if (response._projections?.geotab) {
      result.evStatus = response._projections.geotab?.ev;
    }

    if (response._projections?.driver) {
      result.driver = response._projections.driver;
    }

    return result;
  }
}
