/**
 * Client must not retry if 200, 500, 400, 401, 403 HTTP
 * responses received, since these indicate server successfully
 * processed the request and will unlikely to respond with other result if repeated.
 * Client should retry requests which failed with 5XX except 500.
 */
export enum HttpResponseStatus {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  INTERNAL_SERVER_ERROR = 500,
}
