<wp-user-profile-info [editDisabled]="true"> </wp-user-profile-info>

<div class="wp-user-profile-change-password">
  <div class="header">Login and security</div>

  <div class="form">
    <wp-alert-critical
      class="error-message"
      *ngIf="errorMessage$ | async; let errorMessage"
      [readonly]="true"
      >{{errorMessage}}
    </wp-alert-critical>

    <!-- wrap in <form> for LastPass autocomplete -->
    <form ngNoForm novalidate>
      <wp-input
        class="input"
        [ngClass]="{error: errorMessage$ | async}"
        type="password"
        [label]="'Current password'"
        [control]="currentPassword"
      >
      </wp-input>
    </form>

    <wp-input
      class="input"
      type="password"
      [label]="'New password'"
      [control]="newPassword"
    >
    </wp-input>

    <wp-input
      class="input"
      type="password"
      [label]="'Confirm new password'"
      [control]="confirmNewPassword"
      [customErrorMessageKey]="confirmNewPasswordErrorKey"
    >
    </wp-input>
  </div>

  <div class="buttons">
    <wp-link-btn
      class="cancel-btn"
      [disabled]="isLoading$ | async"
      (clicked)="goUserProfile()"
      >Cancel
    </wp-link-btn>

    <wp-btn-primary
      [width]="'150px'"
      [slim]="true"
      [loading]="isLoading$ | async"
      [disabled]="(formValid$ | async) === false"
      (clicked)="changePassword()"
      >Save
    </wp-btn-primary>
  </div>

  <div class="bottom-line"></div>
</div>
