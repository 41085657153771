<section class="error-container">
  <h2>Error has occurred</h2>

  <div class="error-context">
    <h3>{{ (data | async).text}}</h3>

    <div
      *ngIf="(data | async).originalError.webError else unknownError"
      class="web-error"
    >
      <div>Original Error:</div>
      <div>{{ (data | async).originalError.webError.data }}</div>
      <div
        *ngIf="(data | async).originalError.webError.contextId; let contextId"
      >
        contextId: {{ contextId }}
      </div>
    </div>

    <ng-template #unknownError>
      <div
        class="unknown-error"
        *ngIf="(data | async).originalError.unknownError"
      >
        {{ (data | async).originalError.unknownError | json }}
      </div>
    </ng-template>
  </div>

  <wp-btn-primary class="home-page-btn" (clicked)="goHomePage()"
    >Go to home page
  </wp-btn-primary>

  <wp-btn-secondary class="logout-btn" (clicked)="logout()"
    >Logout
  </wp-btn-secondary>
</section>
