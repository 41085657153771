import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { workQueuePath } from './work-queue-path';
import { WorkQueueStudentRequestStatus } from '@rootTypes';
import { StudentRequestQuickFilter } from '../../../features/work-queue/dependencies/types/student-request-quick-filter';

export interface WorkQueueStudentRequestsRouteData {
  filterStudentIds?: string[];
  filterCampusIds?: string[];
  filterDistrictIds?: string[];
  isStatusPending?: boolean;
  quickFilter?: string;
  pageIndex?: number;
  pageSize?: number;
}

const statePath = 'student-requests';

export const workQueueStudentRequestsRoute: PortalRoute<WorkQueueStudentRequestsRouteData> = {
  path: statePath,

  isOnRoute: (state: RouterStateUrl) => {
    return state && state.url && state.url.startsWith(`/${workQueuePath}/${statePath}`);
  },

  parse: (state: RouterStateUrl): WorkQueueStudentRequestsRouteData => {
    const query = state?.queryParams || {};
    const data: WorkQueueStudentRequestsRouteData = {
      filterStudentIds: query.filterStudentIds
        ? typeof query.filterStudentIds === 'string'
          ? [query.filterStudentIds]
          : query.filterStudentIds
        : [],
      filterCampusIds: query.filterCampusIds
        ? typeof query.filterCampusIds === 'string'
          ? [query.filterCampusIds]
          : query.filterCampusIds
        : [],
      filterDistrictIds: query.filterDistrictIds
        ? typeof query.filterDistrictIds === 'string'
          ? [query.filterDistrictIds]
          : query.filterDistrictIds
        : [],
      pageIndex: query.page ? parseInt(query.page, 10) - 1 : 0,
      pageSize: query.pageSize ? parseInt(query.pageSize, 10) : 10,
      isStatusPending: query.status !== WorkQueueStudentRequestStatus.PROCESSED,
      quickFilter: query.quickFilter || StudentRequestQuickFilter.DEFAULT,
    };
    return data;
  },

  request: (data: WorkQueueStudentRequestsRouteData = {}): NavigationRequest => {
    const request: NavigationRequest = {
      path: ['/', workQueuePath, statePath],
    };
    const { filterStudentIds, filterCampusIds, filterDistrictIds, pageIndex, pageSize, isStatusPending, quickFilter } =
      data;
    const query: { [key: string]: any } = {};
    if (Array.isArray(filterStudentIds)) {
      query.filterStudentIds = filterStudentIds;
    }
    if (Array.isArray(filterCampusIds)) {
      query.filterCampusIds = filterCampusIds;
    }
    if (Array.isArray(filterDistrictIds)) {
      query.filterDistrictIds = filterDistrictIds;
    }
    if (typeof pageIndex === 'number') {
      query.page = (pageIndex + 1).toString();
    }
    if (typeof pageSize === 'number') {
      query.pageSize = pageSize.toString();
    }
    if (isStatusPending === false) {
      query.status = WorkQueueStudentRequestStatus.PROCESSED;
    }
    if (quickFilter && quickFilter !== StudentRequestQuickFilter.DEFAULT) {
      query.quickFilter = quickFilter;
    }
    request.query = query;
    return request;
  },
  getHref(data?: WorkQueueStudentRequestsRouteData): string {
    return `/${workQueuePath}/${statePath}`;
  },
};
