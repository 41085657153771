<wp-auth-wrapper [rightColumnImage]="'resetPassword'">
  <section class="wp-reset-password-setup">
    <div class="container">
      <img src="/assets/img/logo.svg" alt="Zūm" />
      <div class="header">{{headerText$ | async}}</div>
      <div class="instructions">{{instructions$ | async}}</div>

      <wp-alert-critical
        class="error-message"
        *ngIf="errorMsg$ | async; let errorMsg"
        [readonly]="true"
        >{{errorMsg}}
      </wp-alert-critical>

      <wp-input
        class="input"
        [ngClass]="{'error-message': errorMsg$ | async}"
        [label]="'Password'"
        [control]="newPassword"
        type="password"
      >
      </wp-input>

      <wp-input
        class="input"
        [label]="'Confirm password'"
        [control]="confirmNewPassword"
        [customErrorMessageKey]="confirmNewPasswordErrorKey"
        type="password"
      >
      </wp-input>

      <wp-btn-primary
        class="submit-btn"
        (clicked)="setNewPassword()"
        [loading]="isLoading$ | async"
        [disabled]="isSubmitDisabled$ | async"
        >{{submitBtnText$ | async}}
      </wp-btn-primary>
    </div>
  </section>
</wp-auth-wrapper>
