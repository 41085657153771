import { BaseDate, TimeFormat, TimezoneString } from './base-date';
import { YYYYMMDDString } from './year-month-day';
import { dateFormat, dateToYYYYMMDD, formattingConstants } from '@rootTypes/utils/common/date-time-fns';

/**
 * Regular UTC timestamp
 */
export type UTCTimestamp = number;

export class UTCTime extends BaseDate {
  private tz: TimezoneString;

  constructor(value: UTCTimestamp, tz?: TimezoneString) {
    super(value, TimeFormat.UTC_TIMESTAMP);
    this.tz = tz;
  }

  toDate(tz: string = this.tz): Date {
    return new Date(this.value);
  }

  public getDisplayTimeStr(format = formattingConstants.timeWithTimezone): string {
    return dateFormat(this.toDate(), format, this.tz);
  }

  public getDisplayTimeStrNoTZ(): string {
    return dateFormat(this.toDate(), formattingConstants.timeNoTimezone, this.tz);
  }

  public getHHMMString(): string {
    return dateFormat(this.toDate(), formattingConstants.hhMM, this.tz);
  }

  public getDisplayDateStr(format = formattingConstants.dateMedium): string {
    return dateFormat(this.toDate(), format, this.tz);
  }

  public getDisplayWeekdayAndDateStr(format = formattingConstants.dateAndWeekdayMedium): string {
    return dateFormat(this.toDate(), format, this.tz);
  }

  public getDisplayWeekdayDateAndTimeStr(
    format = `${formattingConstants.dateAndWeekdayMedium} ${formattingConstants.timeNoTimezone}`,
  ): string {
    return dateFormat(this.toDate(), format, this.tz);
  }

  getYYYYMMDDString(): YYYYMMDDString {
    return dateToYYYYMMDD(this.toDate(), this.tz);
  }

  formatTo(pattern: string): string {
    return dateFormat(this.toDate(), pattern, this.tz);
  }
}
