import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, take, tap } from 'rxjs/operators';
import * as fromActions from '../actions/employee-details.actions';
import * as fromDataActions from '../actions/employee-data.actions';
import * as fromDeps from '../../types';
import { State } from '@rootStore';
import { Store } from '@ngrx/store';
import { getDistrictIdByOpenedEmployee } from '../selectors/employee-details.selectors';

@Injectable()
export class EmployeeDetailsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private districtDetailsRoute: fromDeps.routes.DistrictDetailsRouterService,
    private campusDetailsRoute: fromDeps.routes.CampusDetailsRouterService,
  ) {}

  public openedEmployee = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setEmployeeId),
      filter((a) => !!a.employeeId),
      map((a) =>
        fromDataActions.loadEmployeeRequested({ employeeId: a.employeeId, options: { withPermissions: true } }),
      ),
    ),
  );

  public employeePermissionLinkClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.employeePermissionLinkClicked),
        tap((action) => {
          const { permission } = action;
          if (permission.entityId === fromDeps.SpecialPermissions.ALL) {
            const districtId$ = this.store.select(getDistrictIdByOpenedEmployee).pipe(take(1));
            districtId$.subscribe((districtId) => {
              this.districtDetailsRoute.navigate({ districtId, displayBackText: 'Back to employee' });
            });
          } else {
            this.campusDetailsRoute.navigate({ campusId: permission.entityId, displayBackText: 'Back to employee' });
          }
        }),
      ),
    { dispatch: false },
  );
}
