<div
  class="btn-increment"
  [ngClass]="{disabled }"
  tabindex="0"
  (click)="onClick()"
>
  <div class="btn-increment-circle">
    <svg
      width="10"
      height="2"
      viewBox="0 0 10 2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 0v2H0V0z" class="icon-path" fill-rule="evenodd" />
    </svg>
  </div>
</div>
