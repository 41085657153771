import { Pipe, PipeTransform } from '@angular/core';
import { StatesUSA } from '@rootTypes/utils';

@Pipe({
  name: 'displayState',
})
export class DisplayStatePipe implements PipeTransform {
  transform(stateAbbreviation: string): string {
    if (!stateAbbreviation) {
      return '--';
    }
    const state = StatesUSA.getStateByToken(stateAbbreviation);
    return state ? state.displayLabel : '--';
  }
}
