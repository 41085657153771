import { createSelector } from '@ngrx/store';
import { getEmployeesFeature } from './get-feature';
import { getAllVendorEntities } from '../../../vendors/store/selectors/vendor-data.selectors';
import { getAllEmployeeEntities } from './employee-data.selectors';
import * as fromTypes from '../../types';

const employeeProfileEditorState = createSelector(getEmployeesFeature, (state) => state.profileEditor);

export const getCurrentUpdatedModel = createSelector(employeeProfileEditorState, (state) => state.updatedModel);

export const getCurrentUpdatedModelValid = createSelector(
  employeeProfileEditorState,
  (state) => state.isUpdatedModelValid,
);

export const getCreateForVendorId = createSelector(employeeProfileEditorState, (state) => state.createForVendorId);

const getCreateForVendorState = createSelector(
  getCreateForVendorId,
  getAllVendorEntities,
  (vendorId, allVendorEntities) => {
    if (vendorId) {
      return allVendorEntities[vendorId];
    }
    return undefined;
  },
);

export const getCreateForVendor = createSelector(getCreateForVendorState, (state) => state?.entity);
export const getCreateForVendorLoading = createSelector(getCreateForVendorState, (state) => state?.isLoading || false);
export const getCreateForVendorError = createSelector(getCreateForVendorState, (state) => state?.error);

export const getOpenedEmployeeId = createSelector(employeeProfileEditorState, (state) => state.employeeId);
const getOpenedEmployeeState = createSelector(
  getOpenedEmployeeId,
  getAllEmployeeEntities,
  (employeeId, allEntities) => {
    if (employeeId) {
      return allEntities[employeeId];
    }
    return undefined;
  },
);

export const getOpenEmployee = createSelector(getOpenedEmployeeState, (state) => state?.entity);
export const getOpenEmployeeEditModel = createSelector(
  getOpenEmployee,
  (source): fromTypes.EmployeeProfileEditModel => {
    if (!source) {
      return undefined;
    }
    return { ...source, photo: { imagePath: source.photo } };
  },
);

export const getOpenEmployeeLoading = createSelector(getOpenedEmployeeState, (state) => state?.isLoading || false);

export const getRequestLoading = createSelector(employeeProfileEditorState, (state) => state.isLoading);
