<div class="wp-user-profile-change-password-preview">
  <div class="columns">
    <div>
      <div class="header">Login and security</div>

      <div *ngIf="isJustUpdated$ | async">
        <i class="success-icon"></i>
        <span class="success-msg"> Password successfully updated </span>
      </div>
    </div>

    <div>
      <wp-btn-secondary
        [disabled]="disabled"
        [width]="'150px'"
        [slim]="true"
        (clicked)="onChangePassword()"
        >Change password
      </wp-btn-secondary>
    </div>
  </div>

  <div class="bottom-line"></div>
</div>
