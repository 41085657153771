import { Pipe, PipeTransform } from '@angular/core';
import { DateRange } from '@rootTypes';
import { yyyymmddDisplay } from '@rootTypes/utils/common/date';

@Pipe({
  name: 'displayDateRange',
})
export class DisplayDateRangePipe implements PipeTransform {
  transform(value: DateRange, long = true, hideYear: boolean = false): string {
    if (!value) {
      return undefined;
    }
    if (!value.endDate) {
      return value.startDate ? yyyymmddDisplay(value.startDate, long, hideYear) : undefined;
    }
    if (!value.startDate) {
      return value.endDate ? yyyymmddDisplay(value.endDate, long, hideYear) : undefined;
    }
    if (value.startDate === value.endDate) {
      return yyyymmddDisplay(value.endDate, long, hideYear);
    }
    return `${yyyymmddDisplay(value.startDate, long, hideYear)} - ${yyyymmddDisplay(value.endDate, long, hideYear)}`;
  }
}
