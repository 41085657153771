<svg
  xmlns="http://www.w3.org/2000/svg"
  width="18"
  height="20"
  viewBox="0 0 18 20"
>
  <g fill="none" fill-rule="evenodd">
    <g fill="#959597">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        fill-rule="nonzero"
                        d="M2 5h14l-.655 11.233C15.22 18.348 13.47 20 11.352 20H6.648c-2.118 0-3.87-1.652-3.993-3.767L2 5zm2.12 2l.532 9.116C4.714 17.174 5.589 18 6.648 18h4.704c1.059 0 1.934-.826 1.996-1.884L13.88 7H4.12z"
                        transform="translate(-1131 -460) translate(486) translate(30 284) translate(0 156) translate(614 20) translate(1)"
                      />
                      <path
                        d="M0 5H18V7H0z"
                        transform="translate(-1131 -460) translate(486) translate(30 284) translate(0 156) translate(614 20) translate(1)"
                      />
                      <path
                        fill-rule="nonzero"
                        d="M6 0h6c2.21 0 4 1.79 4 4v3H2V4c0-2.21 1.79-4 4-4zm0 2c-1.105 0-2 .895-2 2v1h10V4c0-1.105-.895-2-2-2H6zM7 8h1v8H7V8zm1 0v2H7V8h1zM10 8h1v8h-1V8zm1 0v2h-1V8h1z"
                        transform="translate(-1131 -460) translate(486) translate(30 284) translate(0 156) translate(614 20) translate(1)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
