export type GetAuthenticationProviderRequest = {
  email: string;
  appDomain: string;
};

export type GetAuthenticationProviderResponse = {
  provider: AuthenticationProvider;
  ssoUrl?: string;
};

export enum AuthenticationProvider {
  PASSWORD = 'PASSWORD',
  SSO = 'SSO',
}
