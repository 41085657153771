import { distinctUntilChanged, filter, map, switchMap, take, throttleTime } from 'rxjs/operators';
import { GoogleMapState, MapMarker } from '../../interfaces';
import { asyncScheduler, BehaviorSubject, Subject, Subscription } from 'rxjs';
import { MarkerRenderer } from '../marker-renderer';
import { getLocationsHash } from '../get-locations-hash';
import { mapCenterDefault } from '../map-center-default';
import { Observable } from 'rxjs/internal/Observable';

export class MarkerMode {
  public mapTilesLoading$: Observable<boolean>;

  private mapTilesLoadingSubj$$ = new BehaviorSubject(false);
  private resetOn$ = new Subject<void>();
  private subscriptions: Subscription = new Subscription();
  // draws markers (like stops)
  private markerRenderer: MarkerRenderer;

  constructor(
    private state$: BehaviorSubject<GoogleMapState>,
    private fitMapOnMarkersChange = true,
  ) {
    this.mapTilesLoading$ = this.mapTilesLoadingSubj$$.pipe(
      // loading state should persist for at least 2 sec
      // to avoid map flickering
      distinctUntilChanged(),
      throttleTime(1500, asyncScheduler, { trailing: true, leading: true }),
    );
    this.state$
      .asObservable()
      .pipe(
        filter((state) => !!state.map),
        take(1),
      )
      .subscribe(() => this.init());
  }

  public reset(): void {
    this.resetOn$.next();
  }

  public dispose(): void {
    this.subscriptions.unsubscribe();
  }

  public fitMapToMarkers(): void {
    this.markerRenderer.fitMapToMarkers();
  }

  private init(): void {
    this.markerRenderer = new MarkerRenderer(this.state$.value.map);
    google.maps.event.addListener(this.state$.value.map, 'tilesloaded', () => {
      this.mapTilesLoadingSubj$$.next(false);
    });
    this.setMarkerSubscription();
  }

  private setMarkerSubscription(): void {
    const getMarkers$ = this.state$.asObservable().pipe(
      map((state) => state.markers),
      distinctUntilChanged(),
    );

    const markerLocationChanged$ = getMarkers$.pipe(
      switchMap((markers) => {
        return this.markerRenderer.setMarkers(markers).then(() => markers);
      }),
      distinctUntilChanged((prev, curr) => {
        return this.getMarkersLocationHash(prev) === this.getMarkersLocationHash(curr);
      }),
    );
    if (this.fitMapOnMarkersChange) {
      const markerSubscription = markerLocationChanged$.subscribe((markers) => {
        setTimeout(() => {
          this.mapTilesLoadingSubj$$.next(true);
        });
        // make sure loading ends
        setTimeout(() => {
          this.mapTilesLoadingSubj$$.next(false);
        }, 900);
        if (markers && markers.length) {
          this.markerRenderer.fitMapToMarkers();
        } else {
          this.state$.value.map.setCenter(mapCenterDefault);
        }
      });
      this.subscriptions.add(markerSubscription);
    } else {
      const markerSubscription = getMarkers$.subscribe((markers) => {
        this.markerRenderer.setMarkers(markers);
      });
      this.subscriptions.add(markerSubscription);
    }
  }

  private getMarkersLocationHash(markers: MapMarker[]): string {
    if (!markers) {
      return null;
    }
    const locs = markers.map((m) => m.location);
    return getLocationsHash(locs);
  }
}
