import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-icon-alert-warning',
  templateUrl: './icon-alert-warning.component.html',
  styleUrls: ['./icon-alert-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconAlertWarningComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
