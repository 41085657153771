import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { DriversApiService } from '../../services/drivers-api.service';

import {
  getAvailableServiceAreasRequested,
  getAvailableServiceAreasSuccess,
  getAvailableServiceAreasFailed,
} from '../actions';

@Injectable()
export class ServiceRegionDataEffects {
  constructor(
    private actions$: Actions,
    private apiService: DriversApiService,
  ) {}

  public loadServiceRegionsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAvailableServiceAreasRequested),
      mergeMap(() => {
        return this.apiService.getAvailableServiceAreas().pipe(
          map((response) => getAvailableServiceAreasSuccess({ response })),
          catchError((originalError) => {
            return of(
              getAvailableServiceAreasFailed({
                error: {
                  text: 'Failed to load service regions',
                  originalError,
                },
              }),
            );
          }),
        );
      }),
    ),
  );
}
