import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';

import { PortalPermissionsFacade } from '../auth/store/facades/portal-permissions.facade';
import { getPortalFeatures } from '@rootTypes/utils';

export interface SideMenuItem {
  id: string;
  name: string;
  link$: Observable<string>;
  isInMenuForUser$: Observable<boolean>;
  smallFont?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SideMenuService {
  constructor(private permissions: PortalPermissionsFacade) {}

  public getSideMenuItems(): SideMenuItem[] {
    const menuFeatures = getPortalFeatures()
      .filter((feature) => feature.isInMenu)
      .sort((a, b) => a.menuIndex - b.menuIndex);

    return menuFeatures.map((feature) => {
      const item: SideMenuItem = {
        id: feature.id,
        name: feature.menuName,
        smallFont: feature.menuSmallFont,
        link$: undefined,
        isInMenuForUser$: undefined,
      };
      if (typeof feature.link === 'function') {
        item.link$ = feature.link(this.permissions);
      } else {
        item.link$ = of(feature.link);
      }
      if (feature.isInMenuForUser$) {
        item.isInMenuForUser$ = feature.isInMenuForUser$(this.permissions);
      } else {
        item.isInMenuForUser$ = of(false);
      }
      return item;
    });
  }

  public async getHomePageLink(): Promise<string> {
    const isAnyPermissions = await this.permissions.isAnyPermissions$().pipe(take(1)).toPromise();
    if (!isAnyPermissions) {
      return undefined;
    }

    let link: Promise<string>;
    const menuItems = this.getSideMenuItems();
    for (const item of menuItems) {
      const isShownForUser = await item.isInMenuForUser$.pipe(take(1)).toPromise();
      if (isShownForUser) {
        link = item.link$.pipe(take(1)).toPromise();
        break;
      }
    }
    return await link;
  }
}
