import { YYYYMMDDString } from '../../endpoints/common';
import { DriverStatus } from './driver-status';

export type Driver = {
  id: string;
  profileImagePath?: string;
  type: DriverType;
  status: DriverStatus;
  firstName: string;
  lastName: string;
  email?: string;
  phoneNumber?: string;
  gender: Gender;
  license?: License;
  externalEmployeeId?: string;
  vendorId?: string; // required for vendor driver
  seniority?: string;
};

export enum DriverType {
  VENDOR = 'VENDOR',
  ZUMER = 'ZUMER',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export interface License {
  type?: LicenseType;
  expirationDate?: YYYYMMDDString;
}

export enum LicenseType {
  CLASS_A = 'Class A',
  CLASS_B = 'Class B',
  CLASS_C = 'Class C',
  CLASS_D = 'Class D',
  CLASS_E = 'Class E',
  CLASS_CM1 = 'Class CM1',
}

export * from './service-area';
export * from './driver-status';
