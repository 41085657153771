import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { SmartInputStudentTransportationStatus } from '../../models';

@Component({
  selector: 'wp-smart-input-student-transportation-status',
  templateUrl: './smart-input-student-transportation-status.component.html',
  styleUrls: ['./smart-input-student-transportation-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartInputStudentTransportationStatusComponent {
  @Input() public model: SmartInputStudentTransportationStatus;
}
