import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { delay, map, startWith } from 'rxjs/operators';
import { ErrorStateMatcher } from '@angular/material/core';
import { getRandomString } from '@rootTypes/utils';

class CustomErrorStateMatcher implements ErrorStateMatcher {
  constructor(private formControl: UntypedFormControl) {}
  isErrorState(displayControl: UntypedFormControl): boolean {
    const isTouched = this.formControl.touched;
    // this.shouldOverrideTouched || displayControl.touched;
    return isTouched && this.formControl.status !== 'VALID' && Object.keys(this.formControl?.errors || {}).length > 0;
  }
}

@Component({
  selector: 'wp-input-masked',
  templateUrl: './input-masked.component.html',
  styleUrls: ['./input-masked.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputMaskedComponent implements OnInit {
  @Input() public autofocus: boolean;
  @Input() public mask: string;
  @Input() public thousandSeparator = ' ';
  @Input() public suffix = '';
  @Input() public prefix = '';
  @Input() public control: UntypedFormControl;
  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public placeholderCharacter = '_';
  @Input() public tabIndex = '0';
  @Input() public displayErrorKeys: string[];
  @Input() public showMaskTyped: boolean;
  @Input() public dropSpecialCharacters = true;
  @Input() public name: string = getRandomString();
  // Defaults to 'Required'
  @Input() public customRequiredErrorText: string;
  @Output() public blurredEvent = new EventEmitter<string>();
  @Output() public valueChanged = new EventEmitter<string>();

  public errorStateMatcher: CustomErrorStateMatcher;
  public displayValue: string;
  public errors$: Observable<string[] | undefined>;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (!this.control) {
      throw new Error('Masked input should be initialized with FormControl');
    }
    this.errorStateMatcher = new CustomErrorStateMatcher(this.control);
    this.displayValue = this.control.value;
    this.errors$ = this.getErrorsObservable();
    this.control.valueChanges.pipe(startWith(this.control.value), delay(100)).subscribe(() => {
      this.cd.detectChanges();
    });
  }

  public onUserInputChange(value: string): void {
    this.control.setValue(value);
    this.valueChanged.emit(value);
  }

  public onInputBlur(): void {
    this.control.markAsTouched();
    this.control.updateValueAndValidity();
    this.blurredEvent.emit(this.control.value);
  }

  private getErrorsObservable(): Observable<string[] | undefined> {
    const inputInvalid$ = this.control.statusChanges.pipe(map((status) => status !== 'VALID'));
    return inputInvalid$.pipe(
      map((isInvalid) => {
        if (isInvalid) {
          const defaultErrors = [];
          const required = this.control.getError('required');
          if (required) {
            defaultErrors.push(this.customRequiredErrorText || 'Required');
          }
          const customErrors = Object.keys(this.control.errors || {})
            .filter((errorKey) => {
              return (this.displayErrorKeys || []).indexOf(errorKey) >= 0;
            })
            .map((errorKey) => this.control.errors[errorKey]);
          if (customErrors.length) {
            return customErrors;
          } else {
            return defaultErrors;
          }
        } else {
          return undefined;
        }
      }),
    );
  }
}
