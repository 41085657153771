import { PortalListBaseState } from '@rootTypes/utils/list-store-utils/portal-list-base-state';
import { RouteGroupChangeRequestListItem } from '../../dependencies/types/route-group-change-request-list-item';
import {
  routeGroupRequestListActions,
  RouteGroupRequestListParams,
  routeGroupRequestListStatusSelected,
  RouteGroupRequestsFilterType,
} from '../actions/route-group-requests.actions';
import { RouteGroupChangeRequestListStatus } from '../../dependencies/types/route-group-change-request-list-status';
import { PortalListReducer } from '@rootTypes/utils/list-store-utils/portal-list-reducer';
import { createReducer, on } from '@ngrx/store';

export const DEFAULT_ROUTE_GROUPS_PAGE_SIZE = 10;
export const DEFAULT_ROUTE_GROUPS_STATUS: RouteGroupChangeRequestListStatus = 'PENDING';

export type RouteGroupRequestListState = PortalListBaseState<
  RouteGroupRequestListParams,
  RouteGroupChangeRequestListItem
>;

export const getInitialRouteGroupRequestsState = (): RouteGroupRequestListState => {
  return {
    params: {
      page: 0,
      pageSize: DEFAULT_ROUTE_GROUPS_PAGE_SIZE,
      status: DEFAULT_ROUTE_GROUPS_STATUS,
      filters: [],
      selectedItemId: undefined,
      listByEntity: undefined,
      searchQuery: undefined,
    },
    api: {
      isLoading: false,
      items: [],
      total: undefined,
      error: undefined,
    },
  };
};

const baseRouteGroupsListReducer = new PortalListReducer<
  RouteGroupRequestListParams,
  RouteGroupRequestsFilterType,
  RouteGroupChangeRequestListItem
>(routeGroupRequestListActions, getInitialRouteGroupRequestsState);

export const routeGroupRequestListReducer = createReducer<RouteGroupRequestListState>(
  getInitialRouteGroupRequestsState(),
  ...baseRouteGroupsListReducer.getProcessors(),
  on(routeGroupRequestListStatusSelected, (state, action) => {
    const { status } = action;
    return {
      ...state,
      params: {
        ...state.params,
        status,
        page: 0,
      },
      api: {
        ...state.api,
        total: undefined,
        items: [],
      },
    };
  }),
);
