import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { iconPaths } from '@rootTypes/utils';

@Component({
  selector: 'wp-alert-with-icon-check-ok',
  template: `
    <div class="alert" [ngClass]="{ 'no-border': noBorders }">
      <div class="icon-wrap">
        <img class="icon-img" [src]="checkIconPath" alt="check icon" />
      </div>
      <div class="warning-text">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      .alert {
        display: flex;
        padding: 4px 0;
        border-top: 1px solid #dfdfe3;
        border-bottom: 1px solid #dfdfe3;
      }
      .icon-wrap {
        margin-right: 10px;
        width: 32px;
        padding: 4px;
        background-color: rgba(71, 165, 64, 0.12);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .icon-img {
        width: 20px;
        height: 20px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertWithIconCheckOkComponent implements OnInit {
  @Input() public noBorders: boolean;

  public checkIconPath = iconPaths.CHECK_CIRCLE_GREEN_STROKE;
  constructor() {}

  ngOnInit(): void {}
}
