<div
  class="wp-pagination-controls__wrap"
  [ngClass]="{
  'with-page-size-selector': isPageSizeSelector && totalItems > 0,
  'hidden': (isShowPagination$ | async) === false
}"
  [ngStyle]="{marginBottom: marginBottom$ | async, marginTop: marginTop$ | async}"
>
  <div class="wp-pagination-controls" *ngIf="isShowPagination$ | async">
    <div
      class="wp-pagination-controls__btn go-first-page"
      (click)="onFastBackwardClick()"
      [ngClass]="{'disabled': isFastBackwardBtnDisabled | async}"
    >
      <div class="icon">
        <wp-icon-arrow-left-double> </wp-icon-arrow-left-double>
      </div>
    </div>

    <div
      class="wp-pagination-controls__btn prev-page"
      (click)="onPrevPageClick()"
      [ngClass]="{'disabled': isPreviousPageBtnDisabled | async}"
    >
      <div class="icon">
        <wp-icon-arrow-left></wp-icon-arrow-left>
      </div>
    </div>
    <div class="wp-pagination-controls__current-segment">
      <div class="wp-pagination-controls__page-wrap" *ngFor="let page of currentPageSection$ | async">
        <div
          class="wp-pagination-controls__page"
          [ngClass]="{ active: (currentPage$ | async) === page }"
          (click)="onPageClick(page)"
        >
          {{ page + 1 }}
        </div>
      </div>
    </div>

    <div
      class="wp-pagination-controls__btn next-page"
      (click)="onNextPageClick()"
      [ngClass]="{'disabled': isNextPageBtnDisabled | async}"
    >
      <div class="icon">
        <wp-icon-arrow-right [fillColor]="'#636365'"></wp-icon-arrow-right>
      </div>
    </div>

    <div
      class="wp-pagination-controls__btn fast-forward"
      (click)="onFastForwardClick()"
      [ngClass]="{'disabled': isFastForwardBtnDisabled | async}"
    >
      <div class="icon">
        <wp-icon-arrow-left-double></wp-icon-arrow-left-double>
      </div>
    </div>
  </div>
  <div class="items-on-page" [matMenuTriggerFor]="menu" *ngIf="isPageSizeSelector && totalItems > 0">
    <span>{{ pageSize }} per page</span>
    <span class="icon"><wp-icon-arrow-right [rotateDown]="true"></wp-icon-arrow-right></span>
  </div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item *ngFor="let opt of pageSizeOptions" (click)="onPageSizeChanged(opt)">{{opt}} per page</button>
  </mat-menu>
</div>
