import { TransportationRequestProgramFilter } from '../types/transportation-request-list';

export const createTransportationRequestProgramFilter = (programId?: string): TransportationRequestProgramFilter => {
  if (!programId) {
    return undefined;
  }
  return {
    id: `program,${programId}`,
    type: 'program',
    payload: programId,
  };
};
