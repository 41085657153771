import { Coords } from '@rootTypes/utils/map-tracker/coords';

export class TrackerUrlMarker {
  private googleMarker: google.maps.Marker;
  private htmlElement: HTMLImageElement;
  private angle: number;
  private position: Coords;
  private timeoutHandle: any;
  constructor(
    public id: string,
    private url: string,
    private widthPx: number,
    private heightPx: number,
    private map: google.maps.Map,
    position?: Coords,
  ) {
    this.position = position;
    this.init(widthPx, heightPx);
  }

  public destroy(): void {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
    if (this.googleMarker) {
      this.googleMarker.setMap(null);
    }
    this.htmlElement = undefined;
  }

  // BD-3569 Consider showing car tooltip with this method
  public setTooltip(): void {}

  public setPosition(coords: Coords): void {
    this.position = coords;
    if (!this.googleMarker) {
      this.init(this.widthPx, this.heightPx);
    }
    this.draw();
  }

  public setAngle(angle: number): void {
    this.angle = angle;
    if (!this.googleMarker) {
      this.init(this.widthPx, this.heightPx);
    }
    this.draw();
  }

  private init(widthPx: number, heightPx: number): void {
    const midHeight = Math.floor(heightPx / 2);
    const midWidth = Math.floor(widthPx / 2);
    this.googleMarker = new google.maps.Marker({
      optimized: false,
      map: this.map,
      icon: {
        url: this.getFullMarkerUrl(),
        anchor: new google.maps.Point(midWidth, midHeight),
        size: new google.maps.Size(widthPx, heightPx),
      },
    });
    this.setHTMLElement();
  }

  private setHTMLElement(): void {
    this.timeoutHandle = setTimeout(() => {
      this.htmlElement = document.querySelector(`img[src="${this.getFullMarkerUrl()}"]`);
      if (this.htmlElement) {
        this.draw();
      } else {
        this.setHTMLElement();
      }
    }, 500);
  }

  private draw(): void {
    if (this.googleMarker && this.position) {
      this.googleMarker.setPosition(new google.maps.LatLng(this.position.lat, this.position.lng));
    }
    if (this.htmlElement && this.angle) {
      this.htmlElement.style.transform = `rotate(${this.angle}deg)`;
    }
  }

  private getFullMarkerUrl(): string {
    return this.url + `#${this.id}`;
  }
}
