import { combineReducers } from '@ngrx/store';
import * as fromData from './student-data.reducer';
import * as fromLists from './student-lists.reducer';
import * as fromChangeRequestsData from './student-change-requests-data.reducer';
import * as changeLog from './student-change-log.reducer';

export interface StudentDataState {
  data: fromData.StudentDataState;
  lists: fromLists.AllStudentListsState;
  changeRequests: fromChangeRequestsData.StudentChangeRequestsDataState;
  changeLog: changeLog.StudentChangeLogState;
}

export const studentDataReducer = combineReducers<StudentDataState>({
  data: fromData.studentDataReducer,
  lists: fromLists.studentListReducer,
  changeRequests: fromChangeRequestsData.studentChangeRequestsDataReducer,
  changeLog: changeLog.studentChangeLogReducer,
});
