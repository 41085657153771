/**
 * Observe when the target element appears in viewport
 * See https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver
 */
import { Directive, Output, EventEmitter, ElementRef, Input, AfterViewInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[wpIsInViewport]',
})
export class IsInViewportDirective implements AfterViewInit, OnDestroy {
  /**
   * Optional, for tracing purposes
   */
  @Input() public id: string | number;
  /**
   * Defaults to document body
   */
  @Input() public scrollContainer = null;
  /**
   * Will stop listening after first event
   */
  @Input() public emitOnce: boolean;
  /**
   * Configures IntersectionObserver. See link above.
   */
  @Input() public rootMargin = '0px';
  @Input() public thresholds: number[] = [0, 0.1];
  @Output() appearedInViewport = new EventEmitter<void>();
  @Output() disappearedFromViewport = new EventEmitter<void>();

  private intersectionObserver: IntersectionObserver;
  private isInViewport = false;

  constructor(private elRef: ElementRef) {}

  ngAfterViewInit() {
    this.intersectionObserver = new IntersectionObserver(this.onIntersection.bind(this), {
      root: this.scrollContainer,
      rootMargin: this.rootMargin,
      threshold: this.thresholds,
    });
    this.intersectionObserver.observe(this.elRef.nativeElement);
    this.intersectionObserver.takeRecords();
  }

  ngOnDestroy(): void {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }

  private onIntersection(entries: any[]): void {
    if (entries[0].intersectionRatio <= 0) {
      if (this.isInViewport) {
        this.isInViewport = false;
        this.disappearedFromViewport.emit();
      }
    } else {
      this.isInViewport = true;
      this.appearedInViewport.emit();
      if (this.emitOnce) {
        this.intersectionObserver.disconnect();
      }
    }
  }
}
