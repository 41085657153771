import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import * as fromActions from '../actions/permissions-manager.actions';
import * as fromSelectors from '../selectors/permissions-manager.selectors';
import * as fromDataActions from '../actions/employee-data.actions';
import * as fromTypes from '../../types';
import { Action, Store } from '@ngrx/store';
import { getDisplayBackText, openErrorPopup, RouterCommonFacade, State } from '@rootStore';
import { combineLatest, of } from 'rxjs';
import { SchoolEmployeeApiService } from '../../services/school-employee-api.service';
import { SnackbarService } from '../../../../shared/snackbar/snackbar.service';
import { Observable } from 'rxjs/internal/Observable';
import { PopupableService } from '../../../../core/popupable/popupable.service';
import { EmployeePermission } from '../../types';
import { updatedList } from '../actions';

@Injectable()
export class PermissionManagerEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private api: SchoolEmployeeApiService,
    private snackbar: SnackbarService,
    private commonRouter: RouterCommonFacade,
    private popupableService: PopupableService,
  ) {}

  public inittedPermissionEditorForLoadOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initPermissionsEditor),
      map((action) => {
        const { employeeId } = action;
        return fromDataActions.loadPermissionOptionsRequested({ employeeId });
      }),
    ),
  );

  public inittedPermissionEditorForLoadVendor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.initPermissionsEditor),
      map((action) => {
        const { employeeId } = action;
        return fromDataActions.loadDistrictOnEmployeeRequested({ employeeId });
      }),
    ),
  );

  public updatePermissionsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateEmployeePermissionsRequested),
      switchMap((action) => {
        const { target } = action;
        const employeeId$ = this.store.select(fromSelectors.getOpenEmployeeId);
        const initial$ = this.store.select(fromSelectors.getOpenEmployeePermissions);
        return combineLatest([employeeId$, initial$]).pipe(
          take(1),
          switchMap(([employeeId, initial]) => {
            return this.getReducedPermissionConfirmed$(initial, target).pipe(
              switchMap((confirmed) => {
                return this.doUpdatePermissions$(employeeId, initial, target, confirmed);
              }),
            );
          }),
        );
      }),
    ),
  );

  public failedToUpdatePermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateEmployeePermissionsFailed),
      map((action) => {
        const { error } = action;
        return openErrorPopup({ error });
      }),
    ),
  );

  public successfullyUpdatedPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateEmployeePermissionsSuccess),
      withLatestFrom(this.store.select(getDisplayBackText)),
      map(([action, displayBackText]) => {
        const { employeeId } = action;
        this.snackbar.success('Successfully updated permissions');
        if (displayBackText) {
          this.commonRouter.onGoBack();
        } else {
          this.commonRouter.onGoBack(2);
        }
        return fromDataActions.loadEmployeeRequested({ employeeId, options: { withPermissions: true } });
      }),
    ),
  );

  /**
   * Update main employee list, as a new employee might be created
   * Wait for 3s to allow for re-indexing
   */
  public successfullyUpdatedPermissionsForReloadList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateEmployeePermissionsSuccess),
      switchMap(() => {
        return of(updatedList({ listName: fromTypes.EmployeeListName.MAIN_EMPLOYEE_LIST })).pipe(
          delay(fromTypes.configs.SEARCH_INDEX_REFRESH_DELAY),
        );
      }),
    ),
  );

  private getReducedPermissionConfirmed$(
    initial: fromTypes.EmployeePermission[],
    target: fromTypes.EmployeePermission[],
  ): Observable<boolean> {
    const isPermissionsReduced = fromTypes.utils.isEmployeePermissionsReduced(initial, target);
    const confirmed$ = isPermissionsReduced ? this.confirmedPermissionReduce() : of(true);
    return confirmed$;
  }

  private doUpdatePermissions$(
    employeeId: string,
    initial: EmployeePermission[],
    target: EmployeePermission[],
    reductionConfirmed: boolean,
  ): Observable<Action> {
    if (!reductionConfirmed) {
      return of(fromActions.updateEmployeePermissionsCanceled());
    }
    const request = fromTypes.utils.getUpdatePermissionsRequest(employeeId, initial, target);
    if (!request.permissionsChange) {
      return of(fromActions.updateEmployeePermissionsSuccess({ employeeId }));
    }
    return this.api.updateEmployeePermissions(request).pipe(
      map((resp) => fromActions.updateEmployeePermissionsSuccess({ employeeId })),
      catchError((err) => {
        console.log(err);
        return of(
          fromActions.updateEmployeePermissionsFailed({
            employeeId,
            error: {
              text: 'Failed to update employee permissions',
              originalError: err,
            },
          }),
        );
      }),
    );
  }

  private confirmedPermissionReduce(): Observable<boolean> {
    return this.popupableService.openConfirmPopup(
      {
        header: 'Delete permissions',
        text: 'The changes you have made include deleting campus-level permissions, which will remove this employee’s access to data related to this campus.',
        confirmBtnColor: 'red',
        confirmBtnText: 'Delete',
        cancelBtnText: 'Cancel',
        confirmBtnWidthPx: 150,
        cancelBtnWidthPx: 150,
      },
      { contentZIndex: 12, pageMaskZIndex: 11 },
    );
  }
}
