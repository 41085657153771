import * as fromTypes from '../../../types/entities/common';

export enum CampusListFilterType {
  ENTITY = 'ENTITY',
}

export interface CampusListFilter {
  id: string;
  type: CampusListFilterType;
  payload: CampusListFilterPayload<CampusListFilter>;
}

export type EntityFilterPayload = fromTypes.PortalEntity;

type CampusListFilterPayload<T> = T extends { type: CampusListFilterType.ENTITY } ? EntityFilterPayload : {};
