import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'wp-popup-page-top',
  templateUrl: './popup-page-top.component.html',
  styleUrls: ['./popup-page-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupPageTopComponent implements OnInit {
  @Input() public borderBottom = true;
  @Input() public isCloseBtn = true;

  @Output() public closeClicked: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  public onCloseClick(): void {
    this.closeClicked.emit();
  }
}
