import { Pipe, PipeTransform } from '@angular/core';
import { getListItemNumber } from '@rootTypes/utils';

@Pipe({
  name: 'displayListItemNumber',
})
export class DisplayListItemNumberPipe implements PipeTransform {
  transform(index: number, pageSize: number, page: number): unknown {
    return getListItemNumber(index + 1, pageSize || 0, page || 0);
  }
}
