import { createAction, props } from '@ngrx/store';
import { GetPendingStudentChangeRequestsByStudentResponse, WpError } from '../../dependencies';
import { ApiStudent } from '@apiEntities';

const studentChangeRequestRoot = '[StudentChangeRequestRoot]';
export const initStudentChangeRequestRoot = createAction(
  `${studentChangeRequestRoot}: Init state`,
  props<{ studentId: string }>(),
);
export const destroyStudentChangeRequestRoot = createAction(`${studentChangeRequestRoot}: Destroy state`);

export const studentChangeRequestRootLoadPendingRequestsRequested = createAction(
  `${studentChangeRequestRoot}: Load pending requests by student requested`,
  props<{ studentId: string }>(),
);
export const studentChangeRequestRootLoadPendingRequestsSuccess = createAction(
  `${studentChangeRequestRoot}: Load pending requests by student success`,
  props<{ response: GetPendingStudentChangeRequestsByStudentResponse }>(),
);
export const studentChangeRequestRootLoadPendingRequestsFailed = createAction(
  `${studentChangeRequestRoot}: Load pending requests by student failed`,
  props<{ error: WpError }>(),
);

export const loadStudentRequested = createAction(
  `${studentChangeRequestRoot} Load student requested`,
  props<{ studentId: string }>(),
);

export const loadStudentSuccess = createAction(
  `${studentChangeRequestRoot} Load student success`,
  props<{ studentId: string; student: ApiStudent }>(),
);

export const loadStudentFailed = createAction(
  `${studentChangeRequestRoot} Load student failed`,
  props<{ studentId: string; error: WpError }>(),
);
