import { Injectable, NgZone } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { SwUpdate } from '@angular/service-worker';
import { interval, Observable, Subscription } from 'rxjs';
import { delay, filter, map, tap } from 'rxjs/operators';

interface State {
  isNewVersionAvailablePopupOpened: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AppVersionUpdateService extends ComponentStore<State> {
  public isNewVersionAvailablePopupOpened$ = this.select((state) => state.isNewVersionAvailablePopupOpened);

  private pollingForUpdateSubscription?: Subscription;

  // Docs: https://angular.io/guide/service-worker-communications#checking-for-updates
  // Note, we check for update manually by interval instead of listening to swUpdate.versionUpdates as
  // some users are constantly monitoring one app page without changing URL (for instance, on yard's TVs),
  // so the version update event will not be triggered by the default URL change algorithm
  private startPollingForAppUpdate = this.effect((origin$: Observable<void>) => {
    return origin$.pipe(
      // wait till service worker is registered and the home page is loaded,
      // check also AppModule to see the service worker registrationStrategy
      delay(10000),
      map(() => this.swUpdate.isEnabled),
      filter((isSwEnabled) => isSwEnabled === true),
      tap(() => {
        this.zone.runOutsideAngular(() => {
          this.pollingForUpdateSubscription = interval(wpEnvironment.appUpdateCheckIntervalSeconds * 1000).subscribe(
            async () => {
              try {
                // checkForUpdate returns true only for the first time when the new version becomes available,
                // if user rejects the update, the next call will return false until the next version will be deployed
                const isNewVersionFoundAndReadyForInstallation = await this.swUpdate.checkForUpdate();
                if (wpEnvironment.apiLogsEnabled) {
                  console.debug('Is new app version available', isNewVersionFoundAndReadyForInstallation);
                }
                if (isNewVersionFoundAndReadyForInstallation) {
                  this.patchState({
                    isNewVersionAvailablePopupOpened: true,
                  });
                }
              } catch (error) {
                console.error(new Error('Check for app update error'), error);
              }
            },
          );
        });
      }),
    );
  });

  private delayNewVersionPopup = this.effect((origin$: Observable<void>) => {
    return origin$.pipe(
      delay(60 * 60000),
      tap(() => {
        this.patchState({
          isNewVersionAvailablePopupOpened: true,
        });
      }),
    );
  });

  constructor(
    private swUpdate: SwUpdate,
    private zone: NgZone,
  ) {
    super({
      isNewVersionAvailablePopupOpened: false,
    });
  }

  public onAppInit(): void {
    this.startPollingForAppUpdate();
  }

  public applyNewVersion(): void {
    document.location.reload();
  }

  public delayNewVersion(): void {
    if (this.pollingForUpdateSubscription) {
      this.pollingForUpdateSubscription.unsubscribe();
      this.pollingForUpdateSubscription = undefined;
    }
    this.patchState({ isNewVersionAvailablePopupOpened: false });
    this.delayNewVersionPopup();
  }
}
