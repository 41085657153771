<wp-textarea
  [label]="model.label"
  [control]="model.control"
  [controlStateChange]="model.controlStateChange$"
  [characterCounter]="characterCounter"
  [hint]="model.hint"
  [hintFn]="model.hintFn"
  [maxLength]="model.maxLength"
  [tabIndex]="'1'"
  [customErrorMessageKey]="model.customErrorMessageKey"
  (inputEvent)="inputEvent.emit($event)"
  (focusEvent)="focusEvent.emit()"
></wp-textarea>
