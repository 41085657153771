import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-spaced-row',
  templateUrl: './spaced-row.component.html',
  styleUrls: ['./spaced-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpacedRowComponent implements OnInit {
  /**
   * Pixels
   */
  @Input() public marginBottom = 3;

  constructor() {}

  ngOnInit(): void {}
}
