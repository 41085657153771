import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PortalRouterLinkData } from '../../../router/types/portal-router-link-data';
import { ApiPortalPermissionItem } from '@apiEntities/api-portal-permission-item';
import { Store } from '@ngrx/store';
import { isPermissionGivenForAccount } from '../../../auth/store/selectors/portal-permissions.selectors';
import { take } from 'rxjs/operators';

@Component({
  selector: 'wp-portal-router-link',
  template: `
    <a
      [routerLink]="routerLinkData?.routerLink"
      [queryParams]="routerLinkData?.queryParams || {}"
      [queryParamsHandling]="routerLinkData?.queryParamsHandling"
      [replaceUrl]="routerLinkData?.replaceUrl"
      class="portal-router-link"
      [ngClass]="{ 'no-formatting': noFormatting, disabled: !isNavigationAllowed }"
      (click)="onLinkClick($event)"
    >
      <ng-content></ng-content>
    </a>
  `,
  styleUrl: `./portal-router-link.component.scss`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalRouterLinkComponent implements OnInit {
  @Input() public routerLinkData: PortalRouterLinkData;
  @Input() public stopPropagationOnClick = true;
  @Input() public checkPermission: ApiPortalPermissionItem;
  @Input() public noFormatting = false;

  public isNavigationAllowed: boolean;

  constructor(
    private store: Store,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.checkPermission) {
      this.store
        .select(isPermissionGivenForAccount(this.checkPermission))
        .pipe(take(1))
        .subscribe((isAllowed) => {
          this.isNavigationAllowed = isAllowed;
          this.cd.detectChanges();
        });
    } else {
      this.isNavigationAllowed = true;
    }
  }

  onLinkClick(event: MouseEvent): void {
    if (this.stopPropagationOnClick && this.isNavigationAllowed) {
      event.stopPropagation();
    }
  }
}
