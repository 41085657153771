import { Directive, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

/**
 * For this directive to work properly, global styles have to be set. See src/styles.scss (selected item)
 */
@Directive({
  selector: '[wpSelectedItem]',
})
export class SelectedItemDirective implements OnChanges {
  // Will apply selected styles if true
  @Input() public wpSelectedItem: boolean;
  @Input() public wpSelectedVerticalBar = true;
  // Inner - vertical bar will be inside the container, as part of the background, outer - will be applied as box-shadow
  @Input() public wpSelectedHorizontalBarPosition: 'outer' | 'inner' = 'inner';
  @Input() public wpSelectedVerticalBarWidthPx: '1' | '2' | '3' | '4' = '4';

  @HostBinding('style.--portal-selected-bar-width') private portalSelectedBarWidthPx = '4px';
  @HostBinding('class.portal-selected') private isPortalSelected = false;
  @HostBinding('class.inner-bar') private isInnerBar = false;
  @HostBinding('class.outer-bar') private isOuterBar = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.isPortalSelected = this.wpSelectedItem;
    this.isInnerBar = this.wpSelectedItem && this.wpSelectedHorizontalBarPosition === 'inner';
    this.isOuterBar = this.wpSelectedItem && this.wpSelectedHorizontalBarPosition === 'outer';
    if (changes.wpSelectedVerticalBarWidthPx) {
      this.portalSelectedBarWidthPx = `${this.wpSelectedVerticalBarWidthPx}px`;
    }
  }
}
