import { DownloadImageSize } from '@rootTypes';

export type DownloadImageSizePx = DownloadImageSize;

export const getPhotoUrl = (src: string, size?: DownloadImageSizePx): string => {
  const imgWidth = size?.width ? `&width=${size.width}` : '';
  const imgHeight = size?.height ? `&height=${size.height}` : '';
  const imgSize = `${imgWidth}${imgHeight}`;
  return `${wpEnvironment.apiBaseUrl}/images/${wpEnvironment.userRole}/downloadImage?imagePath=${src}${imgSize}`;
};
