import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from './page/page.component';
import { PageTopComponent } from './page-top/page-top.component';
import { PageBottomComponent } from './page-bottom/page-bottom.component';
import { PageTopTitleComponent } from './page-top-title/page-top-title.component';
import { PageTopRightBlockComponent } from './page-top-right-block/page-top-right-block.component';
import { PageBodyComponent } from './page-body/page-body.component';
import { PopupPageComponent } from './drawer/popup-page/popup-page.component';
import { PopupPageTitleComponent } from './drawer/popup-page-title/popup-page-title.component';
import { PopupPageSubtitleComponent } from './drawer/popup-page-subtitle/popup-page-subtitle.component';
import { PopupPageTopComponent } from './drawer/popup-page-top/popup-page-top.component';
import { PopupPageTopRightComponent } from './drawer/popup-page-top-right/popup-page-top-right.component';
import { GreySectionSeparatorLineComponent } from './grey-section-separator-line/grey-section-separator-line.component';
import { PopupPageBodyComponent } from './drawer/popup-page-body/popup-page-body.component';
import { PopupPageTopPhotoComponent } from './drawer/popup-page-top-photo/popup-page-top-photo.component';
import { PopupPagePhotoComponent } from './drawer/popup-page-photo/popup-page-photo.component';
import { PopupPageBackBarComponent } from './drawer/popup-page-back-bar/popup-page-back-bar.component';
import { TypographyModule } from '../typography/typography.module';
import { CommonPopupPageComponent } from './popup/common-popup-page/common-popup-page.component';
import { CommonPopupPageTopComponent } from './popup/common-popup-page-top/common-popup-page-top.component';
import { CommonPopupPageBodyComponent } from './popup/common-popup-page-body/common-popup-page-body.component';
import { CommonPopupPageTitleComponent } from './popup/common-popup-page-title/common-popup-page-title.component';
import { PopupBodyTopComponent } from './drawer/popup-body-top/popup-body-top.component';
import { PopupBodyBottomComponent } from './drawer/popup-body-bottom/popup-body-bottom.component';
import { LoadingContentErrorModule } from './loading-content-error/loading-content-error.module';
import { PopupPageBottomComponent } from './drawer/popup-page-bottom/popup-page-bottom.component';
import { IconCloseModule } from '../icons/icon-close/icon-close.module';
import { ArrowIconsModule } from '../icons/arrow-icons/arrow-icons.module';
import { CommonPopupButtonsComponent } from './popup/common-popup-buttons/common-popup-buttons.component';
import { ButtonsModule } from '../buttons/buttons.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';

const components = [
  GreySectionSeparatorLineComponent,
  CommonPopupPageComponent,
  CommonPopupPageTopComponent,
  CommonPopupPageBodyComponent,
  CommonPopupPageTitleComponent,
  PageComponent,
  PageTopComponent,
  PageBottomComponent,
  PageTopTitleComponent,
  PageTopRightBlockComponent,
  PageBodyComponent,
  PopupPageComponent,
  PopupPageTitleComponent,
  PopupPageSubtitleComponent,
  PopupPageTopComponent,
  PopupPageTopRightComponent,
  PopupPageBodyComponent,
  PopupPageTopPhotoComponent,
  PopupPagePhotoComponent,
  PopupPageBodyComponent,
  PopupPageBackBarComponent,
  PopupBodyTopComponent,
  PopupBodyBottomComponent,
  PopupPageBottomComponent,
  CommonPopupButtonsComponent,
];

const modules = [CommonModule, TypographyModule];

@NgModule({
  declarations: [...components],
  imports: [
    ...modules,
    LoadingContentErrorModule,
    IconCloseModule,
    ArrowIconsModule,
    ButtonsModule,
    MatProgressBarModule,
  ],
  exports: [...components, LoadingContentErrorModule],
})
export class PageModule {}
