<div class="wp-grey-closabel-block">
  <div class="wp-grey-closabel-block__top">
    <div class="wp-grey-closabel-block__title">{{ title }}</div>
    <div class="wp-grey-closabel-block__close" (click)="onClose()">
      <wp-icon-close [size]="'small'"></wp-icon-close>
    </div>
  </div>
  <div class="wp-grey-closabel-block__content">
    <ng-content></ng-content>
  </div>
</div>
