import { RouteGroupDataEffects } from './route-group-data.effects';
import { RouteGroupScheduleChangeRequestEffects } from './route-group-schedule-change-request.effects';
import { RouteGroupOwnerHistoryEffects } from './route-group-owner-history.effects';
import { RouteGroupActivityPaymentConfigsEffects } from './route-group-activity-payment-configs.effects';

export const routeGroupDataEffects = [
  RouteGroupDataEffects,
  RouteGroupScheduleChangeRequestEffects,
  RouteGroupOwnerHistoryEffects,
  RouteGroupActivityPaymentConfigsEffects,
];
