import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideBarComponent } from './side-bar/side-bar.component';
import { SideBarTopComponent } from './side-bar-top/side-bar-top.component';
import { SideBarBottomComponent } from './side-bar-bottom/side-bar-bottom.component';
import { SideBarItemComponent } from './side-bar-item/side-bar-item.component';
import { SideBarIconComponent } from './side-bar-icon/side-bar-icon.component';
import { SideBarTextComponent } from './side-bar-text/side-bar-text.component';
import { SideBarService } from './side-bar.service';
import { IconDashboardComponent } from './icons/icon-dashboard/icon-dashboard.component';
import { IconRidesComponent } from './icons/icon-rides/icon-rides.component';
import { IconRoutesComponent } from './icons/icon-routes/icon-routes.component';
import { IconVehiclesComponent } from './icons/icon-vehicles/icon-vehicles.component';
import { IconDriversComponent } from './icons/icon-drivers/icon-drivers.component';
import { IconSchoolsComponent } from './icons/icon-schools/icon-schools.component';
import { IconStudentsComponent } from './icons/icon-students/icon-students.component';
import { IconInsightsComponent } from './icons/icon-insights/icon-insights.component';
import { SideBarDropdownComponent } from './side-bar-dropdown/side-bar-dropdown.component';
import { SideBarIconWrapComponent } from './side-bar-icon-wrap/side-bar-icon-wrap.component';
import { ClickOutsideDirective } from './click-outside.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconsModule } from '../icons/icons.module';
import { ComponentsModule } from '../components/components.module';
import { DirectivesModule } from '../directives/directives.module';
import { SideBarDropdownOptionComponent } from './side-bar-dropdown-option/side-bar-dropdown-option.component';

const components = [
  SideBarComponent,
  SideBarTopComponent,
  SideBarBottomComponent,
  SideBarItemComponent,
  SideBarIconComponent,
  SideBarTextComponent,
  SideBarDropdownComponent,
  SideBarDropdownOptionComponent,
  SideBarIconWrapComponent,
];

const services = [SideBarService];

@NgModule({
  declarations: [
    ...components,
    IconDashboardComponent,
    IconRidesComponent,
    IconRoutesComponent,
    IconVehiclesComponent,
    IconDriversComponent,
    IconSchoolsComponent,
    IconStudentsComponent,
    IconInsightsComponent,
    ClickOutsideDirective,
  ],
  imports: [CommonModule, MatTooltipModule, IconsModule, ComponentsModule, DirectivesModule],
  providers: [...services],
  exports: [...components],
})
export class SideBarModule {}
