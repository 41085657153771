import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-icon-routes',
  templateUrl: './icon-routes.component.html',
  styleUrls: ['./icon-routes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconRoutesComponent implements OnInit {
  @Input() public green = false;

  constructor() {}

  ngOnInit(): void {}
}
