import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { RideDetailsTab, rideDetailsTabDefault, RideLogsTab, rideLogsTabDefault } from '@rootTypes/entities/ride';
import { rideList } from './ride-list';

export interface RideDetailsRouteData {
  rideId: string;
  rideDetailsTab?: RideDetailsTab;
  rideLogsTab?: RideLogsTab;
  displayBackText?: string;
  isCharterRide?: boolean;
}

export const rideDetailsDrawer: PortalRoute<RideDetailsRouteData> = {
  path: 'details/:rideId/:rideDetailsTab/:rideLogsTab',
  isOnRoute: (state: RouterStateUrl) => {
    return state.drawer && state.drawer.url && state.drawer.url.startsWith('_/drawer/rides/details');
  },
  parse: (state: RouterStateUrl): RideDetailsRouteData => {
    const rideDetailsTab = state.drawer.params.rideDetailsTab || rideDetailsTabDefault;
    let res: RideDetailsRouteData = {
      rideId: state.drawer.params.rideId,
      rideDetailsTab,
    };
    if (rideDetailsTab === RideDetailsTab.RIDE_LOGS) {
      res.rideLogsTab = state.drawer.params.rideLogsTab || rideLogsTabDefault;
    }
    if (state.queryParams.isCharterRide) {
      res.isCharterRide = state.queryParams.isCharterRide === 'true';
    }
    if (state.queryParams) {
      res = {
        ...res,
        ...state.queryParams,
      };
    }
    return res;
  },
  request: (
    data: RideDetailsRouteData,
    queryParamStrategy: QueryParamsHandlingStrategy,
    replaceUrl = false,
  ): NavigationRequest => {
    // do not mutate the original arg
    const copy = { ...data };
    const { rideId } = copy;
    const rideDetailsTab = copy.rideDetailsTab || rideDetailsTabDefault;
    const rideLogsTab = copy.rideLogsTab || rideLogsTabDefault;
    const path: string[] = ['_', 'drawer', 'rides', 'details', rideId, rideDetailsTab];
    if (rideDetailsTab === RideDetailsTab.RIDE_LOGS) {
      path.push(rideLogsTab);
    } else {
      path.push('');
    }
    const res = {
      path,
    } as NavigationRequest;
    delete copy.rideId;
    delete copy.rideDetailsTab;
    delete copy.rideLogsTab;
    // extract additional data fields as query params
    if (Object.keys(copy).length) {
      res.query = { ...copy };
    }
    if (queryParamStrategy) {
      res.extras = {
        queryParamsHandling: queryParamStrategy,
      };
    }
    if (replaceUrl) {
      res.extras = { ...(res.extras || {}), replaceUrl };
    }
    return res;
  },
  getHref(data: RideDetailsRouteData): string {
    return `${rideList.getHref()}(drawer:_/drawer/rides/details/${data.rideId}/${rideDetailsTabDefault}/)`;
  },
};
