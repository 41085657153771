import * as fromRouter from '@ngrx/router-store';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import * as fromRooter from 'src/app/router/types/router-state-url';

export type RouterStateUrl = fromRooter.RouterStateUrl;
export type DrawerStateUrl = fromRooter.DrawerStateUrl;

type RouterOutletState = {
  url: string;
  params?: {
    [key: string]: string | number;
  };
};

/**
 * Maps router state to the app store
 */
export class CustomRouteSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const {
      url,
      root: route,
      root: { queryParams },
    } = routerState;
    const mainOutletState: RouterOutletState = this.getRouterStateForRoot(route);
    let drawerOutletState: RouterOutletState = { url: null, params: {} };
    // check if drawer outlet is activated
    // opening an auxiliary outlet adds children to the root
    if (route.children[1]) {
      drawerOutletState = this.getRouterStateForRoot(route.children[1]);
    }
    const res = {
      url,
      queryParams,
      params: mainOutletState.params,
      drawer: drawerOutletState,
    };
    return res;
  }

  private getRouterStateForRoot(root: ActivatedRouteSnapshot): RouterOutletState {
    let curr: ActivatedRouteSnapshot = root;
    let params = { ...root.params };
    let url = this.getUrlForRoute(root);
    while (curr.firstChild) {
      params = { ...params, ...curr.firstChild.params };
      url = this.mergeUrls(url, this.getUrlForRoute(curr.firstChild));
      curr = curr.firstChild;
    }
    return {
      url,
      params,
    };
  }

  private getUrlForRoute(route: ActivatedRouteSnapshot): string | null {
    const urlPlainStr = route.url.map((u) => u.path).join('/');
    if (!urlPlainStr.length) {
      return null;
    }
    return urlPlainStr;
  }

  private mergeUrls(parent, child): string {
    if (!parent) {
      return child;
    }
    if (!child) {
      return parent;
    }
    return `${parent}/${child}`;
  }
}
