import { createReducer, on } from '@ngrx/store';
import { cleanUpStore, createEntityState, VehicleDataState } from '../../types';
import * as fromActions from '../actions';

const createInitialVehicleState = (): VehicleDataState => {
  return {
    vehicles: {},
    primaryDriversByVehicles: {},
    evStatusesByVehicles: {},
  };
};

export const vehicleDataReducer = createReducer<VehicleDataState>(
  createInitialVehicleState(),
  on(cleanUpStore, createInitialVehicleState),
  // load vehicle
  on(fromActions.loadVehicleRequested, (state, action) => {
    const { vehicleId } = action;
    const vehicleEntityState = state.vehicles[vehicleId] || createEntityState();
    return {
      ...state,
      vehicles: {
        ...state.vehicles,
        [vehicleId]: {
          ...vehicleEntityState,
          isLoading: true,
          error: undefined,
        },
      },
    };
  }),

  on(fromActions.loadVehicleSuccess, fromActions.updateVehicleSuccess, (state, action) => {
    const { vehicle, driver, evStatus, options } = action;
    const { id: vehicleId } = vehicle;

    const result = {
      ...state,
      vehicles: {
        ...state.vehicles,
        [vehicleId]: createEntityState(vehicle),
      },
    };

    if (options?.withDriver) {
      result.primaryDriversByVehicles = {
        ...state.primaryDriversByVehicles,
        [vehicleId]: {
          driverId: driver?.id,
        },
      };
    }

    if (options?.withGeotab) {
      result.evStatusesByVehicles = {
        ...state.evStatusesByVehicles,
        [vehicleId]: evStatus,
      };
    }

    return result;
  }),

  on(fromActions.loadVehicleFailed, (state, action) => {
    const { vehicleId, error } = action;
    return {
      ...state,
      vehicles: {
        ...state.vehicles,
        [vehicleId]: createEntityState(undefined, error),
      },
    };
  }),
);
