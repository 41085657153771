import { tripsConfigReducer, TripsConfigState } from './trips-config.reducer';
import { combineReducers } from '@ngrx/store';
import { TripDataInfoState, tripDataReducer } from './trips-data.reducer';

export interface TripsDataState {
  config: TripsConfigState;
  info: TripDataInfoState;
}

export const tripsDataReducer = combineReducers<TripsDataState>({
  config: tripsConfigReducer,
  info: tripDataReducer,
});
