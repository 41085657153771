import { Activity, PortalEntityType } from '@rootTypes';

export const getActivityAssignmentEntityType = (activity: Activity): PortalEntityType => {
  if (activity?.user) {
    if (activity?.user?.role === 'driver') {
      return PortalEntityType.DRIVER;
    }
  }
  if (activity?.vendor?.id) {
    return PortalEntityType.DRIVER;
  }
  return undefined;
};
