import { PointTime } from '../point-time';
import { EnqueuedPointTime } from '../enqueued-point-time';
import { mphToMetersPerMilli } from './mph-to-meters-per-milli';

export const getEnqueuedPointTime = (
  prev: PointTime | EnqueuedPointTime | null,
  curr: PointTime | EnqueuedPointTime,
  distanceToMe: number,
  bearingToMe: number,
  minSpeedMPH?: number,
  maxSpeedMPH?: number,
): EnqueuedPointTime => {
  if (!prev) {
    return {
      ...curr,
      speed: 0,
      bearingToMe: 0,
      distanceToMe: 0,
      prevLat: undefined,
      prevLng: undefined,
      timeToMe: 0,
    };
  }
  let timeToMe = curr.utc - prev.utc;
  let speed = timeToMe !== 0 ? distanceToMe / timeToMe : 0;
  if (minSpeedMPH !== undefined) {
    const minSpeed = mphToMetersPerMilli(minSpeedMPH);
    speed = Math.max(speed, minSpeed);
  }
  if (maxSpeedMPH !== undefined) {
    const maxSpeed = mphToMetersPerMilli(maxSpeedMPH);
    speed = Math.min(speed, maxSpeed);
  }
  timeToMe = distanceToMe / speed;
  return {
    ...curr,
    speed,
    bearingToMe,
    distanceToMe,
    timeToMe,
    prevLat: prev.lat,
    prevLng: prev.lng,
  };
};
