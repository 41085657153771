import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getActivityLogsFailed,
  getActivityLogsRequested,
  getActivityLogsSuccess,
  getActivityTypesFailed,
  getActivityTypesRequested,
  getActivityTypesSuccess,
  getPendingTimesheetsCountByUser,
  getPendingTimesheetsCountByUserFailed,
  getPendingTimesheetsCountByUserOnRefreshList,
  getPendingTimesheetsCountByUserOnUpdate,
  getPendingTimesheetsCountByUserSuccess,
  loadActivityActions,
  loadActivityFailed,
  loadActivitySuccess,
} from './actions';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { TimesheetEntryDataApiService } from './timesheet-entry-data-api.service';
import { WpError } from '@rootTypes';
import { of } from 'rxjs';
import { GetActivityProjection, GetActivityRequest } from '../../api/endpoints/get-activity';

@Injectable()
export class TimesheetEntryDataEffects {
  constructor(
    private actions$: Actions,
    private api: TimesheetEntryDataApiService,
  ) {}

  public loadActivityRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...loadActivityActions),
      switchMap(({ activityId, options }) => {
        const request: GetActivityRequest = { activityId };
        const projections: GetActivityProjection[] = [];
        if (options?.withDriverBusReport) {
          projections.push('DRIVER_BUS_REPORT');
        }
        if (projections.length) {
          request._projections = projections;
        }
        return this.api.getActivity(request).pipe(
          map((resp) => {
            if (resp._projections && resp._projections.DRIVER_BUS_REPORT) {
              return loadActivitySuccess({
                activity: resp.activity,
                driverBusReport: resp._projections.DRIVER_BUS_REPORT,
              });
            }
            return loadActivitySuccess({ activity: resp.activity });
          }),
          catchError((originalError) => {
            console.error(originalError);
            const error: WpError = {
              text: 'Failed to load activity',
              originalError,
            };
            return of(loadActivityFailed({ activityId, error }));
          }),
        );
      }),
    ),
  );

  public getPendingRequestsCountByUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        getPendingTimesheetsCountByUser,
        getPendingTimesheetsCountByUserOnUpdate,
        getPendingTimesheetsCountByUserOnRefreshList,
      ),
      switchMap(({ entityId, entityType }) => {
        return this.api.getPendingRequestsCountByUser(entityId, entityType).pipe(
          map((count) => getPendingTimesheetsCountByUserSuccess({ entityId, entityType, count })),
          catchError((originalError) => {
            console.error(originalError);
            const error: WpError = {
              text: 'Failed to load pending entries count',
              originalError,
            };
            return of(getPendingTimesheetsCountByUserFailed({ entityId, entityType, error }));
          }),
        );
      }),
    ),
  );

  public getActivityLogsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getActivityLogsRequested),
      switchMap(({ activityId }) => {
        return this.api.getActivityLogs(activityId).pipe(
          map((resp) => getActivityLogsSuccess({ activityId, logs: resp?.activityLog || [] })),
          catchError((originalError) => {
            console.error(originalError);
            const error: WpError = {
              originalError,
              text: 'Failed to get activity logs',
            };
            return of(getActivityLogsFailed({ activityId, error }));
          }),
        );
      }),
    ),
  );

  public getActivityTypesRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getActivityTypesRequested),
      debounceTime(200),
      switchMap(() => {
        return this.api.getActivityTypes().pipe(
          map((resp) => getActivityTypesSuccess({ activityTypes: resp })),
          catchError((originalError) => {
            console.log(originalError);
            const error = {
              originalError,
              text: 'Failed to get activity types',
            };
            return of(getActivityTypesFailed({ error }));
          }),
        );
      }),
    ),
  );
}
