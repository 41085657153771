import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { ChangePasswordPreviewFacade } from '../../store';

@Component({
  selector: 'wp-user-profile-change-password-preview',
  templateUrl: './user-profile-change-password-preview.component.html',
  styleUrls: ['./user-profile-change-password-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ChangePasswordPreviewFacade],
})
export class UserProfileChangePasswordPreviewComponent implements OnInit {
  @Input() public disabled: boolean;

  public isJustUpdated$: Observable<boolean>;

  constructor(private facade: ChangePasswordPreviewFacade) {}

  public ngOnInit(): void {
    this.isJustUpdated$ = this.facade.getIsJustUpdated$();
  }

  public onChangePassword(): void {
    if (!this.disabled) {
      this.facade.goChangePassword();
    }
  }
}
