import { getIndexedDbWrap, IndexDbConfig, IndexedDbWrap } from './indexed-db-wrap';
import { ServerRequestCacheItem } from './tables';

/**
 * Adding new tables:
 * 1. Update table names with the new table name.
 * 2. Update DatabaseStructure with the types of the data to be found in the new table.
 * 3. Update portalIndexDbConfig with table options (do not forget to increment database version).
 */

export enum IndexDbTableNames {
  server_requests_cache = 'server_requests_cache',
}

export type IndexDbDatabaseStructure = {
  [IndexDbTableNames.server_requests_cache]: ServerRequestCacheItem;
};

/**
 * Increment version on each update to table structure
 */
const portalIndexDbConfig: IndexDbConfig<IndexDbDatabaseStructure> = {
  version: wpEnvironment.indexedDbVersion,
  dbName: 'zum_portal_db',
  tables: {
    [IndexDbTableNames.server_requests_cache]: {
      tableName: IndexDbTableNames.server_requests_cache,
      options: {
        indexes: {
          lastUsedTimestamp: { unique: false },
        },
      },
    }, // eof server_requests_cache
  },
};

export const getPortalIndexedDbWrap = (): Promise<IndexedDbWrap<IndexDbDatabaseStructure>> => {
  return getIndexedDbWrap<IndexDbDatabaseStructure>(portalIndexDbConfig);
};
