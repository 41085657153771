import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { NavigationRequest } from '../../types/navigation-request';
import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';
import { ActivityListQueryParams } from '../../../features/common/timesheet-entry-list/dependencies/types/activity-list-query-params';

export type ActivityListRouteData = ActivityListQueryParams;

export const mainActivityListRoute: PortalRoute<ActivityListRouteData> = {
  path: 'activities',
  isOnRoute: (state: RouterStateUrl): boolean => {
    return state.url.startsWith('/activities/list');
  },
  parse: (state: RouterStateUrl): ActivityListRouteData => {
    let data: ActivityListRouteData = {};
    if (state.queryParams) {
      data = {
        ...state.queryParams,
      };
    }
    return data;
  },
  request: (data: ActivityListRouteData = {}, queryParamStrategy?: QueryParamsHandlingStrategy): NavigationRequest => {
    const req: NavigationRequest = {
      path: ['/activities', 'list'],
    };
    const query = { ...data };
    if (Object.keys(query).length) {
      req.query = query;
    }
    if (queryParamStrategy) {
      req.extras = {
        queryParamsHandling: queryParamStrategy,
      };
    }
    return req;
  },
  getHref(data?: ActivityListRouteData): string {
    return '/activities/list';
  },
};

@Injectable({ providedIn: 'root' })
export class MainActivityListRouterService extends PortalRouterService<ActivityListRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route: PortalRoute<ActivityListRouteData> = mainActivityListRoute;
}
