<mat-form-field [hideRequiredMarker]="true" class="full-width">
  <mat-label>{{label}}</mat-label>
  <input
    type="text"
    matInput
    [formControl]="control"
    [autocomplete]="autocompleteValue"
    [matAutocomplete]="autocomplete"
    (click)="$event.stopPropagation()"
  />
  <div
    *ngIf="control?.value && !control.disabled"
    matSuffix
    (click)="onInputClear()"
  >
    <wp-icon-close [size]="'xsmall'"></wp-icon-close>
  </div>
  <mat-autocomplete
    #autocomplete="matAutocomplete"
    [displayWith]="displayFn"
    (optionSelected)="onOptionSelect($event)"
  >
    <mat-option
      *ngFor="let option of filteredOptions$ | async"
      [value]="option"
    >
      {{option.displayLabel}}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="control?.errors && control?.getError('required')">
    Required field
  </mat-error>
  <mat-error *ngIf="control?.errors && control?.getError('selectFromList')">
    Please select value from the list
  </mat-error>
</mat-form-field>
