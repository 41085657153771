import { Pipe, PipeTransform } from '@angular/core';
import { Weekday } from '@rootTypes';
import { getShorterWeekday } from '@rootTypes/utils/common/date';

@Pipe({
  name: 'displayWeekdaysWithDashes',
})
export class DisplayWeekdaysWithDashesPipe implements PipeTransform {
  transform(value: any[]): string {
    if (!value?.length) {
      return '--';
    }
    return value.map((w) => getShorterWeekday(w as Weekday)).join('/');
  }
}
