import * as fromRouter from '@ngrx/router-store';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromCustom from './custom-serializer';
import * as fromAuthReducer from '../../auth/store/reducers';
import * as fromErrorReducer from './error.reducer';
import * as fromHomeUI from './home-ui.reducer';
import { ErrorState } from '@rootTypes';
import { multiAccountManagementFeatureKey } from '../../auth/store/selectors/multi-account-management.selectors';

export interface State {
  router: fromRouter.RouterReducerState;
  auth: fromAuthReducer.AuthState;
  account: fromAuthReducer.AccountState;
  [multiAccountManagementFeatureKey]: fromAuthReducer.MultiAccountManagementState;
  error: ErrorState;
  ui: fromHomeUI.HomeUIState;
}

export const reducers: ActionReducerMap<State> = {
  router: fromRouter.routerReducer,
  auth: fromAuthReducer.authReducer,
  [multiAccountManagementFeatureKey]: fromAuthReducer.multiAccountManagementReducer,
  account: fromAuthReducer.accountReducer,
  error: fromErrorReducer.errorReducer,
  ui: fromHomeUI.homeUIReducer,
};

export const getRouterState = createFeatureSelector<fromRouter.RouterReducerState<fromCustom.RouterStateUrl>>('router');

export * from './custom-serializer';
