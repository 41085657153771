import { NationalHoliday } from '@rootTypes';
import * as fromRoot from '@rootTypes';
import { GetUSNationalHolidaysResponse } from '../response';

export const getNationalHolidaysFromDb = (source: GetUSNationalHolidaysResponse): NationalHoliday[] => {
  if (!source) {
    return [];
  }
  return Object.keys(source)
    .map((key) => source[key])
    .map((sourceItem) => {
      const yyyyMMDD = new fromRoot.utils.date.DisplayDateTime(sourceItem.date).toYearMonthDay();
      const res: NationalHoliday = {
        id: '' + sourceItem.date,
        date: yyyyMMDD,
        labelShort: sourceItem.display_name,
        labelLong: sourceItem.display_summary,
      };
      return res;
    });
};
