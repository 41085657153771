import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CharterLoginService } from '../../../api/services/charter-login.service';
import { commonRoutes } from '@router';

@Component({
  selector: 'wp-charter-login',
  templateUrl: './charter-login.component.html',
  styleUrls: ['./charter-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CharterLoginComponent implements OnInit {
  constructor(
    private charterLoginService: CharterLoginService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  public async ngOnInit(): Promise<void> {
    const target = this.activatedRoute.snapshot.queryParams.target;
    const { isSuccess } = await this.charterLoginService.loginToCharter(false, target);
    if (!isSuccess) {
      await this.router.navigateByUrl(commonRoutes.home.url);
    }
  }
}
