<div
  *ngIf="type === typeOptions.CAR"
  class="wrap"
  [ngClass]="{'background-white': background === 'white'}"
  [ngStyle]="{'border': borderStyle}"
>
  <img
    src="assets/icons/photo-placeholders/car.svg"
    width="80%"
    height="auto"
  />
</div>

<div *ngIf="type === typeOptions.PERSON && background === 'grey'" class="wrap">
  <img
    src="assets/icons/photo-placeholders/person.svg"
    width="80%"
    height="auto"
  />
</div>

<div
  *ngIf="type === typeOptions.PERSON && background === 'white'"
  class="wrap background-white round"
  [ngStyle]="{'border': borderStyle}"
>
  <img
    src="assets/icons/photo-placeholders/person-grey.svg"
    width="40px"
    height="auto"
  />
</div>

<div *ngIf="type === typeOptions.BUILDING" class="wrap">
  <img
    class="building-icon"
    src="assets/icons/photo-placeholders/building.svg"
    width="65%"
    height="auto"
  />
</div>

<div *ngIf="type === typeOptions.DISTRICT" class="wrap">
  <img
    class="building-icon"
    src="assets/icons/photo-placeholders/district.svg"
    width="80%"
    height="auto"
  />
</div>

<div *ngIf="type === typeOptions.DOCUMENT" class="wrap">
  <img
    src="assets/icons/photo-placeholders/document.svg"
    width="100%"
    height="auto"
    [ngStyle]="{width: width}"
  />
</div>

<div *ngIf="type === typeOptions.CAMPUS" class="wrap">
  <img
    src="assets/icons/photo-placeholders/campus.svg"
    width="100%"
    height="auto"
  />
</div>
