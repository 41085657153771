import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {
  SmartAddressWithExactLocation,
  SmartAddressWithExactLocationDataLoaderService,
} from '../../models/smart-address-with-exact-location';
import { checkNotNull } from '@rootTypes/utils';
import { Observable } from 'rxjs';
import { WpError } from '@rootTypes';
import { AddressLocationType, ExactLocationValue } from '@apiEntities';

@Component({
  selector: 'wp-smart-address-with-exact-location',
  templateUrl: './smart-address-with-exact-location.component.html',
  styleUrls: ['./smart-address-with-exact-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartAddressWithExactLocationComponent implements OnInit {
  @Input() public model: SmartAddressWithExactLocation;
  @Input() public isCompactView: boolean;

  public isMapLoading$: Observable<boolean>;
  public exactValue$: Observable<ExactLocationValue>;
  public isExactLocation$: Observable<boolean>;

  public isExactLocationValueLoading$: Observable<boolean>;
  public exactLocationValueLoadError$: Observable<WpError>;
  public selectedLocationType$: Observable<AddressLocationType>;

  constructor(private dataLoaderService: SmartAddressWithExactLocationDataLoaderService) {}

  ngOnInit(): void {
    checkNotNull(this.model, 'Smart input model cannot be null');
    this.isMapLoading$ = this.model.isMapLoading$;
    this.model.setDataLoader(this.dataLoaderService.getDataLoader());
    this.exactValue$ = this.model.exactHomeLocationLoadedValue$();
    this.isExactLocation$ = this.model.isExactLocation$();
    this.isExactLocationValueLoading$ = this.model.isExactLocationValueLoading$.asObservable();
    this.exactLocationValueLoadError$ = this.model.isExactLocationValueLoadError$.asObservable();
    this.selectedLocationType$ = this.model.selectedLocationType$;
  }

  public onRemoveExactLocation(): void {
    this.model.removeExactLocation();
  }
}
