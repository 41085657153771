import { EntityState } from '@rootTypes';
import { GetCatalogDisplayInfoResponse } from '../../../../api/endpoints/get-catalog-display-info';
import { createReducer, on } from '@ngrx/store';
import {
  getCatalogDisplayInfoFailed,
  getCatalogDisplayInfoRequested,
  getCatalogDisplayInfoSuccess,
} from '../actions/catalog-display-info.actions';

export interface CatalogDisplayInfoState {
  [catalogId: string]: EntityState<GetCatalogDisplayInfoResponse>;
}

export const catalogDisplayInfoReducer = createReducer<CatalogDisplayInfoState>(
  {},
  on(getCatalogDisplayInfoRequested, (state, { catalogId }) => {
    const prevState = state[catalogId] || {};
    return {
      ...state,
      [catalogId]: {
        ...prevState,
        isLoading: true,
      },
    };
  }),
  on(getCatalogDisplayInfoSuccess, (state, { catalogId, response }) => {
    const prevState = state[catalogId] || {};
    return {
      ...state,
      [catalogId]: {
        ...prevState,
        isLoading: true,
        entity: response,
      },
    };
  }),
  on(getCatalogDisplayInfoFailed, (state, { catalogId, error }) => {
    const prevState = state[catalogId] || {};
    return {
      ...state,
      [catalogId]: {
        ...prevState,
        isLoading: true,
        error,
      },
    };
  }),
);
