import { createAction, props } from '@ngrx/store';
import { Vendor, WpError } from '../../types';

const moduleName = 'Data';
const submoduleName = 'Vendors';

export const loadVendorRequested = createAction(
  `[${moduleName}] [${submoduleName}]: Load vendor requested`,
  props<{ vendorId: string }>(),
);
export const lazyLoadVendorRequested = createAction(
  `[${moduleName}] [${submoduleName}]: Lazy load vendor requested`,
  props<{ vendorId: string }>(),
);

export const loadVendorSuccess = createAction(
  `[${moduleName}] [${submoduleName}]: Load vendor success`,
  props<{ vendorId: string; vendor: Vendor }>(),
);

export const loadVendorFailed = createAction(
  `[${moduleName}] [${submoduleName}]: Load vendor failed`,
  props<{ vendorId: string; error: WpError }>(),
);
