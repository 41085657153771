<svg
  class="icon"
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <path
    fill="none"
    fill-rule="evenodd"
    stroke="#959597"
    stroke-width="2"
    d="M17.634 18.155L6.348 6.217l11.286 11.938C18.184 17.452 19.04 17 20 17c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3c0-.696.237-1.336.634-1.845zM4 7C2.343 7 1 5.657 1 4s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"
  />
</svg>
