import { Pipe, PipeTransform } from '@angular/core';
import { driverGenderToDisplay } from 'src/app/types/utils/driver';

@Pipe({
  name: 'displayDriverGender',
})
export class DisplayDriverGenderPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '--';
    }
    return driverGenderToDisplay(value);
  }
}
