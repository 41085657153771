<div
  class="y-widget-menu__contents"
  tabindex="0"
  [ngClass]="{
          'open': isOpen$ | async,
          'half-drawer': isHalfDrawer$ | async,
      }"
  (keydown.escape)="onClose()"
>
  <router-outlet name="drawer"></router-outlet>
</div>

<div *ngIf="isOpen$ | async" class="y-widget-menu__page-mask" (click)="onMaskClicked()"></div>
