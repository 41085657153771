import { RideTimetable } from '../entities';
import { RideListSnapshot } from '../ride-list-snapshot';
import { SerializedRideListSnapshot } from '../serialized-snapshot';
import { DEFAULT_PAGE_SIZE } from '../ui';
import { filterStrToRidesFilters } from './ride-filter-utils';

export const deserializeSnapshot = (source: SerializedRideListSnapshot): RideListSnapshot => {
  const target: RideListSnapshot = {
    currentPage: (source.currentPage ? parseInt(source.currentPage, 10) : 1) - 1 || 0,
    pageSize: (source.pageSize ? parseInt(source.pageSize, 10) : DEFAULT_PAGE_SIZE) || DEFAULT_PAGE_SIZE,
    timetable: source.timetable as RideTimetable,
    dynamicFilters: filterStrToRidesFilters(source.filters),
    staticFilters: filterStrToRidesFilters(source.staticFilters),
    accountId: source.accountId,
  };
  return target;
};
