import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { DistrictApiService } from '../../services/districts-api.service';
import * as fromActions from '../actions';
import { ApiService } from '../../../../api/api.service';

@Injectable()
export class DistrictDataEffects {
  constructor(
    private districtApiService: DistrictApiService,
    private actions$: Actions,
    private api: ApiService,
  ) {}

  public loadDistrictRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.loadDistrictRequested,
        fromActions.loadDistrictRequestedOnCreateCampusDistrictChange,
        fromActions.loadDistrictRequestedOnInitCampusCalendarEditor,
        fromActions.loadDistrictRequestedFromStudentDetails,
      ),
      mergeMap(({ districtId, options }) => {
        return this.districtApiService.getDistrict(districtId, options).pipe(
          map((payload) =>
            fromActions.loadDistrictSuccess({
              districtId,
              payload,
            }),
          ),
          catchError((err) => {
            console.log(err);
            return of(
              fromActions.loadDistrictFailed({
                districtId,
                error: {
                  text: 'Failed to load district',
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  /**
   * Load district checks
   */
  public loadAllDistrictChecksRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAllDistrictChecksRequested),
      switchMap(() => {
        return this.districtApiService.getChecksForDistrict().pipe(
          map((checks) => fromActions.loadAllDistrictChecksSuccess({ checks })),
          catchError((err) => {
            console.log(err);
            return of(
              fromActions.loadAllDistrictChecksFailed({
                error: { text: 'Failed to load district checks', originalError: err },
              }),
            );
          }),
        );
      }),
    ),
  );

  // public getBusAddressesRequested$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromActions.getBusAddressesRequested),
  //     switchMap(({ districtId }) => {
  //       return this.api.getBusAddresses({ districtId }).pipe(
  //         map(({ addresses }) => fromActions.getBusAddressesSuccess({ districtId, addresses })),
  //         catchError((originalError) => {
  //           console.error(originalError);
  //           const error: WpError = {
  //             text: 'Failed to load district bus addresses',
  //             originalError,
  //           };
  //           return of(fromActions.getBusAddressesFailed({ error, districtId }));
  //         }),
  //       );
  //     }),
  //   ),
  // );
}
