import { createSelector } from '@ngrx/store';
import { DistrictDataState } from '../reducers/district-data.reducer';
import { getFeature } from './district-data-feature.selector';

const getDistrictDataFeature = createSelector(getFeature, (state) => state.data);

export const getAllDistrictsState = createSelector(getDistrictDataFeature, (state) => state.districts);

type DistrictEntity = DistrictDataState['districts'][string];

const getSingleDistrictState = (districtId) =>
  createSelector(getAllDistrictsState, (state): DistrictEntity => state[districtId] || null);

export const getDistrict = (districtId) =>
  createSelector(getSingleDistrictState(districtId), (state) => (state ? state.entity : null));

export const getDistrictLoading = (districtId) =>
  createSelector(getSingleDistrictState(districtId), (state) => (state ? state.isLoading : false));

export const getDistrictError = (districtId) =>
  createSelector(getSingleDistrictState(districtId), (state) => (state ? state.error : null));

export const getAllCalendars = createSelector(getDistrictDataFeature, (state) => state.calendars);

export const getCalendarForEnterprise = createSelector(
  getAllCalendars,
  (allCalendars, props: { districtId: string }) => {
    const { districtId } = props;
    if (!(allCalendars[districtId] && allCalendars[districtId].entity)) {
      return null;
    }
    return allCalendars[districtId].entity;
  },
);

export const getCalendarForEnterpriseLoading = createSelector(
  getAllCalendars,
  (allCalendars, props: { districtId: string }) => {
    const { districtId } = props;
    if (!allCalendars[districtId]) {
      return false;
    }
    return allCalendars[districtId].isLoading;
  },
);

export const getCalendarForEnterpriseError = createSelector(
  getAllCalendars,
  (allCalendars, props: { districtId: string }) => {
    const { districtId } = props;
    if (!(allCalendars[districtId] && allCalendars[districtId].entity)) {
      return null;
    }
    return allCalendars[districtId].error;
  },
);

/**
 * District checks
 */
export const getDistrictChecksState = createSelector(getDistrictDataFeature, (state) => state.allDistrictChecks);

export const getAllDistrictChecks = createSelector(getDistrictChecksState, (state) => state.entity);

export const getAllDistrictChecksLoading = createSelector(getDistrictChecksState, (state) => state.isLoading);

export const getAllDistrictChecksError = createSelector(getDistrictChecksState, (state) => state.error);
