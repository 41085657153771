import * as fromTypes from '../../types';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from '../actions/yard-details.actions';

export interface YardDetailsState {
  yardId: string | undefined;
  tab: fromTypes.YardDetailsTab;
}

export const initialVendorDetailsState: YardDetailsState = {
  yardId: undefined,
  tab: undefined,
};

export const vendorDetailsReducer = createReducer<YardDetailsState>(
  { ...initialVendorDetailsState },
  on(fromTypes.cleanUpStore, () => ({ ...initialVendorDetailsState })),
  on(fromActions.setYardId, (state, action) => {
    const { yardId } = action;
    const res: YardDetailsState = {
      ...state,
      yardId,
    };
    return res;
  }),
  on(fromActions.setTab, (state, action) => {
    const { tab } = action;
    const res: YardDetailsState = {
      ...state,
      tab,
    };
    return res;
  }),
);
