import { Injectable } from '@angular/core';
import * as fromTypes from '../types';
import { CampusDetailsFacade } from '../store/facades/campus-details/campus-details.facade';
import { LocationsTabFacade } from '../store/facades/campus-details/locations-tab.facade';
import { Observable } from 'rxjs';

@Injectable()
export class CampusLocationsMapService extends fromTypes.utils.AbstractLocationsMapService {
  campus$: Observable<fromTypes.Campus>;
  locations$: Observable<fromTypes.CampusLocation[]>;
  selectedLocationId$: Observable<string>;
  clickCallback;

  constructor(
    private campusDetailsFacade: CampusDetailsFacade,
    private campusLocationsFacade: LocationsTabFacade,
  ) {
    super();
    this.campus$ = campusDetailsFacade.getCampus$();
    this.locations$ = campusLocationsFacade.getLocations$();
    this.selectedLocationId$ = campusLocationsFacade.getSelectedLocationId$();
    this.clickCallback = (locationId) => this.campusLocationsFacade.onSetSelectedLocation(locationId);
    super.init();
  }
}
