import * as fromData from './district-data.reducer';
import * as fromCalendarEditor from './add-edit-calendar.reducer';
import * as fromDistrictEditor from './add-edit-district.reducer';
import { combineReducers } from '@ngrx/store';

export interface FeatureState {
  data: fromData.DistrictDataState;
  editCalendar: fromCalendarEditor.AddEditCalendarState;
  editDistrict: fromDistrictEditor.AddEditDistrictState;
}

export const featureReducer = combineReducers<FeatureState>({
  data: fromData.districtDataReducer,
  editCalendar: fromCalendarEditor.addEditCalendarReducer,
  editDistrict: fromDistrictEditor.addEditDistrictReducer,
});
