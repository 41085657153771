import { createAction, props } from '@ngrx/store';
import { Yard, WpError, LoadYardOptions, Vendor } from '../../types';

const moduleName = 'Data';
const submoduleName = 'Yards';

export const loadYardRequested = createAction(
  `[${moduleName}] [${submoduleName}]: Load yard requested`,
  props<{ yardId: string; options?: LoadYardOptions }>(),
);
export const lazyLoadYardRequested = createAction(
  `[${moduleName}] [${submoduleName}]: Lazy load yard requested`,
  props<{ yardId: string }>(),
);

export const loadYardSuccess = createAction(
  `[${moduleName}] [${submoduleName}]: Load yard success`,
  props<{ yardId: string; yard: Yard; vendor?: Vendor }>(),
);

export const loadYardFailed = createAction(
  `[${moduleName}] [${submoduleName}]: Load yard failed`,
  props<{ yardId: string; error: WpError }>(),
);
