import { YYYYMMDDString } from '../../entities';
import { ApiAddress } from '../../endpoints/common';
import { StudentTransportationStatus } from '../../entities/student-change-requests/student-transportation-status';
import { StudentTransportationCancelReason } from '../../entities/student-change-requests/student-transportation-cancel-reason';
import { AddressWithExactLocationValue } from '../../../shared/smart-forms/models/smart-address-with-exact-location';
import { StudentProfile } from '@rootTypes/entities/students/student-profile';

export enum SmartFormFieldType {
  STRING = 'string',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  ADDRESS = 'address',
  CHECKBOXES = 'checkboxes',
  DATE = 'YYYYMMDD',
  DATE_RANGE = 'daterange',
  TRANSPORTATION_PROVIDER = 'transportationProvider',
  SELECT = 'select',
  STUDENT_SCHOOL_INFO = 'school',
  STUDENT_TRANSPORTATION_STATUS = 'studentTransportationStatus',
  IMAGE = 'image',
  ADDRESS_LOCATION = 'addressLocation',
  HOME_ADDRESS = 'homeAddress',
}

export type SmartFormField =
  | SmartFormFieldString
  | SmartFormFieldEmail
  | SmartFormFieldPhoneNumber
  | SmartFormFieldAddress
  | SmartFormFieldCheckboxes
  | SmartFormFieldDate
  | SmartFormFieldDateRange
  | SmartFormFieldTransportationProvider
  | SmartFormFieldSelect
  | SmartFormFieldStudentSchoolInfo
  | SmartFormFieldStudentTransportationStatus
  | SmartFormFieldImage
  | SmartFormFieldAddressLocation
  | SmartFormFieldHomeAddress;

export type SmartFormFieldString = {
  type: SmartFormFieldType.STRING;
  value?: string;
  isMultiline: boolean;
};

export type SmartFormFieldEmail = {
  type: SmartFormFieldType.EMAIL;
  value?: string;
};

export type SmartFormFieldPhoneNumber = {
  type: SmartFormFieldType.PHONE_NUMBER;
  value?: string;
};

export type SmartFormFieldAddress = {
  type: SmartFormFieldType.ADDRESS;
  value?: ApiAddress;
};

export type SmartFormFieldCheckboxes = {
  type: SmartFormFieldType.CHECKBOXES;
  values: SmartFormFieldCheckbox[];
};

export type SmartFormFieldCheckbox = {
  name: string; // API value
  isChecked: boolean;
  checkedLabel: string;
  uncheckedLabel?: string;
};

export type SmartFormFieldDate = {
  type: SmartFormFieldType.DATE;
  value?: YYYYMMDDString;
};

export type SmartFormFieldDateRange = {
  type: SmartFormFieldType.DATE_RANGE;
  from: SmartFormFieldDateRangeItem;
  to: SmartFormFieldDateRangeItem;
};
export type SmartFormFieldDateRangeItem = {
  label: string;
  name: string;
  isEditable: boolean;
  value?: YYYYMMDDString;
};

export type SmartFormFieldImage = {
  type: SmartFormFieldType.IMAGE;
  value: string; // image path
};

export type SmartFormFieldTransportationProvider = {
  type: SmartFormFieldType.TRANSPORTATION_PROVIDER;
  vendorLabel?: string;
  yardLabel?: string;
  value?: SmartFormFieldTransportationProviderValue;
};
export type SmartFormFieldTransportationProviderValue = {
  vendor: {
    id: string;
    name: string;
  } | null; // null means delete
  yard: {
    id: string;
    name: string;
  } | null;
};

export type SmartFormFieldSelect = {
  type: SmartFormFieldType.SELECT;
  lookup: {
    entitySearch?: {
      params?: any; // filter
      type: any; // entity types example campus | customer etc
      // entityIdProp: string; // example 'driverId'  or 'studentId' (to read id from entity filter results )
    };
    fixed?: {
      label?: string; // if omitted FE will use value as label
      value: string;
    }[];
  };
  selected?: {
    label?: string; // if omitted FE will display value as is.
    value: string;
  };
  label: string;
};

export type SmartFormFieldStudentSchoolInfo = {
  type: SmartFormFieldType.STUDENT_SCHOOL_INFO;
  districtLabel?: string;
  campusLabel?: string;
  gradeLabel?: string;
  schoolYearLabel?: string;
  value?: SmartFormFieldStudentSchoolInfoValue;
};
export type SmartFormFieldStudentSchoolInfoValue = {
  district: {
    id: string;
    name: string;
  };
  // could be omitted if it was not changed by student change request
  campus?: {
    id: string;
    name: string;
  };
  grade?: {
    id: string;
    label: string;
  };
  schoolYear?: {
    value: string;
  };
};

export type SmartFormFieldStudentTransportationStatus = {
  type: SmartFormFieldType.STUDENT_TRANSPORTATION_STATUS;
  status: StudentTransportationStatus;
  cancelTransportationDetails?: SmartFormFieldStudentTransportationCancelDetails;
};
export type SmartFormFieldStudentTransportationCancelDetails = {
  reason: StudentTransportationCancelReason;
  effectiveDate: YYYYMMDDString;
  comment?: string;
};

export type SmartFormFieldAddressLocation = {
  type: SmartFormFieldType.ADDRESS_LOCATION;
  value: AddressWithExactLocationValue;
};

export type SmartFormFieldHomeAddress = {
  type: SmartFormFieldType.HOME_ADDRESS;
  value: StudentProfile['homeAddress'];
};
