import { merge, Observable, Subscription } from 'rxjs';

import { SmartSelect, SmartSelectValue } from './smart-select';
import { PortalEntityType } from '../dependencies';
import { SmartInputModel } from './smart-input-model';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { EntityFilterOptions } from '../../../api/endpoints/entity-filter';

export interface SmartInputSchoolConfig {
  districtLabel?: string; // defaults to 'District'
  campusLabel?: string; // defaults to 'School campus'
  value?: SmartInputSchoolValueInitial;
  required?: boolean;
  disabled?: boolean;
  disableCampusUntilDistrictIsSelected?: boolean;
  campusEntityFilterOptions?: EntityFilterOptions;
}

export interface SmartInputSchoolValueInitial {
  district: SmartInputSchoolEntity;
  campus?: SmartInputSchoolEntity;
}

export interface SmartInputSchoolValue {
  district: SmartInputSchoolEntity;
  campus: SmartInputSchoolEntity;
}

export interface SmartInputSchoolEntity {
  id: string;
  label: string;
}

export class SmartInputSchool implements SmartInputModel {
  public district: SmartSelect;
  public campus: SmartSelect;

  private sub = new Subscription();
  private disableCampusUntilDistrictIsSelected: boolean;
  private campusEntityFilterOptions: EntityFilterOptions;

  constructor(config: SmartInputSchoolConfig) {
    this.campusEntityFilterOptions = config.campusEntityFilterOptions;
    this.disableCampusUntilDistrictIsSelected = config.disableCampusUntilDistrictIsSelected || false;
    this.district = new SmartSelect({
      label: config.districtLabel || 'District',
      value: config.value?.district,
      required: config.required,
      disabled: config.disabled,
      lookup: {
        entitySearch: {
          type: PortalEntityType.DISTRICT,
        },
      },
    });

    this.campus = new SmartSelect({
      label: config.campusLabel || 'School campus',
      value: config.value?.campus,
      required: config.required,
      disabled: config.disabled,
      lookup: {
        entitySearch: {
          params: {
            districtIds: config.value?.district ? [config.value?.district.id] : [],
            ...(config.campusEntityFilterOptions || {}),
          },
          type: PortalEntityType.CAMPUS,
        },
      },
    });
  }

  public getValue(): SmartInputSchoolValue | undefined {
    const district = this.district.getValue();
    const campus = this.campus.getValue();
    if (district && campus) {
      return {
        district: {
          id: district.id,
          label: district.label,
        },
        campus: {
          id: campus.id,
          label: campus.label,
        },
      };
    }
    return undefined;
  }

  public isValid(): boolean {
    return this.district.isValid() && this.campus.isValid();
  }

  public showErrorIfAny(): void {
    this.district.showErrorIfAny();
    this.campus.showErrorIfAny();
  }

  public hasChanges(): boolean {
    return this.district.hasChanges() || this.campus.hasChanges();
  }

  public setValue(value: SmartInputSchoolValue): void {
    this.sub.unsubscribe();
    this.district.setValue(value.district);
    const newCampusSearchByDistrictParams = value?.district?.id ? [value?.district?.id] : [];
    this.campus.setEntitySearchParams({
      districtIds: newCampusSearchByDistrictParams,
      ...(this.campusEntityFilterOptions || {}),
    });
    this.campus.setValue(value.campus);
    this.setSubscriptions();
  }

  public setDisabled(disabled: boolean): void {
    this.sub.unsubscribe();
    this.district.setDisabled(disabled);
    this.campus.setDisabled(disabled);
    this.setSubscriptions();
  }

  public onComponentInit(): void {
    if (this.disableCampusUntilDistrictIsSelected) {
      this.campus.setDisabled(!this.district.getValue());
    }
    this.setSubscriptions();
  }

  public onComponentDestroy(): void {
    this.sub.unsubscribe();
  }

  public getValueChanges(): Observable<SmartInputSchoolValue | undefined> {
    return merge([this.district.getValueChanges(), this.campus.getValueChanges()]).pipe(
      map(() => {
        return this.getValue();
      }),
    );
  }

  private setSubscriptions(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = new Subscription();
    const districtChangeSub = this.district.control.valueChanges
      .pipe(distinctUntilChanged((prev, curr) => prev?.id === curr?.id))
      .subscribe((district: SmartSelectValue) => {
        const newDistrictId = district?.id;
        const currentCampusSearchByDistrictIds = this.campus.getEntitySearchParams().districtIds as string[];
        const currentCampusSearchByDistrictId =
          currentCampusSearchByDistrictIds && currentCampusSearchByDistrictIds.length
            ? currentCampusSearchByDistrictIds[0]
            : undefined;
        if (newDistrictId !== currentCampusSearchByDistrictId) {
          const districtIds = newDistrictId ? [newDistrictId] : [];
          this.campus.setEntitySearchParams({ districtIds, ...(this.campusEntityFilterOptions || {}) });
          this.campus.setValue(undefined);
        }
        if (this.disableCampusUntilDistrictIsSelected) {
          this.campus.setDisabled(!newDistrictId);
        }
      });
    this.sub.add(districtChangeSub);
  }
}
