import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-text-divider-vertical-line',
  templateUrl: './text-divider-vertical-line.component.html',
  styleUrls: ['./text-divider-vertical-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextDividerVerticalLineComponent {
  @Input() public containerHeight = '20px';
  @Input() public height = '14px';
  @Input() public margin: string;
  @Input() public color: 'silver' | 'grey' | 'haze' = 'silver';
}
