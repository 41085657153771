import { ComponentStore } from '@ngrx/component-store';
import { Injectable } from '@angular/core';
import { GotoOption, HardCodedGotoOption, LocallyStoredGotoOption } from './types/goto-option';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { debounceTime, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { tapResponse } from '@ngrx/operators';
import { WpError } from '@rootTypes';
import { getHardCodedGotoOptions } from './utils/get-hard-coded-goto-options';
import { ApiService } from '../../api/api.service';
import { CrossEntitySearchRequestType } from '../../api/endpoints/entity-search';
import { EntitySearchResult } from '@apiEntities/entity-search';
import { Store } from '@ngrx/store';
import { authStatus } from '../../auth/store';
import { getEntitySearchResultToDestinationMap } from './utils/get-entity-search-result-to-destination-map';
import { Router } from '@angular/router';
import { LocalStorageService } from '../services';
import { isPermissionGivenForAccount } from '../../auth/store/selectors/portal-permissions.selectors';
import { PortalRouterService } from '../../router/types/portal-router-service';

interface GotoPageServiceState {
  isGoToPageOpen: boolean;
  searchQuery: string | null;
  hardCodedGotoOptions: HardCodedGotoOption[];
  locallyStoredOptions: HardCodedGotoOption[];
  options: {
    isLoading: boolean;
    data: GotoOption[];
    error: WpError;
  };
  isNavigating: boolean;
}

const STORAGE_GOTO_OPTIONS_KEY = 'gotoRecentlyViewedPages';
const MAX_HISTORY_LENGTH = 40;

@Injectable({ providedIn: 'root' })
export class GotoPageService extends ComponentStore<GotoPageServiceState> {
  public isGoToPageOpen$ = this.select((s) => s.isGoToPageOpen);
  private optionsState$ = this.select((s) => s.options);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public isOptionsLoading$: Observable<boolean> = this.select(this.optionsState$, (s) => s.isLoading);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public options: Observable<GotoOption[]> = this.select(this.optionsState$, (s) => s.data);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public error$ = this.select(this.optionsState$, (s) => s.error);
  public isNavigating$ = this.select((s) => s.isNavigating);
  public navigateEvent$: Observable<GotoOption>;

  public onSearch = this.effect((origin: Observable<string>) => {
    return origin.pipe(
      tap((searchQuery: string) => {
        this.patchState({
          searchQuery: searchQuery,
          options: { isLoading: true, data: [], error: null },
        });
      }),
      debounceTime(200),
      switchMap((searchQuery) => {
        return this.getOptionsForSearchQuery(searchQuery).pipe(
          tapResponse(
            (result) => {
              if (this.get().searchQuery === searchQuery) {
                this.patchState({ options: { isLoading: false, data: result, error: null } });
              }
            },
            (originalError) => {
              console.log(originalError);
              if (this.get().searchQuery === searchQuery) {
                const error: WpError = {
                  originalError,
                  text: 'Failed to fetch search options',
                  retryFn: () => this.onSearch(searchQuery),
                };
                this.patchState({ options: { isLoading: false, data: [], error } });
              }
            },
          ),
        );
      }),
    );
  });

  public open = this.effect((origin: Observable<void>) => {
    return origin.pipe(
      switchMap(() => {
        return of(null).pipe(withLatestFrom(this.isLoggedId$));
      }),
      tap(([, isLoggedIn]) => {
        if (isLoggedIn) {
          this.patchState({
            isGoToPageOpen: true,
            searchQuery: null,
            options: { isLoading: false, data: this.get().options.data, error: null },
          });
        }
      }),
    );
  });

  private entitySearchResultToDestinationMapper: {
    [resultType: string]: (result: EntitySearchResult) => GotoOption[];
  };
  private isLoggedId$: Observable<boolean>;
  private navigateEvent$$ = new Subject<GotoOption>();

  constructor(
    private api: ApiService,
    private store: Store,
    private router: Router,
    private storage: LocalStorageService,
  ) {
    super({
      isGoToPageOpen: false,
      searchQuery: null,
      options: { isLoading: false, data: [], error: null },
      hardCodedGotoOptions: getHardCodedGotoOptions(),
      isNavigating: false,
      locallyStoredOptions: [],
    });
    this.isLoggedId$ = this.store.select(authStatus).pipe(map((u) => u?.isUserAuthenticated));
    this.entitySearchResultToDestinationMapper = getEntitySearchResultToDestinationMap();
    this.patchState({ locallyStoredOptions: this.getOptionsFromStorage() });
    this.navigateEvent$ = this.navigateEvent$$.asObservable();
  }

  public selectOption(option: GotoOption): void {
    const urlTree = option.urlTreeGetter();
    this.close();
    this.patchState({ isNavigating: true });
    this.router.navigateByUrl(urlTree).then(() => {
      this.navigateEvent$$.next(option);
      setTimeout(() => {
        this.patchState({ isNavigating: false });
      });
    });
  }

  public close(): void {
    this.patchState({ isGoToPageOpen: false });
  }

  public saveLocalGoToOption(option: LocallyStoredGotoOption): void {
    let previous = [...this.get().locallyStoredOptions];
    previous = previous.filter((it) => it.id !== option.id);
    const target: HardCodedGotoOption = {
      id: option.id,
      label: option.label,
      category: option.category,
      data: option.data,
      searchBy: option.searchBy,
      permissionGetter: () => this.store.select(isPermissionGivenForAccount(option.permission)).pipe(take(1)),
      urlTreeGetter: () =>
        PortalRouterService.getUrlTreeForRequest(this.router, option.navigation.outlet, option.navigation.request),
    };
    previous.unshift(target);
    this.patchState({ locallyStoredOptions: previous });
    this.saveOptionToStorage(option);
  }

  public clearStoredOptions(): void {
    this.storage.remove(STORAGE_GOTO_OPTIONS_KEY);
  }

  private getOptionsForSearchQuery(searchQuery: string): Observable<GotoOption[]> {
    return combineLatest([
      this.getHardCodedGotoOptionsForSearchQuery(searchQuery),
      this.getRecentlyViewedGotoOptionsForSearchQuery(searchQuery),
      this.getEntitySearchOptionsForSearchQuery(searchQuery),
    ]).pipe(
      map((args) => args.flatMap((a) => a)),
      switchMap((options) => {
        if (options?.length) {
          return this.filterOptionsByPermissions(options);
        } else {
          return of([]);
        }
      }),
    );
  }

  private getEntitySearchOptionsForSearchQuery(searchQuery: string): Observable<GotoOption[]> {
    if (!searchQuery?.length) {
      return of([]);
    }
    return this.api
      .entitySearch({
        query: searchQuery,
        types: [
          CrossEntitySearchRequestType.CAMPUS,
          CrossEntitySearchRequestType.DISTRICT,
          CrossEntitySearchRequestType.DRIVER,
          CrossEntitySearchRequestType.CUSTOMER,
          CrossEntitySearchRequestType.RIDER,
          CrossEntitySearchRequestType.VENDOR,
          CrossEntitySearchRequestType.CHARTER_TRIP,
        ],
        limit: 10,
        skip: 0,
      })
      .pipe(
        map((response) => this.translateEntitySearchResultsToDestinations(response.results)),
        map((options) => {
          const toLower = searchQuery.toLowerCase();
          options.sort((a, b) => {
            const aInd = a.label.toLowerCase().includes(toLower) ? -1 : 1;
            const bInd = b.label.toLowerCase().includes(toLower) ? -1 : 1;
            return aInd - bInd;
          });
          return options;
        }),
      );
  }

  private translateEntitySearchResultsToDestinations(results: EntitySearchResult[]): GotoOption[] {
    return results.flatMap((result) => {
      return this.entitySearchResultToDestinationMapper[result.type]
        ? this.entitySearchResultToDestinationMapper[result.type](result)
        : [];
    });
  }

  private getHardCodedGotoOptionsForSearchQuery(searchQuery: string): Observable<GotoOption[]> {
    if (!searchQuery) {
      return of([]);
    }
    const hardCoded = this.get().hardCodedGotoOptions;
    const words = searchQuery.split(' ').map((w) => w.toLowerCase());
    const matchedOptions = hardCoded.filter((o) => {
      return words.some((w) => o.searchBy.includes(w));
    });
    return of(matchedOptions);
  }

  private getRecentlyViewedGotoOptionsForSearchQuery(searchQuery: string): Observable<GotoOption[]> {
    if (!searchQuery) {
      return of([]);
    }
    const hardCoded = this.get().locallyStoredOptions;
    const words = searchQuery.split(' ').map((w) => w.toLowerCase());
    const matchedOptions = hardCoded.filter((o) => {
      return words.some((w) => o.searchBy.includes(w));
    });
    return of(matchedOptions);
  }

  private filterOptionsByPermissions(options: GotoOption[]): Observable<GotoOption[]> {
    const obs = options.map((o) => {
      return o.permissionGetter().pipe(
        take(1),
        map((isPermitted) => {
          return {
            isPermitted,
            option: o,
          };
        }),
      );
    });
    return forkJoin(obs).pipe(
      map((results) => {
        return results.filter((r) => r.isPermitted).map((r) => r.option);
      }),
    );
  }

  private saveOptionToStorage(option: LocallyStoredGotoOption) {
    const state = this.storage.get(STORAGE_GOTO_OPTIONS_KEY, true);
    let options: LocallyStoredGotoOption[] = state?.options ?? [];
    options = options.filter((o) => o.id !== option.id);
    options.unshift(option);
    const toSave = options.slice(0, MAX_HISTORY_LENGTH);
    this.storage.set(STORAGE_GOTO_OPTIONS_KEY, { options: toSave });
  }

  private getOptionsFromStorage(): HardCodedGotoOption[] {
    const state = this.storage.get(STORAGE_GOTO_OPTIONS_KEY, true);
    const options: LocallyStoredGotoOption[] = state?.options ?? [];
    return options.map((source) => {
      return {
        id: source.id,
        label: source.label,
        data: source.data,
        category: source.category,
        searchBy: source.searchBy,
        permissionGetter: () => this.store.select(isPermissionGivenForAccount(source.permission)).pipe(take(1)),
        urlTreeGetter: () =>
          PortalRouterService.getUrlTreeForRequest(this.router, source.navigation.outlet, source.navigation.request),
      };
    });
  }
}
