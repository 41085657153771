import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import { Observable, of } from 'rxjs';

import {
  selectedAccountId,
  selectedAccount,
  firebaseUserId,
  getSignedInVendor,
  getSignedInDistrict,
  selectedAccountTimezone,
} from '../selectors';
import { SelectedAccount } from '../../../api/endpoints/account';
import { SignedInVendor, SignedInDistrict } from '@rootTypes/entities/auth';

@Injectable()
export class AccountsFacade {
  constructor(private store: Store<State>) {}

  public getSelectedAccountId(): Observable<string> {
    return this.store.select(selectedAccountId);
  }

  public getFirebaseUserId$(): Observable<string> {
    return this.store.select(firebaseUserId);
  }

  public getSelectedAccount(): Observable<SelectedAccount | null> {
    return this.store.select(selectedAccount);
  }

  public getSelectedAccountTimezone(): Observable<string> {
    return this.store.select(selectedAccountTimezone);
  }

  public getSignedInVendorId(): Observable<string | undefined> {
    if (wpEnvironment.userRole === 'vendor') {
      return this.getSelectedAccountId();
    }
    return of(undefined);
  }

  public getSignedInVendor(): Observable<SignedInVendor> {
    if (wpEnvironment.userRole === 'vendor') {
      return this.store.select(getSignedInVendor);
    }
    return of(undefined);
  }

  public getSignedInDistrict(): Observable<SignedInDistrict> {
    if (wpEnvironment.userRole === 'enterprise') {
      return this.store.select(getSignedInDistrict);
    }
    return of(undefined);
  }
}
