<div
  tabindex="0"
  role="dialog"
  class="wp-confirm-popup"
  [ngStyle]="{'width': popupWidth}"
  #container
>
  <wp-icon-close class="close-btn" (click)="cancel()"> </wp-icon-close>

  <div class="header" *ngIf="headerText">{{ headerText }}</div>

  <div *ngIf="bodyText" style="padding-right: 30px">{{ bodyText }}</div>
  <div *ngIf="bodyHtml" [innerHTML]="bodyHtml"></div>

  <div class="footer">
    <wp-stack-layout [spacing]="20" [mainAxisAlignment]="'flex-end'">
      <wp-stack-layout-item>
        <wp-btn-secondary
          *ngIf="cancelBtnText"
          [width]="cancelBtnWidth"
          (clicked)="cancel()"
          >{{cancelBtnText}}
        </wp-btn-secondary>
      </wp-stack-layout-item>
      <wp-stack-layout-item>
        <wp-btn-primary
          *ngIf="confirmBtnText"
          [width]="confirmBtnWidth"
          [color]="confirmBtnColor"
          (clicked)="confirm()"
          >{{confirmBtnText }}
        </wp-btn-primary>
      </wp-stack-layout-item>
    </wp-stack-layout>
  </div>
</div>
