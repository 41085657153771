import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  dayActivityContextLoadFailed,
  dayActivityContextLoadRequested,
  dayActivityContextLoadSuccess,
  daySpecificContextLoadFailed,
  daySpecificContextLoadRequested,
  daySpecificContextLoadSuccess,
  reportedActivityStatusesFromContextActivities,
} from './actions';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { PortalEntity, PortalEntityType, TimesheetEntryListItem, WpError, YYYYMMDDString } from '@rootTypes';
import {
  getActivityEntityId,
  getActivityEntityType,
  getActivityYYYYMMDD,
  getSpecificContextEntityType,
} from './selectors';
import { TimesheetEntryContextApiService } from './timesheet-entry-context-api.service';
import { setPortalEntityInfo } from '../common/store/actions/portal-entities-info.actions';
import { ActivityResult } from '../../api/entities/entity-search';

@Injectable()
export class TimesheetEntryDetailsContextEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private api: TimesheetEntryContextApiService,
  ) {}

  public dayActivityContextLoadRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dayActivityContextLoadRequested),
      switchMap(({ activityId }) => {
        const entityId$ = this.store.select(getActivityEntityId(activityId));
        const entityType$ = this.store.select(getActivityEntityType(activityId));
        const yyyyMMDD$ = this.store.select(getActivityYYYYMMDD(activityId)).pipe(filter((date) => !!date));
        return combineLatest([entityId$, entityType$, yyyyMMDD$]).pipe(
          take(1),
          switchMap(([entityId, entityType, yyyyMMDD]) =>
            this.getActivityContextRequested(activityId, entityId, entityType, yyyyMMDD),
          ),
        );
      }),
    ),
  );

  /**
   * Save activity statuses
   */
  public dayActivityContextLoadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dayActivityContextLoadSuccess),
      map(({ activities }) => {
        const statuses = (activities || []).reduce((prev, curr) => {
          return { ...prev, [curr.activityId]: curr.status };
        }, {});
        return reportedActivityStatusesFromContextActivities({ statuses });
      }),
    ),
  );

  public daySpecificContextLoadRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(daySpecificContextLoadRequested),
      switchMap(({ activityId }) => {
        const entityId$ = this.store.select(getActivityEntityId(activityId));
        const entityType$ = this.store.select(getActivityEntityType(activityId));
        const contextEntityType$ = this.store.select(getSpecificContextEntityType(activityId));
        const yyyyMMDD$ = this.store.select(getActivityYYYYMMDD(activityId)).pipe(filter((date) => !!date));
        return combineLatest([entityId$, entityType$, contextEntityType$, yyyyMMDD$]).pipe(
          take(1),
          switchMap(([entityId, entityType, contextEntityType, yyyyMMDD]) =>
            this.getSpecificActivityContextRequested(activityId, entityId, entityType, contextEntityType, yyyyMMDD),
          ),
        );
      }),
    ),
  );

  private getActivityContextRequested(
    activityId: string,
    entityId: string,
    entityType: PortalEntityType,
    yyyyMMDD: YYYYMMDDString,
  ): Observable<Action> {
    if (!entityId) {
      return of(dayActivityContextLoadSuccess({ activityId, activities: [] }));
    }
    return this.api.getActivityContext(entityId, entityType, yyyyMMDD).pipe(
      tap((resp) => {
        const entities = resp.results.map(
          (item) =>
            ({
              entityId: (item as ActivityResult).activityId,
              type: PortalEntityType.ACTIVITY_TIME_ENTRY,
              label: (item as ActivityResult).activityName,
            }) as PortalEntity,
        );
        this.store.dispatch(setPortalEntityInfo({ entities }));
      }),
      map((resp) => {
        return dayActivityContextLoadSuccess({ activityId, activities: resp.results as TimesheetEntryListItem[] });
      }),
      catchError((originalError) => {
        console.error(originalError);
        const error: WpError = {
          text: 'Failed to load activities for the day',
          originalError,
        };
        return of(dayActivityContextLoadFailed({ activityId, error }));
      }),
    );
  }

  private getSpecificActivityContextRequested(
    activityId: string,
    entityId: string,
    entityType: PortalEntityType,
    contextEntityType: PortalEntityType,
    yyyyMMDD: YYYYMMDDString,
  ): Observable<Action> {
    if (!entityId) {
      return of(daySpecificContextLoadSuccess({ activityId, items: [] }));
    }
    return this.api.getSpecificContext(entityId, entityType, contextEntityType, yyyyMMDD).pipe(
      map((resp) => {
        //TODO: remove test setup when geotab testing is done
        const testActivityIds = ['4KV_kt40US', '4KV_kuh0UT'];
        if (testActivityIds.includes(activityId)) {
          return daySpecificContextLoadSuccess({
            activityId,
            items: [
              { type: PortalEntityType.RIDE, entityId: '-NKfgMq-KGnkexgulyAU' },
              { type: PortalEntityType.RIDE, entityId: '-NKfhGLjIoGYoI-2V_z_' },
              { type: PortalEntityType.RIDE, entityId: '-NKflDY63JdZXy5q80Iu' },
              { type: PortalEntityType.RIDE, entityId: '-NKfnLlSCaqN-kB5ddf8' },
            ],
          });
        } else {
          return daySpecificContextLoadSuccess({ activityId, items: resp });
        }
      }),
      catchError((originalError) => {
        console.error(originalError);
        const error: WpError = {
          text: 'Failed to load specific context for the day',
          originalError,
        };
        return of(daySpecificContextLoadFailed({ activityId, error }));
      }),
    );
  }
}
