import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
  Input,
} from '@angular/core';
import { ScrollService } from '../../../services/scroll.service';

@Component({
  selector: 'wp-popup-page',
  templateUrl: './popup-page.component.html',
  styleUrls: ['./popup-page.component.scss'],
  providers: [ScrollService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupPageComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() public nonScrollable: boolean;
  @Input() public isProgressBar: boolean;

  @Input() public isPageBottom = false;
  @Input() public isPageBottomPositionSticky = true;
  @Input() public isPageBottomBorderTop = true;
  @Input() public pageBottomPadding = '20px 32px 20px 32px';

  @ViewChild('popupPageEl') private popupPageEl: ElementRef<HTMLDivElement>;

  constructor(private scrollService: ScrollService) {}

  ngOnDestroy(): void {
    this.scrollService.reset();
  }

  ngAfterViewInit(): void {
    this.scrollService.init(this.popupPageEl.nativeElement);
    this.scrollService.scrolledTop$().subscribe();
    if (this.popupPageEl?.nativeElement) {
      this.popupPageEl.nativeElement.focus();
    }
  }

  ngOnInit(): void {}
}
