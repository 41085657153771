<div class="map-container" (mousedown)="hideTooltip()">
  <div #map [ngStyle]="mapStyles"></div>
  <wp-portal-icon
    class="center-marker"
    [ngStyle]="cursorPosition"
    [path]="cursorIcon"
    [widthStr]="cursorWidthPx + 'px'"
    [heightStr]="cursorHeightPx + 'px'"
  ></wp-portal-icon>
  <div
    class="tooltip-container"
    *ngIf="isTooltipShown && !model?.address?.control?.disabled"
  >
    <div class="tooltip">
      Move the map with your cursor to position the blue pin on the desired
      location
    </div>
  </div>
</div>
