import { UrlCreationOptions, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiPortalPermissionItem } from '@apiEntities/api-portal-permission-item';
import { NavigationRequest } from '../../../router/types/navigation-request';
import { RouterOutlets } from '@rootTypes/entities/router';

export enum GotoOptionCategory {
  RECENTLY_VIEWED = 'recently-viewed',
}

export type GotoOption = {
  id: string;
  label: string;
  category?: GotoOptionCategory;
  data?: { label: string; value: string }[];
  urlTreeGetter: () => UrlTree;
  permissionGetter: () => Observable<boolean>;
};

export type HardCodedGotoOption = GotoOption & {
  searchBy: string;
};

export type LocallyStoredGotoOption = {
  id: string;
  label: string;
  category?: GotoOptionCategory;
  data?: { label: string; value: string }[];
  permission: ApiPortalPermissionItem;
  navigation: {
    request: NavigationRequest;
    outlet: RouterOutlets;
    extras?: UrlCreationOptions;
  };
  searchBy: string;
};
