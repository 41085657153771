import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../types';
import { FlashEvent } from '@zum/flash-client';

const moduleKey = 'Flash';

/**
 * Connect/disconnect
 */
export const flashConnectRequested = createAction(`[${moduleKey}]: Connect`);

export const flashConnectSuccess = createAction(`[${moduleKey}]: Connect success`);

export const flashConnectFailed = createAction(`[${moduleKey}]: Connect failed`, props<{ error: fromTypes.WpError }>());

export const flashDisconnectRequested = createAction(`[${moduleKey}]: Disconnect`);

export const flashDisconnectSuccess = createAction(`[${moduleKey}]: Disconnect success`);

export const flashDisconnectFailed = createAction(
  `[${moduleKey}]: Disconnect failed`,
  props<{ error: fromTypes.WpError }>(),
);

/**
 * Error received in any update stream
 */
export const flashUpdateError = createAction(`[${moduleKey}]: Update error`, props<{ error: fromTypes.WpError }>());

/**
 * DBR
 */
export const subscribeDBRList = createAction(`[${moduleKey}]: Subscribe DBR list`, props<{ reportIds: string[] }>());
export const unsubscribeDBRList = createAction(`[${moduleKey}]: Unsubscribe DBR list`);
export const dbrUpdateReceived = createAction(`[${moduleKey}]: DBR update received`, props<{ update: FlashEvent }>());
