import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as fromTypes from '@rootTypes';
import * as fromActions from '../actions';
import * as fromSelectors from '../selectors';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import { LoadDistrictOptions } from '../../types';

@Injectable()
export class DistrictDataFacadeService {
  constructor(private store: Store<State>) {}

  public getAllDistrictChecks$(): Observable<fromTypes.Check[]> {
    return this.store.select(fromSelectors.getAllDistrictChecks);
  }

  public getAllDistrictChecksLoading$(): Observable<boolean> {
    return this.store.select(fromSelectors.getAllDistrictChecksLoading);
  }

  public getAllDistrictChecksError$(): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getAllDistrictChecksError);
  }

  public getDistrict$(districtId: string): Observable<fromTypes.district.District> {
    return this.store.select(fromSelectors.getDistrict(districtId));
  }

  public getCalendarForDistrict$(districtId: string): Observable<fromTypes.district.DistrictCalendar> {
    return this.store.select(fromSelectors.getCalendarForEnterprise, {
      districtId,
    });
  }

  public getCalendarForDistrictLoading$(districtId: string): Observable<boolean> {
    return this.store.select(fromSelectors.getCalendarForEnterpriseLoading, {
      districtId,
    });
  }

  public getCalendarForDistrictError$(districtId: string): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getCalendarForEnterpriseError, {
      districtId,
    });
  }

  public getDistrictLoading$(districtId: string): Observable<boolean> {
    return this.store.select(fromSelectors.getDistrictLoading(districtId));
  }

  public getDistrictError$(districtId: string): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getDistrictError(districtId));
  }

  public onLoadDistrictRequested(districtId: string, options: LoadDistrictOptions): void {
    this.store.dispatch(fromActions.loadDistrictRequested({ districtId, options }));
  }

  public onLoadAllDistrictChecksRequested(): void {
    this.store.dispatch(fromActions.loadAllDistrictChecksRequested());
  }
}
