import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { workQueuePath } from './work-queue-path';
import { DriverDocumentEntityFilterStatus } from 'src/app/types';

export interface WorkQueueDriverDocumentsRouteData {
  driverIds?: string[];
  documentStatus?: DriverDocumentEntityFilterStatus;
  pageIndex?: number;
  pageSize?: number;
}

const documentStatusToQueryParam = (status: DriverDocumentEntityFilterStatus): string => {
  const map = {
    [DriverDocumentEntityFilterStatus.PENDING]: 'pending',
    [DriverDocumentEntityFilterStatus.REJECTED]: 'rejected',
    // ignore approved status
    [DriverDocumentEntityFilterStatus.APPROVED]: 'pending',
  };
  return map[status] || 'pending';
};

const queryParamToDocumentStatus = (query = 'pending'): DriverDocumentEntityFilterStatus => {
  const map = {
    pending: DriverDocumentEntityFilterStatus.PENDING,
    rejected: DriverDocumentEntityFilterStatus.REJECTED,
    // ignore approved status
    approved: DriverDocumentEntityFilterStatus.PENDING,
  };
  return map[query] || DriverDocumentEntityFilterStatus.PENDING;
};

const statePath = 'driver-documents';

export const workQueueDriverDocumentsRoute: PortalRoute<WorkQueueDriverDocumentsRouteData> = {
  path: statePath,

  isOnRoute: (state: RouterStateUrl) => {
    return state && state.url && state.url.startsWith(`/${workQueuePath}/${statePath}`);
  },

  parse: (state: RouterStateUrl): WorkQueueDriverDocumentsRouteData => {
    const query = state?.queryParams || {};
    const data: WorkQueueDriverDocumentsRouteData = {
      driverIds: query.driverIds ? (typeof query.driverIds === 'string' ? [query.driverIds] : query.driverIds) : [],
      pageIndex: query.page ? parseInt(query.page, 10) - 1 : 0,
      pageSize: query.pageSize ? parseInt(query.pageSize, 10) : 10,
      documentStatus: queryParamToDocumentStatus(query.documentStatus),
    };
    return data;
  },

  request: (data?: WorkQueueDriverDocumentsRouteData): NavigationRequest => {
    const request: NavigationRequest = {
      path: ['/', workQueuePath, statePath],
    };
    if (data) {
      const { driverIds, pageIndex, pageSize } = data;
      const query: { [key: string]: any } = {};
      if (Array.isArray(driverIds)) {
        query.driverIds = driverIds;
      }
      if (typeof pageIndex === 'number') {
        query.page = (pageIndex + 1).toString();
      }
      if (typeof pageSize === 'number') {
        query.pageSize = pageSize.toString();
      }
      if (data.documentStatus) {
        query.documentStatus = documentStatusToQueryParam(data.documentStatus);
      }
      request.query = query;
    }
    return request;
  },
  getHref(data?: WorkQueueDriverDocumentsRouteData): string {
    return `/${workQueuePath}/${statePath}`;
  },
};
