import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wp-link-accordion',
  templateUrl: './link-accordion.component.html',
  styleUrls: ['./link-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkAccordionComponent implements OnInit {
  @Input() isExpanded = false;
  @Input() public collapsedText = 'Show';
  @Input() public expandedText = 'Hide';
  @Input() public fixLabelWidth = 'auto';
  @Output() public onChangeExpand = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  public toggle() {
    this.isExpanded = !this.isExpanded;
    this.onChangeExpand.emit(this.isExpanded);
  }
}
