import { PortalEntity } from '../../../../types';

export interface DriverListFilter {
  id: string;
  type: DriverListFilterType;
  payload: any;
}

export interface DriverListEntityFilter extends DriverListFilter {
  type: DriverListFilterType.ENTITY;
  payload: PortalEntity;
}

export interface DriverListMultiAccountFilter extends DriverListFilter {
  type: DriverListFilterType.MULTI_ACCOUNT_FILTER;
  payload: PortalEntity & { operatorIds: string[] };
}

export enum DriverListFilterType {
  ENTITY = 'entity',
  MULTI_ACCOUNT_FILTER = 'multi-account-filter',
}
