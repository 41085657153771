import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

import { ServiceAreaOption } from '../../types';
import { getAvailableServiceAreasRequested } from '../actions/driver-data.actions';
import {
  getServiceAreaOptions,
  getServiceAreasErrorMessage,
  isServiceAreasLoading,
} from '../selectors/driver-data.selectors';

@Injectable()
export class ServiceAreaDataFacade {
  constructor(private store: Store) {}

  public loadServiceAreaOptionsLazily(): Observable<ServiceAreaOption[] | undefined> {
    return this.getServiceAreaOptions().pipe(
      take(1),
      mergeMap((options) => {
        if (!options) {
          this.loadServiceAreas();
        }
        return this.getServiceAreaOptions();
      }),
    );
  }

  public isServiceAreasLoading(): Observable<boolean> {
    return this.store.select(isServiceAreasLoading);
  }

  public getLoadingErrorMessage(): Observable<string | undefined> {
    return this.store.select(getServiceAreasErrorMessage);
  }

  private loadServiceAreas(): void {
    this.store.dispatch(getAvailableServiceAreasRequested());
  }

  private getServiceAreaOptions(): Observable<ServiceAreaOption[] | undefined> {
    return this.store.select(getServiceAreaOptions);
  }
}
