import { SmartFormFieldType, SmartFormFieldStudentTransportationStatus } from '@apiEntities/smart-forms';
import { StudentTransportationStatus } from '@apiEntities/student-change-requests';

export const isSmartFormFieldStudentTransportationStatus = (
  arg: any,
): arg is SmartFormFieldStudentTransportationStatus => {
  return !!arg && typeof arg === 'object' && arg.type === SmartFormFieldType.STUDENT_TRANSPORTATION_STATUS;
};

export const isSmartFormFieldStudentTransportationStatusValid = (
  field: SmartFormFieldStudentTransportationStatus,
  required: boolean,
): boolean => {
  if (!required && !field.status) {
    return true;
  }
  if (field.status === StudentTransportationStatus.CANCELED) {
    return !!field.cancelTransportationDetails?.reason && !!field.cancelTransportationDetails?.effectiveDate;
  }
  return !!field.status;
};
