import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { map, filter, take } from 'rxjs/operators';

import { AuthState } from '../../store';
import * as fromSelectors from '../../store/selectors';
import * as fromApi from '../../../api';
import * as fromActions from '../../store/actions';
import { getRelayStateFromRoute } from '../../utils';

@Component({
  selector: 'wp-select-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.scss'],
})
export class SelectAccountComponent implements OnInit, OnDestroy {
  accounts$: Observable<fromApi.account.UserAccount[]>;
  isSetAccountLoading$: Observable<boolean>;
  isSubAccountRequired$: Observable<boolean>;

  private isSetAccountLoading$$: BehaviorSubject<boolean>;
  private subs: Subscription[] = [];

  constructor(
    private store: Store<AuthState>,
    private activatedRoute: ActivatedRoute,
  ) {
    this.isSetAccountLoading$$ = new BehaviorSubject(false);
    this.isSetAccountLoading$ = this.isSetAccountLoading$$.asObservable();
  }

  public ngOnInit(): void {
    this.accounts$ = this.store.select(fromSelectors.accounts).pipe(
      filter((accounts) => !!accounts),
      take(1),
      map((accounts) => accounts),
    );

    this.isSubAccountRequired$ = this.store.select(fromSelectors.isSubAccountRequired).pipe(
      filter((required) => typeof required === 'boolean'),
      take(1),
    );

    const errorSub = this.store.select(fromSelectors.setAccountError).subscribe((error) => {
      if (error) {
        this.isSetAccountLoading$$.next(false);
      }
    });
    this.subs.push(errorSub);
  }

  public ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  public goToAccount(request: fromApi.account.SetAccountRequest): void {
    // Don't stop the button spinner before the navigation starts
    this.isSetAccountLoading$$.next(true);
    this.store.dispatch(
      fromActions.selectAccountPageSetAccountRequested({
        request,
        relayState: getRelayStateFromRoute(this.activatedRoute.snapshot),
      }),
    );
  }

  public logout(): void {
    this.store.dispatch(fromActions.logoutPageRequested());
  }
}
