import * as fromRoot from '@rootTypes';

export enum StudentListFilterType {
  ENTITY = 'entity',
}

export interface StudentListFilter {
  id: string;
  type: StudentListFilterType;
  payload: StudentListFilterPayload<StudentListFilter>;
}

export type StudentListFilterPayload<T> = T extends { type: StudentListFilterType.ENTITY } ? fromRoot.PortalEntity : {};
