<div class="option-content">
  <wp-display-photo
    class="vehicle-image"
    [imagePath]="entity.imagePath"
    [placeholderType]="photoPlaceholder"
    [isRound]="false"
    [width]="'40px'"
    [height]="'30px'"
  >
  </wp-display-photo>

  <div>
    <div class="vehicle-number">{{entity.licenseStateAndNumber}}</div>
    <div class="vehicle-label">
      {{entity.makeAndModel}}, {{entity.assignedDriverText}}
    </div>
  </div>
</div>
