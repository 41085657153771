import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, switchMap, take, tap, withLatestFrom, map } from 'rxjs/operators';

import * as fromActions from '../actions/vehicle-list.actions';
import * as fromSelectors from '../selectors/vehicle-lists.selectors';
import { VehicleListName } from '../../types';
import { getSnapshotFromListState } from '../../types/utils';
import { VehicleListState } from '../reducers/vehicle-lists.reducer';
import { VehicleListRouterService } from 'src/app/router/portal-routes/vehicles';
import { destroyVehicleDetails, initVehicleDetails } from '../actions/vehicle-details.actions';

@Injectable()
export class VehiclesRouterEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private router: VehicleListRouterService,
  ) {}

  public onVehicleListParamsChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromActions.pageChanged,
          fromActions.pageSizeChanged,
          fromActions.searchChanged,
          fromActions.vehicleListInitialized,
          fromActions.filtersChanged,
        ),
        filter(({ listName }) => listName === VehicleListName.VEHICLES),
        switchMap(({ listName }) => {
          return this.store.select(fromSelectors.getVehicleListState, { listName }).pipe(
            take(1),
            tap((state: VehicleListState) => {
              const data = getSnapshotFromListState(state);
              this.router.navigate(data);
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  public onVehicleDetailsOpened$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initVehicleDetails),
      withLatestFrom(this.store.select(fromSelectors.isVehiclesSectionInitialized)),
      filter(([, isInitialized]) => isInitialized),
      map(([{ vehicleId }]) => fromActions.vehiclesSectionVehicleDetailsOpened({ vehicleId })),
    ),
  );

  public onVehicleDetailsClosed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(destroyVehicleDetails),
      withLatestFrom(this.store.select(fromSelectors.isVehiclesSectionInitialized)),
      filter(([, isInitialized]) => isInitialized),
      map(() => fromActions.vehiclesSectionVehicleDetailsClosed()),
    ),
  );
}
