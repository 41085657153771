import { Pipe, PipeTransform } from '@angular/core';
import { ActivityStatus } from '@apiEntities/timesheet-entries';
import { activityDisplayLabels } from '@rootTypes/utils/timesheet-entries/timesheet-entry-configs';

@Pipe({
  name: 'activityDisplayStatus',
})
export class ActivityDisplayStatusPipe implements PipeTransform {
  transform(value: ActivityStatus): string {
    if (!value) {
      return '--';
    }
    return activityDisplayLabels[value]?.long;
  }
}
