<div class="buttons">
  <div class="buttons-inner">
    <div *ngIf="isCancelButton" class="button left">
      <wp-btn-secondary
        [width]="cancelButtonWidth"
        (clicked)="cancelClicked.emit()"
        >{{cancelButtonLabel}}
      </wp-btn-secondary>
    </div>
    <div class="button">
      <wp-btn-primary
        [width]="confirmButtonWidth"
        [color]="confirmButtonColor"
        (clicked)="confirmClicked.emit()"
        [loading]="isConfirmLoading"
        [disabled]="confirmButtonDisabled"
        >{{confirmButtonLabel}}
      </wp-btn-primary>
    </div>
  </div>
</div>
