<mat-form-field class="wp-smart-select-async" [hideRequiredMarker]="true">
  <mat-label>{{model.label}}</mat-label>

  <mat-select
    [formControl]="model.control"
    [compareWith]="model.compareWithFn"
    (focus)="onInputFocus()"
  >
    <mat-option *ngFor="let opt of model.options$ | async" [value]="opt"
      >{{opt.displayLabel || opt.value}}
    </mat-option>

    <mat-option
      *ngIf="model.optionsLoading$ | async"
      [value]="null"
      [disabled]="true"
    >
      <div class="loading">Loading...</div>
    </mat-option>

    <mat-option
      *ngIf="model.optionsErrorMessage$ | async; let optionsError"
      [value]="null"
      [disabled]="true"
    >
      <div class="options-error">{{optionsError}}</div>
    </mat-option>
  </mat-select>

  <mat-error
    *ngIf="model.control.invalid && model.control.hasError('required')"
  >
    Required field
  </mat-error>
</mat-form-field>
