import { RouteGroupShift } from '@apiEntities/route-group/route-group-shift';
import { createReducer } from '@ngrx/store';
import { SelectOption } from '../../../../shared/form-controls';

export interface RouteGroupShiftsState {
  shiftOptions: SelectOption<RouteGroupShift>[];
}

export const getInitialRouteGroupShiftState = (): RouteGroupShiftsState => {
  return {
    shiftOptions: [
      { displayLabel: 'AM', value: RouteGroupShift.AM },
      { displayLabel: 'Midday', value: RouteGroupShift.MIDDAY },
      { displayLabel: 'PM', value: RouteGroupShift.PM },
      { displayLabel: 'Late PM', value: RouteGroupShift.LATE_PM },
    ],
  };
};

export const routeGroupShiftsReducer = createReducer<RouteGroupShiftsState>(getInitialRouteGroupShiftState());
