import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';

import { getRouterNavigationState } from '@rootStore';
import { newUserPasswordSetupRoute } from 'src/app/router/portal-routes/auth';
import { setNewPasswordRequested } from '../actions';
import { newPasswordSetupErrorMsg } from '../selectors';

function confirmNewPasswordValidator(control: AbstractControl): ValidationErrors | null {
  if (!control.value || this.newPassword.value === control.value) {
    return null;
  }
  return {
    [this.confirmNewPasswordControlErrorKey]: 'New passwords do not match',
  };
}

@Injectable()
export class ResetPasswordSetupFacade {
  public newPassword: UntypedFormControl;
  public confirmNewPassword: UntypedFormControl;
  public confirmNewPasswordControlErrorKey = 'confirmNewPassword';

  private form: UntypedFormGroup;
  private isLoading$$ = new BehaviorSubject(false);
  private isFormValid$: Observable<boolean>;
  private sub = new Subscription();

  constructor(private store: Store) {}

  public init(): void {
    this.newPassword = new UntypedFormControl('', [Validators.required, Validators.minLength(6)]);
    this.confirmNewPassword = new UntypedFormControl('', [Validators.required, confirmNewPasswordValidator.bind(this)]);

    const newPasswordSub = this.newPassword.valueChanges.subscribe(() => {
      if (this.confirmNewPassword.value) {
        this.confirmNewPassword.updateValueAndValidity();
      }
    });
    this.sub.add(newPasswordSub);

    this.form = new UntypedFormGroup({
      newPassword: this.newPassword,
      confirmNewPassword: this.confirmNewPassword,
    });

    this.isFormValid$ = this.form.statusChanges.pipe(
      map((status) => status === 'VALID'),
      startWith(false),
    );

    const errorMsgSub = this.getErrorMsg$()
      .pipe(filter((msg) => !!msg))
      .subscribe(() => {
        this.isLoading$$.next(false);
      });
    this.sub.add(errorMsgSub);
  }

  public unsubscribe(): void {
    this.sub.unsubscribe();
  }

  public setNewPassword(): void {
    if (!this.form.valid) {
      return;
    }
    this.isLoading$$.next(true);
    this.store
      .select(getRouterNavigationState)
      .pipe(
        take(1),
        map((state) => newUserPasswordSetupRoute.parse(state).token),
      )
      .subscribe((token) => {
        this.store.dispatch(
          setNewPasswordRequested({
            request: {
              password: this.newPassword.value,
              token,
            },
          }),
        );
      });
  }

  public getHeaderText$(): Observable<string> {
    return this.getIsNewUser$().pipe(
      map((isNewUser) => {
        if (isNewUser) {
          return 'Welcome to Zūm!';
        }
        return 'Reset Password';
      }),
      take(1),
    );
  }

  public getInstructions$(): Observable<string> {
    return this.getIsNewUser$().pipe(
      map((isNewUser) => {
        if (isNewUser) {
          return 'To get started, create your Zūm account by entering a new password below';
        }
        return 'Please enter and confirm a new password';
      }),
      take(1),
    );
  }

  public getSubmitBtnText$(): Observable<string> {
    return this.getIsNewUser$().pipe(
      map((isNewUser) => {
        if (isNewUser) {
          return 'Create account';
        }
        return 'Submit';
      }),
      take(1),
    );
  }

  public getIsLoading$(): Observable<boolean> {
    return this.isLoading$$.asObservable();
  }

  public getIsSubmitDisabled$(): Observable<boolean> {
    return combineLatest([this.getErrorMsg$(), this.getIsLoading$(), this.isFormValid$]).pipe(
      map(([errorMsg, isLoading, isFormValid]) => {
        if (isLoading) {
          return false;
        }
        return !!errorMsg || !isFormValid;
      }),
    );
  }

  public getErrorMsg$(): Observable<string> {
    return this.store.select(newPasswordSetupErrorMsg);
  }

  /**
   * @return whether there is a new user setups a password
   * or an existing user resets a password
   */
  private getIsNewUser$(): Observable<boolean> {
    return this.store.select(getRouterNavigationState).pipe(
      take(1),
      map((state) => newUserPasswordSetupRoute.isOnRoute(state)),
    );
  }
}
