import { isSameDay } from 'date-fns';

/**
 * Returns true if the date is before the compareDate
 * @param date
 * @param compareDate
 */
export const dateIsBefore = (date: Date, compareDate: Date, granularity?: 'day'): boolean => {
  if (!granularity) {
    return date.getTime() < compareDate.getTime();
  }
  switch (granularity) {
    case 'day': {
      return !isSameDay(date, compareDate) && date.getTime() < compareDate.getTime();
    }
  }
};
