import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-icon-attention-round',
  templateUrl: './icon-attention-round.component.html',
  styleUrls: ['./icon-attention-round.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconAttentionRoundComponent implements OnInit {
  @Input() public top = '0px';
  @Input() public marginRight = '0px';

  constructor() {}

  ngOnInit(): void {}
}
