import {
  CampusResult,
  DistrictResult,
  DriverResult,
  EntitySearchResponseType,
  EntitySearchResult,
  RideResult,
  RouteResult,
  StudentResult,
  VendorResult,
} from '@apiEntities/entity-search';
import { GotoOption } from '../types/goto-option';
import { CrossEntitySearchRequestType } from '../../../api/endpoints/entity-search';
import { EnvironmentInjector, inject, runInInjectionContext } from '@angular/core';
import { PortalPermissionsFacade } from '../../../auth/store/facades/portal-permissions.facade';
import { StudentDetailsDrawerRouterService } from '../../../router/portal-routes/students/student-details-drawer.router-service';
import { EditStudentRouterService } from '../../../router/portal-routes/student-editor';
import { DriverDetailsRouterService } from '../../../router/portal-routes/drivers';
import { EditDriverProfileRouterService } from '../../../router/portal-routes/driver-editor';
import { CampusDetailsRouterService } from '../../../router/portal-routes/campuses';
import { CampusEditorRouterService } from '../../../router/portal-routes/campus-info-editor';
import { DistrictDetailsRouterService, EditDistrictRouterService } from '../../../router/portal-routes/districts';
import { RouteExplorerRouterService } from '../../../router/portal-routes/route-explorer';
import { RidesDetailsDrawerRouterService } from '../../../router/portal-routes/rides/rides-details-drawer-router.service';
import { RouteDetailsDrawerRouterService } from '../../../router/portal-routes/routes';
import { VendorDetailsRouterService } from '../../../router/portal-routes/vendors';

export const getEntitySearchResultToDestinationMap = (): {
  [resultType: string]: (result: EntitySearchResult) => GotoOption[];
} => {
  const permissions = inject(PortalPermissionsFacade);
  const studentDetails = inject(StudentDetailsDrawerRouterService);
  const editStudent = inject(EditStudentRouterService);
  const driverDetails = inject(DriverDetailsRouterService);
  const editDriverProfile = inject(EditDriverProfileRouterService);
  const campusDetails = inject(CampusDetailsRouterService);
  const editCampus = inject(CampusEditorRouterService);
  const districtDetails = inject(DistrictDetailsRouterService);
  const editDistrict = inject(EditDistrictRouterService);
  const routeExplorer = inject(RouteExplorerRouterService);
  const rideDetails = inject(RidesDetailsDrawerRouterService);
  const environmentInjector = inject(EnvironmentInjector);
  return {
    [EntitySearchResponseType.STUDENT]: (searchResult: StudentResult) => {
      return [
        {
          id: 'student-details-' + searchResult.studentId,
          label: `${searchResult.label} - Student details`,
          permissionGetter: () => permissions.isStudentView$(),
          urlTreeGetter: () => studentDetails.getUrlTree({ studentId: searchResult.studentId }),
        },
        {
          id: 'edit-student-' + searchResult.studentId,
          label: `${searchResult.label} - Edit student`,
          permissionGetter: () => permissions.isStudentEdit$(),
          urlTreeGetter: () => editStudent.getUrlTree({ studentId: searchResult.studentId }),
        },
      ];
    },
    [CrossEntitySearchRequestType.DRIVER]: (searchResult: DriverResult) => {
      return [
        {
          id: 'driver-details-' + searchResult.driverId,
          label: `${searchResult.label} - Driver details`,
          permissionGetter: () => permissions.isDriverView$(),
          urlTreeGetter: () => driverDetails.getUrlTree({ driverId: searchResult.driverId }),
        },
      ];
    },
    [CrossEntitySearchRequestType.CAMPUS]: (searchResult: CampusResult) => {
      return [
        {
          id: 'campus-details-' + searchResult.campusId,
          label: `${searchResult.label} - Campus details`,
          permissionGetter: () => permissions.isCampusView$(),
          urlTreeGetter: () => campusDetails.getUrlTree({ campusId: searchResult.campusId }),
        },
        {
          id: 'edit-campus-' + searchResult.campusId,
          label: `${searchResult.label} - Edit campus`,
          permissionGetter: () => permissions.isCampusEdit$(),
          urlTreeGetter: () =>
            editCampus.getUrlTree({
              campusId: searchResult.campusId,
            }),
        },
      ];
    },
    [EntitySearchResponseType.DISTRICT]: (searchResult: DistrictResult) => {
      return [
        {
          id: 'district-details-' + searchResult.districtId,
          label: `${searchResult.label} - District details`,
          permissionGetter: () => permissions.isDistrictView$(),
          urlTreeGetter: () => districtDetails.getUrlTree({ districtId: searchResult.districtId }),
        },
        {
          id: 'district-route-explorer' + searchResult.districtId,
          label: `${searchResult.label} - Route explorer`,
          permissionGetter: () => permissions.isRouteExplorerView$(),
          urlTreeGetter: () => routeExplorer.getUrlTree({ districtId: searchResult.districtId }),
        },
        {
          id: 'edit-district-' + searchResult.districtId,
          label: `${searchResult.label} - Edit district`,
          permissionGetter: () => permissions.isCampusEdit$(),
          urlTreeGetter: () =>
            editDistrict.getUrlTree({
              districtId: searchResult.districtId,
            }),
        },
      ];
    },
    ['ride']: (searchResult: RideResult) => {
      return [
        {
          id: 'ride-details-' + searchResult.rideId,
          label: `${searchResult.label} - Ride details`,
          permissionGetter: () => permissions.isRideView$(),
          urlTreeGetter: () => rideDetails.getUrlTree({ rideId: searchResult.rideId }),
        },
      ];
    },
    ['route']: (searchResult: RouteResult) => {
      return [
        {
          id: 'route-details-' + searchResult.routeId,
          label: `${searchResult.label} - Run details`,
          permissionGetter: () => permissions.isRouteView$(),
          urlTreeGetter: () =>
            runInInjectionContext(environmentInjector, () => {
              return inject(RouteDetailsDrawerRouterService).getUrlTree({ routeId: searchResult.routeId });
            }),
        },
      ];
    },
    [EntitySearchResponseType.VENDOR]: (searchResult: VendorResult) => {
      return [
        {
          id: 'vendor-details-' + searchResult.vendorId,
          label: `${searchResult.label} - Vendor details`,
          permissionGetter: () => permissions.isRouteView$(),
          urlTreeGetter: () =>
            runInInjectionContext(environmentInjector, () => {
              return inject(VendorDetailsRouterService).getUrlTree({ vendorId: searchResult.vendorId });
            }),
        },
      ];
    },
  };
};
