import { Component, ChangeDetectionStrategy } from '@angular/core';
import { go } from '@rootStore';
import { commonRoutes } from '@router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'wp-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessDeniedComponent {
  constructor(private store: Store) {}

  public goHome(): void {
    this.store.dispatch(go({ path: [commonRoutes.home.url] }));
  }
}
