<div class="icon">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      fill="#47A540"
      fill-rule="evenodd"
      d="M16 1.333c5.892 0 10.667 4.69 10.667 10.476C26.667 22.285 16 30.667 16 30.667S5.333 22.285 5.333 11.809C5.333 6.023 10.108 1.333 16 1.333zm0 16c2.944 0 5.333-2.389 5.333-5.333 0-2.946-2.389-5.333-5.333-5.333-2.946 0-5.333 2.387-5.333 5.333 0 2.944 2.387 5.333 5.333 5.333z"
    />
  </svg>
</div>
