import { Pipe, PipeTransform } from '@angular/core';
import * as fromRoot from '@rootTypes';
import { Observable, of, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { datesDisplayDuration } from '@rootTypes/utils/common/date-time-fns';

@Pipe({
  name: 'utcTimeFromNow',
})
export class UtcTimeFromNowPipe implements PipeTransform {
  transform(value: fromRoot.utils.date.UTCTimestamp, ...args: unknown[]): Observable<string> {
    if (!value) {
      return of(null);
    }
    return timer(0, 30000).pipe(
      map(() => {
        return datesDisplayDuration(new Date(value), new Date());
      }),
    );
  }
}
