import { AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[wpScrollIntoView]',
})
export class ScrollIntoViewDirective implements AfterViewInit, OnChanges {
  @Input() shouldScrollIntoView: boolean;
  @Input() public scrollBehavior: 'auto' | 'smooth' = 'auto';
  @Input() public scrollBlock: ScrollLogicalPosition = 'nearest';
  @Input() public shouldScrollOnChanges = false;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.shouldScrollIntoView) {
      setTimeout(() => {
        (this.el.nativeElement as HTMLDivElement).scrollIntoView({ behavior: this.scrollBehavior });
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.shouldScrollOnChanges) {
      if (changes.shouldScrollIntoView && !changes.shouldScrollIntoView.isFirstChange()) {
        if (changes.shouldScrollIntoView.currentValue) {
          setTimeout(() => {
            (this.el.nativeElement as HTMLDivElement).scrollIntoView({
              behavior: this.scrollBehavior,
              block: this.scrollBlock,
            });
          });
        }
      }
    }
  }
}
