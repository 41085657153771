import { combineReducers } from '@ngrx/store';

import { changePasswordReducer, ChangePasswordState, getChangePasswordInitialState } from './change-password.reducers';

export interface HomeState {
  changePassword: ChangePasswordState;
}

const initialState: HomeState = {
  changePassword: getChangePasswordInitialState(),
};

export const homeReducer = combineReducers(
  {
    changePassword: changePasswordReducer,
  },
  initialState,
);

export * from './change-password.reducers';
