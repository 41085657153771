import { Weekday } from '../../entities/common/weekday';
import { RouteStop } from '../../entities/route/route-stop';

export const getOperatingWeekdaysForRouteStops = (stops: RouteStop[]): Weekday[] => {
  if (!(stops && stops.length)) {
    return [];
  }
  const firstStop = stops[0];
  return Object.keys(firstStop.displayStartTimes || {}) as Weekday[];
};
