import { Component, OnInit, ChangeDetectionStrategy, Input, TemplateRef } from '@angular/core';
import * as fromTypes from '../../../../types';

@Component({
  selector: 'wp-loaded-content',
  templateUrl: './loading-content-error.component.html',
  styleUrls: ['./loading-content-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingContentErrorComponent implements OnInit {
  @Input() public loading: boolean;
  @Input() public empty: boolean;
  @Input() public error: fromTypes.WpError;

  @Input() public contentTemplate: TemplateRef<any>;
  @Input() public loadingTemplate: TemplateRef<any>;
  @Input() public errorTemplate: TemplateRef<any>;
  @Input() public emptyTemplate: TemplateRef<any>;

  constructor() {}

  ngOnInit(): void {}
}
