<div class="wp-popup-page-top" [ngClass]="{ separator: isSeparatorLine }">
  <div class="wp-popup-page-close-box-container" *ngIf="isCloseButton">
    <div class="wp-popup-page-close-box" (click)="onCloseClicked()">
      <wp-icon-close></wp-icon-close>
    </div>
  </div>
  <div class="wp-popup-page-title-row">
    <ng-content select="wp-common-popup-page-title"></ng-content>
  </div>
</div>
