<div style="margin: 50px">
  <div style="margin-bottom: 30px">
    <button [disabled]="loading" (click)="setupPasswordForNewUser()">
      Setup password for new user
    </button>
  </div>

  <!-- <div>
    <button 
      [disabled]="loading"
      (click)="resetPassword()"
      >
      Request password reset
    </button>
  </div> -->
</div>
