<svg
  *ngIf="!grey"
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
>
  <g fill="none" fill-rule="evenodd">
    <g>
      <g>
        <g>
          <g>
            <g>
              <g
                transform="translate(-766 -430) translate(486) translate(32 124) translate(0 284) translate(248.833 23.667)"
              >
                <rect
                  width="16.333"
                  height="14.667"
                  x="1"
                  y="1.833"
                  stroke="#47A540"
                  stroke-width="2"
                  rx="2"
                />
                <path
                  fill="#47A540"
                  d="M1.667 5H18.334000000000003V6.667H1.667z"
                />
                <path
                  stroke="#47A540"
                  stroke-linecap="round"
                  stroke-width="2"
                  d="M12.5 0v3.333M5.833 0v3.333"
                />
                <path stroke="#47A540" d="M9.667 9.667H13.667V13.667H9.667z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>

<svg
  *ngIf="grey"
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
>
  <g fill="none" fill-rule="evenodd">
    <g>
      <g>
        <g>
          <g>
            <g>
              <g
                transform="translate(-766 -430) translate(486) translate(32 124) translate(0 284) translate(248.833 23.667)"
              >
                <rect
                  width="16.333"
                  height="14.667"
                  x="1"
                  y="1.833"
                  stroke="#959597"
                  stroke-width="2"
                  rx="2"
                />
                <path
                  fill="#959597"
                  d="M1.667 5H18.334000000000003V6.667H1.667z"
                />
                <path
                  stroke="#959597"
                  stroke-linecap="round"
                  stroke-width="2"
                  d="M12.5 0v3.333M5.833 0v3.333"
                />
                <path stroke="#959597" d="M9.667 9.667H13.667V13.667H9.667z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
