import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { switchAccountUserProfileRequested } from 'src/app/auth/store';

@Component({
  selector: 'wp-switch-account-transition',
  template: '<wp-progress-bar></wp-progress-bar>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchAccountTransitionComponent implements OnInit {
  constructor(private store: Store) {}

  public ngOnInit(): void {
    this.store.dispatch(switchAccountUserProfileRequested());
  }
}
