import { createReducer, on } from '@ngrx/store';
import * as fromTypes from '../../types';
import * as fromActions from '../actions/student-list.actions';

export type AllStudentListsState = {
  [key in fromTypes.StudentListName]?: StudentListState;
};

export interface StudentListState {
  page: {
    listStudentIds: fromTypes.PortalEntity[];
    totalFound: number;
    isLoading: boolean;
    listError: fromTypes.WpError;
  };
  filters: fromTypes.StudentListFilter[];
  staticFilters: fromTypes.StudentListFilter[];
  selectedStudentStatus: fromTypes.rider.StudentListStatus;
  currentSearchQuery: string;
  pagination: {
    currentPage: number;
    pageSize: number;
  };
}

const getInitialAllStudentListsState = (): AllStudentListsState => {
  const result: AllStudentListsState = {};
  fromTypes.StudentListsArray.forEach((listName) => {
    result[listName] = {
      page: {
        listStudentIds: [],
        totalFound: undefined,
        isLoading: false,
        listError: null,
      },
      filters: [],
      staticFilters: [],
      currentSearchQuery: null,
      selectedStudentStatus: fromTypes.rider.StudentListStatus.DEFAULT,
      pagination: {
        currentPage: 0,
        pageSize: fromTypes.ui.DEFAULT_PAGE_SIZE,
      },
    };
  });
  return result;
};

export const studentListReducer = createReducer<AllStudentListsState>(
  getInitialAllStudentListsState(),
  on(fromTypes.cleanUpStore, getInitialAllStudentListsState),
  // set student list state
  on(fromActions.setStudentListState, (allState, action) => {
    const { listName, snapshot } = action;
    const { studentSearchQuery, listStatus, pageSize, currentPage } = snapshot;
    const state = allState[listName];
    const res: AllStudentListsState = {
      ...allState,
      [listName]: {
        ...state,
        currentSearchQuery: studentSearchQuery,
        selectedStudentStatus: listStatus,
        pagination: {
          ...state.pagination,
          pageSize,
          currentPage,
        },
        page: {
          ...state.page,
          totalFound: undefined,
        },
      },
    };
    return res;
  }),
  // clean student list state
  on(fromActions.cleanStudentListState, (allState, action) => {
    const { listName } = action;
    const initialListState = getInitialAllStudentListsState()[listName];
    const res: AllStudentListsState = {
      ...allState,
      [listName]: initialListState,
    };
    return res;
  }),
  // list filter controls
  on(fromActions.studentSearchInputChanged, (allState, action) => {
    const { listName, input } = action;
    const state = allState[listName];
    const res: AllStudentListsState = {
      ...allState,
      [listName]: {
        ...state,
        currentSearchQuery: input,
        pagination: {
          ...state.pagination,
          currentPage: 0,
        },
        page: {
          ...state.page,
          totalFound: undefined,
        },
      },
    };
    return res;
  }),
  // entity filters
  on(fromActions.setStaticFilters, (allState, action) => {
    const { listName, filters } = action;
    const state = allState[listName];
    const oldFilters = state.staticFilters;
    let newState: StudentListState;
    if (fromTypes.utils.arraysEqual(oldFilters, filters)) {
      newState = {
        ...state,
        page: {
          ...state.page,
          totalFound: undefined,
        },
      };
    } else {
      newState = {
        ...getInitialAllStudentListsState()[listName],
        staticFilters: filters,
        page: {
          ...state.page,
          totalFound: undefined,
        },
      };
    }
    const res: AllStudentListsState = {
      ...allState,
      [listName]: newState,
    };
    return res;
  }),
  on(fromActions.studentStatusChanged, (allState, action) => {
    const { status, listName } = action;
    const state = allState[listName];
    const res: AllStudentListsState = {
      ...allState,
      [listName]: {
        ...state,
        selectedStudentStatus: status,
        pagination: {
          ...state.pagination,
          currentPage: 0,
        },
        page: {
          ...state.page,
          totalFound: undefined,
        },
      },
    };
    return res;
  }),
  // pagination
  on(fromActions.currentPageChanged, (allState, action) => {
    const { listName, page } = action;
    const state = allState[listName];
    const res: AllStudentListsState = {
      ...allState,
      [listName]: {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: page,
        },
      },
    };
    return res;
  }),
  on(fromActions.pageSizeChanged, (allState, action) => {
    const { pageSize, listName } = action;
    const state = allState[listName];
    const res: AllStudentListsState = {
      ...allState,
      [listName]: {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: 0,
          pageSize,
        },
      },
    };
    return res;
  }),
  // load list data
  on(fromActions.studentListLoadRequested, (allState, action) => {
    const { listName } = action;
    const state = allState[listName];
    const res: AllStudentListsState = {
      ...allState,
      [listName]: {
        ...state,
        page: {
          ...state.page,
          listStudentIds: [],
          isLoading: true,
        },
      },
    };
    return res;
  }),
  on(fromActions.studentListLoadSuccess, (allState, action) => {
    const { listName, studentIds, totalFound } = action;
    const state = allState[listName];
    const res: AllStudentListsState = {
      ...allState,
      [listName]: {
        ...state,
        page: {
          ...state.page,
          listStudentIds: studentIds,
          totalFound,
          isLoading: false,
        },
      },
    };
    return res;
  }),
  on(fromActions.studentListLoadFailed, (allState, action) => {
    const { listName, error } = action;
    const state = allState[listName];
    const res: AllStudentListsState = {
      ...allState,
      [listName]: {
        ...state,
        page: {
          ...state.page,
          listStudentIds: [],
          totalFound: 0,
          listError: error,
          isLoading: false,
        },
      },
    };
    return res;
  }),
);
