import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DrawerAccessDeniedRouterService } from '../../../router/portal-routes';

export abstract class AbstractCanActivateDrawerFeatureGuard {
  public abstract canActivateFeature: Observable<boolean>;

  public static accessDeniedRouter: DrawerAccessDeniedRouterService;

  public static setAccessDeniedRouter(router: DrawerAccessDeniedRouterService): void {
    this.accessDeniedRouter = router;
  }

  public canActivate(): Observable<boolean> {
    return this.canActivateFeature.pipe(
      take(1),
      map((canActivate) => {
        if (!canActivate) {
          AbstractCanActivateDrawerFeatureGuard.accessDeniedRouter.navigate({});
        }
        return canActivate;
      }),
    );
  }
}
