import { Injectable } from '@angular/core';

import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';

import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';

export type AccountTimezoneErrorRouteData = {};

const statePath = 'session-outdated';

export const accountTimezoneErrorRoute: PortalRoute<AccountTimezoneErrorRouteData> = {
  path: statePath,
  isOnRoute(state: RouterStateUrl): boolean {
    return state && state.url && state.url.startsWith(`/${statePath}`);
  },
  parse(state: RouterStateUrl): AccountTimezoneErrorRouteData {
    return {};
  },
  request(
    data?: AccountTimezoneErrorRouteData,
    queryParamStrategy?: QueryParamsHandlingStrategy,
    replaceUrl?: boolean,
  ): NavigationRequest {
    const path = ['/', statePath];
    const request: NavigationRequest = { path };
    if (queryParamStrategy) {
      request.extras = {
        queryParamsHandling: queryParamStrategy,
      };
    }
    if (replaceUrl) {
      request.extras = { ...(request.extras || {}), replaceUrl };
    }
    return request;
  },
};

@Injectable({ providedIn: 'root' })
export class AccountTimezoneErrorRouterService extends PortalRouterService<AccountTimezoneErrorRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route: PortalRoute<AccountTimezoneErrorRouteData> = accountTimezoneErrorRoute;
}
