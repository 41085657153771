import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SmartFormField, SmartFormFieldImage, smartFormFieldToDisplay, SmartFormFieldType } from '../../dependencies';
import { AddressWithExactLocationValue } from '../../models/smart-address-with-exact-location';
import { StudentProfile } from '@rootTypes/entities/students/student-profile';

@Component({
  selector: 'wp-smart-form-field-display',
  templateUrl: './smart-form-field-display.component.html',
  styleUrls: ['./smart-form-field-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartFormFieldDisplayComponent implements OnChanges {
  @Input() public formField: SmartFormField;
  @Input() public fontSize: string;
  @Input() public fontWeight: string;

  public fieldTypes = SmartFormFieldType;
  public displayValue: string;
  public isEmailFormField: boolean;
  public isMultiLineString: boolean;
  public isImageFormField: boolean;
  public imageFieldPath: string;
  public addressWithExactLocation: AddressWithExactLocationValue;
  public homeAddress: StudentProfile['homeAddress'];

  constructor() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.formField) {
      this.displayValue = smartFormFieldToDisplay(this.formField).value;
    }
    this.isEmailFormField = this.formField?.type === SmartFormFieldType.EMAIL;
    this.isImageFormField = this.formField?.type === SmartFormFieldType.IMAGE;
    if (this.isImageFormField) {
      this.imageFieldPath = (this.formField as SmartFormFieldImage).value;
    }
    this.isMultiLineString = this.formField?.type === SmartFormFieldType.STRING && this.formField.isMultiline;
    if (this.formField.type === SmartFormFieldType.ADDRESS_LOCATION) {
      this.addressWithExactLocation = this.formField.value;
    }
    if (this.formField.type === SmartFormFieldType.HOME_ADDRESS) {
      this.homeAddress = this.formField.value;
    }
  }
}
