import * as fromTypes from '../../../data/rides/types';
import { isRideInUserTimezone } from '@rootTypes/utils/ride/is-ride-in-user-timezone';
import { RideV2 } from '@apiEntities/rides/ride-v2';

export const apiRouteTraceToPortalRouteTraceMapper = (
  source: fromTypes.APIRouteTracePoint,
  ridesV2: RideV2,
): fromTypes.PortalRouteTracePoint => {
  return {
    ...source,
    speedMPH: fromTypes.utils.mpsToMilesPerHour(source.speed),
    displayTime: fromTypes.utils.getDisplayTimeForRouteTrace(source, ridesV2.timezone),
    displayTimeUserTimezone: fromTypes.utils.getDisplayTimeForRouteTraceUserTimezone(source),
    isInUserTimezone: isRideInUserTimezone(ridesV2),
  };
};
