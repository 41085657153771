import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { SmartSelectAsync } from '../../models';

@Component({
  selector: 'wp-smart-select-async',
  templateUrl: './smart-select-async.component.html',
  styleUrls: ['./smart-select-async.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartSelectAsyncComponent implements OnInit, OnDestroy {
  @Input() public model: SmartSelectAsync;

  private sub = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    if (this.model.controlStateChange$) {
      const controlStateSub = this.model.controlStateChange$.subscribe(() => {
        this.cdRef.detectChanges();
      });
      this.sub.add(controlStateSub);
    }
  }

  public onInputFocus(): void {
    if (!this.model.control.disabled) {
      this.model.loadOptions();
    }
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
