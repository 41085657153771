import { combineReducers } from '@ngrx/store';
import * as fromLists from './campus-list.reducers';
import * as fromData from './campus-data.reducers';
import * as fromCalendarData from './campus-calendar-data.reducer';
import * as fromLocationData from './campus-locations-data.reducer';
import * as fromDetails from './campus-details';

export interface CampusesState {
  lists: fromLists.AllCampusListsState;
  campusData: fromData.CampusDataState;
  calendarData: fromCalendarData.CampusCalendarDataState;
  locationData: fromLocationData.CampusLocationsDataState;
  details: fromDetails.CampusDetailsState;
}

export const campusesReducers = combineReducers<CampusesState>({
  lists: fromLists.campusListReducer,
  campusData: fromData.campusDataReducer,
  calendarData: fromCalendarData.campusCalendarDataReducer,
  locationData: fromLocationData.campusLocationDataReducer,
  details: fromDetails.campusDetailsReducer,
});
