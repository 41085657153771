<div
  tabindex="0"
  role="dialog"
  class="wp-popup-page"
  #popupPageEl
  [ngClass]="{
    'non-scrollable': nonScrollable
  }"
>
  <div *ngIf="isProgressBar" class="progress-bar-wrap">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <div class="wp-popup-page__top">
    <ng-content select="wp-popup-page-top"></ng-content>
    <ng-content select="wp-popup-page-top-photo"></ng-content>
  </div>

  <div class="wp-popup-page__content-top">
    <ng-content select="wp-popup-body-top"></ng-content>
  </div>

  <div class="wp-popup-page__content-bottom">
    <ng-content select="wp-popup-body-bottom"></ng-content>
  </div>
  <!-- <div class="wp-popup-content">
    <ng-content select="wp-popup-page-body"></ng-content>
  </div> -->
  <div
    class="wp-popup-page__bottom"
    *ngIf="isPageBottom"
    [ngClass]="{'border-top': isPageBottomBorderTop, 'sticky': isPageBottomPositionSticky}"
    [ngStyle]="{'padding': pageBottomPadding}"
  >
    <ng-content select="wp-popup-page-bottom"></ng-content>
  </div>
</div>
