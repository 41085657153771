import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { ButtonsModule } from 'src/app/shared/buttons/buttons.module';
import { PopupComponent } from './popup/popup.component';
import { DrawerComponent } from './drawer/widget-menu.component';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import { ComponentsModule } from '../../shared/components/components.module';
import { IconCloseModule } from '../../shared/icons/icon-close/icon-close.module';
import { StackLayoutModule } from '../../shared/layouts/stack-layout/stack-layout.module';
import { ApiPromptPopupComponent } from './api-prompt-popup/popup.component';

@NgModule({
  declarations: [DrawerComponent, PopupComponent, ApiPromptPopupComponent, ConfirmPopupComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    RouterModule,
    ButtonsModule,
    ComponentsModule,
    IconCloseModule,
    StackLayoutModule,
  ],
  exports: [PopupComponent, ApiPromptPopupComponent, DrawerComponent],
})
export class PopupableModule {}
