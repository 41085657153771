import { createSelector } from '@ngrx/store';
import { getEmployeesFeature } from './get-feature';

export const getEmployeeDataState = createSelector(getEmployeesFeature, (state) => state.data);

export const getAllEmployeeEntities = createSelector(getEmployeeDataState, (state) => state.employees);

const getSingleEmployeeEntity = createSelector(getAllEmployeeEntities, (state, props: { employeeId: string }) => {
  return state[props.employeeId];
});

export const getEmployee = createSelector(getSingleEmployeeEntity, (state) => state?.entity);

export const getEmployeeLoading = createSelector(getSingleEmployeeEntity, (state) => state?.isLoading || false);

export const getEmployeeError = createSelector(getSingleEmployeeEntity, (state) => state?.error);

// permissions
export const getAllEmployeePermissions = createSelector(getEmployeeDataState, (state) => state.permissions);

// permission options
export const getAllPermissionOptionState = createSelector(getEmployeeDataState, (state) => state.permissionOptions);
