import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorFacade } from '@rootStore';
import { take } from 'rxjs/operators';

import { ApiService } from '../../api/api.service';
import { WpError } from '@rootTypes';
import { RidesDetailsDrawerRouterService } from '../../router/portal-routes/rides/rides-details-drawer-router.service';

@Component({
  selector: 'wp-deeplink',
  template: `<wp-section-spinner [sectionHeight]="'100%'"></wp-section-spinner>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeeplinkComponent implements OnInit {
  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private errorService: ErrorFacade,
    private rideDetailsDrawer: RidesDetailsDrawerRouterService,
  ) {}

  public ngOnInit(): void {
    this.redirect();
  }

  private async redirect(): Promise<void> {
    const { screen, rideDisplayId } = this.activatedRoute.snapshot.queryParams;
    if (screen === 'ride-details' && !!rideDisplayId) {
      try {
        const { rideId } = await this.api.getRideId({ rideDisplayId }).pipe(take(1)).toPromise();
        await this.router.navigateByUrl(this.rideDetailsDrawer.getHref({ rideId }));
      } catch (originalError) {
        const error: WpError = {
          text: `Unable to find ride id for ride #${rideDisplayId}`,
          originalError,
        };
        this.errorService.goErrorPage(error);
      }
      return;
    }

    await this.router.navigate(['/page-not-found']);
  }
}
