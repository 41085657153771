import { Injectable } from '@angular/core';
import * as fromTypes from '../../employees-vendor/types';
import { Observable } from 'rxjs/internal/Observable';
import * as fromApi from '../../../api';
import { ImageApiService } from '../../../api';
import { map } from 'rxjs/operators';
import { ApiService } from '../../../api/api.service';
import { entitySearchResultToPortalEntity } from '@rootTypes/utils/portal-entity/entity-search-result-to-portal-enity';

interface LoadListResponse {
  total: number;
  items: fromTypes.PortalEntity[];
}

interface LoadEmployeeResponse {
  employee: fromTypes.SchoolEmployee;
  permissions?: fromTypes.EmployeePermission[];
}

@Injectable()
export class SchoolEmployeeApiService {
  constructor(
    private apiService: ApiService,
    private imageApi: ImageApiService,
  ) {}

  public getEmployee(employeeId: string, options?: fromTypes.LoadEmployeeOptions): Observable<LoadEmployeeResponse> {
    const request: fromApi.getSchoolEmployee.GetSchoolEmployeeRequest = {
      schoolEmployeeId: employeeId,
    };
    const projections = [];
    if (options?.withPermissions) {
      projections.push(fromApi.getSchoolEmployee.ApiSchoolEmployeeProjection.permissions);
      projections.push(fromApi.getSchoolEmployee.ApiSchoolEmployeeProjection.campuses);
    }
    if (projections.length) {
      request._projections = projections;
    }
    return this.apiService.getSchoolEmployee(request).pipe(
      map((res) => {
        const result: LoadEmployeeResponse = {
          employee: fromApi.getSchoolEmployee.convertors.getEmployeeFromApiSchoolEmployee(res.schoolEmployee),
        };
        if (res._projections && res._projections?.permissions) {
          let permissions = res._projections.permissions.map((source) => {
            return {
              entityId: source.campusId,
            };
          });
          if (res._projections.campuses) {
            permissions = permissions.map((source) => {
              return {
                ...source,
                entityName: res._projections.campuses.find((campus) => campus.id === source.entityId)?.name,
              };
            });
          }
          result.permissions = permissions;
        }
        return result;
      }),
    );
  }

  public entityFilter(request: fromApi.entityFilter.EntityFilterRequest): Observable<LoadListResponse> {
    return this.apiService.entityFilter(request).pipe(
      map((resp) => {
        return {
          total: resp.total,
          items: (resp.results || []).map((source) => entitySearchResultToPortalEntity(source)),
        };
      }),
    );
  }

  public getPermissionOptions(request: fromApi.entityFilter.EntityFilterRequest): Observable<fromTypes.PortalEntity[]> {
    return this.apiService.entityFilter(request).pipe(
      map((resp) => {
        return (resp.results || []).map((source) => entitySearchResultToPortalEntity(source));
      }),
    );
  }

  public createEmployee(
    request: fromApi.createSchoolEmployee.CreateSchoolEmployeeRequest,
  ): Observable<fromTypes.SchoolEmployee> {
    return this.apiService.createSchoolEmployee(request).pipe(
      map((resp) => {
        return fromApi.createSchoolEmployee.convertors.getEmployeeFromApiSchoolEmployee(resp.schoolEmployee);
      }),
    );
  }

  public updateEmployeePermissions(
    request: fromApi.updateSchoolEmployeePermissions.UpdateSchoolEmployeePermissionsRequest,
  ): Observable<fromApi.updateSchoolEmployeePermissions.UpdateSchoolEmployeePermissionsResponse> {
    return this.apiService.updateSchoolEmployeePermissions(request);
  }

  public updateEmployee(
    request: fromApi.updateSchoolEmployee.UpdateSchoolEmployeeRequest,
  ): Observable<fromTypes.SchoolEmployee> {
    return this.apiService.updateSchoolEmployee(request).pipe(
      map((res) => {
        return fromApi.updateSchoolEmployee.convertors.getEmployeeFromApiSchoolEmployee(res.schoolEmployee);
      }),
    );
  }

  public uploadPhoto(base64: string): Observable<string> {
    return this.imageApi.uploadImage(base64);
  }
}
