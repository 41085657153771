import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { AuthRoutingModule } from './auth-routing.module';
import { containers } from './containers';
import { FormControlsModule } from '../shared/form-controls/form-controls.module';
import { ButtonsModule } from '../shared/buttons/buttons.module';
import { AlertsModule } from '../shared/alerts/alerts.module';
import { MatSelectModule } from '@angular/material/select';
import { PhotosModule } from '../shared/photos/photos.module';
import { ComponentsModule } from '../shared/components/components.module';
import { AuthWrapperComponent } from './components/auth-wrapper/auth-wrapper.component';
import { ResetPasswordSuccessComponent } from './containers/reset-password-success/reset-password-success.component';
import * as fromFacades from './store/facades';
import { TypographyModule } from '../shared/typography/typography.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SelectAccountFormComponent } from './components/select-account-form/select-account-form.component';
import { SmartFormsModule } from '../shared/smart-forms/smart-forms.module';
import { CharterLoginComponent } from './containers/charter-login/charter-login.component';

@NgModule({
  declarations: [
    ...containers,
    AuthWrapperComponent,
    ResetPasswordSuccessComponent,
    SelectAccountFormComponent,
    CharterLoginComponent,
  ],
  providers: [...fromFacades.featureFacades],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormControlsModule,
    ButtonsModule,
    AlertsModule,
    MatSelectModule,
    MatSnackBarModule,
    PhotosModule,
    ComponentsModule,
    ReactiveFormsModule,
    TypographyModule,
    SmartFormsModule,
  ],
  exports: [SelectAccountFormComponent],
})
export class AuthModule {}
