import { combineReducers } from '@ngrx/store';

import { studentChangeRequestRootReducer, StudentChangeRequestRootState } from './student-change-request-root.reducer';
import {
  studentChangeRequestReviewReducer,
  StudentChangeRequestReviewState,
} from './student-change-request-review.reducer';
import {
  studentChangeRequestProcessedDetailsReducer,
  StudentChangeRequestProcessedDetailsState,
} from './student-change-request-processed-details.reducer';

export interface StudentChangeRequestsState {
  root: StudentChangeRequestRootState;
  review: StudentChangeRequestReviewState;
  processedDetails: StudentChangeRequestProcessedDetailsState;
}

export const studentChangeRequestsFeatureReducer = combineReducers<StudentChangeRequestsState>({
  root: studentChangeRequestRootReducer,
  review: studentChangeRequestReviewReducer,
  processedDetails: studentChangeRequestProcessedDetailsReducer,
});
