export const getPolygonCenter = (polygon: google.maps.Polygon): google.maps.LatLng => {
  const path = polygon.getPath().getArray();

  let latSum = 0,
    lngSum = 0;

  for (let i = 0; i < path.length; i++) {
    latSum += path[i].lat();
    lngSum += path[i].lng();
  }

  const latCenter = latSum / path.length;
  const lngCenter = lngSum / path.length;

  return new google.maps.LatLng({ lat: latCenter, lng: lngCenter });
};
