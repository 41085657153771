import { Component, OnInit, ChangeDetectionStrategy, Input, SimpleChanges, TemplateRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'wp-list-result-bar-full',
  templateUrl: './list-result-bar-full.component.html',
  styleUrls: ['./list-result-bar-full.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListResultBarFullComponent implements OnInit {
  @Input() entityName = 'item';
  @Input() public customPluralForm: string;
  @Input() maxQuantity = 500;
  @Input() public totalFound: number;
  @Input() public pageSize: number;
  @Input() public currentPage: number;
  @Input() public centerContent?: TemplateRef<any>;
  @Input() public rightContent?: TemplateRef<any>;
  @Input() public customTotalFoundHtml?: string;
  @Input() public totalFoundFontSize = '20px';

  public showingFrom: number;
  public showingTo: number;
  public customTotalFoundSafeHtml?: SafeHtml;

  constructor(private sanitized: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalFound || changes.pageSize || changes.currentPage) {
      this.updatePageLimits();
    }
    if (changes.customTotalFoundHtml) {
      this.customTotalFoundSafeHtml = this.customTotalFoundHtml
        ? this.sanitized.bypassSecurityTrustHtml(this.customTotalFoundHtml)
        : undefined;
    }
  }

  ngOnInit(): void {}

  private updatePageLimits(): void {
    this.showingFrom = this.getShowingFrom(this.pageSize, this.currentPage);
    this.showingTo = this.getShowingTo(this.pageSize, this.currentPage, this.totalFound);
  }

  private getShowingFrom(pageSize: number, currentPage: number): number {
    return currentPage * pageSize + 1;
  }

  private getShowingTo(pageSize: number, currentPage: number, totalItemsFound: number): number {
    return Math.min(currentPage * pageSize + pageSize, totalItemsFound);
  }
}
