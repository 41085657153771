import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import * as fromAuthSelectors from '../../auth/store/selectors';
import { selectedAccountName } from '../../auth/store/selectors';
import * as fromUISelectors from '../../store/selectors/home.selectors';
import * as fromUIActions from '../../store/actions/home-ui-actions';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { PopupableService } from 'src/app/core/popupable/popupable.service';
import { DrawerRef } from 'src/app/core/popupable/types/drawer-ref';
import { LocalStorageService, WindowTitleService } from '../../core/services';
import { SegmentManagerService } from 'src/app/segment/store';
import { UserRole } from '@rootTypes/entities/auth';
import { setUserRole } from '../../auth/store';
import { AbstractCanActivateDrawerFeatureGuard, AbstractCanActivateFeatureGuard } from '@rootTypes/utils';
import { AccessDeniedRouterService, DrawerAccessDeniedRouterService } from '../../router/portal-routes';
import { BufferPolyfill } from '@rootTypes/utils/common/buffer-polyfill';
import { AppVersionUpdateService } from '../../core/services/app-version-update.service';
import { GotoPageService } from '../../core/goto-page/goto-page.service';

@Component({
  selector: 'wp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public accountLoading$: Observable<boolean>;
  public isGoToPage$: Observable<boolean>;
  public isGotoNavigating$: Observable<boolean>;

  constructor(
    private store: Store<State>,
    private activatedRoute: ActivatedRoute,
    private loacalStorage: LocalStorageService,
    private popupableService: PopupableService,
    private windowTitleService: WindowTitleService,
    private segment: SegmentManagerService,
    private accessDeniedRouter: AccessDeniedRouterService,
    private drawerAccessDeniedRouter: DrawerAccessDeniedRouterService,
    private appVersionUpdateService: AppVersionUpdateService,
    public gotoPageService: GotoPageService,
  ) {}

  public ngOnInit(): void {
    // buffer polyfill
    (window as any).Buffer = BufferPolyfill;
    AbstractCanActivateFeatureGuard.setAccessDeniedRouter(this.accessDeniedRouter);
    AbstractCanActivateDrawerFeatureGuard.setAccessDeniedRouter(this.drawerAccessDeniedRouter);
    this.accountLoading$ = this.store.select(fromAuthSelectors.homeGuardCheckAccountLoading);

    this.setWindowTitleOnAccountChange();

    // For testing purposes of Select Account feature
    this.activatedRoute.queryParams.subscribe((params) => {
      const accountsPreset: string | void = params.accountsPreset;
      if (accountsPreset) {
        if (accountsPreset === 'null') {
          this.loacalStorage.remove('accountsPreset');
        } else if (accountsPreset.length >= 1 && accountsPreset.length <= 3) {
          this.loacalStorage.set('accountsPreset', accountsPreset);
        }
      }
    });
    this.setDrawerObservable();

    this.segment.initialize();

    this.writeUserRoleToStore();

    this.appVersionUpdateService.onAppInit();

    this.isGoToPage$ = this.gotoPageService.isGoToPageOpen$;
    this.isGotoNavigating$ = this.gotoPageService.isNavigating$;
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.key === 'e' && event.metaKey) {
        this.gotoPageService.open();
        event.preventDefault();
        event.stopPropagation();
      }
    });
  }

  public onDrawerMaskClicked(): void {
    this.store.dispatch(fromUIActions.drawerClosedSelected());
  }

  /**
   * Maps store state to the drawer state
   */
  private setDrawerObservable(): void {
    let currentDrawerRef: DrawerRef;
    this.store
      .select(fromUISelectors.drawerOpened)
      .pipe(
        // when drawer state changes in the store
        distinctUntilChanged(),
        switchMap((isOpened) => {
          return this.store.select(fromUISelectors.isHalfDrawer).pipe(map((isHalfDrawer) => [isOpened, isHalfDrawer]));
        }),
        switchMap(([isOpened, isHalfDrawer]) => {
          // if open
          //      - open the drawer with the special service,
          //      - save drawer reference (to be able to close it later)
          //      - listen for closed$ event of the drawerReference,
          //      - and update the store, when the drawer is closed
          if (isOpened) {
            currentDrawerRef = this.popupableService.openDrawer({ isHalfDrawer });
            return currentDrawerRef.closed$().pipe(
              tap(() => {
                currentDrawerRef = null;
                this.store.dispatch(fromUIActions.drawerClosedSelected());
              }),
            );
            // if closed
            //      - close the drawer, if drawer reference was previosly saved
          } else {
            if (currentDrawerRef) {
              currentDrawerRef.close();
            }
            return of(null);
          }
        }),
      )
      .subscribe();
  }

  private writeUserRoleToStore(): void {
    const userRole = wpEnvironment.userRole as UserRole;
    this.store.dispatch(setUserRole({ userRole }));
  }

  private setWindowTitleOnAccountChange(): void {
    if (wpEnvironment.userRole === 'admin') {
      this.windowTitleService.setTitle('Zum Portal - Admin');
      return;
    }

    this.store.select(selectedAccountName).subscribe((accountName) => {
      const title = accountName ? `Zum Portal - ${accountName}` : 'Zum Portal';
      this.windowTitleService.setTitle(title);
    });
  }
}
