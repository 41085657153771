import { Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import Typed from 'typed.js';

@Directive({
  selector: '[wpTyped]',
})
export class TypedDirective implements OnChanges, OnDestroy {
  @Input('wpTyped')
  public text: string;

  @Input()
  public typeSpeed: number = 30;

  private typedInstance: Typed;

  constructor(private readonly elementRef: ElementRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.text || changes.speed) {
      this.typedInstance?.destroy();

      this.typedInstance = new Typed(this.elementRef.nativeElement, {
        strings: [this.text],
        typeSpeed: this.typeSpeed,
      });
    }
  }

  public ngOnDestroy(): void {
    this.typedInstance?.destroy();
  }
}
