import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-rich-option-card',
  templateUrl: './rich-option-card.component.html',
  styleUrls: ['./rich-option-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichOptionCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
