import { Injectable } from '@angular/core';

import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { workQueuePath } from './work-queue-path';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '@rootTypes/entities/router';
import { deserializeBaseParamsFromUrl } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';
import { serializeBaseParamsToUrl } from '@rootTypes/utils/list-store-utils/serialize-base-params-to-url';
import { getRoutingRequest } from '@rootTypes/utils/router/get-routing-request';
import { StudentRfidListParams } from '../../../features/common/student-rfid-common/types/student-rfid-list';
import { deserializeStudentRfidListRequestTypeFilter } from '../../../features/common/student-rfid-common/utils/deserialize-student-rfid-list-request-type-filter';
import { deserializeStudentRfidListStatusFilter } from '../../../features/common/student-rfid-common/utils/deserialize-student-rfid-list-status-filter';
import { createInitialStudentRfidListState } from '../../../features/common/student-rfid-common/utils/create-initial-student-rfid-list-state';
import { createStudentRfidListStatusFilter } from '../../../features/common/student-rfid-common/utils/create-student-rfid-list-status-filter';
import { StudentRfidStatus } from '@apiEntities';

export type WorkQueueStudentRfidListRouteData = StudentRfidListParams;

const statePath = 'rfid-cards';
const queryPrefix = 'wq-rfid';

export const workQueueStudentRfidListRoute: PortalRoute<WorkQueueStudentRfidListRouteData> = {
  path: statePath,

  isOnRoute: (state: RouterStateUrl) => {
    return state && state.url && state.url.startsWith(`/${workQueuePath}/${statePath}`);
  },

  parse: (state: RouterStateUrl): WorkQueueStudentRfidListRouteData => {
    const query = state?.queryParams || {};
    const data = deserializeBaseParamsFromUrl<StudentRfidListParams>(query, queryPrefix, [
      deserializeStudentRfidListStatusFilter,
      deserializeStudentRfidListRequestTypeFilter,
    ]);
    if (!data.filters) {
      data.filters = [createStudentRfidListStatusFilter(StudentRfidStatus.PENDING)];
    }
    return data as StudentRfidListParams;
  },

  request: (
    data?: WorkQueueStudentRfidListRouteData,
    queryParamStrategy?: QueryParamsHandlingStrategy,
    replaceUrl?: boolean,
  ): NavigationRequest => {
    const path = ['/', workQueuePath, statePath];
    const query = data ? serializeBaseParamsToUrl(data, createInitialStudentRfidListState().params, queryPrefix) : {};
    return getRoutingRequest(path, query, queryParamStrategy, replaceUrl);
  },

  getHref(data?: WorkQueueStudentRfidListRouteData): string {
    return `/${workQueuePath}/${statePath}`;
  },
};

@Injectable({
  providedIn: 'root',
})
export class WorkQueueStudentRfidListRouterService extends PortalRouterService<WorkQueueStudentRfidListRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route = workQueueStudentRfidListRoute;
}
