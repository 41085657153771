import { createSelector } from '@ngrx/store';
import { getEmployeesFeature } from './get-feature';
import * as fromTypes from '../../types';
import { EmployeeListState } from '../reducers/employee-list.reducer';

const getAllListsState = createSelector(getEmployeesFeature, (state) => state.lists);

export const getListState = createSelector(
  getAllListsState,
  (state, props: { listName: fromTypes.EmployeeListName }): EmployeeListState => {
    return state[props.listName];
  },
);

/**
 * Is list currently displayed
 */
export const getListActive = createSelector(getListState, (state) => state.isActive);

/**
 * Pagination
 */
export const getPaginationState = createSelector(getListState, (state) => state.pagination);

export const getPage = createSelector(getPaginationState, (state) => state.page);

export const getPageSize = createSelector(getPaginationState, (state) => state.pageSize);

/**
 * Page load
 */
export const getPageState = createSelector(getListState, (state) => state.page);

export const getListLoading = createSelector(getPageState, (state) => state.isLoading);

export const getListItems = createSelector(getPageState, (state) => state.items);

export const getListError = createSelector(getPageState, (state) => state.listError);

export const getTotalFound = createSelector(getPageState, (state) => state.totalFound);

/**
 * Search
 */
export const getCurrentSearch = createSelector(getListState, (state) => state.currentSearch);

/**
 * Status
 */
export const getCurrentStatus = createSelector(getListState, (state) => state.status);

/**
 * Selected employee
 */
export const getSelectedEmployeeId = createSelector(getListState, (state) => state.selectedEmployeeId);
