<wp-page>
  <wp-page-top>
    <wp-page-top-title>No view permissions</wp-page-top-title>
  </wp-page-top>
  <wp-page-body>
    <wp-grey-label>
      Your account has no permissions to view data in the portal. Please contact
      your administrator.
    </wp-grey-label>
  </wp-page-body>
</wp-page>
