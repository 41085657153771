import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-text-divider-dot',
  templateUrl: './text-divider-dot.component.html',
  styleUrls: ['./text-divider-dot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextDividerDotComponent {
  @Input() public containerHeight = '20px';
  @Input() public margin: string;
}
