import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { take } from 'rxjs/operators';

import { PopupableService } from 'src/app/core/popupable/popupable.service';
import {
  NewImageBase64,
  PhotoInputPopupComponent,
  PhotoInputPopupConfig,
} from '../photo-input-popup/photo-input-popup.component';
import { PlaceholderType } from '../types';

@Component({
  selector: 'wp-photo-input-sync',
  templateUrl: './photo-input-sync.component.html',
  styleUrls: ['./photo-input-sync.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoInputSyncComponent implements OnInit {
  /**
   * FormControl with imageBase64 value and Validators.required if needed
   */
  @Input() public control: UntypedFormControl;
  @Input() public label: string;
  @Input() public uploadImageText = 'Upload image';
  @Input() public updateImageText = 'Update image';
  @Input() public imageForm: 'circle' | 'rectangle' = 'circle';
  @Input() public imagePlaceholder: PlaceholderType = PlaceholderType.PERSON;
  /**
   * If ommitted the default width will be used:
   * 132 for a circle (square) and 184 for a rectangle image
   */
  @Input() public imagePreviewWidthPx?: number;
  /**
   * If ommitted the default width will be used:
   * 600 for a circle (square) and 640 for a rectangle image
   */
  @Input() public imageResultWidthPx?: number;

  public buttonText: string;
  public isRequiredErrorShown = false;

  public isRoundPreview: boolean;
  public previewWidth: string;
  public previewHeight: string;
  public previewImageBase64: string;

  constructor(
    private popupableService: PopupableService,
    private cdRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.setButtonText();
    this.isRoundPreview = this.imageForm === 'circle';
    let previewWidthPx: number;
    let previewHeightPx: number;
    if (this.isRoundPreview) {
      previewWidthPx = this.imagePreviewWidthPx || 132;
      previewHeightPx = previewWidthPx;
    } else {
      previewWidthPx = this.imagePreviewWidthPx || 184;
      previewHeightPx = (previewWidthPx * 3) / 4;
    }
    this.previewWidth = `${previewWidthPx}px`;
    this.previewHeight = `${previewHeightPx}px`;
    this.previewImageBase64 = this.control.value || '';
  }

  public open(): void {
    const config: PhotoInputPopupConfig = {
      isSquareImage: this.imageForm === 'circle',
      imagePlaceholder: this.imagePlaceholder,
      sourceImageBase64: this.control.value || '',
    };
    if (this.imageResultWidthPx) {
      config.resultImageWidthPx = this.imageResultWidthPx;
    }
    this.popupableService
      .openPopup<any, PhotoInputPopupConfig, NewImageBase64>(PhotoInputPopupComponent, config, {
        contentZIndex: 11,
        pageMaskZIndex: 10,
      })
      .pipe(take(1))
      .subscribe((newImageBase64) => {
        this.control.markAsTouched();
        if (newImageBase64) {
          this.isRequiredErrorShown = false;
          this.previewImageBase64 = newImageBase64;
          this.control.setValue(newImageBase64);
          this.control.markAsDirty();
        } else {
          this.isRequiredErrorShown = this.control.hasError('required');
        }
        this.setButtonText();
        this.cdRef.detectChanges();
      });
  }

  public setButtonText(): void {
    this.buttonText = this.control.value ? this.updateImageText : this.uploadImageText;
  }
}
