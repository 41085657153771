/**
 * Types
 */
export type IndexDbConfig<DbStructure extends Record<string, any>> = {
  version: number;
  dbName: string;
  tables: {
    [N in keyof DbStructure]: TableConfig<DbStructure, N>;
  };
  logs?: boolean;
};

export interface TableConfig<DbStructure, T extends keyof DbStructure> {
  tableName: T;
  options?: IDBObjectStoreParameters & {
    // provide index to be able to query items
    indexes?: {
      [fieldName in keyof DbStructure[T]]?: IDBIndexParameters;
    };
  };
}

export interface IndexedDbQuery<D> {
  field?: keyof D; // if absent, will query by id
  equalTo?: number | string;
  upperBound?: number | string;
  upperInclusive?: boolean;
  lowerBound?: number | string;
  lowerInclusive?: boolean;
  direction?: IDBCursorDirection; // next by default
  limit?: number;
  removeIf?: (key: string, item: D) => boolean;
  updateIf?: (key: string, item: D) => D | null; // will also update the field, if this function returns a non-null value
}

export interface QueryResult<D> {
  key: string;
  data: D;
}

type IndexDbConnectionState = {
  database?: IDBDatabase;
  error?: any;
};

export type BatchOperationItem<DbStructure> =
  | GetOperationItem<DbStructure, keyof DbStructure>
  | QueryOperationItem<DbStructure, keyof DbStructure>
  | UpsertOperationItem<DbStructure, keyof DbStructure>
  | RemoveOperationItem<DbStructure, keyof DbStructure>;

export type GetOperationItem<DbStructure, T extends keyof DbStructure> = {
  tableName: T;
  operation: 'get';
  key: string;
};

export type QueryOperationItem<DbStructure, T extends keyof DbStructure> = {
  tableName: keyof DbStructure;
  operation: 'query';
  q: IndexedDbQuery<DbStructure[T]>;
};

export type UpsertOperationItem<DbStructure, T extends keyof DbStructure> = {
  tableName: T;
  operation: 'upsert';
  key: string;
  value: DbStructure[T];
};

export type RemoveOperationItem<DbStructure, T extends keyof DbStructure> = {
  tableName: T;
  operation: 'remove';
  key: string;
};

export type IndexedDbWrap<DbStructure extends Record<string, any>> = {
  get: <T extends keyof DbStructure>(tableName: T, key: string) => Promise<DbStructure[T]>;
  getAll: <T extends keyof DbStructure>(tableName: T) => Promise<DbStructure[T][]>;
  query: <T extends keyof DbStructure>(
    tableName: T,
    q: IndexedDbQuery<DbStructure[T]>,
  ) => Promise<QueryResult<DbStructure[T]>[]>;
  // omit key, if autogenerated
  upsert: <T extends keyof DbStructure>(tableName: T, value: DbStructure[T], key?: string) => Promise<void>;
  remove: <T extends keyof DbStructure>(tableName: T, key: string) => Promise<void>;
  batch: (items: BatchOperationItem<DbStructure>[]) => Promise<any[]>;
  clearTables: <T extends keyof DbStructure>(tableName: T[]) => Promise<void>;
  getQuotaUsePercent: () => Promise<number>;
  clearAllData: () => Promise<void>;
};

/**
 * Utils
 */

let db: IndexDbConnectionState;

const initDb = (config: IndexDbConfig<any>): Promise<IndexDbConnectionState> => {
  const log = config.logs ?? false;
  return new Promise<IndexDbConnectionState>((resolve) => {
    const { dbName, version, tables } = config;
    const req: IDBOpenDBRequest = indexedDB.open(dbName, version);
    req.onsuccess = function (evt) {
      const database = this.result;
      log && console.log('[IndexedDB]: Successfully connected to indexDb');
      resolve({ database });
    };
    req.onerror = function (evt) {
      resolve({ error: evt });
      log && console.error('[IndexedDB]: Failed to connect to indexDb', (evt.target as any).errorCode);
    };

    req.onupgradeneeded = function (evt) {
      log && console.log('[IndexedDB]: Upgrade needed. Creating indexDb schema...');
      const database: IDBDatabase = (evt.currentTarget as any).result;
      const storesLength = database.objectStoreNames.length;
      for (let i = 0; i < storesLength; i++) {
        try {
          const storeName = database.objectStoreNames.item(0);
          database.deleteObjectStore(storeName);
          log && console.log('[IndexedDb]: Removed store ', storeName);
        } catch (error) {
          log && console.error('Error deleting object store: ', database.objectStoreNames.item(i), ' ', error);
        }
      }
      Object.keys(tables).map((tableName: string) => {
        const tableConfig = tables[tableName];
        let { options } = tableConfig;
        if (!options) {
          options = { autoIncrement: false };
        }
        const store = database.createObjectStore(tableName, {
          keyPath: options.keyPath,
          autoIncrement: options.autoIncrement,
        });
        if (options.indexes) {
          Object.keys(options.indexes).forEach((indexedKey) => {
            store.createIndex(indexedKey, indexedKey, options.indexes[indexedKey]);
          });
        }
        if (!store.transaction.oncomplete) {
          store.transaction.oncomplete = function (event) {
            log && console.log('[IndexDB]: Successfully created tables: ', Object.keys(tables).join(', '));
          };
          store.transaction.onerror = function (event) {
            log && console.error('[IndexDB]: Error creating tables');
          };
        }
      });
    };
  });
};

const resolveDb = async (config: IndexDbConfig<any>): Promise<IndexDbConnectionState> => {
  if (!db) {
    db = await initDb(config);
  }
  if (!db.database) {
    throw new Error('[IndexedDB]: Failed to connect to indexDb');
  }
  return db;
};

const get = async <T>(tableName: string, key: string, transaction: IDBTransaction): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    const objectStore = transaction.objectStore(tableName);
    const request = objectStore.get(key);
    request.onsuccess = () => {
      resolve(request.result as T);
    };
    request.onerror = (event) => {
      reject(event);
    };
  });
};

const getAll = async <T>(tableName: string, transaction: IDBTransaction): Promise<T[]> => {
  return new Promise<T[]>((resolve, reject) => {
    const objectStore = transaction.objectStore(tableName);
    const request = objectStore.getAll();
    request.onsuccess = () => {
      resolve(request.result as T[]);
    };
    request.onerror = (event) => {
      reject(event);
    };
  });
};

const query = async <T>(
  tableName: string,
  q: IndexedDbQuery<T>,
  transaction: IDBTransaction,
): Promise<QueryResult<T>[]> => {
  const {
    field,
    equalTo,
    upperBound,
    upperInclusive,
    lowerBound,
    lowerInclusive,
    direction,
    limit,
    removeIf,
    updateIf,
  } = q;
  const bounds = equalTo
    ? IDBKeyRange.only(equalTo)
    : upperBound && lowerBound
      ? IDBKeyRange.bound(lowerBound, upperBound, !lowerInclusive, !upperInclusive)
      : lowerBound
        ? IDBKeyRange.lowerBound(lowerBound, !lowerInclusive)
        : upperBound
          ? IDBKeyRange.upperBound(upperBound, !upperInclusive)
          : undefined;
  return new Promise<QueryResult<T>[]>((resolve, reject) => {
    const store = transaction.objectStore(tableName);
    if (field && !store.indexNames.contains(field as string)) {
      reject(
        new Error(
          `[IndexDBWrap]: Trying to query by field ${field as string}, but no index by field ${field as string} found. Create index in the table config first or query by id.`,
        ),
      );
    }
    const request = store.indexNames.contains(field as string)
      ? store.index(field as string).openCursor(bounds, direction ?? 'next')
      : store.openCursor(bounds, direction ?? 'next');
    const result: QueryResult<T>[] = [];
    request.onsuccess = function () {
      const cursor = request.result;
      if (cursor && (!limit || result.length <= limit)) {
        const key = cursor.primaryKey as string;
        const value = cursor.value;
        result.push({ key, data: value });
        if (removeIf && removeIf(key, value)) {
          cursor.delete();
        }
        if (updateIf) {
          const toUpdate = updateIf(key, value);
          if (toUpdate) {
            cursor.update(toUpdate);
          }
        }
        cursor.continue();
      } else {
        resolve(result);
      }
    };
    request.onerror = function (error) {
      reject(error);
    };
  });
};

const upsert = <T>(tableName: string, key: string | null, value: T, transaction: IDBTransaction): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const objectStore = transaction.objectStore(tableName);
    const request = key ? objectStore.put(value, key) : objectStore.put(value);
    request.onsuccess = function () {
      resolve();
    };
    request.onerror = function (error) {
      reject(error);
    };
  });
};

const remove = (tableName: string, key: string, transaction: IDBTransaction): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const store = transaction.objectStore(tableName);
    const request = store.delete(key);
    request.onsuccess = () => {
      resolve();
    };
    request.onerror = (error) => {
      reject(error);
    };
  });
};
const clearTables = (tableNames: string[], transaction: IDBTransaction) => {
  return new Promise<void>((resolve, reject) => {
    // report on the success of the transaction completing, when everything is done
    transaction.oncomplete = function (event) {
      resolve();
    };

    transaction.onerror = function (event) {
      reject(event);
    };

    tableNames.forEach((table) => {
      // create an object store on the transaction
      const objectStore = transaction.objectStore(table);

      // Make a request to clear all the data out of the object store
      objectStore.clear();
    });
  });
};

const batch = async (items: BatchOperationItem<any>[], transaction: IDBTransaction): Promise<any[]> => {
  const results = [];
  for (const item of items) {
    if (item.operation === 'get') {
      results.push(await get(item.tableName as string, item.key, transaction));
    } else if (item.operation === 'query') {
      results.push(await query(item.tableName as string, item.q, transaction));
    } else if (item.operation === 'upsert') {
      results.push(await upsert(item.tableName as string, item.key ?? null, item.value, transaction));
    } else if (item.operation === 'remove') {
      results.push(await remove(item.tableName as string, item.key, transaction));
    }
  }
  return results;
};

/**
 * Exports
 */
export const getIndexedDbWrap = async <DbStructure extends Record<string, any>>(
  config: IndexDbConfig<DbStructure>,
): Promise<IndexedDbWrap<DbStructure>> => {
  const db = await resolveDb(config);
  return {
    get: <T extends keyof DbStructure>(tableName: T, key: string): Promise<DbStructure[T]> => {
      const transaction = db.database.transaction([tableName as string], 'readonly');
      return get(tableName as string, key, transaction);
    },

    getAll: <T extends keyof DbStructure>(tableName: T): Promise<DbStructure[T][]> => {
      const transaction = db.database.transaction([tableName as string], 'readonly');
      return getAll(tableName as string, transaction);
    },
    query: <T extends keyof DbStructure>(
      tableName: T,
      q: IndexedDbQuery<DbStructure[T]>,
    ): Promise<QueryResult<DbStructure[T]>[]> => {
      const transaction = db.database.transaction([tableName as string], 'readwrite');
      return query(tableName as string, q, transaction);
    },
    upsert: <T extends keyof DbStructure>(tableName: T, value: DbStructure[T], key?: string): Promise<void> => {
      const transaction = db.database.transaction([tableName as string], 'readwrite');
      return upsert(tableName as string, key ?? null, value, transaction);
    },
    remove: <T extends keyof DbStructure>(tableName: T, key: string): Promise<void> => {
      const transaction = db.database.transaction([tableName as string], 'readwrite');
      return remove(tableName as string, key, transaction);
    },
    batch: async (items: BatchOperationItem<DbStructure>[]): Promise<any[]> => {
      const tableNames = Array.from(new Set(items.map((item) => item.tableName)));
      if (!tableNames.length) {
        return [];
      }
      const transactionType = items.some(
        (it) => it.operation === 'query' || it.operation === 'upsert' || it.operation === 'remove',
      )
        ? 'readwrite'
        : 'readonly';
      const transaction = db.database.transaction(tableNames as string[], transactionType);
      return batch(items, transaction);
    },
    clearTables: <T extends keyof DbStructure>(tableNames: T[]): Promise<void> => {
      const transaction = db.database.transaction(tableNames as string[], 'readwrite');
      return clearTables(tableNames as string[], transaction);
    },
    getQuotaUsePercent: async (): Promise<number> => {
      if ('navigator' in window && navigator.storage && navigator.storage.estimate) {
        const estimate = await navigator.storage.estimate();
        if (estimate['usageDetails'] && estimate['usageDetails'].indexedDB) {
          return estimate['usageDetails'].indexedDB / estimate.quota;
        }
        return estimate.usage / estimate.quota;
      } else {
        return undefined;
      }
    },
    clearAllData: (): Promise<void> => {
      const tables = Object.keys(config.tables);
      // open a read/write db transaction, ready for clearing the data
      return new Promise<void>((resolve, reject) => {
        const transaction = db.database.transaction(tables, 'readwrite');
        // report on the success of the transaction completing, when everything is done
        transaction.oncomplete = function (event) {
          config.logs && console.log('[IndexedDB]: Successfully cleaned data');
          resolve();
        };
        transaction.onerror = function (event) {
          reject(event);
        };
        tables.forEach((table) => {
          // create an object store on the transaction
          const objectStore = transaction.objectStore(table);
          // Make a request to clear all the data out of the object store
          objectStore.clear();
        });
      });
    },
  };
};
