import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';

import { ErrorPopupService } from '../../store/services/error-popup.service';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class CharterLoginService {
  constructor(
    private api: ApiService,
    private errorPopup: ErrorPopupService,
  ) {}

  public async loginToCharter(isOpenInNewTab: boolean, charterLandingPath?: string): Promise<{ isSuccess: boolean }> {
    try {
      const res = await this.api.getCharterAccessUrl().pipe(take(1)).toPromise();
      let url: string;
      if (charterLandingPath) {
        url = `${res.url}&target=${encodeURIComponent(charterLandingPath)}`;
      } else {
        url = res.url;
      }
      if (isOpenInNewTab) {
        window.open(url, '_blank');
      } else {
        window.location.replace(url);
      }
      return { isSuccess: true };
    } catch (errorResponse) {
      if (errorResponse === 'FORBIDDEN') {
        this.errorPopup.openDefaultErrorPopup(
          {
            text: `You don't have access to Zum charter trips.\n Please contact your administrator to request access.`,
          },
          true,
          true,
        );
      } else {
        this.errorPopup.openDefaultErrorPopup(
          {
            text: 'Failed to get charter access url',
            originalError: errorResponse,
          },
          false,
          true,
        );
      }
      return { isSuccess: false };
    }
  }
}
