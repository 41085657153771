import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-icon-dollar-in-circle',
  templateUrl: './icon-dollar-in-circle.component.html',
  styleUrls: ['./icon-dollar-in-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconDollarInCircleComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
