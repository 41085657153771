import { createAction, props } from '@ngrx/store';
import { WpError } from '@rootTypes';
import { ChangeRequestsByStudentState } from '@rootTypes/entities/student-change-requests';

export const getChangeRequestsForStudentFromStudentDetails = createAction(
  `[StudentDetailsEffects]: Get student change requests`,
  props<{ studentId: string }>(),
);

export const getPendingChangeRequestsOnNavigateToEditStudentStep = createAction(
  `[EditStudentEffects]: Get pending student change requests on navigate to edit student step`,
  props<{ studentId: string }>(),
);

export const getChangeRequestsForStudentSuccess = createAction(
  `[StudentChangeRequestsEffects]: Get student change requests success`,
  props<{ studentId: string; result: ChangeRequestsByStudentState }>(),
);

export const getChangeRequestsForStudentFailed = createAction(
  `[StudentChangeRequestsEffects]: Get student change requests failed`,
  props<{ studentId: string; error: WpError }>(),
);

export const loadChangeRequestsActions = [
  getChangeRequestsForStudentFromStudentDetails,
  getPendingChangeRequestsOnNavigateToEditStudentStep,
];
