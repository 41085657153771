import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-icon-arrow-down-large',
  templateUrl: './icon-arrow-down-large.component.html',
  styleUrls: ['./icon-arrow-down-large.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconArrowDownLargeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
