import { PointTime } from './point-time';
import { EnqueuedPointTime } from './enqueued-point-time';
import { Coords } from './coords';
import { MoverConfig } from './mover-config';
import { getEnqueuedPointTime } from './utils/get-enqueued-point-time';
import { getAnimationBreakPointsForBearings } from './utils/get-animation-break-points-for-bearings';
import { mphToMetersPerMilli } from '@rootTypes/utils/map-tracker/utils/mph-to-meters-per-milli';
import { Easings } from '@rootTypes/utils/map-tracker/utils/easings';
import { pointBearingToGoogleBearing } from '@rootTypes/utils/map-tracker/utils/point-bearing-to-google-bearing';

enum CoordsAnimationProgress {
  ZERO = 0,
  COMPLETED = 1,
}

export class Mover {
  // current state data
  public currentPosition: Coords;
  // lies in [-180, 180]
  public currentGoogleBearing: number;
  public currentAnimationState: 'init' | 'start' | 'coords' | 'bearing' | 'jump' | 'complete' | 'idle' = 'init';
  public currentAnimationProgress: number;
  public meta: { [key: string]: unknown };
  public id: string;

  // push to back, animate from first, shift after animation is complete
  private queue: EnqueuedPointTime[] = [];
  // total distance of all enqueued points
  private currentAnimationDistanceTotal = 0;
  // total time of all enqueued points
  private currentAnimationTimeTotal = 0;
  // breakpoints for bearing animation
  private bearingAnimationQueue: number[] = [];
  // stores the ts of coord animation start
  private currentPointAnimationStartUTC: number;
  // last point added to the queue, calculate time, distance, bearing to next
  // point based on it
  private lastAddedPoint: EnqueuedPointTime;
  // speed limitations converted to meters per millisecond
  private maxSpeedMetPerMillis: number;
  private minSpeedMetPerMillis: number;

  constructor(
    id: string,
    point: PointTime,
    private config: MoverConfig,
  ) {
    this.id = id;
    this.meta = point.meta;
    const enqueuedPointTime = getEnqueuedPointTime(null, point, 0, 0);
    this.lastAddedPoint = enqueuedPointTime;
    this.currentPosition = {
      lat: point.lat,
      lng: point.lng,
    };
    this.currentGoogleBearing = point.bearing ? pointBearingToGoogleBearing(point.bearing) : 0;
    if (config.maxSpeedLimitMPH !== undefined) {
      this.maxSpeedMetPerMillis = mphToMetersPerMilli(config.maxSpeedLimitMPH);
    }
    if (config.minSpeedLimitMPH !== undefined) {
      this.minSpeedMetPerMillis = mphToMetersPerMilli(config.minSpeedLimitMPH);
    }
  }

  public hasPendingAnimation(): boolean {
    return this.currentAnimationState !== 'idle';
  }

  public jumpTo(point: PointTime, prevPoint: PointTime): void {
    const enqueued = getEnqueuedPointTime(
      prevPoint || null,
      point,
      prevPoint ? this.config.mapUtils.getDistanceBetweenPoints(prevPoint, point) : 0,
      this.getJumpToBearing(point, prevPoint),
    );
    this.currentAnimationDistanceTotal = enqueued.distanceToMe;
    this.currentAnimationTimeTotal = enqueued.timeToMe;
    this.lastAddedPoint = enqueued;
    this.queue = [enqueued];
    this.bearingAnimationQueue = [];
    this.currentGoogleBearing = enqueued.bearingToMe;
    this.currentAnimationState = 'jump';
    this.currentPointAnimationStartUTC = new Date().getTime();
  }

  public tick(utc: number): void {
    if (this.currentAnimationState === 'init') {
      this.currentAnimationState = this.queue?.length > 0 ? 'start' : 'idle';
    } else if (this.currentAnimationState === 'complete') {
      const prev = this.queue.shift();
      if (prev) {
        this.currentAnimationDistanceTotal = Math.max(this.currentAnimationDistanceTotal - prev.distanceToMe, 0);
        this.currentAnimationTimeTotal = Math.max(this.currentAnimationTimeTotal - prev.timeToMe, 0);
      }
      if (this.queue.length) {
        this.currentAnimationState = 'start';
      } else {
        this.currentAnimationState = 'idle';
      }
    } else {
      const targetPoint = this.queue[0];
      if (this.currentAnimationState === 'jump') {
        this.meta = targetPoint.meta;
        this.animateJumpTo(this.currentPointAnimationStartUTC, utc, targetPoint);
      } else if (this.currentAnimationState === 'start') {
        this.currentPointAnimationStartUTC = new Date().getTime();
        this.meta = targetPoint.meta;
        if (this.config.isAnimateBearing) {
          this.bearingAnimationQueue = getAnimationBreakPointsForBearings(
            this.currentGoogleBearing,
            targetPoint.bearingToMe,
            this.config.bearingAnimationIncrement,
          );
        }
        this.currentAnimationState = 'bearing';
      } else if (this.currentAnimationState === 'bearing') {
        this.animateBearing(targetPoint);
      } else {
        this.animateCoordsChange(this.currentPointAnimationStartUTC, utc, targetPoint);
      }
    }
  }

  public addPoint(point: PointTime): void {
    if (this.config.mapUtils.isSamePoints(this.lastAddedPoint, point)) {
      return;
    }
    const speedPoint = getEnqueuedPointTime(
      this.lastAddedPoint,
      point,
      this.config.mapUtils.getDistanceBetweenPoints(this.lastAddedPoint, point),
      this.config.mapUtils.getBearingBetweenPoints(this.lastAddedPoint, point),
      this.config.minSpeedLimitMPH,
      this.config.maxSpeedLimitMPH,
    );
    this.currentAnimationDistanceTotal += speedPoint.distanceToMe;
    this.currentAnimationTimeTotal += speedPoint.timeToMe;
    if (
      this.config.maxDistanceBehindMeters !== undefined &&
      this.currentAnimationDistanceTotal > this.config.maxDistanceBehindMeters
    ) {
      this.jumpTo(point, this.lastAddedPoint);
    } else {
      this.queue.push(speedPoint);
      this.lastAddedPoint = speedPoint;
      if (this.currentAnimationState === 'idle') {
        this.currentAnimationState = 'start';
      }
    }
  }

  private animateBearing(targetPoint: EnqueuedPointTime): void {
    if (targetPoint.bearingToMe !== this.currentGoogleBearing) {
      if (this.bearingAnimationQueue.length) {
        this.currentGoogleBearing = this.bearingAnimationQueue.shift();
      } else {
        this.currentGoogleBearing = targetPoint.bearingToMe;
      }
    } else {
      this.currentAnimationState = 'coords';
    }
  }

  private animateCoordsChange(animationStartUTC: number, currUTC: number, targetPoint: EnqueuedPointTime): void {
    let animationProgress;
    if (!targetPoint.timeToMe) {
      animationProgress = CoordsAnimationProgress.COMPLETED;
    } else {
      const animationTime = currUTC - animationStartUTC;
      animationProgress = Math.min(animationTime / targetPoint.timeToMe, CoordsAnimationProgress.COMPLETED);
    }
    if (animationProgress === CoordsAnimationProgress.COMPLETED) {
      this.currentAnimationState = 'complete';
      this.currentPosition = {
        lat: targetPoint.lat,
        lng: targetPoint.lng,
      };
    } else {
      this.currentPosition = this.config.mapUtils.interpolate(
        { lat: targetPoint.prevLat, lng: targetPoint.prevLng },
        { lat: targetPoint.lat, lng: targetPoint.lng },
        animationProgress,
      );
    }
    this.currentAnimationProgress = animationProgress;
  }

  private animateJumpTo(baseUTC: number, tickUTC: number, targetPoint: EnqueuedPointTime) {
    const jumpDuration = 300;
    let jumpProgress;
    if (targetPoint.timeToMe) {
      jumpProgress = Math.min((tickUTC - baseUTC) / jumpDuration, 1);
      if (jumpProgress !== 1) {
        jumpProgress = Easings.easeInOutSine(jumpProgress);
      }
    } else {
      jumpProgress = 1;
    }
    if (jumpProgress === 1) {
      this.currentPosition = targetPoint;
      this.currentAnimationState = 'complete';
    } else {
      this.currentPosition = this.config.mapUtils.interpolate(
        { lat: targetPoint.prevLat, lng: targetPoint.prevLng },
        { lat: targetPoint.lat, lng: targetPoint.lng },
        jumpProgress,
      );
    }
    this.currentAnimationProgress = jumpProgress;
  }

  private getJumpToBearing(point: PointTime, prevPoint: PointTime): number {
    if (prevPoint) {
      return this.config.mapUtils.getBearingBetweenPoints(prevPoint, point);
    } else {
      if (point.bearing) {
        return pointBearingToGoogleBearing(point.bearing);
      } else {
        return 0;
      }
    }
  }
}
