<wp-page>
  <wp-page-top>
    <wp-page-top-title>Session outdated</wp-page-top-title>
  </wp-page-top>
  <wp-page-body>
    <div class="content">
      <div class="instructions">
        This page cannot be displayed as your session has been outdated.
      </div>
      <div class="instructions">
        Please logout and login again to resolve the issue.
      </div>
      <wp-btn-primary class="logout-btn" (clicked)="onLogout()">
        Logout
      </wp-btn-primary>
    </div>
  </wp-page-body>
</wp-page>
