import { createAction, props } from '@ngrx/store';

import { RideAssignmentDriver, RideAssignmentVehicle, RideAssignmentVendor, WpError } from '../../dependencies';
import { RideAssignmentData } from '../../dependencies';

const rideAssignment = '[RideAssignmentRoot]';

export const destroyRideAssignment = createAction(`${rideAssignment} Destroy`);

export const loadRideAssignmentDataRequested = createAction(
  `${rideAssignment} Load ride assignment data requested`,
  props<{ rideId: string }>(),
);
export const loadRideAssignmentDataSuccess = createAction(
  `${rideAssignment} Load ride assignment data success`,
  props<{ data: RideAssignmentData }>(),
);
export const loadRideAssignmentDataFailed = createAction(
  `${rideAssignment} Load ride assignment data failed`,
  props<{ error: WpError }>(),
);

export const submitRideAssignmentRequested = createAction(`${rideAssignment} Submit ride assignment requested`);
export const submitRideAssignmentSuccess = createAction(`${rideAssignment} Submit ride assignment success`);
export const submitRideAssignmentFailed = createAction(
  `${rideAssignment} Submit ride assignment failed`,
  props<{ error: WpError }>(),
);
export const startZumerFeeStep = createAction(`${rideAssignment} Start zumer fee step`);
export const cancelZumerFeeStep = createAction(`${rideAssignment} Cancel zumer fee step`);
export const applyNewZumerFee = createAction(`${rideAssignment} Apply new zumer fee`, props<{ newFeeCents: number }>());

const selectRideTypeStep = '[SelectRideTypeStep]';
export const startRideTypeSelection = createAction(`${selectRideTypeStep} Start ride type selection`);
export const applyRideType = createAction(
  `${selectRideTypeStep} Apply ride type`,
  props<{ vendor?: RideAssignmentVendor }>(),
);

const selectDriverStep = '[SelectDriverStep]';
export const newDriverSelected = createAction(
  `${selectDriverStep} New driver selected`,
  props<{ driverId?: string }>(),
);
export const loadNewDriverSuccess = createAction(
  `${selectDriverStep} Load new driver success`,
  props<{ driver: RideAssignmentDriver }>(),
);
export const loadNewDriverFailed = createAction(
  `${selectDriverStep} Load new driver failed`,
  props<{ error: WpError }>(),
);
export const applyNewDriver = createAction(`${selectDriverStep} Apply new driver`);
export const keepExistingDriver = createAction(`${selectDriverStep} Keep existing driver`);
export const keepVendorDriverUnassigned = createAction(`${selectDriverStep} Keep vendor driver unassigned`);
export const unassignExistingDriver = createAction(`${selectDriverStep} Unassign existing driver`);

const selectVehicleStep = '[SelectVehicleStep]';
export const newVehicleSelected = createAction(
  `${selectVehicleStep} New vehicle selected`,
  props<{ vehicleId?: string }>(),
);
export const loadNewVehicleSuccess = createAction(
  `${selectVehicleStep} Load new vehicle success`,
  props<{ vehicle: RideAssignmentVehicle }>(),
);
export const loadNewVehicleFailed = createAction(
  `${selectVehicleStep} Load new vehicle failed`,
  props<{ error: WpError }>(),
);
export const applyNewVehicle = createAction(`${selectVehicleStep} Apply new vehicle`);
export const keepVehicleUnassigned = createAction(`${selectVehicleStep} Keep vehicle unassigned`);
export const keepExistingVehicle = createAction(`${selectVehicleStep} Keep existing vehicle`);
export const unassignExistingVehicle = createAction(`${selectVehicleStep} Unassign existing vehicle`);
