import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-auth-wrapper',
  templateUrl: './auth-wrapper.component.html',
  styleUrls: ['./auth-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthWrapperComponent implements OnInit {
  @Input() rightColumnImage: 'kid' | 'resetPassword' = 'kid';

  public isKidImage: boolean;

  constructor() {}

  public ngOnInit(): void {
    this.isKidImage = this.rightColumnImage === 'kid';
  }
}
