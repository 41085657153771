import { createAction, props } from '@ngrx/store';
import {
  Activity,
  ActivityStatus,
  ActivityType,
  PortalEntity,
  PortalEntityType,
  TimesheetEntryListItem,
  WpError,
} from '@rootTypes';
import { ActivityLog } from '@rootTypes/entities/timesheet-entries';
import { GetActivityOptions } from './get-activity-options';
import { GetDriverBusReportResponse } from '../../api/endpoints/get-driver-bus-report';

/**
 * Load activity
 */

export const loadActivityRequestedTimesheetEntryDetails = createAction(
  '[TimesheetEntryDetails]: Load activity requested',
  props<{ activityId: string; options?: GetActivityOptions }>(),
);

export const loadActivityRequestedActivityDetails = createAction(
  '[ActivityDetails]: Load activity requested',
  props<{ activityId: string; options?: GetActivityOptions }>(),
);

export const loadActivityRequestedCreateActivity = createAction(
  '[CreateActivity]: Load activity requested',
  props<{ activityId: string; options?: GetActivityOptions }>(),
);

export const reloadActivityOnUpdate = createAction(
  '[ActivityDetails]: Reload activity on update',
  props<{ activityId: string; options?: GetActivityOptions }>(),
);

export const loadActivityRequestedChangeHistory = createAction(
  '[ActivityChangeHistoryDataEffects]: Load activity requested',
  props<{ activityId: string; options?: GetActivityOptions }>(),
);

export const loadActivityRequestedOnForceClockOut = createAction(
  '[ActivityDetails]: Load activity requested on force clock out',
  props<{ activityId: string; options?: GetActivityOptions }>(),
);

export const loadActivityRequestedActivityEditor = createAction(
  '[ActivityDetails]: Load activity requested from activity editor',
  props<{ activityId: string; options?: GetActivityOptions }>(),
);

export const loadActivityRequestedOnAssignment = createAction(
  '[ActivityDetails]: Load activity requested on assignment',
  props<{ activityId: string; options?: GetActivityOptions }>(),
);

export const loadActivityRequestedOnOpenAssignmentPopup = createAction(
  '[ActivityDetails]: Load activity requested on open assignment popup',
  props<{ activityId: string; options?: GetActivityOptions }>(),
);

export const loadActivityRequestedOnCancel = createAction(
  '[ActivityDetails]: Load activity requested on cancel',
  props<{ activityId: string; options?: GetActivityOptions }>(),
);

export const loadActivityActions = [
  loadActivityRequestedTimesheetEntryDetails,
  loadActivityRequestedActivityDetails,
  reloadActivityOnUpdate,
  loadActivityRequestedChangeHistory,
  loadActivityRequestedOnForceClockOut,
  loadActivityRequestedCreateActivity,
  loadActivityRequestedActivityEditor,
  loadActivityRequestedOnAssignment,
  loadActivityRequestedOnOpenAssignmentPopup,
  loadActivityRequestedOnCancel,
];

export const loadActivitySuccess = createAction(
  '[ActivityDataEffects]: Load activity success',
  props<{ activity: Activity; driverBusReport?: GetDriverBusReportResponse }>(),
);

export const loadActivityFailed = createAction(
  '[ActivityDataEffects]: Load activity failed',
  props<{ activityId: string; error: WpError }>(),
);

/**
 * Reported activity status
 */
export const reportedActivityStatusesFromTimesheetEntryList = createAction(
  '[TimesheetEntryList]: Reported activity statuses',
  props<{ statuses: { [activityId: string]: ActivityStatus } }>(),
);

export const reportedActivityStatusesFromContextActivities = createAction(
  '[TimesheetEntryDetailsContext]: Reported activity statuses',
  props<{ statuses: { [activityId: string]: ActivityStatus } }>(),
);

/**
 * Report removed activity
 */
export const reportedRemoveActivity = createAction(
  '[CancelActivityEffects]: Reported remove activity',
  props<{ activityId: string }>(),
);

/**
 * Pending requests by user
 */
export const getPendingTimesheetsCountByUser = createAction(
  '[TimesheetChangeRequestDetails]: Get pending timesheets count by user',
  props<{ entityId: string; entityType: PortalEntityType }>(),
);

export const getPendingTimesheetsCountByUserOnUpdate = createAction(
  '[TimesheetEntryDetails]: Get pending timesheets count by user on update',
  props<{ entityId: string; entityType: PortalEntityType }>(),
);

export const getPendingTimesheetsCountByUserOnRefreshList = createAction(
  '[TimesheetEntryDetails]: Get pending timesheets count by user on refresh list',
  props<{ entityId: string; entityType: PortalEntityType }>(),
);

export const getPendingTimesheetsCountByUserSuccess = createAction(
  '[TimesheetChangeRequestDetails]: Get pending timesheets count by user success',
  props<{ entityId: string; entityType: PortalEntityType; count: number }>(),
);

export const getPendingTimesheetsCountByUserFailed = createAction(
  '[TimesheetChangeRequestDetails]: Get pending timesheets count by user failed',
  props<{ entityId: string; entityType: PortalEntityType; error: WpError }>(),
);

/**
 * Get activity logs
 */
export const getActivityLogsRequested = createAction(
  '[TimesheetEntryChangeHistoryDataEffects]: API Get activity log',
  props<{ activityId: string }>(),
);

export const getActivityLogsSuccess = createAction(
  '[TimesheetEntryDataEffects]: API Get activity log success',
  props<{ activityId: string; logs: ActivityLog[] }>(),
);

export const getActivityLogsFailed = createAction(
  '[TimesheetEntryDataEffects]: API Get activity log failed',
  props<{ activityId: string; error: WpError }>(),
);

/**
 * Get activity types
 */
export const getActivityTypesRequested = createAction('[ActivityDataEffects]: API Get activity types');

export const getActivityTypesSuccess = createAction(
  '[ActivityDataEffects]: API Get activity types success',
  props<{ activityTypes: ActivityType[] }>(),
);

export const getActivityTypesFailed = createAction(
  '[ActivityDataEffects]: API Get activity types failed',
  props<{ error: WpError }>(),
);

/**
 * Context
 */

// day context
// activities
export const dayActivityContextLoadRequested = createAction(
  '[TimesheetEntryDetailEffects] API: Day activity context load requested',
  props<{ activityId: string }>(),
);

export const dayActivityContextLoadSuccess = createAction(
  '[TimesheetEntryDetailEffects] API: Day activity context load success',
  props<{ activityId: string; activities: TimesheetEntryListItem[] }>(),
);

export const dayActivityContextLoadFailed = createAction(
  '[TimesheetEntryDetailEffects] API: Day activity context load failed',
  props<{ activityId: string; error: WpError }>(),
);

// specific context (rides for drivers)
export const daySpecificContextLoadRequested = createAction(
  '[TimesheetEntryDetailEffects] API: Day specific context load requested',
  props<{ activityId: string }>(),
);

export const daySpecificContextLoadSuccess = createAction(
  '[TimesheetEntryDetailEffects] API: Day specific context load success',
  props<{ activityId: string; items: PortalEntity[] }>(),
);

export const daySpecificContextLoadFailed = createAction(
  '[TimesheetEntryDetailEffects] API: Day specific context load failed',
  props<{ activityId: string; error: WpError }>(),
);
