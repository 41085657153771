<svg
  *ngIf="disabled; else enabled"
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
>
  <g fill="none" fill-rule="evenodd">
    <g fill="#959597">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        d="M7.568 7.557c-2.697 0-4.875 2.178-4.875 4.874 0 2.698 2.178 4.876 4.875 4.876 2.698 0 4.874-2.178 4.874-4.876 0-2.696-2.176-4.874-4.874-4.874zm6.5 0h-.856l-.304.293c1.063 1.234 1.702 2.838 1.702 4.581 0 3.89-3.153 7.042-7.042 7.042-3.89 0-7.042-3.152-7.042-7.042 0-3.889 3.153-7.04 7.042-7.04 1.744 0 3.347.639 4.583 1.7l.291-.303v-.856L17.86.526l1.613 1.614-5.405 5.417z"
                        transform="translate(-836 -752) translate(486) translate(34 700) translate(306 28) translate(10 3) translate(0 21) rotate(90 10 10)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>

<ng-template #enabled>
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="2-element-/-field-/-search-/-default"
        transform="translate(-10.000000, -10.000000)"
        fill="#47A540"
      >
        <g
          id="2-element-/-icon-/-ic_search"
          transform="translate(10.000000, 10.000000)"
        >
          <path
            d="M7.56778947,7.55684211 C4.87094737,7.55684211 2.69326316,9.73452632 2.69326316,12.4313684 C2.69326316,15.1294737 4.87094737,17.3071579 7.56778947,17.3071579 C10.2658947,17.3071579 12.4423158,15.1294737 12.4423158,12.4313684 C12.4423158,9.73452632 10.2658947,7.55684211 7.56778947,7.55684211 L7.56778947,7.55684211 Z M14.068,7.55684211 L13.2115789,7.55684211 L12.9084211,7.84989474 C13.9707368,9.084 14.6098947,10.6882105 14.6098947,12.4313684 C14.6098947,16.3206316 11.4570526,19.4734737 7.56778947,19.4734737 C3.67852632,19.4734737 0.525684211,16.3206316 0.525684211,12.4313684 C0.525684211,8.54210526 3.67852632,5.39052632 7.56778947,5.39052632 C9.31221053,5.39052632 10.9151579,6.02968421 12.1505263,7.09073684 L12.4423158,6.78757895 L12.4423158,5.93242105 L17.86,0.526105263 L19.4730526,2.14042105 L14.068,7.55684211 Z"
            id="Fill-1"
            transform="translate(9.999368, 9.999789) rotate(90.000000) translate(-9.999368, -9.999789) "
          ></path>
        </g>
      </g>
    </g>
  </svg>
</ng-template>
