import { Options } from 'ngx-slider-v2';

export interface RangeValue {
  from: number;
  to: number;
}

export const defaultOptions: Options = {
  floor: 0,
  ceil: 100,
  step: 10,
  showTicks: true,
  showTicksValues: false,
  tickValueStep: 0,
  getLegend: (value) => '' + value,
  hidePointerLabels: true,
  hideLimitLabels: true,
};
