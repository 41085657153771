import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wp-side-bar-bottom',
  templateUrl: './side-bar-bottom.component.html',
  styleUrls: ['./side-bar-bottom.component.scss'],
})
export class SideBarBottomComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
