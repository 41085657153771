import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/api/api.service';
import * as fromTypes from '../types';
import * as fromApi from '../../../api';
import { map } from 'rxjs/operators';

@Injectable()
export class CommonDataApiService {
  constructor(private api: ApiService) {}
  public getUSNationalHolidays(
    startDate: fromTypes.YYYYMMDDString,
    endDate: fromTypes.YYYYMMDDString,
  ): Observable<{ [id: string]: fromTypes.NationalHoliday }> {
    let startDisplayDate: fromTypes.utils.date.DisplayDateTimeStamp;
    let endDisplayDate: fromTypes.utils.date.DisplayDateTimeStamp;
    if (!startDate) {
      startDisplayDate = fromTypes.utils.date.DisplayDateTime.startOfCurrentYear;
    } else {
      const date = new fromTypes.utils.date.YearMonthDay(startDate).toDate();
      startDisplayDate = fromTypes.utils.date.DisplayDateTime.fromDate(date);
    }

    if (!endDate) {
      endDisplayDate = fromTypes.utils.date.DisplayDateTime.endOfCurrentYear;
    } else {
      const date = new fromTypes.utils.date.YearMonthDay(endDate).toDate();
      endDisplayDate = fromTypes.utils.date.DisplayDateTime.fromDate(date);
    }

    return this.api
      .getUSNationalHolidays({
        start_date_ms: startDisplayDate,
        end_date_ms: endDisplayDate,
      })
      .pipe(
        map((res) => fromApi.getUSNationalHolidays.convertors.getNationalHolidaysFromDb(res)),
        map((holidays) => {
          return holidays.reduce((prev, curr) => {
            return { ...prev, [curr.id]: curr };
          }, {});
        }),
      );
  }
}
