import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-badge',
  template: `
    <div class="badge-box" [ngStyle]="{ backgroundColor: bgColor }">
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      .badge-box {
        font-size: 12px;
        padding: 2px 8px;
        border-radius: 4px;
        height: 20px;
        box-sizing: border-box;
        display: inline-flex;
        align-items: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent implements OnInit {
  @Input() public color: 'orange' | 'blue' | 'gray' | string;

  private standardColors = {
    ['orange']: 'rgba(255, 150, 0, 0.2)',
    ['blue']: 'rgba(36, 134, 247, 0.2)',
    ['gray']: '#dfdfe3',
  };

  constructor() {}

  ngOnInit(): void {}

  public get bgColor(): string {
    return this.standardColors[this.color] ?? this.color ?? 'blue';
  }
}
