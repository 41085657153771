import { EntityFilterRequest } from '../../../../api/endpoints/entity-filter';
import {
  PortalEntityType,
  VehicleListEntityFilter,
  VehicleListFilterType,
  VehicleListMultiAccountFilter,
  VehicleListSnapshot,
} from '../entities';
import { getEntityFilterRequest } from '@rootTypes/utils/portal-entity/get-entity-filter-request';
import { EntitySearchResponseType } from '@apiEntities/entity-search';
import { getUniqueArrayValues } from '@rootTypes/utils';

export const getVehicleListRequest = (snapshot: VehicleListSnapshot): EntityFilterRequest => {
  const { page, pageSize, search, status, filters } = snapshot;
  const skip = page * pageSize;
  const limit = pageSize;
  const entityFilters: VehicleListEntityFilter[] = (filters || []).filter(
    (f) => f.type === VehicleListFilterType.ENTITY,
  ) as VehicleListEntityFilter[];
  const yardIds = entityFilters.filter((f) => f.payload.type === PortalEntityType.YARD).map((f) => f.payload.entityId);

  const operatorIds = (filters || [])
    .filter((f) => f.type === 'multi-account')
    .flatMap((f: VehicleListMultiAccountFilter) => f.payload.operatorIds);

  return getEntityFilterRequest(search, EntitySearchResponseType.VEHICLE, status, skip, limit, {
    yardIds,
    operatorIds: getUniqueArrayValues(operatorIds),
  });
};
