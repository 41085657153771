import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { SmartInputTransportationProviderService } from '../../services';

@Component({
  selector: 'wp-smart-input-transportation-provider-yard',
  templateUrl: './smart-input-transportation-provider-yard.component.html',
  styleUrls: ['./smart-input-transportation-provider-yard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartInputTransportationProviderYardComponent implements OnInit {
  public label: string;
  public control: UntypedFormControl;
  public controlStateChange$: Observable<void>;
  public selectedVendorId$: Observable<string | undefined>;

  constructor(private service: SmartInputTransportationProviderService) {}

  public ngOnInit(): void {
    this.label = this.service.yardLabel;
    this.control = this.service.yardControl;
    this.controlStateChange$ = this.service.formGroupStateChange$;
    this.selectedVendorId$ = this.service.selectedVendorId$;
  }
}
