import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-section-spinner',
  templateUrl: './section-spinner.component.html',
  styleUrls: ['./section-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionSpinnerComponent implements OnInit {
  @Input() public sectionHeight = '200px';

  /**
   * Spinner diameter
   */
  @Input() public spinnerSize = 50;

  constructor() {}

  ngOnInit(): void {}
}
