import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-grey-section-separator-line',
  templateUrl: './grey-section-separator-line.component.html',
  styleUrls: ['./grey-section-separator-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GreySectionSeparatorLineComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
