import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { SmartInputAddress } from '../../models';
import { Address } from '@rootTypes';

@Component({
  selector: 'wp-smart-input-address',
  templateUrl: './smart-input-address.component.html',
  styleUrls: ['./smart-input-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartInputAddressComponent {
  @Input() model: SmartInputAddress;
  @Output() inputEvent = new EventEmitter<string>();
  @Output() valueChangedByUser = new EventEmitter<Address | null>();
}
