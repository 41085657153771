import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getCharterCatalogFailed,
  getCharterCatalogRequested,
  getCharterCatalogSuccess,
  getCharterTripFailed,
  getCharterTripForTripAssignmentListItem,
  getCharterTripForTripListItem,
  getCharterTripItineraryFailed,
  getCharterTripItineraryRequested,
  getCharterTripItinerarySuccess,
  getCharterTripRequested,
  getCharterTripSuccess,
  getContractFailed,
  getContractRequested,
  getContractSuccess,
  getTripInvoiceFailed,
  getTripInvoiceRequested,
  getTripInvoiceSuccess,
  getTripStatusHistoryFailed,
  getTripStatusHistoryRequested,
  getTripStatusHistorySuccess,
  tripAssignmentChanged,
  updateCharterTripWithDelayRequested,
  updateTripStatusHistoryWithDelayRequested,
  workQueueBulkTripAssignmentSuccess,
} from '../actions';
import { of } from 'rxjs';
import { catchError, delay, map, mergeMap, tap } from 'rxjs/operators';
import { WpError } from '@rootTypes';
import { Store } from '@ngrx/store';
import { ApiService } from '../../../api/api.service';
import { getRideV2Requested } from '../../rides/store/actions/rides-data.actions';

const UPDATE_CHARTER_TRIP_DELAY_MILLIS = 2000;

@Injectable()
export class TripDataEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store,
  ) {}

  public onCharterTripRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCharterTripForTripListItem, getCharterTripForTripAssignmentListItem),
      map(({ tripId, options }) => getCharterTripRequested({ tripId, options })),
    );
  });

  public getCharterTripRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCharterTripRequested),
      mergeMap(({ tripId, options }) => {
        return this.api.getCharterTrip({ tripId }).pipe(
          map((trip) => {
            if (options?.withCatalogs) {
              (trip.catalogs || []).forEach((c) =>
                this.store.dispatch(getCharterCatalogRequested({ catalogId: c.catalogId })),
              );
            }
            if (options?.withItinerary && trip.tripItineraryId) {
              this.store.dispatch(
                getCharterTripItineraryRequested({
                  charterTripItineraryId: trip.tripItineraryId,
                  options: { withContract: !!options.withContract },
                }),
              );
            }
            if (options?.withRides) {
              if (trip?.rides?.outbound?.length) {
                trip.rides.outbound.forEach(({ charterRideId }) => {
                  this.store.dispatch(
                    getRideV2Requested({ request: { rideId: charterRideId, _projections: ['driver', 'vehicle'] } }),
                  );
                });
              }
              if (trip?.rides?.return?.length) {
                trip.rides.return.forEach(({ charterRideId }) => {
                  this.store.dispatch(
                    getRideV2Requested({ request: { rideId: charterRideId, _projections: ['driver', 'vehicle'] } }),
                  );
                });
              }
            }
            if (options?.withInvoice) {
              this.store.dispatch(getTripInvoiceRequested({ request: { tripId: trip.tripId } }));
            }
            return getCharterTripSuccess({ trip });
          }),
          catchError((originalError) => {
            const error: WpError = {
              originalError,
              text: 'Failed to load charter trip',
            };
            return of(getCharterTripFailed({ tripId, error }));
          }),
        );
      }),
    );
  });

  public getCharterTripItineraryRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCharterTripItineraryRequested),
      mergeMap(({ charterTripItineraryId, options }) => {
        return this.api.getCharterTripItinerary({ tripItineraryId: charterTripItineraryId }).pipe(
          map((charterTripItinerary) => {
            if (options?.withContract && charterTripItinerary?.contractId) {
              this.store.dispatch(getContractRequested({ request: { contractId: charterTripItinerary.contractId } }));
            }
            return getCharterTripItinerarySuccess({ charterTripItinerary });
          }),
          catchError((originalError) => {
            const error: WpError = {
              originalError,
              text: 'Failed to load charter trip itinerary',
            };
            return of(getCharterTripItineraryFailed({ charterTripItineraryId, error }));
          }),
        );
      }),
    );
  });

  public getCharterCatalogRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCharterCatalogRequested),
      mergeMap(({ catalogId }) => {
        return this.api.getCharterCatalog({ catalogId }).pipe(
          map((catalog) => getCharterCatalogSuccess({ catalog, catalogId })),
          catchError((originalError) => {
            const error: WpError = {
              originalError,
              text: 'Failed to load charter catalog',
            };
            return of(getCharterCatalogFailed({ catalogId, error }));
          }),
        );
      }),
    );
  });

  public getTripInvoiceRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getTripInvoiceRequested),
      mergeMap(({ request }) => {
        return this.api.getCharterTripInvoice(request).pipe(
          map((resp) => getTripInvoiceSuccess({ tripId: request.tripId, invoice: resp })),
          catchError((originalError) => {
            const error: WpError = {
              originalError,
              text: 'Failed to load trip invoice',
            };
            return of(getTripInvoiceFailed({ tripId: request.tripId, error }));
          }),
        );
      }),
    );
  });

  public getContractRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getContractRequested),
      mergeMap(({ request }) => {
        return this.api.getCharterContract(request).pipe(
          map((resp) => getContractSuccess({ contractId: request.contractId, contract: resp })),
          catchError((originalError) => {
            const error: WpError = {
              originalError,
              text: 'Failed to load contract',
            };
            return of(getContractFailed({ contractId: request.contractId, error }));
          }),
        );
      }),
    );
  });

  public updateCharterTripWithTimeoutRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCharterTripWithDelayRequested),
      mergeMap(({ tripId, options }) => {
        return of(getCharterTripRequested({ tripId, options })).pipe(delay(UPDATE_CHARTER_TRIP_DELAY_MILLIS));
      }),
    ),
  );

  public getTripStatusHistoryRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTripStatusHistoryRequested),
      mergeMap(({ tripId }) => {
        return this.api.getCharterTripChangeHistory({ tripId }).pipe(
          map((resp) => getTripStatusHistorySuccess({ tripId, history: resp })),
          catchError((originalError) => {
            console.error(originalError);
            const error: WpError = {
              originalError,
              text: 'Failed to load trip status history',
            };
            return of(getTripStatusHistoryFailed({ tripId, error }));
          }),
        );
      }),
    ),
  );

  public updateTripStatusHistoryWithDelayRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTripStatusHistoryWithDelayRequested),
      mergeMap(({ tripId }) => {
        return of(getTripStatusHistoryRequested({ tripId, noLoadingIndicator: true })).pipe(
          delay(UPDATE_CHARTER_TRIP_DELAY_MILLIS),
        );
      }),
    ),
  );

  public onTripAssignmentChanged$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(tripAssignmentChanged, workQueueBulkTripAssignmentSuccess),
        tap(({ updatedTripIds }) => {
          Object.keys(updatedTripIds).forEach((tripId) => {
            this.store.dispatch(
              getCharterTripRequested({
                tripId,
                options: {
                  withRides: true,
                },
              }),
            );
          });
        }),
      );
    },
    {
      dispatch: false,
    },
  );
}
