export enum RouteDriverType {
  VENDOR = 'VENDOR',
  ZUMER = 'ZUMER',
}

export interface RouteDriver {
  driverId: string;
  type: RouteDriverType;
  isDefault: boolean;
}

export type RouteDrivers = {
  [driverId: string]: RouteDriver;
};
