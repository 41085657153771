<div
  class="r-popup__contents"
  [ngStyle]="{
    zIndex: contentZIndex$ | async
  }"
  [ngClass]="{
    open: isOpen$ | async
  }"
  (click)="onPageMaskClicked()"
>
  <div
    class="r-popup__contents__box"
    [ngClass]="{
      open: isOpen$ | async
    }"
    [ngStyle]="{
      width: (options$ | async)?.popupWidth,
    }"
    (click)="$event.stopPropagation()"
  >
    <ng-container #container></ng-container>
  </div>
</div>

<div
  *ngIf="isOpen$ | async"
  [ngStyle]="{
    zIndex: pageMaskZIndex$ | async
  }"
  class="r-popup__page-mask"
  (click)="onPageMaskClicked()"
></div>
