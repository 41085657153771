<div class="wp-cross-entity-search">
  <div
    class="wp-cross-entity-search__input"
    (click)="onSearchInputClick($event)"
  >
    <wp-input
      [control]="searchInputControl"
      [label]="placeholder"
      [type]="'search'"
    >
      <wp-input-prefix [marginRight]="'20px'" moveDown="3px" moveRight="10px">
        <wp-icon-search></wp-icon-search>
      </wp-input-prefix>
      <wp-input-suffix
        [marginRight]="'10px'"
        *ngIf="!!searchInputControl.value"
        (onClicked)="clearInput()"
      >
        <wp-icon-close [size]="'small'"></wp-icon-close>
      </wp-input-suffix>
    </wp-input>
  </div>
  <ng-container *ngIf="!!searchInputControl.value">
    <div
      class="wp-cross-entity-search__dropdown customScroll"
      [ngClass]="{
        'align-left': dropdownAlignment === 'left',
        'align-right': dropdownAlignment === 'right',
        'align-center': dropdownAlignment === 'center'
      }"
      *ngIf="dropdownOpen$ | async"
      wpClickOutside
      (clickedOutside)="onClickOutside()"
      infiniteScroll
      [scrollWindow]="false"
      [infiniteScrollDisabled]="!(hasNext$ | async)"
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScrolled()"
    >
      <wp-animated-width-change>
        <div class="wp-cross-entity-search__dropdown__dropdown-plane">
          <div
            *ngIf="error$ | async; else searchResultsTemplate;"
            class="empty-page"
          >
            <wp-section-error
              [error]="error$ | async"
              [sectionHeightStr]="'130px'"
            ></wp-section-error>
          </div>
          <ng-template #searchResultsTemplate>
            <div>
              <div *ngIf="hits$ | async; let hits">
                <wp-dropdown-option
                  *ngFor="let hit of hits"
                  (selectedWithKeyboard)="onHitClicked(hit)"
                  (click)="onHitClicked(hit)"
                >
                  <div>{{ hit.label }}</div>
                </wp-dropdown-option>
              </div>
              <div *ngFor="let item of items$ | async">
                <wp-dropdown-option
                  [height]="'50px'"
                  (click)="onOptionClick(item)"
                  (selectedWithKeyboard)="onOptionClick(item)"
                >
                  <wp-search-option [entity]="item"></wp-search-option>
                </wp-dropdown-option>
              </div>
            </div>
            <div *ngIf="empty$ | async" class="empty-page">No results</div>
          </ng-template>

          <div *ngIf="nextPageLoading$ | async">
            <wp-section-spinner
              [sectionHeight]="'60px'"
              [spinnerSize]="30"
            ></wp-section-spinner>
          </div>
        </div>
      </wp-animated-width-change>
    </div>
  </ng-container>
</div>
