import { createSelector } from '@ngrx/store';
import { getYardFeature } from './get-feature';
import * as fromTypes from '../../types';
import { YardListState } from '../reducers/yard-lists.reducer';

const getAllYardsListState = createSelector(getYardFeature, (state) => state.lists);

export const getYardListState = createSelector(
  getAllYardsListState,
  (state, props: { listName: fromTypes.YardListName }): YardListState => state[props.listName],
);

/**
 * Pagination
 */
export const getPaginationState = createSelector(getYardListState, (state) => state.pagination);

export const getPage = createSelector(getPaginationState, (state) => state.page);

export const getPageSize = createSelector(getPaginationState, (state) => state.pageSize);

/**
 * Page load
 */
export const getPageState = createSelector(getYardListState, (state) => state.page);

export const getListLoading = createSelector(getPageState, (state) => state.isLoading);

export const getListItems = createSelector(getPageState, (state) => state.items);

export const getListError = createSelector(getPageState, (state) => state.listError);

export const getTotalFound = createSelector(getPageState, (state) => state.totalFound);

/**
 * Search
 */
export const getCurrentSearch = createSelector(getYardListState, (state) => state.currentSearch);

/**
 * Status
 */
export const getCurrentStatus = createSelector(getYardListState, (state) => state.status);

/**
 * Selected yard
 */
export const getSelectedYardId = createSelector(getYardListState, (state) => state.selectedYardId);
