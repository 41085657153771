import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const modulePrefix = 'Data';
const sectionPrefix = 'Campus List';

export const setCampusListState = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Set campus list state`,
  props<{
    snapshot: fromTypes.CampusListStateSnapshot;
    listName: fromTypes.CampusListName;
  }>(),
);

export const cleanCampusListState = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Clean campus list state`,
  props<{ listName: fromTypes.CampusListName }>(),
);

export const campusSearchInputChanged = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Campus search input changed`,
  props<{ input: string; listName: fromTypes.CampusListName }>(),
);

export const campusStatusChanged = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Campus status changed`,
  props<{ status: fromTypes.EntityStatus; listName: fromTypes.CampusListName }>(),
);

export const filtersChanged = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Campus list filters changed`,
  props<{ filters: fromTypes.CampusListFilter[]; listName: fromTypes.CampusListName }>(),
);

export const staticFiltersChanged = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Campus list static filters changed`,
  props<{ filters: fromTypes.CampusListFilter[]; listName: fromTypes.CampusListName }>(),
);

export const currentPageChanged = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Current page changed`,
  props<{ page: number; listName: fromTypes.CampusListName }>(),
);

export const pageSizeChanged = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Page size changed`,
  props<{ pageSize: number; listName: fromTypes.CampusListName }>(),
);

export const campusCardInitialized = createAction(
  `[${modulePrefix}] [${sectionPrefix}] [Campus Card]: Initialized`,
  props<{ campusId: string; listName: fromTypes.CampusListName }>(),
);

export const campusCardDestroyed = createAction(
  `[${modulePrefix}] [${sectionPrefix}] [Campus Card]: Destroyed`,
  props<{ campusId: string; listName: fromTypes.CampusListName }>(),
);

export const campusListLoadRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load list requested`,
  props<{ request: fromTypes.CampusListStateSnapshot; listName: fromTypes.CampusListName }>(),
);

export const campusListLoadSuccess = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load list success`,
  props<{ campusIds: fromTypes.PortalEntity[]; totalFound: number; listName: fromTypes.CampusListName }>(),
);

export const campusListLoadFailed = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load list failed`,
  props<{ error: fromTypes.WpError; listName: fromTypes.CampusListName }>(),
);

export const selectedCampusId = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Selected campus id`,
  props<{ listName: fromTypes.CampusListName; campusId: string }>(),
);

export const addCampusClicked = createAction(`[CampusListComponent]: Add campus clicked`);
