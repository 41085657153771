import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-portal-icon',
  templateUrl: './portal-icon.component.html',
  styleUrls: ['./portal-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalIconComponent implements OnInit {
  @Input() public path: string;
  @Input() public widthStr = 'auto';
  @Input() public heightStr = 'auto';
  @Input() public alt: string;

  // public backgroundStyle: string;

  constructor() {}

  ngOnInit(): void {
    // this.setBackgroundStyleUrl();
  }

  // private setBackgroundStyleUrl(): void {
  //   this.backgroundStyle = `url(${this.path}) center center/${this.heightStr} ${this.widthStr} no-repeat`;
  // }
}
