import { createAction, props } from '@ngrx/store';
import { ManageRouteOwnershipAction } from '../../types/manage-route-ownership-action';
import { PortalEntity, WpError, YYYYMMDDString } from '@rootTypes';
import { RouteGroupManageOwnersConfirmation } from '@apiEntities/route-group/route-group-manage-owners-confirmation';

export const manageRouteOwnershipClicked = createAction(
  '[RouteGroup Details Component]: Manage route ownership clicked',
);

export const viewPastOwnersClicked = createAction('[RouteGroup Details Component]: View past owners clicked');

export const openManageRouteOwnersPopup = createAction(
  '[RouteGroupDetailsEffects]: Open manage route owners popup',
  props<{ routeGroupNameId: string }>(),
);

export const actionSelected = createAction(
  '[ManageRouteOwners]: Action selected',
  props<{ action: ManageRouteOwnershipAction }>(),
);

// unassign
export const unassignSaveClicked = createAction(
  '[ManageRouteOwners] [Unassign]: Save clicked',
  props<{ dateFrom: YYYYMMDDString }>(),
);
export const unassignCancelClicked = createAction('[ManageRouteOwners] [Unassign]: Cancel clicked ');

// unassign-confirm
export const unassignConfirmSaveClicked = createAction('[ManageRouteOwners] [Confirm unassign]: Save clicked');
export const unassignConfirmCancelClicked = createAction('[ManageRouteOwners] [Confirm unassign]: Cancel clicked');

// unassign api
export const unassignRouteGroupDriversRequested = createAction(
  '[ManageRouteGroupOwnerEffects]: API unassign requested',
  props<{ dateFrom: YYYYMMDDString; routeGroupId: string }>(),
);

export const unassignRouteGroupDriversSuccess = createAction(
  '[ManageRouteGroupOwnerEffects]: API unassign success',
  props<{ routeGroupId: string }>(),
);

export const unassignRouteGroupDriversConfirmationNeeded = createAction(
  '[ManageRouteGroupOwnerEffects]: API unassign confirmation needed',
  props<{ confirmation: RouteGroupManageOwnersConfirmation }>(),
);

export const unassignRouteGroupDriversFailed = createAction(
  '[ManageRouteGroupOwnerEffects]: API unassign failed',
  props<{ error: WpError }>(),
);

// unassign confirm api
export const confirmUnassignRouteGroupDriversRequested = createAction(
  '[ManageRouteGroupOwnerEffects]: API confirm unassign requested',
  props<{ confirmationToken: string; routeGroupId: string }>(),
);

export const confirmUnassignRouteGroupDriversSuccess = createAction(
  '[ManageRouteGroupOwnerEffects]: API confirm unassign success',
  props<{ routeGroupId: string }>(),
);

export const confirmUnassignRouteGroupDriversFailed = createAction(
  '[ManageRouteGroupOwnerEffects]: API confirm unassign failed',
  props<{ error: WpError }>(),
);

// change
export const changeSaveClicked = createAction(
  '[ManageRouteOwners] [Change]: Save clicked',
  props<{ dateFrom: YYYYMMDDString; driver: PortalEntity }>(),
);
export const changeCancelClicked = createAction('[ManageRouteOwners] [Change]: Cancel clicked');

// change-confirm
export const changeConfirmSaveClicked = createAction('[ManageRouteOwners] [Confirm Change]: Save clicked');
export const changeConfirmCancelClicked = createAction('[ManageRouteOwners] [Confirm Change]: Cancel clicked');

// change api
export const assignRouteGroupDriversRequested = createAction(
  '[ManageRouteGroupOwnerEffects]: API assign requested',
  props<{ driverId: string; dateFrom: YYYYMMDDString; routeGroupId: string }>(),
);

export const assignRouteGroupDriversConfirmationNeeded = createAction(
  '[ManageRouteGroupOwnerEffects]: API assign confirmation needed',
  props<{ confirmation: RouteGroupManageOwnersConfirmation }>(),
);

export const assignRouteGroupDriversSuccess = createAction(
  '[ManageRouteGroupOwnerEffects]: API assign success',
  props<{ routeGroupId: string }>(),
);

export const assignRouteGroupDriversFailed = createAction(
  '[ManageRouteGroupOwnerEffects]: API assign failed',
  props<{ error: WpError }>(),
);

// change confirm api
export const confirmAssignRouteGroupDriversRequested = createAction(
  '[ManageRouteGroupOwnerEffects]: API confirm assign requested',
  props<{ confirmationToken: string; routeGroupId: string }>(),
);

export const confirmAssignRouteGroupDriversSuccess = createAction(
  '[ManageRouteGroupOwnerEffects]: API confirm assign success',
  props<{ routeGroupId: string }>(),
);

export const confirmAssignRouteGroupDriversFailed = createAction(
  '[ManageRouteGroupOwnerEffects]: API confirm assign failed',
  props<{ error: WpError }>(),
);
