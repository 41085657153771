import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import * as fromTypes from '../types';
import { Observable, fromEvent, Subscription } from 'rxjs';
import { DateRangePickerService } from '../date-range-picker.service';

@Component({
  selector: 'wp-dp-day',
  templateUrl: './dp-day.component.html',
  styleUrls: ['./dp-day.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DpDayComponent implements AfterViewInit, OnInit, OnDestroy {
  // @Input() public readonly!: boolean;
  @Input() public day!: fromTypes.RangePickerDay;
  @Input() public readonly: boolean;
  @Input() public borderSelectionStyle = false;
  @Output() public hoveredIn: EventEmitter<void> = new EventEmitter<void>();
  @Output() public hoveredOut: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('dayWrap') private dayWrap!: ElementRef;
  public day$: Observable<fromTypes.RangePickerDay>;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private store: DateRangePickerService,
    private cd: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    if (this.readonly || !this.day.isDisplayed || this.day.isDisabled) {
      return;
    }
    setTimeout(() => {
      this.initMouseEnterObservables();
    }, 200);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    if (this.readonly || !this.day.isDisplayed) {
      return;
    }
    setTimeout(() => this.initDay(), 200);
  }

  public onDayClick(): void {
    if (!this.day.isDisplayed || this.day.isDisabled) {
      return;
    }
    this.store.dayClicked(this.day.moment);
  }

  private initMouseEnterObservables(): void {
    const sub1 = fromEvent(this.dayWrap.nativeElement, 'mouseenter').subscribe(() =>
      this.store.setHovered(this.day.moment),
    );

    const sub2 = fromEvent(this.dayWrap.nativeElement, 'mouseleave').subscribe(() => this.store.setHovered(null));
    this.subscriptions.add(sub1);
    this.subscriptions.add(sub2);
  }

  private initDay(): void {
    const sub = this.store.dayChanges$(this.day).subscribe((day) => {
      this.day = day;
      this.cd.detectChanges();
    });
    this.subscriptions.add(sub);
  }
}
