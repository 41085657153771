<wp-common-popup-page>
  <wp-common-popup-page-top (closeClicked)="onCloseClick()">
    <wp-common-popup-page-title
      >Search for a school or district account</wp-common-popup-page-title
    >
  </wp-common-popup-page-top>
  <wp-common-popup-page-body>
    <div class="popup">
      <div class="search-wrap">
        <ng-container *ngIf="isDistrictLoading; else loadedDistrict">
          <wp-section-spinner
            [spinnerSize]="33"
            [sectionHeight]="'50px'"
          ></wp-section-spinner>
        </ng-container>
        <ng-template #loadedDistrict>
          <wp-cross-entity-search
            [searchInputControl]="searchInputControl"
            [searchByTypes]="searchByTypes"
            [placeholder]="'School or district name'"
            [isSetInputOnSelect]="true"
            (selected)="onSearchSelect($event)"
            (clearInputClicked)="onSearchCleared()"
          ></wp-cross-entity-search>
        </ng-template>
        <ng-container
          *ngIf="isDistrictPreselected && popupRef?.data?.preSelectedHint"
        >
          <div class="preselect-hint-wrap">
            <wp-grey-label>{{ popupRef.data.preSelectedHint }}</wp-grey-label>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="buttons">
      <div class="btn">
        <wp-btn-secondary [width]="'150px'" (clicked)="onCloseClick()">
          Cancel
        </wp-btn-secondary>
      </div>
      <div class="btn">
        <wp-btn-primary
          [width]="'150px'"
          [disabled]="!selectedEntity"
          (clicked)="onConfirmClick()"
        >
          Confirm
        </wp-btn-primary>
      </div>
    </div>
  </wp-common-popup-page-body>
</wp-common-popup-page>
