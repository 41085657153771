import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import * as fromTypes from '../../types';
import * as fromActions from '../actions/employee-list.actions';
import * as fromSelectors from '../selectors/employee-list.selectors';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';

@Injectable()
export class EmployeeListFacade {
  constructor(
    private store: Store<State>,
    private actions$: Actions,
  ) {}

  public getListLoading$(listName: fromTypes.EmployeeListName): Observable<boolean> {
    return this.store.select(fromSelectors.getListLoading, { listName });
  }

  /**
   *Emits new value on successful list load
   */
  public getListSerializedSnapshot$(
    listName: fromTypes.EmployeeListName,
  ): Observable<fromTypes.SerializedEmployeeListSnapshot> {
    return this.actions$.pipe(
      ofType(fromActions.employeeLoadListSuccess),
      filter((a) => a.listName === listName),
      switchMap(() => {
        return this.store.select(fromSelectors.getListState, { listName }).pipe(
          take(1),
          map((state) => {
            return fromTypes.utils.getSerializedFromSnapshot({
              page: state.pagination.page,
              pageSize: state.pagination.pageSize,
              status: state.status,
              search: state.currentSearch,
              filters: [],
            });
          }),
        );
      }),
    );
  }

  public getListEmpty$(listName: fromTypes.EmployeeListName): Observable<boolean> {
    const loading$ = this.getListLoading$(listName);
    const items$ = this.getListItems$(listName);
    return combineLatest([loading$, items$]).pipe(map(([loading, items]) => !loading && items.length === 0));
  }

  public getListItems$(listName: fromTypes.EmployeeListName): Observable<fromTypes.PortalEntity[]> {
    return this.store.select(fromSelectors.getListItems, { listName });
  }

  public getListError$(listName: fromTypes.EmployeeListName): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getListError, { listName });
  }

  public getSelectedEmployeeId(listName: fromTypes.EmployeeListName): Observable<string> {
    return this.store.select(fromSelectors.getSelectedEmployeeId, { listName });
  }

  public getTotalFound(listName: fromTypes.EmployeeListName): Observable<number> {
    return this.store.select(fromSelectors.getTotalFound, { listName });
  }

  public getPage$(listName: fromTypes.EmployeeListName): Observable<number> {
    return this.store.select(fromSelectors.getPage, { listName });
  }

  public getPageSize$(listName: fromTypes.EmployeeListName): Observable<number> {
    return this.store.select(fromSelectors.getPageSize, { listName });
  }

  public getSearch$(listName: fromTypes.EmployeeListName): Observable<string> {
    return this.store.select(fromSelectors.getCurrentSearch, { listName });
  }

  public getStatus$(listName: fromTypes.EmployeeListName): Observable<fromTypes.EntityStatus> {
    return this.store.select(fromSelectors.getCurrentStatus, { listName });
  }

  public onInitialized(
    listName: fromTypes.EmployeeListName,
    serialized?: fromTypes.SerializedEmployeeListSnapshot,
  ): void {
    const snapshot = fromTypes.utils.getSnapshotFromSerialized(serialized);
    this.store.dispatch(fromActions.initializedList({ listName, snapshot }));
  }

  public onDestroyed(listName: fromTypes.EmployeeListName): void {
    this.store.dispatch(fromActions.destroyedList({ listName }));
  }

  public onCardInitialized(listName: fromTypes.EmployeeListName, employeeId: string): void {
    this.store.dispatch(fromActions.employeeListItemInitialized({ employeeId }));
  }

  public onCardDestroyed(listName: fromTypes.EmployeeListName, employeeId: string): void {
    this.store.dispatch(fromActions.employeeListItemDestroyed({ employeeId }));
  }

  public onPageChanged(listName: fromTypes.EmployeeListName, page: number): void {
    this.store.dispatch(fromActions.pageChanged({ listName, page }));
  }

  public onPageSizeChanged(listName: fromTypes.EmployeeListName, pageSize: number): void {
    this.store.dispatch(fromActions.pageSizeChanged({ listName, pageSize }));
  }

  public onSetStaticEntityFilters(listName: fromTypes.EmployeeListName, entities: fromTypes.PortalEntity[]): void {
    const filters: fromTypes.EmployeeListFilter[] = entities.map((entity) => {
      const id = fromTypes.utils.encodeDataComponents([entity.type, entity.entityId]);
      return {
        id,
        type: fromTypes.EmployeeListFilterType.ENTITY,
        payload: entity,
      };
    });
    this.store.dispatch(fromActions.staticFiltersChanged({ listName, filters }));
  }

  public onStatusChanged(listName: fromTypes.EmployeeListName, status: fromTypes.EntityStatus): void {
    this.store.dispatch(fromActions.statusChanged({ listName, status }));
  }

  public onSearchChanged(listName: fromTypes.EmployeeListName, search: string): void {
    this.store.dispatch(fromActions.searchChanged({ listName, search }));
  }

  public onSelectedEmployeeChanged(listName: fromTypes.EmployeeListName, employeeId: string): void {
    this.store.dispatch(fromActions.employeeItemSelected({ listName, employeeId }));
  }
}
