import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { currentUserFullName } from '../selectors';

@Injectable()
export class UserProfileFacade {
  constructor(private store: Store) {}

  public getUserFullName$(): Observable<string> {
    return this.store.select(currentUserFullName);
  }
}
