import { Pipe, PipeTransform } from '@angular/core';
import * as fromRoot from '@rootTypes';

@Pipe({
  name: 'displayYYYYMMDDMulti',
})
export class DisplayYyyymmddMultiPipe implements PipeTransform {
  transform(value: fromRoot.YYYYMMDDString[]): string {
    if (!value) {
      return undefined;
    }
    return fromRoot.utils.date.yyyyMMDDDisplayMulti(value);
  }
}
