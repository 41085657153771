import { StudentChangeRequestType } from '@apiEntities/student-change-requests';

const typeToDisplayMap = {
  [StudentChangeRequestType.ADDRESS]: 'Change address',
  [StudentChangeRequestType.CAMPUS]: 'Change school/transportation',
  [StudentChangeRequestType.GUARDIAN]: 'Change guardian',
  [StudentChangeRequestType.PROFILE]: 'Change profile',
};

export const studentChangeRequestTypeToDisplay = (type: StudentChangeRequestType): string => {
  return typeToDisplayMap[type] || type;
};
