import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { AddressWithExactLocationValue } from '../../smart-forms/models/smart-address-with-exact-location';

@Component({
  selector: 'wp-display-address-with-exact-location',
  templateUrl: './display-address-with-exact-location.component.html',
  styleUrls: ['./display-address-with-exact-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayAddressWithExactLocationComponent implements OnInit {
  @Input() public value: AddressWithExactLocationValue;

  constructor() {}

  ngOnInit(): void {}
}
