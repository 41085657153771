import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DisplayStudentChangeRequestType } from './pipes/display-student-change-request-type';
import { MatRadioModule } from '@angular/material/radio';
import { TypographyModule } from '../../../shared/typography/typography.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DisplayStudentChangeRequestType],
  imports: [CommonModule, MatRadioModule, TypographyModule, ReactiveFormsModule],
  exports: [DisplayStudentChangeRequestType],
})
export class StudentChangeRequestsCommonModule {}
