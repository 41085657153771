import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wp-icon-bin',
  templateUrl: './icon-bin.component.html',
  styleUrls: ['./icon-bin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconBinComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
