import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RideGoogleMapComponent } from './ride-google-map/ride-google-map.component';
import { StreetViewComponent } from './street-view/street-view.component';
import { RecenterButtonComponent } from './recenter-button/recenter-button.component';
import { TooltipModule } from '../tooltip/tooltip.module';
import { StreetViewPanoService } from './street-view-pano.service';
import { ComponentsModule } from '../components/components.module';
import { GoogleMapComponent } from './google-map/google-map.component';
import { GoogleAddressAutocompleteCustomUiPanelComponent } from './google-place-autocomplete/custom-ui/google-address-autocomplete-custom-ui-panel/google-address-autocomplete-custom-ui-panel.component';
import { GoogleAddressAutocompleteCustomUiInputDirective } from './google-place-autocomplete/custom-ui/google-address-autocomplete-custom-ui-input.directive';
import { MatAutocomplete, MatOption } from '@angular/material/autocomplete';
import { TypographyModule } from '../typography/typography.module';
import { GoogleAddressAutocompleteInputDirective } from './google-place-autocomplete/google-ui/google-address-autocomplete-input.directive';

const components = [
  RideGoogleMapComponent,
  StreetViewComponent,
  GoogleAddressAutocompleteCustomUiPanelComponent,
  GoogleAddressAutocompleteCustomUiInputDirective,
  GoogleAddressAutocompleteInputDirective,
];

@NgModule({
  declarations: [...components, RecenterButtonComponent, GoogleMapComponent],
  imports: [CommonModule, TooltipModule, ComponentsModule, MatOption, MatAutocomplete, TypographyModule],
  providers: [StreetViewPanoService],
  exports: [...components, GoogleMapComponent],
})
export class GoogleMapModule {}
