import { PointTime } from './point-time';
import { ResultPosition } from './result-position';
import { Mover } from './mover';
import { MapTrackerConfig } from './map-tracker-config';
import { getRandomId } from './utils/get-random-id';
import { defaultMapTrackerConfig } from './utils/default-map-tracker-config';
import { MoverConfig } from './mover-config';

export type OnChangeCallbackFn = (positions: ResultPosition[]) => void;

export class MapTracker {
  private moverMap: { [moverId: string]: Mover } = {};
  private subscriptionsMap: {
    [subId: string]: OnChangeCallbackFn;
  } = {};
  private batchesCount = 1;
  private currentBatch = 1;
  private isDestroyed = false;
  private readonly config: MapTrackerConfig;
  private readonly moverConfig: MoverConfig;

  constructor(config: MapTrackerConfig = defaultMapTrackerConfig) {
    this.config = { ...defaultMapTrackerConfig, ...config };
    this.moverConfig = {
      maxSpeedLimitMPH: this.config.maxSpeedLimitMPH,
      minSpeedLimitMPH: this.config.minSpeedLimitMPH,
      mapUtils: this.config.mapUtils || defaultMapTrackerConfig.mapUtils,
      isAnimateBearing: this.config.isAnimateBearing,
      bearingAnimationIncrement: this.config.bearingAnimationStep,
      maxDistanceBehindMeters: this.config.maxDistanceBehindMeters,
    };
    this.config.changeDetectionOptimizer.runOutsideChangeDetection(() => this.tick());
  }

  public addPoints(points: PointTime[]): void {
    points.forEach((point) => {
      const { id } = point;
      if (!this.moverMap[id]) {
        this.moverMap[id] = new Mover(id, point, this.moverConfig);
      } else {
        this.moverMap[id].addPoint(point);
      }
    });
    this.batchesCount = Math.floor(Object.keys(this.moverMap).length / this.config.animationBatchSize) + 1;
  }

  public jumpTo(point: PointTime, angleFromPoint: PointTime): void {
    if (!this.moverMap[point.id]) {
      this.moverMap[point.id] = new Mover(point.id, point, this.moverConfig);
    }
    this.moverMap[point.id].jumpTo(point, angleFromPoint);
  }

  public subscribePositionsChanged(onchange: OnChangeCallbackFn): string {
    const id = getRandomId();
    this.subscriptionsMap[id] = onchange;
    return id;
  }

  public unsubscribePositionsChanged(id: string): void {
    delete this.subscriptionsMap[id];
  }

  public destroy(): void {
    this.isDestroyed = true;
  }

  public clearAnimations(): void {
    this.moverMap = {};
    this.batchesCount = 1;
  }

  private tick(): void {
    if (this.isDestroyed) {
      return;
    } else {
      this.config.tickerFn(() => {
        this.onTicked();
        this.tick();
      });
    }
  }

  private onTicked(): void {
    this.currentBatch = this.currentBatch + 1 > this.batchesCount ? 1 : this.currentBatch + 1;
    const positions = [];
    const currentUTC = new Date().getTime();
    const ids = Object.keys(this.moverMap);
    if (!ids.length) {
      return;
    }
    const startInd = (this.currentBatch - 1) * this.config.animationBatchSize;
    const endInd = Math.min(ids.length, this.currentBatch * this.config.animationBatchSize);
    for (let i = startInd; i < endInd; i++) {
      const id = ids[i];
      const mover = this.moverMap[id];
      if (mover.hasPendingAnimation()) {
        mover.tick(currentUTC);
        const newPosition: ResultPosition = {
          id,
          bearing: mover.currentGoogleBearing,
          animationState: mover.currentAnimationState,
          animationProgress: mover.currentAnimationProgress,
          ...mover.currentPosition,
        };
        if (mover.meta) {
          newPosition.meta = mover.meta;
        }
        positions.push(newPosition);
      }
    }
    if (positions.length) {
      this.config.changeDetectionOptimizer.runInsideChangeDetection(() => {
        for (const id in this.subscriptionsMap) {
          this.subscriptionsMap[id](positions);
        }
      });
    }
  }
}
