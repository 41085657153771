import { createAction, props } from '@ngrx/store';

import { DriverDetailsTab } from 'src/app/router/portal-routes/drivers';

export const driverDetailsInitialized = createAction('[DriverDetailsRoot] Driver details initialized');

export const driverDetailsDestroyed = createAction('[DriverDetailsRoot] Driver details destroyed');

export const setDriverDetailsState = createAction(
  '[DriverDetailsRoot] Set driver details state',
  props<{ tab: DriverDetailsTab; driverId: string }>(),
);

export const closeDriverDetails = createAction('[DriverDetailsRoot] Close driver details');

export const driverDetailsTabSelected = createAction(
  '[DriverDetailsRoot] Driver details tab selected',
  props<{ tab: DriverDetailsTab }>(),
);

export const vendorByDriverClicked = createAction(
  `[DriverDetailsRoot] Vendor by driver clicked`,
  props<{ vendorId: string }>(),
);

export const yardByDriverClicked = createAction(
  `[DriverDetailsRoot] Yard by driver clicked`,
  props<{ yardId: string }>(),
);
