import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wp-page-body',
  templateUrl: './page-body.component.html',
  styleUrls: ['./page-body.component.scss'],
})
export class PageBodyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
