import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SmartInputStudentHomeAddress } from '../../models/smart-input-student-home-address';
import { smartForms } from '../../../../features/student-editor/dependencies';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'wp-smart-input-student-home-address',
  template: `
    <wp-smart-input-address
      [model]="primaryAddressModel"
      (inputEvent)="onPrimaryAddressControlInputEvent()"
    ></wp-smart-input-address>
    <div class="address-constraints-wrap">
      <div class="address-constraints-label">
        <wp-title-five
          ><span [ngClass]="{ disabled: model.disabled$ | async }">Address restrictions:</span></wp-title-five
        >
      </div>
      <div class="address-constraints-radio-wrap">
        <mat-radio-group
          class="address-constraints-radio-group"
          color="primary"
          [formControl]="addressRestrictionControl"
          (change)="onPrimaryAddressRestrictionControlChange()"
        >
          <mat-radio-button [value]="'unknown'" class="address-constraints-radio-option">
            <div class="address-constraints-radio-label">Home address is unknown</div>
          </mat-radio-button>
          <mat-radio-button [value]="'protected'">
            <div class="address-constraints-radio-label protected-label">
              Home address is protected
              <div>
                <wp-grey-label
                  ><span [ngClass]="{ disabled: model.disabled$ | async }">Do not store</span></wp-grey-label
                >
              </div>
            </div>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  `,
  styles: `
    .address-constraints-wrap {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .address-constraints-label {
      margin-right: 5px;
      height: 20px;
      padding-top: 10px;
    }

    .address-constraints-radio-group ::ng-deep .mdc-form-field {
      align-items: flex-start;
    }

    .address-constraints-radio-label {
      padding-top: 10px;
      height: 20px;
    }

    .disabled {
      color: #c6c6ca;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartInputStudentHomeAddressComponent implements OnInit, OnDestroy {
  @Input() public model: SmartInputStudentHomeAddress;

  public primaryAddressModel: smartForms.SmartInputAddress;
  public addressRestrictionControl: FormControl<'unknown' | 'protected' | undefined>;

  private subscriptions = new Subscription();

  ngOnInit(): void {
    this.primaryAddressModel = this.model.componentGetPrimaryAddressModel();
    this.addressRestrictionControl = this.model.componentGetAddressRestrictionControl();
    const validationTriggerSub = this.model.validationTrigger$.pipe(filter((v) => !!v)).subscribe(() => {
      this.runValidation();
    });
    this.subscriptions.add(validationTriggerSub);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onPrimaryAddressControlInputEvent() {
    this.model.componentOnAddressInput();
  }

  onPrimaryAddressRestrictionControlChange() {
    this.model.componentOnRestrictionInput();
  }

  private runValidation(): void {
    this.primaryAddressModel.showErrorIfAny();
  }
}
