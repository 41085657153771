<button
  class="wp-link-btn"
  [ngClass]="classes"
  [disabled]="disabled"
  [ngStyle]="{fontSize: fontSize}"
  [tabIndex]="tabIndex"
  (click)="onClick($event)"
>
  <ng-content></ng-content>
</button>
