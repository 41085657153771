import { Pipe, PipeTransform } from '@angular/core';
import { ErrorParserService } from '../../store/services/error-parser.service';
import { WpError } from '@rootTypes';

@Pipe({
  name: 'errorGeneralMessage',
})
export class ErrorGeneralMessagePipe implements PipeTransform {
  constructor(private parser: ErrorParserService) {}

  transform(value: WpError, ...args: unknown[]): string {
    if (value) {
      return this.parser.getErrorStatements(value).generalErrorMessage;
    }
    return undefined;
  }
}
