import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-icon-calendar',
  templateUrl: './icon-calendar.component.html',
  styleUrls: ['./icon-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconCalendarComponent implements OnInit {
  @Input() public grey: boolean;

  constructor() {}

  ngOnInit(): void {}
}
