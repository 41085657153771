import { CreateVendorEmployeeRequest } from '../../../../api/endpoints/create-vendor-employee';
import * as fromEntities from '../entities';

export const getEmployeeAddRequest = (
  employee: fromEntities.EmployeeProfileEditModel,
  imgPath: string,
  vendorId: string,
): CreateVendorEmployeeRequest => {
  const employeeCopy = { ...employee };
  delete employeeCopy.photo;
  const request: CreateVendorEmployeeRequest = {
    ...employeeCopy,
    vendorEmployeeImagePath: imgPath,
    vendorId,
  };
  return request;
};
