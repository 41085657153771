import { UpsertDistrictCalendarRequest } from '../request';
import * as fromTypes from '@rootTypes';
import {
  getDiff,
  Diff,
  isEditChange,
  isAdditionChange,
  isRemovalChange,
} from '../../../../types/utils/common/get-diff';

/**
 * Converts all arrays to objects. It appears that objects are easier to compare.
 */
const prepareCalendar = (calendar: fromTypes.district.DistrictCalendar): any => {
  const result = { ...calendar, name: calendar.name } as any;
  delete result.exclusions;
  delete result.sortIndex;
  const copy = JSON.parse(JSON.stringify(calendar));
  result['exclusions'] = copy.exclusions.reduce((prev, curr, ind) => {
    curr['datesObj'] = curr.dates.reduce((prevD, currD) => {
      return { ...prevD, [currD]: currD };
    }, {});
    delete curr.dates;
    return { ...prev, [curr.id || `created_${ind}`]: curr };
  }, {});

  return result;
};

export const getUpdateCalendarRequest = (
  districtId: string,
  prevItem: fromTypes.district.DistrictCalendar | null,
  newItem: fromTypes.district.DistrictCalendar,
): UpsertDistrictCalendarRequest => {
  if (!prevItem) {
    const req = {
      districtId,
      name: newItem.name,
      isDefault: newItem.isDefault,
      startDate: newItem.startDate,
      endDate: newItem.endDate,
    } as UpsertDistrictCalendarRequest;
    if (newItem.exclusions) {
      req.exclusionsChange = {
        added: newItem.exclusions.map((s) => ({
          isPublic: s.isPublic,
          displayName: s.name,
          dates: s.dates,
        })),
      };
    }
    return req;
  }

  const diffs = getDiff(prepareCalendar(prevItem), prepareCalendar(newItem));
  const updateObj = getUpdateCalendarObjectFromDiffs(diffs);
  return { ...updateObj, districtId };
};

function getUpdateCalendarObjectFromDiffs(diffs: Diff<any>[]): UpsertDistrictCalendarRequest {
  const target = {} as UpsertDistrictCalendarRequest;

  // Edited any of the top-level fields
  diffs.filter((diff) => isEditChange(diff)).forEach((item) => (target[item.path[0]] = item.rhs));

  // exlusion added
  diffs
    .filter((diff) => isAdditionChange(diff, ['exclusions']))
    .forEach((item: Diff<any>) => {
      const value = item.rhs;
      if (!target.exclusionsChange) {
        target.exclusionsChange = {};
      }
      if (!target.exclusionsChange.added) {
        target.exclusionsChange.added = [];
      }
      target.exclusionsChange.added.push({
        displayName: value.name,
        isPublic: value.isPublic,
        dates: Object.keys(value.datesObj),
      });
    });

  // exlusion removed
  diffs
    .filter((diff) => isRemovalChange(diff, ['exclusions']))
    .forEach((item: Diff<any>) => {
      const value = item.lhs;
      if (!target.exclusionsChange) {
        target.exclusionsChange = {};
      }
      if (!target.exclusionsChange.removed) {
        target.exclusionsChange.removed = [];
      }
      target.exclusionsChange.removed.push(value.id);
    });

  // exclusions fields edited
  diffs
    .filter((diff) => isEditChange(diff, ['exclusions', '*']))
    .forEach((item) => {
      const exclusionId = item.path[1];
      const fieldChanged = item.path[2];
      const newVal = item.rhs;
      if (!target.exclusionsChange) {
        target.exclusionsChange = {};
      }
      if (!target.exclusionsChange.changed) {
        target.exclusionsChange.changed = {};
      }
      if (!target.exclusionsChange.changed[exclusionId]) {
        target.exclusionsChange.changed[exclusionId] =
          {} as UpsertDistrictCalendarRequest['exclusionsChange']['changed'][string];
      }
      target.exclusionsChange.changed[exclusionId][fieldChanged] = newVal;
    });

  // exclusions dates added
  diffs
    .filter((diff) => isAdditionChange(diff, ['exclusions', '*', 'datesObj']))
    .forEach((item) => {
      const exclusionId = item.path[1];
      const newVal = item.rhs;
      if (!target.exclusionsChange) {
        target.exclusionsChange = {};
      }
      if (!target.exclusionsChange.changed) {
        target.exclusionsChange.changed = {};
      }
      if (!target.exclusionsChange.changed[exclusionId]) {
        target.exclusionsChange.changed[exclusionId] =
          {} as UpsertDistrictCalendarRequest['exclusionsChange']['changed'][string];
      }
      if (!target.exclusionsChange.changed[exclusionId].dates) {
        target.exclusionsChange.changed[exclusionId].dates =
          {} as UpsertDistrictCalendarRequest['exclusionsChange']['changed'][string]['dates'];
      }
      if (!target.exclusionsChange.changed[exclusionId].dates.added) {
        target.exclusionsChange.changed[exclusionId].dates.added =
          [] as UpsertDistrictCalendarRequest['exclusionsChange']['changed'][string]['dates']['added'];
      }

      target.exclusionsChange.changed[exclusionId].dates.added.push(newVal);
    });

  // exclusions dates removed
  diffs
    .filter((diff) => isRemovalChange(diff, ['exclusions', '*', 'datesObj']))
    .forEach((item) => {
      const exclusionId = item.path[1];
      const oldVal = item.lhs;
      if (!target.exclusionsChange) {
        target.exclusionsChange = {};
      }
      if (!target.exclusionsChange.changed) {
        target.exclusionsChange.changed = {};
      }
      if (!target.exclusionsChange.changed[exclusionId]) {
        target.exclusionsChange.changed[exclusionId] =
          {} as UpsertDistrictCalendarRequest['exclusionsChange']['changed'][string];
      }
      if (!target.exclusionsChange.changed[exclusionId].dates) {
        target.exclusionsChange.changed[exclusionId].dates =
          {} as UpsertDistrictCalendarRequest['exclusionsChange']['changed'][string]['dates'];
      }
      if (!target.exclusionsChange.changed[exclusionId].dates.removed) {
        target.exclusionsChange.changed[exclusionId].dates.removed =
          [] as UpsertDistrictCalendarRequest['exclusionsChange']['changed'][string]['dates']['removed'];
      }
      target.exclusionsChange.changed[exclusionId].dates.removed.push(oldVal);
    });

  return target;
}
