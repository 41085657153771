import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { ActivityStatus } from '@apiEntities/timesheet-entries';
import { getActivityStatus } from './selectors';

@Injectable()
export class ContextActivityItemFacade {
  constructor(private store: Store) {}

  public getActivityStatus$(activityId: string): Observable<ActivityStatus> {
    return this.store.select(getActivityStatus(activityId));
  }
}
