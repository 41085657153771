import { RouteFilterType, RouteFilter, RouteEntityFilter, SearchHitFilter } from '../entities/filters';
import { PortalEntityType } from '@rootTypes';
import { decodeDataComponents } from '@rootTypes/utils';

export const FILTER_INTERNAL_SEPARATOR = ',';
export const BETWEEN_FILTERS_SEPARATOR = ';';

export const serializeRouteFilters = (filters: RouteFilter[]): string => {
  if (!filters || !filters.length) {
    return undefined;
  }
  return filters.map((f) => f.filterId).join(BETWEEN_FILTERS_SEPARATOR);
};

export const deserializeRouteFilters = (source: string): RouteFilter[] => {
  if (!source) {
    return [];
  }
  const stringifiedFilters = source.split(BETWEEN_FILTERS_SEPARATOR);
  return stringifiedFilters.map((filterStr) => {
    const type = decodeDataComponents(filterStr)[0] as RouteFilterType;
    switch (type) {
      case RouteFilterType.ENTITY:
        return deserializeEntityFilter(filterStr);
      case RouteFilterType.SEARCH_HIT_FILTER:
        return deserializeSearchHitFilter(filterStr);
    }
  });
};

function deserializeEntityFilter(source: string): RouteEntityFilter {
  const [filterType, entityType, entityId] = decodeDataComponents(source);
  return {
    filterId: source,
    type: RouteFilterType.ENTITY,
    payload: {
      entityId,
      entityType: entityType as PortalEntityType,
    },
  };
}

function deserializeSearchHitFilter(source: string): SearchHitFilter {
  const [filterType, pillLabel, field, value] = decodeDataComponents(source);
  return {
    filterId: source,
    type: RouteFilterType.SEARCH_HIT_FILTER,
    payload: {
      pillLabel,
      field,
      value,
    },
  };
}
