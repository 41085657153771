import { PortalListStoreActions } from '@rootTypes/utils/list-store-utils/portal-list-store-actions';
import { RouteGroupListFilterType, RouteGroupListItem, RouteGroupListParams } from '../types';
import { RouterOutlets } from '@rootTypes/entities/router';
import { createAction, props } from '@ngrx/store';
import { RouteGroupQuickFilterType } from '@apiEntities/route-group/route-group';

export const mainOutletRouteGroupListActions = new PortalListStoreActions<
  RouteGroupListParams,
  RouteGroupListFilterType,
  RouteGroupListItem
>('RouteGroupList [MainOutlet]');
export const secondaryOutletRouteGroupListActions = new PortalListStoreActions<
  RouteGroupListParams,
  RouteGroupListFilterType,
  RouteGroupListItem
>('RouteGroupList [SecondaryOutlet]');

export const routeGroupListActions = {
  [RouterOutlets.PRIMARY]: mainOutletRouteGroupListActions,
  [RouterOutlets.DRAWER]: secondaryOutletRouteGroupListActions,
};

export const quickFilterSelectedMainOutlet = createAction(
  'RouteGroupList [MainOutlet]: Quick filter selected',
  props<{ filter: RouteGroupQuickFilterType }>(),
);

export const quickFilterSelectedSecondaryOutlet = createAction(
  'RouteGroupList [SecondaryOutlet]: Quick filter selected',
  props<{ filter: RouteGroupQuickFilterType }>(),
);
