<div class="day-wrap" #dayWrap>
  <div class="disabled" *ngIf="readonly$ | async"></div>
  <div
    *ngIf="day$ | async; let dayObs"
    class="day {{ dayObs.css }}"
    (click)="onDayClick()"
  >
    {{ dayObs.label }}
  </div>
</div>
