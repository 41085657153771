import { YYYYMMString } from '@rootTypes/utils/common/date';
import { dateFormat } from './date-format';

/**
 *
 * @param date
 * @param timezone if null, device's local timezone is used
 */
export const dateToYYYYMM = (date: Date, timezone: string = null): YYYYMMString => {
  if (!date) {
    return undefined;
  }
  return dateFormat(date, 'yyyyMM', timezone);
};
