import { createReducer, on } from '@ngrx/store';
import * as fromTypes from '../../types';
import { RideFilter } from '../../types/filters';
import * as fromActions from '../actions/ride-list.actions';

export type AllRideListState = {
  [key in fromTypes.RideListName]?: RideListState;
};

export interface RideListState {
  page: {
    currentRideIds: string[];
    totalItemsCount: number;
    pageLoading: boolean;
    error: fromTypes.WpError | null;
  };
  pagination: {
    currentPageNumber: number;
    pageSize: number;
  };
  selectedRideId: string;
  filters: fromTypes.filters.RideFilter[];

  // set static filters to pre-filter ride list
  // e.g. when showing rides by route, or by driver
  staticFilters: fromTypes.filters.RideFilter[];
  timetable: fromTypes.RideTimetable;
  accountId?: string; // yardId for vendor user
}

export const initialRideListState: RideListState = {
  page: {
    currentRideIds: [],
    totalItemsCount: 0,
    pageLoading: false,
    error: null,
  },
  pagination: {
    currentPageNumber: 0,
    pageSize: 10,
  },
  selectedRideId: null,
  filters: [],
  staticFilters: [],
  timetable: fromTypes.RideTimetable.UPCOMING,
};

export const getInitialRideList = () => {
  return {
    ...initialRideListState,
    page: {
      ...initialRideListState.page,
    },
    pagination: {
      ...initialRideListState.pagination,
    },
    filters: [],
    staticFilters: [],
  } as RideListState;
};

export const getInitialAllRideLists = (): AllRideListState => {
  const result = {} as AllRideListState;
  fromTypes.rideListNames.forEach((name) => {
    result[name] = getInitialRideList();
  });
  return result;
};

export const rideListReducer = createReducer<AllRideListState>(
  getInitialAllRideLists(),
  on(fromTypes.cleanUpStore, getInitialAllRideLists),
  on(fromActions.setStateFromSerializedSnapshot, (allState, action) => {
    const { listName, snapshot } = action;
    const deserialized = fromTypes.utils.deserializeSnapshot(snapshot);
    const state = allState[listName];
    const newState = {
      ...state,
      pagination: {
        ...state.pagination,
        currentPageNumber: deserialized.currentPage,
        pageSize: deserialized.pageSize,
      },
      timetable: deserialized.timetable,
      filters: deserialized.dynamicFilters,
      staticFilters: deserialized.staticFilters,
      accountId: deserialized.accountId,
    } as RideListState;
    return { ...allState, [listName]: newState };
  }),
  // reset current page on user actions with list
  on(
    fromActions.filterByDatesSelected,
    fromActions.filterByEntitySelected,
    fromActions.quickFilterSelected,
    fromActions.filterRemoveSelected,
    fromActions.timetableChanged,
    fromActions.accountChanged,
    fromActions.pageSizeChanged,
    (allState, action) => {
      const { listName } = action;
      const state = allState[listName];
      const newState = {
        ...state,
        pagination: {
          ...state.pagination,
          currentPageNumber: 0,
        },
      } as RideListState;
      return { ...allState, [listName]: newState };
    },
  ),
  /**
   * Set timetable to 'All' on quick filter select (except for "none")
   */
  on(fromActions.quickFilterSelected, (allState, action) => {
    const { listName, filter } = action;
    const state = allState[listName];
    const newTimetable =
      filter.payload === fromTypes.filters.RideQuickFilterType.NONE
        ? state.timetable
        : fromTypes.RideTimetable.UPCOMING;
    const newState = {
      ...state,
      timetable: newTimetable,
    } as RideListState;
    return { ...allState, [listName]: newState };
  }),
  /**
   * Set flag, when changes in list filters might lead to changes in item count
   */
  on(
    fromActions.filterByDatesSelected,
    fromActions.filterByEntitySelected,
    fromActions.searchHitFilterSelected,
    fromActions.filterRemoveSelected,
    fromActions.timetableChanged,
    fromActions.accountChanged,
    fromActions.rideListInitialized,
    fromActions.staticFiltersChanged,
    (allState, action) => {
      const { listName } = action;
      const state = allState[listName];
      const newState = {
        ...state,
        page: {
          totalItemsCount: undefined,
        },
      } as RideListState;
      return { ...allState, [listName]: newState };
    },
  ),
  on(fromActions.pageSizeChanged, (allState, action) => {
    const { listName } = action;
    const state = allState[listName];
    const newState = {
      ...state,

      pagination: {
        ...state.pagination,
        pageSize: action.pageSize,
      },
    } as RideListState;
    return { ...allState, [listName]: newState };
  }),
  on(fromActions.currentPageChanged, (allState, action) => {
    const { listName } = action;
    const state = allState[listName];
    const newState = {
      ...state,

      pagination: {
        ...state.pagination,
        currentPageNumber: action.page,
      },
    } as RideListState;
    return { ...allState, [listName]: newState };
  }),
  on(fromActions.timetableChanged, (allState, action) => {
    const { listName } = action;
    const state = allState[listName];
    const newState = {
      ...state,
      timetable: action.timetable,
    } as RideListState;
    return { ...allState, [listName]: newState };
  }),
  on(fromActions.accountChanged, (allState, action) => {
    const { listName, accountId } = action;
    const state = allState[listName];
    const newState = {
      ...state,
      accountId,
    } as RideListState;
    return { ...allState, [listName]: newState };
  }),
  on(fromActions.filtersChanged, (allState, action) => {
    const { listName } = action;
    const state = allState[listName];
    const newState = {
      ...state,
      filters: [...action.filters],
    } as RideListState;
    return { ...allState, [listName]: newState };
  }),
  on(fromActions.staticFiltersChanged, (allState, action) => {
    const { listName, filters } = action;
    const state = allState[listName];
    if (areFiltersEqual(state.staticFilters, filters)) {
      return { ...allState, [listName]: { ...state } };
    } else {
      const newState = {
        ...getInitialRideList(),
        staticFilters: [...action.filters],
      } as RideListState;
      return { ...allState, [listName]: newState };
    }
  }),
  // load ride page
  on(fromActions.loadRidePageRequested, (allState, action) => {
    const { listName } = action;
    const state = allState[listName];
    const newState = {
      ...state,
      page: {
        ...state.page,
        pageLoading: true,
      },
    } as RideListState;
    return { ...allState, [listName]: newState };
  }),
  on(fromActions.loadRidePageSuccess, (allState, action) => {
    const { listName } = action;
    const state = allState[listName];
    const { page } = action;
    const newState = {
      ...state,
      page: {
        ...state.page,
        currentRideIds: [...action.page.rideIds],
        totalItemsCount: action.page.totalRides,
        error: null,
        pageLoading: false,
        itemsCountLoading: false,
      },
    } as RideListState;
    return { ...allState, [listName]: newState };
  }),
  on(fromActions.loadRidePageFailed, (allState, action) => {
    const { listName } = action;
    const state = allState[listName];
    const newState = {
      ...state,
      page: {
        ...state.page,
        pageLoading: false,
        error: action.error,
      },
    } as RideListState;
    return { ...allState, [listName]: newState };
  }),
  on(fromActions.selectedRideIdChanged, (allState, action) => {
    const { listName, rideId } = action;
    const state = allState[listName];
    const newState = {
      ...state,
      selectedRideId: rideId,
    } as RideListState;
    return { ...allState, [listName]: newState };
  }),
);

function areFiltersEqual(filters1: RideFilter[] = [], filters2: RideFilter[] = []): boolean {
  const hashFn = (f: RideFilter) => f.filterId;
  return fromTypes.utils.arraysEqual(filters1, filters2, hashFn);
}
