import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const modulePrefix = 'Data';
const sectionPrefix = 'Campus Calendars';

export const loadCampusCalendarsRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: API Load campus calendars requested`,
  props<{ campusId: string; districtId: string }>(),
);

export const loadCampusCalendarsRequestedFromCampusCalendarEditor = createAction(
  `[CampusCalendarEditorEffects]: API Load campus calendars requested`,
  props<{ campusId: string; districtId: string }>(),
);

export const loadCampusCalendarsRequestedFromCampusScheduleEditor = createAction(
  `[CampusScheduleEditorEffects]: API Load campus calendars requested`,
  props<{ campusId: string; districtId: string }>(),
);

export const loadCampusCalendarsSuccess = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load campus calendars success`,
  props<{ campusId: string; payload: fromTypes.LoadCalendarPayload }>(),
);

export const loadCampusCalendarsFailed = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Load campus calendars failed`,
  props<{ campusId: string; error: fromTypes.WpError }>(),
);

/**
 * Make calendar default
 */
export const makeCalendarDefaultRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Make calendar default requested`,
  props<{ calendarId: string; campusId: string; districtId: string }>(),
);

export const makeCalendarDefaultSuccess = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Make calendar default success`,
  props<{ calendarId: string; campusId: string }>(),
);

export const makeCalendarDefaultFailed = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Make calendar default failed`,
  props<{ calendarId: string; campusId: string; error: fromTypes.WpError }>(),
);

/**
 * Remove (deactivate) campus calendar
 */
export const removeCalendarRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Remove calendar requested`,
  props<{ calendarId: string; campusId: string; districtId: string }>(),
);

export const removeCalendarSuccess = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Remove calendar success`,
  props<{ calendarId: string; campusId: string; districtId: string }>(),
);

export const removeCalendarFailed = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Remove calendar failed`,
  props<{ calendarId: string; campusId: string; districtId: string; error: fromTypes.WpError }>(),
);

/**
 * Remove (deactivate schedule)
 */
export const removeScheduleRequested = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Remove schedule requested`,
  props<{ calendarId: string; campusId: string; districtId: string; scheduleId: string }>(),
);

export const removeScheduleSuccess = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Remove schedule success`,
  props<{ calendarId: string; campusId: string; districtId: string; scheduleId: string }>(),
);

export const removeScheduleFailed = createAction(
  `[${modulePrefix}] [${sectionPrefix}]: Remove schedule failed`,
  props<{ calendarId: string; campusId: string; districtId: string; scheduleId: string; error: fromTypes.WpError }>(),
);
