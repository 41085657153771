import { parse } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';

/**
 *
 * @param source
 * @param format
 * @param timezone if timezone is provided, the source string is parsed as if it was in the given timezone,
 * if timezone is not provided, the source string is parsed as if it was in the user's timezone
 */
export const dateParse = (source: string, format: string, timezone?: string): Date => {
  const parsedDate = parse(source, format, new Date());
  return timezone ? fromZonedTime(parsedDate, timezone) : parsedDate;
};
