<div
  class="wp-square-border"
  [ngStyle]="{ 'width': width }"
  [ngClass]="{
    'highlighted': highlighted,
    'disabled': disabled
}"
>
  <wp-disabled-mask *ngIf="disabled"></wp-disabled-mask>
  <ng-content style="width: 100%"></ng-content>
</div>
