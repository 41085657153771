<section class="wp-user-profile">
  <div class="header">My profile</div>

  <div class="columns">
    <div class="column left">
      <ng-container *ngIf="isPhotoLoading$ | async; else photoPreview">
        <wp-section-spinner [sectionHeight]="'132px'"> </wp-section-spinner>
      </ng-container>

      <ng-template #photoPreview>
        <wp-display-photo
          [width]="'132px'"
          [height]="'132px'"
          [imageBase64]="photoBase64$ | async"
          [placeholderAbbreviation]="userInitials$ | async"
          [placeholderAbbreviationFontSize]="'28px'"
          [isRound]="true"
        >
        </wp-display-photo>

        <wp-link-btn class="photo-btn" (clicked)="openUploadPhotoPopup()"
          >Upload photo
        </wp-link-btn>
      </ng-template>
    </div>
    <div class="column">
      <router-outlet></router-outlet>
    </div>
  </div>
</section>
