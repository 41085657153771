import * as fromTypes from '../../types';
import * as fromActions from '../actions/vendor-details.actions';
import { createReducer, on } from '@ngrx/store';

export interface VendorDetailsState {
  vendorId: string | undefined;
  tab: fromTypes.VendorDetailsTabs;
}

export const initialVendorDetailsState: VendorDetailsState = {
  vendorId: undefined,
  tab: undefined,
};

export const vendorDetailsReducer = createReducer<VendorDetailsState>(
  { ...initialVendorDetailsState },
  on(fromTypes.cleanUpStore, () => ({ ...initialVendorDetailsState })),
  on(fromActions.setVendorId, (state, action) => {
    const { vendorId } = action;
    const res: VendorDetailsState = {
      ...state,
      vendorId,
    };
    return res;
  }),
  on(fromActions.setTab, (state, action) => {
    const { tab } = action;
    const res: VendorDetailsState = {
      ...state,
      tab,
    };
    return res;
  }),
);
