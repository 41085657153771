import { EntityFilterRequest, EntityFilterRequestType } from '../../../../api/endpoints/entity-filter';
import { VendorListSnapshot } from '../vendor-list-snapshot';

export const getVendorListRequest = ({ page, pageSize, filters, search }: VendorListSnapshot): EntityFilterRequest => {
  return {
    type: EntityFilterRequestType.VENDOR,
    skip: page * pageSize,
    limit: pageSize,
    query: search,
  } as EntityFilterRequest;
};
