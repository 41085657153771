import { ChangeDetectionStrategy, Component, OnInit, SecurityContext } from '@angular/core';
import { AbstractPopupComponent } from '../../core/popupable/types';
import { ResponseWithApiNotification } from '../../api/notification-api/types/response-with-api-notification';
import { PopupRef } from 'src/app/features/student-change-requests/dependencies';
import { APIButtonStyle, ApiNotification } from '../../api/notification-api/types/api-notification';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CLOSE_NOTIFICATION_MODAL_IF_NO_BUTTON_MILLIS } from '../../api/notification-api/config';

@Component({
  selector: 'wp-notification-api-popup',
  template: `
    <wp-common-popup-page>
      <wp-common-popup-page-top (closeClicked)="onClose()" [isSeparatorLine]="true">
        <wp-common-popup-page-title>
          <ng-container *ngIf="notification.title">
            <span *ngIf="titleHTML; else plainTextTitle" [innerHTML]="titleHTML"></span>
            <ng-template #plainTextTitle>
              {{ notification.title.text }}
            </ng-template>
          </ng-container>
        </wp-common-popup-page-title>
      </wp-common-popup-page-top>
      <wp-common-popup-page-body>
        <wp-stack-layout [direction]="'column'" [spacing]="30" [secondaryAxisAlignment]="'flex-end'">
          <wp-stack-layout-item>
            <div style="min-width: 240px; max-width: 560px;" *ngIf="notification.message">
              <div *ngIf="messageHtml; else plainTextMessage" [innerHTML]="messageHtml"></div>
              <ng-template #plainTextMessage>
                {{ notification.message.text }}
              </ng-template>
            </div>
          </wp-stack-layout-item>

          <wp-stack-layout-item>
            <ng-container *ngIf="notification.button">
              <ng-container [ngSwitch]="notification.button.style">
                <ng-container *ngSwitchCase="buttonStyles.POSITIVE">
                  <wp-btn-primary [width]="'240px'" (clicked)="onClose()">
                    {{ notification.button.displayText }}
                  </wp-btn-primary>
                </ng-container>
                <ng-container *ngSwitchCase="buttonStyles.NEUTRAL">
                  <wp-btn-secondary [width]="'240px'" (clicked)="onClose()">
                    {{ notification.button.displayText }}
                  </wp-btn-secondary>
                </ng-container>
                <ng-container *ngSwitchCase="buttonStyles.DESTRUCTIVE">
                  <wp-btn-primary [width]="'240px'" [color]="'red'" (clicked)="onClose()">
                    {{ notification.button.displayText }}
                  </wp-btn-primary>
                </ng-container>
              </ng-container>
            </ng-container>
          </wp-stack-layout-item>
        </wp-stack-layout>
      </wp-common-popup-page-body>
    </wp-common-popup-page>
  `,
  styleUrl: './notification-api-popup.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationApiPopupComponent
  implements AbstractPopupComponent<ResponseWithApiNotification, ResponseWithApiNotification>, OnInit
{
  popupRef: PopupRef<ResponseWithApiNotification, ResponseWithApiNotification>;
  public notification: ApiNotification;
  public titleHTML: SafeHtml;
  public messageHtml: SafeHtml;
  public buttonStyles = APIButtonStyle;

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.notification = this.popupRef.data.apiNotification;
    if (this.notification.title?.value) {
      this.titleHTML = this.domSanitizer.sanitize(SecurityContext.HTML, this.notification.title?.value);
    }
    if (this.notification.message.value) {
      this.messageHtml = this.domSanitizer.sanitize(SecurityContext.HTML, this.notification.message.value);
    }
    if (!this.notification.button) {
      setTimeout(() => this.onClose(), CLOSE_NOTIFICATION_MODAL_IF_NO_BUTTON_MILLIS);
    }
  }

  onClose(): void {
    this.popupRef.close(this.popupRef.data);
  }
}
