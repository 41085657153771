import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wp-btn-primary',
  templateUrl: './btn-primary.component.html',
  styleUrls: ['./btn-primary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BtnPrimaryComponent implements OnInit {
  @Input() public disabled = false;
  @Input() public loading = false;
  @Input() public slim = false;

  @Input() public height!: string;
  @Input() public width!: string;
  @Input() public fontSize = '16px';
  @Input() public color: 'red' | 'green' = 'green';
  @Input() public tabIndex = '0';

  @Output() public clicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor() {}

  ngOnInit(): void {
    this.height = this.slim ? '36px' : this.height || '46px';
  }

  public btnSize(): any {
    return {
      width: this.width,
      height: this.height,
      padding: this.width === 'auto' ? '0 24px' : '0px',
      fontSize: this.fontSize,
    };
  }

  public onClick(event: MouseEvent): boolean {
    if (this.disabled || this.loading) {
      return false;
    }
    event.preventDefault();
    this.clicked.emit(event);
    return false;
  }
}
