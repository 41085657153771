import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { take, switchMap, map, filter } from 'rxjs/operators';

import { AccountState } from '../store/reducers';
import * as fromActions from '../store/actions';
import { commonRoutes } from '@router';
import { loadAccount, isSelectAccountRequired } from '../utils';
import * as fromSelectors from '../store/selectors';

@Injectable({
  providedIn: 'root',
})
export class SelectAccountGuard {
  constructor(
    private store: Store<AccountState>,
    private router: Router,
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    this.store.dispatch(fromActions.checkAuthRequested());
    return this.store.select(fromSelectors.authStatus).pipe(
      filter((auth) => !auth.isLoading),
      take(1),
      switchMap((auth) => {
        if (!auth.isUserAuthenticated) {
          return of(this.router.parseUrl(commonRoutes.login.url));
        }
        return this.checkAccount();
      }),
    );
  }

  private checkAccount(): Observable<boolean | UrlTree> {
    return loadAccount(this.store, fromActions.selectAccountGuardAccountRequested).pipe(
      take(1),
      switchMap((account) => {
        return isSelectAccountRequired(
          account,
          this.store,
          fromActions.selectAccountGuardAccountsRequested,
          fromActions.selectAccountGuardSetAccountRequested,
        ).pipe(
          take(1),
          map((required) => {
            if (required) {
              return true;
            }
            return this.router.parseUrl(commonRoutes.home.url);
          }),
        );
      }),
    );
  }
}
