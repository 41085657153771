import { createSelector } from '@ngrx/store';
import { getVendorFeature } from './get-feature';
import { getAllVendorEntities } from './vendor-data.selectors';

const getVendorDetailsState = createSelector(getVendorFeature, (state) => state.details);

export const getVendorId = createSelector(getVendorDetailsState, (state) => state.vendorId);

export const getTab = createSelector(getVendorDetailsState, (state) => state.tab);

const getOpenedVendorEntity = createSelector(getAllVendorEntities, getVendorId, (allVendorEntities, vendorId) => {
  if (!vendorId) {
    return undefined;
  }
  return allVendorEntities[vendorId];
});

export const getOpenedVendor = createSelector(getOpenedVendorEntity, (state) => state?.entity);

export const getOpenedVendorLoading = createSelector(getOpenedVendorEntity, (state) => state?.isLoading);

export const getOpenedVendorError = createSelector(getOpenedVendorEntity, (state) => state?.error);
