import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralNoun',
})
export class PluralNounPipe implements PipeTransform {
  transform(noun: string, quantity: number, suffix = 's'): string {
    if (quantity > 1) {
      return noun + suffix;
    }
    return noun;
  }
}
