import {
  ChangeDetectionOptimizer,
  MapTracker,
  PointTime,
  ResultPosition,
  TrackerUrlMarker,
} from '@rootTypes/utils/map-tracker';
import { AnimatedLine } from './animated-line';
import { PortalRouteTracePoint } from '@rootTypes/entities/ride';
import { CustomRouteRenderer } from '../custom-route-renderer';
import { MapUtilsGoogle } from '@rootTypes/utils/map-tracker/utils/map-utils-google';
import { NgZone } from '@angular/core';

const markerURL = `${window.origin}/assets/icons/map-vehicle.svg`;
// start, end
type AnimationLeg = [RouteTracePointWithLeaveTraceFlag, RouteTracePointWithLeaveTraceFlag];
type RouteTracePointWithLeaveTraceFlag = PortalRouteTracePoint & { isLeaveTrace: boolean };

export interface CarMover2Config {
  traceGapMeters: number;
  isShowTraceAdditionalInfo: boolean;
  tracePolylineOptions: google.maps.PolylineOptions;
}

export class CarMover2 {
  private tracker: MapTracker;
  private subId: string;
  private carMarker: TrackerUrlMarker;
  private id = '' + new Date() + Math.random();
  private isInitialized = false;
  private mapUtils = new MapUtilsGoogle();

  /**
   * Segments created when animating transition between two points
   */
  private animationPolylineSegments: AnimatedLine[] = [];
  private lastAnimationPosition: ResultPosition;
  private pendingAnimationLegs: AnimationLeg[] = [];
  private lastAddedPoint: RouteTracePointWithLeaveTraceFlag;
  private alreadyAnimatedMap: { [traceId: string]: boolean } = {};

  constructor(
    private map: google.maps.Map,
    private customRouteRenderer: CustomRouteRenderer,
    private config: CarMover2Config,
    private ngZone: NgZone,
  ) {}

  public pushLocations(locs: PortalRouteTracePoint[], leaveTrace: boolean): void {
    if (!locs.length) {
      return;
    }
    if (!this.isInitialized) {
      this.init();
    }
    // filter out those that we've already animated, and append flag
    const withLeaveTraceAppended = locs
      .filter((l) => !this.alreadyAnimatedMap[l.id])
      .map((l) => this.appendLeaveTraceFlag(l, leaveTrace));
    if (!withLeaveTraceAppended.length) {
      return;
    }
    // add to already animated
    withLeaveTraceAppended.forEach((t) => (this.alreadyAnimatedMap[t.id] = true));
    // check if traces within distance limits
    if (this.isRouteTracesWithinDistanceLimit(locs)) {
      // if yes, add to map tracker
      const points: PointTime[] = withLeaveTraceAppended.map((source) => this.routeTracePointToPointTime(source));
      this.tracker.addPoints(points);
    } else {
      // else jump tracker to the last animation point
      const jumpPoint = withLeaveTraceAppended[withLeaveTraceAppended.length - 1];
      const angleFromPoint =
        withLeaveTraceAppended.length > 1
          ? withLeaveTraceAppended[withLeaveTraceAppended.length - 2]
          : this.lastAddedPoint;
      this.tracker.jumpTo(
        this.routeTracePointToPointTime(jumpPoint),
        angleFromPoint ? this.routeTracePointToPointTime(angleFromPoint) : undefined,
      );
    }
    // update animation state
    this.pendingAnimationLegs.push(...this.getAnimationLegsFromPoints(withLeaveTraceAppended));
    this.lastAddedPoint = this.pendingAnimationLegs[this.pendingAnimationLegs.length - 1][1];
  }

  public jumpTo(loc: PortalRouteTracePoint, angleFrom?: PortalRouteTracePoint, leaveTrace?: boolean): void {
    if (!this.isInitialized) {
      this.init();
    }
    this.alreadyAnimatedMap[loc.id] = true;
    const locAppended = this.appendLeaveTraceFlag(loc, leaveTrace);
    const angleFromAppended = angleFrom ? this.appendLeaveTraceFlag(angleFrom, leaveTrace) : undefined;
    this.tracker.jumpTo(
      this.routeTracePointToPointTime(locAppended),
      angleFromAppended ? this.routeTracePointToPointTime(angleFromAppended) : undefined,
    );
    this.pendingAnimationLegs.push(...this.getAnimationLegsFromPoints([locAppended]));
    this.lastAddedPoint = this.pendingAnimationLegs[this.pendingAnimationLegs.length - 1][1];
  }

  public destroy(): void {
    if (this.carMarker) {
      this.carMarker.destroy();
      this.carMarker = undefined;
    }
    if (this.tracker) {
      this.tracker.unsubscribePositionsChanged(this.subId);
      this.tracker.destroy();
      this.tracker = undefined;
    }
    this.animationPolylineSegments.forEach((p) => p.hide());
    this.animationPolylineSegments = [];
    this.isInitialized = false;
  }

  // BD-3569 Consider showing car tooltip with this method
  public setCarTooltip(): void {
    if (this.carMarker) {
      this.carMarker.setTooltip();
    }
  }

  public hideCarIcon(): void {
    this.carMarker.destroy();
  }

  private init(): void {
    this.carMarker = new TrackerUrlMarker(this.id, markerURL, 40, 41, this.map);
    this.tracker = new MapTracker({
      maxSpeedLimitMPH: 80,
      minSpeedLimitMPH: 10,
      isAnimateBearing: true,
      bearingAnimationStep: 10,
      changeDetectionOptimizer: this.getAngularChangeDetectionOptimizer(),
      maxDistanceBehindMeters: 100,
    });
    this.subId = this.tracker.subscribePositionsChanged((positions) => {
      this.onPositionsEmittedByTracker(positions);
    });
    this.isInitialized = true;
  }

  private onPositionsEmittedByTracker(positions: ResultPosition[]): void {
    if (positions.length) {
      this.ngZone.runOutsideAngular(() => {
        const position = positions[0];
        this.updateMarkerWithPosition(position);
        if (position.animationState === 'coords' || position.animationState === 'complete') {
          this.handleCoordinateUpdate(position);
        }
      });
    }
  }

  private updateMarkerWithPosition(pos: ResultPosition): void {
    this.carMarker.setPosition(pos);
    this.carMarker.setAngle(pos.bearing);
  }

  private handleCoordinateUpdate(pos: ResultPosition): void {
    if (pos.animationState === 'complete') {
      const trace = pos.meta.trace as PortalRouteTracePoint;
      this.customRouteRenderer.addPoints(
        this.extractPendingAnimationPointsUntilId(trace.id).filter((p) => p.isLeaveTrace),
      );
      this.animationPolylineSegments.forEach((p) => p.hide());
      this.animationPolylineSegments = [];
    } else {
      const leaveTrace = (pos.meta.trace as RouteTracePointWithLeaveTraceFlag).isLeaveTrace || false;
      if (leaveTrace && this.lastAnimationPosition) {
        const start = new google.maps.LatLng(this.lastAnimationPosition.lat, this.lastAnimationPosition.lng);
        const end = new google.maps.LatLng(pos.lat, pos.lng);
        this.animationPolylineSegments.push(
          new AnimatedLine(pos.id, this.map, [start, end], this.config.tracePolylineOptions),
        );
      }
    }
    this.lastAnimationPosition = pos;
  }

  /**
   * Utilities
   */

  private extractPendingAnimationPointsUntilId(id: string): RouteTracePointWithLeaveTraceFlag[] {
    const ind = this.pendingAnimationLegs.findIndex((p) => p[1].id === id);
    if (ind >= 0) {
      const result: RouteTracePointWithLeaveTraceFlag[] = [];
      let i = 0;
      while (i++ < ind) {
        result.push(this.pendingAnimationLegs.shift()[0]);
      }
      result.push(...this.pendingAnimationLegs.shift());
      return result;
    } else {
      return [];
    }
  }

  private isRouteTracesWithinDistanceLimit(points: PortalRouteTracePoint[]): boolean {
    if (this.lastAddedPoint) {
      if (this.mapUtils.getDistanceBetweenPoints(this.lastAddedPoint, points[0]) > this.config.traceGapMeters) {
        return false;
      }
    }
    for (let i = 1; i < points.length; i++) {
      const prev = points[i - 1];
      const curr = points[i];
      if (this.mapUtils.getDistanceBetweenPoints(prev, curr) > this.config.traceGapMeters) {
        return false;
      }
    }
    return true;
  }

  private routeTracePointToPointTime(source: RouteTracePointWithLeaveTraceFlag): PointTime {
    return {
      id: this.id,
      lat: source.lat,
      lng: source.lng,
      utc: source.collected_timestamp,
      bearing: source.bearing,
      meta: {
        trace: source,
      },
    };
  }

  private getAnimationLegsFromPoints(points: RouteTracePointWithLeaveTraceFlag[]): AnimationLeg[] {
    if (!this.lastAddedPoint && points.length === 1) {
      return [[points[0], points[0]]];
    }
    if (!points.length) {
      return [];
    }
    const result = [];
    if (this.lastAddedPoint) {
      const legFromLastAddedPoint = { ...this.lastAddedPoint };
      if (points[0].isLeaveTrace) {
        legFromLastAddedPoint.isLeaveTrace = true;
      }
      result.push([legFromLastAddedPoint, points[0]]);
    }
    const l1 = points.length - 1;
    for (let i = 0; i < l1; i++) {
      const curr = points[i];
      const next = points[i + 1];
      if (next.isLeaveTrace) {
        curr.isLeaveTrace = true;
      }
      result.push([curr, next]);
    }
    return result;
  }

  private appendLeaveTraceFlag(point: PortalRouteTracePoint, leaveTrace: boolean): RouteTracePointWithLeaveTraceFlag {
    return {
      ...point,
      isLeaveTrace: leaveTrace,
    };
  }

  private getAngularChangeDetectionOptimizer(): ChangeDetectionOptimizer {
    return {
      runOutsideChangeDetection: (cb: () => void) => {
        this.ngZone.runOutsideAngular(() => cb());
      },
      runInsideChangeDetection: (cb: () => void) => {
        this.ngZone.run(() => cb());
      },
    };
  }
}
