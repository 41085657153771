import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { DriverListStateParams } from '../../store/reducers/driver-lists.reducer';
import { DriverDataFacade } from '../facades';
import {
  driverListInitialize,
  driverListPageItemsFailed,
  driverListPageItemsRequested,
  driverListPageItemsSuccess,
  driverListPageSelected,
  driverListPageSizeSelected,
  driverListSearchTermChanged,
  driverListStatusSelected,
  driverListStaticFiltersChanged,
  driverListMultiAccountFilterChanged,
} from '../actions/driver-list.actions';
import { catchError, delay, filter, map, switchMap, take } from 'rxjs/operators';
import { driverListParams } from '../selectors/driver-lists.selectors';
import { of } from 'rxjs';
import * as fromTypes from '../../types';
import { DriversApiService } from '../../services/drivers-api.service';
import { State } from '@rootStore';

@Injectable()
export class DriverListEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private api: DriversApiService,
    private driverFacade: DriverDataFacade,
  ) {}

  public listInitialize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(driverListInitialize),
      switchMap((action) => {
        const { listName } = action;
        return this.store.select(driverListParams, { listName }).pipe(
          take(1),
          map((params) => {
            return this.createPageItemsRequestedAction(listName, params);
          }),
        );
      }),
    ),
  );

  public pageItemsRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(driverListPageItemsRequested),
      switchMap((action) => {
        const { listName } = action;
        const request = fromTypes.utils.getRequestFromSnapshot(action.request);
        return this.api.getDriverListPage(request).pipe(
          map((res) => {
            return driverListPageItemsSuccess({
              listName,
              data: {
                pageItems: res.results,
                totalFound: res.total,
              },
            });
          }),
          catchError((error) => {
            return of(
              driverListPageItemsFailed({
                listName,
                error: {
                  text: 'Failed to load driver list',
                  originalError: error,
                },
              }),
            );
          }),
        );
      }),
    );
  });

  public dispatchPageItemDetailsRequestsForSearchTerm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(driverListPageItemsSuccess),
        switchMap((action) => {
          const { listName, data } = action;
          return this.store.select(driverListParams, { listName }).pipe(
            take(1),
            filter((params) => !!action.data.pageItems?.length && !!params.search),
            delay(300),
            map(() => this.requestDetailsForEachItem(data.pageItems)),
          );
        }),
      ),
    { dispatch: false },
  );

  public dispatchPageItemDetailsRequestsForEmptySearchTerm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(driverListPageItemsSuccess),
        switchMap((action) => {
          const { listName, data } = action;
          return this.store.select(driverListParams, { listName }).pipe(
            take(1),
            filter((params) => !!action.data.pageItems?.length && !params.search),
            map(() => this.requestDetailsForEachItem(data.pageItems)),
          );
        }),
      ),
    { dispatch: false },
  );

  public listParamsChanged$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        driverListStatusSelected,
        driverListSearchTermChanged,
        driverListPageSelected,
        driverListPageSizeSelected,
        driverListStaticFiltersChanged,
        driverListMultiAccountFilterChanged,
      ),
      switchMap((action) => {
        const { listName } = action;
        return this.store.select(driverListParams, { listName }).pipe(
          take(1),
          map((params) => this.createPageItemsRequestedAction(listName, params)),
        );
      }),
    );
  });

  private createPageItemsRequestedAction(listName: fromTypes.DriverListName, params: DriverListStateParams): Action {
    return driverListPageItemsRequested({
      listName,
      request: {
        ...params,
        filters: [...params.filters, ...params.staticFilters],
      },
    });
  }

  private requestDetailsForEachItem(pageItems: fromTypes.PortalEntity[]): void {
    pageItems.forEach((item) => {
      this.driverFacade.loadDriver(item.entityId, { withVehicle: true });
    });
  }
}
