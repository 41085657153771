import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../../actions/campus-details/calendars-tab.actions';
import * as fromDataActions from '../../actions/campus-calendar-data.actions';
import * as fromSelectors from '../../selectors/campus-details/campus-details.selectors';
import { Store } from '@ngrx/store';
import { openErrorPopup, State } from '@rootStore';
import { filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { SnackbarService } from '../../../../../shared/snackbar/snackbar.service';
import {
  CampusCalendarEditorRouterService,
  CampusScheduleEditorRouterService,
} from '../../../../../router/portal-routes/campus-info-editor';

@Injectable()
export class CalendarsTabEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private toastService: SnackbarService,
    private campusCalendarEditorRoute: CampusCalendarEditorRouterService,
    private campusScheduleEditorRoute: CampusScheduleEditorRouterService,
  ) {}

  /**
   * Make calendar default
   */
  public makeCalendarDefaultClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.makeCalendarDefaultClicked),
      mergeMap((action) => {
        return this.store.select(fromSelectors.getCampus).pipe(
          filter((campus) => !!campus),
          take(1),
          map((campus) =>
            fromDataActions.makeCalendarDefaultRequested({
              calendarId: action.calendarId,
              campusId: campus.id,
              districtId: campus.districtId,
            }),
          ),
        );
      }),
    ),
  );

  /**
   * Open toast on success
   * */
  public makeCalendarDefaultSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromDataActions.makeCalendarDefaultSuccess),
        map((action) => this.toastService.success('Successfully updated default calendar')),
      ),
    {
      dispatch: false,
    },
  );

  /**
   * Open popup on error
   * */
  public makeCalendarDefaultFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDataActions.makeCalendarDefaultFailed),
      map((action) => openErrorPopup({ error: action.error })),
    ),
  );

  /**
   * Remove calendar
   */
  public removeCalendarClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeCalendarClicked),
      mergeMap((action) => {
        return this.store.select(fromSelectors.getCampus).pipe(
          filter((campus) => !!campus),
          take(1),
          map((campus) =>
            fromDataActions.removeCalendarRequested({
              calendarId: action.calendarId,
              campusId: campus.id,
              districtId: campus.districtId,
            }),
          ),
        );
      }),
    ),
  );

  /**
   * Open toast on success
   */
  public removeCalendarSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromDataActions.removeCalendarSuccess),
        map((action) => this.toastService.success('Successfully removed calendar')),
      ),
    {
      dispatch: false,
    },
  );

  /**
   * Open popup on fail
   */
  public removeCalendarFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDataActions.removeCalendarFailed),
      map((action) => openErrorPopup({ error: action.error })),
    ),
  );

  /**
   * Remove schedule
   */
  public removeScheduleClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeScheduleClicked),
      mergeMap((action) => {
        return this.store.select(fromSelectors.getCampus).pipe(
          filter((campus) => !!campus),
          take(1),
          map((campus) =>
            fromDataActions.removeScheduleRequested({
              calendarId: action.calendarId,
              scheduleId: action.scheduleId,
              campusId: campus.id,
              districtId: campus.districtId,
            }),
          ),
        );
      }),
    ),
  );

  /**
   * Open toast on success
   */
  public removeScheduleSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromDataActions.removeScheduleSuccess),
        map((action) => this.toastService.success('Successfully removed schedule')),
      ),
    {
      dispatch: false,
    },
  );

  /**
   * Open popup on fail
   */
  public removeScheduleFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDataActions.removeScheduleFailed),
      map((action) => openErrorPopup({ error: action.error })),
    ),
  );

  /**
   * Add calendar clicked
   */
  public addCalendarClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addNewCalendarClicked),
        switchMap(() => this.store.select(fromSelectors.getCampusId).pipe(take(1))),
        tap((campusId) => {
          this.campusCalendarEditorRoute.navigate({ campusId, displayBackText: 'Back to campus' });
        }),
      ),
    { dispatch: false },
  );

  /**
   * Edit calendar clicked
   */
  public editCalendarClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.editCalendarClicked),
        switchMap(({ calendarId }) => {
          return this.store.select(fromSelectors.getCampusId).pipe(
            take(1),
            tap((campusId) => {
              this.campusCalendarEditorRoute.navigate({ campusId, calendarId, displayBackText: 'Back to campus' });
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  /**
   * Add schedule clicked
   */
  public addScheduleClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.addScheduleClicked),
        switchMap(({ calendarId }) => {
          return this.store.select(fromSelectors.getCampusId).pipe(
            take(1),
            tap((campusId) => {
              this.campusScheduleEditorRoute.navigate({ campusId, calendarId, displayBackText: 'Back to campus' });
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  /**
   * Edit schedule clicked
   */
  public editScheduleClicked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.editScheduleClicked),
        switchMap(({ calendarId, scheduleId }) => {
          return this.store.select(fromSelectors.getCampusId).pipe(
            take(1),
            tap((campusId) => {
              this.campusScheduleEditorRoute.navigate({
                campusId,
                calendarId,
                scheduleId,
                displayBackText: 'Back to campus',
              });
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
