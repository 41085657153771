import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { AnimationsModule } from '../animations/animations.module';
import { ComponentsModule } from '../components/components.module';
import { DirectivesModule } from '../directives/directives.module';
import { FormControlsModule } from '../form-controls/form-controls.module';
import { IconCloseModule } from '../icons/icon-close/icon-close.module';
import { IconsModule } from '../icons/icons.module';
import { PhotosModule } from '../photos/photos.module';
import { PipesModule } from '../pipes/pipes.module';
import { CrossEntitySearchApiService } from './cross-entity-search.api.service';
import { CrossEntitySearchComponent } from './cross-entity-search/cross-entity-search.component';
import { DisplayEntityNameDirective } from './directives/display-entity-name.directive';
import { SearchOptionComponent } from './search-option/search-option.component';

@NgModule({
  imports: [
    CommonModule,
    IconsModule,
    DirectivesModule,
    FormControlsModule,
    ComponentsModule,
    PhotosModule,
    PipesModule,
    AnimationsModule,
    IconCloseModule,
    InfiniteScrollDirective,
  ],
  exports: [CrossEntitySearchComponent, DisplayEntityNameDirective],
  declarations: [CrossEntitySearchComponent, SearchOptionComponent, DisplayEntityNameDirective],
  providers: [CrossEntitySearchApiService],
})
export class CrossEntitySearchModule {}
