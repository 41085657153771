import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { SelectOption } from '../index';

@Component({
  selector: 'wp-inline-select',
  templateUrl: './inline-select.component.html',
  styleUrls: ['./inline-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineSelectComponent implements OnInit, OnChanges {
  @Input() public label: string;
  @Input() public value: string;
  @Input() public options: SelectOption[];
  @Input() public dropdownWidthPx = 200;
  @Input() public viewStyle: 'dot-separator' | 'bold-selection' = 'dot-separator';
  @Input() public alignDropdown: 'left' | 'right' | 'center' | 'full-width' = 'full-width';

  @Output() public selectedChanged = new EventEmitter<string>();

  public selectedOption?: SelectOption;

  public ngOnInit(): void {
    this.setSelectedOption();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && !changes.value.isFirstChange()) {
      this.setSelectedOption();
    }
  }

  public onSelectedChanged(option: SelectOption): void {
    if (option.value !== this.selectedOption?.value) {
      this.selectedOption = option;
      this.selectedChanged.emit(option.value);
    }
  }

  private setSelectedOption(): void {
    if (this.selectedOption?.value !== this.value) {
      this.selectedOption = this.options.find((opt) => opt.value === this.value);
    }
  }
}
