import { createAction, props } from '@ngrx/store';
import * as fromTypes from '../../types';

const moduleKey = 'School Employees';
const submoduleKey = 'List';

export const initializedList = createAction(
  `[${moduleKey}] [${submoduleKey}]: Initialized list`,
  props<{ listName: fromTypes.EmployeeListName; snapshot?: fromTypes.EmployeeListSnapshot }>(),
);

export const destroyedList = createAction(
  `[${moduleKey}] [${submoduleKey}]: Destroyed list`,
  props<{ listName: fromTypes.EmployeeListName }>(),
);

export const updatedList = createAction(
  `[${moduleKey}] [${submoduleKey}]: Updated list`,
  props<{ listName: fromTypes.EmployeeListName }>(),
);

export const searchChanged = createAction(
  `[${moduleKey}] [${submoduleKey}]: Search changed`,
  props<{ search: string; listName: fromTypes.EmployeeListName }>(),
);

export const listUpdated = createAction(
  `[${moduleKey}] [${submoduleKey}]: List updated`,
  props<{ listName: fromTypes.EmployeeListName }>(),
);

export const pageChanged = createAction(
  `[${moduleKey}] [${submoduleKey}]: Page changed`,
  props<{ page: number; listName: fromTypes.EmployeeListName }>(),
);

export const pageSizeChanged = createAction(
  `[${moduleKey}] [${submoduleKey}]: Page size changed`,
  props<{ pageSize: number; listName: fromTypes.EmployeeListName }>(),
);

export const filtersChanged = createAction(
  `[${moduleKey}] [${submoduleKey}]: Page size changed`,
  props<{ filters: fromTypes.EmployeeListFilter[]; listName: fromTypes.EmployeeListName }>(),
);

export const statusChanged = createAction(
  `[${moduleKey}] [${submoduleKey}]: Status changed`,
  props<{ status: fromTypes.EntityStatus; listName: fromTypes.EmployeeListName }>(),
);

export const staticFiltersChanged = createAction(
  `[${moduleKey}] [${submoduleKey}]: Static filters changed`,
  props<{ filters: fromTypes.EmployeeListFilter[]; listName: fromTypes.EmployeeListName }>(),
);

export const employeeLoadListRequested = createAction(
  `[${moduleKey}] [${submoduleKey}] [Data]: Load list requested`,
  props<{ snapshot: fromTypes.EmployeeListSnapshot; listName: fromTypes.EmployeeListName }>(),
);

export const employeeLoadListSuccess = createAction(
  `[${moduleKey}] [${submoduleKey}] [Data]: Load list success`,
  props<{ listName: fromTypes.EmployeeListName; total: number; items: fromTypes.PortalEntity[] }>(),
);

export const employeeLoadListFailed = createAction(
  `[${moduleKey}] [${submoduleKey}] [Data]: Load list failed`,
  props<{ listName: fromTypes.EmployeeListName; error: fromTypes.WpError }>(),
);

/**
 * employee list item
 */
export const employeeListItemInitialized = createAction(
  `[${moduleKey}] [${submoduleKey}]: List item initialized`,
  props<{ employeeId: string }>(),
);

export const employeeListItemDestroyed = createAction(
  `[${moduleKey}] [${submoduleKey}]: List item destroyed`,
  props<{ employeeId: string }>(),
);

export const employeeItemSelected = createAction(
  `[${moduleKey}] [${submoduleKey}]: employee item selected`,
  props<{ employeeId: string; listName: fromTypes.EmployeeListName }>(),
);
