import { NavigationRequest } from '../../types/navigation-request';
import { PortalRoute } from '../../types/portal-route';

export interface ResetPasswordSetupParams {
  token: string;
}

export const resetPasswordSetupRoute: PortalRoute<ResetPasswordSetupParams> = {
  path: 'password-reset',
  request(data: ResetPasswordSetupParams): NavigationRequest {
    return {
      path: [`/${this.path}`],
      query: {
        token: data.token,
      },
    };
  },
};
