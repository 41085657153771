import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wp-spaced-block',
  templateUrl: './spaced-block.component.html',
  styleUrls: ['./spaced-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpacedBlockComponent implements OnInit {
  /**
   * Pixels
   */
  @Input() public marginBottom = 20;

  @Input() public underline: boolean;

  constructor() {}

  ngOnInit(): void {}
}
