import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { go, goErrorPage } from '@rootStore';
import { WpError } from '@rootTypes';
import { commonRoutes } from '@router';
import { of, from, Observable } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, tap, debounceTime } from 'rxjs/operators';

import { ApiService } from 'src/app/api/api.service';
import { CurrentUserProfile } from 'src/app/api/endpoints/get-profile';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';
import { ImageApiService } from '../../../api';
import {
  switchAccountUserProfileFailed,
  switchAccountUserProfileRequested,
  switchAccountUserProfileSuccess,
  updateUserPhotoFailed,
  updateUserPhotoRequested,
  updateUserPhotoSuccess,
  userPhotoFailed,
  userPhotoRequested,
  userPhotoSuccess,
  userProfileFailed,
  userProfileRequested,
  userProfileSuccess,
} from '../actions';
import { currentUserPhotoPath } from '../selectors/auth.selectors';

@Injectable()
export class UserProfileEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private imageApiService: ImageApiService,
    private api: ApiService,
    private snackbar: SnackbarService,
  ) {}

  public userProfileRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProfileRequested, switchAccountUserProfileRequested),
      switchMap((action) => {
        return this.api.getProfile().pipe(
          map((profile) => this.createUserProfileSuccessAction(action.type, profile)),
          catchError((error) => {
            // Handle the case with a mock accountId (/login?accountsPreset=123)
            // on sandbox environment
            if (
              (wpEnvironment.envName === 'development' || wpEnvironment.envName === 'sandbox') &&
              typeof error.data === 'object' &&
              error.data.errorCode === 'PROFILE_NOT_FOUND'
            ) {
              const mockProfile: CurrentUserProfile = {
                name: {
                  first: 'Mock',
                  last: 'User',
                },
                email: 'mock-user-email@ridezum.com',
                phoneNumber: '4245557799',
                avatarPath: '',
              };
              return of(this.createUserProfileSuccessAction(action.type, mockProfile));
            }

            return of(this.createUserProfileFailedAction(action.type, error));
          }),
        );
      }),
    ),
  );

  public requestPhotoOnUserProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProfileSuccess, switchAccountUserProfileSuccess),
      map(() => userPhotoRequested()),
    ),
  );

  public switchAccountUserProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(switchAccountUserProfileSuccess),
      debounceTime(500), // for smoother spinner animation
      map(() => {
        return go({
          path: [commonRoutes.home.url],
        });
      }),
    ),
  );

  public userProfileFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userProfileFailed, switchAccountUserProfileFailed),
      map((action) => goErrorPage({ error: action.error })),
    ),
  );

  public userPhotoRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userPhotoRequested),
      withLatestFrom(this.store.select(currentUserPhotoPath)),
      switchMap(([action, photoPath]) => {
        if (!photoPath) {
          return of(userPhotoSuccess({ imageBase64: '' }));
        }
        // The max image size 225x225 is used on User Profile page
        return from(this.imageApiService.getImageBase64(photoPath, { width: 225, height: 225 })).pipe(
          map((imageBase64) => userPhotoSuccess({ imageBase64 })),
          catchError((error) => {
            return of(
              userPhotoFailed({
                error: {
                  text: 'Failed to load user photo',
                  originalError: error,
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  public updateUserPhotoRequsted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUserPhotoRequested),
      switchMap((action) => {
        if (action.imageBase64) {
          return this.imageApiService.uploadImage(action.imageBase64).pipe(
            switchMap((photoPath) => this.updateUserProfile(photoPath, action.imageBase64)),
            catchError((error) => {
              return of(
                updateUserPhotoFailed({
                  error: {
                    text: 'Failed to upload user photo',
                    originalError: error,
                  },
                }),
              );
            }),
          );
        }
        return this.updateUserProfile();
      }),
    ),
  );

  public updateUserPhotoSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateUserPhotoSuccess),
        tap(() => {
          this.snackbar.success('Photo successfully updated');
        }),
      ),
    { dispatch: false },
  );

  public updateUserPhotoFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateUserPhotoFailed),
        tap((action) => {
          this.snackbar.error(action.error.text);
        }),
      ),
    { dispatch: false },
  );

  private updateUserProfile(avatarPath = '', imageBase64 = ''): Observable<Action> {
    return this.api.updateProfile({ avatarPath }).pipe(
      map(() => {
        return updateUserPhotoSuccess({
          imageBase64,
          photoPath: avatarPath,
        });
      }),
      catchError((error) => {
        return of(
          updateUserPhotoFailed({
            error: {
              text: 'Failed to update user profile',
              originalError: error,
            },
          }),
        );
      }),
    );
  }

  private createUserProfileSuccessAction(
    actionType: typeof userProfileRequested.type | typeof switchAccountUserProfileRequested.type,
    profile: CurrentUserProfile,
  ): Action {
    if (actionType === userProfileRequested.type) {
      return userProfileSuccess({ profile });
    }
    return switchAccountUserProfileSuccess({ profile });
  }

  private createUserProfileFailedAction(
    actionType: typeof userProfileRequested.type | typeof switchAccountUserProfileRequested.type,
    originalError: any,
  ): Action {
    const error: WpError = {
      text: 'Failed to load current user profile',
      originalError,
    };
    if (actionType === userProfileRequested.type) {
      return userProfileFailed({ error });
    }
    return switchAccountUserProfileFailed({ error });
  }
}
