import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'wp-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public type: 'text' | 'email' | 'password' | 'search' | 'number' = 'text';
  @Input() public label: string;
  @Input() public control: UntypedFormControl;
  @Input() public tabIndex = '0';
  @Input() public autofocus = false;
  @Input() public hint?: string;
  @Input() public name?: string;
  @Input() public id?: string;
  @Input() public readOnly = false;
  @Input() public errorStateMatcher: ErrorStateMatcher;

  // If provided Browser does not allow to exceed the input limit
  @Input() public maxLength?: number = null;

  // Should be passed when control state (valid, dirty, touched) is updated outside
  @Input() public controlStateChange?: Observable<void>;

  // If provided the input will display an error message from
  // FormControl.errors[customErrorMessageKey]
  @Input() public customErrorMessageKey: string;

  @Output() public blurEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() public inputEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() public keyUpEvent: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
  @Output() public focusOutEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public focusInEvent: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('inputEl') public inputEl: ElementRef;

  private sub = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {}

  public get minLengthError(): string {
    return `Minimum ${this.control.errors.minlength.requiredLength} characters`;
  }

  public ngAfterViewInit(): void {
    if (this.autofocus) {
      setTimeout(() => this.focusInput());
    }
    // We have to set name attribute manually since [name]="name" binding
    // will be evaluated to name="undefined" if the value is not provided.
    if (this.name) {
      const input = this.inputEl.nativeElement as HTMLInputElement;
      input.setAttribute('name', this.name);
    }
  }

  public ngOnInit(): void {
    if (this.controlStateChange) {
      const controlStateSub = this.controlStateChange.subscribe(() => {
        this.cdRef.detectChanges();
      });
      this.sub.add(controlStateSub);
    }
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public onBlurEvent(): void | undefined {
    if (this.control.errors) {
      return;
    }
    this.blurEvent.emit(this.control.value);
  }

  public onInputEvent(): void {
    this.inputEvent.emit(this.inputEl.nativeElement.value);
  }

  public focusInput(): void {
    if (this.inputEl) {
      const input = this.inputEl.nativeElement as HTMLInputElement;
      input.focus();
    }
  }
}
