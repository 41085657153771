import { Pipe, PipeTransform } from '@angular/core';
import { dateFormat, formattingConstants } from '@rootTypes/utils/common/date-time-fns';

@Pipe({
  name: 'utcStampToLocalDisplayTime',
})
export class UtcStampToLocalDisplayTimePipe implements PipeTransform {
  transform(regularTimestamp?: number, format?: string, timezone?: string): string {
    if (regularTimestamp) {
      return dateFormat(new Date(regularTimestamp), format || formattingConstants.timeWithTimezone, timezone);
    }
    return '--';
  }
}
