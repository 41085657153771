import { EntityFilterRequest, EntityFilterRequestType } from '../../../../api/endpoints/entity-filter';

export const getPermissionOptionsRequest = (vendorId: string): EntityFilterRequest => {
  return {
    type: EntityFilterRequestType.YARD,
    vendorIds: [vendorId],
    skip: 0,
    limit: 1000,
  };
};
