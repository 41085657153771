import { createSelector } from '@ngrx/store';

import { EntityState, YardDataState, Yard } from '../../types';
import { getYardFeature } from './get-feature';

const getYardDataState = createSelector(getYardFeature, (state): YardDataState => state.data);

export const getAllYardEntities = createSelector(getYardDataState, (state) => state.yards);

export const getYardEntity = createSelector(
  getAllYardEntities,
  (state, props: { yardId: string }): EntityState<Yard> | undefined => {
    return state[props.yardId];
  },
);

export const getYardState = (yardId: string) => {
  return createSelector(getAllYardEntities, (state) => {
    return state[yardId] || { isLoading: false };
  });
};

export const getYardLoading = createSelector(getYardEntity, (state) => (state ? state.isLoading : false));

export const getYardError = createSelector(getYardEntity, (state) => state?.error);

export const getYardErrorMessage = createSelector(getYardError, (state) => state?.text);

export const getYard = createSelector(getYardEntity, (state) => state?.entity);

export const getYardName = createSelector(getYard, (state) => state?.name);

/**
 * Vendor id by yard
 */
export const getAllVendorIdsByYards = createSelector(getYardDataState, (state) => state.vendorIdsByYards);

export const getVendorIdByYard = createSelector(getAllVendorIdsByYards, (state, props: { yardId: string }) => {
  return state[props.yardId]?.vendorId;
});
