import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wp-add-edit-action-buttons-mini',
  templateUrl: './add-edit-action-buttons-mini.component.html',
  styleUrls: ['./add-edit-action-buttons-mini.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditActionButtonsMiniComponent implements OnInit {
  @Input() public isSaveBtnDisabled: boolean;
  @Input() public isSaveBtnLoading: boolean;
  @Input() public saveBtnText = 'Save';
  @Output() public saveBtnClicked = new EventEmitter<void>();
  @Output() public cancelBtnClicked = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onCancelBtnClick() {
    this.cancelBtnClicked.emit();
  }

  onSaveBtnClick() {
    this.saveBtnClicked.emit();
  }
}
