import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from '@rootStore';
import { of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, take } from 'rxjs/operators';
import { RoutesApiService } from '../../services/routes-api.service';
import * as fromTypes from '../../types';
import * as fromActions from '../actions/route-lists.actions';
import * as fromSelectors from '../selectors/route-lists.selectors';

@Injectable()
export class RouteListsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private api: RoutesApiService,
  ) {
    fromTypes.routeLists.forEach((listName) => this.createLoadRouteListEffect(listName));
  }

  public routeListChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.initializedRouteList,
        fromActions.setRouteListFromSnapshot,
        fromActions.routeEntityFilterAddSelected,
        fromActions.routeSearchHitFilterAddSelected,
        fromActions.routeEntityFilterDeleteSelected,
        fromActions.routeFiltersRemoveAllSelected,
        fromActions.setStaticFilters,
        fromActions.routeStatusTypeChanged,
        fromActions.currentPageChanged,
        fromActions.pageSizeChanged,
      ),
      switchMap((action) => {
        const { listName } = action;
        return this.store.select(fromSelectors.getRouteListSnapshot, { listName }).pipe(
          take(1),
          map((snapshot) => fromActions.loadRouteListRequested({ listName, snapshot })),
        );
      }),
    ),
  );

  /**
   * Select first route on load route list success
   */
  public loadedRoutes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRouteListSuccess),
      switchMap((action) => {
        const list = action.response.routeIds;
        return this.store
          .select(fromSelectors.getSelectedRouteId, {
            listName: action.listName,
          })
          .pipe(
            take(1),
            map((currentSelectedId) => {
              // select either existing selected route, if it's in the list,
              // otherwise first item in the list
              let newSelectedId: string | null;
              if (list.indexOf(currentSelectedId) >= 0) {
                newSelectedId = currentSelectedId;
              } else {
                newSelectedId = list[0] || null;
              }
              return fromActions.selectedRouteIdChanged({
                listName: action.listName,
                routeId: newSelectedId,
              });
            }),
          );
      }),
    ),
  );

  private createLoadRouteListEffect(listName: fromTypes.RouteListNames): void {
    this[`load_${listName}`] = createEffect(() =>
      this.actions$.pipe(
        ofType(fromActions.loadRouteListRequested),
        filter((a) => a.listName === listName),
        debounceTime(600),
        switchMap((action) => {
          const { snapshot } = action;
          return this.api.loadRoutesPage(snapshot).pipe(
            map((resp) => {
              return fromActions.loadRouteListSuccess({
                listName,
                response: {
                  routeIds: resp.routeIds,
                  totalFound: resp.totalRoutes,
                },
              });
            }),
            catchError((err) => {
              console.log(err);
              return of(
                fromActions.loadRouteListFailed({
                  listName,
                  error: {
                    text: 'Failed to load run list page',
                    originalError: err,
                  },
                }),
              );
            }),
          );
        }),
      ),
    );
  }
}
