import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { DriverDataFacade, VendorDataFacade, Vendor, WpError } from '../types';

@Injectable()
export class DriverVendorFacade {
  constructor(
    private driverFacade: DriverDataFacade,
    private vendorFacade: VendorDataFacade,
  ) {}

  public getVendor(driverId: string): Observable<Vendor> {
    return this.driverFacade.getDriverVendorId(driverId).pipe(
      switchMap((vendorId) => {
        if (vendorId) {
          return this.vendorFacade.getVendor(vendorId);
        }
        return of(undefined);
      }),
    );
  }

  public getVendorId(driverId: string): Observable<string> {
    return this.driverFacade.getDriverVendorId(driverId);
  }

  public getVendorLoading(driverId: string): Observable<boolean> {
    return this.driverFacade.getDriverVendorId(driverId).pipe(
      switchMap((vendorId) => {
        if (vendorId) {
          return this.vendorFacade.getVendorLoading(vendorId);
        }
        return of(undefined);
      }),
    );
  }

  public getVendorError(driverId: string): Observable<WpError> {
    return this.driverFacade.getDriverVendorId(driverId).pipe(
      switchMap((vendorId) => {
        if (vendorId) {
          return this.vendorFacade.getVendorError(vendorId);
        }
        return of(undefined);
      }),
    );
  }

  public getVendorErrorMessage(driverId: string): Observable<string> {
    return this.driverFacade.getDriverVendorId(driverId).pipe(
      switchMap((vendorId) => {
        if (vendorId) {
          return this.vendorFacade.getVendorErrorMessage(vendorId);
        }
        return of(undefined);
      }),
    );
  }

  public getVendorName(driverId: string): Observable<string> {
    return this.driverFacade.getDriverVendorId(driverId).pipe(
      switchMap((vendorId) => {
        if (vendorId) {
          return this.vendorFacade.getVendorName(vendorId);
        }
        return of(undefined);
      }),
    );
  }
}
