import { SmartFormField, SmartFormFieldDisplay } from '../../entities/smart-forms';
import { formatPhone } from '../common';
import { YearMonthDay } from '../common/date';
import { isSmartFormFieldPhoneNumber } from './smart-form-field-phone-number';
import { isSmartFormFieldString } from './smart-form-field-string';
import { isSmartFormFieldAddress } from './smart-form-field-address';
import { isSmartFormFieldCheckboxes } from './smart-form-field-checkboxes';
import { isSmartFormFieldDate } from './smart-form-field-date';
import { isSmartFormFieldEmail } from './smart-form-field-email';
import { isSmartFormFieldDateRange } from './smart-form-field-date-range';
import { isSmartFormFieldTransportationProvider } from './smart-form-field-transportation-provider';
import { isSmartFormFieldSelect } from './smart-form-field-select';
import { isSmartFormFieldStudentSchoolInfo } from './smart-form-field-student-school-info';
import { isSmartFormFieldStudentTransportationStatus } from '@rootTypes/utils/smart-forms/smart-form-field-student-transportation-status';

export const smartFormFieldToDisplay = (field?: SmartFormField): SmartFormFieldDisplay => {
  const result: SmartFormFieldDisplay = {
    type: field?.type,
    value: '--',
  };
  if (isSmartFormFieldString(field) || isSmartFormFieldEmail(field)) {
    result.value = field.value || '--';
    return result;
  }
  if (isSmartFormFieldAddress(field)) {
    result.value = field.value?.formatted_address || '--';
    return result;
  }
  if (isSmartFormFieldPhoneNumber(field)) {
    result.value = formatPhone(field.value) || '--';
    return result;
  }
  if (isSmartFormFieldDate(field)) {
    result.value = field.value ? new YearMonthDay(field.value).formatTo() : '--';
    return result;
  }
  if (isSmartFormFieldDateRange(field)) {
    if (field.from?.value && field.to?.value) {
      result.value = `${new YearMonthDay(field.from?.value).formatTo()} to ${new YearMonthDay(
        field.to?.value,
      ).formatTo()}`;
    }
    return result;
  }
  if (isSmartFormFieldCheckboxes(field)) {
    let labels = '';
    field.values.forEach((checkbox) => {
      if (checkbox.isChecked) {
        if (labels.length) {
          labels += ', ';
        }
        labels += checkbox.checkedLabel;
      } else if (checkbox.isChecked === false && checkbox.uncheckedLabel) {
        if (labels.length) {
          labels += ', ';
        }
        labels += checkbox.uncheckedLabel;
      }
    });
    result.value = labels || '--';
    return result;
  }
  if (isSmartFormFieldTransportationProvider(field)) {
    if (field.value && field.value.vendor && field.value.yard) {
      result.value = `${field.value.vendor.name}\n${field.value.yard.name}`;
    }
    return result;
  }
  if (isSmartFormFieldSelect(field)) {
    if (field.selected) {
      result.value = field.selected.label || field.selected.value;
    }
    return result;
  }
  if (isSmartFormFieldStudentSchoolInfo(field)) {
    if (field.value) {
      result.value = field.value.district.name;
      if (field.value.campus) {
        result.value += `\n${field.value.campus.name}`;
      }
      if (field.value.schoolYear) {
        result.value += `\n${field.value.schoolYear.value}`;
      }
      if (field.value.grade) {
        result.value += `\n${field.gradeLabel || 'Grade'} ${field.value.grade.label}`;
      }
    }
    return result;
  }
  if (isSmartFormFieldStudentTransportationStatus(field)) {
    let text = field.status + '';
    if (field.cancelTransportationDetails) {
      const details = field.cancelTransportationDetails;
      text += ` - effective from\n${new YearMonthDay(details.effectiveDate).formatTo()}\n${details.reason}.`;
      if (details.comment) {
        text += ` ${details.comment}`;
      }
    }
    result.value = text;
    return result;
  }
  return result;
};
