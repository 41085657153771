export interface GetSchoolEmployeeRequest {
  schoolEmployeeId: string;
  _projections?: ApiSchoolEmployeeProjection[];
}

export enum ApiSchoolEmployeeProjection {
  district = 'district',
  permissions = 'permissions',
  campuses = 'campuses',
  roles = 'roles',
}
