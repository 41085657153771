import { VehicleInspectionQuickFilter } from '../../../../data/vehicle-inspections/types';
import { QuickFilterQueryParam } from '../types';

export const quickFilterToQueryParam = (filter?: VehicleInspectionQuickFilter): QuickFilterQueryParam => {
  const map = {
    [VehicleInspectionQuickFilter.MISSING_INSPECTIONS]: QuickFilterQueryParam.MISSING_INSPECTIONS,
    [VehicleInspectionQuickFilter.LATE_INSPECTIONS]: QuickFilterQueryParam.LATE_INSPECTIONS,
    [VehicleInspectionQuickFilter.NONE]: QuickFilterQueryParam.NONE,
  };
  return map[filter] || QuickFilterQueryParam.NONE;
};
