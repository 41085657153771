import { PortalEntity, PortalEntityType, WpError } from '@rootTypes';
import { createReducer, on } from '@ngrx/store';
import {
  getPortalEntityActions,
  getPortalEntityFailed,
  getPortalEntitySuccess,
  setPortalEntityInfo,
} from '../actions/portal-entities-info.actions';

export type PortalEntityInfoState = {
  [EntityType in PortalEntityType]?: {
    [entityId: string]: {
      entity: PortalEntity;
      isLoading?: boolean;
      error?: WpError;
    };
  };
};

export const getInitialPortalEntityInfoState = (): PortalEntityInfoState => {
  return {};
};

export const portalEntityInfoReducer = createReducer<PortalEntityInfoState>(
  getInitialPortalEntityInfoState(),
  on(...getPortalEntityActions, (state, action): PortalEntityInfoState => {
    const { entityId, entityType } = action;
    const byEntityTypeState = state[entityType] || { entity: null };
    const byEntityState = byEntityTypeState[entityId] || { entity: null };
    if (byEntityState.entity) {
      return { ...state };
    }
    return {
      ...state,
      [entityType]: {
        ...byEntityTypeState,
        [entityId]: {
          ...byEntityState,
          isLoading: true,
        },
      },
    };
  }),
  on(getPortalEntitySuccess, (state, action): PortalEntityInfoState => {
    if (!action.entity) {
      return {
        ...state,
      };
    }
    const { entityId, type } = action.entity;
    const byEntityTypeState = state[type] || {};
    const byEntityState = byEntityTypeState[entityId] || {};
    return {
      ...state,
      [type]: {
        ...byEntityTypeState,
        [entityId]: {
          ...byEntityState,
          isLoading: false,
          entity: action.entity,
        },
      },
    };
  }),
  on(getPortalEntityFailed, (state, action): PortalEntityInfoState => {
    const { entityId, entityType, error } = action;
    const byEntityTypeState = state[entityType] || {};
    const byEntityState = byEntityTypeState[entityId] || {};
    return {
      ...state,
      [entityType]: {
        ...byEntityTypeState,
        [entityId]: {
          ...byEntityState,
          isLoading: false,
          error,
        },
      },
    };
  }),
  on(setPortalEntityInfo, (state, action): PortalEntityInfoState => {
    const loadedState = action.entities.reduce((acc, entity) => {
      return {
        ...acc,
        [entity.type]: {
          ...acc[entity.type],
          ...(state[entity.type] || {}),
          [entity.entityId]: {
            ...((state[entity.type] || {})[entity.entityId] || {}),
            entity,
          },
        },
      };
    }, {});
    return {
      ...state,
      ...loadedState,
    };
  }),
);
