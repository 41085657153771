import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayCharterTripId',
})
export class DisplayCharterTripIdPipe implements PipeTransform {
  transform(value?: string): string {
    if (!value) {
      return '--';
    }
    return value.startsWith('T') ? value : `T${value}`;
  }
}
