import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { featureKey } from './store/feature-key';
import { vehicleInspectionsReducer } from './store/reducers/vehicle-inspections.reducer';
import { featureEffects } from './store/effects';

@NgModule({
  imports: [StoreModule.forFeature(featureKey, vehicleInspectionsReducer), EffectsModule.forFeature(featureEffects)],
})
export class VehicleInspectionsStoreModule {}
