import { TripAssignmentListVendorResponseFilter, TripAssignmentListVendorResponseFilterValue } from '../../entities';

export const createTripAssignmentVendorResponseFilter = (
  value?: TripAssignmentListVendorResponseFilterValue,
): TripAssignmentListVendorResponseFilter => {
  if (!value) {
    return undefined;
  }
  return {
    id: `vendor-response,${value}`,
    type: 'vendor-response',
    payload: value,
  };
};
