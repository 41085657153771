import { VendorPortalEntity } from '@rootTypes';
import { SmartInputVendorValue } from '../types';

export const vendorPortalEntityToSmartInputVendorValue = (
  source?: VendorPortalEntity,
): SmartInputVendorValue | undefined => {
  if (source) {
    return {
      id: source.entityId,
      name: source.label,
    };
  }
  return undefined;
};
