import { PortalRoute, QueryParamsHandlingStrategy } from '../../types/portal-route';
import { RouterStateUrl } from '../../types/router-state-url';
import { NavigationRequest } from '../../types/navigation-request';
import { Injectable } from '@angular/core';
import { PortalRouterService } from '../../types/portal-router-service';
import { RouterOutlets } from '../../../types/entities/router';

export interface YardListRouteData {
  page?: string;
  pageSize?: string;
  search?: string;
  status?: string;
  filters?: string;
}

export const yardListRoutePath: string = 'list';

export const yardListRoute: PortalRoute<YardListRouteData> = {
  path: yardListRoutePath,
  isOnRoute: (state: RouterStateUrl): boolean => {
    return state.url.startsWith('/yards/list');
  },
  parse: (state: RouterStateUrl): YardListRouteData => {
    let data: YardListRouteData = {};
    if (state.queryParams) {
      data = {
        ...state.queryParams,
      };
    }
    return data;
  },
  request: (data: YardListRouteData = {}, queryParamStrategy?: QueryParamsHandlingStrategy): NavigationRequest => {
    const req: NavigationRequest = {
      path: ['/yards', 'list'],
    };
    const query = { ...data };
    if (Object.keys(query).length) {
      req.query = query;
    }
    if (queryParamStrategy) {
      req.extras = {
        queryParamsHandling: queryParamStrategy,
      };
    }
    return req;
  },
  getHref(data?: YardListRouteData): string {
    return '/yards/list';
  },
};

@Injectable({ providedIn: 'root' })
export class YardListRouterService extends PortalRouterService<YardListRouteData> {
  outlet = RouterOutlets.PRIMARY;
  route: PortalRoute<YardListRouteData> = yardListRoute;
}
