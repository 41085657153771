import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { iconPaths } from '@rootTypes/utils/common';
import { SelectOption } from '../index';
import { DropdownComponent } from '../dropdown/dropdown/dropdown.component';

@Component({
  selector: 'wp-icon-filter-select',
  templateUrl: './icon-filter-select.component.html',
  styleUrls: ['./icon-filter-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconFilterSelectComponent implements OnInit {
  @Input() public triggerWidthStr: string;
  @Input() public triggerHeightStr: string;
  @Input() public triggerIconTemplate: any;
  @Input() public triggerIconSelectedTemplate: any;
  @Input() public selectedId: string;
  @Input() public options: SelectOption[] = [];
  @Input() public defaultId: string;
  @Input() public disabled: boolean;
  @Input() public customDropdownTemplate: any;
  @Input() public alignDropdown: 'left' | 'right' | 'center' | 'full-width';
  @Input() public alignDropdownVertical: 'top' | 'bottom' = 'bottom';
  @Input() public isDropdownPositionRelative = true;
  @Input() public showRegularTemplateOnSelect: boolean;
  @Output() public selectedChanged = new EventEmitter<string>();

  public isDropdownOpen: boolean;
  public isSelected: boolean;
  public iconPaths = iconPaths;
  @ViewChild('dropdownComponent') private dropdownComponent: DropdownComponent;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedId || changes.defaultId) {
      this.isSelected = !!this.selectedId && this.selectedId !== this.defaultId;
    }
  }

  public forceCloseDropdown(): void {
    if (this.dropdownComponent) {
      this.dropdownComponent.onCloseDropdown();
    }
  }

  public onTriggerClick(): void {
    this.isDropdownOpen = true;
  }

  public onDropdownClose(): void {
    this.isDropdownOpen = false;
  }

  public onOptionClick(option: SelectOption): void {
    if (option.disabled) {
      return;
    }
    this.selectedChanged.emit(option.value);
  }

  public onOptionSelectWithKeyboard(option: SelectOption): void {
    if (option.disabled) {
      return;
    }
    this.onOptionClick(option);
    this.forceCloseDropdown();
  }
}
