import { Injectable } from '@angular/core';
import { State } from '../../../../store';
import { Store } from '@ngrx/store';
import * as fromSelectors from '../selectors/calendar-data.selectors';
import * as fromTypes from '../../types';
import { Observable } from 'rxjs';

@Injectable()
export class CampusCalendarDataFacade {
  constructor(private store: Store<State>) {}

  public getCalendarIds(campusId: string): Observable<string[]> {
    return this.store.select(fromSelectors.getCalendarIdsByCampus, { campusId });
  }

  public getCalendarsLoading(campusId: string): Observable<boolean> {
    return this.store.select(fromSelectors.getCalendarsByCampusLoading, { campusId });
  }

  public getCalendarsCount(campusId: string): Observable<number> {
    return this.store.select(fromSelectors.getCalendarsCountByCampus, { campusId });
  }

  public getCalendarsError(campusId: string): Observable<fromTypes.WpError> {
    return this.store.select(fromSelectors.getCalendarsByCampusError, { campusId });
  }

  public getCalendar(calendarId: string): Observable<fromTypes.CampusCalendar> {
    return this.store.select(fromSelectors.getCalendar, { calendarId });
  }
}
