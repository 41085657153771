<div class="content">
  <div
    *ngFor="let item of model.items$ | async; trackBy: trackByFn"
    class="photo-container"
  >
    <div
      class="close-icon-click-zone"
      *ngIf="(model.disabled$ | async) === false"
      (click)="removePhoto(item.itemId)"
    >
      <div class="close-icon-circle">
        <wp-icon-close class="cross-icon" [size]="'xsmall'"></wp-icon-close>
      </div>
    </div>
    <wp-display-photo
      [width]="'184px'"
      [height]="'184px'"
      [placeholderType]="model.photoPlaceholder"
      [isRound]="false"
      [imagePath]="item.meta?.photoPath"
      [imageBase64]="item.photoBase64"
    >
    </wp-display-photo>
  </div>
</div>
