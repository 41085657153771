import { combineReducers } from '@ngrx/store';
import { vehicleDataReducer } from './vehicle-data.reducer';
import { VehicleDataState } from '../../types';
import * as fromLists from './vehicle-lists.reducer';
import * as fromDetails from './vehicle-details.reducer';

export interface VehicleStoreState {
  data: VehicleDataState;
  lists: fromLists.AllVehicleListState;
  details: fromDetails.VehicleDetailsState;
}

export const vehicleFeatureReducer = combineReducers<VehicleStoreState>({
  data: vehicleDataReducer,
  lists: fromLists.vehicleListsReducer,
  details: fromDetails.vehicleDetailsReducer,
});
