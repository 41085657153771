import { createAction, props } from '@ngrx/store';
import { WpError } from '@rootTypes';

const source = '[ChangePassword]';

export const initChangePasswordState = createAction('[UserProfile]: Init change password state');
export const changePasswordRequested = createAction(
  `${source}: Change password requested`,
  props<{ oldPassword: string; newPassword: string }>(),
);
export const changePasswordSuccess = createAction(`${source}: Change password success`);
export const changePasswordFailed = createAction(`${source}: Change password failed`, props<{ error: WpError }>());
