import { StudentChangeRequestReviewForm } from '../../store/reducers/student-change-request-review.reducer';
import { ApplyStudentChangeRequestRequest } from 'src/app/api/endpoints/apply-student-change-request';

export const createApplyStudentChangeRequestData = (
  changeRequestId: string,
  form: StudentChangeRequestReviewForm,
): ApplyStudentChangeRequestRequest => {
  const result: ApplyStudentChangeRequestRequest = {
    changeRequestId,
    from: [],
    to: [],
    operation: form.operation,
  };
  if (form.chooseAction.selectedEntityId) {
    result.entityId = form.chooseAction.selectedEntityId;
  }
  form.rows
    .filter((row) => row.isEditable)
    .forEach((row) => {
      result.from.push({
        name: row.fieldName,
        value: row.existingData,
      });
      if (row.isKeepExistingDataSelected) {
        result.to.push({
          name: row.fieldName,
          value: row.existingData,
        });
      } else {
        result.to.push({
          name: row.fieldName,
          value: row.revisedDataCurrent,
        });
      }
    });
  return result;
};
