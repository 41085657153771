import { Pipe, PipeTransform } from '@angular/core';
import { ActivityStatus, PortalColorType } from '@rootTypes';
import { activityStatusColors } from '@rootTypes/utils/timesheet-entries/timesheet-entry-configs';

@Pipe({
  name: 'activityStatusColor',
})
export class ActivityStatusColorPipe implements PipeTransform {
  transform(value: ActivityStatus): PortalColorType {
    if (!value) {
      return undefined;
    }
    return activityStatusColors[value] as PortalColorType;
  }
}
