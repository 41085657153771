import { TripListTypeFilter } from '../types/trip-list';
import { FilterDeserializer } from '@rootTypes/utils/list-store-utils/deserialize-base-params-from-url';
import { TripType } from '@rootTypes';

export const getTripListTypeFilter = (type: TripType): TripListTypeFilter => {
  return {
    id: `trip-type,${type}`,
    type: 'trip-type',
    payload: type,
  };
};

export const deserializeTripListTypeFilter: FilterDeserializer<TripListTypeFilter> = (
  s: string,
): TripListTypeFilter | undefined => {
  if (s && s.startsWith('trip-type')) {
    const value: TripType = s.split(',')[1] as TripType;
    return getTripListTypeFilter(value);
  }
  return undefined;
};
