import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { SmartInputVendor } from '../../models';

@Component({
  selector: 'wp-smart-input-vendor',
  templateUrl: './smart-input-vendor.component.html',
  styleUrls: ['./smart-input-vendor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartInputVendorComponent {
  @Input() isSearchGlassIcon = true;
  @Input() model: SmartInputVendor;
}
