import { createReducer, on } from '@ngrx/store';

import * as fromActions from '../actions/vehicle-inspections.actions';
import {
  VehicleInspectionListName,
  VehicleInspectionListParams,
  VehicleInspectionListResults,
  vehicleInspectionLists,
  VehicleInspectionQuickFilter,
  inspectionListResponseToResults,
} from '../../types';
import { VehicleInspectionDetails } from '../../../../api/endpoints/get-vehicle-inspection';
import { VehicleInspectionListRouterData } from '../../../../router/portal-routes/vehicle-inspections';
import { YearMonthDay } from '@rootTypes/utils/common/date';
import { MissingVehicleInspection } from '../../../../api/endpoints/get-missing-vehicle-inspections';
import { EntityState } from '@rootTypes';
import { createEntityState } from '@rootTypes/utils';
import { cleanUpStore } from '@rootStore';

const defaultPageSize = 10;

export interface VehicleInspectionsState {
  lists: VehicleInspectionListMap;
  listItemDetails: {
    [vehicleInspectionId: string]: EntityState<VehicleInspectionDetails>;
  };
}

export type VehicleInspectionListMap = {
  [name in VehicleInspectionListName]: VehicleInspectionList;
};

export interface VehicleInspectionList {
  selectedVehicleInspectionId?: string;
  listParams: VehicleInspectionListParams;
  listItems: EntityState<VehicleInspectionListResults>;
  missingInspections: EntityState<MissingVehicleInspection[]>;
  selectedMissingInspectionDriverId?: string;
}

const createInitialList = (staticVehicle?: { vehicleId: string; vehicleDisplayId: string }): VehicleInspectionList => {
  return {
    listParams: {
      vehicles: [],
      driverIds: [],
      assignedMechanicIds: [],
      inspectionStatus: [],
      mechanicAssessmentStatus: [],
      driverReviewStatus: [],
      pageIndex: 0,
      pageSize: defaultPageSize,
      staticVehicle,
      quickFilter: VehicleInspectionQuickFilter.NONE,
    },
    listItems: createEntityState(),
    missingInspections: createEntityState(),
  };
};

const createInitialMainList = (
  data: VehicleInspectionListRouterData = {},
  selectedVehicleInspectionId?: string,
): VehicleInspectionList => {
  return {
    selectedVehicleInspectionId,
    listParams: {
      vehicles: data.vehicles || [],
      driverIds: data.driverIds || [],
      assignedMechanicIds: data.assignedMechanicIds || [],
      inspectionStatus: data.inspectionStatus || [],
      mechanicAssessmentStatus: data.mechanicAssessmentStatus || [],
      driverReviewStatus: data.driverReviewStatus || [],
      pageIndex: data.pageIndex || 0,
      pageSize: data.pageSize || defaultPageSize,
      dateRange: data.dateRange,
      quickFilter: data.quickFilter || VehicleInspectionQuickFilter.NONE,
    },
    listItems: createEntityState(),
    missingInspections: createEntityState(),
  };
};

const createInitialState = (): VehicleInspectionsState => {
  const lists = {} as VehicleInspectionListMap;
  vehicleInspectionLists.forEach((listName) => {
    if (listName === VehicleInspectionListName.MAIN) {
      lists[VehicleInspectionListName.MAIN] = createInitialMainList();
    } else {
      lists[listName] = createInitialList();
    }
  });
  return {
    lists,
    listItemDetails: {},
  };
};

const getListState = (state: VehicleInspectionsState, listName: VehicleInspectionListName): VehicleInspectionList => {
  return state.lists[listName];
};

export const vehicleInspectionsReducer = createReducer(
  createInitialState(),
  on(cleanUpStore, createInitialState),
  on(
    fromActions.initVehicleInspectionList,
    (state, { listName, vehicleId, vehicleDisplayId }): VehicleInspectionsState => {
      const list = getListState(state, listName);
      if (list.listParams.staticVehicle?.vehicleId !== vehicleId) {
        return {
          ...state,
          lists: {
            ...state.lists,
            [listName]: createInitialList({ vehicleId, vehicleDisplayId }),
          },
        };
      }
      return state;
    },
  ),
  on(fromActions.initDriverInspectionList, (state, { listName, driverId }): VehicleInspectionsState => {
    const list = getListState(state, listName);
    if (list.listParams.staticDriverId !== driverId) {
      const newList = createInitialList();
      return {
        ...state,
        lists: {
          ...state.lists,
          [listName]: { ...newList, listParams: { ...newList.listParams, staticDriverId: driverId } },
        },
      };
    }
    return state;
  }),
  on(
    fromActions.initVehicleInspectionMainList,
    (state, { routerData, selectedVehicleInspectionId }): VehicleInspectionsState => {
      return {
        ...state,
        lists: {
          ...state.lists,
          [VehicleInspectionListName.MAIN]: createInitialMainList(routerData, selectedVehicleInspectionId),
        },
      };
    },
  ),
  on(fromActions.destroyVehicleInspectionMainList, (state): VehicleInspectionsState => {
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: createInitialMainList(),
      },
    };
  }),
  on(fromActions.loadVehicleInspectionListRequested, (state, { listName }): VehicleInspectionsState => {
    const list = getListState(state, listName);
    return {
      ...state,
      lists: {
        ...state.lists,
        [listName]: {
          ...list,
          listItems: { isLoading: true },
        },
      },
    };
  }),
  on(fromActions.loadVehicleInspectionListSuccess, (state, { listName, response }): VehicleInspectionsState => {
    const list = getListState(state, listName);
    const { pageIndex, pageSize } = list.listParams;
    return {
      ...state,
      lists: {
        ...state.lists,
        [listName]: {
          ...list,
          listItems: createEntityState(inspectionListResponseToResults(response, pageIndex, pageSize)),
        },
      },
    };
  }),
  on(fromActions.loadVehicleInspectionListFailed, (state, { listName, error }): VehicleInspectionsState => {
    const list = getListState(state, listName);
    return {
      ...state,
      lists: {
        ...state.lists,
        [listName]: {
          ...list,
          listItems: createEntityState(undefined, error),
        },
      },
    };
  }),
  on(fromActions.getVehicleInspectionRequested, (state, { vehicleInspectionId }): VehicleInspectionsState => {
    return {
      ...state,
      listItemDetails: {
        ...state.listItemDetails,
        [vehicleInspectionId]: { isLoading: true },
      },
    };
  }),
  on(
    fromActions.getVehicleInspectionSuccess,
    fromActions.updateVehicleInspectionDetailsOnUserSubmit,
    (state, { vehicleInspectionId, data }): VehicleInspectionsState => {
      return {
        ...state,
        listItemDetails: {
          ...state.listItemDetails,
          [vehicleInspectionId]: createEntityState(data),
        },
      };
    },
  ),
  on(fromActions.getVehicleInspectionFailed, (state, { vehicleInspectionId, error }): VehicleInspectionsState => {
    return {
      ...state,
      listItemDetails: {
        ...state.listItemDetails,
        [vehicleInspectionId]: createEntityState(undefined, error),
      },
    };
  }),
  on(fromActions.vehicleInspectionsPageChanged, (state, { listName, pageIndex }): VehicleInspectionsState => {
    const list = getListState(state, listName);
    return {
      ...state,
      lists: {
        ...state.lists,
        [listName]: {
          ...list,
          listParams: {
            ...list.listParams,
            pageIndex,
          },
        },
      },
    };
  }),
  on(fromActions.vehicleInspectionsPageSizeChanged, (state, { listName, pageSize }): VehicleInspectionsState => {
    const list = getListState(state, listName);
    return {
      ...state,
      lists: {
        ...state.lists,
        [listName]: {
          ...list,
          listParams: {
            ...list.listParams,
            pageSize,
            pageIndex: 0,
          },
        },
      },
    };
  }),
  on(fromActions.vehicleInspectionsSingleStatusChanged, (state, { listName, status }): VehicleInspectionsState => {
    const list = getListState(state, listName);
    return {
      ...state,
      lists: {
        ...state.lists,
        [listName]: {
          ...list,
          listParams: {
            ...list.listParams,
            inspectionStatus: status ? [status] : [],
            pageIndex: 0,
          },
        },
      },
    };
  }),
  on(fromActions.vehicleInspectionsDateRangeChanged, (state, { listName, dateRange }): VehicleInspectionsState => {
    const list = getListState(state, listName);
    return {
      ...state,
      lists: {
        ...state.lists,
        [listName]: {
          ...list,
          listParams: {
            ...list.listParams,
            dateRange,
            pageIndex: 0,
          },
        },
      },
    };
  }),
  on(fromActions.clearAllFilters, (state): VehicleInspectionsState => {
    const list = getListState(state, VehicleInspectionListName.MAIN);
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: {
          ...list,
          listParams: {
            ...list.listParams,
            dateRange: undefined,
            vehicles: [],
            driverIds: [],
            assignedMechanicIds: [],
            inspectionStatus: [],
            mechanicAssessmentStatus: [],
            driverReviewStatus: [],
            quickFilter: VehicleInspectionQuickFilter.NONE,
            pageIndex: 0,
          },
        },
      },
    };
  }),
  on(fromActions.addVehicleFilter, (state, { vehicle }): VehicleInspectionsState => {
    const list = getListState(state, VehicleInspectionListName.MAIN);
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: {
          ...list,
          listParams: {
            ...list.listParams,
            vehicles: [...list.listParams.vehicles, { ...vehicle }],
            pageIndex: 0,
          },
        },
      },
    };
  }),
  on(fromActions.removeVehicleFilter, (state, { vehicle }): VehicleInspectionsState => {
    const list = getListState(state, VehicleInspectionListName.MAIN);
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: {
          ...list,
          listParams: {
            ...list.listParams,
            vehicles: list.listParams.vehicles.filter((item) => item.vehicleId !== vehicle.vehicleId),
            pageIndex: 0,
          },
        },
      },
    };
  }),
  on(fromActions.addDriverFilter, (state, { driverId }): VehicleInspectionsState => {
    const list = getListState(state, VehicleInspectionListName.MAIN);
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: {
          ...list,
          listParams: {
            ...list.listParams,
            driverIds: [...list.listParams.driverIds, driverId],
            pageIndex: 0,
          },
        },
      },
    };
  }),
  on(fromActions.removeDriverFilter, (state, { driverId }): VehicleInspectionsState => {
    const list = getListState(state, VehicleInspectionListName.MAIN);
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: {
          ...list,
          listParams: {
            ...list.listParams,
            driverIds: list.listParams.driverIds.filter((id) => id !== driverId),
            pageIndex: 0,
          },
        },
      },
    };
  }),
  on(fromActions.removeAssignedMechanicFilter, (state, { mechanicId }): VehicleInspectionsState => {
    const list = getListState(state, VehicleInspectionListName.MAIN);
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: {
          ...list,
          listParams: {
            ...list.listParams,
            assignedMechanicIds: list.listParams.assignedMechanicIds.filter((id) => id !== mechanicId),
            pageIndex: 0,
          },
        },
      },
    };
  }),
  on(fromActions.removeMechanicAssignmentStatusFilter, (state, { value }): VehicleInspectionsState => {
    const list = getListState(state, VehicleInspectionListName.MAIN);
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: {
          ...list,
          listParams: {
            ...list.listParams,
            assignedMechanicIds: list.listParams.assignedMechanicIds.filter((id) => id !== value),
            pageIndex: 0,
          },
        },
      },
    };
  }),
  on(fromActions.removeInspectionStatusFilter, (state, { value }): VehicleInspectionsState => {
    const list = getListState(state, VehicleInspectionListName.MAIN);
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: {
          ...list,
          listParams: {
            ...list.listParams,
            inspectionStatus: list.listParams.inspectionStatus.filter((status) => status !== value),
            pageIndex: 0,
          },
        },
      },
    };
  }),
  on(fromActions.removeMechanicAssessmentStatusFilter, (state, { value }): VehicleInspectionsState => {
    const list = getListState(state, VehicleInspectionListName.MAIN);
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: {
          ...list,
          listParams: {
            ...list.listParams,
            mechanicAssessmentStatus: list.listParams.mechanicAssessmentStatus.filter((status) => status !== value),
            pageIndex: 0,
          },
        },
      },
    };
  }),
  on(fromActions.removeDriverReviewStatusFilter, (state, { value }): VehicleInspectionsState => {
    const list = getListState(state, VehicleInspectionListName.MAIN);
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: {
          ...list,
          listParams: {
            ...list.listParams,
            driverReviewStatus: list.listParams.driverReviewStatus.filter((status) => status !== value),
            pageIndex: 0,
          },
        },
      },
    };
  }),
  on(fromActions.setAdvancedFilters, (state, { filters }): VehicleInspectionsState => {
    const list = getListState(state, VehicleInspectionListName.MAIN);
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: {
          ...list,
          listParams: {
            ...list.listParams,
            assignedMechanicIds: [...filters.assignedMechanicIds],
            driverReviewStatus: [...filters.driverReviewStatus],
            inspectionStatus: [...filters.inspectionStatus],
            mechanicAssessmentStatus: [...filters.mechanicAssessmentStatus],
            pageIndex: 0,
          },
        },
      },
    };
  }),
  on(
    fromActions.selectedVehicleInspectionChanged,
    (state, { selectedVehicleInspectionId }): VehicleInspectionsState => {
      const list = getListState(state, VehicleInspectionListName.MAIN);
      return {
        ...state,
        lists: {
          ...state.lists,
          [VehicleInspectionListName.MAIN]: {
            ...list,
            selectedVehicleInspectionId,
          },
        },
      };
    },
  ),
  on(fromActions.vehicleInspectionDetailsOpened, (state, { selectedVehicleInspectionId }): VehicleInspectionsState => {
    const list = getListState(state, VehicleInspectionListName.MAIN);
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: {
          ...list,
          selectedVehicleInspectionId,
        },
      },
    };
  }),
  on(fromActions.vehicleInspectionDetailsClosed, (state): VehicleInspectionsState => {
    const list = getListState(state, VehicleInspectionListName.MAIN);
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: {
          ...list,
          selectedVehicleInspectionId: undefined,
        },
      },
    };
  }),
  on(fromActions.inspectionQuickFilterChanged, (state, { value }): VehicleInspectionsState => {
    const list = getListState(state, VehicleInspectionListName.MAIN);
    const listParams: VehicleInspectionListParams = {
      ...list.listParams,
      quickFilter: value,
      pageIndex: 0,
    };
    if (value === VehicleInspectionQuickFilter.MISSING_INSPECTIONS) {
      listParams.dateRange = { from: YearMonthDay.today(), to: YearMonthDay.tomorrow() };
      listParams.vehicles = [];
      listParams.assignedMechanicIds = [];
      listParams.driverIds = [];
      listParams.driverReviewStatus = [];
      listParams.inspectionStatus = [];
      listParams.mechanicAssessmentStatus = [];
    }
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: {
          ...list,
          listParams,
        },
      },
    };
  }),
  on(fromActions.removeInspectionQuickFilter, (state): VehicleInspectionsState => {
    const list = getListState(state, VehicleInspectionListName.MAIN);
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: {
          ...list,
          listParams: {
            ...list.listParams,
            quickFilter: VehicleInspectionQuickFilter.NONE,
            pageIndex: 0,
          },
        },
      },
    };
  }),
  on(fromActions.loadMissingInspectionsRequested, (state): VehicleInspectionsState => {
    const list = getListState(state, VehicleInspectionListName.MAIN);
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: {
          ...list,
          missingInspections: { isLoading: true },
        },
      },
    };
  }),
  on(fromActions.loadMissingInspectionsSuccess, (state, { data }): VehicleInspectionsState => {
    const list = getListState(state, VehicleInspectionListName.MAIN);
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: {
          ...list,
          missingInspections: createEntityState(data),
        },
      },
    };
  }),
  on(fromActions.loadMissingInspectionsFailed, (state, { error }): VehicleInspectionsState => {
    const list = getListState(state, VehicleInspectionListName.MAIN);
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: {
          ...list,
          missingInspections: createEntityState(undefined, error),
        },
      },
    };
  }),
  on(
    fromActions.selectedMissingInspectionChanged,
    (state, { selectedMissingInspectionDriverId }): VehicleInspectionsState => {
      const list = getListState(state, VehicleInspectionListName.MAIN);
      return {
        ...state,
        lists: {
          ...state.lists,
          [VehicleInspectionListName.MAIN]: {
            ...list,
            selectedMissingInspectionDriverId,
          },
        },
      };
    },
  ),
  on(fromActions.missingInspectionDriverDetailsClosed, (state): VehicleInspectionsState => {
    const list = getListState(state, VehicleInspectionListName.MAIN);
    return {
      ...state,
      lists: {
        ...state.lists,
        [VehicleInspectionListName.MAIN]: {
          ...list,
          selectedMissingInspectionDriverId: undefined,
        },
      },
    };
  }),
);
