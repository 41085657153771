import { StandbyDriverStatus } from '@apiEntities/driver-standby-status';
import { createReducer, on } from '@ngrx/store';
import { cleanUpStore, createEntityState, DriverLoginSetting, DriverStatus } from '../../types';
import { Driver, EntityState, ServiceArea } from '../../types/entities';
import * as fromActions from '../actions/driver-data.actions';

export interface DriverDataState {
  drivers: {
    [driverId: string]: EntityState<Driver>;
  };
  driverRating: {
    [driverId: string]: DriverRatingState;
  };
  vehiclesByDriver: {
    [driverId: string]: VehicleByDriverState;
  };
  yardsByDriver: {
    [driverId: string]: YardByDriverState;
  };
  serviceAreas: EntityState<ServiceArea[]>;
  loginSettings: {
    [driverId: string]: EntityState<DriverLoginSetting[]>;
  };
  standbyStatus: {
    [driverId: string]: StandbyDriverStatus;
  };
}

export interface DriverRatingState {
  rating: number;
}

export interface VehicleByDriverState {
  vehicleId: string;
}

export interface YardByDriverState {
  yardId: string;
}

export const createInitialDriverDataState = (): DriverDataState => {
  return {
    drivers: {},
    driverRating: {},
    vehiclesByDriver: {},
    yardsByDriver: {},
    serviceAreas: createEntityState(),
    loginSettings: {},
    standbyStatus: {},
  };
};

export const driverDataReducer = createReducer<DriverDataState>(
  createInitialDriverDataState(),
  on(cleanUpStore, createInitialDriverDataState),
  // load driver
  on(
    fromActions.loadDriverRequested,
    fromActions.loadDriverRequestedFromTimesheetChangeRequestItem,
    fromActions.loadDriverRequestedFromTimesheetChangeRequestDetails,
    fromActions.loadDriverRequestedFromCreateTimesheetEntry,
    (state, action): DriverDataState => {
      const { driverId } = action;
      const entityState = state.drivers[driverId] || createEntityState();
      return {
        ...state,
        drivers: {
          ...state.drivers,
          [driverId]: {
            ...entityState,
            isLoading: true,
            error: undefined,
          },
        },
      };
    },
  ),

  on(fromActions.loadDriverSuccess, (state, action): DriverDataState => {
    const { driver, vehicle, rating, yard, standby, requestOptions } = action;
    const { id: driverId } = driver;

    const result = {
      ...state,
      drivers: {
        ...state.drivers,
        [driverId]: createEntityState(driver),
      },
    };

    if (requestOptions.withVehicle) {
      result.vehiclesByDriver = {
        ...result.vehiclesByDriver,
        [driverId]: {
          vehicleId: vehicle?.id || undefined,
        },
      };
    }

    if (requestOptions.withRating) {
      result.driverRating = {
        ...result.driverRating,
        [driverId]: {
          rating: rating || undefined,
        },
      };
    }

    if (requestOptions.withYard) {
      result.yardsByDriver = {
        ...result.yardsByDriver,
        [driverId]: {
          yardId: yard?.id || undefined,
        },
      };
    }

    if (requestOptions.withStandbyStatus) {
      result.standbyStatus = {
        ...result.standbyStatus,
        [driverId]: standby?.standbyDriverConfigId ? (standby as StandbyDriverStatus) : undefined,
      };
    }
    return result;
  }),

  on(fromActions.loadDriverFailed, (state, action): DriverDataState => {
    const { error, driverId } = action;
    return {
      ...state,
      drivers: {
        ...state.drivers,
        [driverId]: createEntityState(undefined, error),
      },
    };
  }),
  on(fromActions.getAvailableServiceAreasRequested, (state, action): DriverDataState => {
    return {
      ...state,
      serviceAreas: {
        isLoading: true,
        entity: state.serviceAreas.entity,
      },
    };
  }),
  on(fromActions.getAvailableServiceAreasSuccess, (state, action): DriverDataState => {
    return {
      ...state,
      serviceAreas: createEntityState(action.response),
    };
  }),
  on(fromActions.getAvailableServiceAreasFailed, (state, action): DriverDataState => {
    return {
      ...state,
      serviceAreas: createEntityState(undefined, action.error),
    };
  }),
  /**
   * Load security settings
   */
  on(fromActions.getSecuritySettingsForDriverRequested, (state, action) => {
    const { driverId } = action;
    const previousState = state.loginSettings[driverId] || createEntityState(undefined, undefined);
    return {
      ...state,
      loginSettings: {
        ...state.loginSettings,
        [driverId]: {
          ...previousState,
          isLoading: true,
        },
      },
    };
  }),
  on(fromActions.getSecuritySettingsForDriverSuccess, (state, action) => {
    const { driverId, settings } = action;
    const previousState = state.loginSettings[driverId] || createEntityState(undefined, undefined);
    return {
      ...state,
      loginSettings: {
        ...state.loginSettings,
        [driverId]: {
          ...previousState,
          isLoading: false,
          entity: settings,
        },
      },
    };
  }),
  on(fromActions.setDriverLoginMethodEnabledState, (state, action) => {
    const { driverId, loginMethod, enabled } = action;
    const previousState = state.loginSettings[driverId];
    const previousSettings = previousState?.entity;
    if (!previousSettings) {
      return {
        ...state,
      };
    } else {
      const newSettings = previousSettings.map((source) => {
        if (source.method === loginMethod) {
          return {
            ...source,
            enabled,
          };
        } else {
          return source;
        }
      });
      return {
        ...state,
        loginSettings: {
          ...state.loginSettings,
          [driverId]: {
            ...previousState,
            entity: newSettings,
          },
        },
      };
    }
  }),
  on(fromActions.getSecuritySettingsForDriverFailed, (state, action) => {
    const { driverId, error } = action;
    const previousState = state.loginSettings[driverId] || createEntityState(undefined, undefined);
    return {
      ...state,
      loginSettings: {
        ...state.loginSettings,
        [driverId]: {
          ...previousState,
          isLoading: false,
          error,
        },
      },
    };
  }),
  on(fromActions.deleteDraftDriverSuccess, (state, { driverId }): DriverDataState => {
    const driver = state.drivers[driverId]?.entity;
    if (driver) {
      return {
        ...state,
        drivers: {
          ...state.drivers,
          [driverId]: createEntityState({ ...driver, status: DriverStatus.DELETED }),
        },
      };
    }
    return state;
  }),
);
