import { combineReducers } from '@ngrx/store';

import { YardDataState } from '../../types';
import { yardDataReducer } from './yard-data.reducer';
import * as fromLists from './yard-lists.reducer';
import * as fromDetails from './yard-details.reducer';

export interface YardStoreState {
  data: YardDataState;
  lists: fromLists.AllYardListState;
  details: fromDetails.YardDetailsState;
}

export const yardFeatureReducer = combineReducers<YardStoreState>({
  data: yardDataReducer,
  lists: fromLists.yardListsReducer,
  details: fromDetails.vendorDetailsReducer,
});
