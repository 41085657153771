import { Observable } from 'rxjs';
import { EntityFilterOptions } from 'src/app/api/endpoints/entity-filter';
import * as fromTypes from '../../types/entities/common';
export * from '../../types/entities/common';
import * as utils from '../../types/utils/common';
export { utils };

export type FilterOptions = EntityFilterOptions;

export type SearchResponseWithSearhTerm = {
  searchTerm: string;
  results: fromTypes.PortalEntity[];
  nextSkip: number;
  error?: any | null;
};

export interface EntityFilterStore {
  closeDropdown(): void;
  dropdownOpen$(): Observable<boolean>;
  loadNextPage(): void;
  items$(): Observable<fromTypes.PortalEntity[]>;
  empty$(): Observable<boolean>;
  error$(): Observable<any>;
  hasNext$(): Observable<boolean>;
  openDropdown(): void;
  pageLoading(): Observable<boolean>;
  search(searchTerm: string): void;
  setSearchByType(type: fromTypes.PortalEntityType): void;
  setEntityStatus(status: fromTypes.EntityStatus): void;
  setFilterOptions(options: FilterOptions): void;
}

export interface EntityFilterState {
  emitLine?: number; // remove on test
  isDropdownOpen: boolean;
  searchTerm: string;
  currentPage: number;
  nextSkip: number;
  status: fromTypes.EntityStatus;
  searchByType: fromTypes.PortalEntityType;
  filterOptions: FilterOptions;
  results: {
    pageLoading: boolean;
    items: fromTypes.PortalEntity[];
    error: any;
  };
}

export const initialEntityFilterState: EntityFilterState = {
  isDropdownOpen: false,
  searchTerm: null,
  currentPage: 0,
  nextSkip: 0,
  status: undefined,
  searchByType: null,
  filterOptions: {},
  results: {
    pageLoading: false,
    items: [],
    error: null,
  },
};
