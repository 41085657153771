import { Pipe, PipeTransform } from '@angular/core';
import { ApiCampus } from '../../api/endpoints/common';

@Pipe({
  name: 'campusGradesDisplay',
})
export class CampusGradesDisplayPipe implements PipeTransform {
  transform(value: ApiCampus['gradesV2']): string {
    if (!value) {
      return undefined;
    }
    const selectedIds = value.selectedGradeIds || [];
    const standard = value.standardGrades || [];
    const custom = value.customGrades || [];
    const all = [...standard, ...custom];
    const selected = all.filter((grade) => selectedIds.includes(grade.gradeId));
    if (!selected.length) {
      return undefined;
    }
    return selected.map((grade) => grade.value).join(', ');
  }
}
