import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { SideBarService } from '../side-bar.service';
import { GotoPageService } from '../../../core/goto-page/goto-page.service';

@Component({
  selector: 'wp-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() activeId: string;
  @Input() public isExpanded!: boolean;
  @Output() public expandedChanged: EventEmitter<void> = new EventEmitter<void>();
  constructor(
    private sideBarService: SideBarService,
    private goToPageService: GotoPageService,
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeId && changes.activeId.currentValue) {
      this.sideBarService.active = changes.activeId.currentValue;
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public change(): void {
    this.expandedChanged.emit();
  }

  public onGoToPageIconClicked(): void {
    this.goToPageService.open();
  }
}
