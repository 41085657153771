const defaultHashFn = (s: any): string => JSON.stringify(s);

/**
 * Do arrays contain equal elements, irrespectively of their order.
 * Returns true, if both arrays are nullish
 * @param arr1
 * @param arr2
 * @param hashFn - (optional) how we get hash value of the array item. JSON.stringify by default
 */
export function arraysEqual<T>(arr1: T[], arr2: T[], hashFn: (item: T) => string = defaultHashFn): boolean {
  if (!(arr1 && arr2)) {
    return true;
  }
  if (!arr1 && !!arr2) {
    return false;
  }
  if (!!arr1 && !arr2) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  const hashArray1 = arr1.map((s) => hashFn(s));
  hashArray1.sort();
  const hashArray2 = arr2.map((s) => hashFn(s));
  hashArray2.sort();
  return hashArray1.join() === hashArray2.join();
}
