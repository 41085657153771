import { Action, ActionCreator, createAction, props } from '@ngrx/store';
import { PortalListBaseParams } from '@rootTypes/utils/list-store-utils/portal-list-base-state';
import { WpError } from '@rootTypes/entities/common';
import { PortalListFilter } from '@rootTypes/utils/list-store-utils/portal-list-filter';

type TypedActionConstructorWithProps<Props> = ActionCreator<string, (props: Props) => Props & Action>;
type TypedActionConstructorWithoutProps = ActionCreator<string, () => Action>;

export class PortalListStoreActions<
  Params extends PortalListBaseParams<Filter>,
  Filter extends PortalListFilter,
  Item,
> {
  public readonly addedFilter: TypedActionConstructorWithProps<{ filter: PortalListFilter }>;
  public readonly removedFilter: TypedActionConstructorWithProps<{ filterId: string }>;
  /**
   * This action replaces filters of the given type with the new filter array
   */
  public readonly setFiltersOfType: TypedActionConstructorWithProps<{
    filterType: string;
    filters: PortalListFilter[];
  }>;
  public readonly clearedAllFilters: TypedActionConstructorWithoutProps;
  public readonly initialized: TypedActionConstructorWithProps<{ initParams: Partial<Params> }>;
  public readonly receivedParamsFromUrl: TypedActionConstructorWithProps<{ params: Partial<Params> }>;
  public readonly selectedItemIdChanged: TypedActionConstructorWithProps<{ itemId: string }>;
  public readonly pageChanged: TypedActionConstructorWithProps<{ page: number }>;
  public readonly pageSizeChanged: TypedActionConstructorWithProps<{ pageSize: number }>;
  public readonly searchQueryChanged: TypedActionConstructorWithProps<{ searchQuery: string }>;
  public readonly loadRequested: TypedActionConstructorWithProps<{ params: Params }>;
  public readonly loadSuccess: TypedActionConstructorWithProps<{ items: Item[]; total: number }>;
  public readonly loadFailed: TypedActionConstructorWithProps<{ error: WpError }>;
  public readonly destroyed: TypedActionConstructorWithoutProps;

  public readonly defaultListChangedActions: ActionCreator<string>[];

  constructor(private actionPrefix: string) {
    this.addedFilter = this.addFilterAction();
    this.removedFilter = this.removeFilterAction();
    this.setFiltersOfType = this.setFilterOfTypeAction();
    this.clearedAllFilters = this.clearAllFiltersAction();
    this.initialized = this.initializedAction();
    this.receivedParamsFromUrl = this.receivedParametersFromUrlAction();
    this.selectedItemIdChanged = this.selectedItemIdChangedAction();
    this.pageChanged = this.pageChangedAction();
    this.pageSizeChanged = this.pageSizeChangedAction();
    this.searchQueryChanged = this.searchQueryChangedAction();
    this.loadRequested = this.loadRequestedAction();
    this.loadSuccess = this.loadSuccessAction();
    this.loadFailed = this.loadFailedAction();
    this.destroyed = this.destroyedListAction();

    this.defaultListChangedActions = [
      this.addedFilter,
      this.removedFilter,
      this.setFiltersOfType,
      this.clearedAllFilters,
      this.initialized,
      this.receivedParamsFromUrl,
      this.pageChanged,
      this.pageSizeChanged,
      this.searchQueryChanged,
    ];
  }

  private addFilterAction() {
    return createAction(`[${this.actionPrefix}]: Filter added`, props<{ filter: PortalListFilter }>());
  }

  private setFilterOfTypeAction() {
    return createAction(
      `[${this.actionPrefix}]: Set filters of type`,
      props<{ filterType: string; filters: PortalListFilter[] }>(),
    );
  }

  private removeFilterAction() {
    return createAction(`[${this.actionPrefix}]: Filter removed`, props<{ filterId: string }>());
  }

  private clearAllFiltersAction() {
    return createAction(`[${this.actionPrefix}]: Cleared all filters`);
  }

  public destroyedListAction() {
    return createAction(`[${this.actionPrefix}]: Destroyed list`);
  }

  private initializedAction() {
    return createAction(`[${this.actionPrefix}]: Initialized`, props<{ initParams: Partial<Params> }>());
  }

  private receivedParametersFromUrlAction() {
    return createAction(`[${this.actionPrefix}]: Received params from url`, props<{ params: Partial<Params> }>());
  }

  private selectedItemIdChangedAction() {
    return createAction(`[${this.actionPrefix}]: Selected item id changed`, props<{ itemId: string }>());
  }

  private pageChangedAction() {
    return createAction(`[${this.actionPrefix}]: Page changed`, props<{ page: number }>());
  }

  private pageSizeChangedAction() {
    return createAction(`[${this.actionPrefix}]: Page size changed`, props<{ pageSize: number }>());
  }

  private searchQueryChangedAction() {
    return createAction(`[${this.actionPrefix}]: Search query changed`, props<{ searchQuery: string }>());
  }

  private loadRequestedAction() {
    return createAction(`[${this.actionPrefix}] [Effects]: API Load list requested`, props<{ params: Params }>());
  }

  private loadSuccessAction() {
    return createAction(
      `[${this.actionPrefix}] [Effects]: API Load list success`,
      props<{ items: Item[]; total: number }>(),
    );
  }

  private loadFailedAction() {
    return createAction(`[${this.actionPrefix}] [Effects]: API Load list failed`, props<{ error: WpError }>());
  }
}
